import axios from './AxiosInstance'

export default {
  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'related_user/list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ related_user_status: res.data.related_user_status })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchForManage: ({organizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'related_user/list_for_manage',
        withCredentials: true,
        params: { organization_id: organizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ related_users: res.data.related_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  destroy: ({organizationID, targetUserID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'related_user',
            withCredentials: true,
            params: { target_user_id: targetUserID, organization_id: organizationID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
