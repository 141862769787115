<template>
  <div class="container" id="container">
    <horus-navi-bar
      :signinUser="loggedInUser"
      :signinMember="signinMember"
      :signinReporter="signinReporter"
      :author="author"
      :taskDisplayConfiguration="taskDisplayConfiguration"
      :serverTaskShouldUpdate="serverTaskShouldUpdate"
      v-on:switchLoginMemberEvent="switchLoginMember"
      v-on:switchLoginReporterEvent="switchLoginReporter"
      v-on:reloadOrganizationEvent="reloadOrganization"
      v-on:showQuickQrEvent="showQuickQrDisplayModal"
      v-on:taskUpdateCheckedEvent="taskUpdateChecked"
      v-on:showUserTaskDetailEvent="showUserTaskDetail"
    />
    <router-view
      :loggedInUser="loggedInUser"
      :signinMember="signinMember"
      :signinReporter="signinReporter"
      :organization="organization"
      :reporterOrganization="reporterOrganization"
      :loggedInAuthor="author"
      :zoomUser="zoomUser"
      :taskUpdatedAt="taskUpdatedAt"
      :taskDisplayConfiguration="taskDisplayConfiguration"
      v-on:loginUserUpdatedEvent="loadLoggedInUser"
      v-on:showQrReaderEvent="showQrReader"
      v-on:showManualQrInputEvent="showManualQrInput"
      v-on:showQuickAgreementActionEvent="showQuickAgreementAction"
      v-on:reloadOrganizationEvent="reloadOrganization"
      v-on:reloadReporterOrganizationEvent="reloadReporterOrganization"
      v-on:reloadZoomUserEvent="loadZoomUser"
      v-on:taskUpdatedEvent="checkTaskUpdated"
      v-on:showTaskConfigurationEvent="showTaskConfiguration"
      v-on:showUserTaskDetailEvent="showUserTaskDetail"
      />
    <horus-quick-qr-display-modal
      :showModal="showQuickQrDisplay"
      :signinUser="signinUser"
      v-on:closeModalEvent="closeQrDisplay"
      />
    <horus-qr-code-reader-modal
      :showModal="showQrReaderModal"
      :title="qrReaderTitle"
      :showFooter="showQrReaderFooter"
      :categories="qrReaderFooterCategories"
      v-on:qrDecodedEvent="qrDecoded"
      v-on:qrReaderCancelEvent="cancelQrRead"
      />
    <input type="text" name="qrReadResult" id="qrReadResult" style="display: none;cursor: pointer;" ref="qrResultText" v-model="qrReadResult" />
    <input type="text" name="qrReadCategoryResult" id="qrReadCategoryResult" style="display: none;cursor: pointer;" ref="qrCategoryResult" v-model="qrCategoryResult" />
    <horus-quick-agreement-action-modal
      :showModal="showQuickAgreementActionModal"
      :title="quickAgreementOrder.title"
      :author="quickAgreementOrder.author"
      :quickAgreementCode="quickAgreementOrder.quickAgreementCode"
      :organizationID="quickAgreementOrder.organizationID"
      :reporterOrganizatonID="quickAgreementOrder.reporterOrganizationID"
      :userID="quickAgreementOrder.userID"
      :callback="quickAgreementOrder.callback"
      :actionType="quickAgreementOrder.actionType"
      v-on:closeModalEvent="closeQuickAgreementActionModal"
      />
    <horus-manual-qr-input-modal
      :showModal="showQrInputModal"
      :title="qrInputTitle"
      v-on:qrSubmitEvent="qrInputSubmit"
      v-on:qrInputCancelEvent="cancelQrInput"
      />
    <horus-user-task-detail-modal
      :showModal="showUserTaskDetailModal"
      :userTask="detailTargetUserTask"
      v-on:closeModalEvent="closeUserTaskDetail"
      v-on:startVideoMeetingEvent="startVideoMeeting"
      v-on:showTaskPhotoEvent="showTaskPhoto"
      />
    <horus-task-display-configuration-modal
      :showModal="taskDisplayConfModalShow"
      :signinUser="signinUser"
      :signinMember="signinMember"
      :taskDisplayConfiguration="taskDisplayConfiguration"
      v-on:cancelUpdateEvent="cancelTaskDisplayConfigUpdate"
      v-on:updateFinishEvent="taskDisplayConfigUpdateFinished"
      />
    <horus-photo-modal
      :photos="photoModalPhotos"
      :type="'userTask'"
      :author="null"
      :targetAt="null"
      :title="'予定写真'"
      :firstShow="photoModalFirst"
      :hashTags="[]"
      :showPhotoModal="photoModalShow"
      v-on:photoModalCloseEvent="closePhotoModal"
      />
    <horus-critical-notification-modal
      :showModal="criticalNotificationModalDisplay"
      :criticalNotifications="firedCriticalNotifications"
      :confirmedNotifications="confirmedCriticalNotifications"
      v-on:toggleNotificationConfirmationEvent="toggleCriticalNotificationConfirm"
      v-on:resolveNotificationEvent="resolveCriticalNotification"
      v-on:closeModalEvent="closeCriticalNotificationModal"
      />
  </div>
</template>

<script>
import {
  Author,
  SigninUser,
  SigninMember,
  SigninReporter,
  TimelineOwner,
  Organization,
  ReporterOrganization,
  TaskDisplayConfiguration,
  // StaffVisibility,
  ZoomMeeting
} from '@/models'
import { 
  CriticalNotification,
  StaticIdentifiyCodeCategory,
  // ZoomMeeting,
  ZoomUser
} from '@/api'

import HorusNaviBar from '@/components/navigation/HorusNaviBar.vue'
import HorusQrCodeReaderModal from '@/components/qrModal/HorusQrCodeReaderModal.vue'
import HorusManualQrInputModal from '@/components/qrModal/HorusManualQrInputModal.vue'
import HorusQuickQrDisplayModal from '@/components/qrModal/HorusQuickQrDisplayModal.vue'
import HorusQuickAgreementActionModal from '@/components/qrModal/HorusQuickAgreementActionModal.vue'
import HorusTaskDisplayConfigurationModal from '@/components/userTask/HorusTaskDisplayConfigurationModal.vue'
import HorusUserTaskDetailModal from '@/components/userTask/HorusUserTaskDetailModal.vue'
import HorusPhotoModal from '@/components/common/HorusPhotoModal.vue'
import HorusCriticalNotificationModal from '@/components/criticalNotification/HorusCriticalNotificationModal.vue'

import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

export default {
  name: 'HorusSignedInView',

  components: {
    HorusNaviBar,
    HorusQrCodeReaderModal,
    HorusManualQrInputModal,
    HorusQuickQrDisplayModal,
    HorusQuickAgreementActionModal,
    HorusTaskDisplayConfigurationModal,
    HorusUserTaskDetailModal,
    HorusPhotoModal,
    HorusCriticalNotificationModal
  },

  data () {
    return {
      signinUser: null,
      signinMember: null,
      signinReporter: null,
      loggedInUser: null,
      author: null,
      organization: null,
      reporterOrganization: null,
      qrAvailable: false,
      qrReaderCallback: null,
      qrReaderTitle: '',
      showQrReaderModal: false,
      showQrReaderFooter: true,
      qrReaderFooterCategories: [],
      showQrInputModal: false,
      qrInputCallback: null,
      qrInputTitle: '',
      showQuickQrDisplay: false,
      showQuickAgreementActionModal: false,
      quickAgreementOrder: {},
      qrReadResult: '',
      qrCategoryResult: '',
      zoomUser: null,
      serverTaskShouldUpdate: false,
      taskUpdatedAt: null,
      taskDisplayConfiguration: null,
      taskDisplayConfModalShow: false,
      showUserTaskDetailModal: false,
      detailTargetUserTask: null,
      photoModalPhotos: [],
      photoModalFirst: null,
      photoModalShow: false,
      criticalNotificationModalDisplay: false,
      firedCriticalNotifications: [],
      confirmedCriticalNotifications: []
    }
  },

  watch: {
    signinUser: { handler: 'loadLoggedInUser', immediate: true },
    signinMember: { handler: 'loadLoggedInMember', immediate: true },
    signinReporter: { handler: 'loadLoggedInReporter', immediate: true },
    qrReadResult: { handler: 'qrResultChange' },
    author: { handler: 'loadTaskConfiguration' }
  },

  created () {
    this.prepareSigninUser()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.qrAvailable = status
    }
    // this.$native.appSendWebSetNative = status => {
    //   this.$native.status = status
    // }
  },

  updated () {
    if (this.$native.status === true) {
      this.qrAvailable = this.$native.status
    }
  },

  methods: {
    async loadLoggedInUser () {
      if (this.signinUser) {
        // console.log('loadLoggedInUser')
        await TimelineOwner.api().fetch({ timelineOwnerID: this.signinUser.user_id })
        this.loggedInUser = await TimelineOwner.find(this.signinUser.user_id)
        this.author = this.loggedInUser.author
        this.serverTaskShouldUpdate = true
      }
    },

    async loadLoggedInMember () {
      console.log('load member call,')
      console.log(this.signinMember)
      if (this.signinMember) {
        if (this.signinMember.author) {
          this.author = this.signinMember.author
        } else {
          await Author.api().fetchByID({ idList: [this.signinMember.author_id] })
          this.author = this.signinMember.author
        }
        await Author.api().fetchByID({ idList: ['usr' + this.signinMember.user_id] })
        if (!this.signinMember.organization) {
          await Organization.api().fetchByID({ idList: [this.signinMember.organization_id] })
        }
        this.organization = this.signinMember.organization
        // console.log('load visibility')
        // await StaffVisibility.api().listInOrganization({ organizationID: this.signinMember.organization_id })
        this.serverTaskShouldUpdate = true
      }
    },

    async reloadOrganization () {
      if (this.signinMember) {
        await Organization.api().fetchByID({ idList: [this.signinMember.organization_id] })
        this.organization = await Organization.find(this.signinMember.organization_id)
        // load staff visibilities
        // await StaffVisibility.api().listInOrganization({ organizationID: this.signinMember.organization_id })
      }
    },

    async loadLoggedInReporter () {      
      if (this.signinReporter) {
        // console.log('loadLoggedInReporter')
        if (!this.signinReporter.author) {
          await Author.api().fetchByID({ idList: [this.signinReporter.author_id] })
        }
        this.author = this.signinReporter.author
        await Author.api().fetchByID({ idList: ['usr' + this.signinReporter.user_id] })
        if (!this.signinReporter.reporterOrganization) {
          await ReporterOrganization.api().fetchByID({ idList: [this.signinReporter.reporter_organization_id] })
        }
        this.reporterOrganization = this.signinReporter.reporterOrganization
      }
    },

    async reloadReporterOrganization () {
      if (this.signinReporter) {
        await ReporterOrganization.fetchByID({ idList: [this.signinReporter.reporter_organization_id] })
        this.reporterOrganization = ReporterOrganization.find(this.signinReporter.reporter_organization_id)
      }
    },

    loadZoomUser () {
      this.zoomUser = null
      console.log('VUE_APP_MEETING_AVAILABLE:' + process.env.VUE_APP_MEETING_AVAILABLE)
      console.log('VUE_APP_MEETING_AVAILABLE type:' + typeof process.env.VUE_APP_MEETING_AVAILABLE)
      if (process.env.VUE_APP_MEETING_AVAILABLE === 'true') {
        console.log('fetch zoom account.')
        ZoomUser.fetchStored().then(res => {
          if (res.zoomUser) {
            this.zoomUser = res.zoomUser
          }
        }).catch(error => {
          console.error(error.response)
          this.zoomUser = null
        })
      }
    },

    async loadTaskConfiguration () {
      if (this.author) {
        await TaskDisplayConfiguration.api().fetch()
        this.taskDisplayConfiguration = TaskDisplayConfiguration.query().where('author_id', this.author.id).first()
      }
    },

    prepareSigninUser () {
      // console.log('prepareSigninUser')
      this.signinUser = SigninUser.query().first()
      this.signinMember = SigninMember.query().first()
      this.signinReporter = SigninReporter.query().first()
      if (process.env.VUE_APP_MEETING_AVAILABLE === 'true') {
        this.loadZoomUser()
      }
      // check critical notifications here.
      this.loadFiredCriticalNotifications()
      // console.log(this.signinUser)
      // console.log(this.signinMember)
      // console.log(this.signinReporter)
    },

    switchLoginMember (memberID) {
      console.log('switch member call,')
      console.log(this.signinMember)
      SigninMember.api().switch({ switchTargetID: memberID }).then(() => {
        this.signinMember = SigninMember.query().first()
        console.log(this.signinMember)
        this.loadLoggedInMember()
        this.$router.push({ path: '/member_portal' })
      })
    },

    switchLoginReporter (reporterID) {
      SigninReporter.api().switch({ switchTargetID: reporterID }).then(() => {
        this.signinReporter = SigninReporter.query().first()
        this.loadLoggedInReporter()
        this.$router.push({ path: '/reporter_portal' })
      })
    },

    // Critical Notification functions ------------------------------------------
    loadFiredCriticalNotifications () {
      // console.log('load notifications')
      CriticalNotification.fetchFiredList().then(res => {
        if (res.critical_notification_targets && res.critical_notification_targets.length > 0) {
          this.firedCriticalNotifications = res.critical_notification_targets
          this.confirmedCriticalNotifications = []
          this.criticalNotificationModalDisplay = true
        }
      }).catch(err => {
        console.error(err)
      })
    },

    toggleCriticalNotificationConfirm (notification) {
      if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE) {
        var index = this.confirmedCriticalNotifications.indexOf(notification)
        if (index > 0) {
          this.confirmedCriticalNotifications.splice(index, 1)
          notification.resolved = false
        } else {
          this.confirmedCriticalNotifications.push(notification)
          notification.resolved = true
        }
      }
    },

    resolveCriticalNotification (notification) {
      // console.log('resolve notification')
      if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_SHOW_ONECE) {
        CriticalNotification.resolveByToken({ confirmationToken: notification.target_token }).then(res => {
          if (res.success) {
            notification.resolved = true
          }
          return notification
        }).catch(err => {
          console.error(err)
        })
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE) {
        this.confirmedCriticalNotifications.push(notification)
        CriticalNotification.resolveByToken({ confirmationToken: notification.target_token }).then(res => {
          // console.log(res)
          if (res.success) {
            notification.resolved = true
          }
          return notification
        }).catch(err => {
          console.error(err)
        })
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET && notification.resolve_url) {
        this.$router.push({ path: notification.resolve_url, query: { criticalNotification: notification } })
      }
      if (this.firedCriticalNotifications.every(notification => notification.resolved)) {
        this.criticalNotificationModalDisplay = false
      }
    },

    async closeCriticalNotificationModal () {
      // console.log('closeCriticalNotificationModal call,')
      for(var notification of this.firedCriticalNotifications) {
        if (!notification.resolved) {
          if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_SHOW_ONECE) {
            await CriticalNotification.resolveByToken({ confirmationToken: notification.target_token }).then(res => {
              if (res.success) {
                notification.resolved = true
              }
              return notification
            }).catch(err => {
              console.error(err)
            })
          } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE && this.confirmedCriticalNotifications.some(n => n === notification)) {
            this.confirmedCriticalNotifications.push(notification)
            await CriticalNotification.resolveByToken({ confirmationToken: notification.target_token }).then(res => {
              if (res.success) {
                notification.resolved = true
              }
              return notification
            }).catch(err => {
              console.error(err)
            })
          }
        }
      }
      this.criticalNotificationModalDisplay = false
    },

    // QR functions ---------------------
    showQrReader (title, callback, showFooter) {
      // fire event
      this.qrReaderTitle = ''
      this.qrReaderCallback = null
      this.showQrReaderFooter = false
      this.qrReadResult = ''
      this.qrCategoryResult = ''
      if (showFooter) {
        StaticIdentifiyCodeCategory.fetch().then(res => {
          this.qrReaderFooterCategories = res.staticIdentifyCodeCategories
          // console.log(this.qrReaderFooterCategories)
          if (this.$native.status) {
            this.qrReaderCallback = callback
            this.showQrReaderModal = true
            this.$native.event('readQrEvent', { title: title, showFooter: showFooter, categories: this.qrReaderFooterCategories })
          } else {
            this.qrReaderTitle = title
            this.qrReaderCallback = callback
            this.showQrReaderFooter = showFooter
            this.showQrReaderModal = true
          }
        })
      } else {
        if (this.$native.status) {
          this.qrReaderCallback = callback
          this.showQrReaderModal = true
          this.$native.event('readQrEvent', { title: title, showFooter: showFooter, categories: this.qrReaderFooterCategories })
        } else {
          this.qrReaderTitle = title
          this.qrReaderCallback = callback
          this.showQrReaderFooter = showFooter
          this.showQrReaderModal = true
        }
      }
    },

    showManualQrInput (title, callback) {
      this.qrInputTitle = title
      this.qrInputCallback = callback
      this.showQrInputModal = true
    },

    qrDecoded (decodedString) {
      this.qrReaderCallback(decodedString)
      this.showQrReaderModal = false
      this.qrReaderCallback = null
      this.qrReaderTitle = ''
    },

    cancelQrRead () {
      this.showQrReaderModal = false
      this.qrReaderCallback = null
      this.qrReaderTitle = ''
    },

    qrInputSubmit (decodedString) {
      this.qrInputCallback(decodedString)
      this.showQrInputModal = false
      this.qrInputCallback = null
      this.qrInputTitle = ''
    },

    cancelQrInput () {
      this.showQrInputModal = false
      this.qrInputCallback = null
      this.qrInputTitle = ''
    },

    showQuickQrDisplayModal () {
      this.showQuickQrDisplay = true
    },

    closeQrDisplay () {
      this.showQuickQrDisplay = false
    },

    qrResultChange () {
      if (this.qrReadResult.length > 0) {
        this.qrReaderCallback({ decodedString: this.qrReadResult, categoryID: this.qrCategoryResult })
        this.qrReadResult = ''
        this.qrReaderCallback = null
      }
    },

    showQuickAgreementAction (quickAgreementOrder) {
      this.quickAgreementOrder = quickAgreementOrder
      this.showQuickAgreementActionModal = true
    },

    closeQuickAgreementActionModal () {
      this.showQuickAgreementActionModal = false
      this.quickAgreementOrder = {}
    },

    // TASK methods -------------------------------------------
    checkTaskUpdated () {
      this.serverTaskShouldUpdate = true
    },

    taskUpdateChecked (updateExist) {
      // console.log('taskUpdateChecked call on SignedInView...... ' + updateExist)
      this.serverTaskShouldUpdate = false
      if (updateExist) {
        this.taskUpdatedAt = new Date()
      }
    },

    showTaskConfiguration () {
      this.taskDisplayConfModalShow = true
    },

    cancelTaskDisplayConfigUpdate () {
      this.taskDisplayConfModalShow = false
    },

    taskDisplayConfigUpdateFinished () {
      this.taskDisplayConfModalShow = false
      this.loadTaskConfiguration()
    },

    showUserTaskDetail (task) {
      // console.log('on signin view, show task detail')
      this.detailTargetUserTask = task
      this.showUserTaskDetailModal = true
    },

    closeUserTaskDetail () {
      this.showUserTaskDetailModal = false
      this.detailTargetUserTask = null
    },

    async startVideoMeeting (userTask) {
      this.closeUserTaskDetail()
      // load zoomMeeting...
      var res = await ZoomMeeting.api().fetchByTask({ taskID: userTask.id })
      // console.log(res.zoom_meeting)
      // console.log(res.zoom_meeting.start_url)
      // console.log(res.zoom_meeting.join_url)
      if (res.zoom_meeting.author_id === this.author.id) {
        // fire event for app.
        if (this.$native.status) {
          // this.$native.event('openZOOMMeeting', { url: res.zoom_meeting.start_url, user_state: 'owner' })
          this.$native.event('openZOOMMeeting', { url: res.zoom_meeting.join_url, user_state: 'owner' })
        } else {
          window.open(res.zoom_meeting.start_url)
        }
      } else {
        // fire event for app.
        if (this.$native.status) {
          this.$native.event('openZOOMMeeting', { url: res.zoom_meeting.join_url, user_state: 'attendee' })
        } else {
          window.open(res.zoom_meeting.join_url)
        }
      }
    },

    showTaskPhoto (userTask, photoIndex) {
      if (userTask.userTaskPhotos.length > 0) {
        if (!photoIndex || photoIndex > userTask.userTaskPhotos.length - 1) {
          photoIndex = 0
        }
      }
      this.photoModalPhotos = userTask.userTaskPhotos
      this.photoModalFirst = userTask.userTaskPhotos[photoIndex]
      this.photoModalShow = true
    },

    closePhotoModal () {
      this.photoModalShow = false
      this.photoModalPhotos = []
      this.photoModalFirst = null
    },

  }
}
</script>

<style lang="scss">
@import '../assets/css/mediaQuery.scss';

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #999;
}

#app {
  height: 100%;
  word-break: break-all;
  font-family: helvetica, Arial, Roboto, “Droid Sans”, “游ゴシック”, YuGothic, “ヒラギノ角ゴ ProN W3”, “Hiragino Kaku Gothic ProN”, “メイリオ”, Meiryo, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-all;
  font-family: helvetica, Arial, Roboto, “Droid Sans”, “游ゴシック”, YuGothic, “ヒラギノ角ゴ ProN W3”, “Hiragino Kaku Gothic ProN”, “メイリオ”, Meiryo, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
}

.container {
  height: 100%;
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  // position: relative;
  @include mediaQuery('tablet') {
    width: 100%;
  }
  @include mediaQuery('phone') {
    width: 100%;
  }
}

.general-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.1;
  cursor: pointer;
}
</style>
