<template>
  <div class="hours-vital-entry-column content-line">
    <div class="content-column">
      <div class="label">計測日時</div>
      <div class="content">{{ vitalEntry.measureAtStr }}</div>
    </div>
    <div class="content-column">
      <div class="label">カテゴリ</div>
      <div class="content">{{ vitalEntry.category_name }}</div>
    </div>
    <div class="content-column">
      <div class="label">データソース</div>
      <div class="content">{{ vitalEntry.source }}</div>
    </div>
    <div class="content-column">
      <div class="label">機器名</div>
      <div class="content">{{ vitalEntry.device }}</div>
    </div>
  </div><!-- content line end -->
</template>

<script>
export default {
  name: 'HorusVitalEntryColumn',

  props: {
    vitalEntry: Object
  }
}
</script>

<style lang="scss" scoped>
.content-line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  .content-column {
    display: flex;
    .label {
      margin-right: 5px;
      font-size: 0.9em;
    }
    .content {
      font-weight: bold;
      color: #666;
    }
  }
}
</style>
