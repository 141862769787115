<template>
  <div class="horus-main-menu" v-show="showMenu">
    <ul class="navbar-dropdown-target" v-if="!this.signinMember && !this.signinReporter">
      <li>
        <a @click.stop="moveToPage('/maintimeline/' + signinUser.id)">タイムライン</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/profile')">プロフィール</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/family')">家族設定</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/related_organization')">かかりつけ設定</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/private_config')">プライベート設定</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/agent_config')">設定代行</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/agent')">代理設定</a>
      </li>
      <li v-if="betaEnable">
        <a @click.stop="moveToPage('/related_reporter_organization')">報告サービス設定</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/deleted_records/' + signinUser.id)">削除済投稿の管理</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/user_task')">予定</a>
      </li>
      <li>
        <a @click.stop="jumpToHelp">ヘルプ</a>
      </li>
      <li>
        <a @click.stop="showQuickQR">かんたんQR登録</a>
      </li>
      <li class="menu-signout-user">
        <a @click.stop="moveToPage('/signout', $event)">ログアウト&nbsp;({{ signinUser.nbid }})</a>
      </li>
    </ul>
    <ul class="navbar-dropdown-target" v-if="this.signinMember">
      <li>
        <a @click.stop="moveToPage('/member_portal_config')">トップページ設定</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/member_profile_configure')">プロフィール</a>
      </li>
      <li v-if="managerSignin">
        <a @click.stop="moveToPage('/manage_organization_basic')">施設管理</a>
      </li>
      <li v-if="signinMember.agent">
        <a @click.stop="moveToPage('/organization_agent')">設定代行</a>
      </li>
      <li>
        <a @click.stop="moveToPage('/user_task')">予定</a>
      </li>
      <li v-for="suom in signinMember.switch_suoms" :key="'switchOrg' + suom.suom_id">
        <a @click.stop="switchSigninMember(suom.suom_id)">施設切替:{{ suom.organization_name }}へ</a>
      </li>
      <li>
        <a @click.stop="jumpToHelp">ヘルプ</a>
      </li>
      <li class="menu-signout-member">
        <a @click.stop="moveToPage('/signout', $event)">ログアウト&nbsp;({{ signinMember.nbid }})</a>
      </li>
    </ul>
    <ul class="navibar-dropdown-target" v-if="this.signinReporter">
      <li v-if="reporterManagerSignin">
        <a @click.stop="moveToPage('/manage_reporter_organization_basic', $event)">施設管理</a>
      </li>
      <li v-for="switchRep in signinReporter.switch_members" :key="'switchRepOrg' + switchRep.reporter_organization_member_id">
        <a @click.stop="switchSigninReporter(switchRep.reporter_organization_member_id)">施設切替:{{ switchRep.reporter_organization_name }}へ</a>
      </li>
      <li>
        <a @click.stop="jumpToHelp">ヘルプ</a>
      </li>
      <li class="menu-signout-reporter">
        <a @click.stop="moveToPage('/signout', $event)">ログアウト&nbsp;({{ signinReporter.nbid }})</a>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  ActivityLog
} from '@/models'

export default {
  name: 'HorusMainMenu',

  props: {
    showMenu: Boolean,
    signinUser: Object,
    signinMember: Object,
    signinReporter: Object
  },

  computed: {
    managerSignin () {
      if (this.signinMember) {
        if (this.signinMember.member_manager || this.signinMember.relation_manager || this.signinMember.deputize_manager) {
          return true
        }
      }
      return false
    },

    reporterManagerSignin () {
      if (this.signinReporter) {
        if (this.signinReporter.manager) {
          return true
        }
      }
      return false
    },

    betaEnable () {
      return process.env.VUE_APP_BETA_ENABLE
    }
  },

  methods: {
    moveToPage (route) {
      console.log('moveToPageEvnet call in MainMenu' + route)
      this.$emit('menuPageJumpEvent', route)
      // this.closeAllDropDown()
      // this.$router.push(route)
    },

    jumpToHelp () {
      var helpPath = '/static/pages/help/index.html'
      if (this.signinMember) {
        helpPath = '/static/pages/' + this.signinMember.help_path
      } else {
        helpPath = '/static/pages/' + this.signinUser.help_path + '/index.html'
      }
      window.open(helpPath, '_blank')
    },

    switchSigninMember (memberID) {
      this.$emit('switchLoginMemberEvent', memberID)
    },

    switchSigninReporter (memberID) {
      this.$emit('switchLoginReporterEvent', memberID)
    },

    moveToLink (activityLog) {
      this.closeAllDropDown()
      ActivityLog.api().toClicked({ targetID: activityLog.id }).then(() => {
      // this.$store.dispatch('toClickedActivityLog', { targetID: activityLog.id }).then(res => {
        this.showActivityLogDropdown = false
        activityLog.clicked = true
        if (activityLog.link_url) {
          // TODO: 旧システム上のURLを翻訳する必要がある
          if (activityLog.link_url.match(/^http.+$/)) {
            window.open(activityLog.link_url, '_blank')
          } else {
            this.$router.push(activityLog.link_url)
          }
        }
      })
    },

    showQuickQR () {
      this.$emit('showQuickQrEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/_basicInputStyle.scss';

.horus-main-menu {
  overflow: hidden;
  ul {
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 1000;
    margin: 0;
    padding: 0;
    width: 250px;
    background-color: #fff;
    border: 1px solid #aaa;
    li {
      padding: 0;
      border-top: 1px solid #aaa;
      list-style-type: none;
      color: #666;
      text-align: left;
      &.menu-signout-user a {
        color: $nb-normal-color;
        background-color: #fff9f5;
        &:hover {
          color: #000;
        }
      }
      &.menu-signout-member a {
        color: $nb-member-color;
        background-color: #f8fcf9;
        &:hover {
          color: #000;
        }
      }
      &.menu-signout-reporter a {
        color: $nb-reporter-color;
        background-color: #f7f6fb;
        &:hover {
          color: #000;
        }
      }
      a {
        display: inline-block;
        padding: 10px 20px;
        width: calc(100% - 40px);
        color: #666;
        text-decoration: none;
        &:hover {
          color: #000;
        }
      }
    }
  }
}
</style>
