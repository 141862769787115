<template>
  <div class="horus-system-data-manager">
    <h1 class="centering-title">システムデータ管理</h1>
    <div class="fix-actions">
      <div class="description">
        システム内の各オブジェクトのライフタイムログを初期化する。<br/>
        ライフタイムログ未対応バージョンで生成されたオブジェクトに、ライフタイムログを付与する。<br/>
        この操作は最初の一度のみで行えば良く、ライフタイムログ対応バージョン以降に生成されたオブジェクトは、すでにライフタイムログを持っているので操作の必要はない。<br/>
      </div>
      <button class="lifetime-log-btn" @click="initializeLifetimeLog">ライフタイムログの生成</button>
    </div>
    <div class="fix-actions">
      <div class="description">
        UserBasicとUserAddressの最新版に対するフラグを初期化する。<br/>
        この機能は、検索のために最新版のUserBasic,UserAddressの最新版がどれかを判別するためのフラグを追加したため、<br/>
        それを初期化する必要が出たために存在する。<br/>
        対応バージョン以降は自動的に最新版の識別フラグを付与するため、一度初期化すれば操作の必要はない。<br/>
      </div>
      <button class="fix-ub-ua-btn" @click="resetCurrentForBasicAndAddress">UserBasicとUserActionの最適化</button>
    </div>
    <div class="fix-actions">
      <div class="description">
        かかりつけ職員の可視性未対応バージョンで作成されたかかりつけ関係での可視性を初期化する。<br/>
        可視性が初期化されていない場合は、かかりつけ担当者としての職員が存在しない状態となるため、この操作を行って、全職員が担当者の状態にする必要がある。<br/>
        対応バージョン以降では、かかりつけ職員の可視性は自動的に初期化されるため、一度初期化すれば以降操作の必要はない。<br/>
      </div>
      <button class="init-staff-visible-btn" @click="initializeStaffVisibility">かかりつけ職員の可視性を初期化</button>
    </div>
    <div class="fix-actions">
      <div class="description">
        多量の職員及びかかりつけ利用者を持つ施設をテスト用に生成する。<br/>
        施設の情報及び施設管理者の情報は直接フォームに入力する。この際のメール認証は省略する。<br/>
        生成されるダミー職員は以下のルールで生成される。<br/>
        職員数は入力された人数。<br/>
        NBIDは入力されたBASE_NAMEに連番を追加したもの。(0始まり)連番で追加された名前で通常のNBIDフォーマットでチェックする。<br/>
        パスワードは固定で'password'<br/>
        メールアドレスは指定した施設管理者と同一のものが設定されるため、一斉同報投稿の際には特に注意すること。<br/>
        氏名は「狩野+連番」「職員+連番」で自動生成された名前。<br/>
        氏名カナは固定、「カリノ」「ショクイン」<br/>
        性別、血液型はランダム。<br/>
        住所はクロスケアフィールドの住所を固定で設定する。<br/>
        生成されるダミーユーザーは以下のルールで生成される。<br/>
        ユーザー数は入力された人数。<br/>
        NBIDは入力されたBASE_NAMEに連番を追加したもの。(0始まり)連番で追加された名前で通常のNBIDフォーマットでチェックする。<br/>
        パスワードは固定で'password'<br/>
        メールアドレスは指定した施設管理者と同一のものが設定されるため、一斉同報投稿の際には特に注意すること。<br/>
        氏名は「狩野+連番」「生絵+連番」で自動生成された名前。<br/>
        氏名カナは固定、「カリノ」「ナマエ」<br/>
        性別、血液型はランダム。<br/>
        住所はクロスケアフィールドの住所を固定で設定する。<br/>
        大量のダミーデータを生成するため、プロダクション環境での利用は推奨しない。<br/>
      </div>
      <button class="create-dummy-data-btn" @click="createDummyData">テスト用のダミーデータ生成</button>
    </div>
    <horus-create-debug-org-modal
      :dummyDataModalDisplay="dummyDataModalDisplay"
      v-on:cancelEvent="createDummyCancel"
      v-on:createDummyFinishEvent="createDummyFinished"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { StaffVisibility } from '@/models'
import {
  SystemTotalization,
  User,
} from '@/api'

import HorusCreateDebugOrgModal from '@/components/systemManageUtils/HorusCreateDebugOrgModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemDataManagerView',

  data () {
    return {
      dummyDataModalDisplay: false
    }
  },

  components: {
    HorusConfirmDialog,
    HorusCreateDebugOrgModal
  },

  methods: {
    initializeLifetimeLog () {
      SystemTotalization.initializeLifetimeLogs().then(() => {
        this.showNotifyDialog('ライフタイムログ生成', 'ライフタイムログの生成が完了しました')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('ライフタイムログ生成エラー', error.response)
      })
    },

    resetCurrentForBasicAndAddress () {
      User.updateCurrentForUserSummary().then(() => {
        this.showConfirmDialog(
          'UserBasicとUserAddressの最適化を完了しました。',
          'UserBasicとUserAddressにcurrentを設定し、内容の更新を行いました。',
          DIALOG_TYPE.NOTIFY
        )
      }).catch(error => {
        console.error(error.response)
      })
    },

    initializeStaffVisibility () {
      StaffVisibility.api().init().then(() => {
        this.showConfirmDialog(
          'かかりつけ職員の可視性を初期化しました。',
          'かかりつけ職員の可視性を初期化しました。内容の更新を行いました。',
          DIALOG_TYPE.NOTIFY
        )
      }).catch(error => {
        console.error(error.response)
      })
    },

    createDummyData () {
      this.dummyDataModalDisplay = true
    },

    createDummyCancel () {
      this.dummyDataModalDisplay = false
    },

    createDummyFinished (result) {
      console.log(result)
      this.dummyDataModalDisplay = false
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-data-manager {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .fix-actions {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #aaa;
    .description {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #b94047;
    }
    button {
      padding: 3px;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
