<template>
  <div class="horus-user-basic-form">
    <div class="form-list-line" v-if="userBasic">
      <horus-attribute-in-form
        :labelText="'姓'"
        :necessary="true"
        :validationState="userBasic.validateFamilyName().state"
        :validationMessage="userBasic.validateFamilyName().message"
        :attributeNotify="'20文字以内'">
        <input type="text" v-model="userBasic.familyName" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'名'"
        :necessary="true"
        :validationState="userBasic.validateGivenName().state"
        :validationMessage="userBasic.validateGivenName().message"
        :attributeNotify="'20文字以内'">
        <input type="text" v-model="userBasic.givenName" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'姓カナ'"
        :necessary="true"
        :validationState="userBasic.validateFamilyNameKana().state"
        :validationMessage="userBasic.validateFamilyNameKana().message"
        :attributeNotify="'20文字以内'">
        <input type="text" v-model="userBasic.familyNameKana" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'名カナ'"
        :necessary="true"
        :validationState="userBasic.validateGivenNameKana().state"
        :validationMessage="userBasic.validateGivenNameKana().message"
        :attributeNotify="'20文字以内'">
        <input type="text" v-model="userBasic.givenNameKana" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'生年月日'"
        :necessary="true"
        :validationState="userBasic.validateBirthDate().state"
        :validationMessage="userBasic.validateBirthDate().message"
        :attributeNotify="''">
        <span class="birth-date-display">{{ currentBirthDateJP }}</span>
        <button class="select-birth-date-button" @click="selectBirthDate">日付選択</button>
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'性別'"
        :necessary="false"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="''">
        <select class="sex-type-select" v-model="userBasic.sexType">
          <option v-for="(sexType, index) in userBasicData.SEX_TYPES" :key="'sexTypeSelect' + index" :value="index">{{ sexType }}</option>
        </select>
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'血液型ABO'"
        :necessary="false"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="''">
        <select class="sex-type-select" v-model="userBasic.bloodTypeABO">
          <option v-for="(abo, index) in userBasicData.BLOOD_TYPE_ABO" :key="'sexTypeSelect' + index" :value="index">{{ abo }}</option>
        </select>
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'血液型RH'"
        :necessary="false"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="''">
        <select class="sex-type-select" v-model="userBasic.bloodTypeRH">
          <option v-for="(rh, index) in userBasicData.BLOOD_TYPE_RH" :key="'sexTypeSelect' + index" :value="index">{{ rh }}</option>
        </select>
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'ポートレート'"
        :necessary="false"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="''">
        <div class="portrate-setting-column">
          <div class="portrate-thumbnail-column">
            <span>設定済の写真</span><br/>
            <img class="current-portrate" :src="timelineOwner.author.portrateSrc" />
          </div>
          <horus-mono-photo-selector
            :photoObj="portrateObj"
            v-on:photoSelectedEvent="photoSelected"
            v-on:cancelSelectPhotoEvent="selectedPhotoCancel"
            />
        </div>
      </horus-attribute-in-form>
    </div>
    <div class="submit-controlls">
      <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
      <button cype="button" class="submit-btn" @click="executeSubmit" :class="{ enable: (userBasic && userBasic.isValid()) }">{{ submitTitle }}</button>
    </div>
    <horus-date-picker
      :showDatePicker="showDatePicker"
      :defaultDate="selectedDate"
      v-on:datePickupEvent="setBirthDate"
      v-on:closeEvent="closeDatePicker"
      />
  </div>
</template>

<script>
import UserBasic from '@/validateModel/UserBasic.js'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusMonoPhotoSelector from '@/components/common/HorusMonoPhotoSelector.vue'
import HorusDatePicker from '@/components/common/HorusDatePicker.vue'

import * as USER_BASIC from '@/assets/constants/userBasicData.js'

export default {
  name: 'HorusUserBasicForm',

  components: {
    HorusAttributeInForm,
    HorusMonoPhotoSelector,
    HorusDatePicker
  },

  props: {
    timelineOwner: Object,
    userBasicSource: Object,
    submitTitle: String
  },

  data () {
    return {
      userBasic: null,
      selectedDate: null,
      portrateObj: null,
      showDatePicker: false,
      userBasicData: USER_BASIC
    }
  },

  watch: {
    userBasicSource: { handler: 'initObject', immediate: true },
    selectedDate: { handler: 'setBirthDate', immediate: true }
  },

  computed: {
    currentBirthDateJP () {
      if (this.userBasic && this.userBasic.birthDate) {
        return this.userBasic.birthDateJp()
      } else {
        return '未設定'
      }
    }
  },

  methods: {
    async initObject () {
      // console.log('userbasicform initObject call,')
      if (this.userBasicSource) {
        this.userBasic = new UserBasic(this.userBasicSource)
        this.selectedDate = new Date(this.userBasic.birthDate)
        this.portrateObj = null
      } else {
        this.userBasic = new UserBasic()
      }
      if (!this.selectedDate || this.selectedDate.toSting === 'Invalid Date') {
        this.selectedDate = new Date(1960, 0, 1)
        this.portrateObj = null
      }
    },

    selectBirthDate () {
      if (this.userBasic.birthDate) {
        this.selectedDate = this.userBasic.birthDate
      }
      this.showDatePicker = true
    },

    setBirthDate (date) {
      if (this.selectedDate) {
        this.userBasic.birthDate = date
      }
      this.showDatePicker = false
    },

    closeDatePicker () {
      this.showDatePicker = false
    },

    photoSelected (photoObj) {
      // console.log('userbasicform photoSelected call,')
      this.portrateObj = photoObj
    },

    selectedPhotoCancel () {
      // console.log('userbasicform selectedPhotoCancel call,')
      this.portrateObj = null
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    executeSubmit () {
      if (this.userBasic.isValid) {
        this.$emit('executeSubmitEvent', this.userBasic.toFormData(this.timelineOwner, this.portrateObj))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-user-basic-form {
  margin: 10px;
  input[type="text"] {
    font-size: 16px;
  }
  .birth-date-display {
    font-size: 16px;
  }
  .select-birth-date-button {
    cursor: pointer;
    margin-left: 10px;
    border-radius: 3px;
    background-color: #999;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  select {
    padding: 0;
    height: 28px;
    font-size: 16px;
    background-color: #999;
    color: #fff;
  }
  .portrate-setting-column {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .portrate-thumbnail-column {
      margin-right: 10px;
    }
  }
  .submit-controlls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      &.cancel-btn {
        background-color: #fff;
        color: #666;
      }
      &.submit-btn {
        background-color: #666;
        color: #fff;
      }
    }
  }
}
</style>
