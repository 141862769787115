<template>
  <div class="horus-ciritical-notification-fire-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>重要警告詳細</h1>
      </div>
      <div class="modal-body" v-if="notification">
        <div class="message-column" v-html="messageHTML"></div>
        <div class="target-user-count">警告対象ユーザー数:{{ targetUserCountEstimation }}</div>
        <div class="target-user-controll" v-if="targetable">
          <h3>対象を指定</h3>
          <label class="search-input">NBID</label><input type="text" class="add-target-nbid" v-model="addTargetNBID" /><button class="search-target-btn" @click="searchTargetUser">ユーザーを検索</button>
          <div class="search-result-message" v-show="searchResultMessage.length > 0">{{ searchResultMessage }}</div>
          <div class="search-result-column" v-if="searchResultUser">
            <div class="unit-block"><span class="title">NBID</span><span class="value">{{ searchResultUser.nbid }}</span></div>
            <div class="unit-block"><span class="title">氏名(カナ)</span><span class="value">{{ searchResultUser.familyName + ' ' + searchResultUser.givenName }}({{ searchResultUser.familyNameKana + ' ' + searchResultUser.givenNameKana }})</span></div>
            <div class="unit-block"><span class="title">生年月日</span><span class="value">{{ searchResultUser.birth_date }}</span></div>
            <div class="unit-block"><span class="title">性別</span><span class="value">{{ searchResultSexTypeStr }}</span></div>
            <div class="unit-block"><span class="title">メールアドレス</span><span class="value">{{ searchResultUser.mail }}</span></div>
            <div class="contorll"><button class="add-target-btn" @click="addTargetUser">対象に追加</button></div>
          </div>
        </div>
        <div class="target-users-column" v-if="targetUsers.length > 0">
          <h3>指定された警告対象</h3>
          <div class="target-user" v-for="(user, index) in targetUsers" :key="index +'targetUser' + user.id">
            <div class="unit-block"><span class="title">NBID</span><span class="value">{{ user.nbid }}</span></div>
            <div class="unit-block"><span class="title">氏名(カナ)</span><span class="value">{{ user.familyName + ' ' + user.givenName }}({{ user.familyNameKana + ' ' + user.givenNameKana }})</span></div>
            <div class="unit-block"><button class="remove-btn" @click="removeTargetUser(index)">対象から外す</button></div>
          </div>
        </div>
        <div class="fire-controll">
          <button class="cancel-btn" @click="cancelFire">キャンセル</button>
          <button class="fire-btn" @click="executeFire">重要警告発火</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  User
} from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

export default {
  name: 'HorusCriticalNotificationFireModal',

  components: {
    HorusModalFrame,
  },

  props: {
    showModal: Boolean,
    notification: Object,
    targetOrganizations: Array
  },

  data () {
    return {
      targetUsers: [],
      addTargetNBID: '',
      searchResultMessage: '',
      searchResultUser: null
    }
  },

  watch: {
    notification: { handler: 'initData', immediate: true }
  },

  computed: {
    messageHTML () {
      var message = ''
      if (this.notification.areaBlock) {
        message = this.notification.areaBlock.name + 'に所属する全施設の、'
        if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MANAGER) {
          message += '全施設管理者に対して'
        } else if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MEMBER) {
          message += '全施設職員に対して'
        }
      } else if (this.targetOrganizations.length > 0) {
        message = '指定された施設、[' + this.targetOrganizations.map(o => o.short_name).join(', ') + ']の、'
        if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MANAGER) {
          message += '全施設管理者に対して'
        } else if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MEMBER) {
          message += '全施設職員に対して'
        }
      } else if (this.targetUsers.length > 0) {
        message = '指定されたユーザー、[' + this.targetUsers.map(u => u.nbid).join(', ') + ']に対して、'
      } else {
        message = 'ナラティブブックを利用している全ユーザーに対して、'
      }
      if (this.notification.block_signin) {
        message += '警告内容を解決するまでログインを停止させる'
      } else {
        if (this.notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET) {
          message += '警告内容を解決するまでログイン後に常に警告を表示させる'
        } else if (this.notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE) {
          message += '警告モーダルで承認ボタンを押下するまで警告を表示させる'
        } else if (this.notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_SHOW_ONECE) {
          message += 'ログイン後に一度だけ警告を表示させる'
        } else {
          message += 'ログイン後に警告を表示させる'
        }
      }
      message += '重要警告です。'
      return message
    },

    targetable () {
      if (!this.notification.areaBlock && this.targetOrganizations.length <= 0) {
        return true
      }
      return false
    },

    targetUserCountEstimation () {
      if (this.targetUsers.length > 0) {
        return this.targetUsers.length
      } else {
        return this.notification.estimated_target_count
      }
    },

    notificationMessageHTML () {
      if (this.notification) {
        return this.notification.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    searchResultSexTypeStr () {
      if (this.searchResultUser) {
        if (this.searchResultUser.sexType === 1) {
          return '男性'
        } else if (this.searchResultUser.sexType === 2) {
          return '女性'
        } else {
          return '----'
        }
      }
      return ''
    },

    groupTypeName () {
      if (this.notification && this.notification.group_target_type) {
        if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MANAGER) {
          return '施設管理者'
        } else if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MEMBER) {
          return '施設職員'
        }
      }
      return '-----'
    }
  },

  methods: {
    initData () {
    },

    searchTargetUser () {
      User.search({ searchConditions: { nbid: this.addTargetNBID } }).then(res => {
        console.log(res)
        if (res.users && res.users.length > 0) {
          console.log(res.users)
          console.log(res.users[0])
          if (this.targetUsers.some(u => u.id === res.users[0].id)) {
            this.searchResultMessage = '対象は追加済みです'
          } else {
            this.searchResultUser = res.users[0]
            this.searchResultMessage = ''
            this.addTargetNBID = ''
          }
        } else {
          this.searchResultMessage = '対象ユーザーが存在しません。'
        }
      }).catch(error => {
        console.error(error.response)
        this.searchResultMessage = 'ユーザー検索でエラーが発生しました'
      })
    },

    addTargetUser () {
      if (this.searchResultUser) {
        if (!this.targetUsers.some(u => u.id === this.searchResultUser.id)) {
          this.targetUsers.push(this.searchResultUser)
          this.searchResultUser = null
        }
      }
    },

    removeTargetUser (targetIndex) {
      this.targetUsers.splice(targetIndex, 1)
    },

    cancelFire () {
      this.$emit('cancelFireEvent')
    },

    executeFire () {
      this.$emit('fireEvent', this.targetUsers)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-ciritical-notification-fire-modal {
  .modal-body {
    padding: 10px;
    .message-column {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #c53d43;
    }
    .target-user-count {
      text-align: center;
      font-size: 1.1em;
      font-weight: bold;
    }
    .target-user-controll {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
      h3 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 1em;
        font-weight: bold;
        color: #666;
      }
      .add-target-nbid {
        margin: 0 10px;
      }
      .search-target-btn {
        padding: 3px 5px;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
      .search-result-column {
        margin: 5px;
        padding: 5px;
        border: 1px solid #ddd;
        .unit-block {
          margin-bottom: 5px;
          .title {
            margin-right: 10px;
            font-weight: bold;
          }
        }
        .add-target-btn {
          padding: 3px 5px;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-weight: bold;
        }
      }
    }
    .target-users-column {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
      h3 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 1em;
        font-weight: bold;
        color: #666;
      }
      .target-user {
        display: flex;
        .unit-block {
          margin-right: 10px;
          .title {
            margin-right: 5px;
            font-weight: bold;
          }
          .remove-btn {
            padding: 3px 5px;
            border-radius: 3px;
            border: 1px solid #666;
            background-color: #fff;
            color: #666;
            font-weight: bold;
          }
        }
      }
    }
    .fire-controll {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
      button {
        margin: 0 10px;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: bold;
        &.cancel-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
        &.fire-btn {
          border: 1px solid #c53d43;
          background-color: #c53d43;
          color: #fff;
        }
      }
    }
  }
}
</style>
