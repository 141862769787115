import axios from './AxiosInstance'

export default {
  fetch: ({targetUserID, limit, offset}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'deputize_activity_log/list',
        withCredentials: true,
        params: { target_user_id: targetUserID, limit: limit, offset: offset }
      }).then(res => {
        resolve({ offset: res.data.offset, count: res.data.count, deputize_activity_logs: res.data.deputize_activity_logs })
      }).catch(error => { reject(error) })
    })
  }
}
