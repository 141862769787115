<template>
  <div class="horus-multi-photo-selector">
    <div class="upload-photo-thumbnails" v-if="photos && photos.length > 0">
      <div class="selected-photo-thumbnail-block" v-for="(selectedPhoto, index) in photos" :key="'selectdphoto' + index">
        <img class="selected-photo-remove-icon" src="/static/images/red_batsu_icon.png" @click="removePhoto(index)" />
        <img class="selected-photo-thumbnail" :src="selectedPhoto.thumbnail" />
      </div>
    </div>
    <input type="file" multiple="multiple" accept="image/*" :id="photoInputID" name="multi_photo_input" style="display: none;cursor: pointer;" :capture="inApp" ref="photoInput" @change="postPhotoChange" />
  </div>
</template>

<script>
import HorusUploadPhotoFixer from '@/mixin/HorusUploadPhotoFixer.js'

export default {
  mixins: [HorusUploadPhotoFixer],

  name: 'HorusMultiPhotoSelector',

  props: {
    photoInputID: String,
    photos: Array
  },

  computed: {
    inApp () {
      return null
      // if (this.$native.status) {
      //   return 'environment'
      // } else {
      //   return null
      // }
    }
  },

  methods: {
    postPhotoChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        for (var i = 0; i < selectedFiles.length; i++) {
          this.addSelectedPhoto(selectedFiles[i])
        }
      }
    },

    async addSelectedPhoto (file) {
      this.fixUploadPhoto(file, 2048, (obj) => {
        this.$emit('newPhotoPreparedEvnt', obj)
      })
    },

    removePhoto (index) {
      this.$emit('photoRemoveEvent', index)
    }

  }
}
</script>

<style lang="scss" scoped>
.upload-photo-thumbnails {
  display: flex;
  flex-wrap: wrap;
  .selected-photo-thumbnail-block {
    position: relative;
    margin: 5px;
    padding: 15px 15px 10px 10px;
    border: 1px solid #aaa;
    .selected-photo-remove-icon {
      position: absolute;
      cursor: pointer;
      top: 2px;
      right: 2px;
      width: 20px;
      height: 20px;
    }
    .selected-photo-thumbnail {
      max-width: 100px;
      max-height: 100px;
      image-orientation: none;
    }
  }
}

</style>
