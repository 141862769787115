import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import moment from 'moment'
import Author from './Author'
import HashTag from './HashTag'
import BoadCommentFile from './BoadCommentFile'
import BoadCommentPhoto from './BoadCommentPhoto'

export default class BoadComment extends Model {
  static entity = 'boadComments'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      author_id: this.attr(null),
      editor_id: this.attr(null),
      title: this.string(''),
      content: this.string(''),
      position: this.number(-1),
      deleted: this.boolean(false),
      created_at: this.string(''),
      updated_at: this.string(''),
      tags: this.attr(null),
      notify_log: this.attr(null)
    }
  }

  get createdAt () {
    return new Date(this.created_at)
  }

  get createdAtJp () {
    return moment(this.createdAt).format('YYYY年MM月DD日 HH:mm:')
  }

  get updatedAt () {
    return new Date(this.updated_at)
  }

  get updatedAtJp () {
    return moment(this.updatedAt).format('YYYY年MM月DD日 HH:mm:')
  }

  get author () {
    return Author.find(this.author_id)
  }

  get editor () {
    return Author.find(this.editor_id)
  }

  get editorName () {
    if (this.editor) {
      return this.editor.fullName
    }
    return ''
  }

  get photos () {
    return BoadCommentPhoto.query().where('boad_comment_id', this.id).get()
  }

  get files () {
    return BoadCommentFile.query().where('boad_comment_id', this.id).get()
  }

  get hashTags () {
    return HashTag.findIn(this.tags).filter(tag => !tag.deleted)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(timelineOwnerID, data) {
    var bComments = await BoadComment.query().where('user_id', timelineOwnerID).get()
    for(var bc of bComments) {
      for(var bcp of bc.photos) {
        await bcp.$delete()
      }
      for(var bcf of bc.files) {
        await bcf.$delete()
      }
      await bc.$delete()
    }
    return Promise.all([
      await BoadComment.insertOrUpdate({ data: data.boad_comments }),
      await BoadCommentPhoto.insertOrUpdate({ data: data.boad_comment_photos }),
      await BoadCommentFile.insertOrUpdate({ data: data.boad_comment_files })
    ])
    .then(async ([boadCommentsEntities]) => {
      if (boadCommentsEntities && boadCommentsEntities.boadComments) {
        // load author and editor
        let loadAuthorID = []
        for(var boadComment of boadCommentsEntities.boadComments) {
          if (!boadComment.author) {
            if (loadAuthorID.indexOf(boadComment.author_id) < 0) loadAuthorID.push(boadComment.author_id)
          }
          if (!boadComment.editor) {
            if (loadAuthorID.indexOf(boadComment.editor_id) < 0) loadAuthorID.push(boadComment.editor_id)
          }
        }
        if (loadAuthorID.length > 0) {
          await Author.api().fetchByID({ idList: loadAuthorID })
        }
        BoadComment.commit((state) => {
          state.fetchedAt = new Date()
        })
        return boadCommentsEntities.boadComments
      } else {
        return []
      }
    })
  }

  static apiConfig = {
    actions: {
      fetch ({timelineOwnerID, targetDate}) {
        return this.request({
          method: 'get',
          url: 'boad_comment/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, target_date: targetDate },
          save: false
        })
        .then(res => {
          return BoadComment.updateData(timelineOwnerID, res.response.data)
        })
        .catch(error => { throw error })
      },
    
      fetchUpdatad ({timelineOwnerID, targetDate}) {
        return this.request({
          method: 'get',
          url: 'boad_comment/updated_list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, target_date: targetDate },
          save: false
        })
        .then(res => {
          return BoadComment.updateData(timelineOwnerID, res.response.data)
        })
        .catch(error => { throw error })
      },
    
      async updatePosition ({timelineOwnerID, targetBoadCommentID, newPosition}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('boad_comment_id', targetBoadCommentID)
        formData.append('position', newPosition)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'boad_comment/move_to',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async toInvisible ({timelineOwnerID, targetBoadCommentID}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('boad_comment_id', targetBoadCommentID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'boad_comment/to_invisible',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async toVisible ({timelineOwnerID, targetBoadCommentID}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('boad_comment_id', targetBoadCommentID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'boad_comment/to_visible',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async create ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'boad_comment',
            data: formData,
            save: false
          }
        })
      },
    
      async update ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'boad_comment',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy ({timelineOwnerID, targetID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'boad_comment',
            params: { target_user_id: timelineOwnerID, boad_comment_id: targetID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }
    
    }
  }
}
