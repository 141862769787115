<template>
  <div class="horus-create-reporter-request-for-organization-modal">
    <horus-modal-frame
      :showModalFlag="showCreateRequestModal">
      <div class="modal-header">
        <h1><img src="/static/images/org_member.png" class="title-icon" />依頼状の作成</h1>
      </div>
      <div class="modal-body">
        <horus-search-reporter-organization-column
          :searchResultOrganization="searchResultOrganization"
          :existOrganizations="relatedOrganizations"
          :sentOrganizations="sentOrganizations"
          :receiveOrganizations="receiveOrganizations"
          :searchType="'relation'"
          v-on:searchFinishedEvent="searchFinished"
          v-on:searchFailedEvent="searchFailed"
          />
        <div class="form-datas" v-if="readyToCreate">
          <horus-attribute-in-form
            :labelText="'担当者へのメッセージ'"
            :necessary="false"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <textarea class="message-content" v-model="messageContent"></textarea>
          </horus-attribute-in-form>
          <div class="notify_for_admit">
            <h1>サービス利用登録における確認事項</h1>
            <ul>
              <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
              <li>依頼先施設との円滑なコミュニケーションのために、かかりつけ施設になることを依頼先施設から同意されていること</li>
              <li>サービス利用施設に登録すると利用サービスとなった施設の職員は、あなたのタイムラインへの書き込みができるようになります（詳しくは利用規約の第２条及び第８条をご確認ください）</li>
              <li>サービス利用施設の職員があなたのタイムラインに書き込む情報の内容について、説明を受け同意した上でサービス利用設定を行ってください</li>
            </ul>
          </div>
          <div class="admit-agreement" @click="toggleAdmit">
            <horus-custom-check-box
              :active="admitAgreement"
              :enabled="true"
              :type="'color'"
              />
            上記確認事項を確認しました
          </div>
        </div><!-- org search result end -->
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendRequest">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !requestReady }" @click="submitSendRequest">依頼状の送信</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusSearchReporterOrganizationColumn from '@/components/common/HorusSearchReporterOrganizationColumn.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusCreateReporterRequestForOrganizationModal',

  components: {
    HorusModalFrame,
    HorusSearchReporterOrganizationColumn,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    timelineOwner: Object,
    showCreateRequestModal: Boolean,
    relatedOrganizations: Array,
    sentRequests: Array,
    receiveRequests: Array
  },

  data () {
    return {
      searchResultOrganization: null,
      readyToCreate: false,
      messageContent: '',
      admitAgreement: false
    }
  },

  computed: {
    requestReady () {
      if (this.searchResultOrganization && this.admitAgreement) {
        return true
      } else {
        return false
      }
    },

    sentOrganizations () {
      var sentOrg = []
      this.sentRequests.forEach(req => {
        sentOrg.push(req.reporter_organization)
      })
      return sentOrg
    },

    receiveOrganizations () {
      var recvOrgs = []
      this.receiveRequests.forEach(req => {
        recvOrgs.push(req.reporter_organization)
      })
      return recvOrgs
    }
  },

  methods: {
    searchFinished (reporterOrganization, avaliable) {
      this.searchResultOrganization = reporterOrganization
      if (avaliable) {
        this.readyToCreate = true
      } else {
        this.readyToCreate = false
      }
    },

    searchFailed () {
      this.readyToCreate = false
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    cancelSendRequest () {
      this.resetParam()
      this.$emit('cancelEvent')
    },

    submitSendRequest () {
      if (this.requestReady) {
        this.$emit('submitSendRequestEvent', this.searchResultOrganization, this.messageContent, () => { this.resetParam() })
      }
    },

    resetParam () {
      this.orgNumberFirst = ''
      this.orgNumberSecond = ''
      this.orgNumberThird = ''
      this.orgNumberFourth = ''
      this.searchResultOrganization = null
      this.messageContent = ''
      this.admitAgreement = false
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-reporter-request-for-organization-modal {
  .modal-body {
    padding: 10px;
    height: calc(100% - 52px);
    overflow: auto;
    .request-message {
      margin-bottom: 25px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
    }
    .notify_for_admit {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #666;
      }
    }
    input[type='text'] {
      margin: 0 5px;
      font-size: 16px;
    }
    .search-org-btn {
      margin: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #999;
      color: #fff;
      font-weight: bold;
      font-size: 13px;
    }
    textarea {
      width: calc(100% - 40px);
      border: 1px solid #aaa;
      font-size: 16px;
    }
    .admit-agreement {
      cursor: pointer;
      margin: 10px;
      font-weight: bold;
    }
    .submit-controll {
      margin: 10px;
      text-align: center;
      button {
        cursor: pointer;
        padding: 2px 20px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        &.disable {
          cursor: normal;
          background-color: #aaa;
        }
        &.cancel-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>
