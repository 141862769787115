<template>
  <div class="horus-manage-organization-member" v-if="organization">
    <horus-manage-organization-frame
      :currentDisplay="'member'"
      :signinMember="signinMember"
      :organization="organization"
      >
      <h1 class="centering-title">施設設定</h1>
      <div class="member-block red_titled_column">
        <div class="revoke-notify" v-if="organization.applyingForRevoke">
          本施設は、現在退会申請中です。<br/>
          退会申請中は施設管理者の権限変更、新規職員の追加、案内の再送、申込みの承諾は行なえません。
        </div>
        <h4>施設に登録している職員</h4>
        <div class="member-list-column" v-if="!memberNowLoading">
          <div class="block-title" @click="memberListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: memberListOpen}" />
            職員リスト<span class="caption">職員:{{ members.length }}名</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="member-list" v-show="memberListOpen" v-if="members.length > 0">
              <div class="notify-comment">
                以下の権限を付与する方には<img src="/static/images/check_on_icon_s.png" />をつけてください
                <ul>
                  <li>施設管理：職員の登録や権限の付与など施設の管理をする方</li>
                  <li>かかりつけ管理：利用者からのかかりつけ依頼を承認するなどかかりつけに関する管理をする方</li>
                  <li>設定代行者管理：利用者からの設定代行の依頼を承認するなど設定代行に関する管理をする方</li>
                  <li>設定代行者：利用者の設定代行を行う方</li>
                </ul>
              </div>
              <horus-organization-member-in-manage-list
                v-for="member in members"
                :key="'orgMemberInList' + member.id"
                :member="member"
                :organization="organization"
                :signinMember="signinMember"
                v-on:toggleMemberManagerEvent="toggleMemberManager"
                v-on:toggleRelationManagerEvent="toggleRelationManager"
                v-on:toggleDeputizeManagerEvent="toggleDeputizeManager"
                v-on:toggleAgentEvent="toggleAgent"
                v-on:editMemberSuomEvent="editMemberSuom"
                v-on:removeMemberEvent="removeMember"
                />
            </div>
          </transition>
        </div><!-- member list column end -->
        <div class="now-loading" v-if="memberNowLoading">読み込み中...</div>
      </div><!-- member block end -->
      <div class="sent-invitation-block red_titled_column">
        <h4>案内状の送信</h4>
        <div class="subtitle" v-if="!organization.applyingForRevoke">職員として登録するための案内を送る</div>
        <div class="block-title" v-if="!organization.applyingForRevoke">
          <button type="button" class="create-invitation-btn" @click="createNewInvitation">案内状の作成</button>
        </div>
        <div class="sent-invitation-list-column" v-if="!sentListNowLoading">
          <div class="block-title" @click="sentInvitationListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
            案内状の送信リスト<span class="caption">案内状の数:{{ sentInvitations.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-invitation-list" v-show="sentListOpen" v-if="sentInvitations.length > 0">
              <horus-sent-member-invitation
                v-for="sentInvitation in sentInvitations"
                :key="'sentRelationReq' + sentInvitation.id"
                :sentInvitation="sentInvitation"
                :organization="organization"
                v-on:sendMailEvent="sendMail"
                v-on:showPrintPageEvent="showPrintPage"
                v-on:removeInvitationEvent="removeInvitation"
                />
            </div><!-- sent-invitation-list end -->
          </transition>
          <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
        </div><!-- sent-invitation-list-column end -->
      </div><!-- sent-invitation-block -->
    </horus-manage-organization-frame>
    <!-- invitation create modal -->
    <horus-create-member-invitation-modal
      :organization="organization"
      :showCreateMemberInvitationModal="showCreateMemberInvitationModal"
      v-on:cancelSentInvitationEvent="cencelCreateInvitation"
      v-on:submitSentInvitationEvent="submitCreateInvitation"
      />
    <horus-member-profile-edit-modal
      :suom="targetSuom"
      :showMemberProfileEditModal="showMemberProfileEditModal"
      v-on:cancelSubmitEvent="cancelMemberProfileSubmit"
      v-on:executeSubmitEvent="executeMemberProfileSubmit"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author
} from '@/models'
import {
  OrganizationAgent,
  OrganizationManager,
  OrganizationMember,
  OrganizationMemberInvitation,
  ServiceUsingOrganizationMember
} from '@/api'

import HorusManageOrganizationFrame from '@/components/manageOrganization/HorusManageOrganizationFrame'
import HorusOrganizationMemberInManageList from '@/components/manageOrganization/member/HorusOrganizationMemberInManageList.vue'
import HorusSentMemberInvitation from '@/components/manageOrganization/member/HorusSentMemberInvitation.vue'
import HorusCreateMemberInvitationModal from '@/components/manageOrganization/member/HorusCreateMemberInvitationModal.vue'
import HorusMemberProfileEditModal from '@/components/memberProfile/HorusMemberProfileEditModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition],

  name: 'HorusManageOrganizationMemberView',

  components: {
    HorusManageOrganizationFrame,
    HorusOrganizationMemberInManageList,
    HorusSentMemberInvitation,
    HorusCreateMemberInvitationModal,
    HorusMemberProfileEditModal,
    HorusConfirmDialog
  },

  props: {
    signinMember: Object,
    organization: Object
  },

  data () {
    return {
      memberNowLoading: true,
      memberListOpen: false,
      members: [],
      sentListNowLoading: true,
      sentListOpen: false,
      sentInvitations: [],
      showCreateMemberInvitationModal: false,
      showMemberProfileEditModal: false,
      targetSuom: {
        id: null,
        name: '',
        mail: '',
        tel: '',
        additional_comment: '',
        organization_id: null,
        author_id: null,
        member_manager: false,
        relation_manager: false,
        deputize_manager: false,
        default_signin: false,
        organization: null,
        author: null
      }
    }
  },

  watch: {
    organization: { handler: 'initObjects', immediate: true }
  },

  methods: {
    initObjects () {
      this.loadMembers()
      this.loadSentInvitations()
    },

    async loadMembers () {
      if (this.organization) {
        this.memberNowLoading = true
        let res = await OrganizationMember.fetch({ organizationID: this.organization.id })
        this.members = res.organization_members
        var unloadAuthors = []
        for(var mem of this.members) {
          if (!Author.find(mem.author_id)) {
            if (unloadAuthors.indexOf(mem.author_id)) unloadAuthors.push(mem.author_id)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ timelineOwnerID: this.signinMember.user_id, idList: unloadAuthors })
        }
        for(var member of this.members) {
          member.author = await Author.find(member.author_id)
        }
        this.memberNowLoading = false
      }
    },

    async loadSentInvitations () {
      if (this.organization) {
        this.sentListNowLoading = true
        OrganizationMemberInvitation.fetch({ organizationID: this.organization.id }).then(res => {
          this.sentInvitations = res.organization_member_invitation_tokens
          this.sentListNowLoading = false
        })
      }
    },

    memberListToggle () {
      this.memberListOpen = !this.memberListOpen
    },

    sentInvitationListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    toggleMemberManager (member) {
      if (member.author_id === this.signinMember.author_id) {
        this.showNotifyDialog('職員管理設定の失敗', '自分自身の設定を変更できません。')
      } else {
        if (member.member_manager) {
          OrganizationManager.revokeMemberManager({ organizationID: this.organization.id, userID: member.user_id })
          .catch(error => {
            console.error(error)
            this.showNotifyDialog('職員管理設定の失敗', '職員管理者の設定変更に失敗しました。')
          }).finally(() => {
            this.loadMembers()
          })
        } else {
          OrganizationManager.toMemberManager({ organizationID: this.organization.id, userID: member.user_id }).catch(error => {
            console.error(error)
            this.showNotifyDialog('職員管理設定の失敗', '職員管理者の設定変更に失敗しました。')
          }).finally(() => {
            this.loadMembers()
          })
        }
      }
    },

    toggleRelationManager (member) {
      if (member.author_id === this.signinMember.author_id) {
        this.showNotifyDialog('職員管理設定の失敗', '自分自身の設定を変更できません。')
      } else {
        if (member.relation_manager) {
          OrganizationManager.revokeRelationManager({ organizationID: this.organization.id, userID: member.user_id })
          .catch(error => {
            console.error(error)
            this.showNotifyDialog('かかりつけ管理設定の失敗', 'かかりつけ管理者の設定変更に失敗しました。')
            this.loadMembers()
          }).finally(() => {
            this.loadMembers()
          })
        } else {
          OrganizationManager.toRelationManager({ organizationID: this.organization.id, userID: member.user_id })
          .catch(error => {
            console.error(error)
            this.showNotifyDialog('かかりつけ管理設定の失敗', 'かかりつけ管理者の設定変更に失敗しました。')
            this.loadMembers()
          }).finally(() => {
            this.loadMembers()
          })
        }
      }
    },

    toggleDeputizeManager (member) {
      if (member.author_id === this.signinMember.author_id) {
        this.showNotifyDialog('職員管理設定の失敗', '自分自身の設定を変更できません。')
      } else {
        if (member.deputize_manager) {
          OrganizationManager.reovkeDeputizeManager({ organizationID: this.organization.id, userID: member.user_id })
          .catch(error => {
            console.error(error)
            this.showNotifyDialog('設定代行管理設定の失敗', '設定代行管理者の設定変更に失敗しました。')
            this.loadMembers()
          }).finally(() => {
            this.loadMembers()
          })
        } else {
          OrganizationManager.toDeputizeManager({ organizationID: this.organization.id, userID: member.user_id })
          .catch(error => {
            console.error(error)
            this.showNotifyDialog('設定代行管理設定の失敗', '設定代行管理者の設定変更に失敗しました。')
            this.loadMembers()
          }).finally(() => {
            this.loadMembers()
          })
        }
      }
    },

    toggleAgent (member) {
      if (member.agent) {
        OrganizationAgent.destroy({ organizationID: this.organization.id, userID: member.user_id })
        .catch(error => {
          console.error(error)
          this.showNotifyDialog('設定代行者設定の失敗', '設定代行者の設定変更に失敗しました。')
          this.loadMembers()
        }).finally(() => {
            this.loadMembers()
        })
      } else {
        OrganizationAgent.create({ organizationID: this.organization.id, userID: member.user_id })
        .catch(error => {
          console.error(error)
          this.showNotifyDialog('設定代行者設定の失敗', '設定代行者の設定変更に失敗しました。')
          this.loadMembers()
        }).finally(() => {
            this.loadMembers()
        })
      }
    },

    editMemberSuom (member) {
      // prepare attributes
      this.targetSuom.id = member.service_using_organization_member_id
      this.targetSuom.name = member.suom_name
      this.targetSuom.mail = member.suom_mail
      this.targetSuom.tel = member.suom_tel
      this.targetSuom.additional_comment = member.suom_additional_comment
      this.targetSuom.organization_id = this.organization.id
      this.targetSuom.author_id = member.author_id
      this.targetSuom.member_manager = member.member_manager
      this.targetSuom.relation_manager = member.relation_manager
      this.targetSuom.deputize_manager = member.deputize_manager
      this.targetSuom.organization = this.organization
      this.targetSuom.author = member.author
      // show modal
      this.showMemberProfileEditModal = true
    },

    resetTargetSuom () {
      this.targetSuom = {
        id: null,
        name: '',
        mail: '',
        tel: '',
        additional_comment: '',
        organization_id: null,
        author_id: null,
        member_manager: false,
        relation_manager: false,
        deputize_manager: false,
        default_signin: false,
        organization: null,
        author: null
      }
    },

    cancelMemberProfileSubmit () {
      this.showConfirmDialog(
        '基本情報の編集キャンセル',
        '基本情報の編集をキャンセルします。\n編集した内容は全て破棄されます。よろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.resetTargetSuom()
          this.showMemberProfileEditModal = false
        }
      )
    },

    executeMemberProfileSubmit (formData) {
      ServiceUsingOrganizationMember.update({ formData: formData }).then(() => {
        this.initObjects()
        this.showMemberProfileEditModal = false
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '職員情報の更新失敗',
          '職情報の更新に失敗しました。',
          () => { this.showMemberProfileEditModal = false }
        )
      })
    },

    removeMember (member) {
      if (member.author_id === this.signinMember.author_id) {
        this.showNotifyDialog('職員管理設定の失敗', '自分自身の設定を変更できません。')
      } else {
        this.showConfirmDialog(
          '職員の削除',
          '職員[' + member.author.name + ']を削除しようとしています。\n一度削除すると、再度案内を送信して職員になるまで元に戻せません。\n削除した職員の書き込みは残ります。\n職員を削除してよろしいですか?',
          DIALOG_TYPE.YES_NO,
          () => { this.executeRemoveMember(member) }
        )
      }
    },

    executeRemoveMember (member) {
      OrganizationMember.destroy({ organizationID: this.organization.id, memberID: member.id }).then(() => {
        this.loadMembers()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '職員の削除失敗',
          '職員の削除に失敗しました。'
        )
      })
    },

    createNewInvitation () {
      this.showCreateMemberInvitationModal = true
    },

    cencelCreateInvitation (callback) {
      this.showCreateMemberInvitationModal = false
      callback()
    },

    submitCreateInvitation (formData, callback) {
      OrganizationMemberInvitation.create({ formData: formData }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('案内の送信完了', '職員への参加案内を送信しました。\n本人確認用コードを伝えて、参加手続きを進めてください。')
        callback()
        this.showCreateMemberInvitationModal = false
        this.sentListOpen = true
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('案内の送信失敗', '職員への参加案内の作成に失敗しました。')
        this.showCreateMemberInvitationModal = false
      })
    },

    sendMail (sentInvitation) {
      OrganizationMemberInvitation.sendMail({ organizationID: this.organization.id, targetID: sentInvitation.id }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('案内の送信完了', '職員への参加案内を再度送信しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('案内の送信失敗', '職員への参加案内の再送信に失敗しました。')
      })
    },

    showPrintPage (sentInvitation) {
      console.log(sentInvitation)
    },

    removeInvitation (sentInvitation) {
      OrganizationMemberInvitation.destroy({ organizationID: this.organization.id, targetID: sentInvitation.id }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('案内の削除完了', '職員への参加案内を削除しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('案内の削除失敗', '職員への参加案内の削除に失敗しました。')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/centeringTitle.scss';
@import '@/assets/css/redTitledColumn.scss';
@import '@/assets/css/listData.scss';

.horus-manage-organization-member {
  .notify-comment {
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fbfaf5;
    img {
      margin: 0 3px;
      height: 14px;
    }
    ul {
      margin: 0;
      margin-left: 1em;
      padding-left: 10px;
    }
  }
  .block-title {
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
    img {
      width: 13px;
      transition: transform 600ms ease;
      &.open {
        transform: rotate(90deg);
      }
    }
    .caption {
      font-size: 12px;
      font-weight: normal;
      &.notice {
        color: #c43d53;
      }
    }
    .create-invitation-btn {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .member-list {
    overflow: hidden;
  }
  .sent-invitation-list {
    overflow: hidden;
  }
  .revoke-notify {
    margin: 0;
    padding: 10px;
    border: 1px solid #c43d53;
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 600ms ease;
}
</style>
