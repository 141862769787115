<template>
  <div class="horus-sent-member-invitation labeled_content_block">
    <div class="labeled_content_single_block" v-if="sentInvitation.login_id">
      <label>氏名</label>
      <div class="content">{{ sentInvitation.name }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentInvitation.name">
      <label>送信先</label>
      <div class="content">{{ sentInvitation.mail }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentInvitation.mail">
      <label>期限</label>
      <div class="content">{{ sentInvitationLimitAt }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>本人確認コード</label>
      <div class="content">{{ sentInvitation.short_token }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ sentInvitation.status }}</div>
    </div>
    <div class="small-title">表示用設定</div>
    <div class="display-block">
      <div class="labeled_content_single_block">
        <label>職種</label>
        <div class="content">{{ sentInvitation.reserved_additional_name }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用氏名</label>
        <div class="content">{{ sentInvitation.reserved_name }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用メール</label>
        <div class="content">{{ sentInvitation.reserved_mail }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用電話</label>
        <div class="content">{{ sentInvitation.reserved_tel }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>管理権限</label>
        <div class="content">{{ managerReserveStatus }}</div>
      </div>
    </div>
    <div class="content-controll-block">
      <img src="/static/images/send_mail_icon.png" @click="sendMail" />
      <router-link :to="{ name: 'PrintMemberInvitation', query: queryForPrint }" target="_blank">
        <img src="/static/images/print.png" @click="showPrintPage" />
      </router-link>
      <img src="/static/images/bucket_icon.png" @click="removeInvitation" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusSentMemberInvitation',

  props: {
    sentInvitation: Object,
    organization: Object
  },

  computed: {
    managerReserveStatus () {
      var reserved = []
      if (this.sentInvitation.reserve_relation_manager) {
        reserved.push('かかりつけ管理者')
      }
      if (this.sentInvitation.reserve_deputize_manager) {
        reserved.push('設定代行管理者')
      }
      if (reserved.length > 0) {
        return reserved.join(', ')
      } else {
        return '設定なし'
      }
    },

    sentInvitationLimitAt () {
      var limitAt = new Date(this.sentInvitation.limit_at)
      if (limitAt) {
        return moment(limitAt).format('YYYY/MM/DD HH:mm:ss')
      }
      return ''
    },

    queryForPrint () {
      return {
        organization_name: this.organization.name,
        target_name: this.sentInvitation.name,
        target_mail: this.sentInvitation.mail,
        short_token: this.sentInvitation.short_token
      }
    }
  },

  methods: {
    sendMail () {
      this.$emit('sendMailEvent', this.sentInvitation)
    },

    showPrintPage () {
      this.$emit('showPrintPageEvent', this.sentInvitation)
    },

    removeInvitation () {
      this.$emit('removeInvitationEvent', this.sentInvitation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
