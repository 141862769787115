<template>
  <div class="horus-manage-organization-request-detail-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/org_member.png" class="title-icon" />施設新規登録申請の詳細</h1>
      </div>
      <div class="modal-body">
        <div class="search-result-column">
          <h4>医療機関コードから検索された結果</h4>
          <div class="search-result" v-html="searchResultHTML"></div>
        </div>
        <div class="organization-request-column" v-if="organizationRequest">
          <h4>入力された施設情報</h4>
          <div class="list-data">
            <div class="list-data-line">
              <div class="labels"><label>担当者名</label></div>
              <div class="content">{{ organizationRequest.operator_name }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>医療機関コード</label></div>
              <div class="content">{{ organizationRequest.medical_institution_code }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>施設名称</label></div>
              <div class="content">{{ organizationRequest.name }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>ヨミガナ</label></div>
              <div class="content">{{ organizationRequest.name_kana }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>略称</label></div>
              <div class="content">{{ organizationRequest.short_name }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>郵便番号</label></div>
              <div class="content">{{ organizationRequest.zip_code }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>住所</label></div>
              <div class="content">{{ fullAddress }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>電話番号</label></div>
              <div class="content">{{ organizationRequest.tel }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>メールアドレス</label></div>
              <div class="content">{{ organizationRequest.mail }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>管理者登録用メールアドレス</label></div>
              <div class="content">{{ organizationRequest.first_manager_mail }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>作成日</label></div>
              <div class="content">{{ dateString(organizationRequest.submit_at) }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>申請日</label></div>
              <div class="content">{{ dateString(organizationRequest.applicated_at) }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>処理日</label></div>
              <div class="content">{{ dateString(organizationRequest.processed_at) }}</div>
            </div>
          </div>
        </div>
        <div class="request-controll-column">
          <div class="controll-buttons">
            <button class="admit-btn" :class="{enable: processable}" @click="admit">承認</button>
            <button class="reject-btn" :class="{enable: processable}" @click="reject">拒否</button>
            <button class="cancel-btn" @click="closeModal">保留</button>
            <button class="destroy-btn" @click="destroy">削除</button>
          </div>
          <div class="reject-message-column">
            <span class="title">拒否理由</span>
            <textarea rows="5" v-model="causeOfReject"></textarea>
          </div>
        </div>
      </div>
    </horus-modal-frame>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  OrganizationRequest
} from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as USER_ADDRESS from '@/assets/constants/addressData.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageOrganizationRequestDetailModal',

  components: {
    HorusModalFrame,
    HorusConfirmDialog
  },

  props: {
    showModal: Boolean,
    organizationRequest: Object
  },

  data () {
    return {
      searchedData: null,
      causeOfReject: '',
      addressData: USER_ADDRESS
    }
  },

  watch: {
    organizationRequest: { handler: 'initData', immediate: true }
  },

  computed: {
    searchResultHTML () {
      if (this.searchedData) {
        var resultData = null
        if (Array.isArray(this.searchedData)) {
          if (this.searchedData.length > 0) {
            resultData = this.searchedData[0]
          } else {
            return '<span class="notify-error">医療機関コードに対応する施設がありませんでした</span>'
          }
        } else if (this.searchedData.IryokikanCode) {
          resultData = this.searchedData
        }
        if (resultData) {
          var lineHead = '<div class="result-line" style="margin: 3px; margin-right: 10px;"><span class="title" style="margin-right: 5px; font-weight: bold;">'
          var lineMid = '</span><span class="value">'
          var lineTail = '</span></div>'
          var prefectureStr = '---'
          var prefectureSet = this.addressData.PREFECTURE_CODE_JP.find(set => set[0] === resultData.TodofukenNo)
          if (prefectureSet) {
            prefectureStr = prefectureSet[1]
          }
          return lineHead + '名称' + lineMid + resultData.IryokikanMei + lineTail +
            lineHead + '医療機関番号' + lineMid + resultData.IryokikanCode + lineTail +
            lineHead + '郵便番号' + lineMid + resultData.ZipCode + lineTail +
            lineHead + '都道府県' + lineMid + prefectureStr + lineTail +
            lineHead + '住所' + lineMid + resultData.Address + lineTail +
            lineHead + '電話番号' + lineMid + resultData.Tel + lineTail
        }
        // "TodofukenNo": "01",
        // "IryokikanCode": "0110112489",
        // "IryokikanMei": "医療法人愛全病院",
        // "ZipCode": "005-0813",
        // "Address": "札幌市南区川沿１３条２丁目１番３８号",
        // "Tel": "011-571-5670",
        // "TelShort": "",
        // "Fax": "",
        // "Kaisetusya": "",
        // "Kanrisya": "",
        // "Torokubi": "202102",
        // "SinryoKamoku": "",
        // "Biko": "",
        // "ByosyoSu": "495"
      }
      return ''
    },

    fullAddress () {
      if (this.organizationRequest) {
        if (this.organizationRequest.city && this.organizationRequest.address) {
          var prefectureSet = this.addressData.PREFECTURE_CODE_JP.find(set => set[0] === this.organizationRequest.prefecture)
          console.log(prefectureSet)
          if (prefectureSet) {
            return prefectureSet[1] + this.organizationRequest.city + this.organizationRequest.address
          }
          return this.organizationRequest.city + this.organizationRequest.address
        }
      }
      return ''
    },

    processable () {
      if (this.organizationRequest && this.organizationRequest.applicated_at && !this.organizationRequest.processed_at) {
        return true
      }
      return false
    }
  },

  methods: {
    initData () {
      if (this.organizationRequest && this.organizationRequest.cause_of_reject) {
        this.causeOfReject = this.organizationRequest.cause_of_reject
      } else {
        this.causeOfReject = ''
      }
      this.loadSearchedData()
    },

    loadSearchedData () {
      if (this.organizationRequest && this.organizationRequest.medical_institution_code) {
        OrganizationRequest.searchMedicalInfo({ code: this.organizationRequest.medical_institution_code }).then(res => {
          this.searchedData = res.medicalInfo
        }).catch(error => {
          console.log(error)
        })
      }
    },

    dateString (targetDate) {
      var date = new Date(targetDate)
      if (targetDate && date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      } else {
        return '------'
      }
    },

    admit () {
      if (this.processable) {
        this.showConfirmDialog(
          '施設作成申請の承認',
          '施設作成申請を承認します。承認した場合、施設は表示された情報で作成され、施設のメールアドレスに承認結果を送信します。\n同時に、管理者アカウント登録用メールアドレスに対して、施設管理者案内のメールが送信されます。\nこの処理は後戻りできません。\nよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeAdmit() }
        )
      } else {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      }
    },

    executeAdmit () {
      if (this.processable) {
        OrganizationRequest.confirm({ organizationRequestID: this.organizationRequest.id }).then(res => {
          this.showConfirmDialog(
            '施設作成申請の承認完了',
            '新規施設[' + res.organization.name + ']が作成されました。\n',
            DIALOG_TYPE.NOTIFY,
            () => { this.closeModal() }
          )
        })
      } else {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      }
    },

    reject () {
      if (!this.processable) {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      } else if (this.causeOfReject && this.causeOfReject.length > 0) {
        this.showConfirmDialog(
          '施設作成申請の拒否',
          '施設作成申請を拒否します。拒否した場合、施設のメールアドレスに拒否の結果と理由を送信します。\nこの処理は後戻りできません。\nよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeReject() }
        )
      } else {
        this.showNotifyDialog('拒否理由がありません。', '施設作成申請の拒否を行うには拒否理由の入力が必須です。')
      }
    },

    executeReject () {
      if (this.processable) {
        OrganizationRequest.reject({ organizationRequestID: this.organizationRequest.id, rejectMessage: this.causeOfReject }).then(() => {
          this.showConfirmDialog(
            '施設作成申請の拒否完了',
            '施設作成申請の拒否が完了しました。',
            DIALOG_TYPE.NOTIFY,
            () => { this.closeModal() }
          )
        })
      } else {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      }
    },

    destroy () {
      this.showConfirmDialog(
        '施設作成申請の削除',
        '施設作成申請を削除します。削除した場合、申請処理は継続不可能となります。。\nこの処理は後戻りできません。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroy() }
      )
    },

    executeDestroy () {
      OrganizationRequest.destroy({ organizationRequestID: this.organizationRequest.id }).then(() => {
        this.showConfirmDialog(
          '施設作成申請の削除完了',
          '施設作成申請の削除が完了しました。',
          DIALOG_TYPE.NOTIFY,
          () => { this.closeModal() }
        )
      }).catch(error => {
        console.log(error.response)
      })
    },

    closeModal () {
      this.$emit('modalCloseEvent')
    }

  }
}
</script>

<style lang="scss" scoped>
.horus-manage-organization-request-detail-modal {
  .modal-body {
    padding: 10px;
    .search-result-column {
      margin-bottom: 10px;
      h4 {
        margin: 5px 0;
        font-size: 1em;
        font-weight: bold;
      }
      .search-result {
        padding: 10px;
        border: 1px solid #aaa;
      }
    }
    .organization-request-column {
      h4 {
        margin: 5px 0;
        font-size: 1em;
        font-weight: bold;
      }
      .list-data {
        margin: 10px 0;
        border: 1px solid #aaa;
        background-color: #fff;
        font-size: 16px;
        .list-data-line {
          display: flex;
          padding: 5px;
          border-bottom: 1px solid #aaa;
          .labels {
            margin-right: 10px;
            width: 150px;
            text-align: right;
            font-size: 14px;
            font-weight: bold;
          }
          .content {
            padding: 0;
            background-color: inherit;
          }
          img {
            margin-left: 20px;
            height: 20px;
          }
        }
      }
    }
    .request-controll-column {
      .controll-buttons {
        display: flex;
        justify-content: space-between;
        button {
          padding: 3px;
          width: 80px;
          border-radius: 3px;
          font-weight: bold;
          color: #fff;
        }
        .admit-btn {
          border-color: #93b881;
          background-color: #93b881;
          &.enable {
            border-color: #5cb85b;
            background-color: #5cb85b;
          }
        }
        .reject-btn {
          border-color: #c97586;
          background-color: #c97586;
          &.enable {
            border-color: #c43d53;
            background-color: #c43d53;
          }
        }
        .cancel-btn {
          border-color: #f08300;
          background-color: #f08300;
        }
        .destroy-btn {
          border-color: #55295b;
          background-color: #55295b;
        }
      }
      .reject-message-column {
        margin-top: 10px;
        textarea {
          width: calc(100% - 10px);
          resize: none;
        }
      }
    }
  }
}
</style>
