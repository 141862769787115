<template>
  <div class="horus-system-third-party-linkage-edit-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />第三者連携サービス編集</h1>
      </div>
      <div class="modal-body">
        <horus-system-third-party-linkage-form
          v-if="editTarget"
          :originalData="editTarget"
          :submitTitle="'更新'"
          :cancelTitle="'キャンセル'"
          v-on:cancelEvent="cancelEdit"
          v-on:submitEvent="submitEdit"
          />
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import HorusSystemThirdPartyLinkageForm from '@/components/manageSystem/thirdPartyLinkage/HorusSystemThirdPartyLinkageForm.vue'

export default {
  name: 'HorusSystemThirdPartyLinkageEditModal',

  components: {
    HorusModalFrame,
    HorusSystemThirdPartyLinkageForm
  },

  props: {
    editTarget: Object,
    showModal: Boolean
  },

  methods: {
    cancelEdit (callback) {
      this.$emit('cancelEvent', callback)
    },

    submitEdit (formData) {
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-third-party-linkage-edit-modal {
  margin: 0;
  .modal-body {
    padding: 10px 20px;
  }
}
</style>
