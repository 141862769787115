<template>
  <div class="horus-system-third-party-linkage-column">
    <div class="column-line status"><label class="column-label">状態</label><span class="column-value">{{ status }}</span></div>
    <div class="column-line name"><label class="column-label">サービス名</label><span class="column-value">{{ linkage.name }}</span></div>
    <div class="column-line provider_name"><label class="column-label">提供者名</label><span class="column-value">{{ linkage.provider_name }}</span></div>
    <div class="column-line updated_at"><label class="column-label">更新日</label><span class="column-value">{{ updateAtStr }}</span></div>
    <div class="controll-column">
      <button class="show-btn" @click="showDetail">詳細</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusSystemThirdPartyLinkageColumn',

  props: {
    linkage: Object
  },

  computed: {
    status () {
      if (this.linkage.deleted) {
        if (this.linkage.admitted) {
          return '削除済み'
        } else {
          return '拒否済'
        }
      } else {
        if (this.linkage.admitted) {
          return '承認済み'
        } else {
          return '承認待ち'
        }
      }
    },

    updateAtStr () {
      if (this.linkage.updated_at) {
        return moment(this.linkage.updated_at).format('YYYY/MM/DD HH:mm:SS')
      }
      return ''
    }
  },

  methods: {
    showDetail () {
      this.$emit('showDetailEvent', this.linkage)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-third-party-linkage-column {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #aaa;
  .column-line {
    margin: 0 10px;
    .column-label {
      margin-right: 10px;
      font-weight: bold;
    }
  }
  .controll-column {
    margin-top: 5px;
    width: 100%;
    text-align: right;
    .show-btn {
      margin: 0 10px;
      padding: 3px 10px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
</style>
