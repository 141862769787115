import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'
import Organization from './Organization'

export default class SigninMember extends Model {
  static entity = 'signinMember'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      nbid: this.attr(''),
      author_id: this.attr(null),
      organization_id: this.attr(null),
      member_manager: this.attr(false),
      relation_manager: this.attr(false),
      deputize_manager: this.attr(false),
      agent: this.attr(false),
      switch_suoms: this.attr(null),
      help_path: this.attr('')
    }
  }

  get organization () {
    return Organization.find(this.organization_id)
  }

  get author () {
    return Author.find(this.author_id)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData (payload) {
    // console.log('SigninMember updateData')
    // console.log(payload)
    await SigninMember.deleteAll()
    let entries = await SigninMember.insert({ data: payload })
    // console.log(entries)
    let signinMember = entries.signinMember[0]
    // console.log(signinMember)
    if (!Author.find(signinMember.author_id)) {
      await Author.api().fetchByID({ idList: [signinMember.author_id] })
    }
    if (!Organization.find(signinMember.organization_id)) {
      // console.log('in signinMember updateData, load organization ' + signinMember.organization_id)
      await Organization.api().fetchByID({ idList: [signinMember.organization_id] })
    }
    SigninMember.commit((state) => {
      state.fetchedAt = new Date()
    })
    // console.log(SigninMember.query().first())
    return signinMember
  }

  static apiConfig = {
    actions: {
      async fetch ({ loginID, password }) {
        var formData = new FormData()
        formData.append('login_id', loginID)
        formData.append('password', password)
        var axiosHeader = await axiosHelper.csrfHeader('PUT')
        await this.request({
          ...axiosHeader,
          ...{
            method: 'put',
            url: 'authenticate/signin_to_service_user',
            data: formData,
            save: false
          }
        })
        .then(res => {
          console.log('in current_member model fetch')
          console.log(res.response.data.member)
          return SigninMember.updateData(res.response.data.member)
        })
        .catch(error => {
          throw error
        })
      },
    
      async fetchMemberSelection ({ loginID, password }) {
        var formData = new FormData()
        formData.append('login_id', loginID)
        formData.append('password', password)
        var axiosHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...axiosHeader,
          ...{
            method: 'put',
            url: 'authenticate/member_selection',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
        .catch(error => {
          throw error
        })
      },
    
      async signinByToken ({code, token}) {
        var formData = new FormData()
        formData.append('code', code)
        formData.append('token', token)
        var axiosHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...axiosHeader,
          ...{
            method: 'put',
            url: 'authenticate/signin_member_by_token',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SigninMember.updateData(res.response.data.member)
        })
        .catch(error => {
          throw error
        })
      },
    
      fetchCurrentSingin () {
        return this.request({
          method: 'get',
          url: 'authenticate/current_signin_member',
          withCredentials: true
        })
        .then(res => {
          return SigninMember.updateData(res.response.data.member)
        })
        .catch(error => { throw error })
      },
    
      async switch ({ switchTargetID }) {
        var formData = new FormData()
        formData.append('service_user_id', switchTargetID)
        var axiosHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...axiosHeader,
          ...{
            method: 'put',
            url: 'authenticate/switch_member',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SigninMember.updateData(res.response.data.member)
        })
        .catch(error => {
          throw error
        })
      }
    
    }
  }
}
