<template>
  <div class="horus-third-party-linkage-validate-mail-view">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="contents">
      <h1 class="centering-title">第三者サービス連携の登録申請/メールアドレスの確認</h1>
      <div class="token-failed-block" v-if="isTokenValid === false">
        <h2>メールアドレスの確認失敗</h2>
        <div class="token-failed-message">
          ナラティブブック第三者サービス連携登録での、メールアドレスの確認に失敗しました。<br/>
          アクセスされたリンクは、期限が切れているか、URLが正しくないか、すでに確認済みです。<br/>
          リンクの利用期限は、登録された日時から2日間です。<br/>
          期限が切れていないにも関わらずこのページが表示されている場合は、URLが正しくありません。<br/>
          メールのリンクを全て選択して、ブラウザーのアドレスバーにペーストするなどして試してください。<br/>
          期限が切れている場合は、再度初めから登録作業をやり直してください。<br/>
          <a href="/third_party_linkage_request">第三者連携サービスの登録ページ</a>
        </div>
      </div>
      <div class="token-valid-block" v-if="isTokenValid && thirdPartyLinkage">
        <h2>メールアドレスの確認完了</h2>
        <div class="token-valid-message">
          ナラティブブック第三者サービス連携登録での、メールアドレスの確認を完了しました。<br/>
          以後、ナラティブブックの運営による承認作業に入ります。<br/>
          承認作業の完了時には、登録されたメールアドレスに承認完了のメールが届きます。<br/>
          承認作業の完了をお待ちください。<br/>
          このページは閉じても問題ありません。
        </div>
        <div class="submit-linkage">
          <div class="form-list-line">
            <div class="category-title">サービス情報</div>
            <div class="form-line name">
              <label class="linkage-label">名称</label>
              {{ thirdPartyLinkage.name }}
            </div>
            <div class="form-line description">
              <label class="linkage-label">詳細</label>
              <span v-html="brTextHtml(thirdPartyLinkage.description)"></span>
            </div>
            <div class="form-line fdqn">
              <label class="linkage-label">FDQN</label>
              {{ thirdPartyLinkage.fdqn }}
            </div>
            <div class="form-line callback_url">
              <label class="linkage-label">コールバックアドレス</label>
              {{ thirdPartyLinkage.callback_url }}
            </div>
            <div class="category-title">サービス提供者情報</div>
            <div class="form-line provider_name">
              <label class="linkage-label">提供者名</label>
              {{ thirdPartyLinkage.provider_name }}
            </div>
            <div class="form-line provider_zip_code">
              <label class="linkage-label">郵便番号</label>
              {{ thirdPartyLinkage.provider_zip_code }}
            </div>
            <div class="form-line provider_address">
              <label class="linkage-label">住所</label>
              <span v-html="brTextHtml(thirdPartyLinkage.provider_address)"></span>
            </div>
            <div class="form-line provider_mail">
              <label class="linkage-label">メールアドレス</label>
              {{ thirdPartyLinkage.provider_mail }}
            </div>
            <div class="form-line provider_tel">
              <label class="linkage-label">電話番号</label>
              {{ thirdPartyLinkage.provider_tel }}
            </div>
            <div class="form-line provider_contact">
              <label class="linkage-label">担当者名</label>
              {{ thirdPartyLinkage.provider_contact }}
            </div>
            <div class="form-line provider_url">
              <label class="linkage-label">ホームページ</label>
              {{ thirdPartyLinkage.provider_url }}
            </div>
          </div>
        </div>
      </div>
      <horus-footer />
    </div>
  </div>
</template>

<script>
import { ThirdPartyLinkage } from '@/api'

import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusThirdPartyLinkageValidateMail',

  components: {
    HorusFooter
  },

  data () {
    return {
      token: null,
      isTokenValid: null,
      thirdPartyLinkage: null
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  methods: {
    async initToken () {
      this.token = this.$attrs.mail_validate_token
      try {
        let res = await ThirdPartyLinkage.validate_mail({ mailValidateToken: this.token })
        this.thirdPartyLinkage = res.third_party_linkage
        this.isTokenValid = true
      } catch (error) {
        console.error(error.response)
        if (error.response.status === 404) {
          this.isTokenValid = false
        }
      }
    },

    brTextHtml (text) {
      if (text) {
        return text.replace(/\n/gi, '<br />')
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-third-party-linkage-validate-mail-view {
  margin: 0 auto;
  background-color: #fff;
  width: 1000px;
  height: 100%;
  .contents {
    height: calc(100% - 51px - 20px);
    overflow: scroll;
    padding: 10px 50px;
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      margin: 5px;
      margin-top: 10px;
      font-size: 1.1em;
      font-weight: bold;
    }
    .token-failed-message {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #c53d43;
    }
    .token-valid-message {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #aaa;
    }
    .submit-linkage {
      .form-list-line {
        .category-title {
          margin-top: 10px;
          font-weight: bold;
        }
        .form-line {
          display: flex;
          margin: 5px 0;
          padding-bottom: 5px;
          border-bottom: 1px solid #ddd;
          .linkage-label {
            width: 150px;
            padding: 0 10px;
            text-align: right;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
