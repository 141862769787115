<template>
  <div class="horus-tag-selection" @click="updateState">
    <horus-custom-check-box :type="'small'" :active="active" :enabled="enabled" />
    <horus-hash-tag-display :hashTag="hashTag" :key="hashTag.id" :sizeType="'large-no-icon'" />
  </div>
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusTagSelection',

  components: {
    HorusCustomCheckBox,
    HorusHashTagDisplay
  },

  props: {
    hashTag: Object,
    enabled: Boolean,
    active: Boolean
  },

  methods: {
    updateState () {
      this.$emit('updateStateEvent', this.hashTag, this.active, this.enabled)
    }
  }
}
</script>

<style lang="scss">
.horus-tag-selection {
  display: inline-block;
  margin: 5px;
}
</style>
