import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import moment from 'moment'
import Author from './Author'
import HashTag from './HashTag'
import WishFile from './WishFile'
import WishPhoto from './WishPhoto'

export default class Wish extends Model {
  static entity = 'wishes'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      author_id: this.attr(null),
      editor_id: this.attr(null),
      title: this.string(''),
      content: this.string(''),
      position: this.number(-1),
      deleted: this.boolean(false),
      created_at: this.string(''),
      updated_at: this.string(''),
      tags: this.attr(null),
      notify_log: this.attr(null)
    }
  }

  get createdAt () {
    return new Date(this.created_at)
  }

  get createdAtJp () {
    return moment(this.createdAt).format('YYYY年MM月DD日 HH:mm:')
  }

  get updatedAt () {
    return new Date(this.updated_at)
  }

  get updatedAtJp () {
    return moment(this.updatedAt).format('YYYY年MM月DD日 HH:mm:')
  }

  get author () {
    // TODO: load data when undefined
    return Author.find(this.author_id)
  }

  get editor () {
    // TODO: load data when undefined
    return Author.find(this.editor_id)
  }

  get editorName () {
    if (this.editor) {
      return this.editor.fullName
    }
    return ''
  }

  get photos () {
    return WishPhoto.query().where('wish_id', this.id).get()
  }

  get files () {
    return WishFile.query().where('wish_id', this.id).get()
  }

  get hashTags () {
    return HashTag.findIn(this.tags)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(data) {
    const existWishes = await Wish.query().where('user_id', data.timelineOwnerID).get()
    for(var existWish of existWishes) {
      for(var photo of existWish.photos){
        await photo.$delete()
      }
      for(var file of existWish.files){
        await file.$delete()
      }
      await existWish.$delete()
    }
    return Promise.all([
      Wish.insertOrUpdate({ data: data.wishes }),
      WishPhoto.insertOrUpdate({ data: data.wish_photos }),
      WishFile.insertOrUpdate({ data: data.wish_files })
    ])
    .then(async ([entities]) => {
      if (entities && entities.wishes) {
        // load author and editor
        let loadAuthorID = []
        for(var wish of entities.wishes) {
          if (!wish.author) {
            if (loadAuthorID.indexOf(wish.author_id) < 0) loadAuthorID.push(wish.author_id)
          }
          if (!wish.editor) {
            if (loadAuthorID.indexOf(wish.editor_id) < 0) loadAuthorID.push(wish.editor_id)
          }
        }
        if (loadAuthorID.length > 0) {
          await Author.api().fetchByID({ idList: loadAuthorID })
        }
        Wish.commit((state) => [
          state.fetchedAt = new Date()
        ])
        return entities.wishes
      }
    })
  }

  static apiConfig = {
    actions: {
      fetch ({timelineOwnerID, targetDate}) {
        return this.request({
          method: 'get',
          url: 'wish/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, target_date: targetDate },
          save: false
        })
        .then(res => {
          return Wish.updateData(res.response.data)
        })
        .catch(error => { throw error })
      },
    
      fetchUpdatad ({timelineOwnerID, targetDate}) {
        return this.request({
          method: 'get',
          url: 'wish/updated_list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, target_date: targetDate },
          save: false
        })
        .then(res => {
          return Wish.updateData(res.response.data)
        })
        .catch(error => { throw error })
      },
    
      async updatePosition ({timelineOwnerID, targetWishID, newPosition}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('wish_id', targetWishID)
        formData.append('position', newPosition)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'wish/move_to',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async toInvisible ({timelineOwnerID, targetWishID}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('wish_id', targetWishID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'wish/to_invisible',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async toVisible ({timelineOwnerID, targetWishID}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('wish_id', targetWishID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'wish/to_visible',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async create ({formData}) {
        // console.log(formData)
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'wish',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async update ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'wish',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy ({timelineOwnerID, targetID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'wish',
            withCredentials: true,
            params: { target_user_id: timelineOwnerID, wish_id: targetID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }

    }
  }
}
