<template>
  <div class="horus-create-organization-request-finish">
    <h2>ナラティブブック新規施設登録の申請完了</h2>
    <div class="finish-content">
      施設の新規登録申請を下記内容で受け付けました。<br/>
      ナラティブブック運営の審査完了をお待ち下さい。<br/>
      <br/>
      審査完了後、この登録で利用したメールアドレス宛に、審査結果をお知らせします。<br/>
      <br/>
      また、審査の結果、登録ができた場合、施設の最初の管理者アカウントの作成手続きがございます。<br/>
      アカウントの作成手続きは登録いただいた管理者のメールアドレス<span class="strong">[{{ requestOrganization.first_manager_mail }}]</span>宛に送信されますので、<br/>
      メール本文にあるリンクからアカウント登録の手続きをお願いします。<br/>
      <br/>
      このウィンドウは閉じてしまって構いません。
      <div class="message" v-if="message.length > 0" v-html="messageHTML"></div>
      <div class="notify-message" v-if="message.length > 0" v-html="notifyMessageHTML"></div>
    </div>
    <div class="list-data">
      <div class="list-data-line">
        <div class="labels"><label>医療機関コード</label></div>
        <div class="content">{{ requestOrganization.medical_institution_code }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>施設名称</label></div>
        <div class="content">{{ requestOrganization.name }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>ヨミガナ</label></div>
        <div class="content">{{ requestOrganization.name_kana }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>略称</label></div>
        <div class="content">{{ requestOrganization.short_name }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>郵便番号</label></div>
        <div class="content">{{ requestOrganization.zip_code }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>住所</label></div>
        <div class="content">{{ fullAddress }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>電話番号</label></div>
        <div class="content">{{ requestOrganization.tel }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>メールアドレス</label></div>
        <div class="content">{{ requestOrganization.mail }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>施設種別</label></div>
        <div class="content">{{ requestOrganization.organizatoin_type }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as USER_ADDRESS from '@/assets/constants/addressData.js'
import * as ORGANIZATION_TYPES from '@/assets/constants/organizationType.js'

export default {
  name: 'HorusCreateOrganizationRequestFinish',

  props: {
    requestOrganization: Object,
    message: String,
    notifyMessage: String
  },

  data () {
    return {
      addressData: USER_ADDRESS,
      orgData: ORGANIZATION_TYPES
    }
  },

  computed: {
    messageHTML () {
      if (this.message) {
        return this.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    notifyMessageHTML () {
      if (this.notifyMessage) {
        return this.notifyMessage.replace(/\n/gi, '<br />')
      }
      return ''
    },

    fullAddress () {
      if (this.requestOrganization) {
        var prefectureSet = this.addressData.PREFECTURE_CODE_JP.find(set => set[0] === this.requestOrganization.prefecture)
        console.log(prefectureSet)
        if (prefectureSet) {
          return prefectureSet[1] + this.requestOrganization.city + this.requestOrganization.address
        }
        return this.requestOrganization.city + this.requestOrganization.address
      }
      return ''
    }
  },

  methods: {
    startNarrativeBook () {
      this.$emit('startNarrativeBookEvent')
    },

    finishNarrativeBook () {
      this.$emit('finishNarrativeBookEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-organization-request-finish {
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .finish-content {
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
    text-align: center;
    font-size: 1.2em;
    .strong {
      margin: 0 5px;
      font-weight: bold;
    }
    .message {
      margin: 10px auto;
      padding: 10px;
      width: 400px;
      border: 1px solid #ddd;
      text-align: left;
    }
    .notify-message {
      margin: 10px auto;
      padding: 10px;
      width: 400px;
      border: 1px solid #b94047;
      text-align: left;
    }
  }
  .list-data {
    margin: 10px 0;
    border: 1px solid #aaa;
    background-color: #fff;
    font-size: 16px;
    .list-data-line {
      display: flex;
      padding: 5px;
      border-bottom: 1px solid #aaa;
      .labels {
        margin-right: 10px;
        width: 150px;
        text-align: right;
        font-size: 14px;
        font-weight: bold;
      }
      .content {
        padding: 0;
        background-color: inherit;
      }
      img {
        margin-left: 20px;
        height: 20px;
      }
    }
  }
}
</style>
