<template>
  <div class="horus-system-manage-archive-view">
    <h1 class="centering-title">アーカイブ管理</h1>
    <div class="controll"><button class="reload-btn" @click="reloadData">データ更新</button></div>
    <h2>承認待ち</h2>
    <table class="archive-list" v-if="waitingRequests.length > 0">
      <tr><th>リクエストユーザー</th><th>期間</th><th>依頼日時</th><th>操作</th></tr>
      <tr v-for="request in waitingRequests" :key="'waiting' + request.id">
        <td class="title" @click="showDetail(request)">{{ request.request_nbid }}({{ request.request_name }})</td>
        <td>{{ term(request) }}</td>
        <td>{{ requestAt(request) }}</td>
        <td>
          <button class="admit-btn" @click="admit(request)">作成承認</button>
          <button class="reject-btn" @click="reject(request)">作成拒否</button>
        </td>
      </tr>
    </table>
    <div class="requests-none" v-if="waitingRequests.length <= 0">承認待ちリクエストはありません。</div>
    <h2>作成待機中</h2>
    <table class="archive-list" v-if="standByRequests.length > 0">
      <tr><th>リクエストユーザー</th><th>期間</th><th>依頼日時</th><th>承認日時</th></tr>
      <tr v-for="request in standByRequests" :key="'standby' + request.id">
        <td class="title" @click="showDetail(request)">{{ request.request_nbid }}({{ request.request_name }})</td>
        <td>{{ term(request) }}</td>
        <td>{{ requestAt(request) }}</td>
        <td>{{ dataCreateAt(request) }}</td>
      </tr>
    </table>
    <div class="requests-none" v-if="standByRequests.length <= 0">作成待機中のリクエストはありません。</div>
    <h2>拒否済(全{{ rejectedCount }}件中&nbsp;最新10件)</h2>
    <table class="archive-list" v-if="rejectedRequests.length > 0">
      <tr><th>リクエストユーザー</th><th>期間</th><th>依頼日時</th><th>拒否日時</th><th>拒否理由</th></tr>
      <tr v-for="request in rejectedRequests" :key="'reject' + request.id">
        <td class="title" @click="showDetail(request)">{{ request.request_nbid }}({{ request.request_name }})</td>
        <td>{{ term(request) }}</td>
        <td>{{ requestAt(request) }}</td>
        <td>{{ rejectedAt(request) }}</td>
        <td v-html="rejectMessageHTML(request)"></td>
      </tr>
    </table>
    <div class="requests-none" v-if="rejectedRequests.length <= 0">拒否済リクエストはありません。</div>
    <h2>作成失敗(全{{ failedCount }}件中&nbsp;最新50件)</h2>
    <table class="archive-list" v-if="failedRequests.length > 0">
      <tr><th>リクエストユーザー</th><th>期間</th><th>依頼日時</th><th>承認日時</th><th>ログ</th></tr>
      <tr v-for="request in failedRequests" :key="'failed' + request.id">
        <td class="title" @click="showDetail(request)">{{ request.request_nbid }}({{ request.request_name }})</td>
        <td>{{ term(request) }}</td>
        <td>{{ requestAt(request) }}</td>
        <td>{{ dataCreateAt(request) }}</td>
        <td v-html="buildLogHTML(request)"></td>
      </tr>
    </table>
    <div class="requests-none" v-if="failedRequests.length <= 0">作成失敗リクエストはありません。</div>
    <h2>作成済み(全{{ publishedCount }}件中&nbsp;最新10件)</h2>
    <table class="archive-list" v-if="publishedRequests.length > 0">
      <tr><th>リクエストユーザー</th><th>期間</th><th>依頼日時</th><th>作成日時</th><th>データサイズ</th></tr>
      <tr v-for="request in publishedRequests" :key="'published' + request.id">
        <td class="title" @click="showDetail(request)">{{ request.request_nbid }}({{ request.request_name }})</td>
        <td>{{ term(request) }}</td>
        <td>{{ requestAt(request) }}</td>
        <td>{{ publishedAt(request) }}</td>
        <td>{{ dataSize(request) }}</td>
      </tr>
    </table>
    <div class="requests-none" v-if="publishedRequests.length <= 0">作成済みリクエストはありません。</div>
    <horus-modal-frame
      :showModalFlag="showRejectModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />アーカイブ作成の拒否</h1>
      </div>
      <div class="modal-body">
        <div class="reject-target" v-if="rejectTarget">
          <div class="line-content"><div class="label">依頼者(依頼者名)</div><div class="contents">{{ rejectTarget.request_nbid }}({{ rejectTarget.request_name }})</div></div>
          <div class="line-content"><div class="label">アーカイブ対象期間</div><div class="contents">{{ term(rejectTarget) }}</div></div>
          <div class="line-content"><div class="label">依頼日時</div><div class="contents">{{ requestAt(rejectTarget) }}</div></div>
        </div>
        <div class="inputs">
          <div class="label">拒否理由</div>
          <textarea v-model="rejectMessage"></textarea>
        </div>
        <div class="controll">
          <button class="cancel-btn" @click="cancelReject">キャンセル</button>
          <button class="execute-btn" @click="executeReject">拒否実行</button>
        </div>
      </div>
    </horus-modal-frame>
    <horus-system-management-archive-modal
      :request="detailModalTarget"
      :showArchiveModal="showDetailModal"
      v-on:closeModalEvent="closeDetailModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { ArchiveData } from '@/api'

import HorusSystemManagementArchiveModal from '@/components/manageSystem/archive/HorusSystemManagementArchiveModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManageArchiveView',

  components: {
    HorusConfirmDialog,
    HorusModalFrame,
    HorusSystemManagementArchiveModal
  },

  data () {
    return {
      waitingRequests: [],
      standByRequests: [],
      rejectedCount: 0,
      rejectedRequests: [],
      failedCount: 0,
      failedRequests: [],
      publishedCount: 0,
      publishedRequests: [],
      showRejectModal: false,
      rejectTarget: null,
      rejectMessage: '',
      showDetailModal: false,
      detailModalTarget: null
    }
  },

  created () {
    this.loadDatas()
  },

  methods: {
    loadDatas () {
      ArchiveData.fetchWaitingList().then(res => {
        this.waitingRequests = res.archive_datas
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データ取得失敗', '承認待ちリストの取得に失敗しました')
      })
      ArchiveData.fetchStandByList().then(res => {
        this.standByRequests = res.archive_datas
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データ取得失敗', '作成待機中リストの取得に失敗しました')
      })
      ArchiveData.fetchRejectedList({ offset: 0 }).then(res => {
        this.rejectedCount = res.count
        this.rejectedRequests = res.archive_datas
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データ取得失敗', '拒否済リストの取得に失敗しました')
      })
      ArchiveData.fetchFailedList({ offset: 0 }).then(res => {
        this.failedCount = res.count
        this.failedRequests = res.archive_datas
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データ取得失敗', '作成失敗リストの取得に失敗しました')
      })
      ArchiveData.fetchPublishedList({ offset: 0 }).then(res => {
        this.publishedCount = res.count
        this.publishedRequests = res.archive_datas
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データ取得失敗', '作成済みリストの取得に失敗しました')
      })
    },

    reloadData () {
      this.waitingRequests = []
      this.standByRequests = []
      this.rejectedCount = 0
      this.rejectedRequests = []
      this.failedCount = 0
      this.failedRequests = []
      this.publishedCount = 0
      this.publishedRequests = []
      this.loadDatas()
    },

    term (request) {
      return moment(request.term_start).format('YYYY/MM/DD') + '〜' + moment(request.term_end).format('YYYY/MM/DD')
    },

    requestAt (request) {
      return moment(request.request_at).format('YYYY/MM/DD HH:mm')
    },

    dataCreateAt (request) {
      return moment(request.data_created_at).format('YYYY/MM/DD HH:mm')
    },

    rejectedAt (request) {
      return moment(request.rejected_at).format('YYYY/MM/DD HH:mm')
    },

    rejectMessageHTML (request) {
      if (request.reject_message) {
        return request.reject_message.replace(/\n/gi, '<br />')
      } else {
        return ''
      }
    },

    buildLogHTML (request) {
      if (request.build_log) {
        return request.build_log.replace(/\n/gi, '<br />')
      } else {
        return ''
      }
    },

    publishedAt (request) {
      return moment(request.published_at).format('YYYY/MM/DD HH:mm')
    },

    dataSize (request) {
      return request.data_size
    },

    admit (request) {
      this.showConfirmDialog(
        'アーカイブ作成承認',
        'アーカイブ作成の承認を行います。\n承認されたアーカイブは、基本データ生成後の夜間に、ダウンロード可能なパッケージに加工されます。',
        DIALOG_TYPE.YES_NO,
        () => {
          ArchiveData.dataCreate({ targetID: request.id }).then(() => {
            this.loadDatas()
            this.showNotifyDialog('作成承認完了', '作成を承認して基礎データの作成を完了しました')
          }).catch(error => {
            console.error(error.response)
            this.loadDatas()
            this.showNotifyDialog('作成承認失敗', '基礎データの作成に失敗しました')
          })
        }
      )
    },

    reject (request) {
      this.rejectTarget = request
      this.rejectMessage = ''
      this.showRejectModal = true
    },

    cancelReject () {
      this.rejectTarget = null
      this.rejectMessage = ''
      this.showRejectModal = false
    },

    executeReject () {
      var formData = new FormData()
      formData.append('id', this.rejectTarget.id)
      formData.append('archive_data[reject_message]', this.rejectMessage)
      ArchiveData.reject({ formData: formData }).then(() => {
        this.rejectTarget = null
        this.rejectMessage = ''
        this.showRejectModal = false
        this.reloadData()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('作成拒否失敗', 'データ作成拒否に失敗しました')
      })
    },

    showDetail (request) {
      this.detailModalTarget = request
      this.showDetailModal = true
    },

    closeDetailModal () {
      this.detailModalTarget = null
      this.showDetailModal = false
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-manage-archive-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .controll {
    text-align: right;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-weight: bold;
    }
  }
  .archive-list {
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    border-collapse: collapse;
    background-color: #fff;
    th {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      text-align: left;
    }
    td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      &.title {
        color: #428bca;
        text-decoration: underline;
      }
      button {
        border: 1px solid #666;
        border-radius: 3px;
        font-weight: bold;
        &.admit-btn {
          border-color: #4cae4c;
          background-color: #4cae4c;
          color: #fff;
        }
        &.reject-btn {
          border-color: #b94047;
          background-color: #b94047;
          color: #fff;
        }
      }
    }
  }
  .requests-none {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  .modal-body {
    padding: 10px 20px;
    .reject-target {
      .line-content {
        display: flex;
        margin: 10px 0;
        .label {
          margin-right: 10px;
          font-weight: bold;
        }
        .contents {
          font-size: 16px;
        }
      }
    }
    .inputs {
      margin-bottom: 10px;
      textarea {
        width: 100%;
        height: 3em;
        font-size: 16px;
      }
    }
    .cancel-btn {
      background-color: #fff;
      color: #666;
      font-size: 14px;
    }
    .execute-btn {
      border-color: #b94047;
      background-color: #b94047;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
