<template>
  <div class="horus-system-manager-mail-validation-view">
    <div class="navbar">
      <div class="main-bar">
        <router-link to="/system_management_landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></router-link>
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">メールの疎通確認</h1>
      <div class="message-column">
        ナラティブブックで、あなたのシステム管理アカウントのメールアドレス変更手続きがなされました。
        このページでは、メールアドレスが実在することを確認するための作業を行います。
        下記ログインフィールドにログインIDとパスワードを入力してログインしてください。
      </div>
      <div class="signin-block">
        <form id='own_signin_form' v-on:submit.prevent="validateMail">
          <div class="signin_id_input"><label>ログインID</label><input type="text" name="login_id" id='login_id' v-model="inputLoginId"></div>
          <div class="signin_pass_input"><label>パスワード</label><input :type="passwordInputType" name="password" id="password" v-model="inputPassword"></div>
          <div class="signin_pass_toggle" @click="togglePasswordVisible">
            <horus-custom-check-box
              :active="passwordVisible"
              :enabled="true"
              :type="'color'"
              />パスワードを表示
          </div>
          <button type="submit" class="btn-success signin-btn">ログイン</button>
        </form>
      </div>
    </div>
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { SystemManager } from '@/models'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManagerMailValidationView',

  components: {
    HorusFooter,
    HorusCustomCheckBox,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      inputLoginId: '',
      inputPassword: '',
      passwordVisible: false
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  computed: {
    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    }
  },

  methods: {
    async initToken () {
      this.token = this.$attrs.token
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    validateMail () {
      var formData = new FormData()
      formData.append('token', this.token)
      formData.append('login_id', this.inputLoginId)
      formData.append('password', this.inputPassword)
      SystemManager.api().updateMail({ formData: formData }).then(() => {
        this.showConfirmDialog('メールアドレスの変更完了', 'メールアドレスの変更を完了しました。', DIALOG_TYPE.MESSAGE, () => {
          this.$router.push('/system_management/system_manager')
        })
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('メールアドレスの変更失敗', 'メールアドレスの変更に失敗しました。\nメール本文に記載されたURLが正しくコピーされていないか、疎通確認のURLが使用済みもしくは期限切れ、ログインID、パスワードが正しくない可能性があります。')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-manager-mail-validation-view {
  background-color: #eee;
  .navbar {
    .main-bar {
      background-color: #039;
      background-image: url("/static/images/system_header.png");
      .signout-btn {
        border: 1px solid #bbc8e6;
        border-radius: 3px;
        background-color: #bbc8e6;
        color: #039;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
  }
  h1.centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .message-column {
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .signin-block {
    margin: 20px auto;
    padding: 40px;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #aaa;
    text-align: center;
    div {
      &.signin_pass_toggle {
        font-size: 12px;
      }
      margin-bottom: 10px;
      label {
        display: inline-block;
        margin-right: 10px;
        width: 100px;
        text-align: right;
        font-weight: bold;
      }
      input {
        font-size: 16px;
      }
    }
    .signin-btn {
      width: 150px;
      font-weight: bold;
    }
  }
}
</style>
