import { Model } from '@vuex-orm/core'

export default class UserTaskFile extends Model {
  static entity = 'userTaskFiles'

  static fields () {
    return {
      id: this.attr(null),
      user_task_id: this.attr(null),
      filename: this.string(''),
      filenameOriginal: this.string('')
    }
  }

  get fileURL () {
    return process.env.VUE_APP_BASE_URL + '/user_task/show_file?user_task_file_id=' + this.id
  }
}
