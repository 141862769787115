<template>
  <div class="horus-album-timeline" id="albumInner">
    <!-- timeline narrow down controll -->
    <horus-narrow-down-hash-tags
      v-show="narrowDownTags.length > 0"
      :narrowDownTags="narrowDownTags"
      v-on:removeNarrowDownTagEvent="removeNarrowDownTag" />
    <horus-narrow-down-authors
      v-show="narrowDownAuthors.length > 0"
      :narrowDownAuthors="narrowDownAuthors"
      v-on:removeNarrowDownAuthorEvent="removeNarrowDownAuthor" />
    <div v-if="photoNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div class="album-block" v-if="!photoNowLoading">
      <div class="load-button-block" v-show="latestPhotosExist">
        <button type="button" class="load-btn" @click="loadLatestPhotos">以後の写真を表示する</button>
      </div>
      <div class="album-content" v-show="mediaContents.length > 0">
        <div class="date-splitter" v-if="dateDisplay">{{ dateSplitString(mediaContents[0]) }}</div>
        <template
          v-for="(mediaContent, index) in mediaContents"
          :key="'mc' + mediaContent.id + mediaContent.type"
          >
          <div>
            <div class="photo-report"
              v-if="mediaContent.type === 'photo'"
              :id="'photoreport' + mediaContent.id"
              :key="'photoreportinalbum' + mediaContent.id"
              :class="photoDirection(mediaContent.photoReport)"
              @click="showAlbumPhotoModal(mediaContent.photoReport)">
              <horus-album-photo
                v-if="mediaContent.photoReport"
                :photoObject="mediaContent.photoReport"
                :photoMinSize="photoMinimumSize"
                :photoDirection="photoDirection(mediaContent.photoReport)"
                />
            </div>
            <div class="video-content"
              v-if="mediaContent.type === 'video' && mediaContent.videoContent"
              :id="'videocontent' + mediaContent.id"
              :key="'videocontent' + mediaContent.id"
              @click="showVideoContent(mediaContent.videoContent)">
              <img :src="mediaContent.videoContent.thumbnailSrc" class="video-thumbnail" />
              <img src="/static/images/video_play.png" class="video-play-icon" />
            </div>
          </div>
          <div :key="'photoSplit' + mediaContent.id" class="date-splitter" v-if="dateDisplay && dateChange(mediaContent, mediaContents[index + 1])">{{ dateSplitString(mediaContents[index + 1]) }}</div>
        </template>
      </div>
      <div class="no-photo-message" v-show="mediaContents.length <= 0">投稿された画像はありません。</div>
      <div class="load-button-block" v-show="oldPhotosExist">
        <button type="button" class="load-btn" @click="loadOldPhotos">以前の写真を表示する</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import {
  PhotoReport,
  MillviVideoContent,
  TimelineRecord
} from '@/models'

import HorusNarrowDownHashTags from '@/components/timelineColumn/HorusNarrowDownHashTags.vue'
import HorusNarrowDownAuthors from '@/components/timelineColumn/HorusNarrowDownAuthors.vue'
import HorusAlbumPhoto from '@/components/timelineColumn/HorusAlbumPhoto.vue'
// import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusAlbumTimeline',

  components: {
    HorusNarrowDownHashTags,
    HorusNarrowDownAuthors,
    HorusAlbumPhoto,
    // HorusHashTagDisplay
  },

  props: {
    timelineOwner: Object,
    timelineTargetDate: Date,
    narrowDownTags: Array,
    narrowDownAuthors: Array,
    timelineShouldLoad: Boolean
  },

  data () {
    return {
      dateDisplay: false,
      tagDisplay: false,
      // photoReports: [],
      mediaContents: [],
      loadReserveRecords: [],
      latestPhotosExist: false,
      oldPhotosExist: false,
      photoNowLoading: true,
      windowWidth: window.innerWidth
    }
  },

  watch: {
    timelineOwner: { handler: 'loadPhotoReports' },
    timelineShouldLoad: { handler: 'loadPhotoReports', immediate: true },
    // timelineTargetDate: { handler: 'loadPhotoReports', immediate: true },
    // narrowDownTags: { handler: 'loadPhotoReports', immediate: true },
    // narrowDownAuthors: { handler: 'loadPhotoReports', immediate: true },
    loadReserveRecords: { handler: 'loadReservedRecord' }
  },

  computed: {
    currentSearchOption () {
      var options = {}
      if (this.narrowDownTags.length > 0) {
        options.hash_tags = this.narrowDownTags.map(tag => tag.id)
      }
      if (this.narrowDownAuthors.length > 0) {
        options.authors = []
        options.author_suoms = []
        this.narrowDownAuthors.forEach(author => {
          if (author.isMember) {
            options.author_suoms.push(author.rawID)
          } else {
            options.authors.push(author.rawID)
          }
        })
      }
      return options
    },

    photoMinimumSize () {
      if (this.windowWidth < 420) {
        return (((this.windowWidth - 20) / 3) - 12)
      } else {
        return 108
      }
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleResize)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    },

    async loadPhotoReports () {
      if (this.timelineOwner && this.timelineShouldLoad) {
        this.mediaContents = []
        // this.photoReports = []
        this.loadReserveRecords = []
        var options = this.currentSearchOption
        if (this.timelineTargetDate) {
          this.photoNowLoading = true
          options.limit = 60
          let res = await PhotoReport.api().fetchTermIDList({ timelineOwnerID: this.timelineOwner.id, targetDate: this.timelineTargetDate, options: options })
          this.reserveLoadPhoto(res.photoReports)
          this.dateDisplay = true
          this.photoNowLoading = false
          if (res.targetPhotoID) {
            this.$emit('scrollAlbumEvent', res.targetPhotoID)
          }
          let videoRes = await MillviVideoContent.api().fetchTermIDList({ timelineOwnerID: this.timelineOwner.id, targetDate: this.timelineTargetDate, options: options })
          this.reserveLoadVideo(videoRes.videoContents)
          // scroll to date index
          this.$emit('timelineLoadFinishEvent')
          this.updateLoadButtonStatus(this.currentSearchOption)
        } else {
          this.photoNowLoading = true
          options.limit = 60
          let res = await PhotoReport.api().fetchIDList({ timelineOwnerID: this.timelineOwner.id, options: options })
          this.reserveLoadPhoto(res.photoReports)
          this.dateDisplay = false
          this.photoNowLoading = false
          this.$emit('timelineLoadFinishEvent')
          this.updateLoadButtonStatus(this.currentSearchOption)
          let videoRes = await MillviVideoContent.api().fetchIDList({ timelineOwnerID: this.timelineOwner.id, options: options })
          this.reserveLoadVideo(videoRes.videoContents)
        }
      }
    },

    async loadLatestPhotos () {
      var options = this.currentSearchOption
      options.limit = 60
      let res = await PhotoReport.api().fetchLatestIDList({ timelineOwnerID: this.timelineOwner.id, targetPhotoID: this.latestPhotoID(), options: options })
      for(var pr of res.photoReports) {
        var photoReport = await PhotoReport.find(pr.id)
        this.mediaContents.unshift({ id: pr.id, photoReport: photoReport, type: 'photo' })
        if (!photoReport) {
          var reserve = this.loadReserveRecords.find(lrr => lrr.recordID === pr.record_id)
          if (reserve) {
            reserve.photoList.push(pr.id)
          } else {
            this.loadReserveRecords.push({recordID: pr.record_id, photoList: [pr.id]})
          }
        }
      }
      let videoRes = await MillviVideoContent.api().fetchLatestIDList({ timelineOwnerID: this.timelineOwner.id, targetVideoID: this.latestVideoID(), options: options })
      for(var vc of videoRes.videoContents) {
        var videoContent = await MillviVideoContent.find(vc.id)
        this.mediaContents.unshift({ id: vc.id, videoContent: videoContent, type: 'video' })
        if (!videoContent) {
          var reserveVideo = this.loadReserveRecords.find(lrr => lrr.recordID === vc.record_id)
          if (reserveVideo) {
            reserveVideo.videoContent = vc.id
          } else {
            this.loadReserveRecords.push({recordID: vc.record_id, videoContent: vc.id})
          }
        }
      }
      this.updateLoadButtonStatus(this.currentSearchOption)
    },

    async loadOldPhotos () {
      var options = this.currentSearchOption
      options.limit = 60
      let res = await PhotoReport.api().fetchOldIDList({ timelineOwnerID: this.timelineOwner.id, targetPhotoID: this.oldPhotoID(), options: options })
      for(var pr of res.photoReports) {
        var photoReport = await PhotoReport.find(pr.id)
        this.mediaContents.push({ id: pr.id, photoReport: photoReport, type: 'photo' })
        if (!photoReport) {
          var reserve = this.loadReserveRecords.find(lrr => lrr.recordID === pr.record_id)
          if (reserve) {
            reserve.photoList.push(pr.id)
          } else {
            this.loadReserveRecords.push({recordID: pr.record_id, photoList: [pr.id]})
          }
        }
      }
      let videoRes = await MillviVideoContent.api().fetchOldIDList({ timelineOwnerID: this.timelineOwner.id, targetVideoID: this.oldVideoID(), options: options })
      for(var vc of videoRes.videoContents) {
        var videoContent = await MillviVideoContent.find(vc.id)
        this.mediaContents.push({ id: vc.id, videoContent: videoContent, type: 'video' })
        if (!videoContent) {
          var reserveVideo = this.loadReserveRecords.find(lrr => lrr.recordID === vc.record_id)
          if (reserveVideo) {
            reserveVideo.videoContent = vc.id
          } else {
            this.loadReserveRecords.push({recordID: vc.record_id, videoContent: vc.id})
          }
        }
      }
      this.updateLoadButtonStatus(this.currentSearchOption)
    },

    latestPhotoID () {
      for (var i = 0; i < this.mediaContents.length; i++) {
        if (this.mediaContents[i].type === 'photo') {
          return this.mediaContents[i].id
        }
      }
    },

    oldPhotoID () {
      for (var i = this.mediaContents.length - 1; i >= 0; i--) {
        if (this.mediaContents[i].type === 'photo') {
          return this.mediaContents[i].id
        }
      }
    },

    latestVideoID () {
      for (var i = 0; i < this.mediaContents.length; i++) {
        if (this.mediaContents[i].type === 'video') {
          return this.mediaContents[i].id
        }
      }
    },

    oldVideoID () {
      for (var i = this.mediaContents.length - 1; i >= 0; i--) {
        if (this.mediaContents[i].type === 'video') {
          return this.mediaContents[i].id
        }
      }
    },

    reserveLoadPhoto (photoList) {
      console.log(photoList.length)
      var reserves = []
      photoList.forEach(pr => {
        var photoReport = PhotoReport.find(pr.id)
        this.mediaContents.push({ id: pr.id, photoReport: photoReport, type: 'photo' })
        // this.photoReports.push({ id: pr.id, photoReport: photoReport })
        if (!photoReport) {
          var reserve = this.loadReserveRecords.find(lrr => lrr.recordID === pr.record_id)
          if (reserve) {
            reserve.photoList.push(pr.id)
          } else {
            reserves.push({ recordID: pr.record_id, photoList: [pr.id] })
          }
        }
      })
      this.loadReserveRecords = reserves
      this.sortMediaContents()
    },

    reserveLoadVideo (videoList) {
      videoList.forEach(vc => {
        var videoContent = MillviVideoContent.find(vc.id)
        this.mediaContents.push({ id: vc.id, videoContent: videoContent, type: 'video' })
        if (!videoContent) {
          var reserve = this.loadReserveRecords.find(lrr => lrr.recordID === vc.record_id)
          if (reserve) {
            reserve.videoContent = vc.id
          } else {
            this.loadReserveRecords.push({ recordID: vc.record_id, videoContent: vc.id })
          }
        }
      })
      this.sortMediaContents()
    },

    async loadReservedRecord () {
      if (this.loadReserveRecords.length > 0) {
        var loadIDList = this.loadReserveRecords.slice(0, 10)
        var loadRecordIDList = loadIDList.map(lrr => lrr.recordID)
        await TimelineRecord.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: loadRecordIDList })
        for(var lrr of loadIDList) {
          if (lrr.photoList) {
            for(var photoID of lrr.photoList) {
              for(var mc of this.mediaContents) {
                if (mc.id === photoID && mc.type === 'photo') {
                  mc.photoReport = await PhotoReport.find(photoID)
                }
              }
            }
          }
          if (lrr.videoContent) {
            for(var mcon of this.mediaContents) {
              if (mcon.id === lrr.videoContent && mcon.type === 'video') {
                mcon.videoContent = await MillviVideoContent.find(lrr.videoContent)
              }
            }
          }
        }
        this.loadReserveRecords.splice(0, 10)
      } else {
        // sort mediaContents
        this.sortMediaContents()
      }
    },

    sortMediaContents () {
      this.mediaContents.sort((f, s) => {
        var fAt, sAt
        if (f.photoReport) {
          fAt = f.photoReport.targetAt
        } else if (f.videoContent) {
          fAt = f.videoContent.targetAt
        }
        if (s.photoReport) {
          sAt = s.photoReport.targetAt
        } else if (s.videoContent) {
          sAt = s.videoContent.targetAt
        }
        if (!fAt || !sAt) {
          return 0
        }
        if (fAt > sAt) {
          return -1
        }
        if (fAt < sAt) {
          return 1
        }
        return 0
      })
    },

    updateLoadButtonStatus (options) {
      this.latestPhotosExist = false
      this.oldPhotosExist = false
      if (this.mediaContents.length > 0) {
        console.log('latest photo id ::::: ' + this.latestPhotoID())
        PhotoReport.api().latestExist({ timelineOwnerID: this.timelineOwner.id, photoReportID: this.latestPhotoID(), options: options }).then(res => {
          console.log('latest exist.....')
          console.log(res)
          this.latestPhotosExist = res.latestExist
        })
        PhotoReport.api().oldExist({ timelineOwnerID: this.timelineOwner.id, photoReportID: this.oldPhotoID(), options: options }).then(res => {
          console.log('old exist.....')
          console.log(res)
          this.oldPhotosExist = res.oldExist
        })
      }
    },

    photoDirection (photoObject) {
      if (photoObject && photoObject.photoReport) {
        if (photoObject.photoReport.imageWidth > photoObject.photoReport.imageHeight) {
          return 'landscape'
        } else {
          return 'portrate'
        }
      }
      return ''
    },

    dateChange (currentMedia, nextMedia) {
      if (currentMedia && nextMedia && (currentMedia.photoReport || currentMedia.videoContent) && (nextMedia.photoReport || nextMedia.videoContent)) {
        var currentDay
        var nextDay
        if (currentMedia.photoReport) {
          currentDay = Number(moment(currentMedia.photoReport.targetAt).format('YYYYMMDD'))
        } else if (currentMedia.videoContent) {
          currentDay = Number(moment(currentMedia.videoContent.targetAt).format('YYYYMMDD'))
        }
        if (nextMedia.photoReport) {
          nextDay = Number(moment(nextMedia.photoReport.targetAt).format('YYYYMMDD'))
        } else if (nextMedia.videoContent) {
          nextDay = Number(moment(nextMedia.videoContent.targetAt).format('YYYYMMDD'))
        }
        if (currentDay && nextDay && nextDay < currentDay) {
          return true
        }
      }
      return false
    },

    dateSplitString (media) {
      if (media && media.photoReport) {
        return moment(media.photoReport.targetAt).format('MM月DD日')
      } else if (media && media.videoContent) {
        return moment(media.videoContent.targetAt).format('MM月DD日')
      }
      return ''
    },

    showAlbumPhotoModal (photoReport) {
      this.$emit('showPhotoEvent', photoReport)
    },

    showVideoContent (videoContent) {
      this.$emit('showVideoEvent', videoContent)
    },

    removeNarrowDownTag (tag) {
      this.$emit('removeNarrowDownTagEvent', tag)
    },

    removeNarrowDownAuthor (author) {
      this.$emit('removeNarrowDownAuthorEvent', author)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-album-timeline {
  height: 100% ;
  overflow: auto;
  .album-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px 0;
    .photo-report {
      position: relative;
      width: 106px;
      height: 106px;
      margin-bottom: 10px;
      margin-right: 10px;
      overflow: hidden;
      border: 1px solid #aaa;
      @include mediaQuery('phone') {
        width: calc(((100vw - 20px) / 3) - 12px);
        height: calc(((100vw - 20px) / 3) - 12px);
      }
    }
    .video-content {
      position: relative;
      margin-bottom: 10px;
      margin-right: 10px;
      border: 1px solid #aaa;
      .video-thumbnail {
        width: 106px;
        height: 106px;
        object-fit: cover;
        @include mediaQuery('phone') {
          width: calc(((100vw - 20px) / 3) - 12px);
          height: calc(((100vw - 20px) / 3) - 12px);
        }
      }
      .video-play-icon {
        position: absolute;
        top: 20px;
        left: 10px;
      }
    }
    .date-splitter {
      width: 100%;
      margin: 5px 0;
      padding: 5px 0;
      border-bottom: 1px solid #aaa;
    }
  }
  .no-photo-message {
    padding: 5px;
    border-bottom: 1px solid #aaa;
    color: #666;
  }
}
</style>
