<template>
  <div class="horus-public-announcement-edit-modal">
    <horus-modal-frame
      :showModalFlag="showPublicAnnouncementEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />{{ titleText }}</h1>
      </div>
      <div class="modal-body">
        <div class="labled-content">
          <div class="date-inputs">
            <div class="label">表示日時</div>
            <div class="content">{{ announcementAtStr }}</div>
            <img src="/static/images/calender_icon.png" @click="pickAnnouncementAt" />
          </div>
          <div class="content-inputs">
            <div class="label">表示内容</div>
            <textarea class="content-message" v-model="content"></textarea>
          </div>
          <div class="date-inputs">
            <div class="label">公開日時</div>
            <div class="content">{{ publicAtStr }}</div>
            <img src="/static/images/calender_icon.png" @click="pickPublicAt" />
            <button class="reset-btn" @click="resetPublicAt">公開日時を設定しない</button>
          </div>
          <div class="date-inputs">
            <div class="label">非公開日時</div>
            <div class="content">{{ closeAtStr }}</div>
            <img src="/static/images/calender_icon.png" @click="pickCloseAt" />
            <button class="reset-btn" @click="resetCloseAt">非公開日時を設定しない</button>
          </div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">{{ submitText }}</button>
        </div>
      </div>
    </horus-modal-frame>
    <horus-date-picker
      :showDatePicker="showDatePicker"
      :defaultDate="pickTargetDate"
      :timeSelection="true"
      v-on:closeEvent="pickDateCancel"
      v-on:datePickupEvent="datePicked"
      />
  </div>
</template>

<script>
import moment from 'moment'

import HorusDatePicker from '@/components/common/HorusDatePicker.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusPublicAnnouncementEditModal',

  components: {
    HorusModalFrame,
    HorusDatePicker
  },

  props: {
    sourcePublicAnnouncement: Object,
    showPublicAnnouncementEditModal: Boolean
  },

  data () {
    return {
      announcementAt: null,
      content: '',
      publicAt: null,
      closeAt: null,
      showDatePicker: false,
      pickTargetDate: new Date(),
      pickDateTarget: ''
    }
  },

  watch: {
    sourcePublicAnnouncement: { handler: 'initDatas', immediate: true }
  },

  computed: {
    announcementAtStr () {
      if (this.announcementAt) {
        return moment(this.announcementAt).format('YYYY/MM/DD')
      } else {
        return '未設定'
      }
    },

    publicAtStr () {
      if (this.publicAt) {
        return moment(this.publicAt).format('YYYY/MM/DD HH:mm')
      } else {
        return '未設定'
      }
    },

    closeAtStr () {
      if (this.closeAt) {
        return moment(this.closeAt).format('YYYY/MM/DD HH:mm')
      } else {
        return '未設定'
      }
    },

    allValid () {
      if (this.announcementAt && this.content.length > 0) {
        return true
      } else {
        return false
      }
    },

    titleText () {
      if (this.sourcePublicAnnouncement) {
        return 'お知らせ編集'
      } else {
        return '新規お知らせ作成'
      }
    },

    submitText () {
      if (this.sourcePublicAnnouncement) {
        return '更 新'
      } else {
        return '新規作成'
      }
    }
  },

  methods: {
    initDatas () {
      if (this.sourcePublicAnnouncement) {
        this.announcementAt = this.sourcePublicAnnouncement.announcementAt
        this.content = this.sourcePublicAnnouncement.content
        this.publicAt = this.sourcePublicAnnouncement.publicAt
        this.closeAt = this.sourcePublicAnnouncement.closeAt
      } else {
        this.announcementAt = new Date()
        this.content = ''
        this.publicAt = null
        this.closeAt = null
      }
    },

    pickAnnouncementAt () {
      this.pickDateTarget = 'announcement'
      this.pickTargetDate = this.announcementAt
      this.showDatePicker = true
    },

    pickPublicAt () {
      this.pickDateTarget = 'public'
      if (this.publicAt) {
        this.pickTargetDate = this.publicAt
      } else {
        this.pickTargetDate = new Date()
      }
      this.showDatePicker = true
    },

    resetPublicAt () {
      this.publicAt = null
    },

    pickCloseAt () {
      this.pickDateTarget = 'close'
      if (this.closeAt) {
        this.pickTargetDate = this.closeAt
      } else {
        this.pickTargetDate = new Date()
      }
      this.showDatePicker = true
    },

    resetCloseAt () {
      this.closeAt = null
    },

    pickDateCancel () {
      this.pickTargetDate.date = new Date()
      this.pickDateTarget = ''
      this.showDatePicker = false
    },

    datePicked (selectedDate, callback) {
      console.log(selectedDate)
      if (this.pickDateTarget === 'announcement') {
        this.announcementAt = new Date(selectedDate.getTime())
      } else if (this.pickDateTarget === 'public') {
        this.publicAt = new Date(selectedDate.getTime())
      } else if (this.pickDateTarget === 'close') {
        this.closeAt = new Date(selectedDate.getTime())
      }
      this.pickDateTarget = ''
      this.pickTargetDate = new Date()
      this.showDatePicker = false
      callback()
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    submit () {
      var formData = new FormData()
      if (this.sourcePublicAnnouncement) {
        formData.append('id', this.sourcePublicAnnouncement.id)
      }
      formData.append('public_announcement[content]', this.content)
      formData.append('public_announcement[announcement_at]', this.announcementAt)
      formData.append('public_announcement[public_at]', this.publicAt)
      formData.append('public_announcement[close_at]', this.closeAt)
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-public-announcement-edit-modal {
  .modal-body {
    padding: 10px 20px;
    .labled-content {
      .date-inputs {
        padding: 10px;
        display: flex;
        align-items: center;
        .label {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          font-weight: bold;
        }
        .content {
          width: 150px;
          margin-right: 10px;
          padding: 3px;
          border: 1px solid #aaa;
          text-align: center;
        }
        img {
          height: 30px;
          margin-right: 10px;
        }
        button {
          border: 1px solid #666;
          background-color: #666;
          color: #fff;
          font-weight: bold;
        }
      }
      .content-inputs {
        padding: 10px;
        display: flex;
        .label {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          font-weight: bold;
        }
        textarea {
          width: calc(100% - 110px);
          height: 3em;
          font-size: 16px;
        }
      }
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
</style>
