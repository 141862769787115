<template>
  <span class="horus-hash-tag" :class="sizeType">
    <img src="/static/images/tag_icon.png" />
    <span v-html="modifiedTagName"></span>
  </span>
</template>

<script>
export default {
  name: 'HorusHashTagDisplay',

  props: {
    hashTag: Object,
    sizeType: String
  },

  computed: {
    modifiedTagName () {
      const splited = this.hashTag.tagName.split('##')
      if (splited.length > 1) {
        return '<span class="hash_tag_type ' + this.tagClassName(splited[0]) + '">' + splited[0] + '</span>' + splited[1]
      } else {
        return splited[0]
      }
    }
  },

  methods: {
    tagClassName (tagName) {
      if (tagName === 'info' || tagName === 'wish' || tagName === 'med') {
        return tagName
      } else if (tagName === '本人') {
        return 'surrogated'
      } else if (tagName === '複写') {
        return 'clone'
      }
      return ''
    }

  }
}
</script>

<style lang="scss">
.horus-hash-tag {
  vertical-align: bottom;
  &.small {
    margin: 0 3px;
    font-size: 0.7em;
    img {
      margin: 0;
      width: 10px;
    }
  }
  &.middle {
    margin-right: 10px;
    padding: 3px;
    font-size: 0.9em;
    img {
      margin: 0;
      width: 10px;
    }
  }
  &.large {
    margin-left: -3px;
    margin-right: 10px;
    padding: 0;
    font-size: 1.1em;
    img {
      margin: 0;
      width: 15px;
    }
  }
  &.large-no-icon {
    margin-left: -3px;
    margin-right: 10px;
    padding: 0;
    font-size: 1.1em;
    img {
      display: none;
    }
  }
  .hash_tag_type {
    display: inline-block;
    padding: 1px 5px;
    margin-right: 2px;
    background-color: #999;
    color: #fff;
    font-size: 0.8em;
    font-weight: bold;
    &.info {
      background-color: #7b8d42;
    }
    &.wish {
      background-color: #c89932;
    }
    &.med {
      background-color: #507ea4;
    }
    &.surrogated {
      background-color: #aa4f37;
    }
    &.clone {
      background-color: #418b89;
    }
  }
}
</style>
