<template>
  <div class="horus-agent-view">
    <h1 class="centering-title">代理設定</h1>
    <div class="notify-caption">
      このページでは、あなたの代理依頼者に関する設定を行うことができます。<br/>
      代理者の権限は、代理依頼者のナラティブブック利用に際して非常に重要な設定を行うことができます。必ず事前に本人と確認した上で設定を行ってください。
      <dl>
        <dt>ログインする事ができない利用者の作成</dt>
        <dd>
          何らかの事情で自分でログインすることができない利用者のために、そのひとに代わってナラティブブックのアカウントを作成することができます。<br/>
          作成された利用者アカウントはログインの機能を持ちません。このため、その利用者の設定は、代理者としてあなたがすべて行う必要があります。<br/>
          作成された利用者は、自動的にあなたの代理依頼者となると同時に、家族に追加されます。<br/>
        </dd>
        <dt>代理依頼者の処理</dt>
        <dd>
          代理依頼者のリストから、それぞれの設定ページに遷移して設定を代行できます。<br/>
          また、代理依頼の関係を削除することもできます。ただし、ログインすることができない利用者アカウントの場合、他に代理者や設定代行施設がいない場合には、代理者をやめることはできません。<br/>
        </dd>
        <dt>代理案内の作成</dt>
        <dd>
          特定の利用者に対し、代理者として設定を代わりに行う権限を与えてもらうための案内を作成できます。<br/>
          送信された案内に、対象となる利用者が承諾した時点で、あなたにその利用者の設定を行う権限が与えられます。<br/>
        </dd>
        <dt>代理依頼申請の処理</dt>
        <dd>
          特定の利用者から、あなた宛に送信された代理依頼の申請を処理できます。<br/>
          処理の方法は、「承諾」と「拒否」です。<br/>
          承諾した場合、その時点あなたは申請を送信した利用者の代理者となり、設定を代行する権限が与えられます。<br/>
          拒否した場合、代理依頼の申請は破棄され、拒否したことが送信者に通知されます。
        </dd>
      </dl>
      ※代理者の権限と家族設定の権限は別のものです。代理者の権限では、代理依頼者のプロフィール変更、家族設定、かかりつけ設定、設定代行を行うことができますが、タイムラインの閲覧と投稿を行うことはできません。タイムラインの閲覧と投稿を行う場合は、家族設定を行う必要があります。
    </div>
    <div class="user-list-block red_titled_column">
      <h4>ログインすることができない利用者の作成</h4>
      <div class="create-user-column">
        ログインすることができない利用者のナラティブブックID(NBID)を新規作成して登録する
        <button class="create-non-signin-user-btn" @click="prepareCreateNonSigninUser">新規作成</button>
      </div>
      <h4>代理依頼者</h4>
      <div class="user-list-column" v-if="!usersNowLoading">
        <div class="block-title" @click="userListToggle">
          <img src="/static/images/open_close_triangle.png" :class="{open: userListOpen}" />
          利用者リスト<span class="caption">利用者数:{{ deputizeRelations.length }}名</span>
        </div>
        <transition
          name="accordion-block"
          @enter="accordionEnter"
          @after-enter="accordionAfterEnter"
          @leave="accordionLeave"
          @after-leave="accordionAfterLeave">
          <div class="user-list" v-show="userListOpen" v-if="deputizeRelations.length > 0">
            <horus-deputized-user-column-for-agent
              v-for="relation in deputizeRelations"
              :key="'manageOrgDeputizeUser' + relation.user_id"
              :deputizeRelation="relation"
              v-on:removeDeputizeEvent="removeDeputize"
              v-on:showAgentLogEvent="showAgentLog"
              />
          </div>
        </transition>
      </div>
      <div class="now-loading" v-if="usersNowLoading">読み込み中...</div>
    </div><!-- user-list-block -->
    <div class="sent-invitation-block red_titled_column">
      <h4>代行者案内の送信</h4>
      <div class="block-title">
        <button type="button" class="quick-qr-btn" @click="readQrForQuickDeputize">かんたんQRで代行者登録</button>
        <button type="button" class="create-invitation-by-qr-btn" @click="readQrForCreateInvitation">QRで案内状を作成</button>
        <button type="button" class="create-invitation-by-qr-btn" @click="inputQrForCreateInvitation">手入力でのQRコードで案内を作成</button>
      </div>
      <div class="block-title">
        <button type="button" class="create-invitation-btn" @click="createNewInvitation">案内の作成</button>
      </div>
      <div class="sent-invitation-list-column" v-if="!sentListNowLoading">
        <div class="block-title" @click="sentInvitationListToggle">
          <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
          案内の送信リスト<span class="caption">案内の数:{{ sentInvitations.length }}件</span>
        </div>
        <transition
          name="accordion-block"
          @enter="accordionEnter"
          @after-enter="accordionAfterEnter"
          @leave="accordionLeave"
          @after-leave="accordionAfterLeave">
          <div class="sent-invitation-list" v-show="sentListOpen" v-if="sentInvitations.length > 0">
            <horus-sent-deputize-invitation-to-client-column
              v-for="sentInvitation in sentInvitations"
              :key="'sentDeputizeInvitation' + sentInvitation.id"
              :sentInvitation="sentInvitation"
              v-on:sendMailEvent="sendMail"
              v-on:removeInvitationEvent="removeInvitation"
              />
          </div>
        </transition>
      </div><!-- sent-invitation-list-column -->
      <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
    </div><!-- sent-invitation-block -->
    <div class="receive-request-block red_titled_column">
      <h4>代理依頼申請の受信</h4>
      <div class="subtitle">利用者から代行依頼申請を受ける</div>
      <div class="receive-request-list-column" v-if="!receiveListNowLoading">
        <div class="notify-comment"></div>
        <div class="receive-request-list" v-if="receiveRequests.length > 0">
          <horus-receive-deputize-request-in-manage
            v-for="receiveRequest in receiveRequests"
            :key="'receiveDeputizeRequest' + receiveRequest.id"
            :receiveRequest="receiveRequest"
            v-on:confirmRequestEvent="confirmRequest"
            />
        </div>
      </div><!-- sent-invitation-list-column -->
      <div class="now-loading" v-if="receiveListNowLoading">読み込み中...</div>
    </div><!-- receive-request-block -->
    <horus-footer />
    <!-- create no signin user form -->
    <horus-confirm-create-non-signin-user-dialog
      :showModal="confirmCreateDialogDisplay"
      v-on:cancelCreateEvent="cancelConfirmCreate"
      v-on:admitAgreementEvent="admitCreateConfirm"
      />
    <horus-create-no-signin-user-form-modal
      :showCreateNoSigninUserFormModal="showCreateNoSigninUserFormModal"
      :modeAgent="true"
      v-on:showConfirmDialogEvent="showConfirmDialog"
      v-on:showNotifyDialgEvent="showNotifyDialog"
      v-on:userCreationFinishEvent="userCreationFinish"
      v-on:userCreationCancelEvent="userCreationCancel"
      />
    <!-- create invitation modal -->
    <horus-create-deputize-invitation-to-user-modal
      :showCreateDeputizeInvitationToUserModal="showCreateDeputizeInvitationToUserModal"
      :agent="loggedInUser"
      :deputizeRelations="deputizeRelations"
      :sentInvitations="sentInvitations"
      :receiveRequests="receiveRequests"
      :reservedNBID="invitationReservedNBID"
      v-on:cancelSendInvitationEvent="cancelSendInvitation"
      v-on:submitSendInvitationEvent="submitSendInvitation"
      />
    <!-- confirm request modal -->
    <horus-confirm-deputize-request-for-user-modal
      :showConfirmDeputizeRequestModal="showConfirmDeputizeRequestModal"
      :targetRequest="targetReceiveRequest"
      v-on:cancelConfirmEvent="cancelConfirmRequest"
      v-on:rejectRequestEvent="rejectRequest"
      v-on:admitRequestEvent="admitRequest"
      />
    <horus-agent-log-modal
      :showAgentLog="agentLogDisplay"
      :loggedInUser="loggedInUser"
      :relation="agentLogTargetRelation"
      v-on:closeModalEvent="closeAgentLog"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { Author } from '@/models'
import {
  AgentDeputizeRelation,
  DeputizingRequestToUser,
  DeputizeRequestForUser,
  DeputizedUserCreateAgreementForAgent,
  QuickAgreementCode,
  StaticIdentifyCode
} from '@/api'

import HorusDeputizedUserColumnForAgent from '@/components/agentView/HorusDeputizedUserColumnForAgent.vue'
import HorusSentDeputizeInvitationToClientColumn from '@/components/agentView/HorusSentDeputizeInvitationToClientColumn.vue'
import HorusReceiveDeputizeRequestInManage from '@/components/manageOrganization/deputize/HorusReceiveDeputizeRequestInManage.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import HorusConfirmCreateNonSigninUserDialog from '@/components/agentView/HorusConfirmCreateNonSigninUserDialog.vue'
import HorusCreateNoSigninUserFormModal from '@/components/manageOrganization/deputize/HorusCreateNoSigninUserFormModal.vue'
import HorusCreateDeputizeInvitationToUserModal from '@/components/manageOrganization/deputize/HorusCreateDeputizeInvitationToUserModal.vue'
import HorusConfirmDeputizeRequestForUserModal from '@/components/agentView/HorusConfirmDeputizeRequestForUserModal.vue'
import HorusAgentLogModal from '@/components/organizationAgent/HorusAgentLogModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as QUICK_ACTION_TYPE from '@/assets/constants/quickActionType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition],

  name: 'HorusAgentView',

  components: {
    HorusDeputizedUserColumnForAgent,
    HorusSentDeputizeInvitationToClientColumn,
    HorusReceiveDeputizeRequestInManage,
    HorusFooter,
    HorusConfirmCreateNonSigninUserDialog,
    HorusCreateNoSigninUserFormModal,
    HorusCreateDeputizeInvitationToUserModal,
    HorusConfirmDeputizeRequestForUserModal,
    HorusAgentLogModal,
    HorusConfirmDialog
  },

  props: {
    loggedInUser: Object
  },

  data () {
    return {
      usersNowLoading: true,
      userListOpen: false,
      deputizeRelations: [],
      sentListNowLoading: true,
      sentListOpen: false,
      sentInvitations: [],
      receiveListNowLoading: true,
      receiveRequests: [],
      confirmCreateDialogDisplay: false,
      showCreateNoSigninUserFormModal: false,
      showCreateDeputizeInvitationToUserModal: false,
      invitationReservedNBID: null,
      showConfirmDeputizeRequestModal: false,
      targetReceiveRequest: null,
      agentLogDisplay: false,
      agentLogTargetRelation: null
    }
  },

  watch: {
    loggedInUser: { handler: 'loadObjects', immediate: true }
  },

  computed: {},

  mounted () {
    this.loadObjects()
  },

  methods: {
    loadObjects () {
      if (this.loggedInUser) {
        this.loadDeputizeUsers()
        this.loadSentInvitations()
        this.loadReceiveRequests()
      }
    },

    async loadDeputizeUsers () {
      this.usersNowLoading = true
      await AgentDeputizeRelation.fetchDeputizeClientIDList().then(async res => {
      // await this.$store.dispatch('fetchDeputizeClientIDList').then(async res => {
        var unloadAuthors = []
        for(var client of res.deputize_clients) {
          if (!Author.find(client.author_id)) {
            if (unloadAuthors.indexOf(client.author_id) < 0) unloadAuthors.push(client.author_id)
          }
        }
        // console.log('unloadAuthors:: ' + unloadAuthors.length)
        // console.log('loggedInUser.id: ' + this.loggedInUser.id)
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ timelineOwnerID: this.loggedInUser.id, idList: unloadAuthors })
        }
        for(var deputize_client of res.deputize_clients) {
          deputize_client.author = Author.find(deputize_client.author_id)
          console.log(deputize_client)
        }
        this.deputizeRelations = res.deputize_clients
      }).catch(err => {
        console.error(err)
      })
      this.deputizeRelations.sort((f, s) => {
        if (f.author.nameKana > s.author.nameKana) {
          return 1
        } else if (f.author.nameKana < s.author.nameKana) {
          return -1
        } else {
          return 0
        }
      })
      this.usersNowLoading = false
    },

    async loadSentInvitations () {
      this.sentListNowLoading = true
      await DeputizingRequestToUser.fetch({ organizationID: null }).then(res => {
        this.sentInvitations = res.deputizing_request_to_users
      }).catch(err => {
        console.error(err)
      })
      this.sentListNowLoading = false
    },

    async loadReceiveRequests () {
      this.receiveListNowLoading = true
      await DeputizeRequestForUser.fetchReceiveRequests().then(res => {
        this.receiveRequests = res.deputize_request_for_users
      }).catch(err => {
        console.error(err)
      })
      this.receiveListNowLoading = false
    },

    prepareCreateNonSigninUser () {
      this.confirmCreateDialogDisplay = true
    },

    cancelConfirmCreate () {
      this.confirmCreateDialogDisplay = false
    },

    admitCreateConfirm () {
      DeputizedUserCreateAgreementForAgent.prepareCreateUser().then(() => {
        this.confirmCreateDialogDisplay = false
        this.createNonSigninUser()
      }).catch(err => {
        console.error(err)
        this.confirmCreateDialogDisplay = false
        this.showNotifyDialog('新規利用者登録確認の失敗', '新規にログインできない利用者を作成しようとしましたが、同意確認の登録に失敗しました。')
      })
    },

    createNonSigninUser () {
      this.showCreateNoSigninUserFormModal = true
    },

    userCreationFinish () {
      this.showNotifyDialog('新規利用者の作成完了', '新規にログイン出来ない利用者を作成しました。')
      this.loadDeputizeUsers()
      this.showCreateNoSigninUserFormModal = false
    },

    userCreationCancel () {
      this.loadDeputizeUsers()
      this.showCreateNoSigninUserFormModal = false
    },

    userListToggle () {
      this.userListOpen = !this.userListOpen
    },

    removeDeputize (relation) {
      if (relation.removable) {
        this.showConfirmDialog(
          '設定代行登録の停止',
          '設定代行登録の停止しようとしています。\n停止した場合、再度設定代行登録を行うまで、元に戻せません。\n停止してよろしいですか?',
          DIALOG_TYPE.YES_NO,
          () => { this.executeRemoveDeputize(relation) }
        )
      } else {
        this.showNotifyDialog('設定代行の停止不可', 'この利用者の設定代行は停止することが出来ません。\nログイン可能な設定を行うか、別の設定代行施設もしくは設定代行者を登録する必要があります。')
      }
    },

    executeRemoveDeputize (relation) {
      AgentDeputizeRelation.destroyClient({ clientUserID: relation.user_id }).then(() => {
        this.loadDeputizeUsers()
        this.showNotifyDialog('設定代行登録の停止', relation.author.name + 'の設定代行登録を停止しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('設定代行登録の停止失敗', '設定代行登録の停止に失敗しました。')
      })
    },

    showAgentLog (relation) {
      this.agentLogTargetRelation = relation
      this.agentLogDisplay = true
    },

    closeAgentLog () {
      this.agentLogDisplay = false
      this.agentLogTargetRelation = null
    },

    readQrForQuickDeputize () {
      this.$emit('showQrReaderEvent', 'かんたんQRで設定代行登録', this.quickQrDeputize, false)
    },

    quickQrDeputize (decodedObject) {
      console.log(JSON.stringify(decodedObject))
      if (decodedObject.decodedString) {
        QuickAgreementCode.fetch({ quickCode: decodedObject.decodedString }).then(res => {
          var order = {
            title: '代理者登録の案内',
            author: res.author,
            quickAgreementCode: decodedObject.decodedString,
            organizationID: null,
            userID: this.loggedInUser.user_id,
            callback: this.quickQrDeputizeFinished,
            actionType: QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE_AGENT
          }
          this.$emit('showQuickAgreementActionEvent', order)
        }).catch(err => {
          console.error(err)
          this.showNotifyDialog(
            'ユーザーの検証失敗',
            'ユーザーの検証に失敗しました。もう一度かんたんQRの表示、読み取りからやり直してください。'
          )
        })
      } else {
        // fail check
        this.showNotifyDialog(
          'QR読み取り失敗',
          'QRの読み取りに失敗しました。'
        )
      }
    },

    quickQrDeputizeFinished () {
      this.loadDeputizeUsers()
    },

    readQrForCreateInvitation () {
      this.$emit('showQrReaderEvent', '設定代行案内の送付先読み取り', this.createNewInvitationByQR, true)
    },

    inputQrForCreateInvitation () {
      this.$emit('showManualQrInputEvent', '設定代行案内の送付先読み取り', this.createNewInvitationByQR)
    },

    createNewInvitationByQR (decodedString) {
      console.log('createNewInvitationByQR: ' + JSON.stringify(decodedString))
      var categoryID = ''
      if (decodedString.categoryID && decodedString.categoryID > 0) {
        categoryID = decodedString.categoryID
      }
      StaticIdentifyCode.fetchUser({ categoryID: categoryID, IDCode: decodedString.decodedString }).then(res => {
        console.log(JSON.stringify(res))
        if (res.success) {
          var fail = false
          var failMessage = ''
          if (this.deputizeRelations.some(rel => rel.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーは設定代行済です'
          } else if (this.sentInvitations.some(inv => inv.login_id === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーはすでに案内済みです'
          } else if (this.receiveRequests.some(req => req.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーの利用者から申請を受けています'
          }
          if (fail) {
            this.showNotifyDialog(
              '代理者登録案内の作成不可',
              failMessage
            )
          } else {
            this.showConfirmDialog(
              '代理者登録案内の作成',
              '[' + res.author.name + ']に代理者登録案内を送信します。\nよろしいですか?',
              DIALOG_TYPE.YES_NO,
              () => {
                this.createNewInvitation(res.nbid)
              }
            )
          }
        } else {
          this.showNotifyDialog(
            'ユーザーの取得失敗',
            res.message
          )
        }
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog(
          'ユーザーの取得失敗',
          'ユーザーの取得に失敗しました。登録されていないQRコードの可能性があります'
        )
      })
    },

    createNewInvitation (reservedNBID = null) {
      // prepare negative list
      if (typeof reservedNBID === 'string') {
        this.invitationReservedNBID = reservedNBID
      }
      this.showCreateDeputizeInvitationToUserModal = true
    },

    cancelSendInvitation (callback) {
      this.showCreateDeputizeInvitationToUserModal = false
      callback()
    },

    submitSendInvitation (formData, sendTarget, callback) {
      DeputizingRequestToUser.create({ formData: formData }).then(() => {
        this.loadSentInvitations()
        this.showCreateDeputizeInvitationToUserModal = false
        this.invitationReservedNBID = null
        callback()
        this.showNotifyDialog('代理者登録案内の送信', sendTarget + '宛に代理者登録案内の送信しました。')
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('代理者登録案内の送信失敗', sendTarget + '宛の代理者登録案内の送信に失敗しました。')
        this.invitationReservedNBID = null
        callback()
      })
    },

    sentInvitationListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    sendMail (sentInvitation) {
      DeputizingRequestToUser.sendMail({ organizationID: '', targetID: sentInvitation.id }).then(() => {
        this.showNotifyDialog('代理者登録案内の再送信', '代理者登録案内を再送信しました。')
        this.loadSentInvitations()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('代理者登録案内の再送信失敗', '代理者登録案内の再送信に失敗しました。')
      })
    },

    removeInvitation (sentInvitation) {
      this.showConfirmDialog(
        '代理者登録案内の削除',
        '代理者登録案内を削除しようとしています。\n削除した場合、代理者登録案内が期限内で未使用でも、承認の操作ができなくなります。\n削除してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeRemoveInvitation(sentInvitation) }
      )
    },

    executeRemoveInvitation (sentInvitation) {
      DeputizingRequestToUser.destroy({ organizationID: null, targetID: sentInvitation.id }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('代理者登録案内の削除', '代理者登録案内を削除しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('代理者登録案内の削除失敗', '代理者登録案内の削除に失敗しました。')
      })
    },

    confirmRequest (receiveRequest) {
      this.targetReceiveRequest = receiveRequest
      this.showConfirmDeputizeRequestModal = true
    },

    cancelConfirmRequest () {
      this.showConfirmDeputizeRequestModal = false
      this.targetReceiveRequest = null
    },

    rejectRequest (receiveRequest) {
      this.showConfirmDialog(
        '代理者依頼申請の拒否',
        '代理者依頼申請の拒否しようとしています。\n拒否した結果は、送信者に通知され、あなたの受信した代理者依頼申請リストから削除されます。\nこの操作は後戻りできません。拒否してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeRejectRequest(receiveRequest) }
      )
    },

    executeRejectRequest (receiveRequest) {
      DeputizeRequestForUser.rejectByRequest({ targetID: receiveRequest.id }).then(() => {
        this.showNotifyDialog('代理者依頼申請の拒否完了', receiveRequest.user_name + 'からの代理者依頼申請を拒否しました。')
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('代理者依頼申請の拒否失敗', receiveRequest.user_name + 'からの代理者依頼申請の拒否に失敗しました。')
      }).finally(() => {
        this.loadReceiveRequests()
        this.showConfirmDeputizeRequestModal = false
      })
    },

    admitRequest (receiveRequest) {
      this.showConfirmDialog(
        '代理者依頼申請の承認',
        '代理者依頼申請の承認しようとしています。\n承認すると、あなたは申請者の代理者となり、申請者の各種設定操作を代行できるようになります。\n承認した結果は、送信者に通知され、この申請はあなたの受信依頼状リストから削除されます。\nこの操作は後戻りできません。承認してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeAdmitRequest(receiveRequest) }
      )
    },

    executeAdmitRequest (receiveRequest) {
      DeputizeRequestForUser.admitByRequest({ targetID: receiveRequest.id }).then(() => {
        this.showNotifyDialog('代理者依頼申請の承認', receiveRequest.user_name + 'からの代理者依頼申請を承認しました。')
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('代理者依頼申請の承認失敗', receiveRequest.user_name + 'からの代理者依頼申請の承認に失敗しました。')
      }).finally(() => {
        this.loadDeputizeUsers()
        this.loadReceiveRequests()
        this.showConfirmDeputizeRequestModal = false
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';
.horus-agent-view {
  height: calc(100% - 60px);
  overflow: auto;
  padding: 10px 50px 0 50px;
  background-color: #f5f5f5;
  @include mediaQuery('phone') {
    padding: 10px;
    padding-bottom: 0;
  }
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  h4 {
    margin: 10px 0;
    padding-bottom: 5px;
    color: #6c272d;
    border-bottom: 1px solid #aaa;
    font-size: 18px;
    font-weight: normal;
  }
  .notify-caption {
    padding: 10px;
    border: 1px solid #6c272d;
    dl {
      margin: 5px 0;
      padding: 5px 0;
      dt {
        margin-top: 5px;
        font-weight: bold;
      }
      dd {
        margin-left: 10px;
      }
    }
  }
  .create-non-signin-user-btn {
    margin-bottom: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .block-title {
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
    img {
      width: 13px;
      transition: transform 300ms ease;
      &.open {
        transform: rotate(90deg);
      }
    }
    .caption {
      font-size: 12px;
      font-weight: normal;
      &.notice {
        color: #c43d53;
      }
    }
    .quick-qr-btn {
      margin-bottom: 10px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
    .create-invitation-by-qr-btn {
      margin-bottom: 10px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
    .create-invitation-btn {
      margin-bottom: 10px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .organization-list {
    overflow: hidden;
  }
  .sent-request-list {
    overflow: hidden;
  }
  .receive-request-list {
    overflow: hidden;
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 300ms ease;
}
</style>
