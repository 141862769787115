<template>
  <div class="horus-area-block-operator-edit-password-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />管理団体オペレーターパスワード変更</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'パスワード'"
          :necessary="true"
          :validationState="validatePassword"
          :validationMessage="validatePasswordMessage"
          :attributeNotify="'8文字以上'">
          <input :type="passwordInputType" v-model="password" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'パスワード(確認)'"
          :necessary="true"
          :validationState="validatePasswordConf"
          :validationMessage="validatePasswordConfMessage"
          :attributeNotify="'上と同じパスワードを入力'">
          <input :type="passwordInputType" v-model="passwordConfirmation" />
        </horus-attribute-in-form>
        <div class="toggle-password-visible" @click="togglePasswordVisible">
          <horus-custom-check-box
            :active="passwordVisible"
            :enabled="true"
            :type="'color'"
            />パスワードを表示
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">変 更</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import AuthenticateInfo from '@/validateModel/AuthenticateInfo.js'

export default {
  name: 'HorusAreaBlockOperatorEditPasswordModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    showModal: Boolean
  },

  data () {
    return {
      password: '',
      passwordConfirmation: '',
      passwordVisible: false
    }
  },

  computed: {
    validatePassword () {
      if (this.password.length <= 0) {
        return 'disabled'
      } else if (AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordMessage () {
      if (this.password.length > 0 && !AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'パスワードは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePasswordConf () {
      if (this.passwordConfirmation.length <= 0) {
        return 'disabled'
      } else if (this.password.trim() === this.passwordConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordConfMessage () {
      if (this.password.length > 0 && (this.password.trim() !== this.passwordConfirmation)) {
        return '上で入力したパスワードと同じ値を入れてください。'
      } else {
        return ''
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    allValid () {
      if (this.validatePassword === 'valid' &&
        this.validatePasswordConf === 'valid'
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initDatas () {
      this.password = ''
      this.passwordConfirmation = ''
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', () => { this.initDatas() })
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('area_block_operator_authentication[password]', this.password)
        formData.append('area_block_operator_authentication[password_confirmation]', this.passwordConfirmation)
        this.$emit('submitEvent', formData, () => { this.initDatas() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-area-block-operator-edit-password-modal {
  .modal-body {
    padding: 10px 20px;
    .toggle-password-visible {
      display: flex;
      margin-left: 36px;
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
