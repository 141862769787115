import axios from './AxiosInstance'

export default {
  fetch: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'authentication_activate_invitation',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ authentication_activate_invitation: res.data.authentication_activate_invitation })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'authentication_activate_invitation',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({targetUserID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'authentication_activate_invitation',
            withCredentials: true,
            params: { target_user_id: targetUserID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  sendMail: ({targetUserID}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        var formData = new FormData()
        formData.append('target_user_id', targetUserID)
          axios.httpClient({
            ...header,
            ...{
            method: 'put',
            url: 'authentication_activate_invitation/send_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  },

  validToken: ({invitationToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'authentication_activate_invitation/validate_token',
        withCredentials: true,
        params: { token: invitationToken }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ authentication_activate_invitation: res.data.authentication_activate_invitation })
        }
      }).catch(error => { reject(error) })
    })
  },

  activate: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authentication_activate_invitation/activate',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  }
}
