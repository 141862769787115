<template>
  <div class="horus-manage-reporter-organization-area-block-relation-view">
    <h1 class="centering-title">報告施設所属管理団体設定</h1>
    <h2>報告施設一覧</h2>
    <div class="head-controll">
      <div class="data-controll">
        <button class="create-btn" @click="goOrgList">報告施設管理に戻る</button>
      </div>
    </div>
    <div v-if="organizationNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div class="organization-list" v-if="!organizationNowLoading">
      <div class="list-column"
        v-for="org in displayReporterOrganizations"
        :key="'sysOrgList' + org.id"
        >
        <div class="ara-block-column">
          <div class="area-block-selection" @click="setAreaBlock(org, 'none')">
            <horus-custom-check-box
              :active="isIndipendent(org)"
              :enabled="true"
              :type="'color'"
              />
              所属なし
          </div>
          <div class="area-block-selection" v-for="areaBlock in areaBlocks" :key="'selaborg' + org.id + '-' + areaBlock.id" @click="setAreaBlock(org, areaBlock)">
            <horus-custom-check-box
              :active="isDipendent(org, areaBlock)"
              :enabled="true"
              :type="'color'"
              />
              {{ areaBlock.name }}
          </div>
        </div>
        <div class="org-column">
          <div class="list-line">
            <div class="name">{{ org.name }}[{{ org.organizationNumber }}]</div>
            <div class="areablock">管理団体 : [{{ areaBlockName(org) }}]</div>
          </div>
          <div class="list-line">
            <div class="title">{{ org.nameKana }}</div>
            <div class="count">管理者数 : {{ org.manager_count }}</div>
            <div class="count">職員数 : {{ org.member_count }}</div>
            <div class="count">利用者数 : {{ org.relaed_user_count }}</div>
          </div>
        </div>
      </div>
    </div><!-- organization-list -->
  </div>
</template>

<script>
import {
  AreaBlock,
  ReporterOrganization
} from '@/models'

import {

} from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusManageReporterOrganizationAreaBlockRelationView',

  components: {
    HorusCustomCheckBox
  },

  props: {},

  data () {
    return {
      areaBlocks: [],
      organizationNowLoading: false,
      reporterOrganizations: [],
      displayReporterOrganizations: [],
      organizationTags: [],
      selectedTags: []
    }
  },

  mounted () {
    this.loadDatas()
  },

  methods: {
    async loadDatas () {
      await this.loadAreaBlocks()
      await this.loadReporterOrganizations()
    },

    loadAreaBlocks () {
      AreaBlock.api().fetchList().then(res => {
        this.areaBlocks = res.area_blocks
      })
    },

    loadReporterOrganizations () {
      ReporterOrganization.api().listWithSummary().then(res => {
        this.reporterOrganizations = res.reporter_organizations
        this.updateDisplay()
      })
    },

    updateDisplay () {
      // narrow down by type
      if (this.currentState === 'revokeRequested') {
        this.displayReporterOrganizations = this.reporterOrganizations.filter(org => org.ready_to_revoke && !org.deleted)
      } else if (this.currentState === 'revoked') {
        this.displayReporterOrganizations = this.reporterOrganizations.filter(org => org.deleted)
      } else {
        this.displayReporterOrganizations = this.reporterOrganizations.filter(org => !org.deleted)
      }
      this.displayReporterOrganizations = this.displayReporterOrganizations.filter(org => {
        if (this.selectedAreaBlock === null) {
          return true
        } else if (this.selectedAreaBlock === 'none') {
          if (org.area_block_id) {
            return false
          } else {
            return true
          }
        } else {
          if (org.area_block_id === this.selectedAreaBlock.id) {
            return true
          } else {
            return false
          }
        }
      })
    },

    isIndipendent (org) {
      if (!org.area_block_id) {
        return true
      }
      return false
    },

    isDipendent (org, areaBlock) {
      if (org.area_block_id === areaBlock.id) {
        return true
      }
      return false
    },

    setAreaBlock (org, areaBlock) {
      var areaBlockID = null
      if (areaBlock === 'none') {
        areaBlockID = 'none'
      } else {
        areaBlockID = areaBlock.id
      }
      ReporterOrganization.api().updateAreaBlock({ reporterOrganizationID: org.id, areaBlockID: areaBlockID }).then(res => {
        console.log(res)
        org.area_block_id = areaBlockID
      }).catch(err => {
        console.error(err)
      })
    },

    areaBlockName (org) {
      if (org.area_block_id) {
        var target = this.areaBlocks.find(areablock => areablock.id === org.area_block_id)
        console.log(target)
        if (target) {
          return target.name
        }
      }
      return '所属なし'
    },

    goOrgList () {
      this.$router.push({ path: '/system_management/reporter_organization' })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-manage-reporter-organization-area-block-relation-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .head-controll {
    display: flex;
    justify-content: space-between;
    .data-controll {
      button {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .narrow-down-title {
    margin-right: 10px;
    font-weight: bold;
  }
  .narrow-down-tags {
    display: flex;
    margin: 10px 0;
    .narrow-down {
      display: flex;
      margin-right: 20px;
    }
  }
  .now-loading {
    display: flex;
    justify-content: center;
    padding: 50px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .organization-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .ara-block-column {
        width: 250px;
        .area-block-selection {
          display: flex;
          align-items: center;
          margin: 5px 0;
        }
      }
      .org-column {
        width: calc(100% - 250px);
      }
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            font-weight: bold;
          }
          &.title {
            width: auto;
          }
          &.type {
            width: 160px;
          }
          &.duptilze {
            width: 100px;
          }
          &.tag {
            width: 100px;
          }
          &.count {
            width: 100px;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
