<template>
  <div class="horus-agent-log-modal">
    <horus-modal-frame
      :showModalFlag="showAgentLog"
      v-on:backdropClickEvent="closeModal">
      <div class="modal-header">
        <h1 v-if="relation">{{ relation.author.name }}への設定代行記録一覧</h1>
        <img src="/static/images/x_icon.png" @click="closeModal" />
      </div>
      <div class="modal-body">
        <div class="user-column" v-if="relation">
          <img :src="relation.author.portrateSrc" />
          <div class="user-info">
            <div class="name-column">
              <span class="user-name">{{ relation.author.name }}&nbsp;({{ relation.author.age }})</span>
              <span class="no-signin-notify" v-if="!relation.self_login">本人ログインできない利用者</span>
            </div>
            <div class="address-column">
              <span class="address">{{ relation.author.address }}</span>
              <span class="tel">TEL : {{ relation.author.tel }}</span>
            </div>
          </div>
        </div>
        <div class="log-column">
          <div class="log-count">{{ logCount }}件中{{ loadLogCount }}件</div>
          <div class="agent-logs" v-if="!logNowLoading && agentLogs.length > 0">
            <div class="agent-log" v-for="log in agentLogs" :key="'agentLog' + log.id">
              <div class="title">
                <span class="log-time">{{ logCreatedAt(log) }}</span>
                <span class="executer">
                  <img :src="log.author.portrateSrc" />
                  <span class="executer-name">{{ log.author.name }}</span>
                  <span class="executer-type">[{{ log.executer_type }}]</span>
                </span>
              </div>
              <div class="log-content" v-html="logContentHTML(log)"></div>
            </div>
            <div class="load-conroll" v-if="logCount > loadLogCount">
              <button class="load-btn" @click="loadAdditinalLogs">さらに読み込む</button>
            </div>
          </div>
          <div class="no-log-notify" v-if="!logNowLoading && agentLogs.length <= 0">ログはありません。</div>
          <div class="now-loading" v-if="logNowLoading">読み込み中...</div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  Author
} from '@/models'
import {
  AgentLog
} from '@/api'

import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusAgentLogModal',

  components: {
    HorusModalFrame
  },

  props: {
    showAgentLog: Boolean,
    relation: Object,
    signinMember: Object,
    loggedInUser: Object
  },

  data () {
    return {
      logNowLoading: true,
      logCount: 0,
      loadLimit: 50,
      loadOffset: 0,
      agentLogs: []
    }
  },

  computed: {
    loadLogCount () {
      return this.agentLogs.length
    }
  },

  watch: {
    relation: { handler: 'loadAgentLogs', immediate: true }
  },

  methods: {
    async loadAgentLogs () {
      if (this.relation) {
        this.logNowLoading = true
        this.loadOffset = 0
        let res = await AgentLog.fetch({ targetUserID: this.relation.user_id, limit: this.loadLimit, offset: this.loadOffset })
        console.log(res)
        this.logCount = res.count
        this.loadOffset = res.offset
        await this.loadUnloadAuthors(res.deputize_activity_logs)
        for(var log of res.deputize_activity_logs) {
          log.author = await Author.find(log.author_id)
        }
        this.agentLogs = res.deputize_activity_logs
        this.logNowLoading = false
      }
    },

    async loadUnloadAuthors (agentLogs) {
      var unloadAuthors = []
      for(var log of agentLogs) {
        if (!Author.find(log.author_id)) {
          if (unloadAuthors.indexOf(log.author_id) < 0) unloadAuthors.push(log.author_id)
        }
      }
      if (unloadAuthors.length > 0) {
        await Author.api().fetchByID({ idList: unloadAuthors })
      }
    },

    async loadAdditinalLogs () {
      let res = await AgentLog.fetch({ targetUserID: this.relation.user_id, limit: this.loadLimit, offset: this.loadOffset + this.loadLimit })
      this.logCount = res.count
      this.loadOffset = res.offset
      await this.loadUnloadAuthors(res.deputize_activity_logs)
      for(var log of res.deputize_activity_logs) {
        log.author = await Author.find(log.author_id)
      }
      this.agentLogs = this.agentLogs.concat(res.deputize_activity_logs)
    },

    logCreatedAt (log) {
      if (log.created_at) {
        return moment(log.created_at).format('YYYY/MM/DD HH:mm:SS')
      } else {
        return ''
      }
    },

    logContentHTML (log) {
      if (log.content) {
        return log.content.replace(/\n/gi, '<br />')
      } else {
        return ''
      }
    },

    closeModal () {
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-agent-log-modal {
  .modal-header {
    display: flex;
    h1 {
      flex-grow: 2;
    }
    img {
      width: 20px;
    }
  }
  .modal-body {
    .user-column {
      display: flex;
      padding: 10px;
      border-bottom: 3px solid #f8b500;
      background-color: #e8ece9;
      img {
        width: 40px;
        height: 40px;
      }
      .user-info {
        margin-left: 10px;
      }
      .user-name {
        font-weight: bold;
        font-size: 14px;
      }
      .no-signin-notify {
        margin-left: 10px;
        color: #c43d53;
      }
    }
    .log-column {
      padding: 10px 20px;
      @include mediaQuery('phone') {
        padding: 10px;
      }
    }
    .log-count {
      font-weight: bold;
      font-size: 16px;
    }
    .agent-log {
      margin: 5px 0;
      border: 1px solid #aaa;
      .title {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
        border-bottom: 1px solid #ddd;
        .log-time {
          margin-right: 10px;
        }
        .executer {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          .executer-name {
            margin-right: 5px;
            font-weight: bold;
          }
        }
      }
      .log-content {
        padding: 5px;
      }
    }
    .no-log-notify {
      border: 1px solid #aaa;
      padding: 10px;
    }
    .now-loading {
      padding: 10px;
      background-color: #ddd;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
