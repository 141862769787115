<template>
  <div class="horus-manage-critical-notification-search-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <div class="header-contents">
          <h1><img src="/static/images/danger_icon.png" class="title-icon" />重要警告検索</h1>
          <div class="close-btn">
            <img src="/static/images/x_icon.png" @click="closeModal" />
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="controll-column">
          <label>重要警告対象コード</label><input type="text" class="token-search-input" v-model="searchToken" />
          <button class="clear-btn" @click="clearSearch">検索結果のクリア</button>
          <button class="search-btn" @click="searchNotificationTarget">検索</button>
        </div>
        <div class="search-result-message" v-if="searchResultMessage && searchResultMessage.length > 0">{{ searchResultMessage }}</div>
        <div class="search-result" v-if="notification">
          <h4>検索結果対象ユーザー</h4>
          <div class="target-data">
            <div class="list-column"><span class="title">ユーザー名</span><span class="value">{{ notification.user_name }}</span></div>
            <div class="list-column"><span class="title">NBID</span><span class="value">{{ notification.user_nbid }}</span></div>
            <div class="list-column"><span class="title">状態</span><span class="value">{{ targetState }}</span></div>
            <div class="list-column"><button class="resolve-btn" @click="resolve" v-if="!notification.resolved">解決する</button></div>
          </div>
          <h4>検索結果重要警告</h4>
          <div class="list-data">
            <div class="list-data-line">
              <div class="labels"><label>警告種別</label></div>
              <div class="content">{{ notification.notification_type_str }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>解決種別</label></div>
              <div class="content">{{ notification.resolve_type_str }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>状態</label></div>
              <div class="content">{{ notificationState }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>発火日時</label></div>
              <div class="content">{{ notificationActivatedAtStr }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>タイトル</label></div>
              <div class="content">{{ notification.title }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>本文</label></div>
              <div class="content" v-html="notificationMessageHTML"></div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>対象ユーザー数</label></div>
              <div class="content">{{ notification.target_count }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>解決率</label></div>
              <div class="content">{{ notification.resolve_ratio * 100 }}％</div>
            </div>
            <div class="list-data-line" v-if="notification.areaBlock">
              <div class="labels"><label>対象管理団体</label></div>
              <div class="content">{{ notification.areaBlock.name }}</div>
            </div>
            <div class="list-data-line" v-if="notification.organizations && notification.organizations.length > 0">
              <div class="labels"><label>対象施設</label></div>
              <div class="content">対象施設: {{ notification.organizations.length }}施設</div>
            </div>
            <div class="list-data-line" v-if="notification.areaBlock || (notification.organizations && notification.organizations.length > 0)">
              <div class="labels"><label>対象ユーザー種別</label></div>
              <div class="content">{{ groupTypeName }}</div>
            </div>
          </div><!-- list-data -->
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import {
  CriticalNotification
} from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

export default {
  name: 'HorusManageCriticalNotificationSearchModal',

  components: {
    HorusModalFrame
  },

  props: {
    showModal: Boolean
  },

  data () {
    return {
      notification: null,
      searchToken: '',
      searchResultMessage: ''
    }
  },

  computed: {
    notificationState () {
      var stateStr = ''
      if (this.notificaiton) {
        if (this.notificaiton.deleted) {
          stateStr = '削除'
        } else if (this.notificaiton.activated_at) {
          stateStr = '発火中'
          if (this.notificaiton.resolve_ratio >= 1.0) {
            stateStr = '解決済み'
          }
        } else {
          stateStr = '未発火'
        }
      }
      return stateStr
    },

    notificationActivatedAtStr () {
      if (this.notification) {
        return this.dateString(this.notification.activated_at)
      }
      return ''
    },

    notificationMessageHTML () {
      if (this.notification) {
        return this.notification.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    groupTypeName () {
      if (this.notification && this.notification.group_target_type) {
        if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MANAGER) {
          return '施設管理者'
        } else if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MEMBER) {
          return '施設職員'
        }
      }
      return '-----'
    },

    targetState () {
      if (this.notification) {
        if (this.notification.resolved) {
          var targetDate = new Date(this.notification.resolved_at)
          if (targetDate) {
            return '解決:' + moment(targetDate).format('YYYY/MM/DD HH:mm')
          } else {
            return '解決:(解決日時エラー)'
          }
        } else {
          return '未解決'
        }
      } else {
        return ''
      }
    }
  },

  methods: {
    dateString (dateText) {
      // console.log(dateText)
      if (dateText) {
        var targetDate = new Date(dateText)
        console.log(targetDate)
        if (targetDate) {
          return moment(targetDate).format('YYYY/MM/DD HH:mm')
        } else {
          return '-----'
        }
      } else {
        return '-----'
      }
    },

    searchNotificationTarget () {
      if (this.searchToken.length > 0) {
        CriticalNotification.seachByTargetToken({ targetToken: this.searchToken }).then(res => {
          if (res.count > 0) {
            this.notification = res.critical_notification_target
          } else {
            this.searchResultMessage = '検索にヒットしませんでした'
          }
        }).catch(err => {
          console.error(err)
          this.searchResultMessage = '検索エラーが発生しました。'
        })
      }
    },

    resolve () {
      if (this.notification) {
        this.$emit('resolveTargetEvent', () => { this.executeResolve() })
      }
    },

    executeResolve () {
      if (this.notification) {
        CriticalNotification.resolveByManager({ targetID: this.notification.target_id }).then(res => {
          this.notification = res.critical_notification_target
          this.searchResultMessage = '対象への重要警告を解決しました'
        }).catch(err => {
          console.error(err)
        })
      }
    },

    closeModal () {
      this.clearSearch()
      this.$emit('closeModalEvent')
    },

    clearSearch () {
      this.searchToken = ''
      this.searchResultMessage = ''
      this.notification = null
    }
  }

}
</script>

<style lang="scss" scoped>
.horus-manage-critical-notification-search-modal {
  .header-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
  .modal-body {
    padding: 10px;
    .controll-column {
      margin: 10px 0;
      label {
        margin-right: 10px;
        font-weight: bold;
      }
      button {
        margin: 0 5px;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: bold;
        &.clear-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
        &.search-btn {
          border: 1px solid #666;
          background-color: #666;
          color: #fff;
        }
      }
    }
    .search-result-message {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #c53d43;
    }
    .list-data {
      margin: 10px 0;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 16px;
      .list-data-line {
        display: flex;
        padding: 5px;
        border-bottom: 1px solid #aaa;
        .labels {
          margin-right: 10px;
          width: 150px;
          text-align: right;
          font-size: 14px;
          font-weight: bold;
        }
        .content {
          padding: 0;
          background-color: inherit;
        }
        img {
          margin-left: 20px;
          height: 20px;
        }
      }
    }
    .target-data {
      display: flex;
      margin: 10px 0;
      button {
        margin: 0 5px;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: bold;
        border: 1px solid #c53d43;
        background-color: #c53d43;
        color: #fff;
      }
      .list-column {
        margin-right: 10px;
        .title {
          margin-right: 5px;
          font-weight: bold;
        }
        .value {
          margin: 0;
        }
      }
    }
  }
}
</style>
