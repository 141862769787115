<template>
  <div class="horus-create-organization-request-mail-confirm-failed">
    <h2>メールアドレスの確認</h2>
    <div class="messages">
      <h4>メールアドレスの確認失敗</h4>
      <div class="message-content">
        メール登録情報が正しくありません。リンクが間違っているか、期限が過ぎているか、使用済みのようです。<br/>
        再度URLを確認してください。URLに間違いがない場合は、案内の期限が切れている可能性があります。
      </div>
      <button type="button" class="back-to-entry-btn" @click="backToEntry">メールの登録に戻る</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusCreateOrganizationRequestMailConfirmFailed',

  methods: {
    backToEntry () {
      this.$emit('backToEntryEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-organization-request-mail-confirm-failed {
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .messages {
    padding: 20px;
    border: 1px solid #b94047;
    background-color: #fff;
    text-align: center;
    h4 {
      margin: 0;
      color: #b94047;
    }
    .message-content {
      margin: 10px 0;
    }
    .back-to-entry-btn {
      border: 1px solid #ee7800;
      border-radius: 3px;
      background-color: #ee7800;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
</style>
