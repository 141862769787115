<template>
  <div class="sub-header" :class="globalStyle">
    <div class="profile">
      <div class="main-portrait">
        <a @click="openSubHeaderMenu" v-click-outside="closeSubHeaderMenu">
          <img :src="timelineOwnerPortrateSrc">
          <ul class="timeline-personal-menu" v-show="subHeaderMenuVisible">
            <li>
              <a @click="updateTimeline" v-if="nowInTimeline">最新の情報に更新</a>
              <a @click="updateTimeline" v-if="!nowInTimeline">タイムライン</a>
            </li>
            <li><router-link :to="'/profile/' + timelineOwner.id" v-if="timelineOwner">プロフィール</router-link></li>
            <li><router-link :to="'/deleted_records/' + timelineOwner.id" v-if="timelineOwner">削除済み投稿の管理</router-link></li>
          </ul>
        </a>
      </div>
      <div class="target-person-column" v-if="timelineOwner">
        <h1>{{ timelineOwner.fullName }}さん</h1>{{ timelineOwnerProfile }}
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'HorusSubHeader',

  props: {
    timelineOwner: Object,
    globalStyle: String,
    nowInTimeline: Boolean
  },

  data () {
    return {
      subHeaderMenuVisible: false
    }
  },

  computed: {
    timelineOwnerPortrateSrc: function () {
      if (this.timelineOwner && this.timelineOwner.author) {
        return this.timelineOwner.author.portrateSrc
      }
      return ''
    },

    timelineOwnerProfile: function () {
      if (this.timelineOwner) {
        return this.timelineOwner.birthDateJp + '生 (' + this.timelineOwner.age + '才)' + this.timelineOwner.sexTypeJp
      }
      return ''
    }
  },

  methods: {
    updateTimeline () {
      this.$emit('updateTimelineEvent')
    },

    openSubHeaderMenu () {
      this.subHeaderMenuVisible = true
    },

    closeSubHeaderMenu () {
      console.log('close subhetdermenu')
      this.subHeaderMenuVisible = false
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>
.sub-header {
  display: flex;
  padding: 10px;
  height: 40px;
  border-bottom: 1px solid #ddd;
  position: relative;
  background-color: #fee5e0;
  &.member {
    background-color: #e8ece9;
  }
  .profile {
    display: flex;
    min-width: calc(100% - 84px - 8px);
    .main-portrait {
      height: 40px;
      width: 40px;
      margin-right: 10px;
      img {
        height: 40px;
        width: 40px;
      }
    }
    .target-person-column {
      height: 40px;
      vertical-align: bottom;
      font-weight: lighter;
      a {
        color: #333;
      }
      h1 {
        font-size: 1.2em;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 3px;
        line-height: 1em;
      }
    }
    .timeline-personal-menu {
      position: absolute;
      top: 40px;
      left: 10px;
      z-index: 1000;
      margin: 0;
      padding: 0;
      width: 250px;
      background-color: #fff;
      border: 1px solid #aaa;
      li {
        padding: 0;
        border-top: 1px solid #aaa;
        list-style-type: none;
        color: #666;
        text-align: left;
        &.menu-signout {
          background-color: #eee;
        }
        a {
          display: inline-block;
          padding: 10px 20px;
          width: calc(100% - 40px);
          color: #666;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
