import { Model } from '@vuex-orm/core'

export default class UserTaskPhoto extends Model {
  static entity = 'userTaskPhotos'

  static fields () {
    return {
      id: this.attr(null),
      user_task_id: this.attr(null),
      filename: this.string(''),
      imageHeight: this.number(0),
      imageWidth: this.number(0)
    }
  }

  get photoSrc () {
    return process.env.VUE_APP_BASE_URL + '/user_task/show_photo?user_task_photo_id=' + this.id
  }

  get photoThumbnailSrc () {
    return process.env.VUE_APP_BASE_URL + '/user_task/show_photo?user_task_photo_id=' + this.id
  }
}
