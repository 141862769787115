import axios from './AxiosInstance'

export default {

  prepareCreateUser: () => {
    var formData = new FormData()
    formData.append('agree', 'YES')
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputized_user_create_agreement_for_agents/prepare_create_user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  },

  creanup: () => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'deputized_user_create_agreement_for_agents/creanup',
            withCredentials: true
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
