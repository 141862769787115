<template>
  <div class="horus-medical-examination-display">
    <div class="med-ex-title">
      <img src="/static/images/vital_green.png" />バイタル
    </div>
    <div class="med-ex-single" v-show="bloodPressureExist">
      <span class="med-ex-single-title">血圧</span>
      <span class="med-ex-single-value">{{ bloodPressureJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.body_temperature_value && this.medicalExamination.body_temperature_value > 0">
      <span class="med-ex-single-title">体温</span>
      <span class="med-ex-single-value">{{ medicalExamination.body_temperature_value }}℃</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.sp_o2_value && this.medicalExamination.sp_o2_value > 0">
      <span class="med-ex-single-title">酸素飽和度</span>
      <span class="med-ex-single-value">{{ medicalExamination.sp_o2_value }}％</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.pulse_rate_value && this.medicalExamination.pulse_rate_value > 0">
      <span class="med-ex-single-title">脈拍</span>
      <span class="med-ex-single-value">{{ medicalExamination.pulse_rate_value }}回／分</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.irregular_pulse_value >= 0">
      <span class="med-ex-single-title">不整脈</span>
      <span class="med-ex-single-value">{{ irregularPulseJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.anemia_state_value >= 0">
      <span class="med-ex-single-title">貧血</span>
      <span class="med-ex-single-value">{{ anemiaStateJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.jaundice_state_value >= 0">
      <span class="med-ex-single-title">黄疸</span>
      <span class="med-ex-single-value">{{ jaundiceStateJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.oral_state_value >= 0">
      <span class="med-ex-single-title">口腔内状況</span>
      <span class="med-ex-single-value">{{ oralStateJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.respiratory_noize_value >= 0">
      <span class="med-ex-single-title">呼吸音</span>
      <span class="med-ex-single-value">{{ respiratoryNoizeJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.respiratory_rate_state_value >= 0">
      <span class="med-ex-single-title">呼吸数</span>
      <span class="med-ex-single-value">{{ respiratoryRateStateJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.hart_sound_value >= 0">
      <span class="med-ex-single-title">心音</span>
      <span class="med-ex-single-value">{{ hartSoundJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.abdominal_palpation_value >= 0">
      <span class="med-ex-single-title">腹部触診</span>
      <span class="med-ex-single-value">{{ abdominalPalpationJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.abdominal_auscultation_value >= 0">
      <span class="med-ex-single-title">腹部聴診</span>
      <span class="med-ex-single-value">{{ abdominalAusculationJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.edema_value >= 0">
      <span class="med-ex-single-title">むくみ</span>
      <span class="med-ex-single-value">{{ edemaJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.bedsore_state_value >= 0">
      <span class="med-ex-single-title">褥瘡</span>
      <span class="med-ex-single-value">{{ bedsoreJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.eating_state_value >= 0">
      <span class="med-ex-single-title">食事状況</span>
      <span class="med-ex-single-value">{{ eatingStateJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.take_medicine_state_value >= 0">
      <span class="med-ex-single-title">服薬状況</span>
      <span class="med-ex-single-value">{{ takeMedicineStateJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.urination_state_value >= 0">
      <span class="med-ex-single-title">排尿状況</span>
      <span class="med-ex-single-value">{{ urinationStateJp }}</span>
    </div>
    <div class="med-ex-single" v-show="this.medicalExamination.faces_state_value >= 0">
      <span class="med-ex-single-title">排便状況</span>
      <span class="med-ex-single-value">{{ facesStateJp }}</span>
    </div>
  </div>
</template>

<script>
const EXIST_SELECTION = ['記録なし', 'あり', 'なし']
const NORMAL_SELECTION = ['記録なし', '異常', '正常']
const PROBREM_SELECTION = ['記録なし', '問題あり', '問題なし']

export default {
  name: 'HorusMedicalExaminationDisplay',

  props: {
    medicalExamination: Object
  },

  computed: {
    bloodPressureExist () {
      if (isNaN(this.medicalExamination.blood_pressure_max_value) ||
          Number(this.medicalExamination.blood_pressure_max_value) <= 0 ||
          isNaN(this.medicalExamination.blood_pressure_min_value) ||
          Number(this.medicalExamination.blood_pressure_min_value) <= 0) {
        // console.log('blood pressure is set')
        return false
      } else {
        // console.log('blood pressure is not set')
        return true
      }
    },

    bloodPressureJp () {
      // console.log('bloodPressureJP..')
      // console.log(this.medicalExamination)
      if (Number(this.medicalExamination.blood_pressure_max_value) > 0 && Number(this.medicalExamination.blood_pressure_min_value) > 0) {
        return this.medicalExamination.blood_pressure_max_value + '／' + this.medicalExamination.blood_pressure_min_value + 'mmHg'
      }
      return ''
    },

    irregularPulseJp () {
      if (this.medicalExamination.irregular_pulse_value >= 0) {
        return EXIST_SELECTION[this.medicalExamination.irregular_pulse_value]
      }
      return ''
    },

    anemiaStateJp () {
      if (this.medicalExamination.anemia_state_value >= 0) {
        return EXIST_SELECTION[this.medicalExamination.anemia_state_value]
      }
      return ''
    },

    jaundiceStateJp () {
      if (this.medicalExamination.jaundice_state_value >= 0) {
        return EXIST_SELECTION[this.medicalExamination.jaundice_state_value]
      }
      return ''
    },

    oralStateJp () {
      if (this.medicalExamination.oral_state_value >= 0) {
        return NORMAL_SELECTION[this.medicalExamination.oral_state_value]
      }
      return ''
    },

    respiratoryNoizeJp () {
      if (this.medicalExamination.respiratory_noize_value >= 0) {
        return NORMAL_SELECTION[this.medicalExamination.respiratory_noize_value]
      }
      return ''
    },

    respiratoryRateStateJp () {
      if (this.medicalExamination.respiratory_rate_state_value >= 0) {
        return NORMAL_SELECTION[this.medicalExamination.respiratory_rate_state_value]
      }
      return ''
    },

    hartSoundJp () {
      if (this.medicalExamination.hart_sound_value >= 0) {
        return NORMAL_SELECTION[this.medicalExamination.hart_sound_value]
      }
      return ''
    },

    abdominalPalpationJp () {
      if (this.medicalExamination.abdominal_palpation_value >= 0) {
        return NORMAL_SELECTION[this.medicalExamination.abdominal_palpation_value]
      }
      return ''
    },

    abdominalAusculationJp () {
      if (this.medicalExamination.abdominal_auscultation_value >= 0) {
        return NORMAL_SELECTION[this.medicalExamination.abdominal_auscultation_value]
      }
      return ''
    },

    edemaJp () {
      if (this.medicalExamination.edema_value >= 0) {
        return EXIST_SELECTION[this.medicalExamination.edema_value]
      }
      return ''
    },

    bedsoreJp () {
      if (this.medicalExamination.bedsore_value >= 0) {
        return EXIST_SELECTION[this.medicalExamination.bedsore_value]
      }
      return ''
    },

    eatingStateJp () {
      if (this.medicalExamination.eating_state_value >= 0) {
        return PROBREM_SELECTION[this.medicalExamination.eating_state_value]
      }
      return ''
    },

    takeMedicineStateJp () {
      if (this.medicalExamination.take_medicine_state_value >= 0) {
        return PROBREM_SELECTION[this.medicalExamination.take_medicine_state_value]
      }
      return ''
    },

    urinationStateJp () {
      if (this.medicalExamination.urination_state_value >= 0) {
        return PROBREM_SELECTION[this.medicalExamination.urination_state_value]
      }
      return ''
    },

    facesStateJp () {
      if (this.medicalExamination.faces_state_value >= 0) {
        return PROBREM_SELECTION[this.medicalExamination.faces_state_value]
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-medical-examination-display {
  margin: 5px 0;
  padding: 5px;
  border: 1px solid #aaa;
}
.med-ex-title {
  font-weight: bold;
  color: #666;
  img {
    margin-right: 5px;
    margin-bottom: 3px;
    width: 15px;
    vertical-align: middle;
  }
}
.med-ex-single {
  margin: 0 20px;
  .med-ex-single-title {
    font-weight: bold;
  }
}
</style>
