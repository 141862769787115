<template>
  <div class="horus-manage-deleted-records-view">
    <horus-sub-header
      :timelineOwner="timelineOwner"
      :globalStyle="globalStyle"
      :nowInTimeline="false"
      v-on:updateTimelineEvent="moveToTimeline"
      v-if="subHeaderVisible" />
    <div class="deleted-records" :class="{subvisible: subHeaderVisible}">
      <h1 class="centering-title">削除済み投稿リスト</h1>
      <div v-if="nowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
      <div class="record-list-column"  v-if="!nowLoading">
        <div class="record-list" v-for="record in deletedRecords" :key="'delrecords' + record.id">
          <div class="timeline-record-block">
            <div class="info-head">
              <div class="editor-column">
                <div class="delete-at-column">{{ record.updateAtStr }} 削除</div>
                <span class="editor-name">{{ editorName(record) }}</span>
                <span class="editor-name-additional">{{ editorAdditionalName(record) }}</span>
              </div>
              <div class="deleted-record-controll">
                <button class="resotre-btn" @click="restore(record)">復元</button>
              </div>
            </div>
            <div class="record-header">
              <horus-record-head-info
                :timelineOwner="timelineOwner"
                :author="record.author"
                :targetAt="record.targetAt" />
              <div class="quated-record" v-if="record.quated_record_id" ><img src="/static/images/talk_icon.png" /></div>
            </div>
            <div class="record-body-box">
              <horus-record-body
                :timelineRecord="record"
                :key="'recordBody' + record.id" />
            </div>
            <div class="record-footer">
              <div class="record-tags">
                <div class="record-hashtag"  v-for="hashTag in record.hashTags" :key="hashTag.id">
                  <horus-hash-tag-display :hashTag="hashTag" :sizeType="'small'"/>
                </div>
              </div>
              <div class="record-footer-info">
                <div class="record-thanks">
                  <div class="thanks" v-if="record.thanksAuthors && record.thanksAuthors.length > 0">
                    <img src="/static/images/thanks_on.png" />
                  </div>
                </div>
                <div class="record-nudged" v-show="record.nudged">
                  <img src="/static/images/nudge_on.png" />
                </div>
                <div class="quated-records" v-if="record.quateRecordsCount">
                  {{ record.quateRecordsCount }}件の返信
                </div>
              </div><!-- record footer info end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author,
  TimelineOwner,
  TimelineRecord
} from '@/models'

import moment from 'moment'

import HorusSubHeader from '@/components/navigation/HorusSubHeader.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
import HorusRecordBody from '@/components/timelineRecord/HorusRecordBody.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageDeletedRecordsView',

  components: {
    HorusSubHeader,
    HorusHashTagDisplay,
    HorusRecordHeadInfo,
    HorusRecordBody,
    HorusConfirmDialog
  },

  props: {
    loggedInUser: Object,
    signinMember: Object,
    loggedInAuthor: Object
  },

  data () {
    return {
      timelineOwner: null,
      deletedRecords: [],
      nowLoading: true
    }
  },

  watch: {
    '$route': { handler: 'initTimelineOwner', immediate: true },
    timelineOwner: { handler: 'initObjects', immediate: true }
  },

  computed: {
    subHeaderVisible () {
      if (this.loggedInUser && this.timelineOwner && this.loggedInUser.id === this.timelineOwner.id) {
        return false
      } else {
        return true
      }
    },

    globalStyle () {
      if (this.signinMember) {
        return 'member'
      } else {
        return ''
      }
    }
  },

  methods: {
    async initTimelineOwner () {
      console.log('initTimelineOwner:: ' + this.$route.params.id)
      if (this.$route.params.id) {
        await TimelineOwner.api().fetch({ timelineOwnerID: this.$route.params.id[0] })
        var owner = TimelineOwner.find(this.$route.params.id[0])
        if (!Author.find(owner.author_id)) {
          await Author.api().fetchByID({ timelineOwnerID: owner.id, idList: [owner.author_id] })
        }
        this.timelineOwner = owner
      } else if (this.loggedInUser) {
        this.timelineOwner = this.loggedInUser
      }
    },

    initObjects () {
      if (this.timelineOwner) {
        this.loadDeletedRecords()
      }
    },

    async loadDeletedRecords () {
      this.nowLoading = true
      TimelineRecord.api().fetchDeletedRecordIDList({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
        await TimelineRecord.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: res.records })
        this.deletedRecords = TimelineRecord.findIn(res.records)
        this.nowLoading = false
      }).catch(error => {
        console.error(error)
        this.nowLoading = false
      })
    },

    editorName (record) {
      if (record.editor) {
        return record.editor.name
      }
      return '削除ユーザーの記録なし'
    },

    editorAdditionalName (record) {
      if (record.editor) {
        if (record.editor.nameAdditional) {
          return '(' + record.editor.nameAdditional + ')'
        } else if (record.editor.id === this.timelineOwner.author_id) {
          return '(本人)'
        }
      }
      return ''
    },

    restore (record) {
      this.showConfirmDialog(
        '削除済み投稿の復元',
        moment(record.targetAt).format('YYYY/MM/DD HH:mm') + 'に' + record.author.name + 'さんが投稿した書き込みを復元します。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeRestore(record)
        }
      )
    },

    executeRestore (record) {
      console.log(moment(record.targetAt).format('YYYY/MM/DD HH:mm') + '/' + record.author.name)
      TimelineRecord.api().restoreRecord({ timelineOwnerID: this.timelineOwner.id, targetRecordID: record.id }).then(() => {
        this.showNotifyDialog(
          '削除済み投稿の復元完了',
          moment(record.targetAt).format('YYYY/MM/DD HH:mm') + 'に' + record.author.name + 'さんが投稿した書き込みを復元しました。'
        )
        this.loadDeletedRecords()
      })
    },

    moveToTimeline () {
      this.$router.push('/maintimeline/' + this.timelineOwner.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-manage-deleted-records-view {
  height: calc(100% - 50px);
  overflow: auto;
  .deleted-records {
    height: calc(100% - 10px);
    padding: 10px 50px 0 50px;
    overflow: auto;
    background-color: #f5f5f5;
    &.subvisible {
      height: calc(100% - 73px);
    }
    @include mediaQuery('phone') {
      padding: 10px;
      padding-bottom: 0;
    }
    .centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    .timeline-record-block {
      margin-bottom: 10px;
      border: 1px solid #aaa;
      background-color: #fff;
      &.highlight {
        animation: highlight 6s ease-out;
      }
    }
    .info-head {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: 1px solid #aaa;
      .editor-column {
        .delete-at-column {
          margin-right: 10px;
          font-weight: bold;
        }
        display: flex;
        margin-right: 20px;
        .editor-name {
          margin-right: 10px;
        }
        .editor-name-additional {
          font-size: 0.9em;
        }
      }
      .resotre-btn {
        padding: 3px;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    .record-header {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      border-bottom: 1px solid #ddd;
    }
    .record-body-box {
      padding: 10px 5px 0 10px;
    }
    .record-footer {
      padding: 5px;
      border-top: 1px solid #aaa;
    }
  }
}
</style>
