<template>
  <div class="horus-video-modal">
    <horus-modal-frame
      :showModalFlag="showVideoModal">
      <div class="modal-header" style="padding: 0;">
        <div class="header-title">
          <div class="author-column" v-if="author">
            <horus-record-head-info
              :timelineOwner="timelineOwner"
              :author="author"
              :targetAt="targetAt"
              :displayStyle="'responsible-small'"
              v-on:modalOpenEvent="showAuthorInfo" />
          </div>
          <div class="title">
            <img src="/static/images/video_icon.png" />投稿動画
          </div>
          <div class="close-btn">
            <img src="/static/images/jump_icon.png" v-if="type === 'album'" class="jump-to-origin" @click="jumpToOrigin"/>
            <img src="/static/images/x_icon.png" @click="closeModal" />
          </div>
          <div class="tag-display" v-if="type === 'album' && hashTags.length > 0">
            <horus-hash-tag-display
              v-for="hashTag in hashTags"
              :key="'photoTag' + hashTag.id"
              :hashTag="hashTag" :sizeType="'small'"/>
          </div>
        </div>
        <div class="narrow-header-author">
          <horus-record-head-info
            :timelineOwner="timelineOwner"
            :author="author"
            :targetAt="targetAt"
            :displayStyle="'responsible-small'"
            v-on:modalOpenEvent="showAuthorInfo" />
        </div>
      </div>
      <div class="modal-body">
        <div class="video-player" :style="{ width: playerSize.width + 'px', height: playerSize.height + 'px'}" v-html="videoIframe"></div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { MillviAPI  } from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusVideoModal',

  components: {
    HorusModalFrame,
    HorusRecordHeadInfo,
    HorusHashTagDisplay
  },

  props: {
    showVideoModal: Boolean,
    type: String,
    videoContent: Object
  },

  data () {
    return {
      timelineOwner: null,
      author: null,
      hashTags: [],
      targetAt: null,
      videoIframe: '',
      windowWidth: 0,
      windowHeight: 0
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },

  watch: {
    videoContent: { handler: 'initObjects', immediate: true }
  },

  computed: {
    playerSize () {
      var playerRatio = 0.5625
      var playerWidth = 800
      var playerHeight = 450
      var modalWidth = 800
      var modalHeight = this.windowHeight - 60 - 60
      if (this.windowWidth <= 420) {
        modalWidth = this.windowWidth - 10
        modalHeight = this.windowHeight - 10 - 60
      } else if (this.windowWidth <= 1023) {
        modalWidth = this.windowWidth - 40
      }
      var modalRatio = modalHeight / modalWidth
      if (modalRatio < playerRatio) {
        // 縦基準
        playerWidth = modalHeight / playerRatio
        playerHeight = modalHeight
      } else {
        // 横基準
        playerWidth = modalWidth
        playerHeight = modalWidth * playerRatio
      }
      return { width: playerWidth, height: playerHeight }
    }
  },

  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },

    initObjects () {
      if (this.videoContent) {
        this.timelineOwner = this.videoContent.timelineRecord.timelineOwner
        this.author = this.videoContent.timelineRecord.author
        this.targetAt = this.videoContent.timelineRecord.targetAt
        this.getVideoIframe()
      } else {
        this.timelineOwner = null
        this.author = null
        this.targetAt = null
        this.videoIframe = ''
      }
    },

    getVideoIframe () {
      MillviAPI.fetchVideoTagSet({ targetUserID: this.timelineOwner.id, videoID: this.videoContent.id }).then(res => {
        this.videoIframe = res.tag
      })
    },

    showAuthorInfo (targetElm, callback) {
      this.$emit('modalOpenEvent', targetElm, callback)
    },

    jumpToOrigin () {
      this.$emit('jumpToOriginEvent', this.videoContent.timelineRecord)
    },

    closeModal () {
      this.$emit('closeVideoModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mediaQuery.scss';

.horus-video-modal {
  .horus-modal-frame {
    .modal {
      width: calc(100% - 100px);
    }
  }
  .modal-header {
    padding: 0;
    .header-title {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 5px 10px;
      height: 50px;
      &.tag-include {
        height: 70px;
      }
      @include mediaQuery('phone') {
        height: 30px;
        padding-bottom: 0;
        .author-column {
          display: none;
        }
      }
      .title {
        text-align: center;
        img {
          width: 15px;
        }
      }
      .close-btn {
        text-align: right;
        height: 30px;
        img {
          cursor: pointer;
        }
        .jump-to-origin {
          margin-right: 20px;
          vertical-align: top;
        }
      }
      .tag-display {
        width: 100%;
        height: 20px;
        text-align: right;
      }
    }
    .narrow-header-author {
      display: none;
      @include mediaQuery('phone') {
        display: block;
        padding: 0 10px 5px;
      }
    }
  }
  .modal-body {
    height: 450px;
    .video-player {
      width: 100%;
      height: 450px;
      margin: 0 auto;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
