<template>
  <div class="horus-task-notifier">
    <div class="notify-batch" v-show="notifyMeetings.length > 0">
      <a v-click-outside="closeNotifyMeeting">
        <img class="recv_call_icon" src="/static/images/recv_call_icon_red.png" @click="showNotifyMeeting" />
        <transition name="meeting-dropdown"
          @enter="accordionEnter"
          @after-enter="accordionAfterEnter"
          @leave="accordionLeave"
          @after-leave="accordionAfterLeave">
          <div v-show="showNotifyMeetingList" class="notify-list">
            <div v-for="notifyMeeting in notifyMeetings" :key="'ntmth' + notifyMeeting.id" class="notify-meeting-wrap" @click="showDetail(notifyMeeting)">
              <horus-user-task-meeting-summary-column
                :userTask="notifyMeeting"
                :signinUser="signinUser"
                :signinMember="signinMember"
                />
            </div>
          </div>
        </transition>
      </a>
    </div>
    <div class="notify-batch" v-show="notifyTasks.length > 0">
      <a v-click-outside="closeNotifyTask">
        <img class="recv_call_icon" src="/static/images/task_notify_icon.png"  @click="showNotifyTask" />
        <transition name="task-dropdown"
          @enter="accordionEnter"
          @after-enter="accordionAfterEnter"
          @leave="accordionLeave"
          @after-leave="accordionAfterLeave">
          <div v-show="showNotifyTaskList" class="notify-list">
            <div v-for="notifyTask in notifyTasks" :key="'ntuth' + notifyTask.id" class="notify-task-wrap" @click="showDetail(notifyTask)">
              <horus-user-task-summary-column
                :userTask="notifyTask"
                :signinUser="signinUser"
                :signinMember="signinMember"
                />
            </div>
          </div>
        </transition>
      </a>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

import {
  UserTask
} from '@/models'

import HorusUserTaskSummaryColumn from '@/components/userTask/HorusUserTaskSummaryColumn.vue'
import HorusUserTaskMeetingSummaryColumn from '@/components/userTask/HorusUserTaskMeetingSummaryColumn.vue'

import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

export default {
  mixins: [HorusAccordionTransition],

  name: 'HorusTaskNotifier',

  components: {
    HorusUserTaskSummaryColumn,
    HorusUserTaskMeetingSummaryColumn
  },

  props: {
    signinUser: Object,
    signinMember: Object,
    signinReporter: Object,
    author: Object,
    serverTaskShouldUpdate: Boolean,
    taskDisplayConfiguration: Object
  },

  data () {
    return {
      lastCheckedAt: null,
      notifyTasks: [],
      notifyMeetings: [],
      updatePoolingIntervalID: null,
      notifyPoolingIntervalID: null,
      updatePoolingInterval: 5 * 60 * 1000,
      notifyPoolingInterval: 10 * 1000,
      showNotifyMeetingList: false,
      showNotifyTaskList: false,
      updateExist: false
    }
  },

  mounted () {
    this.initTask()
  },

  beforeUnmount () {
    this.stopPoolingAction()
  },

  watch: {
    serverTaskShouldUpdate: { handler: 'updateServerTask' },
    taskDisplayConfiguration: { handler: 'updateTaskDisplayConf' },
    signinMember: { handler: 'initTask' }
  },

  methods: {
    updateTaskDisplayConf () {
      if (this.taskDisplayConfiguration) {
        this.updatePoolingInterval = this.taskDisplayConfiguration.update_pooling_span * 60 * 1000
        this.notifyPoolingInterval = this.taskDisplayConfiguration.notify_pooling_span * 60 * 1000
      }
    },

    async initTask () {
      // get pooling interval from config
      await UserTask.api().fetchList()
      this.lastCheckedAt = new Date()
      this.$emit('updateCheckedEvent', true)
      this.startPooingAction()
    },

    async updateServerTask () {
      if (this.serverTaskShouldUpdate) {
        await this.checkUpdate()
        this.updateNotifyList()
        this.$emit('updateCheckedEvent', this.updateExist)
      }
    },

    async checkUpdate () {
      let res = await UserTask.api().fetchUpdated({ lastCheckAt: this.lastCheckedAt })
      this.lastCheckedAt = new Date()
      if ((res.userTasks && res.userTasks.length > 0) || (res.deletedUserTasks && res.deletedUserTasks.length > 0)) {
        this.updateExist = true
      } else {
        this.updateExist = false
      }
    },

    updateNotifyList () {
      if (this.author) {
        var now = new Date()
        var upcomingLimit = new Date(now.getTime + (1000 * 60 * 10))
        var pastLimit = new Date(now.getTime() - (1000 * 60 * 10))
        if (this.taskDisplayConfiguration) {
          upcomingLimit = new Date(now.getTime() + (this.taskDisplayConfiguration.nofify_display_future_min * 60 * 1000))
          pastLimit = new Date(now.getTime() - (this.taskDisplayConfiguration.notify_display_past_min * 60 * 1000))
        }
        // console.log(upcomingLimit)
        // console.log(pastLimit)
        var tasks = UserTask.query().where((task) => {
          return task.reader_id === this.author.id && task.targetAt < upcomingLimit && task.targetAt > pastLimit
        }).orderBy('target_at').get()
        this.notifyTasks = []
        this.notifyMeetings = []
        tasks.forEach(task => {
          if (task.meetingType > 0) {
            this.notifyMeetings.push(task)
          } else {
            this.notifyTasks.push(task)
          }
        })
        // console.log('in updateNotifyList, notifyTask: ' + this.notifyTasks.length + ' notifyMeeting: ' + this.notifyMeetings.length)
      }
    },

    startPooingAction () {
      this.stopPoolingAction()
      // first try
      this.checkUpdate()
      this.updateNotifyList()
      // start pooling
      this.updatePoolingIntervalID = setInterval(() => {
        this.checkUpdate()
      }, this.updatePoolingInterval)
      this.notifyPoolingIntervalID = setInterval(() => {
        this.updateNotifyList()
      }, this.notifyPoolingInterval)
    },

    stopPoolingAction () {
      if (this.updatePoolingIntervalID) {
        clearInterval(this.updatePoolingIntervalID)
      }
      if (this.notifyPoolingIntervalID) {
        clearInterval(this.notifyPoolingIntervalID)
      }
    },

    showNotifyTask () {
      // console.log('show notify task call.')
      this.showNotifyTaskList = true
    },

    closeNotifyTask () {
      // console.log('close notify task call.')
      this.showNotifyTaskList = false
    },

    showNotifyMeeting () {
      // console.log('show notify meeting call.')
      this.showNotifyMeetingList = true
    },

    closeNotifyMeeting () {
      // console.log('close notify meeting call.')
      this.showNotifyMeetingList = false
    },

    showDetail (task) {
      this.closeNotifyTask()
      this.closeNotifyMeeting()
      this.$emit('showUserTaskDetailEvent', task)
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>
.horus-task-notifier {
  position: relative;
  display: flex;
  .notify-batch {
    margin-right: 10px;
  }
  .notify-list {
    position: absolute;
    top: 10px;
    left: 0;
    width: 300px;
    max-height: calc(100vh - 50px);
    overflow: auto;
    z-index: 1000;
  }
}
</style>
