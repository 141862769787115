import axios from './AxiosInstance'

export default {
  fetch: ({organizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'service_relation_request_for_user/list',
        params: { organization_id: organizationID, all: true },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ service_relation_request_for_users: res.data.service_relation_request_for_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'service_relation_request_for_user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({organizationID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'service_relation_request_for_user',
            withCredentials: true,
            params: { organization_id: organizationID, service_relation_request_for_user_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  sendMail: ({organizationID, targetID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('service_relation_request_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_user/send_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchReceiveRequests: ({timelineOwnerID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'service_relation_request_for_user/receive_list',
        params: { target_user_id: timelineOwnerID },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ service_relation_request_for_users: res.data.service_relation_request_for_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchReceive: ({requestToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'service_relation_request_for_user',
        params: { token: requestToken },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ organization_id: res.data.organization_id, limit_at: res.data.limit_at, message_content: res.data.message_content })
        }
      }).catch(error => { reject(error) })
    })
  },

  validateToken: ({requestToken, shortToken}) => {
    var formData = new FormData()
    formData.append('token', requestToken)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_user/validate_token',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitOnSignin: ({timelineOwnerID, targetID}) => {
    var formData = new FormData()
    formData.append('target_user_id', timelineOwnerID)
    formData.append('service_relation_request_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_user/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitWithToken: ({requestToken, shortToken, loginID, password}) => {
    var formData = new FormData()
    formData.append('token', requestToken)
    formData.append('short_token', shortToken)
    formData.append('login_id', loginID)
    formData.append('password', password)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_user/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitWithUserCreate: ({targetID}) => {
    var formData = new FormData()
    formData.append('service_relation_request_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_user/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  rejectOnSignin: ({timelineOwnerID, targetID}) => {
    var formData = new FormData()
    formData.append('target_user_id', timelineOwnerID)
    formData.append('service_relation_request_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_user/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  rejectViaMail: ({requestToken, shortToken}) => {
    var formData = new FormData()
    formData.append('token', requestToken)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_user/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
