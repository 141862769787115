<template>
  <div class="horus-system-quited-user-detail-modal">
    <horus-modal-frame
      :showModalFlag="showQuitedUserDetailtModal">
      <div class="modal-header">
        <h1>
          <img src="/static/images/pencil_icon.png" class="title-icon" />
          退会済ユーザー詳細
          <img class="close-icon" src="/static/images/x_icon.png" @click="closeModal" />
        </h1>
      </div>
      <div class="modal-body" v-if="user">
        <div class="titled-column">
          <h4>利用者情報</h4>
          <div class="flat-list">
            <div class="flat-list-line">
              <div class="labels"><label>NBID(ナラティブブックID)</label></div>
              <div class="content">{{ user.nbid }}</div>
            </div>
            <div class="flat-list-line">
              <div class="labels"><label>登録メールアドレス</label></div>
              <div class="content">{{ user.mail }}</div>
            </div>
          </div>
        </div>
        <div class="titled-column">
          <h4>
            <span>基本情報</span>
          </h4>
          <div class="flat-list">
            <div class="flat-list-line">
              <div class="labels">氏名</div>
              <div class="content">{{ user.fullName }}</div>
            </div>
            <div class="flat-list-line">
              <div class="labels">ヨミガナ</div>
              <div class="content">{{ user.fullNameKana }}</div>
            </div>
            <div class="flat-list-line">
              <div class="labels">生年月日</div>
              <div class="content">{{ user.birthDateJp }}({{ user.age }})</div>
            </div>
            <div class="flat-list-line">
              <div class="labels">性別</div>
              <div class="content">{{ user.sexTypeJp }}</div>
            </div>
            <div class="flat-list-line">
              <div class="labels">血液型</div>
              <div class="content">{{ user.blood_type }}</div>
            </div>
            <div class="flat-list-line">
              <div class="labels">ポートレート</div>
              <div class="content">
                <img :src="userPortrateSrc">
              </div>
            </div>
          </div>
        </div>
        <div class="titled-column">
          <h4>
            <span>住所等の情報</span>
          </h4>
          <div class="flat-list">
            <div class="flat-list-line"><div class="labels">郵便番号</div><div class="content">{{ user.zipCode }}</div></div>
            <div class="flat-list-line"><div class="labels">住所</div><div class="content">{{ user.address }}</div></div>
            <div class="flat-list-line"><div class="labels">電話番号</div><div class="content">{{ user.tel.length > 0 ? user.tel : '登録なし' }}</div></div>
            <div class="flat-list-line"><div class="labels">携帯電話</div><div class="content">{{ user.cellphone.length > 0 ? user.cellphone : '登録なし' }}</div></div>
            <div class="flat-list-line"><div class="labels">FAX</div><div class="content">{{ user.fax.length > 0 ? user.fax : '登録なし' }}</div></div>
          </div>
        </div>
        <div class="controlls">
          <button class="close-btn" @click="closeModal">閉じる</button>
          <button class="restore-btn" @click="restore">復帰させる</button>
          <button class="remove-phisical-btn" @click="removePhisical">物理削除する</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemQuitedUserDetailModal',

  components: {
    HorusModalFrame
  },

  props: {
    showQuitedUserDetailtModal: Boolean,
    user: Object
  },

  computed: {
    userPortrateSrc () {
      if (this.user) {
        return this.user.portrateSrc
      } else {
        return ''
      }
    }
  },

  methods: {
    restore () {
      this.$emit('restoreEvent', this.user)
    },

    removePhisical () {
      console.log('on modal')
      this.$emit('removePhisicalEvent', this.user)
    },

    closeModal () {
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-quited-user-detail-modal {
  .close-icon {
    margin-left: 30px;
  }
  .modal-body {
    padding: 10px 20px;
    .titled-column {
      h4 {
        margin: 10px 0;
        border-bottom: 1px solid #ddd;
        font-size: 16px;
        color: #1c305c;
      }
      .flat-list {
        display: flex;
        flex-wrap: wrap;
        .flat-list-line {
          display: flex;
          align-items: center;
          margin: 5px 10px;
          .labels {
            font-weight: bold;
            margin-right: 10px;
            font-size: 12px;
          }
          .content {
            font-size: 16px;
            img {
              width: 50px;
            }
          }
        }
      }
    }
    .controlls {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-top: 1px solid #ddd;
      button {
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.restore-btn {
          border-color: #4cae4c;
          background-color: #4cae4c;
          color: #fff;
        }
        &.remove-phisical-btn {
          border-color: #a94442;
          background-color: #a94442;
          color: #fff;
        }
      }
    }
  }
}
</style>
