<template>
  <div class="horus-create-organization-entry-view">
    <h2>メールアドレスの入力</h2>
    <div class="new-account-content">
      施設の新規登録に利用するメールアドレスを入力してください。<br />
      メールが受け取れるかの確認のためのメールを送信します。<br />
      次のステップは、メールの本文に書かれたURLから行います。<br />
      <div class="notify-message">
        入力するメールアドレスは、登録作業を行う方が受信できるメールアドレスを指定してください。<br />
        施設登録後に施設の管理ユーザーとしてのアカウント作成を行いますが、このときに利用するメールアドレスと重複しても問題ありません。
      </div>
      <div class="new-organization-create-forms">
        <input type="text" class="mail-address-input" v-model="mail" placeholder="利用するメールアドレス" size="25" />
        <div class="create-user-input-mail-caption">
          ※スマートフォンのメールでご登録を希望の場合は、ナラティブブックで利用する以下のドメインを指定受信設定してください。<br/>
          <span class="notify">@narrativebook.jp</span><br/>
          指定受信設定の方法は各キャリアのサイトでご確認ください。
        </div>
        <button type="button" class="send-mail-btn" @click="sendMail">次へ(確認メールを送信)</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusCreateOrganizationRequestEntry',

  props: {
    areaBlock: Object
  },

  data () {
    return {
      mail: ''
    }
  },

  methods: {
    sendMail () {
      this.$emit('sendMailEvent', this.mail)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-create-organization-entry-view {
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .new-account-content {
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
    text-align: center;
    input.mail-address-input {
      margin: 15px;
      font-size: 16px;
    }
    .create-user-input-mail-caption {
      margin: 10px auto;
      padding: 10px;
      width: 400px;
      border: 1px solid #ddd;
      text-align: left;
      font-size: 0.9em;
      @include mediaQuery('phone') {
        width: auto;
      }
      .notify {
        color: #b94047;
      }
    }
    .send-mail-btn {
      border: 1px solid #b94047;
      border-radius: 3px;
      background-color: #b94047;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
</style>
