<template>
  <div class="horus-record-post-form" :class="{member: author.isMember}" v-if="timelineOwner && author">
    <div class="reference-column" v-if="referenceInfoContent">
      <div class="title-label" v-if="referenceInfoContentType === 'wish'">
        <img src="/static/images/wish_icon.png" class="title-label-icon" />wish
        <img src="/static/images/x_dark_icon.png" class="close-icon" @click="removeReference" />
      </div>
      <div class="title-label" v-if="referenceInfoContentType === 'boadComment'">
        <img src="/static/images/info_icon.png" class="title-label-icon" />info
        <img src="/static/images/x_dark_icon.png" class="close-icon" @click="removeReference" />
      </div>
      <div class="info-content-title">
        <div class="content">{{ referenceInfoContent.title }}</div>
      </div>
      <div class="info-content-body">
        <div class="info-content-inside">
          <div class="text-content" v-html="referenceInfoContentReturnLinedContent"></div>
          <div class="photo-content" v-if="referenceInfoContent.photos.length > 0">
            <horus-inline-photo
              v-for="photo in referenceInfoContent.photos"
              :key="photo.id"
              :photoObject="photo"
              :photoMaxSize="70"
              :sizeType="'small'"
              v-on:timeliePhotoClickEvent="showInfoContentPhoto" />
          </div>
          <div class="file-content" v-if="referenceInfoContent.files.length > 0">
            <horus-inline-file
              v-for="file in referenceInfoContent.files"
              :key="file.id"
              :fileObject="file"
              :sizeType="'small'"
              v-on:timelineFileClickEvent="openInfoContentFileFile" />
          </div>
        </div>
        <div class="info-content-tags">
          <horus-hash-tag-display v-for="tag in referenceInfoContent.hashTags" :hashTag="tag" :key="tag.id" :sizeType="'small'" />
        </div>
        <div class="info-content-footer">
          <div class="create-info">作成：{{ referenceInfoContent.createdAtJp }} {{ referenceInfoContent.author.name }} {{ referenceInfoContent.author.nameAdditional }}</div>
          <div class="update-info" v-if="referenceInfoContent.editor">更新：{{ referenceInfoContent.updatedAtJp }} {{ referenceInfoContent.editor.name }} {{ referenceInfoContent.editor.nameAdditional }}</div>
        </div>
      </div>
    </div><!-- reference column end -->
    <div class="surrogated_notify" v-if="postRecord.surrogated">
      <img :src="timelineOwner.author.portrateSrc" />
      <span>{{ timelineOwner.fullName }}さん曰く</span>
    </div>
    <div class="post-form" v-show="postFormAvailable">
      <div class="quatation-record-block" v-if="quatedRecord">
        <div class="record-header">
          <div class="record-head-info">
            <img :src="quatedRecord.author.portrateSrc" class="author-portrate" />
            <div class="record-head-text">{{ quatedRecord.author.name }}さんへの返信</div>
          </div>
          <div class="record-head-controll"><img src="/static/images/x_dark_icon.png" @click="removeQuatedRecord" /></div>
        </div>
        <div class="record-body-box">
          <horus-record-body
            :timelineRecord="quatedRecord"
            :key="'recordBody' + quatedRecord.id"
            v-on:durgEntryPhotoClickEvent="showQuatedRecordDrugEntryPhoto"
            v-on:timelinePhotoClickEvent="showQuatedRecordPhoto"
            v-on:timelineFileClickEvent="openQuatedRecordFile" />
        </div>
      </div>
      <div class="alway_display">
        <div class="alway_display_left">
          <div class="surragate_toggle" v-if="surrogatable">
            <button type="button" class="surrogate_toggle_btn" :class="{'surrogated': postRecord.surrogated}" @click="postSurrogateToggle">本人曰く</button>
          </div>
          <img :src="author.portrateSrc" />
        </div>
        <div class="alway_display_right">
          <textarea placeholder="ここに書いてください" class="form-control text-comment js_target_text-comment" rows="1" ref="textCommentField" @focus="activateForm" v-model="postRecord.textComment"></textarea>
          <div class="small_controll_icons" v-show="!postFormActive">
            <div class="left_buttons">
              <label :for="photoInputID">
                <img src="/static/images/camera_icon.png" class="add-photo-small" @click="addPhotoForPost" />
              </label>
              <label :for="fileInputID">
                <img src="/static/images/document_icon.png" class="add-file-small" @click="addFileForPost" />
              </label>
              <label :for="videoInputID" v-if="videoUploadAvailable">
                <img src="/static/images/video_icon.png" class="add-video-small" @click="addVideoForPost" />
              </label>
              <label :for="audioInputID" v-if="audioUploadAvailable">
                <img src="/static/images/mic_icon.png" class="add-audio-small" @click="addAudioForPost" />
              </label>
              <img src="/static/images/tag_icon.png" class="add-tag-small" @click="selectTagForPost" />
              <img src="/static/images/notify_icon.png" class="add-push-small" @click="addPushForPost" />
              <img src="/static/images/open_lock.png" class="private-lock-small" v-if="privateControllable" @click="toPrivateForPost" />
            </div>
            <div class="right_buttons">
              <!-- when loggedInUser is member, imprement this. -->
              <img src="/static/images/vital_gray.png" class="vital-small" v-if="medicalExaminationAvailable" @click="addMedicalExamination" />
              <img src="/static/images/drug_icon.png" class="drug-small" v-if="drugEntryAvailable" @click="addDrugEntry" />
            </div>
          </div>
        </div>
      </div><!-- always display end -->
      <div class="post_controlls" v-show="postFormActive">
        <div class="private-record-notify" v-if="postRecord.private"><img src="/static/images/close_lock.png" />プライベートメンバーへの投稿です。</div>
        <horus-medical-examination-display
          v-show="medicalExaminationExist"
          :medicalExamination="medicalExamination"
          />
        <horus-drug-entry-display
          v-show="drugEntryExist"
          :drugEntry="drugEntry"
          />
        <horus-multi-photo-selector
          ref="multiPhotoSelector"
          :photos="postRecord.photos"
          :photoInputID="photoInputID"
          v-on:newPhotoPreparedEvnt="addSelectedPhoto"
          v-on:photoRemoveEvent="removePhoto"
          />
        <horus-multi-file-selector
          ref="multiFileSelector"
          :files="postRecord.files"
          :fileInputID="fileInputID"
          :displaySize="''"
          v-on:newFilePreparedEvent="addSelectedFile"
          v-on:fileRemoveEvent="removeFile"
          />
        <div class="video-input-column">
          <input type="file" :id="videoInputID" :name="videoInputID" accept="video/*" capture style="display: none;cursor: pointer;" ref="videoInput" :data-duration="videoSetting.duration" :data-size="videoSetting.size" @change="postVideoChange" />
          <div class="selected-video" v-if="postRecord.video.uploadFile">
            <img src="/static/images/video_upload_icon.png" class="selected-video-icon" />
            <img src="/static/images/red_batsu_icon.png" class="selected-video-remove-icon" @click="removeVideo" />
          </div>
        </div>
        <div class="audio-input-column">
          <input type="file" :id="audioInputID" :name="audioInputID" accept="audio/*" capture style="display: none;cursor: pointer;" ref="audioInput" :data-duration="audioSetting.duration" @change="postAudioChange" />
          <div class="selected-audio" v-if="postRecord.audio.uploadFile">
            <img src="/static/images/audio_upload_icon.png" class="selected-audio-icon" />
            <img src="/static/images/red_batsu_icon.png" class="selected-audio-remove-icon" @click="removeAudio" />
          </div>
        </div>
        <div class="add_icons js_group_post_inputs">
          <div class="left_add_icons">
            <div class="icon-button" id="add_photo_block">
              <label :for="photoInputID">
                <img src="/static/images/camera_icon.png" class="add-photo" @click="addPhotoForPost"/>
              </label>
            </div>
            <div class="icon-button" id="add_file_block">
              <label :for="fileInputID">
                <img src="/static/images/document_icon.png" class="add-file" @click="addFileForPost"/>
              </label>
            </div>
            <div class="icon-button" id="add_video_block" v-if="videoUploadAvailable">
              <label :for="videoInputID">
                <img src="/static/images/video_icon.png" class="add-video" @click="addVideoForPost"/>
              </label>
            </div>
            <div class="icon-button" id="add_audio_block" v-if="audioUploadAvailable">
              <label :for="audioInputID">
                <img src="/static/images/mic_icon.png" class="add-audio" @click="addAudioForPost"/>
              </label>
            </div>
            <div class="icon-button" id="hashtag_inputs_in_form">
              <img src="/static/images/tag_icon.png" class="add-tag" @click="selectTagForPost">
            </div>
            <div class="icon-button">
              <img src="/static/images/notify_icon.png" class="add-pushtarget" @click="addPushForPost">
            </div>
            <div class="icon-button" id="private_record">
              <img src="/static/images/open_lock.png" v-if="privateControllable && !postRecord.private" @click="toPrivateForPost" />
              <img src="/static/images/close_lock.png" v-if="privateControllable && postRecord.private" @click="toNotPrivateForPost" />
            </div>
          </div>
          <div class="right_add_icons">
            <!-- when loggedInUser is member, imprement this. -->
            <div class="icon-button">
              <img src="/static/images/vital_gray.png" class="vital-small" v-if="medicalExaminationAvailable" @click="addMedicalExamination" />
            </div>
            <div class="icon-button">
              <img src="/static/images/drug_icon.png" class="drug-small" v-if="drugEntryAvailable" @click="addDrugEntry" />
            </div>
          </div><!-- right_add_icons end -->
        </div><!-- add_icons end -->
        <div class="hash_tags">
          <div class="hash-tag"
            v-for="hashTag in selectedTags"
            :key="'postRecordSelected' + hashTag.id"
            @click="removeSelectedTag(hashTag)">
            <horus-hash-tag-display
              :hashTag="hashTag"
              :sizeType="'small'" />
            <img src="/static/images/x_dark_icon.png" class="close-icon" />
          </div>
          <div class="new-hash-tag"
            v-for="(newTag, index) in createTags"
            :key="'newCreatedTag' + index"
            @click="removeCreateTag(index)">
            <img src="/static/images/tag_icon_green.png" class="new-tag-icon" />
            <span v-html="modifiedTagName(newTag)"></span>
            <img src="/static/images/x_dark_icon.png" class="close-icon" />
          </div>
        </div><!-- selected hashtags end -->
        <div class="push-targets">
          <div class="push-target-author"
            v-for="pushAuthor in pushTargets"
            :key="'postRecord' + pushAuthor.id"
            @click="removePushTargetAuthor(pushAuthor)">
            <img :src="pushAuthor.portrateSrc" class="portrate" />
            <div class="author-name">{{ pushAuthor.name }}</div>
            <img src="/static/images/x_dark_icon.png" class="close-icon" />
          </div>
        </div><!-- push target end -->
        <div class="execute_controlls">
          <div class="left_execute_controlls">
            <button class="target-date-button" type="button" v-if="!targetDate.change" @click="setTargetDate">日時変更</button>
            <div class="target-date-display" v-else >
              <span class="date-display" @click="setTargetDate">{{ targetDateStr }}</span>
              <img src="/static/images/batsu_icon.png" @click="clearTargetDate" />
            </div>
          </div>
          <div class="right_execute_controlls">
            <div class="executable_buttons" v-if="postFormAvailable">
              <button type="button" class="record_post_clear" @click="clearPostForm">キャンセル</button>
              <button class="clone-post" :class="cloneBtnState" v-if="cloneable" @click="cloneSubmitPostForm">複写投稿</button>
              <button type="button" class="record_post_submit" @click="submitPostForm">投&nbsp;&nbsp;稿</button>
            </div>
            <div class="disable_buttons" v-else >
              <button type="button" class="execute_blocking">ロード中:送信出来ません</button>
            </div>
          </div>
        </div><!-- execute controll end -->
      </div><!-- post controll (not always display) end -->
    </div><!-- post form end -->
    <div class="post-form-not-ready" v-show="!postFormAvailable"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
  </div><!-- post controll end -->
</template>

<script>
import moment from 'moment'

import {
  RecordPrivatePermission
} from '@/api'

import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'
import HorusMultiFileSelector from '@/components/common/HorusMultiFileSelector.vue'
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
import HorusRecordBody from '@/components/timelineRecord/HorusRecordBody.vue'
import HorusMedicalExaminationDisplay from '@/components/postForm/HorusMedicalExaminationDisplay.vue'
import HorusDrugEntryDisplay from '@/components/postForm/HorusDrugEntryDisplay.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

const NON_ZERO_TYPES = ['blood_pressure_max_value', 'blood_pressure_min_value', 'body_temperature_value', 'sp_o2_value', 'pulse_rate_value']

export default {
  name: 'HorusRecordPostForm',

  components: {
    HorusMultiPhotoSelector,
    HorusMultiFileSelector,
    HorusInlinePhoto,
    HorusInlineFile,
    HorusHashTagDisplay,
    HorusRecordBody,
    HorusMedicalExaminationDisplay,
    HorusDrugEntryDisplay
  },

  props: {
    signinMember: Object,
    author: Object,
    timelineOwner: Object,
    referenceInfoContent: Object,
    referenceInfoContentType: String,
    quatedRecord: Object,
    targetDate: Object,
    selectedTags: Array,
    createTags: Array,
    pushTargets: Array,
    medicalExamination: Object,
    drugEntry: Object,
    postFormAvailable: Boolean,
    postFormActive: Boolean
  },

  data () {
    return {
      photoInputID: 'photoInput',
      fileInputID: 'fileInput',
      videoInputID: 'videoInput',
      audioInputID: 'audioInput',
      videoSetting: { duration: 10, size: '1080p/30fps' },
      audioSetting: { duration: 60 },
      postRecord: {},
      isShowButtonNative: false,
      videoUploadAvailable: false,
      audioUploadAvailable: false,
      privateAvailable: { available: false, message: 'プライベート投稿の権限がありません' },
      clonePost: false
    }
  },

  computed: {
    authorIsMember () {
      if (this.author && this.author.organization_id) {
        return true
      }
      return false
    },

    surrogatable () {
      if (this.timelineOwner && this.author && this.timelineOwner.author_id !== this.author.id) {
        return true
      }
      return false
    },

    privateControllable () {
      if (this.timelineOwner && this.author && this.timelineOwner.author_id === this.author.id) {
        return true
      }
      return false
    },

    referenceInfoContentReturnLinedContent () {
      if (this.referenceInfoContent && this.referenceInfoContent.content) {
        return this.referenceInfoContent.content.replace(/\n/gi, '<br />')
      }
      return ''
    },

    targetDateStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    targetTimeStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('HH:mm')
      }
      return ''
    },

    medicalExaminationExist () {
      if (
        this.medicalExamination.blood_pressure_max_value ||
        this.medicalExamination.blood_pressure_min_value ||
        this.medicalExamination.body_temperature_value ||
        this.medicalExamination.sp_o2_value ||
        this.medicalExamination.pulse_rate_value ||
        this.medicalExamination.irregular_pulse_value >= 0 ||
        this.medicalExamination.anemia_state_value >= 0 ||
        this.medicalExamination.jaundice_state_value >= 0 ||
        this.medicalExamination.oral_state_value >= 0 ||
        this.medicalExamination.respiratory_noize_value >= 0 ||
        this.medicalExamination.respiratory_rate_state_value >= 0 ||
        this.medicalExamination.hart_sound_value >= 0 ||
        this.medicalExamination.abdominal_palpation_value >= 0 ||
        this.medicalExamination.abdominal_auscultation_value >= 0 ||
        this.medicalExamination.edema_value >= 0 ||
        this.medicalExamination.bedsore_value >= 0 ||
        this.medicalExamination.eating_state_value >= 0 ||
        this.medicalExamination.take_medicine_state_value >= 0 ||
        this.medicalExamination.urination_state_value >= 0 ||
        this.medicalExamination.faces_state_value >= 0
      ) {
        return true
      } else {
        return false
      }
    },

    drugEntryExist () {
      if (
        (this.drugEntry.comment && this.drugEntry.comment.length > 0) ||
        this.drugEntry.photos.length > 0 ||
        this.drugEntry.storedPhotos.length > 0
      ) {
        return true
      } else {
        return false
      }
    },

    medicalExaminationAvailable () {
      if (this.signinMember && this.signinMember.organization && this.signinMember.organization.medicalExaminationAvailable) {
        return true
      } else {
        return false
      }
    },

    drugEntryAvailable () {
      if (this.signinMember && this.signinMember.organization && this.signinMember.organization.drugEntryAvailable) {
        return true
      } else {
        return false
      }
    },

    cloneable () {
      if (!this.signinMember && this.timelineOwner && this.author && this.timelineOwner.author_id !== this.author.id) {
        return true
      }
      return false
    },

    cloneBtnState () {
      if (this.postRecord.surrogated) {
        return 'disable'
      }
      return ''
    }
  },

  created () {
    this.initForm()
    this.initPostRecord()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
    this.updateUploadButtonStatus()
  },

  updated () {
    if (this.$native.status === true) {
      this.isShowButtonNative = this.$native.status
    }
    this.updateUploadButtonStatus()
  },

  watch: {
    'postRecord.textComment': { handler: 'fixTextCommentArea' },
    'quatedRecord': { handler: 'quatedRecordChange' },
    'medicalExamination': { handler: 'medExWatch', deep: true },
    'author': { handler: 'loadPrivateSetting', immediate: true }
  },

  methods: {
    initForm () {
      this.photoInputID = 'photoInput' + Math.floor(Math.random() * 10000)
      this.fileInputID = 'fileInput' + Math.floor(Math.random() * 10000)
    },

    // init main data
    initPostRecord () {
      this.postRecord = {
        surrogated: false,
        private: false,
        textComment: '',
        photos: [],
        files: [],
        video: {},
        audio: {},
        drugEntry: {}
      }
      this.clonePost = false
    },

    async loadPrivateSetting () {
      if (this.privateControllable) {
        let res = await RecordPrivatePermission.fetch({ timelineOwnerID: this.author.rawID })
        if (res.record_private_permissions.length > 0) {
          this.privateAvailable.available = true
          this.privateAvailable.message = '利用可能'
        } else {
          this.privateAvailable.available = false
          this.privateAvailable.message = 'プライベート設定の公開先がありません。\nこの投稿を見られるのはあなただけです。\n特定のメンバーに閲覧を許可するときは、 メインメニューの「プライベート設定」で公開メンバーを設定してください。'
        }
      }
    },

    updateUploadButtonStatus () {
      if (this.isShowButtonNative) {
        if (process.env.NODE_ENV === 'production') {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        } else if (process.env.NODE_ENV === 'staging') {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        } else {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        }
      } else {
        if (process.env.NODE_ENV === 'production') {
          // for production build
          this.videoUploadAvailable = false
          this.audioUploadAvailable = false
          // for production test build
          // this.videoUploadAvailable = true
          // this.audioUploadAvailable = true
        } else if (process.env.NODE_ENV === 'staging') {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        } else {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        }
      }
    },

    medExWatch () {
      console.log('med ex watch call -----------------------------------------------------------')
    },

    // visual controlls
    activateForm () {
      this.$emit('activatePostFormEvent')
    },

    fixTextCommentArea () {
      this.HorusHashTagDisplay = false
      this.$nextTick(() => {
        var textArea = this.$refs.textCommentField
        if (textArea) {
          // 一度高さをリセットしないと、scrollHeightが正しく取得出来ない。ただし、ちょっとちらついてしまう場合がある
          textArea.style.height = '26px'
          if (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = textArea.scrollHeight + 'px'
          }
        }
      })
    },

    modifiedTagName (newTag) {
      const splited = newTag.split('##')
      if (splited.length > 1) {
        return '<span class="hash_tag_type ' + splited[0] + '">' + splited[0] + '</span>' + splited[1]
      } else {
        return splited[0]
      }
    },

    quatedRecordChange () {
      if (this.quatedRecord) {
        this.activateForm()
      }
    },

    // event methods
    removeReference () {
      this.$emit('removeReferenceEvent')
    },

    removeQuatedRecord () {
      this.$emit('removeQuatedRecordEvent')
    },

    // multi photo selector controlls
    addPhotoForPost () {
      this.activateForm()
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    addSelectedPhoto (photoObj) {
      this.postRecord.photos.push(photoObj)
    },

    removePhoto (index) {
      this.postRecord.photos.splice(index, 1)
    },

    // multi file selector controlls
    addFileForPost () {
      this.activateForm()
      if (this.$native.status) {
        this.$native.event('takeFileEvent', { targetID: this.fileInputID })
      }
    },

    addSelectedFile (fileObj) {
      this.postRecord.files.push(fileObj)
    },

    removeFile (index) {
      this.postRecord.files.splice(index, 1)
    },

    addVideoForPost () {
      this.activateForm()
      if (this.$native.status) {
        this.$native.event('takeVideoEvent', { duration: this.videoSetting.duration, size: this.videoSetting.size, targetID: this.videoInputID })
      }
    },

    postVideoChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.postRecord.video = { uploadFile: selectedFiles[0], name: selectedFiles[0].name }
      }
      this.$nextTick(() => {
        this.$refs.videoInput.value = ''
      })
    },

    removeVideo () {
      this.postRecord.video = {}
    },

    addAudioForPost () {
      this.activateForm()
      if (this.$native.status) {
        // alert('add audio, native:' + JSON.stringify(this.$native))
        this.$native.event('takeAudioEvent', { duration: this.audioSetting.duration, targetID: this.audioInputID })
      }
    },

    postAudioChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.postRecord.audio = { uploadFile: selectedFiles[0], name: selectedFiles[0].name }
      }
      this.$nextTick(() => {
        this.$refs.audioInput.vaule = ''
      })
    },

    removeAudio () {
      this.postRecord.audio = {}
    },

    selectTagForPost () {
      this.activateForm()
      this.$emit('openTagSelectionModalEvent')
    },

    addPushForPost () {
      this.activateForm()
      this.$emit('openPushTargetSelectionModalEvent')
    },

    addMedicalExamination () {
      this.activateForm()
      // check surrogate
      if (this.postRecord.surrogated) {
        this.$emit('showNotifyEvent', '投稿内容の不整合', '「本人曰く」に設定されている投稿に、バイタル情報は追加できません。')
      } else {
        this.$emit('openMedicalExaminationModalEvent')
      }
    },

    addDrugEntry () {
      this.activateForm()
      // check surrogate
      if (this.postRecord.surrogated) {
        this.$emit('showNotifyEvent', '投稿内容の不整合', '「本人曰く」に設定されている投稿に、お薬情報は追加できません。')
      } else {
        this.$emit('openDrugEntryModalEvent')
      }
    },

    postSurrogateToggle () {
      this.activateForm()
      // check vital and drugInfo
      if (!this.postRecord.surrogated) {
        if (this.medicalExaminationExist) {
          this.$emit('showNotifyEvent', '投稿内容の不整合', 'バイタル情報が追加されている投稿に、「本人曰く」は設定できません。')
        } else if (this.drugEntryExist) {
          this.$emit('showNotifyEvent', '投稿内容の不整合', 'お薬情報が追加されている投稿に、「本人曰く」は設定できません。')
        } else {
          this.postRecord.surrogated = !this.postRecord.surrogated
          this.$emit('updateSurrogateTagEvent', this.postRecord.surrogated)
        }
      } else {
        this.postRecord.surrogated = !this.postRecord.surrogated
        this.$emit('updateSurrogateTagEvent', this.postRecord.surrogated)
      }
    },

    // toggleColne () {
    //   if (this.cloneable && !this.postRecord.surrogated) {
    //     this.clonePost = !this.clonePost
    //   } else {
    //     this.clonePost = false
    //   }
    // },

    toPrivateForPost () {
      this.activateForm()
      if (this.privateAvailable.available) {
        this.postRecord.private = true
      } else {
        // show dialog
        this.$emit('showNotifyEvent', 'プライベート設定失敗', this.privateAvailable.message)
        this.postRecord.private = true
      }
    },

    toNotPrivateForPost () {
      this.postRecord.private = false
    },

    removeSelectedTag (tag) {
      this.$emit('removeSelectedTagEvent', tag)
    },

    removeCreateTag (index) {
      this.$emit('removeCreateTagEvent', index)
    },

    removePushTargetAuthor (author) {
      this.$emit('removeSelectedAuthorEvent', author)
    },

    setTargetDate () {
      this.$emit('setTargetDateForPostEvent')
    },

    clearTargetDate () {
      this.$emit('clearTargetDateForPostEvent')
    },

    clearPostForm () {
      this.$emit('postformClearEvent', this.executeClearForm)
    },

    executeClearForm () {
      this.initPostRecord()
    },

    cloneSubmitPostForm () {
      if (this.cloneable && !this.postRecord.surrogated) {
        this.$emit(
          'showConfirmEvent',
          '複写投稿の実行',
          'この投稿のコピーを、あなた自身のタイムラインに投稿します。通知、タグはコピーされません。\n実行する場合は「はい」を押してください。',
          DIALOG_TYPE.YES_NO,
          () => {
            this.clonePost = true
            this.submitPostForm()
          }
        )
      }
    },

    submitPostForm () {
      console.log('submitPostForm!!!')
      // CHECK FORM DATA
      // empty content is failed
      // console.log(Object.entries(this.medicalExamination))
      // console.log('textComment: ' + (!this.postRecord.textComment || this.postRecord.textComment.length === 0))
      // console.log('photos: ' + (this.postRecord.photos.length === 0))
      // console.log('files: ' + (this.postRecord.files.length === 0))
      // console.log('medEx: ' + !this.medicalExaminationExist)
      // console.log('drugEntryComment: ' + (this.drugEntry.comment.length === 0))
      // console.log('drugEntryPhoto: ' + (this.drugEntry.photos.length === 0))
      // console.log('total: ' + (
      //   (!this.postRecord.textComment || this.postRecord.textComment.length === 0) &&
      //   (this.postRecord.photos.length === 0) &&
      //   (this.postRecord.files.length === 0) &&
      //   !this.medicalExaminationExist &&
      //   (this.drugEntry.comment.length === 0) && (this.drugEntry.photos.length === 0)
      // ))
      if (
        (!this.postRecord.textComment || this.postRecord.textComment.length === 0) &&
        this.postRecord.photos.length === 0 &&
        this.postRecord.files.length === 0 &&
        !this.postRecord.video.uploadFile &&
        !this.postRecord.audio.uploadFile &&
        !this.medicalExaminationExist &&
        this.drugEntry.comment.length === 0 &&
        this.drugEntry.photos.length === 0
      ) {
        console.log('post contents is empty!')
        this.$emit('showNotifyEvent', '投稿内容のエラー', '投稿しようとする書き込みに、内容がありません。\n文章、写真、添付ファイルなどの何らかの内容を入力してください。')
      } else {
        // CREATE FORM DATA
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        formData.append('record[private]', this.postRecord.private)
        if (this.postRecord.surrogated) {
          formData.append('record[surrogate]', this.postRecord.surrogated)
        }
        if (this.quatedRecord) {
          formData.append('record[quated_record_id]', this.quatedRecord.id)
        }
        if (this.targetDate.change) {
          formData.append('record[nb_calendar_target_date]', moment(this.targetDate.date).format('YYYY/MM/DD'))
          formData.append('record[nb_calendar_target_time]', moment(this.targetDate.date).format('HH:mm'))
        }
        formData.append('record[text_comment]', this.postRecord.textComment)
        this.postRecord.photos.forEach(photo => {
          formData.append('record[photos][]', photo.uploadFile, photo.name)
        })
        this.postRecord.files.forEach(file => {
          formData.append('record[files][]', file.uploadFile, file.name)
        })
        Object.entries(this.medicalExamination).forEach(entry => {
          if (NON_ZERO_TYPES.some(name => name === entry[0])) {
            if (!isNaN(entry[1]) && entry[1] > 0) {
              formData.append('record[' + entry[0] + ']', entry[1])
            }
          } else {
            if (!isNaN(entry[1]) && entry[1] >= 0) {
              formData.append('record[' + entry[0] + ']', entry[1])
            }
          }
        })
        if (this.postRecord.video.uploadFile) {
          formData.append('record[video]', this.postRecord.video.uploadFile, this.postRecord.video.name)
        }
        if (this.postRecord.audio.uploadFile) {
          formData.append('record[audio]', this.postRecord.audio.uploadFile, this.postRecord.audio.name)
        }
        if (this.drugEntry) {
          if (this.drugEntry.comment) {
            formData.append('record[drug_entry_comment]', this.drugEntry.comment)
          }
          if (this.drugEntry.photos) {
            this.drugEntry.photos.forEach(dPhoto => {
              formData.append('record[drug_entry_photos][]', dPhoto.uploadFile, dPhoto.name)
            })
          }
        }
        this.selectedTags.forEach(tag => {
          formData.append('record[hashtags][]', tag.id)
        })
        this.createTags.forEach(newtag => {
          formData.append('record[new_hashtags][]', newtag)
        })
        this.pushTargets.forEach(target => {
          if (target.isMember) {
            formData.append('record[pushsuoms][]', target.rawID)
          } else {
            formData.append('record[pushtargets][]', target.rawID)
          }
        })
        if (this.cloneable && !this.postRecord.surrogated && this.clonePost) {
          formData.append('record[clone]', true)
        }
        // send form data
        this.$emit('submitPostFormEvent', formData, () => {
          this.initPostRecord()
        })
      }
    },

    // pass theough methods
    showInfoContentPhoto (photo) {
      var photoIndex = this.referenceInfoContent.photos.indexOf(photo)
      this.$emit('showInfoContentPhotoEvent', this.referenceInfoContent, this.type, photoIndex)
    },

    openInfoContentFileFile (file) {
      this.$emit('timelineFileClickEvent', file)
    },

    showQuatedRecordPhoto (timelineRecord, firstShowIndex) {
      this.$emit('showRecorPhotoEvent', timelineRecord, firstShowIndex)
    },

    openQuatedRecordFile (fileReport) {
      this.$emit('openRecordFileEvent', fileReport)
    },

    showQuatedRecordDrugEntryPhoto (timelineRecord, firstShowIndex) {
      this.$emit('showDrugEntryPhotoEvent', timelineRecord, firstShowIndex)
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-record-post-form {
  &.member {
    .post-form {
      border: 1px solid #2b4733;
      background-color: #f8fcf9;
    }
    .post-form-not-ready {
      border: 1px solid #2b4733;
      background-color: #f8fcf9;
    }
  }
  .surrogated_notify {
    padding: 5px;
    background-color: #692220;
    color: #fff;
    font-weight: bold;
    text-align: center;
    img {
      margin-right: 5px;
      vertical-align: middle;
      width: 20px;
      height: 20px;
    }
  }
  .post-form {
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #692220;
    background-color: #fff9f5;
  }
  .post-form-not-ready {
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #692220;
    background-color: #fff9f5;
    color: #999;
    text-align: center;
    .progress {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }
}
.reference-column {
  margin: 0;
  margin-bottom: 10px;
  padding: 10px;
  padding-top: 5px;
  border: 5px solid #ede4cd;
  background-color: #fff;
  .title-label {
    text-align: center;
    margin-bottom: 5px;
    .title-label-icon {
      width: 10px;
      margin-right: 3px;
    }
    .close-icon {
      cursor: pointer;
      width: 20px;
      float: right;
      opacity: 0.5;
    }
  }
  .info-content-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0;
    padding-bottom: 3px;
    border-bottom: 1px solid #aaa;
    .content {
      color: #666;
      font-size: 24px;
      font-weight: bold;
    }
    .controll {
      cursor: pointer;
      img {
        width: 20px;
        vertical-align: bottom;
      }
    }
  }
  .info-content-body {
    .info-content-inside {
      margin: 10px 0;
      padding: 10px;
      background-color: #fbfaf5;
      .text-content {
        padding-bottom: 10px;
        font-size: 1.2em;
      }
    }
    .info-content-tags {
      padding-bottom: 5px;
    }
    .info-content-footer {
      padding: 5px 10px 5px 0;
      border-top: 1px solid #aaa;
      font-size: 0.8em;
    }
  }
}
.quatation-record-block {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #89c3eb;
  background-color: #eaf4fc;
  .record-header {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    .record-head-info {
      display: flex;
      font-weight: bold;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .record-head-controll {
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        opacity: 0.5;
      }
    }
  }
}
.alway_display {
  display: flex;
  .alway_display_left {
    text-align: center;
    .surragate_toggle {
      text-align: left;
      .surrogate_toggle_btn {
        width: 50px;
        padding: 3px 0;
        border-radius: 3px;
        border: 1px solid #999;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        font-size: 11px;
        &.surrogated {
          background-color: #d9333f;
          color: #fff;
        }
      }
    }
    img {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 10px 10px 0 0;
    }
  }
  .alway_display_right {
    width: calc(100% - 70px);
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      border: 1px solid #aaa;
    }
    .small_controll_icons {
      // margin-top: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .left_buttons {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        img {
          margin: 0 3px 0 10px;
          width: 30px;
          height: 30px;
          @include mediaQuery('phone') {
            margin: 0 3px 0 3px;
          }
        }
      }
      .right_buttons {
        display: flex;
        justify-content: flex-end;
        img {
          margin: 0 3px 10px 3px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
.post_controlls {
  margin-top: 10px;
  padding: 0;
  padding-top: 5px;
  border-top: 1px solid #aaa;
  .private-record-notify {
    margin: 5px;
    margin-bottom: 10px;
    padding: 3px 5px;
    border: 1px solid #a94442;
    background-color: #f2dede;
    font-size: 0.8em;
    color: #a94442;
    img {
      height: 15px;
      width: 15px;
      vertical-align: middle;
    }
  }
  .video-input-column {
    .selected-video {
      position: relative;
      width: 105px;
      .upload-video-icon {
        position: absolute;
        top: 0;
        left: 0;
      }
      .selected-video-remove-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .audio-input-column {
    .selected-audio {
      position: relative;
      width: 65px;
      .upload-audio-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 52px;
      }
      .selected-audio-remove-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .add_icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    input {
      display: none;
    }
    .left_add_icons {
      display: flex;
      justify-content: flex-start;
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
          @include mediaQuery('phone') {
            margin: 0 3px 0 3px;
          }
      }
    }
    .right_add_icons {
      display: flex;
      justify-content: flex-end;
      .icon-button {
        cursor: pointer;
        margin: 0 0 10px 20px;
        @include mediaQuery('phone') {
          margin: 0 3px 0 3px;
        }
        img {
          height: 30px;
        }
      }
    }
  }
}
.medical-examination-display {
  margin-bottom: 10px;
  border: 1px solid #aaa;
  padding: 5px;
}
.hash_tags {
  display: flex;
  flex-wrap: wrap;
  .hash-tag {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    .close-icon {
      margin-left: -5px;
      width: 10px;
      vertical-align: middle;
    }
  }
  .new-hash-tag {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    .new-tag-icon {
      width: 10px;
    }
    .close-icon {
      width: 10px;
    }
  }
}
.push-targets {
  display: flex;
  flex-wrap: wrap;
  .push-target-author {
    cursor: pointer;
    margin: 0 10px 10px 0;
    .portrate {
      width: 15px;
      vertical-align: middle;
    }
    .author-name {
      display: inline-block;
    }
    .close-icon {
      width: 10px;
      vertical-align: middle;
    }
  }
}
.execute_controlls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left_execute_controlls {
    margin-top: 10px;
    .target-date-button {
      padding: 3px 10px;
      border: 1px solid #999;
      background-color: #999;
      color: #fff;
      font-weight: lighter;
      font-size: 14px;
    }
    .target-date-display {
      span {
        display: inline-block;
        cursor: pointer;
        height: 19px;
        margin-right: 3px;
        padding: 5px 10px 3px 10px;;
        background-color: #fff;
        color: #333;
        border: 1px solid #999;
        font-size: 16px;
      }
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
  .right_execute_controlls {
    margin-top: 10px;
    text-align: right;
    .executable_buttons {
      button {
        padding: 3px 10px;
        font-weight: lighter;
        font-size: 14px;
        &.clone-post {
          background-color: #007b43;
          color: #fff;
          border: 1px solid #007b43;
          border-radius: 3px;
          // &.active {
          //   background-color: #007b43;
          //   border-color: #007b43;
          //   color: #fff;
          //   font-weight: bold;
          // }
          &.disable {
            background-color: #ddd;
            border-color: #999;
            color: #999;
          }
        }
        &.record_post_clear {
          background-color: #fff;
          color: #333;
          border: 1px solid #ddd;
        }
        &.record_post_submit {
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
        }
      }
    }
  }
}
</style>
