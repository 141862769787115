<template>
  <div class="info-content-block">
    <div class="info-content-title" @click="contentTitleClicked">{{ infoContent.title }}</div>
    <div class="info-content-body" v-show="contentOpen">
      <div class="text-content" v-html="returnLinedText(infoContent.content)"></div>
      <div class="photo-content" v-if="infoContent.photos.length > 0">
        <horus-inline-photo
          v-for="photo in infoContent.photos"
          :key="photo.id"
          :photoObject="photo"
          :photoMaxSize="70"
          :sizeType="'small'"
          v-on:timeliePhotoClickEvent="showPhoto(photo)" />
      </div>
      <div class="file-content" v-if="infoContent.files.length > 0">
        <horus-inline-file
          v-for="file in infoContent.files"
          :key="file.id"
          :fileObject="file"
          :sizeType="'small'"
          v-on:timelineFileClickEvent="openFile" />
      </div>
      <div class="info-content-tags">
        <horus-hash-tag-display v-for="tag in infoContent.hashTags" :hashTag="tag" :key="tag.id" :sizeType="'small'" />
      </div>
    </div>
  </div>
</template>

<script>
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'

export default {
  name: 'HorusLeftContentInfoContent',

  components: {
    HorusHashTagDisplay,
    HorusInlinePhoto,
    HorusInlineFile
  },

  props: {
    infoContent: Object,
    contentOpen: Boolean,
    type: String
  },

  methods: {
    returnLinedText (text) {
      return text.replace(/\n/gi, '<br />')
    },

    showPhoto (photo) {
      var photoIndex = this.infoContent.photos.indexOf(photo)
      this.$emit('showInfoContentPhotoEvent', this.infoContent, this.type, photoIndex)
    },

    openFile () {
      console.log('info content, openFile CALL, need to imprements')
    },

    contentTitleClicked () {
      this.$emit('infoContentTitleClickEvent', this.infoContent, this.type)
    }
  }
}
</script>

<style lang="scss">
.info-content-block {
  .info-content-title {
    cursor: pointer;
    margin: 20px 10px 0 10px;
    border-bottom: 1px solid #aaa;
    font-size: 1.2em;
  }
  .info-content-body {
    margin: 0 10px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #aaa;
    border-top: none;
    font-size: 0.9em;
    .text-content {
      margin-bottom: 5px;
    }
    .photo-content {
      margin-bottom: 5px;
    }
    .file-content {
      margin-bottom: 5px;
    }
  }
  .info-content-tags {
    padding: 3px;
    border-top: 1px solid #aaa;
  }
}

</style>
