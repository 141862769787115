import { Model } from '@vuex-orm/core'
// import axiosHelper from '@/api/AxiosInstance'
import moment from 'moment'
import Author from './Author'
import VitalValue from './VitalValue'
import VitalBloodPressure from './VitalBloodPressure'
import VitalBodyComposition from './VitalBodyComposition'
import VitalActiveMass from './VitalActiveMass'
import VitalBodyTemperature from './VitalBodyTemperature'
import VitalOxygenSaturation from './VitalOxygenStaturation'
import VitalPulseRate from './VitalPulseRate'

export default class VitalEntry extends Model {
  static entity = 'vitalEntries'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      author_id: this.string(''),
      category: this.attr(null),
      category_name: this.string(''),
      source: this.string(''),
      device: this.string(''),
      measure_at: this.string(''),
      measure_to: this.string(''),
      entry_state: this.attr(null)
    }
  }

  get author () {
    return Author.find(this.author_id)
  }

  get measureAt () {
    return new Date(this.measure_at)
  }

  get measureAtStr () {
    return moment(this.measureAt).format('YYYY/MM/DD HH:mm')
  }

  get measureAtJp () {
    return moment(this.measureAt).format('YYYY年MM月DD日 HH:mm')
  }

  get measureTo () {
    return new Date(this.measure_to)
  }

  get measureToStr () {
    return moment(this.measureTo).format('YYYY/MM/DD HH:mm')
  }

  get measureToJp () {
    return moment(this.measureTo).format('YYYY年MM月DD日 HH:mm')
  }

  // get vitalValues () {
  //   return VitalValue.query().where('vital_entry_id', this.id).get()
  // }

  // get vitalBloodPressure () {
  //   return VitalBloodPressure.query().where('vital_entry_id', this.id).first()
  // }

  // get vitalBodyComposition () {
  //   return VitalBodyComposition.query().where('vital_entry_id', this.id).first()
  // }

  // get vitalActiveMass () {
  //   return VitalActiveMass.query().where('vital_entry_id', this.id).first()
  // }

  // get vitalBodyTemperature () {
  //   return VitalBodyTemperature.query().where('vital_entry_id', this.id).first()
  // }

  // get vitalOxygenSaturation () {
  //   return VitalOxygenSaturation.query().where('vital_entry_id', this.id).first()
  // }

  // get vitalPulseRate () {
  //   return VitalPulseRate.query().where('vital_entry_id', this.id).first()
  // }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async storeData (payload) {
    // console.log('in VitalEntry.storeData.... ')
    // console.log(payload)
    let promises = [VitalEntry.insertOrUpdate({ data: payload.vital_entries })]
    if (payload.vital_values && payload.vital_values.length) {
      promises.push(VitalValue.insertOrUpdate({ data: payload.vital_values }))
    }
    if (payload.vital_blood_pressures && payload.vital_blood_pressures.length > 0) {
      promises.push(VitalBloodPressure.insertOrUpdate({ data: payload.vital_blood_pressures }))
    }
    if (payload.vital_body_compositions && payload.vital_body_compositions.length > 0) {
      promises.push(VitalBodyComposition.insertOrUpdate({ data: payload.vital_body_compositions }))
    }
    if (payload.vital_active_masses && payload.vital_active_masses.length > 0) {
      promises.push(VitalActiveMass.insertOrUpdate({ data: payload.vital_active_masses }))
    }
    if (payload.vital_body_temperatures && payload.vital_body_temperatures.length > 0) {
      promises.push(VitalBodyTemperature.insertOrUpdate({ data: payload.vital_body_temperatures }))
    }
    if (payload.vital_oxygen_saturations && payload.vital_oxygen_saturations.length > 0) {
      promises.push(VitalOxygenSaturation.insertOrUpdate({ data: payload.vital_oxygen_saturations }))
    }
    if (payload.vital_pulse_rates && payload.vital_pulse_rates.length > 0) {
      promises.push(VitalPulseRate.insertOrUpdate({ data: payload.vital_pulse_rates }))
    }
    return Promise.all(promises).then(([entries]) =>{
      // console.log('on VitalEntry.storeData, Promise.all,,')
      // console.log(entries)
      VitalEntry.commit((state) => {
        state.fetchedAt = new Date()
      })
      if (entries && entries.viatalEntries) {
        return entries.viatalEntries
      } else {
        return []
      }
    })
  }

  static apiConfig = {
    actions: {
      fetch ({targetUserID, from}) {
        return this.request({
          method: 'get',
          url: 'vital_entry',
          withCredentials: true,
          params: { target_user_id: targetUserID, from: from },
          save: false
        }).then(async res => {
          console.log('in api fetch vital_entry respound.')
          console.log(res)
          return VitalEntry.storeData(res.response.data)
        })
        .catch(error => { throw error })
      },

      fetchNewest ({targetUserID, limit}) {
        return this.request({
          method: 'get',
          url: 'vital_entry/newest_entires',
          withCredentials: true,
          params: { target_user_id: targetUserID, limit: limit },
          save: false
        }).then(async res => {
          console.log('in api newest vital_entry respound.')
          console.log(res)
          return VitalEntry.storeData(res.response.data)
        })
        .catch(error => { throw error })
      }
      },

  }
}
