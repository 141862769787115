<template>
  <div class="horus-radio-button-group">
    <div class="selections"
      v-for="valueAndLabel in valueAndLabels"
      :key="'radioSelect' + valueAndLabel.value"
      :class="{selected: valueAndLabel.value === selectedValue}"
      @click="valueSelected(valueAndLabel.value)"
      >
      <img src="/static/images/radio_on.png" class="radio-on-btn" />
      <img src="/static/images/radio_off.png" class="radio-off-btn" />
      <span class="label">{{ valueAndLabel.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusRadioButtonGroup',

  props: {
    valueAndLabels: Array,
    selectedValue: Number
  },

  methods: {
    valueSelected (value) {
      this.$emit('valueSelectedEvent', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-radio-button-group {
  display: flex;
  align-items: center;
  .selections {
    display: flex;
    align-items: center;
    margin-right: 15px;
    .radio-on-btn {
      height: 18px;
      display: none;
    }
    .radio-off-btn {
      height: 18px;
      display: inline-block;
    }
    &.selected {
      .radio-on-btn {
        height: 18px;
        display: inline-block;
      }
      .radio-off-btn {
        height: 18px;
        display: none;
      }
    }
    .label {
      font-size: 16px;
    }
  }
}
</style>
