<template>
  <div class="horus-tag-narrowdown-modal-wrapper" v-show="showTagNarrowDownModal">
    <div class="bn-tag-narrowdown-backdrop" @click="backdropClicked"></div>
    <div class="horus-tag-narrowdown-modal">
      <div class="horus-tag-narrowdown-control">
        <div class="submit-controlls">
          <button class="horus-tag-narrowdown-clear" type="button" @click="clearNarrowDownTag">クリア</button>
          <button class="horus-tag-narrowdown-submit" type="button" @click="submitNarrowDownTag">OK</button>
        </div>
        <img src="/static/images/gear_icon.png" class="configure" @click="showTagManage"/>
      </div>
      <horus-tag-selection
        v-for="hashTag in hashTags"
        :key="hashTag.id"
        :hashTag="hashTag"
        :enabled="tagEnabled()"
        :active="tagActive(hashTag)"
        v-on:updateStateEvent="updateNarrowDownState" />
    </div>
  </div>
</template>

<script>
import HorusTagSelection from '@/components/common/HorusTagSelection.vue'

export default {
  name: 'HorusTagNarrowDownModal',

  components: {
    HorusTagSelection
  },

  props: {
    showTagNarrowDownModal: Boolean,
    hashTags: Array,
    activeTags: Array
  },

  methods: {
    tagEnabled () {
      return (this.activeTags.length > 0)
    },

    tagActive (hashTag) {
      return this.activeTags.some(active => { return active === hashTag })
    },

    backdropClicked () {
      this.$emit('tagNarrowDownModalCloseEvent')
    },

    updateNarrowDownState (targetTag, currentActive, currentEnabled) {
      this.$emit('updateNarrowDownTagEvent', targetTag, currentActive, currentEnabled)
    },

    submitNarrowDownTag () {
      this.$emit('sumitNarrowDownTagEvent')
      this.$emit('tagNarrowDownModalCloseEvent')
    },

    clearNarrowDownTag () {
      this.$emit('clearNarrowDownTagEvent')
      this.$emit('tagNarrowDownModalCloseEvent')
    },

    showTagManage () {
      this.$emit('showTagManageEvent')
    }
  }

}
</script>

<style lang="scss">
@import '@/assets/css/mediaQuery.scss';

.horus-tag-narrowdown-modal-wrapper {
  position: absoluete;
}
.bn-tag-narrowdown-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.05;
}
.horus-tag-narrowdown-modal {
  position: absolute;
  width: 340px;
  max-height: calc(100vh - 250px);
  margin: 0;
  padding: 0;
  top: 130px;
  left: calc((100vw - 1000px) / 2 + 30px);
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba($color: #000000, $alpha: 0.175);
  border: 1px solid #aaa;
  border-radius: 4px;
  @include mediaQuery('tablet') {
    left: 10px;
  }
  @include mediaQuery('phone') {
    width: calc(100vw - 20px);
    left: 10px;
  }
}
.horus-tag-narrowdown-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  height: 53px;
  .configure {
    cursor: pointer;
    margin-right: 10px;
  }
}
.horus-tag-narrowdown-submit {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #666;
  background-color: #666;
  color: #fff;
}
.horus-tag-narrowdown-clear {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #333;
}
</style>
