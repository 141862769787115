<template>
  <div class="horus-manage-area-block-operator-invitation-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>管理団体オペレーター案内新規作成</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'宛先表示名'"
          :necessary="true"
          :validationState="validateName.state"
          :validationMessage="validateName.message"
          :attributeNotify="''">
          <input type="text" size="50" v-model="targetName" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="validateMail.state"
          :validationMessage="validateMail.message"
          :attributeNotify="''">
          <input type="text" size="40" v-model="targetMail" />
        </horus-attribute-in-form>
        <div class="notify_for_admit">
          <h1>管理団体オペレーター追加に関する確認事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>管理団体オペレーターの追加には大いなる責任が伴うことに同意していること</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">新規作成</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  AreaBlockOperator
} from '@/models'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusManageAreaBlockOperatorInvitationModal',

  components: {
    HorusAttributeInForm,
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    areaBlock: Object,
    showModal: Boolean
  },

  data () {
    return {
      targetName: '',
      targetMail: '',
      admitAgreement: false,
      validateMail: { valid: false, state: 'none', message: '' }
    }
  },

  watch: {
    targetMail: { handler: 'mailValidation' }
  },

  computed: {
    validateName () {
      var checkResult = this.stringLengthCheck(this.targetName, 127, '宛先表示名')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    // validateMail () {
    //   var checkResult = this.stringLengthCheck(this.targetMail.length, 127, 'メールアドレス')
    //   if (checkResult.valid) {
    //     return { valid: true, state: 'valid', message: checkResult.message }
    //   } else {
    //     return { valid: false, state: 'invalid', message: checkResult.message }
    //   }
    // },

    allValid () {
      if (
        this.validateName.valid &&
        this.validateMail.valid &&
        this.admitAgreement
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    stringLengthCheck (value, length, valueName) {
      var valid = false
      var message = ''
      if (!value || value.length <= 0) {
        message = valueName + 'は入力必須です。'
      } else if (value.length > length) {
        message = valueName + 'は' + length + '文字以下で入力ください。'
      } else {
        valid = true
      }
      return { valid: valid, message: message }
    },

    mailValidation () {
      AreaBlockOperator.api().mailValidation({ areaBlockID: this.areaBlock.id, mail: this.targetMail }).then(res => {
        if (res.valid) {
          this.validateMail = { valid: true, state: 'valid', message: '' }
        } else {
          this.validateMail = { valid: false, state: 'invalid', message: '入力されたメールアドレスは既に登録済みです' }
        }
      }).catch(err => {
        console.log(err)
      })
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    initForm () {
      this.targetName = ''
      this.targetMail = ''
      this.admitAgreement = false
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', () => { this.initForm() })
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('area_block_id', this.areaBlock.id)
        formData.append('area_block_operator_invitation[name]', this.targetName)
        formData.append('area_block_operator_invitation[mail]', this.targetMail)
        this.$emit('submitEvent', formData, () => { this.initForm() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-manage-area-block-operator-invitation-modal {
  .modal-body {
    padding: 10px 20px;
    .link {
      cursor: pointer;
      color: #428bca;
    }
    input {
      font-size: 16px;
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
