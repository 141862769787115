export default {
  data () {
    return {
      selectionTargetDate: null,
      futureDateSelectionModalShow: false,
      futureTargetDate: { data: null },
      futureDateSelectedPostAction: null,
      futureDateSelectCanceledAction: null
    }
  },

  methods: {
    reserveFutureDateSelection (selectionTarget, selectedPostAction, cancelPostAction) {
      console.log('on future date selection....')
      console.log(selectionTarget)
      this.selectionTargetDate = selectionTarget
      // this.futureTargetDate = selectionTarget
      this.futureDateSelectedPostAction = selectedPostAction
      this.futureDateSelectCanceledAction = cancelPostAction
    },

    releaseFutureDateSelection () {
      this.selectionTargetDate = { date: null }
      this.futureDateSelectedPostAction = null
      this.futureDateSelectCanceledAction = null
    },

    futureDateSelectionOpen () {
      this.futureDateSelectionModalShow = true
    },

    futureDateSelectionModalClose () {
      this.futureDateSelectionModalShow = false
    },

    futureDateSelectionCancel () {
      this.futureDateSelectionModalShow = false
      if (this.futureDateSelectCanceledAction && typeof this.futureDateSelectCanceledAction === 'function') {
        this.futureDateSelectCanceledAction()
      }
    },

    futureDateSelectionCommit (targetDate) {
      console.log('future con, ' + targetDate)
      if (this.selectionTargetDate) {
        this.selectionTargetDate.date = targetDate
      }
      this.futureDateSelectionModalShow = false
      if (this.futureDateSelectedPostAction && typeof this.futureDateSelectedPostAction === 'function') {
        this.futureDateSelectedPostAction()
      }
      this.releaseFutureDateSelection()
    }
  }
}
