<template>
  <div class="horus-record-thread-modal-wrapper" v-if="recordThreadModalVisible">
    <div class="horus-record-thread-modal-backdrop" @click="closeModal"></div>
    <div class="horus-record-thread-modal">
      <div class="record-thread-header">
        <div class="title">会話</div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div v-for="threadRecord in threadRecords"
        :key="'threadmodalwrap' + threadRecord.id"
        class="thread-record">
        <horus-record-in-modal
          :key="'threadmodal' + threadRecord.id"
          :record="threadRecord"
          :timelineOwner="timelineOwner"
          v-on:jumpToOriginEvent="jumpToOrigin"
          v-on:durgEntryPhotoClickEvent="showDrugEntryPhoto"
          v-on:timeliePhotoClickEvent="showPhoto"
          v-on:timelineFileClickEvent="openFile" />
        </div>
    </div>
  </div>
</template>

<script>
import HorusRecordInModal from '@/components/timelineRecord/HorusRecordInModal.vue'

export default {
  name: 'HorusRecordThreadModal',

  components: {
    HorusRecordInModal
  },

  props: {
    timelineOwner: Object,
    recordThreadModalVisible: Boolean,
    targetRecord: Object,
    threadRecords: Array
  },

  methods: {
    closeModal () {
      this.$emit('recordThreadModalCloseEvent')
    },

    jumpToOrigin (record) {
      this.$emit('jumpToOriginEvent', record)
      this.$emit('recordThreadModalCloseEvent')
    },

    showPhoto (record, firstShowIndex) {
      this.$emit('showRecorPhotoEvent', record, firstShowIndex)
    },

    showDrugEntryPhoto (record, firstShowIndex) {
      this.$emit('showDrugEntryPhotoEvent', record, firstShowIndex)
    },

    openFile (file) {
      this.$emit('openRecordFileEvent', file)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mediaQuery.scss';

.horus-record-thread-modal-wrapper {
  position: absoluete;
}
.horus-record-thread-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.3;
}
.horus-record-thread-modal {
  position: absolute;
  width: 600px;
  max-height: calc(100vh - 60px);
  margin: 0;
  padding: 0;
  top: 30px;
  left: calc((100vw - 600px) / 2);
  overflow: auto;
  background-color: #eee;
  border: 1px solid #aaa;
  border-radius: 5px;
  @include mediaQuery('tablet') {
    width: calc(100vw - 40px);
    left: 20px;
  }
  @include mediaQuery('phone') {
    width: calc(100vw - 10px);
    left: 5px;
  }
  .record-thread-header {
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
    .title {
      color: #666;
      font-size: 18px;
    }
    .close-btn {
      width: 25px;
      margin-top: 10px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  .thread-record {
    margin-bottom: 5px;
  }
}
</style>
