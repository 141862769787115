import axios from './AxiosInstance'

export default {
  fetch: ({targetID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'quick_agreement_action',
        withCredentials: true,
        params: { id: targetID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ quickAgreementAction: res.data.action })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'quick_agreement_action',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ success: res.data.success, quickAgreementAction: res.data.action })
          } else {
            resolve({ success: res.data.success, message: res.data.message })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  agree: ({targetUserID, targetID}) => {
    var formData = new FormData()
    formData.append('target_user_id', targetUserID)
    formData.append('id', targetID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'quick_agreement_action/agree',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ quickAgreementAction: res.data.action })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }

}
