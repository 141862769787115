<template>
  <div class="horus-create-user-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="content">
      <h1 class="centering-title">ナラティブブック新規登録</h1>
      <horus-create-user-step-bar
        :step="step" />
      <horus-create-user-entry
        v-if="pageState === STEP.ENTRY"
        v-on:sendMailEvent="sendMail"
        />
      <horus-create-user-mail-registed
        v-if="pageState === STEP.CONFIRM_MAIL_SENT"
        :mailAddress="mailAddress"
        />
      <horus-create-user-mail-confirmed
        v-if="pageState === STEP.MAIL_CONFIRMED"
        :mailAddress="mailAddress"
        :additionalStatusMessage="additionalStatusMessage"
        v-on:inputProfileEvent="inputProfiles"
        v-on:sendMailEvent="sendMail"
        />
      <horus-create-user-mail-confirm-failed
        v-if="pageState === STEP.MAIL_CONFIRM_FAILED"
        v-on:backToEntryEvent="backToEntry"
        />
      <horus-create-user-input-profile
        v-if="pageState === STEP.PROFILE_INPUT"
        v-on:showConfirmDialogEvent="showConfirmDialog"
        v-on:userCreationFinishEvent="userCreationFinish"
        />
      <horus-create-user-finish
        v-if="pageState === STEP.FINISH"
        :userName="createdUserName"
        :message="finishedMessage"
        :notifyMessage="finishedNotifyMessage"
        v-on:startNarrativeBookEvent="startNarrativeBook"
        v-on:finishNarrativeBookEvent="finishNarrativeBook"
        />
    </div><!-- content -->
    <horus-footer />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div><!-- container -->
</template>

<script>
import {
  SigninUser,
  SigninMember,
  SigninReporter
} from '@/models'
import {
  User
} from '@/api'

import HorusCreateUserStepBar from '@/components/createUser/HorusCreateUserStepBar.vue'
import HorusCreateUserEntry from '@/components/createUser/HorusCreateUserEntry.vue'
import HorusCreateUserMailRegisted from '@/components/createUser/HorusCreateUserMailRegisted.vue'
import HorusCreateUserMailConfirmed from '@/components/createUser/HorusCreateUserMailConfirmed.vue'
import HorusCreateUserMailConfirmFailed from '@/components/createUser/HorusCreateUserMailConfirmFailed.vue'
import HorusCreateUserInputProfile from '@/components/createUser/HorusCreateUserInputProfile.vue'
import HorusCreateUserFinish from '@/components/createUser/HorusCreateUserFinish.vue'

import HorusFooter from '@/components/common/HorusFooter.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import * as CREATE_STEP from '@/assets/constants/createUserStep.js'
// import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusCreateUserView',

  components: {
    HorusCreateUserStepBar,
    HorusCreateUserEntry,
    HorusCreateUserMailRegisted,
    HorusCreateUserMailConfirmed,
    HorusCreateUserMailConfirmFailed,
    HorusCreateUserInputProfile,
    HorusCreateUserFinish,
    HorusFooter,
    HorusConfirmDialog
  },

  porps: {
  },

  watch: {
    '$route': { handler: 'validateToken', immediate: true }
  },

  data () {
    return {
      token: '',
      tokenType: '',
      shortToken: '',
      both: false,
      STEP: CREATE_STEP,
      pageState: 0,
      step: '',
      mailAddress: null,
      preMailValidationID: null,
      csrfToken: null,
      passwordVisible: false,
      createdUserName: '',
      finishedMessage: '',
      finishedNotifyMessage: '',
      finishedOn: ''
    }
  },

  computed: {
    additionalStatusMessage () {
      if (this.tokenType === 'organization_manager') {
        return '利用者作成完了後、施設管理者となります。'
      } else if (this.tokenType === 'organization_member') {
        return '利用者作成完了後、施設職員となります。'
      } else if (this.tokenType === 'family') {
        return '利用者作成完了後、家族が設定されます。'
      } else if (this.tokenType === 'relation') {
        return '利用者作成完了後、かかりつけ施設が設定されます。'
      } else if (this.tokenType === 'reporter_organization_manager') {
        return '利用者作成完了後、報告施設管理者となります。'
      } else if (this.tokenType === 'reporter_organization_member') {
        return '利用者作成完了後、報告施設職員となります。'
      } else {
        return ''
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      }
      return 'password'
    }
  },

  methods: {
    sendMail (mail) {
      if (mail && mail.length > 0) {
        var formData = new FormData()
        formData.append('mail_address', mail)
        if (this.preMailValidationID) {
          formData.append('pre_mail_validation', this.preMailValidationID)
        }
        User.preMailValidation({ formData: formData }).then(() => {
          this.mailAddress = mail
          this.pageState = this.STEP.CONFIRM_MAIL_SENT
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('確認メール送信の失敗', '入力されたメールアドレスへの確認メール送信に失敗しました。')
        })
      } else {
        this.showNotifyDialog('メールアドレスの入力', 'ナラティブブックで利用するメールアドレスを入力してください。')
      }
    },

    validateToken () {
      this.token = this.$route.query.token
      this.tokenType = this.$route.query.tokenType
      this.shortToken = this.$route.query.shortToken
      if (this.$route.query.mail) {
        this.mailAddress = this.$route.query.mail
      }
      this.familyBoth = this.$route.query.both
      if (this.token && this.token.length > 0) {
        this.pageState = this.STEP.RECEIVE_CONFIRM_TOKEN
        this.step = this.STEP.PAGE_STEP_CONFIRM_MAIL
        var payload = {}
        if (this.tokenType === 'organization_manager') {
          payload = { organization_manager_invitation_token: this.token }
        } else if (this.tokenType === 'organization_member') {
          payload = { organization_member_invitation_token: this.token, short_token: this.short_token }
        } else if (this.tokenType === 'family') {
          payload = { authorized_family_invitation: this.token, short_token: this.short_token, family_both: this.familyBoth }
        } else if (this.tokenType === 'relation') {
          payload = { service_relation_request_for_user: this.token, short_token: this.short_token }
        } else if (this.tokenType === 'reporter_relation') {
          payload = { reporter_organization_invitation_for_user: this.token, short_token: this.short_token }
        } else if (this.tokenType === 'reporter_organization_manager') {
          payload = { reporter_organization_manager_invitation: this.token, short_token: this.short_token }
        } else if (this.tokenType === 'reporter_organization_member') {
          payload = { reporter_organization_member_invitation: this.token, short_token: this.short_token }
        } else {
          payload = { token: this.token }
        }
        User.confirmMail(payload).then(res => {
          console.log('in view.')
          console.log(res)
          this.preMailValidationID = res.pre_mail_validation
          this.mailAddress = res.mail
          this.pageState = this.STEP.MAIL_CONFIRMED
          this.step = this.STEP.PAGE_STEP_CONFIRM_MAIL
        }).catch(error => {
          console.log(error)
          this.pageState = this.STEP.MAIL_CONFIRM_FAILED
        })
      } else {
        this.pageState = this.STEP.ENTRY
        this.step = this.STEP.PAGE_STEP_CHECK_MAIL
      }
    },

    backToEntry () {
      this.mailAddress = ''
      this.token = null
      this.tokenType = null
      this.shortToken = null
      this.pageState = this.STEP.ENTRY
      this.step = this.STEP.PAGE_STEP_CHECK_MAIL
    },

    inputProfiles () {
      this.pageState = this.STEP.PROFILE_INPUT
      this.step = this.STEP.PAGE_STEP_INPUT_PROFILE
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    userCreationFinish (resultObjct) {
      // 結果を表示してログイン状態にする。
      if (resultObjct.created_user) {
        SigninUser.create({
          data: {
            user_id: resultObjct.created_user,
            author_id: 'usr' + resultObjct.created_user
          }
        })
        this.finishedOn = 'user'
      }
      if (resultObjct.service_user) {
        SigninMember.create({
          data: {
            id: resultObjct.service_user.id,
            user_id: resultObjct.service_user.user_id,
            author_id: resultObjct.service_user.author_id,
            organization_id: resultObjct.service_user.organization_id,
            member_manager: resultObjct.service_user.member_manager,
            relation_manager: resultObjct.service_user.relation_manager,
            deputize_manager: resultObjct.service_user.deputize_manager,
            switch_suoms: resultObjct.service_user.switch_suoms,
            help_path: resultObjct.service_user.help_path
          }
        })
        this.finishedOn = 'member'
      }
      if (resultObjct.reporter_member) {
        SigninReporter.create({
          data: {
            id: resultObjct.reporter_member.id,
            user_id: resultObjct.reporter_member.user_id,
            author_id: resultObjct.reporter_member.author_id,
            reporter_organization_id: resultObjct.reporter_member.reporter_organization_id,
            manager: resultObjct.reporter_member.manager,
            name: resultObjct.reporter_member.name,
            mail: resultObjct.reporter_member.mail
          }
        })
        this.finishedOn = 'reporter'
      }
      if (resultObjct.message) {
        this.finishedMessage = resultObjct.message
      }
      if (resultObjct.notify_message) {
        this.finishedNotifyMessage = resultObjct.notify_message
      }
      if (resultObjct.created_user_name) {
        this.createdUserName = resultObjct.created_user_name
      }
      this.pageState = this.STEP.FINISH
      this.step = this.STEP.PAGE_STEP_FINISH
    },

    startNarrativeBook () {
      if (this.finishedOn === 'member') {
        this.$router.push('/member_portal')
      } else if (this.finishedOn === 'reporter') {
        this.$router.push('/reporter_portal')
      } else {
        this.$router.push('/maintimeline')
      }
    },

    finishNarrativeBook () {
      // signout
      this.$router.push('/signout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-create-user-view {
  height: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  .content {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.1em;
      font-weight: normal;
    }
  }
}
</style>
