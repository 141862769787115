<template>
  <div class="horus-signed-in-forbidden">
    <!-- <img class="forbidden-title" src="/static/images/forbidden_title_L.png"/>
    <h1>ページが存在しません</h1>
    <img class="narrative-logo" src="/static/images/narrative_logo_red_portrate_M.png" v-if="!signinMember" />
    <img class="narrative-logo" src="/static/images/narrative_logo_green_portrate_M.png" v-if="signinMember" />
    <button class="back-btn" @click="jumpToHome" v-if="!signinMember">自分のタイムラインに移動</button>
    <button class="back-btn" @click="jumpToHome" v-if="signinMember">ポータルに移動</button> -->
  </div>
</template>

<script>
export default {
  name: 'HorusSignedInForbidden',

  props: {
    loggedInUser: Object,
    signinMember: Object
  },

  beforeMount () {
    this.redirectToHome()
  },

  methods: {
    redirectToHome () {
      if (this.signinMember) {
        this.$router.push('/member_portal')
      } else {
        this.$router.push('/maintimeline')
      }
    },

    jumpToHome () {
      if (this.signinMember) {
        this.$router.push('/member_portal')
      } else {
        this.$router.push('/maintimeline')
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.horus-signed-in-forbidden {
  width: 100%;
  height: 100%;
  padding: 50px 0;
  .forbidden-title {
    display: block;
    margin: 0 auto 50px;
  }
  h1 {
    margin: 0;
    font-size: 16px;
    text-align: center;
    font-weight: normal;
  }
  .narrative-logo {
    display: block;
    margin: 50px auto 0;
  }
  .back-btn {
    display: block;
    margin: 50px auto;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
