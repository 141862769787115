import axios from './AxiosInstance'

export default {
  fetchSimpleData: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'totalization/simple_data',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ result: res.data.result })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchFileList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'totalization/list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ system_calcurate_files: res.data.system_calcurate_files })
        }
      }).catch(error => { reject(error) })
    })
  },

  totalization: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'totalization',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data.result })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  initializeLifetimeLogs: () => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'totalization/initialize_lifetime_logs',
            withCredentials: true
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ message: res.data.message })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
