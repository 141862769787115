<template>
  <div class="horus-task-display-configure-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/task_pad_icon_s.png" class="title-icon" />予定表示設定</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'アップデート確認間隔'"
          :necessary="true"
          :validationState="validateUpdatePoolingSpan.state"
          :validationMessage="validateUpdatePoolingSpan.message"
          :attributeNotify="'分単位、1以上'">
          <input type="number" v-model="updatePoolingSpan" min="1" />分
        </horus-attribute-in-form>
        <div class="note-for-input">
          予定の更新を確認するために定期的にサーバーにアクセスする間隔を指定します。<br/>
          間隔を短くすると、新しく共有された予定やビデオ通話をいち早く確認できますが、その分通信データが増えることになります。
        </div>
        <horus-attribute-in-form
          :labelText="'通知表示更新間隔'"
          :necessary="true"
          :validationState="validateNotifyPoolingSpan.state"
          :validationMessage="validateNotifyPoolingSpan.message"
          :attributeNotify="'分単位、1以上'">
          <input type="number" v-model="notifyPoolingSpan" min="1" />分
        </horus-attribute-in-form>
        <div class="note-for-input">
          通知の表示を更新するためにローカルデータをチェックする間隔を指定します。<br/>
          表示の更新にはサーバーとのアクセスは発生しません。<br/>
          間隔を短くすることで、通知の更新にリアルタイム性が高まりますが、その分端末の負荷が増えることになります。
        </div>
        <horus-attribute-in-form
          :labelText="'通知に表示する予定'"
          :necessary="true"
          :validationState="validateNotifyTerm.state"
          :validationMessage="validateNotifyTerm.message"
          :attributeNotify="''">予定の
          <input type="number" v-model="notifyFutureDay" min="0" />日
          <input type="number" v-model="notifyFutureHour" min="0" max="23" />時間
          <input type="number" v-model="notifyFutureMin" />分前から表示する<br />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'通知する予定の期間'"
          :necessary="true"
          :validationState="validateNotifyTerm.state"
          :validationMessage="validateNotifyTerm.message"
          :attributeNotify="''">予定日時経過後
          <input type="number" v-model="notifyPastDay" min="0" />日
          <input type="number" v-model="notifyPastHour" min="0" max="23" />時間
          <input type="number" v-model="notifyPastMin" min="0" max="59" />分まで表示する
        </horus-attribute-in-form>
        <div class="note-for-input">
          ヘッダーにアイコンを表示する期間を指定します。<br/>
          予定のどれぐらい前から表示するかと、予定が過ぎたあとどれぐらいまで表示するかが指定できます。
        </div>
        <div class="execute_controlls">
          <div class="executable_buttons">
            <button type="button" class="record_post_clear" @click="cancelUpdate">キャンセル</button>
            <button type="button" class="record_post_submit" :class="{enable: allAttributeValid }" @click="submitUpdate">設定更新</button>
          </div>
        </div><!-- execute controll end -->
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { TaskDisplayConfiguration } from '@/models'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

export default {
  name: 'HorusTaskDisplayConfigurationModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm
  },

  props: {
    showModal: Boolean,
    signinUser: Object,
    signinMember: Object,
    taskDisplayConfiguration: Object
  },

  data () {
    // t.integer :update_pooling_span, default: 10
    // t.integer :notify_pooling_span, default: 1
    // t.integer :notify_display_past_min, default: 10
    // t.integer :nofify_display_future_min: default: 30
    // t.integer :display_past_min, default: 60
    // t.integer :display_future_min, default: 1440
    return {
      // taskDisplayConf: null,
      updatePoolingSpan: 10,
      notifyPoolingSpan: 1,
      displayPastDay: 0,
      displayPastHour: 1,
      displayPastMin: 0,
      displayFutureDay: 1,
      displayFutureHour: 0,
      displayFutureMin: 0,
      notifyPastDay: 0,
      notifyPastHour: 0,
      notifyPastMin: 1,
      notifyFutureDay: 0,
      notifyFutureHour: 0,
      notifyFutureMin: 10
    }
  },

  computed: {
    validateUpdatePoolingSpan () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!Number.isInteger(Number(this.updatePoolingSpan)) || Number(this.updatePoolingSpan) <= 0) {
        message = '正の整数以外は入力できません。'
      } else {
        valid = true
        state = 'valid'
      }
      return { valid: valid, state: state, message: message }
    },

    validateNotifyPoolingSpan () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!Number.isInteger(Number(this.notifyPoolingSpan)) || Number(this.notifyPoolingSpan) <= 0) {
        message = '正の整数以外は入力できません。'
      } else {
        valid = true
        state = 'valid'
      }
      return { valid: valid, state: state, message: message }
    },

    validateDisplayTerm () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (
        Number.isInteger(Number(this.displayFutureDay)) &&
        Number.isInteger(Number(this.displayFutureHour)) &&
        Number.isInteger(Number(this.displayFutureMin)) &&
        Number.isInteger(Number(this.displayPastDay)) &&
        Number.isInteger(Number(this.displayPastHour)) &&
        Number.isInteger(Number(this.displayPastMin))
      ) {
        if (
          Number(this.displayFutureHour) <= 0 &&
          Number(this.displayFutureDay) <= 0 &&
          Number(this.displayFutureMin) <= 0
        ) {
          message = '取得する予定の未来の期間は1分以上に設定してください'
        } else {
          if (
            Number(this.displayPastDay) < 0 &&
            Number(this.displayPastHour) < 0 &&
            Number(this.displayPastMin) < 0
          ) {
            message = '取得する予定の未来の期間は0分以上に設定してください'
          } else {
            valid = true
            state = 'valid'
          }
        }
      } else {
        message = '取得する期間は半角整数で入力してください'
      }
      return { valid: valid, state: state, message: message }
    },

    validateNotifyTerm () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (
        Number.isInteger(Number(this.notifyFutureDay)) &&
        Number.isInteger(Number(this.notifyFutureHour)) &&
        Number.isInteger(Number(this.notifyFutureMin)) &&
        Number.isInteger(Number(this.notifyPastDay)) &&
        Number.isInteger(Number(this.notifyPastHour)) &&
        Number.isInteger(Number(this.notifyPastMin))
      ) {
        if (
          Number(this.notifyFutureHour) <= 0 &&
          Number(this.notifyFutureDay) <= 0 &&
          Number(this.notifyFutureMin) <= 0
        ) {
          message = '通知する予定の未来の期間は1分以上に設定してください'
        } else {
          if (
            Number(this.notifyPastDay) < 0 &&
            Number(this.notifyPastHour) < 0 &&
            Number(this.notifyPastMin) < 0
          ) {
            message = '通知する予定の未来の期間は0分以上に設定してください'
          } else {
            valid = true
            state = 'valid'
          }
        }
      } else {
        message = '通知する期間は半角整数で入力してください'
      }
      return { valid: valid, state: state, message: message }
    },

    allAttributeValid () {
      if (this.validateUpdatePoolingSpan.valid && this.validateNotifyPoolingSpan.valid && this.validateDisplayTerm.valid && this.validateNotifyTerm.valid) {
        return true
      }
      return false
    }
  },

  watch: {
    taskDisplayConfiguration: { handler: 'initAttributes', immediate: true }
  },

  methods: {
    initAttributes () {
      if (this.taskDisplayConfiguration) {
        this.updatePoolingSpan = this.taskDisplayConfiguration.update_pooling_span
        this.notifyPoolingSpan = this.taskDisplayConfiguration.notify_pooling_span

        this.displayPastMin = this.taskDisplayConfiguration.display_past_min % 60
        var uPastHour = Math.floor(this.taskDisplayConfiguration.display_past_min / 60)
        this.displayPastHour = uPastHour % 24
        this.displayPastDay = Math.floor(uPastHour / 24)

        this.displayFutureMin = this.taskDisplayConfiguration.display_future_min % 60
        var uFutureHour = Math.floor(this.taskDisplayConfiguration.display_future_min / 60)
        this.displayFutureHour = uFutureHour % 24
        this.displayFutureDay = Math.floor(uFutureHour / 24)

        this.notifyPastMin = this.taskDisplayConfiguration.notify_display_past_min % 60
        var nPastHour = Math.floor(this.taskDisplayConfiguration.notify_display_past_min / 60)
        this.notifyPastHour = nPastHour % 24
        this.notifyPastDay = Math.floor(nPastHour / 24)

        this.notifyFutureMin = this.taskDisplayConfiguration.nofify_display_future_min % 60
        var nFutureHour = Math.floor(this.taskDisplayConfiguration.nofify_display_future_min / 60)
        this.notifyFutureHour = nFutureHour % 24
        this.notifyFutureDay = Math.floor(nFutureHour / 24)
      }
    },

    cancelUpdate () {
      this.initAttributes()
      this.$emit('cancelUpdateEvent')
    },

    submitUpdate () {
      if (this.allAttributeValid) {
        var formData = new FormData()
        formData.append('task_display_configuration[update_pooling_span]', this.updatePoolingSpan)
        formData.append('task_display_configuration[notify_pooling_span]', this.notifyPoolingSpan)
        formData.append('task_display_configuration[display_past_min]', ((this.displayPastDay * 24 * 60) + (this.displayPastHour * 60) + this.displayPastMin))
        formData.append('task_display_configuration[display_future_min]', ((this.displayFutureDay * 24 * 60) + (this.displayFutureHour * 60) + this.displayFutureMin))
        formData.append('task_display_configuration[notify_display_past_min]', ((this.notifyPastDay * 24 * 60) + (this.notifyPastHour * 60) + this.notifyPastMin))
        formData.append('task_display_configuration[nofify_display_future_min]', ((this.notifyFutureDay * 24 * 60) + (this.notifyFutureHour * 60) + this.notifyFutureMin))
        TaskDisplayConfiguration.api().update({ formData: formData }).then(res => {
          console.log(res)
          this.$emit('updateFinishEvent')
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.horus-task-display-configure-modal {
  .modal-body {
    padding: 10px;
    input[type="number"] {
      width: 40px;
    }
    .row-input-group {
      .cols-input-group {
        margin-top: 5px;
      }
    }
    .note-for-input {
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
      font-size: 0.9em;
    }
    .execute_controlls {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-top: 10px;
      .executable_buttons {
        button {
          width: 100px;
          margin-right: 10px;
          padding: 3px 10px;
          font-weight: bold;
          font-size: 14px;
          border-radius: 3px;
          &.record_post_clear {
            background-color: #fff;
            color: #333;
            border: 1px solid #999;
          }
          &.record_post_submit {
            background-color: #aaa;
            color: #ddd;
            border: 1px solid #aaa;
            &.enable {
              background-color: #666;
              color: #fff;
              border: 1px solid #666;
            }
          }
        }
      }
    }
  }
}
</style>
