import axios from './AxiosInstance'

// object info
// { user_id: number as ID,
//   family_name: String,
//   given_name: String,
//   family_name_kana: String,
//   given_name_kana: String,
//   sex_type: number,
//   birth_date: String as Date,
//   blood_type_abo: number,
//   blood_type_rh: number,
//   updated_at: String as Date,
//   deleted: boolean,
//   author_id: number as ID }

export default {
  fetch: ({timelineOwnerID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'user_basic',
        params: { target_user_id: timelineOwnerID },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ user_basic: res.data.user_basic })
        }
      }).catch(error => { reject(error) })
    })
  },

  // createのみ。updateはない。
  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'user_basic',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
