<template>
  <div class="horus-user-address-form">
    <div class="form-list-line" v-if="userAddress">
      <horus-attribute-in-form
        :labelText="'郵便番号'"
        :necessary="true"
        :validationState="userAddress.validateZipCode().state"
        :validationMessage="userAddress.validateZipCode().message"
        :attributeNotify="'ハイフン区切り半角数字: 000-0000'">
        <input type="text" v-model="userAddress.zipCode" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'都道府県'"
        :necessary="true"
        :validationState="userAddress.validatePrefecture().state"
        :validationMessage="userAddress.validatePrefecture().message"
        :attributeNotify="''">
        <select class="sex-type-select" v-model="userAddress.prefecture">
          <option v-for="prefCode in userAddressData.PREFECTURE_CODE_JP" :key="'prefectureSelect' + prefCode[0]" :value="prefCode[0]">{{ prefCode[1] }}</option>
        </select>
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'市町村区'"
        :necessary="true"
        :validationState="userAddress.validateCity().state"
        :validationMessage="userAddress.validateCity().message"
        :attributeNotify="'80文字以内'">
        <input type="text" class="long-text-input" v-model="userAddress.city" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'住所詳細'"
        :necessary="false"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="'(各種依頼を作成する際には必須です)'">
        <input type="text" class="long-text-input" v-model="userAddress.address" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'電話番号'"
        :necessary="true"
        :validationState="userAddress.validateTel().state"
        :validationMessage="userAddress.validateTel().message"
        :attributeNotify="'ハイフン区切り半角数字: 000-000-0000'">
        <input type="text" v-model="userAddress.tel" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'携帯電話番号'"
        :necessary="true"
        :validationState="userAddress.validateCellphone().state"
        :validationMessage="userAddress.validateCellphone().message"
        :attributeNotify="'ハイフン区切り半角数字: 000-0000-0000'">
        <input type="text" v-model="userAddress.cellphone" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'FAX番号'"
        :necessary="true"
        :validationState="userAddress.validateFax().state"
        :validationMessage="userAddress.validateFax().message"
        :attributeNotify="'ハイフン区切り半角数字: 000-000-0000'">
        <input type="text" v-model="userAddress.fax" />
      </horus-attribute-in-form>
    </div>
    <div class="submit-controlls">
      <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
      <button cype="button" class="submit-btn" @click="executeSubmit" :class="{ enable: (userAddress && userAddress.isValid()) }">{{ submitTitle }}</button>
    </div>
  </div>
</template>

<script>
import UserAddress from '@/validateModel/UserAddress.js'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

import * as USER_ADDRESS from '@/assets/constants/addressData.js'

export default {
  name: 'HorusUserAddressForm',

  components: {
    HorusAttributeInForm
  },

  props: {
    timelineOwner: Object,
    userAddressSource: Object,
    submitTitle: String
  },

  data () {
    return {
      userAddress: null,
      userAddressData: USER_ADDRESS
    }
  },

  watch: {
    userAddressSource: { handler: 'initObject', immediate: true }
  },

  methods: {
    async initObject () {
      if (this.userAddressSource) {
        this.userAddress = new UserAddress(this.userAddressSource)
      } else {
        this.userAddress = new UserAddress()
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    executeSubmit () {
      if (this.userAddress.isValid) {
        this.$emit('executeSubmitEvent', this.userAddress.toFormData(this.timelineOwner, this.portrateObj))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-user-address-form {
  margin: 10px;
  input[type="text"] {
    font-size: 16px;
    &.long-text-input {
      width: 300px;
    }
  }
  select {
    padding: 5px;
    height: 28px;
    font-size: 16px;
    background-color: #999;
    color: #fff;
  }
  .submit-controlls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      &.cancel-btn {
        background-color: #fff;
        color: #666;
      }
      &.submit-btn {
        background-color: #666;
        color: #fff;
      }
    }
  }
}
</style>
