<template>
  <div class="horus-system-manager-edit-modal">
    <horus-modal-frame
      :showModalFlag="showSystemManagerEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />システム管理者編集</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'名前'"
          :necessary="true"
          :validationState="validateName"
          :validationMessage="validateNameMessage"
          :attributeNotify="''">
          <input type="text" v-model="name" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">更 新</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemManagerEditModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm
  },

  props: {
    sourceManager: Object,
    showSystemManagerEditModal: Boolean
  },

  data () {
    return {
      name: ''
    }
  },

  watch: {
    sourceManager: { handler: 'initDatas', immediate: true }
  },

  computed: {
    validateName () {
      if (this.name.length > 0) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateNameMessage () {
      if (this.name.length > 0) {
        return ''
      } else {
        return '名前は入力必須です。'
      }
    },

    allValid () {
      if (this.validateName === 'valid') {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initDatas () {
      if (this.sourceManager) {
        this.name = this.sourceManager.name
      } else {
        this.name = ''
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', this.initDatas)
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('id', this.sourceManager.id)
        formData.append('system_manager[name]', this.name)
        this.$emit('submitEvent', formData, this.initDatas)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-system-manager-edit-modal {
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
