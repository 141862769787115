import axios from './AxiosInstance'

export default {
  toManager: ({organizationID, userID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('subject_user_id', userID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_manager/to_manager',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  toMemberManager: ({organizationID, userID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('subject_user_id', userID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_manager/to_member_manager',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  toRelationManager: ({organizationID, userID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('subject_user_id', userID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_manager/to_relation_manager',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  toDeputizeManager: ({organizationID, userID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('subject_user_id', userID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_manager/to_deputize_manager',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  revokeManager: ({organizationID, userID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_manager/revoke_manager',
            withCredentials: true,
            params: { organization_id: organizationID, subject_user_id: userID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  revokeMemberManager: ({organizationID, userID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_manager/revoke_member_manager',
            withCredentials: true,
            params: { organization_id: organizationID, subject_user_id: userID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  revokeRelationManager: ({organizationID, userID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_manager/revoke_relation_manager',
            withCredentials: true,
            params: { organization_id: organizationID, subject_user_id: userID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reovkeDeputizeManager: ({organizationID, userID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_manager/revoke_deputize_manager',
            withCredentials: true,
            params: { organization_id: organizationID, subject_user_id: userID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
