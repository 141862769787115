<template>
  <div class="horus-sent-inbitation-column labeled_content_block">
    <div class="labeled_content_single_block" v-if="sentRequest.login_id">
      <label>NBID</label>
      <div class="content">{{ sentRequest.login_id }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentRequest.name">
      <label>名前</label>
      <div class="content">{{ sentRequest.name }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentRequest.mail">
      <label>メールアドレス</label>
      <div class="content">{{ sentRequest.mail }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentRequest.message_content">
      <label>メッセージ</label>
      <div class="content">{{ sentRequest.message_content }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentRequest.mail">
      <label>本人確認コード</label>
      <div class="content">{{ sentRequest.short_token }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ sentRequest.status }}</div>
    </div>
    <div class="content-controll-block">
      <button type="button" @click="sendMail" v-if="!sentRequest.used && sentRequest.login_id">期限のリセット</button>
      <button type="button" @click="sendMail" v-if="!sentRequest.used && sentRequest.mail">再度送信</button>
      <button type="button" @click="removeRequest" v-if="!sentRequest.used">申請取り消し</button>
      <button type="button" @click="removeRequest" v-if="sentRequest.used">このリストから削除</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusSentInvitationColumn',

  props: {
    sentRequest: Object
  },

  methods: {
    sendMail () {
      this.$emit('sendMailEvent', this.sentRequest)
    },

    removeRequest () {
      this.$emit('removeRequestEvent', this.sentRequest)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
// .horus-sent-inbitation-column {
//   display: flex;
//   flex-wrap: wrap;
//   margin-bottom: 10px;
//   padding: 5px;
//   border: 1px solid #aaa;
//   .content_controll_block {
//     width: 100%;
//     text-align: right;
//     button {
//       cursor: pointer;
//       border: 1px solid #999;
//       border-radius: 3px;
//       background-color: #999;
//       color: #fff;
//       font-weight: bold;
//     }
//   }
//   .labeled_content_single_block {
//     display: flex;
//     flex-wrap: wrap;
//     align-items: baseline;
//     min-width: 300px;
//     margin: 5px;
//     margin-right: 10px;
//     label {
//       margin: 0;
//       margin-right: 5px;
//       font-weight: normal;
//       font-size: 0.9em;
//       color: #666;
//     }
//     .content {
//       display: inline-block;
//       font-size: 1.1em;
//       img {
//         width: 20px;
//         margin-right: 5px;
//       }
//       .change-family-state-btn {
//         background-color: #999;
//         color: #fff;
//         border: 1px solid #999;
//         border-radius: 3px;
//         font-size: 13px;
//         font-weight: bold;
//       }
//     }
//   }
// }
</style>
