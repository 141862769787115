<template>
  <div class="activity-log-in-list" :class="{ 'clicked': activityLog.clicked }" @click="moveToLink">
    <a href="#">
      <div class="activity-log-title" v-html="titleContentHtml"></div>
      <div class="activity-log-content">{{ activityLog.content }}</div>
    </a>
  </div>
</template>

<script>
import * as ActivityLogStatics from '@/models/ActivityLog'

export default {
  name: 'HorusActivityLogInList',

  props: {
    activityLog: null
  },

  computed: {
    titleContentHtml: function () {
      let contentHtml = '<span class="activity-log-created">' + this.activityLog.createdAtStr + '</span>'
      switch (Number(this.activityLog.category)) {
        case ActivityLogStatics.ACTIVITY_LOG_CATEGORY_FAMILY:
          contentHtml += '<span class="activity_log_title">家族</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_CATEGORY_RELATION:
          contentHtml += '<span class="activity_log_title">かかりつけ</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_CATEGORY_DEPUTIZE:
          contentHtml += '<span class="activity_log_title">設定代行</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_CATEGORY_ORGANIZATION_MEMBER:
          contentHtml += '<span class="activity_log_title">施設管理</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_ICON:
          contentHtml += '<img src="/static/images/notify_icon.png" />'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_MEMBER_ICON:
          contentHtml += '<span class="activity_log_tag member">職員</span>' + '<img src="/static/images/notify_icon.png" />'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_MEMBER:
          contentHtml += '<span class="activity_log_tag member">職員</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_AGENT:
          contentHtml += '<span class="activity_log_tag agent">設定代行</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_MEMBER_MANAGER:
          contentHtml += '<span class="activity_log_tag member_manager">施設管理</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_RELATION_MANAGER:
          contentHtml += '<span class="activity_log_tag relation_manager">かかりつけ管理</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_DEPUTIZE_MANAGER:
          contentHtml += '<span class="activity_log_tag deputize_manager">設定代行管理</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_REPORTER_MEMBER:
          contentHtml += '<span class="activity_log_tag reporter_member">報告職員</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_REPORTER_MANAGER:
          contentHtml += '<span class="activity_log_tag reporter_manager">報告施設管理</span>'
          break
        case ActivityLogStatics.ACTIVITY_LOG_TITLE_NONE:
          contentHtml += ''
          break
        default:
          contentHtml += '<span class="activity_log_title"><img src="/static/images/notify_icon.png" /></span>'
          break
      }
      contentHtml += '<span class="activity-log-title-text">' + this.activityLog.title + '</span>'
      return contentHtml
    }
  },

  methods: {
    moveToLink () {
      this.$emit('moveToLinkEvent', this.activityLog)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-log-in-list {
  margin: 0;
  padding: 0;
  background-color: #fff;
  &.clicked {
    background-color: #eee;
  }
  a {
    display: inline-block;
    margin: 0;
    padding: 5px 10px;
    font-size: 0.8em;
    color: #666;
    font-weight: bold;
    :deep() .activity-log-title {
      min-height: 20px;
      img {
        margin: 0 5px;
        height: 15px;
        float: none;
      }
      span.activity-log-created {
        color: #999;
      }
      span.activity_log_title {
        margin-left: 5px;
        img {
          margin: 0;
          height: 15px;
          float: none;
        }
      }
      span.activity-log-title-text {
        margin-left: 5px;
      }
      span.activity_log_tag {
        margin-left: 5px;
        padding: 2px 3px;
        background-color: #999;
        color: #fff;
        font-size: 0.9em;
        &.member {
          padding: 1px 2px;
          color: #7b8d42;
          background-color: #fff;
          border: 1px solid #7b8d42;
        }
        &.agent {
          padding: 1px 2px;
          color: #9079ad;
          background-color: #fff;
          border: 1px solid #9079ad;
        }
        &.member_manager {
          background-color: #7b4741;
        }
        &.relation_manager {
          background-color: #507ea4;
        }
        &.deputize_manager {
          background-color: #915c8b;
        }
        &.reporter_member {
          background-color: #9079ad;
        }
        &.reporter_manager {
          background-color: #9079ad;
        }
      }
    }
    .activity-log-content {
      font-weight: normal;
      color: #333;
    }
  }
}
</style>
