<template>
  <div class="horus-submit-family-view  container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="content">
      <h1 class="centering-title">ナラティブブック家族申請</h1>
      <div class="pre-process" v-if="validationFinished.length <= 0 && isTokenValid">
        <div class="message">
          <div class="system-message">
            {{ requestUserName }}さんから家族申請が届いています。<br/>
            下記いずれかのボタンを押して処理を進めるか、このままページを閉じてください。<br/>
            ボタンを押さない限り、家族申請は処理されません。<br/>
          </div>
          <div class="request-message" v-if="requestMessage.length > 0">
            <h5>{{ requestUserName }}さんからの申請メッセージ</h5>
            <div class="request-message-content" v-html="requestMessageHTML"></div>
          </div>
          <div class="limit">利用期限: {{ limitAtStr }}</div>
        </div>
        <div class="accept-form">
          <h2>利用者ID(NBID)をお持ちの方</h2>
          <div class="caption">
            事前に知らされている本人確認コードと、<br/>
            相互にタイムラインを見られるようにするかの設定を選択し、<br/>
            ナラティブブックのログインに用いる利用者ID(NBID)とパスワードを入力ください。<br/>
          </div>
          <div class="input-block">
            <label>本人確認コード:</label>
            <input type="text" class="short-token-input" cols="6" v-model="shortToken" />
          </div>
          <div class="input-block">
            <label>利用者ID(NBID):</label>
            <input type="text" class="nbid-input" v-model="nbid" />
          </div>
          <div class="input-block">
            <label>パスワード：</label>
            <input type="password" class="password-input" v-model="password" />
          </div>
          <div class="input-block">
            <div class="admit-agreement" @click="toggleBoth">
              <horus-custom-check-box
                :active="bothFollow"
                :enabled="true"
                :type="'color'"
                />
              お互いにタイムラインを見られるようにする。(チェックを外すと、相手からあなたのタイムラインを見ることは出来ません)<br/>
              ※すでに相手が自分のタイムラインを見ることが出来る状態にある場合、この設定は無効です。
            </div>
          </div>
          <div class="input-block">
            <button type="button" class="admit-by-signin-btn" :class="{disable: !validForSignin}" @click="admitBySignin">ログインして承認</button>
          </div>
        </div>
        <div class="accept-form">
          <h2>利用者ID(NBID)をお持ちでない方</h2>
          <div class="caption">
            ナラティブブックの利用者IDをお持ちでない方は、事前に知らされている本人確認コードを入力の上、<br/>
            相互にタイムラインを見られるようにするかの設定を選択し、<br/>
            下記のボタンから利用者登録を行ってください。
          </div>
          <div class="input-block">
            <label>本人確認コード:</label>
            <input type="text" class="short-token-input" cols="6" v-model="shortToken" />
          </div>
          <div class="input-block">
            <div class="admit-agreement" @click="toggleBoth">
              <horus-custom-check-box
                :active="bothFollow"
                :enabled="true"
                :type="'color'"
                />
              お互いにタイムラインを見られるようにする。(チェックを外すと、相手からあなたのタイムラインを見ることは出来ません)
            </div>
          </div>
          <div class="input-block">
            <button type="button" class="admit-with-create-btn" :class="{disable: !validForCreate}" @click="admitWithCreate">新規利用者登録</button>
          </div>
        </div>
      </div><!-- pre process -->
      <div class="processed" v-if="validationFinished === 'withSignin' && isTokenValid">
        <h2>承認処理完了</h2>
        <div class="message">
          {{ requestUserName }}さんの家族申請を承認しました。<br/>
          以後、あなたは{{ requestUserName }}さんの家族として、{{ requestUserName }}さんのタイムラインを閲覧、書き込み出来るようになります。<br/>
        </div>
        <div class="finish-controlls">
          <button type="button" class="start-btn" @click="doSignin">ログインして続行</button>
          <button type="button" class="finish-btn" @click="backToLanding">ログインせずに終了</button>
        </div>
      </div>
      <div class="processed" v-if="validationFinished === 'withCreate' && isTokenValid">
        <h2>承認処理完了</h2>
        <div class="message">
          {{ requestUserName }}さんの家族申請を照合しました。<br/>
          ナラティブブック利用者登録を完了させると、{{ requestUserName }}の家族となります。<br/>
        </div>
        <div class="finish-controlls">
          <button type="button" class="start-btn" @click="createUser">利用者登録</button>
        </div>
      </div>
      <div class="token-failed" v-if="!isTokenValid">
        <h4>案内状の検証失敗</h4>
        家族申請の承認処理に利用するURLが正しくありません。<br/>
        メールに記されたURLが正しく入力されていないか、利用期限を過ぎているか、試行回数を超えて本人確認コードを間違えたか、またはすでに利用済の可能性があります。<br/>
        メールのリンクを全てコピーして、ブラウザのアドレスバーにペーストするなどして正しいURLを入力ください。<br/>
        正しくURLが入力されているにもかかわらずこの状態となっている場合は、送信した利用者に問い合わせて、案内を再発行してもらってください。<br/>
      </div>
    </div><!-- content -->
    <horus-footer />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  SigninUser
} from '@/models'
import { AuthorizedFamilyInvitation } from '@/api'

import moment from 'moment'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSubmitFamilyView',

  components: {
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      requestUserName: '',
      limitAt: null,
      requestMessage: '',
      isTokenValid: true,
      shortToken: '',
      nbid: '',
      password: '',
      bothFollow: false,
      validationFinished: '',
      signinUser: null
    }
  },

  computed: {
    limitAtStr () {
      if (this.limitAt) {
        return moment(this.limitAt).format('YYYY年MM月DD日 HH時mm分')
      } else {
        return '--------'
      }
    },

    requestMessageHTML () {
      if (this.requestMessage && this.requestMessage.length > 0) {
        return this.requestMessage.replace(/\n/gi, '<br />')
      }
      return '施設からのメッセージはありません。'
    },

    validForCreate () {
      if (this.shortToken && this.shortToken.trim().match(/^\d{4}$/g)) {
        return true
      } else {
        return false
      }
    },

    validForSignin () {
      if (this.shortToken && this.shortToken.trim().match(/^\d{4}$/g) && this.nbid && this.nbid.length > 5 && this.password && this.password.length > 7) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  methods: {
    async initToken () {
      this.token = this.$attrs.token
      try {
        let res = await AuthorizedFamilyInvitation.fetchReceive({ invitationToken: this.token })
        this.requestUserName = res.sender_name
        this.limitAt = new Date(res.limit_at)
        this.isTokenValid = true
        this.requestMessage = res.message_content
      } catch (error) {
        console.error(error.response)
        if (error.response.status === 404) {
          this.isTokenValid = false
        }
      }
    },

    toggleBoth () {
      this.bothFollow = !this.bothFollow
    },

    admitWithCreate () {
      AuthorizedFamilyInvitation.validateToken({ invitationToken: this.token, shortToken: this.shortToken }).then(() => {
        this.validationFinished = 'withCreate'
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('家族申請の承認失敗', '家族申請の承認に失敗しました。\n本人確認コードが正しくないようです。\n正しい本人確認コードを入力してください。\n最大試行回数の3回を超えて間違っている場合は、この案内は無効となっているので、送信者に申請の再発行を依頼してください。')
      })
    },

    admitBySignin () {
      AuthorizedFamilyInvitation.admitWithToken({ invitationToken: this.token, shortToken: this.shortToken, loginID: this.nbid, password: this.password, both: this.bothFollow }).then(() => {
        this.validationFinished = 'withSignin'
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('家族申請の承認失敗', '家族申請の承認に失敗しました。\n本人確認コードが正しくないか、ログインIDかパスワードを間違えているようです。\n正しい情報を入力してください。\n本人確認コードを、最大試行回数の3回を超えて間違っている場合は、この案内は無効となっているので、送信者に申請の再発行を依頼してください。')
      })
    },

    doSignin () {
      SigninUser.api().fetch({ loginID: this.nbid, password: this.password }).then(() => {
        const allUser = SigninUser.all()
        this.signinUser = allUser[0]
      })
      this.$router.push({ path: '/maintimeline' })
    },

    backToLanding () {
      this.$router.push({ path: '/signout' })
    },

    createUser () {
      this.$router.push({ path: '/create_user', query: { token: this.token, tokenType: 'family', shortToken: this.shortToken, both: this.bothFollow } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-submit-family-view {
  height: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  .content {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.1em;
      font-weight: normal;
    }
    .message {
      margin: 10px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      background-color: #fff;
      text-align: center;
      @include mediaQuery('phone') {
        margin: 10px 0;
        padding: 10px;
      }
      .system-message {
        @include mediaQuery('phone') {
          text-align: left;
        }
      }
      .request-message {
        margin: 10px 0;
        h5 {
          margin: 10px 0 5px 0;
          font-size: 1em;
          font-weight: bold;
        }
        .request-message-content {
          padding: 10px;
          border: 1px solid #ddd;
        }
      }
    }
    .accept-form {
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      background-color: #fff;
      @include mediaQuery('phone') {
        margin: 10px 0;
        padding: 10px;
      }
      h2 {
        margin: 10px;
        color: #b94047;
        text-align: center;
      }
      .caption {
        margin: 10px auto;
      }
      .input-block {
        margin: 5px auto;
        label {
          display: inline-block;
          width: 120px;
          text-align: right;
        }
        input {
          width: 150px;
          font-size: 16px;
          border: 1px solid #aaa;
        }
        button {
          margin: 20px 0;
          padding: 3px 10px;
          border-radius: 5px;
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          &.disable {
            border: #aaa;
            background-color: #aaa;
          }
        }
      }
    }
    .finish-controlls {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
      button {
        margin: 10px 0;
        padding: 3px 10px;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        &.start-btn {
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
        }
        &.finish-btn {
          border: 1px solid #ee7800;
          background-color: #ee7800;
        }
      }
    }
    .token-failed {
      margin: 10px 20px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #b94047;
      h4 {
        margin: 5px;
        color: #b94047;
        text-align: center;
      }
    }
  }
}
</style>
