import axios from './AxiosInstance'

export default {

  fetch: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'static_identify_code',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ staticIdentifyCodes: res.data.static_identify_codes })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'static_identify_code',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({targetUserID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'static_identify_code',
            withCredentials: true,
            params: { target_user_id: targetUserID, static_identify_code_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchUser: ({categoryID, IDCode}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'static_identify_code/user',
        withCredentials: true,
        params: { static_identify_code_category_id: categoryID, identify_code: IDCode }
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  }

}
