import axios from './AxiosInstance'

export default {
  fetchList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'member_portal_showing_user/list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ portal_showing_users: res.data.cgc_portal_showing_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({targetUserID}) => {
    var formData = new FormData()
    formData.append('subject_user_id', targetUserID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'member_portal_showing_user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({targetUserID}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'member_portal_showing_user',
            withCredentials: true,
            params: { subject_user_id: targetUserID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
