<template>
  <div class="horus-multipost-form-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/task_pad_icon_s.png" class="title-icon" />予定登録先利用者選択</h1>
      </div>
      <div class="modal-body">

        <div class="post-targets">
          <div class="post-target-column" v-for="timelineOwner in multipostTargets" :key="'usrMultipostTarget' + timelineOwner.user_id">
            <img :src="timelineOwner.author.portrateSrc" />
            <div class="name-column">{{ timelineOwner.author.name }}({{ timelineOwner.author.age }})</div>
          </div>
        </div>
        <div class="alway_display">
          <div class="alway_display_right">
            <textarea placeholder="ここに書いてください" class="form-control text-comment js_target_text-comment" rows="1" ref="textCommentField" v-model="postRecord.textComment"></textarea>
          </div>
        </div><!-- always display end -->
        <div class="post_controlls">
          <horus-multi-photo-selector
            ref="multiPhotoSelector"
            :photos="postRecord.photos"
            :photoInputID="photoInputID"
            v-on:newPhotoPreparedEvnt="addSelectedPhoto"
            v-on:photoRemoveEvent="removePhoto"
            />
          <horus-multi-file-selector
            ref="multiFileSelector"
            :files="postRecord.files"
            :fileInputID="fileInputID"
            :displaySize="''"
            v-on:newFilePreparedEvent="addSelectedFile"
            v-on:fileRemoveEvent="removeFile"
            />
          <div class="video-input-column">
            <input type="file" :id="videoInputID" :name="videoInputID" accept="video/*" capture style="display: none;cursor: pointer;" ref="videoInput" :data-duration="videoSetting.duration" :data-size="videoSetting.size" @change="postVideoChange" />
            <div class="selected-video" v-if="postRecord.video.uploadFile">
              <img src="/static/images/video_upload_icon.png" class="selected-video-icon" />
              <img src="/static/images/red_batsu_icon.png" class="selected-video-remove-icon" @click="removeVideo" />
            </div>
          </div>
          <div class="audio-input-column">
            <input type="file" :id="audioInputID" :name="audioInputID" accept="audio/*" capture style="display: none;cursor: pointer;" ref="audioInput" :data-duration="audioSetting.duration" @change="postAudioChange" />
            <div class="selected-audio" v-if="postRecord.audio.uploadFile">
              <img src="/static/images/audio_upload_icon.png" class="selected-audio-icon" />
              <img src="/static/images/red_batsu_icon.png" class="selected-audio-remove-icon" @click="removeAudio" />
            </div>
          </div>
          <div class="add_icons js_group_post_inputs">
            <div class="left_add_icons">
              <div class="icon-button" id="add_photo_block">
                <label :for="photoInputID">
                  <img src="/static/images/camera_icon.png" class="add-photo" @click="addPhotoForPost"/>
                </label>
              </div>
              <div class="icon-button" id="add_file_block">
                <label :for="fileInputID">
                  <img src="/static/images/document_icon.png" class="add-file" @click="addFileForPost"/>
                </label>
              </div>
              <div class="icon-button" id="add_video_block" v-if="videoUploadAvailable">
                <label :for="videoInputID">
                  <img src="/static/images/video_icon.png" class="add-video" @click="addVideoForPost"/>
                </label>
              </div>
              <div class="icon-button" id="add_audio_block" v-if="audioUploadAvailable">
                <label :for="audioInputID">
                  <img src="/static/images/mic_icon.png" class="add-audio" @click="addAudioForPost"/>
                </label>
              </div>
            </div>
          </div><!-- add_icons end -->
          <div class="execute_controlls">
            <div class="left_execute_controlls">
              <button class="target-date-button" type="button" v-if="!targetDate.change" @click="setTargetDate">日時変更</button>
              <div class="target-date-display" v-else >
                <span class="date-display" @click="setTargetDate">{{ targetDateStr }}</span>
                <img src="/static/images/batsu_icon.png" @click="clearTargetDate" />
              </div>
            </div>
            <div class="right_execute_controlls">
              <div class="executable_buttons">
                <button type="button" class="record_post_clear" @click="clearPostForm">キャンセル</button>
                <button type="button" class="record_post_submit" @click="submitPostForm">投&nbsp;&nbsp;稿</button>
              </div>
            </div>
          </div><!-- execute controll end -->
        </div>

      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'
import HorusMultiFileSelector from '@/components/common/HorusMultiFileSelector.vue'

export default {
  name: 'HorusMultipostFormModal',

  components: {
    HorusModalFrame,
    HorusMultiPhotoSelector,
    HorusMultiFileSelector,
  },

  props: {
    showModal: Boolean,
    signinMember: Object,
    multipostTargets: Array,
    targetDate: Object
  },

  data () {
    return {
      photoInputID: 'photoInput',
      fileInputID: 'fileInput',
      videoInputID: 'videoInput',
      audioInputID: 'audioInput',
      videoSetting: { duration: 10, size: '1080p/30fps' },
      audioSetting: { duration: 60 },
      postRecord: {},
      isShowButtonNative: false,
      videoUploadAvailable: false,
      audioUploadAvailable: false
    }
  },

  computed: {
    targetDateStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    targetTimeStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('HH:mm')
      }
      return ''
    }
  },

  created () {
    this.initForm()
    this.initPostRecord()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
    this.updateUploadButtonStatus()
  },

  updated () {
    if (this.$native.status === true) {
      this.isShowButtonNative = this.$native.status
    }
    this.updateUploadButtonStatus()
  },

  watch: {
    'postRecord.textComment': { handler: 'fixTextCommentArea' }
  },

  methods: {
    initForm () {
      this.photoInputID = 'photoInput' + Math.floor(Math.random() * 10000)
      this.fileInputID = 'fileInput' + Math.floor(Math.random() * 10000)
    },

    // init main data
    initPostRecord () {
      this.postRecord = {
        textComment: '',
        photos: [],
        files: [],
        video: {},
        audio: {}
      }
    },

    updateUploadButtonStatus () {
      if (this.isShowButtonNative) {
        if (process.env.NODE_ENV === 'production') {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        } else if (process.env.NODE_ENV === 'staging') {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        } else {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        }
      } else {
        if (process.env.NODE_ENV === 'production') {
          this.videoUploadAvailable = false
          this.audioUploadAvailable = false
        } else if (process.env.NODE_ENV === 'staging') {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        } else {
          this.videoUploadAvailable = true
          this.audioUploadAvailable = true
        }
      }
    },

    medExWatch () {
      console.log('med ex watch call -----------------------------------------------------------')
    },

    // visual controlls
    fixTextCommentArea () {
      this.HorusHashTagDisplay = false
      this.$nextTick(() => {
        var textArea = this.$refs.textCommentField
        if (textArea) {
          // 一度高さをリセットしないと、scrollHeightが正しく取得出来ない。ただし、ちょっとちらついてしまう場合がある
          textArea.style.height = '26px'
          if (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = textArea.scrollHeight + 'px'
          }
        }
      })
    },

    // multi photo selector controlls
    addPhotoForPost () {
      // this.activateForm()
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    addSelectedPhoto (photoObj) {
      this.postRecord.photos.push(photoObj)
    },

    removePhoto (index) {
      this.postRecord.photos.splice(index, 1)
    },

    // multi file selector controlls
    addFileForPost () {
      // this.activateForm()
      if (this.$native.status) {
        this.$native.event('takeFileEvent', { targetID: this.fileInputID })
      }
    },

    addSelectedFile (fileObj) {
      this.postRecord.files.push(fileObj)
    },

    removeFile (index) {
      this.postRecord.files.splice(index, 1)
    },

    addVideoForPost () {
      // this.activateForm()
      if (this.$native.status) {
        this.$native.event('takeVideoEvent', { duration: this.videoSetting.duration, size: this.videoSetting.size, targetID: this.videoInputID })
      }
    },

    postVideoChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.postRecord.video = { uploadFile: selectedFiles[0], name: selectedFiles[0].name }
      }
      this.$nextTick(() => {
        this.$refs.videoInput.value = ''
      })
    },

    removeVideo () {
      this.postRecord.video = {}
    },

    addAudioForPost () {
      // this.activateForm()
      if (this.$native.status) {
        // alert('add audio, native:' + JSON.stringify(this.$native))
        this.$native.event('takeAudioEvent', { duration: this.audioSetting.duration, targetID: this.audioInputID })
      }
    },

    postAudioChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.postRecord.audio = { uploadFile: selectedFiles[0], name: selectedFiles[0].name }
      }
      this.$nextTick(() => {
        this.$refs.audioInput.vaule = ''
      })
    },

    removeAudio () {
      this.postRecord.audio = {}
    },

    setTargetDate () {
      this.$emit('setTargetDateForPostEvent')
    },

    clearTargetDate () {
      this.$emit('clearTargetDateForPostEvent')
    },

    clearPostForm () {
      this.$emit('postformCancelEvent', this.executeClearForm)
    },

    executeClearForm () {
      this.initPostRecord()
    },

    submitPostForm () {
      console.log('submitPostForm!!!')
      // CHECK FORM DATA
      if (
        (!this.postRecord.textComment || this.postRecord.textComment.length === 0) &&
        this.postRecord.photos.length === 0 &&
        this.postRecord.files.length === 0 &&
        !this.postRecord.video.uploadFile &&
        !this.postRecord.audio.uploadFile
      ) {
        console.log('post contents is empty!')
        this.$emit('showNotifyEvent', '投稿内容のエラー', '投稿しようとする書き込みに、内容がありません。\n文章、写真、添付ファイルなどの何らかの内容を入力してください。')
      } else {
        // CREATE FORM DATA
        var formData = new FormData()
        formData.append('record[multipost]', true)
        this.multipostTargets.forEach(target => {
          formData.append('record[multipost_targets][]', target.user_id)
        })
        if (this.targetDate.change) {
          formData.append('record[nb_calendar_target_date]', moment(this.targetDate.date).format('YYYY/MM/DD'))
          formData.append('record[nb_calendar_target_time]', moment(this.targetDate.date).format('HH:mm'))
        }
        formData.append('record[text_comment]', this.postRecord.textComment)
        this.postRecord.photos.forEach(photo => {
          formData.append('record[photos][]', photo.uploadFile, photo.name)
        })
        this.postRecord.files.forEach(file => {
          formData.append('record[files][]', file.uploadFile, file.name)
        })
        if (this.postRecord.video.uploadFile) {
          formData.append('record[video]', this.postRecord.video.uploadFile, this.postRecord.video.name)
        }
        if (this.postRecord.audio.uploadFile) {
          formData.append('record[audio]', this.postRecord.audio.uploadFile, this.postRecord.audio.name)
        }
        // send form data
        this.$emit('submitPostFormEvent', formData, () => {
          this.initPostRecord()
        })
      }
    }

  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
.modal-body {
  padding: 10px;
}
.post-targets {
  display: flex;
  flex-wrap: wrap;
  .post-target-column {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    img {
      margin-right: 5px;
      width: 30px;
      height: 30px;
    }
    .name-column {
      font-weight: bold;
    }
  }
}
.alway_display {
  display: flex;
  .alway_display_right {
    width: calc(100% - 70px);
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      border: 1px solid #aaa;
    }
    .small_controll_icons {
      // margin-top: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .left_buttons {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        img {
          margin: 0 3px 0 10px;
          width: 30px;
          height: 30px;
          @include mediaQuery('phone') {
            margin: 0 3px 0 3px;
          }
        }
      }
      .right_buttons {
        display: flex;
        justify-content: flex-end;
        img {
          margin: 0 3px 10px 3px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
.post_controlls {
  margin-top: 10px;
  padding: 0;
  padding-top: 5px;
  border-top: 1px solid #aaa;
  .video-input-column {
    .selected-video {
      position: relative;
      width: 105px;
      .upload-video-icon {
        position: absolute;
        top: 0;
        left: 0;
      }
      .selected-video-remove-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .audio-input-column {
    .selected-audio {
      position: relative;
      width: 65px;
      .upload-audio-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 52px;
      }
      .selected-audio-remove-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .add_icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    input {
      display: none;
    }
    .left_add_icons {
      display: flex;
      justify-content: flex-start;
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
          @include mediaQuery('phone') {
            margin: 0 3px 0 3px;
          }
      }
    }
    .right_add_icons {
      display: flex;
      justify-content: flex-end;
      .icon-button {
        cursor: pointer;
        margin: 0 0 10px 20px;
        @include mediaQuery('phone') {
          margin: 0 3px 0 3px;
        }
        img {
          height: 30px;
        }
      }
    }
  }
}
.medical-examination-display {
  margin-bottom: 10px;
  border: 1px solid #aaa;
  padding: 5px;
}
.execute_controlls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left_execute_controlls {
    margin-top: 10px;
    .target-date-button {
      padding: 3px 10px;
      border: 1px solid #999;
      background-color: #999;
      color: #fff;
      font-weight: lighter;
      font-size: 14px;
    }
    .target-date-display {
      span {
        display: inline-block;
        cursor: pointer;
        height: 19px;
        margin-right: 3px;
        padding: 5px 10px 3px 10px;;
        background-color: #fff;
        color: #333;
        border: 1px solid #999;
        font-size: 16px;
      }
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
  .right_execute_controlls {
    margin-top: 10px;
    text-align: right;
    .executable_buttons {
      button {
        padding: 3px 10px;
        font-weight: lighter;
        font-size: 14px;
        &.disable {
          background-color: #ddd;
          border-color: #999;
          color: #999;
        }
        &.record_post_clear {
          background-color: #fff;
          color: #333;
          border: 1px solid #ddd;
        }
        &.record_post_submit {
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
        }
      }
    }
  }
}
</style>
