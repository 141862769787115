<template>
  <div class="horus-tag-selection-modal-wrapper" v-show="showTagSelectionModal">
    <div class="bn-tag-selection-backdrop" @click="backdropClicked"></div>
    <div class="horus-tag-selection-modal">
      <div class="horus-tag-selection-header" :class="headerClass">
        <img src="/static/images/tag_red.png" v-if="!signinMember && !signinReporter" />
        <img src="/static/images/tag_green.png" v-if="signinMember || signinReporter" />
        タグ選択
      </div>
      <div class="horus-tag-selection-control">
        <button class="horus-tag-selection-clear" type="button" @click="clearSelectedTag">クリア</button>
        <button class="horus-tag-selection-commit" type="button" @click="commitSelectedTag">OK</button>
      </div>
      <div class="tags">
        <div class="tag-list">
          <horus-tag-selection
            v-for="hashTag in hashTags"
            :key="hashTag.id"
            :hashTag="hashTag"
            :enabled="tagEnabled()"
            :active="tagActive(hashTag)"
            v-on:updateStateEvent="updateSelectionState" />
          <div class="new-tag" v-for="(newTag, index) in createTags" :key="'newtag' + index">
            <img src="/static/images/check_on_icon_s.png" @click="removeNewTag(index)" />
            {{ newTag }}
          </div>
        </div>
        <div class="create-tag-column">
          <input type="text" ref="newTagNameInput" placeholder="タグを追加" />
          <button type="button" class="new-tag-add-button" @click="addNewTag">+</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorusTagSelection from '@/components/common/HorusTagSelection.vue'

export default {
  name: 'HorusTagSelectForPostModal',

  components: {
    HorusTagSelection
  },

  props: {
    showTagSelectionModal: Boolean,
    hashTags: Array,
    activeTags: Array,
    createTags: Array,
    signinMember: Object,
    signinReporter: Object
  },

  computed: {
    headerClass () {
      if (this.signinMember) {
        return 'member'
      } else if (this.signinReporter) {
        return 'reporter'
      }
      return ''
    }
  },

  methods: {
    tagEnabled () {
      return (this.activeTags.length > 0)
    },

    tagActive (hashTag) {
      return this.activeTags.some(active => { return active.id === hashTag.id })
    },

    backdropClicked () {
      this.$emit('tagSelectionModalCloseEvent')
    },

    updateSelectionState (targetTag, currentActive, currentEnabled) {
      this.$emit('updateSelectedTagEvent', targetTag, currentActive, currentEnabled)
    },

    addNewTag () {
      var newTagText = this.$refs.newTagNameInput.value
      this.$emit('addNewPostTagEvent', newTagText)
      this.$refs.newTagNameInput.value = ''
    },

    removeNewTag (index) {
      this.$emit('removeNewTagEvent', index)
    },

    clearSelectedTag () {
      this.$emit('clearSelectedTagEvent')
      this.$emit('tagSelectionModalCloseEvent')
    },

    commitSelectedTag () {
      this.$emit('tagSelectionModalCloseEvent')
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

// .horus-tag-selection-modal-wrapper {
// }
.bn-tag-selection-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.2;
}
.horus-tag-selection-modal {
  position: absolute;
  max-width: 924px;
  max-height: calc(100vh - 250px);
  margin: 0;
  padding: 0;
  top: 30px;
  left: calc((100vw - 1000px) / 2 + 30px);
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba($color: #000000, $alpha: 0.175);
  border: 1px solid #aaa;
  border-radius: 4px;
  @include mediaQuery('tablet') {
    width: calc(100vw - 40px);
    left: 20px;
  }
  @include mediaQuery('phone') {
    width: calc(100vw - 10px);
    max-height: calc(100vh - 100px);
    left: 5px;
  }
}
.horus-tag-selection-header {
  padding: 10px 20px;
  background-color: #fff9f5;
  color: #692220;
  font-weight: bold;
  font-size: 12px;
  img {
    margin: 0 5px 3px 0;
    width: 15px;
    vertical-align: middle;
  }
  &.member {
    background-color: #f8fcf9;
    color: #2b4733;
  }
  &.reporter {
    background-color: #eaedf7;
    color: #4f455c;
  }
}
.horus-tag-selection-control {
  padding: 5px 10px;
  text-align: right;
  button {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    width: 100px;
    margin: 0 0 0 10px;
    padding: 5px 20px;
    width: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 10px;
    border-radius: 2px;
  }
  .horus-tag-selection-clear {
    border: 1px solid #aaa;
    background-color: #fff;
    color: #333;
  }
  .horus-tag-selection-commit {
    border: 1px solid #666;
    background-color: #666;
    color: #fff;
  }
}
.tags {
  max-height: calc(100vh - 250px - 78px);
  overflow: scroll;
  @include mediaQuery('phone') {
    max-height: calc(100vh - 100px - 78px);
  }
}
.tag-list {
  padding: 0 10px;
  .new-tag {
    display: inline-block;
    margin: 10px;
    color: #007b43;
  }
}
.create-tag-column {
  padding: 20px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  text-align: right;
  input {
    width: 130px;
    font-size: 16px;
  }
  button {
    background-color: #5cb85c;
    border-color: #4cae4c;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
