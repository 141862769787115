<template>
  <div class="horus-rules">
    <div class="rule-content jp" v-if="displayLang === 'jp'">
      <h2 class="top">第1章&nbsp;総則</h2>
      <h3>（目的等）</h3>
      <h4>第1条</h4>
      <p>この規約（以下「本規約」といいます。）は、クロスケアフィールド株式会社が「ナラティブブック」の名称で提供するシステムの利用に関する条件を、本システムを利用する者と本法人との間で定めるものです。</p>
      <h3>（定義）</h3>
      <h4>第2条</h4>
      <p>本規約において、次に掲げる用語は次の定義とします。</p>
      <p>（1）「本法人」とは、クロスケアフィールド株式会社のことです。</p>
      <p>（2）「本システム」とは、本法人が「ナラティブブック」の名称で、利用者が利用者本人の健康や生活に係る情報を集約、管理又は利用するサービスを提供するシステムのことです。</p>
      <p>（3）「利用者」とは、本法人が本システムを利用することを許諾した個人のことです。</p>
      <p>（4）「家族」とは、利用者が他の利用者に対して家族申請を行い、当該他の利用者が当該利用者の家族になることを承認した場合における、当該他の利用者のことです。家族になるには、利用者として本システムを利用していることが必要です。</p>
      <p>（5）「施設」とは、医療、介護若しくは福祉関連の施設、及び医療、介護若しくは福祉に係る団体または事業所のことです。</p>
      <p>（6）「職員」とは、本法人が本システムの利用を許諾した施設の従事者の地位に基づいて本システムを利用する利用者のことです。職員になるには、利用者として本システムを利用していることが必要です。</p>
      <p>（7）「管理者」とは、当該施設を代理又は代表する地位に基づいて本システムを利用する利用者のことです。管理者になるには、利用者及び当該施設の職員として本システムを利用していることが必要です。</p>
      <p>（8）「ユーザ」とは、本システムを利用する者のことであり、利用者、家族、施設、職員及び管理者の総称です。</p>
      <p>（9）「利用者情報」とは、利用者が本システム上に掲載した利用者の基本情報（氏名、生年月日、性別、血液型など）のことです。</p>
      <p>（10）「特記情報」とは、利用者本人、家族または職員が記載した当該利用者に関わる利用者情報以外の定常的情報若しくは、特に注意を必要とする情報のことです。</p>
      <p>（11）「レポートデータ」とは、利用者本人、家族または職員が記載し、タイムラインに掲載された利用者に係る情報のことです。</p>
      <p>（12）「タイムライン」とは、時系列に表示された利用者に係るレポートデータのことです。</p>
      <p>（13）「本システム上の情報」とは、利用者情報、特記情報、レポートデータ、タイムラインその他本システムを通じて取り扱われ又は本システムに保存された情報をいう。</p>
      <p>（14）「個別利用規約」とは、本システムに関して、本規約とは別に「規約」、「ガイドライン」、「プライバシーポリシー」などの名称で本法人が配布または掲示する文書のことです。</p>
      <p>（15）「本サービス」とは、本システムで利用者が利用できる機能の総称です。</p>
      <h2>第2章&nbsp;利用者</h2>
      <h3>（利用者の利用申込等）</h3>
      <h4>第3条</h4>
      <p>1.個人が、本システムの利用を希望する場合は、本システム内の適宜の場所に掲示された個人利用申込から利用申込の申請ができます。</p>
      <p>2.利用者は、真実、正確かつ完全な情報を登録しなければなりません。また、利用者は利用者自身に関する情報を常に最新の情報となるよう修正しなければなりません。</p>
      <p>3.本法人は、第1項に規定する個人利用申込申請をした個人が本システムを利用することを、本法人の判断に基づき許諾します。</p>
      <p>4.第1項の利用申込みをした個人は、本法人が利用許諾をすることで、利用者として本システムを利用することができます。</p>
      <h3>（施設としての利用申込等）</h3>
      <h4>第4条</h4>
      <p>1.施設が、本システムの利用を希望する場合は、当該施設に所属する利用者が、管理者として当該施設を代理又は代表して、本システム内の適宜の場所に掲示された所定の施設利用申請書に必要事項を記載した後、当該施設申請書を印刷し押印の上、本法人に郵送にて提出し、本法人の許諾を得なければなりません。この場合、当該施設利用申請書の提出により、当該施設は本規約に同意したものとみなします。</p>
      <p>2.施設は、真実、正確かつ完全な情報を登録しなければなりません。また、施設は施設自身に関する情報を常に最新の情報となるよう修正しなければなりません。</p>
      <p>3.本法人は、前項に規定する施設利用申請書を提出した施設が本システムを利用することを、本法人の判断に基づき許諾します。</p>
      <p>4.第1項の利用申請をした施設は、本法人が利用許諾することで、施設として利用することができます。</p>
      <h3>（職員としての利用申込等）</h3>
      <h4>第5条</h4>
      <p>1.施設の従事者が、本システムを職員として利用することを希望する場合は、本法人が本システムの利用許諾をした施設の管理者から、本システム内の所定の方法により、本システムを利用する職員として承認を得なければなりません。</p>
      <p>2.施設の従事者は、当該管理者に承認をされることで、職員として本システムの利用ができます。</p>
      <h3>（利用者ができること）</h3>
      <h4>第6条</h4>
      <p>1.利用者は、利用者自身に係る情報に関して次に掲げる事項を行うことができます。</p>
      <p>（1）家族申請</p>
      <p>（2）本システムの利用停止申請及び利用者登録の抹消申請</p>
      <p>（3）本システムを利用している施設に対するかかりつけ設定（利用者が施設にかかりつけ設定の依頼を行うこと及び施設が利用者に案内したかかりつけ設定を承認すること）及びかかりつけ設定の停止</p>
      <p>（4）他の利用者からの家族申請の承認及び家族の削除申請</p>
      <p>（5）利用者情報、特記情報、レポートデータの記載及び閲覧</p>
      <p>（6）利用者情報の編集</p>
      <p>（7）特記情報の編集及び削除</p>
      <p>（8）利用者が自ら記載したレポートデータの編集</p>
      <p>（9）利用者のタイムラインに記載されたレポートデータの削除</p>
      <p>（10）その他本システムが利用者に提供する機能</p>
      <p>2. 家族、職員又は管理者が利用者として本システムを利用する場合には、前項に掲げる事項を行うことができます。</p>
      <h3>（家族ができること）</h3>
      <h4>第7条</h4>
      <p>家族は、家族になることの承認を得た当該利用者に係る情報に関して、次に掲げる事項を行うことができます。</p>
      <p>（1）利用者からの家族申請の承認及び家族の削除申請</p>
      <p>（2）当該利用者の利用者情報、特記情報、レポートデータの閲覧</p>
      <p>（3）当該利用者の特記情報の記載、編集及び削除</p>
      <p>（4）当該利用者のレポートデータの記載</p>
      <p>（5）家族が自ら記載したレポートデータの編集及び削除</p>
      <p>（6）その他本システムが利用者に提供する機能</p>
      <h3>（職員ができること）</h3>
      <h4>第8条</h4>
      <p>職員は、利用者との間でかかりつけ設定がなされた施設における当該施設の職員として、当該利用者に係る情報に関して、次に掲げる事項を行うことができます。</p>
      <p>（1）当該利用者の利用者情報の閲覧</p>
      <p>（2）当該利用者のレポートデータの記載及び閲覧</p>
      <p>（3）当該利用者の特記情報の記載、編集及び削除</p>
      <p>（4）職員が自ら記載したレポートデータの編集及び削除</p>
      <p>（5）職員が当該利用者に行う当該施設のサービス提供のために利用すること</p>
      <p>（6）その他本システムが利用者に提供する機能</p>
      <h3>（管理者ができること）</h3>
      <h4>第9条</h4>
      <p>管理者は、次に掲げる事項を行うことができます。</p>
      <p>（1）管理者が代理または代表する施設の本システムの利用申請</p>
      <p>（2）本システムを利用する職員の設定及び利用停止（職員が施設から移動した場合の利用停止を含む。）</p>
      <p>（3）当該管理者以外の新たな管理者の設定及び利用停止</p>
      <p>（4）管理者が代理または代表する施設と利用者との間のかかりつけ設定（利用者が施設に依頼したかかりつけ設定を承認すること及び施設が利用者にかかりつけ設定を案内すること）</p>
      <p>（5）その他本システムが管理者に提供する機能</p>
      <h3>（利用者の同意等）</h3>
      <h4>第10条</h4>
      <p>1. 利用者は、個人利用申込を本法人に申請したことにより、次に掲げる事項に同意したものとみなします。</p>
      <p>（1）利用者は、本システムを利用するにあたり、必要なパーソナルコンピュータ、携帯電話機、通信機器、オペレーションシステム、通信手段および電力などを、利用者の費用と責任で用意すること</p>
      <p>（2）利用者は本システムを利用するにあたり利用者IDを用いること（ただし、利用者が職員として本システムを利用する場合は自らの利用者IDを職員IDとして利用すること）</p>
      <p>（3）家族が第7条に掲げる各事項を行うこと</p>
      <p>（4）職員が第8条に揚げる各事項を行い、当該職員に関して管理者が第9条に掲げる各事項を行うこと</p>
      <p>（5）利用者の生命及び身体に対する急迫の危難を免れさせるためにやむを得ない場合は、本法人は、当該利用者に係る情報を、必要な限度と範囲において当該利用者以外の者に閲覧させる場合があること</p>
      <p>（6）本法人は、利用者に係る情報を当該利用者個人が特定できない情報に加工して、その加工後の情報を当該利用者以外の者に提供する場合があること</p>
      <p>（7）利用者に係る情報の管理に係る事項については、本法人が利用者の同意を得て別に定める場合があること</p>
      <p>（8）本法人は、利用者が本規約に違反して本システムを利用していると認めた場合、当該利用者に対して本法人が必要かつ適切と判断する措置を講じる場合があること。ただし、本法人は、かかる違反を防止または是正する義務を負うものではないこと。</p>
      <p>（9）利用者は、本システムを利用するに際して、本システム上の情報に関して、著作権法、個人情報保護法等の情報の取扱に関する関係法規及び医療・介護関係事業者における個人情報の適切な取扱いのためのガイドライン（厚生労働省）、医療情報システムの安全管理に関するガイドライン（厚生労働省）等の関係省庁が定める情報の取扱に関するガイドラインを遵守すること</p>
      <p>（10）本規約で揚げる全ての条文に同意すること</p>
      <p>2. 利用者は、前項に掲げる事項に合意したことにより、家族並びにかかりつけ設定がなされた施設及び当該施設の職員が、当該利用者の利用者情報、特記情報及びレポートデータの閲覧を行い、当該利用者の特記情報の記載、編集及び削除が可能となることに同意します。</p>
      <h3>（利用者の責任）</h3>
      <h4>第11条</h4>
      <p>利用者は、本システムを利用にあたり、次の各号に掲げる責任を負います。利用者が家族、職員又は管理者として本システムを利用する場合も同様に、次の各号に掲げる責任を負います。</p>
      <p>（1）利用者は、本システムの利用に際して登録するパスワードを不正に利用されないよう利用者自身の責任で厳重に管理しなければなりません。本法人は、登録されたパスワードを利用して行なわれた一切の行為を、当該登録された利用者本人の行為とみなします。</p>
      <p>（2）利用者は、本システム上の情報について適正な利用に努めなければなりません。</p>
      <p>（3）利用者は、自己の責任において本システムを利用するものとし、本システムにおいて行った一切の行為およびその結果について、一切の責任を負います。</p>
      <p>（4）利用者は、本システム上の情報を編集及び削除したことに伴う一切の責任を負います。</p>
      <p>（5）利用者は、当該利用者が本システムを利用したことに起因して（本法人がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、本法人が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、本法人の請求にしたがって直ちにこれを補償しなければなりません。</p>
      <h3>（禁止事項等）</h3>
      <h4>第12条</h4>
      <p>1. 利用者は、本システムの利用にあたり、次に掲げる行為をしてはなりません。利用者が家族、職員又は管理者として本システムを利用する場合も同様に、次に掲げる行為をしてはなりません。</p>
      <p>（1）本システムを通じて取り扱われる他のユーザに係る情報その他本システム上の情報を不正に利用する行為</p>
      <p>（2）本システムを通じて取り扱われる他のユーザに係る情報その他本システム上の情報を改竄する行為</p>
      <p>（3）本システムを通じて取り扱われる他のユーザに係る情報その他本システム上の情報を漏洩させる行為</p>
      <p>（4）他のユーザになりすまして本システムを利用する行為</p>
      <p>（5）本システム上に有害なコンピュータープログラム等を送信又は書き込む行為</p>
      <p>（6）他のユーザの同意を得ることなく又は詐欺的な手段により、他のユーザに係る本システム上の情報を収集する行為</p>
      <p>（7）本システムの利用又は提供を妨げる行為</p>
      <p>（8）本システムを利用した営業活動その他営利を目的とした行為（本法人の事前の同意がある場合を除く）</p>
      <p>（9）自己以外の者に本システムを利用させる行為（本法人の事前の同意がある場合を除く）</p>
      <p>（10）第三者又は本法人の財産、名誉及びプライバシー等を侵害する行為</p>
      <p>（11）その他本法人が不適切と判断した行為</p>
      <p>2. 職員が、利用者との間でかかりつけ設定がなされた施設における当該施設の職員として第8条に掲げる事項を行う場合には、当該利用者に係る情報の記載、閲覧、編集又は削除が当該利用者の意に反することがないように、当該利用者との間で協議を行うようにしてください。</p>
      <h3>（管理者の責任）</h3>
      <h4>第13条</h4>
      <p>管理者は、第11条で利用者の責任として規定した事項に加え、当該管理者が管理する施設における本システムの安全かつ適正な運用管理を行うため、次の各号に掲げる責任を負います。</p>
      <p>（1）当該施設に設置した本システムに接続する機器、ソフトウェア等の保守管理並びにウイルス対策ソフトの導入及び最新ウイルス定義状態への常時更新</p>
      <p>（2）当該施設に設置した機器において発生した障害への対応</p>
      <p>（3）当該施設の職員（本システムを利用する職員に限らない。）による本システムの運用管理</p>
      <p>（4）当該施設の職員（本システムを利用する職員に限らない。）による本システムの利用及び本規約の遵守に関する教育、指導及び管理監督</p>
      <p>（5）当該施設の職員が当該施設から異動した場合における、当該職員による当該施設の職員としての利用の停止</p>
      <p>（6）当該施設の職員（本システムを利用する職員に限らない。）に起因する行為に対する一切の責任</p>
      <p>（7）その他当該施設内における本システムの円滑な運用</p>
      <h2>第3章&nbsp;運営</h2>
      <h3>（運営管理）</h3>
      <h4>第14条</h4>
      <p>1.本法人は、本法人が許諾した者に利用者に利用者IDを付与し、施設には施設IDを付与します。</p>
      <p>2.本法人が利用者IDを使用可能な状態に設定することにより、利用者IDを付与された利用者は本システムの利用を開始することができます。ただし、職員としての利用には、所属する施設の施設IDが本法人により使用可能な状態に設定されることを更に要します。</p>
      <p>3.管理者又は職員の利用は、当該管理者又は職員の所属する施設による利用とみなします。施設の管理者又は職員が本システムを利用する場合には、当該施設は、当該管理者又は職員の行為に基づく一切の責任を、当該管理者又は職員と連帯して同様の責任を負担しなければなりません。</p>
      <p>4.本法人は、本システムの安全かつ適正な運用管理を行うため、次の各号に掲げる業務を行います。</p>
      <p>（1）利用者ID、施設ID及びパスワードの管理</p>
      <p>（2）本システムのサーバへのアクセス状況及び稼働状況の定期的な確認、検証、本システム上の情報の保全状態の把握</p>
      <p>（3）その他本システムの運用及び管理に関すること</p>
      <p>5.本法人は、前項各号の業務を補助又は業務の一部若しくは全部を代行させるため、システム管理者及びシステム管理補助者を指名することがあります。</p>
      <h3>（プライバシー）</h3>
      <h4>第15条</h4>
      <p>本法人は、利用者のプライバシーを尊重しています。 利用者から収集した本システム上の情報を安全に管理するため、セキュリティに最大限の注意を払います。利用者のプライバシー情報及び個人情報は、本法人のプライバシーポリシーに従って適切に取り扱います。 </p>
      <h3>（緊急通報機能に関して）</h3>
      <h4>第16条</h4>
      <p>本システムは、警察機関、海上保安機関、消防機関、医療機関その他の機関への緊急通報手段を提供するものではありません。</p>
      <h3>（広告に関して）</h3>
      <h4>第17条</h4>
      <p>本法人は、本システムに本法人または本法人が認めた第三者の広告を掲載することがあります。</p>
      <h3>（第三者の委託に基づき本サービスを提供する場合）</h3>
      <h4>第18条</h4>
      <p>第三者（以下「外部運営主体」といいます。）の委託に基づき、本法人がユーザに本サービスを提供する場合、外部運営主体が別途ユーザに対し、運用管理規約その他取り決めを行う場合があります。ユーザが当該外部運営主体の取り決めに合意した場合、その取り決めは本規約に抵触しない範囲においてその効力を有するものとします。</p>
      <h3>（情報管理）</h3>
      <h4>第19条</h4>
      <p>1.利用者に係る本システム上の情報のバックアップは利用者ご自身で行なってください。本法人は、利用者に係る本システム上の情報のバックアップを行う義務を負いません。 </p>
      <p>2.本法人は、本法人が利用者に提供する本サービスのために、本システム上の情報を利用することがあります。この場合、本法人は、本法人が必要かつ適正とみなす範囲で、情報の省略等の情報の変更を加えることがあります。本法人は、本システム上の情報の利用権を本法人と提携する第三者に再承認する場合があります。</p>
      <p>3.本法人は、法令または本規約の遵守状況などを確認する必要がある場合、本システム上の情報の内容の確認をすることができます。ただし、本法人は確認を行なう義務を負うものではありません。 </p>
      <p>4.本法人は、本システム上の情報に関し、法令もしくは本規約に違反し、または違反するおそれがあると認めた場合、その他業務上の必要がある場合、あらかじめ利用者に通知することなく、これらの情報を削除するなどの方法により、本システムでの情報の利用を制限できます。</p>
      <h3>（利用時間）</h3>
      <h4>第20条</h4>
      <p>1.本システムの利用は常時可能とします。</p>
      <p>2.前項の規定にかかわらず、本システムの保守点検又は機能更新を行う場合は、本法人が利用者に対して、本システムを通じて事前に通知をした上で、運用を停止することができるものとします。ただし、不定期又は緊急に必要となった保守点検や修理の際は通知なく運用を停止することができるものとします。</p>
      <h3>（利用抹消）</h3>
      <h4>第21条</h4>
      <p>1.利用者又は施設が本システムを利用しなくなる場合には、利用しなくなる時までに又は利用しなくなった後速やかに本法人に対して、利用抹消の届出をしなければなりません。</p>
      <p>2.本法人は、利用者ID及び施設IDを使用不能な状態に設定することにより、利用抹消の登録を行います。</p>
      <p>3. 本法人は、施設が6ヶ月以上にわたり別途定める本システムの利用料を滞納した場合には、本法人がその事実を確認した上で、当該施設及び当該施設の管理者又は職員へ通知することなく、当該施設及び当該施設の管理者又は職員の利用抹消の登録を行います。</p>
      <p>4.本法人は、ユーザが次の事項のいずれかに該当したときは、当該ユーザに通知した上で、当該ユーザの利用者ID又は施設IDを抹消する場合があります。</p>
      <p>（1）情報の取扱いに関する関係法規又はガイドラインに違反したとき</p>
      <p>（2）ネットワークへの多大な負荷をかける等、本システムの安定稼働を妨げる行為を行ったとき</p>
      <p>（3）情報の取り扱いが不適切であり、本法人による指導又は警告にもかかわらず改善が認められないとき</p>
      <p>（4）その他、本規約の規定に違反したとき</p>
      <p>5.本法人が利用抹消の登録を行った場合には、情報を掲載した者からの特段の要請が無い限り、本法人の判断で、当該利用抹消したユーザに係る本システム上の情報の掲載の継続若しくは停止又は当該情報の保存若しくは削除を行います。</p>
      <p>6.前項に規定する要請を行うための本人の手続に関しては、本法人が別途定めます。</p>
      <h3>（一時停止）</h3>
      <h4>第22条</h4>
      <p>1.本法人は、利用者ID又は施設IDが1ヶ月使用されない場合はユーザに通知することなく、当該ユーザに係る利用者ID又は施設IDの使用を一時的に不能にする場合があります。この場合において、利用者ID又は施設IDを再度使用可能にする場合には、当該ユーザは、本法人が別途指示する手順により本法人に対して再開申請をしなければなりません。</p>
      <p>2. 本法人は、施設が3ヶ月以上にわたり別途定める本システムの利用料を滞納した場合には、本法人がその事実を確認した上で、当該施設及び当該施設の管理者又は職員へ通知することなく、当該施設の施設IDまたは当該施設の管理者又は職員の利用者IDの使用を一時的に停止する場合があります。この場合において、施設IDまたは利用者IDを再度使用可能にする場合には、ユーザは本法人が別途指示する手順により本法人に対して再開申請をしなければなりません。</p>
      <h3>（通信内容の削除）</h3>
      <h4>第23条</h4>
      <p>本法人は、本システム上の情報が次の各号のいずれかに該当すると本法人が判断した場合には、当該情報を掲載したユーザに連絡することなく情報を削除します。</p>
      <p>（1）掲載された情報が、他のユーザを含む第三者の権利を侵害している又は侵害するおそれがある場合</p>
      <p>（2）掲載された情報が、本システムの信用を失墜させるおそれがある場合</p>
      <p>（3）情報の取扱いに関する本規約、関係法規又はガイドラインに違反する情報がある場合</p>
      <h3>（弁償）</h3>
      <h4>第24条</h4>
      <p>利用者が本規約の規定に違反して、本システムに障害を発生させ、又は本システム上の情報を漏洩若しくは消去させた場合には、利用者の故意又は過失の程度に応じて修理又は弁償に要した経費を負担しなければなりません。当該利用者が施設の管理者又は職員である場合には、当該管理者又は職員が所属する施設が連帯して同様の責任を負担しなければなりません。</p>
      <h3>（規約の変更）</h3>
      <h4>第25条</h4>
      <p>本法人は、本法人が必要と判断する場合、あらかじめ利用者に通知することなく、いつでも、本規約および個別利用規約を変更できるものとします。変更後の本規約および個別利用規約は、本法人が運営するウェブサイト内の適宜の場所に掲示された時点からその効力を生じるものとし、利用者は本規約および個別利用規約の変更後も本システムを使い続けることにより、変更後の本規約および適用のある個別利用規約に対する有効かつ取消不能な同意をしたものとみなされます。本システムをご利用の際には、随時、最新の本規約および適用のある個別利用規約をご参照ください。</p>
      <h3>（本法人の免責）</h3>
      <h4>第26条</h4>
      <p>1. 本法人は、本システムに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証するものではありません。本法人は、ユーザに対して、かかる瑕疵を除去して本システムを提供する義務を負いません。 </p>
      <p>2. 本法人は、本システムに起因してユーザに生じたあらゆる損害について一切の責任を負いません。ただし、本システムに関する本法人とユーザとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合には、この免責規定は適用されません。 </p>
      <p>3. 前項の定める場合であっても、本法人は、本法人の過失（重過失を除きます。）による債務不履行または不法行為によりユーザに生じた損害のうち、特別な事情から生じた損害（本法人またはユーザが損害発生につき予見し、または予見し得たに過ぎないものを含みます。）について一切の責任を負いません。また、本法人の過失（重過失を除きます。）による債務不履行または不法行為によりユーザに生じた損害の賠償は、ユーザから当該損害が発生した月に受領した利用料の額を上限とします。</p>
      <h3>（連絡方法）</h3>
      <h4>第27条</h4>
      <p>1. 本システムに関する本法人からユーザへの連絡は、本法人が運営するウェブサイト内の適宜の場所への掲示その他本法人が適当と判断する方法により行ないます。</p>
      <p>2. 本システムに関するユーザから本法人への連絡は、本法人が運営するウェブサイト内の適宜の場所に設置するお問い合わせフォームの送信または本法人が指定する方法により行うこととします。</p>
      <h3>（料金）</h3>
      <h4>第28条</h4>
      <p>本システムに利用するために必要な初期諸設備の構築及びその運営又は維持のため、本システムを利用する施設は、本法人に対して、所定の料金を支払うものとします。</p>
      <h3>（雑則）</h3>
      <h4>第29条</h4>
      <p>本規約に定めるもののほか、本システムの運営に必要な事項については、本法人が別に定めるものとします。</p>
      <h3>附則</h3>
      <p>本規約は平成30年&nbsp;3月&nbsp;3日から施行します。</p>
    </div>
    <div class="rule-content en" v-if="displayLang === 'en'">
      <h2 class="centering">IMPORTANT NOTE</h2>
      <p>The Japanese version of this document will govern our relationship - this translated version is provided for convenience only and will not be interpreted to modify the Japanese version.  For the Japanese version, please see <a href="https://narrativebook.jp/static/pages/rules.html">https://narrativebook.jp/static/pages/rules.html</a>.</p>
      <h2 class="top">Chapter 1 :  General Provisions</h2>
      <h3>Article 1. (Purpose)</h3>
      <p>These Terms of Use (hereinafter referred to as "Terms of Use") stipulate the conditions regarding the use of the system provided by xCareField Co., Ltd. under the name of "Narrative Book", between the person using this system and xCareField Co., Ltd.</p>
      <h3>Article 2. (Definitions)</h3>
      <p>this Terms of Use, the following terms shall have the following definitions</p>
      <table class="difinition-table">
        <tbody>
          <tr>
            <td class="index">(1)</td>
            <td class="title">the Corporation</td>
            <td class="content">xCareField Co., Ltd.</td>
          </tr>
          <tr>
            <td class="index">(2)</td>
            <td class="title">the System</td>
            <td class="content">The system that the Corporation provides under the name of “Narrative Book”, which is a service for users to collect, manage and use information related to their own health and life.</td>
          </tr>
          <tr>
            <td class="index">(3)</td>
            <td class="title">user</td>
            <td class="content">An individual who has been authorized by the Corporation to use the System.</td>
          </tr>
          <tr>
            <td class="index">(4)</td>
            <td class="title">family member</td>
            <td class="content">A user who has applied to become a family member of another user, and the other user has made the application to become a family member of the user. In order to become a family member, the user must be using the System as a user.</td>
          </tr>
          <tr>
            <td class="index">(5)</td>
            <td class="title">facility(facilities)</td>
            <td class="content">Medical, nursing care, or welfare related facilities, and organizations or offices related to medical, nursing care, or welfare.</td>
          </tr>
          <tr>
            <td class="index">(6)</td>
            <td class="title">stuff</td>
            <td class="content">A user who uses the System based on the status of an employee of the facility that has been licensed by the Corporation to use the System. To become a stuff, you must be using the System as a user.</td>
          </tr>
          <tr>
            <td class="index">(7)</td>
            <td class="title">administrator</td>
            <td class="content">A user who uses the System based on the status of a representative of the facility.</td>
          </tr>
          <tr>
            <td class="index">(8)</td>
            <td class="title">user</td>
            <td class="content">A person who uses the System, and is a generic term for users, family members, facilities, staff, and administrators.</td>
          </tr>
          <tr>
            <td class="index">(9)</td>
            <td class="title">user information</td>
            <td class="content">The basic information (name, date of birth, gender, blood type, etc.) of the user that the user has posted on the System.</td>
          </tr>
          <tr>
            <td class="index">(10)</td>
            <td class="title">special information</td>
            <td class="content">Routine information other than the user information related to the user, or information that requires special attention, written by the user himself/herself, family members, or staff.</td>
          </tr>
          <tr>
            <td class="index">(11)</td>
            <td class="title">report data</td>
            <td class="content">Information related to the user, which is described by the user, his/her family or staff, and posted on the timeline.</td>
          </tr>
          <tr>
            <td class="index">(12)</td>
            <td class="title">timeline</td>
            <td class="content">The report data pertaining to a user displayed in chronological order.</td>
          </tr>
          <tr>
            <td class="index">(13)</td>
            <td class="title">information on the System</td>
            <td class="content">The user information, special information, report data, timeline, and other information handled through or stored in the System.</td>
          </tr>
          <tr>
            <td class="index">(14)</td>
            <td class="title">Individual Terms of Use</td>
            <td class="content">The documents distributed or posted by the Corporation separately from these Terms of Use, such as “Terms”, “Guidelines”, and “Privacy Policy”.</td>
          </tr>
          <tr>
            <td class="index">(15)</td>
            <td class="title">Service”</td>
            <td class="content">The general term for the functions that users can use in the System.</td>
          </tr>
        </tbody>
      </table>
      <h2>Chapter 2 :  Users</h2>
      <h3>Article 3. (User application)</h3>
      <ol>
        <li>Individuals who wish to use the System may apply for the use of the System through the personal application posted at appropriate places in the System.</li>
        <li>Users must register truthful, accurate, and complete information. In addition, users must correct the information about themselves so that it is always up to date.</li>
        <li>The Corporation grants permission to individuals who have applied for personal use as specified in paragraph 1 of this article to use the System based on the Corporation's judgment.</li>
        <li>This corporation permits the use of this system by the individual who applied for the personal use application prescribed in paragraph 1 (of Article 3) based on the judgment of this corporation.</li>
      </ol>
      <h3>Article 4. (Application for use as a facility)</h3>
      <ol>
        <li>If a facility wishes to use the system, a user belonging to the facility, acting as an administrator or representative of the facility, must fill out the prescribed "facility use application form" posted at an appropriate location in the System, print out, seal, and submit it to the Corporation by mail to obtain the Corporation's permission. In this case, this facility is deemed to have agreed to this Terms of Use by submitting this "facility use application".</li>
        <li>The facility must register truthful, accurate and complete information. In addition, the facility must revise the information about the facility itself to keep it up to date.</li>
        <li>The Corporation permits the facility that submitted the “facility use application” prescribed in paragraph 1 (of Article 4) to use the System, based on the judgment of the Corporation,.</li>
        <li>The facility that has applied for the use of the System as described in paragraph 1 (of Article 4) may use the System as a facility by obtaining a license from the Corporation.</li>
      </ol>
      <h3>Article 5. (Application for use as a stuff)</h3>
      <ol>
        <li>If an employee of the facility wishes to use the System as a stuff, the employee must obtain approval as a stuff to use the System from the administrator of the facility to which the Corporation has granted a license to use the System by the prescribed method in the System.</li>
        <li>Employees of the facility can use the System as stuffs only after the administrator of applicable facility approves their use of the System.</li>
      </ol>
      <h3>Article 6. (What users can do)</h3>
      <ol>
        <li>
          Users may do the following with respect to information pertaining to themselves.
          <ol class="round-bracket">
            <li>Family application</li>
            <li>Application for suspension of the use of the System, and application for cancellation of the user registration</li>
            <li>Requesting the setting of exclusive care to the facility using the System, approving the exclusive care setting guided by the facility, and stopping the exclusive care setting</li>
            <li>Approval of family application from other users and deletion of family settings.</li>
            <li>Writing and viewing user information, special information, and report data.</li>
            <li>Editing of user information.</li>
            <li>Editing and deleting of special information</li>
            <li>Editing of report data written by the user</li>
            <li>Deletion of report data on the user's timeline</li>
            <li>Other functions provided by the System to users. </li>
          </ol>
        </li>
        <li>When family members, staff members, or administrators use this system as users, they may do the matters listed in the preceding paragraph (of Article 6).</li>
      </ol>
      <h3>Article 7. (What family members can do)</h3>
      <p>Family members can do the following with respect to information about users who have approved family settings:</p>
      <ol class="round-bracket">
        <li>Approval of family application from user and cancellation of family setting</li>
        <li>Viewing the user information, special information, and report data of the applicable user.</li>
        <li>Description, editing and deletion of special information of the user.</li>
        <li>Description of the user's report data.</li>
        <li>Editing and deleting report data written by the family.</li>
        <li>Other functions provided by the System to users</li>
      </ol>
      <h3>Article 8. (What the staff can do)</h3>
      <p>The staff can do the following matters regarding the information related to the user, as a staff member of a facility for which exclusive care has been set with applicable user</p>
      <ol class="round-bracket">
        <li>Browsing the user information of the applicable user</li>
        <li>Writing and viewing the report data of the applicable user</li>
        <li>Description, editing and deletion of special information of the user</li>
        <li>Editing and deleting of report data written by staff members themselves</li>
        <li>Use by staff members for the provision of services of the applicable facility to the relevant user</li>
        <li>Other functions provided by the System to users.</li>
      </ol>
      <h3>Article 9. (What the administrator can do)</h3>
      <p>The administrator can do the following matters.</p>
      <ol class="round-bracket">
        <li>Application for use of the System by facilities that the administrator represents.</li>
        <li>Setting and suspension of staff using the System (including suspension of use when a staff member moves from the facility).</li>
        <li>Setting and suspension of use of new administrators other than the applicable administrator.</li>
        <li>Setting up an exclusive care between the user and the facility that the administrator represents. (Approval of exclusive care requested by the user to the facility and the facility informing the user of the exclusive care)</li>
        <li>Other functions provided by the System to the administrator.</li>
      </ol>
      <h3>Article 10. (User consent)</h3>
      <ol>
        <li>
          By submitting an application for personal use to the Corporation, a user shall be deemed to have agreed to the following
          <ol class="round-bracket">
            <li>The user shall prepare the necessary personal computer, cell phone, communication equipment, operation system, communication means, and electric power, etc., at the user's expense and responsibility when using the System.</li>
            <li>The user should use the user ID when using the System. (However, when a user uses the System as a staff member, he/she must use his/her own user ID as a staff member ID.)</li>
            <li>Family members shall perform each of the matters listed in Article 7.</li>
            <li>A stuff shall perform each of the matters listed in Article 8, and the administrator shall perform each of the matters listed in Article 9 with respect to applicable staff.</li>
            <li>In cases where it is unavoidable in order to spare the life or body of a user from imminent danger, the Corporation may allow persons other than applicable user to view information pertaining to applicable user within the necessary limits and scope.</li>
            <li>The Corporation may process information pertaining to a user into information that does not identify the individual user, and provide the processed information to parties other than the user.</li>
            <li>Matters pertaining to the management of information related to a user may be determined separately by the Corporation with the consent of the user.</li>
            <li>In the event that the Corporation recognizes that a user is using the System in violation of these Terms, the Corporation may take measures that the Corporation deems necessary and appropriate, against such User. However, the Corporation shall not be obligated to prevent or correct such violation.</li>
            <li>When using the System, users must comply with the relevant laws and regulations regarding the handling of information on the System, such as the Copyright Act and the Personal Information Protection Act, as well as the Guidelines for the Appropriate Handling of Personal Information by Medical and Nursing Care Providers (Ministry of Health, Labor and Welfare) and the Guidelines for the Safe Management of Medical Information Systems (Ministry of Health, Labor and Welfare).</li>
            <li>The user agrees to all of the articles listed in the Terms of Use.</li>
          </ol>
        </li>
        <li>By agreeing to the items listed in the previous section, the user agrees to allow his/her family members, the facility where the user's exclusive care is set up, and the staff of applicable facility to view the user information, special information, and report data of applicable user, and to enter, edit, and delete the special information of this user.</li>
      </ol>
      <h3>Article 11. (Responsibility of the user)</h3>
      <p>The user assumes the following responsibilities when using the System. When a user uses the System as a family member, staff member, or administrator, the User shall likewise assume the following responsibilities.</p>
      <ol class="round-bracket">
        <li>Users shall be responsible for strictly managing the passwords they register when using the System to prevent unauthorized use. The Corporation shall deem any and all acts conducted by using the registered password to be the acts of the registered User.</li>
        <li>Users shall endeavor to use the information on the System in an appropriate manner.</li>
        <li>Users shall use the System at their own responsibility, and shall be responsible for any and all acts conducted on the System and the results thereof.</li>
        <li>Users shall bear all responsibility for editing and deleting the information on the System.</li>
        <li>In the event that the Corporation suffers any direct or indirect damage (including the burden of attorney's fees) as a result of the use of the System by a user (including cases where the Corporation receives a claim from a third party due to the use of the System), the user shall be liable for such damage. The applicable user must immediately compensate for such damage according to the Corporation's request.</li>
      </ol>
      <h3>Article 12. (Prohibited Matters)</h3>
      <ol>
        <li>
          A User shall not commit any of the following acts when using the System The user shall not commit any of the following acts when using the System as a family member, employee, or administrator.
          <ol class="round-bracket">
            <li>Illegally use information related to other users handled through the System or other information on the System.</li>
            <li>Falsifying information pertaining to other users or other information on the System that is handled through the System</li>
            <li>Falsifying information pertaining to other users handled through the System or other information on the System</li>
            <li>Leaking information pertaining to other users handled through the System or other information on the System</li>
            <li>Using the System by impersonating another user</li>
            <li>Installing harmful computer programs on the System</li>
          </ol>
        </li>
        <li>When a staff member performs the matters listed in Article 8 as an employee of the facility in which an exclusive care setting has been made with the user, the description, viewing, editing or deletion of the information related to the user, Please consult with the user so as not to go against the will of the person.</li>
      </ol>
      <h3>Article 13. (Administrator responsibility)</h3>
      <p>In addition to the matters stipulated as the responsibility of the user in Article 11, the administrator bears the responsibilities listed in the following items in order to perform safe and proper operation management of the System in the facility managed by the administrator.</p>
      <ol>
        <li>Maintenance and management of equipment and software connected to the System installed in the facility, installation of antivirus software, and constant update to the latest virus definition status.</li>
        <li>Response to failures that occur in the equipment installed at the facility</li>
        <li>Operation management of the System by the employee of the facility (not limited to the stuff who use the System)</li>
        <li>Education, guidance and management supervision regarding the use of the System and compliance with this agreement by the employee of the facility (not limited to the stuff who use the System).</li>
        <li>Suspension of use of the facility as a staff member when the staff member of the facility is transferred from the facility.</li>
        <li>All responsibility for acts caused by the employee of the facility (not limited to the staff who use the System)</li>
        <li>Others Smooth operation of the System in the facility</li>
      </ol>
      <h2>Chapter 3 : Management</h2>
      <h3>Article 14. (Operational management)</h3>
      <ol>
        <li>The Corporation gives the user ID to the user, and the facility ID to the facility, both licensed by the Corporation.</li>
        <li>By setting the user ID in a usable state by the Corporation, the user who has been given the user ID can start using the System. However, in order to use it as a staff member, it is further necessary for the facility ID of applicable facility to which it belongs to be set so that it can be used by the Corporation.</li>
        <li>The use of the manager or staff is considered to be the use by the facility to which the manager or staff belongs. When the manager or staff of the facility uses the System, the facility must bear all responsibility based on the actions of the manager or staff in solidarity with the manager or staff. </li>
        <li>
          The Corporation will carry out the operations listed in the following items in order to manage the safe and proper operation of the System.
          <ol class="round-bracket">
            <li>Management of user ID, facility ID and password</li>
            <li>Periodic confirmation and verification of the access status and operating status of the server of the System, grasping the preservation status of information on the System</li>
            <li>Other matters related to the operation and management of the System</li>
          </ol>
        </li>
        <li>The Corporation may appoint a system administrator and a system management assistant in order to assist the work of each item in the preceding paragraph or to act for part or all of the work.</li>
      </ol>
      <h3>Article 15. (Privacy)</h3>
      <p>The Corporation respects the privacy of users. We will pay the utmost attention to security in order to safely manage the information collected from users on the System. User privacy information and personal information will be handled appropriately in accordance with the privacy policy of the Corporation.</p>
      <h3>Article 16. (Regarding the emergency call function)</h3>
      <p>The System does not provide emergency call means to police agencies, coast guard agencies, fire departments, medical institutions and other institutions.</p>
      <h3>Article 17. (Regarding advertising)</h3>
      <p>The Corporation may place advertisements of the Corporation or a third party approved by the Corporation on the System.</p>
      <h3>Article 18. (When providing this Service on behalf of a third party)</h3>
      <p>When the Corporation provides this Service to users based on the consignment of a third party (hereinafter referred to as "external operating entity"), the external operating entity may separately make operational management agreements and other arrangements with the user. If the user agrees to the agreement of the external operating entity, the agreement shall be effective to the extent that it does not conflict with this agreement.</p>
      <h3>Article 19. (Information management)</h3>
      <ol>
        <li>Please back up the information on the System related to the user by yourself. The Corporation is not obligated to back up the information on the System related to the user.</li>
        <li>The Corporation may use the information on the System for this Service provided by the Corporation to users. In this case, the Corporation may make changes to the information, such as omission of information, to the extent that the Corporation deems it necessary and appropriate. The Corporation may re-approve the right to use the information on the System to a third party affiliated with the Corporation.</li>
        <li>The Corporation can check the content of information on the System when it is necessary to check the status of compliance with laws and regulations or this agreement. However, the Corporation is not obligated to confirm.</li>
        <li>If we find that the information on the System violates or may violate laws and regulations or this regulation, or if there is any other business need, we will not notify the user in advance. We can limit the use of information on the System by deleting this information.</li>
      </ol>
      <h3>Article 20. (Utilization time)</h3>
      <ol>
        <li>The System can be used at all times.</li>
        <li>Notwithstanding the provisions of the preceding paragraph, when performing maintenance inspections or function updates of the System, the Corporation may suspend the operation after notifying the user in advance through the System. However, in the case of irregular or urgent maintenance inspections and repairs, the operation can be stopped without notice.</li>
      </ol>
      <h3>Article 21. (Delete usage)</h3>
      <ol>
        <li>If a user or facility ceases to use the System, he/she must notify the Corporation of the cancellation of use by the time he/she ceases to use it or immediately after he/she ceases to use it.</li>
        <li>The Corporation registers the cancellation of use by setting the user ID and facility ID to the unusable state.</li>
        <li>If the facility fails to pay the usage fee for the System, which is separately determined for 6 months or more, the Corporation will confirm the fact and notify the facility and the manager or staff of the facility. Without notifying the manager or staff of the facility, the Corporation will delete the use of the facility and the manager or staff of the facility.</li>
        <li>
          When a user falls under any of the following items, the Corporation may delete the user ID or facility ID of the user after notifying the user.
          <ol class="round-bracket">
            <li>When the relevant laws and guidelines regarding the handling of information are violated</li>
            <li>When an act that hinders the stable operation of the System, such as putting a heavy load on the network, is performed.</li>
            <li>When the handling of information is inappropriate and no improvement is recognized despite the guidance or warning from the Corporation.</li>
            <li>In addition, when the provisions of this agreement are violated</li>
          </ol>
        </li>
        <li>If the Corporation registers for cancellation of use any user, unless otherwise requested by the person who posted the information, the Corporation will decide stopping or continuing applicable account, saving or deleting its information.</li>
        <li>The Corporation will separately determine the procedure for the person to make the request prescribed in the preceding paragraph.</li>
      </ol>
      <h3>Article 22. (Pause)</h3>
      <ol>
        <li>If the user ID or facility ID is not used for one month, the Corporation may temporarily disable the use of the user ID or facility ID related to the user without notifying. In this case, if the user ID or facility ID is to be made available again, the user must apply to the Corporation for resumption according to the procedure separately specified by the Corporation.</li>
        <li>If the facility delinquent the usage fee for the System, which is separately determined, for 3 months or more, the Corporation will confirm the fact. And without notifying the facility and the manager or staff of the facility, the use of the facility ID of the facility or the user ID of the manager or staff of the facility may be temporarily suspended. In this case, if you want to make the facility ID or user ID reusable, the user must apply to the Corporation for resumption according to the procedure separately specified by the Corporation.</li>
      </ol>
      <h3>Article 23. (Delete communication content)</h3>
      <p>If the Corporation determines that the information on the System falls under any of the following items, the Corporation will delete the information without contacting the user who posted the information.</p>
      <ol class="round-bracket">
        <li>When the posted information infringes or may infringe the rights of a third party including other users</li>
        <li>When the posted information may damage the credibility of the System</li>
        <li>When there is information that violates this agreement, related laws and guidelines regarding the handling of information</li>
      </ol>
      <h3>Article 24. (Compensation)</h3>
      <p>If the user violates the provisions of this agreement and causes a failure in the System, or leaks or erases information on the System, repair or compensation will be made according to the degree of intentionality or negligence of the user. If the user is a facility manager or staff member, the facility to which the manager or staff member belongs must jointly bear the same responsibility.</p>
      <h3>Article 25. (Change of terms)</h3>
      <p>Corporation shall be able to change this agreement and individual terms of use at any time without notifying the user in advance if the Corporation deems it necessary. The revised Terms and Conditions and Individual Terms of Use shall become effective from the time they are posted on the website operated by the Corporation, and the user shall continue to use the Terms and Conditions and the Individual Terms of Use even after the changes. By continuing to use the System, you are deemed to have given valid and irrevocable consent to the revised Terms and Conditions and applicable Individual Terms of Use. When using the System, please refer to the latest Terms and Conditions and applicable Individual Terms of Use from time to time.</p>
      <h3>Article 26. (Disclaimer of the Corporation)</h3>
      <ol>
        <li>The Corporation does not guarantee that there is no practical or legal defects in the System (safety, reliability, accuracy, completeness, effectiveness, suitability for a specific purpose, security defects, errors and bugs, infringement of rights, etc.), either explicitly or implicitly. The Corporation is not obligated to provide the System to users by removing such defects.</li>
        <li>The Corporation does not take any responsibility for any damage caused to the user due to the System. However, this disclaimer does not apply if the contract between the Corporation and the user regarding the System (including this agreement) is a consumer contract stipulated in the Consumer Contract Law.</li>
        <li>Even in the case prescribed in the preceding paragraph, the Corporation does not take any responsibility for what caused by special circumstances among the damage (including what the corporation or the user foresaw or could only foresee the occurrence of damage) caused to the user due to default or illegal act brought by the negligence (excluding gross negligence) of the Corporation. In addition, compensation for damages caused to the user due to default or illegal acts due to the negligence (excluding gross negligence) of the Corporation shall be limited to the amount of usage fee received from the user in the month in which the damage occurred.</li>
      </ol>
      <h3>Article 27. (Contact method)</h3>
      <ol>
        <li>The Corporation will contact the user regarding the System by posting it at an appropriate place on the website operated by the Corporation or by any other method that the Corporation deems appropriate.</li>
        <li>Users regarding the System will contact the Corporation by sending an inquiry form set up at an appropriate location on the website operated by the Corporation or by the method specified by the Corporation.</li>
      </ol>
      <h3>Article 28. (Fee)</h3>
      <p>In order to construct, operate or maintain the initial equipment necessary for using the System, the facilities that use the System shall pay the Corporation a prescribed fee.</p>
      <h3>Article 29. (Miscellaneous rules)</h3>
      <p>In addition to what is prescribed in this agreement, the matters necessary for the operation of the System shall be stipulated separately by the Corporation.</p>
      <h3>Supplementary provisions</h3>
      <p>This agreement will come into effect on March 3, 2018.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusRules',

  props: {
    lang: String
  },

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    lang: { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.lang === 'en') {
        this.displayLang = 'en'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-rules {
  padding: 10px;
  background-color: #fff;
  h1 {
    margin: 20px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  h2 {
    margin: 20px 0;
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
    &.top {
      margin-top: 0;
    }
    &.centering {
      text-align: center;
    }
  }
  h3 {
    margin: 3px 0;
    margin-top: 20px;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
  }
  h4 {
    margin: 3px 0;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
    border-bottom: 1px solid #ddd;
  }
  p {
    margin: 3px 0;
    font-size: 0.9em;
  }
  .rule-content.en {
    padding: 0 30px;
    word-break: normal;
    @include mediaQuery('phone') {
      padding: 0;
    }
    h1 {
      margin: 20px 0;
      font-size: 1em;
      font-weight: bold;
      color: #000;
      text-align: center;
    }
    h2 {
      margin: 20px 0 10px;
      padding-bottom: 3px;
      font-size: 1em;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #000;
      &.centering {
        border: none;
        @include mediaQuery('phone') {
          margin-top: 0;
        }
      }
      &.first {
        margin-top: 0;
      }
    }
    h3 {
      margin: 3px 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
      color: #000;
    }
    h4 {
      margin: 3px 0;
      font-size: 1em;
      font-weight: normal;
      color: #000;
    }
    p {
      margin: 0;
      margin-top: 10px;
      margin-left: 50px;
      font-size: 1em;
      @include mediaQuery('phone') {
        margin-left: 10px;
      }
    }
    table.difinition-table {
      width: 100%;
      border: none;
      border-collapse: collapse;
      td {
        padding-top: 10px;
        border: none;
        vertical-align: top;
      }
      .index {
        width: 44px;
        padding-right: 5px;
        vertical-align: top;
        text-align: right;
        @include mediaQuery('phone') {
          width: 24px;
        }
      }
      .title {
        padding-right: 5px;
        white-space: nowrap;
        @include mediaQuery('phone') {
          width: 50px;
        }
      }
      .content {
        margin: 0;
        @include mediaQuery('phone') {
          width: calc(100% - 44px - 50px);
        }
      }
    }
    ol {
      margin: 0;
      margin-top: 10px;
      // margin-left: 50px;
      padding-inline-start: 50px;
      @include mediaQuery('phone') {
        padding-inline-start: 20px;
      }
      li {
        margin: 0;
        margin-top: 10px;
      }
      &.round-bracket {
        li {
          list-style-type: none;
          counter-increment: cnt;
        }
        li::before {
          display: inline-block;
          width: 45px;
          margin-left: -50px;
          padding-right: 5px;
          text-align: right;
          content: "(" counter(cnt) ")";
          @include mediaQuery('phone') {
            // margin-left: -30px;
          }
        }
      }
    }
  }
}
</style>
