<template>
  <div class="photo-in-timeline" @click="timelinePhotoClick" :class="sizeType" :style="{ width: photoWidth, height: photoHeight }">
    <img :src="photoObject.photoThumbnailSrc" />
    <img src="/static/images/deleted_mark.png" class="deleted-mark" v-show="deleted" />
  </div>
</template>

<script>
export default {
  name: 'HorusInlinePhoto',

  props: {
    photoObject: Object,
    photoMaxSize: Number,
    sizeType: String,
    deleted: { type: Boolean, default: false }
  },

  computed: {
    photoRatio () {
      if (this.photoObject.imageWidth > this.photoObject.imageHeight) {
        return this.photoMaxSize / this.photoObject.imageWidth
      } else {
        return this.photoMaxSize / this.photoObject.imageHeight
      }
    },

    photoWidth () {
      return (this.photoObject.imageWidth * this.photoRatio) + 'px'
    },

    photoHeight () {
      return (this.photoObject.imageHeight * this.photoRatio) + 'px'
    }
  },

  methods: {
    timelinePhotoClick () {
      this.$emit('timeliePhotoClickEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-in-timeline {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  &.small {
    margin-right: 5px;
    margin-bottom: 0;
  }
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.deleted-mark {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 80%;
      height: 80%;
      object-fit: contain;
      opacity: 0.8;
    }
  }
}
</style>
