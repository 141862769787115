<template>
  <div class="horus-manage-reporter-organization-basic">
    <horus-manage-reporter-organization-frame
      :currentDisplay="'basic'"
      :signinReporter="signinReporter"
      :reporterOrganization="reporterOrganization"
      >
      <h1 class="centering-title">基本情報</h1>
      <div class="red_titled_column" v-if="reporterOrganization">
        <h4>{{ reporterOrganization.name }}<img src="/static/images/pencil_icon.png" class="edit-organization-btn" @click="edit" v-if="signinReporter.manager"/></h4>
        <div class="list-data">
          <div class="list-data-line">
            <div class="labels"><label>施設番号</label></div>
            <div class="content">{{ reporterOrganization.numberForHuman }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>施設名称</label></div>
            <div class="content">{{ reporterOrganization.name }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>ヨミガナ</label></div>
            <div class="content">{{ reporterOrganization.nameKana }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>略称</label></div>
            <div class="content">{{ reporterOrganization.shortName }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>郵便番号</label></div>
            <div class="content">{{ reporterOrganization.zipCode }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>住所</label></div>
            <div class="content">{{ reporterOrganization.address }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>電話番号</label></div>
            <div class="content">{{ reporterOrganization.tel }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>メールアドレス</label></div>
            <div class="content">{{ reporterOrganization.mail }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>イメージアイコン</label></div>
            <div class="content">
              <img :src="iconImageSrc" class="icon-image">
            </div>
          </div>
        </div>
      </div>
      <div class="red_titled_column" v-if="signinReporter.manager">
        <h4>施設の管理</h4>
        <div class="list-data">
          <div class="list-data-line">
            <div class="labels"><label>施設の退会申請</label></div>
            <div class="content">
              <div class="submit-revoke" v-if="reporterOrganization && (!reporterOrganization.revoke_log || reporterOrganization.revoke_log.restored)">
                <div class="notify-caption">
                  ここで施設のナラティブブック退会の申請が行なえます。<br/>
                  以下の注意事項をよく読んでから手続きを行ってください。<br/><br/>
                  施設のナラティブブック退会のためには、以下の条件を満たす必要があります。<br/>
                  条件を満たしていない場合は、それぞれの処理を完了させてください。<br/>
                  <ul>
                    <li>未処理の職員案内がないこと</li>
                    <li>未処理のサービス利用案内がないこと</li>
                  </ul>
                  条件を満たしている場合、下記フォームの「上記確認事項を確認しました」にチェックを入れ、施設管理者であるあなたのNBIDとログインパスワードを入力して「施設退会申請」をクリックしてください。<br/>
                  システム管理者に施設の退会申請が通知され、施設の削除処理が行われます。<br/>
                  施設の削除処理はシステム管理者の操作によるもので、実行されるまで時間がかかる場合があります。<br/>
                  削除申請送信後は、以下の操作は一切できなくなります。<br/>
                  <ul>
                    <li>サービス利用案内の作成・送信</li>
                    <li>サービス利用申込みの受理</li>
                    <li>職員案内の作成・送信</li>
                  </ul>
                  上記以外のタイムライン書き込みなどの操作は行えます。<br/>
                  施設の削除処理が完了した場合、利用者とのサービス利用関係は自動的に削除されますが、
                  これまでにサービス利用者のタイムラインに書き込まれた情報は削除されることはありません。<br/>
                  また、施設の削除に伴い、施設職員全員の職員としてのアカウントは削除されますが、削除されたあとも、個人として、ナラティブブックはご利用いただけます。<br/>
                  施設削除後も、個人としてのログインに際して利用するNBIDとログインパスワードに変更はありません。<br/>
                  また、施設削除後もシステム管理者に申請することで、削除前の状態に施設を復帰させることも可能です。<br/>
                </div>
                <div class="revoke-form">
                  <div class="admit-agreement" @click="toggleAdmit">
                    <horus-custom-check-box
                      :active="admitAgreement"
                      :enabled="true"
                      :type="'color'"
                      />
                    上記確認事項を確認しました
                  </div>
                  <label>NBID: <input type="text" v-model="submitNBID" /></label>
                  <label>パスワード: <input type="password" v-model="submitPassword" /></label>
                  <button class="submit-revoke-btn" :class="{ready: prepareRevokeReady}" @click="submitPrepareRevoke">施設退会申請</button>
                </div>
              </div>
              <div class="cancel-revoke" v-if="reporterOrganization && reporterOrganization.revoke_log && !reporterOrganization.revoke_log.restored">
                <div class="revoke-prepared-notify">
                  現在、退会申請を受付中です。<br/>
                  システム管理者による削除処理実行をお待ち下さい。<br/>
                  申請の確認のためにシステム管理者より連絡が来る場合があります。<br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </horus-manage-reporter-organization-frame>
    <horus-system-reporter-organization-edit-modal
      :sourceReporterOrganization="reporterOrganization"
      :showReporterOrganizationEditModal="showReporterOrganizationEditModal"
      :submitString="'更 新'"
      v-on:submitCancelEvent="cencelEditSubmit"
      v-on:submitEvent="submitEdit"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { ReporterOrganization } from '@/models'

import HorusManageReporterOrganizationFrame from '@/components/reporterOrganization/HorusManageReporterOrganizationFrame.vue'
import HorusSystemReporterOrganizationEditModal from '@/components/manageSystem/reporterOrganization/HorusSystemReporterOrganizationEditModal.vue'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageReporterOrganizationBasic',

  components: {
    HorusManageReporterOrganizationFrame,
    HorusSystemReporterOrganizationEditModal,
    HorusCustomCheckBox,
    HorusConfirmDialog
  },

  props: {
    signinReporter: Object,
    reporterOrganization: Object
  },

  data () {
    return {
      showReporterOrganizationEditModal: false,
      admitAgreement: false,
      submitNBID: '',
      submitPassword: ''
    }
  },

  computed: {
    prepareRevokeReady () {
      if (this.submitNBID && this.submitNBID.length > 0 && this.submitPassword && this.submitPassword.length > 0 && this.admitAgreement) {
        return true
      }
      return false
    },

    iconImageSrc () {
      if (this.reporterOrganization) {
        return this.reporterOrganization.iconImageSrc
      } else {
        return ''
      }
    }
  },

  methods: {
    edit () {
      this.showReporterOrganizationEditModal = true
    },

    cencelEditSubmit (callback) {
      this.showReporterOrganizationEditModal = false
      callback()
    },

    submitEdit (formData, callback) {
      ReporterOrganization.api().update({ formData: formData }).then(() => {
        // reload data
        this.$emit('reloadReporterOrganizationEvent')
        this.showReporterOrganizationEditModal = false
        callback()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('報告施設更新失敗', '報告施設の情報更新に失敗しました。')
      })
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    submitPrepareRevoke () {
      if (this.submitNBID && this.submitNBID.length > 0 && this.submitPassword && this.submitPassword.length > 0 && this.admitAgreement) {
        this.showConfirmDialog(
          '報告施設退会申請の送信',
          '報告施設の退会申請を送信します。\n送信後、システム管理者によって報告施設は削除されます。\n削除申請を送信しますか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executePrepareRevoke() }
        )
      } else {
        this.showNotifyDialog('報告施設退会申請', 'フォームに、報告施設管理者であるあなたのNBIDとログインパスワードを入力し、確認事項のチェックを行ってください。')
      }
    },

    executePrepareRevoke () {
      var formData = new FormData()
      if (this.admitAgreement) {
        formData.append('admit_quit', true)
      }
      formData.append('id', this.reporterOrganization.id)
      formData.append('login_id', this.submitNBID)
      formData.append('password', this.submitPassword)
      ReporterOrganization.api().prepareRevoke({ formData: formData }).then(res => {
        console.log('prepare revoke send.')
        console.log(JSON.stringify(res))
        if (res.success) {
          this.$emit('reloadReporterOrganizationEvent')
          this.showNotifyDialog('報告施設退会申請', '報告施設の退会申請を送信しました。')
        } else {
          this.showNotifyDialog('報告施設退会申請失敗', res.message)
        }
      }).catch(error => {
        console.error(error.response)
      })
    },

    cancelRevoke () {},

    executeCancelRevoke () {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/centeringTitle.scss';
@import '@/assets/css/redTitledColumn.scss';
@import '@/assets/css/listData.scss';

.horus-manage-reporter-organization-basic {
  margin: 0;
  .icon-image {
    width: 100px;
    height: 100px;
  }
  .remove-linkage-btn {
    margin-left: 30px;
    width: 100px;
    margin-left: 10px;
    border: 1px solid #999;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    background-color: #666;
    color: #fff;
  }
  .submit-revoke {
    .notify-caption {
      padding: 5px;
      border: 1px solid #ddd;
      font-size: 0.9em;
      ul {
        margin: 5px 0;
        padding-inline-start: 20px;
      }
    }
    .revoke-form {
      margin: 5px 0;
      padding: 10px;
      border: 1px solid #aaa;
      label {
        margin-right: 10px;
      }
      .submit-revoke-btn {
        padding: 3px;
        border-radius: 3px;
        border: 1px solid #666;
        background-color: #ddd;
        color: #fff;
        font-weight: bold;
        &.ready {
          background-color: #666;
        }
      }
    }
  }
  .revoke-prepared-notify {
    padding: 5px;
    border: 1px solid #c43d53;
  }
}
</style>
