<template>
  <div class="horus-timeline-drug-entry">
    <div class="drug-entry-title">
      <img src="/static/images/drug_green.png" />
      お薬情報
    </div>
    <div class="drug-entry-content">
      <div class="drug-entry-text" v-html="textCommentHtml"></div>
      <div class="drug-entry-photos" v-if="drugEntry.drugEntryPhotos.length > 0">
        <horus-inline-photo
          v-for="entryPhoto in drugEntry.drugEntryPhotos"
          :key="entryPhoto.id"
          :photoObject="entryPhoto"
          :photoMaxSize="100"
          v-on:timeliePhotoClickEvent="showPhoto(entryPhoto)" />
      </div>
    </div>
  </div>
</template>

<script>
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'

export default {
  name: 'HorusTimelineDrugEntry',

  components: { HorusInlinePhoto },

  props: {
    drugEntry: Object
  },

  computed: {
    textCommentHtml () {
      if (this.drugEntry.comment) {
        return this.drugEntry.comment.replace('\n', '<br />')
      } else {
        return ''
      }
    }
  },

  methods: {
    showPhoto (entryPhoto) {
      this.$emit('durgEntryPhotoClickEvent', entryPhoto)
    }
  }

}
</script>

<style lang="scss" scoped>
.drug-entry-title {
  font-weight: bold;
  color: #666;
  img {
    margin-right: 5px;
    margin-bottom: 3px;
    width: 15px;
    vertical-align: middle;
  }
}
.drug-entry-text {
  padding-bottom: 5px;
  line-height: 1.5em;
}
</style>
