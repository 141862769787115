import { Model } from '@vuex-orm/core'
// import axiosHelper from '@/api/AxiosInstance'

import TimelineRecord from '@/models/TimelineRecord.js'

export default class PhotoReport extends Model {
  static entity = 'photoReports'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      record_id: this.attr(null),
      target_at: this.string(''),
      filename: this.string(''),
      imageHeight: this.number(0),
      imageWidth: this.number(0)
    }
  }

  get photoSrc () {
    return process.env.VUE_APP_BASE_URL + '/record/show_photo_report?target_user_id=' + this.user_id + '&photo_report_id=' + this.id
  }

  get photoThumbnailSrc () {
    return process.env.VUE_APP_BASE_URL + '/record/show_photo_report?target_user_id=' + this.user_id + '&photo_report_id=' + this.id + '&version=thumb'
  }

  get photoMiddleSrc () {
    return process.env.VUE_APP_BASE_URL + '/record/show_photo_report?target_user_id=' + this.user_id + '&photo_report_id=' + this.id + '&version=middle'
  }

  get timelineRecord () {
    return TimelineRecord.find(this.record_id)
  }

  get targetAt () {
    return this.timelineRecord.targetAt
    // return new Date(this.target_at)
  }

  static apiConfig = {
    actions: {
      fetchCount ({ timelineOwnerID }) {
        return this.request({
          method: 'get',
          url: 'photo_report/count',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          return { count: res.response.data.photo_report_count }
        })
      },
    
      fetchIDList ({ timelineOwnerID, options }) {
        var params = { target_user_id: timelineOwnerID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'photo_report/list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { photoReports: res.response.data.photo_reports }
        })
      },
    
      fetchTermIDList ({timelineOwnerID, targetDate, options}) {
        var params = { target_user_id: timelineOwnerID, target_date: targetDate }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'photo_report/term_list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { photoReports: res.response.data.photo_reports, targetPhotoID: res.response.data.target_photo_id }
        })
      },
    
      fetchLatestIDList ({timelineOwnerID, targetPhotoID, options}) {
        var params = { target_user_id: timelineOwnerID, photo_report_id: targetPhotoID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'photo_report/latest_list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { photoReports: res.response.data.photo_reports }
        })
      },
    
      fetchOldIDList ({timelineOwnerID, targetPhotoID, options}) {
        var params = { target_user_id: timelineOwnerID, photo_report_id: targetPhotoID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'photo_report/old_list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { photoReports: res.response.data.photo_reports }
        })
      },
    
      latestExist ({ timelineOwnerID, photoReportID, options }) {
        var params = { target_user_id: timelineOwnerID, photo_report_id: photoReportID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'photo_report/latest_exist',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { latestExist: res.response.data.latestExist }
        })
      },
    
      oldExist ({ timelineOwnerID, photoReportID, options }) {
        var params = { target_user_id: timelineOwnerID, photo_report_id: photoReportID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'photo_report/old_exist',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { oldExist: res.response.data.oldExist }
        })
      }
    }
  }
}
