import axios from './AxiosInstance'

// id: Number
// login_id: as NBID
// name: String
// mail: String
// message_content: String
// short_token: String(4disit number)
// status: String
export default {
  fetchSentList: ({timelineOwnerID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'authorized_family_invitation/list',
        params: { target_user_id: timelineOwnerID, all: true },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ authorized_family_invitations: res.data.authorized_family_invitations })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchReceiveList: ({timelineOwnerID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'authorized_family_invitation/received_list',
        params: { target_user_id: timelineOwnerID },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ authorized_family_invitations: res.data.authorized_family_invitations })
        }
      }).catch(error => { reject(error) })
    })
  },

  // createのみ。updateはない。
  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'authorized_family_invitation',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({timelineOwnerID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'authorized_family_invitation',
            withCredentials: true,
            params: { target_user_id: timelineOwnerID, authorized_family_invitation_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  sendMail: ({timelineOwnerID, targetID}) => {
    var formData = new FormData()
    formData.append('target_user_id', timelineOwnerID)
    formData.append('authorized_family_invitation_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authorized_family_invitation/send_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchReceive: ({invitationToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'authorized_family_invitation',
        params: { token: invitationToken },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ sender_name: res.data.sender_name, limit_at: res.data.limit_at, message_content: res.data.message_content })
        }
      }).catch(error => { reject(error) })
    })
  },

  validateToken: ({invitationToken, shortToken}) => {
    var formData = new FormData()
    formData.append('token', invitationToken)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authorized_family_invitation/validate_token',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitOnSignin: ({timelineOwnerID, targetID, both}) => {
    console.log('In API, show own timeline: ' + both)
    var formData = new FormData()
    formData.append('target_user_id', timelineOwnerID)
    formData.append('authorized_family_invitation_id', targetID)
    if (both) {
      formData.append('both', both)
    }
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authorized_family_invitation/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitWithToken: ({invitationToken, shortToken, loginID, password, both}) => {
    var formData = new FormData()
    formData.append('token', invitationToken)
    formData.append('short_token', shortToken)
    formData.append('login_id', loginID)
    formData.append('password', password)
    if (both) {
      formData.append('both', both)
    }
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authorized_family_invitation/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitWithUserCreate: ({targetID, both}) => {
    var formData = new FormData()
    formData.append('authorized_family_invitation_id', targetID)
    if (both) {
      formData.append('both', both)
    }
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authorized_family_invitation/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  rejectOnSignin: ({timelineOwnerID, targetID}) => {
    var formData = new FormData()
    formData.append('target_user_id', timelineOwnerID)
    formData.append('authorized_family_invitation_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authorized_family_invitation/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  rejectViaMail: ({invitationToken, shortToken}) => {
    var formData = new FormData()
    formData.append('token', invitationToken)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'authorized_family_invitation/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
