<template>
  <div class="horus-manage-organization-request">
    <h1 class="centering-title">施設管理</h1>
    <h2>施設登録申請一覧</h2>
    <div class="head-controll">
      <div class="state-list">
        <button class="narrow-down-btn" :class="{disable: currentState === 'waiting'}" @click="showWaitingList">処理待ち申請一覧</button>
        <button class="narrow-down-btn" :class="{disable: currentState === 'nonProcessed'}" @click="showNonProcessedList">未処理一覧</button>
        <button class="narrow-down-btn" :class="{disable: currentState === 'all'}" @click="showAllList">全申請一覧</button>
      </div>
      <div class="data-controll">
        <span class="controll-content">登録後7日以上メール承認していない申請: {{ notValidatedCount }}件</span><button class="destroy-garbage-btn" @click="destroyGarvage">未処理申請の削除</button>
      </div>
    </div>
    <div class="organization-request-list">
      <div class="list-column"
        v-for="orgReq in displayOrganizationRequests"
        :key="'sysOrgReqList' + orgReq.id"
        >
        <div class="list-line">
          <div class="mail"><span>申請mail:</span>{{ orgReq.mail }}</div>
          <div class="name"><span>施設名:</span>{{ orgReq.name }}</div>
        </div>
        <div class="list-line">
          <div class="created">作成日 : [{{ dateString(orgReq.submit_at)}}]</div>
          <div class="applicate">申請日 : {{ dateString(orgReq.applicate_at) }}</div>
          <div class="state">状態 : {{ orgReq.state }}</div>
        </div>
        <div class="list-line">
          <div class="controll"><button type="button" class="show-btn" @click="showDetail(orgReq)">詳細</button></div>
        </div>
      </div>
    </div><!-- organization-list -->
    <horus-manage-organization-request-detail-modal
      :organizationRequest="targetRequest"
      :showModal="showDetailModal"
      v-on:modalCloseEvent="closeDetailModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  OrganizationRequest
} from '@/api'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusManageOrganizationRequestDetailModal from '@/components/manageSystem/organizationRequest/HorusManageOrganizationRequestDetailModal.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageOrganizationRequestView',

  components: {
    HorusConfirmDialog,
    HorusManageOrganizationRequestDetailModal
  },

  data () {
    return {
      currentState: 'waiting',
      displayOrganizationRequests: [],
      notValidatedCount: 0,
      targetRequest: null,
      showDetailModal: false
    }
  },

  mounted () {
    this.loadOrganizationRequests()
    this.loadNotValidatedCount()
  },

  methods: {
    loadOrganizationRequests () {
      switch (this.currentState) {
        case 'waiting':
          this.showWaitingList()
          break
        case 'nonProcessed':
          this.showNonProcessedList()
          break
        case 'all':
          this.showAllList()
          break
        default:
          this.showWaitingList()
      }
    },

    loadNotValidatedCount () {
      OrganizationRequest.fetchNotValidatedCount().then(res => {
        this.notValidatedCount = res.count
      }).catch(error => {
        console.log(error.response)
      })
    },

    showWaitingList () {
      OrganizationRequest.fetchConfirmWaitingRequests().then(res => {
        console.log(res)
        this.displayOrganizationRequests = res.organizationRequests
        this.currentState = 'waiting'
      }).catch(error => {
        console.error(error.response)
      })
    },

    showNonProcessedList () {
      OrganizationRequest.fetchAllNotProcessedRequests().then(res => {
        this.displayOrganizationRequests = res.organizationRequests
        this.currentState = 'nonProcessed'
      }).catch(error => {
        console.error(error.response)
      })
    },

    showAllList () {
      OrganizationRequest.fetchList().then(res => {
        this.displayOrganizationRequests = res.organizationRequests
        this.currentState = 'all'
      }).catch(error => {
        console.error(error.response)
      })
    },

    dateString (targetDate) {
      return moment(targetDate).format('YYYY/MM/DD HH:mm')
    },

    destroyGarvage () {
      this.showConfirmDialog(
        '疎通確認待機中で7日以上経過した施設作成申請の全削除',
        '疎通確認待機中で7日以上経過した施設作成申請をすべて削除にしようとしています。削除した場合、申請処理は継続不可能となります。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroyGarvage() }
      )
    },

    executeDestroyGarvage () {
      OrganizationRequest.destroyAllNotValidated().then(res => {
        this.showNotifyDialog('疎通確認待機中で7日以上経過した施設作成申請の全削除完了', '疎通確認待機中で7日以上経過した施設作成申請の全削除を完了しました。削除された申請は' + res.count + '件です。')
        this.loadOrganizationRequests()
        this.loadNotValidatedCount()
      }).catch(error => {
        console.error(error.response)
      })
    },

    showDetail (orgRequest) {
      this.targetRequest = orgRequest
      this.showDetailModal = true
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    closeDetailModal () {
      this.showDetailModal = false
      this.targetRequest = null
      this.loadOrganizationRequests()
      this.loadNotValidatedCount()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-manage-organization-request {
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  .head-controll {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .state-list {
      display: flex;
      .narrow-down-btn {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #37a34a;
        border-radius: 3px;
        background-color: #37a34a;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        &.disable {
          background-color: #fff;
          color: #37a34a;
        }
      }
    }
    .data-controll {
      .destroy-garbage-btn {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .organization-request-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        // justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.mail {
            width: 30%;
            span {
              margin-right: 10px;
              font-weight: bold;
            }
          }
          &.name {
            // width: 50%;
            font-weight: bold;
            span {
              margin-right: 10px;
              font-weight: bold;
            }
          }
          &.type {
            width: 160px;
          }
          &.created {
            width: 180px;
          }
          &.applicate {
            width: 180px;
          }
          &.state {
            // width: 120px;
            width: auto;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
