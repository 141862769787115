import axios from './AxiosInstance'

export default {
  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'member_portal_configuration',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ portal_configuration: res.data.cgc_portal_configuration })
        }
      }).catch(error => { reject(error) })
    })
  },

  update: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'member_portal_configuration',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
