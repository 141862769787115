import axios from 'axios'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'

import {
  ActivityLog,
  AreaBlock,
  AreaBlockOperator,
  Author,
  BoadComment,
  BoadCommentFile,
  BoadCommentPhoto,
  DrugEntry,
  DrugEntryPhoto,
  Family,
  FileReport,
  HashTag,
  MedicalExamination,
  MillviAudioContent,
  MillviVideoContent,
  NudgedRecord,
  Organization,
  PhotoReport,
  PublicAnnouncement,
  ReporterOrganization,
  SigninMember,
  SigninReporter,
  SigninUser,
  StaffVisibility,
  SystemManager,
  TaskDisplayConfiguration,
  TimelineOwner,
  TimelineRecord,
  UserTask,
  UserTaskFile,
  UserTaskPhoto,
  VitalActiveMass,
  VitalBloodPressure,
  VitalBodyComposition,
  VitalBodyTemperature,
  VitalDisplayConfig,
  VitalEntry,
  VitalOxygenSaturation,
  VitalPulseRate,
  VitalValue,
  Wish,
  WishFile,
  WishPhoto,
  ZoomMeeting
} from '../models'

VuexORM.use(VuexORMAxios, {
  axios, 
  baseURL: window.location.origin + process.env.VUE_APP_BACKEND_PATH,
  xsrfHeaderName: 'X-CSRF-TOKEN',
  headers: {
    'Cache-Controll': 'no-store',
    'Expires': 0,
  },
  withCredentials: true, // 依然として必要
  credentials: 'include',
  mode: 'cors',
})

const database = new VuexORM.Database()

database.register(ActivityLog)
database.register(AreaBlock)
database.register(AreaBlockOperator)
database.register(Author)
database.register(BoadComment)
database.register(BoadCommentFile)
database.register(BoadCommentPhoto)
database.register(DrugEntry)
database.register(DrugEntryPhoto)
database.register(Family)
database.register(FileReport)
database.register(HashTag)
database.register(MedicalExamination)
database.register(MillviAudioContent)
database.register(MillviVideoContent)
database.register(NudgedRecord)
database.register(Organization)
database.register(PhotoReport)
database.register(PublicAnnouncement)
database.register(ReporterOrganization)
database.register(SigninMember)
database.register(SigninReporter)
database.register(SigninUser)
database.register(StaffVisibility)
database.register(SystemManager)
database.register(TaskDisplayConfiguration)
database.register(TimelineOwner)
database.register(TimelineRecord)
database.register(UserTask)
database.register(UserTaskFile)
database.register(UserTaskPhoto)
database.register(VitalActiveMass)
database.register(VitalBloodPressure)
database.register(VitalBodyComposition)
database.register(VitalBodyTemperature)
database.register(VitalDisplayConfig)
database.register(VitalEntry)
database.register(VitalOxygenSaturation)
database.register(VitalPulseRate)
database.register(VitalValue)
database.register(Wish)
database.register(WishFile)
database.register(WishPhoto)
database.register(ZoomMeeting)
export default database
