<template>
  <div class="container" :class="signinType" id="container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="public_announcement_block" @click="loadPublicAnnouncements">
      <div class="public_announcement_heading">お知らせ</div>
      <div class="public_announcement_column">
        <div v-for="publicAnnouncement in publicAnnouncements" :key="publicAnnouncement.id" class="public_announcement_single">
          <div class="public_announcement_date">{{ publicAnnouncement.announcementAtStr }}</div>
          <div class="public_announcement_content" v-html="publicAnnouncement.contentHTML"></div>
        </div>
      </div>
    </div>
    <div class="signin-block">
      <form id='own_signin_form' v-on:submit.prevent="doSignin">
        <div class="signin-input"><label>NBID</label><input type="text" v-model="inputLoginId" ref="loginIDField"></div>
        <div class="signin-input"><label>パスワード</label><input type="password" ref="passwordField" id="password" v-model="inputPassword"></div>
        <div class="signin_pass_toggle" @click="togglePasswordVisible">
          <span>パスワード表示</span>
          <horus-custom-check-box
            :active="passwordVisible"
            :enabled="true"
            :type="'small'"
            />
        </div>
        <button type="submit" class="btn success signin-btn">ログイン</button>
      </form>
    </div>
    <div class="signin-button-controlls">
      <router-link to="/create_user" class="btn warning join-btn">新規利用者登録</router-link><br/>
      パスワードを忘れた場合は<a href="/reset_password">こちら</a>
      <br>
      <span class="switch-signin-type-btn normal" @click="switchSigninType(null)" v-show="userBtnVisible">一般ユーザーログインに切り替え</span>
      <span class="switch-signin-type-btn member" @click="switchSigninType('member')" v-show="memberBtnVisible">施設職員ログインに切り替え</span>
      <span class="switch-signin-type-btn reporter" @click="switchSigninType('reporter')" v-show="reporterBtnVisible">報告施設ログインに切り替え</span>
    </div>
    <horus-footer/>
    <horus-critical-notification-modal
      :showModal="criticalNotificationModalDisplay"
      :criticalNotifications="firedCriticalNotifications"
      :confirmedNotifications="[]"
      v-on:resolveNotificationEvent="resolveCriticalNotification"
      v-on:closeModalEvent="closeCriticalNotificationModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
// import { mapState } from "vuex"

import {
  PublicAnnouncement,
  SigninUser,
  SigninMember,
  SigninReporter
} from '@/models'
import {
CriticalNotification,
  SigninState
} from '@/api'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusCriticalNotificationModal from '@/components/criticalNotification/HorusCriticalNotificationModal.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusLandingView',

  components: {
    HorusFooter,
    HorusConfirmDialog,
    HorusCriticalNotificationModal,
    HorusCustomCheckBox
  },

  data () {
    return {
      signinType: '',
      publicAnnouncements: [],
      inputLoginId: '',
      inputPassword: '',
      signinUser: null,
      firedCriticalNotifications: [],
      criticalNotificationModalDisplay: false,
      passwordVisible: false
    }
  },

  created () {
    this.$native.appSendWeb = (id, password) => {
      this.appSendWeb(id, password)
    }
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      console.log(this.$native.status)
    }
    this.checkSigninedIn()
    this.preparePublicAnnouncements()
  },

  computed: {
    // ...mapState('entities/publicAnnouncement', { publicAnnouncementsFetchedAt: (state) => state.fetchedAt }),

    signinTypeUser () {
      if (this.signinType !== 'member' && this.signinType !== 'reporter') {
        return true
      }
      return false
    },

    signinTypeMember () {
      if (this.signinType === 'member') {
        return true
      }
      return false
    },

    signinTypeReporter () {
      if (this.signinType === 'reporter') {
        return true
      }
      return false
    },

    userBtnVisible () {
      if (this.signinType !== 'member' && this.signinType !== 'reporter') {
        return false
      }
      return true
    },

    memberBtnVisible () {
      if (this.signinType === 'member') {
        return false
      }
      return true
    },

    reporterBtnVisible () {
      if (!process.env.VUE_APP_BETA_ENABLE || this.signinType === 'reporter') {
        return false
      }
      return true
    }
  },

  watch: {
    '$route': { handler: 'fixType', immediate: true },
    // publicAnnouncementsFetchedAt: { handler: 'loadPublicAnnouncements', immediate: true }
  },

  methods: {
    fixType () {
      console.log('route params')
      console.log(this.$route.query)
      if (this.$route.params.type) {
        this.signinType = this.$route.params.type[0]
      }
    },

    preparePublicAnnouncements () {
      PublicAnnouncement.api().fetch().then(() => {
        // console.log('preaprePublicAnnouncement')
        // console.log(PublicAnnouncement.all())
        // console.log(this.$store.state.entities.publicAnnouncement.data)
        this.publicAnnouncements = PublicAnnouncement.all()
      })
    },

    loadPublicAnnouncements () {
      // console.log('loadPA')
      this.publicAnnouncements = PublicAnnouncement.all()
    },

    async checkSigninedIn () {
      // const allUser = SigninUser.all()
      // this.signinUser = allUser[0]
      // console.log('checkSignedIn, ')
      // console.log(this.signinUser)
      // SigninState.currentState().then(data => {
      //   console.log('in landingview checkSignedIn')
      //   console.log(data)
      //   if (this.signinUser && data.user_id === this.signinUser.user_id) {
      //     this.$router.push({ path: '/maintimeline/' + this.signinUser.user_id })
      //   } else {
      //     SigninUser.deleteAll()
      //     this.signinUser = null
      //   }
      // })
      console.log('redirect path on landing.')
      console.log(this.$route.query.redirect)
      // 現在のサインイン状態を取得する
      var user = null
      var member = null
      var reporter = null
      await Promise.all([
        SigninUser.api().fetchCurrentSingin().then(usr => {
          user = usr
        }).catch(() => { user = null}),
        SigninMember.api().fetchCurrentSingin().then(mem => {
          member = mem
        }).catch(() => { member = null}),
        SigninReporter.api().fetchCurrentSingin().then(rep => {
          reporter = rep
        }).catch(() => { reporter = null})
      ])
      // console.log('on landing current signins....')
      // console.log(user)
      // console.log(member)
      // console.log(reporter)
      let state = await SigninState.currentState()
      if (!state.user_id && !state.member_id && !state.reporter_id) {
        // state not signin
        console.log('user/menber not signed in.')
        // DO NOTHING
      } else if ((user && state.user_id !== user.user_id) || (member && state.member_id !== member.id) || (reporter && state.reporter_id !== reporter.id)) {
        // 状態が不整合なので一旦リセットを掛ける（本来は発生しないはず）
        console.log('requireAuth, not match server response state with local signinObjects.')
        this.signinUser = null
        // DO NOTHING
      } else {
        // ログインしている状態に応じて遷移先を振り分ける
        if (member) {
          // console.log('gonna redirect for member')
          if (this.$route.query.redirect && this.$route.query.redirect.length > 2) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ path: '/member_portal' })
          }
        } else if (reporter) {
          // console.log('gonna redirect for reporter')
          if (this.$route.query.redirect && this.$route.query.redirect.length > 2) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ path: '/reporter_portal' })
          }
        } else if (user) {
          // console.log('gonna redirect for user')
          if (this.$route.query.redirect && this.$route.query.redirect.length > 2) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ path: '/maintimeline/' + user.user_id })
          }
        } else {
          console.log('all type object could not get.')
        }
      }
    },

    switchSigninType (type) {
      if (type) {
        this.signinType = type
      } else {
        this.signinType = ''
      }
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
      const passwordField = this.$refs.passwordField
      if (this.passwordVisible) {
        passwordField.type = 'text'
      } else {
        passwordField.type = 'password'
      }
    },

    async doSignin () {
      this.sendDataWebToApp()
      if (this.signinTypeMember) {
        try {
          let signinMember = await SigninMember.api().fetch({ loginID: this.inputLoginId, password: this.inputPassword })
          // const allMember = SigninMember.all()
          // this.SigninMember = allMember[0]
          this.signinMember = signinMember
          // ログインが指定される前のパスへ遷移
          if (this.$route.query.redirect && this.$route.query.redirect.length > 2) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ path: '/member_portal' })
          }
        } catch (error) {
          console.error(error)
          if (error.response.status === 401 && error.response.data.critical_notification_targets) {
            this.showCriticalNotification(error.response.data.critical_notification_targets)
          } else if (error.response.status === 401 && error.response.data.user) {
            this.showConfirmDialog(
              '職員としてのログイン失敗',
              '職員としてログインしようとしましたが、正しい職員アカウントが存在しません。\n一般利用者としてのログインは可能です。\n一般利用者としてログインしますか?',
              DIALOG_TYPE.YES_NO,
              () => {
                this.signinType = ''
                this.doSignin()
              }
            )
          } else {
            this.showFailSigninDialog()
          }
        }
      } else if (this.signinTypeReporter) {
        try {
          let signinReporter = await SigninReporter.api().fetch({ loginID: this.inputLoginId, password: this.inputPassword })
          // const allReporter = SigninReporter.all()
          // this.SigninReporter = allReporter[0]
          this.signinReporter = signinReporter
          console.log('reporter signin succeeded...')
          // ログインが指定される前のパスへ遷移
          if (this.$route.query.redirect && this.$route.query.redirect.length > 2) {
            console.log('gonna move to ' + this.$route.query.redirect)
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            console.log('gonna move to reporter_portal')
            this.$router.push({ path: '/reporter_portal' })
          }
        } catch (error) {
          if (error.response.status === 401 && error.response.data.critical_notification_targets) {
            this.showCriticalNotification(error.response.data.critical_notification_targets)
          } else if (error.response.status === 401 && error.response.data.user) {
            this.showConfirmDialog(
              '報告施設職員としてのログイン失敗',
              '報告施設職員としてログインしようとしましたが、正しい職員アカウントが存在しません。\n一般利用者としてのログインは可能です。\n一般利用者としてログインしますか?',
              DIALOG_TYPE.YES_NO,
              () => {
                this.signinType = ''
                this.doSignin()
              }
            )
          } else {
            this.showFailSigninDialog()
          }
        }
      } else {
        try {
          let signinUser = await SigninUser.api().fetch({ loginID: this.inputLoginId, password: this.inputPassword })
          this.signinUser = signinUser
          console.log(this.signinUser)
          // ログインが指定される前のパスへ遷移
          if (this.$route.query.redirect && this.$route.query.redirect.length > 2) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ path: '/maintimeline/' + this.signinUser.user_id })
          }
        } catch (error) {
          console.log(error)
          console.log(error.response)
          if (error.response.status === 401 && error.response.data.critical_notification_targets) {
            this.showCriticalNotification(error.response.data.critical_notification_targets)
          } else if (error.response.status === 401) {
            this.showFailSigninDialog()
          } else {
            // 何らかのエラー
            this.showNotifyDialog('ログイン失敗', 'ログインに失敗しました。')
            this.inputLoginId = ''
            this.inputPassword = ''
          }
        }
      }
    },

    showCriticalNotification (notifications) {
      this.firedCriticalNotifications = notifications
      this.criticalNotificationModalDisplay = true
    },

    resolveCriticalNotification (notification) {
      if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_SHOW_ONECE) {
        CriticalNotification.resolveByToken({ confirmationToken: notification.target_token }).then(res => {
          if (res.success) {
            notification.resolved = true
          }
        }).catch(err => {
          console.error(err)
        })
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE) {
        this.confirmedCriticalNotifications.push(notification)
        CriticalNotification.resolveByToken({ confirmationToken: notification.target_token }).then(res => {
          console.log(res)
          if (res.success) {
            notification.resolved = true
          }
        }).catch(err => {
          console.error(err)
        })
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET && notification.resolve_url) {
        this.$router.push({ path: notification.resolve_url, query: { criticalNotification: notification } })
      }
      if (this.firedCriticalNotifications.every(notification => notification.resolved)) {
        this.criticalNotificationModalDisplay = false
      }
    },

    closeCriticalNotificationModal () {
      this.criticalNotificationModalDisplay = false
    },

    showFailSigninDialog () {
      this.showConfirmDialog(
        'ログイン失敗',
        'ログインに失敗しました。NBIDかパスワードが間違っています。',
        DIALOG_TYPE.NOTIFY,
        () => {
          this.$nextTick(() => { this.$refs.loginIDField.focus() })
        }
      )
      this.inputLoginId = ''
      this.inputPassword = ''
    },

    appSendWeb (mUserName, mPassword) {
      console.log('Vo appSendWeb')
      this.inputLoginId = mUserName.toString()
      this.inputPassword = mPassword.toString()
      this.doSignin()
    },

    sendDataWebToApp () {
      try {
        var str = '{"userID":"' + this.inputLoginId + '","password":"' + this.inputPassword + '"}'
        // console.log(str)
        if (window.webkit) {
          window.webkit.messageHandlers.callback.postMessage(str)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';
@import '../assets/css/_basicInputStyle.scss';

.container {
  background-color: #eee;
  .public_announcement_block {
    margin: 20px 40px;
    padding: 0;
    @include mediaQuery('tablet') {
      margin: 10px;
    }
    @include mediaQuery('phone') {
      margin: 10px;
    }
  }
  .public_announcement_heading {
    margin: 0;
    padding: 3px 10px;
    color: #fff;
    background-color: #692220;
    border: 1px solid #692220;
    font-weight: bold;
  }
  .public_announcement_column {
    margin: 0;
    padding: 10px 20px 0;
    background-color: #fff;
    border: 1px solid #aaa;
    .public_announcement_single {
      .public_announcement_date {
        font-weight: bold;
      }
      .public_announcement_content {
        margin-bottom: 10px;
      }
    }
  }
  .message-column {
    margin: 20px 40px;
    padding: 20px;
    text-align: center;
    border: 1px solid #692220;
    color: #692220;
    background-color: #fdeff2;
  }
  .signin-block {
    margin: 20px auto;
    padding: 40px;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #aaa;
    text-align: center;
    @include mediaQuery('tablet') {
      // margin: 10px;
    }
    @include mediaQuery('phone') {
      padding: 30px 10px;
      margin: 10px;
    }
    .signin-input {
      width: 100%;
      margin-bottom: 10px;
      label {
        display: inline-block;
        margin-right: 10px;
        width: 100px;
        text-align: right;
        font-weight: bold;
        @include mediaQuery('phone') {
          width: 70px;
        }
      }
      input {
        font-size: 16px;
      }
    }
    .signin_pass_toggle {
      margin: 0 auto;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;
      font-size: 12px;
      span {
        display: inline-block;
        margin-right: 5px;
      }
    }
    .signin-btn {
      width: 150px;
      padding: 8px 16px;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .signin-button-controlls {
    margin: 20px;
    text-align: center;
    line-height: 2em;
    .join-btn {
      font-weight: bold;
    }
  }
  .switch-signin-type-btn {
    display: inline-block;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0 8px;
    border: 1px solid #999;
    border-radius: 3px;
    background-color: #999;
    color: #fff;
    font-weight: bold;
    &.normal {
      border-color: #692220;
      background-color: #692220;
    }
    &.member {
      border-color: #2b4733;
      background-color: #2b4733;
    }
    &.reporter {
      border-color: #4f455c;
      background-color: #4f455c;
    }
  }
  .navbar {
    .main-bar {
      background-color: #692220;
      background-image: url("/static/images/nb_header_iconless.png");
    }
  }
  &.member {
    .navbar {
      .main-bar {
        background-color: #2b4733;
        background-image: url("/static/images/cgc_header_iconless.png");
      }
    }
    .public_announcement_heading {
      background-color: #2b4733;
      border: 1px solid #2b4733;
    }
    .message-column {
      margin: 20px 40px;
      padding: 20px;
      text-align: center;
      border: 1px solid #2b4733;
      color: #2b4733;
    }
  }
  &.reporter {
    .navbar {
      .main-bar {
        background-color: #4f455c;
        background-image: url("/static/images/reporter_header_iconless.png");
      }
    }
    .public_announcement_heading {
      background-color: #4f455c;
      border: 1px solid #4f455c;
    }
    .message-column {
      margin: 20px 40px;
      padding: 20px;
      text-align: center;
      border: 1px solid #4f455c;
      color: #4f455c;
    }
  }
}
</style>
