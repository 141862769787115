import axios from './AxiosInstance'

export default {
  fetch: ({ targetUserID }) => {
    var params = {}
    if (targetUserID) {
      params = { target_user_id: targetUserID }
    }
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'user_watching_state/unlead_counts',
        params: params,
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve( res.data )
        }
      }).catch(error => { reject(error) })
    })
  },
}
