import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'

export default class StaffVisibility extends Model {
  static entity = 'staffVisibilities'

  static fields () {
    return {
      id: this.attr(null),
      organizationID: this.attr(null),
      userID: this.attr(null),
      memberID: this.attr(null),
      visible: this.attr(null)
    }
  }

  get member () {
    // TODO: load data when undefined
    return Author.find(this.memberID)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async storeData (payload) {
    StaffVisibility.commit((state) => {
      state.fetchedAt = new Date()
    })
    console.log(payload.staff_visibilities)
    let orgID = null
    let userIDList = []
    if (payload.staff_visibilities) {
      var unstoredVisibility = []
      for(var visibility of payload.staff_visibilities) {
        orgID = visibility.organizationID
        userIDList.push(visibility.userID)
        var sVisibility = StaffVisibility.find(visibility.id)
        if (!sVisibility || sVisibility.visible != visibility.visible) {
          unstoredVisibility.push(visibility)
        }
      }
      // TODO: remove lost staff_visibilities
      let allVisibility = StaffVisibility.query().where('organizationID', orgID).get()
      for(let oldVisibility of allVisibility) {
        let exist = false
        for(let newVisivirity of payload.staff_visibilities) {
          if (newVisivirity.id === oldVisibility.id) {
            exist = true
          }
        }
        if (!exist) {
          oldVisibility.$delete()
        }
      }
      return StaffVisibility.insertOrUpdate({ data: unstoredVisibility })
    }
  }

  static async loadAuthors(entities) {
    console.log('StaffVisibiryty:loadAuthors')
    console.log(entities)
    if (entities && entities.staffVisibilities) {
      var loadIDList = []
      for(var entity of entities.staffVisibilities) {
        if (!entity.member) {
          if (loadIDList.indexOf(entity.memberID) < 0) loadIDList.push(entity.memberID)
        }
      }
      if (loadIDList.length > 0) {
        await Author.api().fetchByID({ idList: loadIDList })
      }
      return entities.staffVisibilities
    } else {
      return []
    }
  }

  static apiConfig = {
    actions: {
      async init () {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'staff_visibility/init',
            save: false
          }
        })
      },
    
      list ({organizationID, userID}) {
        return this.request({
          method: 'get',
          url: 'staff_visibility/list',
          params: { id: organizationID, target_user_id: userID },
          withCredentials: true,
          save: false
        })
        .then(res => {
          console.log('StaffVisibility:list')
          console.log(res)
          return StaffVisibility.storeData(res.response.data)
          // StaffVisibility.commit((state) => {
          //   state.fetchedAt = new Date()
          // })
          // return res.entities.staffVisibilities
          // return StaffVisibility.loadAuthors(res.entities)
        })
      },
    
      listInOrganization ({organizationID}) {
        return this.request({
          method: 'get',
          url: 'staff_visibility/list_in_organization',
          params: { id: organizationID },
          withCredentials: true,
          save: false
        })
        .then(res => {
          console.log('StaffVisibility:listInOrganization')
          console.log(res)
          return StaffVisibility.storeData(res.response.data)
          // StaffVisibility.commit((state) => {
          //   state.fetchedAt = new Date()
          // })
          // return res.entities.staffVisibilities
          // return StaffVisibility.loadAuthors(res.entities)
        })
      },
    
      listForMember () {
        return this.request({
          method: 'get',
          url: 'staff_visibility/list_for_member',
          withCredentials: true,
          save: false
        }).then(res => {
          console.log('StaffVisibility:listForMember')
          console.log(res)
          return StaffVisibility.storeData(res.response.data)
          // StaffVisibility.commit((state) => {
          //   state.fetchedAt = new Date()
          // })
          // return res.entities.staffVisibilities
          // return StaffVisibility.loadAuthors(res.entities)
        })
      },
    
      listForUser ({targetUserID}) {
        return this.request({
          method: 'get',
          url: 'staff_visibility/list_for_user',
          params: { target_user_id: targetUserID },
          withCredentials: true,
          save: false
        }).then(res => {
          console.log('StaffVisibility:listForUser')
          console.log(res)
          return StaffVisibility.storeData(res.response.data)
          // StaffVisibility.commit((state) => {
          //   state.fetchedAt = new Date()
          // })
          // return res.entities.staffVisibilities
          // return StaffVisibility.loadAuthors(res.entities)
        })
      },
    
      async initVisibilityForUser ({organizationID, userID}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'staff_visibility/init_visibility_for_user',
            withCredentials: true,
            params: { id: organizationID, user_id: userID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async toVisible ({organizationID, memberID, userID}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'staff_visibility/to_visible',
            withCredentials: true,
            params: { id: organizationID, service_using_organization_member_id: memberID, user_id: userID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async toInvisible ({organizationID, memberID, userID}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'staff_visibility/to_invisible',
            params: { id: organizationID, service_using_organization_member_id: memberID, user_id: userID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }
    
    }
  }
}
