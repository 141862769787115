<template>
  <div class="horus-print-member-invitation-view print-invitation-page">
    <h1>ナラティブブック&nbsp;新規職員仮登録証</h1>
    <h2>■ 施設情報</h2>
    <table class="print_table">
      <tr><th>施設名</th><td>{{ organizationName }}</td></tr>
    </table>
    <h2>■ 職員情報</h2>
    <table class="print_table">
      <tr><th>氏名</th><td>{{ targetName }}</td></tr>
      <tr><th>メールアドレス</th><td>{{ targetMail }}</td></tr>
    </table>
    <h2>■ 職員登録時に必要な情報（初回のみ）</h2>
    <table class="print_table">
      <tr><th>本人確認用コード</th><td class="short_token">{{ shortToken }}</td></tr>
    </table>
    <h2>■ 職員登録方法</h2>
    <table class="print_table">
      <tr>
        <th>①メールの確認</th>
        <td>
          ナラティブブックから{{ organizationName }}の職員参加案内が届きます。<br />
          <span class="print_caption">※もし届かない場合は、ナラティブブックで利用する以下のドメインを指定受信設定してください。<br />
          @narrativebook.jp<br />
          指定受信設定の方法は各キャリアのサイトでご確認ください。</span>
        </td>
      </tr>
      <tr>
        <th>②メールに記載のURLをクリック</th>
        <td>
          メールに記載のURLをクリックします。<br />
          <span class="print_caption">※もし画面のレイアウトが崩れている場合は、お手数ですが、ヘルプページの事前準備のご案内「１．通信機器等のご案内」に記載のナラティブブックの推奨ブラウザでURLを開いてください。</span>
        </td>
      </tr>
      <tr>
        <th>③本人確認用コードの入力</th>
        <td>
          本人確認用コードの入力を求められますので、この紙に記載の本人確認用コードを入力してください。<br />
          <span class="print_caption">※本人確認用コードの入力は３回までです。もし３回間違えてしまった場合は、{{ organizationName }}の管理者に職員参加案内メールの再送信をお願いしてください。</span>
        </td>
      </tr>
      <tr>
        <th>④利用者IDの入力</th>
        <td>
          既に利用者IDをお持ちの場合は、ご自身の利用者IDを入力すると、その利用者IDに{{ organizationName }}の職員情報が追加されます。<br />
          <span class="print_caption">まだ利用者IDをお持ちでない場合は、「利用者IDをお持ちでない方」を選択して、利用者IDを取得します。</span>
        </td>
      </tr>
      <tr>
        <th>⑤職員登録完了</th>
        <td>
          職員登録が完了しました。<br />
          <span class="print_caption">デスクトップにナラティブブックへのログイン画面のショートカットを作成すると次回からのログインが簡単です。（ヘルプページの事前準備のご案内「2．ナラティブブックショートカット作成」をご覧ください）</span>
        </td>
      </tr>
    </table>
    <input type="button" class="btn btn btn-success" value="印刷する" onclick="window.print();" />
  </div>
</template>

<script>
export default {
  name: 'HorusPrintMemberInvitationView',

  data () {
    return {
      organiationName: '',
      targetName: '',
      targetMail: '',
      shortToken: ''
    }
  },

  watch: {
    '$route': { handler: 'initDatas', immediate: true }
  },

  methods: {
    initDatas () {
      this.organizationName = this.$route.query.organization_name
      this.targetName = this.$route.query.target_name
      this.targetMail = this.$route.query.target_mail
      this.shortToken = this.$route.query.short_token
    }
  }
}
require('@/assets/css/printInvitation.scss')
</script>
