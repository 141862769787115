<template>
  <div class="horus-reporter-organization-member-in-manage-list labeled_content_block">
    <div class="labeled_content_single_block">
      <label>氏名(NBID)</label>
      <div class="content">
        {{ member.author.name }}({{ member.nbid }})
        <span class="own-label" v-if="ownInformation">あなた自身</span>
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>管理権限</label>
      <div class="content">
        <span @click="toggleManager">
          <horus-custom-check-box
            :type="'small'"
            :active="member.manager"
            :enabled="changeAvailable"
            />
          <span class="checkbox-label">施設管理者</span>
        </span>
      </div>
    </div>
    <div class="small-title">表示用設定<img src="/static/images/pencil_icon.png" @click="editMember" /></div>
    <div class="display-block">
      <div class="labeled_content_single_block">
        <label>表示用氏名</label>
        <div class="content">
          <span class="disable-content" v-if="!member.member_name">未設定</span>
          <span>{{ member.member_name }}</span>
        </div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用メール</label>
        <div class="content">
          <span class="disable-content" v-if="!member.member_mail">未設定</span>
          <span>{{ member.member_mail }}</span>
        </div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用電話番号</label>
        <div class="content"><span class="disable-content" v-if="!member.member_tel">未設定</span>
          <span>{{ member.member_tel }}</span>
        </div>
      </div>
    </div>
    <div class="controll-for-member"><button class="remove-member-btn" :class="{disable: !changeAvailable}" @click="removeMember">職員停止</button></div>
  </div><!-- organization member in manage list -->
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusReporterOrganizationMemberInManageList',

  components: {
    HorusCustomCheckBox
  },

  props: {
    member: Object,
    reporterOrganization: Object,
    signinReporter: Object
  },

  computed: {
    ownInformation () {
      if (this.member.author_id === this.signinReporter.author_id) {
        return true
      } else {
        return false
      }
    },

    changeAvailable () {
      if (this.ownInformation || this.reporterOrganization.applyingForRevoke) {
        return false
      } else {
        return true
      }
    }
  },

  methods: {
    toggleManager () {
      if (!this.ownInformation) {
        this.$emit('toggleManagerEvent', this.member)
      }
    },

    editMember () {
      this.$emit('editMemberEvent', this.member)
    },

    removeMember () {
      if (!this.ownInformation) {
        this.$emit('removeMemberEvent', this.member)
      }
    }
  }

}
require('@/assets/css/labeledContent.scss')
</script>

<style lang="scss" scoped>
.horus-reporter-organization-member-in-manage-list {
  .checkbox-label {
    margin-right: 20px;
  }
  .own-label {
    margin-left: 10px;
    padding: 1px 5px;
    border: 1px solid #c43d53;
    color: #c43d53;
    font-size: 12px;
  }
  .controll-for-member {
    width: 100%;
    padding: 0 10px;
    text-align: right;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      &.disable {
        border-color: #aaa;
        background-color: #aaa;
      }
    }
  }
}
</style>
