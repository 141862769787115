<template>
  <div class="horus-system-third-party-linkage-detail-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>第三者連携サービス詳細</h1>
      </div>
      <div class="modal-body">
        <div class="form-list-line" v-if="linkage">
          <div class="category-title">サービス情報</div>
          <div class="form-line status">
            <label class="linkage-label">状態</label>
            {{ status }}
          </div>
          <div class="form-line name">
            <label class="linkage-label">名称</label>
            {{ linkage.name }}
          </div>
          <div class="form-line description">
            <label class="linkage-label">詳細</label>
            <span v-html="brTextHtml(linkage.description)"></span>
          </div>
          <div class="form-line fdqn">
            <label class="linkage-label">FDQN</label>
            {{ linkage.fdqn }}
          </div>
          <div class="form-line callback_url">
            <label class="linkage-label">コールバックアドレス</label>
            {{ linkage.callback_url }}
          </div>
          <div class="form-line code">
            <label class="linkage-label">第三者サービスコード</label>
            {{ linkage.code }}
          </div>
          <div class="form-line token">
            <label class="linkage-label">第三者サービス連携トークン</label>
            {{ linkage.token }}
          </div>
          <div class="category-title">サービス提供者情報</div>
          <div class="form-line provider_name">
            <label class="linkage-label">提供者名</label>
            {{ linkage.provider_name }}
          </div>
          <div class="form-line provider_zip_code">
            <label class="linkage-label">郵便番号</label>
            {{ linkage.provider_zip_code }}
          </div>
          <div class="form-line provider_address">
            <label class="linkage-label">住所</label>
            <span v-html="brTextHtml(linkage.provider_address)"></span>
          </div>
          <div class="form-line provider_mail">
            <label class="linkage-label">メールアドレス</label>
            {{ linkage.provider_mail }}
          </div>
          <div class="form-line provider_tel">
            <label class="linkage-label">電話番号</label>
            {{ linkage.provider_tel }}
          </div>
          <div class="form-line provider_url">
            <label class="linkage-label">ホームページ</label>
            {{ linkage.provider_url }}
          </div>
          <div class="form-line provider_contact">
            <label class="linkage-label">担当者名</label>
            {{ linkage.provider_contact }}
          </div>
          <div class="form-line created_at"><label class="linkage-label">作成日</label>{{ createdAtStr }}</div>
          <div class="form-line updated_at"><label class="linkage-label">更新日</label>{{ updatedAtStr }}</div>
          <div class="controll-column" v-if="!rejectEditing">
            <button class="close-btn" @click="closeModal">閉じる</button>
            <button class="edit-btn" v-if="linkage.admitted && !linkage.deleted" @click="editLinkage">編集</button>
            <button class="delete-btn" v-if="linkage.admitted && !linkage.deleted" @click="deleteLinkage">削除</button>
            <button class="admit-btn" v-if="!linkage.admitted && !linkage.deleted" @click="admitLinkage">承認</button>
            <button class="reject-btn" v-if="!linkage.admitted && !linkage.deleted" @click="rejectLinkage">拒否</button>
            <button class="delete-btn" v-if="!linkage.admitted && linkage.deleted" @click="destroyLinkage">物理削除</button>
          </div>
          <div class="reject-edit-column" v-if="rejectEditing">
            <div class="reject-message-label">拒否理由</div>
            <textarea class="reject-message-area" v-model="rejectMessage"></textarea>
            <div class="reject-controll">
              <button class="close-btn" @click="cancelReject">閉じる</button>
              <button class="reject-btn" @click="executeReject">拒否</button>
            </div>
          </div>
        </div>
      </div>
    </horus-modal-frame>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemThirdPartyLinkageDetailModal',

  components: {
    HorusModalFrame,
    HorusConfirmDialog
  },

  props: {
    linkage: Object,
    showModal: Boolean
  },

  data () {
    return {
      rejectEditing: false,
      rejectMessage: ''
    }
  },

  computed: {
    status () {
      if (this.linkage.deleted) {
        if (this.linkage.admitted) {
          return '削除済み'
        } else {
          return '拒否済'
        }
      } else {
        if (this.linkage.admitted) {
          return '承認済み'
        } else {
          return '承認待ち'
        }
      }
    },

    createdAtStr () {
      if (this.linkage.created_at) {
        return moment(this.linkage.created_at).format('YYYY/MM/DD HH:mm:SS')
      }
      return ''
    },

    updatedAtStr () {
      if (this.linkage.updated_at) {
        return moment(this.linkage.updated_at).format('YYYY/MM/DD HH:mm:SS')
      }
      return ''
    }
  },

  mounted () {
    this.rejectEditing = false
    this.rejectMessage = ''
  },

  methods: {
    brTextHtml (text) {
      if (text) {
        return text.replace(/\n/gi, '<br />')
      }
      return ''
    },

    closeModal () {
      this.$emit('closeEvent')
    },

    editLinkage () {
      this.$emit('editEvent', this.linkage)
    },

    deleteLinkage () {
      this.$emit('deleteEvent', this.linkage)
    },

    admitLinkage () {
      this.$emit('admitEvent', this.linkage)
    },

    rejectLinkage () {
      this.rejectEditing = true
      this.rejectMessage = ''
      // this.$emit('rejectEvent', this.linkage)
    },

    cancelReject () {
      this.rejectEditing = false
    },

    executeReject () {
      if (this.rejectMessage.length <= 0) {
        this.showNotifyDialog('拒否理由の入力がありません', '第三者連携サービスの登録拒否には、拒否理由を明記してください。\n拒否理由は、結果通知メールに記載されます。')
      } else {
        this.$emit('rejectEvent', this.linkage, this.rejectMessage)
      }
    },

    destroyLinkage () {
      this.$emit('destroyEvent', this.linkage)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-third-party-linkage-detail-modal {
  .modal-body {
    padding: 0 20px 10px 20px;
  }
  .form-list-line {
    .category-title {
      margin-top: 20px;
      font-weight: bold;
    }
    .form-line {
      display: flex;
      margin: 5px 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
      .linkage-label {
        width: 200px;
        padding: 0 10px;
        text-align: right;
        font-weight: bold;
      }
    }
  }
  .controll-column {
    margin: 10px;
    text-align: center;
    button {
      margin: 0 10px;
      padding: 3px 10px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      &.close-btn {
        color: #666;
        background-color: #fff;
      }
      &.edit-btn {
        border-color: #f08300;
        background-color: #f08300;
      }
      &.delete-btn {
        border-color: #c43d53;
        background-color: #c43d53;
      }
      &.admit-btn {
        border-color: #5cb85b;
        background-color: #5cb85b;
      }
      &.reject-btn {
        border-color: #c43d53;
        background-color: #c43d53;
      }
    }
  }
  .reject-edit-column {
    margin: 10px;
    .reject-message-label {
      margin: 5px 0;
      font-weight: bold;
    }
    .reject-message-area {
      width: 100%;
      resize: none;
    }
    .reject-controll {
      margin: 5px;
      text-align: center;
      button {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        &.close-btn {
          color: #666;
          background-color: #fff;
        }
        &.reject-btn {
          border-color: #c43d53;
          background-color: #c43d53;
        }
      }
    }
  }
}
</style>
