<template>
  <div class="horus-create-organization-area-block-column">
    以下の監理団体の監理下で施設が登録されます。
    <div class="area-block-list">
      <div class="list-column">
        <div class="list-line">
          <div class="name"><span>団体名(カナ):</span>{{ areaBlock.name }}({{ areaBlock.name_kana }})</div>
          <div class="short-name"><span>略名(カナ):</span>{{ areaBlock.short_name }}({{ areaBlock.short_name_kana }})</div>
        </div>
        <div class="list-line">
          <div class="zip-code">{{ areaBlock.zip_code }}</div>
          <div class="address">{{ areaBlock.address }}</div>
          <div class="tel">tel:{{ areaBlock.tel }}</div>
          <div class="mail">mail: {{ areaBlock.mail }}</div>
        </div>
      </div>
    </div><!-- organization-list -->
  </div>
</template>

<script>
export default {
  name: 'HorusCreateOrganizationAreaBlockColumn',

  props: {
    areaBlock: Object
  }
}
</script>

<style lang="scss" scoped>
.horus-create-organization-area-block-column {
  .area-block-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            width: auto;
            font-weight: bold;
          }
          &.short-name {
            // width: 50%;
            font-weight: bold;
          }
          &.zip-code {
            width: auto;
          }
          &.address {
            width: auto;
          }
          &.tel {
            width: auto;
          }
          &.mail {
            width: auto;
          }
        }
      }
    }
  }
}
</style>
