<template>
  <div class="horus-manage-organization-frame">
    <div class="manage-header">
      <button type="button" class="basic-btn" :class="{disable: currentDisplay === 'basic'}" @click="jumpTo('/manage_organization_basic')">基本</button>
      <button type="button" class="member-btn" :class="{disable: currentDisplay === 'member'}" @click="jumpTo('/manage_organization_member')" v-if="signinMember.member_manager">施設</button>
      <button type="button" class="relation-btn" :class="{disable: currentDisplay === 'relation'}" @click="jumpTo('/manage_organization_relation')" v-if="signinMember.relation_manager">かかりつけ</button>
      <button type="button" class="deputize-btn" :class="{disable: currentDisplay === 'deputize'}" @click="jumpTo('/manage_organization_deputize')" v-if="signinMember.deputize_manager && organization && organization.deputizable">設定代行</button>
    </div>
    <div class="manage-body">
      <div class="manage-contents">
        <slot></slot>
      </div>
      <horus-footer/>
    </div>
  </div>
</template>

<script>
import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusManageOrganizationFrame',

  components: {
    HorusFooter
  },

  props: {
    currentDisplay: String,
    signinMember: Object,
    organization: Object
  },

  methods: {
    jumpTo (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-manage-organization-frame {
  height: calc(100vh - 50px);
  .manage-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    height: 30px;
    background-color: #ddd;
    border-bottom: 1px solid #aaa;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    button {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      &.disable {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .manage-body {
    height: calc(100% - 41px);
    overflow: scroll;
    .manage-contents {
      min-height: calc(100% - 132px);
      padding: 10px 50px;
      @include mediaQuery('phone') {
        padding: 10px;
      }
    }
  }
}
</style>
