import { Model } from '@vuex-orm/core'

const EXIST_SELECTION = ['記録なし', 'あり', 'なし']
const NORMAL_SELECTION = ['記録なし', '異常', '正常']
const PROBREM_SELECTION = ['記録なし', '問題あり', '問題なし']

export default class MedicalExamination extends Model {
  static entity = 'medicalExaminations'

  static fields () {
    return {
      id: this.attr(null),
      record_id: this.attr(null),
      bloodPressureMax: this.number(-1),
      bloodPressureMin: this.number(-1),
      bodyTemperature: this.number(-1),
      spO2: this.number(-1),
      pulseRate: this.number(-1),
      irregularPulse: this.number(-1),
      anemiaState: this.number(-1),
      jaundiceState: this.number(-1),
      oralState: this.number(-1),
      respiratoryNoize: this.number(-1),
      respiratoryRateState: this.number(-1),
      hartSound: this.number(-1),
      abdominalPalpation: this.number(-1),
      abdominalAusculation: this.number(-1),
      edema: this.number(-1),
      bedsore: this.number(-1),
      eatingState: this.number(-1),
      takeMedicineState: this.number(-1),
      urinationState: this.number(-1),
      facesState: this.number(-1)
    }
  }

  get bloodPressureJp () {
    if (this.bloodPressureMax >= 0 && this.bloodPressureMin >= 0) {
      return this.bloodPressureMax + '／' + this.bloodPressureMin + 'mmHg'
    }
    return ''
  }

  get bodyTemperatureJp () {
    if (this.bodyTemperature >= 0) {
      return this.bodyTemperature + '℃'
    }
    return ''
  }

  get spO2Jp () {
    if (this.spO2 >= 0) {
      return this.spO2 + '％'
    }
    return ''
  }

  get pulseRateJp () {
    if (this.pulseRate >= 0) {
      return this.pulseRate + '回／分'
    }
    return ''
  }

  get irregularPulseJp () {
    if (this.irregularPulse >= 0) {
      return EXIST_SELECTION[this.irregularPulse]
    }
    return ''
  }

  get anemiaStateJp () {
    if (this.anemiaState >= 0) {
      return EXIST_SELECTION[this.anemiaState]
    }
    return ''
  }

  get jaundiceStateJp () {
    if (this.jaundiceState >= 0) {
      return EXIST_SELECTION[this.jaundiceState]
    }
    return ''
  }

  get oralStateJp () {
    if (this.oralState >= 0) {
      return NORMAL_SELECTION[this.oralState]
    }
    return ''
  }

  get respiratoryNoizeJp () {
    if (this.respiratoryNoize >= 0) {
      return NORMAL_SELECTION[this.respiratoryNoize]
    }
    return ''
  }

  get respiratoryRateStateJp () {
    if (this.respiratoryRateState >= 0) {
      return NORMAL_SELECTION[this.respiratoryRateState]
    }
    return ''
  }

  get hartSoundJp () {
    if (this.hartSound >= 0) {
      return NORMAL_SELECTION[this.hartSound]
    }
    return ''
  }

  get abdominalPalpationJp () {
    if (this.abdominalPalpation >= 0) {
      return NORMAL_SELECTION[this.abdominalPalpation]
    }
    return ''
  }

  get abdominalAusculationJp () {
    if (this.abdominalAusculation >= 0) {
      return NORMAL_SELECTION[this.abdominalAusculation]
    }
    return ''
  }

  get edemaJp () {
    if (this.edema >= 0) {
      return EXIST_SELECTION[this.edema]
    }
    return ''
  }

  get bedsoreJp () {
    if (this.bedsore >= 0) {
      return EXIST_SELECTION[this.bedsore]
    }
    return ''
  }

  get eatingStateJp () {
    if (this.eatingState >= 0) {
      return PROBREM_SELECTION[this.eatingState]
    }
    return ''
  }

  get takeMedicineStateJp () {
    if (this.takeMedicineState >= 0) {
      return PROBREM_SELECTION[this.takeMedicineState]
    }
    return ''
  }

  get urinationStateJp () {
    if (this.urinationState >= 0) {
      return PROBREM_SELECTION[this.urinationState]
    }
    return ''
  }

  get facesStateJp () {
    if (this.facesState >= 0) {
      return PROBREM_SELECTION[this.facesState]
    }
    return ''
  }

  get summaryText () {
    var medExes = []
    if (this.bloodPressureMax >= 0 && this.bloodPressureMin >= 0) {
      medExes.push('血圧:' + this.bloodPressureMax + '／' + this.bloodPressureMin + 'mmHg')
    }
    if (this.bodyTemperature >= 0) {
      medExes.push('体温:' + this.bodyTemperature + '℃')
    }
    if (this.spO2 >= 0) {
      medExes.push('酸素飽和度:' + this.spO2 + '％')
    }
    if (this.pulseRate >= 0) {
      medExes.push('心拍数:' + this.pulseRate + '回／分')
    }
    if (this.irregularPulse >= 0) {
      medExes.push('不整脈:' + EXIST_SELECTION[this.irregularPulse])
    }
    if (this.anemiaState >= 0) {
      medExes.push('貧血:' + EXIST_SELECTION[this.anemiaState])
    }
    if (this.jaundiceState >= 0) {
      medExes.push('黄疸:' + EXIST_SELECTION[this.jaundiceState])
    }
    if (this.oralState >= 0) {
      medExes.push('口腔状況:' + NORMAL_SELECTION[this.oralState])
    }
    if (this.respiratoryNoize >= 0) {
      medExes.push('呼吸雑音:' + NORMAL_SELECTION[this.respiratoryNoize])
    }
    if (this.respiratoryRateState >= 0) {
      medExes.push('呼吸数:' + NORMAL_SELECTION[this.respiratoryRateState])
    }
    if (this.hartSound >= 0) {
      medExes.push('心音:' + NORMAL_SELECTION[this.hartSound])
    }
    if (this.abdominalPalpation >= 0) {
      medExes.push('腹部触診:' + NORMAL_SELECTION[this.abdominalPalpation])
    }
    if (this.abdominalAusculation >= 0) {
      medExes.push('腹部聴診:' + NORMAL_SELECTION[this.abdominalAusculation])
    }
    if (this.edema >= 0) {
      medExes.push('浮腫:' + EXIST_SELECTION[this.edema])
    }
    if (this.bedsore >= 0) {
      medExes.push('褥瘡:' + EXIST_SELECTION[this.bedsore])
    }
    if (this.eatingState >= 0) {
      medExes.push('食事状況:' + PROBREM_SELECTION[this.eatingState])
    }
    if (this.takeMedicineState >= 0) {
      medExes.push('服薬状況:' + PROBREM_SELECTION[this.takeMedicineState])
    }
    if (this.urinationState >= 0) {
      medExes.push('排尿状況:' + PROBREM_SELECTION[this.urinationState])
    }
    if (this.facesState >= 0) {
      medExes.push('排便状況:' + PROBREM_SELECTION[this.facesState])
    }
    return medExes.join(', ')
  }
}
