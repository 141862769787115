<template>
  <div class="horus-user-task-detail-modal" v-if="userTask">
    <horus-modal-frame :showModalFlag="showModal">
      <div class="modal-header">
        <h1 class="modal-title">
          <div class="target-date" :class="{past: targetAtPast}">{{ targetDateStr }}</div>
          <div class="target-info" v-if="taskTimelineAuthor">
            <img class="task-timeline-portrate" :src="taskTimelineAuthor.portrateSrc"/>
            <span class="timeline-owner-name">{{ taskTimelineAuthor.name }}の予定</span>
          </div>
          <div class="target-info" v-if="taskOrganization">
            <img src="/static/images/org_member.png"/>
            <span class="timeline-owner-name">{{ taskOrganization.shortName }}の予定</span>
          </div>
          <div class="video-meeting-notify" v-if="meetingExist">
            <img src="/static/images/video_meeting_s_green_icon.png" />
            ビデオ通話予定
            <span class="join-meeting" @click="startVideoMeeting">ビデオ通話を開始</span>
          </div>
        </h1>
        <div class="task-controll">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="task-content">
          <div class="text-content" v-if="userTask.textContent && !linkageMeetingExist" v-html="textContentHtml"></div>
          <div class="meeting-content" v-if="linkageMeetingExist">
            <span v-html="splittedMeetingTextContents.before"></span>
            <div class="meeting-url" @click="startVideoMeeting">{{ userTask.linkURL }}</div>
            <span v-html="splittedMeetingTextContents.after"></span>
          </div>
          <div class="attach-content">
            <horus-inline-photo
              v-for="taskPhoto in userTask.userTaskPhotos"
              :key="'taskPhotoThumb' + taskPhoto.id"
              :photoObject="taskPhoto"
              :sizeType="'small'"
              :photoMaxSize="70"
              v-on:timeliePhotoClickEvent="showPhoto(taskPhoto)" />
            <horus-inline-file
              v-for="taskFile in userTask.userTaskFiles"
              :key="'taskFileIcon' + taskFile.id"
              :fileObject="taskFile"
              :sizeType="'small'"
              />
          </div>
        </div>
        <div class="additional-content">
          <div class="hash-tags" v-if="userTask.hashTags.length > 0">
            <div class="user-task-hashtag"  v-for="hashTag in userTask.hashTags" :key="'taskTag' + hashTag.id">
              <horus-hash-tag-display :hashTag="hashTag" :sizeType="'small'"/>
            </div>
          </div>
          <div class="notify-targets" v-if="userTask.notifyTargets.length > 0">
            <span class="notify-target-title">予定の共有者: </span>
            <div class="notify-author"
              v-for="author in userTask.notifyTargets"
              :key="'notifyTarget' + author.id">
              <img :src="author.portrateSrc" class="portrate" />
              <div class="author-name">{{ author.name }}</div>
            </div>
          </div>
        </div>
        <div class="task-footer">
          <span class="footer-label">作成:</span>
          <img :src="userTask.author.portrateSrc" />
          <span class="author-name">{{ userTask.author.name }}</span>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

import * as TASK_MEETING_TYPE from '@/assets/constants/taskMeetingType.js'

export default {
  name: 'HorusUserTaskDetailModal',

  components: {
    HorusModalFrame,
    HorusInlinePhoto,
    HorusInlineFile,
    HorusHashTagDisplay
  },

  props: {
    showModal: Boolean,
    userTask: Object
  },

  data () {
    return {}
  },

  computed: {
    targetDateStr () {
      if (this.userTask) {
        return moment(this.userTask.targetAt).format('MM月DD日 HH:mm')
      }
      return ''
    },

    targetAtPast () {
      if (this.userTask && this.userTask.targetAt < new Date()) {
        return true
      } else {
        return false
      }
    },

    meetingExist () {
      if (this.userTask.meetingType > 0) {
        return true
      }
      return false
    },

    linkageMeetingExist () {
      if (this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_ZOOM || this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_GOOGLE_MEET || this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_MS_TEAMS) {
        return true
      }
      return false
    },

    textContentHtml () {
      if (this.userTask && this.userTask.textContent) {
        var contentText = this.userTask.textContent.replace(TASK_MEETING_TYPE.MEETING_LINK_REPLACE_TEXT, this.userTask.linkURL)
        return contentText.replace(/\n/gi, '<br />')
      }
      return ''
    },

    splittedMeetingTextContents () {
      if (this.userTask && this.userTask.textContent) {
        var contentTexts = this.userTask.textContent.split(TASK_MEETING_TYPE.MEETING_LINK_REPLACE_TEXT)
        var before = contentTexts[0].replace(/\n/gi, '<br />')
        var after = ''
        if (contentTexts[1]) {
          after = contentTexts[1].replace(/\n/gi, '<br />')
        }
        return { before: before, after: after }
      }
      return ''
    },

    taskTimelineAuthor () {
      if (this.userTask) {
        return this.userTask.timelineAuthor
      }
      return null
    },

    taskOrganization () {
      if (this.userTask) {
        return this.userTask.organization
      }
      return null
    },

    taskEditable () {
      if ((this.signinUser && this.userTask.author_id === this.signinUser.author_id) || (this.signinMember && this.userTask.author_id === this.signinMember.author_id)) {
        return true
      }
      return false
    }
  },

  methods: {
    closeModal () {
      this.$emit('closeModalEvent')
    },

    startVideoMeeting () {
      if (this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_EMBEDED_ZOOM) {
        this.$emit('startVideoMeetingEvent', this.userTask)
      } else if (this.linkageMeetingExist) {
        // fire event for app.
        if (this.$native.status) {
          this.$native.event('openURLByBrowser', { url: this.userTask.linkURL, name: this.userTask.title })
        } else {
          window.open(this.userTask.linkURL)
        }
      }
    },

    showPhoto (taskPhoto) {
      this.$emit('showTaskPhotoEvent', this.userTask, this.userTask.userTaskPhotos.indexOf(taskPhoto))
    }

  }
}
</script>

<style lang="scss" scoped>
.horus-user-task-detail-modal {
  z-index: 20000;
  .modal-header {
    display: flex;
    h1.modal-title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: calc(100% - 30px);
      font-size: 1.1em;
      .target-date {
        margin-right: 10px;
      }
      .video-meeting-notify {
        margin-left: 10px;
        display: flex;
        align-items: center;
        font-size: 1em;
        font-weight: normal;
        img {
          margin: 0;
          margin-right: 5px;
          width: 30px;
          height: 30px;
        }
        .join-meeting {
          cursor: pointer;
          margin-left: 5px;
          padding: 3px;
          font-size: 0.9em;
          border: 1px solid #b94047;
          background-color: #b94047;
          color: #fff;
          font-weight: bold;
        }
      }
    }
    .task-controll {
      cursor: pointer;
      width: 30px;
    }
  }
  .modal-body {
    padding: 10px;
    .task-content {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #aaa;
      .text-content {
        margin: 5px 0;
        font-size: 16px;
      }
      .meeting-content {
        margin: 5px 0;
        font-size: 16px;
        .meeting-url {
          cursor: pointer;
          text-decoration: underline;
          color: #428bca;
        }
      }
      .attach-content {
        margin-bottom: 5px;
        align-items: flex-end;
        flex-wrap: wrap;
        display: flex;
      }
    }
    .additional-content {
      .hash-tags {
        display: flex;
        margin-bottom: 5px;
      }
      .notify-targets {
        .notify-author {
          display: inline-block;
          margin-right: 5px;
          font-size: 0.8em;
          cursor: pointer;
          .portrate {
            display: inline-block;
            margin-right: -3px;
            width: 12px;
            height: 12px;
          }
          .author-name {
            display: inline-block;
          }
          .close-icon {
            display: inline-block;
            margin-left: -3px;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .task-footer {
      padding-top: 5px;
      border-top: 1px solid #aaa;
      .footer-label {
        font-size: 0.9em;
      }
      img {
        display: inline-block;
        width: 12px;
        height: 12px;
      }
      .author-name {
        font-size: 0.9em;
      }
    }
  }
}
</style>
