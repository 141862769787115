export const TASK_MEETING_NONE = 0
export const TASK_MEETING_ZOOM = 10
export const TASK_MEETING_GOOGLE_MEET = 11
export const TASK_MEETING_MS_TEAMS = 12

export const TASK_MEETING_EMBEDED_ZOOM = 100

export const ZOOM_LINK_REGEXP = /^(https:\/\/(?:[a-zA-Z0-9]+\.|)zoom\.us\/[a-zA-Z]+\/[0-9]{9,12}(?:\?pwd=[a-zA-Z0-9._-]+|))$/gm
export const G_MEET_LINK_REGEXP = /(https:\/\/meet\.google\.com\/[a-zA-Z-]+)$/gm
export const MS_TEAMS_LINK_REGEXP = /^(https:\/\/teams\.live\.com\/meet\/[0-9]+)$/gm

export const MEETING_LINK_REPLACE_TEXT = '[*****MEETING_URL*****]'
