<template>
  <div class="horus-manage-reporter-organization-member">
    <horus-manage-reporter-organization-frame
      :currentDisplay="'member'"
      :signinReporter="signinReporter"
      :reporterOrganization="reporterOrganization"
      >
      <h1 class="centering-title">施設設定</h1>
      <div class="member-block red_titled_column">
        <div class="revoke-notify" v-if="reporterOrganization && reporterOrganization.applyingForRevoke">
          本施設は、現在退会申請中です。<br/>
          退会申請中は施設管理者の権限変更、新規職員の追加、案内の再送、申込みの承諾は行なえません。
        </div>
        <h4>施設に登録している職員</h4>
        <div class="member-list-column" v-if="!memberNowLoading">
          <div class="block-title" @click="memberListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: memberListOpen}" />
            職員リスト<span class="caption">職員:{{ members.length }}名</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="member-list" v-show="memberListOpen" v-if="members.length > 0">
              <div class="notify-comment">
                以下の権限を付与する方には<img src="/static/images/check_on_icon_s.png" />をつけてください
                <ul>
                  <li>施設管理：職員の登録や権限の付与など施設の管理をする方</li>
                </ul>
              </div>
              <horus-reporter-organization-member-in-manage-list
                v-for="member in members"
                :key="'orgMemberInList' + member.id"
                :member="member"
                :reporterOrganization="reporterOrganization"
                :signinReporter="signinReporter"
                v-on:toggleManagerEvent="toggleManager"
                v-on:editMemberEvent="editMember"
                v-on:removeMemberEvent="removeMember"
                />
            </div>
          </transition>
        </div><!-- member list column end -->
        <div class="now-loading" v-if="memberNowLoading">読み込み中...</div>
      </div><!-- member block end -->
      <div class="sent-invitation-block red_titled_column">
        <h4>案内状の送信</h4>
        <div class="subtitle" v-if="reporterOrganization && !reporterOrganization.applyingForRevoke">職員として登録するための案内を送る</div>
        <div class="block-title" v-if="reporterOrganization && !reporterOrganization.applyingForRevoke">
          <button type="button" class="create-invitation-btn" @click="createNewInvitation">案内状の作成</button>
        </div>
        <div class="sent-invitation-list-column" v-if="!sentListNowLoading">
          <div class="block-title" @click="sentInvitationListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
            案内状の送信リスト<span class="caption">案内状の数:{{ sentInvitations.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-invitation-list" v-show="sentListOpen" v-if="sentInvitations.length > 0">
              <horus-sent-reporter-member-invitation
                v-for="sentInvitation in sentInvitations"
                :key="'sentRelationReq' + sentInvitation.id"
                :sentInvitation="sentInvitation"
                :reporterOrganization="reporterOrganization"
                v-on:sendMailEvent="sendMail"
                v-on:showPrintPageEvent="showPrintPage"
                v-on:removeInvitationEvent="removeInvitation"
                />
            </div><!-- sent-invitation-list end -->
          </transition>
          <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
        </div><!-- sent-invitation-list-column end -->
      </div><!-- sent-invitation-block -->
    </horus-manage-reporter-organization-frame>
    <!-- invitation create modal -->
    <horus-create-reporter-member-invitation-modal
      :reporterOrganization="reporterOrganization"
      :showCreateMemberInvitationModal="showCreateMemberInvitationModal"
      v-on:cancelSentInvitationEvent="cencelCreateInvitation"
      v-on:submitSentInvitationEvent="submitCreateInvitation"
      />
    <horus-reporter-member-profile-edit-modal
      :member="targetMember"
      :showMemberProfileEditModal="showMemberProfileEditModal"
      v-on:cancelSubmitEvent="cancelMemberProfileSubmit"
      v-on:executeSubmitEvent="executeMemberProfileSubmit"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author
} from '@/models'
import {
  ReporterOrganizationMember,
  ReporterOrganizationMemberInvitation,
  ReporterOrganizationManager
} from '@/api'

import HorusManageReporterOrganizationFrame from '@/components/reporterOrganization/HorusManageReporterOrganizationFrame'
import HorusReporterOrganizationMemberInManageList from '@/components/reporterOrganization/HorusReporterOrganizationMemberInManageList.vue'
import HorusSentReporterMemberInvitation from '@/components/reporterOrganization/HorusSentReporterMemberInvitation.vue'
import HorusCreateReporterMemberInvitationModal from '@/components/reporterOrganization/HorusCreateReporterMemberInvitationModal.vue'
import HorusReporterMemberProfileEditModal from '@/components/reporterOrganization/HorusReporterMemberProfileEditModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition],

  name: 'HorusManageReporterOrganizationMember',

  components: {
    HorusManageReporterOrganizationFrame,
    HorusReporterOrganizationMemberInManageList,
    HorusSentReporterMemberInvitation,
    HorusCreateReporterMemberInvitationModal,
    HorusReporterMemberProfileEditModal,
    HorusConfirmDialog
  },

  props: {
    signinReporter: Object,
    reporterOrganization: Object
  },

  data () {
    return {
      memberNowLoading: true,
      memberListOpen: false,
      members: [],
      sentListNowLoading: true,
      sentListOpen: false,
      sentInvitations: [],
      showCreateMemberInvitationModal: false,
      showMemberProfileEditModal: false,
      targetMember: {
        id: null,
        name: '',
        mail: '',
        tel: '',
        reporter_organization_id: null,
        author_id: null,
        manager: false,
        reporter_organization: null,
        author: null
      }
    }
  },

  watch: {
    reporterOrganization: { handler: 'initObjects', immediate: true }
  },

  methods: {
    initObjects () {
      this.loadMembers()
      this.loadSentInvitations()
    },

    async loadMembers () {
      if (this.reporterOrganization) {
        this.memberNowLoading = true
        let res = await ReporterOrganizationMember.fetch({ reporterOrganizationID: this.reporterOrganization.id })
        this.members = res.reporter_organization_members
        var unloadAuthors = []
        for(var mem of this.members) {
          if (!Author.find(mem.author_id)) {
            if (unloadAuthors.indexOf(mem.author_id) < 0) unloadAuthors.push(mem.author_id)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ timelineOwnerID: this.signinReporter.user_id, idList: unloadAuthors })
        }
        for(var member of this.members) {
          member.author = await Author.find(member.author_id)
        }
        this.memberNowLoading = false
      }
    },

    loadSentInvitations () {
      if (this.reporterOrganization) {
        this.sentListNowLoading = true
        ReporterOrganizationMemberInvitation.fetch({ reporterOrganizationID: this.reporterOrganization.id }).then(res => {
          this.sentInvitations = res.reporter_organization_member_invitations
          this.sentListNowLoading = false
        }).catch(error => {
          console.error(error.response)
        })
      }
    },

    memberListToggle () {
      this.memberListOpen = !this.memberListOpen
    },

    sentInvitationListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    toggleManager (member) {
      if (member.author_id === this.signinReporter.author_id) {
        this.showNotifyDialog('職員管理設定の失敗', '自分自身の設定を変更できません。')
      } else {
        if (member.manager) {
          ReporterOrganizationManager.revokeManager({ reporterOrganizationID: this.reporterOrganization.id, userID: member.user_id })
          .catch(error => {
            console.error(error)
            this.showNotifyDialog('管理者設定の失敗', '管理者の設定変更に失敗しました。')
          })
          .finally(() => {
            this.loadMembers()
          })
        } else {
          ReporterOrganizationManager.toManager({ reporterOrganizationID: this.reporterOrganization.id, userID: member.user_id })
          .catch(error => {
            console.error(error)
            this.showNotifyDialog('管理者設定の失敗', '管理者の設定変更に失敗しました。')
          })
          .finally(() => {
            this.loadMembers()
          })
        }
      }
    },

    editMember (member) {
      // prepare attributes
      this.targetMember.id = member.id
      this.targetMember.name = member.member_name
      this.targetMember.mail = member.member_mail
      this.targetMember.tel = member.member_tel
      this.targetMember.reporter_organization_id = this.reporterOrganization.id
      this.targetMember.author_id = member.author_id
      this.targetMember.manager = member.manager
      this.targetMember.reporterOrganization = this.reporterOrganization
      this.targetMember.author = member.author
      // show modal
      this.showMemberProfileEditModal = true
    },

    resetTargetMember () {
      this.targetMember = {
        id: null,
        name: '',
        mail: '',
        tel: '',
        reporter_organization_id: null,
        author_id: null,
        manager: false,
        reporterOrganization: null,
        author: null
      }
    },

    cancelMemberProfileSubmit () {
      this.showConfirmDialog(
        '職員情報の編集キャンセル',
        '職員情報の編集をキャンセルします。\n編集した内容は全て破棄されます。よろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.resetTargetMember()
          this.showMemberProfileEditModal = false
        }
      )
    },

    executeMemberProfileSubmit (formData) {
      ReporterOrganizationMember.update({ formData: formData }).then(() => {
        this.initObjects()
        this.showMemberProfileEditModal = false
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '職員情報の更新失敗',
          '職情報の更新に失敗しました。',
          () => { this.showMemberProfileEditModal = false }
        )
      })
    },

    removeMember (member) {
      if (member.author_id === this.signinReporter.author_id) {
        this.showNotifyDialog('職員管理設定の失敗', '自分自身の設定を変更できません。')
      } else {
        this.showConfirmDialog(
          '職員の削除',
          '職員[' + member.author.name + ']を削除しようとしています。\n一度削除すると、再度案内を送信して職員になるまで元に戻せません。\n削除した職員の書き込みは残ります。\n職員を削除してよろしいですか?',
          DIALOG_TYPE.YES_NO,
          () => { this.executeRemoveMember(member) }
        )
      }
    },

    executeRemoveMember (member) {
      ReporterOrganizationMember.destroy({ reporterOrganizationID: this.reporterOrganizationID.id, memberID: member.id }).then(() => {
        this.loadMembers()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '職員の削除失敗',
          '職員の削除に失敗しました。'
        )
      })
    },

    createNewInvitation () {
      this.showCreateMemberInvitationModal = true
    },

    cencelCreateInvitation (callback) {
      this.showCreateMemberInvitationModal = false
      callback()
    },

    submitCreateInvitation (formData, callback) {
      ReporterOrganizationMemberInvitation.create({ formData: formData }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('案内の送信完了', '職員への参加案内を送信しました。\n本人確認用コードを伝えて、参加手続きを進めてください。')
        callback()
        this.sentListOpen = true
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('案内の送信失敗', '職員への参加案内の作成に失敗しました。')
      }).finally(() => {
        this.showCreateMemberInvitationModal = false
      })
    },

    sendMail (sentInvitation) {
      ReporterOrganizationMemberInvitation.sendMail({ reporterOrganizationID: this.reporterOrganization.id, targetID: sentInvitation.id }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('案内の送信完了', '職員への参加案内を再度送信しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('案内の送信失敗', '職員への参加案内の再送信に失敗しました。')
      })
    },

    showPrintPage (sentInvitation) {
      console.log(sentInvitation)
    },

    removeInvitation (sentInvitation) {
      ReporterOrganizationMemberInvitation.destroy({ reporterOrganizationID: this.reporterOrganization.id, targetID: sentInvitation.id }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('案内の削除完了', '職員への参加案内を削除しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('案内の削除失敗', '職員への参加案内の削除に失敗しました。')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/centeringTitle.scss';
@import '@/assets/css/redTitledColumn.scss';
@import '@/assets/css/listData.scss';

.horus-manage-reporter-organization-member {
  .notify-comment {
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fbfaf5;
    img {
      margin: 0 3px;
      height: 14px;
    }
    ul {
      margin: 0;
      margin-left: 1em;
      padding-left: 10px;
    }
  }
  .block-title {
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
    img {
      width: 13px;
      transition: transform 600ms ease;
      &.open {
        transform: rotate(90deg);
      }
    }
    .caption {
      font-size: 12px;
      font-weight: normal;
      &.notice {
        color: #c43d53;
      }
    }
    .create-invitation-btn {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .member-list {
    overflow: hidden;
  }
  .sent-invitation-list {
    overflow: hidden;
  }
  .revoke-notify {
    margin: 0;
    padding: 10px;
    border: 1px solid #c43d53;
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 600ms ease;
}
</style>
