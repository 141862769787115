<template>
  <div class="horus-change-password-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">パスワードの再発行</h1>
      <div class="message-column">
        パスワードの再発行を受付しました。<br/>
        下記フォームにパスワードを入力すると、パスワードの更新が行えます。
      </div>
      <div class="signin-block">
        <horus-attribute-in-form
          :labelText="'パスワード'"
          :necessary="true"
          :validationState="validatePassword"
          :validationMessage="validatePasswordMessage"
          :attributeNotify="'8文字以上'">
          <input :type="passwordInputType" v-model="password" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'パスワード(確認)'"
          :necessary="true"
          :validationState="validatePasswordConf"
          :validationMessage="validatePasswordConfMessage"
          :attributeNotify="'上と同じパスワードを入力'">
          <input :type="passwordInputType" v-model="passwordConfirmation" />
        </horus-attribute-in-form>
        <div class="toggle-password-visible" @click="togglePasswordVisible">
          <horus-custom-check-box
            :active="passwordVisible"
            :enabled="true"
            :type="'color'"
            />パスワードを表示
        </div>
        <div class="expire-tokens">
          <div class="expire-tokens-notify">
            チェックを入れると、アプリの自動ログインの設定をリセットします。<br/>
            リセット後は、アプリでログインする際に、再度NBIDとパスワードを入力する必要があります。
          </div>
          <div class="toggle-expire-token" @click="toggleExpireTokens">
            <horus-custom-check-box
              :active="expireAppTokens"
              :enabled="true"
              :type="'color'"
              />アプリ用ログイントークンを失効させる
          </div>
        </div>
        <button class="submit-btn" :class="{disable: !allValidated}" @click="changePassword">パスワード変更</button>
      </div>
    </div>
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { User } from '@/api'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import AuthenticateInfo from '@/validateModel/AuthenticateInfo.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusChangePasswordView',

  components: {
    HorusAttributeInForm,
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      password: '',
      passwordConfirmation: '',
      passwordVisible: false,
      expireAppTokens: false
    }
  },

  computed: {
    validateNBID () {
      if (this.nbidState === 'available') {
        return 'valid'
      } else if (this.nbidState === 'alreadyuse' || this.nbidState === 'invalid') {
        return 'invalid'
      } else {
        return 'disabled'
      }
    },

    validateNBIDMessage () {
      if (this.nbidState === 'alreadyuse') {
        return '入力されたNBIDは、すでに別のユーザーが利用しています。別のNBIDを設定してください。'
      } else if (this.nbidState === 'invalid') {
        return '入力されたNBIDは、形式が正しくありません。６文字以上で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePassword () {
      if (this.password.length <= 0) {
        return 'disabled'
      } else if (AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordMessage () {
      if (this.password.length > 0 && !AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'パスワードは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePasswordConf () {
      if (this.passwordConfirmation.length <= 0) {
        return 'disabled'
      } else if (this.password.trim() === this.passwordConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordConfMessage () {
      if (this.password.length > 0 && (this.password.trim() !== this.passwordConfirmation)) {
        return '上で入力したパスワードと同じ値を入れてください。'
      } else {
        return ''
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    allValidated () {
      if (
        this.validatePassword === 'valid' &&
        this.validatePasswordConf === 'valid'
      ) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  methods: {
    initToken () {
      this.token = this.$attrs.token
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    toggleExpireTokens () {
      this.expireAppTokens = !this.expireAppTokens
    },

    changePassword () {
      if (this.allValidated) {
        var formData = new FormData()
        formData.append('token', this.token)
        formData.append('authenticate_info[password]', this.password)
        formData.append('authenticate_info[password_confirmation]', this.passwordConfirmation)
        var alartMessage = 'パスワードの変更を完了しました。'
        if (this.expireAppTokens) {
          formData.append('expire_app_tokens', 'YES')
          alartMessage += '\n同時に登録済みのアプリ用ログイントークンを全て失効させました。'
        }
        User.changePassword({formData: formData}).then(() => {
          this.showConfirmDialog('パスワードの変更完了', alartMessage, DIALOG_TYPE.MESSAGE, () => {
            this.$router.push('/profile')
          })
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('パスワードの変更失敗', 'パスワードの変更に失敗しました。')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-change-password-view {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
  }
  h1.centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .message-column {
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .signin-block {
    margin: 20px auto;
    padding: 40px;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #aaa;
    text-align: center;
    @include mediaQuery('phone') {
      padding: 20px;
    }
    input {
      font-size: 16px;
      border: 1px solid #aaa;
    }
    button {
      margin-top: 20px;
      padding: 3px 10px;
      border: 1px solid #4cae4c;
      border-radius: 5px;
      background-color: #4cae4c;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      &.disable {
        border-color: #aaa;
        background-color: #aaa;
        color: #ddd;
      }
    }
    .expire-tokens {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
      .expire-tokens-notify {
        margin-bottom: 10px;
        padding: 5px;
        border: 1px solid #c53d43;
        text-align: left;
        font-size: 0.9em;
      }
    }
  }
}
</style>
