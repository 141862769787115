import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'

export default class HashTag extends Model {
  static entity = 'hashTags'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(-1),
      tagName: this.attr(''),
      deleted: this.attr(false),
      position: this.attr(-1)
    }
  }

  static afterLimit (hashTags) {
    hashTags = hashTags.filter(tag => tag.position >= 0)
    return hashTags.sort((f, s) => { return f.position - s.position })
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(timelineOwnerID, data) {
    let exTags = await HashTag.query().where('user_id', timelineOwnerID).get()
    for(var et of exTags) {
      await et.$delete()
    }
    let entities = await HashTag.insertOrUpdate({ data: data.hash_tags })
    if (entities && entities.hashTags) {
      return entities.hashTags
    } else {
      return []
    }
  }

  static apiConfig = {
    actions: {
      fetch ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'hash_tag/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          return HashTag.updateData(timelineOwnerID, res.response.data)
        })
      },
    
      async create ({timelineOwnerID, tagName}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('tag_name', tagName)
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'hash_tag',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async update ({timelineOwnerID, tagID, tagName}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('hash_tag_id', tagID)
        formData.append('tag_name', tagName)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'hash_tag',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy ({timelineOwnerID, tagID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'hash_tag',
            params: { target_user_id: timelineOwnerID, hash_tag_id: tagID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async moveTo ({timelineOwnerID, tagID, newPosition}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('hash_tag_id', tagID)
        formData.append('position', newPosition)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'hash_tag/move_to',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }

    }
  }
}
