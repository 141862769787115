import axios from './AxiosInstance'

export default {
  latestRecordExist: ({ timelineOwnerID, targetRecordID, options }) => {
    var params = { target_user_id: timelineOwnerID, target_record_id: targetRecordID }
    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        params['options[' + key + ']'] = value
      })
    }
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'record/latest_record_exist',
        withCredentials: true,
        params: params
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  oldRecordExist: ({ timelineOwnerID, targetRecordID, options }) => {
    var params = { target_user_id: timelineOwnerID, target_record_id: targetRecordID }
    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        params['options[' + key + ']'] = value
      })
    }
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'record/old_record_exist',
        withCredentials: true,
        params: params
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetch: ({ timelineOwnerID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'timeline_state',
        withCredentials: true,
        params: { target_user_id: timelineOwnerID }
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
