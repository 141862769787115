import { Model } from '@vuex-orm/core'
import BoadComment from './BoadComment'

export default class BoadCommentFile extends Model {
  static entity = 'boadCommentFiles'

  static fields () {
    return {
      id: this.attr(null),
      boad_comment_id: this.attr(null),
      filename: this.string(''),
      size: this.number(0),
      filenameOriginal: this.string('')
    }
  }

  get fileURL () {
    const info = BoadComment.find(this.boad_comment_id)
    return process.env.VUE_APP_BASE_URL + '/boad_comment/show_file?target_user_id=' + info.user_id + '&boad_comment_file_id=' + this.id
  }
}
