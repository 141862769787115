<template>
  <div class="horus-family-view">
    <horus-deputize-sub-header
      :timelineOwner="timelineOwner"
      v-if="deputizing"
      />
    <div class="family-contents" :class="{deputizing: deputizing}">
      <h1 class="centering-title">家族</h1>
      <div class="family-block">
        <h4>家族になっている利用者</h4>
        <div class="family-list-column" v-if="!familyNowLoading">
          <div class="block-title" @click="familyListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: familyListOpen}" />
            家族リスト<span class="caption">家族の数:{{ families.length }}名</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="family-list" v-show="familyListOpen" v-if="families.length > 0">
              <horus-family-detail-column
                v-for="family in families"
                :key="family.id"
                :family="family"
                v-on:removeWatchingEvent="removeWatchingFamily"
                v-on:removeVisibleEvent="removeVisibleFamily"
                v-on:removeFamilyEvent="removeFamily"
                />
            </div>
          </transition>
        </div><!-- family list column end -->
        <div class="now-loading" v-if="familyNowLoading">読み込み中...</div>
      </div>
      <div class="sent-request-block">
        <h4>依頼状の送信</h4>
        <div class="subtitle">家族の登録依頼を送る</div>
        <div class="block-title">
          <button type="button" class="quick-qr-btn" @click="readQrForQuickFamily">かんたんQRで家族登録</button>
          <button type="button" class="create-invitation-by-qr-btn" @click="readQrForCreateInvitation">QRで案内状を作成</button>
          <button type="button" class="create-invitation-by-qr-btn" @click="inputQrForCreateInvitation">手入力でのQRコードで案内状を作成</button>
        </div>
        <div class="block-title">
          <button type="button" class="create-request-btn" @click="createNewRequest">依頼状の作成</button>
        </div>
        <div class="sent-request-list-column" v-if="!sentListNowLoading">
          <div class="block-title" @click="sentRequestListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
            依頼状の送信リスト<span class="caption">案内状の数:{{ sentRequests.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-request-list" v-show="sentListOpen" v-if="sentRequests.length > 0">
              <horus-sent-invitation-column
                v-for="sentRequest in sentRequests"
                :key="'sentReq' + sentRequest.id"
                :sentRequest="sentRequest"
                v-on:sendMailEvent="sendMail"
                v-on:removeRequestEvent="removeRequest"
                />
            </div><!-- sent-request-list end -->
          </transition>
          <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
        </div><!-- sent-request-list-column end -->
      </div><!-- send request block end -->
      <div class="receive-list-block">
        <h4>依頼状の受信</h4>
        <div class="receive-list-column" v-if="!receiveListNowLoading">
          <div class="block-title" @click="receiveRequestListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: receiveListOpen}" />
            依頼状の受信リスト<span class="caption notice">受け取った依頼の数:{{ receiveRequests.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="receive-request-list" v-show="receiveListOpen">
              <div class="no-receive-notify" v-if="receiveRequests.length <= 0">あなた宛の家族案内はありません。</div>
              <div v-if="receiveRequests.length > 0">
                <horus-receive-invitation-column
                  v-for="receiveRequest in receiveRequests"
                  :key="'receiveReq' + receiveRequest.id"
                  :receiveRequest="receiveRequest"
                  v-on:confirmRequestEvent="confirmRequest"
                  />
              </div>
            </div>
          </transition>
        </div><!-- receive list column end -->
        <div class="now-loading" v-if="receiveListNowLoading">読み込み中...</div>
      </div>
      <horus-footer />
    </div>
    <horus-family-invitation-create-modal
      :showCreateModal="showCreateModal"
      :timelineOwner="timelineOwner"
      :families="families"
      :sentRequests="sentRequests"
      :receiveRequests="receiveRequests"
      :reservedNBID="requestReservedNBID"
      v-on:calcelSendEvent="cancelCreateRequest"
      v-on:requestSubmitEvent="requestSubmit"
      />
    <horus-family-invitation-confirm-modal
      :request="confirmTargetRequest"
      :showFamilyInvitationConfirmModal="showFamilyInvitationConfirmModal"
      :families="families"
      v-on:admitEvent="admitRequest"
      v-on:rejectEvent="rejectRequest"
      v-on:cancelEvent="cancelConfirmRequest"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author,
  TimelineOwner,
  Family
} from '@/models'

import {
  Permission,
  AuthorizedFamilyInvitation,
  QuickAgreementCode,
  StaticIdentifyCode
} from '@/api'

import HorusDeputizeSubHeader from '@/components/navigation/HorusDeputizeSubHeader.vue'
import HorusFamilyDetailColumn from '@/components/familyConfig/HorusFamilyDetailColumn.vue'
import HorusSentInvitationColumn from '@/components/familyConfig/HorusSentInvitationColumn.vue'
import HorusReceiveInvitationColumn from '@/components/familyConfig/HorusReceiveInvitationColumn.vue'
import HorusFamilyInvitationCreateModal from '@/components/familyConfig/HorusFamilyInvitationCreateModal.vue'
import HorusFamilyInvitationConfirmModal from '@/components/familyConfig/HorusFamilyInvitationConfirmModal.vue'
// import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
// import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'
import HorusManageQrReaderBtn from '@/mixin/HorusManageQrReaderBtn.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as QUICK_ACTION_TYPE from '@/assets/constants/quickActionType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition, HorusManageQrReaderBtn],

  name: 'HorusFamilyView',

  components: {
    HorusDeputizeSubHeader,
    HorusFamilyDetailColumn,
    HorusSentInvitationColumn,
    HorusReceiveInvitationColumn,
    // HorusAttributeInForm,
    // HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog,
    HorusFamilyInvitationCreateModal,
    HorusFamilyInvitationConfirmModal
  },

  props: {
    loggedInUser: Object,
    signinMember: Object
  },

  data () {
    return {
      timelineOwner: null,
      deputizing: false,
      families: [],
      familyNowLoading: true,
      showCreateModal: false,
      sentRequests: [],
      sentListNowLoading: true,
      receiveRequests: [],
      receiveListNowLoading: true,
      showFamilyInvitationConfirmModal: false,
      confirmTargetRequest: null,
      familyListOpen: false,
      sentListOpen: false,
      receiveListOpen: false,
      requestReservedNBID: null
    }
  },

  watch: {
    '$route': 'initTimelineOwner',
    loggedInUser: { handler: 'initTimelineOwner', immediate: true },
    timelineOwner: { handler: 'initObjects', immediate: true }
  },

  methods: {
    async initTimelineOwner () {
      if (this.$route.params.id) {
        await TimelineOwner.api().fetch({ timelineOwnerID: this.$route.params.id })
        var owner = TimelineOwner.find(this.$route.params.id)
        if (!Author.find(owner.author_id)) {
          await Author.api().fetchByID({ timelineOwnerID: owner.id, idList: [owner.author_id] })
        }
        this.timelineOwner = owner
        if (this.$route.query.deputize) {
          await Permission.configure({ targetUserID: this.timelineOwner.id }).then(() => {
            this.deputizing = true
          })
        }
      } else if (this.loggedInUser) {
        this.timelineOwner = this.loggedInUser
      }
    },

    initObjects () {
      if (this.timelineOwner) {
        this.loadFamilies()
        this.loadSentRequests()
        this.loadReceiveRequests()
      }
    },

    async loadFamilies () {
      this.familyNowLoading = true
      this.families = []
      await Family.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      var fams = Family.query().where('user_id', this.timelineOwner.id).get()
      await this.loadMissingAuthors(fams)
      this.families = Family.query().where('user_id', this.timelineOwner.id).get()
      this.familyNowLoading = false
    },

    async loadMissingAuthors (preLoadFamilies) {
      var missingAuthorIDList = []
      preLoadFamilies.forEach(family => {
        if (!family.author) {
          if(missingAuthorIDList.indexOf(family.family_id) < 0) missingAuthorIDList.push(family.family_id)
        }
      })
      if (missingAuthorIDList.length > 0) {
        await Author.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: missingAuthorIDList })
      }
    },

    async loadSentRequests () {
      this.sentListNowLoading = true
      this.sentRequests = []
      await AuthorizedFamilyInvitation.fetchSentList({ timelineOwnerID: this.timelineOwner.id }).then(res => {
        this.sentRequests = res.authorized_family_invitations
      })
      this.sentListNowLoading = false
    },

    async loadReceiveRequests () {
      this.receiveListNowLoading = true
      this.receiveRequests = []
      AuthorizedFamilyInvitation.fetchReceiveList({ timelineOwnerID: this.timelineOwner.id }).then(res => {
        this.receiveRequests = res.authorized_family_invitations
      }).finally(() => {
        this.receiveListNowLoading = false
      })
    },

    familyListToggle () {
      this.familyListOpen = !this.familyListOpen
    },

    sentRequestListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    receiveRequestListToggle () {
      this.receiveListOpen = !this.receiveListOpen
    },

    removeWatchingFamily (family) {
      var familyName = family.author.name
      this.showConfirmDialog(
        familyName + 'さんがあなたのタイムラインを見られないようにします',
        familyName + 'さんがあなたのタイムラインを見られないように設定を変更します。\nあなたが相手を見る権限がある場合、その権限は残ります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          Family.api().removeWatching({ timelineOwnerID: this.timelineOwner.id, targetID: family.rawFamilyID }).then(() => {
            this.loadFamilies()
            this.showNotifyDialog(
              '家族の設定変更完了',
              familyName + 'さんがあなたのタイムラインを見られないように設定を変更しました。\n再度相手に依頼を送り、承認されるまで、' + familyName + 'さんはあなたのタイムラインを閲覧できません。'
            )
          }).catch(error => {
            console.error(error)
            this.loadFamilies()
            this.showNotifyDialog(
              '家族の設定変更失敗',
              familyName + 'に関する設定の変更に失敗しました。'
            )
          })
        }
      )
    },

    removeVisibleFamily (family) {
      var familyName = family.author.name
      this.showConfirmDialog(
        'あなたが' + familyName + 'さんのタイムラインを閲覧できないようにします',
        'あなたが' + familyName + 'さんのタイムラインを閲覧できないように設定を変更します。\n相手があなたを見る権限がある場合、その権限は残ります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          Family.api().removeWatching({ timelineOwnerID: this.timelineOwner.id, targetID: family.rawFamilyID }).then(() => {
            this.loadFamilies()
            this.showNotifyDialog(
              '家族の設定変更完了',
              familyName + 'さんのタイムラインを閲覧できないように設定を変更しました。\n再度相手に依頼を送り、承認されるまで、あなたは' + familyName + 'さんのタイムラインを閲覧できません。'
            )
          }).catch(error => {
            console.error(error)
            this.loadFamilies()
            this.showNotifyDialog(
              '家族の設定変更失敗',
              familyName + 'に関する設定の変更に失敗しました。'
            )
          })
        }
      )
    },

    removeFamily (family) {
      var familyName = family.author.name
      this.showConfirmDialog(
        familyName + 'さんの家族設定を削除します',
        '家族設定を削除します。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          Family.api().destroy({ timelineOwnerID: this.timelineOwner.id, targetID: family.rawFamilyID }).then(() => {
            this.loadFamilies()
            this.showNotifyDialog(
              '家族設定の削除完了',
              familyName + 'を家族から削除しました。\n再度相手に依頼を送り、承認されるまで、' + familyName + 'さんはあなたのタイムラインを閲覧できず、あなたも' + familyName + 'サンのタイムラインを閲覧できません。'
            )
          }).catch(error => {
            console.error(error)
            this.loadFamilies()
            this.showNotifyDialog(
              '家族設定の削除失敗',
              familyName + 'を家族からの削除に失敗しました。'
            )
          })
        }
      )
    },

    readQrForQuickFamily () {
      this.$emit('showQrReaderEvent', 'かんたんQRで家族登録', this.quickQrFamily, false)
    },

    quickQrFamily (decodedObject) {
      console.log(decodedObject)
      console.log(JSON.stringify(decodedObject))
      console.log(decodedObject.decodedString)
      if (decodedObject.decodedString) {
        QuickAgreementCode.fetch({ quickCode: decodedObject.decodedString }).then(res => {
          console.log(res)
          var order = {
            title: '家族登録の依頼',
            author: res.author,
            quickAgreementCode: decodedObject.decodedString,
            organizationID: null,
            userID: this.timelineOwner.id,
            callback: this.quickQrFamilyFinished,
            actionType: QUICK_ACTION_TYPE.QUICK_ACTION_FAMILY
          }
          this.$emit('showQuickAgreementActionEvent', order)
        }).catch(err => {
          console.error(err)
          this.showNotifyDialog(
            'ユーザーの検証失敗',
            'ユーザーの検証に失敗しました。もう一度かんたんQRの表示、読み取りからやり直してください。'
          )
        })
      } else {
        // fail check
        this.showNotifyDialog(
          'QR読み取り失敗',
          'QRの読み取りに失敗しました。'
        )
      }
    },

    quickQrFamilyFinished () {
      this.loadFamilies()
    },

    readQrForCreateInvitation () {
      this.$emit('showQrReaderEvent', '家族の登録依頼の送付先読み取り', this.createNewInvitationByQR, true)
    },

    inputQrForCreateInvitation () {
      this.$emit('showManualQrInputEvent', '家族の登録依頼の送付先読み取り', this.createNewInvitationByQR)
    },

    createNewInvitationByQR (decodedString) {
      console.log('createNewInvitationByQR: ' + JSON.stringify(decodedString))
      var categoryID = ''
      if (decodedString.categoryID && decodedString.categoryID > 0) {
        categoryID = decodedString.categoryID
      }
      StaticIdentifyCode.fetchUser({ categoryID: categoryID, IDCode: decodedString.decodedString }).then(res => {
        console.log(JSON.stringify(res))
        if (res.success) {
          var fail = false
          var failMessage = ''
          if (this.families.some(family => family.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーは既に家族です'
          } else if (this.sentRequests.some(req => req.login_id === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーはすでに依頼済みです'
          } else if (this.receiveRequests.some(req => req.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーの利用者から依頼を受けています'
          } else if (this.timelineOwner.nbid === res.nbid) {
            fail = true
            failMessage = '自分自身に家族依頼を送信できません。'
          }
          if (fail) {
            this.showNotifyDialog(
              '家族の登録依頼の作成不可',
              failMessage
            )
          } else {
            this.showConfirmDialog(
              '家族の登録依頼の作成',
              '[' + res.author.name + ']に家族の登録依頼を送信します。\nよろしいですか?',
              DIALOG_TYPE.YES_NO,
              () => {
                this.createNewRequest(res.nbid)
              }
            )
          }
        } else {
          this.showNotifyDialog(
            'ユーザーの取得失敗',
            res.message
          )
        }
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog(
          'ユーザーの取得失敗',
          'ユーザーの取得に失敗しました。登録されていないQRコードの可能性があります'
        )
      })
    },

    createNewRequest (reservedNBID = null) {
      if (typeof reservedNBID === 'string') {
        this.requestReservedNBID = reservedNBID
      }
      this.showCreateModal = true
    },

    cancelCreateRequest () {
      this.showCreateModal = false
    },

    requestSubmit (formData, targetName, callback) {
      AuthorizedFamilyInvitation.create({ formData: formData }).then(() => {
        this.loadSentRequests()
        this.showCreateModal = false
        callback()
        this.showNotifyDialog(
          '依頼状送信完了',
          targetName + '宛てに、家族の登録依頼を送信しました。\n相手の承認をお待ちください。'
        )
      }).catch(error => {
        console.error(error)
        this.showCreateModal = false
        callback()
        this.showNotifyDialog(
          '依頼状送信失敗',
          targetName + '宛てに、家族の登録依頼を送信しようとしましたが、失敗しました。'
        )
      })
    },

    sendMail (sentRequest) {
      var sentName = sentRequest.login_id
      if (!sentRequest.login_id) {
        sentName = sentRequest.name
      }
      AuthorizedFamilyInvitation.sendMail({ timelineOwnerID: this.timelineOwner.id, targetID: sentRequest.id }).then(() => {
        this.loadSentRequests()
        this.showNotifyDialog(
          '依頼状の再送信完了',
          sentName + '宛てに、家族の登録依頼を再度送信しました。\n相手の承認をお待ちください。'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '依頼状再送信失敗',
          sentName + '宛てに、家族の登録依頼を再度送信しようとしましたが、失敗しました。'
        )
      })
    },

    removeRequest (sentRequest) {
      var sentName = sentRequest.login_id
      if (!sentRequest.login_id) {
        sentName = sentRequest.name
      }
      this.showConfirmDialog(
        '家族依頼の削除',
        sentName + 'に宛てた家族依頼を削除します。\nまだ承認が完了していない場合、相手の承認が出来なくなります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          AuthorizedFamilyInvitation.destroy({ timelineOwnerID: this.timelineOwner.id, targetID: sentRequest.id }).then(() => {
            this.loadSentRequests()
            this.showNotifyDialog(
              '家族依頼の削除完了',
              sentName + '宛の家族依頼を削除しました。'
            )
          }).catch(error => {
            console.error(error)
            this.showNotifyDialog(
              '家族依頼の削除失敗',
              sentName + '宛の家族依頼の削除に失敗しました。'
            )
          })
        }
      )
    },

    receiveRequestMessage (receiveRequest) {
      if (receiveRequest.message_content && receiveRequest.message_content.length > 0) {
        return receiveRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return '家族案内のメッセージはありません。'
      }
    },

    confirmRequest (receiveRequest) {
      this.confirmTargetRequest = receiveRequest
      this.showFamilyInvitationConfirmModal = true
    },

    admitRequest (request, showOwnTimeline) {
      this.showConfirmDialog(
        '家族依頼の承認',
        request.user_name + 'からの家族案内を承認します。\n実行した結果、相手はあなたのタイムラインの閲覧、書き込みが行えるようになります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeAdmitRequest(request, showOwnTimeline)
        }
      )
    },

    executeAdmitRequest (request, showOwnTimeline) {
      console.log('In View, show own timeline: ' + showOwnTimeline)
      var addtionMessage = '相手のタイムラインを見られるようになりました。'
      if (showOwnTimeline) {
        addtionMessage = '互いに相手のタイムラインを見られるようになりました。'
      }
      AuthorizedFamilyInvitation.admitOnSignin({ timelineOwnerID: this.timelineOwner.id, targetID: request.id, both: showOwnTimeline }).then(() => {
        this.showNotifyDialog(
          '家族案内の承認完了',
          request.user_name + 'と家族になりました。\n' + addtionMessage
        )
        this.loadReceiveRequests()
        this.loadFamilies()
        this.showFamilyInvitationConfirmModal = false
        this.confirmTargetRequest = null
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '家族案内の承認失敗',
          request.user_name + 'からの家族案内承認に失敗しました。'
        )
        this.showFamilyInvitationConfirmModal = false
        this.confirmTargetRequest = null
      })
    },

    rejectRequest (request) {
      this.showConfirmDialog(
        '家族依頼の拒否',
        request.user_name + 'からの家族案内を拒否します。\n実行した結果、この案内は削除され、拒否した結果が相手に通知されます。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeRejectRequest(request)
        }
      )
    },

    executeRejectRequest (request) {
      AuthorizedFamilyInvitation.rejectOnSignin({ timelineOwnerID: this.timelineOwner.id, targetID: request.id }).then(() => {
        this.showNotifyDialog(
          '家族案内の拒否完了',
          request.user_name + 'からの家族案内を拒否しました。'
        )
        this.showFamilyInvitationConfirmModal = false
        this.confirmTargetRequest = null
        this.loadReceiveRequests()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '家族案内の拒否失敗',
          request.user_name + 'からの家族案内の拒否に失敗しました。'
        )
        this.showFamilyInvitationConfirmModal = false
        this.confirmTargetRequest = null
      })
    },

    cancelConfirmRequest () {
      console.log('call cancel in view')
      this.showFamilyInvitationConfirmModal = false
      this.confirmTargetRequest = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-family-view {
  height: calc(100% - 50px);
  overflow: auto;
  .family-contents {
    height: calc(100% - 10px);
    padding: 10px 50px 0 50px;
    overflow: auto;
    background-color: #f5f5f5;
    &.deputizing {
      height: calc(100% - 73px);
    }
    @include mediaQuery('phone') {
      padding: 10px;
      padding-bottom: 0;
    }
    .centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h4 {
      margin: 10px 0;
      padding-bottom: 5px;
      color: #6c272d;
      border-bottom: 1px solid #aaa;
      font-size: 18px;
      font-weight: normal;
    }
    .block-title {
      cursor: pointer;
      margin: 10px 0;
      font-weight: bold;
      img {
        width: 13px;
        transition: transform 300ms ease;
        &.open {
          transform: rotate(90deg);
        }
      }
      .caption {
        font-size: 12px;
        font-weight: normal;
        &.notice {
          color: #c43d53;
        }
      }
    }
    .family-list {
      height: auto;
      overflow: hidden;
    }
    .family {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      padding: 5px;
      border: 1px solid #aaa;
    }
    .quick-qr-btn {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 2px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
    .create-invitation-by-qr-btn {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 2px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
    .create-request-btn {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 20px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
    .create-request {
      overflow: hidden;
      .request-message {
        margin-bottom: 25px;
        padding: 10px;
        border: 1px solid #aaa;
        background-color: #fbfaf5;
      }
      .switch-request-type {
        .link {
          cursor: pointer;
          color: #428bca;
        }
      }
      .notify_for_admit {
        margin-bottom: 5px;
        padding: 10px;
        border: 1px solid #aaa;
        background-color: #fbfaf5;
        h1 {
          margin: 0;
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: bold;
          color: #666;
        }
      }
      input[type='text'] {
        font-size: 16px;
      }
      textarea {
        font-size: 16px;
      }
      .admit-agreement {
        cursor: pointer;
        margin: 10px;
        font-weight: bold;
      }
      .submit-controll {
        margin: 10px;
        text-align: center;
        button {
          cursor: pointer;
          padding: 2px 20px;
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          &.disable {
            cursor: normal;
            background-color: #aaa;
          }
        }
      }
    }
    .sent-request-list {
      overflow: hidden;
      .sent-request {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 5px;
        border: 1px solid #aaa;
        .content_controll_block {
          width: 100%;
          text-align: right;
          button {
            cursor: pointer;
            border: 1px solid #999;
            border-radius: 3px;
            background-color: #999;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
    .receive-request-list {
      overflow: hidden;
    }
    .labeled_content_single_block {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      min-width: 300px;
      margin: 5px;
      margin-right: 10px;
      label {
        margin: 0;
        margin-right: 5px;
        font-weight: normal;
        font-size: 0.9em;
        color: #666;
      }
      .content {
        display: inline-block;
        font-size: 1.1em;
        img {
          width: 20px;
          margin-right: 5px;
        }
        .change-family-state-btn {
          background-color: #999;
          color: #fff;
          border: 1px solid #999;
          border-radius: 3px;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 300ms ease;
}
</style>
