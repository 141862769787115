export default {

  methods: {
    organizationVisibleMembers (organization) {
      // console.log('mixin:HorusOrganizationMemberVisibility.js:organizationVisibleMembers')
      var members = []
      if (this.organizationStaffVisibilities && this.organizationStaffVisibilities.length > 0) {
        var organizationSet = this.organizationStaffVisibilities.find(orgSet => orgSet.organizationID === organization.id)
        if (organizationSet) {
          organizationSet.visibilities.forEach(visibility => {
            if (visibility.visible) {
              var member = visibility.member
              if (member) {
                members.push(member)
              }
            }
          })
        }
      }
      return members
    }

  }
}
