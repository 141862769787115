<template>
  <div class="horus-create-organization-request-mail-confirmed">
    <h2>メールアドレスの確認</h2>
    <div class="messages">
      <h4>メールアドレスを登録する</h4>
      以下のメールアドレスでナラティブブックの施設登録を進めます。続けて施設情報入力画面に移動します。
      <h1>{{ mailAddress }}</h1>
      <button type="button" class="input-profile-btn" @click="inputProfiles">次へ(施設情報入力)</button>
      <br />
      <div class="change-address-column">
        <div class="content">
          上記メールアドレスと異なるメールアドレスで登録したい場合は、<br/>
          下のフィールドに登録したいメールアドレスを入力して、再度確認メールを送信してください。
        </div>
        <input type="text" class="mail-address-input" v-model="mail" placeholder="利用するメールアドレス" size="25" />
        <button type="button" class="send-mail-btn" @click="sendMail">メールアドレスの変更</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusCreateOrganizationRequestMailConfirmed',

  props: {
    mailAddress: String
  },

  data () {
    return {
      mail: ''
    }
  },

  methods: {
    inputProfiles () {
      this.$emit('inputProfileEvent')
    },

    sendMail () {
      this.$emit('sendMailEvent', this.mail)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-create-organization-request-mail-confirmed {
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .messages {
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
    text-align: center;
    @include mediaQuery('phone') {
      padding: 20px 10px;
    }
    .input-profile-btn {
      padding: 3px 10px;
      border: 1px solid #b94047;
      border-radius: 3px;
      background-color: #b94047;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
    .additional-status {
      margin: 10px;
      padding: 20px;
      border: 1px solid #aaa;
    }
    .change-address-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      @include mediaQuery('phone') {
        margin: 20px 10px;
      }
      .content {
        margin: 10px 0;
      }
      .mail-address-input {
        font-size: 16px;
        border: 1px solid #aaa;
      }
      .send-mail-btn {
        margin: 10px 0;
        padding: 3px 10px;
        border: 1px solid #ee7800;
        border-radius: 3px;
        background-color: #ee7800;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
}
</style>
