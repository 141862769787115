<template>
  <div class="horus-manage-organization-deputize" v-if="organization">
    <horus-manage-organization-frame
      :currentDisplay="'deputize'"
      :signinMember="signinMember"
      :organization="organization"
      >
      <h1 class="centering-title">設定代行</h1>
      <div class="not-ready-notify" v-show="!deputizeAvailable">
        この施設は、設定代行の準備が出来ていません。
        <div v-if="noDeputizeManager">設定代行管理者が一人も任命されていません。「施設」タブで、最低一人の設定代行管理者を任命してください。</div>
        <div v-if="noAgent">設定代行者が一人も任命されていません。「施設」タブで、最低一人の設定代行者を任命してください。</div>
        <div v-if="organization && !organization.deputizable">施設に設定代行の権限が与えられていません。システム管理者に設定代行の権限を申請してください。</div>
      </div>
      <div class="ready-to-deputize" v-show="deputizeAvailable">
        <div class="user-list-block red_titled_column">
          <div class="revoke-notify" v-if="organization.applyingForRevoke">
            本施設は、現在退会申請中です。<br/>
            退会申請中は、新規設定代行及び設定代行案内の追加、案内の再送、申込みの承諾、新規利用者の作成は行なえません。
          </div>
          <h4>新規利用者の作成</h4>
          <div class="create-user-column">
            ナラティブブックID(NBID)を新規作成して登録する
            <div class="block-title" v-if="!organization.applyingForRevoke" @click="createUserToggle">
              <img src="/static/images/open_close_triangle.png" :class="{open: createUserOpen}" />
              利用者を作成する
            </div>
            <transition
              name="accordion-block"
              @enter="accordionEnter"
              @after-enter="accordionAfterEnter"
              @leave="accordionLeave"
              @after-leave="accordionAfterLeave">
              <div class="create-user" v-show="createUserOpen">
                <horus-create-no-signin-user
                  v-on:createUserEvent="createUser"
                  />
                <div class="uploaded-agreement-column" v-if="!organization.applyingForRevoke && uploadedAgreements.length > 0">
                  <h4>アップロード済で未使用の同意書</h4>
                  <div class="uploaded-agreements labeled_content_block"
                    v-for="agreement in uploadedAgreements"
                    :key="'uploadedAgreement' + agreement.id">
                    <div class="labeled_content_single_block">
                      <label>ファイル名</label>
                      <div class="content">{{ agreement.filename }}</div>
                    </div>
                    <div class="labeled_content_single_block agreement-controll">
                      <a :href="documentDownloadURL(agreement)" target="_blank"><button class="download-btn">ダウンロード</button></a>
                      <button class="delete-agreement-btn" @click="deleteAgreement(agreement)">削除</button>
                      <button class="create-user-btn" @click="createUserByAgreement(agreement)">この同意書で利用者を作成する</button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <h4>設定代行登録している利用者</h4>
          <div class="user-list-column" v-if="!usersNowLoading">
            <div class="block-title" @click="userListToggle">
              <img src="/static/images/open_close_triangle.png" :class="{open: userListOpen}" />
              利用者リスト<span class="caption">利用者数:{{ deputizeRelations.length }}名</span>
            </div>
            <transition
              name="accordion-block"
              @enter="accordionEnter"
              @after-enter="accordionAfterEnter"
              @leave="accordionLeave"
              @after-leave="accordionAfterLeave">
              <div class="user-list" v-show="userListOpen" v-if="deputizeRelations.length > 0">
                <horus-deputized-user-in-manage-list
                  v-for="relation in deputizeRelations"
                  :key="'manageOrgDeputizeUser' + relation.user_id"
                  :deputizeRelation="relation"
                  :organization="organization"
                  v-on:removeDeputizeEvent="removeDeputize"
                  v-on:updateAgreementEvent="updateDeputizeAgreement"
                  />
              </div>
            </transition>
          </div>
          <div class="now-loading" v-if="usersNowLoading">読み込み中...</div>
        </div><!-- user-list-block -->
        <div class="sent-invitation-block red_titled_column">
          <h4>案内状の送信</h4>
          <div class="subtitle" v-if="!organization.applyingForRevoke">利用者へかかりつけの登録案内を送る</div>
          <div class="block-title" v-if="!organization.applyingForRevoke">
            <button type="button" class="quick-qr-btn" @click="readQrForQuickDeputize">かんたんQRで設定代行登録</button>
            <button type="button" class="create-invitation-by-qr-btn" @click="readQrForCreateInvitation">QRで案内状を作成</button>
            <button type="button" class="create-invitation-by-qr-btn" @click="inputQrForCreateInvitation">手入力でのQRコードで案内状を作成</button>
          </div>
          <div class="block-title" v-if="!organization.applyingForRevoke">
            <button type="button" class="create-invitation-btn" @click="createNewInvitation">案内状の作成</button>
          </div>
          <div class="sent-invitation-list-column" v-if="!sentListNowLoading">
            <div class="block-title" @click="sentInvitationListToggle">
              <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
              案内状の送信リスト<span class="caption">案内状の数:{{ sentInvitations.length }}件</span>
            </div>
            <transition
              name="accordion-block"
              @enter="accordionEnter"
              @after-enter="accordionAfterEnter"
              @leave="accordionLeave"
              @after-leave="accordionAfterLeave">
              <div class="sent-invitation-list" v-show="sentListOpen" v-if="sentInvitations.length > 0">
                <horus-sent-deputize-invitation-in-manage
                  v-for="sentInvitation in sentInvitations"
                  :key="'sentDeputizeInvitation' + sentInvitation.id"
                  :sentInvitation="sentInvitation"
                  :organization="organization"
                  v-on:sendMailEvent="sendMail"
                  v-on:showPrintPageEvent="showPrintPage"
                  v-on:removeInvitationEvent="removeInvitation"
                  />
              </div>
            </transition>
          </div><!-- sent-invitation-list-column -->
          <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
        </div><!-- sent-invitation-block -->
        <div class="receive-request-block red_titled_column">
          <h4>依頼状の受信</h4>
          <div class="subtitle">利用者から設定代行の登録依頼を受ける</div>
          <div class="receive-request-list-column" v-if="!receiveListNowLoading">
            <div class="notify-comment"></div>
            <div class="receive-request-list" v-if="receiveRequests.length > 0">
              <horus-receive-deputize-request-in-manage
                v-for="receiveRequest in receiveRequests"
                :key="'receiveDeputizeRequest' + receiveRequest.id"
                :receiveRequest="receiveRequest"
                v-on:confirmRequestEvent="confirmRequest"
                />
            </div>
          </div><!-- sent-invitation-list-column -->
          <div class="now-loading" v-if="receiveListNowLoading">読み込み中...</div>
        </div><!-- receive-request-block -->
      </div><!-- DEPUTIZE AVAILABLE -->
    </horus-manage-organization-frame>
    <!-- create no signin user form -->
    <horus-create-no-signin-user-form-modal
      :showCreateNoSigninUserFormModal="showCreateNoSigninUserFormModal"
      :modeAgent="false"
      v-on:showConfirmDialogEvent="showConfirmDialog"
      v-on:showNotifyDialgEvent="showNotifyDialog"
      v-on:userCreationFinishEvent="userCreationFinish"
      v-on:userCreationCancelEvent="userCreationCancel"
      />
    <!-- create invitation modal -->
    <horus-create-deputize-invitation-to-user-modal
      :showCreateDeputizeInvitationToUserModal="showCreateDeputizeInvitationToUserModal"
      :organization="organization"
      :deputizeRelations="deputizeRelations"
      :sentInvitations="sentInvitations"
      :receiveRequests="receiveRequests"
      :reservedNBID="invitationReservedNBID"
      v-on:cancelSendInvitationEvent="cancelSendInvitation"
      v-on:submitSendInvitationEvent="submitSendInvitation"
      />
    <!-- confirm request modal -->
    <horus-confirm-deputize-request-for-organization-modal
      :showConfirmDeputizeRequestModal="showConfirmDeputizeRequestModal"
      :targetRequest="targetReceiveRequest"
      :organization="organization"
      v-on:cancelConfirmEvent="cancelConfirmRequest"
      v-on:rejectRequestEvent="rejectRequest"
      v-on:admitRequestEvent="admitRequest"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author
} from '@/models'
import {
  OrganizationMember,
  DeputizedUserCreationAgreement,
  OrganizationDeputizeRelation,
  DeputizingRequestToUser,
  DeputizeRequestForOrganization,
  QuickAgreementCode,
  StaticIdentifyCode
} from '@/api'

import HorusManageOrganizationFrame from '@/components/manageOrganization/HorusManageOrganizationFrame.vue'
import HorusCreateNoSigninUser from '@/components/manageOrganization/deputize/HorusCreateNoSigninUser.vue'
import HorusCreateNoSigninUserFormModal from '@/components/manageOrganization/deputize/HorusCreateNoSigninUserFormModal.vue'
import HorusDeputizedUserInManageList from '@/components/manageOrganization/deputize/HorusDeputizedUserInManageList.vue'
import HorusSentDeputizeInvitationInManage from '@/components/manageOrganization/deputize/HorusSentDeputizeInvitationInManage.vue'
import HorusReceiveDeputizeRequestInManage from '@/components/manageOrganization/deputize/HorusReceiveDeputizeRequestInManage.vue'
import HorusCreateDeputizeInvitationToUserModal from '@/components/manageOrganization/deputize/HorusCreateDeputizeInvitationToUserModal.vue'
import HorusConfirmDeputizeRequestForOrganizationModal from '@/components/manageOrganization/deputize/HorusConfirmDeputizeRequestForOrganizationModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'
import HorusManageQrReaderBtn from '@/mixin/HorusManageQrReaderBtn.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as QUICK_ACTION_TYPE from '@/assets/constants/quickActionType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition, HorusManageQrReaderBtn],

  name: 'HorusManageOrganizationDeputizeView',

  components: {
    HorusManageOrganizationFrame,
    HorusCreateNoSigninUser,
    HorusCreateNoSigninUserFormModal,
    HorusDeputizedUserInManageList,
    HorusSentDeputizeInvitationInManage,
    HorusReceiveDeputizeRequestInManage,
    HorusCreateDeputizeInvitationToUserModal,
    HorusConfirmDeputizeRequestForOrganizationModal,
    HorusConfirmDialog
  },

  props: {
    signinMember: Object,
    organization: Object
  },

  data () {
    return {
      deputizeAvailable: true,
      noDeputizeManager: true,
      noAgent: true,
      uploadedAgreements: [],
      createUserOpen: false,
      showCreateNoSigninUserFormModal: false,
      usersNowLoading: true,
      userListOpen: false,
      deputizeRelations: [],
      sentListNowLoading: true,
      sentListOpen: false,
      sentInvitations: [],
      receiveListNowLoading: true,
      receiveRequests: [],
      showCreateDeputizeInvitationToUserModal: false,
      targetReceiveRequest: null,
      showConfirmDeputizeRequestModal: false,
      invitationReservedNBID: null
    }
  },

  watch: {
    organization: { handler: 'initObjects', immediate: true },
    deputizeAvailable: { handler: 'loadObjects' }
  },

  methods: {
    async initObjects () {
      if (this.organization) {
        await this.checkDeputizeAvailable()
        this.loadObjects()
        console.log('checkdeputizeavailable await after,')
      }
    },

    async checkDeputizeAvailable () {
      OrganizationMember.fetch({ organizationID: this.organization.id }).then(async res => {
      // this.$store.dispatch('fetchOrganizationMembers', { organizationID: this.organization.id }).then(async res => {
        this.noDeputizeManager = true
        this.noAgent = true
        res.organization_members.forEach(member => {
          if (member.deputize_manager) {
            this.noDeputizeManager = false
          }
          if (member.agent) {
            this.noAgent = false
          }
        })
        if (this.noDeputizeManager || this.noAgent) {
          this.deputizeAvailable = false
        } else {
          this.deputizeAvailable = true
        }
        console.log('checkdeputizeavailable end.')
      }).catch(error => {
        console.log(error)
      })
    },

    loadObjects () {
      console.log('load object call, orgMan Deputisze...')
      if (this.deputizeAvailable) {
        this.loadAgreements()
        this.loadRelations()
        this.loadSentInvitations()
        this.loadReceiveRequests()
      }
    },

    loadAgreements () {
      console.log('loadagreement')
      DeputizedUserCreationAgreement.fetchList({ organizationID: this.organization.id }).then(res => {
        this.uploadedAgreements = res.deputized_user_creation_agreements
      })
    },

    async loadRelations () {
      this.usersNowLoading = true
      let rel = await OrganizationDeputizeRelation.fetchDeputizedUserIDList({ organizationID: this.organization.id })
      var unloadAuthors = []
      for(var usr of rel.deputized_users) {
        if (!Author.find(usr.author_id)) {
          if (unloadAuthors.indexOf(usr.author_id) < 0) unloadAuthors.push(usr.author_id)
        }
      }
      if (unloadAuthors.length > 0) {
        await Author.api().fetchByID({ timelineOwnerID: this.signinMember.user_id, idList: unloadAuthors })
      }
      for(var user of rel.deputized_users) {
        user.author = await Author.find(user.author_id)
      }
      this.deputizeRelations = rel.deputized_users
      this.deputizeRelations.sort((f, s) => {
        if (f.author.nameKana > s.author.nameKana) {
          return 1
        } else if (f.author.nameKana < s.author.nameKana) {
          return -1
        } else {
          return 0
        }
      })
      this.usersNowLoading = false
    },

    async loadSentInvitations () {
      this.sentListNowLoading = true
      await DeputizingRequestToUser.fetch({ organizationID: this.organization.id }).then(res => {
        this.sentInvitations = res.deputizing_request_to_users
        this.sentListNowLoading = false
      })
    },

    async loadReceiveRequests () {
      await DeputizeRequestForOrganization.fetchReceiveRequests({ organizationID: this.organization.id }).then(res => {
        this.receiveRequests = res.deputize_request_for_organizations
        this.receiveListNowLoading = false
      })
    },

    createUserToggle () {
      this.createUserOpen = !this.createUserOpen
    },

    userListToggle () {
      this.userListOpen = !this.userListOpen
    },

    sentInvitationListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    documentDownloadURL (agreement) {
      return process.env.BASE_URL + '/deputized_user_creation_agreement/?organization_id=' + this.organization.id + '&deputized_user_creation_agreement_id=' + agreement.id
    },

    deleteAgreement (agreement) {
      this.showConfirmDialog(
        '同意書の削除',
        '同意書を削除してよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestoryAgreement(agreement) }
      )
    },

    executeDestoryAgreement (agreement) {
      DeputizedUserCreationAgreement.destroy({ organizationID: this.organization.id, targetID: agreement.id }).then(() => {
        this.showNotifyDialog('同意書の削除完了', '同意書を削除しました')
        this.loadAgreements()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('同意書の削除失敗', '同意書の削除に失敗しました')
      })
    },

    createUserByAgreement (agreement) {
      DeputizedUserCreationAgreement.prepareCreateUser({ organizationID: this.organization.id, targetID: agreement.id }).then(() => {
        this.showCreateNoSigninUserFormModal = true
      })
    },

    createUser (uploadFile, callback) {
      var formData = new FormData()
      formData.append('organization_id', this.organization.id)
      formData.append('filename', uploadFile.uploadFile, uploadFile.name)
      DeputizedUserCreationAgreement.create({ formData: formData }).then(() => {
        callback()
        this.showCreateNoSigninUserFormModal = true
      })
    },

    userCreationCancel () {
      this.showCreateNoSigninUserFormModal = false
      this.loadAgreements()
    },

    userCreationFinish () {
      this.showNotifyDialog('新規利用者の作成完了', '新規にログイン出来ない利用者を作成しました。\n各種設定は、設定代行者のみが行えます')
      this.showCreateNoSigninUserFormModal = false
      this.loadAgreements()
      this.loadRelations()
    },

    removeDeputize (relation) {
      if (relation.removable) {
        this.showConfirmDialog(
          '設定代行登録の停止',
          '設定代行登録の停止しようとしています。\n停止した場合、再度設定代行登録を行うまで、元に戻せません。\n停止してよろしいですか?',
          DIALOG_TYPE.YES_NO,
          () => { this.executeRemoveDeputize(relation) }
        )
      } else {
        this.showNotifyDialog('設定代行の停止不可', 'この利用者の設定代行は停止することが出来ません。\nログイン可能な設定を行うか、別の設定代行施設もしくは設定代行者を登録する必要があります。')
      }
    },

    executeRemoveDeputize (relation) {
      OrganizationDeputizeRelation.destroyUser({ organizationID: this.organization.id, targetUserID: relation.user_id }).then(() => {
        this.loadRelations()
        this.showNotifyDialog('設定代行登録の停止', relation.author.name + 'の設定代行登録を停止しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('設定代行登録の停止失敗', '設定代行登録の停止に失敗しました。')
      })
    },

    updateDeputizeAgreement (relation, uploadFile) {
      var formData = new FormData()
      formData.append('organization_id', this.organization.id)
      formData.append('deputize_organization_id', relation.id)
      formData.append('filename', uploadFile.uploadFile, uploadFile.name)
      DeputizedUserCreationAgreement.update({ formData: formData }).then(() => {
        this.loadRelations()
        this.loadAgreements()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('同意書の更新失敗', '同意書の更新に失敗しました')
      })
    },

    readQrForQuickDeputize () {
      this.$emit('showQrReaderEvent', 'かんたんQRで設定代行登録', this.quickQrDeputize, false)
    },

    quickQrDeputize (decodedObject) {
      console.log(JSON.stringify(decodedObject))
      if (decodedObject.decodedString) {
        QuickAgreementCode.fetch({ quickCode: decodedObject.decodedString }).then(res => {
          var order = {
            title: '設定代行登録の依頼',
            author: res.author,
            quickAgreementCode: decodedObject.decodedString,
            organizationID: this.organization.id,
            userID: null,
            callback: this.quickQrDeputizeFinished,
            actionType: QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE
          }
          this.$emit('showQuickAgreementActionEvent', order)
        }).catch(err => {
          console.error(err)
          this.showNotifyDialog(
            'ユーザーの検証失敗',
            'ユーザーの検証に失敗しました。もう一度かんたんQRの表示、読み取りからやり直してください。'
          )
        })
      } else {
        // fail check
        this.showNotifyDialog(
          'QR読み取り失敗',
          'QRの読み取りに失敗しました。'
        )
      }
    },

    quickQrDeputizeFinished () {
      this.loadRelations()
    },

    readQrForCreateInvitation () {
      this.$emit('showQrReaderEvent', '設定代行案内の送付先読み取り', this.createNewInvitationByQR, true)
    },

    inputQrForCreateInvitation () {
      this.$emit('showManualQrInputEvent', '設定代行案内の送付先読み取り', this.createNewInvitationByQR)
    },

    createNewInvitationByQR (decodedString) {
      console.log('createNewInvitationByQR: ' + JSON.stringify(decodedString))
      var categoryID = ''
      if (decodedString.categoryID && decodedString.categoryID > 0) {
        categoryID = decodedString.categoryID
      }
      StaticIdentifyCode.fetchUser({ categoryID: categoryID, IDCode: decodedString.decodedString }).then(res => {
        console.log(JSON.stringify(res))
        if (res.success) {
          var fail = false
          var failMessage = ''
          if (this.deputizeRelations.some(rel => rel.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーは設定代行済です'
          } else if (this.sentInvitations.some(inv => inv.login_id === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーはすでに案内済みです'
          } else if (this.receiveRequests.some(req => req.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーの利用者から申請を受けています'
          }
          if (fail) {
            this.showNotifyDialog(
              '設定代行案内の作成不可',
              failMessage
            )
          } else {
            this.showConfirmDialog(
              '設定代行案内の作成',
              '[' + res.author.name + ']に設定代行案内を送信します。\nよろしいですか?',
              DIALOG_TYPE.YES_NO,
              () => {
                this.createNewInvitation(res.nbid)
              }
            )
          }
        } else {
          this.showNotifyDialog(
            'ユーザーの取得失敗',
            res.message
          )
        }
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog(
          'ユーザーの取得失敗',
          'ユーザーの取得に失敗しました。登録されていないQRコードの可能性があります'
        )
      })
    },

    createNewInvitation (reservedNBID = null) {
      // prepare negative list
      if (typeof reservedNBID === 'string') {
        this.invitationReservedNBID = reservedNBID
      }
      this.invitationNegativeList = []
      this.deputizeRelations.forEach(rel => {
        this.invitationNegativeList.push(rel.nbid)
      })
      this.sentInvitations.forEach(inv => {
        if (inv.login_id) {
          this.invitationNegativeList.push(inv.login_id)
        }
      })
      this.showCreateDeputizeInvitationToUserModal = true
    },

    cancelSendInvitation (callback) {
      this.showCreateDeputizeInvitationToUserModal = false
      this.invitationReservedNBID = null
      callback()
    },

    submitSendInvitation (formData, sendTarget, callback) {
      DeputizingRequestToUser.create({ formData: formData }).then(() => {
        this.loadSentInvitations()
        this.showCreateDeputizeInvitationToUserModal = false
        this.invitationReservedNBID = null
        callback()
        this.showNotifyDialog('案内状の送信', sendTarget + '宛に案内状の送信しました。')
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('案内状の送信失敗', sendTarget + '宛の案内状の送信に失敗しました。')
        this.invitationReservedNBID = null
        callback()
      })
    },

    sendMail (sentInvitation) {
      DeputizingRequestToUser.sendMail({ organizationID: this.organization.id, targetID: sentInvitation.id }).then(() => {
        this.showNotifyDialog('案内状の再送信', '案内状を再送信しました。')
        this.loadSentInvitations()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('案内状の再送信失敗', '案内状の再送信に失敗しました。')
      })
    },

    showPrintPage (sentInvitation) {
      console.log('show print page' + sentInvitation)
    },

    removeInvitation (sentInvitation) {
      this.showConfirmDialog(
        '案内状の削除',
        '案内状を削除しようとしています。\n削除した場合、案内状が期限内で未使用でも、承認の操作ができなくなります。\n削除してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeRemoveInvitation(sentInvitation) }
      )
    },

    executeRemoveInvitation (sentInvitation) {
      DeputizingRequestToUser.destroy({ organizationID: this.organization.id, targetID: sentInvitation.id }).then(() => {
        this.loadSentInvitations()
        this.showNotifyDialog('案内状の削除', '案内状を削除しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('案内状の削除失敗', '案内状の削除に失敗しました。')
      })
    },

    confirmRequest (receiveRequest) {
      this.targetReceiveRequest = receiveRequest
      this.showConfirmDeputizeRequestModal = true
    },

    cancelConfirmRequest () {
      this.targetReceiveRequest = null
      this.showConfirmDeputizeRequestModal = false
    },

    rejectRequest (receiveRequest) {
      this.showConfirmDialog(
        '設定代行依頼の拒否',
        '設定代行依頼の拒否しようとしています。\n拒否した結果は、送信者に通知され、受信依頼状リストから削除されます。\nこの操作は後戻りできません。拒否してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeRejectRequest(receiveRequest) }
      )
    },

    executeRejectRequest (receiveRequest) {
      DeputizeRequestForOrganization.reject({ organizationID: this.organization.id, targetID: receiveRequest.id }).then(() => {
        this.showNotifyDialog('依頼状の拒否完了', receiveRequest.user_name + 'からの依頼状を拒否しました。')
        this.loadReceiveRequests()
        this.showConfirmDeputizeRequestModal = false
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('依頼状の拒否失敗', receiveRequest.user_name + 'からの依頼状の拒否に失敗しました。')
        this.loadReceiveRequests()
        this.showConfirmDeputizeRequestModal = false
      })
    },

    admitRequest (receiveRequest) {
      this.showConfirmDialog(
        '設定代行依頼の承認',
        '設定代行依頼の承認しようとしています。\n承認すると、この施設は依頼者の設定代行施設となり、施設の設定代行車によって依頼者の各種設定操作を代行できるようになります。\n承認した結果は、送信者に通知され、受信依頼状リストから削除されます。\nこの操作は後戻りできません。承認してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeAdmitRequest(receiveRequest) }
      )
    },

    executeAdmitRequest (receiveRequest) {
      DeputizeRequestForOrganization.admit({ organizationID: this.organization.id, targetID: receiveRequest.id }).then(() => {
        this.showNotifyDialog('依頼状の承認', receiveRequest.user_name + 'からの依頼状を承認しました。')
        this.loadRelations()
        this.loadReceiveRequests()
        this.showConfirmDeputizeRequestModal = false
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('依頼状の承認失敗', receiveRequest.user_name + 'からの依頼状の承認に失敗しました。')
        this.loadRelations()
        this.loadReceiveRequests()
        this.showConfirmDeputizeRequestModal = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
@import '@/assets/css/centeringTitle.scss';
@import '@/assets/css/redTitledColumn.scss';
@import '@/assets/css/listData.scss';

.horus-manage-organization-deputize {
  .not-ready-notify {
    padding: 20px;
    border: 1px solid #692220;
    color: #692220;
    background-color: #fdeff2;
    font-size: 16px;
  }
  .block-title {
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
    img {
      width: 13px;
      transition: transform 300ms ease;
      &.open {
        transform: rotate(90deg);
      }
    }
    .caption {
      font-size: 12px;
      font-weight: normal;
      &.notice {
        color: #c43d53;
      }
    }
  }
  .quick-qr-btn {
    margin-bottom: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .create-invitation-by-qr-btn {
    margin-bottom: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .create-invitation-btn {
    margin-bottom: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .create-user {
    overflow: hidden;
  }
  .uploaded-agreements {
    justify-content: space-between;
  }
  .agreement-controll {
    text-align: right;
    button {
      margin: 0 5px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-weight: bold;
    }
    .download-btn {
      border-color: #666;
      background-color: #666;
      color: #fff;
    }
    .delete-agreement-btn {
      border-color: #c43d53;
      background-color: #c43d53;
      color: #fff;
    }
    .create-user-btn {
      border-color: #f08300;
      background-color: #f08300;
      color: #fff;
    }
  }
  .user-list {
    overflow: hidden;
  }
  .sent-invitation-list {
    overflow: hidden;
  }
  .revoke-notify {
    margin: 0;
    padding: 10px;
    border: 1px solid #c43d53;
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 300ms ease;
}
</style>
