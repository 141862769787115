<template>
  <div class="horus-create-user-finish">
    <h2>ナラティブブック利用者登録完了</h2>
    <div class="finish-content">
      {{ userName }}様<br/>
      ご登録ありがとうございます。<br/>
      すぐにナラティブブックをご利用いただけます。<br/>
      <div class="message" v-if="message.length > 0" v-html="messageHTML"></div>
      <div class="notify-message" v-if="message.length > 0" v-html="notifyMessageHTML"></div>
      <div class="finish-controlls">
        <button type="button" class="start-btn" @click="startNarrativeBook">ログイン</button>
        <button type="button" class="finish-btn" @click="finishNarrativeBook">ログインせずに終了</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusCreateUserFinish',

  props: {
    userName: String,
    message: String,
    notifyMessage: String
  },

  computed: {
    messageHTML () {
      if (this.message) {
        return this.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    notifyMessageHTML () {
      if (this.notifyMessage) {
        return this.notifyMessage.replace(/\n/gi, '<br />')
      }
      return ''
    }
  },

  methods: {
    startNarrativeBook () {
      this.$emit('startNarrativeBookEvent')
    },

    finishNarrativeBook () {
      this.$emit('finishNarrativeBookEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-user-finish {
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .finish-content {
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
    text-align: center;
    font-size: 1.2em;
    .message {
      margin: 10px auto;
      padding: 10px;
      width: 400px;
      border: 1px solid #ddd;
      text-align: left;
    }
    .notify-message {
      margin: 10px auto;
      padding: 10px;
      width: 400px;
      border: 1px solid #b94047;
      text-align: left;
    }
    .finish-controlls {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
      button {
        margin: 20px 0;
        padding: 3px 10px;
        border-radius: 5px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        &.start-btn {
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
        }
        &.finish-btn {
          border: 1px solid #ee7800;
          background-color: #ee7800;
        }
      }
    }
  }
}
</style>
