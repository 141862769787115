<template>
  <div class="horus-family-invitation-create-modal" v-show="showCreateModal">
    <horus-modal-frame
      :showModalFlag="showCreateModal">
      <div class="modal-header">
        <h1><img src="/static/images/family.png" class="title-icon" />依頼状の作成</h1>
      </div>
      <div class="modal-body">
        <div class="request-message">
          ナラティブブックでは「家族」の設定ができます。<br />
          家族設定を行うと、相手の方があなたに関する情報の閲覧や書込みができるようになります。<br />
        </div>
        <div class="create-tab-labels">
          <div class="tab-label" :class="{active: createRequestModeNBID}" @click="requestModeToNBID">NBIDで作成</div>
          <div class="tab-label" :class="{active: !createRequestModeNBID}" @click="requestModeToMail">メールアドレスで作成</div>
        </div>
        <div class="create-by-nbid" v-if="createRequestModeNBID">
          <div class="notify_for_admit">
            <h1>家族設定をする方のNBIDがわかっている場合</h1>
            ステップ１：家族設定をする方の ”NBID(ナラティブブックID)” を下記フォームに入力し［送信］します<br />
            ステップ２：相手の方に案内メールが届きますので、メールに記載されたURLにアクセスして承認作業を行います。<br />
          </div>
          <horus-attribute-in-form
            :labelText="'NBID'"
            :necessary="true"
            :validationState="requestNBIDValidation.state"
            :validationMessage="requestNBIDValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetNBID" />
          </horus-attribute-in-form>
        </div>
        <div class="create-by-mail" v-if="!createRequestModeNBID">
          <div class="notify_for_admit">
            <h1>家族設定をする方のNBIDがわからない、もしくはNBIDを持っていない場合</h1>
            ステップ１：家族設定をする方の”メールアドレス”と”お名前”を下記フォームに入力し［送信］します。<br />
            ステップ２：相手の方に数字4桁の本人確認コードを知らせます。<br />
            ステップ３：相手の方に案内メールが届きますので、メールに記載されたURLにアクセスし、本人確認コードを入力して承認作業を行います。<br />
          </div>
          <horus-attribute-in-form
            :labelText="'メールアドレス'"
            :necessary="true"
            :validationState="requestMailValidation.state"
            :validationMessage="requestMailValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMail" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'メールアドレス(確認)'"
            :necessary="true"
            :validationState="requestMailConfirmationValidation.state"
            :validationMessage="requestMailConfirmationValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMailConfirmation" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'相手の名前'"
            :necessary="true"
            :validationState="requestNameValidation.state"
            :validationMessage="requestNameValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetName" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'本人確認コード'"
            :necessary="false"
            :validationState="requestShortTokenValidation.state"
            :validationMessage="requestShortTokenValidation.message"
            :attributeNotify="'4桁の数字(半角)'">
            <input type="text" v-model="requestShortToken" />
          </horus-attribute-in-form>
          <div class="notify-for-invitation-by-mail">
            案内で送信されるメールには、登録処理のためのURLは記載されますが、プロセスを進めるための本人確認コードは記載されません。<br/>
            本人確認コードは他の手段で利用者に伝える必要があります。<br/>
            必ず何らかの手段で本人確認コードを伝えてください。
          </div>
        </div>
        <horus-attribute-in-form
          :labelText="'メッセージ'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <textarea rows="3" cols="20" v-model="requestMessage"></textarea>
        </horus-attribute-in-form>
        <div class="notify_for_admit">
          <h1>家族登録における同意事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>円滑なコミュニケーションのために、ナラティブブックで家族設定することを相手の方から同意されていること</li>
            <li>なりすまし防止のために、依頼先の方との確認ができていること</li>
            <li>家族に登録すると家族となった利用者は、あなたのタイムラインや特記事項の閲覧や書き込みができるようになります（詳しくは利用規約の第２条及び第７条をご確認ください）</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendRequest">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !requestReady }" @click="submitSendRequest">案内状の送信</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusUserInvitationValidation from '@/mixin/HorusUserInvitationValidation.js'

export default {
  mixins: [HorusUserInvitationValidation],

  name: 'HorusFamilyInvitationCreateModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox,
    HorusAttributeInForm
  },

  props: {
    timelineOwner: Object,
    showCreateModal: Boolean,
    families: Array,
    sentRequests: Array,
    receiveRequests: Array,
    reservedNBID: String
  },

  data () {
    return {
      createRequestModeNBID: true,
      requestTargetNBID: '',
      requestTargetMail: '',
      requestTargetMailConfirmation: '',
      requestTargetName: '',
      requestShortToken: '0000',
      requestMessage: 'ナラティブブックで、あなたと家族になりたくて、ご連絡差し上げました。\n宜しくお願いいたします。',
      admitAgreement: false
    }
  },

  watch: {
    requestTargetNBID: { handler: 'checkValidNBID' },
    showCreateModal: { handler: 'initReserved', immediate: true }
  },

  computed: {
    requestNBIDValidation () {
      if (this.requestTargetNBID.length > 0) {
        if (this.families.some(fam => ((fam.relation === 1 || fam.relation === 2) && fam.nbid === this.requestTargetNBID))) {
          return { state: 'invalid', message: '指定したNBIDの利用者は、すでにあなたのタイムラインを見られる家族です。' }
        } else if (this.sentRequests.some(req => req.login_id === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定したNBIDには、すでに案内を送信済です。' }
        } else if (this.receiveRequests.some(req => req.nbid === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定したNBIDの利用者は、すでにあなたのタイムラインを見られる家族です。' }
        } else {
          return { state: 'valid', message: '' }
        }
      } else {
        return { state: 'invalid', message: 'NBIDは入力必須です' }
      }
    }
  },

  methods: {
    checkValidNBID () {
      if (this.requestNBIDState === 'valid') {
        this.targetNBIDValid = true
      } else {
        this.targetNBIDValid = false
      }
    },

    initReserved () {
      if (this.showCreateModal) {
        this.initParams()
      }
    },

    initParams () {
      this.createRequestModeNBID = true
      this.targetNBIDValid = false
      if (typeof this.reservedNBID === 'string') {
        this.requestTargetNBID = this.reservedNBID
      } else {
        this.requestTargetNBID = ''
      }
      this.requestTargetMail = ''
      this.requestTargetMailConfirmation = ''
      this.requestTargetName = ''
      this.requestShortToken = '0000'
      this.requestMessage = 'ナラティブブックで、あなたと家族になりたくて、ご連絡差し上げました。\n宜しくお願いいたします。'
      this.admitAgreement = false
    },

    cancelSendRequest () {
      this.initParams()
      this.$emit('calcelSendEvent')
    },

    submitSendRequest () {
      if (this.requestReady) {
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        if (this.requestTargetNBID) {
          formData.append('authorized_family_invitation[login_id]', this.requestTargetNBID)
        } else if (this.requestTargetMail) {
          formData.append('authorized_family_invitation[name]', this.requestTargetName)
          formData.append('authorized_family_invitation[mail]', this.requestTargetMail)
          formData.append('authorized_family_invitation[short_token]', this.requestShortToken)
        }
        formData.append('authorized_family_invitation[message_content]', this.requestMessage)
        var sendTarget = this.requestTargetNBID
        if (!this.requestTargetNBID) {
          sendTarget = this.requestTargetName
        }
        this.$emit('requestSubmitEvent', formData, sendTarget, this.initParams)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/createWithAgreementForm.scss';

.horus-family-invitation-create-modal {
  .modal-body {
    padding: 10px 20px;
    .request-message {
      margin-bottom: 25px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
    }
    .create-tab-labels {
      margin: 0;
      display: flex;
      align-items: flex-end;
      .tab-label {
        margin: 0;
        padding: 3px 10px;
        border: 1px solid #666;
        border-bottom: none;
        background-color: #ddd;
        color: #666;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        &.active {
          background-color: #fff;
          color: #666;
        }
      }
    }
    .create-by-nbid {
      border: 1px solid #666;
      padding: 10px;
    }
    .create-by-mail {
      border: 1px solid #666;
      padding: 10px;
      .notify-for-invitation-by-mail {
        border: 1px solid #c53d43;
        padding: 5px;
      }
    }
    input[type='text'] {
      font-size: 16px;
    }
    textarea {
      width: 100%;
      font-size: 16px;
    }
    .admit-agreement {
      cursor: pointer;
      margin: 10px;
      font-weight: bold;
    }
    .submit-controll {
      margin: 10px;
      text-align: center;
      button {
        cursor: pointer;
        padding: 2px 20px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        &.disable {
          cursor: normal;
          background-color: #aaa;
        }
        &.cancel-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>
