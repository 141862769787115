<template>
  <div class="horus-system-organization-manager-invitation-modal">
    <horus-modal-frame
      :showModalFlag="showOrganizationManagerInvitationModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />施設管理者案内送信</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="validateMail"
          :validationMessage="validateMailMessage"
          :attributeNotify="''">
          <input type="text" v-model="mail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'宛先名'"
          :necessary="true"
          :validationState="validateName"
          :validationMessage="validateNameMessage"
          :attributeNotify="'送信したメールに記載される相手の名前です'">
          <input type="text" v-model="name" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">送 信</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemOrganizatonManagerInvitationModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm
  },

  props: {
    organization: Object,
    showOrganizationManagerInvitationModal: Boolean
  },

  data () {
    return {
      name: '',
      mail: ''
    }
  },

  computed: {
    validateName () {
      if (this.stringLengthCheck(this.name, 127, '宛先名').valid) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateNameMessage () {
      return this.stringLengthCheck(this.name, 127, '宛先名').message
    },

    validateMail () {
      if (this.stringLengthCheck(this.mail.length, 127, 'メールアドレス').valid) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateMailMessage () {
      return this.stringLengthCheck(this.mail.length, 127, 'メールアドレス').message
    },

    allValid () {
      if (
        this.stringLengthCheck(this.name, 127, '宛先名').valid &&
        this.stringLengthCheck(this.mail.length, 127, 'メールアドレス').valid
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initData () {
      this.name = ''
      this.mail = ''
    },

    stringLengthCheck (value, length, valueName) {
      var valid = false
      var message = ''
      if (!value || value.length <= 0) {
        message = valueName + 'は入力必須です。'
      } else if (value.length > length) {
        message = valueName + 'は' + length + '文字以下で入力ください。'
      } else {
        valid = true
      }
      return { valid: valid, message: message }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', () => { this.initData() })
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('organization_id', this.organization.id)
        formData.append('organization_manager_invitation_token[name]', this.name)
        formData.append('organization_manager_invitation_token[mail]', this.mail)
        this.$emit('submitEvent', formData, () => { this.initData() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-system-organization-manager-invitation-modal {
  .modal {
    width: 400px;
    left: calc((100vw - 400px) / 2);
  }
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
