<template>
  <div class="horus-select-timeline-owner-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/task_pad_icon_s.png" class="title-icon" />予定登録先利用者選択</h1>
      </div>
      <div class="modal-body">
        <div v-if="signinMember">
          <h2>施設に予定を入れる</h2>
          <div class="target-organization">
            <button class="target-organization-btn" @click="select(null)">施設の予定を作成</button>
          </div>
        </div>
        <h2>予定を入れるタイムラインを選択</h2>
        <div class="timeline-owner-list">
          <div class="timeline-owner-column" v-for="timelineOwner in timelineOwners" :key="'usrTaskTLO' + timelineOwner.user_id">
            <img :src="timelineOwner.author.portrateSrc" @click="select(timelineOwner)"/>
            <div class="name-column" @click="select(timelineOwner)">{{ timelineOwner.author.name }}({{ timelineOwner.author.age }})</div>
          </div>
        </div>
        <div class="controll">
          <button class="cancel-btn" @click="cancelSelect">キャンセル</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSelectTimelineOwnerModal',

  components: {
    HorusModalFrame
  },

  props: {
    signinUser: Object,
    signinMember: Object,
    timelineOwners: Array,
    showModal: Boolean
  },

  methods: {
    select (timelineOwner) {
      if (timelineOwner) {
        this.$emit('timelineOwnerSelectedEvent', timelineOwner.user_id)
      } else {
        this.$emit('timelineOwnerSelectedEvent', null)
      }
    },

    cancelSelect () {
      this.$emit('timelineOwnerSelectCancelEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/_basicInputStyle.scss';

.horus-select-timeline-owner-modal {
  .modal-body {
    padding: 10px;
    @include mediaQuery('phone') {
      padding: 5px;
    }
    h2 {
      margin: 0 10px;
      font-size: 1.1em;
      color: #666;
    }
    .target-organization {
      margin: 5px;
      padding: 5px;
      .target-organization-btn {
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 1.1em;
        font-weight: bold;
        color: #fff;
        background-color: $nb-member-color;
      }
    }
    .timeline-owner-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .timeline-owner-column {
        display: flex;
        margin: 5px;
        padding: 5px;
        width: 30%;
        border: 1px solid #aaa;
        img {
          height: 40px;
          width: 40px;
        }
        .name-column {
          margin-left: 5px;
          font-size: 1.1em;
          font-weight: bold;
        }
        @include mediaQuery('phone') {
          width: calc(50% - 25px);
        }
      }
    }
    .controll {
      text-align: right;
      .cancel-btn {
          width: 100px;
          margin-right: 10px;
          padding: 3px 10px;
          font-weight: bold;
          font-size: 14px;
          border-radius: 3px;
          background-color: #fff;
          color: #333;
          border: 1px solid #999;
      }
    }
  }
}
</style>
