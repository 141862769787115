<template>
  <div class="horus-manage-reporter-organization-area-block-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />{{ reporterOrganization.name }}報告施設所属管理団体変更</h1>
      </div>
      <div class="modal-body">
        <div class="select-target-area-block">
          <div class="selection-wrapper" @click="beIndipendent">
            <horus-custom-check-box
              :active="isIndipendent"
              :enabled="true"
              :type="'color'"
              />
          </div>
          <div class="area-block-info">
            <div class="list-line">
              どの管理団体にも所属しない
            </div>
          </div>
        </div>
        <div class="select-target-area-block" v-for="areaBlock in areaBlocks" :key="'selOAB' + areaBlock.id">
          <div class="selection-wrapper" @click="dependTo(areaBlock)">
            <horus-custom-check-box
              :active="depended(areaBlock)"
              :enabled="true"
              :type="'color'"
              />
          </div>
          <div class="area-block-info">
            <div class="list-line">
              <div class="name"><span>団体名(カナ):</span>{{ areaBlock.name }}({{ areaBlock.name_kana }})</div>
              <div class="short-name"><span>略名(カナ):</span>{{ areaBlock.short_name }}({{ areaBlock.short_name_kana }})</div>
            </div>
            <div class="list-line">
              <div class="zip-code">{{ areaBlock.zip_code }}</div>
              <div class="address">{{ areaBlock.address }}</div>
              <div class="tel">tel:{{ areaBlock.tel }}</div>
              <div class="mail">mail: {{ areaBlock.mail }}</div>
            </div>
          </div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="close-btn" @click="closeModal">閉じる</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  ReporterOrganization
} from '@/models'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusManageOrganizationAreaBlockModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    sourceReporterOrganization: Object,
    areaBlocks: Array,
    showModal: Boolean
  },

  data () {
    return {
      reporterOrganization: {}
    }
  },

  computed: {
    isIndipendent () {
      if (this.reporterOrganization.area_block_id) {
        return false
      }
      return true
    }
  },

  watch: {
    sourceReporterOrganization: { handler: 'initData', immediate: true }
  },

  methods: {
    initData () {
      this.reporterOrganization = Object.assign(this.sourceReporterOrganization)
    },

    depended (areaBlock) {
      if (this.reporterOrganization.area_block_id === areaBlock.id) {
        return true
      }
      return false
    },

    beIndipendent () {
      ReporterOrganization.api().updateAreaBlock({ reporterOrganizationID: this.reporterOrganization.id, areaBlockID: 'none' }).then(res => {
        console.log(res)
        this.reporterOrganization.area_block_id = null
      }).catch(err => {
        console.error(err)
      })
    },

    dependTo (areaBlock) {
      ReporterOrganization.api().updateAreaBlock({ reporterOrganizationID: this.reporterOrganization.id, areaBlockID: areaBlock.id }).then(res => {
        console.log(res)
        this.reporterOrganization.area_block_id = areaBlock.id
      }).catch(err => {
        console.error(err)
      })
    },

    closeModal () {
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-manage-reporter-organization-area-block-modal {
  .modal-body {
    padding: 10px 20px;
    .select-target-area-block {
      display: flex;
      margin: 10px 0;
      padding: 5px 0;
      border-bottom: 1px solid #aaa;
    }
    .area-block-info {
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            width: auto;
            font-weight: bold;
          }
          &.short-name {
            // width: 50%;
            font-weight: bold;
          }
          &.zip-code {
            width: auto;
          }
          &.address {
            width: auto;
          }
          &.tel {
            width: auto;
          }
          &.mail {
            width: auto;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.close-btn {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
</style>
