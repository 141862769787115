import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import UserTask from './UserTask'

export default class ZoomMeeting extends Model {
  static entity = 'zoomMeetings'

  static fields () {
    return {
      id: this.attr(null),
      zoom_meeting_id: this.string(''),
      zoom_host_id: this.string(''),
      topic: this.string(''),
      join_url: this.string(''),
      start_url: this.string(''),
      start_time: this.attr(null),
      password: this.string(''),
      meeting_options: this.attr(null),
      zoom_user_id: this.attr(null),
      user_task_id: this.attr(null),
      main_attendee_id: this.attr(null),
      attendees: this.attr(null)
    }
  }

  get startTime () {
    return new Date(this.start_time)
  }

  get userTask () {
    return UserTask.find(this.user_task_id)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static apiConfig = {
    actions: {
      fetchByTask ({ taskID }) {
        return this.request({
          method: 'get',
          url: 'zoom_meeting/show_by_task',
          withCredentials: true,
          params: { task_id: taskID },
          dataKey: 'data.zoom_meeting'
        })
        .then(res => {
          ZoomMeeting.commit((state) => [
            state.fetchedAt = new Date()
          ])
          return res.entries
        })
      },
    
      async create ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'zoom_meeting',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async update ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'zoom_meeting',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy ({meetingID}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
              method: 'delete',
              url: 'zoom_meeting',
              withCredentials: true,
              params: { zoom_meeting_id: meetingID },
              save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }
    }
  }
}
