import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'

export default class VitalDisplayConfig extends Model {
  static entity = 'vitalDisplayConfig'

  static fields () {
    return {
      id: this.attr(null),
      timelineOnwerID: this.attr(null),
      bloodPressure: this.number(1),
      bloodPressureUnReliable: this.number(0),
      bodyComposition: this.number(2),
      bodyCompositionWeight: this.number(1),
      bodyCompositionBodyFatRatio: this.number(0),
      bodyCompositionBaseMetabolism: this.number(0),
      bodyCompositionBodySkeltalMuscleRatio: this.number(0),
      bodyCompositionBMI: this.number(0),
      bodyCompositionBodyAge: this.number(0),
      bodyCompositionViceralFatLevel: this.number(0),
      activeMass: this.number(3),
      activeMassStepCount: this.number(1),
      activeMassFastWalkStepCount: this.number(0),
      activeMassStairwayStepCount: this.number(0),
      activeMassUsedCaloriesInActive: this.number(0),
      activeMassTotalUsedCalories: this.number(0),
      activeMassMovingDistance: this.number(0),
      activeMassBurningBodyFat: this.number(0),
      bodyTemperature: this.number(4),
      oxygenSaturation: this.number(5),
      pulseRate: this.number(6)
    }
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static apiConfig = {
    actions: {
      fetch ({targetUserID}) {
        return this.request({
          method: 'get',
          url: 'vital_display_configure',
          withCredentials: true,
          params: { target_user_id: targetUserID },
          dataKey: 'vital_display_configure'
        }).then(res => {
          console.log(res)
          if (res.response.data.success === true) {
            VitalDisplayConfig.commit((state) => {
              state.fetchedAt = new Date()
            })
          }
        }).catch(error => { throw error })
      },
    
      async update ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'vital_display_configure',
            headers: {
              'X-HTTP-Method-Override': 'PUT',
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
            save: false
          }
        }).then(res => {
          return res.data
        }).catch(error => { throw error })
      }
  
    }
  }
}
