<template>
  <div class="horus-sent-deputize-request-for-agent-column labeled_content_block">
    <div class="labeled_content_single_block" v-if="sentRequest.login_id">
      <label>NBID</label>
      <div class="content">{{ sentRequest.login_id }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentRequest.mail">
      <label>メールアドレス(宛先名)</label>
      <div class="content">{{ sentRequest.mail }}({{ sentRequest.name }})</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentRequest.mail">
      <label>本人確認コード</label>
      <div class="content">{{ sentRequest.short_token }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ sentRequest.status }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>メッセージ</label>
      <div class="content" v-html="messageContent"></div>
    </div>
    <div class="content-controll-block">
      <button type="button" @click="sendMailRequest" v-if="!sentRequest.used">再送信</button>
      <button type="button" @click="removeRequest" v-if="!sentRequest.used">申請取り消し</button>
      <button type="button" @click="removeRequest" v-if="sentRequest.used">このリストから削除</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusSentDeputizeRequestForAgentColumn',

  props: {
    sentRequest: Object
  },

  computed: {
    messageContent () {
      if (this.sentRequest.message_content && this.sentRequest.message_content.length > 0) {
        return this.sentRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return '代理依頼申請のメッセージはありません。'
      }
    }
  },

  methods: {
    sendMailRequest () {
      this.$emit('sendMailRequestEvent', this.sentRequest)
    },

    removeRequest () {
      this.$emit('removeRequestEvent', this.sentRequest)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
