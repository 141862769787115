<template>
  <div class="horus-reset-area-block-operator-password-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">パスワードの再発行</h1>
      <div class="message-column">
        パスワードの再発行を受付しました。<br/>
        下記フォームにパスワードを入力すると、パスワードの更新が行えます。
      </div>
      <div class="signin-block">
        <horus-attribute-in-form
          :labelText="'パスワード'"
          :necessary="true"
          :validationState="validatePassword.state"
          :validationMessage="validatePassword.message"
          :attributeNotify="'8文字以上'">
          <input :type="passwordInputType" v-model="password" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'パスワード(確認)'"
          :necessary="true"
          :validationState="validatePasswordConf.state"
          :validationMessage="validatePasswordConf.message"
          :attributeNotify="'上と同じパスワードを入力'">
          <input :type="passwordInputType" v-model="passwordConfirmation" />
        </horus-attribute-in-form>
        <div class="toggle-password-visible" @click="togglePasswordVisible">
          <horus-custom-check-box
            :active="passwordVisible"
            :enabled="true"
            :type="'color'"
            />パスワードを表示
        </div>
        <button class="submit-btn" :class="{disable: !allValidated}" @click="changePassword">パスワード変更</button>
      </div>
    </div>
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { AreaBlockOperator } from '@/models'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import AuthenticateInfo from '@/validateModel/AuthenticateInfo.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusResetAreaBlockOperatorPasswordView',

  components: {
    HorusAttributeInForm,
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      password: '',
      passwordConfirmation: '',
      passwordVisible: false
    }
  },

  computed: {
    validatePassword () {
      if (this.password.length <= 0) {
        return { state: 'disabled', valid: false, message: '' }
      } else if (AuthenticateInfo.validatePasswordFormat(this.password)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'パスワードは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。' }
      }
    },

    validatePasswordConf () {
      if (this.passwordConfirmation.length <= 0) {
        return { state: 'disabled', valid: false, message: '' }
      } else if (this.password.trim() === this.passwordConfirmation) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '上で入力したパスワードと同じ値を入れてください。' }
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    allValidated () {
      if (this.validatePassword.valid && this.validatePasswordConf.valid) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  methods: {
    initToken () {
      this.token = this.$attrs.token
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    toggleExpireTokens () {
      this.expireAppTokens = !this.expireAppTokens
    },

    changePassword () {
      if (this.allValidated) {
        var formData = new FormData()
        formData.append('token', this.token)
        formData.append('area_block_operator_authentication[password]', this.password)
        formData.append('area_block_operator_authentication[password_confirmation]', this.passwordConfirmation)
        var alartMessage = 'パスワードの変更を完了しました。'
        AreaBlockOperator.api().resetPassword({formData: formData}).then(res => {
          console.log(res)
          this.showConfirmDialog('パスワードの変更完了', alartMessage, DIALOG_TYPE.MESSAGE, () => {
            this.$router.push('/system_management/operator_home')
          })
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('パスワードの変更失敗', 'パスワードの変更に失敗しました。\nURLが正しくないか、パスワードリセットが使用済みか、期限切れの可能性があります。\nパスワードリセットのメールの利用は1度きりで、メール送信から1時間以内に使用する必要があります。')
        })
      }
    },

    autoSignin () {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-reset-area-block-operator-password-view {
  background-color: #eee;
  .navbar {
    .main-bar {
      background-color: #066292;
      background-image: url("/static/images/operator_header.png");
      .signout-btn {
        border: 1px solid #bbc8e6;
        border-radius: 3px;
        background-color: #bbc8e6;
        color: #039;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
  }
  h1.centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .message-column {
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .signin-block {
    margin: 20px auto;
    padding: 40px;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #aaa;
    text-align: center;
    @include mediaQuery('phone') {
      padding: 20px;
    }
    input {
      font-size: 16px;
      border: 1px solid #aaa;
    }
    button {
      margin-top: 20px;
      padding: 3px 10px;
      border: 1px solid #4cae4c;
      border-radius: 5px;
      background-color: #4cae4c;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      &.disable {
        border-color: #aaa;
        background-color: #aaa;
        color: #ddd;
      }
    }
    .expire-tokens {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
      .expire-tokens-notify {
        margin-bottom: 10px;
        padding: 5px;
        border: 1px solid #c53d43;
        text-align: left;
        font-size: 0.9em;
      }
    }
  }
}
</style>
