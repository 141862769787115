<template>
  <div class="horus-user-mail-edit-modal">
    <horus-modal-frame
      :showModalFlag="showUserMailEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />メールアドレス変更</h1>
      </div>
      <div class="modal-body">
        <div class="caption">
          メールアドレスの変更には、メールの疎通確認が必要です。<br/>
          変更ボタンをクリックすると、入力されたメールアドレス宛に変更確認のメールが送信されます。<br/>
          受信したメールのリンクをクリックして、表示されたページでNBIDとパスワード入力してください。<br/>
          確認ページでのログインが完了するまで、メールアドレスは変更されません。
        </div>
        <horus-attribute-in-form
          :labelText="'新しいメールアドレス'"
          :necessary="true"
          :validationState="validateMail"
          :validationMessage="validateMailMessage"
          :attributeNotify="''">
          <input type="text" v-model="mail" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: validateMail !== 'valid'}" @click="submit">変 更</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusUserMailEditModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm
  },

  props: {
    showUserMailEditModal: Boolean
  },

  data () {
    return {
      mail: ''
    }
  },

  computed: {
    validateMail () {
      if (this.mail.length > 0) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateMailMessage () {
      if (this.mail.length > 0) {
        return ''
      } else {
        return 'メールアドレスは入力必須です。'
      }
    }
  },

  methods: {
    cancelSubmit () {
      console.log('cancel in modal')
      this.$emit('cancelSubmitEvent', () => { this.mail = '' })
    },

    submit () {
      var formData = new FormData()
      formData.append('mail', this.mail)
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-user-mail-edit-modal {
  .modal-body {
    padding: 10px 20px;
    .caption {
      margin: 10px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 12px;
    }
    input {
      font-size: 16px;
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
