import { Model } from '@vuex-orm/core'
import Wish from './Wish'

export default class WishFile extends Model {
  static entity = 'wishFiles'

  static fields () {
    return {
      id: this.attr(null),
      wish_id: this.attr(null),
      filename: this.string(''),
      size: this.number(0),
      filenameOriginal: this.string('')
    }
  }

  get fileURL () {
    const wish = Wish.find(this.wish_id)
    return process.env.VUE_APP_BASE_URL + '/wish/show_file?target_user_id=' + wish.user_id + '&wish_file_id=' + this.id
  }
}
