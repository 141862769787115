<template>
  <div class="horus-confirm-deputizing-request-to-user-modal">
    <horus-modal-frame
      :showModalFlag="showConfirmRequestModal">
      <div class="modal-header">
        <h1><img src="/static/images/org_member.png" class="title-icon" />案内の承認/拒否</h1>
      </div>
      <div class="modal-body" v-if="request">
        <h4 class="labeled_content_block_title">設定代行案内の送信元情報</h4>
        <div class="org-notify" v-if="request.organization && request.organization.revoke_requested">
          現在この施設は退会準備中です。「承認」を行うことはできません。
        </div>
        <div class="org-info" v-if="request.organization">
          <div class="labeled_content_single_block">
            <label>施設名称</label>
            <div class="content">{{ request.organization.name }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>ヨミガナ</label>
            <div class="content">{{ request.organization.nameKana }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>略称</label>
            <div class="content">{{ request.organization.shortName }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>郵便番号</label>
            <div class="content">{{ request.organization.zipCode }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>住所</label>
            <div class="content">{{ request.organization.address }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>電話番号</label>
            <div class="content">{{ request.organization.tel }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>メッセージ</label>
            <div class="content" v-html="messageContent"></div>
          </div>
        </div><!-- org info end -->
        <div class="agent-info" v-if="request.agentUser">
          <div class="labeled_content_single_block">
            <label>名前</label>
            <div class="content">{{ request.agentUser.name }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>ヨミガナ</label>
            <div class="content">{{ request.agentUser.nameKana }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>郵便番号</label>
            <div class="content">{{ request.agentUser.zipCode }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>住所</label>
            <div class="content">{{ request.agentUser.address }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>電話番号</label>
            <div class="content">{{ request.agentUser.tel }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>メッセージ</label>
            <div class="content" v-html="messageContent"></div>
          </div>
        </div>
        <div class="notify_for_admit" v-if="request.organization">
          <h1>設定代行施設の登録における確認事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>
              設定代行施設への委任する機能は下記の通りであること
              <ol>
                <li>本システムの利用者として、利用申込の申請並びに利用停止申請及び利用者登録の抹消申請を行うこと。</li>
                <li>利用者本人の利用者情報の閲覧及び編集を行うこと。</li>
                <li>家族申請、他の利用者からの家族申請の承認及び家族の削除申請を行うこと。</li>
                <li>施設の設定及び停止を行うこと。</li>
                <li>利用者本人が利用者情報の閲覧及び編集をできるようにすること。</li>
                <li>本書面の委任事項を他の施設または利用者に再委任すること（但し、再委任先への委任状の作成は利用者本人による）。</li>
              </ol>
            </li>
            <li>依頼元施設に提出する委任状の委任事項について同意していること</li>
            <li>依頼元施設に委任状を提出していること</li>
            <li>ナラティブブックであなたの情報を共有してほしい家族や施設を追加したいときは、設定代行施設に連絡すること</li>
          </ul>
        </div>
        <div class="notify_for_admit" v-if="request.agentUser">
          <h1>代理者の登録における確認事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>
              代理者への委任する機能は下記の通りであること
              <ol>
                <li>本システムの利用者として、利用申込の申請並びに利用停止申請及び利用者登録の抹消申請を行うこと。</li>
                <li>利用者本人の利用者情報の閲覧及び編集を行うこと。</li>
                <li>家族申請、他の利用者からの家族申請の承認及び家族の削除申請を行うこと。</li>
                <li>施設の設定及び停止を行うこと。</li>
                <li>利用者本人が利用者情報の閲覧及び編集をできるようにすること。</li>
                <li>本書面の委任事項を他の施設または利用者に再委任すること（但し、再委任先への委任状の作成は利用者本人による）。</li>
              </ol>
            </li>
            <li>ナラティブブックであなたの情報を共有してほしい家族や施設を追加したいときは、代理者に連絡すること</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="submit_notify_description">
          設定代行を承認するには、「承認」ボタンを、拒否する場合は「拒否」ボタンをクリックしてください。<br />
          <div class="controlls">
            <button class="submit-btn" type="button" :class="{disable: !readyToAdmit}" @click="admit">承認</button>
            <button class="reject-btn" type="button" @click="reject">拒否</button>
            <button class="cancel-btn" type="button" @click="cancel">保留</button>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusConfirmDeputizingRequestToUserModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    request: Object,
    showConfirmRequestModal: Boolean
  },

  data () {
    return {
      admitAgreement: false
    }
  },

  computed: {
    messageContent () {
      if (this.request.message_content && this.request.message_content.length > 0) {
        return this.request.message_content.replace(/\n/gi, '<br />')
      } else {
        return '設定代行案内のメッセージはありません。'
      }
    },

    readyToAdmit () {
      if (this.admitAgreement && (this.request.agentUser || (this.request.organization && !this.request.organization.revoke_requested))) {
        return true
      }
      return false
    }
  },

  methods: {
    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    admit () {
      if (this.readyToAdmit) {
        this.$emit('admitRequestEvent', this.request)
        this.admitAgreement = false
      }
    },

    reject () {
      this.admitAgreement = false
      this.$emit('rejectRequestEvent', this.request)
    },

    cancel () {
      this.$emit('cencelConfirmEvent')
      this.admitAgreement = false
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-confirm-deputizing-request-to-user-modal {
  .modal-body {
    padding: 10px;
    .org-notify {
      padding: 3px;
      border: 1px solid #c43d53;
      color: #c43d53;
    }
    .labeled_content_block_title {
      margin: 10px 0 5px 0;
      font-weight: bold;
      color: #666;
    }
    .labeled_content_block {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #aaa;
    }
    .labeled_content_single_block {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      min-width: 300px;
      margin: 5px;
      margin-right: 10px;
      label {
        margin: 0;
        margin-right: 5px;
        width: 100px;
        text-align: right;
        font-weight: normal;
        font-size: 0.9em;
        color: #666;
      }
      .content {
        display: inline-block;
        font-size: 1.1em;
        img {
          width: 20px;
          margin-right: 5px;
        }
      }
    }
    .notify_for_admit {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #666;
      }
    }
    .admit-agreement {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .submit_notify_description {
      margin: 10px;
      padding: 10px;
      border: 1px solid #aaa;
      .controlls {
        display: flex;
        justify-content: space-around;
        margin: 10px;
        button {
          width: 150px;
          border: 1px solid #ddd;
          border-radius: 3px;
          background-color: #ddd;
          color: #fff;
          font-weight: bold;
          font-size: 13px;
          &.submit-btn {
            border-color: #5cb85b;
            background-color: #5cb85b;
            &.disable {
              border-color: #93b881;
              background-color: #93b881;
            }
          }
          &.reject-btn {
            border-color: #c43d53;
            background-color: #c43d53;
          }
          &.cancel-btn {
            border-color: #f08300;
            background-color: #f08300;
          }
        }
      }
    }
  }
}
</style>
