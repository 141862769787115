<template>
  <div class="content-line active-mass">
    <div class="vital-value-column">
      <span class="label">歩数</span>
      <span class="value">{{ vitalActiveMass.step_count }}</span>
      <span class="unit">歩</span>
    </div>
    <div class="vital-value-column">
      <span class="label">早歩き歩数</span>
      <span class="value">{{ vitalActiveMass.fast_walk_step_count }}</span>
      <span class="unit">歩</span>
    </div>
    <div class="vital-value-column">
      <span class="label">上り階段歩数</span>
      <span class="value">{{ vitalActiveMass.stairway_step_count }}</span>
      <span class="unit">歩</span>
    </div>
    <div class="vital-value-column">
      <span class="label">活動消費カロリー</span>
      <span class="value">{{ vitalActiveMass.used_calories_in_active }}</span>
      <span class="unit">kcal</span>
    </div>
    <div class="vital-value-column">
      <span class="label">総消費カロリー</span>
      <span class="value">{{ vitalActiveMass.total_used_calories }}</span>
      <span class="unit">kcal</span>
    </div>
    <div class="vital-value-column">
      <span class="label">移動距離</span>
      <span class="value">{{ vitalActiveMass.moving_distance }}</span>
      <span class="unit">km</span>
    </div>
    <div class="vital-value-column">
      <span class="label">脂肪燃焼量</span>
      <span class="value">{{ vitalActiveMass.burning_body_fat }}</span>
      <span class="unit">g</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusVitalActiveMassColumn',

  props: {
    vitalActiveMass: Object
  }
}
</script>

<style lang="scss" scoped>
.content-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  padding-top: 5px;
  .vital-value-column {
    display: flex;
    padding-left: 20px;
    .label {
      align-self: flex-start;
      margin-right: 5px;
      font-size: 0.9em;
    }
    .value {
      align-self: center;
      font-size: 1.5em;
      font-weight: bold;
      color: #666;
    }
    .unit {
      align-self: flex-end;
      font-size: 0.9em;
    }
  }
}
</style>
