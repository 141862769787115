<template>
  <div class="horus-cofirm-reporter-request-for-user-modal">
    <horus-modal-frame
      :showModalFlag="showConfirmRequestModal">
      <div class="modal-header">
        <h1><img src="/static/images/org_member.png" class="title-icon" />サービス利用案内の承認/拒否</h1>
      </div>
      <div class="modal-body" v-if="request">
        <h4 class="labeled_content_block_title">サービス利用案内の送信元施設情報</h4>
        <div class="org-notify" v-if="request.reporter_organization.revoke_requested">
          現在この施設は退会準備中です。「承認」を行うことはできません。
        </div>
        <div class="org-info">
          <div class="labeled_content_single_block">
            <label>施設名称</label>
            <div class="content">{{ request.reporter_organization.name }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>ヨミガナ</label>
            <div class="content">{{ request.reporter_organization.nameKana }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>略称</label>
            <div class="content">{{ request.reporter_organization.shortName }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>郵便番号</label>
            <div class="content">{{ request.reporter_organization.zipCode }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>住所</label>
            <div class="content">{{ request.reporter_organization.address }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>電話番号</label>
            <div class="content">{{ request.reporter_organization.tel }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>メッセージ</label>
            <div class="content" v-html="messageContent"></div>
          </div>
        </div><!-- org info end -->
        <div class="notify_for_admit">
          <h1>かかりつけ施設の登録における確認事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>報告サービス利用の登録することをあなたが同意していること</li>
            <li>報告サービス利用の登録すると利用サービスとなった施設の職員は、あなたのタイムラインの書き込みができるようになります（詳しくは利用規約の第２条及び第８条をご確認ください）</li>
            <li>報告サービス施設の職員があなたのタイムラインに書き込む情報の内容について、説明を受け同意した上でサービス利用設定を行ってください</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="submit_notify_description">
          サービス利用を承認するには、「承認」ボタンを、拒否する場合は「拒否」ボタンをクリックしてください。<br />
          <div class="controlls">
            <button class="submit-btn" type="button" :class="{disable: !readyToAdmit}" @click="admit">承認</button>
            <button class="reject-btn" type="button" @click="reject">拒否</button>
            <button class="cancel-btn" type="button" @click="cancel">保留</button>
          </div>
        </div>
      </div><!-- modal body end -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusConfirmReporterRequestForUserModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    request: Object,
    showConfirmRequestModal: Boolean
  },

  data () {
    return {
      admitAgreement: false
    }
  },

  computed: {
    messageContent () {
      if (this.request.message_content && this.request.message_content.length > 0) {
        return this.request.message_content.replace(/\n/gi, '<br />')
      } else {
        return 'サービス利用案内のメッセージはありません。'
      }
    },

    readyToAdmit () {
      if (this.admitAgreement && !this.request.reporter_organization.revoke_requested) {
        return true
      }
      return false
    }
  },

  methods: {
    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    admit () {
      if (this.readyToAdmit) {
        this.$emit('admitRequestEvent', this.request)
        this.admitAgreement = false
      }
    },

    reject () {
      this.admitAgreement = false
      this.$emit('rejectRequestEvent', this.request)
    },

    cancel () {
      this.$emit('cencelConfirmEvent')
      this.admitAgreement = false
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-cofirm-reporter-request-for-user-modal {
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .modal {
    position: absolute;
    width: 800px;
    max-height: calc(100vh - 60px);
    margin: 0;
    padding: 0;
    top: 30px;
    left: calc((100vw - 800px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    .modal-header {
      padding: 10px;
      border-bottom: 1px solid #aaa;
      h1 {
        margin: 0;
        font-size: 14px;
        color: #666;
        text-align: center;
        img {
          margin-right: 10px;
          height: 15px;
        }
      }
    }
    .modal-body {
      padding: 10px;
      .org-notify {
        padding: 3px;
        border: 1px solid #c43d53;
        color: #c43d53;
      }
      .labeled_content_block_title {
        margin: 10px 0 5px 0;
        font-weight: bold;
        color: #666;
      }
      .labeled_content_block {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #aaa;
      }
      .labeled_content_single_block {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        min-width: 300px;
        margin: 5px;
        margin-right: 10px;
        label {
          margin: 0;
          margin-right: 5px;
          width: 100px;
          text-align: right;
          font-weight: normal;
          font-size: 0.9em;
          color: #666;
        }
        .content {
          display: inline-block;
          font-size: 1.1em;
          img {
            width: 20px;
            margin-right: 5px;
          }
        }
      }
      .notify_for_admit {
        margin-bottom: 5px;
        padding: 10px;
        border: 1px solid #aaa;
        background-color: #fbfaf5;
        h1 {
          margin: 0;
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: bold;
          color: #666;
        }
      }
      .admit-agreement {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .submit_notify_description {
        margin: 10px;
        padding: 10px;
        border: 1px solid #aaa;
        .controlls {
          display: flex;
          justify-content: space-around;
          margin: 10px;
          button {
            width: 150px;
            border: 1px solid #ddd;
            border-radius: 3px;
            background-color: #ddd;
            color: #fff;
            font-weight: bold;
            font-size: 13px;
            &.submit-btn {
              border-color: #5cb85b;
              background-color: #5cb85b;
              &.disable {
                border-color: #93b881;
                background-color: #93b881;
              }
            }
            &.reject-btn {
              border-color: #c43d53;
              background-color: #c43d53;
            }
            &.cancel-btn {
              border-color: #f08300;
              background-color: #f08300;
            }
          }
        }
      }
    }
  }
}
</style>
