<template>
  <div class="horus-system-management-menu">
    <div class="open-controll"><img src="/static/images/small_both_allow.png" @click="toggleMenu"/></div>
    <div class="menu">
      <ul class="manager-menu" v-if="menuOpen">
        <li v-if="systemManager"><a @click="pageJump('/system_management/home')">ホーム</a></li>
        <li v-if="areaBlockOperator"><a @click="pageJump('/system_management/operator_home')">ホーム</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/public_announcement')">お知らせ</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/system_manager')">システム管理ユーザー</a></li>
        <li><a @click="pageJump('/system_management/totalization')">集計</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/area_block')">管理団体</a></li>
        <li><a @click="pageJump('/system_management/organization_request')">施設申請</a></li>
        <li><a @click="pageJump('/system_management/organization')">施設</a></li>
        <li><a @click="pageJump('/system_management/organization_tag')">施設タグ</a></li>
        <li><a @click="pageJump('/system_management/reporter_organization_request')">報告施設申請</a></li>
        <li><a @click="pageJump('/system_management/reporter_organization')">報告施設</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/system_contact_mail')">受信お問い合わせ</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/manage_user')">ユーザー</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/manage_archive')">冊子リクエスト</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/third_party_linkage')">第三者連携サービス</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/static_identify_code_category')">静的QRコードカテゴリ</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/critical_notification')">重要警告</a></li>
        <li v-if="systemManager"><a @click="pageJump('/system_management/system_data_manager')">システムデータ管理</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusSystemManagementMenu',

  props: {
    systemManager: Object,
    areaBlockOperator: Object,
    menuOpen: Boolean
  },

  methods: {
    toggleMenu () {
      this.$emit('toggleMenuEvent')
    },

    pageJump (route) {
      this.$emit('pageJumpEvent', route)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-management-menu {
  .open-controll {
    height: 30px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .menu {
    ul {
      margin: 0;
      padding: 0;
      width: 250px;
      background-color: #f5f5f5;
      li {
        padding: 0;
        border-top: 1px solid #aaa;
        list-style-type: none;
        color: #666;
        text-align: left;
        a {
          display: inline-block;
          padding: 10px 20px;
          width: calc(100% - 40px);
          color: #666;
          text-decoration: none;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
</style>
