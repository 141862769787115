<template>
  <div class="horus-multi-file-selector">
    <div class="upload-files" v-if="files && files.length > 0">
      <div class="selected-file-block" v-for="(selectedFile, index) in files" :key="'selectedfile' + index" :class="displaySize">
        <img class="selected-file_remove-icon" src="/static/images/red_batsu_icon.png" @click="removeFile(index)" />
        <img class="selected-file-icon" src="/static/images/file_icon_blue.png" />
        <span class="selected-file-name">{{ selectedFile.name }}</span>
      </div>
    </div>
    <input type="file" multiple="multiple" :accept="fileInputAccept" :id="fileInputID" :name="fileInputID" style="display: none;cursor: pointer;" ref="fileInput" @change="postFileChange" />
  </div>
</template>

<script>
export default {
  name: 'HorusMultiFileSelector',

  props: {
    fileInputID: String,
    files: Array,
    displaySize: { type: String, default: '' }
  },

  computed: {
    fileInputAccept () {
      // if (this.$native.status) {
      //   return 'image/*,text/*,application/*'
      // }
      return null
    }
  },

  methods: {
    // MEMO: no use.
    addFileForPost () {
      this.$nextTick(() => {
        this.$refs.fileInput.click()
      })
    },

    postFileChange () {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        for (var i = 0; i < selectedFiles.length; i++) {
          var obj = {
            uploadFile: selectedFiles[i],
            name: selectedFiles[i].name
          }
          this.$emit('newFilePreparedEvent', obj)
        }
      }
    },

    removeFile (index) {
      this.$emit('fileRemoveEvent', index)
    }

  }
}
</script>

<style lang="scss" scoped>
.upload-files {
  display: flex;
  .selected-file-block {
    position: relative;
    max-width: 100px;
    margin: 10px;
    text-align: center;
    .selected-file_remove-icon {
      position: absolute;
      cursor: pointer;
      top: 2px;
      right: 2px;
      width: 20px;
      height: 20px;
    }
    .selected-file-name {
      display: block;
      margin: 0 auto;
      font-size: 0.8em;
      color: #333;
    }
  }
}
</style>
