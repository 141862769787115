<template>
  <div class="org-modal" v-show="currentOpenOrg === organization.id" v-click-outside="closeSelf">
    <div class="org-modal-head">{{ organization.name }}</div>
    <div class="org-modal-body">
      <div class="org-address" v-if="organization.address">{{ organization.address }}</div>
      <div class="org-tel" v-if="organization.tel">TEL: <a :href="'tel:' + organization.tel">{{ organization.tel }}</a></div>
      <div class="org-mail" v-if="organization.mail">Mail: {{ organization.mail }}</div>
    </div>
  </div><!-- org-modal end -->
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'HorusOrganizationModal',

  props: {
    organization: Object,
    currentOpenOrg: Number
  },

  methods: {
    closeSelf () {
      if (this.currentOpenOrg === this.organization.id) {
        this.$emit('closeOrganizationModalEvent')
      }
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>
.org-modal {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 280px;
  background-color: #fff;
  border: 1px solid #aaa;
  z-index: 1000;
}

.org-modal-head {
  padding: 10px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  color: #666;
  img {
    width: 100px;
  }
}

.org-modal-body {
  padding: 10px;
  border-top: 1px solid #ddd;
}
</style>
