import axios from './AxiosInstance'

export default {
  fetch: ({timelineOwnerID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'service_relation_request_for_organization/list',
        params: { target_user_id: timelineOwnerID, all: true },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ service_relation_request_for_organizations: res.data.service_relation_request_for_organizations })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'service_relation_request_for_organization',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({timelineOwnerID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
          method: 'delete',
          url: 'service_relation_request_for_organization',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, service_relation_request_for_organization_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  sendMail: ({timelineOwnerID, targetID}) => {
    var formData = new FormData()
    formData.append('target_user_id', timelineOwnerID)
    formData.append('service_relation_request_for_organization_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_organization/send_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchReceiveRequests: ({organizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'service_relation_request_for_organization/receive_list',
        params: { organization_id: organizationID },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ service_relation_request_for_organizations: res.data.service_relation_request_for_organizations })
        }
      }).catch(error => { reject(error) })
    })
  },

  admit: ({organizationID, targetID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('service_relation_request_for_organization_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_organization/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reject: ({organizationID, targetID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('service_relation_request_for_organization_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_relation_request_for_organization/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
