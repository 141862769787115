<template>
  <div class="horus-resolve-confirm-agreement-view container">
    <div class="navbar">
      <div class="main-bar">
        <router-link to="/system_management_operator_landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></router-link>
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">規約への同意が必要です。</h1>
      <div class="message-column">
        あなたのアカウント[{{ criticalNotification.user_name }}]({{ criticalNotification.user_nbid }})では、新しい利用規約への同意が必要となります。<br/>
        このページの下にある新しいを規約を呼んだ上で、「規約に同意」のチェックボックスをチェックして、「送信」ボタンを押してください。<br/>
        規約への同意確認ができると、この警告は解除されます。<br/>
      </div>
      <div class="notification-information">
        <div class="notification-title">{{ criticalNotification.title }}</div>
        <div class="notification-message" v-html="notificationMessageHTML"></div>
        <div class="date-of-fired">警告日時: {{ notificationFireAtStr }}</div>
      </div>
      <div class="rule-confirmation-column">
        <h4 class="heading">規約の説明</h4>
        <h4 class="rule-title">ナラティブブックシステム利用規約</h4>
        <div class="narrativebook_rule small_box">
          <horus-rules />
        </div>
        <h4 class="rule-title">利用者の個人情報の取扱について</h4>
        <div class="narrativebook_rule small_box">
          <horus-personal-information />
        </div>
        <div class="confirm-rules">
          <div class="admit-column" @click="toggleAdmit">
            <horus-custom-check-box
              :active="admitAgreement"
              :enabled="true"
              :type="'color'"
              />
            <span>ナラティブブックシステム利用規約および利用者情報の取扱についてに同意</span>
          </div>
        </div>
        <div class="send-controll"><button class="send-btn" :class="{ enable: admitAgreement }" @click="confirmation">送信</button></div>
      </div>
    </div>
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  CriticalNotification
} from '@/api'

import HorusRules from '@/components/common/HorusRules.vue'
import HorusPersonalInformation from '@/components/common/HorusPersonalInformation.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusResolveConfirmAgreementView',

  components: {
    HorusRules,
    HorusPersonalInformation,
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  props: {
    criticalNotification: Object
  },

  computed: {
    notificationMessageHTML () {
      if (this.criticalNotification.message) {
        return this.criticalNotification.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    notificationFireAtStr () {
      var targetDate = new Date(this.criticalNotification.activated_at)
      console.log(targetDate)
      if (targetDate) {
        return moment(targetDate).format('YYYY年MM月DD日 HH時mm分')
      } else {
        return '-----'
      }
    }
  },

  data () {
    return {
      admitAgreement: false
    }
  },

  methods: {
    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    confirmation () {
      CriticalNotification.resolveByToken({ confirmationToken: this.criticalNotification.target_token, signinToken: this.criticalNotification.signin_token }).then(() => {
        this.showConfirmDialog(
          '規約への同意を確認しました',
          '規約への同意を確認しました。\n警告は解除されました。「OK」を押すとタイムラインにログインします。',
          DIALOG_TYPE.MESSAGE,
          () => { this.$router.push('/maintimeline') }
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-resolve-confirm-agreement-view {
  .contents {
    padding: 10px 100px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1 {
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
      color: #666;
    }
    .message-column {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #c53d43;
      font-size: 1.1em;
    }
  }
  .notification-information {
    padding: 10px;
    border: 1px solid #ddd;
    .notification-title {
      margin-bottom: 10px;
      font-size: 1.1em;
      font-weight: bold;
    }
    .notification-message {
      margin-bottom: 10px;
    }
    .date-of-fired {
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
    }
  }
  .rule-title {
    margin: 5px 0;
    text-align: center;
  }
  .small_box {
    margin-bottom: 10px;
    border: 1px solid #aaa;
    height: 300px;
    overflow: scroll;
  }
  .confirm-rules {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    .admit-column {
      display: flex;
      align-items: center;
      justify-content: space-around;
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .send-controll {
    text-align: center;
    button {
      padding: 5px 20px;
      border-radius: 3px;
      font-size: 1.1em;
      font-weight: bold;
      background-color: #aaa;
      color: #fff;
      &.enable {
        background-color: #4cae4c;
      }
    }
  }
}
</style>
