<template>
  <div class="horus-reporter-related-user-column" :class="columnClasses">
    <div class="upper-column">
      <div class="portrate-column">
        <img :src="user.author.portrateSrc" @click="showTimeline"/>
        <div class="name-column" v-show="!layoutGrid">
          <span @click="showTimeline">{{ user.author.name }}({{ user.author.age }})</span>
        </div>
      </div>
      <div class="user-status">
        <span class="unread-count" v-show="user.unread_count > 0">{{ user.unread_count }}</span>
      </div>
    </div>
    <div class="name-column" v-show="layoutGrid">
      <span @click="showTimeline">{{ user.author.name }}({{ user.author.age }})</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusReporterRelatedUserColumn',

  props: {
    user: Object,
    layoutGrid: Boolean
  },

  computed: {
    columnClasses () {
      var classText = ''
      if (this.layoutGrid) {
        classText += 'grid '
      } else {
        classText += 'line '
      }
      if (this.user.author.sexType === 1) {
        classText += 'male'
      } else if (this.user.author.sexType === 2) {
        classText += 'female'
      } else {
        classText += 'unknown'
      }
      return classText
    }
  },

  methods: {
    showTimeline () {
      this.$emit('showRecordHistoryEvent', this.user)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-reporter-related-user-column {
  margin: 5px;
  width: 180px;
  border: 1px solid #aaa;
  background-color: #fff;
  @include mediaQuery('tablet') {
    width: 170px;
  }
  @include mediaQuery('phone') {
    width: calc((100% - 24px) / 2);
  }
  &.male {
    background-color: #f4f9ff;
  }
  &.female {
    background-color: #fff4ff;
  }
  &.line {
    width: 31%;
    @include mediaQuery('tablet') {
      width: 31%;
    }
    @include mediaQuery('phone') {
      width: calc(100% - 14px);
    }
    .upper-column {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .portrate-column {
        display: flex;
        align-items: center;
        img {
          margin: 5px 0 5px 10px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .upper-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .portrate-column {
      display: flex;
      align-items: flex-start;
      img {
        cursor: pointer;
        margin: 10px 10px 5px 10px;
        width: 50px;
        height: 50px;
      }
    }
    .user-status{
      .unread-count {
        display: inline-block;
        margin: 3px;
        min-width: 5px;
        padding: 3px 7px;
        background-color: #b94047;
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 10px;
      }
    }
  }
  .name-column {
    cursor: pointer;
    margin: 0 10px 5px;
    font-size: 15px;
    font-weight: bold;
    color: #333;
  }
}
</style>
