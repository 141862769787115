<template>
  <div class="horus-password-update-modal" v-if="showPasswordUpdateModal">
    <horus-modal-frame
      :showModalFlag="showPasswordUpdateModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />パスワード変更
        </h1>
      </div>
      <div class="modal-body">
        <div class="notify">パスワードを変更します。<br/>新しく設定したいパスワードを入力してください。</div>
        <div class="form">
          <div class="pass-input">
            <label>パスワード</label>
            <input type="password" name="password" ref="passwordField" id="password" v-model="password">
            <div class="check-icon" :class="passwordValid">
              <img src="/static/images/check_valid.png" class="valid-icon" />
              <img src="/static/images/check_invalid.png" class="invalid-icon" />
            </div>
          </div>
          <div class="pass-input">
            <label>パスワード(確認)</label>
            <input type="password" name="passwordConf" ref="passwordConfirmField" id="passwordConf" v-model="passwordConfirmation">
            <div class="check-icon" :class="passwordConfValid">
              <img src="/static/images/check_valid.png" class="valid-icon" />
              <img src="/static/images/check_invalid.png" class="invalid-icon" />
            </div>
          </div>
          <div class="signin-pass-toggle">パスワード表示<input type="checkbox" id="toggle_password_field" ref="toggle" v-on:change="togglePasswordVisible" /></div>
          <div class="expire-tokens">
            <div class="expire-tokens-notify">
              チェックを入れると、アプリの自動ログインの設定をリセットします。<br/>
              リセット後は、アプリでログインする際に、再度NBIDとパスワードを入力する必要があります。
            </div>
            <div class="toggle-expire-token" @click="toggleExpireTokens">
              <horus-custom-check-box
                :active="expireAppTokens"
                :enabled="true"
                :type="'color'"
                />アプリ用ログイントークンを失効させる
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="submit-controlls">
          <button class="cancel-btn" type="button" @click="cancelSubmit">キャンセル</button>
          <button class="submit-btn" type="button" @click="executeSubmit">パスワード変更</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import AuthenticateInfo from '@/validateModel/AuthenticateInfo.js'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusPasswordUpdateModal',

  components: {
    HorusCustomCheckBox,
    HorusModalFrame
  },

  props: {
    showPasswordUpdateModal: Boolean
  },

  data () {
    return {
      password: '',
      passwordConfirmation: '',
      expireAppTokens: false
    }
  },

  computed: {
    passwordValid () {
      if (this.password.length <= 0) {
        return 'disable'
      } else if (AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    passwordConfValid () {
      if (this.passwordConfirmation.length <= 0) {
        return 'disable'
      } else if (this.password.trim() === this.passwordConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    }
  },

  methods: {
    togglePasswordVisible (event) {
      const checkbox = event.target
      const passwordField = this.$refs.passwordField
      const passwordConfirmField = this.$refs.passwordConfirmField
      if (checkbox.checked === true) {
        passwordField.type = 'text'
        passwordConfirmField.type = 'text'
      } else {
        passwordField.type = 'password'
        passwordConfirmField.type = 'password'
      }
    },

    toggleExpireTokens () {
      this.expireAppTokens = !this.expireAppTokens
    },

    cancelSubmit () {
      this.password = ''
      this.passwordConfirmation = ''
      this.$emit('cancelSubmit')
    },

    executeSubmit () {
      if (this.password.trim() === this.passwordConfirmation.trim() && AuthenticateInfo.validatePasswordFormat(this.password)) {
        this.$emit('executeSubmit', this.password, this.passwordConfirmation, this.expireAppTokens, () => {
          this.password = ''
          this.passwordConfirmation = ''
        })
      } else {
        console.log('password format failed.')
        this.$emit('badPasswordFormatEvent')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-password-update-modal {
  // .modal-header {
  //   padding: 10px;
  //   border-bottom: 1px solid #aaa;
  //   h1 {
  //     margin: 0;
  //     font-size: 14px;
  //     color: #666;
  //     text-align: center;
  //     img {
  //       margin-right: 10px;
  //       height: 15px;
  //     }
  //   }
  // }
  .modal-body {
    padding: 10px;
    .notify {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #c53d43;
      background-color: #fdeff2;
      color: #c53d43;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
    .pass-input {
      margin-bottom: 10px;
      text-align: center;
      label{
        display: inline-block;
        margin-right: 10px;
        width: 130px;
        text-align: right;
        font-weight: bold;
        @include mediaQuery('phone') {
          width: 116px;
        }
      }
      input {
        display: inline-block;
        font-size: 16px;
      }
      .check-icon {
        display: inline-block;
        margin-right: 10px;
        width: 25px;
        .valid-icon {
          display: none;
          width: 25px;
          height: 18px;
        }
        .invalid-icon {
          display: none;
          width: 20px;
          height: 20px;
        }
        &.disable {
          .valid-icon {
            display: none;
          }
          .invalid-icon {
            display: none;
          }
        }
        &.valid {
          .valid-icon {
            display: inline;
          }
          .invalid-icon {
            display: none;
          }
        }
        &.invalid {
          .valid-icon {
            display: none;
          }
          .invalid-icon {
            display: inline;
          }
        }
      }
    }
    .signin-pass-toggle {
      text-align: center;
    }
    .expire-tokens {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
      text-align: center;
      .expire-tokens-notify {
        margin-bottom: 10px;
        padding: 5px;
        border: 1px solid #c53d43;
        text-align: left;
        font-size: 0.9em;
      }
    }
    // input[type="text"] {
    //   font-size: 16px;
    // }
  }
  .modal-footer {
    .submit-controlls {
      padding: 10px;
      text-align: right;
      button {
        width: 150px;
        font-size: 14px;
        font-weight: bold;
        border: 1px solid #999;
        border-radius: 3px;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
          font-weight: normal;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
</style>
