<template>
  <div class="horus-tag-manage-item">
    <div class="item-column">
      <img src="/static/images/sortable_icon.png" class="drug-handle" />
      <horus-hash-tag-display :hashTag="hashTag" :sizeType="'large'" />
    </div>
    <div class="controll-column">
      <div class="button-area" v-show="!modeEdit">
        <img src="/static/images/pencil_icon.png" class="edit-btn" @click="modeToEdit" :class="{disable: !editable()}" />
        <img src="/static/images/bucket_icon.png" class="delete-btn" @click="removeTag" :class="{disable: !editable()}" />
      </div>
      <div class="edit-area" v-show="modeEdit">
        <input type="text" class="text-field" v-model="tagName" />
        <button class="cancel-tag-btn" @click="cancelEdit">キャンセル</button>
        <button class="submit-tag-btn" @click="updateTag">変更</button>
      </div>
    </div>
  </div>
</template>

<script>
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusTagManageItem',

  components: {
    HorusHashTagDisplay
  },

  props: {
    hashTag: Object
  },

  data () {
    return {
      modeEdit: false,
      tagName: ''
    }
  },

  computed: {
  },

  watch: {
    hashTag: { handler: 'initTagName', immediate: true }
  },

  methods: {
    editable () {
      if (this.hashTag.tagName.match(/.+##.+/g)) {
        return false
      } else {
        return true
      }
    },

    initTagName () {
      if (this.hashTag) {
        this.tagName = this.hashTag.tagName
      }
    },

    modeToEdit () {
      if (this.editable()) {
        this.modeEdit = true
      }
    },

    cancelEdit () {
      this.tagName = this.hashTag.tagName
      this.modeEdit = false
    },

    updateTag () {
      if (this.editable()) {
        this.$emit('updateTagEvent', this.hashTag, this.tagName, () => {
          this.modeEdit = false
        })
      }
    },

    removeTag () {
      if (this.editable()) {
        this.$emit('deleteTagEvent', this.hashTag)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-tag-manage-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #aaa;
  .item-column {
    display: flex;
    .drug-handle {
      cursor: grab;
      padding-right: 20px;
      height: 15px;
    }
  }
  .controll-column {
    text-align: right;
    .button-area {
      img {
        cursor: pointer;
        height: 20px;
        margin-left: 10px;
        &.disable {
          cursor: default;
          opacity: 0.5;
        }
      }
    }
    .edit-area {
      .text-field {
        font-size: 16px;
        width: 200px;
      }
      button {
        cursor: pointer;
        background-color: #666;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
      .cancel-tag-btn {
        background-color: #fff;
        border: 1px solid #aaa;
        color: #999;
      }
      .submit-tag-btn {
        width: 100px;
        border: 1px solid #666;
      }
    }
  }
}
</style>
