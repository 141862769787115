import { Model } from '@vuex-orm/core'
import DrugEntry from './DrugEntry'
import TimelineRecord from './TimelineRecord'

export default class DrugEntryPhoto extends Model {
  static entity = 'drugEntryPhotos'

  static fields () {
    return {
      id: this.attr(null),
      drug_entry_id: this.attr(null),
      record_id: this.attr(null),
      filename: this.string(''),
      imageHeight: this.number(0),
      imageWidth: this.number(0)
    }
  }

  get drugEntry () {
    return DrugEntry.find(this.drug_entry_id)
  }

  get photoSrc () {
    const record = TimelineRecord.find(this.record_id)
    return process.env.VUE_APP_BASE_URL + '/record/show_drug_entry_photo?target_user_id=' + record.user_id + '&drug_entry_photo_id=' + this.id
  }

  get photoThumbnailSrc () {
    const record = TimelineRecord.find(this.record_id)
    return process.env.VUE_APP_BASE_URL + '/record/show_drug_entry_photo?target_user_id=' + record.user_id + '&drug_entry_photo_id=' + this.id + '&version=thumb'
  }

  get photoMiddleSrc () {
    const record = TimelineRecord.find(this.record_id)
    return process.env.VUE_APP_BASE_URL + '/record/show_drug_entry_photo?target_user_id=' + record.user_id + '&drug_entry_photo_id=' + this.id + '&version=middle'
  }
}
