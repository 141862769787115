import axios from './AxiosInstance'

export default {

  fetchList: ({userID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'deputize_request_for_user/list',
        withCredentials: true,
        params: { target_user_id: userID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ deputize_request_for_users: res.data.deputize_request_for_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'deputize_request_for_user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      }).catch(error => {
        throw error
      })
    })
  },

  destroy: ({userID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'deputize_request_for_user',
            withCredentials: true,
            params: { target_user_id: userID, deputize_request_for_user_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  sendMail: ({userID, targetID}) => {
    var formData = new FormData()
    formData.append('target_user_id', userID)
    formData.append('deputize_request_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputize_request_for_user/send_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchByToken: ({requestToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'deputize_request_for_user',
        withCredentials: true,
        params: { token: requestToken }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ client: res.data.client, message_content: res.data.message_content, limit_at: res.data.limit_at })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchReceiveRequests: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'deputize_request_for_user/receive_list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ deputize_request_for_users: res.data.deputize_request_for_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  admitByToken: ({requestToken, signinID, password, shortToken}) => {
    var formData = new FormData()
    formData.append('token', requestToken)
    formData.append('login_id', signinID)
    formData.append('password', password)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputize_request_for_user/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitByRequest: ({targetID}) => {
    var formData = new FormData()
    formData.append('deputize_request_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputize_request_for_user/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  rejectByToken: ({requestToken}) => {
    var formData = new FormData()
    formData.append('token', requestToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputize_request_for_user/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  rejectByRequest: ({targetID}) => {
    var formData = new FormData()
    formData.append('deputize_request_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputize_request_for_user/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }

}
