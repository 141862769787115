<template>
  <div class="horus-prepare-app-signin-token-view">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <div class="form-set" v-if="!processFinished">
        <h1 class="centering-title">アプリ専用ログインの設定</h1>
        <div class="app-info-block">
          <h2>[{{ appInfo }}]上のアプリでのログイン情報設定</h2>
          <div class="app-info-caption">
            ナラティブブックアプリでの自動ログインを設定します。<br/>
            よろしければ、下のフォームにNBIDとパスワードを入力して、「設定」を押してください。
          </div>
        </div>
        <div class="signin-block">
          <form id='own_signin_form' v-on:submit.prevent="createAppSignin">
            <div class="signin-input"><label>NBID</label><input type="text" v-model="inputLoginId" ref="loginIDField"></div>
            <div class="signin-input"><label>パスワード</label><input type="password" ref="passwordField" id="password" v-model="inputPassword"></div>
            <div class="signin_pass_toggle">パスワード表示<input type="checkbox" ref="toggle" v-on:change="togglePasswordVisible" /></div>
            <button type="submit" class="btn success signin-btn">設定</button>
          </form>
        </div>
      </div>
      <div class="test-complete" v-if="processFinished">
        <h2>トークン取得完了</h2>
        <div class="token-info">
          <div class="info-list">
            <span class="list-title">トークン:</span><span class="list-content">{{ this.finishedInfo.token }}</span>
          </div>
          <div class="info-list">
            <span class="list-title">アプリ情報:</span><span class="list-content">{{ this.appInfo }}</span>
          </div>
          <div class="info-list">
            <span class="list-title">ユーザー種別:</span><span class="list-content">{{ this.finishedInfo.type }}</span>
          </div>
          <div class="info-list">
            <span class="list-title">AuthorID:</span><span class="list-content">{{ this.finishedInfo.authorID }}</span>
          </div>
          <h3>ファイル取得テスト</h3>
          <label>ファイルID</label><input type="text" v-model="targetFileID" />
          <label>ユーザーID</label><input type="text" v-model="targetUserID" />
          <label>nbID</label><input type="text" v-model="targetNBID" />
          <button @click="createResultURL">create url</button>
          <div>{{ resultURL }}</div>
        </div>
      </div>
    </div>
    <horus-footer/>
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>連携するアカウントの選択</h1>
      </div>
      <div class="modal-body member-selection-modal-body">
        <div class="member-selection" v-if="memberSelection.length > 0">
          <div class="member-info" v-if="timelineOwner">
            <div class="member-name">一般ユーザー</div>
            <div class="org-name">職員ではない、一般ユーザー[{{ timelineOwner.fullName }}]として設定</div>
            <button class="member-select-btn" @click="confirmCreateAppSignin('normal_user')">選択</button>
          </div>
          <div class="member-info"
            v-for="member in memberSelection"
            :key="'tplMemberSel' + member.id">
            <div class="member-name">{{ member.name }}</div>
            <div class="org-name">所属: {{ member.organization_name }}</div>
            <button class="member-select-btn" @click="confirmCreateAppSignin(member)">選択</button>
          </div>
        </div>
        <div class="member-select-controll">
          <button class="close-btn" @click="cancelMemberSelect">選択せずに閉じる</button>
        </div>
      </div>
    </horus-modal-frame>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'
import crypto from 'crypto'
import {
  SigninUser,
  SigninMember,
  TimelineOwner
} from '@/models'
import { AppSigninToken } from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusPrepareAppSigninTokenView',

  components: {
    HorusFooter,
    HorusConfirmDialog,
    HorusModalFrame
  },

  data () {
    return {
      appInfo: null,
      inputLoginId: null,
      inputPassword: null,
      showModal: false,
      signinUser: null,
      timelineOwner: null,
      memberSelection: [],
      processFinished: false,
      finishedInfo: {},
      targetFileID: '',
      targetUserID: '',
      targetNBID: '',
      resultURL: 'not set yet.'
    }
  },

  watch: {
    '$attrs': { handler: 'loadQuery', immediate: true }
  },

  methods: {
    loadQuery () {
      this.appInfo = this.$attrs.app_info
    },

    togglePasswordVisible (event) {
      const checkbox = event.target
      const passwordField = this.$refs.passwordField
      if (checkbox.checked === true) {
        passwordField.type = 'text'
      } else {
        passwordField.type = 'password'
      }
    },

    async createAppSignin () {
      try {
        await SigninUser.api().fetch({ loginID: this.inputLoginId, password: this.inputPassword })
        const allUser = SigninUser.all()
        this.signinUser = allUser[0]
        var owner = TimelineOwner.find(this.signinUser.user_id)
        if (owner === null) {
          await TimelineOwner.api().fetch({ timelineOwnerID: this.signinUser.user_id })
          this.timelineOwner = TimelineOwner.find(this.signinUser.user_id)
        } else {
          this.timelineOwner = owner
        }
        let res = await SigninMember.api().fetchMemberSelection({ loginID: this.inputLoginId, password: this.inputPassword })
        console.log(res)
        if (res.members) {
          if (res.members.length >= 1) {
            this.memberSelection = res.members
            this.showModal = true
          } else {
            this.executeCreateAppSignin('normal_user')
          }
        }
      } catch (error) {
        console.error(error.response)
        this.showNotifyDialog('ログイン情報が正しくありません', 'NBIDかパスワードが間違っています。\nアカウント情報が取得出来ませんでした。')
      }
    },

    cancelMemberSelect () {
      this.showModal = false
      this.memberSelection = []
    },

    confirmCreateAppSignin (member) {
      var message = '一般ユーザー[' + this.timelineOwner.fullName + ']のアカウントで設定します。\nアプリ起動の際、' + this.timelineOwner.fullName + 'のタイムラインを表示します。\nよろしいですか?'
      var sendParam = 'normal_user'
      if (member !== 'normal_user') {
        message = member.organization_name + 'の職員アカウントと連携します。\nアプリ起動の際、' + member.organization_name + '所属の' + member.name + 'としてログインし、職員ポータルを表示します。\nよろしいですか?'
        sendParam = member.id
      }
      this.showConfirmDialog(
        '連携アカウントの選択',
        message,
        DIALOG_TYPE.EXECUTE,
        () => { this.executeCreateAppSignin(sendParam) }
      )
    },

    executeCreateAppSignin (memberID) {
      this.showModal = false
      var formData = new FormData()
      formData.append('app_info', this.appInfo)
      formData.append('login_id', this.inputLoginId)
      formData.append('password', this.inputPassword)
      formData.append('signin_target', memberID)
      AppSigninToken.create({ formData: formData }).then(res => {
        console.log(res)
        if (res.success) {
          this.processFinished = true
          this.finishedInfo.token = res.token
          if (res.signin_user_id) {
            this.finishedInfo.type = '一般ユーザー'
            this.finishedInfo.authorID = res.author_id
          } else if (res.member) {
            this.finishedInfo.type = '職員ユーザー'
            this.finishedInfo.authorID = res.member.author_id
          }
        } else {
          this.showNotifyDialog('アプリ専用ログイン情報の作成失敗', 'ログイン対象のユーザーが指定されていません。')
        }
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('アプリ専用ログイン情報の作成失敗', 'ナラティブブック管理者にお問い合わせください。')
      })
    },

    createResultURL () {
      var now = new Date()
      var dateStr = moment(now).format('YYYYMMDDHHmm')
      dateStr = dateStr.substr(0, 11)
      console.log('dateStr: ' + dateStr)
      var hashed = crypto.createHash('sha256').update(this.finishedInfo.token + dateStr, 'utf8').digest('hex')
      this.resultURL = '/record/show_file_report?file_report_id=' + this.targetFileID + '&target_user_id=' + this.targetUserID + '&nbid=' + this.targetNBID + '&token=' + hashed
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/_basicInputStyle.scss';

.horus-prepare-app-signin-token-view {
  background-color: #fff;
  .navbar {
    .main-bar {
      background-color: #692220;
      background-image: url("/static/images/nb_header_iconless.png");
    }
  }
  .contents {
    height: calc(100% - 51px - 132px - 20px);
    overflow: scroll;
    padding: 10px 50px;
    @include mediaQuery('tablet') {
      padding: 10px;
    }
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    .fail-message-block {
      margin: 30px;
      padding: 20px;
      border: 1px solid #c53d43;
      @include mediaQuery('phone') {
        margin: 30px 0px;
        padding: 10px;
      }
    }
    .app-info-block {
      margin: 10px 0;
      h2 {
        margin: 5px 0;
        font-size: 1.1em;
        font-weight: bold;
      }
      .app-info-caption {
        margin: 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
      }
    }
    .signin-block {
      margin: 20px auto;
      padding: 40px;
      max-width: 500px;
      background-color: #fff;
      border: 1px solid #aaa;
      text-align: center;
      @include mediaQuery('tablet') {
        // margin: 10px;
      }
      @include mediaQuery('phone') {
        padding: 30px 10px;
        margin: 10px;
      }
      .signin-input {
        width: 100%;
        margin-bottom: 10px;
        label {
          display: inline-block;
          margin-right: 10px;
          width: 100px;
          text-align: right;
          font-weight: bold;
          @include mediaQuery('phone') {
            width: 70px;
          }
        }
        input {
          font-size: 16px;
        }
      }
      .signin_pass_toggle {
        margin-bottom: 10px;
        font-size: 12px;
      }
      .signin-btn {
        width: 150px;
        padding: 8px 16px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .signin-button-controlls {
      margin: 20px;
      text-align: center;
      line-height: 2em;
      .join-btn {
        font-weight: bold;
      }
    }
  }
  .member-selection-modal-body {
    padding: 10px;
    .member-info {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      padding: 5px;
      border: 1px solid #ddd;
      .member-name {
        margin-right: 10px;
        font-weight: bold;
      font-size: 16px;
      }
      .org-name {
        margin-right: 20px;
      font-size: 16px;
      }
      .member-select-btn {
        flex-shrink: 0;
        width: 50px;
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
    .member-select-controll {
      margin-top: 10px;
      text-align: center;
      .close-btn {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #fff;
        color: #666;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
