import moment from 'moment'

export default class UserBasic {
  constructor (obj = {}) {
    this.userID = obj.user_id || null
    this.familyName = obj.family_name || ''
    this.givenName = obj.given_name || ''
    this.familyNameKana = obj.family_name_kana || ''
    this.givenNameKana = obj.given_name_kana || ''
    this.sexType = obj.sex_type || 0
    this.birthDate = obj.birth_date || null
    this.bloodTypeABO = obj.blood_type_abo || 0
    this.bloodTypeRH = obj.blood_type_rh || 0
    this.updateAt = obj.updated_at || null
    this.authorID = obj.author_id || null
  }

  birthDateJp () {
    if (this.birthDate) {
      return moment(this.birthDate).format('YYYY年MM月DD日')
    }
  }

  isValid () {
    if (
      this.validateFamilyName().valid &&
      this.validateGivenName().valid &&
      this.validateFamilyNameKana().valid &&
      this.validateGivenNameKana().valid &&
      this.validateBirthDate().valid
    ) {
      return true
    } else {
      return false
    }
  }

  validateFamilyName () {
    var valid = false
    var state = 'invalid'
    var message = ''
    if (!this.familyName) {
      message = '姓は入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
    } else {
      var trimed = this.familyName.trim()
      if (trimed.length > 0 && trimed.length <= 20) {
        valid = true
        state = 'valid'
      } else if (trimed.length > 20) {
        message = '姓は20文字以内で入力ください。'
      } else {
        message = '姓は空白を含めず1文字以上20文字以下で入力ください。'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  validateGivenName () {
    var valid = false
    var state = 'invalid'
    var message = ''
    if (!this.givenName) {
      message = '名は入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
    } else {
      var trimed = this.givenName.trim()
      if (trimed.length > 0 && trimed.length <= 20) {
        valid = true
        state = 'valid'
      } else if (trimed.length > 20) {
        message = '名は20文字以内で入力ください。'
      } else {
        message = '名は空白を含めず1文字以上20文字以下で入力ください。'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  validateFamilyNameKana () {
    var valid = false
    var state = 'invalid'
    var message = ''
    if (!this.familyNameKana) {
      message = '姓カナは入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
    } else {
      var trimed = this.familyNameKana.trim()
      if (trimed.match(/^[ァ-ヶー]+$/)) {
        if (trimed.length > 0 && trimed.length <= 20) {
          valid = true
          state = 'valid'
        } else if (trimed.length > 20) {
          message = '姓カナは20文字以内で入力ください。'
        } else {
          message = '姓カナは空白を含めず1文字以上20文字以下で入力ください。'
        }
      } else {
        message = '姓カナは全角カタカナ以外の文字は使えません。'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  validateGivenNameKana () {
    var valid = false
    var state = 'invalid'
    var message = ''
    if (!this.givenNameKana) {
      message = '名カナは入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
    } else {
      var trimed = this.givenNameKana.trim()
      if (trimed.match(/^[ァ-ヶー]+$/)) {
        if (trimed.length > 0 && trimed.length <= 20) {
          valid = true
          state = 'valid'
        } else if (trimed.length > 20) {
          message = '名カナは20文字以内で入力ください。'
        } else {
          message = '名カナは空白を含めず1文字以上20文字以下で入力ください。'
        }
      } else {
        message = '名カナは全角カタカナ以外の文字は使えません。'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  validateBirthDate () {
    if (this.birthDate && this.birthDate.toString() !== 'Invalid Date') {
      return { valid: true, state: 'valid', message: '' }
    } else {
      return { valid: false, state: 'invalid', message: '生年月日は入力必須です。' }
    }
  }

  toFormData (targetUserID, portrateObj = null) {
    if (this.isValid()) {
      var formData = new FormData()
      formData.append('target_user_id', targetUserID.id)
      formData.append('user_basic[family_name]', this.familyName)
      formData.append('user_basic[given_name]', this.givenName)
      formData.append('user_basic[family_name_kana]]', this.familyNameKana)
      formData.append('user_basic[given_name_kana]', this.givenNameKana)
      formData.append('user_basic[sex_type]', this.sexType)
      formData.append('user_basic[birth_date]', this.birthDate)
      formData.append('user_basic[blood_type_abo]', this.bloodTypeABO)
      formData.append('user_basic[blood_type_rh]', this.bloodTypeRH)
      if (portrateObj) {
        formData.append('user_basic[portrate]', portrateObj.uploadFile, portrateObj.name)
      }
      return formData
    } else {
      return null
    }
  }
}
