<template>
  <div class="horus-member-select-modal" v-if="showModal">
    <div class="bg-select-backdrop" @click="backdropClicked"></div>
    <div class="member-select-modal">
      <div class="member-select-header">
        <img src="/static/images/notify_green.png" v-if="signinMember" />
        {{ modalTitle }}
      </div>
      <div class="member-select-control">
        <div class="left-buttons">
          <button class="member-select-all" type="button" @click="allMemberSelect">全員に通知</button>
          <button class="member-select-clear" type="button" @click="clearSelectedMember">クリア</button>
        </div>
        <div class="right-buttons">
          <button class="member-select-commit" type="button" @click="commitSelectedMember">OK</button>
        </div>
      </div>
      <div class="select-groups">
        <div class="target-organization">
          <div class="target-organization-head">
            <img src="/static/images/org_member.png" />
            <span class="target-orgnization-name">{{ organization.shortName }}</span>
          </div>
          <div class="selection-group">
            <div class="select-member-block"
              v-for="member in organization.members"
              :key="'memberselectmodal' + member.id"
              @click="toggleMember(member)">
              <horus-custom-check-box :type="'small'"
                :active="isSelected(member)"
                :enabled="true" />
              <img :src="member.portrateSrc" />
              <span class="author-name">{{ member.name }}</span>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusMemberSelectModal',

  components: {
    HorusCustomCheckBox
  },

  props: {
    showModal: Boolean,
    organization: Object,
    selectedMembers: Array,
    signinMember: Object,
    modalTitle: String
  },

  methods: {
    isSelected (author) {
      return this.selectedMembers.some(selected => { return selected.id === author.id })
    },

    toggleMember (author) {
      this.$emit('toggleSelectMemberEvent', author)
    },

    allMemberSelect () {
      this.$emit('allMemberSelectEvent')
    },

    commitSelectedMember () {
      this.$emit('memberSelectionModalCloseEvent')
    },

    clearSelectedMember () {
      this.$emit('clearSelectedMemberEvent')
      this.$emit('memberSelectionModalCloseEvent')
    },

    backdropClicked () {
      this.$emit('memberSelectionModalCloseEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.bg-select-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.2;
}
.member-select-modal {
  position: absolute;
  max-width: 924px;
  max-height: calc(100vh - 250px);
  margin: 0;
  padding: 0;
  top: 30px;
  left: calc((100vw - 1000px) / 2 + 30px);
  overflow: auto;
  background-color: #fff;
  border: 1px solid #aaa;
  @include mediaQuery('tablet') {
    width: calc(100vw - 40px);
    left: 20px;
  }
  @include mediaQuery('phone') {
    width: calc(100vw - 10px);
    max-height: calc(100vh - 100px);
    left: 5px;
  }
}
.member-select-header {
  padding: 10px 20px;
  background-color: #f8fcf9;
  color: #2b4733;
  font-weight: bold;
  font-size: 12px;
  img {
    margin: 0 5px 3px 0;
    width: 15px;
    vertical-align: middle;
  }
}
.member-select-control {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  button {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    width: 100px;
    margin: 0 0 0 10px;
    padding: 5px 20px;
    width: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 10px;
    border-radius: 2px;
  }
  .member-select-all {
    width: auto;
    padding: 5px;
    border: 1px solid #999;
    background-color: #999;
    color: #fff;
  }
  .member-select-clear {
    border: 1px solid #aaa;
    background-color: #fff;
    color: #333;
    @include mediaQuery('phone') {
      width: 70px;
      padding: 5px 10px;
    }
  }
  .member-select-commit {
    border: 1px solid #666;
    background-color: #666;
    color: #fff;
    @include mediaQuery('phone') {
      width: 70px;
      padding: 5px 10px;
    }
  }
}
.member-select-clear {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #333;
}
.select-groups {
  max-height: calc(100vh - 250px - 78px);
  overflow: scroll;
  @include mediaQuery('phone') {
    max-height: calc(100vh - 100px - 78px);
  }
}
.target-organization-head {
  margin: 10px 0 0 0;
  padding: 5px 5px 5px 25px;
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  cursor: pointer;
  img {
    width: 10px;
    height: 10px;
  }
}
.selection-group {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}
.select-member-block {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  img {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
}
</style>
