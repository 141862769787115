<template>
  <div class="horus-select-date-controll-wrapper" v-show="showDateSelectionModal">
    <div class="horus-select-date-controll-backdrop" @click="closeControll"></div>
    <div class="hours-select-date-body">
      <div class="horus-select-date-controll">
        <div class="select-day">
          <div class="title-bar">
            <div class="month-move" @click="movePrevMonth">&lt;</div>
            <div class="year-month-display">{{ currentYear }}年{{ currentMonth }}月</div>
            <div class="month-move" @click="moveNextMonth" :class="{disabled: nextMonthDisable}">&gt;</div>
          </div>
          <div class="wday-bar">
            <div class="wday">日</div>
            <div class="wday">月</div>
            <div class="wday">火</div>
            <div class="wday">水</div>
            <div class="wday">木</div>
            <div class="wday">金</div>
            <div class="wday">土</div>
          </div>
          <div class="date-column">
            <div class="blank-panel" v-for="blank in prevBlanks" :key="blank">&nbsp;</div>
            <div class="selectable-panel" v-for="(day, index) in dateArrays" :key="day" @click="selectDate(index)" :class="{selected: day, disabled: dateDisable(index)}">{{ index+1 }}</div>
            <div class="blank-panel" v-for="blank in postBlanks" :key="blank">&nbsp;</div>
          </div>
        </div>
        <div class="select-time">
          <div class="title-bar">時</div>
          <div class="hour-column">
            <div class="selectable-panel" v-for="(hour, index) in hours" :key="'hour'+ index" @click="selectHour(index)" :class="{selected: hour, disabled: hourDisable(index)}">
              {{ ('00' + index).slice(-2) }}
            </div>
          </div>
          <div class="title-bar">分</div>
          <div class="minute-column">
            <div class="selectable-panel" v-for="(minute, index) in minutes" :key="'minute'+ index" @click="selectMinute(index)" :class="{selected: minute, disabled: minuteDisable(index)}">
              {{ ('00' + (index * 10)).slice(-2) }}
            </div>
          </div>
        </div>
      </div><!-- select date controll end -->
      <div class="selected-date-display">
        <div class="selected-datetime">選択日時 : {{ selectedDateTime }}</div>
        <div class="controlls">
          <button type="button" class="cancel" @click="cancelAndClose">キャンセル</button>
          <button type="button" class="commit" @click="commitAndClose">OK</button>
        </div>
      </div>
    </div><!-- select date body end -->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusPostTargetDateSelectionModal',

  props: {
    showDateSelectionModal: Boolean,
    initializeDate: Object
  },

  data () {
    return {
      selectedDate: new Date(),
      currentYear: 0,
      currentMonth: 1,
      hours: new Array(24),
      minutes: new Array(6)
    }
  },

  watch: {
    initializeDate: { handler: 'initValues', immediate: true }
  },

  computed: {
    prevBlanks () {
      const prevCount = this.firstDayWday(this.currentYear, this.currentMonth)
      if (prevCount > 0) {
        return new Array(prevCount)
      } else {
        return []
      }
    },

    postBlanks () {
      const postCount = this.leftCountOfChart(this.currentYear, this.currentMonth)
      if (postCount > 0) {
        return new Array(postCount)
      } else {
        return []
      }
    },

    nextMonthDisable () {
      const now = new Date()
      const next = new Date(this.currentYear, this.currentMonth)
      const current = new Date(now.getFullYear(), now.getMonth())
      if (next > current) {
        return true
      }
      return false
    },

    dateArrays () {
      var days = new Array(this.daysOfMonth(this.currentYear, this.currentMonth))
      if (this.currentYear === this.selectedDate.getFullYear() && this.currentMonth === this.selectedDate.getMonth() + 1) {
        days[this.selectedDate.getDate() - 1] = true
      }
      return days
    },

    selectedDateTime () {
      return moment(this.selectedDate).format('YYYY/MM/DD HH:mm')
    }
  },

  // mounted () {
  //   this.initValues()
  // },

  methods: {
    initValues () {
      if (this.initializeDate.date) {
        this.selectedDate = new Date(this.initializeDate.date.getTime())
      } else {
        this.selectedDate = new Date()
      }
      this.currentYear = this.selectedDate.getFullYear()
      this.currentMonth = this.selectedDate.getMonth() + 1
      this.hours = new Array(24)
      this.hours.fill(false)
      this.hours[this.selectedDate.getHours()] = true
      this.minutes = new Array(6)
      this.minutes.fill(false)
      this.minutes[parseInt(this.selectedDate.getMinutes() / 10)] = true
    },

    // 引数のmonthは普通の月の数値。Date.getMonthで返ってくる月のインデックス値(数値の月-1の値)ではない
    daysOfMonth (year, month) {
      // 指定した月の次の月の0日目は, 指定した月の最終日が返ってくるのを利用
      return new Date(year, month, 0).getDate()
    },

    // 指定した月の一日の曜日を取得。日曜始まりの0インデックス
    firstDayWday (year, month) {
      return new Date(year, month - 1, 1).getDay()
    },

    leftCountOfChart (year, month) {
      const mod = ((this.firstDayWday(year, month) + this.daysOfMonth(year, month)) % 7)
      if (mod > 0) {
        return 7 - mod
      } else {
        return mod
      }
    },

    chartCount (year, month) {
      const left = this.firstDayWday(year, month) + this.daysOfMonth(year, month)
      const after = 7 - (left % 7)
      return left + after
    },

    movePrevMonth () {
      // 今月の0日目は前月の末日であることを利用
      const calcDay = new Date(this.currentYear, this.currentMonth - 1, 0)
      this.currentYear = calcDay.getFullYear()
      this.currentMonth = calcDay.getMonth() + 1
    },

    moveNextMonth () {
      const calcDay = new Date(this.currentYear, this.currentMonth, 1)
      this.currentYear = calcDay.getFullYear()
      this.currentMonth = calcDay.getMonth() + 1
    },

    dateDisable (dayIndex) {
      const subjectDate = new Date(this.currentYear, this.currentMonth - 1, dayIndex + 1, this.selectedDate.getHours(), this.selectedDate.getMinutes())
      if (subjectDate > new Date()) {
        return true
      }
      return false
    },

    hourDisable (hourIndex) {
      const subjectDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), hourIndex, this.selectedDate.getMinutes())
      if (subjectDate > new Date()) {
        return true
      }
      return false
    },

    minuteDisable (minuteIndex) {
      const subjectDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), this.selectedDate.getHours(), minuteIndex * 10)
      if (subjectDate > new Date()) {
        return true
      }
      return false
    },

    selectDate (dayIndex) {
      const subjectDate = new Date(this.currentYear, this.currentMonth - 1, dayIndex + 1, this.selectedDate.getHours(), this.selectedDate.getMinutes())
      if (subjectDate < new Date()) {
        this.selectedDate = subjectDate
      }
    },

    selectHour (hourIndex) {
      const subjectDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), hourIndex, this.selectedDate.getMinutes())
      if (subjectDate < new Date()) {
        this.selectedDate = subjectDate
        this.hours.forEach((hour, index) => {
          if (index === hourIndex) {
            this.hours[index] = true
          } else {
            this.hours[index] = false
          }
        })
      }
    },

    selectMinute (minuteIndex) {
      const subjectDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), this.selectedDate.getHours(), minuteIndex * 10)
      if (subjectDate < new Date()) {
        this.selectedDate = subjectDate
        this.minutes.forEach((minute, index) => {
          if (index === minuteIndex) {
            this.minutes[index] = true
          } else {
            this.minutes[index] = false
          }
        })
      }
    },

    closeControll () {
      this.$emit('targetDateSelectionCloseEvent')
    },

    cancelAndClose () {
      this.$emit('selectTargetDateCancelEvent')
    },

    commitAndClose () {
      console.log(this.selectedDate)
      this.$emit('selectTargetDateCommitEvent', this.selectedDate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-select-date-controll-wrapper {
  position: absoluete;
}
.horus-select-date-controll-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.2;
}
.hours-select-date-body {
  position: absolute;
  top: 130px;
  left: calc((100vw - 582px) / 2);
  background-color: #fff;
  border: 1px solid #aaa;
  @include mediaQuery('phone') {
    width: 310px;
    max-height: calc(100vh - 100px);
    overflow: scroll;
    top: 10px;
    left: 5px;
  }
}
.horus-select-date-controll {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  .title-bar {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: #999;
    color: #fff;
    font-weight: bold;
    .month-move {
      padding: 0 5px;
      cursor: pointer;
      &.disabled {
        cursor: auto;
        color: #ddd;
      }
    }
  }
  .select-day {
    width: 300px;
    padding: 5px;
    .wday-bar {
      display: flex;
      margin-top: 5px;
      justify-content: space-between;
      .wday {
        width: 40px;
        text-align: center;
      }
    }
    .date-column {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .blank-panel {
        margin-top: 3px;
        width: 40px;
        height: calc(40px - ((40px - 14pt) / 2) + 2);
        color: #322;
      }
    }
  }
  .select-time {
    width: 250px;
    padding: 5px;
    @include mediaQuery('phone') {
      padding: 5px 25px;
    }
    .title-bar {
      text-align: center;
    }
    .hour-column {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 5px;
    }
    .minute-column {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .selectable-panel {
    width: 38px;
    height: calc(40px - ((40px - 14pt) / 2));
    border: 1px solid #999;
    margin-top: 3px;
    padding-top: calc((40px - 14pt) / 2);
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
    color: #999;
    cursor: pointer;
    &.selected {
      color: #c9171e;
      border-color: #c9171e;
    }
    &.disabled {
      cursor: auto;
      background-color: #ddd;
    }
  }
}
.selected-date-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #aaa;
  padding: 5px 10px;
  .selected-datetime {
    font-size: 16px;
  }
  .controlls {
    button {
      display: inline-block;
      width: 100px;
      height: 26px;
      font-size: 14px;
      background-color: #eee;
      &.cancel {
        background-color: #fffffe;
        color: #666;
      }
      &.commit {
        background-color: #666;
        color: #fff;
      }
    }
  }
}
</style>
