import axios from './AxiosInstance'

export default {
  currentState: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/authenticate/current_area_block_operator_state',
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetchCurrentSignin: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/authenticate/current_area_block_operator',
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  signOut: () => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: '/signout',
            withCredentials: true,
            xsrfHeaderName: 'X-CSRF-TOKEN',
            credentials: 'include',
            mode: 'cors',
          }
        }).then(res => {
          resolve({ message: res.data.message })
        }).catch(error => {
          reject(error)
        })
      })
    })
  },
}
