<template>
  <div class="boad-comments left-column-contents">
    <h3>
      <a @click="openBoadCommentModal"><img src="/static/images/info_icon.png">info</a>
      <img src="/static/images/open_close_icon.png" class="info-content-toggle" @click="infoContentToggle" />
    </h3>
    <div v-if="infosNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div
      v-else
      v-for="boadComment in boadComments"
      :key="boadComment.id">
      <horus-left-column-info-content
        :infoContent="boadComment"
        :key="'info' + boadComment.id"
        :type="'boadComment'"
        :contentOpen="boadCommentOpen"
        v-on:showInfoContentPhotoEvent="showInfoContentPhoto"
        v-on:infoContentTitleClickEvent="titleClicked" />
    </div>
  </div>
</template>

<script>
import HorusLeftColumnInfoContent from '@/components/leftColumn/HorusLeftColumnInfoContent.vue'

export default {
  name: 'HorusLeftColumnInfoList',

  components: {
    HorusLeftColumnInfoContent
  },

  props: {
    boadComments: Array,
    infosNowLoading: Boolean
  },

  data () {
    return {
      boadCommentOpen: false
    }
  },

  methods: {
    infoContentToggle () {
      this.boadCommentOpen = !this.boadCommentOpen
    },

    showInfoContentPhoto (infoContent, type, firstShowIndex) {
      this.$emit('showInfoContentPhotoEvent', infoContent, type, firstShowIndex)
    },

    openBoadCommentModal () {
      this.$emit('boadCommentModalOpenEvent')
    },

    titleClicked (infoContent, type) {
      this.$emit('infoContentTitleClickEvent', infoContent, type)
    }
  }
}
</script>

<style lang="scss" scoped>
.left-column-contents {
  h3 {
    margin: 20px 0 5px 0;
    color: #666;
    font-size: 1em;
    text-align: left;
    a {
      color: #666;
      text-decoration: none;
    }
    img {
      margin-right: 5px;
      width: 10px;
    }
    .info-content-toggle {
      width: 15px;
    }
  }
}
</style>
