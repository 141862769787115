<template>
  <div class="horus-manage-area-block">
    <h1 class="centering-title">管理団体</h1>
    <h2>管理団体一覧<button class="create-btn" @click="newAreaBlock">新規管理団体</button></h2>
    <div class="area-block-list">
      <div class="list-column"
        v-for="areaBlock in areaBlocks"
        :key="'areablock' + areaBlock.id"
        >
        <div class="list-line">
          <div class="name"><span>団体名(カナ):</span>{{ areaBlock.name }}({{ areaBlock.name_kana }})</div>
          <div class="short-name"><span>略名(カナ):</span>{{ areaBlock.short_name }}({{ areaBlock.short_name_kana }})</div>
        </div>
        <div class="list-line">
          <div class="zip-code">{{ areaBlock.zip_code }}</div>
          <div class="address">{{ areaBlock.address }}</div>
          <div class="tel">tel:{{ areaBlock.tel }}</div>
          <div class="mail">mail: {{ areaBlock.mail }}</div>
        </div>
        <div class="list-line">
          <div class="controll"><button type="button" class="show-btn" @click="showDetail(areaBlock)">詳細</button></div>
        </div>
      </div>
    </div><!-- organization-list -->
    <horus-manage-area-block-form-modal
      :sourceAreaBlock="null"
      :showModal="areaBlockFormModalDisplay"
      v-on:submitCancelEvent="modalSubmitCancel"
      v-on:submitEvent="modalSubmit"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { AreaBlock } from '@/models'

import HorusManageAreaBlockFormModal from '@/components/manageSystem/areaBlock/HorusManageAreaBlockFormModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageAreaBlockView',

  components: {
    HorusManageAreaBlockFormModal,
    HorusConfirmDialog
  },

  props: {
    systemManager: Object
  },

  data () {
    return {
      areaBlocks: [],
      areaBlockFormModalDisplay: false
    }
  },

  mounted () {
    this.loadAreaBlocks()
  },

  methods: {
    loadAreaBlocks () {
      AreaBlock.api().fetchList().then(aBlocks => {
        this.areaBlocks = aBlocks
      })
    },

    newAreaBlock () {
      this.areaBlockFormModalDisplay = true
    },

    showDetail (areaBlock) {
      this.$router.push({ path: '/system_management/area_block_detail', query: { area_block: areaBlock.id } })
    },

    modalSubmitCancel (callback) {
      this.areaBlockFormModalDisplay = false
      callback()
    },

    modalSubmit (formData, callback) {
      AreaBlock.api().create({ formData: formData }).then(() => {
        this.areaBlockFormModalDisplay = false
        callback()
        this.showNotifyDialog('新規作成完了', '管理団体を新規作成しました。')
        this.loadAreaBlocks()
      }).catch(err => {
        console.error(err.response)
        this.areaBlockFormModalDisplay = false
        callback()
        this.showNotifyDialog('新規作成失敗', '管理団体の新規作成に失敗しました。')
        this.loadAreaBlocks()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-manage-area-block {
  padding: 10px 50px;
  .area-block-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            width: auto;
            font-weight: bold;
          }
          &.short-name {
            // width: 50%;
            font-weight: bold;
          }
          &.zip-code {
            width: auto;
          }
          &.address {
            width: auto;
          }
          &.tel {
            width: auto;
          }
          &.mail {
            width: auto;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
