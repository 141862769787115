<template>
  <div class="nudged-timeline-record-wrapper" v-show="visible">
    <div class="nudged-header">
      <span class="time-display">{{ nudgedDateStr }}</span>
      <img :src="nudgedRecord.author.portrateSrc" class="nudge-author-portrate"/>
      <span class="author-name">{{ nudgedRecord.author.name }}がナッジ</span>
      <div class="nudge-controll" @click="openNudgeControll" v-click-outside="closeNudgeControll">
        <img alt="Nudge_on" src="/static/images/nudge_on.png" class="controll-icon" @click="openNudgeControll" />
        <div class="nudge-cancel-popover" v-show="showNudgeCancelPopover" @click="deleteNudge">
          <div class="popover-content">nudge を取り消す</div>
        </div>
      </div>
    </div>
    <div class="timeline-record-block">
      <div class="record-header">
        <div class="record-head-info">
          <img :src="nudgedRecord.record.author.portrateSrc" class="author-portrate" />
          <div class="record-head-text">
            <div class="record-target-date">{{ recordTargetDateJp }}</div>
            <div class="record-haed-author" @click="showAuthorInfo($event)" v-click-outside="closeAuthorInfo">
              <span class="author-name">{{ nudgedRecord.record.author.name }}</span>
              <span class="author-name-additional">{{ authorAdditionalName }}</span>
            </div>
          </div>
        </div>
        <div class="jump-icon" @click="jumpToOrigin" ><img src="/static/images/jump_icon.png" /></div>
      </div>
      <div class="record-body-box">
        <horus-record-body
          :timelineRecord="nudgedRecord.record"
          :key="'nudgedRecordBody' + nudgedRecord.record.id"
          v-on:durgEntryPhotoClickEvent="showDrugEntryPhoto"
          v-on:timelinePhotoClickEvent="showPhoto"
          v-on:timelineFileClickEvent="openFile" />
      </div>
      <div class="record-footer">
        <div class="record-tags">
          <div class="record-hashtag"  v-for="hashTag in nudgedRecord.record.hashTags" :key="hashTag.id" @click="narrowDownByTag(hashTag)">
            <horus-hash-tag-display :hashTag="hashTag" :sizeType="'small'"/>
          </div>
        </div>
      </div><!-- footer info end -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import vClickOutside from 'click-outside-vue3'

// import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
import HorusRecordBody from '@/components/timelineRecord/HorusRecordBody.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
// import HorusAuthorModal from '@/components/common/HorusAuthorModal.vue'

export default {
  name: 'HorusNudgedTimelineRecord',

  components: {
    // HorusRecordHeadInfo,
    HorusRecordBody,
    HorusHashTagDisplay,
    // HorusAuthorModal
  },

  props: {
    timelineOwner: Object,
    loggedInUser: Object,
    nudgedRecord: Object,
    visible: Boolean,
  },

  data () {
    return {
      showNudgeCancelPopover: false,
      // from HorusRecrodHeadInfo.vue
      currentOpenAuthor: ''
    }
  },

  computed: {
    nudgedDateStr () {
      return moment(this.nudgedRecord.createdAt).format('YYYY/MM/DD HH:mm')
    },

    // from HorusRecrodHeadInfo.vue
    recordTargetDateJp () {
      if (this.nudgedRecord.record.targetAt) {
        return moment(this.nudgedRecord.record.targetAt).format('MM月DD日 HH:mm')
      } else {
        return ''
      }
    },

    authorAdditionalName () {
      if (this.nudgedRecord.record.author.nameAdditional) {
        return '(' + this.nudgedRecord.record.author.nameAdditional + ')'
      } else if (this.nudgedRecord.record.author.id === this.timelineOwner.author_id) {
        return '(本人)'
      }
      return ''
    }
    // targetRecord () {
    //   return this.nudgedRecord.record
    // }
  },

  methods: {
    openNudgeControll () {
      this.showNudgeCancelPopover = true
    },

    closeNudgeControll () {
      this.showNudgeCancelPopover = false
    },

    deleteNudge () {
      this.$emit('deleteNudgeEvent', this.nudgedRecord.record)
    },

    jumpToOrigin () {
      this.$emit('jumpToOriginEvent', this.nudgedRecord.record)
    },

    narrowDownByTag (hashTag) {
      this.$emit('narrowDownByTagEvent', hashTag)
    },

    showPhoto (record, firstShowIndex) {
      this.$emit('showRecorPhotoEvent', record, firstShowIndex)
    },

    showDrugEntryPhoto (record, firstShowIndex) {
      this.$emit('showDrugEntryPhotoEvent', record, firstShowIndex)
    },

    openFile (file) {
      this.$emit('openRecordFileEvent', file)
    },

    // from HorusRecrodHeadInfo.vue
    showAuthorInfo () {
      this.currentOpenAuthor = this.nudgedRecord.record.author.id
      // const targetElm = event.target.parentNode.querySelector('.author-modal')
      // this.$emit('modalOpenEvent', targetElm, () => { this.currentOpenAuthor = '' })
    },

    closeAuthorInfo () {
      this.currentOpenAuthor = null
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>
.nudged-timeline-record-wrapper{
  .nudged-header {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #aaa;
    border-bottom: none;
    background-color: #fff;
    .time-display {
      margin-right: 5px;
      font-size: 0.8em;
      color: #999;
    }
    .nudge-author-portrate {
      margin-right: 5px;
      width: 20px;
    }
    .author-name {
      margin-right: 10px;
      color: #333;
    }
    .nudge-controll {
      position: relative;
      .controll-icon {
        cursor: pointer;
        margin-right: 5px;
        height: 20px;
      }
      .nudge-cancel-popover {
        cursor: pointer;
        position: absolute;
        top: 5px;
        left: 5px;
        width: 150px;
        padding: 5px;
        border: 1px solid #aaa;
        border-radius: 3px;
        background-color: #fff;
        text-align: center;
      }
    }
  }
}
.timeline-record-block {
  margin-bottom: 10px;
  border: 1px solid #aaa;
  background-color: #fff;
  .record-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    .record-head-info {
      display: flex;
      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      &.small {
        img {
          width: 40px;
          height: 40px;
        }
      }
      &.responsible-small {
        @include mediaQuery('phone') {
          img {
            width: 30px;
            height: 30px;
            margin-right: 5px;
          }
        }
      }
    }
    .record-target-date {
      font-size: 1.1em;
      font-weight: bold;
      color: #666;
    }
    .record-haed-author {
      font-size: 0.8em;
      cursor: pointer;
      position: relative;
    }
    .jump-icon {
      cursor: pointer;
      height: 20px;
    }
  }
  .record-body-box {
    padding: 5px 10px;
  }
  .record-footer {
    padding: 5px 10px;
  }
}
</style>
