<template>
  <div class="horus-attribute-in-form">
    <div class="form-list-label">
      {{ labelText }}
      <div class="necessary" v-if="necessary">必須</div>
    </div>
    <div class="form-list-content" :class="validationState">
      <div class="check-icon">
        <img src="/static/images/check_valid.png" class="valid-icon" />
        <img src="/static/images/check_invalid.png" class="invalid-icon" />
        <img src="/static/images/disable_validation.png" class="disable-icon" />
      </div>
      <slot></slot>
      <div class="captions">
        <span class="validation-message">{{ validationMessage }}</span>
        <span class="attribute-notify">{{ attributeNotify }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusAttributeInForm',

  props: {
    labelText: { type: String, default: '' },
    necessary: { type: Boolean, default: false },
    validationState: { type: String, default: '' },
    validationMessage: { type: String, default: '' },
    attributeNotify: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
.horus-attribute-in-form {
  margin: 10px 0;
}
.form-list-label {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
  .necessary {
    margin-left: 10px;
    font-size: 12px;
    color: #f00;
  }
}
.form-list-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .check-icon {
    margin-right: 10px;
    width: 25px;
    .valid-icon {
      width: 25px;
      height: 18px;
    }
    .invalid-icon {
      width: 20px;
      height: 20px;
    }
    .disable-icon {
      width: 20px;
      height: 20px;
    }
  }
  &.valid {
    .invalid-icon {
      display: none;
    }
    .disable-icon {
      display:none;
    }
  }
  &.invalid {
    .valid-icon {
      display: none;
    }
    .disable-icon {
      display: none;
    }
    input {
      border-color: #c53d43;
    }
    textarea {
      border-color: #c53d43;
    }
  }
  &.disabled {
    .valid-icon {
      display: none;
    }
    .invalid-icon {
      display: none;
    }
  }
  &.none {
    .valid-icon {
      display: none;
    }
    .invalid-icon {
      display: none;
    }
    .disable-icon {
      display: none;
    }
  }
  .captions {
    margin-left: 10px;
    .validation-message {
      font-size: 12px;
      color: #c53d43;
    }
    .attribute-notify {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
