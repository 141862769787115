<template>
  <div class="horus-confirm-dialog-wrapper" v-show="showDialog">
    <div class="dialog-backdrop" @click="backdropClicked"></div>
    <div class="dialg">
      <div class="dialog-header">
        <img src="/static/images/caution_icon.png" v-if="titleIconType === 'caution'" />
        <img src="/static/images/danger_icon.png" v-if="titleIconType === 'danger'"/>
        <span class="title">{{ title }}</span>
      </div>
      <div class="dialog-body" v-html="messageHtml"></div>
      <div class="dialog-footer">
        <button class="cancel" v-if="confirmTypeChoice" @click="negativeClicked" ref="cencelBtn">{{ negativeMessage }}</button>
        <button class="confirm" @click="positiveClicked" ref="confirmBtn">{{ positiveMessage }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  name: 'HorusConfirmDialog',

  props: {
    showDialog: Boolean,
    title: String,
    message: String,
    dialogType: Number
  },

  computed: {
    messageHtml () {
      if (this.message) {
        return this.message.replace(/\n/g, '<br />')
      }
      return ''
    },

    titleIconType () {
      if (this.dialogType === DIALOG_TYPE.CONFIRM ||
        this.dialogType === DIALOG_TYPE.ACCEPT ||
        this.dialogType === DIALOG_TYPE.EXECUTE ||
        this.dialogType === DIALOG_TYPE.YES_NO
      ) {
        return 'caution'
      } else if (this.dialogType === DIALOG_TYPE.NOTIFY) {
        return 'danger'
      }
      return 'none'
    },

    confirmTypeChoice () {
      if (this.dialogType < 10) {
        return false
      }
      return true
    },

    negativeMessage () {
      switch (this.dialogType) {
        case DIALOG_TYPE.CONFIRM:
          return ''
        case DIALOG_TYPE.ACCEPT:
          return '拒否'
        case DIALOG_TYPE.YES_NO:
          return 'いいえ'
        case DIALOG_TYPE.EXECUTE:
          return 'やめる'
        default:
          return ''
      }
    },

    positiveMessage () {
      switch (this.dialogType) {
        // case DIALOG_TYPE.CONFIRM:
        //   return 'OK'
        // case DIALOG_TYPE.NOTIFY:
        //   return 'OK'
        // case DIALOG_TYPE.MESSAGE:
        //   return 'OK'
        case DIALOG_TYPE.ACCEPT:
          return '了承'
        case DIALOG_TYPE.YES_NO:
          return 'はい'
        case DIALOG_TYPE.EXECUTE:
          return '実行'
        default:
          return 'OK'
      }
    }
  },

  watch: {
    showDialog: { handler: 'setFocus' }
  },

  methods: {
    backdropClicked () {
      this.$emit('negativeResultEvent')
    },

    negativeClicked () {
      this.$emit('negativeResultEvent')
    },

    positiveClicked () {
      this.$emit('positiveResultEvent')
    },

    setFocus () {
      if (this.showDialog) {
        console.log('call setfocus')
        if (this.confirmTypeChoice) {
          console.log('gonna set forcus for cancel')
          this.$nextTick(() => { this.$refs.cencelBtn.focus() })
        } else {
          console.log('gonna set forcus for confirm')
          this.$nextTick(() => { this.$refs.confirmBtn.focus() })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-confirm-dialog-wrapper {
  .dialog-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.2;
  }
  .dialg {
    position: absolute;
    width: 600px;
    max-height: calc(100vh - 250px);
    margin: 0;
    padding: 0;
    top: 80px;
    left: calc((100vw - 600px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100vh - 100px);
      left: 5px;
    }
    .dialog-header {
      padding: 10px 10px;
      text-align: center;
      img {
        height: 18px;
        margin-left: 10px;
        vertical-align: middle;
        opacity: 0.8;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #666;
      }
    }
    .dialog-body {
      margin: 0 auto;
      padding: 10px 30px;
      max-width: 100%;
      font-size: 16px;
    }
    .dialog-footer {
      margin: 10px;
      text-align: center;
      button {
        margin: 0 5px;
        font-size: 16px;
        width: 100px;
        border-radius: 3px;
        font-weight: bold;
      }
      .cancel {
        border: 1px solid #999;
        background-color: #fff;
        color: #666;
      }
      .confirm {
        border: 1px solid #999;
        background-color: #666;
        color: #fff;
      }
    }
  }
}
</style>
