<template>
  <div class="horus-quick-qr-display-modal" v-show="showModal">
    <div class="horus-qr-display-backdrop"></div>
    <div class="horus-qr-display-modal-frame">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/qr_icon.png" />かんたん登録QR
        </div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="qr-display-notify">
          このQRの読み取りで、家族登録、かかりつけ登録、設定代行登録が行なえます。<br/>
          利用期限までに登録してほしい相手にこのQRを見せてください。
        </div>
        <div class="expire-limit">読取り期限切れまであと<span class="time-display">{{ expireLimitStr }}</span></div>
        <div class="qr-display-column" v-if="quickAgreementCode">
          <vue-qrcode
            :value="quickAgreementCode"
            :options="{
              errorCorrectionLevel: 'Q',
              width: 300,
            }"
          ></vue-qrcode>
        </div>
        <div class="expired-message-column" v-show="expireLimit <= 0 && !quickAgreementAction">
          かんたん登録QRの読取り期限が切れました。
          <div class="execute-controlls">
            <button class="reject" @click="closeModal">表示をやめる</button>
            <button class="agree" @click="reCreateQR">QRを再取得</button>
          </div>
        </div>
        <div class="message-column" v-show="showMessageColumn">
          <div class="error-message" v-show="showErrorMessage">{{ errorMessage }}</div>
          <div class="action-display" v-if="quickAgreementAction && !finished">
            <strong>{{ quickAgreementAction.name }}</strong>が<strong>{{ quickAgreementAction.action_name }}</strong>をしています。<br/>
            下記の同意事項を確認して、承認してください。<br/>
            <div class="relation-action-notify" v-if="actionTypeRelation">
              <div class="notify_for_admit">
                <h1>かかりつけ施設の登録における確認事項</h1>
                <ul>
                  <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                  <li>かかりつけ施設に登録することをあなたが同意していること</li>
                  <li>かかりつけ施設に登録するとかかりつけとなった施設の職員は、あなたのタイムラインや特記事項の閲覧や書き込みができるようになります（詳しくは利用規約の第２条及び第８条をご確認ください）</li>
                  <li>かかりつけ施設の職員があなたのタイムラインや特記事項に書き込む情報の内容について、説明を受け同意した上でかかりつけ設定を行ってください</li>
                </ul>
              </div>
            </div>
            <div class="deputize-action-notify" v-if="actionTypeDeputize">
              <div class="notify_for_admit">
                <h1>設定代行施設の登録における確認事項</h1>
                <ul>
                  <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                  <li>
                    設定代行施設への委任する機能は下記の通りであること
                    <ol>
                      <li>本システムの利用者として、利用申込の申請並びに利用停止申請及び利用者登録の抹消申請を行うこと。</li>
                      <li>利用者本人の利用者情報の閲覧及び編集を行うこと。</li>
                      <li>家族申請、他の利用者からの家族申請の承認及び家族の削除申請を行うこと。</li>
                      <li>施設の設定及び停止を行うこと。</li>
                      <li>利用者本人が利用者情報の閲覧及び編集をできるようにすること。</li>
                      <li>本書面の委任事項を他の施設に再委任すること（但し、再委任先への委任状の作成は利用者本人による）。</li>
                    </ol>
                  </li>
                  <li>依頼元施設に提出する委任状の委任事項について同意していること</li>
                  <li>依頼元施設に委任状を提出していること</li>
                  <li>ナラティブブックであなたの情報を共有してほしい家族や施設を追加したいときは、設定代行施設に連絡すること</li>
                </ul>
              </div>
            </div>
            <div class="deputize-action-notify" v-if="actionTypeDeputizeAgent">
              <div class="notify_for_admit">
                <h1>代理者の登録における確認事項</h1>
                <ul>
                  <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                  <li>
                    代理者への委任する機能は下記の通りであること
                    <ol>
                      <li>本システムの利用者として、利用申込の申請並びに利用停止申請及び利用者登録の抹消申請を行うこと。</li>
                      <li>利用者本人の利用者情報の閲覧及び編集を行うこと。</li>
                      <li>家族申請、他の利用者からの家族申請の承認及び家族の削除申請を行うこと。</li>
                      <li>施設の設定及び停止を行うこと。</li>
                      <li>利用者本人が利用者情報の閲覧及び編集をできるようにすること。</li>
                      <li>本書面の委任事項を他の施設または利用者に再委任すること。</li>
                    </ol>
                  </li>
                  <li>ナラティブブックであなたの情報を共有してほしい家族や施設を追加したいときは、代理者に連絡すること</li>
                </ul>
              </div>
            </div>
            <div class="family-action-notify" v-if="actionTypeFamily">
              <div class="notify_for_admit">
                <h1>家族登録における同意事項</h1>
                <ul>
                  <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                  <li>ナラティブブックで家族設定することに同意していること</li>
                  <li>なりすまし防止のために、依頼元の方との確認ができていること</li>
                  <li>家族に登録すると家族となった利用者は、あなたのタイムラインや特記事項の閲覧や書き込みができるようになります（詳しくは利用規約の第２条及び第７条をご確認ください）
                    ただし、上記の「私のナラティブブックは見せない」をチェックして承認した場合は、家族となった利用者があなたのタイムラインや特記事項を閲覧したり書き込んだりすることはできません</li>
                </ul>
              </div>
            </div>
            <div class="admit-agreement" @click="toggleAdmit">
              <horus-custom-check-box
                :active="admitAgreement"
                :enabled="true"
                :type="'color'"
                />
              上記確認事項を確認しました
            </div>
            <div class="execute-controlls">
              <button class="reject" @click="rejectAction">拒否</button>
              <button class="agree" :class="{disable: !admitAgreement}" @click="agreeAction">承認</button>
            </div>
          </div>
          <div class="finished-message" v-if="finished">
            {{ this.quickAgreementAction.name }}からの{{ this.quickAgreementAction.action_name }}を完了しました。<br/>
            <button class="finished-btn" @click="closeModal">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueQrcode from 'vue-qrcode'
import VueQrcode from '@chenfengyuan/vue-qrcode'

import {
  QuickAgreementCode,
  QuickAgreementAction
} from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as QUICK_ACTION_TYPE from '@/assets/constants/quickActionType.js'

export default {
  name: 'HorusQuickQrDisplayModal',

  components: {
    VueQrcode,
    HorusCustomCheckBox
  },

  props: {
    showModal: Boolean,
    signinUser: Object
  },

  watch: {
    showModal: { handler: 'initData', immediate: true }
  },

  data () {
    return {
      poolingIntervalID: null,
      quickAgreementCode: null,
      quickAgreementAction: null,
      showMessageColumn: false,
      showErrorMessage: false,
      errorMessage: '',
      admitAgreement: false,
      submitProgress: false,
      finished: false,
      expireLimit: 0,
      expireCountdownID: null
    }
  },

  beforeUnmount () {
    this.stopPoolingAction()
  },

  computed: {
    expireLimitStr () {
      if (this.quickAgreementCode) {
        return ('00' + Math.floor(this.expireLimit / 60)).slice(-2) + ':' + ('00' + this.expireLimit % 60).slice(-2)
      } else {
        return '--:--'
      }
    },

    actionTypeFamily () {
      if (this.quickAgreementAction && this.quickAgreementAction.action_type === QUICK_ACTION_TYPE.QUICK_ACTION_FAMILY) {
        return true
      }
      return false
    },

    actionTypeRelation () {
      if (this.quickAgreementAction && this.quickAgreementAction.action_type === QUICK_ACTION_TYPE.QUICK_ACTION_RELATION) {
        return true
      }
      return false
    },

    actionTypeDeputize () {
      if (this.quickAgreementAction && this.quickAgreementAction.action_type === QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE) {
        return true
      }
      return false
    },

    actionTypeDeputizeAgent () {
      if (this.quickAgreementAction && this.quickAgreementAction.action_type === QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE_AGENT) {
        return true
      }
      return false
    }
  },

  methods: {
    initData () {
      if (this.showModal) {
        this.quickAgreementCode = null
        this.quickAgreementAction = null
        this.showMessageColumn = false
        this.showErrorMessage = false
        this.errorMessage = ''
        this.admitAgreement = false
        this.submitProgress = false
        this.finished = false
        this.admitAgreement = false
        // for view test
        // this.quickAgreementAction = {
        //   id: 0,
        //   name: '山田権助クリニック',
        //   action_type: 10,
        //   action_name: 'かかりつけ設定',
        //   agreed: true
        // }
        // this.finished = true
        this.fetchQuickCode()
      }
    },

    startPoolingAction () {
      if (this.poolingIntervalID) {
        clearInterval(this.poolingIntervalID)
      }
      if (this.expireCountdownID) {
        clearInterval(this.expireCountdownID)
      }
      if (this.quickAgreementCode) {
        // start pooling
        this.poolingIntervalID = setInterval(() => {
          this.fetchQuickAction()
        }, 5000)
        this.expireCountdownID = setInterval(() => {
          this.countDownExpire()
        }, 1000)
      }
    },

    stopPoolingAction () {
      if (this.poolingIntervalID) {
        clearInterval(this.poolingIntervalID)
      }
    },

    countDownExpire () {
      if (this.expireLimit > 0) {
        this.expireLimit -= 1
      } else {
        clearInterval(this.expireCountdownID)
        this.stopPoolingAction()
      }
    },

    reCreateQR () {
      this.initData()
    },

    fetchQuickCode () {
      QuickAgreementCode.create({ targetUserID: this.signinUser.user_id }).then(res => {
        console.log('fetchQuickCode')
        console.log(res)
        if (res.data.code) {
          this.quickAgreementCode = res.data.code
          this.expireLimit = 300
          this.startPoolingAction()
        }
      }).catch(error => {
        console.error(error)
        this.showError('かんたんQRの取得に失敗しました')
      })
    },

    fetchQuickAction () {
      if (this.quickAgreementCode) {
        QuickAgreementCode.fetchAction({ quickCode: this.quickAgreementCode }).then(res => {
          console.log(res)
          if (res.quickAgreementAction) {
            this.quickAgreementAction = res.quickAgreementAction
            this.showMessageColumn = true
            this.stopPoolingAction()
            clearInterval(this.expireCountdownID)
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },

    showError (message) {
      this.errorMessage = message
      this.showErrorMessage = true
      this.showMessageColumn = true
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    rejectAction () {
      this.closeModal()
    },

    agreeAction () {
      if (this.admitAgreement && !this.submitProgress) {
        this.submitProgress = true
        QuickAgreementAction.agree({ targetUserID: this.signinUser.user_id, targetID: this.quickAgreementAction.id }).then(res => {
          if (this.quickAgreementAction.id === res.quickAgreementAction.id) {
            this.finished = true
          }
        }).catch(error => {
          console.error(error)
          this.showError(this.quickAgreementAction.name + 'からの' + this.quickAgreementAction.action_name + 'に失敗しました')
        })
      }
    },

    closeModal () {
      this.stopPoolingAction()
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-quick-qr-display-modal {
  position: absoluete;
  top: 0;
  left: 0;
  .horus-qr-display-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .horus-qr-display-modal-frame {
    position: absolute;
    top: 30px;
    left: calc((100vw - (600px - 40px)) / 2);
    width: calc(600px - 40px);
    // height: calc(100vh - 100px);
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    filter: drop-shadow(3px 3px 5px rgba(0,0,0,.5));
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: 100vw;
      // height: calc(100vh - 80px);
      top: 0;
      left: 0;
    }
    .modal-header {
      display: flex;
      margin: 10px 5px;
      .title {
        width: 100%;
        text-align: center;
        img {
          width: 15px;
        }
      }
      .close-btn {
        text-align: right;
        height: 30px;
        img {
          cursor: pointer;
        }
        .jump-to-origin {
          margin-right: 20px;
          vertical-align: top;
        }
      }
    }
    .modal-body {
      width: calc(100% - 20px);
      height: 450px;
      padding: 10px;
      position: relative;
      .qr-display-notify {
        padding: 10px;
        border: 1px solid #aaa;
      }
      .expire-limit {
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
        .time-display {
          font-size: 1.5em;
        }
      }
      .qr-display-column {
        text-align: center;
      }
      .expired-message-column {
        position: absolute;
        width: 400px;
        top: 150px;
        left: 80px;
        padding: 10px;
        border: 1px solid #aaa;
        border-radius: 3px;
        background-color: #fff;
        filter: drop-shadow(3px 3px 5px rgba(0,0,0,.5));
        @include mediaQuery('phone') {
          width: calc(100% - 40px);
          top: 180px;
          left: 10px;
        }
      }
      .message-column {
        position: absolute;
        width: 400px;
        top: 0;
        left: 80px;
        padding: 10px;
        border: 1px solid #aaa;
        border-radius: 3px;
        background-color: #fff;
        filter: drop-shadow(3px 3px 5px rgba(0,0,0,.5));
        @include mediaQuery('phone') {
          width: calc(100% - 40px);
          top: 0;
          left: 10px;
        }
        .error-message {
          width: 100%;
          text-align: center;
        }
        .action-display {
          font-size: 1.1em;
          line-height: 1.6em;
          max-height: calc(100vh - 200px);
          overflow: auto;
          .notify_for_admit {
            font-size: 14px;
            line-height: 1.15;
          }
        }
        .finished-message {
          width: 100%;
          font-size: 1.1em;
          line-height: 1.6em;
          text-align: center;
          .finished-btn {
            width: 100px;
            margin: 5px;
            padding: 3px;
            border: 1px solid #ddd;
            border-radius: 3px;
            background-color: #ddd;
            color: #fff;
            font-weight: bold;
            font-size: 13px;
            border-color: #999;
            background-color: #999;
          }
        }
      }
      .execute-controlls {
        display: flex;
        justify-content: space-around;
        button {
          width: 100px;
          margin: 5px;
          padding: 3px;
          border: 1px solid #ddd;
          border-radius: 3px;
          background-color: #ddd;
          color: #fff;
          font-weight: bold;
          font-size: 13px;
          &.reject {
            border-color: #c43d53;
            background-color: #c43d53;
          }
          &.agree {
            border-color: #5cb85b;
            background-color: #5cb85b;
            color: #fff;
            &.disable {
              border-color: #999;
              background-color: #999;
              color: #ddd;
            }
          }
        }
      }
    }
  }
}
</style>
