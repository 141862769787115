export const ENTRY = 0
export const CONFIRM_MAIL_SENT = 10
export const RECEIVE_CONFIRM_TOKEN = 15
export const MAIL_CONFIRMED = 20
export const MAIL_CONFIRM_FAILED = 25
export const PROFILE_INPUT = 30
export const FINISH = 100
export const PAGE_STEP_CHECK_MAIL = 'check_mail'
export const PAGE_STEP_CONFIRM_MAIL = 'confirm_mail'
export const PAGE_STEP_INPUT_PROFILE = 'input_profile'
export const PAGE_STEP_FINISH = 'finish'
