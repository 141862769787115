<template>
  <div class="horus-reporter-portal-view">
    <div class="horus-reporter-portal-frame">
      <div class="horus-reporter-portal-inner-frame">
        <div class="horus-reporter-portal-content">
          <div class="reporter-portal-controll">
            <input type="text" class="search-user-input" placeholder="絞込(かな)" v-model="searchText"/>
            <div class="controll-buttons">
              <img src="/static/images/name_index_on_icon.png" class="name-index-btn" v-show="!nameIndexOn" @click="toggleNameIndex" />
              <img src="/static/images/name_index_off_icon.png" class="name-index-btn" v-show="nameIndexOn" @click="toggleNameIndex" />
              <img src="/static/images/grid_layout_icon.png" class="layout-btn" v-show="!layoutGrid" @click="toggleUserLayoutGrid" />
              <img src="/static/images/line_layout_icon.png" class="layout-btn" v-show="layoutGrid" @click="toggleUserLayoutGrid" />
              <!-- <select class="user-display-type-select" v-model="userDisplayLimitType">
                <option v-bind:value="LIMIT_TYPE_LIMITED">一部表示</option>
                <option v-bind:value="LIMIT_TYPE_NONE">全表示</option>
              </select> -->
            </div>
          </div><!-- controll end -->
          <div class="user-list" v-if="!nameIndexOn">
            <div class="list-inside-box">
              <horus-reporter-related-user-column
                v-for="user in relatedUsers"
                v-show="isLimited(user)"
                :key="'reporterPortalUserList' + user.author_id"
                :user="user"
                :layoutGrid="layoutGrid"
                v-on:showRecordHistoryEvent="showRecordHistory"
                />
            </div>
          </div>
          <div  class="user-indexed" v-if="nameIndexOn">
            <div class="jump-index">
              <button class="jump-index-btn"><a href="#name-index-0">ア</a></button>
              <button class="jump-index-btn"><a href="#name-index-1">カ</a></button>
              <button class="jump-index-btn"><a href="#name-index-2">サ</a></button>
              <button class="jump-index-btn"><a href="#name-index-3">タ</a></button>
              <button class="jump-index-btn"><a href="#name-index-4">ナ</a></button>
              <button class="jump-index-btn"><a href="#name-index-5">ハ</a></button>
              <button class="jump-index-btn"><a href="#name-index-6">マ</a></button>
              <button class="jump-index-btn"><a href="#name-index-7">ヤ</a></button>
              <button class="jump-index-btn"><a href="#name-index-8">ラ</a></button>
              <button class="jump-index-btn"><a href="#name-index-9">ワ</a></button>
              <button class="jump-index-btn"><a href="#name-index-10">他</a></button>
            </div>
            <div class="user-list-indexed">
              <div class="index-column"
                v-for="(indexColumn, index) in aiueoArray"
                :key="'indexColumn' + indexColumn.name">
                <div class="index-title"><a :name="'name-index-' + index">{{ indexColumn.name }}</a></div>
                <div class="indexed-users">
                  <horus-reporter-related-user-column
                    v-for="user in indexColumn.users"
                    v-show="isLimited(user)"
                    :key="'reporterPortalIndexedUser' + user.author_id"
                    :user="user"
                    :layoutGrid="layoutGrid"
                    v-on:showRecordHistoryEvent="showRecordHistory"
                    />
                </div>
              </div>
            </div>
          </div><!-- user-indexed -->
        </div><!-- horus-reporter-portal-content -->
      </div><!-- horus-reporter-portal-inner-frame -->
      <horus-footer />
    </div>
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author
} from '@/models'
import {
  ReporterOrganizationRelatedUser,
  ReporterPortalConfiguration
} from '@/api'

import HorusReporterRelatedUserColumn from '@/components/reporterOrganization/HorusReporterRelatedUserColumn.vue'

import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusReporterPortalView',

  components: {
    HorusReporterRelatedUserColumn,
    HorusConfirmDialog,
    HorusFooter
  },

  props: {
    loggedInUser: Object,
    signinReporter: Object,
    reporterOrganization: Object,
    loggedInAuthor: Object
  },

  data () {
    return {
      nameIndexOn: false,
      layoutGrid: true,
      userDisplayLimited: false,
      userDisplayLimitType: 1,
      searchText: '',
      portaConfig: null,
      relatedUsers: [],
      aiueoArray: []
    }
  },

  created () {
    this.LIMIT_TYPE_NONE = 0
    this.LIMIT_TYPE_LIMITED = 1
  },

  watch: {
    signinReporter: { handler: 'initObjects', immediate: true }
  },

  methods: {
    async initObjects () {
      await this.loadRelatedUsers()
      await this.loadPortalConfig()
    },

    async loadRelatedUsers () {
      let rel = await ReporterOrganizationRelatedUser.fetch()
      var unloadAuthors = []
      for(var uStat of rel.related_user_status) {
        if (!Author.find(uStat.author_id)) {
          if (unloadAuthors.indexOf(uStat.author_id) < 0) unloadAuthors.push(uStat.author_id)
        }
      }
      if (unloadAuthors.length > 0) {
        await Author.api().fetchByID({ idList: unloadAuthors })
      }
      for(var userStatus of rel.related_user_status) {
        userStatus.author = await Author.find(userStatus.author_id)
      }
      this.relatedUsers = rel.related_user_status
      this.relatedUsers.sort((f, s) => {
        if (f.author.nameKana > s.author.nameKana) {
          return 1
        } else if (f.author.nameKana < s.author.nameKana) {
          return -1
        } else {
          return 0
        }
      })
      this.prepareAiueo()
    },

    prepareAiueo () {
      this.aiueoArray = [
        {name: 'ア', users: []},
        {name: 'カ', users: []},
        {name: 'サ', users: []},
        {name: 'タ', users: []},
        {name: 'ナ', users: []},
        {name: 'ハ', users: []},
        {name: 'マ', users: []},
        {name: 'ヤ', users: []},
        {name: 'ラ', users: []},
        {name: 'ワ', users: []},
        {name: '他', users: []}
      ]
      var indexArray = ['アイウエオ', 'カキクケコガギグゲゴ', 'サシスセソザジズゼゾ', 'タチツテトダヂヅデド', 'ナニヌネノ', 'ハヒフヘホバビブベボパピプペポ', 'マミムメモ', 'ヤユヨ', 'ラリルレロ', 'ワヲン']
      this.relatedUsers.forEach(user => {
        var exist = false
        indexArray.forEach((kanaSet, index) => {
          if (kanaSet.indexOf(user.author.nameKana[0]) >= 0) {
            this.aiueoArray[index].users.push(user)
            exist = true
          }
        })
        if (!exist) {
          this.aiueoArray[10].users.push(user)
        }
      })
    },

    async loadPortalConfig () {
      await ReporterPortalConfiguration.fetch().then(rel => {
        if (rel.portal_configuration.display_style === 0) {
          this.layoutGrid = true
        } else {
          this.layoutGrid = false
        }
        if (rel.portal_configuration.indexing_element === 0) {
          this.nameIndexOn = false
        } else {
          this.nameIndexOn = true
        }
      })
    },

    isLimited (user) {
      if (this.searchText.length > 0) {
        var fixedSarchText = this.searchText.replace(/[ぁ-ん]/g, (s) => {
          return String.fromCharCode(s.charCodeAt(0) + 0x60)
        })
        var contentText = user.author.name + user.author.nameKana + user.author.address
        if (contentText.indexOf(fixedSarchText) >= 0) {
          return true
        } else {
          return false
        }
      } else if (this.userDisplayLimited) {
        if (this.limitedUsers) {
          if (this.limitedUsers.some(lu => lu.user_id === user.user_id)) {
            return true
          } else {
            return false
          }
        }
      }
      return true
    },

    toggleNameIndex () {
      this.nameIndexOn = !this.nameIndexOn
      this.portalConfigUpdate()
    },

    toggleUserLayoutGrid () {
      this.layoutGrid = !this.layoutGrid
      this.portalConfigUpdate()
    },

    portalConfigUpdate () {
      var formData = new FormData()
      var displayStyle = 1
      if (this.layoutGrid) {
        displayStyle = 0
      }
      var indexingElement = 0
      if (this.nameIndexOn) {
        indexingElement = 1
      }
      formData.append('reporter_portal_configuration[display_style]', displayStyle)
      formData.append('reporter_portal_configuration[indexing_element]', indexingElement)
      ReporterPortalConfiguration.update({ formData: formData })
    },

    showRecordHistory (user) {
      this.$router.push('/post_history/' + user.user_id)
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-reporter-portal-view {
  height: calc(100% - 50px);
  background-color: #f5f5f5;
  .horus-reporter-portal-frame {
    height: calc(100% - 36px);
    overflow: auto;
  }
  .horus-reporter-portal-inner-frame {
    display: flex;
  }
  .horus-reporter-portal-content {
    padding: 10px 20px 0;
    width: 100%;
    height: calc(100% - 10px);
    @include mediaQuery('phone') {
      padding: 10px;
    }
    .reporter-portal-controll {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      input.search-user-input {
        font-size: 16px;
        height: 25px;
        @include mediaQuery('phone') {
          width: 100px;
        }
      }
      .controll-buttons {
        display: flex;
        img {
          margin-left: 20px;
          height: 40px;
          width: 40px;
          @include mediaQuery('phone') {
            margin-left: 10px;
          }
        }
        button {
          margin-left: 20px;
          background-color: #999;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
          @include mediaQuery('phone') {
            margin-left: 10px;
          }
        }
        .user-display-type-select {
          margin-left: 10px;
          padding: 10px;
          height: 40px;
          border-radius: 3px;
          font-weight: bold;
        }
      }
    }
    .user-list {
      height: calc(100% - 40px);
      overflow: auto;
      .list-inside-box {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        align-content: flex-start;
        margin-top: 10px;
      }
    }
    .user-indexed {
      margin-top: 10px;
      height: calc(100% - 60px);
      .jump-index {
        button {
          margin-right: 10px;
          padding: 5px 8px;
          border: 1px solid #999;
          border-radius: 3px;
          background-color: #999;
          color: #fff;
          font-weight: bold;
          font-size: 15px;
          @include mediaQuery('phone') {
            margin-bottom: 10px;
          }
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
      .user-list-indexed {
        height: calc(100% - 20px);
        // overflow: scroll;
        @include mediaQuery('phone') {
          height: calc(100% - 78px);
        }
        .index-title {
          margin: 10px 0;
          padding: 0 10px;
          border-bottom: 1px solid #aaa;
          font-weight: bold;
          font-size: 15px;
          color: #666;
          a {
            color: inherit;
            text-decoration: none;
          }
        }
        .indexed-users {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
        }
      }
    }
  }
  .user-task-column {
    display: none;
    &.show-task {
      display: block;
      width: 360px;
      @include mediaQuery('phone') {
        width: 100%;
      }
    }
  }
}
</style>
