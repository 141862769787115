<template>
  <div class="horus-critical-notification-form-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />{{ titleString }}</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'警告種別'"
          :necessary="true"
          :validationState="notificationTypeValidation.state"
          :validationMessage="notificationTypeValidation.message"
          :attributeNotify="''">
          <select class="notification-type-select" v-model="notificationType" @change="notificationTypeChanged">
            <option v-for="notifType in CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_TYPES" :key="'notificationTypeSelection' + notifType[0]" :value="notifType[0]">{{ notifType[1] }}</option>
          </select>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'解決種別'"
          :necessary="true"
          :validationState="resolveTypeValidation.state"
          :validationMessage="resolveTypeValidation.message"
          :attributeNotify="''">
          <select class="notification-type-select" v-model="resolveType" @change="resolveTypeChanged">
            <option v-for="resType in CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPES" :key="'resolveTypeSelection' + resType[0]" :value="resType[0]">{{ resType[1] }}</option>
          </select>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'ログイン停止'"
          :necessary="true"
          :validationState="'none'"
          :validationMessage="blockSigninNotification"
          :attributeNotify="''"
          >
          <span @click="toggleBlockSignin">
            <horus-custom-check-box
              :active="blockSignin"
              :enabled="true"
              :type="'color'"
              />
            <span>ログインできなくする</span>
          </span>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'タイトル'"
          :necessary="true"
          :validationState="titleValidation.state"
          :validationMessage="titleValidation.message"
          :attributeNotify="''">
          <input type="text" class="title-text" v-model="title" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'本文'"
          :necessary="true"
          :validationState="messageValidation.state"
          :validationMessage="messageValidation.message"
          :attributeNotify="''">
          <textarea v-model="message" rows="5"></textarea>
        </horus-attribute-in-form>
        <div class="target-selection-column">
          <h3>警告対象の指定</h3>
          <div class="area-block-selection-column">
            <label class="area-block-selection">
              <input type="radio" v-model="areaBlockID" :value="null" />管理団体を指定しない</label>
            <label class="area-block-selection" v-for="areaBlock in areaBlocks" :key="'areaBlockSelection' + areaBlock.id">
              <input type="radio" v-model="areaBlockID" :value="areaBlock.id" />{{ areaBlock.name }}
            </label>
          </div>
          <div class="selected-organization-column">
            <!-- <button class="add-organization-btn" @click="addOrganization">施設を指定</button> -->
            <div class="selected-organizations">
              <div class="selected-organization"
                v-for="selectedOrganization in selectedOrganizations"
                :key="'selectedOrganization' + selectedOrganization.id"
                @click="removeOrganization(selectedOrganization)">
                {{ selectedOrganization.shortName }}
              </div>
            </div>
          </div>
          <div class="target-type-selection-column" v-show="groupTargetDisplay">
            <div class="notify-message">管理団体及び施設を対象とする場合、対象が施設管理者か、施設職員かを選ぶ必要があります</div>
            <div class="target-type-selections">
              <label class="target-type-selection" v-for="targetType in CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPES" :key="'notifyTargetSelection' + targetType[0]">
                <input type="radio" v-model="groupTargetType" :value="targetType[0]" />{{ targetType[1] }}
              </label>
            </div>
          </div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !readyToSubmit}" @click="submit">{{ submitString }}</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { 
  AreaBlock,
  Organization 
} from '@/models'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

export default {
  name: 'HorusCriticalNotificationFormModal',

  components: {
    HorusAttributeInForm,
    HorusCustomCheckBox,
    HorusModalFrame
  },

  props: {
    showModal: Boolean,
    sourceNotification: Object
  },

  data () {
    return {
      // notification: {},
      notificationType: null,
      resolveType: null,
      resolveURL: '',
      blockSignin: false,
      title: '',
      message: '',
      areaBlockID: null,
      groupTargetType: null,
      CRITICAL_NOTIFICATION_CONSTANTS: CRITICAL_NOTIFICATION_CONSTANTS,
      areaBlocks: [],
      selectedOrganizations: []
    }
  },

  computed: {
    titleString () {
      if (this.sourceNotification) {
        return '重要警告編集'
      } else {
        return '重要警告新規作成'
      }
    },

    submitString () {
      if (this.sourceNotification) {
        return '更新'
      } else {
        return '新規作成'
      }
    },

    notificationTypeValidation () {
      if (CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_TYPES.some(notifType => notifType[0] === this.notificationType)) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '警告種別が範囲内にありません' }
      }
    },

    resolveTypeValidation () {
      if (CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPES.some(resType => resType[0] === this.resolveType)) {
        if ((this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_MAIL_NOT_VALID ||
            this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_CONFIRM_AGREEMENT ||
            this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_ACCOUNT_BAN) &&
            this.resolveType !== CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET) {
          return { valid: false, state: 'invalid', message: '指定された警告タイプでは、この解決タイプを選択できません。' }
        }
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '解決種別が範囲内にありません' }
      }
    },

    titleValidation () {
      if (this.title && this.title.replace(/(^\s+)|(\s+$)/g, '').length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: 'タイトルは空白にできません' }
      }
    },

    messageValidation () {
      if (this.message && this.message.replace(/(^\s+)|(\s+$)/g, '').length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '本文は空白にできません' }
      }
    },

    blockSigninNotification () {
      if (this.blockSignin) {
        if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_ACCOUNT_BAN) {
          return 'アカウント停止の場合、必ずログイン停止がなされます。対象となるユーザーは解決するまでログインできなくなります。'
        }
        return 'この重要警告が発火した場合、対象となるユーザーは解決するまでログインできなくなります。'
      }
      return ''
    },

    groupTargetDisplay () {
      if (this.areaBlockID || this.selectedOrganizations.length > 0) {
        return true
      }
      return false
    },

    readyToSubmit () {
      if (
        this.notificationTypeValidation.valid &&
        this.resolveTypeValidation.valid &&
        this.titleValidation.valid &&
        this.messageValidation.valid &&
        (!this.groupTargetDisplay || (this.groupTargetDisplay && this.groupTargetType))
      ) {
        return true
      }
      return false
    }
  },

  watch: {
    sourceNotification: { handler: 'initNotification', immediate: true }
  },

  mounted () {
    this.loadAreaBlock()
  },

  methods: {
    initNotification () {
      if (this.sourceNotification) {
        this.notificationType = this.sourceNotification.notification_type
        this.resolveType = this.sourceNotification.resolve_type
        this.title = this.sourceNotification.title
        this.message = this.sourceNotification.message
        this.blockSignin = this.sourceNotification.block_signin
        this.areaBlockID = this.sourceNotification.area_block_id
        this.groupTargetType = this.sourceNotification.group_target_type
        this.loadSelectedOrganizations()
      } else {
        this.notificationType = null
        this.resolveType = null
        this.title = ''
        this.message = ''
        this.blockSignin = false
        this.areaBlockID = null
        this.groupTargetType = null
        this.selectedOrganizations = []
      }
    },

    loadAreaBlock () {
      AreaBlock.api().fetchList().then(res => {
        this.areaBlocks = res.area_blocks
      })
    },

    async loadSelectedOrganizations () {
      if (this.sourceNotification.organizations && this.sourceNotification.organizations.length > 0) {
        var unStoredOrgs = []
        for(var orgID of this.sourceNotification.organizations) {
          if (!Organization.find(orgID)) {
            if (unStoredOrgs.indexOf(orgID) < 0) unStoredOrgs.push(orgID)
          }
        }
        if (!unStoredOrgs.length > 0) {
          await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unStoredOrgs })
        }
        this.selectedOrganizations = Organization.findIn(this.sourceNotification.organizations)
      }
    },

    notificationTypeChanged () {
      if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_MAIL_NOT_VALID) {
        this.resolveType = CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET
      } else if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_CONFIRM_AGREEMENT) {
        this.resolveType = CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET
      } else if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_ACCOUNT_BAN) {
        this.resolveType = CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET
        this.blockSignin = true
      }
    },

    resolveTypeChanged () {},

    toggleBlockSignin () {
      if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_ACCOUNT_BAN) {
        this.blockSignin = true
      } else {
        this.blockSignin = !this.blockSignin
      }
    },

    addOrganization () {
      // think any way to add organization.....
    },

    validResolveURL () {
      if (this.notificationType) {
        if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_MAIL_NOT_VALID) {
          this.resolveURL = CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_URL_MAIL
        } else if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_CONFIRM_AGREEMENT) {
          this.resolveURL = CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_URL_AGREEMENT
        } else if (this.notificationType === CRITICAL_NOTIFICATION_CONSTANTS.NOTIFICATION_ACCOUNT_BAN) {
          this.resolveURL = CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_URL_BAN_EXCUSE
        } else {
          this.resolveURL = CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_URL_ANY
        }
      }
    },

    removeOrganization (selectedOrganization) {
      var tagIndex = this.selectedOrganizations.indexOf(selectedOrganization)
      if (tagIndex >= 0) {
        this.selectedOrganizations.splice(tagIndex, 1)
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', () => { this.initNotification() })
    },

    submit () {
      if (this.readyToSubmit) {
        this.validResolveURL()
        var formData = new FormData()
        formData.append('critical_notification[notification_type]', this.notificationType)
        formData.append('critical_notification[resolve_type]', this.resolveType)
        formData.append('critical_notification[resolve_url]', this.resolveURL)
        formData.append('critical_notification[title]', this.title)
        formData.append('critical_notification[message]', this.message)
        formData.append('critical_notification[block_signin]', this.blockSignin)
        formData.append('critical_notification[group_target_type]', this.groupTargetType)
        if (this.areaBlockID) {
          formData.append('area_block_id', this.areaBlockID)
        }
        if (this.selectedOrganizations.length > 0) {
          this.selectedOrganizations.forEach(orgID => {
            formData.append('organizaitons[]', orgID)
          })
        }
        this.$emit('submitEvent', formData, () => { this.initNotification() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-critical-notification-form-modal {
  .modal-body {
    padding: 10px 30px;
    .title-text {
      width: calc(100% - 60px);
    }
    textarea {
      width: calc(100% - 60px);
      resize: none;
    }
    .target-selection-column {
      padding: 10px;
      border: 1px solid #aaa;
      h3 {
        margin: 0;
        font-size: 1em;
        font-weight: bold;
      }
      .area-block-selection-column {
        display: flex;
        flex-wrap: wrap;
        margin: 5px 0;
        .area-block-selection {
          margin-right: 20px;
        }
      }
      .target-type-selection-column {
        margin: 5px 0;
        .notify-message {
          font-size: 0.9em;
          color: #c53d43;
        }
        .target-type-selections {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
        }
        .target-type-selection {
          margin-right: 20px;
        }
      }
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      button {
        margin-left: 20px;
        padding: 3px 10px;
        border-radius: 3px;
        font-weight: bold;
        &.cancel-btn {
          border: 1px solid #666;
          color: #666;
          background-color: #fff;
        }
        &.submit-btn {
          border: 1px solid #666;
          color: #fff;
          background-color: #666;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
