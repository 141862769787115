<template>
  <div class="horus-system-management-landing-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="message-column" v-show="messageForUser.length > 0" v-on:click="resetMessageForUser">
      {{ messageForUser }}
    </div>
    <div class="signin-block">
      <h1>システム管理</h1>
      <form id='own_signin_form' v-on:submit.prevent="doSignin">
        <div class="signin_id_input"><label>ログインID</label><input type="text" name="login_id" id='login_id' v-model="inputLoginId"></div>
        <div class="signin_pass_input"><label>パスワード</label><input type="password" name="password" ref="passwordField" id="password" v-model="inputPassword"></div>
        <div class="signin_pass_toggle">パスワード表示<input type="checkbox" id="toggle_password_field" ref="toggle" v-on:change="togglePasswordVisible" /></div>
        <button type="submit" class="btn-success signin-btn">ログイン</button>
      </form>
    </div>
    <div class="signin-button-controlls">
      パスワードを忘れた場合は<a @click="forgetPassword">こちら</a>
    </div>
    <horus-footer/>
  </div>
</template>

<script>
import {
  SystemManager
} from '@/models'

import {
  SystemManageSigninState
} from '@/api'

import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusSystemManagementLandingView',

  components: {
    HorusFooter
  },

  data () {
    return {
      inputLoginId: '',
      inputPassword: '',
      messageForUser: '',
      systemManager: null
    }
  },

  created () {
    this.checkSigninedIn()
  },

  methods: {
    checkSigninedIn () {
      this.systemManager = SystemManager.query().first()
      SystemManageSigninState.currentState().then((data) => {
        if (this.systemManager && data.manager_id === this.systemManager.id) {
          this.$router.push({ path: '/system_management/home' })
        } else {
          SystemManager.deleteAll()
          this.systemManager = null
        }
      })
    },

    resetMessageForUser () {
      // TODO: micro interruction
      this.messageForUser = ''
    },

    togglePasswordVisible (event) {
      const checkbox = event.target
      const passwordField = this.$refs.passwordField
      if (checkbox.checked === true) {
        passwordField.type = 'text'
      } else {
        passwordField.type = 'password'
      }
    },

    doSignin () {
      SystemManager.api().fetch({ loginID: this.inputLoginId, password: this.inputPassword }).then(() => {
        console.log('system signin success.')
        console.log(SystemManager.all())
        this.systemManager = SystemManager.query().first()
        console.log(this.systemManager)
        console.log(SystemManager.find(1))
        this.$router.push({ path: '/system_management/home' })
      }).catch(error => {
        // TODO: micro interruction
        if (error.response.status === 401) {
          this.messageForUser = 'ログインに失敗しました。ログインIDかパスワードが間違っています。'
        } else {
          this.messageForUser = 'ログインに失敗しました。'
        }
      })
    },

    forgetPassword () {
      this.$router.push({ path: '/forgot_system_manager_password' })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-management-landing-view {
  background-color: #eee;
  .navbar {
    .main-bar {
      background-color: #039;
      background-image: url("/static/images/system_header.png");
    }
  }
  .message-column {
    margin: 20px 40px;
    padding: 20px;
    text-align: center;
    border: 1px solid #2b4733;
    color: #2b4733;
  }
  .message-column {
    margin: 20px 40px;
    padding: 20px;
    text-align: center;
    border: 1px solid #692220;
    color: #692220;
    background-color: #fdeff2;
  }
  .signin-block {
    margin: 20px auto;
    padding: 40px;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #aaa;
    text-align: center;
    h1 {
      margin: 0;
      margin-bottom: 10px;
      font-size: 1.2em;
      font-weight: bold;
      color: #666;
    }
    div {
      &.signin_pass_toggle {
        font-size: 12px;
      }
      margin-bottom: 10px;
      label {
        display: inline-block;
        margin-right: 10px;
        width: 100px;
        text-align: right;
        font-weight: bold;
      }
      input {
        font-size: 16px;
      }
    }
    .signin-btn {
      width: 150px;
      font-weight: bold;
    }
  }
  .signin-button-controlls {
    margin: 20px;
    text-align: center;
    line-height: 2em;
    .join-btn {
      font-weight: bold;
    }
  }
}
</style>
