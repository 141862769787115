<template>
  <div class="horus-sent-deputize-invitation-in-manage labeled_content_block">
    <div class="labeled_content_single_block" v-if="sentInvitation.login_id">
      <label>NBID</label>
      <div class="content">{{ sentInvitation.login_id }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentInvitation.name">
      <label>氏名</label>
      <div class="content">{{ sentInvitation.name }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentInvitation.mail">
      <label>メールアドレス</label>
      <div class="content">{{ sentInvitation.mail }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentInvitation.mail">
      <label>期限</label>
      <div class="content">{{ sentInvitationLimitAt }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="sentInvitation.mail">
      <label>本人確認コード</label>
      <div class="content">{{ sentInvitation.short_token }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ sentInvitation.status }}</div>
    </div>
    <div class="content-controll-block">
      <img src="/static/images/send_mail_icon.png" @click="sendMail" />
      <router-link :to="{ name: 'PrintDeputizeInvitation', query: queryForPrint }" target="_blank">
        <img src="/static/images/print.png" @click="showPrintPage" />
      </router-link>
      <img src="/static/images/bucket_icon.png" @click="removeInvitation" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusSentDeputizeInvitationInManage',

  props: {
    sentInvitation: Object,
    organization: Object
  },

  computed: {
    sentInvitationLimitAt () {
      var limitAt = new Date(this.sentInvitation.limit_at)
      if (limitAt) {
        return moment(limitAt).format('YYYY/MM/DD HH:mm:ss')
      }
      return '----/--/-- --:--:--'
    },

    queryForPrint () {
      var nbidFlag = false
      if (this.sentInvitation.login_id && this.sentInvitation.login_id.length > 0) {
        nbidFlag = true
      }
      return {
        organization_name: this.organization.name,
        target_name: this.sentInvitation.name || '',
        target_mail: this.sentInvitation.mail || '',
        short_token: this.sentInvitation.short_token || '',
        nbid: nbidFlag
      }
    }
  },

  methods: {
    sendMail () {
      this.$emit('sendMailEvent', this.sentInvitation)
    },

    showPrintPage () {
      this.$emit('showPrintPageEvent', this.sentInvitation)
    },

    removeInvitation () {
      console.log('in list')
      this.$emit('removeInvitationEvent', this.sentInvitation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
