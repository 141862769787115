<template>
 <div class="horus-footer">
    <p>
      <a href="/term_of_use" target="_blank">ナラティブブック利用規約</a>
      <a href="/static/pages/personal_information.html" target="_blank">利用者の個人情報の取扱について</a>
      <a href="/personal_information_protection_policy" target="_blank">プライバシーポリシー</a>
      <a href="/contact">お問い合せ</a>
    </p>
    <p>Copyright © 2019 NarrativeBook All Rights Reserved.</p>
  </div>
</template>

<style lang="scss" scoped>
.horus-footer {
  padding: 20px;
  border-top: 1px solid #aaa;
  height: 91px;
  p {
    text-align: center;
    font-size: 0.8em;
    color: #666;
    a {
      margin: 0 30px;
      color: #666;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}
</style>
