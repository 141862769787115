<template>
  <div class="horus-related-reporter-organization-view">
    <horus-deputize-sub-header
      :timelineOwner="timelineOwner"
      v-if="deputizing"
      />
    <div class="related-organization-contents" :class="{deputizing: deputizing}">
      <h1 class="centering-title">報告サービス利用設定</h1>
      <div class="organization-list-block">
        <h4>報告サービス利用になっている施設</h4>
        <div class="caption">報告サービス利用設定を行うと、設定したサービス施設の方があなたのタイムラインに書込みのみができるようになります。サービス施設の職員は、あなたのタイムラインを閲覧することはできません。</div>
        <div class="organization-list-column" v-if="!organizationListNowLoading">
          <div class="block-title" @click="organizationListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: organizationListOpen}" />
            施設リスト<span class="caption">施設数:{{ relatedOrganizations.length }}施設</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="organization-list" v-show="organizationListOpen" v-if="relatedOrganizations.length > 0">
              <horus-related-reporter-organization-column
                v-for="org in relatedOrganizations"
                :key="'relatedRepOrg' + org.id"
                :reporterOrganization="org"
                v-on:removeRelationEvent="removeRelatedOrganization"
                />
            </div>
          </transition>
        </div><!-- organization list column end -->
        <div class="now-loading" v-if="organizationListNowLoading">読み込み中...</div>
      </div><!-- organization list block end -->
      <div class="sent-request-list-block">
        <h4>依頼状の送信</h4>
        <div class="caption">施設へサービス利用の登録依頼を送る</div>
        <div class="block-title">
          <button type="button" class="create-request-btn" @click="createNewRequest">依頼状の作成</button>
        </div>
        <div class="sent-list-column" v-if="!sentListNowLoading">
          <div class="block-title" @click="sentListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
            依頼状の送信リスト<span class="caption">送信した依頼の数:{{ sentRequests.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-request-list" v-show="sentListOpen" v-if="sentRequests.length > 0">
              <horus-sent-reporter-request-for-organization-column
                v-for="sentRequest in sentRequests"
                :key="'sentReq' + sentRequest.id"
                :sentRequest="sentRequest"
                v-on:removeRequestEvent="removeSentRequest"
                />
            </div>
          </transition>
        </div><!-- sent request list column end -->
        <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
      </div><!-- sent request list block end -->
      <div class="receive-list-block">
        <h4>案内状の受信</h4>
        <div class="receive-list-column" v-if="!receiveListNowLoading">
          <div class="block-title" @click="receiveRequestListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: receiveListOpen}" />
            案内状の受信リスト<span class="caption notice">受け取った案内の数:{{ receiveRequests.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="receive-request-list" v-show="receiveListOpen">
              <div class="no-receive-notify" v-if="receiveRequests.length <= 0">受信したサービス利用登録案内はありません。</div>
              <div v-if="receiveRequests.length > 0">
                <horus-receive-reporter-request-for-user-column
                  v-for="receiveRequest in receiveRequests"
                  :key="'receiveReq' + receiveRequest.id"
                  :receiveRequest="receiveRequest"
                  v-on:confirmRequestEvent="confirmRequest"
                  />
              </div>
            </div>
          </transition>
        </div><!-- receive list column end -->
        <div class="now-loading" v-if="receiveListNowLoading">読み込み中...</div>
      </div>
      <horus-footer />
    </div>
    <horus-create-reporter-request-for-organization-modal
      :showCreateRequestModal="showCreateRequestModal"
      :relatedOrganizations="relatedOrganizations"
      :sentRequests="sentRequests"
      :receiveRequests="receiveRequests"
      :timelineOwner="timelineOwner"
      v-on:cancelEvent="cancelCreateRequest"
      v-on:submitSendRequestEvent="submitSendRequest"
      />
    <horus-confirm-reporter-request-for-user-modal
      :showConfirmRequestModal="showConfirmRequestModal"
      :request="confirmTargetRequest"
      v-on:admitRequestEvent="admitReceiveRequest"
      v-on:rejectRequestEvent="rejectReceivedRequest"
      v-on:cencelConfirmEvent="cancelConfirmRequest"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  ReporterOrganization,
  Author,
  TimelineOwner
} from '@/models'
import {
  ReporterOrganizationInvitationForOrganization,
  ReporterOrganizationInvitationForUser,
  ReporterOrganizationRelatedUser
} from '@/api'

import HorusRelatedReporterOrganizationColumn from '@/components/relatedReporterOrganization/HorusRelatedReporterOrganizationColumn.vue'
import HorusSentReporterRequestForOrganizationColumn from '@/components/relatedReporterOrganization/HorusSentReporterRequestForOrganizationColumn.vue'
import HorusReceiveReporterRequestForUserColumn from '@/components/relatedReporterOrganization/HorusReceiveReporterRequestForUserColumn.vue'
import HorusCreateReporterRequestForOrganizationModal from '@/components/relatedReporterOrganization/HorusCreateReporterRequestForOrganizationModal.vue'
import HorusConfirmReporterRequestForUserModal from '@/components/relatedReporterOrganization/HorusConfirmReporterRequestForUserModal.vue'

import HorusDeputizeSubHeader from '@/components/navigation/HorusDeputizeSubHeader.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition],

  name: 'HorusRelatedReporterOrganizationView',

  components: {
    HorusDeputizeSubHeader,
    HorusRelatedReporterOrganizationColumn,
    HorusSentReporterRequestForOrganizationColumn,
    HorusReceiveReporterRequestForUserColumn,
    HorusConfirmDialog,
    HorusCreateReporterRequestForOrganizationModal,
    HorusConfirmReporterRequestForUserModal,
    HorusFooter
  },

  props: {
    loggedInUser: Object,
    signinMember: Object
  },

  data () {
    return {
      timelineOwner: null,
      deputizing: false,
      relatedOrganizations: [],
      sentRequests: [],
      receiveRequests: [],
      organizationListNowLoading: true,
      sentListNowLoading: true,
      receiveListNowLoading: true,
      organizationListOpen: false,
      sentListOpen: false,
      receiveListOpen: false,
      confirmTargetRequest: null,
      showConfirmRequestModal: false,
      showCreateRequestModal: false
    }
  },

  watch: {
    '$route': 'initTimelineOwner',
    loggedInUser: { handler: 'initTimelineOwner', immediate: true },
    timelineOwner: { handler: 'initObjects', immediate: true }
  },

  methods: {
    async initTimelineOwner () {
      if (this.$route.params.id) {
        await TimelineOwner.api().fetch({ timelineOwnerID: this.$route.params.id })
        var owner = TimelineOwner.find(this.$route.params.id)
        if (!Author.find(owner.author_id)) {
          await Author.api().fetchByID({ timelineOwnerID: owner.id, idList: [owner.author_id] })
        }
        this.timelineOwner = owner
        if (this.signinMember) {
          this.deputizing = true
        }
      } else if (this.loggedInUser) {
        this.timelineOwner = this.loggedInUser
      }
    },

    async initObjects () {
      if (this.timelineOwner) {
        this.loadRealtedOrganizations()
        this.loadSentRequests()
        this.loadReceiveRequests()
      }
    },

    async loadRealtedOrganizations () {
      this.organizationListNowLoading = true
      ReporterOrganization.api().fetchRelatedIDList({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
        var unloadID = []
        for(var orgID of res.reporter_organizations) {
          if (!ReporterOrganization.find(orgID)) {
            if (unloadID.indexOf(orgID) < 0) unloadID.push(orgID)
          }
        }
        if (unloadID.length > 0) {
          await ReporterOrganization.api().fetchByID({ idList: unloadID })
          this.relatedOrganizations = ReporterOrganization.findIn(res.reporter_organizations)
          this.organizationListNowLoading = false
        } else {
          this.relatedOrganizations = ReporterOrganization.findIn(res.reporter_organizations)
          this.organizationListNowLoading = false
        }
      })
    },

    async loadSentRequests () {
      this.sentListNowLoading = true
      await ReporterOrganizationInvitationForOrganization.fetch({ targetUserID: this.timelineOwner.id }).then(async res => {
        var unloadOrgs = []
        for(var rRel of res.reporter_organization_invitation_for_organizations) {
          if (!ReporterOrganization.find(rRel.reporter_organization_id)) {
            if (unloadOrgs.indexOf(rRel.reporter_organization_id) < 0) unloadOrgs.push(rRel.reporter_organization_id)
          }
        }
        if (unloadOrgs.length > 0) {
          await ReporterOrganization.api().fetchByID({ idList: unloadOrgs })
        }
        for(var rel of res.reporter_organization_invitation_for_organizations) {
          rel.reporter_organization = await ReporterOrganization.find(rel.reporter_organization_id)
        }
        this.sentRequests = res.reporter_organization_invitation_for_organizations
        this.sentListNowLoading = false
      })
    },

    async loadReceiveRequests () {
      this.receiveListNowLoading = true
      await ReporterOrganizationInvitationForUser.fetchReceiveList({ targetUserID: this.timelineOwner.id }).then(async res => {
        var unloadOrgs = []
        for(var rRel of res.reporter_organization_invitation_for_users) {
          if (!ReporterOrganization.find(rRel.reporter_organization_id)) {
            if (unloadOrgs.indexOf(rRel.reporter_organization_id) < 0) unloadOrgs.push(rRel.reporter_organization_id)
          }
        }
        if (unloadOrgs.length > 0) {
          await ReporterOrganization.api().fetchByID({ idList: unloadOrgs })
        }
        for(var rel of res.reporter_organization_invitation_for_users) {
          rel.reporter_organization = await ReporterOrganization.find(rel.reporter_organization_id)
        }
        this.receiveRequests = res.reporter_organization_invitation_for_users
        this.receiveListNowLoading = false
      })
    },

    organizationListToggle () {
      this.organizationListOpen = !this.organizationListOpen
    },

    sentListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    receiveRequestListToggle () {
      this.receiveListOpen = !this.receiveListOpen
    },

    async removeRelatedOrganization (reporterOrganization) {
      this.showConfirmDialog(
        '報告サービス施設のサービス利用を停止します',
        '現在あなたが報告サービスを利用している施設「' + reporterOrganization.name + '」のサービス利用を停止します。\nサービス利用を停止した場合、今後この施設の職員はあなたのタイムラインに書き込みも出来なくなります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          await ReporterOrganizationRelatedUser.destroy({ reporterOrganizationID: reporterOrganization.id, targetUserID: this.timelineOwner.id }).then(() => {
            this.loadRealtedOrganizations()
            this.showNotifyDialog(
              '報告サービス利用の停止完了',
              reporterOrganization.shortName + 'のサービス利用を停止しました。\n再度相手に依頼を送り、承認されるまで、' + reporterOrganization.shortName + 'の職員はあなたのタイムラインに書き込みを行えません。'
            )
          }).catch(error => {
            console.error(error)
            this.loadRealtedOrganizations()
            this.showNotifyDialog(
              'サービス利用の停止失敗',
              reporterOrganization.shortName + 'のサービス利用停止に失敗しました。'
            )
          })
        }
      )
    },

    createNewRequest () {
      this.showCreateRequestModal = true
    },

    cancelCreateRequest () {
      this.showCreateRequestModal = false
    },

    async submitSendRequest (targetOrg, message, callBack) {
      var formData = new FormData()
      formData.append('target_user_id', this.timelineOwner.id)
      formData.append('reporter_organization_id', targetOrg.id)
      formData.append('reporter_organization_invitation_for_organization[message_content]', message)
      await ReporterOrganizationInvitationForOrganization.create({ formData: formData }).then(() => {
        this.loadSentRequests()
        this.showNotifyDialog(
          '依頼状送信完了',
          targetOrg.shortName + '宛てに、サービス利用登録依頼を送信しました。\n相手の承認をお待ちください。'
        )
        this.showCreateRequestModal = false
        callBack()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '依頼状送信失敗',
          targetOrg.shortName + '宛てに、サービス利用登録依頼を送信しようとしましたが、失敗しました。'
        )
        this.showCreateRequestModal = false
        callBack()
      })
    },

    removeSentRequest (request) {
      this.showConfirmDialog(
        'サービス利用依頼を削除します',
        request.reporter_organization.shortName + '宛のサービス利用依頼を削除します。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          await ReporterOrganizationInvitationForOrganization.destroy({ targetUserID: this.timelineOwner.id, targetID: request.id }).then(() => {
            this.loadSentRequests()
            this.showNotifyDialog(
              'サービス利用依頼の削除完了',
              request.reporter_organization.shortName + '宛てのサービス利用依頼を削除しました。'
            )
          }).catch(error => {
            console.error(error)
            this.loadSentRequests()
            this.showNotifyDialog(
              'サービス利用依頼の削除失敗',
              request.reporter_organization.shortName + '宛てのサービス利用依頼の削除に失敗しました。'
            )
          })
        }
      )
    },

    confirmRequest (request) {
      this.confirmTargetRequest = request
      this.showConfirmRequestModal = true
    },

    async admitReceiveRequest (request) {
      await ReporterOrganizationInvitationForUser.admitByID({ targetUserID: this.timelineOwner.id, targetID: request.id }).then(() => {
        this.loadRealtedOrganizations()
        this.loadReceiveRequests()
        this.showNotifyDialog(
          'サービス利用案内の承認完了',
          request.reporter_organization.shortName + 'からのサービス利用登録を承認しました。\n' + request.reporter_organization.shortName + 'の報告サービスを利用開始しました。'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          'サービス利用案内の承認失敗',
          request.reporter_organization.shortName + 'からのサービス利用登録の承認に失敗しました。'
        )
      })
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    },

    async rejectReceivedRequest (request) {
      await ReporterOrganizationInvitationForUser.reject({ targetUserID: this.timelineOwner.id, targetID: request.id }).then(() => {
        this.loadRealtedOrganizations()
        this.loadReceiveRequests()
        this.showNotifyDialog(
          'サービス利用案内の拒否完了',
          request.reporter_organization.shortName + 'からのサービス利用登録を拒否しました。\n' + request.reporter_organization.shortName + 'からのサービス利用案内は破棄されました。'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          'サービス利用案内の拒否失敗',
          request.reporter_organization.shortName + 'からのサービス利用登録の拒否に失敗しました。'
        )
      })
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    },

    cancelConfirmRequest () {
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-related-reporter-organization-view {
  height: calc(100% - 50px);
  overflow: auto;
  .related-organization-contents {
    height: 100%;
    padding: 10px 50px 0 50px;
    overflow: auto;
    background-color: #f5f5f5;
    &.deputizing {
      height: calc(100% - 73px);
    }
    @include mediaQuery('phone') {
      padding: 10px;
      padding-bottom: 0;
    }
    .centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h4 {
      margin: 10px 0;
      padding-bottom: 5px;
      color: #6c272d;
      border-bottom: 1px solid #aaa;
      font-size: 18px;
      font-weight: normal;
    }
    .block-title {
      cursor: pointer;
      margin: 10px 0;
      font-weight: bold;
      img {
        width: 13px;
        transition: transform 300ms ease;
        &.open {
          transform: rotate(90deg);
        }
      }
      .caption {
        font-size: 12px;
        font-weight: normal;
        &.notice {
          color: #c43d53;
        }
      }
      .create-request-btn {
        cursor: pointer;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
      }
    }
    .organization-list {
      overflow: hidden;
    }
    .sent-request-list {
      overflow: hidden;
    }
    .receive-request-list {
      overflow: hidden;
    }
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 300ms ease;
}
</style>
