<template>
  <div class="horus-ciritical-notification-detail-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>重要警告詳細</h1>
      </div>
      <div class="modal-body">
        <div class="controll-column" v-if="notification">
          <button class="close-btn" @click="closeModal">閉じる</button>
          <button class="activate-btn" @click="activate" v-if="!notification.activated_at">発火する</button>
        </div>
        <div class="list-data" v-if="notification">
          <div class="list-data-line">
            <div class="labels"><label>警告種別</label></div>
            <div class="content">{{ notification.notification_type_str }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>解決種別</label></div>
            <div class="content">{{ notification.resolve_type_str }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>状態</label></div>
            <div class="content">{{ notification.state }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>作成日時</label></div>
            <div class="content">{{ notification.created_at_str }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>更新日時</label></div>
            <div class="content">{{ notification.updated_at_str }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>発火日時</label></div>
            <div class="content">{{ notification.activated_at_str }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>タイトル</label></div>
            <div class="content">{{ notification.title }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>本文</label></div>
            <div class="content" v-html="notificationMessageHTML"></div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>対象ユーザー数</label></div>
            <div class="content">{{ notification.target_count }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>解決率</label></div>
            <div class="content">{{ notification.resolve_ratio * 100 }}％</div>
          </div>
          <div class="list-data-line" v-if="notification.areaBlock">
            <div class="labels"><label>対象管理団体</label></div>
            <div class="content">{{ notification.areaBlock.name }}</div>
          </div>
          <div class="list-data-line" v-if="notification.organizations && notification.organizations.length > 0">
            <div class="labels"><label>対象施設</label></div>
            <div class="content">
              <span class="organization" v-for="organization in targetOrganizations" :key="'selectedOrg' + organization.id">{{ organization.short_name }}</span>
            </div>
          </div>
          <div class="list-data-line" v-if="notification.areaBlock || (notification.organizations && notification.organizations.length > 0)">
            <div class="labels"><label>対象ユーザー種別</label></div>
            <div class="content">{{ groupTypeName }}</div>
          </div>
        </div><!-- list-data -->
        <div class="targets-column" v-if="notification && notification.activated_at">
          <button class="load-targets-btn" @click="loadTargets">対象ユーザーリストを読み込み</button>
          <div class="now-loading" v-if="targetLoading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
          <div class="target-list-column">
            <div class="list-line" v-for="target in notificationTargets" :key="'notificationTarget' + target.id">
              <div class="list-column"><span class="title">ユーザー名</span><span class="value">{{ target.user_name }}</span></div>
              <div class="list-column"><span class="title">NBID</span><span class="value">{{ target.user_nbid }}</span></div>
              <div class="list-column"><span class="title">状態</span><span class="value" :class="{ resolved: target.resolved }">{{ targetState(target) }}</span></div>
              <div class="list-column"><button class="resolve-btn" @click="resolve(target)" v-if="!notification.deleted && !target.resolved">解決する</button></div>
            </div>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import {
  CriticalNotification
} from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

export default {
  name: 'HorusCriticalNotificationDetailModal',

  components: {
    HorusModalFrame
  },

  props: {
    showModal: Boolean,
    notification: Object,
    targetOrganizations: Array
  },

  data () {
    return {
      notificationTargets: [],
      targetLoading: false
    }
  },

  // watch: {
  //   notification: { handler: 'initData', immediate: true }
  // },

  computed: {
    notificationMessageHTML () {
      if (this.notification) {
        return this.notification.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    groupTypeName () {
      if (this.notification && this.notification.group_target_type) {
        if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MANAGER) {
          return '施設管理者'
        } else if (this.notification.group_target_type === CRITICAL_NOTIFICATION_CONSTANTS.GROUP_TARGET_TYPE_MEMBER) {
          return '施設職員'
        }
      }
      return '-----'
    }
  },

  watch: {
    notification: { handler: 'initData', immediate: true }
  },

  methods: {
    initData () {
      this.notificationTargets = []
    },

    loadTargets () {
      if (this.notification.activated_at) {
        this.targetLoading = true
        CriticalNotification.fetch({ targetID: this.notification.id }).then(res => {
          this.notificationTargets = res.critical_notification_targets
          this.targetLoading = false
        }).catch(err => {
          console.error(err)
          this.targetLoding = false
        })
      }
    },

    targetState (target) {
      if (target.resolved) {
        var targetDate = new Date(target.resolved_at)
        if (targetDate) {
          return '解決:' + moment(targetDate).format('YYYY/MM/DD HH:mm')
        } else {
          return '解決:(解決日時エラー)'
        }
      } else {
        return '未解決'
      }
    },

    closeModal () {
      this.$emit('closeModalEvent')
    },

    activate () {
      this.$emit('activateEvent')
    },

    resolve (target) {
      this.$emit('resolveNotificationTargetEvent', target, () => { this.loadTargets() })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-ciritical-notification-detail-modal {
  .modal-body {
    padding: 10px;
    .controll-column {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 0 5px;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: bold;
        &.close-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
        &.activate-btn {
          border: 1px solid #c53d43;
          background-color: #c53d43;
          color: #fff;
        }
      }
    }
    .list-data {
      margin: 10px 0;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 16px;
      .list-data-line {
        display: flex;
        padding: 5px;
        border-bottom: 1px solid #aaa;
        .labels {
          margin-right: 10px;
          width: 150px;
          text-align: right;
          font-size: 14px;
          font-weight: bold;
        }
        .content {
          padding: 0;
          background-color: inherit;
        }
        img {
          margin-left: 20px;
          height: 20px;
        }
      }
    }
    .targets-column {
      button {
        margin: 0 5px;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: bold;
        border: 1px solid #666;
        background-color: #666;
        color: #fff;
      }
      .target-list-column {
        margin: 10px 0;
        .list-line {
          margin-bottom: 5px;
          display: flex;
          .list-column {
            margin-right: 10px;
            .title {
              margin-right: 5px;
              font-weight: bold;
            }
            .value {
              margin: 0;
              &.resolved {
                color: #5cb85c;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
</style>
