<template>
  <div class="horus-system-management-third-party-linkage-view">
    <h1 class="centering-title">第三者サービス連携</h1>
    <h2>サービス一覧</h2>
    <div class="data-controll">
      <button class="create-btn" @click="createThirdPartyLinkage">新規作成</button>
    </div>
    <div class="list-column">
      <horus-system-third-party-linkage-column
        v-for="linkage in thirdPartyLinkages"
        :key="'tpl' + linkage.id"
        :linkage="linkage"
        v-on:showDetailEvent="showDetailLinkage"
        />
    </div>
    <horus-system-third-party-linkage-detail-modal
      :linkage="detailTarget"
      :showModal="showDetailModal"
      v-on:closeEvent="closeDetailLinkage"
      v-on:editEvent="editLinkage"
      v-on:deleteEvent="deleteLinkage"
      v-on:admitEvent="admitLinkage"
      v-on:rejectEvent="rejectLinkage"
      v-on:destroyEvent="destroyLinkage"
      />
    <horus-system-third-party-linkage-edit-modal
      :editTarget="editTarget"
      :showModal="showEditModal"
      v-on:cancelEvent="cancelEdit"
      v-on:submitEvent="submitEdit"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  ThirdPartyLinkage
} from '@/api'

import HorusSystemThirdPartyLinkageColumn from '@/components/manageSystem/thirdPartyLinkage/HorusSystemThirdPartyLinkageColumn.vue'
import HorusSystemThirdPartyLinkageDetailModal from '@/components/manageSystem/thirdPartyLinkage/HorusSystemThirdPartyLinkageDetailModal.vue'
import HorusSystemThirdPartyLinkageEditModal from '@/components/manageSystem/thirdPartyLinkage/HorusSystemThirdPartyLinkageEditModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManagementThirdPartyLinkageView',

  components: {
    HorusSystemThirdPartyLinkageColumn,
    HorusSystemThirdPartyLinkageDetailModal,
    HorusSystemThirdPartyLinkageEditModal,
    HorusConfirmDialog
  },

  props: {
    systemManager: Object
  },

  data () {
    return {
      thirdPartyLinkages: [],
      showEditModal: false,
      editTarget: null,
      detailTarget: null,
      showDetailModal: false
    }
  },

  mounted () {
    this.loadTharPartyLinkages()
  },

  methods: {
    loadTharPartyLinkages () {
      ThirdPartyLinkage.fetch().then(res => {
        console.log(res.result.third_party_linkages)
        this.thirdPartyLinkages = res.result.third_party_linkages
      })
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    createThirdPartyLinkage () {
      this.editTarget = null
      this.showEditModal = true
    },

    showDetailLinkage (linkage) {
      this.detailTarget = linkage
      this.showDetailModal = true
    },

    closeDetailLinkage () {
      this.showDetailModal = false
      this.detailTarget = null
    },

    editLinkage (linkage) {
      this.editTarget = linkage
      this.showEditModal = true
      this.closeDetailLinkage()
    },

    cancelEdit (callback) {
      this.showConfirmDialog(
        '編集のキャンセル',
        '編集をキャンセルします。\nキャンセルすると編集の内容は破棄されます\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => {
          callback()
          this.executeCancelEdit()
        }
      )
    },

    executeCancelEdit () {
      this.showEditModal = false
      this.editTarget = null
    },

    submitEdit (formData) {
      ThirdPartyLinkage.update({formData: formData}).then(() => {
        this.loadTharPartyLinkages()
        this.showEditModal = false
        this.editTarget = null
      })
    },

    deleteLinkage (linkage) {
      this.showConfirmDialog(
        '第三者サービス連携の削除',
        '第三者サービス連携を削除します。\n削除された第三者サービス連携は以後利用出来なくなり、この操作は戻せません。\n削除してよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDeleteLinkage(linkage) }
      )
    },

    executeDeleteLinkage (linkage) {
      ThirdPartyLinkage.destroy({ targetID: linkage.id }).then(() => {
        this.loadTharPartyLinkages()
        this.closeDetailLinkage()
      })
    },

    admitLinkage (linkage) {
      this.showConfirmDialog(
        '第三者サービス連携の承認',
        '第三者サービス連携を承認します。\n承認と同時にコードとトークンが生成され、登録者にメールが送信されます。この操作は戻せません。\n承認しますか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeAdmitLinkage(linkage) }
      )
    },

    executeAdmitLinkage (linkage) {
      ThirdPartyLinkage.admit({targetID: linkage.id}).then(() => {
        this.loadTharPartyLinkages()
        this.closeDetailLinkage()
      })
    },

    rejectLinkage (linkage, message) {
      this.showConfirmDialog(
        '第三者サービス連携の拒否',
        '第三者サービス連携を拒否します。\n拒否と同時に登録者に拒否理由を含めたメールが送信され、この第三者連携サービスは使用不能になります。この操作は戻せません。\n拒否しますか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeRejectLinkage(linkage, message) }
      )
    },

    executeRejectLinkage (linkage, message) {
      ThirdPartyLinkage.reject({ targetID: linkage.id, message: message }).then(() => {
        this.loadTharPartyLinkages()
        this.closeDetailLinkage()
      })
    },

    destroyLinkage (linkage) {
      this.showConfirmDialog(
        '第三者サービス連携の物理削除',
        '第三者サービス連携を物理削除します。\n物理削除すると、この第三者サービス連携が完全に削除されます。この操作は戻せません。\n物理削除しますか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroyLinkage(linkage) }
      )
    },

    executeDestroyLinkage (linkage) {
      // gonna destroy
      ThirdPartyLinkage.destroy_physically({ targetID: linkage.id }).then(() => {
        this.loadTharPartyLinkages()
        this.closeDetailLinkage()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-management-third-party-linkage-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
}
</style>
