<template>
  <div class="horus-system-management-reporter-organization">
    <h1 class="centering-title">報告施設管理</h1>
    <div class="organization-list-column" v-if="!detailTargetReporterOrganization">
      <h2>報告施設一覧</h2>
      <div class="head-controll">
        <div class="state-list">
          <button class="narrow-down-btn" :class="{disable: currentState === 'normal'}" @click="showNormal">施設一覧</button>
          <button class="narrow-down-btn" :class="{disable: currentState === 'revokeRequested'}" @click="showRevokeRequest">退会希望一覧</button>
          <button class="narrow-down-btn" :class="{disable: currentState === 'revoked'}" @click="showRevoked">退会済一覧</button>
        </div>
        <div class="data-controll">
          <button class="dependence-btn" @click="editOrganizationDependence">所属管理団体一括設定</button>
          <button class="create-btn" @click="createOrganization">新規作成</button>
        </div>
      </div>
      <div class="display-area-blocks" v-if="systemManager">
        <div class="narrow-down-title">管理団体絞り込み</div>
        <div class="display-area-block" :class="{selected: !selectedAreaBlock}" @click="displayAreaBlock(null)">全管理団体</div>
        <div class="display-area-block" :class="{selected: selectedAreaBlock === 'none'}" @click="displayAreaBlock('none')">無所属</div>
        <div class="display-area-block"
          :class="{selected: selectedAreaBlock && selectedAreaBlock.id && areaBlock.id === selectedAreaBlock.id}"
          v-for="areaBlock in areaBlocks"
          :key="'sysOrgAB' + areaBlock.id"
          @click="displayAreaBlock(areaBlock)"
          >
          {{ areaBlock.name }}
        </div>
      </div>
      <div class="organization-count">{{ displayReporterOrganizations.length }} / {{ reporterOrganizations.length }}</div>
      <div v-if="organizationNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
      <div class="organization-list" v-if="!organizationNowLoading && !detailTargetReporterOrganization">
        <div class="list-column"
          v-for="org in displayReporterOrganizations"
          :key="'sysOrgList' + org.id"
          >
          <div class="list-line">
            <div class="name">{{ org.name }}[{{ org.organizationNumber }}]</div>
            <div class="areablock">管理団体 : [{{ areaBlockName(org) }}]</div>
          </div>
          <div class="list-line">
            <div class="title">{{ org.nameKana }}</div>
            <div class="count">管理者数 : {{ org.manager_count }}</div>
            <div class="count">職員数 : {{ org.member_count }}</div>
            <div class="count">利用者数 : {{ org.relaed_user_count }}</div>
            <div class="controll"><button type="button" class="show-btn" @click="showDetail(org)">詳細</button></div>
          </div>
        </div>
      </div><!-- organization-list -->
    </div>
    <div class="organization-detail-column" v-if="detailTargetReporterOrganization">
      <horus-system-management-reporter-organization-detail-column
        :sourceReporterOrganization="detailTargetReporterOrganization"
        :areaBlocks="areaBlocks"
        :systemManager="systemManager"
        :areaBlockOperator="areaBlockOperator"
        v-on:closeDetailEvent="closeDetail"
        />
    </div>
    <horus-system-reporter-organization-edit-modal
      :showReporterOrganizationEditModal="showReporterOrganizationEditModal"
      :systemManager="systemManager"
      :areaBlockOperator="areaBlockOperator"
      :submitString="'新規作成'"
      v-on:submitCancelEvent="cencelCreateSubmit"
      v-on:submitEvent="submitCreate"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import  {
  AreaBlock,
  ReporterOrganization
} from '@/models'

import HorusSystemManagementReporterOrganizationDetailColumn from '@/components/manageSystem/reporterOrganization/HorusSystemManagementReporterOrganizationDetailColumn.vue'
import HorusSystemReporterOrganizationEditModal from '@/components/manageSystem/reporterOrganization/HorusSystemReporterOrganizationEditModal.vue'
// import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManagementReporterOrganizationView',

  components: {
    HorusSystemManagementReporterOrganizationDetailColumn,
    HorusSystemReporterOrganizationEditModal,
    // HorusCustomCheckBox,
    HorusConfirmDialog
  },

  props: {
    systemManager: Object,
    areaBlockOperator: Object
  },

  data () {
    return {
      areaBlocks: [],
      organizationNowLoading: false,
      reporterOrganizations: [],
      currentState: 'normal',
      selectedAreaBlock: null,
      selectedTags: [],
      displayReporterOrganizations: [],
      showReporterOrganizationEditModal: false,
      detailTargetReporterOrganization: null
    }
  },

  mounted () {
    this.loadDatas()
  },

  methods: {
    async loadDatas () {
      await this.loadAreaBlocks()
      await this.loadReporterOrganizations()
    },

    loadAreaBlocks () {
      AreaBlock.api().fetchList().then(res => {
        this.areaBlocks = res.area_blocks
      })
    },

    loadReporterOrganizations () {
      ReporterOrganization.api().listWithSummary().then(res => {
        this.reporterOrganizations = res.reporter_organizations
        this.updateDisplay()
      })
    },

    updateDisplay () {
      // narrow down by type
      if (this.currentState === 'revokeRequested') {
        this.displayReporterOrganizations = this.reporterOrganizations.filter(org => org.ready_to_revoke && !org.deleted)
      } else if (this.currentState === 'revoked') {
        this.displayReporterOrganizations = this.reporterOrganizations.filter(org => org.deleted)
      } else {
        this.displayReporterOrganizations = this.reporterOrganizations.filter(org => !org.deleted)
      }
      this.displayReporterOrganizations = this.displayReporterOrganizations.filter(org => {
        if (this.selectedAreaBlock === null) {
          return true
        } else if (this.selectedAreaBlock === 'none') {
          if (org.area_block_id) {
            return false
          } else {
            return true
          }
        } else {
          if (org.area_block_id === this.selectedAreaBlock.id) {
            return true
          } else {
            return false
          }
        }
      })
    },

    showNormal () {
      this.currentState = 'normal'
      this.updateDisplay()
    },

    showRevokeRequest () {
      this.currentState = 'revokeRequested'
      this.updateDisplay()
    },

    showRevoked () {
      this.currentState = 'revoked'
      this.updateDisplay()
    },

    displayAreaBlock (areaBlock) {
      this.selectedAreaBlock = areaBlock
      this.updateDisplay()
    },

    areaBlockName (org) {
      if (org.area_block_id) {
        var target = this.areaBlocks.find(areablock => areablock.id === org.area_block_id)
        console.log(target)
        if (target) {
          return target.name
        }
      }
      return '所属なし'
    },

    organizationDeputizable (org) {
      if (org.deputizable) {
        return 'あり'
      } else {
        return 'なし'
      }
    },

    showDetail (org) {
      this.detailTargetReporterOrganization = org
    },

    closeDetail () {
      this.detailTargetReporterOrganization = null
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    createOrganization () {
      this.showReporterOrganizationEditModal = true
    },

    cencelCreateSubmit (callback) {
      this.showReporterOrganizationEditModal = false
      callback()
    },

    submitCreate (formData, callback) {
      ReporterOrganization.api().create({ formData: formData }).then(() => {
        this.showReporterOrganizationEditModal = false
        callback()
        this.loadReporterOrganizations()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('新規作成失敗', '報告施設の新規作成に失敗しました。')
      })
    },

    editOrganizationDependence () {
      this.$router.push({ path: '/system_management/reporter_organization_area_block_relation' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-management-reporter-organization {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .head-controll {
    display: flex;
    justify-content: space-between;
    .state-list {
      display: flex;
      .narrow-down-btn {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #37a34a;
        border-radius: 3px;
        background-color: #37a34a;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        &.disable {
          background-color: #fff;
          color: #37a34a;
        }
      }
    }
    .data-controll {
      button {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .narrow-down-title {
    margin-right: 10px;
    font-weight: bold;
  }
  .display-area-blocks {
    display: flex;
    margin: 10px 0;
    .display-area-block {
      margin: 0 10px;
      padding: 3px 5px;
      background-color: #666;
      color: #fff;
      border-radius: 3px;
      border: 1px solid #666;
      font-weight: bold;
      &.selected {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .narrow-down-tags {
    display: flex;
    margin: 10px;
    .narrow-down {
      display: flex;
      margin-right: 20px;
    }
  }
  .organization-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            width: 50%;
            font-weight: bold;
          }
          &.title {
            width: 50%;
          }
          &.type {
            width: 160px;
          }
          &.duptilze {
            width: 100px;
          }
          &.tag {
            width: 100px;
          }
          &.count {
            width: 100px;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
