<template>
  <div class="content-line vital-value">
    <div class="content-column">
      <div class="label">計測内容</div>
      <div class="content">{{ vitalValue.type_name }}</div>
    </div>
    <div class="content-column">
      <div class="label">計測結果</div>
      <div class="content">{{ vitalValue.value }}{{ vitalValue.unit_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusVitalValueColumn',

  props: {
    vitalValue: Object
  }
}
</script>

<style lang="scss" scoped>

</style>
