<template>
  <div class="horus-system-manage-static-identify-code-category-view">
    <h1 class="centering-title">静的QRコードカテゴリ</h1>
    <h2>お知らせ一覧<button class="create-btn" @click="newCategory">新規カテゴリ作成</button></h2>
    <table class="id-code-categories">
      <tr><th>名称</th><th>prefix</th><th>公式</th><th>登録ID数</th><th>&nbsp;</th></tr>
      <tr v-for="category in idCodeCategories" :key="'systemSICC' + category.id">
        <td>{{ category.name }}</td>
        <td>{{ category.prefix }}</td>
        <td>{{ category.official }}</td>
        <td>{{ category.id_count }}</td>
        <td>
          <button class="delete-btn" @click="edit(category)">編集</button>
          <button class="edit-btn" @click="destroy(category)">削除</button>
        </td>
      </tr>
    </table>
    <horus-system-manage-static-id-category-edit-modal
      :editCategory="editCategory"
      :showEditCategory="showEditCategoryModal"
      :registedCategories="idCodeCategories"
      v-on:cancelSubmitEvent="cancelEdit"
      v-on:submitEvent="submitCategory"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { StaticIdentifiyCodeCategory } from '@/api'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusSystemManageStaticIdCategoryEditModal from '@/components/manageSystem/staticIdentifyCodeCategory/HorusSystemManageStaticIdCategoryEditModal.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManageStaticIdentifyCodeCategoryView',

  components: {
    HorusConfirmDialog,
    HorusSystemManageStaticIdCategoryEditModal
  },

  data () {
    return {
      idCodeCategories: [],
      editCategory: null,
      showEditCategoryModal: false
    }
  },

  created () {
    this.loadCategories()
  },

  methods: {
    loadCategories () {
      StaticIdentifiyCodeCategory.fetchForManage().then(res => {
        this.idCodeCategories = res.staticIdentifyCodeCategories
      }).catch(err => {
        console.error(err.response)
        this.showNotifyDialog('静的QRコードカテゴリの取得失敗', '静的QRコードカテゴリの取得に失敗しました')
      })
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    newCategory () {
      this.editCategory = null
      this.showEditCategoryModal = true
    },

    edit (category) {
      this.editCategory = category
      this.showEditCategoryModal = true
    },

    cancelEdit () {
      this.editCategory = null
      this.showEditCategoryModal = false
    },

    submitCategory (formData) {
      if (this.editCategory) {
        StaticIdentifiyCodeCategory.update({ formData: formData }).then(() => {
          this.loadCategories()
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('静的QRコードカテゴリの更新失敗', '静的QRコードカテゴリの更新に失敗しました')
        }).finally(() => {
          this.showEditCategoryModal = false
          this.editCategory = null
        })
      } else {
        StaticIdentifiyCodeCategory.create({ formData: formData }).then(() => {
          this.loadCategories()
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('静的QRコードカテゴリの新規作成失敗', '静的QRコードカテゴリの新規作成に失敗しました')
        }).finally(() => {
          this.showEditCategoryModal = false
          this.editCategory = null
        })
      }
    },

    destroy (category) {
      this.showConfirmDialog(
        '静的QRコードカテゴリの削除',
        '静的QRコードカテゴリを削除にしようとしています。\n実行するとこのカテゴリに関連する静的QRコード' + category.id_count + '件の静的QRコードが利用不能になります。\n本当によろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroy(category) }
      )
    },

    executeDestroy (category) {
      StaticIdentifiyCodeCategory.destroy({ categoryID: category.id }).then(() => {
        this.showNotifyDialog('静的QRコードカテゴリの削除完了', '静的QRコードカテゴリ[' + category.name + ']を削除しました。')
        this.loadCategories()
      }).catch(err => {
        console.error(err.response)
        this.showNotifyDialog('静的QRコードカテゴリの削除失敗', '静的QRコードカテゴリの削除に失敗しました')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-manage-static-identify-code-category-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .id-code-categories {
    width: 100%;
    margin: 10px 0;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
    th {
      border-bottom: 1px solid #aaa;
    }
    td {
      padding: 5px 3px;
      border-bottom: 1px solid #aaa;
      vertical-align: top;
      button {
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
</style>
