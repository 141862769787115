<template>
  <div class="horus-create-deputize-request-for-agent-modal">
    <horus-modal-frame
      :showModalFlag="showCreateRequestModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />代理依頼申請の作成</h1>
      </div>
      <div class="modal-body">
        <div class="form-datas">
          <div class="create-tab-labels">
            <div class="tab-label" :class="{active: createRequestModeNBID}" @click="requestModeToNBID">NBIDで作成</div>
            <div class="tab-label" :class="{active: !createRequestModeNBID}" @click="requestModeToMail">メールアドレスで作成</div>
          </div>
          <div class="create-by-nbid" v-if="createRequestModeNBID">
            <div class="switch-request-type">NBIDがわからない場合は<span class="link" @click="requestModeToMail">こちら</span></div>
            <horus-attribute-in-form
              :labelText="'NBID'"
              :necessary="true"
              :validationState="requestNBIDValidation.state"
              :validationMessage="requestNBIDValidation.message"
              :attributeNotify="''">
              <input type="text" v-model="requestTargetNBID" />
            </horus-attribute-in-form>
          </div>
          <div class="create-by-mail" v-if="!createRequestModeNBID">
            <div class="switch-request-type">NBIDがわかっている場合は<span class="link" @click="requestModeToNBID">こちら</span></div>
            <horus-attribute-in-form
              :labelText="'メールアドレス'"
              :necessary="true"
              :validationState="requestMailValidation.state"
              :validationMessage="requestMailValidation.message"
              :attributeNotify="''">
              <input type="text" v-model="requestTargetMail" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'メールアドレス(確認)'"
              :necessary="true"
              :validationState="requestMailConfirmationValidation.state"
              :validationMessage="requestMailConfirmationValidation.message"
              :attributeNotify="''">
              <input type="text" v-model="requestTargetMailConfirmation" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'宛先名(メールに書き込まれる相手の名前)'"
              :necessary="true"
              :validationState="requestNameValidation.state"
              :validationMessage="requestNameValidation.message"
              :attributeNotify="''">
              <input type="text" v-model="requestTargetName" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'本人確認コード(数字4桁:空白の場合自動生成)'"
              :necessary="false"
              :validationState="requestShortTokenValidation.state"
              :validationMessage="requestShortTokenValidation.message"
              :attributeNotify="'4桁の数字(半角)'">
              <input type="text" v-model="requestShortToken" />
            </horus-attribute-in-form>
          </div>
          <horus-attribute-in-form
            :labelText="'メッセージ'"
            :necessary="false"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <textarea rows="3" cols="20" v-model="requestMessage"></textarea>
          </horus-attribute-in-form>
          <div class="notify_for_admit">
            <h1>代理依頼申請における確認事項</h1>
            <ul>
              <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
              <li>
                代理者への委任する機能は下記の通りであること
                <ol>
                  <li>本システムの利用者として、利用申込の申請並びに利用停止申請及び利用者登録の抹消申請を行うこと。</li>
                  <li>利用者本人の利用者情報の閲覧及び編集を行うこと。</li>
                  <li>家族申請、他の利用者からの家族申請の承認及び家族の削除申請を行うこと。</li>
                  <li>施設の設定及び停止を行うこと。</li>
                  <li>利用者本人が利用者情報の閲覧及び編集をできるようにすること。</li>
                  <li>本書面の委任事項を他の施設または利用者に再委任すること（但し、再委任先への委任状の作成は利用者本人による）。</li>
                </ol>
              </li>
              <li>ナラティブブックであなたの情報を共有してほしい家族や施設を追加したいときは、代理者に連絡すること</li>
            </ul>
          </div>
          <div class="admit-agreement" @click="toggleAdmit">
            <horus-custom-check-box
              :active="admitAgreement"
              :enabled="true"
              :type="'color'"
              />
            上記確認事項を確認しました
          </div>
        </div><!-- org search result end -->
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendRequest">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !requestReady }" @click="submitSendRequest">申請の送信</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusUserInvitationValidation from '@/mixin/HorusUserInvitationValidation.js'

export default {
  mixins: [HorusUserInvitationValidation],

  name: 'HorusCreateDeputizeRequestForAgentModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    timelineOwner: Object,
    showCreateRequestModal: Boolean,
    deputizeAgents: Array,
    sentRequests: Array,
    receiveRequests: Array
  },

  data () {
    return {
      createRequestModeNBID: true,
      requestTargetNBID: '',
      requestTargetMail: '',
      requestTargetMailConfirmation: '',
      requestTargetName: '',
      requestShortToken: '0000',
      requestMessage: '',
      admitAgreement: false
    }
  },

  computed: {
    requestNBIDValidation () {
      if (this.requestTargetNBID.length > 0) {
        if (this.deputizeAgents.some(agent => (agent.nbid === this.requestTargetNBID))) {
          return { state: 'invalid', message: '指定したNBIDの利用者は、すでにあなたの設定代行者です。' }
        } else if (this.sentRequests.some(req => req.login_id === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定したNBIDには、すでに案内を送信済です。' }
        } else if (this.receiveRequests.some(req => req.agentUser && req.agentUser.nbid === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定したNBIDの利用者から、すでに案内を受信しています。' }
        } else {
          return { state: 'valid', message: '' }
        }
      } else {
        return { state: 'invalid', message: 'NBIDは入力必須です' }
      }
    }
  },

  methods: {
    cancelSendRequest () {
      this.resetParams()
      this.$emit('cancelEvent')
    },

    submitSendRequest () {
      if (this.requestReady) {
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        formData.append('deputize_request_for_user[login_id]', this.requestTargetNBID)
        formData.append('deputize_request_for_user[mail]', this.requestTargetMail)
        formData.append('deputize_request_for_user[name]', this.requestTargetName)
        formData.append('deputize_request_for_user[short_token]', this.requestShortToken)
        formData.append('deputize_request_for_user[message_content]', this.requestMessage)
        this.$emit('submitSendRequestEvent', formData, () => { this.resetParams() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-deputize-request-for-agent-modal {
  .modal-body {
    padding: 10px;
    height: calc(100% - 100px);
    overflow: auto;
    .create-tab-labels {
      margin: 0;
      display: flex;
      align-items: flex-end;
      .tab-label {
        margin: 0;
        padding: 3px 10px;
        border: 1px solid #666;
        border-bottom: none;
        background-color: #ddd;
        color: #666;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        &.active {
          background-color: #fff;
          color: #666;
        }
      }
    }
  .create-tab-labels {
    margin: 0;
    display: flex;
    align-items: flex-end;
    .tab-label {
      margin: 0;
      padding: 3px 10px;
      border: 1px solid #666;
      border-bottom: none;
      background-color: #ddd;
      color: #666;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      &.active {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .create-by-nbid {
    border: 1px solid #666;
    padding: 10px;
  }
  .create-by-mail {
    border: 1px solid #666;
    padding: 10px;
  }
    .request-message {
      margin-bottom: 25px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
    }
    .notify_for_admit {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #666;
      }
    }
    input[type='text'] {
      margin: 0 5px;
      font-size: 16px;
    }
    textarea {
      width: calc(100% - 40px);
      font-size: 16px;
      border: 1px solid #aaa;
    }
    .admit-agreement {
      cursor: pointer;
      margin: 10px;
      font-weight: bold;
    }
    .submit-controll {
      margin: 10px;
      text-align: center;
      button {
        cursor: pointer;
        padding: 2px 20px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        &.disable {
          cursor: normal;
          background-color: #aaa;
        }
        &.cancel-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>
