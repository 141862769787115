<template>
  <div class="horus-system-reporter-organization-edit-modal">
    <horus-modal-frame
      :showModalFlag="showReporterOrganizationEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />報告施設編集</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'所属管理団体'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <div class="area-block-selection" v-if="systemManager">
            <div class="area-block" @click="setTargetAreaBlock(null)">
              <horus-custom-check-box
                :active="!targetAreaBlock"
                :enabled="true"
                :type="'color'"
                />
              所属なし
            </div>
            <div class="area-block" v-for="areaBlock in areaBlocks" :key="'editOrgAB' + areaBlock.id" @click="setTargetAreaBlock(areaBlock)">
              <horus-custom-check-box
                :active="targetAreaBlock === areaBlock"
                :enabled="true"
                :type="'color'"
                />
              {{ areaBlock.name }}
            </div>
          </div>
          <input type="text" disabled size="12" v-if="!systemManager" v-model="areaBlockName" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'名称'"
          :necessary="true"
          :validationState="validateName.state"
          :validationMessage="validateName.message"
          :attributeNotify="''">
          <input type="text" size="50" v-model="editOrg.name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'ヨミガナ'"
          :necessary="true"
          :validationState="validateNameKana.state"
          :validationMessage="validateNameKana.message"
          :attributeNotify="'カタカナのみ'">
          <input type="text" size="50" v-model="editOrg.nameKana" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'略称'"
          :necessary="true"
          :validationState="validateShortName.state"
          :validationMessage="validateShortName.message"
          :attributeNotify="''">
          <input type="text" size="30" v-model="editOrg.shortName" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'郵便番号'"
          :necessary="true"
          :validationState="validateZipCode.state"
          :validationMessage="validateZipCode.message"
          :attributeNotify="''">
          <input type="text" size="10" v-model="editOrg.zipCode" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'都道府県'"
          :necessary="true"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <select class="sex-type-select" v-model="editOrg.prefecture">
            <option v-for="prefCode in addressData.PREFECTURE_CODE_JP" :key="'prefectureSelect' + prefCode[0]" :value="prefCode[0]">{{ prefCode[1] }}</option>
          </select>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'市町村区'"
          :necessary="true"
          :validationState="validateCity.state"
          :validationMessage="validateCity.message"
          :attributeNotify="''">
          <input type="text" v-model="editOrg.city" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'住所詳細'"
          :necessary="true"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <input type="text" size="50" v-model="editOrg.addressDetail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'電話番号'"
          :necessary="true"
          :validationState="validateTel.state"
          :validationMessage="validateTel.message"
          :attributeNotify="'ハイフン区切り半角数字: 000-000-0000'">
          <input type="text" size="15" v-model="editOrg.tel" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="validateMail.state"
          :validationMessage="validateMail.message"
          :attributeNotify="''">
          <input type="text" size="40" v-model="editOrg.mail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'イメージアイコン'"
          :necessary="false"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <div class="iconimage-setting-column">
            <div class="iconimage-thumbnail-column">
              <span>設定済の写真</span><br/>
              <img class="current-iconimage" :src="editOrg.iconImageSrc" />
            </div>
            <horus-mono-photo-selector
              :photoObj="iconimageObj"
              v-on:photoSelectedEvent="photoSelected"
              v-on:cancelSelectPhotoEvent="selectedPhotoCancel"
              />
          </div>
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">{{ submitString }}</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import { AreaBlock } from '@/models'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusMonoPhotoSelector from '@/components/common/HorusMonoPhotoSelector.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as USER_ADDRESS from '@/assets/constants/addressData.js'

export default {
  name: 'HorusSystemReporterOrganizationEditModal',

  components: {
    HorusAttributeInForm,
    HorusMonoPhotoSelector,
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    sourceReporterOrganization: Object,
    showReporterOrganizationEditModal: Boolean,
    systemManager: Object,
    areaBlockOperator: Object,
    submitString: String
  },

  data () {
    return {
      editOrg: {},
      addressData: USER_ADDRESS,
      iconimageObj: null,
      areaBlocks: [],
      targetAreaBlock: null
    }
  },

  watch: {
    sourceReporterOrganization: { handler: 'initData', immediate: true }
  },

  async mounted () {
    await this.loadAreaBlocks()
    this.initData()
  },

  computed: {
    areaBlockName () {
      if (this.targetAreaBlock) {
        return this.targetAreaBlock.name
      }
      return '所属なし'
    },

    validateName () {
      var checkResult = this.stringLengthCheck(this.editOrg.name, 127, '施設名称')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateNameKana () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.editOrg.nameKana) {
        message = 'ヨミガナは入力必須です。1文字以上126文字以下で入力ください。'
      } else {
        var trimed = this.editOrg.nameKana.trim()
        if (trimed.match(/^[ァ-ヶー\u{3000}\s]+$/)) {
          if (trimed.length > 0 && trimed.length <= 127) {
            valid = true
            state = 'valid'
          } else if (trimed.length > 127) {
            message = 'ヨミガナは20文字以内で入力ください。'
          } else {
            message = 'ヨミガナは1文字以上126文字以下で入力ください。'
          }
        } else {
          message = 'ヨミガナは全角カタカナ以外の文字は使えません。'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateShortName () {
      var checkResult = this.stringLengthCheck(this.editOrg.shortName, 20, '施設略称')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateZipCode () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.editOrg.zipCode) {
        message = '郵便番号は入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
      } else {
        var trimed = this.editOrg.zipCode.trim()
        if (trimed.match(this.addressData.ZIP_CODE_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '郵便番号はハイフン区切りの正しい形式で入力してください。(例:000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateCity () {
      var checkResult = this.stringLengthCheck(this.editOrg.city, 127, '市町村区')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateTel () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.editOrg.tel) {
        message = '電話雁号は入力必須です。'
      } else {
        var trimed = this.editOrg.tel.trim()
        if (trimed.match(this.addressData.TEL_NUMBER_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateMail () {
      var checkResult = this.stringLengthCheck(this.editOrg.mail.length, 127, 'メールアドレス')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    allValid () {
      if (
        this.validateName.valid &&
        this.validateNameKana.valid &&
        this.validateShortName.valid &&
        this.validateZipCode.valid &&
        this.validateCity.valid &&
        this.validateTel.valid &&
        this.validateMail.valid
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    stringLengthCheck (value, length, valueName) {
      var valid = false
      var message = ''
      if (!value || value.length <= 0) {
        message = valueName + 'は入力必須です。'
      } else if (value.length > length) {
        message = valueName + 'は' + length + '文字以下で入力ください。'
      } else {
        valid = true
      }
      return { valid: valid, message: message }
    },

    initData () {
      if (this.sourceReporterOrganization) {
        this.editOrg.name = this.sourceReporterOrganization.name
        this.editOrg.nameKana = this.sourceReporterOrganization.nameKana
        this.editOrg.shortName = this.sourceReporterOrganization.shortName
        this.editOrg.zipCode = this.sourceReporterOrganization.zipCode
        this.editOrg.prefecture = this.sourceReporterOrganization.prefecture
        this.editOrg.city = this.sourceReporterOrganization.city
        this.editOrg.addressDetail = this.sourceReporterOrganization.addressDetail
        this.editOrg.tel = this.sourceReporterOrganization.tel
        this.editOrg.mail = this.sourceReporterOrganization.mail
        this.iconimageObj = null
        if (this.sourceReporterOrganization.area_block_id) {
          this.targetAreaBlock = this.areaBlocks.find(areablock => areablock.id === this.sourceReporterOrganization.area_block_id)
        } else {
          this.targetAreaBlock = null
        }
      } else {
        this.initEditOrg()
        this.iconimageObj = null
        this.targetAreaBlock = null
      }
    },

    initEditOrg () {
      this.editOrg = {
        name: '',
        nameKana: '',
        shortName: '',
        zipCode: '',
        prefecture: '00',
        city: '',
        address: '',
        tel: '',
        mail: ''
      }
      this.iconimageObj = null
      if (this.areaBlockOperator) {
        this.targetAreaBlock = this.areaBlocks.find(areablock => areablock.id === this.areaBlockOperator.area_block_id)
        console.log('areablockoperator exist.')
      } else {
        this.targetAreaBlock = null
      }
    },

    async loadAreaBlocks () {
      let res = await AreaBlock.api().fetchList()
      this.areaBlocks = res.area_blocks
      if (this.sourceReporterOrganization && this.sourceReporterOrganization.area_block_id && !this.targetAreaBlock) {
        this.targetAreaBlock = this.areaBlocks.find(areablock => areablock.id === this.sourceReporterOrganization.area_block_id)
      } else if (this.areaBlockOperator && !this.sourceReporterOrganization) {
        this.targetAreaBlock = this.areaBlocks.find(areablock => areablock.id === this.areaBlockOperator.area_block_id)
      }
    },

    setTargetAreaBlock (areaBlock) {
      if (this.systemManager) {
        this.targetAreaBlock = areaBlock
      }
    },

    photoSelected (photoObj) {
      this.iconimageObj = photoObj
    },

    selectedPhotoCancel () {
      this.iconimageObj = null
    },

    cancelSubmit () {
      this.$emit('submitCancelEvent', () => { this.initEditOrg() })
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        if (this.sourceReporterOrganization) {
          formData.append('id', this.sourceReporterOrganization.id)
        }
        if (this.targetAreaBlock) {
          formData.append('reporter_organization[area_block_id]', this.targetAreaBlock.id)
        }
        formData.append('reporter_organization[name]', this.editOrg.name)
        formData.append('reporter_organization[name_kana]', this.editOrg.nameKana)
        formData.append('reporter_organization[short_name]', this.editOrg.shortName)
        formData.append('reporter_organization[prefecture]', this.editOrg.prefecture)
        formData.append('reporter_organization[city]', this.editOrg.city)
        formData.append('reporter_organization[address]', this.editOrg.addressDetail)
        formData.append('reporter_organization[zip_code]', this.editOrg.zipCode)
        formData.append('reporter_organization[tel]', this.editOrg.tel)
        formData.append('reporter_organization[mail]', this.editOrg.mail)
        if (this.iconimageObj) {
          formData.append('reporter_organization[icon_image]', this.iconimageObj.uploadFile, this.iconimageObj.name)
        }
        this.$emit('submitEvent', formData, () => { this.initEditOrg() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-system-reporter-organization-edit-modal {
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
