<template>
  <div class="horus-manage-area-block-detail">
    <h1 class="centering-title">管理団体詳細</h1>
    <div class="navigation"><button class="back-btn" @click="backToList">一覧へ戻る</button></div>
    <horus-manage-area-block-detail-column
      :systemManager="systemManager"
      :areaBlockOperator="areaBlockOperator"
      />
  </div>
</template>

<script>
import HorusManageAreaBlockDetailColumn from '@/components/manageSystem/areaBlock/HorusManageAreaBlockDetailColumn.vue'

export default {
  name: 'HorusManageAreaBlockDetailView',

  components: {
    HorusManageAreaBlockDetailColumn
  },

  props: {
    systemManager: Object,
    areaBlockOperator: Object
  },

  data () {
    return {
      // areaBlockID: null
    }
  },

  watch: {
    '$route': { handler: 'initDatas', immediate: true }
  },

  methods: {
    initDatas () {
      if (this.$route.query.area_block) {
        console.log(this.$route.query.area_block)
        // this.areaBlockID = this.$route.query.area_block
      }
    },

    backToList () {
      this.$router.push({ path: '/system_management/area_block' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-manage-area-block-detail {
  padding: 10px 50px;
  .navigation {
    display: flex;
    justify-content: flex-end;
    .back-btn {
      padding: 3px 10px;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
    }
  }
}
</style>
