import axios from './AxiosInstance'

export default {
  fetch: ({reporterOrganizationID}) => {
    console.log('fetch reporterOrganizatonMemberInvitation')
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_member_invitation/list',
        withCredentials: true,
        params: { reporter_organization_id: reporterOrganizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_member_invitations: res.data.reporter_organization_member_invitations })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'reporter_organization_member_invitation',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({reporterOrganizationID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'reporter_organization_member_invitation',
            withCredentials: true,
            params: { reporter_organization_id: reporterOrganizationID, reporter_organization_member_invitation_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  sendMail: ({reporterOrganizationID, targetID}) => {
    var formData = new FormData()
    formData.append('reporter_organization_id', reporterOrganizationID)
    formData.append('reporter_organization_member_invitation_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_member_invitation/send_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchReceive: ({invitationToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_member_invitation',
        withCredentials: true,
        params: { token: invitationToken }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_name: res.data.reporter_organization_name, limit_at: res.data.limit_at })
        }
      }).catch(error => { reject(error) })
    })
  },

  validateToken: ({invitationToken, shortToken}) => {
    var formData = new FormData()
    formData.append('token', invitationToken)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_member_invitation/validate_token',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  // :token, :short_token, :login_id, :password
  admit: ({token, loginID, password, shortToken}) => {
    var formData = new FormData()
    formData.append('token', token)
    formData.append('login_id', loginID)
    formData.append('password', password)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_member_invitation/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
