import {
  Author,
  Family,
  TimelineOwner,
  UserTask
} from '@/models'
import {
  RelatedUser
} from '@/api'

import * as PRESENTED_MODAL_TYPE from '@/assets/constants/modalType.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {

  // props: {
  //   signinMember: Object,
  //   loggedInUser: Object
  //   // timelineOwner: Object
  // },

  data () {
    return {
      teskRelatedUsers: null,
      futureDateSelectionModalShow: false,
      taskShouldUpdate: true,
      showSelectTimelineOwnerModal: false,
      showUserTaskFormModal: false,
      selectTargetOrganization: null,
      // taskTargetTimelineOwner: null, // variable for userTask create and edit.
      teskOrganizations: null, // for select task shareing target.
      taskSelectedTags: [],
      taskCreateTags: [],
      taskPushTargets: [],
      taskAllFamilyNotify: { value: false },
      taskSelectedOrganizationForNotify: [],
      notificatableFamilies: [],
      editTargetTask: null,
      formTaskTargetAt: { date: null },
      photoModalShow: false, // photo modal
      photoModalPhotos: [],
      photoModalFirst: null
    }
  },

  watch: {
    loggedInUser: { handler: 'loadTaskRelatedUsers', immediate: true },
    signinMember: { handler: 'loadTaskRelatedUsers', immediate: true },
    // timelineOwner: { handler: 'setTargetTimelineOwner', immediate: true },
    // timlineOwnerはHorusMainTimelineViewの場合、新たにセットされないし、削除もされない。それ以外のビューで採用される場合はそもそもTimelineOwnerがこれ以外で利用されることがない。
    timelineOwner: { handler: 'loadTimelineOwnerAroundDatas', immediate: true }
  },

  methods: {
    // setTargetTimelineOwner () {
    //   this.taskTargetTimelineOwner = this.timelineOwner
    // },

    async loadTaskRelatedUsers () {
      if (this.loggedInUser) {
        this.teskRelatedUsers = []
        await Family.api().fetch({ timelineOwnerID: this.loggedInUser.id })
        var fams = await Family.query().where('user_id', this.loggedInUser.id).get()
        await this.loadMissingAuthors(fams)
        var families = await Family.query().where('user_id', this.loggedInUser.id).where('relation', (value) => value < 3).get()
        // console.log('family count is ' + families.length)
        this.teskRelatedUsers.push({ author: this.loggedInUser.author, user_id: this.loggedInUser.id, author_id: this.loggedInUser.author_id, nbid: this.loggedInUser.nbid })
        for(var family of families) {
          // console.log(JSON.stringify(family))
          this.teskRelatedUsers.push({ author: family.author, user_id: family.rawFamilyID, author_id: family.family_id, nbid: family.nbid })
        }
      } else if (this.signinMember) {
        if (!this.teskRelatedUsers) {
          await RelatedUser.fetch().then(async rel => {
            var unloadAuthors = []
            for(var uStatus of rel.related_user_status) {
              if (!Author.find(uStatus.author_id)) {
                if (unloadAuthors.indexOf(uStatus.author_id) < 0) unloadAuthors.push(uStatus.author_id)
              }
            }
            if (unloadAuthors.length > 0) {
              await Author.api().fetchByID({ timelineOwnerID: this.signinMember.user_id, idList: unloadAuthors })
            }
            for(var userStatus of rel.related_user_status) {
              userStatus.author = Author.find(userStatus.author_id)
            }
            this.teskRelatedUsers = rel.related_user_status
            this.teskRelatedUsers.sort((f, s) => {
              if (f.author.nameKana > s.author.nameKana) {
                return 1
              } else if (f.author.nameKana < s.author.nameKana) {
                return -1
              } else {
                return 0
              }
            })
            return this.teskRelatedUsers
          })
        }
      }
    },

    async loadMissingAuthors (preLoadFamilies) {
      var missingAuthorIDList = []
      for(var family of preLoadFamilies) {
        if (!family.author) {
          if (missingAuthorIDList.indexOf(family.family_id) < 0) missingAuthorIDList.push(family.family_id)
        }
      }
      if (missingAuthorIDList.length > 0) {
        await Author.api().fetchByID({ timelineOwnerID: this.loggedInUser.id, idList: missingAuthorIDList })
        return true
      }
    },

    userTaskUpdated () {
      this.taskShouldUpdate = false
    },

    createTaskByUser (targetUser) {
      this.timelineOwnerSelected(targetUser)
    },

    createUserTask (targetType) {
      // console.log('HorusUserTaskControll.js, createUserTask call, ::' + targetType)
      if (targetType === 'none') {
        if (this.timelineOwner) {
          this.timelineOwnerSelected(this.timelineOwner.id)
        } else {
          this.showSelectTimelineOwnerModal = true
        }
      } else if (targetType === 'user') {
        if (this.timelineOwner) {
          this.showUserTaskFormModal = true
        } else {
          this.showSelectTimelineOwnerModal = true
        }
      } else if (targetType === 'organization') {
        this.showUserTaskFormModal = true
      }
    },

    async editTask (targetTask) {
      // ここのtargetTask.timelineOwnerは、HorusMainTimelineViewである場合は、MainTimelineのTimelineOwnerに限定されているから、上書きしても問題は発生しない。
      // 自身のタイムラインを表示している際、他者のタイムラインに設定されている予定がある場合、TimelineOwnerが変更されるとタイムラインを移動してしまう。
      if (this.timelineOwner === targetTask.timelineOwner || this.signinMember) {
        this.executeEditTask(targetTask)
      } else {
        this.showConfirmDialog(
          '予定の編集',
          '自分以外のタイムラインに設定されている予定を編集するため、その人のタイムラインに移動します。\nよろしいですか？',
          DIALOG_TYPE.YES_NO,
          () => {
            this.executeEditTask(targetTask)
          }
        )
      }
    },

    async executeEditTask (targetTask) {
      this.timelineOwner = targetTask.timelineOwner
      if (targetTask.user_id && !this.timelineOwner) {
        // load timelineOwner from server
        await TimelineOwner.api().fetch({ timelineOwnerID: targetTask.user_id })
        this.timelineOwner = TimelineOwner.find(targetTask.user_id)
      }
      this.selectTargetOrganization = targetTask.organization
      this.formTaskTargetAt.date = targetTask.targetAt
      this.taskSelectedTags = targetTask.hashTags
      this.taskCreateTags = []
      this.taskPushTargets = targetTask.notifyTargets
      this.editTargetTask = targetTask
      this.showUserTaskFormModal = true
    },

    deleteTask (targetTask) {
      // console.log('delete task :: ' + JSON.stringify(targetTask))
      var message = ''
      if (targetTask.timelineAuthor) {
        message = '[' + targetTask.timelineAuthor.name + ', ' + targetTask.targetAtStr + ']の予定を削除します。\nよろしいですか?'
      } else if (targetTask.organization) {
        message = '[' + targetTask.organization.shortName + ', ' + targetTask.targetAtStr + ']の予定を削除します。\nよろしいですか?'
      }
      this.showConfirmDialog(
        '予定の削除',
        message,
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeDestroyTask(targetTask)
        }
      )
    },

    executeDestroyTask (targetTask) {
      UserTask.api().destroy({ targetID: targetTask.id }).then(() => {
        this.$emit('taskUpdatedEvent')
      })
    },

    showTaskDetail (task) {
      this.$emit('showUserTaskDetailEvent', task)
    },

    showTaskPhoto (userTask, photoIndex) {
      if (userTask.userTaskPhotos.length > 0) {
        if (!photoIndex || photoIndex > userTask.userTaskPhotos.length - 1) {
          photoIndex = 0
        }
      }
      this.photoModalPhotos = userTask.userTaskPhotos
      this.photoModalFirst = userTask.userTaskPhotos[photoIndex]
      this.photoModalShow = true
    },

    async timelineOwnerSelected (userID) {
      this.showSelectTimelineOwnerModal = false
      if (!this.targetDate) {
        this.setTaskTargetDate()
      }
      if (userID) {
        var owner = TimelineOwner.find(userID)
        if (owner === null) {
          await TimelineOwner.api().fetch({ timelineOwnerID: userID })
          this.timelineOwner = TimelineOwner.find(userID)
        } else {
          this.timelineOwner = owner
        }
        this.organizationStaffVisibilities = this.timelineOwner.organizationStaffVisibilities
        this.createUserTask('user')
      } else {
        this.timelineOwner = null
        if (this.signinMember) {
          this.selectTargetOrganization = this.signinMember.organization
          this.teskOrganizations = [this.signinMember.organization]
        }
        this.createUserTask('organization')
      }
    },

    timelineOwnerSelectCancel () {
      this.showSelectTimelineOwnerModal = false
    },

    async loadTimelineOwnerAroundDatas () {
      this.hashTags = []
      this.teskOrganizations = []
      this.notificatableFamilies = []
      if (this.timelineOwner) {
        await Promise.all([
          this.loadAuthors(),
          this.loadHashTags(),
          this.loadOrganizations(),
          this.loadFamilies()
        ])
        var fams = []
        fams = fams.concat(this.families.filter(family => family.relation < 3))
        var famNotify = []
        if (this.timelineOwner) {
          if (this.signinMember) {
            famNotify.push(this.timelineOwner.author)
          }
        }
        fams.forEach(family => {
          famNotify.push(family.author)
        })
        this.notificatableFamilies = famNotify
      }
    },

    setTaskTargetDate () {
      this.reserveFutureDateSelection(this.formTaskTargetAt, this.openUserTaskFormModal, null)
      this.futureDateSelectionOpen()
      // this.futureDateSelectionModalShow = true
    },

    openUserTaskFormModal () {
      if (!this.showUserTaskFormModal) {
        this.showUserTaskFormModal = true
      }
    },

    openTagSelectionModalForTask () {
      this.presentedModal = PRESENTED_MODAL_TYPE.TAG_SELECTION
      this.selectTargetsForTag = this.taskSelectedTags
      this.createdTargetForTag = this.taskCreateTags
    },

    removeSelectedTagForTask (targetTag) {
      var tagIndex = this.taskSelectedTags.indexOf(targetTag)
      if (tagIndex >= 0) {
        this.taskSelectedTags.splice(tagIndex, 1)
      }
    },

    removeCreateTagForTask (index) {
      this.taskCreateTags.splice(index, 1)
    },

    openPushTargetSelectionModalForTask () {
      // console.log('openPushTargetSelectionModalForTask call,')
      this.selectTargetForAuthor = this.taskPushTargets
      this.allFamilySelectForAuthor = this.taskAllFamilyNotify
      this.selectOrganizationTargetForAuthor = this.taskSelectedOrganizationForNotify
      // console.log('openPushTargetSelectionModalForTask call, processing.....')
      this.pushTargetSelectModalOpen()
    },

    removeSelectedAuthorForTask (author, index) {
      var authorIndex = index
      if (!index >= 0) {
        authorIndex = this.selectTargetForAuthor.indexOf(author)
      }
      if (authorIndex >= 0) {
        this.selectTargetForAuthor.splice(authorIndex, 1)
        this.updatePushTargetGroupState(author)
      }
    },

    taskFormClear (modalTitle, callback) {
      this.showConfirmDialog(
        modalTitle,
        modalTitle + 'します。\n変更された内容は破棄されます。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.showUserTaskFormModal = false
          this.clearTaskModalFormElements()
          callback()
        }
      )
    },

    clearTaskModalFormElements () {
      // console.log('clear task modal form elements.....')
      // ここでは、HorusMainTimelineViewのとき、this.timelineOwnerが別のコンポーネントで利用されている可能性があるので、null代入してはいけない。
      if (this.$options.name !== 'HorusMainTimelineView') {
        this.timelineOwner = null
      }
      this.selectTargetOrganization = null
      this.editTargetTask = null
      this.formTaskTargetAt.date = null
      this.selectTargetsForTag = []
      this.createdTargetForTag = []
      this.taskSelectedTags = []
      this.taskCreateTags = []
      this.selectTargetForAuthor = []
      this.taskPushTargets = []
    },

    submitTaskForm (formData, successCallback) {
      if (this.editTargetTask) {
        UserTask.api().update({ formData: formData }).then(() => {
          this.showNotifyDialog('予定の更新', '予定の内容を更新しました')
          this.showUserTaskFormModal = false
          this.clearTaskModalFormElements()
          successCallback()
          this.taskShouldUpdate = true
          this.$emit('taskUpdatedEvent')
        }).catch(err => {
          console.error(err.response)
          this.showNotifyDialog('予定の更新失敗', '予定の更新に失敗しました')
        })
      } else {
        UserTask.api().create({ formData: formData }).then(() => {
          this.showNotifyDialog('予定の新規作成', '予定を新規作成しました')
          this.showUserTaskFormModal = false
          this.clearTaskModalFormElements()
          successCallback()
          this.taskShouldUpdate = true
          this.$emit('taskUpdatedEvent')
        }).catch(err => {
          console.error(err.response)
          this.showNotifyDialog('予定の新規作成失敗', '予定の新規作成に失敗しました')
        })
      }
    },

    showTaskConfiguration () {
      this.$emit('showTaskConfigurationEvent')
    },

    jumpToOriginFromPhotoModal () {
      // nothing to do.
    },

    closePhotoModal () {
      this.photoModalShow = false
      this.photoModalPhotos = []
      this.photoModalFirst = null
    }
  }
}
