<template>
  <div class="horus-system-contact-mail-view">
    <h1 class="centering-title">お問い合わせ</h1>
    <h2>お問い合わせ一覧</h2>
    <div class="contact-list">
      <div class="list-column"
        v-for="contact in contacts"
        :key="'sysContactList' + contact.id"
        >
        <div class="list-line">
          <div class="name">{{ contact.name }}[{{ contact.name_kana }}]</div>
          <div class="mail">メール : [{{ contact.mail_address }}]</div>
          <div class="contact-at">送信日 : [{{ contactAt(contact.contact_at) }}]</div>
          <div class="from">送信 : {{ contact.contact_from }}</div>
          <div class="controll"><button type="button" class="show-btn" @click="showDetail(contact)">詳細</button></div>
        </div>
      </div>
    </div>
    <div class="contact-controlls"><button class="load-btn" @click="appendContact">更に読み込む</button></div>
    <horus-system-contact-mail-detail-modal
      :contact="detailContact"
      :showModal="showDetailModal"
      v-on:closeEvent="closeDetail"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { ContactMail } from '@/api'

import HorusSystemContactMailDetailModal from '@/components/manageSystem/contactMail/HorusSystemContactMailDetailModal.vue'

export default {
  name: 'HorusSystemContactMailView',

  components: {
    HorusSystemContactMailDetailModal
  },

  data () {
    return {
      contacts: [],
      limit: 100,
      currentOffset: 0,
      detailContact: null,
      showDetailModal: false
    }
  },

  mounted () {
    this.initDatas()
  },

  methods: {
    initDatas () {
      this.detailContact = null
      this.currentOffset = 0
      this.contacts = []
      this.loadContacts()
    },

    loadContacts () {
      ContactMail.fetch({ limit: this.limit, offset: this.currentOffset }).then(res => {
        console.log(res)
        this.contacts = this.contacts.concat(res.contactMails)
        this.currentOffset += res.contactMails.length
      }).catch(err => {
        console.error(err.response)
      })
    },

    appendContact () {
      this.loadContacts()
    },

    reloadContacts () {
      this.initDatas()
    },

    contactAt (time) {
      var date = new Date(time)
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm:ss')
      }
    },

    showDetail (contact) {
      this.detailContact = contact
      this.showDetailModal = true
    },

    closeDetail () {
      console.log('close detail')
      this.showDetailModal = false
      this.detailContact = null
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-contact-mail-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .contact-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            width: 200px;
            font-weight: bold;
          }
          &.mail {
            width: 250px;
          }
          &.from {
            width: 100px;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .contact-controlls {
    margin: 10px 0;
    text-align: center;
    .load-btn {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
