<template>
  <div class="horus-create-reporter-relation-invitation-modal">
    <horus-modal-frame
      :showModalFlag="showCreateRelationInvitationModal">
      <div class="modal-header">
        <h1>サービス利用案内状の作成</h1>
      </div>
      <div class="modal-body" v-if="reporterOrganization">
        <div class="create-tab-labels">
          <div class="tab-label" :class="{active: createRequestModeNBID}" @click="requestModeToNBID">NBIDで作成</div>
          <div class="tab-label" :class="{active: !createRequestModeNBID}" @click="requestModeToMail">メールアドレスで作成</div>
        </div>
        <div class="create-by-nbid" v-if="createRequestModeNBID">
          <div class="switch-request-type">NBIDがわからない場合は<span class="link" @click="requestModeToMail">こちら</span></div>
          <horus-attribute-in-form
            :labelText="'NBID'"
            :necessary="true"
            :validationState="requestNBIDValidation.state"
            :validationMessage="requestNBIDValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetNBID" />
          </horus-attribute-in-form>
        </div>
        <div class="create-by-mail" v-if="!createRequestModeNBID">
          <div class="switch-request-type">NBIDがわかっている場合は<span class="link" @click="requestModeToNBID">こちら</span></div>
          <horus-attribute-in-form
            :labelText="'メールアドレス'"
            :necessary="true"
            :validationState="requestMailValidation.state"
            :validationMessage="requestMailValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMail" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'メールアドレス(確認)'"
            :necessary="true"
            :validationState="requestMailConfirmationValidation.state"
            :validationMessage="requestMailConfirmationValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMailConfirmation" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'宛先名(メールに書き込まれる相手の名前)'"
            :necessary="true"
            :validationState="requestNameValidation.state"
            :validationMessage="requestNameValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetName" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'本人確認コード(数字4桁:空白の場合自動生成)'"
            :necessary="false"
            :validationState="requestShortTokenValidation.state"
            :validationMessage="requestShortTokenValidation.message"
            :attributeNotify="'4桁の数字(半角)'">
            <input type="text" v-model="requestShortToken" />
          </horus-attribute-in-form>
        </div>
        <horus-attribute-in-form
          :labelText="'メッセージ'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <textarea rows="3" cols="20" v-model="requestMessage"></textarea>
        </horus-attribute-in-form>
        <div class="notify_for_admit">
          <h1>サービス利用登録における確認事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>利用者との円滑なコミュニケーションのために、報告サービス施設になることを利用者本人から同意されていること</li>
            <li>なりすまし防止のために、案内先の方との確認ができていること</li>
            <li>利用者のタイムラインに書き込む情報の内容について、説明し同意を取得した上でサービス利用設定を行ってください</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendRequest">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !requestReady }" @click="submitSendRequest">案内状の送信</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusUserInvitationValidation from '@/mixin/HorusUserInvitationValidation.js'

export default {
  mixins: [HorusUserInvitationValidation],

  name: 'HorusCreateReporterRelationInvitationModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    showCreateRelationInvitationModal: Boolean,
    reporterOrganization: Object,
    users: Array,
    sentInvitations: Array,
    receiveRequests: Array,
    reservedNBID: String
  },

  data () {
    return {
      createRequestModeNBID: true,
      requestTargetNBID: '',
      requestTargetMail: '',
      requestTargetMailConfirmation: '',
      requestTargetName: '',
      requestShortToken: '0000',
      requestMessage: '',
      admitAgreement: false
    }
  },

  watch: {
    showCreateRelationInvitationModal: { handler: 'initReserved', immediate: true }
  },

  computed: {
    requestNBIDValid () {
      if (this.requestTargetNBID.length > 0) {
        if (this.users.some(user => user.nbid === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定されたNBIDはサービス利用済です' }
        } else if (this.sentInvitations.some(inv => inv.login_id === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定されたNBIDはすでに案内済みです' }
        } else if (this.receiveRequests.some(req => req.nbid === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定されたNBIDの利用者から申請を受けています' }
        } else {
          return { state: 'valid', message: '' }
        }
      } else {
        return { state: 'invalid', message: 'NBIDは入力必須です' }
      }
    }

    // requestNBIDNotify () {
    //   if (this.requestTargetNBID.length <= 0) {
    //     return 'NBIDは入力必須です'
    //   } else {
    //     if (this.users.some(user => user.nbid === this.requestTargetNBID)) {
    //       return '指定されたNBIDはサービス利用済です'
    //     } else if (this.sentInvitations.some(inv => inv.login_id === this.requestTargetNBID)) {
    //       return '指定されたNBIDはすでに案内済みです'
    //     } else if (this.receiveRequests.some(req => req.nbid === this.requestTargetNBID)) {
    //       return '指定されたNBIDの利用者から申請を受けています'
    //     } else {
    //       return ''
    //     }
    //   }
    // },

    // requestMailState () {
    //   if (this.requestTargetMail.length > 0) {
    //     return 'valid'
    //   } else {
    //     return 'invalid'
    //   }
    // },

    // requestMailNotify () {
    //   if (this.requestTargetMail.length <= 0) {
    //     return 'メールアドレスは入力必須です'
    //   } else {
    //     return ''
    //   }
    // },

    // requestMailConfirmationState () {
    //   if (this.requestTargetMail.length > 0 && this.requestTargetMail === this.requestTargetMailConfirmation) {
    //     return 'valid'
    //   } else {
    //     return 'invalid'
    //   }
    // },

    // requestMailConfirmationNotify () {
    //   if (this.requestTargetMail !== this.requestTargetMailConfirmation) {
    //     return '入力されたメールアドレスと一致しません'
    //   } else {
    //     return ''
    //   }
    // },

    // requestNameState () {
    //   if (this.requestTargetName.length > 0) {
    //     return 'valid'
    //   } else {
    //     return 'invalid'
    //   }
    // },

    // requestNameNotify () {
    //   if (this.requestTargetName.length <= 0) {
    //     return '送信相手の名前は入力必須です'
    //   } else {
    //     return ''
    //   }
    // },

    // requestShortTokenState () {
    //   if (this.requestShortToken.length <= 0 || this.requestShortToken.trim().match(/^\d{4}$/g)) {
    //     return 'valid'
    //   } else {
    //     return 'invalid'
    //   }
    // },

    // requestShortTokenNotify () {
    //   if (this.requestShortToken.length > 0 && this.requestShortToken.trim().match(/^\d{4}$/g)) {
    //     return '本人確認コードは半角数字4桁で入力ください'
    //   } else {
    //     return ''
    //   }
    // },

    // requestReady () {
    //   if (this.createRequestModeNBID) {
    //     if (this.requestTargetNBID.length > 0 && this.admitAgreement) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   } else {
    //     if (
    //       this.requestTargetMail.length > 0 &&
    //       this.requestTargetMail === this.requestTargetMailConfirmation &&
    //       this.requestTargetName.length > 0 &&
    //       this.admitAgreement
    //     ) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   }
    // }
  },

  methods: {
    initReserved () {
      if (this.showCreateRelationInvitationModal) {
        this.initParams()
      }
    },

    // requestModeToMail () {
    //   this.createRequestModeNBID = false
    // },

    // requestModeToNBID () {
    //   this.createRequestModeNBID = true
    // },

    // toggleAdmit () {
    //   this.admitAgreement = !this.admitAgreement
    // },

    initParams () {
      this.createRequestModeNBID = true
      if (typeof this.reservedNBID === 'string') {
        this.requestTargetNBID = this.reservedNBID
      } else {
        this.requestTargetNBID = ''
      }
      this.requestTargetMail = ''
      this.requestTargetMailConfirmation = ''
      this.requestTargetName = ''
      this.requestShortToken = '0000'
      this.requestMessage = ''
      this.admitAgreement = false
    },

    cancelSendRequest () {
      this.$emit('cancelSendInvitationEvent', () => { this.initParams() })
    },

    submitSendRequest () {
      if (this.admitAgreement && (this.requestTargetNBID || (this.requestTargetMail && this.requestTargetMail === this.requestTargetMailConfirmation && this.requestTargetName))) {
        var formData = new FormData()
        formData.append('reporter_organization_id', this.reporterOrganization.id)
        if (this.requestTargetNBID) {
          formData.append('reporter_organization_invitation_for_user[login_id]', this.requestTargetNBID)
        } else if (this.requestTargetMail) {
          formData.append('reporter_organization_invitation_for_user[name]', this.requestTargetName)
          formData.append('reporter_organization_invitation_for_user[mail]', this.requestTargetMail)
          formData.append('reporter_organization_invitation_for_user[short_token]', this.requestShortToken)
        }
        formData.append('reporter_organization_invitation_for_user[message_content]', this.requestMessage)
        var sendTarget = this.requestTargetNBID
        if (!this.requestTargetNBID) {
          sendTarget = this.requestTargetName
        }
        this.$emit('submitSendInvitationEvent', formData, sendTarget, () => { this.initParams() })
      }
    }
  }
}
require('@/assets/css/createWithAgreementForm.scss')
</script>

<style lang="scss" scoped>
.horus-create-reporter-relation-invitation-modal {
  .modal-body {
    padding: 10px 20px;
    .link {
      cursor: pointer;
      color: #428bca;
    }
    input {
      font-size: 16px;
    }
    textarea {
      font-size: 16px;
    }
  }
  .create-tab-labels {
    margin: 0;
    display: flex;
    align-items: flex-end;
    .tab-label {
      margin: 0;
      padding: 3px 10px;
      border: 1px solid #666;
      border-bottom: none;
      background-color: #ddd;
      color: #666;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      &.active {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .create-by-nbid {
    border: 1px solid #666;
    padding: 10px;
  }
  .create-by-mail {
    border: 1px solid #666;
    padding: 10px;
  }
  .submit-controll {
    margin: 10px 0;
    text-align: center;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      &.cancel-btn {
        background-color: #fff;
        color: #666;
      }
      &.send-request-btn {
        background-color: #666;
        color: #fff;
        &.disable {
          background-color: #aaa;
        }
      }
    }
  }
}
</style>
