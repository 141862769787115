<template>
  <div class="horus-third-party-linkage-request-view">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="contents">
      <h1 class="centering-title">第三者サービス連携の登録申請</h1>
      <h2 class="how-to-title">第三者サービス連携登録の流れ</h2>
      <div class="how-to">
        <ol>
          <li>
            本ページにて登録情報を入力する(新規登録)
            <div class="how-to-caption">
              内容は正確にお願いします。特に、メールアドレスの入力間違いは、その先の手続きが行えなくなりますので注意ください。
            </div>
          </li>
          <li>
            登録したメールアドレスに疎通確認メールが届くので、そのリンクを表示する(メール疎通確認)
            <div class="how-to-caption">
              疎通確認の期限は、登録後2日間となっています。それを過ぎると登録が無効化されますのでご注意ください。
            </div>
          </li>
          <li>
            ナラティブブックの運営担当による審査(審査)
            <div class="how-to-caption">
              内容の確認のため、登録された電話番号に連絡する場合があります。
            </div>
          </li>
          <li>
            審査結果のメールによる通知
            <div class="how-to-caption">
              登録されたメールアドレスに審査結果を送信します。承認された場合、接続に必要な情報がメールに記載されていますので、メールをなくさないように気をつけてください。
            </div>
          </li>
          <li>
            ログインキーの作成(キー作成)
            <div class="how-to-caption">
              別途公開されているマニュアルに従ってログインキーの生成等を行って利用してください。
            </div>
          </li>
        </ol>
      </div>
      <div class="input-form" v-if="!linkage">
        <h2>申請登録内容の入力</h2>
        <div class="input-form-content">
          <horus-system-third-party-linkage-form
            :originalData="null"
            :submitTitle="'登録'"
            :cancelTitle="'クリア'"
            v-on:cancelEvent="clearForm"
            v-on:submitEvent="submitForm"
            />
        </div>
      </div>
      <div class="submitted-result" v-if="linkage">
        <h2>申請登録受け付け完了</h2>
        <div class="submitted-message">
          ナラティブブック第三者サービス連携の登録を下記の内容で受け付けしました。<br/>
          このページを閉じて構いません。<br/>
          確認メールが送信されていますので、メールのリンクに従って手続きを進めてください。
        </div>
        <div class="submit-linkage">
          <div class="form-list-line">
            <div class="category-title">サービス情報</div>
            <div class="form-line name">
              <label class="linkage-label">名称</label>
              {{ linkage.name }}
            </div>
            <div class="form-line description">
              <label class="linkage-label">詳細</label>
              <span v-html="brTextHtml(linkage.description)"></span>
            </div>
            <div class="form-line fdqn">
              <label class="linkage-label">FDQN</label>
              {{ linkage.fdqn }}
            </div>
            <div class="form-line callback_url">
              <label class="linkage-label">コールバックアドレス</label>
              {{ linkage.callback_url }}
            </div>
            <div class="category-title">サービス提供者情報</div>
            <div class="form-line provider_name">
              <label class="linkage-label">提供者名</label>
              {{ linkage.provider_name }}
            </div>
            <div class="form-line provider_zip_code">
              <label class="linkage-label">郵便番号</label>
              {{ linkage.provider_zip_code }}
            </div>
            <div class="form-line provider_address">
              <label class="linkage-label">住所</label>
              <span v-html="brTextHtml(linkage.provider_address)"></span>
            </div>
            <div class="form-line provider_mail">
              <label class="linkage-label">メールアドレス</label>
              {{ linkage.provider_mail }}
            </div>
            <div class="form-line provider_tel">
              <label class="linkage-label">電話番号</label>
              {{ linkage.provider_tel }}
            </div>
            <div class="form-line provider_contact">
              <label class="linkage-label">担当者名</label>
              {{ linkage.provider_contact }}
            </div>
            <div class="form-line provider_url">
              <label class="linkage-label">ホームページ</label>
              {{ linkage.provider_url }}
            </div>
          </div>
        </div>
      </div>
      <horus-footer />
    </div>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { ThirdPartyLinkage } from '@/api'

import HorusSystemThirdPartyLinkageForm from '@/components/manageSystem/thirdPartyLinkage/HorusSystemThirdPartyLinkageForm.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusThirdPartyLinkageRequestView',

  components: {
    HorusSystemThirdPartyLinkageForm,
    HorusConfirmDialog,
    HorusFooter
  },

  data () {
    return {
      linkage: null
    }
  },

  mounted () {
    this.linkage = null
  },

  computed: {
    linkageDescriptionHtml () {
      if (this.linkage && this.linkage.description) {
        return this.linkage.description.replace(/\n/gi, '<br />')
      }
      return ''
    }
  },

  methods: {
    brTextHtml (text) {
      if (text) {
        return text.replace(/\n/gi, '<br />')
      }
      return ''
    },

    clearForm (callback) {
      this.showConfirmDialog(
        '編集内容のクリア',
        '編集内容をクリアします。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        callback
      )
    },

    submitForm (formData, linkage) {
      ThirdPartyLinkage.create({formData: formData}).then(() => {
        this.linkage = linkage
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('第三者サービス連携の登録失敗', '第三者サービス連携の登録に失敗しました。')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-third-party-linkage-request-view {
  margin: 0 auto;
  background-color: #fff;
  width: 1000px;
  height: 100%;
  .contents {
    height: calc(100% - 51px - 20px);
    overflow: scroll;
    padding: 10px 50px;
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      margin: 5px;
      margin-top: 10px;
      font-size: 1.1em;
      font-weight: bold;
    }
    .how-to {
      border: 1px solid #ddd;
      background-color: #eee;
      ol {
        padding-inline-start: 30px;
        li {
          margin: 10px 0;
          .how-to-caption {
            margin: 5px 0;
            font-size: 12px;
          }
        }
      }
    }
    .input-form {
      padding: 20px 0 0 0;
      .input-form-content {
        padding: 10px;
        border: 1px solid #ddd;
      }
    }
  }
  .submitted-message {
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #aaa;
  }
  .submit-linkage {
    .form-list-line {
      .category-title {
        margin-top: 10px;
        font-weight: bold;
      }
      .form-line {
        display: flex;
        margin: 5px 0;
        padding-bottom: 5px;
        border-bottom: 1px solid #ddd;
        .linkage-label {
          width: 150px;
          padding: 0 10px;
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
