export const NOTIFICATION_MAIL_NOT_VALID = 10
export const NOTIFICATION_CONFIRM_AGREEMENT = 20
export const NOTIFICATION_ACCOUNT_BAN = 30
export const NOTIFICATION_MESSAGE = 50
export const NOTIFICATION_OTHERS = 100

export const NOTIFICATION_TYPES = [
  [NOTIFICATION_MAIL_NOT_VALID, 'メールアドレスの不正'],
  [NOTIFICATION_CONFIRM_AGREEMENT, '規約への同意'],
  [NOTIFICATION_ACCOUNT_BAN, 'アカウント停止'],
  [NOTIFICATION_MESSAGE, '重要な通知'],
  [NOTIFICATION_OTHERS, 'その他の警告']
]

export const RESOLVE_TYPE_SHOW_ONECE = 10
export const RESOLVE_TYPE_CONFIRM_ONCE = 20
export const RESOLVE_TYPE_TARGET = 100

export const RESOLVE_TYPES = [
  [RESOLVE_TYPE_SHOW_ONECE, '一度だけ表示'],
  [RESOLVE_TYPE_CONFIRM_ONCE, 'その場の同意で消去'],
  [RESOLVE_TYPE_TARGET, '問題の解決が必要']
]

export const RESOLVE_URL_MAIL = '/resolve_invalid_mail'
export const RESOLVE_URL_AGREEMENT = '/resolve_confirm_agreement'
export const RESOLVE_URL_BAN_EXCUSE = '/ban_excuse'
export const RESOLVE_URL_ANY = '/critical_notification_resolve'

export const RESOLVE_URLS = [
  RESOLVE_URL_MAIL,
  RESOLVE_URL_AGREEMENT,
  RESOLVE_URL_BAN_EXCUSE,
  RESOLVE_URL_ANY
]

export const GROUP_TARGET_TYPE_MANAGER = 10
export const GROUP_TARGET_TYPE_MEMBER = 20

export const GROUP_TARGET_TYPES = [
  [GROUP_TARGET_TYPE_MANAGER, '施設管理者'],
  [GROUP_TARGET_TYPE_MEMBER, '施設職員']
]
