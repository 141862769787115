<template>
  <div class="horus-nudged-timeline">
    <h1 class="nudged-timeline-title">
      <img src="/static/images/nudge_title_icon.png" />
      nudge&nbsp;リスト
    </h1>
    <!-- timeline narrow down controll -->
    <horus-narrow-down-hash-tags
      v-show="narrowDownTags.length > 0"
      :narrowDownTags="narrowDownTags"
      v-on:removeNarrowDownTagEvent="removeNarrowDownTag" />
    <horus-narrow-down-authors
      v-show="narrowDownAuthors.length > 0"
      :narrowDownAuthors="narrowDownAuthors"
      v-on:removeNarrowDownAuthorEvent="removeNarrowDownAuthor" />
    <div class="normal-record-timeline" ref="mainTimelineInner">
      <div v-if="nudgeNowLoading" class="now-loading">読み込み中...</div>
      <div class="nudged-records"  v-if="!nudgeNowLoading">
        <horus-nudged-timeline-record
          v-for="nudgedRecord in nudgedRecords"
          :key="'nudgedTimelineRecord' + nudgedRecord.id"
          :timelineOwner="timelineOwner"
          :loggedInAuthor="loggedInAuthor"
          :nudgedRecord="nudgedRecord"
          :visible="recordVisible(nudgedRecord.record)"
          v-on:deleteNudgeEvent="removeNudge"
          v-on:jumpToOriginEvent="jumpToOrigin"
          v-on:narrowDownByTagEvent="narrowDownByTag"
          v-on:showRecorPhotoEvent="showPhoto"
          v-on:showDrugEntryPhotoEvent="showDrugEntryPhoto"
          v-on:openRecordFileEvent="openFile"
          />
      </div>
      <div class="empty-message" v-if="nudgedRecords.length <= 0">nudgeされた書き込みはありません</div>
    </div><!-- normal-timeline end -->
  </div>
</template>

<script>
import HorusNarrowDownHashTags from '@/components/timelineColumn/HorusNarrowDownHashTags.vue'
import HorusNarrowDownAuthors from '@/components/timelineColumn/HorusNarrowDownAuthors.vue'
import HorusNudgedTimelineRecord from '@/components/timelineColumn/HorusNudgedTimelineRecord.vue'

export default {
  name: 'HorusNudgedTimeline',

  components: {
    HorusNarrowDownHashTags,
    HorusNarrowDownAuthors,
    HorusNudgedTimelineRecord
  },

  props: {
    timelineOwner: Object,
    loggedInAuthor: Object,
    nudgedRecords: Array,
    narrowDownTags: Array,
    narrowDownAuthors: Array,
    nudgeNowLoading: Boolean
  },

  methods: {
    recordVisible (record) {
      if (this.narrowDownTags.length > 0) {
        var exist = false
        this.narrowDownTags.forEach(tag => {
          if (record.tags.indexOf(tag.id) >= 0) {
            exist = true
          }
        })
        return exist
      } else if (this.narrowDownAuthors.length > 0) {
        if (this.narrowDownAuthors.indexOf(record.author) >= 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    removeNarrowDownTag (tag) {
      this.$emit('removeNarrowDownTagEvent', tag)
    },

    removeNarrowDownAuthor (author) {
      this.$emit('removeNarrowDownAuthorEvent', author)
    },

    removeNudge (nudgedRecord) {
      this.$emit('deleteNudgeEvent', nudgedRecord)
    },

    jumpToOrigin (record) {
      this.$emit('jumpToOrginEvent', record)
    },

    narrowDownByTag (tag) {
      this.$emit('narrowDownByTagEvent', tag)
    },

    showPhoto (record, firstShowIndex) {
      this.$emit('showRecorPhotoEvent', record, firstShowIndex)
    },

    showDrugEntryPhoto (record, firstShowIndex) {
      this.$emit('showDrugEntryPhotoEvent', record, firstShowIndex)
    },

    openFile (file) {
      this.$emit('openRecordFileEvent', file)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-nudged-timeline {
  margin: 0;
  .nudged-timeline-title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    color: #999;
    img {
      width: 10px;
    }
  }
}
</style>
