<template>
  <div class="horus-select-timeline-owner-for-multipost-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/task_pad_icon_s.png" class="title-icon" />予定登録先利用者選択</h1>
      </div>
      <div class="modal-body">
        <div class="timeline-owner-list">
          <div class="timeline-owner-column" v-for="timelineOwner in timelineOwners" :key="'usrMultipostTLO' + timelineOwner.user_id" @click="toggleSelect(timelineOwner)">
            <horus-custom-check-box :type="'small'" :active="ownerSelected(timelineOwner)" :enabled="true" />
            <img :src="timelineOwner.author.portrateSrc"/>
            <div class="name-column">{{ timelineOwner.author.name }}({{ timelineOwner.author.age }})</div>
          </div>
        </div>
        <div class="controll">
          <button class="cancel-btn" @click="cancelSelect">キャンセル</button>
          <button class="submit-btn" @click="submitSelect">決定</button>
          <button class="select-all-btn" @click="selectAllAndSubmit">全員を選択</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusSelectTimelineOwnerForMultipostModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    timelineOwners: Array,
    showModal: Boolean
  },

  data () {
    return {
      selectedOwners: []
    }
  },

  watch: {
    showModal: { handler: 'initSelection', immediate: true }
  },

  methods: {
    initSelection () {
      this.selectedOwners = []
    },

    toggleSelect (timelineOwner) {
      var index = this.selectedOwners.indexOf(timelineOwner)
      console.log('toggle select :' + index)
      if (index > 0) {
        this.selectedOwners.splice(index, 1)
      } else {
        this.selectedOwners.push(timelineOwner)
      }
    },

    ownerSelected (timelineOwner) {
      var index = this.selectedOwners.indexOf(timelineOwner)
      if (index < 0) {
        return false
      } else {
        return true
      }
    },

    cancelSelect () {
      this.$emit('multipostTargetSelectCancelEvent')
    },

    submitSelect () {
      this.$emit('multipostTargetSelectSubmitEvent', this.selectedOwners)
    },

    selectAllAndSubmit () {
      this.$emit('multipostTargetSelectAllSubmitEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/_basicInputStyle.scss';

.horus-select-timeline-owner-for-multipost-modal {
  .modal-body {
    padding: 10px;
    @include mediaQuery('phone') {
      padding: 5px;
    }
    h2 {
      margin: 0 10px;
      font-size: 1.1em;
      color: #666;
    }
    .timeline-owner-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .timeline-owner-column {
        display: flex;
        align-items: center;
        margin: 5px;
        padding: 5px;
        // width: 30%;
        // border: 1px solid #aaa;
        img {
          height: 30px;
          width: 30px;
        }
        .name-column {
          margin-left: 5px;
          font-size: 1.1em;
          font-weight: bold;
        }
        @include mediaQuery('phone') {
          width: calc(50% - 25px);
        }
      }
    }
    .controll {
      text-align: right;
      .cancel-btn {
          width: 100px;
          margin-right: 10px;
          padding: 3px 10px;
          font-weight: bold;
          font-size: 14px;
          border-radius: 3px;
          background-color: #fff;
          color: #333;
          border: 1px solid #999;
      }
      .submit-btn {
          width: 100px;
          margin-right: 10px;
          padding: 3px 10px;
          font-weight: bold;
          font-size: 14px;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
      }
      .select-all-btn {
          width: 100px;
          margin-right: 10px;
          padding: 3px 10px;
          font-weight: bold;
          font-size: 14px;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          border: 1px solid #333;
      }
    }
  }
}
</style>
