<template>
  <div class="horus-create-deputize-request-for-organization-modal">
    <horus-modal-frame
      :showModalFlag="showCreateRequestModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />設定代行依頼状の作成</h1>
      </div>
      <div class="modal-body">
        <horus-search-organization-column
          :searchResultOrganization="searchResultOrganization"
          :existOrganizations="deputizeOrganizations"
          :sentOrganizations="sentOrganizations"
          :receiveOrganizations="receiveOrganizations"
          :searchType="'deputize'"
          v-on:searchFinishedEvent="searchFinished"
          v-on:searchFailedEvent="searchFailed"
          />
        <div class="form-datas" v-if="readyToCreate">
          <horus-attribute-in-form
            :labelText="'担当者へのメッセージ'"
            :necessary="false"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <textarea class="message-content" v-model="messageContent"></textarea>
          </horus-attribute-in-form>
          <div class="notify_for_admit">
            <h1>設定代行施設登録における確認事項</h1>
            <ul>
              <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
              <li>
                設定代行施設への委任する機能は下記の通りであること
                <ol>
                  <li>本システムの利用者として、利用申込の申請並びに利用停止申請及び利用者登録の抹消申請を行うこと。</li>
                  <li>利用者本人の利用者情報の閲覧及び編集を行うこと。</li>
                  <li>家族申請、他の利用者からの家族申請の承認及び家族の削除申請を行うこと。</li>
                  <li>施設の設定及び停止を行うこと。</li>
                  <li>利用者本人が利用者情報の閲覧及び編集をできるようにすること。</li>
                  <li>本書面の委任事項を他の施設に再委任すること（但し、再委任先への委任状の作成は利用者本人による）。</li>
                </ol>
              </li>
              <li>依頼先施設に提出する委任状の委任事項について同意していること</li>
              <li>依頼先施設に委任状を提出していること</li>
              <li>ナラティブブックであなたの情報を共有してほしい家族や施設を追加したいときは、設定代行施設に連絡すること</li>
            </ul>
          </div>
          <div class="admit-agreement" @click="toggleAdmit">
            <horus-custom-check-box
              :active="admitAgreement"
              :enabled="true"
              :type="'color'"
              />
            上記確認事項を確認しました
          </div>
        </div><!-- org search result end -->
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendRequest">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !requestReady }" @click="submitSendRequest">依頼状の送信</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusSearchOrganizationColumn from '@/components/common/HorusSearchOrganizationColumn.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusCreateDeputizeRequestForOrganizationModal',

  components: {
    HorusModalFrame,
    HorusSearchOrganizationColumn,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    timelineOwner: Object,
    showCreateRequestModal: Boolean,
    deputizeOrganizations: Array,
    sentRequests: Array,
    receiveRequests: Array
  },

  data () {
    return {
      searchResultOrganization: null,
      readyToCreate: false,
      messageContent: '',
      admitAgreement: false,
      exculdeOrganizations: []
    }
  },

  computed: {
    requestReady () {
      if (this.searchResultOrganization && this.readyToCreate && this.admitAgreement) {
        return true
      } else {
        return false
      }
    },

    sentOrganizations () {
      var sentOrgs = []
      this.sentRequests.forEach(req => {
        sentOrgs.push(req.organization)
      })
      return sentOrgs
    },

    receiveOrganizations () {
      var recvOrgs = []
      this.receiveRequests.forEach(req => {
        recvOrgs.push(req.organization)
      })
      return recvOrgs
    }
  },

  methods: {
    searchFinished (organization, avaliable) {
      this.searchResultOrganization = organization
      if (avaliable) {
        this.readyToCreate = true
      } else {
        this.readyToCreate = false
      }
    },

    searchFailed () {
      this.readyToCreate = false
      this.searchResultOrganization = null
      this.admitAgreement = false
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    resetParam () {
      this.searchResultOrganization = null
      this.readyToCreate = false
      this.messageContent = ''
      this.admitAgreement = false
    },

    cancelSendRequest () {
      this.resetParam()
      this.$emit('cancelEvent')
    },

    submitSendRequest () {
      if (this.requestReady) {
        this.$emit('submitSendRequestEvent', this.searchResultOrganization, this.messageContent)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-deputize-request-for-organization-modal {
  .modal-body {
    padding: 10px;
    height: calc(100% - 52px);
    overflow: auto;
    .request-message {
      margin-bottom: 25px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
    }
    .notify_for_admit {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #666;
      }
    }
    input[type='text'] {
      margin: 0 5px;
      font-size: 16px;
    }
    textarea {
      width: calc(100% - 40px);
      font-size: 16px;
      border: 1px solid #aaa;
    }
    .admit-agreement {
      cursor: pointer;
      margin: 10px;
      font-weight: bold;
    }
    .submit-controll {
      margin: 10px;
      text-align: center;
      button {
        cursor: pointer;
        padding: 2px 20px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        &.disable {
          cursor: normal;
          background-color: #aaa;
        }
        &.cancel-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>
