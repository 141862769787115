<template>
  <div class="horus-manage-reporter-organization-request-detail-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/org_member.png" class="title-icon" />施設新規登録申請の詳細</h1>
      </div>
      <div class="modal-body">
        <div class="organization-request-column" v-if="reporterOrganizationRequest">
          <h4>入力された施設情報</h4>
          <div class="list-data">
            <div class="list-data-line">
              <div class="labels"><label>担当者名</label></div>
              <div class="content">{{ reporterOrganizationRequest.operator_name }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>施設名称</label></div>
              <div class="content">{{ reporterOrganizationRequest.name }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>ヨミガナ</label></div>
              <div class="content">{{ reporterOrganizationRequest.name_kana }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>略称</label></div>
              <div class="content">{{ reporterOrganizationRequest.short_name }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>郵便番号</label></div>
              <div class="content">{{ reporterOrganizationRequest.zip_code }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>住所</label></div>
              <div class="content">{{ fullAddress }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>電話番号</label></div>
              <div class="content">{{ reporterOrganizationRequest.tel }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>メールアドレス</label></div>
              <div class="content">{{ reporterOrganizationRequest.mail }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>管理者登録用メールアドレス</label></div>
              <div class="content">{{ reporterOrganizationRequest.first_manager_mail }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>作成日</label></div>
              <div class="content">{{ dateString(reporterOrganizationRequest.submit_at) }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>申請日</label></div>
              <div class="content">{{ dateString(reporterOrganizationRequest.applicated_at) }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>処理日</label></div>
              <div class="content">{{ dateString(reporterOrganizationRequest.processed_at) }}</div>
            </div>
          </div>
        </div>
        <div class="request-controll-column">
          <div class="controll-buttons">
            <button class="admit-btn" :class="{enable: processable}" @click="admit">承認</button>
            <button class="reject-btn" :class="{enable: processable}" @click="reject">拒否</button>
            <button class="cancel-btn" @click="closeModal">保留</button>
            <button class="destroy-btn" @click="destroy">削除</button>
          </div>
          <div class="reject-message-column">
            <span class="title">拒否理由</span>
            <textarea rows="5" v-model="causeOfReject"></textarea>
          </div>
        </div>
      </div>
    </horus-modal-frame>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  ReporterOrganizationRequest
} from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as USER_ADDRESS from '@/assets/constants/addressData.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageReporterOrganizationRequestDetailModal',

  components: {
    HorusModalFrame,
    HorusConfirmDialog
  },

  props: {
    showModal: Boolean,
    reporterOrganizationRequest: Object
  },

  data () {
    return {
      causeOfReject: '',
      addressData: USER_ADDRESS
    }
  },

  watch: {
    reporterOrganizationRequest: { handler: 'initData', immediate: true }
  },

  computed: {
    fullAddress () {
      if (this.reporterOrganizationRequest) {
        if (this.reporterOrganizationRequest.city && this.reporterOrganizationRequest.address) {
          var prefectureSet = this.addressData.PREFECTURE_CODE_JP.find(set => set[0] === this.reporterOrganizationRequest.prefecture)
          console.log(prefectureSet)
          if (prefectureSet) {
            return prefectureSet[1] + this.reporterOrganizationRequest.city + this.reporterOrganizationRequest.address
          }
          return this.reporterOrganizationRequest.city + this.reporterOrganizationRequest.address
        }
      }
      return ''
    },

    processable () {
      if (this.reporterOrganizationRequest && this.reporterOrganizationRequest.applicated_at && !this.reporterOrganizationRequest.processed_at) {
        return true
      }
      return false
    }
  },

  methods: {
    initData () {
      if (this.reporterOrganizationRequest && this.reporterOrganizationRequest.cause_of_reject) {
        this.causeOfReject = this.reporterOrganizationRequest.cause_of_reject
      } else {
        this.causeOfReject = ''
      }
    },

    dateString (targetDate) {
      var date = new Date(targetDate)
      if (targetDate && date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      } else {
        return '------'
      }
    },

    admit () {
      if (this.processable) {
        this.showConfirmDialog(
          '報告施設作成申請の承認',
          '報告施設作成申請を承認します。承認した場合、報告施設は表示された情報で作成され、報告施設のメールアドレスに承認結果を送信します。\n同時に、管理者アカウント登録用メールアドレスに対して、報告施設管理者案内のメールが送信されます。\nこの処理は後戻りできません。\nよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeAdmit() }
        )
      } else {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      }
    },

    executeAdmit () {
      if (this.processable) {
        ReporterOrganizationRequest.confirm({ reporterOrganizationRequestID: this.reporterOrganizationRequest.id }).then(res => {
          this.showConfirmDialog(
            '報告施設作成申請の承認完了',
            '新規報告施設[' + res.reporter_organization.name + ']が作成されました。\n',
            DIALOG_TYPE.NOTIFY,
            () => { this.closeModal() }
          )
        })
      } else {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      }
    },

    reject () {
      if (!this.processable) {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      } else if (this.causeOfReject && this.causeOfReject.length > 0) {
        this.showConfirmDialog(
          '報告施設作成申請の拒否',
          '報告施設作成申請を拒否します。拒否した場合、報告施設のメールアドレスに拒否の結果と理由を送信します。\nこの処理は後戻りできません。\nよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeReject() }
        )
      } else {
        this.showNotifyDialog('拒否理由がありません。', '報告施設作成申請の拒否を行うには拒否理由の入力が必須です。')
      }
    },

    executeReject () {
      if (this.processable) {
        ReporterOrganizationRequest.reject({ reporterOrganizationRequestID: this.reporterOrganizationRequest.id, rejectMessage: this.causeOfReject }).then(() => {
          this.showConfirmDialog(
            '報告施設作成申請の拒否完了',
            '報告施設作成申請の拒否が完了しました。',
            DIALOG_TYPE.NOTIFY,
            () => { this.closeModal() }
          )
        })
      } else {
        this.showNotifyDialog('この申請は操作できる状態でありません。', '')
      }
    },

    destroy () {
      this.showConfirmDialog(
        '報告施設作成申請の削除',
        '報告施設作成申請を削除します。削除した場合、申請処理は継続不可能となります。。\nこの処理は後戻りできません。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroy() }
      )
    },

    executeDestroy () {
      ReporterOrganizationRequest.destroy({ reporterOrganizationRequestID: this.reporterOrganizationRequest.id }).then(() => {
        this.showConfirmDialog(
          '報告施設作成申請の削除完了',
          '報告施設作成申請の削除が完了しました。',
          DIALOG_TYPE.NOTIFY,
          () => { this.closeModal() }
        )
      }).catch(error => {
        console.log(error.response)
      })
    },

    closeModal () {
      this.$emit('modalCloseEvent')
    }

  }
}
</script>

<style lang="scss" scoped>
.horus-manage-reporter-organization-request-detail-modal {
  .modal-body {
    padding: 10px;
    .organization-request-column {
      h4 {
        margin: 5px 0;
        font-size: 1em;
        font-weight: bold;
      }
      .list-data {
        margin: 10px 0;
        border: 1px solid #aaa;
        background-color: #fff;
        font-size: 16px;
        .list-data-line {
          display: flex;
          padding: 5px;
          border-bottom: 1px solid #aaa;
          .labels {
            margin-right: 10px;
            width: 150px;
            text-align: right;
            font-size: 14px;
            font-weight: bold;
          }
          .content {
            padding: 0;
            background-color: inherit;
          }
          img {
            margin-left: 20px;
            height: 20px;
          }
        }
      }
    }
    .request-controll-column {
      .controll-buttons {
        display: flex;
        justify-content: space-between;
        button {
          padding: 3px;
          width: 80px;
          border-radius: 3px;
          font-weight: bold;
          color: #fff;
        }
        .admit-btn {
          border-color: #93b881;
          background-color: #93b881;
          &.enable {
            border-color: #5cb85b;
            background-color: #5cb85b;
          }
        }
        .reject-btn {
          border-color: #c97586;
          background-color: #c97586;
          &.enable {
            border-color: #c43d53;
            background-color: #c43d53;
          }
        }
        .cancel-btn {
          border-color: #f08300;
          background-color: #f08300;
        }
        .destroy-btn {
          border-color: #55295b;
          background-color: #55295b;
        }
      }
      .reject-message-column {
        margin-top: 10px;
        textarea {
          width: calc(100% - 10px);
          resize: none;
        }
      }
    }
  }
}
</style>
