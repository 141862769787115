<template>
  <div class="horus-revoke-process-modal">
    <horus-modal-frame
      :showModalFlag="showRevokeProcessModal">
      <div class="modal-header">
        <h1><img src="/static/images/bucket_icon.png" class="title-icon" />ナラティブブック退会処理</h1>
      </div>
      <div class="modal-body">
        <div class="notify-box">
          <h4>
            <span>退会の前にしなければならない事</span>
          </h4>
          <ol>
            <li><strong>未処理のかかりつけ依頼、設定代行依頼、家族の登録依頼が存在する場合、退会処理を続行できません。</strong>必ず、それぞれのページで、未処理のかかりつけ依頼、設定代行依頼、家族の登録依頼を削除してから、退会のプロセスに進んでください</li>
            <li><strong>あなたがいずれかの施設の管理者をしているならば、その施設で、他に職員管理者、かかりつけ管理者、設定代行管理者が存在しない場合は退会処理を続行できません。</strong>その施設の職員管理者に自分以外の職員管理者、かかりつけ管理者、設定代行管理者を任命してもらってから、退会のプロセスに進んでください</li>
            <li><strong>あなたがいずれかの施設の設定代行者をしているならば、その施設で、他に設定代行者が存在しない場合は退会処理を続行できません。</strong>その施設の職員管理者に依頼して、他の設定代行者を任命してもらってから、退会のプロセスに進んでください</li>
          </ol>
        </div>
        <div class="revoke-notify" v-if="!readyToRevoke">
          <h4>
            <span>退会処理を続行できません。以下の事項を処理してから行ってください</span>
          </h4>
          <ul>
            <li v-for="(message, index) in revokeMessages" :key="'revekeNotify' + index">{{ message }}</li>
          </ul>
          <div class="controll"><button type="button" class="back-btn" @click="cancelRevoke">戻る</button></div>
        </div>
        <div class="revoke-inputs" v-if="readyToRevoke">
          <div class="notify-box">
            <h4>
              <span>退会する前に下記の注意事項を良くお読みください</span>
            </h4>
            <ol>
              <li>ナラティブブックを退会すると今まで利用していたサービスがすべて利用できなくなり、<strong>アカウント情報とデータが失われます。</strong></li>
              <li>家族設定やかかりつけ設定などにより情報を共有したことのある他の利用者(以下、「共有したことのある利用者」と呼びます)へ投稿したレポートデータや特記事項等(以下、「投稿したデータ」と呼びます)に関しては<strong>削除されません。</strong></li>
              <li>共有したことのある利用者へ投稿したデータの削除を希望する場合は、<strong>退会処理を行う前に、</strong>共有したことのある利用者のナラティブブックにアクセスし、ご自身が投稿したデータを削除してください。 (家族設定やかかりつけ設定を解除していると、共有したことのある利用者のナラティブブックにアクセス出来ませんので、家族設定やかかりつけ設定を再度行う必要があります。)</li>
              <li>共有したことのある利用者へ投稿したデータには、現在設定のポートレートの情報が含まれています。ポートレートの情報を削除したい場合は、<strong>退会処理を行う前に、</strong>プロフィール画面よりポートレートの変更をしてください。</li>
              <li>退会後は、共有したことのある利用者へ投稿したデータを<strong>削除することはできません。</strong></li>
            </ol>
          </div>
          <div class="admit-agreement" @click="toggleAdmit">
            <horus-custom-check-box
              :active="acceptAgreement"
              :enabled="true"
              :type="'color'"
              />
            <div class="check-label">はい、上記の注意事項を理解しました。<br/>その上で退会し、ナラティブブックのアカウント（NBID）を削除します。</div>
          </div>
          <div class="notify-caption">「退会する」ボタンを押し、ダイアログで「実行」するとアカウントの削除を開始します。この操作は取り消せません。</div>
          <div class="signin_inputs"><label>NBID</label><input type="text" v-model="inputLoginID"></div>
          <div class="signin_inputs"><label>パスワード</label><input :type="passWordInputType" v-model="inputPassword"></div>
          <div class="signin_pass_toggle" @click="toggleShowPassword">パスワード表示
            <horus-custom-check-box
              :active="showPassword"
              :enabled="true"
              :type="'color'"
              />
          </div>
          <div class="submit-controll">
            <button type="button" class="execute-btn" :class="{disable: !inputValid}" @click="executeRevoke">退会実行</button><br/>
            <button type="button" class="cancel-btn" @click="cancelRevoke">退会を取りやめる</button>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  User
} from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusRevokeProcessModal',

  components: {
    HorusCustomCheckBox,
    HorusModalFrame
  },

  props: {
    showRevokeProcessModal: Boolean,
    checkRevokeProcess: Boolean,
    timelineOwner: Object
  },

  data () {
    return {
      revokeMessages: [],
      readyToRevoke: false,
      acceptAgreement: false,
      showPassword: false,
      inputLoginID: '',
      inputPassword: ''
    }
  },

  computed: {
    inputValid () {
      if (this.acceptAgreement && this.inputLoginID.length > 0 && this.inputPassword.length > 0) {
        return true
      } else {
        return false
      }
    },

    passWordInputType () {
      if (this.showPassword) {
        return 'text'
      } else {
        return 'password'
      }
    }
  },

  watch: {
    checkRevokeProcess: { handler: 'prepareRevoke', immediate: true },
    timelineOwner: { handler: 'prepareRevoke', immediate: true }
  },

  methods: {
    prepareRevoke () {
      if (!this.checkRevokeProcess && this.timelineOwner) {
        this.revokeMessages = []
        this.readyToRevoke = false
        User.prepareRevoke({ targetUserID: this.timelineOwner.id }).then(res => {
          this.revokeMessages = res.messages
          if (this.revokeMessages.length <= 0) {
            this.readyToRevoke = true
          }
          this.$emit('checkRevokeEvent')
        }).catch(error => {
          console.error(error.response)
          this.$emit('serverErrorEvent')
        })
      }
    },

    toggleAdmit () {
      this.acceptAgreement = !this.acceptAgreement
    },

    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },

    executeRevoke () {
      if (this.readyToRevoke && this.inputValid) {
        var formData = new FormData()
        formData.append('login_id', this.inputLoginID)
        formData.append('password', this.inputPassword)
        formData.append('admit_quit', this.acceptAgreement)
        this.$emit('executeQuitEvent', formData)
      }
    },

    cancelRevoke () {
      this.$emit('cancelRevokeEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-revoke-process-modal {
  .modal-body {
    padding: 10px 20px;
    h4 {
      margin: 10px 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #aaa;
      color: #6c272d;
      font-size: 18px;
    }
    .notify-box {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
    }
    .revoke-notify {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fbfaf5;
      color: #a94442;
      .controll {
        text-align: center;
        .back-btn {
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
    .revoke-inputs {
      .admit-agreement {
        margin: 0;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
      .notify-caption {
        margin-bottom: 10px;
        color: #f00;
        font-size: 11px;
        text-align: center;
      }
      .signin_inputs {
        text-align: center;
        margin:  3px;
        label {
          display: inline-block;
          width: 100px;
          margin-right: 10px;
          text-align: right;
          font-size: 14px;
        }
        input {
          font-size: 16px;
        }
      }
      .signin_pass_toggle {
        text-align: center;
      }
      .submit-controll {
        margin: 10px 0;
        text-align: center;
        button {
          margin: 5px 0;
          padding: 3px 10px;
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          &.execute-btn {
            border-color: #a94442;
            background-color: #a94442;
            &.disable {
              border-color: #ee836f;
              background-color: #ee836f;
              color: #ddd;
            }
          }
          &.cancel-btn {
            background-color: #fff;
            color: #666;
          }
        }
      }
    }
  }
}
</style>
