<template>
  <div class="horus-date-picker" v-show="showDatePicker">
    <div class="backdrop" @click="closeControll"></div>
    <div class="controll" :class="{withTime: timeSelection}">
      <div class="select-date-controll">
        <div class="date-selection-column">
          <div class="title-bar">
            <select class="year-select" v-model="currentYear">
              <option
                v-for="calenderYear in calenderYears"
                :key="'calenderYearSelect' + calenderYear[0]"
                :value="calenderYear[0]">
                {{ calenderYear[0] + '年 (' + calenderYear[1] +'年)' }}
              </option>
            </select>
            <select class="month-select" v-model="currentMonth">
              <option v-for="month in calenderMonths" :key="'calnderMonthSelect' + month" :value="month">{{ month }}月</option>
            </select>
          </div>
          <div class="wday-bar">
            <div class="wday">日</div>
            <div class="wday">月</div>
            <div class="wday">火</div>
            <div class="wday">水</div>
            <div class="wday">木</div>
            <div class="wday">金</div>
            <div class="wday">土</div>
          </div>
          <div class="date-column">
            <div class="blank-panel" v-for="blank in prevBlanks" :key="blank">&nbsp;</div>
            <div class="date-panel" v-for="day in dateArrays" :key="day" :class="{ selected: isSelectedDate(day) }" @click="pickUpDate(day)">{{ day }}</div>
            <div class="blank-panel" v-for="blank in postBlanks" :key="blank">&nbsp;</div>
          </div>
        </div>
        <div class="time-selection-column" v-if="timeSelection">
          <div class="title-bar">時</div>
          <div class="hour-column">
            <div class="selectable-panel" v-for="(hour, index) in hours" :key="'hour'+ index" @click="selectHour(index)" :class="{selected: hour}">
              {{ ('00' + index).slice(-2) }}
            </div>
          </div>
          <div class="title-bar">分</div>
          <div class="minute-column">
            <div class="selectable-panel" v-for="(minute, index) in minutes" :key="'minute'+ index" @click="selectMinute(index)" :class="{selected: minute}">
              {{ ('00' + (index * 10)).slice(-2) }}
            </div>
          </div>
        </div>
      </div><!-- select-date-controll -->
      <div class="selected-date-display" v-if="timeSelection">
        <div class="selected-datetime">選択日時 : {{ selectedDateTime }}</div>
        <div class="controlls">
          <button type="button" class="cancel" @click="cancelAndClose">キャンセル</button>
          <button type="button" class="commit" @click="commitAndClose">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusDatePicker',

  props: {
    showDatePicker: Boolean,
    defaultDate: Date,
    timeSelection: Boolean
  },

  data () {
    return {
      calenderYears: [],
      calenderMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      currentYear: 1900,
      currentMonth: 1,
      currentDate: 1,
      hours: new Array(24),
      minutes: new Array(6),
      selectedDate: new Date()
    }
  },

  created () {
    this.initCalenderYears()
  },

  watch: {
    defaultDate: { handler: 'initCalender', immediate: true }
  },

  computed: {
    prevBlanks () {
      const prevCount = this.firstDayWday(this.currentYear, this.currentMonth)
      if (prevCount > 0) {
        return new Array(prevCount)
      } else {
        return []
      }
    },

    postBlanks () {
      const postCount = this.leftCountOfChart(this.currentYear, this.currentMonth)
      if (postCount > 0) {
        return new Array(postCount)
      } else {
        return []
      }
    },

    dateArrays () {
      var arr = []
      for (var i = 0, l = this.daysOfMonth(this.currentYear, this.currentMonth); i < l; i++) {
        arr.push(i + 1)
      }
      return arr
    },

    selectedDateTime () {
      return moment(this.selectedDate).format('YYYY/MM/DD HH:mm')
    }
  },

  methods: {
    initCalender () {
      if (this.defaultDate) {
        this.currentYear = this.defaultDate.getFullYear()
        this.currentMonth = this.defaultDate.getMonth() + 1
        this.currentDate = this.defaultDate.getDate()
        this.hours = new Array(24)
        this.hours.fill(false)
        this.hours[this.selectedDate.getHours()] = true
        this.minutes = new Array(6)
        this.minutes.fill(false)
        this.minutes[parseInt(this.selectedDate.getMinutes() / 10)] = true
      }
    },

    initCalenderYears () {
      this.calenderYears = []
      var nowYear = new Date().getFullYear()
      for (let year = 1900; year <= nowYear; year++) {
        if (year < 1912) {
          this.calenderYears.push([year, '明治' + (year - 1867)])
        } else if (year === 1912) {
          this.calenderYears.push([year, '明治' + (year - 1867) + '/大正1'])
        } else if (year > 1912 && year < 1926) {
          this.calenderYears.push([year, '大正' + (year - 1911)])
        } else if (year === 1926) {
          this.calenderYears.push([year, '大正' + (year - 1911) + '/昭和1'])
        } else if (year > 1926 && year < 1989) {
          this.calenderYears.push([year, '昭和' + (year - 1925)])
        } else if (year === 1989) {
          this.calenderYears.push([year, '昭和' + (year - 1925) + '/平成1'])
        } else if (year > 1989 && year < 2019) {
          this.calenderYears.push([year, '平成' + (year - 1988)])
        } else if (year === 2019) {
          this.calenderYears.push([year, '平成' + (year - 1988) + '/令和1'])
        } else if (year > 2019) {
          this.calenderYears.push([year, '令和' + (year - 2018)])
        } else {
          this.calenderYears.push([year, '西暦' + year])
        }
      }
    },

    // 引数のmonthは普通の月の数値。Date.getMonthで返ってくる月のインデックス値(数値の月-1の値)ではない
    daysOfMonth (year, month) {
      // 指定した月の次の月の0日目は, 指定した月の最終日が返ってくるのを利用
      return new Date(year, month, 0).getDate()
    },

    // 指定した月の一日の曜日を取得。日曜始まりの0インデックス
    firstDayWday (year, month) {
      return new Date(year, month - 1, 1).getDay()
    },

    leftCountOfChart (year, month) {
      const mod = ((this.firstDayWday(year, month) + this.daysOfMonth(year, month)) % 7)
      if (mod > 0) {
        return 7 - mod
      } else {
        return mod
      }
    },

    chartCount (year, month) {
      const left = this.firstDayWday(year, month) + this.daysOfMonth(year, month)
      const after = 7 - (left % 7)
      return left + after
    },

    isDefault (day) {
      if (
        this.currentYear === this.defaultDate.getFullYear() &&
        this.currentMonth === this.defaultDate.getMonth() + 1 &&
        day === this.defaultDate.getDate()
      ) {
        return true
      } else {
        return false
      }
    },

    isSelectedDate (day) {
      if (this.currentYear === this.selectedDate.getFullYear() && this.currentMonth === this.selectedDate.getMonth() + 1 && day === this.selectedDate.getDate()) {
        return true
      } else {
        return false
      }
    },

    pickUpDate (dayIndex) {
      if (!this.timeSelection) {
        this.$emit('datePickupEvent', new Date(this.currentYear, this.currentMonth - 1, dayIndex))
      } else {
        this.selectedDate = new Date(this.currentYear, this.currentMonth - 1, dayIndex, this.selectedDate.getHours(), this.selectedDate.getMinutes())
      }
    },

    selectHour (hourIndex) {
      const subjectDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), hourIndex, this.selectedDate.getMinutes())
      this.selectedDate = subjectDate
      this.hours.forEach((hour, index) => {
        if (index === hourIndex) {
          this.hours[index] = true
        } else {
          this.hours[index] = false
        }
      })
    },

    selectMinute (minuteIndex) {
      const subjectDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), this.selectedDate.getHours(), minuteIndex * 10)
      this.selectedDate = subjectDate
      this.minutes.forEach((minute, index) => {
        if (index === minuteIndex) {
          this.minutes[index] = true
        } else {
          this.minutes[index] = false
        }
      })
    },

    closeControll () {
      this.$emit('closeEvent')
      this.selectedDate = new Date()
    },

    cancelAndClose () {
      this.$emit('closeEvent')
      this.selectedDate = new Date()
    },

    commitAndClose () {
      this.$emit('datePickupEvent', this.selectedDate, () => {
        this.selectedDate = new Date()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-date-picker {
  position: absoluete;
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100vw;
    // height: 100vh;
    // height: 100dvh;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.2;
  }
  .controll {
    position: absolute;
    top: 130px;
    // left: calc((100vw - 310px) / 2);
    left: calc((100% - 310px) / 2);
    width: 300px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #aaa;
    &.withTime {
      width: 570px;
      left: calc((100% - 570px) / 2)
    }
    .select-date-controll {
      display: flex;
      .date-selection-column {
        width: 300px;
        .title-bar {
          display: flex;
          justify-content: space-between;
          padding: 5px;
          background-color: #999;
          color: #fff;
          font-weight: bold;
          select {
            padding: 0;
            height: 28px;
            font-size: 16px;
            background-color: #666;
            color: #fff;
            &.year-select {
              width: 200px;
            }
            &.month-select {
              width: 80px;
            }
          }
        }
        .wday-bar {
          display: flex;
          margin-top: 5px;
          justify-content: space-between;
          .wday {
            width: 40px;
            text-align: center;
          }
        }
        .date-column {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .blank-panel {
            margin-top: 3px;
            width: 40px;
            height: calc(40px - ((40px - 14pt) / 2) + 2);
            color: #322;
          }
          .date-panel {
            width: 38px;
            height: calc(40px - ((40px - 14pt) / 2));
            border: 1px solid #999;
            margin-top: 3px;
            padding-top: calc((40px - 14pt) / 2);
            font-size: 14pt;
            font-weight: bold;
            text-align: center;
            color: #999;
            cursor: pointer;
            &.selected {
              color: #c9171e;
              border-color: #c9171e;
            }
          }
        }
      }
      .time-selection-column {
        width: 250px;
        margin-left: 15px;
        font-size: 16px;
        .title-bar {
          height: 28px;
          padding: 5px 10px;
          text-align: center;
        }
        .hour-column {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-bottom: 5px;
        }
        .minute-column {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
      .selectable-panel {
        width: 38px;
        height: calc(40px - ((40px - 14pt) / 2));
        border: 1px solid #999;
        margin-top: 3px;
        padding-top: calc((40px - 14pt) / 2);
        font-size: 14pt;
        font-weight: bold;
        text-align: center;
        color: #999;
        cursor: pointer;
        &.selected {
          color: #c9171e;
          border-color: #c9171e;
        }
        &.disabled {
          cursor: auto;
          background-color: #ddd;
        }
      }
    }
  }
}
.selected-date-display {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #aaa;
  margin-top: 10px;
  padding: 5px 10px;
  .selected-datetime {
    font-size: 16px;
  }
  .controlls {
    button {
      display: inline-block;
      width: 100px;
      height: 26px;
      font-size: 14px;
      background-color: #eee;
      &.cancel {
        background-color: #fffffe;
        color: #666;
      }
      &.commit {
        background-color: #666;
        color: #fff;
      }
    }
  }
}
</style>
