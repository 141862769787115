<template>
  <div class="horus-private-permission-column" @click="togglePermission">
    <horus-custom-check-box
      :active="permitted"
      :enabled="true"
      :type="'color'"
      />
    <img v-bind:src="author.portrateSrc" class="portrate" />
    <span class="name">{{ author.name }}</span>
  </div>
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusPrivatePermissionColumn',

  components: {
    HorusCustomCheckBox
  },

  props: {
    author: Object,
    permitted: Boolean
  },

  methods: {
    togglePermission () {
      this.$emit('togglePermissionEvent', this.author)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-private-permission-column {
  margin: 10px 0;
  display: flex;
  align-items: center;
  .portrate {
    margin: 0 10px;
    width: 40px;
  }
  .name {
    font-weight: bold;
  }
}
</style>
