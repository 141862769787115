<template>
  <div class="horus-system-contact-mail-detail-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>お問い合わせ詳細</h1>
      </div>
      <div class="modal-body">
        <div class="labled-content" v-if="contact">
          <div class="date-inputs">
            <div class="label">名前(フリガナ)</div>
            <div class="content">{{ contact.name }}({{ contact.name_kana }})</div>
          </div>
          <div class="content-inputs">
            <div class="label">メールアドレス</div>
            <div class="content"><a :href="'malto:' + contact.mail_address">{{ contact.mail_address }}</a></div>
          </div>
          <div class="date-inputs">
            <div class="label">受付日時</div>
            <div class="content">{{ contactStr }}</div>
          </div>
          <div class="date-inputs">
            <div class="label">送信</div>
            <div class="content">{{ contact.contact_from }}</div>
          </div>
          <div class="date-inputs">
            <div class="label">内容</div>
            <div class="content" v-html="contentStr"></div>
          </div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="close-btn" @click="close">閉じる</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemContactMailDetailModal',

  components: {
    HorusModalFrame
  },

  props: {
    contact: Object,
    showModal: Boolean
  },

  data () {
    return {
    }
  },

  computed: {
    contactStr () {
      if (this.contact) {
        var date = new Date(this.contact.contact_at)
        if (date) {
          return moment(date).format('YYYY/MM/DD HH:mm:ss')
        }
      }
      return '-------'
    },

    contentStr () {
      if (this.contact && this.contact.content) {
        return this.contact.content.replace(/\n/gi, '<br />')
      }
      return ''
    }
  },

  methods: {
    close () {
      console.log('close')
      this.$emit('closeEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-contact-mail-detail-modal {
  .modal-body {
    padding: 10px 20px;
    .labled-content {
      .date-inputs {
        padding: 10px;
        display: flex;
        align-items: flex-start;
        .label {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          font-weight: bold;
        }
        .content {
          margin-right: 10px;
          padding: 3px;
          text-align: left;
        }
        img {
          height: 30px;
          margin-right: 10px;
        }
        button {
          border: 1px solid #666;
          background-color: #666;
          color: #fff;
          font-weight: bold;
        }
      }
      .content-inputs {
        padding: 10px;
        display: flex;
        .label {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          font-weight: bold;
        }
        textarea {
          width: calc(100% - 110px);
          height: 3em;
          font-size: 16px;
        }
      }
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.close-btn {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
</style>
