<template>
  <div class="horus-mono-photo-selector">
    <div class="selected-photo-thumbnail-block" v-if="photoObj">
      <span>変更後の写真</span><br/>
      <img class="selected-photo-thumbnail" :src="photoObj.thumbnail" />
    </div>
    <div class="select-photo-controll-block">
      <label :for="photoInputID" onclick="">
        <img src="/static/images/camera_icon.png" class="add-photo" @click="selectPhoto"/>
      </label>
      <button class="cancel-btn" v-if="photoObj" @click="cancelSelectPhoto">変更の取り消し</button>
    </div>
    <input type="file" multiple="multiple" accept="image/*" :id="photoInputID" :name="photoInputID" style="display: none; cursor: pointer;" :capture="inApp" ref="photoInput" @change="postPhotoChange" />
  </div>
</template>

<script>
import HorusUploadPhotoFixer from '@/mixin/HorusUploadPhotoFixer.js'

export default {
  mixins: [HorusUploadPhotoFixer],

  name: 'HorusMonoPhotoSelector',

  props: {
    photoObj: Object
  },

  data () {
    return {
      photoInputID: 'photoInputMono'
    }
  },

  computed: {
    inApp () {
      return null
      // if (this.$native.status) {
      //   return 'environment'
      // } else {
      //   return null
      // }
    }
  },

  created () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.photoInputID = 'photoInputMonoSelect' + Math.floor(Math.random() * 10000)
    },

    selectPhoto () {
      // this.$nextTick(() => {
      //   this.$refs.photoInput.click()
      // })
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    postPhotoChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        for (var i = 0; i < selectedFiles.length; i++) {
          this.addSelectedPhoto(selectedFiles[i])
        }
      }
      this.$nextTick(() => {
        this.$refs.photoInput.value = ''
      })
    },

    async addSelectedPhoto (file) {
      this.fixUploadPhoto(file, 300, (obj) => {
        this.$emit('photoSelectedEvent', obj)
      })
    },

    cancelSelectPhoto () {
      this.$emit('cancelSelectPhotoEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-mono-photo-selector {
  display: flex;
  height: 134px;
  .selected-photo-thumbnail-block {
    position: relative;
    width: 115px;
    height: 115px;
    margin: 0;
    margin-right: 10px;
    // border: 1px solid #aaa;
    .selected-photo-thumbnail {
      width: 115px;
      height: 115px;
      object-fit: cover;
      border: 1px solid #aaa;
      image-orientation: none;
    }
  }
  .select-photo-controll-block {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 380px) {
      height: 127px;
      justify-content: flex-end;
    }
    button {
      width: 120px;
      font-size: 14px;
      font-weight: bold;
      border: 1px solid #999;
      border-radius: 3px;
      &.cancel-btn {
        margin-top: 10px;
        background-color: #fff;
        color: #999;
      }
      &.change-btn {
        margin-top: 10px;
        background-color: #999;
        color: #fff;
      }
    }
  }
}
</style>
