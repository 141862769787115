<template>
  <div class="horus-deputize-sub-header">
    <div class="profile">
      <div class="main-portrait">
        <img :src="timelineOwnerPortrateSrc">
      </div>
      <div class="target-person-column">
        <h1>{{ timelineOwner.fullName }}さん</h1>{{ timelineOwnerProfile }}
      </div>
    </div>
    <div class="deputizing-label" @click="backToAgentPage">
      <div class="title">設定代行実行中</div>
      <div class="action-caption">クリックで設定代行に戻る</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusDeputizeSubHeader',

  props: {
    timelineOwner: Object,
    loggedInUser: Object,
    signinMember: Object
  },

  computed: {
    timelineOwnerPortrateSrc: function () {
      if (this.timelineOwner) {
        return this.timelineOwner.author.portrateSrc
      }
      return ''
    },

    timelineOwnerProfile: function () {
      if (this.timelineOwner) {
        return this.timelineOwner.birthDateJp + '生 (' + this.timelineOwner.age + '才)' + this.timelineOwner.sexTypeJp
      }
      return ''
    }
  },

  methods: {
    backToAgentPage () {
      if (this.signinMember) {
        this.$router.push('/organization_agent')
      } else {
        this.$router.push('/agent')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-deputize-sub-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 60px;
  border-bottom: 3px solid #f8b500;
  position: relative;
  background-color: #e8ece9;
  @include mediaQuery('phone') {
    flex-wrap: wrap;
    height: 76px; // temp
  }
  .profile {
    display: flex;
    min-width: calc(100% - 200px);
    padding: 10px;
    .main-portrait {
      height: 40px;
      width: 40px;
      margin-right: 10px;
      img {
        height: 40px;
        width: 40px;
      }
    }
    .target-person-column {
      height: 40px;
      vertical-align: bottom;
      font-weight: lighter;
      a {
        color: #333;
      }
      h1 {
        font-size: 1.2em;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 3px;
        line-height: 1em;
      }
    }
  }
  .deputizing-label {
    cursor: pointer;
    padding: 5px 10px 2px 10px;
    border: 1px solid #f8b500;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #f8b500;
    text-align: center;
    @include mediaQuery('phone') {
      width: 100%;
      height: 16px;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
    }
    .title {
      font-weight: bold;
      color: #fff;
    }
    .action-caption {
      font-size: 10px;
      font-weight: normal;
      color: #333;
    }
  }
}
</style>
