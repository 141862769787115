<template>
  <div class="horus-sent-relatoin-invitation-in-manage labeled_content_block">
    <div class="labeled_content_single_block" v-if="sentInvitation.login_id">
      <label>利用者ID</label>
      <div class="content">{{ sentInvitation.login_id }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="!sentInvitation.login_id">
      <label>氏名</label>
      <div class="content">{{ sentInvitation.name }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="!sentInvitation.login_id">
      <label>メール</label>
      <div class="content">{{ sentInvitation.mail }}</div>
    </div>
    <div class="labeled_content_single_block" v-if="!sentInvitation.login_id">
      <label>本人確認コード</label>
      <div class="content">{{ sentInvitation.short_token }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>メッセージ</label>
      <div class="content">{{ sentInvitation.message_content }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ sentInvitation.status }}</div>
    </div>
    <div class="content-controll-block">
      <img src="/static/images/send_mail_icon.png" @click="sendMail" v-if="!sentInvitation.used" />
      <router-link :to="{ name: 'PrintRelationInvitation', query: queryForPrint }" target="_blank">
        <img src="/static/images/print.png" />
      </router-link>
      <img src="/static/images/bucket_icon.png" @click="removeInvitation" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusSentRelationInvitationInManage',

  props: {
    sentInvitation: Object,
    organization: Object
  },

  computed: {
    queryForPrint () {
      var nbidFlag = false
      if (this.sentInvitation.login_id && this.sentInvitation.login_id.length > 0) {
        nbidFlag = true
      }
      return {
        organization_name: this.organization.name,
        target_user_name: this.sentInvitation.name,
        short_token: this.sentInvitation.short_token,
        nbid: nbidFlag
      }
    }
  },

  methods: {
    sendMail () {
      this.$emit('sendMailEvent', this.sentInvitation)
    },

    removeInvitation () {
      this.$emit('removeInvitationEvent', this.sentInvitation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
