<template>
  <div class="horus-create-no-signin-user-form-modal">
    <horus-modal-frame
      :showModalFlag="showCreateNoSigninUserFormModal">
      <div class="modal-header">
        <h1>ログイン情報を持たない新規利用者の作成</h1>
      </div>
      <div class="modal-body">
        <h4 class="new_account_heading">利用者情報</h4>
        <div class="form-list">
          <horus-attribute-in-form
            :labelText="'NBID'"
            :necessary="true"
            :validationState="validateNBID"
            :validationMessage="validateNBIDMessage"
            :attributeNotify="'６文字以上 半角英小文字、半角数字、一部の記号@._/-+() （使用できる記号は、@._/-+()の８種類のみです。）'">
            <input type="text" v-model="nbid" />
          </horus-attribute-in-form>
        </div>
        <h4 class="new_account_heading">基本情報</h4>
        <div class="form-list">
          <horus-user-basic-form-inputs ref="userBasicForm" />
        </div>
        <h4 class="new_account_heading">連絡先等</h4>
        <div class="form-list">
          <horus-user-address-form-inputs ref="userAddressForm" />
        </div>
        <div class="new_user_submit">
          <div class="new_user_submit_notify" v-show="!allValidated">
            全ての項目を正しく入力してください
          </div>
          <button type="button" class="new-user-cancel-btn" @click="cancelCreate">キャンセル</button>
          <button type="button" class="new-user-submit-btn" :class="{disable: !allValidated}" @click="submitCreate">新規利用者登録</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  User
} from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusUserBasicFormInputs from '@/components/profile/HorusUserBasicFormInputs.vue'
import HorusUserAddressFormInputs from '@/components/profile/HorusUserAddressFormInputs.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  name: 'HorusCreateNoSigninUserFormModal',

  props: {
    showCreateNoSigninUserFormModal: Boolean,
    modeAgent: Boolean
  },

  components: {
    HorusModalFrame,
    HorusUserBasicFormInputs,
    HorusUserAddressFormInputs,
    HorusAttributeInForm
  },

  data () {
    return {
      nbid: '',
      nbidState: ''
    }
  },

  watch: {
    showCreateNoSigninUserFormModal: { handler: 'prepareForm' },
    nbid: { handler: 'updateNBIDState' }
  },

  computed: {
    validateNBID () {
      if (this.nbidState === 'available') {
        return 'valid'
      } else if (this.nbidState === 'alreadyuse' || this.nbidState === 'invalid') {
        return 'invalid'
      } else {
        return 'disabled'
      }
    },

    validateNBIDMessage () {
      if (this.nbidState === 'alreadyuse') {
        return '入力されたNBIDは、すでに別のユーザーが利用しています。別のNBIDを設定してください。'
      } else if (this.nbidState === 'invalid') {
        return '入力されたNBIDは、形式が正しくありません。６文字以上で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    allValidated () {
      if (this.nbidState === 'available' &&
        this.$refs.userBasicForm.userBasic.isValid() &&
        this.$refs.userAddressForm.userAddress.isValid()
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    prepareForm () {
      if (!this.showCreateNoSigninUserFormModal) {
        this.nbid = ''
        this.$refs.userBasicForm.initObject()
        this.$refs.userAddressForm.initObject()
      }
    },

    updateNBIDState () {
      console.log('updateNBIDState')
      if (this.nbid.length <= 0) {
        this.nbidState = 'none'
      } else if (this.nbid.trim().match(/^([a-z0-9]|@|\.|_|\/|-|\+|\(|\)){6,120}$/g)) {
        // get valid
        User.nbidValidation({ nbid: this.nbid }).then(res => {
        // this.$store.dispatch('nbidValidation', { nbid: this.nbid }).then(res => {
          this.nbidState = res.result
        })
      } else {
        this.nbidState = 'invalid'
      }
    },

    cancelCreate () {
      var message = 'ログイン情報のないナラティブブック利用者の登録を中止します。\n入力された情報はクリアされますが、アップロードされた同意書は削除されません。\n中止してよろしいですか?'
      if (this.modeAgent) {
        message = 'ログイン情報のないナラティブブック利用者の登録を中止します。\n入力された情報はクリアされます。\n中止してよろしいですか?'
      }
      this.$emit('showConfirmDialogEvent',
        'ナラティブブック利用者登録中止',
        message,
        DIALOG_TYPE.YES_NO,
        () => { this.$emit('userCreationCancelEvent') }
      )
    },

    submitCreate () {
      if (this.allValidated) {
        this.$emit('showConfirmDialogEvent',
          'ナラティブブック利用者登録',
          '入力された情報で、ログイン情報のないナラティブブック利用者の登録を実行します。\nよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeSubmit() }
        )
      }
    },

    executeSubmit () {
      if (this.allValidated) {
        var userBasic = this.$refs.userBasicForm.userBasic
        var userAddress = this.$refs.userAddressForm.userAddress
        var portrate = this.$refs.userBasicForm.portrateObj
        var formData = new FormData()
        formData.append('user[name]', this.nbid)
        formData.append('user_basic[family_name]', userBasic.familyName)
        formData.append('user_basic[given_name]', userBasic.givenName)
        formData.append('user_basic[family_name_kana]]', userBasic.familyNameKana)
        formData.append('user_basic[given_name_kana]', userBasic.givenNameKana)
        formData.append('user_basic[sex_type]', userBasic.sexType)
        formData.append('user_basic[birth_date]', userBasic.birthDate)
        formData.append('user_basic[blood_type_abo]', userBasic.bloodTypeABO)
        formData.append('user_basic[blood_type_rh]', userBasic.bloodTypeRH)
        if (portrate) {
          formData.append('user_basic[portrate]', portrate.uploadFile, portrate.name)
        }
        formData.append('user_address[zip_code]', userAddress.zipCode)
        formData.append('user_address[prefecture]', userAddress.prefecture)
        formData.append('user_address[city]', userAddress.city)
        formData.append('user_address[address]', userAddress.address)
        formData.append('user_address[tel]', userAddress.tel)
        formData.append('user_address[cellphone]', userAddress.cellphone)
        formData.append('user_address[fax]', userAddress.fax)
        formData.append('user_address[mail]', userAddress.mail)
        formData.append('nb_rule_accept', this.admitAgreement)
        User.create({ formData: formData }).then(res => {
          console.log(res)
          this.$emit('userCreationFinishEvent', res)
        }).catch(error => {
          console.log(error.response)
          if (error.response.status === 400) {
            this.$emit('showNotifyDialgEvent', 'ナラティブブック利用者登録の失敗', 'ナラティブブック利用者登録に失敗しました。\n内容を再度確認してください。')
          } else {
            this.$emit('showNotifyDialgEvent', 'ナラティブブック利用者登録の失敗', 'ナラティブブック利用者登録に失敗しました。\nシステム側に問題がある可能性があります。\nしばらく断ってからもう一度お試しください。\n改善しない場合は、システム管理者に連絡をお願いいたします。')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-create-no-signin-user-form-modal {
  .modal-body {
    padding: 10px 20px;
    h2 {
      font-size: 1.1em;
      font-weight: normal;
    }
    .new_account_heading {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding-bottom: 5px;
      color: #6c272d;
      border-bottom: 1px solid #aaa;
      font-size: 18px;
      font-weight: normal;
    }
    .form-list {
      margin: 10px 0;
      padding: 10px 20px;
      border: 1px solid #ddd;
      background-color: #fff;
      @include mediaQuery('phone') {
        padding: 0 10px;
      }
      input {
        font-size: 16px;
      }
    }
    .new_user_submit {
      text-align: center;
      .new_user_submit_notify {
        padding: 10px;
        border: 1px solid #6c272d;
        background-color: #fff;
        color: #6c272d;
        text-align: center;
      }
      button {
        margin-top: 20px;
        padding: 3px 10px;
        border: 1px solid #f08300;
        border-radius: 5px;
        background-color: #f08300;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        &.disable {
          border-color: #aaa;
          background-color: #aaa;
          color: #ddd;
        }
        .new-user-cancel-btn {
          border-color: #666;
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>
