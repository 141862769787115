<template>
  <div class="horus-manual-qr-input-modal" v-show="showModal">
    <div class="horus-qr-input-backdrop"></div>
    <div class="horus-qr-input-modal-frame">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/qr_read_icon.png" />
          {{ title }}
        </div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'QRコードナンバー'"
          :necessary="true"
          :validationState="validateID"
          :validationMessage="validateIDMessage"
          :attributeNotify="''">
          <input type="text" v-model="staticID" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'読み取りカードの種別'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="'※ナラティブブック公式カードを読み取る場合は、カード種別の選択は不要です。'">
          <select v-model="selectedCategoryID">
            <option value="">カードの種別を選択</option>
            <option v-for="category in categories" v-bind:value="category.id" :key="'categorySelectForRead' + category.id">
              {{ category.name }}
            </option>
          </select>
        </horus-attribute-in-form>
        <div class="controll-column">
          <button class="submit-btn" @click="submit">入力</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

import {
  StaticIdentifiyCodeCategory
} from '@/api'

export default {
  name: 'HorusManualQrInputModal',

  components: {
    HorusAttributeInForm
  },

  props: {
    title: String,
    showModal: Boolean
  },

  data () {
    return {
      staticID: '',
      categories: [],
      selectedCategoryID: null,
      selectedCategory: null
    }
  },

  created () {
    this.loadCategories()
  },

  watch: {
    selectedCategoryID: { handler: 'updateSelectedCategory' }
  },

  computed: {
    validateID () {
      if (this.staticID.length <= 0) {
        return 'invalid'
      }
      if (this.selectedCategory && !this.selectedCategory.official) {
        return 'valid'
      }
      // ここまで到達できるのはcategory.officialかカテゴリなし
      if (this.staticID.match(/^[a-zA-Z]{2}\d{14}$/)) {
        // check sum
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateIDMessage () {
      if (this.staticID.length <= 0) {
        return 'QRコードナンバーは入力必須です'
      }
      if (this.selectedCategory && !this.selectedCategory.official) {
        return ''
      }
      // ここまで到達できるのはcategory.officialかカテゴリなし
      if (this.staticID.match(/^[a-zA-Z]{2}\d{14}$/)) {
        // check sum
        return ''
      } else {
        return '形式が正しくありません。'
      }
    }
  },

  methods: {
    loadCategories () {
      StaticIdentifiyCodeCategory.fetch().then(res => {
        this.categories = res.staticIdentifyCodeCategories
      })
    },

    updateSelectedCategory () {
      this.selectedCategory = this.categories.find(cate => cate.id === this.selectedCategoryID)
    },

    submit () {
      this.$emit('qrSubmitEvent', { decodedString: this.staticID, categoryID: this.selectedCategoryID })
    },

    decoded (decodedString) {
      console.log('QR DECODE COMPLETE:: ' + decodedString)
      this.$emit('qrSubmitEvent', { decodedString: decodedString, categoryID: this.selectedCategoryID })
    },

    closeModal () {
      this.$emit('qrInputCancelEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-manual-qr-input-modal {
  position: absoluete;
  top: 0;
  left: 0;
  .horus-qr-input-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .horus-qr-input-modal-frame {
    position: absolute;
    top: 30px;
    left: calc((100vw - (1000px - 40px)) / 2);
    width: calc(1000px - 40px);
    // height: calc(100vh - 100px);
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: 100vw;
      // height: calc(100vh - 80px);
      top: 0;
      left: 0;
    }
    .modal-header {
      display: flex;
      margin: 10px 5px;
      .title {
        width: 100%;
        text-align: center;
        img {
          width: 15px;
        }
      }
      .close-btn {
        text-align: right;
        height: 30px;
        img {
          cursor: pointer;
        }
        .jump-to-origin {
          margin-right: 20px;
          vertical-align: top;
        }
      }
    }
    .modal-body {
      width: calc(100% - 20px);
      height: 100%;
      padding: 10px;
      .controll-column {
        padding: 10px;
        text-align: right;
        .submit-btn {
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
