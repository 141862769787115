<template>
  <div class="horus-reply-records-modal-wrapper" v-if="replyRecordsModalVisible">
    <div class="horus-reply-records-modal-backdrop" @click="closeModal"></div>
    <div class="horus-reply-records-modal">
      <div class="record-thread-header">
        <div class="title">{{ titleMessage }}</div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="sub-header">{{ subTitleMessage }}</div>
      <div class="origin-record" :class="{ nudged: nudgedRecord }">
        <horus-record-in-modal
          :record="targetRecord"
          :timelineOwner="timelineOwner"
          v-on:jumpToOriginEvent="jumpToOrigin"
          v-on:durgEntryPhotoClickEvent="showDrugEntryPhoto"
          v-on:timeliePhotoClickEvent="showPhoto"
          v-on:timelineFileClickEvent="openFile" />
      </div>
      <div class="sub-header">返信</div>
      <div class="replies" v-if="targetRecord">
        <div class="no-reply" v-if="targetRecord && targetRecord.quateRecordsCount <= 0">この書き込みには返信がありません</div>
        <horus-record-in-modal
          v-for="replyRecord in replyRecords"
          :key="'replymodal' + replyRecord.id"
          :record="replyRecord"
          :timelineOwner="timelineOwner"
          v-on:jumpToOriginEvent="jumpToOrigin"
          v-on:durgEntryPhotoClickEvent="showDrugEntryPhoto"
          v-on:timeliePhotoClickEvent="showPhoto"
          v-on:timelineFileClickEvent="openFile" />
      </div>
    </div>
  </div>
</template>

<script>
import HorusRecordInModal from '@/components/timelineRecord/HorusRecordInModal.vue'

export default {
  name: 'HorusReplyRecordsModal',

  components: {
    HorusRecordInModal
  },

  props: {
    timelineOwner: Object,
    replyRecordsModalVisible: Boolean,
    nudgedRecord: Object,
    targetRecord: Object,
    replyRecords: Array
  },

  // updated () {
  //   if (!this.targetRecord && this.nudgedRecord) {
  //     this.targetRecord = this.nudgedRecord.record
  //   }
  // },

  computed: {
    titleMessage () {
      if (this.nudgedRecord) {
        return 'ナッジと返信'
      } else {
        return '返信一覧'
      }
    },

    subTitleMessage () {
      if (this.nudgedRecord) {
        return this.nudgedRecord.author.name + 'のナッジ'
      } else {
        return '元の書き込み'
      }
    }
  },

  methods: {
    closeModal () {
      this.$emit('replyRecordsModalCloseEvent')
    },

    jumpToOrigin (record) {
      this.$emit('jumpToOriginEvent', record)
      this.$emit('replyRecordsModalCloseEvent')
    },

    showPhoto (record, firstShowIndex) {
      this.$emit('showRecorPhotoEvent', record, firstShowIndex)
    },

    showDrugEntryPhoto (record, firstShowIndex) {
      this.$emit('showDrugEntryPhotoEvent', record, firstShowIndex)
    },

    openFile (file) {
      this.$emit('openRecordFileEvent', file)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-reply-records-modal-wrapper {
  position: absoluete;
}
.horus-reply-records-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.3;
}
.horus-reply-records-modal {
  position: absolute;
  width: 600px;
  max-height: calc(100vh - 60px);
  margin: 0;
  padding: 0;
  top: 30px;
  left: calc((100vw - 600px) / 2);
  overflow: auto;
  background-color: #eee;
  border: 1px solid #aaa;
  border-radius: 5px;
  @include mediaQuery('tablet') {
    width: calc(100vw - 40px);
    left: 20px;
  }
  @include mediaQuery('phone') {
    width: calc(100vw - 10px);
    left: 5px;
  }
  .record-thread-header {
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
    .title {
      color: #666;
      font-size: 18px;
    }
    .close-btn {
      width: 25px;
      margin-top: 10px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  .sub-header {
    margin: 5px 10px;
    color: #333;
  }
  .origin-record.nudged {
    border: 1px solid #752100;
    background-color: #fef4f4;
    .horus-record-in-modal {
      background-color: #fef4f4;
    }
  }
  .no-reply {
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #aaa;
  }
}
</style>
