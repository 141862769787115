<template>
  <div class="horus-related-user-in-manage-list labeled_content_block">
    <div class="labeled_content_single_block">
      <label>利用開始日</label>
      <div class="content">{{ userAssignedAt }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>施設利用者名</label>
      <div class="content">
        <img :src="user.author.portrateSrc" class="portrate" />
        {{ user.author.name }}
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>生年月日(年齢):性別</label>
      <div class="content">{{ user.author.birthDateJp }}({{ user.author.age }}):{{ user.author.sexTypeJp }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ user.author.address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ user.author.tel }}</div>
    </div>
    <div class="charged-members">
      <div class="label">担当者設定</div>
      <div class="members">
        <div v-for="visibility in chargedMembers" :key="'charged'+visibility.id">
          <div class="member-column" v-if="visibility.member && visibility.visible">
            <img :src="visibility.member.portrateSrc" class="member-portrate" />
            <div>{{ visibility.member.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-controll-block">
      <button class="configure-staff-visibility-btn" @click="configureStaffVisibility">担当者設定</button>
      <button class="remove-member-btn" @click="removeRelation">利用停止</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusRelatedUserInManageList',

  components: {},

  props: {
    user: Object,
    organization: Object,
    chargedMembers: Array
  },

  computed: {
    userAssignedAt () {
      var assignedAt = new Date(this.user.assigned_at)
      if (assignedAt) {
        return moment(assignedAt).format('YYYY/MM/DD HH:mm')
      }
      return ''
    }

    // chargedMembers () {
    //   return this.organization.usersChargedMembers.find(userSet => userSet.userID === this.user.user_id).visibilities
    // }
  },

  methods: {
    removeRelation () {
      this.$emit('removeRelationEvent', this.user)
    },

    configureStaffVisibility () {
      this.$emit('configureStaffVisibilityEvent', this.user)
    }
  }
}
// require('@/assets/css/labeledContent.scss')
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
.charged-members {
  width: 100%;
  .label {
    color: #000;
    font-size: 0.9em;
    font-weight: bold;
  }
  .members {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    .member-column {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
      img {
        height: 20px;
        width: 20px;
        margin-right: 3px;
      }
    }
  }
}
.configure-staff-visibility-btn {
  margin-right: 30px;
}
</style>
