<template>
  <div class="horus-reporter-member-profile-edit-modal">
    <horus-modal-frame
      :showModalFlag="showMemberProfileEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />プロフィール編集
        </h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'名前'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="'空欄の場合は個人設定の氏名が表示されます'">
          <input type="text" v-model="memberAttr.name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="'空欄の場合は個人設定のメールアドレスがセットされますが、利用者には表示されません'">
          <input type="text" v-model="memberAttr.mail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'電話'"
          :necessary="false"
          :validationState="validateTelNumber.state"
          :validationMessage="validateTelNumber.message"
          :attributeNotify="'ハイフン区切り半角数字: 000-000-0000 ※空欄の場合は表示されません'">
          <input type="text" v-model="memberAttr.tel" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button cype="button" class="submit-btn" :class="{enable: validateValues}" @click="executeSubmit">更&nbsp;新</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
// import HorusMonoPhotoSelector from '@/components/common/HorusMonoPhotoSelector.vue'

import * as USER_ADDRESS_DATA from '@/assets/constants/addressData.js'

export default {
  name: 'HorusReporterMemberProfileEditModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    // HorusMonoPhotoSelector
  },

  props: {
    member: Object,
    showMemberProfileEditModal: Boolean
  },

  data () {
    return {
      memberAttr: { name: '', mail: '', tel: '' }
    }
  },

  watch: {
    member: { handler: 'initObjects', immediate: true, deep: true }
  },

  computed: {
    validateTelNumber () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.memberAttr.tel) {
        valid = true
        state = 'valid'
      } else {
        var trimed = this.memberAttr.tel.trim()
        if (trimed.match(USER_ADDRESS_DATA.TEL_NUMBER_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateValues () {
      if (this.validateTelNumber.valid) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initObjects () {
      if (this.member) {
        this.memberAttr.name = this.member.name
        this.memberAttr.mail = this.member.mail
        this.memberAttr.tel = this.member.tel
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    executeSubmit () {
      if (this.validateValues) {
        var formData = new FormData()
        var tel = ''
        if (this.memberAttr.tel) {
          tel = this.memberAttr.tel
        }
        formData.append('reporter_organization_id', this.member.reporterOrganization.id)
        formData.append('id', this.member.id)
        formData.append('reporter_organization_member[name]', this.memberAttr.name)
        formData.append('reporter_organization_member[mail]', this.memberAttr.mail)
        formData.append('reporter_organization_member[tel]', tel)
        this.$emit('executeSubmitEvent', formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-reporter-member-profile-edit-modal {
  .modal-body {
    padding: 10px;
    input {
      font-size: 16px;
    }
    .portrate-setting-column {
      display: flex;
      flex-wrap: wrap;
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #aaa;
          color: #fff;
          &.enable {
            background-color: #666;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
