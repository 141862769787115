<template>
  <div class="horus-profile-view">
    <horus-deputize-sub-header
      :timelineOwner="timelineOwner"
      :loggedInUser="loggedInUser"
      :signinMember="signinMember"
      v-if="deputizing"
      />
    <horus-sub-header
      :timelineOwner="timelineOwner"
      :globalStyle="signinMember ? 'member' : ''"
      :nowInTimeline="false"
      v-on:updateTimelineEvent="moveToTimeline"
      v-if="subHeaderVisible" />
    <div class="profile-contents" :class="{deputizing: deputizing, 'sub-header-visible': subHeaderVisible }">
      <h1 class="centering-title">プロフィール</h1>
      <horus-profile-column
        :loggedInUser="loggedInUser"
        :signinMember="signinMember"
        :timelineOwner="timelineOwner"
        :deputizing="deputizing"
        :authenticationAcitvateInvitation="authenticationAcitvateInvitation"
        :userBasicSource="userBasicSource"
        :userAddressSource="userAddressSource"
        :archiveRequests="archiveRequests"
        :archiveLimited="archiveLimited"
        :appSigninTokens="appSigninTokens"
        :registedQrCodes="registedQrCodes"
        :zoomUser="zoomUser"
        :omronConnectAccountAvailable="omronConnectAccountAvailable"
        :narrativePhoneAccount="narrativePhoneAccount"
        v-on:newActivateEvent="newActivateInvitation"
        v-on:sendMailActivateEvent="sendMailActivateInvitation"
        v-on:destroyActivateEvent="destroyActivateInvitation"
        v-on:changeMailEvent="changeMail"
        v-on:changePasswordEvent="changePassword"
        v-on:editUserBasicEvent="editUserBasic"
        v-on:editUserAddressEvent="editUserAddress"
        v-on:quitNarrativeBookEvent="quitNarrativeBook"
        v-on:archiveRequestEvent="archiveRequest"
        v-on:removeAppSigninTokenEvent="removeAppSigninToken"
        v-on:registQrEvent="registQrCode"
        v-on:manualRegistQrEvent="manualRegistQrCode"
        v-on:deleteQrCodeEvent="deleteQrCode"
        v-on:revokeOmronConnectEvent="revokeOmronConnectAccount"
        v-on:addZoomUserEvent="addZoomUser"
        v-on:reconnectZoomUserEvent="reconnectZoomUser"
        v-on:revokeZoomAuthEvent="revokeZoomAuth"
        />
      <horus-footer />
    </div>
    <horus-authentication-activate-invitation-modal
      :showAcitivateInvitationModal="showAcitivateInvitationModal"
      :timelineOwner="timelineOwner"
      v-on:cancelSubmitEvent="cancelActivateInvitationSubmit"
      v-on:submitEvent="activateInvitationSubmit"
      />
    <!-- user meil edit modal -->
    <horus-user-mail-edit-modal
     :showUserMailEditModal="showUserMailEditModal"
     v-on:cancelSubmitEvent="cancelUserMailSubmit"
     v-on:submitEvent="submitUserMailChange"
     />
    <!-- user password edit modal -->
    <horus-password-update-modal
      :showPasswordUpdateModal="showPasswordUpdateModal"
      v-on:cancelSubmit="cancelPasswordUpdate"
      v-on:badPasswordFormatEvent="showPasswordFormatNotify"
      v-on:executeSubmit="executePasswordUpdate"/>
    <!-- user basic edit modal -->
    <horus-user-basic-edit-modal
      :showUserBasicEditModal="showUserBasicEditModal"
      :timelineOwner="timelineOwner"
      :userBasicSource="userBasicSource"
      v-on:cancelSubmitEvent="cancelUserBasicSubmit"
      v-on:executeSubmitEvent="submitUserBasicForm"
      />
    <!-- user address edit modal -->
    <horus-user-address-edit-modal
      :showUserAddressEditModal="showUserAddressEditModal"
      :timelineOwner="timelineOwner"
      :userAddressSource="userAddressSource"
      v-on:cancelSubmitEvent="cancelUserAddressSubmit"
      v-on:executeSubmitEvent="submitUserAddressForm"
      />
    <horus-revoke-process-modal
      :showRevokeProcessModal="showRevokeProcessModal"
      :checkRevokeProcess="checkRevokeProcess"
      :timelineOwner="timelineOwner"
      v-on:checkRevokeEvent="revokeChecked"
      v-on:cancelRevokeEvent="cancelRevoke"
      v-on:executeQuitEvent="revokeUser"
      v-on:serverErrorEvent="serverErrorOnRevoke"
      v-if="ownProfile"
      />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import HorusDeputizeSubHeader from '@/components/navigation/HorusDeputizeSubHeader.vue'
import HorusSubHeader from '@/components/navigation/HorusSubHeader.vue'

import HorusProfileColumn from '@/components/profile/HorusProfileColumn.vue'
import HorusAuthenticationActivateInvitationModal from '@/components/profile/HorusAuthenticationActivateInvitationModal.vue'
import HorusPasswordUpdateModal from '@/components/profile/HorusPasswordUpdateModal.vue'
import HorusUserBasicEditModal from '@/components/profile/HorusUserBasicEditModal.vue'
import HorusUserAddressEditModal from '@/components/profile/HorusUserAddressEditModal.vue'
import HorusUserMailEditModal from '@/components/profile/HorusUserMailEditModal.vue'
import HorusRevokeProcessModal from '@/components/profile/HorusRevokeProcessModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import HorusProfileControll from '@/mixin/HorusProfileControll.js'
// import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusProfileControll],

  name: 'HorusProfileView',

  components: {
    HorusDeputizeSubHeader,
    HorusSubHeader,
    HorusProfileColumn,
    HorusFooter,
    HorusAuthenticationActivateInvitationModal,
    HorusPasswordUpdateModal,
    HorusUserBasicEditModal,
    HorusUserAddressEditModal,
    HorusUserMailEditModal,
    HorusConfirmDialog,
    HorusRevokeProcessModal
  },

  methods: {
    backToTimeline () {
      this.$router.push('/maintimeline/' + this.timelineOwner.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-profile-view {
  height: calc(100% - 50px);
  overflow: auto;
  .profile-contents {
    height: calc(100% - 10px);
    padding: 10px 50px 0 50px;
    overflow: auto;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    &.deputizing {
      height: calc(100% - 73px);
    }
    &.with-sub-header {
      height: calc(100% - 70px);
    }
    .centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    .return-to-home {
      button {
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #999;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
</style>
