import axios from './AxiosInstance'

export default {
  fetch: ({reporterOrganizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_invitation_for_user/list',
        withCredentials: true,
        params: { reporter_organization_id: reporterOrganizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_invitation_for_users: res.data.reporter_organization_invitation_for_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'reporter_organization_invitation_for_user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({reporterOrganizationID, targetID}) => {
    return axios.csrfHeader('DELETE').then(
      header => {new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'reporter_organization_invitation_for_user',
            withCredentials: true,
            params: { reporter_organization_id: reporterOrganizationID, reporter_organization_invitation_for_user_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  sendMail: ({reporterOrganizationID, targetID}) => {
    var formData = new FormData()
    formData.append('reporter_organization_id', reporterOrganizationID)
    formData.append('reporter_organization_invitation_for_user_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_invitation_for_user/send_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchReceiveList: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_invitation_for_user/receive_list',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_invitation_for_users: res.data.reporter_organization_invitation_for_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchReceive: ({invitationToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_invitation_for_user',
        withCredentials: true,
        params: { token: invitationToken }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_id: res.data.reporter_organization_id, message_content: res.data.message_content, limit_at: res.data.limit_at })
        }
      }).catch(error => { reject(error) })
    })
  },

  validateToken: ({invitationToken, shortToken}) => {
    var formData = new FormData()
    formData.append('token', invitationToken)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_invitation_for_user/validate_token',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admitByID: ({targetUserID, targetID}) => {
    var formData = new FormData()
    formData.append('reporter_organization_invitation_for_user_id', targetID)
    formData.append('target_user_id', targetUserID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_invitation_for_user/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  // :token, :short_token, :login_id, :password
  admitByToken: ({token, loginID, password, shortToken}) => {
    var formData = new FormData()
    formData.append('token', token)
    formData.append('login_id', loginID)
    formData.append('password', password)
    formData.append('short_token', shortToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_invitation_for_user/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reject: ({targetUserID, targetID}) => {
    var formData = new FormData()
    formData.append('reporter_organization_invitation_for_user_id', targetID)
    formData.append('target_user_id', targetUserID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_invitation_for_user/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
