<template>
  <div class="horus-record-post-form-for-repoter" v-if="timelineOwner && author">
    <div class="post-form" v-show="postFormAvailable">
      <div class="alway_display">
        <div class="alway_display_left">
          <img :src="author.portrateSrc" />
        </div>
        <div class="alway_display_right">
          <textarea placeholder="ここに書いてください" class="form-control text-comment js_target_text-comment" rows="1" ref="textCommentField" @focus="activateForm" v-model="postRecord.textComment"></textarea>
          <div class="small_controll_icons" v-show="!postFormActive">
            <div class="left_buttons">
              <label :for="photoInputID">
                <img src="/static/images/camera_icon.png" class="add-photo-small" @click="addPhotoForPost" />
              </label>
              <label :for="fileInputID">
                <img src="/static/images/document_icon.png" class="add-file-small" @click="addFileForPost" />
              </label>
              <img src="/static/images/tag_icon.png" class="add-tag-small" @click="selectTagForPost" />
            </div>
          </div>
        </div>
      </div><!-- always display end -->
      <div class="post_controlls" v-show="postFormActive">
        <horus-multi-photo-selector
          ref="multiPhotoSelector"
          :photos="postRecord.photos"
          :photoInputID="photoInputID"
          v-on:newPhotoPreparedEvnt="addSelectedPhoto"
          v-on:photoRemoveEvent="removePhoto"
          />
        <horus-multi-file-selector
          ref="multiFileSelector"
          :files="postRecord.files"
          :fileInputID="fileInputID"
          :displaySize="''"
          v-on:newFilePreparedEvent="addSelectedFile"
          v-on:fileRemoveEvent="removeFile"
          />
        <div class="add_icons js_group_post_inputs">
          <div class="left_add_icons">
            <div class="icon-button" id="add_photo_block">
              <label :for="photoInputID">
                <img src="/static/images/camera_icon.png" class="add-photo" @click="addPhotoForPost"/>
              </label>
            </div>
            <div class="icon-button" id="add_file_block">
              <label :for="fileInputID">
                <img src="/static/images/document_icon.png" class="add-file" @click="addFileForPost"/>
              </label>
            </div>
            <div class="icon-button" id="hashtag_inputs_in_form">
              <img src="/static/images/tag_icon.png" class="add-tag" @click="selectTagForPost">
            </div>
          </div>
        </div><!-- add_icons end -->
        <div class="hash_tags">
          <div class="hash-tag"
            v-for="hashTag in selectedTags"
            :key="'postRecordSelected' + hashTag.id"
            @click="removeSelectedTag(hashTag)">
            <horus-hash-tag-display
              :hashTag="hashTag"
              :sizeType="'small'" />
            <img src="/static/images/x_dark_icon.png" class="close-icon" />
          </div>
          <div class="new-hash-tag"
            v-for="(newTag, index) in createTags"
            :key="'newCreatedTag' + index"
            @click="removeCreateTag(index)">
            <img src="/static/images/tag_icon_green.png" class="new-tag-icon" />
            <span v-html="modifiedTagName(newTag)"></span>
            <img src="/static/images/x_dark_icon.png" class="close-icon" />
          </div>
        </div><!-- selected hashtags end -->
        <div class="execute_controlls">
          <div class="left_execute_controlls">
            <button class="target-date-button" type="button" v-if="!targetDate.change" @click="setTargetDate">日時変更</button>
            <div class="target-date-display" v-else >
              <span class="date-display" @click="setTargetDate">{{ targetDateStr }}</span>
              <img src="/static/images/batsu_icon.png" @click="clearTargetDate" />
            </div>
          </div>
          <div class="right_execute_controlls">
            <div class="executable_buttons" v-if="postFormAvailable">
              <button type="button" class="record_post_clear" @click="clearPostForm">キャンセル</button>
              <button type="button" class="record_post_submit" @click="submitPostForm">投&nbsp;&nbsp;稿</button>
            </div>
            <div class="disable_buttons" v-else >
              <button type="button" class="execute_blocking">ロード中:送信出来ません</button>
            </div>
          </div>
        </div><!-- execute controll end -->
      </div><!-- post controll (not always display) end -->
    </div><!-- post form end -->
    <div class="post-form-not-ready" v-show="!postFormAvailable"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
  </div><!-- post controll end -->
</template>

<script>
import moment from 'moment'

import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'
import HorusMultiFileSelector from '@/components/common/HorusMultiFileSelector.vue'
// import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
// import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
// import HorusRecordBody from '@/components/common/HorusRecordBody.vue'

export default {
  name: 'HorusRecordPostFormForReporter',

  components: {
    HorusMultiPhotoSelector,
    HorusMultiFileSelector,
    // HorusInlinePhoto,
    // HorusInlineFile,
    HorusHashTagDisplay,
    // HorusRecordBody
  },

  props: {
    signinRepoter: Object,
    author: Object,
    timelineOwner: Object,
    targetDate: Object,
    selectedTags: Array,
    createTags: Array,
    pushTargets: Array,
    postFormAvailable: Boolean,
    postFormActive: Boolean
  },

  data () {
    return {
      photoInputID: 'photoInput',
      fileInputID: 'fileInput',
      postRecord: {},
      isShowButtonNative: false
    }
  },

  computed: {
    targetDateStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    targetTimeStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('HH:mm')
      }
      return ''
    }

  },

  created () {
    this.initForm()
    this.initPostRecord()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
  },

  updated () {
    if (this.$native.status === true) {
      this.isShowButtonNative = this.$native.status
    }
  },

  watch: {
    'postRecord.textComment': { handler: 'fixTextCommentArea' }
  },

  methods: {
    initForm () {
      this.photoInputID = 'photoInput' + Math.floor(Math.random() * 10000)
      this.fileInputID = 'fileInput' + Math.floor(Math.random() * 10000)
    },

    // init main data
    initPostRecord () {
      this.postRecord = {
        textComment: '',
        photos: [],
        files: []
      }
    },

    // visual controlls
    activateForm () {
      this.$emit('activatePostFormEvent')
    },

    fixTextCommentArea () {
      this.HorusHashTagDisplay = false
      this.$nextTick(() => {
        var textArea = this.$refs.textCommentField
        if (textArea) {
          // 一度高さをリセットしないと、scrollHeightが正しく取得出来ない。ただし、ちょっとちらついてしまう場合がある
          textArea.style.height = '26px'
          if (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = textArea.scrollHeight + 'px'
          }
        }
      })
    },

    modifiedTagName (newTag) {
      const splited = newTag.split('##')
      if (splited.length > 1) {
        return '<span class="hash_tag_type ' + splited[0] + '">' + splited[0] + '</span>' + splited[1]
      } else {
        return splited[0]
      }
    },

    // multi photo selector controlls
    addPhotoForPost () {
      this.activateForm()
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    addSelectedPhoto (photoObj) {
      this.postRecord.photos.push(photoObj)
    },

    removePhoto (index) {
      this.postRecord.photos.splice(index, 1)
    },

    // multi file selector controlls
    addFileForPost () {
      this.activateForm()
      if (this.$native.status) {
        this.$native.event('takeFileEvent', { targetID: this.fileInputID })
      }
    },

    addSelectedFile (fileObj) {
      this.postRecord.files.push(fileObj)
    },

    removeFile (index) {
      this.postRecord.files.splice(index, 1)
    },

    selectTagForPost () {
      this.activateForm()
      this.$emit('openTagSelectionModalEvent')
    },

    removeSelectedTag (tag) {
      this.$emit('removeSelectedTagEvent', tag)
    },

    removeCreateTag (index) {
      this.$emit('removeCreateTagEvent', index)
    },

    setTargetDate () {
      this.$emit('setTargetDateForPostEvent')
    },

    clearTargetDate () {
      this.$emit('clearTargetDateForPostEvent')
    },

    clearPostForm () {
      this.$emit('postformClearEvent', this.executeClearForm)
    },

    executeClearForm () {
      this.initPostRecord()
    },

    submitPostForm () {
      console.log('submitPostForm!!!')
      if (
        (!this.postRecord.textComment || this.postRecord.textComment.length === 0) &&
        this.postRecord.photos.length === 0 &&
        this.postRecord.files.length === 0
      ) {
        console.log('post contents is empty!')
        this.$emit('showNotifyEvent', '投稿内容のエラー', '投稿しようとする書き込みに、内容がありません。\n文章、写真、添付ファイルなどの何らかの内容を入力してください。')
      } else {
        // CREATE FORM DATA
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        formData.append('record[private]', this.postRecord.private)
        if (this.postRecord.surrogated) {
          formData.append('record[surrogate]', this.postRecord.surrogated)
        }
        if (this.quatedRecord) {
          formData.append('record[quated_record_id]', this.quatedRecord.id)
        }
        if (this.targetDate.change) {
          formData.append('record[nb_calendar_target_date]', moment(this.targetDate.date).format('YYYY/MM/DD'))
          formData.append('record[nb_calendar_target_time]', moment(this.targetDate.date).format('HH:mm'))
        }
        formData.append('record[text_comment]', this.postRecord.textComment)
        this.postRecord.photos.forEach(photo => {
          formData.append('record[photos][]', photo.uploadFile, photo.name)
        })
        this.postRecord.files.forEach(file => {
          formData.append('record[files][]', file.uploadFile, file.name)
        })
        this.selectedTags.forEach(tag => {
          formData.append('record[hashtags][]', tag.id)
        })
        this.createTags.forEach(newtag => {
          formData.append('record[new_hashtags][]', newtag)
        })
        // send form data
        this.$emit('submitPostFormEvent', formData, () => {
          this.initPostRecord()
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colorTable.scss';
@import '@/assets/css/mediaQuery.scss';

.horus-record-post-form-for-repoter {
  .post-form {
    border: 1px solid $nb-reporter-color;
    background-color: #f8fcf9;
  }
  .post-form-not-ready {
    border: 1px solid $nb-reporter-color;
    background-color: #f8fcf9;
  }
  .post-form {
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid $nb-reporter-color;
    background-color: #fff9f5;
  }
  .post-form-not-ready {
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid $nb-reporter-color;
    background-color: #fff9f5;
    color: #999;
    text-align: center;
    .progress {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }
}
.alway_display {
  display: flex;
  .alway_display_left {
    text-align: center;
    img {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 10px 10px 0 0;
    }
  }
  .alway_display_right {
    width: calc(100% - 70px);
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      border: 1px solid #aaa;
    }
    .small_controll_icons {
      // margin-top: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .left_buttons {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        img {
          margin: 0 3px 0 10px;
          width: 30px;
          height: 30px;
          @include mediaQuery('phone') {
            margin: 0 3px 0 3px;
          }
        }
      }
      .right_buttons {
        display: flex;
        justify-content: flex-end;
        img {
          margin: 0 3px 10px 3px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
.post_controlls {
  margin-top: 10px;
  padding: 0;
  padding-top: 5px;
  border-top: 1px solid #aaa;
  .add_icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    input {
      display: none;
    }
    .left_add_icons {
      display: flex;
      justify-content: flex-start;
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
          @include mediaQuery('phone') {
            margin: 0 3px 0 3px;
          }
      }
    }
    .right_add_icons {
      display: flex;
      justify-content: flex-end;
      .icon-button {
        cursor: pointer;
        margin: 0 0 10px 20px;
        @include mediaQuery('phone') {
          margin: 0 3px 0 3px;
        }
        img {
          height: 30px;
        }
      }
    }
  }
}
.hash_tags {
  display: flex;
  flex-wrap: wrap;
  .hash-tag {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    .close-icon {
      margin-left: -5px;
      width: 10px;
      vertical-align: middle;
    }
  }
  .new-hash-tag {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    .new-tag-icon {
      width: 10px;
    }
    .close-icon {
      width: 10px;
    }
  }
}
.execute_controlls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left_execute_controlls {
    margin-top: 10px;
    .target-date-button {
      padding: 3px 10px;
      border: 1px solid #999;
      background-color: #999;
      color: #fff;
      font-weight: lighter;
      font-size: 14px;
    }
    .target-date-display {
      span {
        display: inline-block;
        cursor: pointer;
        height: 19px;
        margin-right: 3px;
        padding: 5px 10px 3px 10px;;
        background-color: #fff;
        color: #333;
        border: 1px solid #999;
        font-size: 16px;
      }
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
  .right_execute_controlls {
    margin-top: 10px;
    text-align: right;
    .executable_buttons {
      button {
        padding: 3px 10px;
        font-weight: lighter;
        font-size: 14px;
        &.record_post_clear {
          background-color: #fff;
          color: #333;
          border: 1px solid #ddd;
        }
        &.record_post_submit {
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
        }
      }
    }
  }
}
</style>
