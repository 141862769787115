<template>
  <div class="horus-system-management-organization-tag-view">
    <h1 class="centering-title">施設タグ</h1>
    <h2>施設タグ一覧<button class="create-btn" @click="newTag">新規施設タグ作成</button></h2>
    <div class="new-tag-form" v-if="createFormVisible">
      <div class="line-contnet">
        <label>タグ名</label><input type="text" size="10" v-model="tagName"/>
      </div>
      <div class="line-contnet">
        <label>ヘルプのパス</label><input type="text" size="15" v-model="helpPath"/>
        <label>重要度</label><input type="number" size="3" v-model="helpPriority"/>
      </div>
      <div class="controll">
        <button class="cancel-btn" @click="cancelCreate">キャンセル</button>
        <button class="submit-btn" @click="submitCreate">新規作成</button>
      </div>
    </div>
    <table class="organization-tags">
      <tr><th>タグ名</th><th>ヘルプパス(重要度)</th><th>&nbsp;</th></tr>
      <tr v-for="tag in organizationTags" :key="'systemOrgTag' + tag.id">
        <td>
          <div class="tag-name" v-if="!editTagID || editTagID !== tag.id">{{ tag.name }}</div>
          <div class="edit-name" v-if="editTagID && editTagID === tag.id">
            <input type="text" cols="10" v-model="editTagName" /><br/>
            <button class="cancel-btn" @click="cancelEdit">キャンセル</button>
            <button class="submit-btn" @click="submitEdit">更新</button>
          </div>
        </td>
        <td>
          <div class="help-path" v-if="!editHelpID || editHelpID !== tag.id">{{ tag.help_path }}(重要度:{{ tag.help_priority }})</div>
          <div class="edit-help" v-if="editHelpID && editHelpID === tag.id">
            <label>ヘルプのパス</label><input type="text" cols="15" v-model="editHelpPath"/><br/>
            <label>重要度</label><input type="number" cols="0" v-model="editHelpPriority"/><br/>
            <button class="cancel-btn" @click="cancelEditHelp">キャンセル</button>
            <button class="sumit-btn" @click="submitEditHelp">更新</button>
          </div>
        </td>
        <td class="controlls">
          <button class="edit-btn" @click="edit(tag)">タグ名編集</button>
          <button class="edit-help-btn" @click="editHelp(tag)">ヘルプパス編集</button>
          <button class="remove-btn" @click="remove(tag)">タグ削除</button>
          <button class="remove-help-btn" @click="removeHelp(tag)">ヘルプのみ削除</button>
        </td>
      </tr>
    </table>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { OrganizationTag } from '@/api'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManagementOrganizationTagView',

  components: {
    HorusConfirmDialog
  },

  data () {
    return {
      organizationTags: [],
      createFormVisible: false,
      tagName: '',
      helpPath: '',
      helpPriority: 0,
      editTagID: null,
      editHelpID: null,
      editTagName: '',
      editHelpPath: '',
      editHelpPriority: 0
    }
  },

  mounted () {
    this.loadOrganizationTags()
  },

  methods: {
    loadOrganizationTags () {
      OrganizationTag.fetchList().then(res => {
        this.organizationTags = res.organization_tags
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データロード失敗', '施設タグデータのロードに失敗しました')
      })
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    newTag () {
      this.resetEdit()
      this.resetEditHelp()
      this.createFormVisible = true
    },

    cancelCreate () {
      this.tagName = ''
      this.helpPath = ''
      this.helpPriority = 0
      this.createFormVisible = false
    },

    submitCreate () {
      if (this.tagName.length <= 0) {
        this.showNotifyDialog('タグ名の空白', 'タグ名は入力必須です')
      } else if (this.organizationTags.some(tag => tag.name === this.tagName)) {
        this.showNotifyDialog('タグ名の重複', 'すでに同一名のタグが存在ましす')
      } else {
        var formData = new FormData()
        formData.append('organization_tag[name]', this.tagName)
        formData.append('help_path', this.helpPath)
        formData.append('help_priority', this.helpPriority)
        OrganizationTag.create({ formData: formData }).then(() => {
          this.loadOrganizationTags()
          this.createFormVisible = false
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('タグの追加失敗', 'タグの新規追加に失敗しました')
        })
      }
    },

    edit (tag) {
      this.cancelCreate()
      this.resetEditHelp()
      this.editTagID = tag.id
      this.editTagName = tag.name
    },

    resetEdit () {
      this.editTagID = null
      this.editTagName = ''
    },

    editHelp (tag) {
      this.cancelCreate()
      this.resetEdit()
      this.editHelpID = tag.id
      this.editHelpPath = tag.help_path
      this.editHelpPriority = tag.help_priority
    },

    resetEditHelp () {
      this.editHelpID = null
      this.editHelpPath = ''
      this.editHelpPriority = 0
    },

    remove (tag) {
      this.cancelCreate()
      this.resetEdit()
      this.resetEditHelp()
      this.showConfirmDialog(
        'タグの削除',
        'タグを削除にしようとしています。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeRemove(tag) }
      )
    },

    executeRemove (tag) {
      OrganizationTag.destroy({ targetID: tag.id }).then(() => {
        this.loadOrganizationTags()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('タグの削除失敗', 'タグの削除に失敗しました')
      })
    },

    removeHelp (tag) {
      this.cancelCreate()
      this.resetEdit()
      this.resetEditHelp()
      this.showConfirmDialog(
        'タグヘルプの削除',
        'タグヘルプを削除にしようとしています。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeRemoveHelp(tag) }
      )
    },

    executeRemoveHelp (tag) {
      OrganizationTag.destroyHelpPath({ targetID: tag.id }).then(() => {
        this.loadOrganizationTags()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('タグの削除失敗', 'タグの削除に失敗しました')
      })
    },

    cancelEdit () {
      this.resetEdit()
    },

    submitEdit () {
      if (this.editTagName.length <= 0) {
        this.showNotifyDialog('タグ名の空白', 'タグ名は入力必須です')
      } else if (this.organizationTags.some(extag => (extag.name === this.editTagName && extag.id !== this.editTagID))) {
        this.showNotifyDialog('タグ名の重複', 'すでに同一名のタグが存在ましす')
      } else {
        var formData = new FormData()
        formData.append('id', this.editTagID)
        formData.append('organization_tag[name]', this.editTagName)
        OrganizationTag.update({ formData: formData }).then(() => {
          this.loadOrganizationTags()
          this.resetEdit()
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('タグの更新失敗', 'タグの更新に失敗しました')
        })
      }
    },

    cancelEditHelp () {
      this.resetEditHelp()
    },

    submitEditHelp () {
      var formData = new FormData()
      formData.append('id', this.editHelpID)
      formData.append('help_path', this.editHelpPath)
      formData.append('help_priority', this.editHelpPriority)
      OrganizationTag.updateHelpPath({ formData: formData }).then(() => {
        this.loadOrganizationTags()
        this.resetEditHelp()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('ヘルプパスの更新失敗', 'ヘルプパスの更新に失敗しました')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-management-organization-tag-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .new-tag-form {
    padding: 10px;
    border: 1px solie #aaa;
    background-color: #fff;
    .line-contnet {
      margin-bottom: 10px;
      label {
        display: inline-block;
        width: 100px;
        margin-right: 10px;
        text-align: right;
      }
      input {
        font-size: 16px;
        &[type='number'] {
          width: 50px;
        }
      }
    }
    .controll {
      text-align: right;
      button {
        border: 1px solid #666;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
  .organization-tags {
    width: 100%;
    margin: 10px 0;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
    th {
      border-bottom: 1px solid #aaa;
    }
    td {
      width: 30%;
      padding: 5px 3px;
      border-bottom: 1px solid #aaa;
      vertical-align: top;
      input {
        font-size: 16px;
      }
      button {
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
        }
        &.edit-btn {
          border-color: #f39800;
          background-color: #f39800;
          color: #fff;
        }
        &.edit-help-btn {
          border-color: #f39800;
          background-color: #f39800;
          color: #fff;
        }
        &.remove-btn {
          border-color: #b7282e;
          background-color: #b7282e;
          color: #fff;
        }
        &.remove-help-btn {
          border-color: #b7282e;
          background-color: #b7282e;
          color: #fff;
        }
      }
      &.controlls {
        width: 40%;
        text-align: right;
      }
    }
  }
}
</style>
