<template>
  <div class="horus-manage-organization-area-block-relation-view">
    <h1 class="centering-title">施設所属管理団体設定</h1>
    <h2>施設一覧</h2>
    <div class="head-controll">
      <div class="data-controll">
        <button class="create-btn" @click="goOrgList">施設管理に戻る</button>
      </div>
    </div>
    <div class="narrow-down-tags">
      <div class="narrow-down-title">タグ絞り込み</div>
      <div class="narrow-down"
        v-for="tag in organizationTags"
        :key="'sysOrgListTag' + tag.id"
        @click="toggleNarrowDownTag(tag)"
        >
        <horus-custom-check-box
          :active="tagSelected(tag)"
          :enabled="true"
          :type="'color'"
          />
          {{ tag.name }}
      </div>
    </div><!-- narrow-down-tags -->
    <div v-if="organizationNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div class="organization-list" v-if="!organizationNowLoading">
      <div class="list-column"
        v-for="org in displayOrganizations"
        :key="'sysOrgList' + org.id"
        >
        <div class="ara-block-column">
          <div class="area-block-selection" @click="setAreaBlock(org, 'none')">
            <horus-custom-check-box
              :active="isIndipendent(org)"
              :enabled="true"
              :type="'color'"
              />
              所属なし
          </div>
          <div class="area-block-selection" v-for="areaBlock in areaBlocks" :key="'selaborg' + org.id + '-' + areaBlock.id" @click="setAreaBlock(org, areaBlock)">
            <horus-custom-check-box
              :active="isDipendent(org, areaBlock)"
              :enabled="true"
              :type="'color'"
              />
              {{ areaBlock.name }}
          </div>
        </div>
        <div class="org-column">
          <div class="list-line">
            <div class="name">{{ org.name }} [{{ org.organizationNumber }}]</div>
          </div>
          <div class="list-line">
            <div class="title">{{ org.nameKana }}</div>
          </div>
          <div class="list-line">
            <div class="type">種別 : [{{ org.organization_type.name }}]</div>
            <div class="duptize">設定代行 : {{ organizationDeputizable(org) }}</div>
            <div class="tag">タグ : {{ tagsOfOrganization(org) }}</div>
          </div>
        </div>
      </div>
    </div><!-- organization-list -->
  </div>
</template>

<script>
import {
  AreaBlock,
  Organization
} from '@/models'

import {
  OrganizationTag
} from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusManageOrganizationAreaBlockRelationView',

  components: {
    HorusCustomCheckBox
  },

  props: {},

  data () {
    return {
      areaBlocks: [],
      organizationNowLoading: false,
      organizations: [],
      displayOrganizations: [],
      organizationTags: [],
      selectedTags: []
    }
  },

  mounted () {
    this.loadDatas()
  },

  methods: {
    async loadDatas () {
      await this.loadAreaBlocks()
      await this.loadOrganizations()
      await this.loadOrganozationTags()
    },

    loadAreaBlocks () {
      AreaBlock.api().fetchList().then(res => {
        this.areaBlocks = res
        return res
      })
    },

    loadOrganizations () {
      this.organizationNowLoading = true
      Organization.api().listWithSummary().then(res => {
        this.organizations = res.organizations
        this.updateDisplay()
        this.organizationNowLoading = false
        return res
      })
    },

    loadOrganozationTags () {
      OrganizationTag.fetchList().then(res => {
        this.organizationTags = res.organization_tags
        return res
      })
    },

    updateDisplay () {
      // narrow down by type
      this.displayOrganizations = this.organizations.filter(org => !org.deleted)
      // narrow down by tags
      if (this.selectedTags.length > 0) {
        this.displayOrganizations = this.displayOrganizations.filter(org => {
          var exist = false
          org.tags.forEach(oTag => {
            if (this.selectedTags.some(sTag => sTag === oTag)) {
              exist = true
            }
          })
          return exist
        })
      }
    },

    toggleNarrowDownTag (tag) {
      var tagIndex = this.selectedTags.indexOf(tag.id)
      if (tagIndex >= 0) {
        this.selectedTags.splice(tagIndex, 1)
      } else {
        this.selectedTags.push(tag.id)
      }
      this.updateDisplay()
    },

    tagSelected (tag) {
      if (this.selectedTags.some(sTag => sTag === tag.id)) {
        return true
      } else {
        return false
      }
    },

    organizationDeputizable (org) {
      if (org.deputizable) {
        return 'あり'
      } else {
        return 'なし'
      }
    },

    isIndipendent (org) {
      if (!org.area_block_id) {
        return true
      }
      return false
    },

    isDipendent (org, areaBlock) {
      if (org.area_block_id === areaBlock.id) {
        return true
      }
      return false
    },

    setAreaBlock (org, areaBlock) {
      var areaBlockID = null
      if (areaBlock === 'none') {
        areaBlockID = 'none'
      } else {
        areaBlockID = areaBlock.id
      }
      Organization.api().updateAreaBlock({ organizationID: org.id, areaBlockID: areaBlockID }).then(() => {
        org.area_block_id = areaBlockID
      }).catch(err => {
        console.error(err)
      })
    },

    areaBlockName (org) {
      if (org.area_block_id) {
        var target = this.areaBlocks.find(areablock => areablock.id === org.area_block_id)
        console.log(target)
        if (target) {
          return target.name
        }
      }
      return '所属なし'
    },

    tagsOfOrganization (org) {
      var tagnames = []
      this.organizationTags.forEach(tag => {
        if (org.tags.some(otag => otag === tag.id)) {
          tagnames.push(tag.name)
        }
      })
      return tagnames.join(', ')
    },

    goOrgList () {
      this.$router.push({ path: '/system_management/organization' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-manage-organization-area-block-relation-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .head-controll {
    display: flex;
    justify-content: space-between;
    .data-controll {
      button {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .narrow-down-title {
    margin-right: 10px;
    font-weight: bold;
  }
  .narrow-down-tags {
    display: flex;
    margin: 10px 0;
    .narrow-down {
      display: flex;
      margin-right: 20px;
    }
  }
  .now-loading {
    display: flex;
    justify-content: center;
    padding: 50px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .organization-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .ara-block-column {
        width: 250px;
        .area-block-selection {
          display: flex;
          align-items: center;
          margin: 5px 0;
        }
      }
      .org-column {
        width: calc(100% - 250px);
      }
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            font-weight: bold;
          }
          &.title {
            width: auto;
          }
          &.type {
            width: 160px;
          }
          &.duptilze {
            width: 100px;
          }
          &.tag {
            width: 100px;
          }
          &.count {
            width: 100px;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
