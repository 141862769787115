// id: member.id,
// nbid: member.user.name,
// organization_id: member.organization_id,
// user_id: member.user.id,
// service_using_organization_member_id: suom.id,
// author_id: "mem#{suom.id}",
// member_manager: @organization.member_manager?(user),
// relation_manager: @organization.relation_manager?(user),
// deputize_manager: @organization.deputize_manager?(user),
// agent: @organization.agent?(user),
// suom_name: suom.name,
// suom_mail: suom.mail,
// suom_tel: suom.tel,
// suom_additional_comment: suom.additional_comment,
// suom_portrate_own: suom.portrate?

import axios from './AxiosInstance'

export default {
  fetch: ({organizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_member/list',
        withCredentials: true,
        params: { organization_id: organizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ organization_members: res.data.organization_members })
        }
      }).catch(error => { reject(error) })
    })
  },

  destroy: ({organizationID, memberID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_member',
            withCredentials: true,
            params: { organization_id: organizationID, organization_member_id: memberID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
