<template>
  <div class="horus-user-task-form-modal">
    <horus-modal-frame :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/task_pad_icon_s.png" class="title-icon" />{{ taskModalTitle }}</h1>
        <div class="target-owner-column">
          <div class="target-owner-user" v-if="timelineOwner && timelineOwner.author">
            <img :src="timelineOwner.author.portrateSrc" class="author-portrate" />
            <span class="owner-name">{{ timelineOwner.author.name }}({{ timelineOwner.author.age }})のタイムラインの予定を作成</span>
          </div>
          <div class="target-owner-organization" v-if="organization">
            <span class="organization-name">{{ organization.shortName }}の予定を作成</span>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="form-datas">
          <div class="top-configure">
            <div class="target-date-display">
              <span class="date-display" @click="setTargetDate">予定日時:{{ targetDateStr }}</span>
            </div>
            <div class="recordable-controll">
              <span class="recordable-click-target" @click="toggleRecordable">
                <span class="title">自動書込</span>
                <horus-custom-check-box
                  :type="'color'"
                  :active="userTask.recordable"
                  :enabled="recordStatus.enable"
                  />
              </span>
              <span class="record-status-message">{{ recordStatus.message }}</span>
            </div>
          </div>
          <div class="text-lock-notify" v-show="textLocked">
            この予定は、リンクによるビデオ通話の予約がなされているため、ビデオ通話のURLを含めた本文テキストの編集はロックされています。<br/>
            ビデオ通話のURLを変更するなどの必要がある場合、一度この予定を削除して新規作成してください。
          </div>
          <div class="text-lock-notify" v-show="meetingLocked">
            この予定は、Zoomミーティングのビデオ通話予約がなされています。<br/>
            予定の日付を変更した場合は予約したZoomミーティングの日付、予定の本文を変更した場合にはZoomミーティングのトピックが変更されます。<br/>
            Zoomミーティングのパスワードを変更したい場合は「パスワード変更」を押してください。<br/>
            Zoomミーティングを削除する場合、一度この予定を削除してください。
          </div>
          <textarea placeholder="ここに書いてください" class="text-content" rows="1" :disabled="textLocked" ref="textContentField" v-model="userTask.textContent"></textarea>
          <div class="post-controlls">
            <div class="uploaded-photos" v-if="sourceTask && sourceTask.userTaskPhotos.length > 0">
              <h3>アップロード済写真</h3>
              <div class="uploaded-photo-thumbnails">
                <div class="uploaded-photo-thumbnail"
                  v-for="taskPhoto in sourceTask.userTaskPhotos"
                  :key="'editinguploadedphotothumbnail' + taskPhoto.id">
                  <horus-inline-photo
                    :photoObject="taskPhoto"
                    :photoMaxSize="100"
                    v-on:timeliePhotoClickEvent="showPhoto(taskPhoto)" />
                  <div class="uploaded-photo-controll" @click="togglePhotoRemove(taskPhoto.id)">
                    <horus-custom-check-box :type="'small'" :active="photoGonnaRemove(taskPhoto.id)" :enabled="true" />この写真は削除する
                  </div>
                </div>
              </div>
            </div>
            <div class="file-reports" v-if="sourceTask && sourceTask.userTaskFiles.length > 0">
              <h3>アップロード済ファイル</h3>
              <div class="uploaded-file-list">
                <div class="uploaded-file"
                  v-for="taskFile in sourceTask.userTaskFiles"
                  :key="'editinguploadedfile' + taskFile.id">
                  <horus-inline-file
                    :fileObject="taskFile"
                    :sizeType="'normal'" />
                  <div class="uploaded-file-controll" @click="toggleFileRemove(taskFile.id)">
                    <horus-custom-check-box :type="'small'" :active="fileGonnaRemove(taskFile.id)" :enabled="true" />このファイルは削除する
                  </div>
                </div>
              </div>
            </div>
            <horus-multi-photo-selector
              ref="multiPhotoSelector"
              :photos="userTask.photos"
              :photoInputID="photoInputID"
              v-on:newPhotoPreparedEvnt="addSelectedPhoto"
              v-on:photoRemoveEvent="removePhoto"
              />
            <horus-multi-file-selector
              ref="multiFileSelector"
              :files="userTask.files"
              :displaySize="''"
              :fileInputID="fileInputID"
              v-on:newFilePreparedEvent="addSelectedFile"
              v-on:fileRemoveEvent="removeFile"
              />
          </div><!-- post controll end -->
          <div class="add_icons js_group_post_inputs">
            <div class="icon-button">
              <label :for="photoInputID" @click="addPhotoForPost">
                <img src="/static/images/camera_icon.png" class="add-photo"/>
              </label>
            </div>
            <div class="icon-button">
              <label :for="fileInputID" @click="addFileForPost">
                <img src="/static/images/document_icon.png" class="add-file"/>
              </label>
            </div>
            <div class="icon-button" v-show="timelineOwner">
              <img src="/static/images/tag_icon.png" class="add-tag" @click="selectTag">
            </div>
            <div class="icon-button">
              <img src="/static/images/select_share_icon.png" class="add-pushtarget" @click="addPushForTarget">
            </div>
            <div class="icon-button" v-if="videoMeetingEnable" :class="{ disable: !zoomMeetingAvailable }">
              <img src="/static/images/video_meeting_s_icon.png" class="add-meeting" v-show="!userTask.zoomMeeting" @click="toggleMeetingEmbed">
              <img src="/static/images/video_meeting_s_green_icon.png" class="add-meeting" v-show="userTask.zoomMeeting" @click="toggleMeetingEmbed">
            </div>
            <div class="link-meeting-notify" v-if="meetingLinkFound">
              <div class="notify-title">ミーティングのリンクを発見</div>
              <div class="meeting-type">{{ meetingTypeText }}</div>
            </div>
          </div><!-- add_icons end -->
          <div class="meeting-password-inputs" v-if="userTask.zoomMeeting">
            <div class="meeting-password-message" v-if="!sourceTask">Zoomミーティングを追加します。ミーティングのパスワードを入力してください。</div>
            <div class="meeting-password-message" v-if="sourceTask">Zoomミーティングが設定済みです。パスワードの変更を行えます。</div>
            <button class="zoom-meeting-password-show-btn" @click="meetingPasswordChangeToggle" v-if="sourceTask">{{ meetingPasswordChangeBtnText }}</button>
            <horus-attribute-in-form
              :labelText="'ミーティングパスワード'"
              :necessary="true"
              :validationState="meetingPasswordState.state"
              :validationMessage="meetingPasswordState.message"
              :attributeNotify="'6文字以上、10文字以内、半角英数字と記号[@-_^]のみ。'"
              v-show="meetingPasswordInputVisible">
              <input :type="passwordInputType" v-model="meetingPassword" />
            </horus-attribute-in-form>
            <div class="toggle-password-visible" @click="togglePasswordVisible" v-show="meetingPasswordInputVisible">
              <horus-custom-check-box
                :active="passwordVisible"
                :enabled="true"
                :type="'color'"
                />パスワードを表示
            </div>
          </div>
          <div class="hash_tags">
            <div class="hash-tag"
              v-for="hashTag in selectedTags"
              :key="'userTaskSelectedTag' + hashTag.id"
              @click="removeSelectedTag(hashTag)">
              <horus-hash-tag-display
                :hashTag="hashTag"
                :sizeType="'small'" />
              <img src="/static/images/x_dark_icon.png" class="close-icon" />
            </div>
            <div class="new-hash-tag"
              v-for="(newTag, index) in createTags"
              :key="'newCreatedTagUserTask' + index"
              @click="removeCreateTag(index)">
              <img src="/static/images/tag_icon_green.png" class="new-tag-icon" />
              <span v-html="modifiedTagName(newTag)"></span>
              <img src="/static/images/x_dark_icon.png" class="close-icon" />
            </div>
          </div><!-- selected hashtags end -->
          <div class="push-targets">
            <div class="push-target-author"
              v-for="pushAuthor in pushTargets"
              :key="'userTaskNotify' + pushAuthor.id"
              @click="removePushTargetAuthor(pushAuthor)">
              <img :src="pushAuthor.portrateSrc" class="portrate" />
              <div class="author-name">{{ pushAuthor.name }}</div>
              <img src="/static/images/x_dark_icon.png" class="close-icon" />
            </div>
          </div><!-- push target end -->
          <div class="execute_controlls">
            <div class="executable_buttons">
              <button type="button" class="record_post_clear" @click="clearPostForm">キャンセル</button>
              <button type="button" class="record_post_submit" :class="{ enable: readyToSubmit.ready && !submitButtonLock }" @click="submitPostForm">{{ executeText }}</button>
            </div>
          </div><!-- execute controll end -->
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'
import HorusMultiFileSelector from '@/components/common/HorusMultiFileSelector.vue'
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

import * as TASK_MEETING_TYPE from '@/assets/constants/taskMeetingType.js'

export default {
  name: 'HorusUserTaskFormModal',

  components: {
    HorusModalFrame,
    HorusMultiPhotoSelector,
    HorusMultiFileSelector,
    HorusInlinePhoto,
    HorusInlineFile,
    HorusHashTagDisplay,
    HorusCustomCheckBox,
    HorusAttributeInForm
  },

  props: {
    signinUser: Object,
    signinMember: Object,
    timelineOwner: Object,
    organization: Object,
    showModal: Boolean,
    sourceTask: Object,
    targetDate: Object,
    selectedTags: Array,
    createTags: Array,
    pushTargets: Array,
    zoomUser: Object
  },

  data () {
    return {
      userTask: {
        textContent: '',
        linkURL: '',
        meetingType: TASK_MEETING_TYPE.TASK_MEETING_NONE,
        photos: [],
        files: [],
        recordable: true,
        zoomMeeting: false,
        zoomMeetingExist: false
      },
      removePhotos: [],
      removeFiles: [],
      photoInputID: 'photoInputForUserTask',
      fileInputID: 'fileInputForUserTask',
      linkSafe: true,
      meetingPasswordInputVisible: false,
      meetingPassword: '',
      passwordVisible: false,
      submitButtonLock: false
    }
  },

  created () {
    this.initFrom()
    this.initData()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
  },

  watch: {
    sourceTask: { handler: 'initData', immediate: true },
    'userTask.textContent': { handler: 'textContentUpdatedCheck' }
  },

  computed: {
    taskModalTitle () {
      if (this.sourceTask) {
        return '予定の編集'
      } else {
        return '予定の新規作成'
      }
    },

    executeText () {
      if (this.sourceTask) {
        return '更新'
      } else {
        return '作成'
      }
    },

    targetDateStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('YYYY/MM/DD HH:mm')
      }
      return '日付が設定されていません'
    },

    recordStatus () {
      if (this.organization) {
        return { enable: false, message: '施設の予定は自動書き込みされません' }
      } else if (this.userTask.zoomMeeting) {
        return { enable: false, message: 'ビデオ通話の予約は自動書込されません' }
      } else if (this.userTask.meetingType !== TASK_MEETING_TYPE.TASK_MEETING_NONE) {
        return { enable: false, message: 'ビデオ通話の予約は自動書込されません' }
      } else {
        if (this.userTask.recordable) {
          return { enable: true, message: '予定時刻に対象利用者のタイムラインに内容が書き込まれます。' }
        } else {
          return { enable: true, message: 'タイムラインには自動書き込みしません。' }
        }
      }
    },

    videoMeetingEnable () {
      if (this.signinMember && this.signinMember.organization && this.signinMember.organization.callVideoMeetingAvailable && this.zoomUser) {
        return true
      } else if (this.signinUser && this.zoomUser) {
        return true
      }
      return false
    },

    zoomMeetingAvailable () {
      if (this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_NONE || this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_EMBEDED_ZOOM) {
        return true
      }
      return false
    },

    meetingLinkFound () {
      if (this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_ZOOM || this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_GOOGLE_MEET || this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_MS_TEAMS) {
        return true
      }
      return false
    },

    meetingTypeText () {
      if (this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_ZOOM) {
        return 'ZOOM'
      } else if (this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_GOOGLE_MEET) {
        return 'GOOGLE MEET'
      } else if (this.userTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_MS_TEAMS) {
        return 'MS TEAMS'
      }
      return ''
    },

    textLocked () {
      if (this.sourceTask &&
        (this.sourceTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_ZOOM ||
        this.sourceTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_GOOGLE_MEET ||
        this.sourceTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_MS_TEAMS)
      ) {
        return true
      } else {
        return false
      }
    },

    meetingLocked () {
      if (this.sourceTask && this.userTask.zoomMeeting) {
        return true
      }
      return false
    },

    meetingPasswordChangeBtnText () {
      if (this.sourceTask) {
        if (this.meetingPasswordInputVisible) {
          return 'パスワード変更中止'
        } else {
          return 'パスワード変更'
        }
      } else {
        return ''
      }
    },

    meetingPasswordState () {
      if (this.meetingPassword.length <= 0) {
        return { state: 'disabled', message: '', valid: false }
      } else if (this.meetingPassword.trim().match(/^([a-zA-Z0-9]|@|_|-|\^){6,10}$/g)) {
        return { state: 'valid', message: '', valid: true }
      } else {
        return { state: 'invalid', message: 'パスワードは半角英数字と記号[@-_^]のみで6文字以上10文字以内です。', valid: false }
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    readyToSubmit () {
      var existPhotoCount = 0
      var existFileCount = 0
      if (this.sourceTask) {
        existPhotoCount = (this.sourceTask.userTaskPhotos.length - this.removePhotos.length)
        existFileCount = (this.sourceTask.userTaskFiles.length - this.removeFiles.length)
      }
      if (!this.linkSafe) {
        return { ready: false, title: '本文のエラー', message: '本文に含まれるビデオ通話のリンクが正しくありません。\n本文には、ビデオ通話のリンクを複数含めることはできません。\nまた、新規ZOOMビデオ通話を設定している場合、本文にリンクを貼ることはできません。' }
      } else if (
        (!this.userTask.textContent || this.userTask.textContent.length === 0) &&
        (this.userTask.photos.length + existPhotoCount) === 0 &&
        (this.userTask.files.length + existFileCount) === 0
      ) {
        return { ready: false, title: '内容のエラー', message: '内容のエラー／書込情報がありません。' }
      } else if (
        (!this.sourceTask && this.userTask.zoomMeeting && !this.meetingPasswordState.valid) || (this.meetingPasswordInputVisible && !this.meetingPasswordState.valid)) {
        return { ready: false, title: 'Zoomミーティングパスワード', message: 'Zoomミーティングのパスワードが正しく入力されていません。' }
      } else if (!this.sourceTask && this.userTask.zoomMeeting && this.signinUser && this.signinUser.id === this.timelineOwner.id && this.pushTargets.length <= 0) {
        return { ready: false, title: 'Zoomミーティング参加者', message: 'Zoomミーティングの参加者がいません。' }
      } else {
        return { ready: true, title: '', message: '' }
      }
    }

  },

  methods: {
    initFrom () {
      this.photoInputID = 'photoInputUT' + Math.floor(Math.random() * 10000)
      this.fileInputID = 'fileInputUT' + Math.floor(Math.random() * 10000)
      this.videoInputID = 'videInputUT' + Math.floor(Math.random() * 10000)
      this.audioInputID = 'audioInputUT' + Math.floor(Math.random() * 10000)
      this.submitButtonLock = false
    },

    initData () {
      if (this.sourceTask) {
        this.userTask.textContent = this.sourceTask.textContent
        this.userTask.linkURL = this.sourceTask.linkURL
        this.userTask.recordable = this.sourceTask.recordable
        this.userTask.meetingType = this.sourceTask.meetingType
        if (this.sourceTask.linkURL) {
          this.userTask.textContent = this.sourceTask.textContent.replace(TASK_MEETING_TYPE.MEETING_LINK_REPLACE_TEXT, this.sourceTask.linkURL)
        }
        // TASK_MEETING_EMBEDED_ZOOM
        if (this.sourceTask.meetingType === TASK_MEETING_TYPE.TASK_MEETING_EMBEDED_ZOOM) {
          this.userTask.recordable = false
          this.userTask.zoomMeeting = true
          this.userTask.zoomMeetingExist = true
        }
      } else {
        this.userTask.textContent = ''
        this.userTask.linkURL = null
        this.userTask.recordable = true
        this.userTask.meetingType = TASK_MEETING_TYPE.TASK_MEETING_NONE
        this.userTask.zoomMeeting = false
        this.userTask.zoomMeetingExist = false
      }
      this.userTask.photos = []
      this.userTask.files = []
      this.removePhotos = []
      this.removeFiles = []
      if (this.organization) {
        this.userTask.recordable = false
      }
      this.meetingPassword = ''
    },

    setTargetDate () {
      this.$emit('setTargetDateEvent')
    },

    toggleRecordable () {
      console.log('toggle rcordable call,')
      this.userTask.recordable = !this.userTask.recordable
      if (!this.recordStatus.enable) {
        this.userTask.recordable = false
      }
    },

    textContentUpdatedCheck () {
      this.fixtextContentArea()
      this.extractMeetingLink()
    },

    fixtextContentArea () {
      this.$nextTick(() => {
        var textArea = this.$refs.textContentField
        if (textArea) {
          // 一度高さをリセットしないと、scrollHeightが正しく取得出来ない。ただし、ちょっとちらついてしまう場合がある
          textArea.style.width = 'calc(100% - 10px)'
          textArea.style.marginTop = '10px'
          textArea.style.height = '26px'
          if (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = textArea.scrollHeight + 'px'
          }
        }
      })
    },

    extractMeetingLink () {
      if (this.userTask.textContent) {
        // check regexp
        // matchでチェック。複数ヒットする場合は問題があるので警告を上げる
        var zoomResult = []
        for (let res of this.userTask.textContent.matchAll(TASK_MEETING_TYPE.ZOOM_LINK_REGEXP)) {
          // console.log(res)
          zoomResult.push(res)
        }
        var gMeetResult = []
        for (let res of this.userTask.textContent.matchAll(TASK_MEETING_TYPE.G_MEET_LINK_REGEXP)) {
          gMeetResult.push(res)
        }
        var msTeamsResult = []
        for (let res of this.userTask.textContent.matchAll(TASK_MEETING_TYPE.MS_TEAMS_LINK_REGEXP)) {
          msTeamsResult.push(res)
        }
        // console.log('zoom regexp result.')
        // console.log(zoomResult)
        // console.log('length' + zoomResult.length)
        // console.log('g meet regexp result.')
        // console.log(gMeetResult)
        // console.log('ms teams regexp result.')
        // console.log(msTeamsResult)
        if (zoomResult.length <= 0 && gMeetResult.length <= 0 && msTeamsResult.length <= 0) {
          this.userTask.meetingType = TASK_MEETING_TYPE.TASK_MEETING_NONE
          this.userTask.linkURL = null
          this.linkSafe = true
          return
        }
        // ZOOMのチェック
        if (zoomResult.length > 0) {
          if (this.userTask.zoomMeeting) {
            // zoomMeetingを設定している場合はリンクを許さない。
            this.$emit('showNotifyEvent', '本文リンクのエラー', '新規ZOOMビデオ通話を設定している場合、本文にリンクを貼ることはできません。')
            this.linkSafe = false
          } else if (gMeetResult.length > 0 || msTeamsResult.length > 0) {
            // 他のミーティングがある場合は修正を要求する
            this.$emit('showNotifyEvent', '本文リンクのエラー', '複数のビデオ通話リンクを本文に含めることはできません。')
            this.linkSafe = false
          } else if (zoomResult.length > 1) {
            // 複数のミーティングリンクが有る場合は修正を要求する
            this.$emit('showNotifyEvent', '本文リンクのエラー', '複数のビデオ通話リンクを本文に含めることはできません。')
            this.linkSafe = false
          } else {
            this.userTask.meetingType = TASK_MEETING_TYPE.TASK_MEETING_ZOOM
            this.linkSafe = true
            this.userTask.recordable = false
            return
          }
        }
        // google meetのチェック
        if (gMeetResult.length > 0) {
          if (this.userTask.zoomMeeting) {
            // zoomMeetingを設定している場合はリンクを許さない。
            this.$emit('showNotifyEvent', '本文リンクのエラー', '新規ZOOMビデオ通話を設定している場合、本文にリンクを貼ることはできません。')
            this.linkSafe = false
          } else if (zoomResult.length > 0 || msTeamsResult.length > 0) {
            // 他のミーティングがある場合は修正を要求する
            this.$emit('showNotifyEvent', '本文リンクのエラー', '複数のビデオ通話リンクを本文に含めることはできません。')
            this.linkSafe = false
          } else if (gMeetResult.length > 1) {
            // 複数のミーティングリンクが有る場合は修正を要求する
            this.$emit('showNotifyEvent', '本文リンクのエラー', '複数のビデオ通話リンクを本文に含めることはできません。')
            this.linkSafe = false
          } else {
            this.userTask.meetingType = TASK_MEETING_TYPE.TASK_MEETING_GOOGLE_MEET
            this.linkSafe = true
            this.userTask.recordable = false
            return
          }
        }
        // MS teamsのチェック
        if (msTeamsResult.length > 0) {
          if (this.userTask.zoomMeeting) {
            // zoomMeetingを設定している場合はリンクを許さない。
            this.$emit('showNotifyEvent', '本文リンクのエラー', '新規ZOOMビデオ通話を設定している場合、本文にリンクを貼ることはできません。')
            this.linkSafe = false
          } else if (gMeetResult.length > 0 || zoomResult.length > 0) {
            // 他のミーティングがある場合は修正を要求する
            this.$emit('showNotifyEvent', '本文リンクのエラー', '複数のビデオ通話リンクを本文に含めることはできません。')
            this.linkSafe = false
          } else if (msTeamsResult.length > 1) {
            // 複数のミーティングリンクが有る場合は修正を要求する
            this.$emit('showNotifyEvent', '本文リンクのエラー', '複数のビデオ通話リンクを本文に含めることはできません。')
            this.linkSafe = false
          } else {
            this.userTask.meetingType = TASK_MEETING_TYPE.TASK_MEETING_MS_TEAMS
            this.userTask.recordable = false
            this.linkSafe = true
            // return
          }
        }
      }
    },

    modifiedTagName (newTag) {
      const splited = newTag.split('##')
      if (splited.length > 1) {
        return '<span class="hash_tag_type ' + splited[0] + '">' + splited[0] + '</span>' + splited[1]
      } else {
        return splited[0]
      }
    },

    // multi photo selector controlls
    addPhotoForPost () {
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    togglePhotoRemove (userTaskPhotoID) {
      var removeIndex = this.removePhotos.indexOf(userTaskPhotoID)
      if (removeIndex < 0) {
        this.removePhotos.push(userTaskPhotoID)
      } else {
        this.removePhotos.splice(removeIndex, 1)
      }
    },

    photoGonnaRemove (userTaskPhotoID) {
      return this.removePhotos.some(pID => pID === userTaskPhotoID)
    },

    // multi file selector controlls
    addFileForPost () {
      if (this.$native.status) {
        this.$native.event('takeFileEvent', { targetID: this.fileInputID })
      }
    },

    toggleFileRemove (userTaskFileID) {
      var removeIndex = this.removeFiles.indexOf(userTaskFileID)
      if (removeIndex < 0) {
        this.removeFiles.push(userTaskFileID)
      } else {
        this.removeFiles.splice(removeIndex, 1)
      }
    },

    fileGonnaRemove (userTaskFileID) {
      return this.removeFiles.some(fID => fID === userTaskFileID)
    },

    showPhoto (taskPhoto) {
      if (this.userTask) {
        this.$emit('showTaskPhotoEvent', this.userTask, this.userTask.userTaskPhotos.indexOf(taskPhoto))
      }
    },

    addSelectedPhoto (photoObj) {
      this.userTask.photos.push(photoObj)
    },

    removePhoto (index) {
      this.userTask.photos.splice(index, 1)
    },

    addSelectedFile (fileObj) {
      this.userTask.files.push(fileObj)
    },

    removeFile (index) {
      this.userTask.files.splice(index, 1)
    },

    selectTag () {
      this.$emit('openTagSelectionModalEvent')
    },

    removeSelectedTag (tag) {
      this.$emit('removeSelectedTagEvent', tag)
    },

    removeCreateTag (index) {
      this.$emit('removeCreateTagEvent', index)
    },

    addPushForTarget () {
      if (this.timelineOwner) {
        this.$emit('openPushTargetSelectionModalEvent')
      } else if (this.organization) {
        this.$emit('openMemberSelectModalEvent')
      }
    },

    removePushTargetAuthor (author) {
      this.$emit('removeSelectedAuthorEvent', author)
    },

    meetingPasswordChangeToggle () {
      this.meetingPasswordInputVisible = !this.meetingPasswordInputVisible
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    toggleMeetingEmbed () {
      console.log('toggleMeetingEmbed')
      if (!this.sourceTask) {
        if (this.userTask.zoomMeeting) {
          this.userTask.zoomMeeting = false
          this.userTask.meetingType = TASK_MEETING_TYPE.TASK_MEETING_NONE
          this.meetingPasswordInputVisible = false
        } else {
          if (this.zoomMeetingAvailable) {
            this.userTask.zoomMeeting = true
            this.userTask.meetingType = TASK_MEETING_TYPE.TASK_MEETING_EMBEDED_ZOOM
            this.userTask.recordable = false
            this.meetingPasswordInputVisible = true
          }
        }
      }
    },

    clearPostForm () {
      this.$emit('postformClearEvent', this.taskModalTitle + 'のキャンセル', () => { this.executeClearForm() })
    },

    executeClearForm () {
      this.initFrom()
      this.initData()
    },

    submitPostForm () {
      if (!this.readyToSubmit.ready) {
        this.$emit('showNotifyEvent', this.readyToSubmit.title, this.readyToSubmit.message)
      } else if (!this.submitButtonLock) {
        this.submitButtonLock = true
        // CREATE FORM DATA
        var formData = new FormData()
        if (this.timelineOwner) {
          formData.append('target_user_id', this.timelineOwner.id)
        }
        if (this.organization) {
          formData.append('organization_id', this.organization.id)
        }
        if (this.sourceTask) {
          formData.append('user_task_id', this.sourceTask.id)
        }
        formData.append('user_task[nb_calendar_target_date]', moment(this.targetDate.date).format('YYYY/MM/DD'))
        formData.append('user_task[nb_calendar_target_time]', moment(this.targetDate.date).format('HH:mm'))
        // manual created task should recrodable
        formData.append('user_task[recordable]', this.userTask.recordable)
        formData.append('user_task[text_content]', this.userTask.textContent)
        this.userTask.photos.forEach(photo => {
          formData.append('user_task[photos][]', photo.uploadFile, photo.name)
        })
        this.userTask.files.forEach(file => {
          formData.append('user_task[files][]', file.uploadFile, file.name)
        })
        this.removePhotos.forEach(removePhoto => {
          formData.append('user_task[remove_photos][]', removePhoto)
        })
        this.removeFiles.forEach(removeFile => {
          formData.append('user_task[remove_files][]', removeFile)
        })
        this.selectedTags.forEach(tag => {
          formData.append('user_task[hashtags][]', tag.id)
        })
        this.createTags.forEach(newtag => {
          formData.append('user_task[new_hashtags][]', newtag)
        })
        this.pushTargets.forEach(target => {
          if (target.isMember) {
            formData.append('user_task[pushsuoms][]', target.rawID)
          } else {
            formData.append('user_task[pushtargets][]', target.rawID)
          }
        })
        if (this.userTask.zoomMeeting) {
          formData.append('user_task[zoom_meeting]', true)
        }
        if (this.meetingPasswordInputVisible && this.meetingPasswordState.valid) {
          formData.append('usertask[zoom_password]', this.meetingPassword)
        }
        // send form data
        this.$emit('submitPostFormEvent', formData, () => {
          this.executeClearForm()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-user-task-form-modal {
  .modal-header {
    .target-owner-column {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      img {
        width: 30px;
        height: 30px;
      }
      .owner-name {
        display: inline-block;
        margin-left: 10px;
        font-size: 1.1em;
        font-weight: bold;
      }
    }
  }
  .modal-body {
    padding: 10px;
    .top-configure {
      display: flex;
      flex-direction: row;
      @include mediaQuery('phone') {
        flex-direction: column;
      }
    }
    .date-display {
      display: inline-block;
      margin-right: 10px;
      padding: 5px;
      border: 1px solid #aaa;
      font-size: 1.2em;
      font-weight: bold;
    }
    .recordable-controll {
      display: flex;
      align-items: center;
      padding: 5px;
      .recordable-click-target {
        display: flex;
        align-items: center;
      }
      .title {
        margin-right: 5px;
        font-weight: bold;
      }
      .record-status-message {
        font-size: 0.9em;
      }
    }
    .text-lock-notify {
      margin: 5px;
      padding: 5px;
      border: 1px solid #b94047;
      font-size: 0.8em;
      color: #b94047;
    }
    .text-content {
      width: calc(100% - 10px);
      margin-top: 10px;
      resize: none;
      font-size: 16px;
      border: 1px solid #aaa;
    }
    .add_icons {
      display: flex;
      margin-top: 10px;
      .icon-button {
        height: 30px;
        width: 33px;
        margin-right: 10px;
        &.disable {
          opacity: 0.5;
        }
      }
      .link-meeting-notify {
        .notify-title {
          font-size: 0.9em;
          color: #666;
        }
        .meeting-type {
          color: #b94047;
          font-weight: bold;
        }
      }
    }

    .meeting-password-inputs {
      margin-top: 10px;
      padding: 5px;
      border: 1px solid #b94047;
      .toggle-password-visible {
        display: flex;
        align-items: center;
      }
      .zoom-meeting-password-show-btn {
        margin-top: 5px;
        padding: 3px 10px;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    .hash_tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .hash-tag {
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
        .close-icon {
          margin-left: -5px;
          width: 10px;
          vertical-align: middle;
        }
      }
      .new-hash-tag {
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
        .new-tag-icon {
          width: 10px;
        }
        .close-icon {
          width: 10px;
        }
      }
    }
    .push-targets {
      display: flex;
      flex-wrap: wrap;
      .push-target-author {
        cursor: pointer;
        margin: 0 10px 10px 0;
        .portrate {
          width: 15px;
          vertical-align: middle;
        }
        .author-name {
          display: inline-block;
        }
        .close-icon {
          width: 10px;
          vertical-align: middle;
        }
      }
    }
    .execute_controlls {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .executable_buttons {
        button {
          width: 100px;
          margin-right: 10px;
          padding: 3px 10px;
          font-weight: bold;
          font-size: 14px;
          border-radius: 3px;
          &.record_post_clear {
            background-color: #fff;
            color: #333;
            border: 1px solid #999;
          }
          &.record_post_submit {
            background-color: #aaa;
            color: #fff;
            border: 1px solid #aaa;
            &.enable {
              background-color: #666;
              color: #fff;
              border: 1px solid #666;
            }
          }
        }
      }
    }
  }
}
</style>
