import axios from './AxiosInstance'

export default {
  preMailValidation: ({ mailAddress, organizationRequestID, areaBlockID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_request/pre_mail_validation',
            withCredentials: true,
            params: { mail_address: mailAddress, organization_request_id: organizationRequestID, area_block_id: areaBlockID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      }).catch(error => {
        throw error
      })
    })
  },

  confirmMail: ({ validationToken }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_request/confirm_mail',
            withCredentials: true,
            params: { token: validationToken }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      }).catch(error => {
        throw error
      })
    })
  },

  searchMedicalInfo: ({ code }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_request/search_medical_info',
        withCredentials: true,
        params: { code: code }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ medicalInfo: res.data.result })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({ formData }) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_request',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_request/list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ organizationRequests: res.data.organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchNotValidatedRequests: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_request/not_validated_requests',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ organizationRequests: res.data.organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchNotValidatedCount: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_request/not_validated_count',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ count: res.data.count })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchConfirmWaitingRequests: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_request/confirm_waiting_requests',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ organizationRequests: res.data.organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchAllNotProcessedRequests: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_request/all_not_processed_requests',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ organizationRequests: res.data.organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  confirm: ({ organizationRequestID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_request/confirm_organization_request',
            withCredentials: true,
            params: { organization_request_id: organizationRequestID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reject: ({ organizationRequestID, rejectMessage }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_request/reject_organization_request',
            withCredentials: true,
            params: { organization_request_id: organizationRequestID, cause_of_reject: rejectMessage }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({ organizationRequestID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_request',
            withCredentials: true,
            params: { organization_request_id: organizationRequestID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroyAllNotValidated: () => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_request/destroy_all_not_validated',
            withCredentials: true
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }

}
