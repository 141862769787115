<template>
  <div class="horus-family-invitation-confirm-modal">
    <horus-modal-frame
      :showModalFlag="showFamilyInvitationConfirmModal">
      <div class="modal-header">
        <h1><img src="/static/images/family.png" class="title-icon" />家族案内の処理</h1>
      </div>
      <div class="modal-body">
        <h4 class="labeled_content_block_title">家族になる相手の情報</h4>
        <div class="labeled_content_block" v-if="request">
          <div class="labeled_content_single_block">
            <label>氏名(カナ)</label>
            <div class="content">{{ request.user_name }}({{ request.user_name_kana }})</div>
          </div>
          <div class="labeled_content_single_block">
            <label>生年月日/性別</label>
            <div class="content">{{ request.user_birth_date }}/{{ request.user_sex_type }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>住所</label>
            <div class="content">{{ request.user_address }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>電話番号</label>
            <div class="content">{{ request.user_tel }}</div>
          </div>
          <div class="labeled_content_single_block">
            <label>メッセージ</label>
            <div class="content" v-html="messageContent"></div>
          </div>
        </div><!-- labeld content block (target information) end -->
        <div class="labeled_content_line_block">
          <div class="notify_for_admit">
            <h1>家族登録における同意事項</h1>
            <ul>
              <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
              <li>ナラティブブックで家族設定することに同意していること</li>
              <li>なりすまし防止のために、依頼元の方との確認ができていること</li>
              <li>家族に登録すると家族となった利用者は、あなたのタイムラインや特記事項の閲覧や書き込みができるようになります（詳しくは利用規約の第２条及び第７条をご確認ください）
                ただし、上記の「私のナラティブブックは見せない」をチェックして承認した場合は、家族となった利用者があなたのタイムラインや特記事項を閲覧したり書き込んだりすることはできません</li>
            </ul>
          </div>
          <div class="admit-agreement" @click="toggleAdmit">
            <horus-custom-check-box
              :active="admitAgreement"
              :enabled="true"
              :type="'color'"
              />
            上記確認事項を確認しました
          </div>
          <div class="show-timeline-option" @click="toggleShowTimeline" v-if="!senderAlreadyWatching">
            <horus-custom-check-box
              :active="!showOwnTimeline"
              :enabled="true"
              :type="'color'"
              />
            <span>私のナラティブブックは見せない</span>
          </div>
          <div class="show-timeline-option-caption" v-if="!senderAlreadyWatching">
            上記にチェックを入れると、あなたは相手のタイムラインを見ることが出来ますが、<br/>
            相手からあなたのタイムラインを見ることは出来なくなります。<br/>
            チェックを外した場合は、お互いにタイムラインを見ることが出来るようになります。
          </div>
          <div class="show-timeline-option-caption" v-if="senderAlreadyWatching">
            家族案内の送信者は、すでにあなたのタイムラインを見ることが出来る家族です。
          </div>
          <div class="submit_notify_description">
            家族案内を承認するには、「承認」ボタンを、拒否する場合は「拒否」ボタンをクリックしてください。<br />
            <div class="controlls">
              <button class="submit-btn" type="button" :class="{disable: !admitAgreement}" @click="admit">承認</button>
              <button class="reject-btn" type="button" @click="reject">拒否</button>
              <button class="cancel-btn" type="button" @click="cancel">保留</button>
            </div>
          </div>
        </div><!-- labeled content block (confirm agreement) end -->
      </div><!-- modal body end -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusFamilyInvitationConfirmModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    request: Object,
    showFamilyInvitationConfirmModal: Boolean,
    families: Array
  },

  data () {
    return {
      showOwnTimeline: false,
      admitAgreement: false
    }
  },

  computed: {
    messageContent () {
      if (this.request && this.request.message_content && this.request.message_content.length > 0) {
        return this.request.message_content.replace(/\n/gi, '<br />')
      } else {
        return '家族案内のメッセージはありません。'
      }
    },

    senderAlreadyWatching () {
      if (this.request && this.families.some(fam => ((fam.relation === 1 || fam.relation === 2) && fam.nbid === this.request.nbid))) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    toggleShowTimeline () {
      this.showOwnTimeline = !this.showOwnTimeline
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    admit () {
      if (this.admitAgreement) {
        console.log('In Modal, show own timeline: ' + this.showOwnTimeline)
        this.$emit('admitEvent', this.request, this.showOwnTimeline)
      }
    },

    reject () {
      this.$emit('rejectEvent', this.request)
    },

    cancel () {
      this.$emit('cancelEvent')
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/createWithAgreementForm.scss';

.horus-family-invitation-confirm-modal {
  .modal-body {
    padding: 10px;
    // .labeled_content_block_title {
    //   margin: 10px 0 5px 0;
    //   font-weight: bold;
    //   color: #666;
    // }
    // .labeled_content_block {
    //   margin-bottom: 10px;
    //   padding: 10px;
    //   border: 1px solid #aaa;
    // }
    // .labeled_content_single_block {
    //   display: flex;
    //   flex-wrap: wrap;
    //   align-items: baseline;
    //   min-width: 300px;
    //   margin: 5px;
    //   margin-right: 10px;
    //   label {
    //     margin: 0;
    //     margin-right: 5px;
    //     width: 100px;
    //     text-align: right;
    //     font-weight: normal;
    //     font-size: 0.9em;
    //     color: #666;
    //   }
    //   .content {
    //     display: inline-block;
    //     font-size: 1.1em;
    //     img {
    //       width: 20px;
    //       margin-right: 5px;
    //     }
    //   }
    // }
    // .notify_for_admit {
    //   margin-bottom: 5px;
    //   padding: 10px;
    //   border: 1px solid #aaa;
    //   background-color: #fbfaf5;
    //   h1 {
    //     margin: 0;
    //     margin-bottom: 5px;
    //     font-size: 16px;
    //     font-weight: bold;
    //     color: #666;
    //   }
    // }
    // .admit-agreement {
    //   display: flex;
    //   justify-content: center;
    //   margin-bottom: 10px;
    //   font-weight: bold;
    // }
    // .show-timeline-option {
    //   display: flex;
    //   padding: 10px;
    //   padding-bottom: 0;
    //   font-weight: bold;
    //   span {
    //     margin-left: 10px;
    //   }
    // }
    // .show-timeline-option-caption {
    //   padding: 10px;
    //   padding-top: 0;
    //   font-size: 12px;
    // }
    // .submit_notify_description {
    //   margin: 10px;
    //   padding: 10px;
    //   border: 1px solid #aaa;
    //   .controlls {
    //     display: flex;
    //     justify-content: space-around;
    //     margin: 10px;
    //     button {
    //       width: 150px;
    //       border: 1px solid #ddd;
    //       border-radius: 3px;
    //       background-color: #ddd;
    //       color: #fff;
    //       font-weight: bold;
    //       font-size: 13px;
    //       &.submit-btn {
    //         border-color: #5cb85b;
    //         background-color: #5cb85b;
    //         &.disable {
    //           border-color: #93b881;
    //           background-color: #93b881;
    //         }
    //       }
    //       &.reject-btn {
    //         border-color: #c43d53;
    //         background-color: #c43d53;
    //       }
    //       &.cancel-btn {
    //         border-color: #f08300;
    //         background-color: #f08300;
    //       }
    //     }
    //   }
    // }
  }
}
</style>
