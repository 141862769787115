<template>
  <div class="wishes left-column-contents">
    <h3>
      <a @click="openWishModal"><img src="/static/images/wish_icon.png">wish</a>
      <img src="/static/images/open_close_icon.png"  class="info-content-toggle" @click="wishContentToggle"/>
    </h3>
    <div v-if="wishesNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div
      v-else
      v-for="wish in wishes" :key="wish.id">
      <horus-left-column-info-content
        :infoContent="wish"
        :key="'wish' + wish.id"
        :type="'wish'"
        :contentOpen="wishOpen"
        v-on:showInfoContentPhotoEvent="showInfoContentPhoto"
        v-on:infoContentTitleClickEvent="titleClicked" />
    </div>
  </div>
</template>

<script>
import HorusLeftColumnInfoContent from '@/components/leftColumn/HorusLeftColumnInfoContent.vue'

export default {
  name: 'HorusLeftColumnWishList',

  props: {
    wishesNowLoading: Boolean,
    wishes: Array
  },

  components: {
    HorusLeftColumnInfoContent
  },

  data () {
    return {
      wishOpen: false
    }
  },

  methods: {
    wishContentToggle () {
      this.wishOpen = !this.wishOpen
    },

    showInfoContentPhoto (infoContent, type, firstShowIndex) {
      this.$emit('showInfoContentPhotoEvent', infoContent, type, firstShowIndex)
    },

    openWishModal () {
      this.$emit('wishModalOpenEvent')
    },

    titleClicked (infoContent, type) {
      this.$emit('infoContentTitleClickEvent', infoContent, type)
    }

  }
}
</script>

<style lang="scss" scoped>
.left-column-contents {
  h3 {
    margin: 20px 0 5px 0;
    color: #666;
    font-size: 1em;
    text-align: left;
    a {
      color: #666;
      text-decoration: none;
    }
    img {
      margin-right: 5px;
      width: 10px;
    }
    .info-content-toggle {
      width: 15px;
    }
  }
}
</style>
