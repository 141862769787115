import axios from './AxiosInstance'

export default {

  fetchDeputizeOrganizationIDList: ({userID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_deputize_relation/list_by_user',
        withCredentials: true,
        params: { target_user_id: userID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ deputize_organizations: res.data.deputize_organizations })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchDeputizedUserIDList: ({organizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_deputize_relation/list_by_organization',
        withCredentials: true,
        params: { organization_id: organizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ deputized_users: res.data.deputized_users })
        }
      }).catch(error => { reject(error) })
    })
  },

  destroyUser: ({organizationID, targetUserID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_deputize_relation/destroy_deputized_user',
            withCredentials: true,
            params: { organization_id: organizationID, user_id: targetUserID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroyOrganization: ({organizationID, targetUserID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_deputize_relation/destroy_deputize_organization',
            withCredentials: true,
            params: { organization_id: organizationID, target_user_id: targetUserID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
