<template>
  <div class="hours-vital-entry-list-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/vital_green.png" class="title-icon" />バイタル一覧</h1>
        <img src="/static/images/x_icon.png" class="close-icon" @click="closeModal" />
      </div>
      <div class="modal-body">
        <div class="type-switches">
          <span>
            <button class="all-btn" :class="{selected: displayType == 'all'}" @click="switchType('all')">すべて</button><br/>
            ({{ vitalEntries.length }}/{{ vitalEntryCount }})
          </span>
          <span>
            <button class="blood-pressure-btn" :class="{selected: displayType == 'blood-pressure'}" @click="switchType('blood-pressure')">血圧</button><br/>
            ({{ bloodPressures.length }}/{{ bloodPressureCount }})
          </span>
          <span>
            <button class="body-composition-btn" :class="{selected: displayType == 'body-composition'}" @click="switchType('body-composition')">体組成</button><br/>
            ({{ bodyCompositions.length }}/{{ bodyCompositionCount }})
          </span>
          <span>
            <button class="active-mass-btn" :class="{selected: displayType == 'active-mass'}" @click="switchType('active-mass')">活動量</button><br/>
            ({{ activeMasses.length }}/{{ activeMassCount }})
          </span>
          <span>
            <button class="body-temperature-btn" :class="{selected: displayType == 'body-temperature'}" @click="switchType('body-temperature')">体温</button><br/>
            ({{ bodyTemperatures.length }}/{{ bodyTemperatureCount }})
          </span>
          <span>
            <button class="oxygen-saturation-btn" :class="{selected: displayType == 'oxygen-saturation'}" @click="switchType('oxygen-saturation')">酸素飽和度</button><br/>
            ({{ oxygenSaturations.length }}/{{ oxygenSaturationCount }})
          </span>
          <span>
            <button class="pulse-rate-btn" :class="{selected: displayType == 'pulse-rate'}" @click="switchType('pulse-rate')">脈拍</button><br/>
            ({{ pulseRates.length }}/{{ pulseRateCount }})
          </span>
        </div>
        <div class="list-column">
          <div v-if="displayType == 'all'" class="vital-entry-list">
            <div v-for="vitalEntry in vitalEntries" :key="'vital-entry' + vitalEntry.id" >
              <horus-vital-entry-column
                :vitalEntry="vitalEntry" />
              <horus-vital-value-column
                v-for="vitalValue in vitalEntry.vitalValues" :key="'vital-value' + vitalValue.id"
                :vitalValue="vitalValue" />
              <horus-vital-blood-pressure-column v-if="vitalEntry.vitalBloodPressure"
                :vitalBloodPressure="vitalEntry.vitalBloodPressure" />
              <horus-vital-body-composition-column v-if="vitalEntry.vitalBodyComposition"
                :vitalBodyComposition="vitalEntry.vitalBodyComposition" />
              <horus-vital-active-mass-column v-if="vitalEntry.vitalActiveMass"
                :vitalActiveMass="vitalEntry.vitalActiveMass" />
              <horus-vital-body-temperature-column v-if="vitalEntry.vitalBodyTemperature"
                :vitalBodyTemperature="vitalEntry.vitalBodyTemperature" />
              <horus-vital-oxygen-saturation-column v-if="vitalEntry.vitalOxygenSaturation"
                :vitalOxygenSaturation="vitalEntry.vitalOxygenSaturation" />
              <horus-vital-pulse-rate-column v-if="vitalEntry.vitalPulseRate"
                :vitalPulseRate="vitalEntry.vitalPulseRate" />
            </div>
            <div class="load-controll" v-if="vitalEntries.length < vitalEntryCount"><button class="load-btn" @click="loadAdditionalEntry">追加読み込み</button></div>
          </div><!-- all end -->
          <div v-if="displayType == 'blood-pressure'" class="vital-entry-list">
            <div v-for="bloodPressure in bloodPressures" :key="'bp' + bloodPressure.id">
              <horus-vital-entry-column
                :vitalEntry="bloodPressure.vitalEntry" />
              <horus-vital-blood-pressure-column
                :vitalBloodPressure="bloodPressure" />
            </div>
            <div class="load-controll" v-if="bloodPressures.length < bloodPressureCount"><button class="load-btn" @click="loadAdditionalBloodPressure">追加読み込み</button></div>
          </div><!-- blood pressure end -->
          <div v-if="displayType == 'body-composition'" class="vital-entry-list">
            <div v-for="bodyComposition in bodyCompositions" :key="'bc' + bodyComposition.id">
              <horus-vital-entry-column
                :vitalEntry="bodyComposition.vitalEntry" />
              <horus-vital-body-composition-column
                :vitalBodyComposition="bodyComposition" />
            </div>
            <div class="load-controll" v-if="bodyCompositions.length < bodyCompositionCount"><button class="load-btn" @click="loadAdditionalBodyComposition">追加読み込み</button></div>
          </div><!-- body composition end -->
          <div v-if="displayType == 'active-mass'" class="vital-entry-list">
            <div v-for="activeMass in activeMasses" :key="'am' + activeMass.id">
              <horus-vital-entry-column
                :vitalEntry="activeMass.vitalEntry" />
              <horus-vital-active-mass-column
                :vitalActiveMass="activeMass" />
            </div>
            <div class="load-controll" v-if="activeMasses.length < activeMassCount"><button class="load-btn" @click="loadAdditionalActiveMass">追加読み込み</button></div>
          </div><!-- active mass end -->
          <div v-if="displayType == 'body-temperature'" class="vital-entry-list">
            <div v-for="bodyTemperature in bodyTemperatures" :key="'bt' + bodyTemperature.id">
              <horus-vital-entry-column
                :vitalEntry="bodyTemperature.vitalEntry" />
              <horus-vital-body-temperature-column
                :vitalBodyTemperature="bodyTemperature" />
            </div>
            <div class="load-controll" v-if="bodyTemperatures.length < bodyTemperatureCount"><button class="load-btn" @click="loadAdditionalBodyTemperature">追加読み込み</button></div>
          </div><!-- body temperature end -->
          <div v-if="displayType == 'oxygen-saturation'" class="vital-entry-list">
            <div v-for="oxygenSaturation in oxygenSaturations" :key="'os' + oxygenSaturation.id">
              <horus-vital-entry-column
                :vitalEntry="oxygenSaturation.vitalEntry" />
              <horus-vital-oxygen-saturation-column
                :vitalOxygenSaturation="oxygenSaturation" />
            </div>
            <div class="load-controll" v-if="oxygenSaturations.length < oxygenSaturationCount"><button class="load-btn" @click="loadAdditionalOxigenSaturation">追加読み込み</button></div>
          </div><!-- oxygen saturation end -->
          <div v-if="displayType == 'pulse-rate'" class="vital-entry-list">
            <div v-for="pulseRate in pulseRates" :key="'pr' + pulseRate.id">
              <horus-vital-entry-column
                :vitalEntry="pulseRate.vitalEntry" />
              <horus-vital-pulse-rate-column
                :vitalPulseRate="pulseRate" />
            </div>
            <div class="load-controll" v-if="pulseRates.length < pulseRateCount"><button class="load-btn" @click="loadAdditionalPulseRate">追加読み込み</button></div>
          </div><!-- pulse rate end -->
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  VitalEntry,
  VitalBloodPressure,
  VitalBodyComposition,
  VitalActiveMass,
  VitalBodyTemperature,
  VitalOxygenSaturation,
  VitalPulseRate
} from '@/models'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusVitalEntryColumn from '@/components/vitals/HorusVitalEntryColumn.vue'
import HorusVitalValueColumn from '@/components/vitals/HorusVitalValueColumn.vue'
import HorusVitalBloodPressureColumn from '@/components/vitals/HorusVitalBloodPressureColumn.vue'
import HorusVitalBodyCompositionColumn from '@/components/vitals/HorusVitalBodyCompositionColumn.vue'
import HorusVitalActiveMassColumn from '@/components/vitals/HorusVitalActiveMassColumn.vue'
import HorusVitalBodyTemperatureColumn from '@/components/vitals/HorusVitalBodyTemperatureColumn.vue'
import HorusVitalOxygenSaturationColumn from '@/components/vitals/HorusVitalOxygenSaturationColumn.vue'
import HorusVitalPulseRateColumn from '@/components/vitals/HorusVitalPulseRateColumn.vue'
// import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusVitalEntryListModal',

  components: {
    HorusModalFrame,
    HorusVitalEntryColumn,
    HorusVitalValueColumn,
    HorusVitalBloodPressureColumn,
    HorusVitalBodyCompositionColumn,
    HorusVitalActiveMassColumn,
    HorusVitalBodyTemperatureColumn,
    HorusVitalOxygenSaturationColumn,
    HorusVitalPulseRateColumn,
    // HorusFooter
  },

  props: {
    timelineOwner: Object,
    showModal: Boolean
  },

  data () {
    return {
      displayType: 'all',
      displayOffset: 0,
      vitalEntries: [],
      vitalEntryCount: 0,
      bloodPressures: [],
      bloodPressureCount: 0,
      bodyCompositions: [],
      bodyCompositionCount: 0,
      activeMasses: [],
      activeMassCount: 0,
      bodyTemperatures: [],
      bodyTemperatureCount: 0,
      oxygenSaturations: [],
      oxygenSaturationCount: 0,
      pulseRates: [],
      pulseRateCount: 0,
      limit: 100
    }
  },

  watch: {
    timelineOwner: { handler: 'loadVitals', immediate: true },
    showModal: { handler: 'loadVitals', immediate: true }
  },

  computed: {},

  methods: {
    loadVitals () {
      if (this.timelineOwner) {
        this.vitalEntries = VitalEntry.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).get()
        this.vitalEntryCount = VitalEntry.query().where('user_id', this.timelineOwner.id).count()
        this.bloodPressures = VitalBloodPressure.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).get()
        this.bloodPressureCount = VitalBloodPressure.query().where('user_id', this.timelineOwner.id).count()
        this.bodyCompositions = VitalBodyComposition.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).get()
        this.bodyCompositionCount = VitalBodyComposition.query().where('user_id', this.timelineOwner.id).count()
        this.activeMasses = VitalActiveMass.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).get()
        this.activeMassCount = VitalActiveMass.query().where('user_id', this.timelineOwner.id).count()
        this.bodyTemperatures = VitalBodyTemperature.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).get()
        this.bodyTemperatureCount = VitalBodyTemperature.query().where('user_id', this.timelineOwner.id).count()
        this.oxygenSaturations = VitalOxygenSaturation.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).get()
        this.oxygenSaturationCount = VitalOxygenSaturation.query().where('user_id', this.timelineOwner.id).count()
        this.pulseRates = VitalPulseRate.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).get()
        this.pulseRateCount = VitalPulseRate.query().where('user_id', this.timelineOwner.id).count()
      }
    },

    loadAdditionalEntry () {
      if (this.vitalEntries.length < this.vitalEntryCount) {
        console.log('gonna load entries')
        this.vitalEntries = this.vitalEntries.concat(VitalEntry.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).offset(this.vitalEntries.length).get())
      }
    },

    loadAdditionalBloodPressure () {
      if (this.bloodPressures.length < this.bloodPressureCount) {
        this.bloodPressures = this.bloodPressures.concat(VitalBloodPressure.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).offset(this.bloodPressures.length).get())
      }
    },

    loadAdditionalBodyComposition () {
      if (this.bodyCompositions.length < this.bodyCompositionCount) {
        this.bodyCompositions = this.bodyCompositions.concat(VitalBodyComposition.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).offset(this.bodyCompositions.length).get())
      }
    },

    loadAdditionalActiveMass () {
      if (this.activeMasses.length < this.activeMassCount) {
        this.activeMasses = this.activeMasses.concat(VitalActiveMass.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).offset(this.activeMasses.length).get())
      }
    },

    loadAdditionalBodyTemperature () {
      if (this.bodyTemperatures.length < this.bodyTemperatureCount) {
        this.bodyTemperatures = this.bodyTemperatures.concat(VitalBodyTemperature.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).offset(this.bodyTemperatures.length).get())
      }
    },

    loadAdditionalOxigenSaturation () {
      if (this.oxygenSaturations.length < this.oxygenSaturationCount) {
        this.oxygenSaturations = this.oxygenSaturations.concat(VitalOxygenSaturation.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).offset(this.oxygenSaturations.length).get())
      }
    },

    loadAdditionalPulseRate () {
      if (this.pulseRates.length < this.pulseRateCount) {
        this.pulseRates = this.pulseRates.concat(VitalPulseRate.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').limit(this.limit).offset(this.pulseRates.length).get())
      }
    },

    switchType (type) {
      this.displayType = type
      this.displayOffset = 0
    },

    closeModal () {
      this.$emit('closeModalEvent')
    }
  }

}
</script>

<style lang="scss" scoped>
.hours-vital-entry-list-modal {
  .modal-header {
    display: flex;
    justify-content: space-between;
    h1 {
      width: calc(100% - 60px);
    }
  }
  .modal-body {
    padding: 10px;
    max-height: calc(100% - 51px);
    .type-switches {
      display: flex;
      justify-content: space-around;
      padding: 5px;
      button {
        margin: 0 5px;
        padding: 3px 5px;
        border-radius: 3px;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        &.selected {
          background-color: #fff;
          border: 1px solid #999;
          color: #999;
        }
      }
    }
    .list-column {
      height: calc(100vh - 180px);
      overflow: auto;
      .vital-entry-list {
        border-bottom: 1px solid #ddd;
        .content-line {
          display: flex;
          .content-column {
            display: flex;
            .label {
              font-size: 0.9em;
            }
          }
        }
      }
      .load-controll {
        margin-top: 10px;
        text-align: center;
        .load-btn {
          padding: 3px 10px;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
