<template>
  <div class="horus-create-organization-request-view container">
    <div class="navbar">
      <div class="main-bar member">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="content">
      <h1 class="centering-title">ナラティブブック施設新規登録</h1>
      <horus-create-organization-request-step-bar
        :step="step" />
      <horus-create-organization-request-area-block-column
        v-if="areaBlock"
        :areaBlock="areaBlock" />
      <horus-create-organization-request-entry
        v-if="pageState === STEP.ENTRY"
        v-on:sendMailEvent="sendMail"
        />
      <horus-create-organization-request-mail-registed
        v-if="pageState === STEP.CONFIRM_MAIL_SENT"
        :mailAddress="mailAddress"
        />
      <horus-create-organization-request-mail-confirmed
        v-if="pageState === STEP.MAIL_CONFIRMED"
        :mailAddress="mailAddress"
        v-on:inputProfileEvent="inputProfiles"
        v-on:sendMailEvent="sendMail"
        />
      <horus-create-organization-request-mail-confirm-failed
        v-if="pageState === STEP.MAIL_CONFIRM_FAILED"
        v-on:backToEntryEvent="backToEntry"
        />
      <horus-create-organization-request-input-profile
        v-if="pageState === STEP.PROFILE_INPUT"
        :validateToken="token"
        v-on:showConfirmDialogEvent="showConfirmDialog"
        v-on:submitFinishEvent="submitOrganizationRequest"
        />
      <horus-create-organization-request-finish
        v-if="pageState === STEP.FINISH"
        :requestOrganization="resultRequest"
        :message="finishedMessage"
        :notifyMessage="finishedNotifyMessage"
        />
    </div><!-- content -->
    <horus-footer />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div><!-- container -->
</template>

<script>
import {
  AreaBlock
} from '@/models'
import {
  OrganizationRequest
} from '@/api'

import HorusCreateOrganizationRequestStepBar from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestStepBar.vue'
import HorusCreateOrganizationRequestAreaBlockColumn from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestAreaBlockColumn.vue'
import HorusCreateOrganizationRequestEntry from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestEntry.vue'
import HorusCreateOrganizationRequestMailRegisted from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestMailRegisted.vue'
import HorusCreateOrganizationRequestMailConfirmed from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestMailConfirmed.vue'
import HorusCreateOrganizationRequestMailConfirmFailed from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestMailConfirmFailed.vue'
import HorusCreateOrganizationRequestInputProfile from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestInputProfile.vue'
import HorusCreateOrganizationRequestFinish from '@/components/createOrganizationRequest/HorusCreateOrganizationRequestFinish.vue'

import HorusFooter from '@/components/common/HorusFooter.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import * as CREATE_STEP from '@/assets/constants/createUserStep.js'
// import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusCreateOrganizationRequestView',

  components: {
    HorusCreateOrganizationRequestStepBar,
    HorusCreateOrganizationRequestAreaBlockColumn,
    HorusCreateOrganizationRequestEntry,
    HorusCreateOrganizationRequestMailRegisted,
    HorusCreateOrganizationRequestMailConfirmed,
    HorusCreateOrganizationRequestMailConfirmFailed,
    HorusCreateOrganizationRequestInputProfile,
    HorusCreateOrganizationRequestFinish,
    HorusFooter,
    HorusConfirmDialog
  },

  porps: {
  },

  watch: {
    '$route': { handler: 'validateToken', immediate: true }
  },

  data () {
    return {
      token: '',
      STEP: CREATE_STEP,
      pageState: 0,
      step: '',
      areaBlock: null,
      mailAddress: null,
      organizationRequestID: '',
      csrfToken: null,
      createdOrganizationName: '',
      finishedMessage: '',
      finishedNotifyMessage: '',
      finishedOn: '',
      resultRequest: null
    }
  },

  computed: {
  },

  methods: {
    sendMail (mail) {
      if (mail && mail.length > 0) {
        var areaBlockID = null
        if (this.areaBlock) {
          areaBlockID = this.areaBlock.id
        }
        OrganizationRequest.preMailValidation({ mailAddress: mail, organizationRequestID: this.organizationRequestID, areaBlockID: areaBlockID }).then(() => {
          this.mailAddress = mail
          this.pageState = this.STEP.CONFIRM_MAIL_SENT
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('確認メール送信の失敗', '入力されたメールアドレスへの確認メール送信に失敗しました。')
        })
      } else {
        this.showNotifyDialog('メールアドレスの入力', '施設登録に利用するメールアドレスを入力してください。')
      }
    },

    async validateToken () {
      this.token = this.$route.query.token
      if (this.$route.query.mail) {
        this.mailAddress = this.$route.query.mail
      }
      if (this.$route.query.area_block_id) {
        await AreaBlock.api().fetch({ targetID: this.$route.query.area_block_id }).then(res => {
          this.areaBlock = res.area_block
        })
      }
      if (this.token && this.token.length > 0) {
        this.pageState = this.STEP.RECEIVE_CONFIRM_TOKEN
        this.step = this.STEP.PAGE_STEP_CONFIRM_MAIL
        await OrganizationRequest.confirmMail({ validationToken: this.token }).then(async res => {
          console.log(res)
          this.organizationRequestID = res.organization_request.organization_request_id
          this.mailAddress = res.organization_request.mail
          if (res.organization_request.area_block_id) {
            await AreaBlock.api().fetch({ targetID: res.organization_request.area_block_id }).then(res => {
              this.areaBlock = res.area_block
            })
          }
          this.pageState = this.STEP.MAIL_CONFIRMED
          this.step = this.STEP.PAGE_STEP_CONFIRM_MAIL
        }).catch(error => {
          console.log(error.response)
          if (error.response.status === 400) {
            this.pageState = this.STEP.MAIL_CONFIRM_FAILED
          }
        })
      } else {
        this.pageState = this.STEP.ENTRY
        this.step = this.STEP.PAGE_STEP_CHECK_MAIL
      }
    },

    backToEntry () {
      this.mailAddress = ''
      this.token = null
      this.organizationRequestID = ''
      this.pageState = this.STEP.ENTRY
      this.step = this.STEP.PAGE_STEP_CHECK_MAIL
    },

    inputProfiles () {
      this.pageState = this.STEP.PROFILE_INPUT
      this.step = this.STEP.PAGE_STEP_INPUT_PROFILE
    },

    submitOrganizationRequest (resultObjct) {
      // 結果を表示する。
      this.resultRequest = resultObjct
      this.pageState = this.STEP.FINISH
      this.step = this.STEP.PAGE_STEP_FINISH
    },

    finishNarrativeBook () {
      // signout
      this.$router.push('/signout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-create-organization-request-view {
  height: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  .content {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.1em;
      font-weight: normal;
    }
  }
}
</style>
