<template>
  <div class="horus-submit-system-manager-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="content">
      <h1 class="centering-title">ナラティブブックシステム管理ユーザー登録案内</h1>
      <div class="taken-not-valid accept-form" v-if="!isTokenValid">
        <h2>システム管理ユーザー登録案内が無効です</h2>
        <div class="caption">
          メールのリンクURLが正しくアドレスバーに表示されているかをご確認ください。<br/>
          リンクURLが正しく入力されているにも関わらず、この画面が見えている場合は、案内が無効化されています。<br/>
          ナラティブブックのシステム管理者にお問合せください。<br/>
        </div>
      </div>
      <div v-if="isTokenValid">
        <div class="pre-process" v-if="!validationFinished" >
          <div class="message" v-if="invitation">
            システム管理ユーザー登録案内です。<br/>
            下記の手順に従って登録手続きを行ってください。<br/>
            <span>利用期限: {{ limitAtStr }}</span><br/>
            <span>メールアドレス: {{ invitation.mail }}</span>
          </div>
          <div class="form-list" v-if="invitation">
            <horus-attribute-in-form
              :labelText="'ログインID'"
              :necessary="true"
              :validationState="signinIDValidation.state"
              :validationMessage="signinIDValidation.message"
              :attributeNotify="'６文字以上 半角英小文字、半角数字、一部の記号@._/-+() （使用できる記号は、@._/-+()の８種類のみです。）'">
              <input type="text" v-model="signinID" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'パスワード'"
              :necessary="true"
              :validationState="passwordValidation.state"
              :validationMessage="passwordValidation.message"
              :attributeNotify="'8文字以上'">
              <input :type="passwordInputType" v-model="password" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'パスワード(確認)'"
              :necessary="true"
              :validationState="passwordConfValidation.state"
              :validationMessage="passwordConfValidation.message"
              :attributeNotify="'上と同じパスワードを入力'">
              <input :type="passwordInputType" v-model="passwordConfirmation" />
            </horus-attribute-in-form>
            <div class="toggle-password-visible" @click="togglePasswordVisible">
              <horus-custom-check-box
                :active="passwordVisible"
                :enabled="true"
                :type="'color'"
                />パスワードを表示
            </div>
            <horus-attribute-in-form
              :labelText="'名前'"
              :necessary="true"
              :validationState="nameValidation.state"
              :validationMessage="nameValidation.message"
              :attributeNotify="''">
              <input type="text" v-model="name" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'メールアドレス'"
              :necessary="true"
              :validationState="'valid'"
              :validationMessage="''"
              :attributeNotify="'案内に登録されたメールアドレスから変更は行えません。変更は登録後に行えます。'">
              <input type="text" disabled v-model="invitation.mail" />
            </horus-attribute-in-form>
            <div class="notify_for_admit">
              <h1>システム管理ユーザー登録に関する確認事項</h1>
              <ul>
                <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                <li>大いなる力には大いなる責任が伴うことに同意していること</li>
              </ul>
            </div>
            <div class="admit-agreement" @click="toggleAdmit">
              <horus-custom-check-box
                :active="admitAgreement"
                :enabled="true"
                :type="'color'"
                />
              上記確認事項を確認しました
            </div>
            <div class="submit-controll"><button type="submit" class="submit-btn" :class="{enable: readyToSubmit}" @click="submitInvitation">登録</button></div>
          </div>
        </div><!-- pre process -->
        <div class="processed" v-if="validationFinished">
          <h2>登録処理完了</h2>
          <div class="message">
            システム管理ユーザー登録処理を完了しました。<br/>
            以後、あなたはシステム管理ユーザーとなります。<br/>
          </div>
          <div class="finish-controlls">
            <button type="button" class="start-btn" @click="doSignin">ログインして続行</button>
            <button type="button" class="finish-btn" @click="backToLanding">ログインせずに終了</button>
          </div>
        </div>
      </div>
    </div><!-- content -->
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { SystemManager } from '@/models'
import { SystemManagerInvitationToken } from '@/api'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSubmitSystemManagerView',

  components: {
    HorusAttributeInForm,
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      invitation: null,
      isTokenValid: true,
      name: '',
      signinID: '',
      password: '',
      passwordConfirmation: '',
      admitAgreement: false,
      passwordVisible: false,
      validationFinished: false,
      signinIDValidation: { state: 'none', valid: false, message: '' },
      nameValidation: { state: 'none', valid: false, message: '' }
    }
  },

  computed: {
    limitAtStr () {
      if (this.invitation && this.invitation.limit_at) {
        var limitAt = new Date(this.invitation.limit_at)
        return moment(limitAt).format('YYYY年MM月DD日 HH時mm分')
      } else {
        return '--------'
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    passwordValidation () {
      if (this.password.trim().match(/^([A-Za-z0-9]|@|\.|_|\/|-|\+|\(|\)){6,120}$/g)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'パスワードは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。' }
      }
    },

    passwordConfValidation () {
      if (this.password.length <= 0) {
        return { state: 'none', valid: false, message: '' }
      } else {
        if (this.password === this.passwordConfirmation) {
          return { state: 'valid', valid: true, message: '' }
        } else {
          return { state: 'invalid', valid: false, message: 'パスワードとパスワード（確認）の値が異なっています。' }
        }
      }
    },

    readyToSubmit () {
      if (
        this.signinIDValidation.valid &&
        this.passwordValidation.valid &&
        this.passwordConfValidation.valid &&
        this.nameValidation.valid &&
        this.admitAgreement
      ) {
        return true
      }
      return false
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true },
    signinID: { handler: 'validateSigninID' },
    name: { handler: 'validateName' }
  },

  methods: {
    initToken () {
      this.token = this.$attrs.token
      SystemManagerInvitationToken.validateToken({ token: this.token }).then(res => {
        this.invitation = res.system_manager_invitation_token
        this.isTokenValid = true
      }).catch(error => {
        console.error(error.response)
        if (error.response.status === 400) {
          this.isTokenValid = false
        }
      })
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    validateSigninID () {
      if (this.signinID.trim().match(/^([A-Za-z0-9]|@|\.|_|\/|-|\+|\(|\)){6,120}$/g)) {
        SystemManager.api().signinIDValidation({ signinID: this.signinID }).then(res => {
          if (res.valid) {
            this.signinIDValidation = { state: 'valid', valid: true, message: '' }
          } else {
            if (res.message === 'already use') {
              this.signinIDValidation = { state: 'invalid', valid: false, message: '同一のログインIDが使用されています。違う値を入力してください。' }
            } else if (res.message === 'invalid format') {
              this.signinIDValidation = { state: 'invalid', valid: false, message: 'ログインIDは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。' }
            }
          }
        }).catch(err => {
          console.log(err)
          this.showNotifyDialog('チェック失敗', 'ログインIDの検証問い合わせに失敗しました')
        })
      } else {
        this.signinIDValidation = { state: 'invalid', valid: false, message: 'ログインIDは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。' }
      }
    },

    validateName () {
      if (this.name.length > 0) {
        SystemManager.api().nameValidation({ name: this.name }).then(res => {
          if (res.valid) {
            this.nameValidation = { state: 'valid', valid: true, message: '' }
          } else {
            this.nameValidation = { state: 'invalid', valid: false, message: '入力された名前が使用されています。違う値を入力してください。' }
          }
        }).catch(err => {
          console.log(err)
          this.showNotifyDialog('チェック失敗', '名前の検証問い合わせに失敗しました')
        })
      } else {
        this.nameValidation = { state: 'invalid', valid: false, message: '名前は入力必須です。' }
      }
    },

    submitInvitation () {
      if (this.readyToSubmit) {
        var formData = new FormData()
        formData.append('system_manager[name]', this.name)
        formData.append('system_manager_authenticate_info[authenticate_id]', this.signinID)
        formData.append('system_manager_authenticate_info[password]', this.password)
        formData.append('system_manager_authenticate_info[password_confirmation]', this.passwordConfirmation)
        formData.append('token', this.token)
        SystemManager.api().create({ formData: formData }).then(() => {
          this.validationFinished = true
        }).catch(err => {
          console.error(err)
          this.showNotifyDialog('システム管理ユーザー登録失敗', '新規システム管理ユーザーの登録に失敗しました')
        })
      }
    },

    doSignin () {
      this.$router.push({ path: '/system_management/home' })
    },

    backToLanding () {
      this.$router.push({ path: '/signout' })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-submit-system-manager-view {
  height: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  .navbar .main-bar {
    background-color: #039;
    background-image: url("/static/images/system_header.png");
  }
  .content {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px 50px;
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.1em;
      font-weight: normal;
    }
    .message {
      margin: 10px auto;
      padding: 10px 20px;
      width: 500px;
      border: 1px solid #ddd;
      background-color: #fff;
      text-align: center;
    }
    .list-data {
      background-color: #fff;
      .list-data-line {
        display: flex;
        padding: 3px 0;
        border-bottom: 1px solid #aaa;
        .labels {
          margin-right: 10px;
          width: 150px;
          text-align: right;
        }
        .content {
          padding: 0;
          background-color: inherit;
        }
        img {
          margin-left: 20px;
          height: 20px;
        }
      }
    }
    .form-list {
      margin: 10px 0;
      padding: 10px 20px;
      border: 1px solid #ddd;
      background-color: #fff;
      input {
        font-size: 16px;
        border: 1px solid #aaa;
      }
    }
    .submit-controll {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button.submit-btn {
        // height: 30px;
        padding: 5px;
        width: 100px;
        border-radius: 3px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: #aaa;
        &.enable {
          background-color: #4cae4c;
        }
      }
    }
    .finish-controlls {
      display: flex;
      justify-content: center;
      button {
        margin: 10px;
        padding: 5px 10px;
        // width: 100px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        &.start-btn {
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
        }
        &.finish-btn {
          border: 1px solid #ee7800;
          background-color: #ee7800;
        }
      }
    }
  }
}
</style>
