<template>
  <div class="horus-user-task-column">
    <div class="task-header">
      <div class="header-info" @click="showDetail">
        <div class="target-date" :class="{past: targetAtPast}">{{ targetDateStr }}</div>
        <div class="target-info" v-if="taskTimelineAuthor">
          <img class="task-timeline-portrate" :src="taskTimelineAuthor.portrateSrc"/>
          <span class="timeline-owner-name">{{ taskTimelineAuthor.name }}</span>
        </div>
        <div class="target-info" v-if="taskOrganization">
          <img src="/static/images/org_member.png"/>
          <span class="timeline-owner-name">{{ taskOrganization.shortName }}</span>
        </div>
      </div>
      <div class="task-controll" v-if="taskEditable">
        <span class="edit-btn" @click="editTask"><img src="/static/images/pencil_icon.png" /></span>
        <span class="delete-btn" @click="deleteTask"><img src="/static/images/bucket_icon.png" /></span>
      </div>
    </div>
    <div class="task-content">
      <div class="video-meeting" v-if="meetingExist">
        <img src="/static/images/video_meeting_s_green_icon.png" class="video-meeting-icon">
      </div>
      <div class="text-content" v-if="userTask.textContent" v-html="textContentHtml"></div>
      <div class="attach-content">
        <horus-inline-photo
          v-for="taskPhoto in userTask.userTaskPhotos"
          :key="'taskPhotoThumb' + taskPhoto.id"
          :photoObject="taskPhoto"
          :sizeType="'small'"
          :photoMaxSize="70"
          v-on:timeliePhotoClickEvent="showPhoto(taskPhoto)" />
        <horus-inline-file
          v-for="taskFile in userTask.userTaskFiles"
          :key="'taskFileIcon' + taskFile.id"
          :fileObject="taskFile"
          :sizeType="'small'"
          v-on:timelineFileClickEvent="openFile" />
      </div>
      <div class="hash-tags" v-if="userTask.hashTags.length > 0">
        <div class="user-task-hashtag"  v-for="hashTag in userTask.hashTags" :key="'taskTag' + hashTag.id">
          <horus-hash-tag-display :hashTag="hashTag" :sizeType="'small'"/>
        </div>
      </div>
      <div class="notify-targets" v-if="userTask.notifyTargets.length > 0">
        <div class="notify-author"
          v-for="author in userTask.notifyTargets"
          :key="'notifyTarget' + author.id">
          <img :src="author.portrateSrc" class="portrate" />
          <div class="author-name">{{ author.name }}</div>
        </div>
      </div>
    </div>
    <div class="task-footer" v-if="userTask.author">
      <span class="footer-label">作成:</span>
      <img :src="userTask.author.portrateSrc" />
      <span class="author-name">{{ userTask.author.name }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

import * as TASK_MEETING_TYPE from '@/assets/constants/taskMeetingType.js'

export default {
  name: 'HorusUserTaskColumn',

  components: {
    HorusInlinePhoto,
    HorusInlineFile,
    HorusHashTagDisplay
  },

  props: {
    // timelineOwner: Object,
    signinUser: Object,
    signinMember: Object,
    userTask: Object
  },

  computed: {
    targetDateStr () {
      return moment(this.userTask.targetAt).format('MM月DD日 HH:mm')
    },

    targetAtPast () {
      if (this.userTask.targetAt < new Date()) {
        return true
      } else {
        return false
      }
    },

    meetingExist () {
      if (this.userTask.meetingType > 0) {
        return true
      }
      return false
    },

    textContentHtml () {
      if (this.userTask && this.userTask.textContent) {
        var contentText = this.userTask.textContent.replace(TASK_MEETING_TYPE.MEETING_LINK_REPLACE_TEXT, this.userTask.linkURL)
        return contentText.replace(/\n/gi, '<br />')
      }
      return ''
    },

    taskTimelineAuthor () {
      return this.userTask.timelineAuthor
    },

    taskOrganization () {
      return this.userTask.organization
    },

    taskEditable () {
      if (!this.targetAtPast && ((this.signinUser && this.userTask.author_id === this.signinUser.author_id) || (this.signinMember && this.userTask.author_id === this.signinMember.author_id))) {
        return true
      }
      return false
    }
  },

  methods: {
    editTask () {
      this.$emit('editTaskEvent', this.userTask)
    },

    deleteTask () {
      this.$emit('deleteTaskEvent', this.userTask)
    },

    showDetail () {
      this.$emit('showDetailEvent', this.userTask)
    },

    showPhoto (taskPhoto) {
      this.$emit('showTaskPhotoEvent', this.userTask, this.userTask.userTaskPhotos.indexOf(taskPhoto))
    },

    // openFile (taskFile) {
    //   // this.$emit('openFile', taskFile)
    // }
  }

}
</script>

<style lang="scss" scoped>
.horus-user-task-column {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #aaa;
  .task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #aaa;
    .header-info {
      cursor: pointer;
      display: flex;
      .target-date {
        margin-right: 10px;
        // min-width: 110px;
        font-size: 1.1em;
        font-weight: bold;
        &.past {
          color: #9e3d3f;
        }
      }
      .target-info {
        display: flex;
        justify-content: flex-start;
        // width: calc(100% - 60px);
        .task-timeline-portrate {
          width: 20px;
          height: 20px;
        }
        .timeline-owner-name {
          margin: 0 5px;
        }
      }
    }
    .task-controll {
      width: 60px;
      .edit-btn {
        margin-right: 8px;
        img {
          height: 24px;
          width: 24px;
        }
      }
      .delete-btn{
        img {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  .task-content {
    .video-meeting {
      margin-top: 5px;
      margin-bottom: -5px;
      .video-meeting-icon {
        width: 20px;
        height: 20px;
      }
    }
    .text-content {
      margin: 5px 0;
      font-size: 16px;
    }
    .attach-content {
      margin-bottom: 5px;
      align-items: flex-end;
      flex-wrap: wrap;
      display: flex;
    }
    .hash-tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
    }
    .notify-targets {
      .notify-author {
        display: inline-block;
        margin-right: 5px;
        font-size: 0.8em;
        cursor: pointer;
        .portrate {
          display: inline-block;
          margin-right: -3px;
          width: 12px;
          height: 12px;
        }
        .author-name {
          display: inline-block;
        }
        .close-icon {
          display: inline-block;
          margin-left: -3px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .task-footer {
    padding-top: 5px;
    border-top: 1px solid #aaa;
    .footer-label {
      font-size: 0.9em;
    }
    img {
      display: inline-block;
      width: 12px;
      height: 12px;
    }
    .author-name {
      font-size: 0.9em;
    }
  }
}
</style>
