// var timelineOwner MUST imprement.
import {
  Author,
  Family,
  Organization,
  StaffVisibility,
  HashTag,
  BoadComment,
  Wish
} from '@/models'

export default {

  data () {
    return {
      authors: [],
      families: [],
      organizations: [],
      organizationStaffVisibilities: [],
      hashTags: [],
      wishes: [],
      infos: [],
      authorsNowLoading: false,
      familiesNowLoading: false,
      organizationsNowLoading: false,
      hashTagNowLoading: false,
      wishesNowLoading: false,
      infosNowLoading: false
    }
  },

  methods: {
    // chanc of loaders
    // タイムラインオーナーに関連付いているユーザー、施設職員をauthorとして読み込む
    async loadAuthors () {
      this.authorsNowLoading = true
      await Author.api().fetchIDList({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
        var unloadAuthors = []
        for(var authorID of res.authors) {
          if (!Author.find(authorID)) {
            if (unloadAuthors.indexOf(authorID) < 0) unloadAuthors.push(authorID)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadAuthors })
        }
        this.authors = await Author.findIn(res.authors)
        this.authorsNowLoading = false
        return this.authors
      })
    },

    // 家族関連を読み込む
    async loadFamilies () {
      this.familiesNowLoading = true
      this.families = []
      await Family.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      this.families = await Family.query().where('user_id', this.timelineOwner.id).get()
      this.familiesNowLoading = false
    },

    // かかりつけ設定の施設を読み込む
    // かかりつけ関連のある施設のIDリストを取得し、storeに存在しない場合はサーバーから読み込んでstoreに格納、リストに従ってthis.organizationsに格納する
    // ※storeに格納済の施設情報は更新されない
    async loadOrganizations () {
      this.organizationsNowLoading = true
      await Organization.api().fetchRelatedIDList({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
        var unStoredOrgs = []
        for(var orgID of res.organizations) {
          if (!Organization.find(orgID)) {
            if (unStoredOrgs.indexOf(orgID) < 0) unStoredOrgs.push(orgID)
          }
        }
        if (unStoredOrgs.length > 0) {
          await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unStoredOrgs })
        }
        this.organizations = await Organization.findIn(res.organizations)
      })
      await StaffVisibility.api().listForUser({ targetUserID: this.timelineOwner.id })
      this.organizationsNowLoading = false
      this.organizationStaffVisibilities = this.timelineOwner.organizationStaffVisibilities
    },

    // 更新のあった施設をサーバーから読み出し、storeに格納し直す
    // TODO: 削除に対応する
    async updateOrganization (organizationIDList) {
      await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: organizationIDList })
      await this.loadOrganizations()
      await StaffVisibility.api().listForUser({ targetUserID: this.timelineOwner.id })
      this.organizationStaffVisibilities = this.timelineOwner.organizationStaffVisibilities
    },

    // サーバーからタイムラインオーナーに関連付いたハッシュタグを取得し、storeに格納(更新)し、this.hashTagsに格納する
    async loadHashTags () {
      this.hashTagNowLoading = true
      await HashTag.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      this.hashTags = await HashTag.query().where('user_id', this.timelineOwner.id).get()
      this.narrowDownTags = [] // !!!
      this.hashTagNowLoading = false
    },

    // タイムラインオーナーに関連付いたwishを取得し、storeに格納(更新)し、this.wishesに格納する
    async loadWishes () {
      this.wishesNowLoading = true
      await Wish.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      this.wishes = await Wish.query().where('user_id', this.timelineOwner.id).where('position', value => value > 0).orderBy('position', 'asc').get()
      this.wishesNowLoading = false
    },

    // タイムラインオーナーに関連付いたinfo(boadComment)を取得し、storeに格納(更新)し、this.infosに格納する
    async loadInfos () {
      this.infosNowLoading = true
      await BoadComment.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      this.infos = await BoadComment.query().where('user_id', this.timelineOwner.id).where('position', value => value > 0).orderBy('position', 'asc').get()
      this.infosNowLoading = false
    }
  }
}
