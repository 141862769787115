<template>
  <div class="horus-deputized-user-in-manage-list labeled_content_block">
    <div class="labeled_content_single_block">
      <label>設定代行開始日</label>
      <div class="content">{{ deputizeRelationAssignedAt }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>利用者名(NBID)</label>
      <div class="content">
        <img :src="deputizeRelation.author.portrateSrc" class="portrate" />
        {{ deputizeRelation.author.name }}({{ deputizeRelation.nbid }})
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>生年月日(年齢):性別</label>
      <div class="content">{{ deputizeRelation.author.birthDateJp }}({{ deputizeRelation.author.age }}):{{ deputizeRelation.author.sexTypeJp }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ deputizeRelation.author.address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ deputizeRelation.author.tel }}</div>
    </div>
    <div class="small-caption" v-if="!deputizeRelation.removable">
      この利用者は利用停止出来ません。利用停止するには、自らログイン出来るように設定するか、他の代理者もしくは設定代行施設を登録する必要があります。
    </div>
    <div class="content-controll-block">
      <button class="remove-user-btn" :class="{disable: !deputizeRelation.removable}" @click="removeRelation">利用停止</button>
      <div class="deputize-controlls">
        <button class="show-log-btn" @click="showLog">設定代行ログ</button>
        <button class="show-log-btn" @click="moveToProfile">プロフィール編集</button>
        <button class="show-log-btn" @click="moveToServiceRelation">かかりつけ設定</button>
        <button class="show-log-btn" @click="moveToFamily">家族設定</button>
        <button class="show-log-btn" @click="moveToAgentConfig">設定代行</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusDeputizedUserColumnForAgent',

  props: {
    deputizeRelation: Object
  },

  computed: {
    deputizeRelationAssignedAt () {
      if (this.deputizeRelation.created_at) {
        return moment(this.deputizeRelation.created_at).format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    }
  },

  methods: {
    removeRelation () {
      this.$emit('removeDeputizeEvent', this.deputizeRelation)
    },

    showLog () {
      this.$emit('showAgentLogEvent', this.deputizeRelation)
    },

    moveToProfile () {
      this.$router.push({ path: '/profile/' + this.deputizeRelation.user_id, query: { deputize: true } })
    },

    moveToServiceRelation () {
      this.$router.push({ path: '/related_organization/' + this.deputizeRelation.user_id, query: { deputize: true } })
    },

    moveToFamily () {
      this.$router.push({ path: '/family/' + this.deputizeRelation.user_id, query: { deputize: true } })
    },

    moveToAgentConfig () {
      this.$router.push({ path: '/agent_config/' + this.deputizeRelation.user_id, query: { deputize: true } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';

.horus-deputized-user-in-manage-list {
  button {
    cursor: pointer;
    margin: 3px 10px;
    padding: 3px 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-weight: bold;
  }
  .small-caption {
    font-size: 11px;
    color: #6c272d;
  }
  .content-controll-block {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .remove-user-btn {
    width: 90px;
    &.disable {
      border-color: #666;
      background-color: #ddd;
      color: #999;
    }
  }
}
</style>
