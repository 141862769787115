import { Model } from '@vuex-orm/core'
import Wish from './Wish'

export default class WishPhoto extends Model {
  static entity = 'wishPhotos'

  static fields () {
    return {
      id: this.attr(null),
      wish_id: this.attr(null),
      filename: this.string(''),
      size: this.number(0),
      imageWidth: this.number(0),
      imageHeight: this.number(0)
    }
  }

  // static afterLimit (entries) {
  //   entries.forEach(entry => {
  //     entry.photoSrc = require('@/assets/images/infoContentPhotos/' + entry.filename)
  //   })
  //   return entries
  // }

  get photoSrc () {
    const wish = Wish.find(this.wish_id)
    return process.env.VUE_APP_BASE_URL + '/wish/show_photo?target_user_id=' + wish.user_id + '&wish_photo_id=' + this.id
  }

  get photoThumbnailSrc () {
    const wish = Wish.find(this.wish_id)
    return process.env.VUE_APP_BASE_URL + '/wish/show_photo?target_user_id=' + wish.user_id + '&wish_photo_id=' + this.id + '&version=thumb'
  }

  get photoMiddleSrc () {
    const wish = Wish.find(this.wish_id)
    return process.env.VUE_APP_BASE_URL + '/wish/show_photo?target_user_id=' + wish.user_id + '&wish_photo_id=' + this.id + '&version=middle'
  }
}
