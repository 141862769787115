<template>
  <div class="horus-record-in-modal">
    <div class="record-header">
      <horus-record-head-info
        :author="record.author"
        :targetAt="record.targetAt"
        :timelineOwner="timelineOwner"
        class="small" />
      <div class="jump-button">
        <img src="/static/images/jump_icon.png" @click="jumpToOrigin"/>
      </div>
    </div>
    <horus-record-body
      :timelineRecord="record"
      v-on:durgEntryPhotoClickEvent="showDrugEntryPhoto"
      v-on:timeliePhotoClickEvent="showPhoto"
      v-on:timelineFileClickEvent="openFile" />
    <div class="record-footer">
      <div class="record-hashtag"  v-for="hashTag in record.hashTags" :key="hashTag.id">
        <horus-hash-tag-display :hashTag="hashTag" :sizeType="'small'"/>
      </div>
    </div>
  </div>
</template>

<script>
import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
import HorusRecordBody from '@/components/timelineRecord/HorusRecordBody.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusRecordInModal',

  components: {
    HorusRecordHeadInfo,
    HorusRecordBody,
    HorusHashTagDisplay
  },

  props: {
    timelineOwner: Object,
    record: Object
  },

  methods: {
    jumpToOrigin () {
      console.log('jump to origin, recordInModal')
      this.$emit('jumpToOriginEvent', this.record)
    },

    showPhoto (record, firstShowIndex) {
      this.$emit('timeliePhotoClickEvent', record, firstShowIndex)
    },

    showDrugEntryPhoto (record, firstShowIndex) {
      this.$emit('durgEntryPhotoClickEvent', record, firstShowIndex)
    },

    openFile (file) {
      this.$emit('openRecordFileEvent', file)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-record-in-modal {
  border: 1px solid #aaa;
  background-color: #fff;
  .record-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    .jump-button {
      cursor: pointer;
    }
  }
  .record-footer {
    display: flex;
    flex-wrap: nowrap;
    padding: 5px;
    border-top: 1px solid #ddd;
  }
}
</style>
