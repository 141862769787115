<template>
  <div class="horus-deputize-organization-column labeled_content_block">
    <div class="labeled_content_single_block">
      <label>施設名</label>
      <div class="content">{{ organization.name }}({{ organization.shortName }})</div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ organization.address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話</label>
      <div class="content">{{ organization.tel }}</div>
    </div>
    <div class="content-controll-block">
      <div class="content">
        <button class="remove-organization-btn" @click="removeOrganization">設定代行停止</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusDeputizeOrganizationColumn',

  props: {
    organization: Object
  },

  methods: {
    removeOrganization () {
      this.$emit('removeDeputizeOrganizationEvent', this.organization)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
