import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import moment from 'moment'

import { ZoomMeeting } from '@/models'

export default {
  data () {
    return {
      showMeetingFormModal: false,
      meetingMainAttendee: null,
      meetingAttendees: [],
      meetingAllFamilyAttend: { value: false },
      meetingSelectedOrganizationForAttend: [],
      meetingTargetTime: { date: null }
    }
  },

  methods: {
    createMeeting (targetAuthor) {
      if (this.meetingAvailable) {
        console.log('createMeeting: meetingAvailable call, ')
        // console.log('on timeline, create meeting received.' + targetAuthor)
        if (this.zoomUser) {
          console.log('createMeeting: zoomUser exist...')
          this.meetingFormInit(targetAuthor)
          this.showMeetingFormModal = true
        } else {
          this.showConfirmDialog(
            'テレビ会議を作成するには、zoomアカウントの連携が必要です。',
            'テレビ会議を作成するには、zoomアカウントの連携が必要です。\nプロフィールページで、zoomアカウントの設定を行ってください。\nプロフィールページに移動しますか?',
            DIALOG_TYPE.YES_NO,
            () => {
              this.$router.push('/profile')
            }
          )
        }
      }
    },

    meetingFormInit (mainAttendee) {
      if (this.meetingAvailable) {
        this.meetingMainAttendee = mainAttendee
        this.meetingAttendees = [mainAttendee]
        this.meetingAllFamilyAttend.value = false
        this.meetingSelectedOrganizationForAttend = []
        this.meetingTargetTime = { date: null }
        this.selectLockAuthor = [mainAttendee, this.loggedInAuthor]
      }
    },

    addMeetingAttendee () {
      if (this.meetingAvailable) {
        this.selectTargetForAuthor = this.meetingAttendees
        this.allFamilySelectForAuthor = this.meetingAllFamilyAttend
        this.selectOrganizationTargetForAuthor = this.meetingSelectedOrganizationForAttend
        this.pushTargetSelectModalOpen()
      }
    },

    removeMeetingAttendee (attendee) {
      if (this.meetingAvailable) {
        var authorIndex = this.meetingAttendees.indexOf(attendee)
        if (authorIndex >= 0) {
          this.meetingAttendees.splice(authorIndex, 1)
          this.updatePushTargetGroupState(attendee)
        }
      }
    },

    setMeetingTargetTime () {
      if (this.meetingAvailable) {
        this.reserveFutureDateSelection(this.meetingTargetTime, null, null)
        this.futureDateSelectionOpen()
        // this.futureTargetDate = this.meetingTargetTime
        // this.futureDateSelectionModalShow = true
      }
    },

    removeMeetingTargetTime () {
      if (this.meetingAvailable) {
        this.meetingTargetTime.date = null
      }
    },

    async submitMeeting (formData) {
      if (this.meetingAvailable) {
        console.log('zoom meeting request starting....')
        this.showSendingModal = true
        formData.append('zoom_meeting[timeline_owner_id]', this.timelineOwner.id)
        ZoomMeeting.api().create({ formData: formData }).then(res => {
          console.log(res)
          this.showSendingModal = false
          if (res.success) {
            this.taskShouldUpdate = true
            this.clearMeetingModalFormElements()
            this.showMeetingFormModal = false
            var meeting = res.meeting
            var startAt = new Date(meeting.start_time)
            var now = new Date()
            if (startAt.getTime() <= now.getTime()) {
              this.showConfirmDialog(
                'ビデオ会議を開始しました',
                'zoomでビデオ会議を開始しました。\nzoomのページ移動してミーティングに参加しますか？',
                DIALOG_TYPE.YES_NO,
                () => {
                  // fire event for app.
                  window.open(meeting.start_url)
                  if (this.$native.status) {
                    this.$native.event('openZOOMMeeting', { url: meeting.start_url, user_state: 'owner' })
                  } else {
                    window.open(meeting.start_url)
                  }
                }
              )
            } else {
              this.showConfirmDialog(
                'ビデオ会議を予約しました',
                'zoomでビデオ会議を予約しました。\n開始日時は' + moment(startAt).format('YYYY/MM/DD HH:mm') + 'です。\n参加用のURLはタスクで確認ください。',
                DIALOG_TYPE.CONFIRM,
                () => {}
              )
            }
          } else {
            if (res.shouldAuthorize) {
              this.showNotifyDialog('ミーティングの新規作成失敗', '予定の新規作成に失敗しました。トークンの認証に失敗しました。')
            } else if (res.accountNotMatch) {
              this.showNotifyDialog('ミーティングの新規作成失敗', '予定の新規作成に失敗しました。アカウントがマッチしません。' + res.message)
            }
          }
        }).catch(error => {
          console.error(error)
          console.error(error.response)
          this.showSendingModal = false
          this.showNotifyDialog('ミーティングの新規作成失敗', '予定の新規作成に失敗しました。')
        })
      }
    },

    cancelMeeting () {
      if (this.meetingAvailable) {
        this.showConfirmDialog(
          'テレビ会議の予約をキャンセルします',
          'テレビ会議の予約をキャンセルします。\n入力された内容は破棄されます。\nよろしいですか?',
          DIALOG_TYPE.YES_NO,
          () => {
            this.showMeetingFormModal = false
            this.clearMeetingModalFormElements()
          }
        )
      }
    },

    clearMeetingModalFormElements () {
      if (this.meetingAvailable) {
        this.meetingMainAttendee = null
        this.meetingAttendees = []
        this.meetingAllFamilyAttend.value = false
        this.meetingSelectedOrganizationForAttend = []
        this.meetingTargetTime = { date: null }
        this.futureTargetDate = { date: null }
        this.selectLockAuthor = []
      }
    }

  }
}
