<template>
  <div class="horus-tag-manage-modal-wrapper" v-show="showTagManageModal">
    <div class="backdrop" @click="closeModal"></div>
    <div class="modal">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/tag_icon_green.png" />タグ管理
        </div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="new-tag-column">
          <input type="text" class="text-field" placeholder="追加するタグの名前を入力してください" v-model="newTagName" />
          <button class="create-tag-btn" @click="createTag">新規作成</button>
        </div>
        <div class="hash-tag-list">
          <draggable v-model="hashTags" draggable=".horus-tag-manage-item" handle=".drug-handle" @end="onDrugEnd" item-key="id">
            <horus-tag-manage-item
              v-for="hashTag in hashTags"
              :key="'tagManageModalItem' + hashTag.id"
              :hashTag="hashTag"
              v-on:updateTagEvent="updateTag"
              v-on:deleteTagEvent="deleteTag" />
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import { HashTag } from '@/models'

import HorusTagManageItem from '@/components/leftColumn/HorusTagManageItem.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  name: 'HorusTagManageModal',

  components: {
    draggable,
    HorusTagManageItem
  },

  props: {
    timelineOwner: Object,
    // hashTags: Array,
    showTagManageModal: Boolean
  },

  data () {
    return {
      hashTags: [],
      newTagName: ''
    }
  },

  watch: {
    showTagManageModal: { handler: 'loadHashTags', immediate: true }
  },

  methods: {
    async loadHashTags () {
      if (this.timelineOwner && this.showTagManageModal) {
        await HashTag.api().fetch({ timelineOwnerID: this.timelineOwner.id }).then(() => {
        // await this.$store.dispatch('fetchHashTags', { timelineOwnerID: this.timelineOwner.id }).then(() => {
          this.hashTags = HashTag.query().where('user_id', this.timelineOwner.id).get()
        })
      }
    },

    closeModal () {
      this.$emit('closeModalEvent')
    },

    hashTagNameValid (tagName) {
      var valid = true
      var failMessage = ''
      // タグ名チェック
      if (HashTag.query().where('user_id', this.timelineOwner.id).where('tagName', tagName).count() > 0) {
        valid = false
        failMessage = '同名のタグがすでに存在します。別のタグ名をつけてください。'
      }
      // 空白チェック
      if (tagName.trim().length <= 0) {
        valid = false
        failMessage = 'タグ名は空白に出来ません。'
      }
      // 文字列長
      if (tagName.trim().length > 255) {
        valid = false
        failMessage = 'タグ名が長すぎます。'
      }
      // 不正文字(##)が含まれているか
      if (tagName.match(/.+##.+/g)) {
        valid = false
        failMessage = 'タグ名に不正な文字列が存在します。'
      }
      return { valid: valid, message: failMessage }
    },

    createTag () {
      var newTagName = this.newTagName.trim()
      var validCheck = this.hashTagNameValid(newTagName)
      if (!validCheck.valid) {
        this.$emit('showNotifyEvent', 'タグの新規作成', validCheck.message)
      } else {
        HashTag.api().create({ timelineOwnerID: this.timelineOwner.id, tagName: newTagName }).then(() => {
        // this.$store.dispatch('createHashTag', { timelineOwnerID: this.timelineOwner.id, tagName: newTagName }).then(res => {
          this.newTagName = ''
          this.loadHashTags()
        }).catch(error => {
          if (error.response.status === 400) {
            this.$emit('showNotifyEvent', 'タグの新規作成失敗', 'タグの新規作成に失敗しました。\n' + error.response.data.message)
          }
        })
      }
    },

    onDrugEnd (event) {
      console.log(event.newIndex)
      console.log(this.hashTags[event.newIndex])
      HashTag.api().moveTo({ timelineOwnerID: this.timelineOwner.id, tagID: this.hashTags[event.newIndex].id, newPosition: event.newIndex + 1 })
    },

    updateTag (hashTag, tagName, callBack) {
      var newTagName = tagName.trim()
      // タグの名前が変わらない場合は無視する
      if (newTagName !== hashTag.tagName) {
        var validCheck = this.hashTagNameValid(newTagName)
        if (!validCheck.valid) {
          this.$emit('showNotifyEvent', 'タグの更新', validCheck.message)
        } else {
          HashTag.api().update({ timelineOwnerID: this.timelineOwner.id, tagID: hashTag.id, tagName: newTagName }).then(() => {
            this.loadHashTags()
            callBack()
          }).catch(error => {
            if (error.response.status === 400) {
              this.$emit('showNotivyEvent', 'タグの更新失敗', 'タグの更新に失敗しました。\n' + error.response.data.message)
            }
          })
        }
      }
    },

    deleteTag (hashTag) {
      this.$emit('showConfirmEvent',
        'タグの削除',
        'タグを削除しようとしています。\n本当に削除しますか?',
        DIALOG_TYPE.YES_NO,
        () => {
          HashTag.api().destroy({ timelineOwnerID: this.timelineOwner.id, tagID: hashTag.id }).then(() => {
            this.loadHashTags()
          })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mediaQuery.scss';

.horus-tag-manage-modal-wrapper {
  position: absoluete;
  top: 0;
  left: 0;
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .modal {
    position: absolute;
    top: 20px;
    left: calc((100vw - 980px) / 2);
    width: 980px;
    height: calc(100vh - 80px);
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100vh - 100px);
      left: 5px;
    }
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      height: 30px;
      .title {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        img {
          height: 15px;
          margin-right: 10px;
        }
      }
      .close-btn {
        cursor: pointer;
      }
    }
    .modal-body {
      padding: 10px;
      padding-top: 0;
      height: calc(100% - 30px - 30px);
      .new-tag-column {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 10px 20px;
        border: 1px solid #aaa;
        background-color: #eee;
        .text-field {
          width: calc(100% - 150px);
          font-size: 16px;
        }
        .create-tag-btn {
          width: 100px;
          background-color: #666;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .hash-tag-list {
        height: calc(100% - 51px - 10px);
        overflow: scroll;
      }
    }
  }
}
</style>
