import axios from './AxiosInstance'

export default {
  fetch: ({quickCode}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'quick_agreement_code',
        withCredentials: true,
        params: { code: quickCode }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ nbid: res.data.nbid, author: res.data.author })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchAction: ({quickCode}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'quick_agreement_code/show_action',
        withCredentials: true,
        params: { code: quickCode }
      }).then(res => {
        console.log(res)
        if (res.data.success === true) {
          resolve({ quickAgreementAction: res.data.action })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({targetUserID}) => {
    var formData = new FormData()
    formData.append('target_user_id', targetUserID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'quick_agreement_code',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          console.log(res)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  }

}
