// 画像ファイルのアップロードの準備として、画像のリサイズと回転方向の修正を行う。
// jpeg画像の場合、EXIF情報を読み取って回転、反転を行い、修正が終わったオブジェクトを返す。
// params:
//    file: file-inputから読み出したファイルデータ
//    maxWidthOrHeight: 縦横のうち、長い辺のサイズをこのサイズに合わせる
//    callback: コールバック関数。引数にobjを渡す。
//      obj:
//        name: ファイル名
//        orientation: EXIFのorientation. 整数
//        thumbnail: リサイズ、回転、反転を反映させた後の画像データをbase64エンコードしたもの
//        uploadFile: リサイズ、回転、反転を反映させた後の画像データをblobにしたもの

import imageCompression from 'browser-image-compression'

export default {
  methods: {
    async fixUploadPhoto (file, maxWidthOrHeight, callback) {
      // 圧縮オプションの設定
      // 写真のトリミングはサーバー側で行う。vueでは、トリミング予想をCSSでコントロールして表示する
      const options = {
        maxWidthOrHeight: maxWidthOrHeight
      }
      var obj = {}
      try {
        const compFile = await imageCompression(file, options)
        obj.name = file.name
        obj.orientation = await imageCompression.getExifOrientation(file)
        // canvas作成
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const image = new Image()
        image.onload = () => {
          // orientation 1:そのまま 2:上下反転 3:180度回転 4:左右反転 5:上下反転、時計周りに270度回転 6:時計周りに90度回転 7:上下反転、時計周りに90度回転 8:時計周りに270度回転
          // if (obj.orientation === 2) { // 左右反転
          //   console.log('orientation: ' + obj.orientation + 'fix orientation mirror')
          //   canvas.height = image.height
          //   canvas.width = image.width
          //   context.scale(-1, 1)
          //   context.translate(-image.width, 0)
          // } else if (obj.orientation === 3) { // 180度回転
          //   console.log('orientation: ' + obj.orientation + 'fix orientation 180 digree')
          //   canvas.height = image.height
          //   canvas.width = image.width
          //   context.translate(canvas.width, canvas.height)
          //   context.rotate(Math.PI)
          // } else if (obj.orientation === 4) { // 上下反転
          //   console.log('orientation: ' + obj.orientation + 'fix orientation upsidedown')
          //   canvas.height = image.height
          //   canvas.width = image.width
          //   context.scale(1, -1)
          //   context.translate(0, -canvas.height)
          // } else if (obj.orientation === 5) { // 上下反転から右90度回転
          //   console.log('orientation: ' + obj.orientation + 'fix orientation upsidedown and 90 digree')
          //   canvas.height = image.width
          //   canvas.width = image.height
          //   context.scale(1, -1)
          //   context.rotate(-Math.PI / 2)
          // } else if (obj.orientation === 6) { // 右90度回転
          //   console.log('orientation: ' + obj.orientation + 'fix orientation 90 digree')
          //   canvas.height = image.width
          //   canvas.width = image.height
          //   context.rotate(-Math.PI / 2)
          //   context.translate(-canvas.height, 0)
          // } else if (obj.orientation === 7) { // 左右反転から右90度回転
          //   console.log('orientation: ' + obj.orientation + 'fix orientation mirror and 90 digree')
          //   canvas.height = image.width
          //   canvas.width = image.height
          //   context.scale(-1, 1)
          //   context.translate(-canvas.width, canvas.height)
          //   context.rotate(-Math.PI / 2)
          // } else if (obj.orientation === 8) { // 右270度(左90度)回転
          //   console.log('orientation: ' + obj.orientation + 'fix orientation 270 digree')
          //   canvas.height = image.width
          //   canvas.width = image.height
          //   context.rotate(Math.PI / 2)
          //   context.translate(0, -canvas.width)
          // } else {
          //   console.log('orientation: ' + obj.orientation + 'not fix')
          //   canvas.height = image.height
          //   canvas.width = image.width
          // }
          console.log('orientation: ' + obj.orientation + 'not fix')
          canvas.height = image.height
          canvas.width = image.width
          context.drawImage(image, 0, 0, image.width, image.height)
          var dataURL = canvas.toDataURL(compFile.type)
          // canvasで修正した画像をサムネイルにセット
          obj.thumbnail = dataURL
          var bin = atob(dataURL.replace(/^.*,/, ''))
          var buffer = new Uint8Array(bin.length)
          for (var i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i)
          }
          try {
            var blob = new Blob([buffer.buffer], {
              type: compFile.type
            })
          } catch (e) {
            console.error(e)
          }
          // blobにしたデータをフォームデータにセット
          obj.uploadFile = blob
          console.log(obj)
          callback(obj)
          // this.$emit('photoSelectedEvent', obj)
        }
        image.src = await imageCompression.getDataUrlFromFile(compFile)
        // console.log(image)

        // canvasで修正したデータをアップロードファイルに入れる
      } catch (error) {
        console.error('image compression error occor.', error)
        throw error
      }
    }
  }
}
