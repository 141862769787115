import { Model } from '@vuex-orm/core'

export default class FileReport extends Model {
  static entity = 'fileReports'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      record_id: this.attr(null),
      filename: this.string(''),
      filenameOriginal: this.string('')
    }
  }

  get fileURL () {
    return process.env.VUE_APP_BASE_URL + '/record/show_file_report?target_user_id=' + this.user_id + '&file_report_id=' + this.id
  }
}
