<template>
  <div class="horus-photo-modal-content horus-photo-display" :class="extendClass">
    <div class="staged-photo" v-if="currentPhoto" ref="photoStage" >
      <horus-zoom-photo
        :photoObject=currentPhoto />
    </div>
    <!-- main photo content here. -->
    <div class="horus-photo-controlls" v-show="extendClass.includes('single')">
      <img src="/static/images/l_rotate_icon.png" @click="rotateLeft" ref="lRotateBtn"/>
      <img src="/static/images/r_rotate_icon.png" @click="rotateRight" ref="rRotateBtn" />
    </div>
  </div>
</template>

<script>
import HorusZoomPhoto from '@/components/zoomMeeting/HorusZoomPhoto.vue'

export default {
  name: 'HorusPhotoDisplay',

  components: {
    HorusZoomPhoto
  },

  props: {
    currentPhoto: Object,
    extendClass: String
  },

  data () {
    return {
      stageScales: {}
    }
  },

  methods: {
    rotateLeft () {
      this.$emit('rotateLeftEvent')
    },

    rotateRight () {
      this.$emit('rotateRightEvent')
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-photo-display {
  position: relative;
  height: 100%;
  .staged-photo {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .horus-photo-controlls {
    position: absolute;
    bottom: 0;
    right: 0;
    img {
      margin: 5px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
</style>
