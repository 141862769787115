import { Model } from '@vuex-orm/core'
import DrugEntryPhoto from './DrugEntryPhoto'

export default class DrugEntry extends Model {
  static entity = 'durgEntries'

  static fields () {
    return {
      id: this.attr(null),
      record_id: this.attr(null),
      comment: this.string('')
    }
  }

  get drugEntryPhotos () {
    return DrugEntryPhoto.query().where('drug_entry_id', this.id).get()
  }
}
