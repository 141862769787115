<template>
  <div class="horus-deputize-agent-column labeled_content_block">
    <div class="labeled_content_single_block">
      <label>利用者名(NBID)</label>
      <div class="content">
        <img :src="agent.portrateSrc" class="portrate" />
        {{ agent.name }}({{ agent.nbid }})
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>生年月日(年齢):性別</label>
      <div class="content">{{ agent.birthDateJp }}({{ agent.age }}):{{ agent.sexTypeJp }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ agent.address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ agent.tel }}</div>
    </div>
    <div class="content-controll-block">
      <button class="remove-user-btn" :class="{disable: !removable}" @click="removeAgent">利用停止</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusDeputizeAgentColumn',

  props: {
    agent: Object,
    removable: Boolean
  },

  methods: {
    removeAgent () {
      this.$emit('removeDeputizeAgentEvent', this.agent)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';

.horus-deputize-agent-column {
  .content-controll-block {
    display: flex;
    .small-caption {
      font-size: 11px;
      color: #6c272d;
    }
    .remove-user-btn.disable {
      background-color: #aaa;
      border-color: #aaa;
    }
  }
}
</style>
