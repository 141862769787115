import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import TimelineOwner from './TimelineOwner'
import Author from './Author'

// RELATIONSHIP_SELF = 100
// RELATIONSHIP_FAMILY_BOTH = 1
// RELATIONSHIP_FAMILY_VISIBLE = 2
// RELATIONSHIP_FAMILY_WATCHING = 3
// RELATIONSHIP_MEMBER = 10
// RELATIONSHIP_NONE = 0

export default class Family extends Model {
  static entity = 'families'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      relation: this.attr(-1),
      family_id: this.attr(''),
      nbid: this.attr(''),
      unread_count: this.attr(0)
    }
  }

  get timelineOwner () {
    // TODO: load data when undefined
    var owner = TimelineOwner.find(this.user_id)
    if (!owner) {
      TimelineOwner.api().fetch({ timelineOwnerID: this.user_id }).then(() => {
        owner = TimelineOwner.find(this.user_id)
      })
    }
    return owner
    // return TimelineOwner.find(this.user_id)
  }

  get author () {
    // TODO: load data when undefined
    // return Author.findOrLoad(this.family_id)
    return Author.find(this.family_id)
  }

  get rawFamilyID () {
    return /^usr(\d+)$/.exec(this.family_id)[1]
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(timelineOwnerID, data) {
    // console.log(data)
    let exFamilies = await Family.query().where('user_id', timelineOwnerID).get()
    for(var ef of exFamilies) {
      await ef.$delete()
    }
    if (data.authorize_families && data.authorize_families.length > 0) {
      let entities = await Family.insertOrUpdate({ data: data.authorize_families })
      // console.log(entities)
      let families = entities.families
      // console.log(families)
      // load author
      let loadAuthors = []
      for(var family of families) {
        if (!family.author) {
          if (loadAuthors.indexOf(family.author_id) < 0) loadAuthors.push(family.author_id)
        }
      }
      if (loadAuthors.length > 0) {
        await Author.api().fetchByID({ idList: loadAuthors })
      }
      Family.commit((state) => {
        state.fetchedAt = new Date()
      })
      return families
    } else {
      return []
    }
  }

  static apiConfig = {
    actions: {
      fetch ({timelineOwnerID}) {
        console.log('gonna get falimies, ownerID: ' + timelineOwnerID)
        return this.request({
          method: 'get',
          url: 'authorized_family/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          console.log(res)
          return Family.updateData(timelineOwnerID, res.response.data)
        })
      },
    
      async removeWatching ({timelineOwnerID, targetID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'authorized_family/lock_authorize_family',
            params: { target_user_id: timelineOwnerID, subject_user_id: targetID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async removeVisible ({timelineOwnerID, targetID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'authorized_family/lock_authorized_family',
            params: { target_user_id: timelineOwnerID, subject_user_id: targetID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy ({timelineOwnerID, targetID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'authorized_family',
            params: { target_user_id: timelineOwnerID, subject_user_id: targetID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }
    
    }
  }
}
