export default {
  data () {
    return {
      isShowButtonNative: false,
      qrReaderAvailable: false
    }
  },

  created () {
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
    this.updateQrReaderStatus()
  },

  updated () {
    if (this.$native.status === true) {
      this.isShowButtonNative = this.$native.status
    }
    this.updateQrReaderStatus()
  },

  methods: {
    updateQrReaderStatus () {
      if (this.isShowButtonNative) {
        if (process.env.NODE_ENV === 'production') {
          this.qrReaderAvailable = false
        } else if (process.env.NODE_ENV === 'staging') {
          this.qrReaderAvailable = false
        } else {
          this.qrReaderAvailable = true
        }
      } else {
        if (process.env.NODE_ENV === 'production') {
          this.qrReaderAvailable = true
        } else if (process.env.NODE_ENV === 'staging') {
          this.qrReaderAvailable = true
        } else {
          this.qrReaderAvailable = true
        }
      }
    }
  }

}
