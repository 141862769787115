<template>
  <div class="horus-system-totalization-view">
    <h1 class="centering-title">データ集計</h1>
    <h2>集計</h2>
    <div class="totalization-setting">
      <div class="target-date-selection">
        <div class="target-date">{{ targetDateStr }}</div>
        <button class="select-date-btn" @click="selectTargetDate">集計対象日時選択</button>
        <div class="caption">
          集計対象日時時点での各施設の集計を行います。<br/>
          施設の名称は、集計時点での名称ではなく、現在の施設名として表示されます。<br/>
          集計時点では存在しており、現在はデータの完全削除を行われた利用者は集計されません。
        </div>
      </div>
      <div class="top-category">
        <div class="top-element" @click="selectTargetAll">
          <horus-custom-check-box
            :active="targetType === 'all'"
            :enabled="true"
            :type="'color'"
            />
          <span class="top-selection">全施設を対象</span>
        </div>
        <div class="top-element" @click="selectTargetTag">
          <horus-custom-check-box
            :active="targetType === 'tag'"
            :enabled="true"
            :type="'color'"
            />
          <span class="top-selection">施設タグで選択</span>
        </div>
        <div class="top-element" @click="selectTargetOrg">
          <horus-custom-check-box
            :active="targetType === 'org'"
            :enabled="true"
            :type="'color'"
            />
          <span class="top-selection">施設の個別指定</span>
        </div>
      </div>
      <div class="tag-selection" v-show="targetType === 'tag'">
        <div v-for="tag in organizationTags" :key="'totalTag' + tag.id" class="select-tag" @click="toggleSelectTag(tag)">
          <horus-custom-check-box
            :active="tagSelected(tag)"
            :enabled="true"
            :type="'color'"
            />
          <span class="tagname">{{ tag.name }}</span>
        </div>
      </div>
      <div class="org-selection" v-show="targetType === 'org'">
        <div class="select-org" v-for="org in organizations" :key="'totalOrg' + org.id" @click="toggleSelectOrg(org)">
          <horus-custom-check-box
            :active="orgSelected(org)"
            :enabled="true"
            :type="'color'"
            />
          <span class="orgname">{{ org.shortName }}</span>
        </div>
      </div>
      <div class="controll">
        <button class="execute-btn" @click="totalization">集計実行</button>
      </div>
    </div>
    <div class="result" v-if="result">
      <h2>集計結果</h2>
      <table class="result-table">
        <tr>
          <th class="title">検索条件</th>
          <td colspan=2>{{ result.calc_source }}</td>
        </tr>
        <tr>
          <th class="title">総施設数/現在総数</th>
          <td class="result">{{ result.org_count }} / {{ result.total_org_count }}</td>
          <td class="caption">・総施設数は集計時点で選択された施設タグがふられた施設数で、削除された施設は含みません。<br/>・現在総数は集計時点の総数（NBに登録されている施設全数）で、削除された施設を含みます。</td>
        </tr>
        <tr>
          <th class="title">総職員数/現在総数</th>
          <td class="result">{{ result.member_count }} / {{ result.total_member_count }}</td>
          <td class="caption">総職員数は集計時点で選択された施設タグがふられた施設における各職員数の合計で、削除された職員は含みません。<br/>・現在総数は集計時点の総数（NBに登録されている全施設に所属する職員数の合計）で、削除された職員を含んだのべ人数です(複数の施設に所属する職員は所属施設分の人数とカウントされます)。</td>
        </tr>
        <tr>
          <th class="title">総利用者数/現在総数</th>
          <td class="result">{{ result.user_count }} / {{ result.total_user_count }}</td>
          <td class="caption">総利用者数は集計時点で選択された施設タグがふられた施設に、かかりつけている利用者のユニーク数（かかりつけではなくなった、もしくは削除された利用者は含みません）の合計です。<br/>・現在総数は集計時点の総数で、アカウント総数です。</td>
        </tr>
      </table>
      <table class="org-results">
        <tr><th>施設名</th><th>施設区分</th><th>電話番号</th><th>施設番号</th><th>職員数</th><th>利用者数</th></tr>
        <tr v-for="(org, index) in result.orgs" :key="'totalResultOrg' + index">
          <td>{{ org.short_name }}</td>
          <td>{{ org.org_type }}</td>
          <td>{{ org.tel }}</td>
          <td>{{ org.org_number }}</td>
          <td>{{ org.member }}</td>
          <td>{{ org.user }}</td>
        </tr>
        <tr><td>単純集計(のべ人数)</td><td></td><td></td><td></td><td>{{ member_total }}</td><td>{{ user_total }}</td></tr>
      </table>
      <h4>かかりつけ利用者の家族数分布</h4>
      <div class="family-distributions">
        <div class="title-block">
          <div class="title">利用者をみまもる家族数（“利用者←家族”向き）</div>
          <div class="caption">かかりつけ利用者数</div>
        </div>
        <div class="family-distribution" v-for="(watching_count, index) in result.family_distributions.watching_count" :key="'family-distribution_watching' + index">
          <div class="main-result">{{ index }}名</div>
          <div class="sub-result">{{ Number(watching_count) }}</div>
        </div>
        <div class="family-distribution" v-for="n of (familyDistributionMaxIndex - result.family_distributions.watching_count.length)" :key="'watching-void' + n">
          <div class="main-result">&nbsp;</div>
          <div class="sub-result">&nbsp;</div>
        </div>
      </div>
      <div class="family-distributions">
        <div class="title-block">
          <div class="title">利用者がみまもる家族数（“利用者→家族”向き）</div>
          <div class="caption">かかりつけ利用者数</div>
        </div>
        <div class="family-distribution" v-for="(watched_count, index) in result.family_distributions.watched_count" :key="'family-distribution_watched' + index">
          <div class="main-result">{{ index }}名</div>
          <div class="sub-result">{{ Number(watched_count) }}</div>
        </div>
        <div class="family-distribution" v-for="n of (familyDistributionMaxIndex - result.family_distributions.watched_count.length)" :key="'watched-void' + n">
          <div class="main-result">&nbsp;</div>
          <div class="sub-result">&nbsp;</div>
        </div>
      </div>
      <div class="family-distributions">
        <div class="title-block">
          <div class="title">利用者に（向き関係なく）紐付く家族数</div>
          <div class="caption">かかりつけ利用者数</div>
        </div>
        <div class="family-distribution" v-for="(uniq_count, index) in result.family_distributions.uniq_count" :key="'family-distribution_uniq' + index">
          <div class="main-result">{{ index }}名</div>
          <div class="sub-result">{{ Number(uniq_count) }}</div>
        </div>
        <div class="family-distribution" v-for="n of (familyDistributionMaxIndex - result.family_distributions.uniq_count.length)" :key="'uniq-void' + n">
          <div class="main-result">&nbsp;</div>
          <div class="sub-result">&nbsp;</div>
        </div>
      </div>
    </div>
    <h2>集計済データ</h2>
    <table class="result-datas">
      <tr><th>ファイル名</th><th>作成日時</th><th>集計対象日</th><th>&nbsp;</th></tr>
      <tr v-for="file in resultFiles" :key="'resultFile' + file.id">
        <td>{{ shortFilename(file) }}</td>
        <td>{{ fileCreatedAt(file) }}</td>
        <td>{{ fileTargetAt(file) }}</td>
        <td class="controll"><a :href="fileSrc(file)"><button class="download-btn">ダウンロード</button></a></td>
      </tr>
    </table>
    <horus-date-picker
      :showDatePicker="showDatePicker"
      :defaultDate="targetDate"
      :timeSelection="false"
      v-on:closeEvent="closeDatePicker"
      v-on:datePickupEvent="dateSelected"
      />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  Organization
} from '@/models'

import {
  OrganizationTag,
  SystemTotalization
} from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusDatePicker from '@/components/common/HorusDatePicker.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemTotalizationView',

  components: {
    HorusCustomCheckBox,
    HorusDatePicker,
    HorusConfirmDialog
  },

  data () {
    return {
      targetDate: null,
      showDatePicker: false,
      targetType: '',
      organizationTags: [],
      organizations: [],
      targetTags: [],
      targetOrgs: [],
      result: null,
      resultFiles: []
    }
  },

  mounted () {
    this.loadDatas()
  },

  computed: {
    targetDateStr () {
      return moment(this.targetDate).format('YYYY/MM/DD')
    },

    member_total () {
      var total = 0
      if (this.result) {
        total = 0
        this.result.orgs.forEach(orgCount => {
          total += orgCount.member
        })
      }
      return total
    },

    user_total () {
      var total = 0
      if (this.result) {
        total = 0
        this.result.orgs.forEach(orgCount => {
          total += orgCount.user
        })
      }
      return total
    },

    familyDistributionMaxIndex () {
      var maxCount = 0
      if (maxCount < this.result.family_distributions.watching_count.length) {
        maxCount = this.result.family_distributions.watching_count.length
      }
      if (maxCount < this.result.family_distributions.watched_count.length) {
        maxCount = this.result.family_distributions.watched_count.length
      }
      if (maxCount < this.result.family_distributions.uniq_count.length) {
        maxCount = this.result.family_distributions.uniq_count.length
      }
      return maxCount
    }
  },

  methods: {
    loadDatas () {
      this.targetDate = new Date()
      this.loadOrganizaitons()
      this.loadOrganizationTags()
      this.loadTotalizationFiles()
    },

    loadOrganizaitons () {
      Organization.api().listWithSummary().then(res => {
        this.organizations = res.organizations
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データロード失敗', '施設データのロードに失敗しました')
      })
    },

    loadOrganizationTags () {
      OrganizationTag.fetchList().then(res => {
        this.organizationTags = res.organization_tags
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データロード失敗', '施設タグデータのロードに失敗しました')
      })
    },

    loadTotalizationFiles () {
      SystemTotalization.fetchFileList().then(res => {
        this.resultFiles = res.system_calcurate_files
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データロード失敗', '集計ファイルデータのロードに失敗しました')
      })
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    selectTargetDate () {
      this.showDatePicker = true
    },

    closeDatePicker () {
      this.showDatePicker = false
    },

    dateSelected (date) {
      this.targetDate = date
      this.showDatePicker = false
    },

    selectTargetAll () {
      this.targetType = 'all'
    },

    selectTargetTag () {
      this.targetType = 'tag'
    },

    selectTargetOrg () {
      this.targetType = 'org'
    },

    toggleSelectTag (tag) {
      var selectIndex = this.targetTags.indexOf(tag.id)
      if (selectIndex < 0) {
        this.targetTags.push(tag.id)
      } else {
        this.targetTags.splice(selectIndex, 1)
      }
    },

    tagSelected (tag) {
      return this.targetTags.some(tagID => tagID === tag.id)
    },

    toggleSelectOrg (org) {
      var selectIndex = this.targetOrgs.indexOf(org.id)
      if (selectIndex < 0) {
        this.targetOrgs.push(org.id)
      } else {
        this.targetOrgs.splice(selectIndex, 1)
      }
    },

    orgSelected (org) {
      return this.targetOrgs.some(orgID => orgID === org.id)
    },

    totalization () {
      var formData = new FormData()
      formData.append('target[year]', this.targetDate.getFullYear())
      formData.append('target[month]', this.targetDate.getMonth() + 1)
      formData.append('target[day]', this.targetDate.getDate())
      if (this.targetType === 'all') {
        formData.append('target_selection_type', 'all')
      } else if (this.targetType === 'tag') {
        formData.append('target_selection_type', 'tag')
        this.targetTags.forEach(tag => {
          formData.append('target_organization_tags[]', tag)
        })
      } else if (this.targetType === 'org') {
        formData.append('target_selection_type', 'organization')
        this.targetOrgs.forEach(org => {
          formData.append('target_organizations[]', org)
        })
      }
      SystemTotalization.totalization({ formData: formData }).then(res => {
        this.result = res.result
        this.loadTotalizationFiles()
      }).catch(error => {
        console.error(error.response)
        this.loadTotalizationFiles()
        this.showNotifyDialog('集計失敗', '集計に失敗しました')
      })
    },

    fileCreatedAt (file) {
      return moment(new Date(file.created_at)).format('YYYY/MM/DD HH:mm:SS')
    },

    fileTargetAt (file) {
      return moment(new Date(file.target_at)).format('YYYY/MM/DD')
    },

    fileSrc (file) {
      return process.env.VUE_APP_BASE_URL + '/totalization?id=' + file.id
    },

    shortFilename (file) {
      var result = file.filename.url.match(/.+totalization_files\/(.+)\?.+/)
      console.log(result)
      return result[1]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-totalization-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .totalization-setting {
    border: 1px solid #aaa;
    padding: 10px;
    background-color: #fff;
    .target-date-selection {
      .target-date {
        display: inline-block;
        border: 1px solid #aaa;
        margin-right: 10px;
        padding: 3px 5px;
        font-size: 16px;
      }
      button {
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
      .caption {
        border: 1px solid #ddd;
        margin: 10px 0;
        padding: 10px;
        font-size: 12px;
      }
    }
    .top-category {
      display: flex;
      margin-bottom: 10px;
      .top-element {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
    .tag-selection {
      display: flex;
      border: 1px solid #ddd;
      padding: 10px;
      margin-bottom: 10px;
      .select-tag {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 20px;
      }
    }
    .org-selection {
      display: flex;
        flex-wrap: wrap;
      border: 1px solid #ddd;
      padding: 10px;
      padding-bottom: 0;
      margin-bottom: 10px;
      .select-org {
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
    .controll {
      button {
        border: 1px solid #666;
        padding: 3px 10px;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  .result-table {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-collapse: collapse;
    background-color: #fff;
    th.title {
      width: 200px;
      padding: 5px;
      text-align: left;
      border-top: 1px solid #ddd;
    }
    td {
      padding: 5px;
      border-top: 1px solid #ddd;
      &.result {
        width: 100px;
      }
      &.caption {
        font-size: 11px;
      }
    }
  }
  .org-results {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-collapse: collapse;
    background-color: #fff;
    th {
      padding: 5px;
      border-top: 1px solid #ddd;
      text-align: left;
    }
    td {
      padding: 5px;
      border-top: 1px solid #ddd;
    }
  }
  .family-distributions {
    display: flex;
    border: 1px solid #ddd;
    background-color: #fff;
    .title-block {
      flex-grow: 1;
      width: 350px;
      .title {
        padding: 5px 10px;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }
      .caption {
        padding: 5px 10px;
      }
    }
    .family-distribution {
      flex-grow: 1;
      width: 50px;
      .main-result {
        padding: 5px 10px;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }
      .sub-result {
        padding: 5px 10px;
      }
    }
  }
  .result-datas {
    width: 100%;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
    th {
      border-bottom: 1px solid #aaa;
    }
    td {
      padding: 5px;
      border-bottom: 1px solid #aaa;
      text-align: center;
      &.controll {
        text-align: right;
        button {
          border: 1px solid #666;
          padding: 3px 10px;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
