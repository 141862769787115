<template>
  <div class="file-in-timeline" :class="sizeType">
    <a :href="fileObject.fileURL" target="_blank">
      <img src="/static/images/document_icon.png" />
      <div class="filename">{{ fileObject.filenameOriginal }}</div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'HorusInlineFile',

  props: {
    fileObject: Object,
    sizeType: String
  },

  computed: {
    filePath () {
      return this.fileObject.fileData
    }
  },

  methods: {
    fileClicked () {
      this.$emit('timelineFileClickEvent', this.fileObject)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-in-timeline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  &.small {
    margin-top: -10px;
    margin-right: 3px;
    margin-bottom: 0px;
    font-size: 0.6em;
    img {
      margin-top: 5px;
    }
  }
  img {
    display: block;
    margin: 0 auto;
    height: 30px;
  }
  .filename {
    margin: 0 auto;
    font-size: 0.8em;
    color: #333;
  }
}
</style>
