<template>
  <div class="horus-system-third-party-linkage-form">
    <form>
      <div class="form-list-line">
        <div class="category-title">サービス情報</div>
        <horus-attribute-in-form
          :labelText="'サービス名称'"
          :necessary="true"
          :validationState="validateName"
          :validationMessage="validateNameMessage"
          :attributeNotify="'この情報はユーザーに公開されます。'">
          <input type="text" size=40 v-model="thirdPartyLinkage.name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'サービス説明'"
          :necessary="true"
          :validationState="validateDescription"
          :validationMessage="validateDescriptionMessage"
          :attributeNotify="'この情報はユーザーに公開されます。'">
          <textarea class="linkage-description" v-model="thirdPartyLinkage.description"></textarea>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'FDQN'"
          :necessary="true"
          :validationState="validateFDQN"
          :validationMessage="validateFDQNMessage"
          :attributeNotify="'この情報は内部で利用されます。'">
          <input type="text" size=30 v-model="thirdPartyLinkage.fdqn" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'コールバックURL'"
          :necessary="true"
          :validationState="validateCallbackURL"
          :validationMessage="validateCallbackURLMessage"
          :attributeNotify="'この情報は内部で使用されます。'">
          <input type="text" size=80 v-model="thirdPartyLinkage.callback_url" />
        </horus-attribute-in-form>
        <div class="category-title">サービス提供者情報</div>
        <horus-attribute-in-form
          :labelText="'名称'"
          :necessary="true"
          :validationState="validateProviderName"
          :validationMessage="validateProviderNameMessage"
          :attributeNotify="'この情報はユーザーに公開されます。'">
          <input type="text" size=30 v-model="thirdPartyLinkage.provider_name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'郵便番号'"
          :necessary="true"
          :validationState="validateProviderZipCode"
          :validationMessage="validateProviderZipCodeMessage"
          :attributeNotify="'ハイフン付きの半角数字にて入力ください(例: 000-0001)。この情報はユーザーに公開されます。'">
          <input type="text" size=10 v-model="thirdPartyLinkage.provider_zip_code" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'住所'"
          :necessary="true"
          :validationState="validateProviderAddress"
          :validationMessage="validateProviderAddressMessage"
          :attributeNotify="'この情報はユーザーに公開されます。'">
          <textarea class="privider-address" v-model="thirdPartyLinkage.provider_address"></textarea>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="validateProviderMail"
          :validationMessage="validateProviderMailMessage"
          :attributeNotify="'この情報はユーザーに公開されます。'">
          <input type="text" size=30 v-model="thirdPartyLinkage.provider_mail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'電話番号'"
          :necessary="true"
          :validationState="validateProviderTel"
          :validationMessage="validateProviderTelMessage"
          :attributeNotify="'この情報はユーザーに公開されます。'">
          <input type="text" size=15 v-model="thirdPartyLinkage.provider_tel" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'担当者名'"
          :necessary="true"
          :validationState="validateProviderContact"
          :validationMessage="validateProviderContactMessage"
          :attributeNotify="'担当者名は、審査の際に円滑に連絡を取るために使用します。ユーザーには公開されません。部署名、役職等も明記してください。'">
          <input type="text" size=30 v-model="thirdPartyLinkage.provider_contact" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'サイトURL'"
          :necessary="false"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="'サービスの詳細を説明するサイトがあれば入力ください。この情報はユーザーに公開されます。'">
          <input type="text" size=60 v-model="thirdPartyLinkage.provider_url" />
        </horus-attribute-in-form>
      </div>
      <div class="submit-controlls">
        <button type="button" class="cancel-btn" @click="cancelSubmit">{{ cancelTitle }}</button>
        <button type="button" class="submit-btn" @click="executeSubmit" :class="{ enabled: (isValid) }">{{ submitTitle }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

import * as USER_ADDRESS_DATA from '@/assets/constants/addressData.js'

export default {
  name: 'HorusThirdPartyLinkageForm',

  components: {
    HorusAttributeInForm
  },

  props: {
    originalData: Object,
    cancelTitle: String,
    submitTitle: String
  },

  data () {
    return {
      thirdPartyLinkage: {}
    }
  },

  watch: {
    originalData: { handler: 'initData', immediate: true }
  },

  computed: {
    validateName () {
      if (this.isStringPresent(this.thirdPartyLinkage.name)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateNameMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.name)) {
        return ''
      } else {
        return '名称は入力必須です'
      }
    },

    validateDescription () {
      if (this.isStringPresent(this.thirdPartyLinkage.description)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateDescriptionMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.description)) {
        return ''
      } else {
        return '説明は入力必須です'
      }
    },

    validateFDQN () {
      if (this.isStringPresent(this.thirdPartyLinkage.fdqn)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateFDQNMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.fdqn)) {
        return ''
      } else {
        return 'FDQNは入力必須です'
      }
    },

    validateCallbackURL () {
      if (this.isStringPresent(this.thirdPartyLinkage.callback_url)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateCallbackURLMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.callback_url)) {
        return ''
      } else {
        return 'コールバックアドレスは入力必須です'
      }
    },

    validateProviderName () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_name)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateProviderNameMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_name)) {
        return ''
      } else {
        return '名称は入力必須です'
      }
    },

    validateProviderZipCode () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_zip_code)) {
        var trimed = this.thirdPartyLinkage.provider_zip_code.trim()
        if (trimed.match(USER_ADDRESS_DATA.ZIP_CODE_REGXP)) {
          return 'valid'
        }
      }
      return 'invalid'
    },

    validateProviderZipCodeMessage () {
      if (!this.isStringPresent(this.thirdPartyLinkage.provider_zip_code)) {
        return '郵便番号は入力必須です'
      } else {
        var trimed = this.thirdPartyLinkage.provider_zip_code.trim()
        if (trimed.match(USER_ADDRESS_DATA.ZIP_CODE_REGXP)) {
          return ''
        } else {
          return '郵便番号はハイフン区切りの正しい形式で入力してください。(例:000-0000)'
        }
      }
    },

    validateProviderAddress () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_address)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateProviderAddressMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_address)) {
        return ''
      } else {
        return '住所は入力必須です'
      }
    },

    validateProviderMail () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_mail)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateProviderMailMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_mail)) {
        return ''
      } else {
        return 'メールアドレスは入力必須です'
      }
    },

    validateProviderTel () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_tel)) {
        var trimed = this.thirdPartyLinkage.provider_tel.trim()
        if (trimed.match(USER_ADDRESS_DATA.TEL_NUMBER_REGXP)) {
          return 'valid'
        }
      }
      return 'invalid'
    },

    validateProviderTelMessage () {
      if (!this.isStringPresent(this.thirdPartyLinkage.provider_tel)) {
        return '電話番号は入力必須です'
      } else {
        var trimed = this.thirdPartyLinkage.provider_tel.trim()
        if (trimed.match(USER_ADDRESS_DATA.TEL_NUMBER_REGXP)) {
          return ''
        } else {
          return '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
        }
      }
    },

    validateProviderContact () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_contact)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateProviderContactMessage () {
      if (this.isStringPresent(this.thirdPartyLinkage.provider_contact)) {
        return ''
      } else {
        return '担当者名は入力必須です'
      }
    },

    isValid () {
      if (
        this.validateName === 'valid' &&
        this.validateDescription === 'valid' &&
        this.validateFDQN === 'valid' &&
        this.validateCallbackURL === 'valid' &&
        this.validateProviderName === 'valid' &&
        this.validateProviderZipCode === 'valid' &&
        this.validateProviderAddress === 'valid' &&
        this.validateProviderMail === 'valid' &&
        this.validateProviderTel === 'valid'
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initData () {
      if (this.originalData) {
        this.thirdPartyLinkage = {
          name: this.originalData.name,
          description: this.originalData.description,
          fdqn: this.originalData.fdqn,
          callback_url: this.originalData.callback_url,
          provider_name: this.originalData.provider_name,
          provider_mail: this.originalData.provider_mail,
          provider_zip_code: this.originalData.provider_zip_code,
          provider_address: this.originalData.provider_address,
          provider_tel: this.originalData.provider_tel,
          provider_contact: this.originalData.provider_contact,
          provider_url: this.originalData.provider_url
        }
      } else {
        this.thirdPartyLinkage = {
          name: null,
          description: null,
          fdqn: null,
          callback_url: null,
          provider_name: null,
          provider_mail: null,
          provider_zip_code: null,
          provider_address: null,
          provider_tel: null
        }
      }
    },

    isStringPresent (string) {
      if (string && string.length > 0) {
        return true
      }
      return false
    },

    cancelSubmit () {
      this.$emit('cancelEvent', () => { this.initData() })
    },

    executeSubmit () {
      if (this.isValid) {
        var formData = new FormData()
        if (this.originalData) {
          formData.append('id', this.originalData.id)
        }
        formData.append('third_party_linkage[name]', this.thirdPartyLinkage.name)
        formData.append('third_party_linkage[description]', this.thirdPartyLinkage.description)
        formData.append('third_party_linkage[fdqn]', this.thirdPartyLinkage.fdqn)
        formData.append('third_party_linkage[callback_url]', this.thirdPartyLinkage.callback_url)
        formData.append('third_party_linkage[provider_name]', this.thirdPartyLinkage.provider_name)
        formData.append('third_party_linkage[provider_mail]', this.thirdPartyLinkage.provider_mail)
        formData.append('third_party_linkage[provider_zip_code]', this.thirdPartyLinkage.provider_zip_code)
        formData.append('third_party_linkage[provider_address]', this.thirdPartyLinkage.provider_address)
        formData.append('third_party_linkage[provider_tel]', this.thirdPartyLinkage.provider_tel)
        formData.append('third_party_linkage[provider_contact]', this.thirdPartyLinkage.provider_contact)
        if (this.thirdPartyLinkage.provider_url) {
          formData.append('third_party_linkage[provider_url]', this.thirdPartyLinkage.provider_url)
        }
        this.$emit('submitEvent', formData, this.thirdPartyLinkage)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-third-party-linkage-form {
  margin: 0;
  .category-title {
    padding-top: 10px;
    font-weight: bold;
    font-size: 16px;
  }
  .linkage-description {
    width: calc(100% - 200px);
    resize: none;
  }
  .privider-address {
    width: calc(100% - 200px);
    resize: none;
  }
  .submit-controlls {
    padding: 10px;
    text-align: center;
    button {
      width: 150px;
      margin: 0 20px;
      padding: 3px 10px;
      border: 1px solid #666;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      &.submit-btn {
        color: #fff;
        border-color: #666;
        background-color: #ddd;
        &.enabled {
          border-color: #666;
          background-color: #666;
        }
      }
    }
  }
}
</style>
