import { Model } from '@vuex-orm/core'
import moment from 'moment'
import Organization from './Organization'
import ReporterOrganization from './ReporterOrganization'
import StaffVisibility from './StaffVisibility'

// RELATIONSHIP = {
//   SELF: 100,
//   FAMILY_BOTH: 1,
//   FAMILY_AUTHORIZE: 2,
//   FAMILY_AUTHORIZED: 3,
//   ORGANIZATION_MEMBER: 10
// }

export default class Author extends Model {
  static entity = 'authors'

  static fields () {
    return {
      id: this.string(''),
      nbid: this.string(''),
      organization_id: this.attr(null),
      reporter_organization_id: this.attr(null),
      name: this.string(''),
      nameKana: this.string(''),
      nameAdditional: this.string(''),
      sexType: this.number(0),
      birth_date: this.string(''),
      portrate: this.string(''),
      address: this.string(''),
      zipCode: this.string(''),
      mail: this.string(''),
      tel: this.string(''),
      fax: this.string(''),
      cellphone: this.string(''),
      bloodType: this.string(''),
      user_basic_version: this.attr(null)
    }
  }

  get organization () {
    if (this.organization_id) {
      return Organization.find(this.organization_id)
    }
    return null
  }

  get reporterOrganization () {
    if (this.reporter_organization_id) {
      return ReporterOrganization.find(this.reporter_organization_id)
    }
    return null
  }

  get portrateSrc () {
    return process.env.VUE_APP_BASE_URL + '/author/portrate?author_id=' + this.id + '&version=' + this.user_basic_version
  }

  get birthDate () {
    if (this.birth_date.length > 0) {
      return new Date(this.birth_date)
    }
    return null
  }

  get birthDateJp () {
    return moment(this.birthDate).format('YYYY年MM月DD日')
  }

  get sexTypeJp () {
    return ['---', '男性', '女性'][this.sexType]
  }

  get age () {
    const now = new Date()
    const today = now.getFullYear() * 10000 + (now.getMonth() + 1) * 100 + now.getDate()
    const birth = this.birthDate
    const bday = birth.getFullYear() * 10000 + (birth.getMonth() + 1) * 100 + birth.getDate()
    return Math.floor((today - bday) / 10000)
  }

  get isUser () {
    if (this.id.match(/^usr\d+$/)) {
      return true
    }
    return false
  }

  get isMember () {
    if (this.organization_id && this.id.match(/^mem\d+$/)) {
      return true
    }
    return false
  }

  get isReporter () {
    if (this.reporter_organization_id && this.id.match(/^rep\d+$/)) {
      return true
    }
    return false
  }

  get rawID () {
    if (this.isMember) {
      return Number(/^mem(\d+)$/.exec(this.id)[1])
    } else if (this.isReporter) {
      return Number(/^rep(\d+)$/.exec(this.id)[1])
    } else {
      return Number(/^usr(\d+)$/.exec(this.id)[1])
    }
  }

  get userStaffVisibilities () {
    return StaffVisibility.query().where('userID', this.rawID).get()
  }

  get memberStaffVisibilities () {
    return StaffVisibility.query().where('memberID', this.id).get()
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static apiConfig = {
    actions: {
      fetchByID ({idList}) {
        return this.request({
          method: 'get',
          url: 'author/list_by_id',
          withCredentials: true,
          params: { author_id: idList },
          dataKey: 'authors'
        })
        .then(response => {
          console.log('Author, apiConfig, fetchByID')
          console.log(response.entities)
          Author.commit((state) => {
            state.fetchedAt = new Date()
          })
          if (response.entities && response.entities.authors) {
            return response.entities.authors
          } else {
            return []
          }
        })
      },
    
      fetch ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'author/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          dataKey: 'authors'
        })
        .then(response => {
          // console.log(res)
          Author.commit((state) =>{
            state.fetchedAt = new Date()
          })
          if (response.entities && response.entities.authors) {
            return response.entities.authors[0]
          } else {
            return []
          }
        })
      },

      fetchIDList ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'author/id_list',
          headers: {
            'X-HTTP-Method-Override': 'GET',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          withCredentials: true,
          params: { target_user_id: timelineOwnerID }
        })
        .then(res => {
          return { authors: res.response.data.authors }
        })
      },

    }
  }
}
