import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'
import ReporterOrganization from './ReporterOrganization'

export default class SigninReporter extends Model {
  static entity = 'sigininReporters'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      nbid: this.attr(''),
      author_id: this.attr(null),
      reporter_organization_id: this.attr(null),
      manager: this.attr(false),
      switch_members: this.attr(null)
    }
  }

  get reporterOrganization () {
    // TODO: load data when undefined
    return ReporterOrganization.find(this.reporter_organization_id)
  }

  get author () {
    // TODO: load data when undefined
    return Author.find(this.author_id)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData (payload) {
    await SigninReporter.deleteAll()
    let entities = await SigninReporter.insert({ 
      data: { 
        id: payload.id,
        user_id: payload.signin_user_id, 
        author_id: payload.author_id, 
        signin_at: new Date() 
      } 
    })
    let signinReporter = entities.signinReporters[0]
    // TODO: load organization and author
    if (!Author.find(signinReporter.author_id)) {
      await Author.api().fetchByID({ idList: [signinReporter.author_id] })
    }
    if (!ReporterOrganization.find(signinReporter.organization_id)) {
      await ReporterOrganization.api().fetchByID({ idList: [signinReporter.organization_id] })
    }
    SigninReporter.commit((state) => {
      state.fetchedAt = new Date()
    })
    return signinReporter
  }

  static apiConfig = {
    actions: {
      async fetch ({ loginID, password }) {
        var formData = new FormData()
        formData.append('login_id', loginID)
        formData.append('password', password)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'authenticate/signin_to_reporter_member',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SigninReporter.updateData(res.response.data.reporter_member)
        })
        .catch(error => { throw error })
      },
    
      fetchCurrentSingin () {
        return this.request({
          method: 'get',
          url: 'authenticate/current_signin_reporter_member',
          withCredentials: true,
          save: false
        })
        .then(res => {
          return SigninReporter.updateData(res.response.data.reporter_member)
        })
        .catch(error => { throw error })
      },
    
      async switch ({ switchTargetID }) {
        var formData = new FormData()
        formData.append('reporter_member_id', switchTargetID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'authenticate/switch_reporter_member',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SigninReporter.updateData(res.response.data.reporter_member)
        })
        .catch(error => { throw error })
      }

    }
  }
}
