<template>
  <div class="horus-test-app-signin-view">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <div class="form-set">
        <h1 class="centering-title">アプリ専用ログインのテスト</h1>
        <div class="app-info-block">
          <div class="app-info-caption">
            ナラティブブックアプリでの自動ログイン設定をテストします。<br/>
            下のフォームにNBIDと事前に取得したトークンを入力して、「ログイン」を押してください。
          </div>
        </div>
        <div class="signin-block">
          <form id='own_signin_form' v-on:submit.prevent="signinTest">
            <div class="signin-input"><label>NBID</label><input type="text" v-model="inputLoginId"></div>
            <div class="signin-input"><label>トークン</label><input type="text" v-model="inputToken"></div>
            <button type="submit" class="btn success signin-btn">ログイン</button>
          </form>
        </div>
      </div>
    </div>
    <horus-footer/>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { AppSigninToken } from '@/api'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusTestAppSigninView',

  components: {
    HorusConfirmDialog,
    HorusFooter
  },

  data () {
    return {
      inputLoginId: '',
      inputToken: ''
    }
  },

  methods: {
    signinTest () {
      AppSigninToken.testSignin({ nbID: this.inputLoginId, signinToken: this.inputToken }).then(res => {
        console.log(res)
        this.showConfirmDialog(
          'ログイン成功',
          'ログインに成功しました。\nレスポンスデータ:' + JSON.stringify(res) + '\n\n実行ボタンクリックでログイン続行します。',
          DIALOG_TYPE.EXECUTE,
          () => { this.jumpToHome(res) }
        )
      })
    },

    jumpToHome (responseData) {
      console.log(responseData)
      this.$router.push({ path: '/landing#' })
      // if (responseData.member) {
      //   this.$router.push({ path: '/member_portal' })
      // } else if (responseData.signin_user_id) {
      //   var route = '/maintimeline/' + responseData.signin_user_id
      //   if (this.$route.path === route) {
      //     route = '/maintimeline/'
      //   }
      //   this.$router.push({ path: route, force: true })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/_basicInputStyle.scss';

.horus-test-app-signin-view {
  background-color: #fff;
  .navbar {
    .main-bar {
      background-color: #692220;
      background-image: url("/static/images/nb_header_iconless.png");
    }
  }
  .contents {
    height: calc(100% - 51px - 132px - 20px);
    overflow: scroll;
    padding: 10px 50px;
    @include mediaQuery('tablet') {
      padding: 10px;
    }
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    .app-info-block {
      margin: 10px 0;
      .app-info-caption {
        margin: 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
      }
    }
    .signin-block {
      margin: 20px auto;
      padding: 40px;
      max-width: 500px;
      background-color: #fff;
      border: 1px solid #aaa;
      text-align: center;
      @include mediaQuery('tablet') {
        // margin: 10px;
      }
      @include mediaQuery('phone') {
        padding: 30px 10px;
        margin: 10px;
      }
      .signin-input {
        width: 100%;
        margin-bottom: 10px;
        label {
          display: inline-block;
          margin-right: 10px;
          width: 100px;
          text-align: right;
          font-weight: bold;
          @include mediaQuery('phone') {
            width: 70px;
          }
        }
        input {
          font-size: 16px;
        }
      }
      .signin-btn {
        width: 150px;
        padding: 8px 16px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .signin-button-controlls {
      margin: 20px;
      text-align: center;
      line-height: 2em;
      .join-btn {
        font-weight: bold;
      }
    }
  }
}
</style>
