<template>
  <div class="horus-area-block-operator-edit-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />管理団体オペレーター編集</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'名前'"
          :necessary="true"
          :validationState="nameValidation.state"
          :validationMessage="nameValidation.message"
          :attributeNotify="''">
          <input type="text" v-model="name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'ニックネーム'"
          :necessary="true"
          :validationState="nicknameValidation.state"
          :validationMessage="nicknameValidation.message"
          :attributeNotify="''">
          <input type="text" v-model="nickname" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">更 新</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { AreaBlockOperator } from '@/models'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemManagerEditModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm
  },

  props: {
    areaBlock: Object,
    sourceOperator: Object,
    showModal: Boolean
  },

  data () {
    return {
      name: '',
      nickname: '',
      nicknameValidation: { state: 'disabled', valid: false, message: '' }
    }
  },

  watch: {
    sourceOperator: { handler: 'initDatas', immediate: true },
    nickname: { handler: 'validateNickname' }
  },

  computed: {
    nameValidation () {
      if (this.name.length > 0) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '名前は入力必須です' }
      }
    },

    allValid () {
      if (this.nameValidation.valid && this.nicknameValidation.valid) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initDatas () {
      if (this.sourceOperator) {
        this.name = this.sourceOperator.name
        this.nickname = this.sourceOperator.nickname
      } else {
        this.name = ''
        this.nickname = ''
      }
    },

    validateNickname () {
      if (this.nickname.length > 0) {
        AreaBlockOperator.api().nicknameValidation({ areaBlockID: this.areaBlock.id, nickname: this.nickname, exceptID: this.sourceOperator.id }).then(res => {
          if (res.valid) {
            this.nicknameValidation = { state: 'valid', valid: true, message: '' }
          } else {
            this.nicknameValidation = { state: 'invalid', valid: false, message: 'ニックネームが使用されています。違う値を入力してください。' }
          }
        }).catch(err => {
          console.log(err)
          this.showNotifyDialog('チェック失敗', 'ニックネームの検証問い合わせに失敗しました')
        })
      } else {
        this.nicknameValidation = { state: 'invalid', valid: false, message: 'ニックネームは入力必須です。' }
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', this.initDatas)
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('id', this.sourceOperator.id)
        formData.append('area_block_operator[name]', this.name)
        formData.append('area_block_operator[nickname]', this.nickname)
        this.$emit('submitEvent', formData, this.initDatas)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-area-block-operator-edit-modal {
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
