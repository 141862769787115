import axios from './AxiosInstance'

export default {

  fetchSummary: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'critical_notification/summary',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve(res.data)
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchList: ({ displayType }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'critical_notification/list',
        params: { display_type: displayType },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve(res.data)
        }
      }).catch(error => { reject(error) })
    })
  },

  fetch: ({ targetID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'critical_notification',
        params: { critical_notification_id: targetID },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve(res.data)
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({ formData }) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'critical_notification',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  update: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'critical_notification',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({ targetID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'critical_notification',
            withCredentials: true,
            params: { critical_notification_id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fire: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'critical_notification/fire',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  resolveByManager: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'critical_notification/resolve_by_manager',
            withCredentials: true,
            params: { critical_notification_target_id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchFiredList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'critical_notification/fired_list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve(res.data)
        }
      }).catch(error => { reject(error) })
    })
  },

  resolveByToken: ({ confirmationToken, signinToken }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'critical_notification/resolve',
            withCredentials: true,
            params: { critical_notification_target_token: confirmationToken, critical_notification_target_signin_token: signinToken }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  seachByTargetToken: ({ targetToken }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'critical_notification/search_by_target_token',
        params: { critical_notification_target_token: targetToken },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve(res.data)
        }
      }).catch(error => { reject(error) })
    })
  }

}
