import * as PRESENTED_MODAL_TYPE from '@/assets/constants/modalType.js'

export default {

  data () {
    return {
      // signinMember: false, // NEED TO IMPREMENT MIXIN CLASS TYPE OBJECT OR BOOLEAN
      notificatableFamilies: [], // NEED TO IMPREMENT MIXIN CLASS TYPE ARRAY
      // organizations: [], // NEED TO IMPREMENT MIXIN CLASS TYPE ARRAY
      selectTargetForAuthor: [],
      allFamilySelectForAuthor: { value: false },
      selectOrganizationTargetForAuthor: [],
      selectLockAuthor: [],
      deselectLockAuthor: []
    }
  },

  methods: {
    pushTargetSelectModalOpen () {
      this.presentedModal = PRESENTED_MODAL_TYPE.PUSH_TARGET_SELECTION
    },

    pushTargetSelectModalClose () {
      this.presentedModal = PRESENTED_MODAL_TYPE.NONE
    },

    addPushTargetAuthor (author) {
      if (!this.selectTargetForAuthor.some(na => na.id === author.id) && !this.deselectLockAuthor.some(na => na.id === author.id)) {
        this.selectTargetForAuthor.push(author)
        this.updatePushTargetGroupState(author)
      }
    },

    removePushTargetAuthor (author, index) {
      var authorIndex = index
      if (!index >= 0) {
        authorIndex = this.selectTargetForAuthor.indexOf(author)
      }
      if (authorIndex >= 0) {
        this.selectTargetForAuthor.splice(authorIndex, 1)
        this.updatePushTargetGroupState(author)
      }
    },

    toggleAllFamilySelect () {
      if (this.allFamilySelectForAuthor.value) {
        this.notificatableFamilies.forEach(family => {
          if (!this.selectLockAuthor.some(na => na.id === family.id)) {
            this.removePushTargetAuthor(family)
          }
        })
        this.allFamilySelectForAuthor.value = false
      } else {
        this.notificatableFamilies.forEach(family => {
          if (!this.deselectLockAuthor.some(na => na.id === family.id)) {
            this.addPushTargetAuthor(family)
          }
        })
        this.allFamilySelectForAuthor.value = true
      }
    },

    toggleAllMemberSelect (organization) {
      const orgIndex = this.selectOrganizationTargetForAuthor.indexOf(organization.id)
      if (orgIndex >= 0) {
      // if (this.selectOrganizationTargetForAuthor.some(orgId => orgId === organization.id)) {
        this.organizationVisibleMembers(organization).forEach(member => {
          if (!this.selectLockAuthor.some(na => na.id === member.id)) {
            this.removePushTargetAuthor(member)
          }
        })
        this.selectOrganizationTargetForAuthor.splice(orgIndex, 1)
      } else {
        this.organizationVisibleMembers(organization).forEach(member => {
          if (!this.deselectLockAuthor.some(na => na.id === member.id)) {
            this.addPushTargetAuthor(member)
          }
        })
        this.selectOrganizationTargetForAuthor.push(organization.id)
      }
    },

    toggleSelectAuthor (author) {
      const authorIndex = this.selectTargetForAuthor.indexOf(author)
      if (authorIndex >= 0) {
        if (!this.selectLockAuthor.some(na => na.id === author.id)) {
          this.removePushTargetAuthor(author, authorIndex)
        }
      } else {
        if (!this.deselectLockAuthor.some(na => na.id === author.id)) {
          this.addPushTargetAuthor(author)
        }
      }
    },

    allAuthorsSelect () {
      this.selectTargetForAuthor.splice(0, this.selectTargetForAuthor.length)
      this.notificatableFamilies.forEach(family => {
        if (!this.deselectLockAuthor.some(na => na.id === family.id)) {
          this.addPushTargetAuthor(family)
        }
      })
      this.allFamilyPush = true
      this.organizations.forEach(org => {
        this.organizationVisibleMembers(org).forEach(member => {
          if (!this.deselectLockAuthor.some(na => na.id === member.id)) {
            this.addPushTargetAuthor(member)
          }
        })
      })
      this.pushTargetSelectModalClose()
    },

    clearSelectedAuthors () {
      this.selectTargetForAuthor.splice(0, this.selectTargetForAuthor.length)
      this.selectLockAuthor.forEach(author => {
        this.selectTargetForAuthor.push(author)
      })
      this.selectOrganizationTargetForAuthor.splice(0, this.selectOrganizationTargetForAuthor.length)
      this.pushTargetSelectModalClose()
    },

    updatePushTargetGroupState (author) {
      var allActive = true
      if (author.organization) {
        this.organizationVisibleMembers(author.organization).forEach(member => {
          if (!this.selectTargetForAuthor.some(author => author.id === member.id) && !this.deselectLockAuthor.some(author => author.id === member.id)) {
            allActive = false
          }
        })
        var orgIndex = this.selectOrganizationTargetForAuthor.indexOf(author.organization.id)
        if (allActive) {
          if (orgIndex < 0) {
            this.selectOrganizationTargetForAuthor.push(author.organization.id)
          }
        } else {
          if (orgIndex >= 0) {
            this.selectOrganizationTargetForAuthor.splice(orgIndex, 1)
          }
        }
      } else {
        this.notificatableFamilies.forEach(family => {
          if (!this.selectTargetForAuthor.some(author => author.id === family.id) && !this.deselectLockAuthor.some(author => author.id === family.id)) {
            allActive = false
          }
        })
        this.allFamilySelectForAuthor.vaule = allActive
      }
    }

  }
}
