<template>
  <div class="horus-submit-authentication-activate-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="contents">
      <h1 class="centering-title">ログイン情報の登録</h1>
      <div class="pre-process" v-if="validationFinished.length <= 0 && isTokenValid">
        <div class="message">
          <div class="message-org" v-if="organization">
            あなたの設定代行をしている{{ organization.name }}から、あなたがナラティブブックにログインできるようにするためのご案内です。<br />
            ナラティブブックにログインできるようになると、あなたの希望した家族や施設と情報の共有ができるようになります。
          </div>
          <div class="message-agent" v-if="agent">
            あなたの代理者である{{ agent.name }}から、あなたがナラティブブックにログインできるようにするためのご案内です。<br />
            ナラティブブックにログインできるようになると、あなたの希望した家族や施設と情報の共有ができるようになります。
          </div>
          <h2>{{ agentName }}からのメッセージ</h2>
          <div class="message-content" v-html="messageContentHTML"></div>
          <h2>ログイン案内の状態</h2>
          <div class="invitation-status">{{ invitation.status }}</div>
          <h2>すでに登録されているあなたの情報</h2>
          <div class="labeled_content_block uer-information">
            <div class="labeled_content_single_block">
              <label>氏名</label>
              <div class="content">{{ invitation.user.name }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>ヨミガナ</label>
              <div class="content">{{ invitation.user.nameKana }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>生年月日</label>
              <div class="content">{{ userBirthDateJp }}</div>
            </div>
          </div>
        </div>
        <div class="accept-form">
          <div class="caption">
            現在、あなたのログインのための情報は登録されていません。<br/>
            事前に知らされているNBIDを入力し、<br/>
            ナラティブブックのログインに用いるパスワードを入力ください。<br/>
            ※NBIDとパスワードはナラティブブックにログインするために必要な情報ですので忘れないように注意してください。<br />
            ※ナラティブブックへのログインが完了した後も設定代行者が設定した情報は引き継がれます。<br />
          </div>
          <div class="input-block">
            <horus-attribute-in-form
              :labelText="'NBID'"
              :necessary="true"
              :validationState="validateNBID"
              :validationMessage="validateNBIDMessage"
              :attributeNotify="'6文字以上(ログインできるようにしたいアカウントのNBIDを入力してください)'">
              <input type="text" v-model="signinID" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'パスワード'"
              :necessary="true"
              :validationState="validatePassword"
              :validationMessage="validatePasswordMessage"
              :attributeNotify="'8文字以上'">
              <input :type="passwordInputType" v-model="password" />
            </horus-attribute-in-form>
            <horus-attribute-in-form
              :labelText="'パスワード(確認)'"
              :necessary="true"
              :validationState="validatePasswordConf"
              :validationMessage="validatePasswordConfMessage"
              :attributeNotify="'上と同じパスワードを入力'">
              <input :type="passwordInputType" v-model="passwordConfirmation" />
            </horus-attribute-in-form>
            <div class="toggle-password-visible" @click="togglePasswordVisible">
              <horus-custom-check-box
                :active="passwordVisible"
                :enabled="true"
                :type="'color'"
                />パスワードを表示
            </div>
          </div>
          <h2 class="new_account_heading">規約の説明</h2>
          <h4 class="rule-title">ナラティブブックシステム利用規約</h4>
          <div class="narrativebook_rule small_box">
            <horus-rules />
          </div>
          <h4 class="rule-title">利用者の個人情報の取扱について</h4>
          <div class="narrativebook_rule small_box">
            <horus-personal-information />
          </div>
          <div class="confirm-rules">
            <div class="admit-column" @click="toggleAdmit">
              <horus-custom-check-box
                :active="admitAgreement"
                :enabled="true"
                :type="'color'"
                />
              <span>ナラティブブックシステム利用規約および利用者情報の取扱についてに同意</span>
            </div>
            <button type="button" class="admit-by-signin-btn" :class="{disable: !validateInput}" @click="activate">ログイン情報を作成</button>
          </div>
        </div>
      </div><!-- pre process -->
      <div class="processed" v-if="validationFinished === 'withSignin' && isTokenValid">
        <h2>ログイン情報登録処理完了</h2>
        <div class="message">
          ログイン情報登録処理を完了しました。<br/>
          以後、あなたは自分でログインして、ナラティブブックを使用できます。<br/>
        </div>
        <div class="finish-controlls">
          <button type="button" class="start-btn" @click="doSignin">ログインして続行</button>
          <button type="button" class="finish-btn" @click="backToLanding">ログインせずに終了</button>
        </div>
      </div>
      <div class="token-failed" v-if="isTokenValid === false">
        <h4>案内状の検証失敗</h4>
        ログイン案内の承認処理に利用するURLが正しくありません。<br/>
        メールに記されたURLが正しく入力されていないか、利用期限を過ぎているか、すでに利用済の可能性があります。<br/>
        メールのリンクを全てコピーして、ブラウザのアドレスバーにペーストするなどして正しいURLを入力ください。<br/>
        正しくURLが入力されているにもかかわらずこの状態となっている場合は、送信した設定代行施設の管理者に問い合わせて、案内を再発行してもらってください。<br/>
      </div>
    </div><!-- content -->
    <horus-footer />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Organization,
  SigninUser
} from '@/models'
import { AuthenticationActivateInvitation } from '@/api'

import moment from 'moment'

import HorusRules from '@/components/common/HorusRules.vue'
import HorusPersonalInformation from '@/components/common/HorusPersonalInformation.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import AuthenticateInfo from '@/validateModel/AuthenticateInfo.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSubmitAuthenticationActivateView',

  components: {
    HorusRules,
    HorusPersonalInformation,
    HorusAttributeInForm,
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      invitation: null,
      organization: null,
      agent: null,
      isTokenValid: null,
      signinID: '',
      password: '',
      passwordConfirmation: '',
      passwordVisible: false,
      admitAgreement: false,
      validationFinished: '',
      signinUser: null
    }
  },

  computed: {
    agentName () {
      if (this.organization) {
        return '設定代行施設'
      } else if (this.agent) {
        return '代理者'
      } else {
        return '設定代行者'
      }
    },

    messageContentHTML () {
      if (this.invitation && this.invitation.message_content.length > 0) {
        return this.invitation.message_content.replace(/\n/gi, '<br />')
      }
      return '施設からのメッセージはありません。'
    },

    userBirthDateJp () {
      if (this.invitation.user.birthDate) {
        return moment(this.invitation.user.birthDate).format('YYYY年MM月DD日')
      }
      return ''
    },

    validateNBID () {
      if (this.signinID.length <= 0) {
        return 'disabled'
      } else if (this.signinID.trim().length >= 6) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateNBIDMessage () {
      if (!this.signinID.trim().length >= 6) {
        return 'NBIDは6文字以上です'
      } else {
        return ''
      }
    },

    validatePassword () {
      if (this.password.length <= 0) {
        return 'disabled'
      } else if (AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordMessage () {
      if (this.password.length > 0 && !AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'パスワードは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePasswordConf () {
      if (this.passwordConfirmation.length <= 0) {
        return 'disabled'
      } else if (this.password.trim() === this.passwordConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordConfMessage () {
      if (this.password.length > 0 && (this.password.trim() !== this.passwordConfirmation)) {
        return '上で入力したパスワードと同じ値を入れてください。'
      } else {
        return ''
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    validateInput () {
      if (this.validateNBID === 'valid' &&
        this.validatePassword === 'valid' &&
        this.validatePasswordConf === 'valid' &&
        this.admitAgreement
      ) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  methods: {
    async initToken () {
      console.log(this.$attrs)
      this.token = this.$attrs.token
      try {
        let res = await AuthenticationActivateInvitation.validToken({ invitationToken: this.token })
        this.invitation = res.authentication_activate_invitation
        if (this.invitation.organization_id) {
          await Organization.api().fetchByID({ idList: [this.invitation.organization_id] })
          this.organization = Organization.find(this.invitation.organization_id)
        }
        this.agent = this.invitation.agent
        this.isTokenValid = true
      } catch (error) {
        console.error(error.response)
        if (error.response.status === 404) {
          this.isTokenValid = false
        }
      }
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    activate () {
      var formData = new FormData()
      formData.append('token', this.token)
      formData.append('signin_id', this.signinID)
      formData.append('authenticate_info[password]', this.password)
      formData.append('authenticate_info[password_confirmation]', this.passwordConfirmation)
      AuthenticationActivateInvitation.activate({ formData: formData }).then(() => {
        this.validationFinished = 'withSignin'
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('ログイン案内の処理失敗', 'ログイン案内の処理に失敗しました。\nNBIDが正しくないか、パスワードを形式が正しくないようです。\n正しい情報を入力してください。')
      })
    },

    doSignin () {
      SigninUser.api().fetch({ loginID: this.invitation.user.nbid, password: this.password }).then(() => {
        const allUser = SigninUser.all()
        this.signinUser = allUser[0]
        this.$router.push({ path: '/maintimeline' })
      })
    },

    backToLanding () {
      this.$router.push({ path: '/signout' })
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/labeledContent.scss";

.horus-submit-authentication-activate-view {
  height: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      margin: 10px;
      margin-bottom: 5px;
      color: #b94047;
      font-size: 1.1em;
      font-weight: normal;
    }
    h4 {
      margin: 5px 0;
    }
    .message {
      margin: 10px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      background-color: #fff;
      .message-content {
        padding: 10px;
        border: 1px solid #aaa;
        background-color: #fff;
      }
      .invitation-status {
        padding: 10px;
        border: 1px solid #aaa;
        background-color: #fff;
      }
      .uer-information {
        background-color: #fff;
      }
    }
    .accept-form {
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      background-color: #fff;
      .caption {
        margin: 10px;
        padding: 10px 20px;
        border: 1px solid #aaa;
        background-color: #fbfaf5;
      }
      .input-block {
        margin: 5px 10px;
        label {
          display: inline-block;
          width: 150px;
          text-align: right;
        }
        input {
          font-size: 16px;
        }
      }
      .small_box {
        margin-bottom: 10px;
        border: 1px solid #aaa;
        height: 200px;
        overflow: scroll;
      }
      .confirm-rules {
        text-align: center;
        font-weight: bold;
        .admit-column {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        button {
          margin: 20px 0;
          padding: 3px 10px;
          border-radius: 5px;
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          &.disable {
            border: #aaa;
            background-color: #aaa;
          }
        }
      }
    }
    .finish-controlls {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
      button {
        margin: 10px 0;
        padding: 3px 10px;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        &.start-btn {
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
        }
        &.finish-btn {
          border: 1px solid #ee7800;
          background-color: #ee7800;
        }
      }
    }
    .token-failed {
      margin: 10px 20px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #b94047;
      h4 {
        margin: 5px;
        color: #b94047;
        text-align: center;
      }
    }
  }
}
</style>
