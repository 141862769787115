import axios from './AxiosInstance'

export default {
  fetchList: ({organizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'deputized_user_creation_agreement/list',
        withCredentials: true,
        params: { organization_id: organizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ deputized_user_creation_agreements: res.data.deputized_user_creation_agreements })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'deputized_user_creation_agreement',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      }).catch(error => {
        throw error
      })
    })
  },

  update: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputized_user_creation_agreement',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({organizationID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'deputized_user_creation_agreement',
            withCredentials: true,
            params: { organization_id: organizationID, deputized_user_creation_agreement_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  prepareCreateUser: ({organizationID, targetID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('deputized_user_creation_agreement_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'deputized_user_creation_agreement/prepare_create_user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      }).catch(error => {
        throw error
      })
    })
  }
}
