
export default {

  data () {
    return {
      // createRequestModeNBID: true,
      // requestTargetNBID: '',
      // requestTargetMail: '',
      // requestTargetMailConfirmation: '',
      // requestTargetName: '',
      // requestShortToken: '0000',
      // requestMessage: ''
    }
  },

  computed: {
    // requestNBIDValidation () {
    //   if (this.requestTargetNBID.length > 0) {
    //     if (this.deputizeAgents.some(agent => (agent.nbid === this.requestTargetNBID))) {
    //       return { state: 'invalid', message: '指定したNBIDの利用者は、すでにあなたの設定代行者です。' }
    //     } else if (this.sentRequests.some(req => req.login_id === this.requestTargetNBID)) {
    //       return { state: 'invalid', message: '指定したNBIDには、すでに案内を送信済です。' }
    //     } else if (this.receiveRequests.some(req => req.agentUser && req.agentUser.nbid === this.requestTargetNBID)) {
    //       return { state: 'invalid', message: '指定したNBIDの利用者から、すでに案内を受信しています。' }
    //     } else {
    //       return { state: 'valid', message: '' }
    //     }
    //   } else {
    //     return { state: 'invalid', message: 'NBIDは入力必須です' }
    //   }
    // },

    requestMailValidation () {
      if (this.requestTargetMail.length > 0) {
        return { state: 'valid', message: '' }
      } else {
        return { state: 'invalid', message: 'メールアドレスは入力必須です' }
      }
    },

    requestMailConfirmationValidation () {
      if (this.requestTargetMail.length > 0 && this.requestTargetMail === this.requestTargetMailConfirmation) {
        return { state: 'valid', message: '' }
      } else {
        return { state: 'invalid', message: '入力されたメールアドレスと一致しません' }
      }
    },

    requestNameValidation () {
      if (this.requestTargetName.length > 0) {
        return { state: 'valid', message: '' }
      } else {
        return { state: 'invalid', message: '送信相手の名前は入力必須です' }
      }
    },

    requestShortTokenValidation () {
      if (this.requestShortToken.length <= 0 || this.requestShortToken.trim().match(/^\d{4}$/g)) {
        return { state: 'valid', message: '' }
      } else {
        return { state: 'invalid', message: '本人確認コードは半角数字4桁で入力ください' }
      }
    },

    requestReady () {
      if (this.createRequestModeNBID) {
        if (this.requestNBIDValidation.state === 'valid' && this.admitAgreement) {
          return true
        } else {
          return false
        }
      } else {
        if (
          this.requestMailValidation.state === 'valid' &&
          this.requestMailConfirmationValidation.state === 'valid' &&
          this.requestNameValidation.state === 'valid' &&
          this.admitAgreement
        ) {
          return true
        } else {
          return false
        }
      }
    }
  },

  methods: {
    resetParams () {
      this.createRequestModeNBID = true
      this.requestTargetNBID = ''
      this.requestTargetMail = ''
      this.requestTargetMailConfirmation = ''
      this.requestTargetName = ''
      this.requestShortToken = '0000'
      this.requestMessage = ''
      this.admitAgreement = false
    },

    requestModeToMail () {
      this.createRequestModeNBID = false
    },

    requestModeToNBID () {
      this.createRequestModeNBID = true
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    }
  }
}
