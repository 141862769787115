<template>
  <div class="Horus-narrow-down-authors">
    <div class="narrow-down-author"
      v-for="author in narrowDownAuthors"
      :key="author.id"
      @click="removeNarrowDownAuthor(author)">
      <img :src="author.portrateSrc" class="portrate" />
      <div class="author-name">{{ author.name }}</div>
      <img src="/static/images/batsu_icon.png" class="close-icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusNarrowDownAuthors',

  props: {
    narrowDownAuthors: Array
  },

  computed: {
  },

  methods: {
    removeNarrowDownAuthor (author) {
      this.$emit('removeNarrowDownAuthorEvent', author)
    }
  }
}
</script>

<style lang="scss" scoped>
.Horus-narrow-down-authors {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  .narrow-down-author {
    display: inline-block;
    margin-right: 5px;
    font-size: 0.8em;
    cursor: pointer;
    .portrate {
      display: inline-block;
      margin-right: -3px;
      width: 12px;
      height: 12px;
    }
    .author-name {
      display: inline-block;
    }
    .close-icon {
      display: inline-block;
      margin-left: -3px;
      width: 12px;
      height: 12px;
    }
  }
}
</style>
