<template>
  <div class="horus-record-edit-for-reporter-modal-wrapper"  v-if="editRecord">
    <div class="backdrop"></div>
    <div class="edit-modal">
      <div class="form-column">
        <div class="record-header" v-show="editRecord">
          <horus-record-head-info
            :timelineOwner="timelineOwner"
            :author="editRecord.author"
            :targetAt="editRecord.targetAt"
            />
          <div class="editing-title">
            <div class="title-text">編集中</div>
          </div>
        </div>
        <div class="form-body">
          <div class="editable-edit-column">
            <textarea placeholder="ここに書いてください" class="form-control text-comment" rows="1" ref="textCommentField" v-model="editData.textComment"></textarea>
            <div class="post-controlls">
              <div class="uploaded-photos" v-if="editRecord.photoReports.length > 0">
                <h3>アップロード済写真</h3>
                <div class="uploaded-photo-thumbnails">
                  <div class="uploaded-photo-thumbnail"
                    v-for="photoReport in editRecord.photoReports"
                    :key="'editinguploadedphotothumbnail' + photoReport.id">
                    <horus-inline-photo
                      :photoObject="photoReport"
                      :photoMaxSize="100"
                      v-on:timeliePhotoClickEvent="showPhoto(photoReport)" />
                    <div class="uploaded-photo-controll" @click="togglePhotoRemove(photoReport.id)">
                      <horus-custom-check-box :type="'small'" :active="photoGonnaRemove(photoReport.id)" :enabled="true" />この写真は削除する
                    </div>
                  </div>
                </div>
              </div>
              <div class="file-reports" v-if="editRecord.fileReports.length > 0">
                <h3>アップロード済ファイル</h3>
                <div class="uploaded-file-list">
                  <div class="uploaded-file"
                    v-for="fileReport in editRecord.fileReports"
                    :key="'editinguploadedfile' + fileReport.id">
                    <horus-inline-file
                      :fileObject="fileReport"
                      :sizeType="'normal'" />
                    <div class="uploaded-file-controll" @click="toggleFileRemove(fileReport.id)">
                      <horus-custom-check-box :type="'small'" :active="fileGonnaRemove(fileReport.id)" :enabled="true" />このファイルは削除する
                    </div>
                  </div>
                </div>
              </div>
              <horus-multi-photo-selector
                ref="multiPhotoSelector"
                :photos="editData.photos"
                :photoInputID="photoInputID"
                v-on:newPhotoPreparedEvnt="addSelectedPhoto"
                v-on:photoRemoveEvent="removePhoto"
                />
              <horus-multi-file-selector
                ref="multiFileSelector"
                :files="editData.files"
                :displaySize="''"
                :fileInputID="fileInputID"
                v-on:newFilePreparedEvent="addSelectedFile"
                v-on:fileRemoveEvent="removeFile"
                />
            </div><!-- post controll end -->
          </div><!-- editable-edit end -->
          <div class="post-controlls">
            <div class="add_icons js_group_post_inputs">
              <div class="left_add_icons">
                <div class="icon-button">
                  <label :for="photoInputID">
                    <img src="/static/images/camera_icon.png" class="add-photo" @click="addPhotoForPost"/>
                  </label>
                </div>
                <div class="icon-button">
                  <label :for="fileInputID">
                    <img src="/static/images/document_icon.png" class="add-file" @click="addFileForPost"/>
                  </label>
                </div>
                <div class="icon-button">
                  <img src="/static/images/tag_icon.png" class="add-tag" @click="selectTagForPost">
                </div>
              </div>
            </div><!-- add_icons end -->
            <div class="hash_tags">
              <div class="hash-tag"
                v-for="hashTag in selectedTags"
                :key="'editRecordSelected' + hashTag.id"
                @click="removeSelectedTag(hashTag)">
                <horus-hash-tag-display
                  :hashTag="hashTag"
                  :sizeType="'small'" />
                <img src="/static/images/x_dark_icon.png" class="close-icon" />
              </div>
              <div class="new-hash-tag"
                v-for="(newTag, index) in createTags"
                :key="'newCreatedTagEdit' + index"
                @click="removeCreateTag(index)">
                <img src="/static/images/tag_icon_green.png" class="new-tag-icon" />
                <span v-html="modifiedTagName(newTag)"></span>
                <img src="/static/images/x_dark_icon.png" class="close-icon" />
              </div>
            </div><!-- selected hashtags end -->
            <div class="execute_controlls">
              <div class="left_execute_controlls">
                <div class="target-date-display">
                  <span class="date-display" @click="setTargetDate">{{ targetDateStr }}</span>
                </div>
              </div>
              <div class="right_execute_controlls">
                <div class="executable_buttons">
                  <button type="button" class="record_post_clear" @click="clearPostForm">キャンセル</button>
                  <button type="button" class="record_post_submit" @click="submitPostForm">更&nbsp;&nbsp;新</button>
                </div>
              </div>
            </div><!-- execute controll end -->
          </div><!-- post controll end -->
        </div><!-- form body end -->
      </div><!-- post form end -->
    </div><!-- modal end -->
  </div><!-- wrapper end -->
</template>

<script>
import moment from 'moment'

import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
// import HorusRecordBody from '@/components/common/HorusRecordBody.vue'
// import HorusPhotoDisplay from '@/components/common/HorusPhotoDisplay.vue'
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'
import HorusMultiFileSelector from '@/components/common/HorusMultiFileSelector.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusRecordEditForReporterModal',

  components: {
    HorusRecordHeadInfo,
    // HorusRecordBody,
    // HorusPhotoDisplay,
    HorusInlinePhoto,
    HorusInlineFile,
    HorusMultiPhotoSelector,
    HorusMultiFileSelector,
    HorusCustomCheckBox,
    HorusHashTagDisplay
  },

  // 呼び出しもとでselectedTags,pushTargetを初期化しておくこと
  props: {
    author: Object,
    signinReporter: Object,
    timelineOwner: Object,
    editRecord: Object,
    targetDate: Object,
    selectedTags: Array,
    createTags: Array
  },

  data () {
    return {
      editData: {
        textComment: '',
        photos: [],
        files: []
      },
      photoInputID: 'photoInputForEdit',
      fileInputID: 'fileInputForEdit',
      removePhotos: [],
      removeFiles: [],
      referencePhoto: null,
      showReference: false,
      stageScales: {}
    }
  },

  created () {
    this.initFrom()
    this.initEditData()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
  },

  updated () {
    if (this.$native.status === true) {
      this.isShowButtonNative = this.$native.status
    }
  },

  watch: {
    editRecord: { handler: 'initEditData', immediate: true },
    'editData.textComment': { handler: 'fixTextCommentArea' }
  },

  computed: {
    targetDateStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    }

  },

  methods: {
    initFrom () {
      this.photoInputID = 'photoInputE' + Math.floor(Math.random() * 10000)
      this.fileInputID = 'fileInputE' + Math.floor(Math.random() * 10000)
    },

    initEditData () {
      this.editData = {
        textComment: '',
        photos: [],
        files: []
      }
      this.showReference = false
      this.referencePhoto = null
      this.removePhotos = []
      this.removeFiles = []
      if (this.editRecord) {
        this.editData.textComment = this.editRecord.textComment
      }
    },

    fixTextCommentArea () {
      this.$nextTick(() => {
        var textArea = this.$refs.textCommentField
        if (textArea) {
          // 一度高さをリセットしないと、scrollHeightが正しく取得出来ない。ただし、ちょっとちらついてしまう場合がある
          textArea.style.height = '26px'
          if (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = textArea.scrollHeight + 'px'
          }
        }
      })
    },

    modifiedTagName (newTag) {
      const splited = newTag.split('##')
      if (splited.length > 1) {
        return '<span class="hash_tag_type ' + splited[0] + '">' + splited[0] + '</span>' + splited[1]
      } else {
        return splited[0]
      }
    },

    togglePhotoRemove (photoReportID) {
      var removeIndex = this.removePhotos.indexOf(photoReportID)
      if (removeIndex < 0) {
        this.removePhotos.push(photoReportID)
      } else {
        this.removePhotos.splice(removeIndex, 1)
      }
    },

    photoGonnaRemove (photoReportID) {
      return this.removePhotos.some(pID => pID === photoReportID)
    },

    toggleFileRemove (fileReportID) {
      var removeIndex = this.removeFiles.indexOf(fileReportID)
      if (removeIndex < 0) {
        this.removeFiles.push(fileReportID)
      } else {
        this.removeFiles.splice(removeIndex, 1)
      }
    },

    fileGonnaRemove (fileReportID) {
      return this.removeFiles.some(fID => fID === fileReportID)
    },

    showPhoto (photoReport) {
      this.referencePhoto = photoReport
      this.showReference = true
    },

    showUneditablePhoto (record, photoIndex) {
      this.showPhoto(record.photoReports[photoIndex])
    },

    closeReference () {
      this.showReference = false
      this.referencePhoto = null
    },

    addPhotoForPost () {
      // this.$nextTick(() => {
      //   this.$refs.multiPhotoSelector.addPhotoForPost()
      // })
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    addSelectedPhoto (photoObj) {
      this.editData.photos.push(photoObj)
    },

    removePhoto (index) {
      this.editData.photos.splice(index, 1)
    },

    addFileForPost () {
      // this.$nextTick(() => {
      //   this.$refs.multiFileSelector.addFileForPost()
      // })
      if (this.$native.status) {
        this.$native.event('takeFileEvent', { targetID: this.fileInputID })
      }
    },

    addSelectedFile (fileObj) {
      this.editData.files.push(fileObj)
    },

    removeFile (index) {
      this.editData.files.splice(index, 1)
    },

    selectTagForPost () {
      this.$emit('openTagSelectionModalEvent')
    },

    removeSelectedTag (tag) {
      this.$emit('removeSelectedTagEvent', tag)
    },

    removeCreateTag (index) {
      this.$emit('removeCreateTagEvent', index)
    },

    setTargetDate () {
      this.$emit('setTargetDateForPostEvent')
    },

    clearTargetDate () {
      this.$emit('clearTargetDateForPostEvent')
    },

    clearPostForm () {
      this.$emit('postformClearEvent', () => { this.executeClearForm() })
    },

    executeClearForm () {
      this.initFrom()
      this.initEditData()
    },

    submitPostForm () {
      if (
        (!this.editData.textComment || this.editData.textComment.length === 0) &&
        (this.editData.photos.length + (this.editRecord.photoReports.length - this.removePhotos.length)) === 0 &&
        (this.editData.files.length + (this.editRecord.fileReports.length - this.removeFiles.length)) === 0
      ) {
        console.log('post contents is empty!')
        this.$emit('showNotifyEvent', '投稿内容のエラー', '投稿編集のエラー／書込情報が全て削除されています。\n投稿自身を削除する場合は、削除ボタン（ゴミ箱ボタン）から行ってください。')
      } else {
        // CREATE FORM DATA
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        formData.append('target_record_id', this.editRecord.id)
        if (this.targetDate.change) {
          formData.append('record[nb_calendar_target_date]', moment(this.targetDate.date).format('YYYY/MM/DD'))
          formData.append('record[nb_calendar_target_time]', moment(this.targetDate.date).format('HH:mm'))
        }
        formData.append('record[text_comment]', this.editData.textComment)
        this.editData.photos.forEach(photo => {
          formData.append('record[photos][]', photo.uploadFile, photo.name)
        })
        this.editData.files.forEach(file => {
          formData.append('record[files][]', file.uploadFile, file.name)
        })
        this.removePhotos.forEach(removePhoto => {
          formData.append('record[remove_photos][]', removePhoto)
        })
        this.removeFiles.forEach(removeFile => {
          formData.append('record[remove_files][]', removeFile)
        })
        this.selectedTags.forEach(tag => {
          formData.append('record[hashtags][]', tag.id)
        })
        this.createTags.forEach(newtag => {
          formData.append('record[new_hashtags][]', newtag)
        })
        // send form data
        this.$emit('submitPostFormEvent', formData, { deleteObjects: { photos: this.removePhotos, files: this.removeFiles } }, () => {
          this.closeReference()
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-record-edit-for-reporter-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .edit-modal {
    position: absolute;
    top: 30px;
    left: calc((100vw - 900px) / 2);
    width: 900px;
    max-height: calc(100vh - 100px);
    display: flex;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100vh - 100px);
      left: 5px;
    }
  }
}
.reference-column {
  margin-right: 10px;
  border-right: 1px solid #aaa;
  width: 50%;
  .reference-display-controll {
    padding: 10px;
    img {
      width: 20px;
    }
  }
}
.form-column {
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  padding-right: 5px;
  .record-header {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    padding: 5px;
    border: 1px solid #ddd;
    .editing-title {
      text-align: right;
      .title-text {
        padding: 3px 10px;
        background-color: #007b43;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .form-body {
    padding: 10px;
    .editable-edit-column {
      textarea {
        width: calc(100% - 6px);
        resize: none;
        font-size: 16px;
      }
    }
  }
}
.post-controlls {
  .uploaded-photos {
    margin: 10px 0;
    h3 {
      margin: 0;
      margin-bottom: 5px;
      border-bottom: 1px solid #aaa;
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
    .uploaded-photo-thumbnails {
      display: flex;
      flex-wrap: wrap;
      .uploaded-photo-thumbnail {
        margin: 0 10px 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
        text-align: center;
        .photo-in-timeline {
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
  .file-reports {
    margin: 10px 0;
    h3 {
      margin: 0;
      margin-bottom: 5px;
      border-bottom: 1px solid #aaa;
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
    .uploaded-file-list {
      display: flex;
      flex-wrap: wrap;
      .uploaded-file {
        margin: 0 10px 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
        text-align: center;
        .file-in-timeline {
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
  .add_icons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #aaa;
    padding-top: 10px;
    input {
      display: none;
    }
    .left_add_icons {
      display: flex;
      margin-left: 10px;
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
        @include mediaQuery('phone') {
          margin: 0 3px 0 3px;
        }
      }
    }
    .right_add_icons {
      display: flex;
      img {
        cursor: pointer;
        margin: 0 0 10px 20px;
        height: 30px;
      }
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
        @include mediaQuery('phone') {
          margin: 0 3px 0 3px;
        }
      }
    }
  }
  .hash_tags {
    display: flex;
    flex-wrap: wrap;
    .hash-tag {
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      .close-icon {
        margin-left: -5px;
        width: 10px;
        vertical-align: middle;
      }
    }
    .new-hash-tag {
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      .new-tag-icon {
        width: 10px;
      }
      .close-icon {
        width: 10px;
      }
    }
  }
}
.execute_controlls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left_execute_controlls {
    margin-top: 10px;
    .target-date-button {
      padding: 3px 10px;
      border: 1px solid #999;
      background-color: #999;
      color: #fff;
      font-weight: lighter;
      font-size: 14px;
    }
    .target-date-display {
      span {
        display: inline-block;
        cursor: pointer;
        height: 19px;
        margin-right: 3px;
        padding: 5px 10px 3px 10px;;
        background-color: #fff;
        color: #333;
        border: 1px solid #999;
        font-size: 16px;
      }
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
  .right_execute_controlls {
    margin-top: 10px;
    text-align: right;
    .executable_buttons {
      button {
        padding: 3px 10px;
        font-weight: lighter;
        font-size: 14px;
        &.record_post_clear {
          background-color: #fff;
          color: #333;
          border: 1px solid #ddd;
        }
        &.record_post_submit {
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
        }
      }
    }
  }
}
</style>
