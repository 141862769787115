<template>
  <div
    class="horus-zoom-photo"
    ref="photoStage"
    @mousedown.prevent="handleMouseDown($event)"
    @mouseup.prevent="handleMouseUp($event)"
    @mousemove.prevent="handleMouseMove($event)"
    @touchstart.prevent="handleTouchStart($event)"
    @touchend.prevent="handleTouchEnd($event)"
    @touchmove.prevent="handleTouchMove($event)"
    @wheel="handleWheel($event)"
  >
    <div class="size-controll">
      <div class="zoom-btn" data-zoomscale="0" @click.stop="setScale(0)">--</div>
      <div class="zoom-btn" data-zoomscale="1" @click.stop="setScale(1)">-</div>
      <div class="zoom-btn" data-zoomscale="2" @click.stop="setScale(2)">+</div>
      <div class="zoom-btn" data-zoomscale="3" @click.stop="setScale(3)">++</div>
    </div>
    <img
      :src="photoObject.photo.photoSrc"
      :width="photoObject.photo.imageWidth"
      :height="photoObject.photo.imageHeight"
      :style="{ transform: transformProps }"
      ref="stagedPhoto"
      draggable="true" />
  </div>
</template>

<script>
export default {
  name: 'HorusZoomPhoto',

  props: {
    photoObject: Object
  },

  data () {
    return {
      stageWidth: 0,
      stageHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
      currentImageScale: 0,
      minImageScale: 0,
      imageCenterOffsetX: 0,
      imageCenterOffsetY: 0,
      currentImageOffsetX: 0,
      currentImageOffsetY: 0,
      scaleStep: 0.0,
      draggableX: false,
      draggableY: false,
      dragLeftLimit: 0,
      dragRightLimit: 0,
      dragTopLimit: 0,
      dragBottomLimit: 0,
      wheelStep: 0.001,
      dragging: false,
      dragStartOffsetX: 0,
      dragStartOffsetY: 0,
      dragStartX: 0,
      dragStartY: 0,
      transformProps: ''
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleResize)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },

  watch: {
    photoObject: { handler: 'initScale', immediate: true },
    'photoObject.rotate': { handler: 'updateRotate' }
  },

  methods: {
    handleResize () {
      this.stageWidth = this.$refs.photoStage.clientWidth
      this.stageHeight = this.$refs.photoStage.clientHeight
      this.imageCenterOffsetX = (this.imageWidth * -1 + this.stageWidth) / 2
      this.imageCenterOffsetY = (this.imageHeight * -1 + this.stageHeight) / 2
      this.updateDrugLimit()
      this.updateTransform()
    },

    initScale () {
      if (this.photoObject) {
        this.imageWidth = this.photoObject.photo.imageWidth
        this.imageHeight = this.photoObject.photo.imageHeight
        this.$nextTick(() => {
          this.stageWidth = this.$refs.photoStage.clientWidth
          this.stageHeight = this.$refs.photoStage.clientHeight
          this.updateScaleLimit()
          this.updateTransform()
        })
      }
    },

    updateScaleLimit () {
      var oRatio = this.stageWidth / this.stageHeight
      var iRatio = this.imageWidth / this.imageHeight
      var tmpScale = 0.0
      if (oRatio >= iRatio) {
        // height
        tmpScale = this.stageHeight / this.imageHeight
      } else {
        // width
        tmpScale = this.stageWidth / this.imageWidth
      }
      if (this.currentImageScale < tmpScale) {
        this.currentImageScale = tmpScale
      }
      this.minImageScale = tmpScale
      this.imageCenterOffsetX = (this.photoObject.photo.imageWidth * -1 + this.stageWidth) / 2
      this.imageCenterOffsetY = (this.photoObject.photo.imageHeight * -1 + this.stageHeight) / 2
      this.scaleStep = (1 - this.minImageScale) / 4
      this.updateDrugLimit()
    },

    updateRotate () {
      if (this.photoObject.rotate === 90 || this.photoObject.rotate === 270) {
        this.imageWidth = this.photoObject.photo.imageHeight
        this.imageHeight = this.photoObject.photo.imageWidth
      } else {
        this.imageWidth = this.photoObject.photo.imageWidth
        this.imageHeight = this.photoObject.photo.imageHeight
      }
      this.updateScaleLimit()
      this.updateTransform()
    },

    updateScale (scale) {
      this.currentImageScale = scale
      this.updateDrugLimit()
      this.updateTransform()
    },

    updateDrugLimit () {
      var currentImageWidth = this.currentImageScale * this.imageWidth
      var currentImageHeight = this.currentImageScale * this.imageHeight
      this.dragLeftLimit = this.imageCenterOffsetX + (currentImageWidth - this.stageWidth) / 2
      this.dragRightLimit = this.imageCenterOffsetX - (currentImageWidth - this.stageWidth) / 2
      this.dragTopLimit = this.imageCenterOffsetY + (currentImageHeight - this.stageHeight) / 2
      this.dragBottomLimit = this.imageCenterOffsetY - (currentImageHeight - this.stageHeight) / 2
      if (this.currentImageScale * this.imageWidth > this.stageWidth) {
        this.draggableX = true
      } else {
        this.draggableX = false
      }
      if (this.currentImageScale * this.imageHeight > this.stageHeight) {
        this.draggableY = true
      } else {
        this.draggableY = false
      }
    },

    updateImageCenter () {
      if (!this.draggableX) {
        this.currentImageOffsetX = this.imageCenterOffsetX
      }
      if (!this.draggableY) {
        this.currentImageOffsetY = this.imageCenterOffsetY
      }
    },

    // 縦横比は変えないので、scaleは一つでxy共用する
    updateTransform () {
      this.updateImageCenter()
      // set scale
      this.transformProps = 'translate(' + this.currentImageOffsetX + 'px, ' + this.currentImageOffsetY + 'px) scale(' + this.currentImageScale + ', ' + this.currentImageScale + ') rotate(' + this.photoObject.rotate + 'deg)'
    },

    setScale (sizeIndex) {
      if (sizeIndex === 0) {
        this.updateScale(this.minImageScale)
      } else if (sizeIndex >= 3) {
        this.updateScale(1)
      } else {
        this.updateScale(this.minImageScale + (this.scaleStep * sizeIndex))
      }
    },

    handleWheel (event) {
      var scale = this.currentImageScale + event.deltaY * this.wheelStep
      if (scale <= this.minImageScale) {
        scale = this.minImageScale
      } else if (scale >= 1) {
        scale = 1
      }
      this.updateScale(scale)
    },

    handleMouseDown (event) {
      this.imageDrugStart(event.clientX, event.clientY)
    },

    handleMouseMove (event) {
      this.imageDrugMove(event.clientX, event.clientY)
    },

    handleMouseUp (event) {
      var zoommscale = event.target.getAttribute('data-zoomscale')
      if (zoommscale !== null && (zoommscale >= 0 || zoommscale <= 3)) {
        this.setScale(zoommscale)
      }
      this.imageDrugEnd()
    },

    handleTouchStart (event) {
      this.imageDrugStart(event.touches[0].clientX, event.touches[0].clientY)
    },

    handleTouchEnd (event) {
      var zoommscale = event.target.getAttribute('data-zoomscale')
      if (zoommscale !== null && (zoommscale >= 0 || zoommscale <= 3)) {
        this.setScale(zoommscale)
      }
      this.imageDrugEnd()
    },

    handleTouchMove (event) {
      this.imageDrugMove(event.touches[0].clientX, event.touches[0].clientY)
    },

    imageDrugStart (clientX, clientY) {
      if (this.photoObject.onStage) {
        this.dragging = true
        this.dragStartOffsetX = this.currentImageOffsetX
        this.dragStartOffsetY = this.currentImageOffsetY
        this.dragStartX = clientX
        this.dragStartY = clientY
      }
    },

    imageDrugMove (clientX, clientY) {
      if (this.photoObject.onStage && this.dragging) {
        if (this.draggableX) {
          this.currentImageOffsetX = this.dragStartOffsetX - (this.dragStartX - clientX)
          if (this.currentImageOffsetX > this.dragLeftLimit) {
            this.currentImageOffsetX = this.dragLeftLimit
          } else if (this.currentImageOffsetX < this.dragRightLimit) {
            this.currentImageOffsetX = this.dragRightLimit
          }
        }
        if (this.draggableY) {
          this.currentImageOffsetY = this.dragStartOffsetY - (this.dragStartY - clientY)
          if (this.currentImageOffsetY > this.dragTopLimit) {
            this.currentImageOffsetY = this.dragTopLimit
          } else if (this.currentImageOffsetY < this.dragBottomLimit) {
            this.currentImageOffsetY = this.dragBottomLimit
          }
        }
        this.updateTransform()
      }
    },

    imageDrugEnd () {
      this.dragging = false
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-zoom-photo {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: move;
  img {
    margin: 0;
  }
  .size-controll {
    position: absolute;
    display: flex;
    margin: 0;
    padding: 0;
    width: 160px;
    bottom: 10px;
    left: calc((100% - 160px) / 2);
    z-index: 100;
    .zoom-btn {
      margin: 0;
      padding: 0;
      padding-top: 8px;
      border: 1px solid #aaa;
      width: 38px;
      height: 32px;
      background-color: #333;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      opacity: 0.75;
    }
  }
}
</style>
