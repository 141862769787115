<template>
  <div class="horus-search-organization-column">
    <div class="org-search-column">
      <horus-attribute-in-form
        :labelText="'施設番号(半角数字)'"
        :necessary="false"
        :validationState="orgNumberValid"
        :validationMessage="orgNumberValidMessage"
        :attributeNotify="'4桁区切り16桁の半角数字です'">
        <input type="text" size="4" class="org-number-input" ref="orgNumberFirstInput" v-model="orgNumberFirst" @input="moveFocusFirst" />-
        <input type="text" size="4" class="org-number-input" ref="orgNumberSecondInput" v-model="orgNumberSecond" @input="moveFocusSecond" />-
        <input type="text" size="4" class="org-number-input" ref="orgNumberThirdInput" v-model="orgNumberThird" @input="moveFocusThird" />-
        <input type="text" size="4" class="org-number-input" ref="orgNumberFourthInput" v-model="orgNumberFourth" @input="moveFocusFourth" />
        <button class="search-org-btn" :class="{ active: orgNumberValid == 'valid' }" ref="serchOrgBtn" @click="searchOrganization">施設番号の確認</button>
      </horus-attribute-in-form>
    </div>
    <div class="org-search-result-failed" v-if="searchResultFail.length > 0">{{ this.searchResultFail }}</div>
    <div class="org-search-result" v-if="searchResultOrganization">
      <div class="labeled_content_single_block">
        <label>施設名称</label>
        <div class="content">{{ searchResultOrganization.name }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>ヨミガナ</label>
        <div class="content">{{ searchResultOrganization.nameKana }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>略称</label>
        <div class="content">{{ searchResultOrganization.shortName }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>郵便番号</label>
        <div class="content">{{ searchResultOrganization.zipCode }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>住所</label>
        <div class="content">{{ searchResultOrganization.address }}</div>
      </div>
      <div class="labeled_content_single_block">
        <label>電話番号</label>
        <div class="content">{{ searchResultOrganization.tel }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Organization
} from '@/models'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

export default {
  name: 'HorusSearchOrganizationColumn',

  components: {
    HorusAttributeInForm
  },

  props: {
    searchResultOrganization: Object,
    existOrganizations: Array,
    sentOrganizations: Array,
    receiveOrganizations: Array,
    searchType: String
  },

  data () {
    return {
      orgNumberFirst: '',
      orgNumberSecond: '',
      orgNumberThird: '',
      orgNumberFourth: '',
      searchResultOrg: null,
      searchResultFail: ''
    }
  },

  computed: {
    orgNumberValid () {
      var orgNum = this.orgNumberFirst + this.orgNumberSecond + this.orgNumberThird + this.orgNumberFourth
      if (orgNum.length <= 0) {
        return 'none'
      } else if (orgNum.trim().match(/^\d{16}$/g)) {
        return 'valid'
      } else {
        return 'none'
      }
    },

    orgNumberValidMessage () {
      var orgNum = this.orgNumberFirst + this.orgNumberSecond + this.orgNumberThird + this.orgNumberFourth
      if (orgNum.trim().match(/^\d{16}$/g)) {
        return ''
      } else {
        return '形式が正しくありません。'
      }
    }
  },

  methods: {
    moveFocusFirst () {
      this.$nextTick(() => {
        if (this.orgNumberFirst.length === 4) {
          this.$refs.orgNumberSecondInput.focus()
        }
      })
    },

    moveFocusSecond () {
      this.$nextTick(() => {
        if (this.orgNumberSecond.length === 4) {
          this.$refs.orgNumberThirdInput.focus()
        }
      })
    },

    moveFocusThird () {
      this.$nextTick(() => {
        if (this.orgNumberThird.length === 4) {
          this.$refs.orgNumberFourthInput.focus()
        }
      })
    },

    moveFocusFourth () {
      this.$nextTick(() => {
        if (this.orgNumberFourth.length === 4) {
          this.$refs.serchOrgBtn.focus()
        }
      })
    },

    resetCodeInputs () {
      this.orgNumberFirst = ''
      this.orgNumberSecond = ''
      this.orgNumberThird = ''
      this.orgNumberFourth = ''
    },

    async searchOrganization () {
      this.searchResultOrg = null
      this.searchResultFail = ''
      this.readyToCreate = false
      var orgNum = this.orgNumberFirst + this.orgNumberSecond + this.orgNumberThird + this.orgNumberFourth
      var orgNumHuman = '[' + this.orgNumberFirst + ' - ' + this.orgNumberSecond + ' - ' + this.orgNumberThird + ' - ' + this.orgNumberFourth + ']'
      try {
        let res = await Organization.api().findByNumber({ organizationNumber: orgNum })
        console.log(res)
        var orgID = res.organizations.id
        await Organization.api().fetchByID({ idList: [orgID] })
        this.searchResultOrg = Organization.find(orgID)
        if (this.searchResultOrg) {
          if (this.searchType === 'relation') {
            if (this.existOrganizations && this.existOrganizations.some(org => org.id === orgID)) {
              this.searchResultFail = 'この施設はすでにかかりつけになっています。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else if (this.sentOrganizations && this.sentOrganizations.some(org => org.id === orgID)) {
              this.searchResultFail = 'この施設にはすでに申請を送信しています。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else if (this.receiveOrganizations && this.receiveOrganizations.some(org => org.id === orgID)) {
              this.searchResultFail = 'この施設からはすでに案内を受信しています。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else if (this.searchResultOrg.applyingForRevoke) {
              this.searchResultFail = 'この施設は利用不能です。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else {
              this.$emit('searchFinishedEvent', this.searchResultOrg, true)
            }
          } else if (this.searchType === 'deputize') {
            if (this.existOrganizations && this.existOrganizations.some(org => org.id === orgID)) {
              this.searchResultFail = 'この施設はすでに設定代行施設になっています。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else if (this.sentOrganizations && this.sentOrganizations.some(org => org.id === orgID)) {
              this.searchResultFail = 'この施設にはすでに申請を送信しています。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else if (this.receiveOrganizations && this.receiveOrganizations.some(org => org.id === orgID)) {
              this.searchResultFail = 'この施設からはすでに案内を受信しています。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else if (!this.searchResultOrg.deputize_available) {
              this.searchResultFail = 'この施設は設定代行を行う許可を受けていません。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else if (this.searchResultOrg.applyingForRevoke) {
              this.searchResultFail = 'この施設は利用不能です。'
              this.resetCodeInputs()
              this.$emit('searchFinishedEvent', this.searchResultOrg, false)
            } else {
              this.$emit('searchFinishedEvent', this.searchResultOrg, true)
            }
          } else {
            this.$emit('searchFinishedEvent', this.searchResultOrg, true)
          }
        } else {
          this.$emit('searchFailedEvent')
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.searchResultOrg = null
          this.searchResultFail = '施設番号' + orgNumHuman + 'の施設は見つかりません。番号を再度ご確認ください。'
          this.orgNumberFirst = ''
          this.orgNumberSecond = ''
          this.orgNumberThird = ''
          this.orgNumberFourth = ''
          this.$emit('searchFailedEvent')
        } else {
          console.error(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-search-organization-column {
  .org-search-result-failed {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    color: #c43d53;
    text-align: center;
  }
  .org-search-result {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #aaa;
    .labeled_content_single_block {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      min-width: 300px;
      margin: 5px;
      margin-right: 10px;
      label {
        margin: 0;
        margin-right: 5px;
        font-weight: normal;
        font-size: 0.9em;
        color: #666;
      }
      .content {
        display: inline-block;
        font-size: 1.1em;
      }
    }
  }
  input[type='text'] {
    margin: 0 5px;
    font-size: 16px;
  }
  .search-org-btn {
    margin: 10px;
    border: 1px solid #999;
    border-radius: 3px;
    background-color: #999;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    &.active {
      background-color: #666;
    }
  }
}
</style>
