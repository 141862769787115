import axios from './AxiosInstance'

export default {
  preMailValidation: ({ mailAddress, reporterOrganizationRequestID, areaBlockID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_request/pre_mail_validation',
            withCredentials: true,
            params: { mail_address: mailAddress, reporter_organization_request_id: reporterOrganizationRequestID, area_block_id: areaBlockID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  confirmMail: ({ validationToken }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_request/confirm_mail',
            withCredentials: true,
            params: { token: validationToken }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  create: ({ formData }) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'reporter_organization_request',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_request/list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporterOrganizationRequests: res.data.reporter_organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchNotValidatedRequests: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_request/not_validated_requests',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporterOrganizationRequests: res.data.reporter_organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchNotValidatedCount: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_request/not_validated_count',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ count: res.data.count })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchConfirmWaitingRequests: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_request/confirm_waiting_requests',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporterOrganizationRequests: res.data.reporter_organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchAllNotProcessedRequests: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_request/all_not_processed_requests',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporterOrganizationRequests: res.data.reporter_organization_requests })
        }
      }).catch(error => { reject(error) })
    })
  },

  confirm: ({ reporterOrganizationRequestID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_request/confirm_request',
            withCredentials: true,
            params: { reporter_organization_request_id: reporterOrganizationRequestID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reject: ({ reporterOrganizationRequestID, rejectMessage }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_request/reject_request',
            withCredentials: true,
            params: { reporter_organization_request_id: reporterOrganizationRequestID, cause_of_reject: rejectMessage }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({ reporterOrganizationRequestID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'reporter_organization_request',
            withCredentials: true,
            params: { reporter_organization_request_id: reporterOrganizationRequestID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroyAllNotValidated: () => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'reporter_organization_request/destroy_all_not_validated',
            withCredentials: true
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }

}
