<template>
  <div class="horus-manage-reporter-organization-relation">
    <horus-manage-reporter-organization-frame
      :currentDisplay="'relation'"
      :signinReporter="signinReporter"
      :reporterOrganization="reporterOrganization"
      >
      <h1 class="centering-title">サービス利用</h1>
      <div class="user-list-block red_titled_column">
        <div class="revoke-notify" v-if="reporterOrganization && reporterOrganization.applyingForRevoke">
          本施設は、現在退会申請中です。<br/>
          退会申請中は、新規サービス案内及びサービス利用案内の追加、案内の再送、申込みの承諾は行なえません。
        </div>
        <h4>サービス利用者</h4>
        <div class="user-list-column" v-if="!usersNowLoading">
          <div class="block-title" @click="userListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: userListOpen}" />
            利用者リスト<span class="caption">利用者数:{{ users.length }}名</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="user-list" v-show="userListOpen" v-if="users.length > 0">
              <horus-reporter-related-user-in-manage-list
                v-for="user in users"
                :key="'manageOrgRelaedUser' + user.user_id"
                :user="user"
                :reporterOrganization="reporterOrganization"
                v-on:removeRelationEvent="removeRelation"
                />
            </div>
          </transition>
        </div>
        <div class="now-loading" v-if="usersNowLoading">読み込み中...</div>
      </div><!-- user-list-block -->
      <div class="read-qrcode-block red_titled_column">
      </div>
      <div class="sent-invitation-block red_titled_column">
        <h4>案内状の送信</h4>
        <div class="subtitle" v-if="reporterOrganization && !reporterOrganization.applyingForRevoke">利用者へサービス利用登録案内を送る</div>
        <div class="block-title" v-if="reporterOrganization && !reporterOrganization.applyingForRevoke">
          <button type="button" class="quick-qr-btn" @click="readQrForQuickRelation">かんたんQRで利用登録</button>
          <button type="button" class="create-invitation-by-qr-btn" @click="readQrForCreateInvitation">QRで案内状を作成</button>
          <button type="button" class="create-invitation-by-qr-btn" @click="inputQrForCreateInvitation">手入力でのQRコードで案内状を作成</button>
        </div>
        <div class="block-title" v-if="reporterOrganization && !reporterOrganization.applyingForRevoke">
          <button type="button" class="create-invitation-btn" @click="createNewInvitation">案内状の作成</button>
        </div>
        <div class="sent-invitation-list-column" v-if="!sentListNowLoading">
          <div class="block-title" @click="sentInvitationListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
            案内状の送信リスト<span class="caption">案内状の数:{{ sentInvitations.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-invitation-list" v-show="sentListOpen" v-if="sentInvitations.length > 0">
              <horus-sent-reporter-relation-invitation-in-manage
                v-for="sentInvitation in sentInvitations"
                :key="'sentRelationInvitation' + sentInvitation.id"
                :sentInvitation="sentInvitation"
                :reporterOrganization="reporterOrganization"
                v-on:sendMailEvent="sendMail"
                v-on:removeInvitationEvent="removeInvitation"
                />
            </div>
          </transition>
        </div><!-- sent-invitation-list-column -->
        <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
      </div><!-- sent-invitation-block -->
      <div class="receive-request-block red_titled_column">
        <h4>依頼状の受信</h4>
        <div class="subtitle">利用者からかかりつけの登録依頼を受ける</div>
        <div class="receive-request-list-column" v-if="!receiveListNowLoading">
          <div class="notify-comment"></div>
          <div class="receive-request-list" v-if="receiveRequests.length > 0">
            <horus-receive-reporter-relation-request-in-manage
              v-for="receiveRequest in receiveRequests"
              :key="'receiveRelationRequest' + receiveRequest.id"
              :receiveRequest="receiveRequest"
              v-on:confirmRequestEvent="confirmRequest"
              />
          </div>
        </div><!-- sent-invitation-list-column -->
        <div class="now-loading" v-if="receiveListNowLoading">読み込み中...</div>
      </div><!-- receive-request-block -->
    </horus-manage-reporter-organization-frame>
    <!-- create invitation modal -->
    <horus-create-reporter-relation-invitation-modal
      :showCreateRelationInvitationModal="showCreateRelationInvitationModal"
      :reporterOrganization="reporterOrganization"
      :users="users"
      :sentInvitations="sentInvitations"
      :receiveRequests="receiveRequests"
      :reservedNBID="invitationTargetNBID"
      v-on:cancelSendInvitationEvent="cancelSendInvitation"
      v-on:submitSendInvitationEvent="submitSendInvitation"
      />
    <!-- confirm request modal -->
    <horus-confirm-reporter-relation-request-modal
      :showConfirmRelationRequestModal="showConfirmRelationRequestModal"
      :targetRequest="targetReceiveRequest"
      :reporterOrganization="reporterOrganization"
      v-on:cancelConfirmEvent="cancelConfirmRequest"
      v-on:rejectRequestEvent="rejectRequest"
      v-on:admitRequestEvent="admitRequest"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author
} from '@/models'
import {
  QuickAgreementCode,
  ReporterOrganizationRelatedUser,
  ReporterOrganizationInvitationForUser,
  ReporterOrganizationInvitationForOrganization,
  StaticIdentifyCode
} from '@/api'

import HorusManageReporterOrganizationFrame from '@/components/reporterOrganization/HorusManageReporterOrganizationFrame.vue'
import HorusReporterRelatedUserInManageList from '@/components/reporterOrganization/HorusReporterRelatedUserInManageList.vue'
import HorusSentReporterRelationInvitationInManage from '@/components/reporterOrganization/HorusSentReporterRelationInvitationInManage.vue'
import HorusReceiveReporterRelationRequestInManage from '@/components/reporterOrganization/HorusReceiveReporterRelationRequestInManage.vue'
import HorusCreateReporterRelationInvitationModal from '@/components/reporterOrganization/HorusCreateReporterRelationInvitationModal.vue'
import HorusConfirmReporterRelationRequestModal from '@/components/reporterOrganization/HorusConfirmReporterRelationRequestModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'
import HorusManageQrReaderBtn from '@/mixin/HorusManageQrReaderBtn.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as QUICK_ACTION_TYPE from '@/assets/constants/quickActionType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition, HorusManageQrReaderBtn],

  name: 'HorusManageReporterOrganizationRelation',

  components: {
    HorusManageReporterOrganizationFrame,
    HorusReporterRelatedUserInManageList,
    HorusSentReporterRelationInvitationInManage,
    HorusReceiveReporterRelationRequestInManage,
    HorusCreateReporterRelationInvitationModal,
    HorusConfirmReporterRelationRequestModal,
    HorusConfirmDialog
  },

  props: {
    signinReporter: Object,
    reporterOrganization: Object
  },

  data () {
    return {
      usersNowLoading: true,
      userListOpen: false,
      users: [],
      sentListNowLoading: true,
      sentListOpen: false,
      sentInvitations: [],
      receiveListNowLoading: true,
      receiveRequests: [],
      showCreateRelationInvitationModal: false,
      targetReceiveRequest: null,
      showConfirmRelationRequestModal: false,
      invitationTargetNBID: null
      // isShowButtonNative: false,
      // qrReaderAvailable: false
    }
  },

  watch: {
    reporterOrganization: { handler: 'initObjects', immediate: true }
  },

  methods: {
    async initObjects () {
      if (this.reporterOrganization) {
        await this.loadUsers()
        await this.loadSentInvitations()
        await this.loadReceiveRequests()
      }
    },

    async loadUsers () {
      this.usersNowLoading = true
      let rel = await ReporterOrganizationRelatedUser.fetchForManage({ reporterOrganziationID: this.reporterOrganization.id })
      var unloadAuthors = []
      for(var usr of rel.related_users) {
        if (!Author.find(usr.author_id)) {
          if (unloadAuthors.indexOf(usr.author_id) < 0) unloadAuthors.push(usr.author_id)
        }
      }
      if (unloadAuthors.length > 0) {
        await Author.api().fetchByID({ idList: unloadAuthors })
      }
      for(var user of rel.related_users) {
        user.author = await Author.find(user.author_id)
      }
      this.users = rel.related_users
      this.users.sort((f, s) => {
        if (f.author.nameKana > s.author.nameKana) {
          return 1
        } else if (f.author.nameKana < s.author.nameKana) {
          return -1
        } else {
          return 0
        }
      })
      this.usersNowLoading = false
    },

    async loadSentInvitations () {
      this.sentListNowLoading = true
      await ReporterOrganizationInvitationForUser.fetch({ reporterOrganizationID: this.reporterOrganization.id }).then(res => {
        this.sentInvitations = res.reporter_organization_invitation_for_users
        this.sentListNowLoading = false
      })
    },

    async loadReceiveRequests () {
      await ReporterOrganizationInvitationForOrganization.fetchReceiveList({ reporterOrganizationID: this.reporterOrganization.id }).then(res => {
        this.receiveRequests = res.reporter_organization_invitation_for_organizations
        this.receiveListNowLoading = false
      })
    },

    userListToggle () {
      this.userListOpen = !this.userListOpen
    },

    sentInvitationListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    removeRelation (user) {
      this.showConfirmDialog(
        'サービス利用の停止',
        '[' + user.author.name + ']とのサービス利用を停止しようとしています。\n一度停止すると、再度案内を送信してサービス利用者になるまで元に戻せません。\nサービス利用を停止してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          console.log(user)
          ReporterOrganizationRelatedUser.destroy({ reporterOrganizationID: this.reporterOrganization.id, targetUserID: user.user_id }).then(() => {
            this.loadUsers()
          })
        }
      )
    },

    readQrForQuickRelation () {
      this.$emit('showQrReaderEvent', 'かんたんQRでサービス利用', this.quickQrReporterRelation, false)
    },

    quickQrReporterRelation (decodedObject) {
      console.log(JSON.stringify(decodedObject))
      if (decodedObject.decodedString) {
        QuickAgreementCode.fetch({ quickCode: decodedObject.decodedString }).then(res => {
          var order = {
            title: 'サービス利用の依頼',
            author: res.author,
            quickAgreementCode: decodedObject.decodedString,
            reporterOrganizationID: this.reporterOrganization.id,
            userID: null,
            callback: this.quickQrReporterRelationFinished,
            actionType: QUICK_ACTION_TYPE.QUICK_ACTION_REPORTER_RELATION
          }
          this.$emit('showQuickAgreementActionEvent', order)
        })
      }
    },

    quickQrRelationFinished () {
      this.loadUsers()
    },

    readQrForCreateInvitation () {
      this.$emit('showQrReaderEvent', 'サービス利用案内の送付先読み取り', this.createNewInvitationByQR, true)
    },

    inputQrForCreateInvitation () {
      this.$emit('showManualQrInputEvent', 'サービス利用案内の送付先読み取り', this.createNewInvitationByQR)
    },

    createNewInvitationByQR (decodedString) {
      console.log('createNewInvitationByQR: ' + JSON.stringify(decodedString))
      var categoryID = ''
      if (decodedString.categoryID && decodedString.categoryID > 0) {
        categoryID = decodedString.categoryID
      }
      StaticIdentifyCode.fetchUser({ categoryID: categoryID, IDCode: decodedString.decodedString }).then(res => {
        console.log(JSON.stringify(res))
        if (res.success) {
          var fail = false
          var failMessage = ''
          if (this.users.some(user => user.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーはサービス利用済です'
          } else if (this.sentInvitations.some(inv => inv.login_id === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーはすでに案内済みです'
          } else if (this.receiveRequests.some(req => req.nbid === res.nbid)) {
            fail = true
            failMessage = 'QRのユーザーの利用者から申請を受けています'
          }
          if (fail) {
            this.showNotifyDialog(
              'サービス利用案内の作成不可',
              failMessage
            )
          } else {
            this.showConfirmDialog(
              'サービス利用案内の作成',
              '[' + res.author.name + ']にサービス利用案内を送信します。\nよろしいですか?',
              DIALOG_TYPE.YES_NO,
              () => {
                this.createNewInvitation(res.nbid)
              }
            )
          }
        } else {
          this.showNotifyDialog(
            'ユーザーの取得失敗',
            res.message
          )
        }
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog(
          'ユーザーの取得失敗',
          'ユーザーの取得に失敗しました。登録されていないQRコードの可能性があります'
        )
      })
    },

    createNewInvitation (targetNBID = null) {
      if (typeof targetNBID === 'string') {
        this.invitationTargetNBID = targetNBID
      }
      this.showCreateRelationInvitationModal = true
    },

    cancelSendInvitation (callback) {
      this.showCreateRelationInvitationModal = false
      this.invitationTargetNBID = null
      callback()
    },

    submitSendInvitation (formData, sendTarget, callback) {
      ReporterOrganizationInvitationForUser.create({ formData: formData }).then(() => {
        this.loadSentInvitations()
        this.showCreateRelationInvitationModal = false
        this.invitationTargetNBID = null
        callback()
        this.showNotifyDialog('案内状の送信', sendTarget + '宛に案内状の送信しました。')
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('案内状の送信失敗', sendTarget + '宛の案内状の送信に失敗しました。')
        callback()
      })
    },

    sendMail (sentInvitation) {
      ReporterOrganizationInvitationForUser.sendMail({ reporterOrganizationID: this.reporterOrganization.id, targetID: sentInvitation.id }).then(() => {
        this.showNotifyDialog('案内状の再送信', '案内状を再送信しました。')
        this.loadSentInvitations()
      })
    },

    removeInvitation (sentInvitation) {
      this.showConfirmDialog(
        '案内状の削除',
        '案内状を削除しようとしています。\n削除した場合、案内状が期限内で未使用でも、承認の操作ができなくなります。\n削除してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeRemoveSentRelationInvitation(sentInvitation) }
      )
    },

    executeRemoveSentRelationInvitation (sentInvitation) {
      ReporterOrganizationInvitationForUser.destroy({ reporterOrganizationID: this.reporterOrganization.id, targetID: sentInvitation.id }).then(() => {
        this.loadSentInvitations()
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog(
          '案内状の削除失敗',
          '案内状の削除に失敗しました。'
        )
      })
    },

    confirmRequest (receiveRequest) {
      this.targetReceiveRequest = receiveRequest
      this.showConfirmRelationRequestModal = true
    },

    cancelConfirmRequest () {
      this.targetReceiveRequest = null
      this.showConfirmRelationRequestModal = false
    },

    rejectRequest (receiveRequest) {
      ReporterOrganizationInvitationForOrganization.reject({ reporterOrganizationID: this.reporterOrganization.id, targetID: receiveRequest.id }).then(() => {
        this.showNotifyDialog('依頼状の拒否完了', receiveRequest.user_name + 'からの依頼状を拒否しました。')
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('依頼状の拒否失敗', receiveRequest.user_name + 'からの依頼状の拒否に失敗しました。')
      }).finally(() => {
        this.loadReceiveRequests()
        this.showConfirmRelationRequestModal = false
      })
    },

    admitRequest (receiveRequest) {
      ReporterOrganizationInvitationForOrganization.admit({ reporterOrganizationID: this.reporterOrganization.id, targetID: receiveRequest.id }).then(() => {
        this.showNotifyDialog('依頼状の承認', receiveRequest.user_name + 'からの依頼状を承認しました。')
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('依頼状の承認失敗', receiveRequest.user_name + 'からの依頼状の承認に失敗しました。')
      }).finally(() => {
        this.loadUsers()
        this.loadReceiveRequests()
        this.showConfirmRelationRequestModal = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
@import '@/assets/css/centeringTitle.scss';
@import '@/assets/css/redTitledColumn.scss';
@import '@/assets/css/listData.scss';

.horus-manage-reporter-organization-relation {
  .block-title {
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
    img {
      width: 13px;
      transition: transform 600ms ease;
      &.open {
        transform: rotate(90deg);
      }
    }
    .caption {
      font-size: 12px;
      font-weight: normal;
      &.notice {
        color: #c43d53;
      }
    }
  }
  .quick-qr-btn {
    margin-bottom: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .create-invitation-btn {
    margin-bottom: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .create-invitation-by-qr-btn {
    margin-bottom: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .user-list {
    overflow: hidden;
  }
  .sent-invitation-list {
    overflow: hidden;
  }
  .revoke-notify {
    margin: 0;
    padding: 10px;
    border: 1px solid #c43d53;
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 600ms ease;
}
</style>
