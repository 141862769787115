<template>
  <div class="horus-crete-user-step-bar">
    <div class="step-block" :class="{active: step === STEP.PAGE_STEP_CHECK_MAIL}">
      <h4><span class="number">1</span><span class="title">メールアドレスの入力</span></h4>
    </div>
    <div class="step-block" :class="{active: step === STEP.PAGE_STEP_CONFIRM_MAIL}">
      <h4><span class="number">2</span><span class="title">メールアドレスの確認</span></h4>
    </div>
    <div class="step-block" :class="{active: step === STEP.PAGE_STEP_INPUT_PROFILE}">
      <h4><span class="number">3</span><span class="title">プロフィールの入力</span></h4>
    </div>
    <div class="step-block" :class="{active: step === STEP.PAGE_STEP_FINISH}">
      <h4><span class="number">4</span><span class="title">完了</span></h4>
    </div>
  </div>
</template>

<script>
import * as CREATE_STEP from '@/assets/constants/createUserStep.js'

export default {
  name: 'HorusCreateUserStepBar',

  props: {
    step: String
  },

  data () {
    return {
      STEP: CREATE_STEP
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-crete-user-step-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-left: 1px solid #aaa;
  .step-block {
    flex-grow: 0;
    min-width: 25%;
    border: 1px solid #aaa;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    &.active {
      border: 1px solid #b94047;
      background-color: #fefafa;
    }
    h4 {
      margin: 0;
      color: #999;
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
      // .number {}
      .title {
        margin-left: 10px;
      }
    }
  }
}
</style>
