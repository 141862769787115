<template>
  <div class="horus-system-organizaton-option-edit-modal">
    <horus-modal-frame
      :showModalFlag="showOrganizationOptionEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />施設オプション編集</h1>
      </div>
      <div class="modal-body">
        <h4>施設タグ</h4>
        <div class="organization-tags check-selections">
          <div class="tag selection-block"
            v-for="tag in organizationTags"
            :key="'sysOrgTagSel' + tag.id"
            @click="toggleTag(tag)"
            >
            <horus-custom-check-box
              :active="tagSelected(tag)"
              :enabled="true"
              :type="'color'"
              />
              {{ tag.name }}
          </div>
        </div>
        <h4>設定代行権限</h4>
          <div class="deputize-setting check-selections" @click="toggleDiputize">
            <horus-custom-check-box
              :active="deputizable"
              :enabled="true"
              :type="'color'"
              />
            設定代行権限あり
            <span class="caption">※チェックを入れると設定代行権限付与</span>
          </div>
        <h4>追加機能</h4>
        <div class="additinal-functions check-selections">
          <div class="func selection-block"
            v-for="addFunc in organizationFunctions.ORGANIZATION_FUNCTIONS"
            :key="'systemAddFunc' + addFunc.id"
            @click="toggleFunction(addFunc)"
            >
            <horus-custom-check-box
              :active="funcSelected(addFunc)"
              :enabled="true"
              :type="'color'"
              />
            {{ addFunc.name }}
          </div>
        </div>
        <h4>第三者サービス連携</h4>
        <div class="third-party-linkages check-selections">
          <div class="func selection-block"
            v-for="thirdPartyLinkage in thirdPartyLinkages"
            :key="'systemOrgTPL' + thirdPartyLinkage.id"
            @click="toggleLinkage(thirdPartyLinkage)"
            >
            <horus-custom-check-box
              :active="linkageSelected(thirdPartyLinkage)"
              :enabled="true"
              :type="'color'"
              />
            {{ thirdPartyLinkage.name }}
          </div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" @click="submit">更 新</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as ORGANIZATION_FUNCTIONS from '@/assets/constants/organizationFunctions.js'

export default {
  name: 'HorusSystemOrganizationOptionEditModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    sourceOrganization: Object,
    showOrganizationOptionEditModal: Boolean,
    organizationTags: Array,
    thirdPartyLinkages: Array
  },

  data () {
    return {
      selectedTags: [],
      deputizable: false,
      selectedFunctions: [],
      selectedTPLs: [],
      organizationFunctions: ORGANIZATION_FUNCTIONS
    }
  },

  watch: {
    sourceOrganization: { handler: 'initObjects', immediate: true }
  },

  methods: {
    initObjects () {
      if (this.sourceOrganization) {
        this.selectedTags = Array.from(this.sourceOrganization.tags)
        this.selectedFunctions = Array.from(this.sourceOrganization.additionalFunctions)
        this.deputizable = this.sourceOrganization.deputizable
        this.selectedTPLs = this.sourceOrganization.thirdPartyLinkages.map(tpl => tpl.id)
      }
    },

    tagSelected (tag) {
      if (this.selectedTags.some(tagID => tagID === tag.id)) {
        return true
      } else {
        return false
      }
    },

    funcSelected (func) {
      if (this.selectedFunctions.some(funcID => funcID === func.id)) {
        return true
      } else {
        return false
      }
    },

    toggleTag (tag) {
      var index = this.selectedTags.indexOf(tag.id)
      if (index < 0) {
        this.selectedTags.push(tag.id)
      } else {
        this.selectedTags.splice(index, 1)
      }
    },

    toggleDiputize () {
      this.deputizable = !this.deputizable
    },

    toggleFunction (addFunc) {
      var index = this.selectedFunctions.indexOf(addFunc.id)
      if (index < 0) {
        this.selectedFunctions.push(addFunc.id)
      } else {
        this.selectedFunctions.splice(index, 1)
      }
    },

    linkageSelected (linkage) {
      if (this.selectedTPLs.some(linkageID => linkageID === linkage.id)) {
        return true
      } else {
        return false
      }
    },

    toggleLinkage (linkage) {
      var index = this.selectedTPLs.indexOf(linkage.id)
      if (index < 0) {
        this.selectedTPLs.push(linkage.id)
      } else {
        this.selectedTPLs.splice(index, 1)
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', () => { this.initObjects() })
    },

    submit () {
      var formData = new FormData()
      formData.append('organization_id', this.sourceOrganization.id)
      this.selectedTags.forEach(tagID => {
        formData.append('tags[]', tagID)
      })
      formData.append('deputizable', this.deputizable)
      this.selectedFunctions.forEach(funcID => {
        formData.append('organization_functions[]', funcID)
      })
      this.selectedTPLs.forEach(linkageID => {
        formData.append('third_party_linkages[]', linkageID)
      })
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-organizaton-option-edit-modal {
  .modal-body {
    padding: 10px 20px;
    h4 {
      margin: 5px 0;
    }
    .check-selections {
      display: flex;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #aaa;
      .selection-block {
        display: flex;
        margin-right: 20px;
      }
      .caption {
        margin: 0 10px;
        font-size: 12px;
      }
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
</style>
