<template>
  <div class="horus-narrow-down-hash-tags">
    <div class="hash-tag"
      v-for="hashTag in narrowDownTags"
      :key="hashTag.id"
      @click="removeSelectedTag(hashTag)">
      <horus-hash-tag-display
        :hashTag="hashTag"
        :sizeType="'small'" />
      <img src="/static/images/batsu_icon.png" class="close-icon" />
    </div>
  </div>
</template>

<script>
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusNarrowDownHashTags',

  components: {
    HorusHashTagDisplay
  },

  props: {
    narrowDownTags: Array
  },

  methods: {
    removeSelectedTag (tag) {
      this.$emit('removeNarrowDownTagEvent', tag)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-narrow-down-hash-tags {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  .hash-tag {
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    .close-icon {
      display: inline-block;
      margin-left: -5px;
      width: 12px;
      height: 12px;
    }
  }
}
</style>
