<template>
  <div class="horus-resolve-invalid-mail-view container">
    <div class="navbar">
      <div class="main-bar">
        <router-link to="/system_management_operator_landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></router-link>
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">メールアドレスにメールが到達しません</h1>
      <div class="message-column">
        あなたのアカウント[{{ criticalNotification.user_name }}]({{ criticalNotification.user_nbid }})に登録されたメールアドレスが、あたりどころ不明で送信が完了しません。<br/>
        正しいメールアドレスを登録し直すまで、この警告は解除されません。<br/>
        下記フォームより、正しいメールアドレスを登録して、届いたメールのリンクから、登録を完了させてください。<br/>
        新しいメールアドレスの登録が完了すると、この警告は解除されます。<br/>
      </div>
      <div class="notification-information">
        <div class="notification-title">{{ criticalNotification.title }}</div>
        <div class="notification-message" v-html="notificationMessageHTML"></div>
        <div class="date-of-fired">警告日時: {{ notificationFireAtStr }}</div>
      </div>
      <div class="mail-form">
        <horus-attribute-in-form
          :labelText="'新しいメールアドレス'"
          :necessary="true"
          :validationState="validateMail.state"
          :validationMessage="validateMail.message"
          :attributeNotify="''">
          <input type="text" v-model="mail" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="submit-btn" :class="{disable: !validateMail.valid}" @click="submit">メールアドレスを再登録</button>
        </div>
      </div>
    </div>
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  User
} from '@/api'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusResolveInvalidMailView',

  components: {
    HorusAttributeInForm,
    HorusFooter,
    HorusConfirmDialog
  },

  props: {
    criticalNotification: Object
  },

  data () {
    return {
      mail: ''
    }
  },

  computed: {
    notificationMessageHTML () {
      if (this.criticalNotification.message) {
        return this.criticalNotification.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    notificationFireAtStr () {
      var targetDate = new Date(this.criticalNotification.activated_at)
      console.log(targetDate)
      if (targetDate) {
        return moment(targetDate).format('YYYY年MM月DD日 HH時mm分')
      } else {
        return '-----'
      }
    },

    validateMail () {
      if (this.mail.length > 0) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'メールアドレスは入力必須です。' }
      }
    }

  },

  methods: {
    submit () {
      if (this.validateMail.valid) {
        var formData = new FormData()
        formData.append('mail', this.mail)
        formData.append('critical_notification_target_signin_token', this.criticalNotification.signin_token)
        formData.append('critical_notification_target_token', this.criticalNotification.target_token)
        User.acceptMailChange({formData: formData}).then(() => {
          this.showConfirmDialog(
            'メールアドレス変更の受付',
            'メールアドレスの変更を受け付けました。\n確認のメールが送信されていますので、変更手続きを完了させてください。\n変更手続きが完了すると、警告は解除されます。',
            DIALOG_TYPE.MESSAGE,
            () => { this.$router.push('/maintimeline') }
          )
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('メールアドレス変更の失敗', 'メールアドレスの変更受け付けに失敗しました。')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-resolve-invalid-mail-view {
  .contents {
    padding: 10px 100px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1 {
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
      color: #666;
    }
    .message-column {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #c53d43;
      font-size: 1.1em;
    }
  }
  .notification-information {
    padding: 10px;
    border: 1px solid #ddd;
    .notification-title {
      margin-bottom: 10px;
      font-size: 1.1em;
      font-weight: bold;
    }
    .notification-message {
      margin-bottom: 10px;
    }
    .date-of-fired {
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
    }
  }
  .mail-form {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    .submit-controlls {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: auto;
        padding: 3px 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.submit-btn {
          background-color: #4cae4c;
          border-color: #4cae4c;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
