<template>
  <div class="horus-submit-deputize-relation-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="content">
      <h1 class="centering-title">{{ targetTitleName }}の登録</h1>
      <div class="pre-process" v-if="validationFinished.length <= 0 && isTokenValid">
        <div class="message" v-if="organization">
          {{ organization.name }}からの設定代行案内です。<br/>
          下記の手順に従って承認手続きを行ってください。<br/>
          <div class="request-informations">
            <div class="information-list"><label>設定代行施設名称:</label><span>{{ organization.name }}</span></div>
            <div class="information-list"><label>設定代行施設からのメッセージ:</label><span v-html="requestMessageHTML"></span></div>
            <div class="information-list"><label>設定代行案内利用期限:</label><span>{{ limitAtStr }}</span></div>
          </div>
        </div>
        <div class="message" v-if="agent">
          {{ agent.name }}からの設定代行案内です。<br/>
          下記の手順に従って承認手続きを行ってください。<br/>
          <div class="request-informations">
            <div class="information-list"><label>設定代行者氏名(NBID):</label><span>{{ agent.name }}({{ agent.nbid }})</span></div>
            <div class="information-list"><label>設定代行者からのメッセージ:</label><span v-html="requestMessageHTML"></span></div>
            <div class="information-list"><label>設定代行案内利用期限:</label><span>{{ limitAtStr }}</span></div>
          </div>
        </div>
        <div class="organization-information" v-if="organization">
          <h2>施設の情報</h2>
          <div class="labeled_content_block">
            <div class="labeled_content_single_block">
              <label>施設名称</label>
              <div class="content">{{ organization.name }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>ヨミガナ</label>
              <div class="content">{{ organization.nameKana }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>略称</label>
              <div class="content">{{ organization.shortName }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>郵便番号</label>
              <div class="content">{{ organization.zipCode }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>住所</label>
              <div class="content">{{ organization.address }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>電話番号</label>
              <div class="content">{{ organization.tel }}</div>
            </div>
          </div>
        </div>
        <div class="organization-information" v-if="agent">
          <h2>送信者の情報</h2>
          <div class="labeled_content_block">
            <div class="labeled_content_single_block">
              <label>氏名</label>
              <div class="content">{{ agent.name }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>ヨミガナ</label>
              <div class="content">{{ agent.nameKana }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>年齢(性別)</label>
              <div class="content">{{ agentAge }}({{ agentSextype }})</div>
            </div>
            <div class="labeled_content_single_block">
              <label>郵便番号</label>
              <div class="content">{{ agent.zipCode }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>住所</label>
              <div class="content">{{ agent.address }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>電話番号</label>
              <div class="content">{{ agentTel }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>メールアドレス</label>
              <div class="content">{{ agent.mail }}</div>
            </div>
          </div>
        </div>
        <div class="accept-form">
          <div class="caption">
            設定代行を承認するには、<br/>
            下記確認事項を読んで、同意のチェックを入れた上で、<br/>
            事前に知らされている本人確認コードと、<br/>
            ナラティブブックのログインに用いる利用者ID(NBID)とパスワードを入力ください。<br/>
          </div>
          <div class="input-block">
            <label>本人確認コード:</label>
            <input type="text" class="short-token-input" cols="6" v-model="shortToken" />
          </div>
          <div class="input-block">
            <label>利用者ID(NBID):</label>
            <input type="text" class="nbid-input" v-model="nbid" />
          </div>
          <div class="input-block">
            <label>パスワード：</label>
            <input type="password" class="password-input" v-model="password" />
          </div>
          <div class="notify_for_admit">
            <h1>{{ targetTitleName }}の登録における確認事項</h1>
            <ul>
              <li>
                {{ targetTitleName }}を登録すると、ナラティブブックで設定する下記項目をあなたの代わりに{{ targetTitleName }}が代行できるようになります。
                <ul>
                  <li>プロフィール編集</li>
                  <li>家族設定</li>
                  <li>施設かかりつけ設定</li>
                  <li>設定代行者設定</li>
                </ul>
              </li>
              <li>{{ targetTitleName }}情報に間違いがないか確認してください。</li>
              <li>ナラティブブックは、あなたの情報をあなたが希望した家族や施設と共有するシステムです。</li>
              <li>ナラティブブックであなたの情報を共有してほしい家族や施設を追加したいとき、{{ targetTitleName }}にご連絡ください。</li>
            </ul>
          </div>
          <div class="input-block">
            <div class="admit-agreement" @click="toggleAdmit">
              <horus-custom-check-box
                :active="admitAgreement"
                :enabled="true"
                :type="'color'"
                />
              上記確認事項を確認しました
            </div>
          </div>
          <div class="input-block">
            <button type="button" class="admit-by-signin-btn" :class="{disable: !validForSignin}" @click="admitBySignin">ログインして承認</button>
          </div>
        </div>
      </div><!-- pre process -->
      <div class="processed" v-if="validationFinished === 'withSignin' && isTokenValid">
        <h2>設定代行承認処理完了</h2>
        <div class="message" v-if="organization">
          {{ organization.name }}の設定代行案内承認処理を完了しました。<br/>
          以後、{{ organization.name }}は、あなたの設定代行施設となります。<br/>
        </div>
        <div class="message" v-if="agent">
          {{ agent.name }}の設定代行案内承認処理を完了しました。<br/>
          以後、{{ agent.name }}は、あなたの設定代行者となります。<br/>
        </div>
        <div class="finish-controlls">
          <button type="button" class="start-btn" @click="doSignin">ログインして続行</button>
          <button type="button" class="finish-btn" @click="backToLanding">ログインせずに終了</button>
        </div>
      </div>
      <div class="token-failed" v-if="!isTokenValid">
        <h4>案内状の検証失敗</h4>
        設定代行案内の承認処理に利用するURLが正しくありません。<br/>
        メールに記されたURLが正しく入力されていないか、利用期限を過ぎているか、試行回数を超えて本人確認コードを間違えたか、またはすでに利用済の可能性があります。<br/>
        メールのリンクを全てコピーして、ブラウザのアドレスバーにペーストするなどして正しいURLを入力ください。<br/>
        正しくURLが入力されているにもかかわらずこの状態となっている場合は、発信元に問い合わせて、案内を再発行してもらってください。<br/>
      </div>
    </div><!-- content -->
    <horus-footer />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  Organization,
  SigninUser
} from '@/models'
import { DeputizingRequestToUser } from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSubmitDeputizeRelationView',

  components: {
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      organization: null,
      agent: null,
      limitAt: null,
      requestMessage: '',
      isTokenValid: true,
      shortToken: '',
      nbid: '',
      password: '',
      admitAgreement: false,
      validationFinished: '',
      signinUser: null
    }
  },

  computed: {
    targetTitleName () {
      if (this.organization) {
        return '設定代行施設'
      } else {
        return '設定代行者'
      }
    },

    limitAtStr () {
      if (this.limitAt) {
        return moment(this.limitAt).format('YYYY年MM月DD日 HH時mm分')
      } else {
        return '--------'
      }
    },

    requestMessageHTML () {
      if (this.requestMessage && this.requestMessage.length > 0) {
        return this.requestMessage.replace(/\n/gi, '<br />')
      }
      if (this.organization) {
        return '施設からのメッセージはありません。'
      } else {
        return '案内送信者からのメッセージはありません。'
      }
    },

    agentAge () {
      if (this.agent && this.agent.birth_date.length > 0) {
        const birth = new Date(this.agent.birth_date)
        const now = new Date()
        const today = now.getFullYear() * 10000 + (now.getMonth() + 1) * 100 + now.getDate()
        const bday = birth.getFullYear() * 10000 + (birth.getMonth() + 1) * 100 + birth.getDate()
        return Math.floor((today - bday) / 10000)
      }
      return ''
    },

    agentSextype () {
      if (this.agent && this.agent.sexType) {
        return ['---', '男性', '女性'][this.agent.sexType]
      }
      return ''
    },

    agentTel () {
      if (this.agent) {
        if (this.agent.tel) {
          return this.agent.tel
        } else if (this.agent.cellphone) {
          return this.agent.cellphone
        }
      }
      return ''
    },

    validForSignin () {
      if (this.admitAgreement && this.shortToken && this.shortToken.trim().match(/^\d{4}$/g) && this.nbid && this.nbid.length > 5 && this.password && this.password.length > 7) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  methods: {
    async initToken () {
      this.token = this.$attrs.token
      try {
        let res = await DeputizingRequestToUser.fetchReceive({ requestToken: this.token })
        if (res.organization_id) {
          await Organization.api().fetchByID({ idList: [res.organization_id] })
          this.organization = Organization.find(res.organization_id)
        } else {
          this.agent = res.agent
        }
        this.limitAt = new Date(res.limit_at)
        this.isTokenValid = true
        this.requestMessage = res.message_content
      } catch (error) {
        console.error(error.response)
        if (error.response.status === 404) {
          this.isTokenValid = false
        }
      }
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    admitBySignin () {
      if (this.validForSignin) {
        DeputizingRequestToUser.admitWithToken({ requestToken: this.token, shortToken: this.shortToken, loginID: this.nbid, password: this.password }).then(() => {
          this.validationFinished = 'withSignin'
        }).catch(error => {
          console.error(error.response)
          if (error.response.data.error_type === 'TARGET_OWN') {
            this.showNotifyDialog('設定代行案内の承認失敗', '設定代行案内の承認に失敗しました。\n自分自身を設定代行の対象にすることはできません。\n正しい情報を入力してください。')
          } else {
            this.showNotifyDialog('設定代行案内の承認失敗', '設定代行案内の承認に失敗しました。\n本人確認コードが正しくないか、ログインIDかパスワードを間違えているようです。\n正しい情報を入力してください。\n本人確認コードを、最大試行回数の3回を超えて間違っている場合は、この案内は無効となっているので、送信者に案内の再発行を依頼してください。')
          }
        })
      }
    },

    doSignin () {
      SigninUser.api().fetch({ loginID: this.nbid, password: this.password }).then(() => {
        const allUser = SigninUser.all()
        this.signinUser = allUser[0]
        this.$router.push({ path: '/maintimeline' })
      })
    },

    backToLanding () {
      this.$router.push({ path: '/signout' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/labeledContent.scss";
@import '@/assets/css/mediaQuery.scss';

.horus-submit-deputize-relation-view {
  height: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  .content {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.1em;
      font-weight: normal;
    }
    .message {
      margin: 10px;
      padding: 10px 20px;
      // width: 500px;
      border: 1px solid #ddd;
      background-color: #fff;
      text-align: center;
      @include mediaQuery('phone') {
        padding: 10px;
        width: auto;
      }
      .request-informations {
        margin: 10px 0;
        .information-list {
          display: flex;
          margin: 5px;
          text-align: left;
          label {
            display: inline-block;
            width: 200px;
            text-align: right;
            font-size: 0.9em;
            color: #000;
          }
          span {
            display: inline-block;
            margin-left: 10px;
            color: #000;
          }
        }
      }
    }
    .organization-information {
      margin: 10px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      background-color: #fff;
      @include mediaQuery('phone') {
        margin: 10px 0;
        padding: 10px;
      }
      h2 {
        margin: 10px;
        color: #b94047;
        text-align: center;
      }
      div {
        padding: 0;
      }
    }
    .accept-form {
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      background-color: #fff;
      @include mediaQuery('phone') {
        margin: 10px 0;
        padding: 10px;
      }
      h2 {
        margin: 10px;
        color: #b94047;
        text-align: center;
      }
      .caption {
        margin: 10px auto;
      }
      .input-block {
        margin: 5px auto;
        label {
          display: inline-block;
          width: 120px;
          text-align: right;
        }
        input {
          width: 150px;
          font-size: 16px;
        }
        button {
          margin: 20px 0;
          padding: 3px 10px;
          border-radius: 5px;
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          &.disable {
            border: #aaa;
            background-color: #aaa;
          }
        }
      }
    }
    .finish-controlls {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;
      button {
        margin: 10px 0;
        padding: 3px 10px;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        &.start-btn {
          border: 1px solid #4cae4c;
          background-color: #4cae4c;
        }
        &.finish-btn {
          border: 1px solid #ee7800;
          background-color: #ee7800;
        }
      }
    }
    .token-failed {
      margin: 10px 20px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #b94047;
      h4 {
        margin: 5px;
        color: #b94047;
        text-align: center;
      }
    }
  }
}
</style>
