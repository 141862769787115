import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'
import TimelineRecord from './TimelineRecord'

export default class NudgedRecord extends Model {
  static entity = 'nudgedRecords'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      record_id: this.attr(null),
      executer_id: this.string(''),
      created_at: this.string('')
    }
  }

  get author () {
    // TODO: load data when undefined
    return Author.find(this.executer_id)
  }

  get createdAt () {
    return new Date(this.created_at)
  }

  get record () {
    return TimelineRecord.find(this.record_id)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(timelineOwnerID, data) {
    let exNRecords = await NudgedRecord.query().where('user_id', timelineOwnerID).get()
    for(var exNrecord of exNRecords) {
      await exNrecord.$delete()
    }
    let entities = await NudgedRecord.insertOrUpdate({ data: data.nudge_records })
    NudgedRecord.commit((state) => {
      state.fetchedAt = new Date()
    })
    if (entities && entities.nudgedRecords) {
      return entities.nudgedRecords
    } else {
      return []
    }
  }

  static apiConfig = {
    actions: {
      fetch ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'nudge_record/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          return NudgedRecord.updateData(timelineOwnerID, res.response.data)
        })
        .catch(error => { throw error })
      },
    
      async create ({timelineOwnerID, targetRecordID}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('subject_record_id', targetRecordID)
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'nudge_record',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy ({timelineOwnerID, targetRecordID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE ')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'nudge_record',
            params: { target_user_id: timelineOwnerID, subject_record_id: targetRecordID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
        .catch(error => { throw error })
      }
    }
  }
}
