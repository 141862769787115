<template>
  <div class="horus-ban-excuse-view container">
    <div class="navbar">
      <div class="main-bar">
        <router-link to="/system_management_operator_landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></router-link>
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">アカウントの強制停止</h1>
      <div class="message-column">
        あなたのアカウント[{{ criticalNotification.user_name }}]({{ criticalNotification.user_nbid }})は、下記の理由で利用停止措置が行われています。<br/>
        このページのメールフォームより、ナラティブブック運営担当者にコンタクトして、アカウント停止措置を解除するまで、ナラティブブックを利用いただけません。
      </div>
      <div class="notification-information">
        <div class="notification-title">{{ criticalNotification.title }}</div>
        <div class="notification-message" v-html="notificationMessageHTML"></div>
        <div class="date-of-fired">アカウント停止日時: {{ notificationFireAtStr }}</div>
      </div>
      <div class="contacts">
        <horus-contact-form-column
          :lang="lang"
          :localizedTitle="localizedTitle"
          :localizedMessage="localizedMessage"
          :postscript="postscript"/>
      </div>
    </div>
    <horus-footer />
  </div>
</template>

<script>
import moment from 'moment'

import HorusContactFormColumn from '@/components/common/HorusContactFormColumn.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusBANExcuseView',

  components: {
    HorusContactFormColumn,
    HorusFooter
  },

  props: {
    criticalNotification: Object
  },

  data () {
    return {
      lang: 'jp',
      localizedTitle: { jp: 'アカウント停止に関するお問い合わせ', en: 'Inquiries regarding account suspension' },
      localizedMessage: { jp: 'お問い合わせいただきました内容は、弊社の掲げる個人情報保護方針に沿って管理し、お客様の同意なく第三者に開示・提供することはございません。アカウント停止の解除に関しては、本お問い合わせをもとに判断させていただくことになり、停止解除を保証するものではありません。', en: 'The content of your inquiry will be managed in accordance with our personal information protection policy, and will not be disclosed or provided to a third party without your consent. Regarding the cancellation of account suspension, we will make a decision based on this inquiry, and we do not guarantee the cancellation of suspension.' }
    }
  },

  computed: {
    notificationMessageHTML () {
      if (this.criticalNotification.message) {
        return this.criticalNotification.message.replace(/\n/gi, '<br />')
      }
      return ''
    },

    notificationFireAtStr () {
      var targetDate = new Date(this.criticalNotification.activated_at)
      console.log(targetDate)
      if (targetDate) {
        return moment(targetDate).format('YYYY年MM月DD日 HH時mm分')
      } else {
        return '-----'
      }
    },

    postscript () {
      return '\n\nアカウント停止に関するお問い合わせ\n重要警告対象コード: ' + this.criticalNotification.target_token
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-ban-excuse-view {
  .contents {
    padding: 10px 100px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1 {
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
      color: #666;
    }
    .message-column {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #c53d43;
      font-size: 1.1em;
    }
    .notification-information {
      padding: 10px;
      border: 1px solid #ddd;
      .notification-title {
        margin-bottom: 10px;
        font-size: 1.1em;
        font-weight: bold;
      }
      .notification-message {
        margin-bottom: 10px;
      }
      .date-of-fired {
        font-size: 1.1em;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
</style>
