<template>
  <div class="horus-personal-information">
    <h2>1. 個人情報の利用目的</h2>
    <ul>
      <li>利用者本人の健康や生活に係る情報を集約、管理又は利用するサービスの提供のため</li>
      <li>個人情報の管理のため</li>
      <li>利用者からの問合せに対する対応(本人への連絡を含む)のため</li>
      <li>利用者の氏名、連絡先、及び健康や生活に係る情報などを、本システムを介して、利用者が許可した施設と当該施設の職員、または家族と共有するため</li>
    </ul>
    <p>
      --- ご利用における注意事項 ---<br />
      特記事項として扱う情報は、利用者間でのコミュニケーションのため、ご本人以外にも家族設定にて家族となった利用者もしくはかかりつけ設定にてかかりつけとなった施設の職員が追加・編集・削除することができます。なお、特記事項の定義については利用規約の2条にあります。
    </p>
    <h2>2. 個人情報の第三者への提供</h2>
    <p>当社は、利用者の個人情報を以下に示す場合を除き、第三者に提供することはありません。</p>
    <ul>
      <li>利用者の事前の同意がある場合:この場合、a) 当社の名称、b) 個人情報保護管理責任者の氏名又は職名、所属及び連絡先、c) 利用目的、d) 個人情報を第三者に提供することが予定される場合の事項を通知し、事前に同意を取得させていただきます。</li>
      <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
      <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
      <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合</li>
      <li>合併その他の事由による事業の承継に伴って個人情報を提供する場合であって、承継前の利用目的の範囲内で当該個人情報を取り扱う場合</li>
      <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を業務委託先に預託する場合</li>
      <li>法令に基づく場合</li>
    </ul>
    <h2>3. 個人情報の取扱いの委託</h2>
    <p>当社は事業運営上、前項利用目的の範囲に限って個人情報を外部に委託することがあります。この場合、個人情報保護水準の高い委託先を選定し、個人情報の適正管理・機密保持についての契約を交わし、適切な管理を実施させます。</p>
    <h2>4. 事業者名称</h2>
    <p>クロスケアフィールド株式会社</p>
    <h2>5. 個人情報保護管理者</h2>
    <p>
      所属及び連絡先<br/>
      管理者職名:代表取締役<br/>
      連絡先:03-3831-7755<br/>
    </p>
    <h2>6. 開示等の請求</h2>
    <p>
      ご本人様は、当社に対してご自身の個人情報の開示等(利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止）に関して、下記の当社問合わせ窓口に申し出ることができます。その際、当社はお客様ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。<br />
      【お問合せ窓口】<br />
      〒110-0005&nbsp;東京都台東区上野3-7-3 SDビル2階<br />
      クロスケアフィールド株式会社&nbsp;個人情報問合せ窓口<br />
      TEL:03-3831-7755 （受付時間&nbsp;10:00～17:00※）<br />
      ※土・日曜日、祝日、年末年始は翌営業日以降の対応とさせていただきます。<br />
    </p>
    <h2>7. 個人情報を提供されることの任意性について</h2>
    <p>ご本人様が当社に個人情報を提供されるかどうかは任意によるものです。 ただし、必要な項目をいただけない場合、適切な対応ができない場合があります。</p>
  </div>
</template>

<script>
export default {
  name: 'HorusPersonalInformation'
}
</script>

<style lang="scss" scoped>
.horus-personal-information {
  padding: 10px;
  background-color: #fff;
  h1 {
    margin: 20px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  h2 {
    margin: 20px 0;
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
  }
  h3 {
    margin: 3px 0;
    margin-top: 20px;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
  }
  h4 {
    margin: 3px 0;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
    border-bottom: 1px solid #ddd;
  }
  p {
    margin: 3px 0;
    font-size: 0.9em;
  }
}
</style>
