import { Model } from '@vuex-orm/core'
import TimelineRecord from '@/models/TimelineRecord.js'

export default class MillviAudioContent extends Model {
  static entity = 'millviAudioContents'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      record_id: this.attr(null),
      content_id: this.number(0),
      duration: this.number(0.0),
      content_state: this.number(0)
    }
  }

  get timelineRecord () {
    return TimelineRecord.find(this.record_id)
  }

  get stateJp () {
    switch (this.content_state) {
      case 0:
        return 'なし'
      case 10:
        return '完了'
      case 20:
        return '処理中'
      case 30:
        return '準備完了'
      case 50:
        return '失敗'
      default:
        return 'なしーー'
    }
  }

  get contentName () {
    return this.timelineRecord.targetAtStr + ' ' + this.timelineRecord.author.name + 'の音声'
  }
}
