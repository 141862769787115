<template>
  <div class="horus-forgot-area-block-operator-password-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">パスワードの再発行</h1>
      <div class="message-column">
        パスワードの再発行には、管理団体オペレーターのログインIDが必要です。<br/>
        下記フォームにログインIDを入力すると、登録されているメールアドレス宛にパスワード再発行メールが届きます。<br/>
        リンクをクリックして、パスワードを再設定してください。
      </div>
      <div class="signin-block">
        <form id='own_signin_form' v-on:submit.prevent="createToken">
          <div class="signin_id_input"><label>ログインID</label><input type="text" name="login_id" id='login_id' v-model="inputLoginId"></div>
          <button type="submit" class="btn-success signin-btn">パスワード再発行</button>
        </form>
      </div>
    </div>
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { AreaBlockOperator } from '@/models'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusForgotAreaBlockOperatorPasswordView',

  components: {
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      inputLoginId: ''
    }
  },

  methods: {
    createToken () {
      var formData = new FormData()
      formData.append('login_id', this.inputLoginId)
      AreaBlockOperator.api().forgotPassword({ formData: formData }).then(() => {
        this.showConfirmDialog('パスワードの再発行受付', 'パスワードの再発行メールを送信しました。\nメールをご確認ください。', DIALOG_TYPE.MESSAGE, () => {
          this.$router.push('/system_management_operator_landing')
        })
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('パスワード再発行の受付失敗', 'パスワードの再発行受付に失敗しました。')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-forgot-area-block-operator-password-view {
  background-color: #eee;
  .navbar {
    .main-bar {
      background-color: #066292;
      background-image: url("/static/images/operator_header.png");
    }
  }
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
  }
  h1.centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .message-column {
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .signin-block {
    margin: 20px auto;
    padding: 40px;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #aaa;
    text-align: center;
    .signin_id_input {
      margin-bottom: 20px;
      label {
        margin-right: 10px;
      }
      input {
        font-size: 16px;
        border: 1px solid #aaa;
      }
    }
    .signin-btn {
      width: 150px;
      font-weight: bold;
    }
  }
}
</style>
