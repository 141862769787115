<template>
  <div class="horus-contact-form-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <horus-contact-form-column
        :lang="currentLang"
        v-on:switchLangEvent="switchLang"/>
    </div>
    <horus-footer />
  </div>
</template>

<script>
// import Vue from "vue"
// import { VueReCaptcha } from "vue-recaptcha-v3"
// import * as gRecaptchaConf from '@/assets/constants/gReCaptchaConf.js'

// var gSitekey = ''
// if (process.env.NODE_ENV === 'development') {
//   gSitekey = gRecaptchaConf.development.SITE_KEY
// } else if (process.env.NODE_ENV === 'staging') {
//   gSitekey = gRecaptchaConf.staging.SITE_KEY
// } else if (process.env.NODE_ENV === 'production') {
//   gSitekey = gRecaptchaConf.production.SITE_KEY
// }
// Vue.use(VueReCaptcha, { siteKey: gSitekey });

import HorusContactFormColumn from '@/components/common/HorusContactFormColumn.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusContactFormView',

  components: {
    HorusContactFormColumn,
    HorusFooter
  },

  props: {
    lang: String
  },

  data () {
    return {
      currentLang: 'jp'
    }
  },

  watch: {
    lang: { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.lang) {
        this.currentLang = this.lang
      }
    },

    switchLang (lang) {
      this.currentLang = lang
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-contact-form-view {
  .contents {
    padding: 10px 100px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
  }
}
</style>
