<template>
  <div class="horus-user-address-edit-modal">
    <horus-modal-frame
      :showModalFlag="showUserAddressEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />ユーザー住所情報編集
        </h1>
      </div>
      <div class="modal-body">
        <horus-user-address-form
          :timelineOwner="timelineOwner"
          :userAddressSource="userAddressSource"
          :submitTitle="'更  新'"
          v-on:cancelSubmitEvent="cancelSubmit"
          v-on:executeSubmitEvent="executeSubmit"
          />
      </div>
      <div class="modal-footer"></div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusUserAddressForm from '@/components/profile/HorusUserAddressForm.vue'

export default {
  name: 'HorusUserAddressEditModal',

  components: {
    HorusModalFrame,
    HorusUserAddressForm
  },

  props: {
    timelineOwner: Object,
    showUserAddressEditModal: Boolean,
    userAddressSource: Object
  },

  methods: {
    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    executeSubmit (formData) {
      this.$emit('executeSubmitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-user-address-edit-modal {
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .modal {
    position: absolute;
    width: 900px;
    max-height: calc(100vh - 60px);
    margin: 0;
    padding: 0;
    top: 30px;
    left: calc((100vw - 900px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    .modal-header {
      padding: 10px;
      border-bottom: 1px solid #aaa;
      h1 {
        margin: 0;
        font-size: 14px;
        color: #666;
        text-align: center;
        img {
          margin-right: 10px;
          height: 15px;
        }
      }
    }
    .modal-body {
      padding: 10px;
      // input[type="text"] {
      //   font-size: 16px;
      // }
    }
  }
}
</style>
