<template>
  <div class="horus-receive-reporter-request-for-user-column labeled_content_block">
    <div class="labeled_content_single_block">
      <label>施設番号</label>
      <div class="content">{{ receiveRequest.reporter_organization.numberForHuman }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>名称</label>
      <div class="content">{{ receiveRequest.reporter_organization.name }}({{ receiveRequest.reporter_organization.short_name }})</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ receiveRequest.reporter_organization.tel }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ receiveRequest.status }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>メッセージ</label>
      <div class="content" v-html="messageContent"></div>
    </div>
    <div class="content-controll-block">
      <button type="button" @click="confirmRequest">承認もしくは拒否の処理</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusReceiveRequestForUserColumn',

  props: {
    receiveRequest: Object
  },

  computed: {
    messageContent () {
      if (this.receiveRequest.message_content && this.receiveRequest.message_content.length > 0) {
        return this.receiveRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return 'サービス利用案内のメッセージはありません。'
      }
    }
  },

  methods: {
    confirmRequest () {
      this.$emit('confirmRequestEvent', this.receiveRequest)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
