import { Model } from '@vuex-orm/core'
import TimelineRecord from '@/models/TimelineRecord.js'

export default class MillviVideoContent extends Model {
  static entity = 'millviVideoContents'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      record_id: this.attr(null),
      content_id: this.number(0),
      duration: this.number(0.0),
      content_state: this.number(0)
    }
  }

  get timelineRecord () {
    return TimelineRecord.find(this.record_id)
  }

  get stateJp () {
    switch (this.content_state) {
      case 0:
        return 'なし'
      case 10:
        return '完了'
      case 20:
        return '処理中'
      case 30:
        return '準備完了'
      case 50:
        return '失敗'
      default:
        return 'なしーー'
    }
  }

  get thumbnailSrc () {
    return process.env.VUE_APP_BASE_URL + '/record/show_video_thumbnail?target_user_id=' + this.user_id + '&video_content_id=' + this.id + '&version=thumb'
  }

  get contentName () {
    return this.timelineRecord.targetAtStr + ' ' + this.timelineRecord.author.name + 'の動画'
  }

  get targetAt () {
    return this.timelineRecord.targetAt
    // return new Date(this.target_at)
  }

  static apiConfig = {
    actions: {
      fetchCount ({ timelineOwnerID }) {
        return this.request({
          method: 'get',
          url: 'video_content/count',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          return { count: res.response.data.video_content_count }
        })
      },
    
      fetchIDList ({ timelineOwnerID, options }) {
        var params = { target_user_id: timelineOwnerID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'video_content/list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { videoContents: res.response.data.video_contents }
        })
      },
    
      fetchTermIDList ({timelineOwnerID, targetDate, options}) {
        var params = { target_user_id: timelineOwnerID, target_date: targetDate }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'video_content/term_list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { videoContents: res.response.data.video_contents, targetVideoID: res.response.data.target_video_id }
        })
      },
    
      fetchLatestIDList ({timelineOwnerID, targetVideoID, options}) {
        var params = { target_user_id: timelineOwnerID, video_content_id: targetVideoID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'video_content/latest_list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { videoContents: res.response.data.video_contents }
        })
      },
    
      fetchOldIDList ({timelineOwnerID, targetVideoID, options}) {
        var params = { target_user_id: timelineOwnerID, video_content_id: targetVideoID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'video_content/old_list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { videoContents: res.response.data.video_contents }
        })
      },
    
      latestExist ({ timelineOwnerID, videoContentID, options }) {
        var params = { target_user_id: timelineOwnerID, video_content_id: videoContentID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'video_content/latest_exist',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { latestExist: res.response.data.latestExist }
        })
      },
    
      oldExist ({ timelineOwnerID, videoContentID, options }) {
        var params = { target_user_id: timelineOwnerID, video_content_id: videoContentID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'video_content/old_exist',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { oldExist: res.response.data.oldExist }
        })
      }
    }
  }
}
