// var hashTags: [] MUST imprement.

import * as PRESENTED_MODAL_TYPE from '@/assets/constants/modalType.js'

export default {

  data () {
    return {
      // hashTags: [],
      selectTargetsForTag: [],
      createdTargetForTag: []
      // signinMember: false
    }
  },

  methods: {
    tagSelectionModalClose () {
      this.presentedModal = PRESENTED_MODAL_TYPE.NONE
    },

    updateSelectedTag (targetTag, currentActive, currentEnabled) {
      if (currentEnabled) {
        if (currentActive) {
          var selectedTag = this.selectTargetsForTag.find(tag => tag.id === targetTag.id)
          if (selectedTag) {
            var tagIndex = this.selectTargetsForTag.indexOf(selectedTag)
            if (tagIndex >= 0) {
              this.selectTargetsForTag.splice(tagIndex, 1)
            }
          }
        } else {
          this.selectTargetsForTag.push(targetTag)
        }
      } else {
        this.selectTargetsForTag.push(targetTag)
      }
    },

    addNewPostTag (newTagName) {
      if (this.createdTargetForTag.indexOf(newTagName) < 0) {
        this.createdTargetForTag.push(newTagName)
      }
    },

    removeNewPostTag (index) {
      this.createdTargetForTag.splice(index, 1)
    },

    clearSelectedTags () {
      this.selectTargetsForTag.splice(0, this.selectTargetsForTag.length)
      this.createdTargetForTag.splice(0, this.createdTargetForTag.length)
    }

  }
}
