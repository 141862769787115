import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'
import StaffVisibility from './StaffVisibility'

const MY_FUNCTION_MEDICAL_EXAMINATION = 0
const MY_FUNCTION_DRUG_ENTRY = 1
const MY_FUNCTION_CALL_VIDEO_MESSAGE = 10
const MY_FUNCTION_ONLINE_MEDICAL = 11

export default class Organization extends Model {
  static entity = 'organizations'

  static fields () {
    return {
      id: this.attr(null),
      organizationCode: this.attr(''),
      organizationNumber: this.attr(''),
      name: this.attr(''),
      nameKana: this.attr(''),
      shortName: this.attr(''),
      prefecture: this.attr(''),
      city: this.attr(''),
      addressDetail: this.attr(''),
      address: this.attr(''),
      zipCode: this.attr(''),
      tel: this.attr(''),
      mail: this.attr(''),
      deleted: this.attr(false),
      deputizable: this.attr(false),
      deputize_available: this.attr(false),
      additionalFunctions: this.attr(null),
      thirdPartyLinkages: this.attr(null),
      help_path: this.attr(''),
      medicalInstitutionCode: this.attr(''),
      organization_type: this.attr(null),
      revoke_log: this.attr(null),
      revoke_requested: this.attr(false)
    }
  }

  get numberForHuman () {
    if (this.organizationNumber) {
      return this.organizationNumber.substr(0, 4) + '-' + this.organizationNumber.substr(4, 4) + '-' + this.organizationNumber.substr(8, 4) + '-' + this.organizationNumber.substr(12, 4)
    } else {
      return ''
    }
  }

  get members () {
    return Author.query().where('organization_id', this.id).get()
  }

  get medicalExaminationAvailable () {
    return this.additionalFunctions.some(func => func === MY_FUNCTION_MEDICAL_EXAMINATION)
  }

  get drugEntryAvailable () {
    return this.additionalFunctions.some(func => func === MY_FUNCTION_DRUG_ENTRY)
  }

  get callVideoMeetingAvailable () {
    return this.additionalFunctions.some(func => func === MY_FUNCTION_CALL_VIDEO_MESSAGE)
  }

  get onlineMedicalAvailable () {
    return this.additionalFunctions.some(func => func === MY_FUNCTION_ONLINE_MEDICAL)
  }

  get applyingForRevoke () {
    if (this.revoke_log && !this.revoke_log.restored && this.revoke_log.revoke_key.length > 0) {
      return true
    } else {
      return false
    }
  }

  get usersChargedMembers () {
    var visibilities = StaffVisibility.query().where('organizationID', this.id).get()
    var userSet = []
    visibilities.forEach(visibility => {
      var usrExist = false
      userSet.forEach(uset => {
        if (uset.userID === visibility.userID) {
          usrExist = true
          uset.visibilities.push(visibility)
        }
      })
      if (!usrExist) {
        userSet.push({ userID: visibility.userID, visibilities: [visibility] })
      }
    })
    return userSet
  }

  chargedMembers (timelineOwnerID) {
    return StaffVisibility.query().where('organizationID', this.id).where('userID', timelineOwnerID).get()
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static apiConfig = {
    actions: {
      fetchByID ({idList}) {
        return this.request({
          method: 'get',
          url: 'organization/list_by_id',
          withCredentials: true,
          params: { organizations: idList },
          dataKey: 'organizations'
        })
        .then(res => {
          // console.log(res)
          Organization.commit((state) => {
            state.fetchedAt = new Date()
          })
          if (res.entities && res.entities.organizations) {
            return res.entities.organizations
          } else {
            return []
          }
        })
      },

      async update ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'organization',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async create ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'organization',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async updateOptions ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'organization/update_options',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async updateAreaBlock ({ organizationID, areaBlockID }) {
        var formData = new FormData()
        formData.append('id', organizationID)
        formData.append('area_block_id', areaBlockID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'organization/update_area_block',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会申請（施設管理者）
      async prepareRevoke ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'organization/prepare_revoke',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会申請取り消し（システム管理者）
      async cancelRevoke ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'organization/cancel_revoke',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会受理(システム管理者)
      async revoke ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'organization/revoke',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会復帰(システム管理者)
      async restore ({organizationID}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'organization/restore',
            withCredentials: true,
            params: { id: organizationID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 未使用施設の削除
      destroy ({organizationID}) {
        return this.request({
          ...axiosHelper.csrfHeader(),
          ...{
            method: 'delete',
            url: 'organization',
            headers: {
              'X-HTTP-Method-Override': 'DELETE',
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
            params: { id: organizationID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
        .catch(error => { throw error })
      },

      // non store actions
      fetchRelatedIDList ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'organization/related_id_list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          if (res.response.data.success === true) {
            return { organizations: res.response.data.organizations }
          }
        })
        .catch(error => { throw error })
      },
    
      fetch ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'organization/related_list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        }).then(res => {
          return { organizations: res.response.data.organizations }
        }).catch(error => { throw error })
      },
    
      findByNumber ({organizationNumber}) {
        return this.request({
          method: 'get',
          url: 'organization/find_by_number',
          withCredentials: true,
          params: { organization_number: organizationNumber },
          save: false
        })
        .then(res => {
          return { organizations: res.response.data.organization }
        })
        .catch(error => { throw error })
      },
    
      listWithSummary () {
        return this.request({
          method: 'get',
          url: 'organization/list_with_summary',
          withCredentials: true,
          save: false
        })
        .then(res => {
            return { organizations: res.response.data.organizations }
        })
        .catch(error => { throw error })
      },
    
      fetchWithSummary ({organizationID}) {
        return this.request({
          method: 'get',
          url: 'organization/find_with_summary',
          withCredentials: true,
          params: { organization_id: organizationID },
          save: false
        })
        .then(res => {
          return { organization: res.response.data.organization }
        })
        .catch(error => { throw error })
      },
    }
  }
}
