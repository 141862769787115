import axios from './AxiosInstance'

const crypto = require('crypto')

export default {
  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'app_signin_token',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({targetUserID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'app_signin_token',
            withCredentials: true,
            params: { target_user_id: targetUserID, app_signin_token_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetch: ({targetUserID, userNormal}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'app_signin_token/list',
        withCredentials: true,
        params: { target_user_id: targetUserID, normal_user: userNormal }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ authorID: res.data.author_id, appSigninTokens: res.data.app_signin_tokens })
        }
      }).catch(error => { reject(error) })
    })
  },

  // test signin action---------
  testSignin: ({nbID, signinToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'app_signin_token/challenge',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          var challenge = res.data.challenge
          console.log('challenge: ' + challenge)
          var hashedToken = crypto.createHash('sha256').update(signinToken + challenge, 'utf8').digest('hex')
          var formData = new FormData()
          formData.append('nbid', nbID)
          formData.append('token', hashedToken)
          axios.csrfHeader('PUT').then(header => {
            axios.httpClient({
              ...header,
              ...{
                method: 'post',
                url: 'authenticate/signin_by_app_token',
                withCredentials: true,
                data: formData
              }
            }).then(res => {
              if (res.data.success === true) {
                resolve(res.data)
              }
            }).catch(error => {
              reject(error)
            })
          })
        }
      }).catch(error => {
        reject(error)
      })
    })
  }
}
