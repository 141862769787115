<template>
  <div class="horus-system-management-archive-modal">
    <horus-modal-frame
      :showModalFlag="showArchiveModal"
      v-on:backdropClickEvent="closeModal">
      <div class="modal-header">
        <h1>
          <img src="/static/images/pencil_icon.png" class="title-icon" />
          アーカイブ作成リクエスト詳細
          <img class="close-icon" src="/static/images/x_icon.png" @click="closeModal" />
        </h1>
      </div>
      <div class="modal-body">
        <div class="arcive" v-if="request">
          <div class="archive-info">
            <div class="archive-info-title">リクエストユーザー:</div>
            <div class="archive-info-content">{{ request.request_nbid }}({{ request.request_name }})</div>
          </div>
          <div class="archive-info">
            <div class="archive-info-title">アーカイブ期間:</div>
            <div class="archive-info-content">{{ term }}</div>
          </div>
          <div class="archive-info">
            <div class="archive-info-title">依頼日:</div>
            <div class="archive-info-content">{{ requestAt }}</div>
          </div>
          <div class="archive-info">
            <div class="archive-info-title">ステータス:</div>
            <div class="archive-info-content">{{ request.status }}</div>
          </div>
          <div class="archive-info" v-if="request.rejected_at">
            <div class="archive-info-title">拒否理由:</div>
            <div class="archive-info-content" v-html="rejectMessageHTML"></div>
          </div>
          <div class="archive-info" v-if="request.data_created_at">
            <div class="archive-info-title">承認日時:</div>
            <div class="archive-info-content">{{ dataCreateAt }}</div>
          </div>
          <div class="archive-info" v-if="request.published_at">
            <div class="archive-info-title">アーカイブ作成日時:</div>
            <div class="archive-info-content">{{ publisedAt }}</div>
          </div>
          <div class="archive-info" v-if="request.published_at">
            <div class="archive-info-title">データファイルサイズ:</div>
            <div class="archive-info-content">{{ request.data_size }}</div>
          </div>
          <div class="build-log">
            <div class="build-log-title">ビルドログ</div>
            <div class="build-log-content" v-html="buildLogHTML">
            </div>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemManagementArchiveModal',

  components: {
    HorusModalFrame
  },

  props: {
    request: Object,
    showArchiveModal: Boolean
  },

  computed: {
    term () {
      return moment(this.request.term_start).format('YYYY/MM/DD') + '〜' + moment(this.request.term_end).format('YYYY/MM/DD')
    },

    requestAt () {
      return moment(this.request.request_at).format('YYYY/MM/DD HH:mm')
    },

    dataCreateAt () {
      return moment(this.request.data_created_at).format('YYYY/MM/DD HH:mm')
    },

    rejectedAt () {
      return moment(this.request.rejected_at).format('YYYY/MM/DD HH:mm')
    },

    rejectMessageHTML () {
      if (this.request.reject_message) {
        return this.request.reject_message.replace(/\n/gi, '<br />')
      } else {
        return ''
      }
    },

    buildLogHTML () {
      if (this.request.build_log) {
        return this.request.build_log.replace(/\n/gi, '<br />')
      } else {
        return ''
      }
    },

    publishedAt () {
      return moment(this.request.published_at).format('YYYY/MM/DD HH:mm')
    }
  },

  methods: {
    closeModal () {
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-management-archive-modal {
  .modal-body {
    padding: 10px;
    .arcive {
      .archive-info {
        display: flex;
        margin: 5px 0;
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        .archive-info-title {
          padding-right: 5px;
          width: 200px;
          text-align: right;
          font-weight: bold;
        }
      }
      .build-log {
        .build-log-title {
          font-weight: bold;
        }
        .build-log-content {
          margin: 10px;
          padding: 0 5px;
          height: 200px;
          overflow: scroll;
          border: 1px solid #ddd;
          font-family: "Courier New", Consolas, monospace;
        }
      }
    }
  }
}
</style>
