<template>
  <div class="horus-revoke-finsh-view container">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">ナラティブブック退会完了</h1>
      <div class="message-column">
        ナラティブブック退会手続きを完了しました。<br/>
        今までナラティブブックをご利用いただきまして、誠にありがとうございました。<br/>
        <button type="button" class="btn" @click="jumpToTop">トップページへ戻る</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusRevokeFinishView',

  methods: {
    jumpToTop () {
      this.$router.push('/landing')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-revoke-finsh-view {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
  }
  h1.centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .message-column {
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    .btn {
      margin-top: 20px;
      padding: 3px 10px;
      border: 1px solid #4cae4c;
      border-radius: 5px;
      background-color: #4cae4c;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
