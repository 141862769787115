<template>
  <div class="horus-manage-critical-notification">
    <h1 class="centering-title">重要警告管理</h1>
    <div class="summary-column" v-if="criticalNotificationSummary">
      <h2>発火中重要警告</h2>
      <div class="total-summary">
        <div class="single-column"><span class="title">総件数</span><span class="value">{{ criticalNotificationSummary.total.fired }}</span></div>
        <div class="single-column"><span class="title">解決済み件数</span><span class="value">{{ criticalNotificationSummary.total.resolved }}</span></div>
        <div class="single-column"><span class="title">対象のべユーザー数</span><span class="value">{{ criticalNotificationSummary.total.targets }}</span></div>
        <div class="single-column"><span class="title">未解決のべユーザー数</span><span class="value">{{ criticalNotificationSummary.total.non_resolved_targets }}</span></div>
        <div class="single-column"><span class="title">未解決ユニークユーザー数</span><span class="value">{{ criticalNotificationSummary.total.uniq_non_resolved }}</span></div>
      </div>
      <h2>発火中ログイン停止重要警告</h2>
      <div class="total-summary">
        <div class="single-column"><span class="title">総件数</span><span class="value">{{ criticalNotificationSummary.blocking.fired }}</span></div>
        <div class="single-column"><span class="title">解決済み件数</span><span class="value">{{ criticalNotificationSummary.blocking.resolved }}</span></div>
        <div class="single-column"><span class="title">対象のべユーザー数</span><span class="value">{{ criticalNotificationSummary.blocking.targets }}</span></div>
        <div class="single-column"><span class="title">未解決のべユーザー数</span><span class="value">{{ criticalNotificationSummary.blocking.non_resolved_targets }}</span></div>
        <div class="single-column"><span class="title">未解決ユニークユーザー数</span><span class="value">{{ criticalNotificationSummary.blocking.uniq_non_resolved }}</span></div>
      </div>
    </div>
    <div class="seach-controll"><button class="search-btn" @click="openSearchModal">重要警告検索</button></div>
    <div class="now-loading" v-if="!criticalNotificationSummary"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div class="notification-list" v-if="criticalNotifications">
      <h2>重要警告一覧<button class="create-btn" @click="createNotification">新規作成</button></h2>
      <div class="list-controll">
        <div class="list-controll-block">
          <div class="controll-unit" @click="showNonFiredToggle">
            <horus-custom-check-box
              :active="showNonFired"
              :enabled="true"
              :type="'color'"
              />未発火
          </div>
          <div class="controll-unit" @click="showFiredToggle">
            <horus-custom-check-box
              :active="showFired"
              :enabled="true"
              :type="'color'"
              />発火中(未解決)
          </div>
          <div class="controll-unit" @click="showResolvedToggle">
            <horus-custom-check-box
              :active="showResolved"
              :enabled="true"
              :type="'color'"
              />解決済み
          </div>
          <div class="controll-unit" @click="showDestroiedToggle">
            <horus-custom-check-box
              :active="showDestroied"
              :enabled="true"
              :type="'color'"
              />削除済み
          </div>
        </div>
        <div class="controll-unit" @click="showBlockingOnlyToggle">
          <horus-custom-check-box
            :active="showBlockingOnly"
            :enabled="true"
            :type="'color'"
            />ログイン停止のみ表示
        </div>
      </div>
      <div class="list-controll">
        <div class="controll-unit">
          <span class="index">作成日時</span>
          <span class="direction-controll" :class="{active: sortKey === 'created' && sortDirection === 'desc'}" @click="sortNotification('created', 'desc')">▲</span>
          <span class="direction-controll" :class="{active: sortKey === 'created' && sortDirection === 'asc'}" @click="sortNotification('created', 'asc')">▼</span>
        </div>
        <div class="controll-unit">
          <span class="index">更新日時</span>
          <span class="direction-controll" :class="{active: sortKey === 'updated' && sortDirection === 'desc'}" @click="sortNotification('updated', 'desc')">▲</span>
          <span class="direction-controll" :class="{active: sortKey === 'updated' && sortDirection === 'asc'}" @click="sortNotification('updated', 'asc')">▼</span>
        </div>
        <div class="controll-unit" :class="{ disable: showNonFired }">
          <span class="index">発火日時</span>
          <span class="direction-controll" :class="{active: sortKey === 'fired' && sortDirection === 'desc'}" @click="sortNotification('fired', 'desc')">▲</span>
          <span class="direction-controll" :class="{active: sortKey === 'fired' && sortDirection === 'asc'}" @click="sortNotification('fired', 'asc')">▼</span>
        </div>
      </div>
      <div class="notifications">
        <div class="list-line" v-for="notification in displayNotifications" :key="'criticalNotifyList' + notification.id">
          <div class="list-state">
            <div class="list-column"><span class="title">警告タイプ</span><span class="value">{{ notification.notification_type_str }}</span></div>
            <div class="list-column"><span class="title">解決タイプ</span><span class="value">{{ notification.resolve_type_str }}</span></div>
            <div class="list-column"><span class="title">ログイン停止</span><span class="value" :class="{notify: notification.block_signin }">{{ notification.block_signin ? '停止' : 'なし' }}</span></div>
            <div class="list-column"><span class="title">発火</span><span class="value">{{ notification.activated_at_str }}</span></div>
            <div class="list-column"><span class="title">作成</span><span class="value">{{ notification.created_at_str }}</span></div>
            <div class="list-column"><span class="title">更新</span><span class="value">{{ notification.updated_at_str }}</span></div>
            <div class="list-column"><span class="title">対象ユーザー数</span><span class="value">{{ notification.target_count }}</span></div>
            <div class="list-column"><span class="title">解決率</span><span class="value">{{ notification.resolve_ratio > 0 ? notification.resolve_ratio * 100 : '0' }}％</span></div>
            <div class="list-column"><span class="title">タイトル</span><span class="value">{{ notification.title }}</span></div>
            <div class="list-column"><span class="title">本文</span><span class="value">{{ notification.message.substring(0, 20) + '...' }}</span></div>
            <div class="list-column"><span class="title">状態</span><span class="value">{{ notification.state }}</span></div>
            <div class="controll-column">
              <button class="detail-btn" @click="showNotification(notification)">詳細</button>
              <button class="edit-btn" @click="editNotification(notification)" v-if="notification.editable">編集</button>
              <button class="delete-btn" @click="destroyNotification(notification)" v-if="notification.deletable">削除</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <horus-critical-notification-form-modal
      :showModal="displayNotificationFormModal"
      :sourceNotification="editTargetNotification"
      v-on:cancelSubmitEvent="cancelSubmitNotificationForm"
      v-on:submitEvent="submitNotificationForm"
      />
    <horus-critical-notification-detail-modal
      :showModal="displayNotificationDetailModal"
      :notification="detailDisplayNotification"
      :targetOrganizations="detailDisplayNotificationTargetOrganizations"
      v-on:closeModalEvent="hideNotificationDetail"
      v-on:activateEvent="prepareFireNotification"
      v-on:resolveNotificationTargetEvent="resoleveNotificationTarget"
      />
    <horus-critical-notification-fire-modal
      :showModal="displayNotificationFireModal"
      :notification="detailDisplayNotification"
      :targetOrganizations="detailDisplayNotificationTargetOrganizations"
      v-on:cancelFireEvent="cancelFireNotification"
      v-on:fireEvent="confirmFireNotification"
      />
    <horus-manage-critical-notification-search-modal
      :showModal="searchModalDisplay"
      v-on:resolveTargetEvent="resolveSearchedCriticalNotificationTarget"
      v-on:closeModalEvent="closeSeachModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  AreaBlock,
  Organization
} from '@/models'

import {
  CriticalNotification
} from '@/api'

import HorusCriticalNotificationFormModal from '@/components/manageSystem/criticalNotification/HorusCriticalNotificationFormModal.vue'
import HorusCriticalNotificationDetailModal from '@/components/manageSystem/criticalNotification/HorusCriticalNotificationDetailModal.vue'
import HorusCriticalNotificationFireModal from '@/components/manageSystem/criticalNotification/HorusCriticalNotificationFireModal.vue'
import HorusManageCriticalNotificationSearchModal from '@/components/manageSystem/criticalNotification/HorusManageCriticalNotificationSearchModal.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
// import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageCriticalNotificationView',

  components: {
    HorusCriticalNotificationFormModal,
    HorusCriticalNotificationDetailModal,
    HorusCriticalNotificationFireModal,
    HorusManageCriticalNotificationSearchModal,
    HorusCustomCheckBox,
    HorusConfirmDialog
  },

  props: {
    systemManager: Object
  },

  data () {
    return {
      areaBlocks: [],
      criticalNotificationSummary: null,
      criticalNotifications: [],
      displayNotifications: [],
      showBlockingOnly: false,
      showResolved: true,
      showNonFired: true,
      showFired: true,
      showDestroied: false,
      sortKey: 'created',
      sortDirection: 'desc',
      editTargetNotification: null,
      detailDisplayNotification: null,
      detailDisplayNotificationTargetOrganizations: [],
      displayNotificationFormModal: false,
      displayNotificationDetailModal: false,
      displayNotificationFireModal: false,
      searchModalDisplay: false
    }
  },

  async mounted () {
    await this.loadAreaBlocks()
    this.loadSummary()
    this.loadNotifications()
  },

  methods: {
    async loadAreaBlocks () {
      await AreaBlock.api().fetchList().then(res => {
        console.log(res)
        this.areaBlocks = res.area_blocks
      })
    },

    loadSummary () {
      CriticalNotification.fetchSummary().then(res => {
        this.criticalNotificationSummary = res
      }).catch(err => {
        console.error(err)
      })
    },

    async loadNotifications () {
      this.criticalNotifications = []
      let res = await CriticalNotification.fetchList({ displayType: 'all' })
      for(var notification of res.critical_notifications) {
        this.criticalNotifications.push(this.formNotification(notification))
        console.log('load notificatios, each block.....' + notification)
      }
      this.updateDisplay()
    },

    formNotification (rawNotification) {
      rawNotification.activated_at_str = this.dateString(rawNotification.activated_at)
      rawNotification.created_at_str = this.dateString(rawNotification.created_at)
      rawNotification.updated_at_str = this.dateString(rawNotification.updated_at)
      var stateStr = ''
      if (rawNotification.deleted) {
        stateStr = '削除'
      } else if (rawNotification.activated_at) {
        stateStr = '発火中'
        if (rawNotification.resolve_ratio >= 1.0) {
          stateStr = '解決済み'
        }
      } else {
        stateStr = '未発火'
      }
      rawNotification.state = stateStr
      if (rawNotification.activated_at || rawNotification.deleted) {
        rawNotification.editable = false
      } else {
        rawNotification.editable = true
      }
      if (rawNotification.deleted) {
        rawNotification.deletable = false
      } else {
        rawNotification.deletable = true
      }
      // set area_block
      if (rawNotification.area_block_id) {
        rawNotification.areaBlock = this.areaBlocks.find(rawNotification.area_block_id)
      }
      return rawNotification
    },

    dateString (dateText) {
      // console.log(dateText)
      if (dateText) {
        var targetDate = new Date(dateText)
        // console.log(targetDate)
        if (targetDate) {
          return moment(targetDate).format('YYYY/MM/DD HH:mm')
        } else {
          return '-----'
        }
      } else {
        return '-----'
      }
    },

    updateDisplay () {
      console.log('update display call,')
      var tempArray = []
      if (this.showResolved) {
        tempArray = this.criticalNotifications.filter(notify => (notify.resolve_ratio >= 1 && !notify.deleted))
      }
      if (this.showNonFired) {
        tempArray = tempArray.concat(this.criticalNotifications.filter(notify => (!notify.activated_at && !notify.deleted)))
      }
      if (this.showFired) {
        tempArray = tempArray.concat(this.criticalNotifications.filter(notify => (notify.activated_at && notify.resolve_ratio < 1 && !notify.deleted)))
      }
      if (this.showDestroied) {
        tempArray = tempArray.concat(this.criticalNotifications.filter(notify => notify.deleted))
      }
      // console.log(tempArray)
      var uniqSet
      if (this.showBlockingOnly) {
        uniqSet = new Set(tempArray.filter(notify => notify.block_signin))
        this.displayNotifications = Array.from(uniqSet)
      } else {
        uniqSet = new Set(tempArray)
        this.displayNotifications = Array.from(uniqSet)
      }
      this.sortDisplayNotifications()
    },

    showNonFiredToggle () {
      this.showNonFired = !this.showNonFired
      if (this.sortKey === 'fired') {
        this.sortKey = 'created'
      }
      this.updateDisplay()
    },

    showFiredToggle () {
      this.showFired = !this.showFired
      this.updateDisplay()
    },

    showResolvedToggle () {
      this.showResolved = !this.showResolved
      this.updateDisplay()
    },

    showDestroiedToggle () {
      this.showDestroied = !this.showDestroied
      this.updateDisplay()
    },

    showBlockingOnlyToggle () {
      this.showBlockingOnly = !this.showBlockingOnly
      this.updateDisplay()
    },

    sortNotification (sortKey, direction) {
      console.log('sortNotification, showNonFired:' + this.showNonFired + ' sortKey:' + sortKey)
      if (sortKey === 'fired' && this.showNonFired) {
        console.log('block sort')
      } else {
        console.log('gonna update sort')
        this.sortKey = sortKey
        this.sortDirection = direction
        this.sortDisplayNotifications()
      }
    },

    sortDisplayNotifications () {
      console.log('sortDisplayNotifications:' + this.sortKey + '/' + this.sortDirection)
      if (this.sortKey === 'fired') {
        if (this.sortDirection === 'asc') {
          this.displayNotifications.sort((f, s) => {
            if (f.activated_at > s.activated_at) {
              return 1
            } else if (f.activated_at < s.activated_at) {
              return -1
            } else {
              return 0
            }
          })
        } else {
          // this.displayNotifications.sort((f, s) => s.activated_at - f.activated_at)
          this.displayNotifications.sort((f, s) => {
            if (f.activated_at > s.activated_at) {
              return -1
            } else if (f.activated_at < s.activated_at) {
              return 1
            } else {
              return 0
            }
          })
        }
      } else if (this.sortKey === 'updated') {
        if (this.sortDirection === 'asc') {
          // this.displayNotifications.sort((f, s) => f.updated_at - s.updated_at)
          this.displayNotifications.sort((f, s) => {
            if (f.updated_at > s.updated_at) {
              return 1
            } else if (f.updated_at < s.updated_at) {
              return -1
            } else {
              return 0
            }
          })
        } else {
          // this.displayNotifications.sort((f, s) => s.updated_at - f.updated_at)
          this.displayNotifications.sort((f, s) => {
            if (f.updated_at > s.updated_at) {
              return -1
            } else if (f.updated_at < s.updated_at) {
              return 1
            } else {
              return 0
            }
          })
        }
      } else {
        if (this.sortDirection === 'asc') {
          console.log('sort created asc')
          // this.displayNotifications.sort((f, s) => f.created_at - s.created_at)
          this.displayNotifications.sort((f, s) => {
            console.log(f.created_at + '/' + s.created_at)
            if (f.created_at > s.created_at) {
              return 1
            } else if (f.created_at < s.created_at) {
              return -1
            } else {
              return 0
            }
          })
        } else {
          console.log('sort created desc')
          // this.displayNotifications.sort((f, s) => s.created_at - f.created_at)
          this.displayNotifications.sort((f, s) => {
            if (f.created_at > s.created_at) {
              return -1
            } else if (f.created_at < s.created_at) {
              return 1
            } else {
              return 0
            }
          })
        }
      }
      console.log(this.displayNotifications)
    },

    createNotification () {
      this.editTargetNotification = null
      this.displayNotificationFormModal = true
    },

    editNotification (notification) {
      this.editTargetNotification = notification
      this.displayNotificationFormModal = true
    },

    cancelSubmitNotificationForm (callback) {
      this.editTargetNotification = null
      this.displayNotificationFormModal = false
      callback()
    },

    submitNotificationForm (formData, callback) {
      if (this.editTargetNotification) {
        formData.append('critical_notification_id', this.editTargetNotification.id)
        // update
        CriticalNotification.update({ formData: formData }).then(() => {
          this.loadSummary()
          this.loadNotifications()
          this.showNotifyDialog('更新', '重要警告を更新しました。')
          this.displayNotificationFormModal = false
          callback()
        }).catch(err => {
          console.error(err.response)
          this.showNotifyDialog('更新失敗', '重要警告の更新に失敗しました。')
        })
      } else {
        CriticalNotification.create({ formData: formData }).then(() => {
          this.showNotifyDialog('新規作成', '重要警告を新規作成しました。警告を発火するまで有効になりません。')
          this.loadSummary()
          this.loadNotifications()
          this.editTargetNotification = null
          this.displayNotificationFormModal = false
          callback()
        }).catch(err => {
          console.error(err.response)
          this.showNotifyDialog('新規作成失敗', '重要警告の新規作成に失敗しました。')
        })
      }
    },

    async showNotification (notification) {
      this.detailDisplayNotification = notification
      await this.loadTargetOrganizations()
      this.displayNotificationDetailModal = true
    },

    async loadTargetOrganizations () {
      if (this.detailDisplayNotification.organizations && this.detailDisplayNotification.organizations.length > 0) {
        var unStoredOrgs = []
        this.detailDisplayNotification.organizations.forEach(orgID => {
          if (!Organization.find(orgID)) {
            unStoredOrgs.push(orgID)
          }
        })
        if (!unStoredOrgs.length > 0) {
          Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unStoredOrgs }).then(() => {
            this.detailDisplayNotificationTargetOrganizations = Organization.findIn(this.detailDisplayNotification.organizations)
          })
        }
      } else {
        this.detailDisplayNotificationTargetOrganizations = []
      }
    },

    hideNotificationDetail () {
      this.detailDisplayNotification = null
      this.displayNotificationDetailModal = false
    },

    destroyNotification (notification) {
      var message = '重要警告を削除します。\nよろしいですか？'
      if (notification.activated_at) {
        if (notification.block_signin) {
          message = '重要警告を削除します。\nこの重要警告は現在発火中です。削除すると、警告を未解決であるためにログインをブロックされているユーザーもログイン可能となります。\nよろしいですか？'
        } else {
          message = '重要警告を削除します。\nこの重要警告は現在発火中です。削除すると、警告を未解決のユーザーも警告の強制表示が解除されます。\nよろしいですか？'
        }
      }
      this.showConfirmDialog(
        '重要警告の削除',
        message,
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroyNotification(notification) }
      )
    },

    executeDestroyNotification (notification) {
      CriticalNotification.destroy({ targetID: notification.id }).then(() => {
        this.loadSummary()
        this.loadNotifications()
      }).catch(err => {
        console.error(err)
      })
    },

    prepareFireNotification () {
      if (this.detailDisplayNotification) {
        if (!this.detailDisplayNotification.activeted_at) {
          this.displayNotificationFireModal = true
        } else {
          this.showNotifyDialog('重要警告発火', '指定された重要警告は発火済みです')
        }
      } else {
        this.showNotifyDialog('重要警告発火', '重要警告が指定されていません')
      }
    },

    cancelFireNotification () {
      this.displayNotificationFireModal = false
    },

    confirmFireNotification (selectedUsers = null) {
      var targetCount = this.detailDisplayNotification.estimated_target_count
      if (selectedUsers && selectedUsers.length > 0) {
        targetCount = selectedUsers.length
      }
      var message = '重要警告を発火します。\n影響を受けるユーザー数: ' + targetCount + ' 名\nよろしいですか？'
      if (this.detailDisplayNotification) {
        if (this.detailDisplayNotification.block_signin) {
          message = '重要警告を発火します。\nこの重要警告は対象となるユーザーが問題を解決するまでログインを停止させます。\n影響を受けるユーザー数: ' + targetCount + ' 名\nよろしいですか？'
        } else {
          message = '重要警告を発火します。\nこの重要警告は対象となるユーザーのログイン後に強制的に警告を表示します。\n影響を受けるユーザー数: ' + targetCount + ' 名\nよろしいですか？'
        }
      }
      this.showConfirmDialog(
        '重要警告の発火',
        message,
        DIALOG_TYPE.EXECUTE,
        () => { this.executeFireNotification(selectedUsers) }
      )
    },

    executeFireNotification (selectedUsers = null) {
      var formData = new FormData()
      formData.append('token', this.detailDisplayNotification.confirmation_token)
      if (selectedUsers) {
        selectedUsers.forEach(user => {
          formData.append('users[]', user.id)
        })
      }
      CriticalNotification.fire({ formData: formData }).then(res => {
        if (res.success) {
          this.showNotifyDialog('重要警告発火', '指定された重要警告を発火しました')
          this.displayNotificationFireModal = false
          this.detailDisplayNotification = null
          this.displayNotificationDetailModal = false
        } else {
          this.showNotifyDialog('重要警告発火', '指定された重要警告の発火に失敗しました。')
        }
        this.loadSummary()
        this.loadNotifications()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('重要警告発火失敗', '指定された重要警告の発火に失敗しました')
      })
    },

    resoleveNotificationTarget (notificationTarget, callback) {
      this.showConfirmDialog(
        '重要警告の解決',
        '現在発火中の重要警告[' + this.detailDisplayNotification.title + ']の発火先[' + notificationTarget.user_name + '(' + notificationTarget.user_nbid + ')]を解決します。\nよろしいですか？',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeResolveNotificationTarget(notificationTarget, callback) }
      )
    },

    executeResolveNotificationTarget (notificationTarget, callback) {
      CriticalNotification.resolveByManager({ targetID: notificationTarget.id }).then(res => {
        if (res.success) {
          this.showNotifyDialog('重要警告解決', '現在発火中の重要警告[' + this.detailDisplayNotification.title + ']の発火先[' + notificationTarget.user_name + '(' + notificationTarget.user_nbid + ')]を解決しました。')
          callback()
        } else {
          this.showNotifyDialog('重要警告解決失敗', '指定された重要警告の解決に失敗しました')
        }
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('重要警告発火失敗', '指定された重要警告の発火に失敗しました')
      })
    },

    openSearchModal () {
      this.searchModalDisplay = true
    },

    resolveSearchedCriticalNotificationTarget (callback) {
      this.showConfirmDialog(
        '検索した警告対象の警告を解決します',
        '検索した警告対象の警告を解決します。\n本当によろしいですか？',
        DIALOG_TYPE.EXECUTE,
        () => { callback() }
      )
    },

    closeSeachModal () {
      this.searchModalDisplay = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-manage-critical-notification {
  padding: 10px 50px;
  .total-summary {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 10px;
    border: 1px solid #aaa;
    .single-column {
      margin: 5px 10px;
      .title {
        font-weight: bold;
      }
      .value {
        padding-left: 5px;
      }
    }
  }
  .seach-controll {
    margin: 10px;
    .search-btn {
      margin: 0 5px;
      padding: 3px 5px;
      border-radius: 3px;
      font-weight: bold;
      border: 1px solid #666;
      background-color: #666;
      color: #fff;
    }
  }
  .notification-list {
    .create-btn {
      margin: 0 5px;
      padding: 3px 5px;
      border-radius: 3px;
      font-weight: bold;
      border: 1px solid #666;
      background-color: #666;
      color: #fff;
    }
    .list-controll {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .list-controll-block {
        display: flex;
      }
      .controll-unit {
        display: flex;
        align-items: center;
        margin-right: 10px;
        &.disable {
          color: #999;
        }
        .direction-controll {
          &.active {
            cursor: pointer;
            color: #c53d43;
          }
        }
      }
    }
    .notifications {
      .list-line {
        margin: 5px 0;
        padding: 5px 0;
        border-bottom: 1px solid #aaa;
        .list-state {
          display: flex;
          flex-wrap: wrap;
          .list-column {
            margin: 5px 0;
            margin-right: 10px;
            .title {
              font-weight: bold;
            }
            .value {
              margin-left: 5px;
            }
          }
          .controll-column {
            button {
              margin: 0 5px;
              padding: 3px 5px;
              border-radius: 3px;
              font-weight: bold;
              &.detail-btn {
                border: 1px solid #666;
                background-color: #666;
                color: #fff;
              }
              &.edit-btn {
                border: 1px solid #666;
                background-color: #666;
                color: #fff;
              }
              &.delete-btn {
                border: 1px solid #c53d43;
                background-color: #c53d43;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
