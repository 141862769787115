import axios from './AxiosInstance'

export default {
  fetchIDList: ({timelineOwnerID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'related_organization_service/list',
        params: { target_user_id: timelineOwnerID, all: true },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ organizations: res.data.organizations })
        }
      }).catch(error => { reject(error) })
    })
  },

  destroy: ({timelineOwnerID, organizationID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'related_organization_service',
            withCredentials: true,
            params: { target_user_id: timelineOwnerID, organization_id: organizationID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
