import axios from './AxiosInstance'

export default {

  fetchAudioTagSet: ({targetUserID, audioID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'millvi_utility/audio_tag_set',
        withCredentials: true,
        params: { target_user_id: targetUserID, audio_id: audioID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ tag: res.data.tag })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchVideoTagSet: ({targetUserID, videoID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'millvi_utility/video_tag_set',
        withCredentials: true,
        params: { target_user_id: targetUserID, video_id: videoID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ tag: res.data.tag })
        }
      }).catch(error => { reject(error) })
    })
  }
}
