<template>
  <div class="horus-create-organization-request-input-profile">
    <h2>施設情報の入力</h2>
    <div class="organization-search-block">
      <div class="organization-search-controll">
        <horus-attribute-in-form
          :labelText="'医療機関コード（10桁）'"
          :necessary="true"
          :validationState="validateMedCode.state"
          :validationMessage="validateMedCode.message"
          :attributeNotify="''">
          <input type="text" v-model="medicalCode" />
        </horus-attribute-in-form>
        <button class="search-medical-btn" :class="{ enable: validateMedCode.valid }" @click="searchMedical">医療機関情報検索</button>
      </div>
      <div class="caption">
        10桁の医療機関コードを入力いただければ、医療機関コードデータベースに問い合わせて、登録情報を表示できます。
        検索された医療機関情報を利用して入力がサポートされます。
      </div>
      <div class="search-result-column">
        <h5>検索結果</h5>
        <div class="result-data" v-html="searchResultHTML"></div>
        <button class="copy-info-btn" :class="{ enable: searchResultAvailable }" @click="copyMedicalInfo">転記</button>
      </div>
    </div>
    <div class="organization-input-form">
      <horus-attribute-in-form
        :labelText="'担当者名'"
        :necessary="true"
        :validationState="validateOperatorName.state"
        :validationMessage="validateOperatorName.message"
        :attributeNotify="'本申込みの担当者名を記入ください。所在確認のために、この担当者様宛に電話をする場合があります。'">
        <input type="text" v-model="operatorName" size="20" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'医療機関コード'"
        :necessary="false"
        :validationState="validateMedCode.state"
        :validationMessage="validateMedCode.message"
        :attributeNotify="''">
        <input type="text" v-model="medicalCode" size="20" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'名称'"
        :necessary="true"
        :validationState="validateName.state"
        :validationMessage="validateName.message"
        :attributeNotify="''">
        <input type="text" v-model="name" size="40" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'ヨミガナ'"
        :necessary="true"
        :validationState="validateNameKana.state"
        :validationMessage="validateNameKana.message"
        :attributeNotify="'カタカナのみ'">
        <input type="text" v-model="nameKana" size="40" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'略称'"
        :necessary="true"
        :validationState="validateShortName.state"
        :validationMessage="validateShortName.message"
        :attributeNotify="''">
        <input type="text" v-model="shortName" size="25" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'郵便番号'"
        :necessary="true"
        :validationState="validateZipCode.state"
        :validationMessage="validateZipCode.message"
        :attributeNotify="''">
        <input type="text" v-model="zipCode" size="10" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'都道府県'"
        :necessary="true"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="''">
        <select class="sex-type-select" v-model="prefecture">
          <option v-for="prefCode in addressData.PREFECTURE_CODE_JP" :key="'prefectureSelect' + prefCode[0]" :value="prefCode[0]">{{ prefCode[1] }}</option>
        </select>
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'市町村区'"
        :necessary="true"
        :validationState="validateCity.state"
        :validationMessage="validateCity.message"
        :attributeNotify="''">
        <input type="text" v-model="city" size="30" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'住所詳細'"
        :necessary="false"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="''">
        <input type="text" v-model="address" size="40" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'電話番号'"
        :necessary="true"
        :validationState="validateTel.state"
        :validationMessage="validateTel.message"
        :attributeNotify="'ハイフン区切り半角数字: 000-000-0000'">
        <input type="text" v-model="tel" size="20" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'施設種別'"
        :necessary="true"
        :validationState="'disabled'"
        :validationMessage="''"
        :attributeNotify="''">
        <select class="sex-type-select" v-model="organizationType">
          <option v-for="(orgType, index) in orgData.ORGANIZATION_TYPES" :key="'orgTypeSelect' + index" :value="index">{{ orgType }}</option>
        </select>
      </horus-attribute-in-form>
      <div class="manager-mail-column">
        <horus-attribute-in-form
          :labelText="'管理者メールアドレス'"
          :necessary="true"
          :validationState="validateManagerMail.state"
          :validationMessage="validateManagerMail.message"
          :attributeNotify="''">
          <input type="text" v-model="managerMail" size="30" />
        </horus-attribute-in-form>
        <div class="notify">
          施設の登録承認後、施設管理アカウントの登録のために利用するメールアドレスです。<br />
          ナラティブブックの利用に際して使用されるメールアドレスですので、施設の管理者になる方が常に受け取れるメールアドレスを入力してください。
        </div>
      </div>
      <h4 class="new_account_heading">規約の説明</h4>
      <h4 class="rule-title">ナラティブブックシステム利用規約</h4>
      <div class="narrativebook_rule small_box">
        <horus-rules />
      </div>
      <h4 class="rule-title">利用者の個人情報の取扱について</h4>
      <div class="narrativebook_rule small_box">
        <horus-personal-information />
      </div>
      <div class="confirm-rules">
        <div class="admit-column" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          <span>ナラティブブックシステム利用規約および利用者情報の取扱についてに同意</span>
        </div>
      </div>
      <div class="new_user_submit">
        <div class="new_user_submit_notify" v-show="!allValid">
          全ての項目を正しく入力し、「ナラティブブックシステム利用規約および利用者情報の取扱についてに同意」にチェックしてください
        </div>
        <button type="button" class="new-user-submit-btn" :class="{enable: allValid}" @click="submitCreate">新規施設登録を申請</button>
      </div>
    </div>
  </div>
</template>

<script>
import { OrganizationRequest } from '@/api'

import HorusRules from '@/components/common/HorusRules.vue'
import HorusPersonalInformation from '@/components/common/HorusPersonalInformation.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as USER_ADDRESS from '@/assets/constants/addressData.js'
import * as ORGANIZATION_TYPES from '@/assets/constants/organizationType.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  name: 'HorusCreateOrganizationRequestInputProfile',

  components: {
    HorusRules,
    HorusPersonalInformation,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    validateToken: String
  },

  data () {
    return {
      medicalCode: '',
      searchResult: {},
      name: '',
      nameKana: '',
      shortName: '',
      zipCode: '',
      prefecture: '',
      city: '',
      address: '',
      tel: '',
      organizationType: '',
      managerMail: '',
      operatorName: '',
      addressData: USER_ADDRESS,
      orgData: ORGANIZATION_TYPES,
      admitAgreement: false
    }
  },

  computed: {
    validateOperatorName () {
      var checkResult = this.stringLengthCheck(this.operatorName, 127, '担当者名')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateMedCode () {
      var valid = false
      var state = 'disabled'
      var message = ''
      var trimed = this.medicalCode.trim()
      if (trimed.length > 0) {
        if (trimed.match(/^\d{10}$/)) {
          valid = true
          state = 'valid'
        } else {
          state = 'invalid'
          message = '医療機関コードは半角数字で10桁です。'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateName () {
      var checkResult = this.stringLengthCheck(this.name, 127, '施設名称')
      if (checkResult.valid) {
        return { state: 'valid', message: checkResult.message }
      } else {
        return { state: 'invalid', message: checkResult.message }
      }
    },

    validateNameKana () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.nameKana) {
        message = 'ヨミガナは入力必須です。1文字以上126文字以下で入力ください。'
      } else {
        var trimed = this.nameKana.trim()
        if (trimed.match(/^[ァ-ヶー\u{3000}\s]+$/)) {
          if (trimed.length > 0 && trimed.length <= 127) {
            valid = true
            state = 'valid'
          } else if (trimed.length > 127) {
            message = 'ヨミガナは20文字以内で入力ください。'
          } else {
            message = 'ヨミガナは1文字以上126文字以下で入力ください。'
          }
        } else {
          message = 'ヨミガナは全角カタカナ以外の文字は使えません。'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateShortName () {
      var checkResult = this.stringLengthCheck(this.shortName, 20, '施設略称')
      if (checkResult.valid) {
        return { state: 'valid', message: checkResult.message }
      } else {
        return { state: 'invalid', message: checkResult.message }
      }
    },

    validateZipCode () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.zipCode) {
        message = '郵便番号は入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
      } else {
        var trimed = this.zipCode.trim()
        if (trimed.match(this.addressData.ZIP_CODE_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '郵便番号はハイフン区切りの正しい形式で入力してください。(例:000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateCity () {
      var checkResult = this.stringLengthCheck(this.city.length, 127, '市町村区')
      if (checkResult.valid) {
        return { state: 'valid', message: checkResult.message }
      } else {
        return { state: 'invalid', message: checkResult.message }
      }
    },

    validateTel () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.tel) {
        message = '電話雁号は入力必須です。'
      } else {
        var trimed = this.tel.trim()
        if (trimed.match(this.addressData.TEL_NUMBER_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateManagerMail () {
      var checkResult = this.stringLengthCheck(this.managerMail.length, 127, '管理者メールアドレス')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    allValid () {
      if (
        this.validateOperatorName.valid &&
        this.validateMedCode &&
        this.stringLengthCheck(this.name, 127, '施設名称').valid &&
        this.validateNameKana.valid &&
        this.stringLengthCheck(this.shortName, 20, '施設略称').valid &&
        this.validateZipCode.valid &&
        this.stringLengthCheck(this.city.length, 127, '市町村区').valid &&
        this.validateTel.valid &&
        this.validateManagerMail.valid &&
        this.admitAgreement
      ) {
        return true
      } else {
        return false
      }
    },

    searchResultAvailable () {
      if (Array.isArray(this.searchResult)) {
        if (this.searchResult.length > 0) {
          return true
        } else {
          return false
        }
      } else if (this.searchResult.IryokikanCode) {
        return true
      }
      return false
    },

    searchResultHTML () {
      if (this.searchResult) {
        var resultData = null
        if (Array.isArray(this.searchResult)) {
          if (this.searchResult.length > 0) {
            resultData = this.searchResult[0]
          } else {
            return '<span class="notify-error">医療機関コードに対応する施設がありませんでした</span>'
          }
        } else if (this.searchResult.IryokikanCode) {
          resultData = this.searchResult
        }
        if (resultData) {
          var lineHead = '<div class="result-line" style="margin: 3px; margin-right: 10px;"><span class="title" style="margin-right: 5px; font-weight: bold;">'
          var lineMid = '</span><span class="value">'
          var lineTail = '</span></div>'
          var prefectureStr = '---'
          var prefectureSet = this.addressData.PREFECTURE_CODE_JP.find(set => set[0] === resultData.TodofukenNo)
          if (prefectureSet) {
            prefectureStr = prefectureSet[1]
          }
          return lineHead + '名称' + lineMid + resultData.IryokikanMei + lineTail +
            lineHead + '医療機関番号' + lineMid + resultData.IryokikanCode + lineTail +
            lineHead + '郵便番号' + lineMid + resultData.ZipCode + lineTail +
            lineHead + '都道府県' + lineMid + prefectureStr + lineTail +
            lineHead + '住所' + lineMid + resultData.Address + lineTail +
            lineHead + '電話番号' + lineMid + resultData.Tel + lineTail
        }
        // "TodofukenNo": "01",
        // "IryokikanCode": "0110112489",
        // "IryokikanMei": "医療法人愛全病院",
        // "ZipCode": "005-0813",
        // "Address": "札幌市南区川沿１３条２丁目１番３８号",
        // "Tel": "011-571-5670",
        // "TelShort": "",
        // "Fax": "",
        // "Kaisetusya": "",
        // "Kanrisya": "",
        // "Torokubi": "202102",
        // "SinryoKamoku": "",
        // "Biko": "",
        // "ByosyoSu": "495"
      }
      return ''
    }
  },

  methods: {
    stringLengthCheck (value, length, valueName) {
      var valid = false
      var message = ''
      if (!value || value.length <= 0) {
        message = valueName + 'は入力必須です。'
      } else if (value.length > length) {
        message = valueName + 'は' + length + '文字以下で入力ください。'
      } else {
        valid = true
      }
      return { valid: valid, message: message }
    },

    searchMedical () {
      if (this.validateMedCode.valid) {
        OrganizationRequest.searchMedicalInfo({ code: this.medicalCode }).then(res => {
          this.searchResult = res.medicalInfo
        }).catch(error => {
          console.log(error)
        })
      }
    },

    copyMedicalInfo () {
      if (this.searchResult) {
        var resultData = null
        if (Array.isArray(this.searchResult)) {
          if (this.searchResult.length > 0) {
            resultData = this.searchResult[0]
          } else {
            return '<span class="notify-error">医療機関コードに対応する施設がありませんでした</span>'
          }
        } else if (this.searchResult.IryokikanCode) {
          resultData = this.searchResult
        }
        if (resultData) {
          this.medicalCode = resultData.IryokikanCode
          this.name = resultData.IryokikanMei
          this.zipCode = resultData.ZipCode
          this.prefecture = resultData.TodofukenNo
          this.city = resultData.Address
          this.tel = resultData.Tel
        }
      }
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    submitCreate () {
      if (this.allValid) {
        this.$emit('showConfirmDialogEvent',
          'ナラティブブック新規施設登録',
          '入力された情報で、ナラティブブックの施設登録を申請します。\nよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeSubmit() }
        )
      }
    },

    executeSubmit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('token', this.validateToken)
        formData.append('organization_request[operator_name]', this.operatorName)
        formData.append('organization_request[medical_institution_code]', this.medicalCode)
        formData.append('organization_request[name]', this.name)
        formData.append('organization_request[name_kana]', this.nameKana)
        formData.append('organization_request[short_name]', this.shortName)
        formData.append('organization_request[prefecture]', this.prefecture)
        formData.append('organization_request[city]', this.city)
        formData.append('organization_request[address]', this.address)
        formData.append('organization_request[zip_code]', this.zipCode)
        formData.append('organization_request[tel]', this.tel)
        formData.append('organization_request[organization_type_id]', this.organizationType)
        formData.append('organization_request[first_manager_mail]', this.managerMail)
        OrganizationRequest.create({ formData: formData }).then(res => {
          this.$emit('submitFinishEvent', res.organization_request)
        }).catch(error => {
          console.error(error)
        })
      }
    }

  }

}
</script>

<style lang="scss" scoped>
.horus-create-organization-request-input-profile {
  margin: 0;
  padding: 0 10px;
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .organization-search-block {
    margin: 0;
    padding: 10px;
    border: 1px solid #aaa;
    .organization-search-controll {
      // display: flex;
      .search-medical-btn {
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    .caption {
      margin: 5px 0;
      padding: 5px;
      border: 1px solid #aaa;
      font-size: 0.9em;
    }
    .search-result-column {
      padding: 5px;
      border: 1px solid #aaa;
      h5 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 1em;
        font-weight: bold;
      }
      .result-data {
        display: flex;
        flex-wrap: wrap;
        .result-line {
          margin: 3px;
          margin-right: 10px;
          .title {
            margin-right: 5px;
            font-weight: bold;
          }
        }
      }
      .copy-info-btn {
        border-radius: 3px;
        background-color: #aaa;
        color: #ddd;
        font-weight: bold;
        &.enable {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
  .new_account_heading {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding-bottom: 5px;
    color: #6c272d;
    border-bottom: 1px solid #aaa;
    font-size: 18px;
    font-weight: normal;
  }
  .form-list {
    margin: 10px 0;
    padding: 10px 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    input {
      font-size: 16px;
      border: 1px solid #aaa;
    }
  }
  .manager-mail-column {
    padding: 10px;
    padding-top: 5px;
    border: 1px solid #aaa;
  }
  .rule-title {
    margin: 5px 0;
    text-align: center;
  }
  .small_box {
    margin-bottom: 10px;
    border: 1px solid #aaa;
    height: 300px;
    overflow: scroll;
  }
  .confirm-rules {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    .admit-column {
      display: flex;
      align-items: center;
      justify-content: space-around;
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .new_user_submit {
    text-align: center;
    .new_user_submit_notify {
      padding: 10px;
      border: 1px solid #6c272d;
      background-color: #fff;
      color: #6c272d;
      text-align: center;
    }
    button {
      margin-top: 20px;
      padding: 3px 10px;
      border-radius: 5px;
      border: 1px solid #4cae4c;
      border-color: #aaa;
      background-color: #aaa;
      color: #ddd;
      font-size: 18px;
      font-weight: bold;
      &.enable {
        border-color: #4cae4c;
        background-color: #4cae4c;
        color: #fff;
      }
    }
  }
}
</style>
