<template>
  <div class="horus-system-pubic-announcement-view">
    <h1 class="centering-title">お知らせ</h1>
    <h2>お知らせ一覧<button class="create-btn" @click="newAnnouncement">新規お知らせ作成</button></h2>
    <table class="public-announcements">
      <tr><th class="date-column">表示日時</th><th>内容</th><th>公開日時</th><th>非公開日時</th><th>&nbsp;</th></tr>
      <tr v-for="announcement in publicAnnouncements" :key="'systemPA' + announcement.id" :class="{publishing: announcement.nowPublishing}">
        <td>{{ announcement.announcementAtStr }}</td>
        <td v-html="announcement.contentHTML"></td>
        <td>{{ announcement.publicAtStr }}</td>
        <td>{{ announcement.closeAtStr }}</td>
        <td>
          <button class="close-btn" @click="closeNow(announcement)" v-if="announcement.nowPublishing">即時公開終了</button>
          <button class="public-btn" @click="publicNow(announcement)" v-if="!announcement.publicAt">即時公開</button>
          <button class="public-btn" @click="publicAgain(announcement)" v-if="!announcement.nowPublishing && announcement.closeAt">即時再公開</button>
          <button class="close-btn" @click="edit(announcement)">編集</button>
          <button class="close-btn" @click="destroy(announcement)">削除</button>
        </td>
      </tr>
    </table>
    <horus-public-announcement-edit-modal
      :sourcePublicAnnouncement="editAnnouncement"
      :showPublicAnnouncementEditModal="showPublicAnnouncementEditModal"
      v-on:cancelSubmitEvent="cancelEdit"
      v-on:submitEvent="submitAnnouncement"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { mapState } from "vuex"

import {
  PublicAnnouncement
} from '@/models'

import HorusPublicAnnouncementEditModal from '@/components/manageSystem/publicAnnouncement/HorusPublicAnnouncementEditModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemPublicAnnouncementView',

  components: {
    HorusPublicAnnouncementEditModal,
    HorusConfirmDialog
  },

  data () {
    return {
      publicAnnouncements: [],
      editAnnouncement: null,
      showPublicAnnouncementEditModal: false
    }
  },

  mounted () {
    this.loadAnnouncements()
  },

  computed: {
    ...mapState('entities/publicAnnouncement', { publicAnnouncementsFetchedAt: (state) => state.fetchedAt }),
  },

  watch: {
    publicAnnouncementsFetchedAt: { handler: 'readAnnouncement' }
  },

  methods: {
    async loadAnnouncements () {
      PublicAnnouncement.api().fetchList().then(() => {
        this.publicAnnouncements = PublicAnnouncement.all()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('お知らせの取得失敗', 'お知らせの取得に失敗しました')
      })
    },

    readAnnouncement () {
      this.publicAnnouncements = PublicAnnouncement.all()
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    newAnnouncement () {
      this.editAnnouncement = null
      this.showPublicAnnouncementEditModal = true
    },

    edit (announcement) {
      this.editAnnouncement = announcement
      this.showPublicAnnouncementEditModal = true
    },

    cancelEdit () {
      this.showPublicAnnouncementEditModal = false
    },

    submitAnnouncement (formData) {
      if (this.editAnnouncement) {
        PublicAnnouncement.api().update({ formData: formData }).then(() => {
          this.loadAnnouncements()
          this.showPublicAnnouncementEditModal = false
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('お知らせの更新失敗', 'お知らせの更新に失敗しました')
          this.showPublicAnnouncementEditModal = false
        })
      } else {
        PublicAnnouncement.api().create({ formData: formData }).then(() => {
          this.loadAnnouncements()
          this.showPublicAnnouncementEditModal = false
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('お知らせの新規作成失敗', 'お知らせの新規作成に失敗しました')
          this.showPublicAnnouncementEditModal = false
        })
      }
    },

    closeNow (announcement) {
      this.showConfirmDialog(
        'お知らせの非公開',
        'お知らせを非公開にしようとしています。\n実行するとこのお知らせは即時非公開になります。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeClose(announcement) }
      )
    },

    executeClose (announcement) {
      PublicAnnouncement.api().closeNow({ targetID: announcement.id }).then(() => {
        this.loadAnnouncements()
        this.showNotifyDialog('お知らせ非公開', 'お知らせを非公開にしました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('お知らせの非公開失敗', 'お知らせの非公開に失敗しました')
        this.showPublicAnnouncementEditModal = false
      })
    },

    publicNow (announcement) {
      this.showConfirmDialog(
        'お知らせの公開',
        'お知らせを公開しようとしています。\n実行するとこのお知らせは即時公開されます。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executePublic(announcement) }
      )
    },

    executePublic (announcement) {
      PublicAnnouncement.api().putlicNow({ targetID: announcement.id }).then(() => {
        this.loadAnnouncements()
        this.showNotifyDialog('お知らせ公開', 'お知らせを公開しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('お知らせの公開失敗', 'お知らせの公開に失敗しました')
        this.showPublicAnnouncementEditModal = false
      })
    },

    publicAgain (announcement) {
      this.showConfirmDialog(
        'お知らせの再公開',
        'お知らせを再公開しようとしています。\n実行するとこのお知らせは即時公開になり、非公開日時は未設定の状態となります。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executePublicAgain(announcement) }
      )
    },

    executePublicAgain (announcement) {
      PublicAnnouncement.api().publicAgain({ targetID: announcement.id }).then(() => {
        this.loadAnnouncements()
        this.showNotifyDialog('お知らせ再公開', 'お知らせを再び公開にしました。非公開日時は設定されていません。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('お知らせの再公開失敗', 'お知らせの再公開に失敗しました')
        this.showPublicAnnouncementEditModal = false
      })
    },

    destroy (announcement) {
      this.showConfirmDialog(
        'お知らせの削除',
        'お知らせを削除しようとしています。\n実行するとこのお知らせは削除され、二度と元に戻せません。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroy(announcement) }
      )
    },

    executeDestroy (announcement) {
      PublicAnnouncement.api().destroy({ targetID: announcement.id }).then(() => {
        this.loadAnnouncements()
        this.showNotifyDialog('お知らせ削除', 'お知らせを削除しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('お知らせの削除失敗', 'お知らせの削除に失敗しました')
        this.showPublicAnnouncementEditModal = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-pubic-announcement-view {
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  .public-announcements {
    width: 100%;
    margin: 10px 0;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
    th {
      border-bottom: 1px solid #aaa;
      &.date-column {
        width: 80px;
      }
    }
    td {
      padding: 5px 3px;
      border-bottom: 1px solid #aaa;
      vertical-align: top;
      button {
        margin-bottom: 5px;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
</style>
