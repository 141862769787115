import axios from './AxiosInstance'

export default {

  fetchState: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'archive_data/state',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ limited: res.data.limited })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchList: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'archive_data/list',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ archive_datas: res.data.archive_datas })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'archive_data',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      }).catch(error => {
        throw error
      })
    })
  },

  fetchWaitingList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'archive_data/waiting_list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ archive_datas: res.data.archive_datas })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchStandByList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'archive_data/stand_by_list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ archive_datas: res.data.archive_datas })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchRejectedList: ({offset}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'archive_data/rejected_list',
        withCredentials: true,
        params: { offset: offset }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ archive_datas: res.data.archive_datas, count: res.data.count })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchFailedList: ({offset}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'archive_data/failed_list',
        withCredentials: true,
        params: { offset: offset }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ archive_datas: res.data.archive_datas, count: res.data.count })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchPublishedList: ({offset}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'archive_data/published_list',
        withCredentials: true,
        params: { offset: offset }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ archive_datas: res.data.archive_datas, count: res.data.count })
        }
      }).catch(error => { reject(error) })
    })
  },

  reject: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'archive_data/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      }).catch(error => {
        throw error
      })
    })
  },

  dataCreate: ({targetID}) => {
    var formData = new FormData()
    formData.append('id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'archive_data/data_create',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  }

}
