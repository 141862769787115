import axios from './AxiosInstance'

export default {
  timeline: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'permission/timeline',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  configure: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'permission/configure',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
