<template>
  <div class="horus-contact-form-column">
    <h1>
      {{ localizedTitleText }}
      <a class="switch-lang" @click="switchLang('en')" v-if="displayLang !== 'en'">English</a>
      <a class="switch-lang" @click="switchLang('jp')" v-if="displayLang !== 'jp'">日本語</a>
    </h1>
    <div class="notify">{{ localizedNotifyMessage }}</div>
    <form
      accept-charset="UTF-8"
      v-on:submit.prevent="submitForm"
      method="POST"
      enctype="multipart/form-data"
      id="ajaxForm"
    >
      <horus-attribute-in-form
        :labelText="localizedTexts.name.title"
        :necessary="true"
        :validationState="validateName.state"
        :validationMessage="validateName.message"
        :attributeNotify="localizedTexts.name.messaage">
        <input type="text" size="20" v-model="contactName" :placeholder="localizedTexts.name.placeholder" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="localizedTexts.nameKana.title"
        :necessary="false"
        :validationState="validateNameKana.state"
        :validationMessage="validateNameKana.message"
        :attributeNotify="localizedTexts.nameKana.message">
        <input type="text" size="30" v-model="contactNameKana" :placeholder="localizedTexts.nameKana.placeholder" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="localizedTexts.mail.title"
        :necessary="true"
        :validationState="validateMail.state"
        :validationMessage="validateMail.message"
        :attributeNotify="localizedTexts.mail.message">
        <input type="text" size="25" v-model="mailAddress" :placeholder="localizedTexts.mail.placeholder" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="localizedTexts.accessFrom.title"
        :necessary="false"
        :validationState="'none'"
        :validationMessage="''"
        :attributeNotify="localizedTexts.accessFrom.message">
        <select v-model="contactFrom">
          <option value="none">特になし</option>
          <option value="coco">COCO(秋田県)</option>
          <option value="monogatari">ものがたりの街</option>
        </select>
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="localizedTexts.content.title"
        :necessary="true"
        :validationState="validateContent.state"
        :validationMessage="validateContent.message"
        :attributeNotify="localizedTexts.content.message">
        <textarea class="content-input" :placeholder="localizedTexts.content.placeholder" v-model="contactContent"></textarea>
      </horus-attribute-in-form>
      <div class="confirm-check-column" @click="toggleConfirmCheck">
        <horus-custom-check-box
          :active="confirmCheck"
          :enabled="true"
          :type="'color'"
          />
        {{ localizedTexts.confirmCheck.checkLabel }}
      </div>
      <div class="privacy-policy jp" v-if="displayLang !== 'en'">
        <h3>個人情報の取扱いについて</h3>
        <h4>■個人情報の利用目的</h4>
        <p>お問い合わせ対応（本人への連絡を含む）のため</p>
        <h4>■個人情報取扱いの委託</h4>
        <p>事業運営上、前項利用目的の範囲に限って個人情報を外部に委託することがあります。この場合、個人情報保護水準の高い委託先を選定し、個人情報の適正管理・機密保持についての契約を交わし、適切な管理を実施させます。</p>
        <h4>■個人情報に関するお問い合わせ</h4>
        <p>当社の個人情報の取扱および開示等(利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止)に関するお問合せは個人情報保護方針に記載の【お問合せ窓口】へご連絡ください。</p>
        <h4>■個人情報を提供されることの任意性について</h4>
        <p>ご本人様が当社に個人情報を提供されるかどうかは任意によるものです。 ただし、必要な項目をいただけない場合、適切な対応ができない場合があります。</p>
        <h4>■個人情報保護管理者</h4>
        <p>
          クロスケアフィールド株式会社<br/>
          代表取締役 社長 岡﨑 光洋<br/>
          連絡先:03-3831-7755<br/>
        </p>
      </div>
      <div class="privacy-policy en" v-if="displayLang !== 'jp'">
        <h3>About the handling of personal information</h3>
        <h4>Purpose of use of personal information</h4>
        <p>For responding to inquiries (including contacting the person)</p>
        <h4>Entrustment of handling personal information</h4>
        <p>In terms of business operations, personal information may be outsourced only within the scope of the purpose of use set forth in the preceding paragraph. In this case, we will select a contractor with a high level of personal information protection, conclude a contract for proper management and confidentiality of personal information, and have them carry out appropriate management.</p>
        <h4>Inquiries regarding personal information</h4>
        <p>Inquiries regarding the handling and disclosure of our personal information (notification of purpose of use, disclosure, correction / addition / deletion of content, suspension or deletion of use, suspension of provision to third parties) are described in the personal information protection policy. Please contact the [Inquiry Desk].</p>
        <h4>About the voluntary provision of personal information</h4>
        <p>Whether or not you provide personal information to us is voluntary. However, if you do not receive the required items, we may not be able to take appropriate measures.</p>
        <h4>Personal information protection manager</h4>
        <p>
          Cross Care Field CO.,LTD<br/>
          Representative Director and President Mitsuhiro Okazaki<br/>
          TEL:03-3831-7755<br/>
        </p>
      </div>
      <div class="recaptcha-notify">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
      <div class="controll-content"><button type="submit" :class="{enable: formValid}">{{ localizedTexts.submitText }}</button></div>
    </form>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { ContactMail } from '@/api'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
// import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

const LOCALIZED_TEXT_JP = {
  name: {
    title: 'お名前',
    message: '',
    placeholder: 'お名前をご入力ください'
  },
  nameKana: {
    title: 'フリガナ',
    message: '',
    placeholder: 'カタカナで入力ください'
  },
  mail: {
    title: 'メールアドレス',
    message: '',
    placeholder: 'example@example.com'
  },
  accessFrom: {
    title: 'アクセス元',
    message: '',
    placeholder: 'ナラティブブックにアクセスする元のサイトがあれば入力ください'
  },
  content: {
    title: 'お問い合わせ内容',
    message: '',
    placeholder: 'お問い合わせの内容をご記入ください。'
  },
  confirmCheck: {
    title: '',
    message: '',
    placeholder: '',
    checkLabel: '下記の「個人情報の取扱いについて」に同意します。'
  },
  titleText: 'お問い合わせ',
  notifyMessage: 'お問い合わせいただきました内容は、弊社の掲げる個人情報保護方針に沿って管理し、お客様の同意なく第三者に開示・提供することはございません。',
  submitText: '送信'
}

const LOCALIZED_TEXT_EN = {
  name: {
    title: 'Name',
    message: '',
    placeholder: 'your name'
  },
  nameKana: {
    title: 'Name Kana',
    message: '',
    placeholder: 'カタカナで入力'
  },
  mail: {
    title: 'Mail Address',
    message: '',
    placeholder: 'example@example.com'
  },
  accessFrom: {
    title: 'access from',
    message: 'Enter the original site to access the narrative book',
    placeholder: ''
  },
  content: {
    title: 'content',
    message: '',
    placeholder: 'Please fill in the content of your inquiry.'
  },
  confirmCheck: {
    title: '',
    message: '',
    placeholder: '',
    checkLabel: 'I agree to the following "Handling of personal information".'
  },
  titleText: 'Contact',
  notifyMessage: 'The content of your inquiry will be managed in accordance with our privacy policy, and will not be disclosed or provided to a third party without your consent.',
  submitText: 'send'
}

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusContactFormColumn',

  components: {
    HorusAttributeInForm,
    // HorusFooter,
    HorusConfirmDialog,
    HorusCustomCheckBox
  },

  props: {
    lang: String,
    localizedTitle: Object,
    localizedMessage: Object,
    postscript: String
  },

  data () {
    return {
      mailAddress: '',
      contactName: '',
      contactNameKana: '',
      contactContent: '',
      contactFrom: '',
      confirmCheck: false,
      displayLang: 'jp',
      localizedTexts: LOCALIZED_TEXT_JP
    }
  },

  watch: {
    // '$attrs': { handler: 'initLang', immediate: true },
    lang: { handler: 'initLang', immediate: true }
  },

  computed: {
    localizedTitleText () {
      if (this.localizedTitle && this.localizedTitle[this.lang]) {
        return this.localizedTitle[this.lang]
      } else {
        return this.localizedTexts.titleText
      }
    },

    localizedNotifyMessage () {
      if (this.localizedMessage && this.localizedMessage[this.lang]) {
        return this.localizedMessage[this.lang]
      } else {
        return this.localizedTexts.notifyMessage
      }
    },

    validateMail () {
      if (this.mailAddress && this.mailAddress.length > 0) {
        if (this.mailAddress.match(/^.+@.+$/)) {
          return { valid: true, state: 'valid', message: '' }
        } else {
          return { valid: false, state: 'invalid', message: 'メールアドレスの形式が正しくありません。' }
        }
      } else {
        return { valid: false, state: 'disabled', message: '' }
      }
    },

    validateName () {
      if (this.contactName && this.contactName.length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'disabled', message: 'お名前は入力必須です' }
      }
    },

    validateNameKana () {
      if (this.contactNameKana && this.contactNameKana.length > 0) {
        if (this.contactNameKana.match(/^[ァ-ヶー]+$/)) {
          return { valid: true, state: 'valid', message: '' }
        } else {
          return { valid: false, state: 'invalid', message: 'カタカナのみで入力ください' }
        }
      } else {
        return { valid: true, state: 'disabled', message: 'フリガナは入力必須です' }
      }
    },

    validateContent () {
      if (this.contactContent && this.contactContent.length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'disabled', message: 'お問い合わせ内容は入力必須です' }
      }
    },

    validateConfirmCheck () {
      if (this.confirmCheck) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'disabled', message: '「個人情報の取扱いについて」への同意は必須です' }
      }
    },

    formValid () {
      if (this.validateName.valid && this.validateMail.valid && this.validateNameKana.valid && this.validateContent.valid && this.validateConfirmCheck.valid) {
        return true
      }
      return false
    }
  },

  methods: {
    initLang () {
      this.displayLang = this.lang
      if (this.displayLang === 'en') {
        this.localizedTexts = LOCALIZED_TEXT_EN
      } else {
        this.localizedTexts = LOCALIZED_TEXT_JP
      }
    },

    switchLang (lang) {
      this.$emit('switchLangEvent', lang)
    },

    toggleConfirmCheck () {
      this.confirmCheck = !this.confirmCheck
    },

    submitForm () {
      if (this.formValid) {
        var formData = new FormData()
        formData.append('contact_mail[mail_address]', this.mailAddress)
        formData.append('contact_mail[name]', this.contactName)
        formData.append('contact_mail[name_kana]', this.contactNameKana)
        formData.append('contact_mail[contact_from]', this.contactFrom)
        formData.append('contact_mail[content]', this.contactContent + this.postscript)
        this.$recaptcha('login').then((token) => {
          formData.append('g_recaptcha_response', token)
          ContactMail.send({ formData: formData }).then(() => {
            this.showNotifyDialog(
              'お問い合わせを受付しました',
              'お問い合わせを受付しました。確認の上、担当者から入力されたメールアドレスに返信を行います。\n' + 'お名前(フリガナ): ' + this.contactName + '(' + this.contactNameKana + ')\n' + 'メールアドレス: ' + this.mailAddress + '\n' + 'お問い合わせ内容:\n' + this.contactContent
            )
            this.clearForm()
          }).catch(error => {
            console.error(error.response)
            this.showNotifyDialog('お問い合わせの送信に失敗しました', 'お問い合わせの送信に失敗しました。')
          })
        })
      }
    },

    clearForm () {
      this.mailAddress = ''
      this.contactName = ''
      this.contactNameKana = ''
      this.contactFrom = ''
      this.contactContent = ''
      this.confirmCheck = false
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-contact-form-column {
  h1 {
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    color: #666;
    .switch-lang {
      padding-left: 20px;
      font-size: 12px;
      font-weight: normal;
    }
  }
  .notify {
    margin-bottom: 20px;
    padding: 10px 20px;
    border: 1px solid #aaa;
    background-color: #eee;
  }
  form {
    padding: 20px;
    border: 1px solid #666;
    textarea.content-input {
      resize: none;
      width: 100%;
      height: 8em;
      font-size: 16px;
    }
    .recaptcha-notify {
      margin-bottom: 10px;
      padding: 5px;
      border: 1px solid #ddd;
      font-size: 0.9em;
    }
    .confirm-check-column {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-weight: bold;
    }
    .privacy-policy {
      height: 200px;
      margin-top: 10px;
      padding: 10px;
      border: 1px solid #aaa;
      overflow: auto;
      h3 {
        margin: 0;
      }
      h4 {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      p {
        margin: 0;
      }
    }
    .controll-content {
      text-align: center;
      button {
        padding: 3px 10px;
        border-radius: 3px;
        font-size: 1.1em;
        font-weight: bold;
        background-color: #a6c8b2;
        color: #fff;
        &.enable {
          background-color: #00552e;
        }
      }
    }
  }
}
</style>
