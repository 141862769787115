import axios from './AxiosInstance'

export default {
  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_third_party_linkage_relation',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({organizationID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_third_party_linkage_relation',
            withCredentials: true,
            params: { organization_id: organizationID, third_party_linkage_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
