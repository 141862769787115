import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'

export default class TaskDisplayConfiguration extends Model {
  static entity = 'taskDisplayConfiguration'

  static fields () {
    return {
      id: this.attr(null),
      author_id: this.attr(null),
      update_pooling_span: this.attr(null),
      notify_pooling_span: this.attr(null),
      notify_display_past_min: this.attr(null),
      nofify_display_future_min: this.attr(null),
      display_past_min: this.attr(null),
      display_future_min: this.attr(null),
      created_at: this.string(''),
      updated_at: this.string('')
    }
  }

  get author () {
    // TODO: load data when undefined
    return Author.find(this.author_id)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(result) {
    // console.log(result)
    // console.log(result.entities.taskDisplayConfiguration[0])
    TaskDisplayConfiguration.commit((state) => {
      state.fetchedAt = new Date()
    })
    if (result.entities && result.entities.taskDisplayConfiguration) {
      if (!Author.find(result.entities.taskDisplayConfiguration[0].author_id)) {
        await Author.api().fetchByID({ idList: [result.entities.taskDisplayConfiguration[0].author_id] })
      }
      return result.entities.taskDisplayConfiguration[0]
    } else {
      return null
    }
  }

  static apiConfig = {
    actions: {
      fetch () {
        return this.request({
          method: 'get',
          url: 'task_display_configuration',
          withCredentials: true,
          dataKey: 'task_display_configuration'
        })
        .then(res => {
          return TaskDisplayConfiguration.updateData(res)
        })
      },
    
      async update ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'task_display_configuration',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy () {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'task_display_configuration',
            withCredentials: true,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }
    
    }
  }
}
