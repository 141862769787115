<template>
  <div class="horus-member-profile-personal-view">
    <div class="profile-header">
      <button class="to-personal-btn disable">個人設定</button>
      <router-link to="/member_profile_configure" active-class="active">
        <button class="to-member-btn">職員用設定</button>
      </router-link>
    </div>
    <div class="personal-profile">
      <h1 class="centering-title">プロフィール</h1>
      <horus-profile-column
        :loggedInUser="loggedInUser"
        :signinMember="signinMember"
        :timelineOwner="timelineOwner"
        :userBasicSource="userBasicSource"
        :userAddressSource="userAddressSource"
        :appSigninTokens="appSigninTokens"
        :registedQrCodes="registedQrCodes"
        :zoomUser="zoomUser"
        v-on:changeMailEvent="changeMail"
        v-on:changePasswordEvent="changePassword"
        v-on:editUserBasicEvent="editUserBasic"
        v-on:editUserAddressEvent="editUserAddress"
        v-on:quitNarrativeBookEvent="quitNarrativeBook"
        v-on:removeAppSigninTokenEvent="removeAppSigninToken"
        v-on:registQrEvent="registQrCode"
        v-on:manualRegistQrEvent="manualRegistQrCode"
        v-on:deleteQrCodeEvent="deleteQrCode"
        v-on:addZoomUserEvent="addZoomUser"
        v-on:reconnectZoomUserEvent="reconnectZoomUser"
        v-on:revokeZoomAuthEvent="revokeZoomAuth"
        />
      <horus-footer />
    </div>
    <!-- user meil edit modal -->
    <horus-user-mail-edit-modal
     :showUserMailEditModal="showUserMailEditModal"
     v-on:cancelSubmitEvent="cancelUserMailSubmit"
     v-on:submitEvent="submitUserMailChange"
     />
    <!-- user password edit modal -->
    <horus-password-update-modal
      :showPasswordUpdateModal="showPasswordUpdateModal"
      v-on:cancelSubmit="cancelPasswordUpdate"
      v-on:badPasswordFormatEvent="showPasswordFormatNotify"
      v-on:executeSubmit="executePasswordUpdate"/>
    <!-- user basic edit modal -->
    <horus-user-basic-edit-modal
      :showUserBasicEditModal="showUserBasicEditModal"
      :timelineOwner="timelineOwner"
      :userBasicSource="userBasicSource"
      v-on:cancelSubmitEvent="cancelUserBasicSubmit"
      v-on:executeSubmitEvent="submitUserBasicForm"
      />
    <!-- user address edit modal -->
    <horus-user-address-edit-modal
      :showUserAddressEditModal="showUserAddressEditModal"
      :timelineOwner="timelineOwner"
      :userAddressSource="userAddressSource"
      v-on:cancelSubmitEvent="cancelUserAddressSubmit"
      v-on:executeSubmitEvent="submitUserAddressForm"
      />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import HorusProfileColumn from '@/components/profile/HorusProfileColumn.vue'
import HorusUserMailEditModal from '@/components/profile/HorusUserMailEditModal.vue'
import HorusPasswordUpdateModal from '@/components/profile/HorusPasswordUpdateModal.vue'
import HorusUserBasicEditModal from '@/components/profile/HorusUserBasicEditModal.vue'
import HorusUserAddressEditModal from '@/components/profile/HorusUserAddressEditModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusProfileControll from '@/mixin/HorusProfileControll.js'

export default {
  mixins: [HorusProfileControll, HorusConfirmDialogControll],

  name: 'HorusMemberProfilePersonalView',

  components: {
    HorusProfileColumn,
    HorusFooter,
    HorusPasswordUpdateModal,
    HorusUserMailEditModal,
    HorusUserBasicEditModal,
    HorusUserAddressEditModal,
    HorusConfirmDialog
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-member-profile-personal-view {
  height: calc(100% - 51px);
  .profile-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    height: 30px;
    background-color: #ddd;
    border-bottom: 1px solid #aaa;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      &.disable {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .personal-profile {
    height: calc(100% - 60px);
    overflow: scroll;
    padding: 10px 50px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
  }
}
</style>
