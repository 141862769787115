<template>
  <div class="horus-create-deputize-invitation-to-user-modal">
    <horus-modal-frame
      :showModalFlag="showCreateDeputizeInvitationToUserModal">
      <div class="modal-header">
        <h1>設定代行の案内状作成</h1>
      </div>
      <div class="modal-body" v-if="organization || agent">
        <div class="create-tab-labels">
          <div class="tab-label" :class="{active: createRequestModeNBID}" @click="requestModeToNBID">NBIDで作成</div>
          <div class="tab-label" :class="{active: !createRequestModeNBID}" @click="requestModeToMail">メールアドレスで作成</div>
        </div>
        <div class="create-by-nbid" v-if="createRequestModeNBID">
          <div class="switch-request-type">NBIDがわからない場合は<span class="link" @click="requestModeToMail">こちら</span></div>
          <horus-attribute-in-form
            :labelText="'NBID'"
            :necessary="true"
            :validationState="requestNBIDValidation.state"
            :validationMessage="requestNBIDValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetNBID" />
          </horus-attribute-in-form>
        </div>
        <div class="create-by-mail" v-if="!createRequestModeNBID">
          <div class="switch-request-type">NBIDがわかっている場合は<span class="link" @click="requestModeToNBID">こちら</span></div>
          <horus-attribute-in-form
            :labelText="'メールアドレス'"
            :necessary="true"
            :validationState="requestMailValidation.state"
            :validationMessage="requestMailValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMail" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'メールアドレス(確認)'"
            :necessary="true"
            :validationState="requestMailConfirmationValidation.state"
            :validationMessage="requestMailConfirmationValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMailConfirmation" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'宛先名(メールに書き込まれる相手の名前)'"
            :necessary="true"
            :validationState="requestNameValidation.state"
            :validationMessage="requestNameValidation.message"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetName" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'本人確認コード(数字4桁:空白の場合自動生成)'"
            :necessary="false"
            :validationState="requestShortTokenValidation.state"
            :validationMessage="requestShortTokenValidation.message"
            :attributeNotify="'4桁の数字(半角)'">
            <input type="text" v-model="requestShortToken" />
          </horus-attribute-in-form>
          <div class="notify-for-invitation-by-mail">
            案内で送信されるメールには、登録処理のためのURLは記載されますが、プロセスを進めるための本人確認コードは記載されません。<br/>
            本人確認コードは他の手段で利用者に伝える必要があります。<br/>
            必ず何らかの手段で本人確認コードを伝えてください。
          </div>
        </div>
        <horus-attribute-in-form
          :labelText="'メッセージ'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <textarea rows="3" cols="20" v-model="requestMessage"></textarea>
        </horus-attribute-in-form>
        <div class="notify_for_admit" v-if="organization">
          <h1>設定代行登録における確認事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>当該施設を設定代行施設として登録する事について、利用者本人が同意していること</li>
            <li>利用者本人が作成した委任状を受け取っていること</li>
            <li>設定代行施設に依頼されることを施設が同意していること</li>
            <li>設定代行施設の登録が完了後、受け取った委任状を所定の場所にアップロードすること</li>
            <li>なりすまし防止のために、利用者本人との確認ができていること</li>
            <li>設定の変更は必ず利用者本人の意思に基づいて行うこと</li>
          </ul>
        </div>
        <div class="notify_for_admit" v-if="agent">
          <h1>設定代行登録における確認事項</h1>
          <ul>
            <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
            <li>あなたを設定代行者として登録する事について、利用者本人が同意していること</li>
            <li>設定代行者に依頼されることをあなたが同意していること</li>
            <li>なりすまし防止のために、利用者本人との確認ができていること</li>
            <li>設定の変更は必ず利用者本人の意思に基づいて行うこと</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendRequest">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !requestReady }" @click="submitSendRequest">案内状の送信</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusUserInvitationValidation from '@/mixin/HorusUserInvitationValidation.js'

export default {
  mixins: [HorusUserInvitationValidation],

  name: 'HorusCreateDeputizeInvitationToUserModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    showCreateDeputizeInvitationToUserModal: Boolean,
    organization: Object,
    agent: Object,
    existAuthorIDList: Array,
    deputizeRelations: Array,
    sentInvitations: Array,
    receiveRequests: Array,
    reservedNBID: String
  },

  data () {
    return {
      createRequestModeNBID: true,
      requestTargetNBID: '',
      requestTargetMail: '',
      requestTargetMailConfirmation: '',
      requestTargetName: '',
      requestShortToken: '0000',
      requestMessage: 'あなたの設定代行施設に登録させていただきたく案内いたしました。\n承認のほど宜しくお願いいたします。',
      admitAgreement: false
    }
  },

  watch: {
    showCreateDeputizeInvitationToUserModal: { handler: 'initReserve', immediate: true }
  },

  computed: {
    requestNBIDValidation () {
      if (this.requestTargetNBID.length > 0) {
        if (this.deputizeRelations.some(rel => rel.nbid === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定されたNBIDの利用者は設定代行済です' }
        } else if (this.sentInvitations.some(inv => inv.login_id === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定されたNBIDにはすでに案内済みです' }
        } else if (this.receiveRequests.some(req => req.nbid === this.requestTargetNBID)) {
          return { state: 'invalid', message: '指定されたNBIDの利用者から申請を受けています' }
        } else {
          return { state: 'valid', message: '' }
        }
      } else {
        return { state: 'invalid', message: 'NBIDは入力必須です' }
      }
    }
  },

  methods: {
    initReserve () {
      if (this.showCreateDeputizeInvitationToUserModal) {
        this.initParams()
      }
    },

    initParams () {
      this.createRequestModeNBID = true
      if (typeof this.reservedNBID === 'string') {
        this.requestTargetNBID = this.reservedNBID
      } else {
        this.requestTargetNBID = ''
      }
      this.requestTargetMail = ''
      this.requestTargetMailConfirmation = ''
      this.requestTargetName = ''
      this.requestShortToken = '0000'
      if (this.organization) {
        this.requestMessage = 'あなたの設定代行施設に登録させていただきたく案内いたしました。\n承認のほど宜しくお願いいたします。'
      } else if (this.agent) {
        this.requestMessage = 'あなたの設定代行者に登録させていただきたく案内いたしました。\n承認のほど宜しくお願いいたします。'
      } else {
        this.requestMessage = ''
      }
      this.admitAgreement = false
    },

    cancelSendRequest () {
      this.$emit('cancelSendInvitationEvent', () => { this.initParams() })
    },

    submitSendRequest () {
      if (this.requestReady) {
        var formData = new FormData()
        if (this.organization) {
          formData.append('organization_id', this.organization.id)
        }
        if (this.requestTargetNBID) {
          formData.append('deputizing_request_to_user[login_id]', this.requestTargetNBID)
        } else if (this.requestTargetMail) {
          formData.append('deputizing_request_to_user[name]', this.requestTargetName)
          formData.append('deputizing_request_to_user[mail]', this.requestTargetMail)
          formData.append('deputizing_request_to_user[short_token]', this.requestShortToken)
        }
        formData.append('deputizing_request_to_user[message_content]', this.requestMessage)
        var sendTarget = this.requestTargetNBID
        if (!this.requestTargetNBID) {
          sendTarget = this.requestTargetName
        }
        this.$emit('submitSendInvitationEvent', formData, sendTarget, () => { this.initParams() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/createWithAgreementForm.scss';

.horus-create-deputize-invitation-to-user-modal {
  .modal-body {
    padding: 10px 20px;
    .link {
      cursor: pointer;
      color: #428bca;
    }
    input {
      font-size: 16px;
    }
    textarea {
      font-size: 16px;
    }
  }
  .create-tab-labels {
    margin: 0;
    display: flex;
    align-items: flex-end;
    .tab-label {
      margin: 0;
      padding: 3px 10px;
      border: 1px solid #666;
      border-bottom: none;
      background-color: #ddd;
      color: #666;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      &.active {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .create-by-nbid {
    border: 1px solid #666;
    padding: 10px;
  }
  .create-by-mail {
    border: 1px solid #666;
    padding: 10px;
    .notify-for-invitation-by-mail {
      border: 1px solid #c53d43;
      padding: 5px;
    }
  }
  textarea {
    width: 100%;
  }
  .submit-controll {
    margin: 10px 0;
    text-align: center;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      &.cancel-btn {
        background-color: #fff;
        color: #666;
      }
      &.send-request-btn {
        background-color: #666;
        color: #fff;
        &.disable {
          background-color: #aaa;
        }
      }
    }
  }
}
</style>
