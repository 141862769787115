import axios from './AxiosInstance'

export default {
  toManager: ({reporterOrganizationID, userID}) => {
    var formData = new FormData()
    formData.append('reporter_organization_id', reporterOrganizationID)
    formData.append('subject_user_id', userID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'reporter_organization_manager/to_manager',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  revokeManager: ({reporterOrganizationID, userID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'reporter_organization_manager/revoke_manager',
            withCredentials: true,
            params: { reporter_organization_id: reporterOrganizationID, subject_user_id: userID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }

}
