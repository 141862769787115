import axios from './AxiosInstance'

// id: number(id)
// name: string
// mail: string
// tel: string
// additional_comment: string
// organization_id: number (id)
// author_id: string (id)
// member_manager: boolean
// relation_manager: boolean
// deputize_manager: boolean
// default_signin: boolean
export default {
  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'service_using_organization_member/list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ service_using_organization_members: res.data.service_using_organization_members })
        }
      }).catch(error => { reject(error) })
    })
  },

  update: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_using_organization_member',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  updateDefaultSignin: ({targetID}) => {
    var formData = new FormData()
    formData.append('id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'service_using_organization_member/update_default_signin_member',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
