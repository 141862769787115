<template>
  <div class="horus-date-jump-controll-wrapper" v-show="showDateJumpControll">
    <div class="horus-date-jump-controll-backdrop" @click="closeControll"></div>
    <div class="horus-date-jump-controll">
      <div class="title-bar">
        <div class="month-move" @click="movePrevMonth">&lt;</div>
        <div class="year-month-display">{{ currentYear }}年{{ currentMonth }}月</div>
        <div class="month-move" @click="moveNextMonth">&gt;</div>
      </div>
      <div class="wday-bar">
        <div class="wday">日</div>
        <div class="wday">月</div>
        <div class="wday">火</div>
        <div class="wday">水</div>
        <div class="wday">木</div>
        <div class="wday">金</div>
        <div class="wday">土</div>
      </div>
      <div class="date-column">
        <div class="blank-panel" v-for="blank in prevBlanks" :key="blank">&nbsp;</div>
        <div class="date-panel" v-for="day in dateArrays" :key="day" @click="jump(day)">{{ day }}</div>
        <div class="blank-panel" v-for="blank in postBlanks" :key="blank">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusDateJumpControll',

  props: {
    showDateJumpControll: Boolean
  },

  data () {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1
    }
  },

  computed: {
    prevBlanks () {
      const prevCount = this.firstDayWday(this.currentYear, this.currentMonth)
      if (prevCount > 0) {
        return new Array(prevCount)
      } else {
        return []
      }
    },
    postBlanks () {
      const postCount = this.leftCountOfChart(this.currentYear, this.currentMonth)
      if (postCount > 0) {
        return new Array(postCount)
      } else {
        return []
      }
    },
    dateArrays () {
      var arr = []
      for (var i = 0, l = this.daysOfMonth(this.currentYear, this.currentMonth); i < l; i++) {
        arr.push(i + 1)
      }
      return arr
    }
  },

  methods: {
    // 引数のmonthは普通の月の数値。Date.getMonthで返ってくる月のインデックス値(数値の月-1の値)ではない
    daysOfMonth (year, month) {
      // 指定した月の次の月の0日目は, 指定した月の最終日が返ってくるのを利用
      return new Date(year, month, 0).getDate()
    },

    // 指定した月の一日の曜日を取得。日曜始まりの0インデックス
    firstDayWday (year, month) {
      return new Date(year, month - 1, 1).getDay()
    },

    leftCountOfChart (year, month) {
      const mod = ((this.firstDayWday(year, month) + this.daysOfMonth(year, month)) % 7)
      if (mod > 0) {
        return 7 - mod
      } else {
        return mod
      }
    },

    chartCount (year, month) {
      const left = this.firstDayWday(year, month) + this.daysOfMonth(year, month)
      const after = 7 - (left % 7)
      return left + after
    },

    movePrevMonth () {
      // 今月の0日目は前月の末日であることを利用
      const calcDay = new Date(this.currentYear, this.currentMonth - 1, 0)
      this.currentYear = calcDay.getFullYear()
      this.currentMonth = calcDay.getMonth() + 1
    },

    moveNextMonth () {
      const calcDay = new Date(this.currentYear, this.currentMonth, 1)
      this.currentYear = calcDay.getFullYear()
      this.currentMonth = calcDay.getMonth() + 1
    },

    closeControll () {
      this.$emit('dateJumpControlCloseEvent')
    },

    jump (day) {
      this.$emit('jumpDateTimelineEvent', this.currentYear, this.currentMonth, day)
      this.$emit('dateJumpControlCloseEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mediaQuery.scss';

.horus-date-jump-controll-wrapper {
  position: absoluete;
}
.horus-date-jump-controll-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.05;
}
.horus-date-jump-controll {
  position: absolute;
  top: 130px;
  left: calc((100vw - 1000px) / 2);
  width: 300px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #aaa;
  @include mediaQuery('tablet') {
    left: 10px;
  }
  @include mediaQuery('phone') {
    left: 5px;
  }
  .title-bar {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: #999;
    color: #fff;
    font-weight: bold;
    .month-move {
      padding: 0 5px;
      cursor: pointer;
    }
  }
  .wday-bar {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    .wday {
      width: 40px;
      text-align: center;
    }
  }
  .date-column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .blank-panel {
      margin-top: 3px;
      width: 40px;
      height: calc(40px - ((40px - 14pt) / 2) + 2);
      color: #322;
    }
    .date-panel {
      width: 38px;
      height: calc(40px - ((40px - 14pt) / 2));
      border: 1px solid #999;
      margin-top: 3px;
      padding-top: calc((40px - 14pt) / 2);
      font-size: 14pt;
      font-weight: bold;
      text-align: center;
      color: #999;
      cursor: pointer;
    }
  }
}
</style>
