<template>
  <div class="horus-user-mail-validation-view container">
    <div class="navbar">
      <div class="main-bar">
        <router-link to="/landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></router-link>
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">メールの疎通確認</h1>
      <div class="message-column">
        ナラティブブックで、あなたのメールアドレス変更手続きがなされました。
        このページでは、メールアドレスが実在することを確認するための作業を行います。
        下記ログインフィールドにNBIDとパスワードを入力してログインしてください。
      </div>
      <div class="signin-block">
        <form id='own_signin_form' v-on:submit.prevent="validateMail">
          <div class="signin_id_input"><label>NBID</label><input type="text" name="login_id" id='login_id' v-model="inputLoginId"></div>
          <div class="signin_pass_input"><label>パスワード</label><input type="password" name="password" ref="passwordField" id="password" v-model="inputPassword"></div>
          <div class="signin_pass_toggle">パスワード表示<input type="checkbox" id="toggle_password_field" ref="toggle" v-on:change="togglePasswordVisible" /></div>
          <button type="submit" class="btn-success signin-btn">ログイン</button>
        </form>
      </div>
    </div>
    <horus-footer />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  SigninUser
} from '@/models'
import { User } from '@/api'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusUserMailValidationView',

  components: {
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      token: '',
      inputLoginId: '',
      inputPassword: ''
    }
  },

  watch: {
    '$attrs': { handler: 'initToken', immediate: true }
  },

  methods: {
    async initToken () {
      this.token = this.$attrs.token
    },

    togglePasswordVisible (event) {
      const checkbox = event.target
      const passwordField = this.$refs.passwordField
      if (checkbox.checked === true) {
        passwordField.type = 'text'
      } else {
        passwordField.type = 'password'
      }
    },

    validateMail () {
      var formData = new FormData()
      formData.append('token', this.token)
      formData.append('login_id', this.inputLoginId)
      formData.append('password', this.inputPassword)
      User.mailValidation({ formData: formData }).then(() => {
        this.showConfirmDialog('メールアドレスの変更完了', 'メールアドレスの変更を完了しました。', DIALOG_TYPE.MESSAGE, () => {
          this.doSignin()
        })
      }).catch(error => {
        console.error(error.response)
        var additionalMessage = ''
        if (error.response.data.message) {
          if (error.response.data.message === 'bad token') {
            additionalMessage = '\n疎通確認メールが使用済みか、URLが正しく入力されていません。'
          } else if (error.response.data.message === 'bad auth') {
            additionalMessage = '\nNBIDかパスワードが正しくありません。'
          } else if (error.response.data.message === 'bad user') {
            additionalMessage = '\nメールアドレスの変更を請求した利用者と異なる利用者でログインしようとしています。'
          }
        }
        this.showNotifyDialog('メールアドレスの変更失敗', 'メールアドレスの変更に失敗しました。' + additionalMessage)
      })
    },

    doSignin () {
      SigninUser.api().fetch({ loginID: this.inputLoginId, password: this.inputPassword }).then(() => {
        const allUser = SigninUser.all()
        this.signinUser = allUser[0]
      })
      this.$router.push({ path: '/profile' })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-user-mail-validation-view {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
  }
  h1.centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .message-column {
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .signin-block {
    margin: 20px auto;
    padding: 40px;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #aaa;
    text-align: center;
    div {
      &.signin_pass_toggle {
        font-size: 12px;
      }
      margin-bottom: 10px;
      label {
        display: inline-block;
        margin-right: 10px;
        width: 100px;
        text-align: right;
        font-weight: bold;
      }
      input {
        font-size: 16px;
      }
    }
    .signin-btn {
      width: 150px;
      font-weight: bold;
    }
  }
}
</style>
