<template>
  <div class="horus-prepare-third-party-linkage-ignin-view container member">
    <div class="navbar">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div>
    <div class="contents">
      <h1 class="centering-title">第三者サービス連携ログイン情報の作成</h1>
      <div class="third-party-linkage-block" v-if="thirdPartyLinkage">
        <h2>[{{ thirdPartyLinkage.name }}]との連携ログイン情報を作成</h2>
        <div class="tpl-description">
          {{ thirdPartyLinkage.description }}
        </div>
        <div class="tpl-caption">
          [{{ thirdPartyLinkage.name }}]と連携した自動ログインを設定します。<br/>
          よろしければ、下のフォームにNBIDとパスワードを入力して、「設定」を押してください。
        </div>
      </div>
      <div class="fail-message-block" v-if="thirdPartyLinkageFailed">
        連携しようとしているサービスが正常に取得出来ません。<br/>
        複数の原因が考えられますが、連携元のサービス担当者にお問い合わせください。
      </div>
      <div class="signin-block" v-if="thirdPartyLinkage">
        <form id='own_signin_form' v-on:submit.prevent="createLinkageSignin">
          <div class="signin-input"><label>NBID</label><input type="text" v-model="inputLoginId" ref="loginIDField"></div>
          <div class="signin-input"><label>パスワード</label><input type="password" ref="passwordField" id="password" v-model="inputPassword"></div>
          <div class="signin_pass_toggle">パスワード表示<input type="checkbox" ref="toggle" v-on:change="togglePasswordVisible" /></div>
          <button type="submit" class="btn success signin-btn">設定</button>
        </form>
      </div>
    </div>
    <horus-footer/>
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>連携するアカウントの選択</h1>
      </div>
      <div class="modal-body member-selection-modal-body">
        <div class="member-selection" v-if="memberSelection.length > 0">
          <div class="member-info"
            v-for="member in memberSelection"
            :key="'tplMemberSel' + member.id">
            <div class="member-name">{{ member.name }}</div>
            <div class="org-name">所属: {{ member.organization_name }}</div>
            <button class="member-select-btn" @click="confirmCreateLinkageSignin(member)">選択</button>
          </div>
        </div>
        <div class="member-select-controll">
          <button class="close-btn" @click="cancelMemberSelect">選択せずに閉じる</button>
        </div>
      </div>
    </horus-modal-frame>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { SigninMember } from '@/models'
import {
  ThirdPartyLinkage,
  ThirdPartySigninToken
} from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusPrepareThirdPartyLinkageSigninView',

  components: {
    HorusFooter,
    HorusConfirmDialog,
    HorusModalFrame
  },

  data () {
    return {
      code: null,
      token: null,
      returnID: null,
      thirdPartyLinkage: null,
      thirdPartyLinkageFailed: false,
      inputLoginId: null,
      inputPassword: null,
      showModal: false,
      memberSelection: []
    }
  },

  watch: {
    '$attrs': { handler: 'loadLinkage', immediate: true }
  },

  methods: {
    async loadLinkage () {
      this.code = this.$attrs.code
      this.token = this.$attrs.token
      this.returnID = this.$attrs.return_id
      if (this.code && this.token) {
        try {
          let res = await ThirdPartyLinkage.fetch_by_token({ code: this.code, token: this.token })
          console.log(res.result)
          this.thirdPartyLinkage = res.result.third_party_linkage
        } catch (error) {
          console.error(error.response)
          this.thirdPartyLinkageFailed = true
        }
      }
    },

    togglePasswordVisible (event) {
      const checkbox = event.target
      const passwordField = this.$refs.passwordField
      if (checkbox.checked === true) {
        passwordField.type = 'text'
      } else {
        passwordField.type = 'password'
      }
    },

    createLinkageSignin () {
      console.log('createLinkageSignin')
      SigninMember.api().fetchMemberSelection({ loginID: this.inputLoginId, password: this.inputPassword }).then(res => {
        console.log(res)
        if (res.members) {
          if (res.members.length === 1) {
            this.executeCreateLinkageSignin(res.members[0].id)
          } else if (res.members.length > 1) {
            this.memberSelection = res.members
            this.showModal = true
          } else {
            this.showNotifyDialog('職員アカウントがありません', '第三者サービス連携の利用には、職員アカウントが必要です。\n入力されたログイン情報のアカウントには、職員アカウントがありません。')
          }
        }
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('ログイン情報が正しくありません', 'NBIDかパスワードが間違っています。\nアカウント情報が取得出来ませんでした。')
      })
    },

    cancelMemberSelect () {
      this.showModal = false
      this.memberSelection = []
    },

    confirmCreateLinkageSignin (member) {
      this.showConfirmDialog(
        '連携アカウントの選択',
        member.organization_name + 'の職員アカウントと連携します。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeCreateLinkageSignin(member.id) }
      )
    },

    executeCreateLinkageSignin (memberID) {
      var formData = new FormData()
      formData.append('code', this.code)
      formData.append('token', this.token)
      formData.append('login_id', this.inputLoginId)
      formData.append('password', this.inputPassword)
      formData.append('target_service_user_id', memberID)
      ThirdPartySigninToken.create({ formData: formData }).then(res => {
        console.log(res)
        window.location = this.thirdPartyLinkage.callback_url + '?code=' + res.code + '&token=' + res.token + '&return_id=' + this.returnID
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('サービス連携ログイン情報の作成失敗', 'このアカウントでは、指定された第三者サービスの連携が行えません。\n施設での連携許可が無いようです。\n施設のナラティブブック管理担当者にお問い合わせください。')
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/_basicInputStyle.scss';

.horus-prepare-third-party-linkage-ignin-view {
  .navbar {
    .main-bar {
      background-color: #2b4733;
      background-image: url("/static/images/cgc_header_iconless.png");
    }
  }
  .contents {
    height: calc(100% - 51px - 132px - 20px);
    overflow: scroll;
    padding: 10px 50px;
    @include mediaQuery('tablet') {
      padding: 10px;
    }
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    .fail-message-block {
      margin: 30px;
      padding: 20px;
      border: 1px solid #c53d43;
      @include mediaQuery('phone') {
        margin: 30px 0px;
        padding: 10px;
      }
    }
    .third-party-linkage-block {
      margin: 10px 0;
      h2 {
        margin: 5px 0;
        font-size: 1.1em;
        font-weight: bold;
      }
      .tpl-description {
        padding: 10px;
      }
      .tpl-caption {
        margin: 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
      }
    }
    .signin-block {
      margin: 20px auto;
      padding: 40px;
      max-width: 500px;
      background-color: #fff;
      border: 1px solid #aaa;
      text-align: center;
      @include mediaQuery('tablet') {
        // margin: 10px;
      }
      @include mediaQuery('phone') {
        padding: 30px 10px;
        margin: 10px;
      }
      .signin-input {
        width: 100%;
        margin-bottom: 10px;
        label {
          display: inline-block;
          margin-right: 10px;
          width: 100px;
          text-align: right;
          font-weight: bold;
          @include mediaQuery('phone') {
            width: 70px;
          }
        }
        input {
          font-size: 16px;
        }
      }
      .signin_pass_toggle {
        margin-bottom: 10px;
        font-size: 12px;
      }
      .signin-btn {
        width: 150px;
        padding: 8px 16px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .signin-button-controlls {
      margin: 20px;
      text-align: center;
      line-height: 2em;
      .join-btn {
        font-weight: bold;
      }
    }
  }
  .member-selection-modal-body {
    padding: 10px;
    .member-info {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      padding: 5px;
      border: 1px solid #ddd;
      .member-name {
        margin-right: 10px;
        font-weight: bold;
      font-size: 16px;
      }
      .org-name {
        margin-right: 20px;
      font-size: 16px;
      }
      .member-select-btn {
        flex-shrink: 0;
        width: 50px;
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
    .member-select-controll {
      margin-top: 10px;
      text-align: center;
      .close-btn {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #fff;
        color: #666;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
