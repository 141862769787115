<template>
  <div class="horus-system-management-home">
    <h1 class="centering-title">ホーム</h1>
    <h2>簡易集計&nbsp;<button type="button" class="ahead-btn" @click="loadSimpleData">集計リロード</button></h2>
    <div class="simple-data">
      <div class="simple-list">
        <div class="list-element"><label>総ユーザー数</label><span>{{ systemSimpleData.total_user_count }}</span></div>
        <div class="list-element"><label>ログイン可能なユーザー数</label><span>{{ systemSimpleData.available_user_count }}</span></div>
        <div class="list-element"><label>かかりつけを設定していないユーザー数</label><span>{{ systemSimpleData.non_related_user_count }}</span></div>
      </div>
    </div>
    <h2>システム概略</h2>
    <div class="simple-list">
      <div class="list-element">
        <label>お知らせ</label>
        <span>公開数:{{systemSimpleData.publish_announcement_count}} 総数:{{systemSimpleData.all_announcement_count}}</span>
      </div>
      <div class="list-element">
        <label>管理ユーザー</label>
        <span>管理ユーザー数:{{ systemSimpleData.manager_count }} 停止中:{{ systemSimpleData.deleted_manager_count }}</span>
      </div>
      <div class="list-element">
        <label>施設申請</label>
        <span>処理待ち申請数:{{ systemSimpleData.organization_request_count }}</span>
      </div>
      <div class="list-element">
        <label>施設</label>
        <span>施設総数:{{ systemSimpleData.organization_count }}</span>
      </div>
      <div class="list-element">
        <label>施設タグ</label>
        <span>施設タグ数:{{ systemSimpleData.organization_tag_count }}</span>
      </div>
      <div class="list-element">
        <label>報告施設申請</label>
        <span>処理待ち申請数:{{ systemSimpleData.reporter_organization_request_count }}</span>
      </div>
      <div class="list-element">
        <label>報告施設</label>
        <span>報告施設総数:{{ systemSimpleData.reporter_organization_count }}</span>
      </div>
      <div class="list-element">
        <label>ユーザー</label>
        <span>総ユーザー数: {{ systemSimpleData.total_user_count }}</span>
      </div>
      <div class="list-element">
        <label>冊子リクエスト</label>
        <span>承認待ちリクエスト: {{ systemSimpleData.waiting_archve_request_count }}</span>
      </div>
      <div class="list-element">
        <label>第三者連携サービス</label>
        <span>承認待ちサービス: {{ systemSimpleData.third_party_linkage_request_count }}</span>
      </div>
      <div class="list-element">
        <label>静的QRコードカテゴリ管理</label>
        <span>登録済静的QRコードカテゴリ: {{ systemSimpleData.static_identify_code_category_count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SystemTotalization
} from '@/api'

export default {
  name: 'HorusSystemManagementHome',

  props: {
    systemManager: Object
  },

  data () {
    return {
      systemSimpleData: {}
    }
  },

  created () {
    this.loadSimpleData()
  },

  methods: {
    loadSimpleData () {
      SystemTotalization.fetchSimpleData().then(res => {
        this.systemSimpleData = res.result
      })
    },

    showTotalizationPage () {
      this.$router.push({ path: '/system_management/totalization' })
    },

    showAnnouncementPage () {
      this.$router.push({ path: '/system_management/public_announcement' })
    },

    showSystemManagerPage () {
      this.$router.push({ path: '/system_management/system_manager' })
    },

    showOrganizationRequestPage () {
      this.$router.push({ path: '/system_management/organization_request' })
    },

    showOrganizationPage () {
      this.$router.push({ path: '/system_management/organization' })
    },

    showOrganizatoinTagPage () {
      this.$router.push({ path: '/system_management/organization_tag' })
    },

    showReporterOrganizationRequestPage () {
      this.$router.push({ path: '/system_management/reporter_organization_request' })
    },

    showReporterOrganizationPage () {
      this.$router.push({ path: '/system_management/reporter_organization' })
    },

    showUserPage () {
      this.$router.push({ path: '/system_management/manage_user' })
    },

    showArchivePage () {
      this.$router.push({ path: '/system_management/manage_archive' })
    },

    showThirdPartyLinkage () {
      this.$router.push({ path: '/system_management/third_party_linkage' })
    },

    showStaticIdentifyCodeCategory () {
      this.$router.push({ path: '/system_management/static_identify_code_category' })
    },

    showClientCertificate () {
      this.$router.push({ path: '/system_management/client_certificate' })
    },

    showSystemDataManage () {
      this.$router.push({ path: '/system_management/system_data_manager' })
    },

    showContactMails () {
      this.$router.push({ path: '/system_management/system_contact_mail' })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-management-home {
  height: calc(100% - 60px);
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  h2 {
    margin: 10px 0;
    border-bottom: 1px solid #039;
    font-size: 1.2em;
    font-weight: normal;
  }
  .simple-data {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .simple-list {
    .list-element {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }
  }
  .ahead-btn {
    border: 1px solid #039;
    border-radius: 3px;
    background-color: #039;
    color: #fff;
    font-weight: bold;
  }
}
</style>
