import axios from 'axios'

// csrfトークンを自動更新
// axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
// axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
// axios.defaults.withCredentials = true

// NOTE !!!!!!! バックエンドへの回送用URLを環境で返られるようにすべし !!!!!!!!!
let httpClient = axios.create({
  // jar: cookiejar, // cookiejarを有効化する
  withCredentials: true, // 依然として必要
  credentials: 'include',
  headers: {
    'Cache-Controll': 'no-store',
    'Expires': 0,
  },
  baseURL: window.location.origin + process.env.VUE_APP_BACKEND_PATH,
})

export default {
  httpClient,

  // NOTE !!!!!!! バックエンドへの回送用URLを環境で返られるようにすべし !!!!!!!!!
  currentCsrfToken () {
    return new Promise((resolve, reject) => {
      const client = axios.create({
        withCredentials: true, // 依然として必要
        xsrfHeaderName: 'X-CSRF-TOKEN',
        credentials: 'include',
        mode: 'cors',
        baseURL: window.location.origin + process.env.VUE_APP_BACKEND_PATH,
      })
      client.get('/ajax_helper/csrf_token', { withCredentials: true }).then(res => {
        // console.log(res)
        const parser = new DOMParser()
        const document = parser.parseFromString(res.data, 'text/html')
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        // const token = res.headers["x-csrf-token"]
        // console.log(token)
        resolve(token)
      }).catch(error => {
        reject(error)
      })
    }).catch(error => {
      console.error(error)
    })
  },

  csrfHeader (method = 'POST') {
    return new Promise((resolve, reject) => {
      this.currentCsrfToken().then(token => {
        // console.log('axiosInstance, csrfHeader..' + token)
        resolve( {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token,
            'X-HTTP-Method-Override': method,
            'Cache-Controll': 'no-store',
            'Expires': 0,
          },
          withCredentials: true,
          xsrfHeaderName: 'X-CSRF-TOKEN',
          credentials: 'include',
          mode: 'cors',
          // baseURL: window.location.origin + process.env.VUE_APP_BACKEND_PATH,
        })
      }).catch(error => {
        reject(error)
      })
    })
  }

}
