<template>
  <div class="horus-authenticate-activate-invitation-modal">
    <horus-modal-frame
      :showModalFlag="showAcitivateInvitationModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />本人がログイン出来るようにする</h1>
      </div>
      <div class="modal-body">
        <div class="notify_for_admit">
          <h1>本人がログイン出来るようにする必要事項</h1>
          ナラティブブックで本人がログイン出来るようにするためには、本人に連絡の取れるメールアドレスの設定が必要です。<br/>
          本人に連絡が取れるメールアドレス宛に、ログイン案内を送信し、その後本人の操作でパスワード等を設定してもらいます。
          <ul>
            <li>下記フォームに本人が確認出来るメールアドレスと本人確認コードを入力する</li>
            <li>ログイン案内を送信</li>
            <li>入力した本人確認コードを利用者本人に伝える</li>
            <li>利用者本人が受け取ったメールのリンクからログイン情報登録画面にアクセスする</li>
            <li>利用者本人がNBIDとパスワードを入力する</li>
            <li>完了</li>
          </ul>
        </div>
        <div class="create-by-mail">
          <horus-attribute-in-form
            :labelText="'メールアドレス'"
            :necessary="true"
            :validationState="requestMailState"
            :validationMessage="requestMailNotify"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMail" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'メールアドレス(確認)'"
            :necessary="true"
            :validationState="requestMailConfirmationState"
            :validationMessage="requestMailConfirmationNotify"
            :attributeNotify="''">
            <input type="text" v-model="requestTargetMailConfirmation" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'メッセージ'"
            :necessary="false"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <textarea rows="3" cols="20" v-model="requestMessage"></textarea>
          </horus-attribute-in-form>
        </div>
        <div class="notify_for_admit">
          <h1>ログイン情報登録案内送信における確認事項</h1>
          <ul>
            <li>ナラティブブックの利用規約に基づいて運用していること</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendRequest">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !requestReady }" @click="submitSendRequest">案内状の送信</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusAuthenticationActivateInvitationModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    showAcitivateInvitationModal: Boolean,
    timelineOwner: Object
  },

  data () {
    return {
      requestTargetMail: '',
      requestTargetMailConfirmation: '',
      requestShortToken: '0000',
      requestMessage: '',
      admitAgreement: false
    }
  },

  computed: {
    requestMailState () {
      if (this.requestTargetMail.length > 0) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    requestMailNotify () {
      if (this.requestTargetMail.length <= 0) {
        return 'メールアドレスは入力必須です'
      } else {
        return ''
      }
    },

    requestMailConfirmationState () {
      if (this.requestTargetMail.length > 0 && this.requestTargetMail === this.requestTargetMailConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    requestMailConfirmationNotify () {
      if (this.requestTargetMail !== this.requestTargetMailConfirmation) {
        return '入力されたメールアドレスと一致しません'
      } else {
        return ''
      }
    },

    // requestShortTokenState () {
    //   if (this.requestShortToken.length <= 0 || this.requestShortToken.trim().match(/^\d{4}$/g)) {
    //     return 'valid'
    //   } else {
    //     return 'invalid'
    //   }
    // },

    // requestShortTokenNotify () {
    //   if (this.requestShortToken.length > 0 && this.requestShortToken.trim().match(/^\d{4}$/g)) {
    //     return '本人確認コードは半角数字4桁で入力ください'
    //   } else {
    //     return ''
    //   }
    // },

    requestReady () {
      if (
        this.requestTargetMail.length > 0 &&
        this.requestTargetMail === this.requestTargetMailConfirmation &&
        this.admitAgreement
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    initParams () {
      this.requestTargetMail = ''
      this.requestTargetMailConfirmation = ''
      this.requestShortToken = '0000'
      this.requestMessage = ''
      this.admitAgreement = false
    },

    cancelSendRequest () {
      this.$emit('cancelSubmitEvent', () => { this.initParams() })
    },

    submitSendRequest () {
      console.log('auth modal')
      if (this.admitAgreement && (this.requestTargetMail && this.requestTargetMail === this.requestTargetMailConfirmation)) {
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        formData.append('authentication_activate_invitation[mail]', this.requestTargetMail)
        // formData.append('authentication_activate_invitation[short_token]', this.requestShortToken)
        formData.append('authentication_activate_invitation[message_content]', this.requestMessage)
        this.$emit('submitEvent', formData, () => { this.initParams() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-authenticate-activate-invitation-modal {
  .modal-body {
    padding: 10px 20px;
    .link {
      cursor: pointer;
      color: #428bca;
    }
    input {
      font-size: 16px;
    }
    textarea {
      font-size: 16px;
      width: 100%;
      resize: none;
    }
  }
  .submit-controll {
    margin: 10px 0;
    text-align: center;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      &.cancel-btn {
        background-color: #fff;
        color: #666;
      }
      &.send-request-btn {
        background-color: #666;
        color: #fff;
        &.disable {
          background-color: #aaa;
        }
      }
    }
  }
}
</style>
