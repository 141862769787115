<template>
  <div class="horus-related-reporter-organization-column labeled_content_block">
    <div class="labeled_content_single_block">
      <label>施設名</label>
      <div class="content">
        <img :src="reporterOrganization.iconImageSrc"/>
        {{ reporterOrganization.name }}({{ reporterOrganization.shortName }})
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ reporterOrganization.address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話</label>
      <div class="content">{{ reporterOrganization.tel }}</div>
    </div>
    <div class="content-controll-block">
      <div class="content">
        <button class="remove-organization-btn" @click="removeOrganization">サービス利用停止</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusRelatedReporterOrganizationColumn',

  props: {
    reporterOrganization: Object
  },

  methods: {
    removeOrganization () {
      this.$emit('removeRelationEvent', this.reporterOrganization)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
