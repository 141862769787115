<template>
  <div class="horus-normal-record-timeline" ref="mainTimelineInner" id="mainTimelineInner">
    <div class="load-button-block" v-show="latestRecordsExist">
      <button type="button" class="load-btn" @click="loadLatestRecords">以後のレポートを表示する</button>
    </div>
    <div class="month-splitter" v-if="timelineRecords[0] && timelineRecords[0].record">{{ timelineRecords[0].record.targetAt.getFullYear() + '年' + (timelineRecords[0].record.targetAt.getMonth() + 1) + '月' }}</div>
    <div v-if="timelineNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <horus-timeline-record
      v-else
      v-for="(timelineRecord, index) in timelineRecords"
      :timelineOwner="timelineOwner"
      :timelineRecord="timelineRecord"
      :loggedInAuthor="loggedInAuthor"
      :nextRecord="timelineRecords[index+1]"
      :lastWatchAt="timelineLastWatchAt"
      :key="'trmaintimelinedisplay' + timelineRecord.id"
      v-on:showQuateEvent="showQuateThreadModal"
      v-on:showReplyEvent="showReplyRecordsModal"
      v-on:recordTagNarrowDownEvent="narrowDownByTag"
      v-on:showRecorPhotoEvent="showRecordPhoto"
      v-on:openRecordFileEvent="openRecordFile"
      v-on:showDrugEntryPhotoEvent="showDrugEntryPhotos"
      v-on:showVideoContentEvent="showVideoContent"
      v-on:showAudioContentEvent="showAudioContent"
      v-on:addThanksEvent="addThanks"
      v-on:removeThanksEvent="removeThanks"
      v-on:nudgeRecordEvent="addNudge"
      v-on:deleteNudgeEvent="removeNudge"
      v-on:setReplyEvent="setReplyRecord"
      v-on:destroyRecordEvent="destroyRecord"
      v-on:editRecordEvent="editRecord"
      />
    <div class="load-button-block" v-show="oldRecordsExist">
      <button type="button" class="load-btn" @click="loadOldRecords">以前のレポートを表示する</button>
    </div>
  </div>
</template>

<script>
import {
  TimelineRecord,
  Author
} from '@/models'

import {
  TimelineState
} from '@/api'

import HorusTimelineRecord from '@/components/timelineRecord/HorusTimelineRecord.vue'

export default {
  name: 'HorusNormalRecordTimeline',

  components: {
    HorusTimelineRecord
  },

  props: {
    loggedInUser: Object,
    signinMember: Object,
    loggedInAuthor: Object,
    timelineOwner: Object,
    timelineTargetDate: Date,
    timelineTargetRecord: Object,
    narrowDownTags: Array,
    narrowDownAuthors: Array,
    timelineShouldLoad: Boolean,
    timelineShouldUpdate: Boolean
  },

  data () {
    return {
      timelineRecords: [],
      loadReserveRecords: [],
      heroRecord: null,
      latestRecordsExist: false,
      oldRecordsExist: false,
      timelineNowLoading: false,
      timelineLastWatchAt: null
    }
  },

  watch: {
    timelineOwner: { handler: 'loadRecords' },
    timelineShouldLoad: { handler: 'loadRecords', immediate: true },
    timelineShouldUpdate: { handler: 'updateRecords' },
    // 'loadReserveRecords.length': { handler: 'loadReservedRecord' }
  },

  computed: {
    currentSearchOption () {
      var options = {}
      if (this.narrowDownTags.length > 0) {
        options.hash_tags = this.narrowDownTags.map(tag => tag.id)
      }
      if (this.narrowDownAuthors.length > 0) {
        options.authors = []
        options.author_suoms = []
        this.narrowDownAuthors.forEach(author => {
          if (author.isMember) {
            options.author_suoms.push(author.rawID)
          } else {
            options.authors.push(author.rawID)
          }
        })
      }
      return options
    }
  },

  methods: {
    // recordIDListは、{ id: id, update_at: update_at }で返却されるように仕様変更された。IDの取得方法変更への対応と、update_atの照合でロードリストに追加するかの判定をする
    async loadRecords () {
      if (this.timelineOwner && this.timelineShouldLoad) {
        this.timelineNowLoading = true
        this.heroRecord = null
        if (this.timelineTargetDate) {
          console.log('load target date records')
          let dateRes = await TimelineRecord.api().fetchTargetDateIDList({ timelineOwnerID: this.timelineOwner.id, targetDate: this.timelineTargetDate, options: this.currentSearchOption })
          await this.reserveLoadRecords(dateRes.recordIDList)
          await TimelineRecord.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: [dateRes.targetRecordID] })
          this.heroRecord = await TimelineRecord.find(dateRes.targetRecordID)
          this.$emit('timelineLoadFinishEvent')
          this.updateLoadButtonStatus(this.currentSearchOption)
          this.scrollToRecord(this.heroRecord)
        } else if (this.timelineTargetRecord) {
          console.log('load target RECORD records')
          let targetRes = await TimelineRecord.api().fetchTargetRecordIDList({ timelineOwnerID: this.timelineOwner.id, targetRecordID: this.timelineTargetRecord.id })
          // console.log(JSON.stringify(res))
          await this.reserveLoadRecords(targetRes.recordIDList)
          await TimelineRecord.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: [targetRes.targetRecordID] })
          this.heroRecord = await TimelineRecord.find(targetRes.targetRecordID)
          this.$emit('timelineLoadFinishEvent')
          this.updateLoadButtonStatus()
          this.scrollToRecord(this.heroRecord)
        } else if (this.timelineRecords.length <= 0) {
          console.log('load LAST READ records')
          console.log('loading : ', this.timelineNowLoading)
          let lastRes = await TimelineRecord.api().fetchLastReadIDList({ timelineOwnerID: this.timelineOwner.id })
          await this.reserveLoadRecords(lastRes.recordIDList)
          this.timelineLastWatchAt = new Date(lastRes.lastWatchAt)
          if (lastRes.targetRecordID) {
            await TimelineRecord.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: [lastRes.targetRecordID] })
            this.heroRecord = await TimelineRecord.find(lastRes.targetRecordID)
            this.scrollToRecord(this.heroRecord)
          }
          this.$emit('timelineLoadFinishEvent')
          this.updateLoadButtonStatus()
        } else {
          console.log('load NORMAL records')
          console.log('loading : ', this.timelineNowLoading)
          let normalRes = await TimelineRecord.api().fetchRecordIDList({ timelineOwnerID: this.timelineOwner.id, options: this.currentSearchOption })
          this.$emit('timelineLoadFinishEvent')
          await this.reserveLoadRecords(normalRes.recordIDList)
          this.updateLoadButtonStatus(this.currentSearchOption)
          this.timelineLastWatchAt = new Date(normalRes.lastWatchAt)
        }
        this.timelineNowLoading = false
      }
    },

    async loadLatestRecords () {
      if (this.timelineRecords.length > 0) {
        var topRecord = this.timelineRecords[0]
        let res = await TimelineRecord.api().fetchLatestRecordIDList({ timelineOwnerID: this.timelineOwner.id, targetRecordID: topRecord.id, options: this.currentSearchOption })
        this.reserveLoadRecords(res.recordIDList.reverse())
        this.updateLoadButtonStatus(this.currentSearchOption)
      }
    },

    async loadOldRecords () {
      console.log('loadOrdRecords call,')
      if (this.timelineRecords.length > 0) {
        var lastRecord = this.timelineRecords[this.timelineRecords.length - 1]
        let res = await TimelineRecord.api().fetchOldRecordIDList({ timelineOwnerID: this.timelineOwner.id, targetRecordID: lastRecord.id, options: this.currentSearchOption })
        console.log('loadOldRecordIDList respond: ' + res)
        this.reserveLoadRecords(res.recordIDList)
        this.updateLoadButtonStatus(this.currentSearchOption)
      }
    },

    // 引数のrecordIDListは{id: id, update_at: update_at}の配列形式
    async reserveLoadRecords (recordIDList) {
      this.timelineRecords = []
      for(var recordHash of recordIDList) {
        var record = await TimelineRecord.find(recordHash.id)
        this.timelineRecords.push({ id: recordHash.id, record: record })
        if (!record || record.update_at !== recordHash.update_at) {
          this.loadReserveRecords.push(recordHash.id)
        }
      }
      this.loadReservedRecord()
    },

    async loadReservedRecord () {
      console.log('laodReservedRecrod')
      if (this.loadReserveRecords.length > 0) {
        var loadIDList = this.loadReserveRecords.slice(0, 10)
        // console.log(loadIDList)
        var loadAuthorList = []
        await TimelineRecord.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: loadIDList })
        for(var recordID of loadIDList) {
          for(var tr of this.timelineRecords) {
            if (tr.id === recordID) {
              tr.record = await TimelineRecord.find(recordID)
              console.log('on loadReservedRecord')
              console.log(tr.record)
              if (!tr.record.author) {
                if (loadAuthorList.indexOf(tr.record.author_id) < 0) loadAuthorList.push(tr.record.author_id)
              }
            }
          }
        }
        this.loadReserveRecords.splice(0, 10)
        if (loadAuthorList.length > 0) {
          Author.api().fetchByID({ idList: loadAuthorList })
        }
        this.loadReservedRecord()
      }
    },

    updateRecords () {
      if (this.timelineOwner && this.timelineShouldUpdate) {
        TimelineRecord.api().fetchUpdateRecordList({ timelineOwnerID: this.timelineOwner.id }).then(res => {
          console.log('NormalRecordTimeline, updateRecords')
          console.log(res)
          res.deletedRecords.forEach(deletedRecord => {
            this.timelineRecords.forEach((timelineRecord, index) => {
              if (timelineRecord.id === deletedRecord) {
                this.timelineRecords.splice(index, 1)
              }
            })
          })
          var updatedRecords = []
          var addedRecords = []
          for(var updateRecord of res.records) {
            var recordExist = false
            for(var timelineRecord of this.timelineRecords) {
              var uRecord = TimelineRecord.find(updateRecord.id)
              if (timelineRecord.id === updateRecord.id) {
                timelineRecord.record = uRecord
                updatedRecords.push(uRecord)
                recordExist = true
              }
            }
            if (!recordExist) {
              var aRecord = TimelineRecord.find(updateRecord.id)
              this.timelineRecords.unshift({ id: updateRecord.id, record: aRecord })
              addedRecords.push(aRecord)
            }
          }
          this.timelineRecords.sort((f, s) => {
            if (f.record && s.record && f.record.targetAt > s.record.targetAt) return -1
            if (f.record && s.record && f.record.targetAt < s.record.targetAt) return 1
            return 0
          })
          this.$emit('timelineUpdateFinishEvent', addedRecords, updatedRecords)
        }).catch(err => {
          console.error(err)
        })
      }
    },

    // load button flag
    updateLoadButtonStatus (options = {}) {
      if (this.timelineRecords.length > 0) {
        TimelineState.latestRecordExist({ timelineOwnerID: this.timelineOwner.id, targetRecordID: this.timelineRecords[0].id, options: options }).then(res => {
          this.latestRecordsExist = res.latestExist
        })
        TimelineState.oldRecordExist({ timelineOwnerID: this.timelineOwner.id, targetRecordID: this.timelineRecords[this.timelineRecords.length - 1].id, options: options }).then(res => {
          this.oldRecordsExist = res.oldExist
        })
      }
    },

    scrollToRecord (record) {
      this.$emit('scrollToRecordEvent', record)
    },

    scrollToTimelineTop () {
      this.$emit('scrollTopEvent')
    },

    // event listeners bubble up
    showQuateThreadModal (timelineRecord) {
      this.$emit('shoeQuateThreadModalEvent', timelineRecord)
    },

    showReplyRecordsModal (timelineRecord) {
      this.$emit('showReplyEvent', timelineRecord)
    },

    narrowDownByTag (tag) {
      this.$emit('recordTagNarrowDownEvent', tag)
    },

    showRecordPhoto (timelineRecord, firstShowIndex) {
      this.$emit('showRecordPhotoEvent', timelineRecord, firstShowIndex)
    },

    showDrugEntryPhotos (timelineRecord, firstShowIndex) {
      this.$emit('showDrugEntryPhotoEvent', timelineRecord, firstShowIndex)
    },

    openRecordFile () {
      this.$emit('')
    },

    showVideoContent (videoContent) {
      this.$emit('showVideoContentEvent', videoContent)
    },

    showAudioContent (audioContent) {
      this.$emit('showAudioContentEvent', audioContent)
    },

    addNudge (record) {
      this.$emit('nudgeRecordEvent', record)
    },

    removeNudge (record) {
      this.$emit('deleteNudgeEvent', record)
    },

    setReplyRecord (record) {
      this.$emit('setReplyEvent', record)
    },

    editRecord (record) {
      this.$emit('editRecordEvent', record)
    },

    addThanks (record) {
      this.$emit('addThanksEvent', record)
    },

    removeThanks (record) {
      this.$emit('removeThanksEvent', record)
    },

    destroyRecord (record) {
      this.$emit('destroyRecordEvent', record)
    }
  }

}
</script>

<style lang="scss" scoped>
.now-loading {
  padding: 5px;
  // border: 1px solid #ddd;
  // background-color: #eee;
  color: #999;
  text-align: center;
  .progress {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }
}
</style>
