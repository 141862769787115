import { Model } from '@vuex-orm/core'
import moment from 'moment'
import VitalEntry from './VitalEntry'

export default class VitalPulseRate extends Model {
  static entity = 'vitalPulseRate'

  static fields () {
    return {
      id: this.attr(null),
      vital_entry_id: this.attr(null),
      user_id: this.attr(null),
      measure_at: this.attr(''),
      value: this.attr(null)
    }
  }

  get vitalEntry () {
    return VitalEntry.find(this.vital_entry_id)
  }

  get measureAt () {
    return new Date(this.measure_at)
  }

  get measureAtStr () {
    return moment(this.measureAt).format('YYYY/MM/DD HH:mm')
  }

  get measureAtJp () {
    return moment(this.measureAt).format('YYYY年MM月DD日 HH:mm')
  }
}
