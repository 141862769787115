import axios from './AxiosInstance'

export default {
  preMailValidation: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/pre_mail_validation',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  confirmMail: (payload) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/confirm_mail',
            withCredentials: true,
            params: payload
          }
        }).then(res => {
          // console.log('in api')
          // console.log(res)
          // resolve(res)
          resolve({ pre_mail_validation: res.data.pre_mail_validation, mail: res.data.mail_address })
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  nbidValidation: ({nbid}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'user/user_name_valid',
        params: { name: nbid },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ result: res.data.status })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  acceptMailChange: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/accept_mail_change',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  mailValidation: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/mail_validation',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  resetPassword: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/reset_password',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  changePassword: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/change_password',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  quitedList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'user/quited_users',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ users: res.data.users })
        }
      }).catch(error => { reject(error) })
    })
  },

  prepareRevoke: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'user/prepare_revoke',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ messages: res.data.messages })
        }
      }).catch(error => { reject(error) })
    })
  },

  quitNarrativeBook: ({formData}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'user/quit_narrativebook',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  restoreUser: ({targetUserID}) => {
    var formData = new FormData()
    formData.append('id', targetUserID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/restore_quit_user',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroyUserPhisical: ({targetUserID}) => {
    var formData = new FormData()
    formData.append('id', targetUserID)
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'user/destroy_user_physicaly',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve({ result: res.data })
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  search: ({searchConditions}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'user/search',
        withCredentials: true,
        params: searchConditions
      }).then(res => {
        if (res.data.success === true) {
          resolve(res.data)
        }
      }).catch(error => { reject(error) })
    })
  },

  updateCurrentForUserSummary: () => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/update_current_for_user_summary',
            withCredentials: true
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  hardResetMail: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'user/hard_reset_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
