import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import moment from 'moment'
import TimelineOwner from './TimelineOwner'
import Author from './Author'
import HashTag from './HashTag'
import PhotoReport from './PhotoReport'
import FileReport from './FileReport'
import MedicalExamination from './MedicalExamination'
import DrugEntry from './DrugEntry'
import DrugEntryPhoto from './DrugEntryPhoto'
import MillviVideoContent from './MillviVideoContent'
import MillviAudioContent from './MillviAudioContent'

export default class TimelineRecord extends Model {
  static entity = 'timelineRecords'

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      record_at: this.string(''),
      target_at: this.string(''),
      update_at: this.string(''),
      surrogated: this.boolean(false),
      private: this.boolean(false),
      deleted: this.boolean(false),
      author_id: this.string(''),
      editor_id: this.string(''),
      textComment: this.string(''),
      tags: this.attr(null),
      thanks_authors: this.attr(null),
      quated_record_id: this.attr(null),
      quateRecordsCount: this.attr(null),
      nudged: this.attr(null),
      multipost: this.attr(false),
      multipost_authors: this.attr(null)
    }
  }

  get timelineOwner () {
    return TimelineOwner.find(this.user_id)
  }

  get author () {
    // if (!this.author_) {
    //   this.author_ = Author.find(this.author_id)
    // }
    // return this.author_
    return Author.find(this.author_id)
  }

  get editor () {
    if (this.editor_id.length > 0) {
      return Author.find(this.editor_id)
    }
    return null
  }

  get recordAt () {
    if (!this.recordAt_) {
      this.recordAt_ = new Date(this.record_at)
    }
    return this.recordAt_
  }

  get targetAt () {
    if (!this.targetAt_) {
      this.targetAt_ = new Date(this.target_at)
    }
    return this.targetAt_
  }

  get updateAt () {
    if (!this.updateAt_) {
      this.updateAt_ = new Date(this.update_at)
    }
    return this.updateAt_
  }

  get targetAtStr () {
    return moment(this.targetAt).format('YYYY/MM/DD HH:mm')
  }

  get targetAtJp () {
    return moment(this.targetAt).format('YYYY年MM月DD日 HH:mm')
  }

  get updateAtStr () {
    if (this.updateAt) {
      return moment(this.updateAt).format('YYYY/MM/DD HH:mm')
    }
    return ''
  }

  get hashTags () {
    return HashTag.findIn(this.tags)
  }

  get photoReports () {
    return PhotoReport.query().where('record_id', this.id).get()
  }

  get fileReports () {
    return FileReport.query().where('record_id', this.id).get()
  }

  get thanksAuthors () {
    if (this.thanks_authors && this.thanks_authors.length > 0) {
      // TODO: load data when undefined
      return Author.findIn(this.thanks_authors)
    } else {
      return []
    }
  }

  get medicalExamination () {
    return MedicalExamination.query().where('record_id', this.id).first()
  }

  get drugEntry () {
    return DrugEntry.query().where('record_id', this.id).first()
  }

  get videoContent () {
    return MillviVideoContent.query().where('record_id', this.id).first()
  }

  get audioContent () {
    return MillviAudioContent.query().where('record_id', this.id).first()
  }

  get multipostAuthors () {
    // console.log(this.multipost_authors)
    if (this.multipost_authors) {
      // TODO: load data when undefined
      return Author.findIn(this.multipost_authors)
    } else {
      return []
    }
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async storeData (payload) {
    console.log(payload)
    let loadOwnerID = []
    let loadAuthorID = []
    for(var record of payload.records) {
      var photos = await PhotoReport.query().where('record_id', record.id).get()
      for (var photo of photos) {
        await photo.$delete()
      }
      var files = await FileReport.query().where('record_id', record.id).get()
      for (var file of files) {
        await file.$delete()
      }
      var medexes = await MedicalExamination.query().where('record_id', record.id).get()
      for(var medex of medexes) {
        await medex.$delete()
      }
      var drugs = await DrugEntry.query().where('record_id', record.id).get()
      for(var drug of drugs) {
        var dPhotos = await DrugEntryPhoto.query().where('record_id', drug.id).get()
        for (var dp of dPhotos) {
          await dp.$delete()
        }
        await drug.$delete()
      }
      var millviVideos = await MillviVideoContent.query().where('record_id', record.id).get()
      for(var video of millviVideos) {
        await video.$delete()
      }
      var millviAudios = await MillviAudioContent.query().where('record_id', record.id).get()
      for(var audio of millviAudios) {
        await audio.$delete()
      }
      // set load target, timelineOwner, author
      if (!record.timelineOwner && !record.multipost) {
        // 重複登録を避ける
        if (loadOwnerID.indexOf(record.user_id) < 0) {
          loadOwnerID.push(record.user_id)
        }
      }
      if (!record.author) {
        // 重複登録を避ける
        if (loadAuthorID.indexOf(record.author_id) < 0) {
          loadAuthorID.push(record.author_id)
        }
      }
      if (record.multipost_authors && record.multipost_authors.length > 0) {
        for(var authorID of record.multipost_authors) {
          if (!Author.find(authorID)) {
            if (loadAuthorID.indexOf(authorID) < 0) loadAuthorID.push(authorID)
          }
        }
      }
    }
    if (payload.records.length) {
      let promises = [TimelineRecord.insertOrUpdate({ data: payload.records })]
      if (payload.photo_reports.length) promises.push(PhotoReport.insertOrUpdate({ data: payload.photo_reports }))
      if (payload.file_reports.length) promises.push(FileReport.insertOrUpdate({ data: payload.file_reports }))
      if (payload.medical_examinations.length) promises.push(MedicalExamination.insertOrUpdate({ data: payload.medical_examinations }))
      if (payload.drug_entries.length) promises.push(DrugEntry.insertOrUpdate({ data: payload.drug_entries }))
      if (payload.drug_entry_photos.length) promises.push(DrugEntryPhoto.insertOrUpdate({ data: payload.drug_entry_photos }))
      if (payload.video_contents.length) promises.push(MillviVideoContent.insertOrUpdate({ data: payload.video_contents }))
      if (payload.audio_contents.length) promises.push(MillviAudioContent.insertOrUpdate({ data: payload.audio_contents }))
      if (loadAuthorID.length > 0) promises.push(Author.api().fetchByID({ idList: loadAuthorID }))
      for(var ownerID of loadOwnerID) {
        promises.push(TimelineOwner.api().fetch({ timelineOwnerID: ownerID}))
      }
      return Promise.all(promises).then(([timelineRecordEntries]) => {
        TimelineRecord.commit((state) => {
          state.fetchedAt = new Date()
        })
        if (timelineRecordEntries && timelineRecordEntries.timelineRecords) {
          return timelineRecordEntries.timelineRecords
        } else {
          return []
        }
      })
    } else {
      return []
    }
  }

  static async deleteData (payload) {
    console.log(payload)
    var timelineRecords = []
    for(var deleteID of payload.deleted_records) {
      console.log(payload.deleted_records)
      console.log(deleteID)
      timelineRecords.push(TimelineRecord.find(deleteID))
      TimelineRecord.delete(deleteID)

      var photos = await PhotoReport.query().where('record_id', deleteID).get()
      for (var photo of photos) {
        await photo.$delete()
      }
      var files = await FileReport.query().where('record_id', deleteID).get()
      for (var file of files) {
        await file.$delete()
      }
      var medexes = await MedicalExamination.query().where('record_id', deleteID).get()
      for(var medex of medexes) {
        await medex.$delete()
      }
      var drugs = await DrugEntry.query().where('record_id', deleteID).get()
      for(var drug of drugs) {
        var dPhotos = await DrugEntryPhoto.query().where('record_id', drug.id).get()
        for (var dp of dPhotos) {
          await dp.$delete()
        }
        await drug.$delete()
      }
      var millviVideos = await MillviVideoContent.query().where('record_id', deleteID).get()
      for(var video of millviVideos) {
        await video.$delete()
      }
      var millviAudios = await MillviAudioContent.query().where('record_id', deleteID).get()
      for(var audio of millviAudios) {
        await audio.$delete()
      }
    }
    return timelineRecords
  }

  static async formedObject (payload) {
    return {
      records: payload.records,
      photoReports: payload.photo_reports,
      fileReports: payload.file_reports,
      medicalExaminations: payload.medical_examinations,
      drugEntries: payload.drug_entries,
      drugEntryPhotos: payload.drug_entry_photos,
      videoContents: payload.video_contents,
      audioContents: payload.audio_contents,
      deletedRecords: payload.deleted_records
    }
  }

  static apiConfig = {
    actions: {
      fetchByID ({timelineOwnerID, idList}) {
        return this.request({
          method: 'get',
          url: 'record/records',
          headers: {
            'Cache-Controll': 'no-store',
            'Expires': 0,
          },
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, target_records: idList },
          save: false
        })
        .then(res => {
          return TimelineRecord.storeData(res.response.data)
        })
      },
    
      fetchUpdateRecordList ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, type: 'updated' },
          save: false
        })
        .then(res => {
          return Promise.all([
            TimelineRecord.storeData(res.response.data),
            TimelineRecord.deleteData(res.response.data)
          ]).then(() => {
            return TimelineRecord.formedObject(res.response.data)
          })
        })
      },
    
      fetchMultipostRecordIDList ({ offset, limit }) {
        return this.request({
          method: 'get',
          url: 'record/multipost_records',
          withCredentials: true,
          params: { offset: offset, limit: limit },
          save: false
        })
        .then(async res => {
          await TimelineRecord.storeData(res.response.data)
          return TimelineRecord.formedObject(res.response.data)
        })
      },
    
      async postNewRecord ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'record',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return TimelineRecord.formedObject(res.response.data)
        })
      },
    
      async updateRecord ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'record',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return TimelineRecord.formedObject(res.response.data)
        })
      },
    
      async destroyRecord ({timelineOwnerID, targetRecordID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'record',
            withCredentials: true,
            params: { target_user_id: timelineOwnerID, target_record_id: targetRecordID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async restoreRecord ({timelineOwnerID, targetRecordID}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'record/restore',
            withCredentials: true,
            params: { target_user_id: timelineOwnerID, target_record_id: targetRecordID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async addThanks ({timelineOwnerID, targetRecordID}) {
        var formData = new FormData()
        formData.append('target_user_id', timelineOwnerID)
        formData.append('record_id', targetRecordID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'record/add_thanks_tag',
            data: formData,
            save: false
          }
        }).then(res => {
          return res.response.data
        })
      },
    
      async removeThanks ({timelineOwnerID, targetRecordID}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'record/remove_thanks_tag',
            params: { target_user_id: timelineOwnerID, record_id: targetRecordID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
        .catch(error => { throw error })
      },
    
      fetchForRepoter ({timelineOwnerID, idList}) {
        return this.request({
          method: 'get',
          url: 'record/records_for_reporter',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, target_records: idList }
        })
        .then(res => {
          return TimelineRecord.storeData(res.response.data)
        })
      },

      fetchUpdateListForRepoter ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'record/updated_list_for_reporter',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        }).then(res => {
          return Promise.all([
            TimelineRecord.storeData(res.response.data),
            TimelineRecord.deleteData(res.response.data)
          ]).then(() => {
            return TimelineRecord.formedObject(res.response.data)
          })
        })
      },
    
      async createForRepoter ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'record/create_for_reporter',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return TimelineRecord.formedObject(res.response.data)
        })
      },
    
      async updateForRepoter ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'record/update_for_reporter',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroyForRepoter ({timelineOwnerID, targetRecordID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'record/destroy_for_reporter',
            params: { target_user_id: timelineOwnerID, target_record_id: targetRecordID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },

      // only fetch id list
      fetchRecordIDList ({ timelineOwnerID, options }) {
        var params = { target_user_id: timelineOwnerID, type: 'normal' }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { targetRecordID: res.response.data.target_record_id, recordIDList: res.response.data.records, lastWatchAt: res.response.data.last_watch_at }
        })
      },
    
      fetchLastReadIDList ({ timelineOwnerID }) {
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, type: 'last_read' },
          save: false
        })
        .then(res => {
          return { targetRecordID: res.response.data.target_record_id, recordIDList: res.response.data.records, lastWatchAt: res.response.data.last_watch_at }
        })
      },
    
      fetchTargetDateIDList ({ timelineOwnerID, targetDate, options }) {
        var params = { target_user_id: timelineOwnerID, type: 'target_date', target_date: targetDate }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { targetRecordID: res.response.data.target_record_id, recordIDList: res.response.data.records }
        })
      },
    
      fetchTargetRecordIDList ({ timelineOwnerID, targetRecordID }) {
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, type: 'target_record', target_record_id: targetRecordID },
          save: false
        })
        .then(res => {
          return { targetRecordID: res.response.data.target_record_id, recordIDList: res.response.data.records }
        })
      },
    
      fetchLatestRecordIDList ({ timelineOwnerID, targetRecordID, options }) {
        var params = { target_user_id: timelineOwnerID, type: 'latest', target_record_id: targetRecordID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { recordIDList: res.response.data.records }
        })
      },
    
      fetchOldRecordIDList ({ timelineOwnerID, targetRecordID, options }) {
        var params = { target_user_id: timelineOwnerID, type: 'old', target_record_id: targetRecordID }
        if (options) {
          Object.entries(options).forEach(([key, value]) => {
            params['options[' + key + ']'] = value
          })
        }
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: params,
          save: false
        })
        .then(res => {
          return { recordIDList: res.response.data.records }
        })
      },
    
      fetchThreadRecordIDList ({ timelineOwnerID, targetRecordID }) {
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, type: 'thread', target_record_id: targetRecordID },
          save: false
        })
        .then(res => {
          return { targetRecordID: res.response.data.target_record_id, recordIDList: res.response.data.records }
        })
      },
    
      fetchChildrenRecordIDList ({ timelineOwnerID, targetRecordID }) {
        return this.request({
          method: 'get',
          url: 'record/list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, type: 'children', target_record_id: targetRecordID },
          save: false
        })
        .then(res => {
          return { targetRecordID: res.response.data.target_record_id, recordIDList: res.response.data.records }
        })
      },
    
      fetchDeletedRecordIDList ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'record/deleted_list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          return res.response.data
        })
      },
    
      fetchIDListForRepoter ({timelineOwnerID, limit, offset, options}) {
        return this.request({
          method: 'get',
          url: 'record/id_list_for_reporter',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID, limit: limit, offset: offset, option: options },
          save: false
        })
        .then(res => {
          return { recordIDList: res.response.data.records }
        })
      },
    
    }
  }
}
