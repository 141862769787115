<template>
  <div class="horus-photo-modal-wrapper" v-show="showPhotoModal">
    <div class="horus-photo-backdrop" @click="backdropClicked"></div>
    <div class="horus-photo-modal">
      <div class="horus-photo-modal-header">
        <div class="header-title" :class="extendClass">
          <div class="author-column" v-if="author">
            <horus-record-head-info
              :timelineOwner="timelineOwner"
              :author="author"
              :targetAt="targetAt"
              :displayStyle="'responsible-small'"
              v-on:modalOpenEvent="showAuthorInfo" />
          </div>
          <div class="title">
            <img src="/static/images/info_icon.png" v-if="type === 'boadComment'" />
            <img src="/static/images/wish_icon.png" v-if="type === 'wish'" />
            <img src="/static/images/drug_green.png" v-if="type === 'drugEntry'" />
            {{ title }}
          </div>
          <div class="close-btn">
            <img src="/static/images/jump_icon.png" v-if="type === 'album'" class="jump-to-origin" @click="jumpToOrigin"/>
            <img src="/static/images/x_icon.png" @click="closeModal" />
          </div>
          <div class="tag-display" v-if="type === 'album' && hashTags.length > 0">
            <horus-hash-tag-display
              v-for="hashTag in hashTags"
              :key="'photoTag' + hashTag.id"
              :hashTag="hashTag" :sizeType="'small'"/>
          </div>
        </div>
        <div class="narrow-header-author">
          <horus-record-head-info
            :timelineOwner="timelineOwner"
            :author="author"
            :targetAt="targetAt"
            :displayStyle="'responsible-small'"
            v-on:modalOpenEvent="showAuthorInfo" />
        </div>
      </div>
      <div class="stage-photos" :class="extendClass">
        <div class="staged-photo-inner"
          v-for="stagePhoto in currentPhotos"
          :key="'stage'+stagePhoto.photo.id"
          :class="onStage(stagePhoto)">
          <horus-photo-display
            :currentPhoto="stagePhoto"
            :extendClass="extendClass"
            v-on:rotateLeftEvent="currentPhotoRotateLeft"
            v-on:rotateRightEvent="currentPhotoRotateRight" />
        </div>
      </div>
      <div class="horus-photo-modal-footer" v-show="photos.length > 1">
        <div class="thumbnails">
          <div class="photo-thumbnail"
            v-for="(photoObj, index) in currentPhotos"
            :key="photoObj.photo.id"
            :class="onStage(photoObj)"
            @click="toStage(photoObj)"
            :style="{ left: thumbnailOffset * index + 'px', zIndex: photoObj.zindex }">
            <img :src="photoObj.photo.photoSrc"
              class="footer-thumbnail"/>
          </div>
        </div>
        <div class="horus-photo-controlls">
          <img src="/static/images/l_rotate_icon.png" @click="currentPhotoRotateLeft" />
          <img src="/static/images/r_rotate_icon.png" @click="currentPhotoRotateRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
import HorusPhotoDisplay from '@/components/common/HorusPhotoDisplay.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

// const THUMBNAIL_MAX_SIZE = 100
export default {
  name: 'HorusPhotoModal',

  components: {
    HorusRecordHeadInfo,
    HorusPhotoDisplay,
    HorusHashTagDisplay
  },

  props: {
    timelineOwner: Object,
    showPhotoModal: Boolean,
    photos: Array,
    type: String,
    author: Object,
    targetAt: Date,
    title: String,
    firstShow: Object,
    hashTags: Array
  },

  data () {
    return {
      currentPhoto: null,
      windowWidth: 0,
      currentPhotos: []
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },

  watch: {
    photos: { handler: 'initPhotos', immediate: true }
  },

  computed: {
    extendClass () {
      var classtexts = []
      if (this.type === 'album' && this.hashTags.length > 0) {
        classtexts.push('tag-include')
      }
      if (this.photos.length === 1) {
        classtexts.push('single')
      }
      return classtexts.join(' ')
    },

    // NOTE: スタイルと密結合になってる。如何ともし難い
    thumbnailOffset () {
      var modalWidth = this.windowWidth
      var controllWidth = 120
      var thumbnailWidth = 50
      var thumbnailMargin = 5
      if (this.windowWidth > 420) {
        modalWidth = this.windowWidth - 40
      }
      var thumbnailBlockWidth = modalWidth - controllWidth
      if (thumbnailBlockWidth > (thumbnailWidth * this.photos.length + thumbnailMargin * (this.photos.length - 1))) {
        return thumbnailWidth + thumbnailMargin
      } else {
        return (thumbnailBlockWidth - thumbnailWidth) / this.photos.length
      }
    }
  },

  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    },

    initPhotos () {
      this.currentPhotos = this.photos.map((p) => {
        var photoObj = {}
        photoObj.photo = p
        photoObj.zindex = 0
        photoObj.rotate = 0
        photoObj.onStage = false
        return photoObj
      })
      if (this.firstShow) {
        this.toStage(this.currentPhotos.find(p => p.photo === this.firstShow))
      } else {
        this.toStage(this.currentPhotos[0])
      }
      this.updateThumbnailZIndex()
    },

    onStage (photo) {
      if (photo === this.currentPhoto) {
        return 'on-stage'
      }
    },

    toStage (photo) {
      this.currentPhotos.forEach(photoObj => {
        photoObj.onStage = false
      })
      this.currentPhoto = photo
      if (this.currentPhoto) {
        this.currentPhoto.onStage = true
      }
      this.updateThumbnailZIndex()
    },

    updateThumbnailZIndex () {
      var index = this.currentPhotos.indexOf(this.currentPhoto)
      var zindex = this.currentPhotos.length
      for (let step = index; step >= 0; step--) {
        if (this.currentPhotos[step]) {
          this.currentPhotos[step].zindex = zindex
          zindex--
        }
      }
      for (let step = index + 1; step < this.currentPhotos.length; step++) {
        if (this.currentPhotos[step]) {
          this.currentPhotos[step].zindex = zindex
          zindex--
        }
      }
    },

    currentPhotoRotateLeft () {
      this.currentPhoto.rotate -= 90
      if (this.currentPhoto.rotate < 0) {
        this.currentPhoto.rotate += 360
      }
    },

    currentPhotoRotateRight () {
      this.currentPhoto.rotate += 90
      if (this.currentPhoto.rotate > 360) {
        this.currentPhoto.rotate -= 360
      }
    },

    showAuthorInfo (targetElm, callback) {
      this.$emit('modalOpenEvent', targetElm, callback)
    },

    closeModal () {
      this.$emit('photoModalCloseEvent')
    },

    backdropClicked () {
      this.closeModal()
    },

    jumpToOrigin () {
      this.$emit('jumpToOriginEvent', this.currentPhoto.photo)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-photo-modal-wrapper {
  position: absoluete;
  top: 0;
  left: 0;
}
.horus-photo-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.3;
}

.horus-photo-modal {
  position: absolute;
  top: 30px;
  left: 20px;
  width: calc(100vw - 40px);
  height: calc(100vh - 100px);
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 5px;
  @include mediaQuery('tablet') {
    width: calc(100vw - 40px);
    left: 20px;
  }
  @include mediaQuery('phone') {
    width: 100vw;
    height: calc(100vh - 80px);
    top: 0;
    left: 0;
  }
}
.horus-photo-modal-header {
  .header-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px 10px;
    height: 50px;
    &.tag-include {
      height: 70px;
    }
    @include mediaQuery('phone') {
      height: 30px;
      padding-bottom: 0;
      .author-column {
        display: none;
      }
    }
    .title {
      text-align: center;
      img {
        width: 15px;
      }
    }
    .close-btn {
      text-align: right;
      height: 30px;
      img {
        cursor: pointer;
      }
      .jump-to-origin {
        margin-right: 20px;
        vertical-align: top;
      }
    }
    .tag-display {
      width: 100%;
      height: 20px;
      text-align: right;
    }
  }
  .narrow-header-author {
    display: none;
    @include mediaQuery('phone') {
      display: block;
      padding: 0 10px 5px;
    }
  }
}
.stage-photos {
  position: relative;
  height: calc(100% - 60px - 72px);
  overflow: auto;
  &.tag-include {
    height: calc(100% - 60px - 20px - 72px);
  }
  &.single {
    height: calc(100% - 60px);
    &.tag-include {
      height: calc(100% - 60px - 20px);
    }
  }
  @include mediaQuery('phone') {
    height: calc(100% - 60px - 72px);
    &.single {
      height: calc(100% - 60px);
      &.tag-include {
        height: calc(100% - 60px - 40px);
      }
    }
  }
  .staged-photo-inner {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    &.on-stage {
      z-index: 1000;
    }
  }
}
.horus-photo-modal-footer {
  display: flex;
  justify-content: flex-start;
  height: 62px;
  overflow: hidden;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #aaa;
  .thumbnails {
    position: relative;
    width: calc(100% - 120px);
    .photo-thumbnail {
      position: absolute;
      top: 0;
      height: 50px;
      width: 50px;
      border: 1px solid #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.on-stage {
        z-index: 100;
        border: 1px solid #ffdc00;
      }
    }
  }
  .horus-photo-controlls {
    img {
      margin: 5px;
      cursor: pointer;
    }
  }
}
</style>
