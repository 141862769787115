<template>
  <div class="horus-system-manager-invitation-create-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />新規システム管理者案内作成</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'名前'"
          :necessary="true"
          :validationState="nameValidation.state"
          :validationMessage="nameValidation.message"
          :attributeNotify="''">
          <input type="text" v-model="name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="mailValidation.state"
          :validationMessage="mailValidation.message"
          :attributeNotify="''">
          <input type="text" v-model="mail" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">新規作成</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { SystemManager } from '@/models'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

export default {
  name: 'HorusSystemManagerInvitationCreateModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
  },

  props: {
    showModal: Boolean
  },

  data () {
    return {
      name: '',
      mail: '',
      mailValidation: { state: 'disabled', valid: false, message: '' }
    }
  },

  watch: {
    mail: { handler: 'validateMail' }
  },

  computed: {
    nameValidation () {
      if (this.name.length > 0) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '宛先名は入力必須です' }
      }
    },

    allValid () {
      if (this.nameValidation.valid && this.mailValidation.valid) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    validateMail () {
      if (this.mail.length > 6) {
        SystemManager.api().mailValidation({ mail: this.mail }).then(res => {
          if (res.success) {
            if (res.valid) {
              this.mailValidation = { state: 'valid', valid: true, message: '' }
            } else {
              this.mailValidation = { state: 'invalid', valid: false, message: '入力されたメールアドレスは登録済みです。' }
            }
          } else {
            this.mailValidation = { state: 'disabled', valid: true, message: '入力チェックが機能していません' }
          }
        }).catch(err => {
          console.log(err)
          this.mailValidation = { state: 'disabled', valid: true, message: '入力チェックが機能していません' }
        })
      }
    },

    initDatas () {
      this.name = ''
      this.mail = ''
      this.mailValidation = { state: 'disabled', valid: false, message: '' }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', () => { this.initDatas() })
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('system_manager_invitation_token[name]', this.name)
        formData.append('system_manager_invitation_token[mail]', this.mail)
        this.$emit('submitEvent', formData, () => { this.initDatas() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-system-manager-invitation-create-modal {
  .modal-body {
    padding: 10px 20px;
    .toggle-password-visible {
      display: flex;
      margin-left: 36px;
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
