import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import AreaBlockOperator from './AreaBlockOperator'

export default class AreaBlock extends Model {
  static entity = 'areaBlocks'

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(''),
      name_kana: this.string(''),
      short_name: this.string(''),
      short_name_kana: this.string(''),
      address: this.string(''),
      zip_code: this.string(''),
      tel: this.string(''),
      mail: this.string(''),
      deleted: this.attr(null)
    }
  }

  get operators () {
    return AreaBlockOperator.query().where('area_block_id', this.id).get()
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static apiConfig = {
    actions: {
      fetch ({ targetID }) {
        return this.request({
          method: 'get',
          url: 'area_block',
          withCredentials: true,
          params: { id: targetID },
          dataKey: 'area_block'
        })
        .then(res => {
          console.log(res)
          AreaBlock.commit((state) => {
            state.fetchedAt = new Date()
          })
          if (res.entities && res.entities.areaBlocks) {
            return res.entities.areaBlocks[0]
          } else {
            return null
          }
        })
        .catch(error => { error })
      },
    
      fetchList () {
        return this.request({
          method: 'get',
          url: 'area_block/list',
          withCredentials: true,
          dataKey: 'area_blocks'
        })
        .then(res => {
          console.log(res)
          console.log(res.entities)
          AreaBlock.commit((state) => {
            state.fetchedAt = new Date()
          })
          if (res.entities && res.entities.areaBlocks) {
            return res.entities.areaBlocks
          } else {
            return []
          }
        })
        .catch(error => { error })
      },
    
      async create ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'area_block',
            data: formData,
            save: false
          }
        })
      },
    
      async update ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block',
            data: formData,
            save: false
          }
        })
      },
    
      async destroy ({ targetID }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'area_block',
            headers: {
              'X-HTTP-Method-Override': 'DELETE',
              'Content-Type': 'multipart/form-data',
            },
            params: { id: targetID },
            save: false
          }
        })
      }

    }
  }
}
