<template>
  <div class="horus-family-detail-column labeled_content_block">
    <div class="labeled_content_single_block">
      <label>氏名</label>
      <div class="content">
        <img :src="family.author.portrateSrc">{{ family.author.name }}
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>生年月日(年齢):性別</label>
      <div class="content">{{ familyShortInfo }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>私の情報</label>
      <div class="content">
        <div v-if="isWatching">
          表示可
          <button class="change-family-state-btn" @click="removeWatchingFamily">停止</button>
        </div>
        <div v-else>表示不可</div>
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>相手の情報</label>
      <div class="content">
        <div v-if="isVisible">
          表示可
          <button class="change-family-state-btn" @click="removeVisibleFamily">停止</button>
        </div>
        <div v-else>表示不可</div>
      </div>
    </div>
    <div class="content-controll-block">
      <button class="remove-family-btn" @click="removeFamily">家族から削除</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusFamilyDetailColumn',

  props: {
    family: Object
  },

  computed: {
    familyShortInfo () {
      return this.family.author.birthDateJp + '(' + this.family.author.age + ') : ' + this.family.author.sexTypeJp
    },

    isWatching () {
      if (this.family.relation === 1 || this.family.relation === 2) {
        return true
      } else {
        return false
      }
    },

    isVisible () {
      if (this.family.relation === 1 || this.family.relation === 3) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    removeWatchingFamily () {
      this.$emit('removeWatchingEvent', this.family)
    },

    removeVisibleFamily () {
      this.$emit('removeVisibleEvent', this.family)
    },

    removeFamily () {
      this.$emit('removeFamilyEvent', this.family)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';

.horus-family-detail-column {
  .change-family-state-btn {
    background-color: #999;
    color: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
  }
}
</style>
