<template>
  <div class="horus-author-selection-modal-wrapper" v-show="showAuthorSelectionModal">
    <div class="bn-author-selection-backdrop" @click="backdropClicked"></div>
    <div class="horus-author-selection-modal">
      <div class="horus-author-selection-header" :class="{member: signinMember}">
        <img src="/static/images/notify_red.png" v-if="!signinMember" />
        <img src="/static/images/notify_green.png" v-if="signinMember" />
        通知先選択
      </div>
      <div class="horus-author-selection-control">
        <div class="left-buttons">
          <button class="horus-author-selection-all" type="button" @click="allAuthorSelect">全員に通知</button>
          <button class="horus-author-selection-clear" type="button" @click="clearSelectedAuthor">クリア</button>
        </div>
        <div class="right-buttons">
          <button class="horus-author-selection-commit" type="button" @click="commitSelectedAuthor">OK</button>
        </div>
      </div>
      <div class="selection-author-groups">
        <div class="selection-group-head" @click="toggleAllFamily">
          <horus-custom-check-box :type="'small'"
            :active="allFamilySelected.value"
            :enabled="anySelectedAuthor" />
          <img src="/static/images/family.png" />
          <span class="selection-group-name">家族</span>
        </div>
        <div class="selection-group">
          <div class="selection-author-block"
            v-for="family in families"
            :key="'authorselectmodal' + family.id"
            @click="toggleAuthor(family)">
            <horus-custom-check-box :type="'small'"
              :active="isSelected(family)"
              :enabled="isNotLocked(family)" />
            <img :src="family.portrateSrc" />
            <span class="author-name">{{ family.name }}</span>
          </div>
        </div>
        <div class="selection-organizations" v-for="organization in organizations" :key="'authorselectorg' + organization.id">
          <div class="selection-group-head" @click="toggleAllMember(organization)">
            <horus-custom-check-box :type="'small'"
              :active="allMemberSelect(organization)"
              :enabled="anySelectedAuthor" />
            <img src="/static/images/org_member.png" />
            <span class="selection-group-name">{{ organization.shortName }}</span>
          </div>
          <div class="selection-group">
            <div class="selection-author-block"
              v-for="member in organizationVisibleMembers(organization)"
              :key="'authorselectmodal' + member.id"
              @click="toggleAuthor(member)">
              <horus-custom-check-box :type="'small'"
                :active="isSelected(member)"
                :enabled="isNotLocked(member)" />
              <img :src="member.portrateSrc" />
              <span class="author-name">{{ member.name }}</span>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusOrganizationMemberVisibility from '@/mixin/HorusOrganizationMemberVisibility.js'

export default {
  mixins: [HorusOrganizationMemberVisibility],

  name: 'HorusAuthorSelectForPostModal',

  components: {
    HorusCustomCheckBox
  },

  props: {
    showAuthorSelectionModal: Boolean,
    families: Array,
    organizations: Array,
    selectedAuthors: Array,
    allFamilySelected: Object,
    selectedOrganizations: Array,
    signinMember: Object,
    selectLockAuthor: Array,
    deselectLockAuthor: Array,
    organizationStaffVisibilities: Array
  },

  computed: {
    anySelectedAuthor () { return (this.selectedAuthors && this.selectedAuthors.length > 0) }
  },

  methods: {
    toggleAllFamily () {
      this.$emit('toggleAllFamilyEvent')
    },

    allMemberSelect (organization) {
      return this.selectedOrganizations.some(orgId => orgId === organization.id)
    },

    toggleAllMember (organization) {
      this.$emit('toggleAllMemberEvent', organization)
    },

    isSelected (author) {
      return this.selectedAuthors.some(selected => { return selected.id === author.id })
    },

    isNotLocked (author) {
      if (this.selectLockAuthor) {
        if (this.selectLockAuthor.some(locked => { return locked.id === author.id }) || this.deselectLockAuthor.some(locked => { return locked.id === author.id })) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },

    toggleAuthor (author) {
      this.$emit('toggleSelectAuthorEvent', author)
    },

    allAuthorSelect () {
      this.$emit('allAuthorSelectEvent')
    },

    commitSelectedAuthor () {
      this.$emit('authorSelectionModalCloseEvent')
    },

    clearSelectedAuthor () {
      this.$emit('clearSelectedAuthorEvent')
      this.$emit('authorSelectionModalCloseEvent')
    },

    backdropClicked () {
      this.$emit('authorSelectionModalCloseEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.bn-author-selection-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.2;
}
.horus-author-selection-modal {
  position: absolute;
  max-width: 924px;
  max-height: calc(100vh - 250px);
  margin: 0;
  padding: 0;
  top: 30px;
  left: calc((100vw - 1000px) / 2 + 30px);
  overflow: auto;
  background-color: #fff;
  border: 1px solid #aaa;
  @include mediaQuery('tablet') {
    width: calc(100vw - 40px);
    left: 20px;
  }
  @include mediaQuery('phone') {
    width: calc(100vw - 10px);
    max-height: calc(100vh - 100px);
    left: 5px;
  }
}
.horus-author-selection-header {
  padding: 10px 20px;
  background-color: #fff9f5;
  color: #692220;
  font-weight: bold;
  font-size: 12px;
  img {
    margin: 0 5px 3px 0;
    width: 15px;
    vertical-align: middle;
  }
  &.member {
    background-color: #f8fcf9;
    color: #2b4733;
  }
}
.horus-author-selection-control {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  button {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    width: 100px;
    margin: 0 0 0 10px;
    padding: 5px 20px;
    width: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 10px;
    border-radius: 2px;
  }
  .horus-author-selection-all {
    width: auto;
    padding: 5px;
    border: 1px solid #999;
    background-color: #999;
    color: #fff;
  }
  .horus-author-selection-clear {
    border: 1px solid #aaa;
    background-color: #fff;
    color: #333;
    @include mediaQuery('phone') {
      width: 70px;
      padding: 5px 10px;
    }
  }
  .horus-author-selection-commit {
    border: 1px solid #666;
    background-color: #666;
    color: #fff;
    @include mediaQuery('phone') {
      width: 70px;
      padding: 5px 10px;
    }
  }
}
.horus-author-selection-clear {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #333;
}
.selection-author-groups {
  max-height: calc(100vh - 250px - 78px);
  overflow: scroll;
  @include mediaQuery('phone') {
    max-height: calc(100vh - 100px - 78px);
  }
}
.selection-group-head {
  margin: 10px 0 0 0;
  padding: 5px 5px 5px 25px;
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  cursor: pointer;
  img {
    width: 10px;
    height: 10px;
  }
}
.selection-group {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}
.selection-author-block {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  img {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
}
</style>
