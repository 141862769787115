<template>
  <div class="subBar">
    <div class="subBarButton">
      <a @click="toggleLeft">
        <img src="/static/images/boad_comment.png" />
      </a>
    </div>
    <div class="subBarButton">
      <a @click="showNormalTimeline">
        <img src="/static/images/timeline.png" />
      </a>
    </div>
    <div class="subBarButton">
      <a @click="showAlbumTimeline">
        <img src="/static/images/album_icon.png" />
      </a>
    </div>
    <div class="subBarButton" @click="toggleAddressColumn">
      <a>
        <img src="/static/images/addressbook_icon.png" />
      </a>
      <span class="unread-badge" v-if="ownTimeline && unreadCount > 0">{{ unreadCount }}</span>
    </div>
    <div class="subBarButton">
      <a @click="toggleTaskColumn">
        <img src="/static/images/task_pad_icon.png" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NbSubBar',

  props: {
    pageName: String,
    unreadCount: Number,
    ownTimeline: Boolean
  },

  computed: {
    meetingAvailable () {
      return process.env.VUE_APP_MEETING_AVAILABLE
    }
  },

  methods: {
    toggleLeft () {
      this.$emit('toggleLeftEvent')
    },

    toggleRight () {
      this.$emit('toggleRightEvent')
    },

    toggleAddressColumn () {
      this.$emit('toggleAddressColumnEvent')
    },

    toggleTaskColumn () {
      this.$emit('toggleTaskColumnEvent')
    },

    showNormalTimeline () {
      this.$emit('showNormalTimelineEvent')
    },

    showAlbumTimeline () {
      this.$emit('showAlbumTimelineEvent')
    },

    reloadContent () {
      this.$emit('reloadContentEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.subBar {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 5px 10px;
  background-color: #e7e7eb;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #aaa;
  height: 29px;
  .subBarButton {
    position: relative;
    vertical-align: middle;
    img {
      height: 25px;
    }
    .unread-badge {
      position: absolute;
      top: 0;
      left: 10px;
      display: inline-block;
      min-width: 5px;
      padding: 3px 7px;
      background-color: #b94047;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 10px;
    }
  }
}
</style>
