<template>
  <div class="horus-info-content-in-modal">
    <div class="info-content-title" @click="toggleBody">
      <div class="title-column drug-handle">
        <img src="/static/images/sortable_icon.png" v-if="showDrugHandle" />
        {{ infoContent.title }}
      </div>
      <div class="controll-column">
        <button class="hide-button" @click.stop="toInvisibleContent" v-if="infoContent.position > 0">隠す</button>
        <button class="hide-button" @click.stop="toVisibleContent" v-if="infoContent.position === 0">見せる</button>
        <img src="/static/images/pencil_icon.png" @click.stop="editContent" />
        <img src="/static/images/bucket_icon.png" @click.stop="deleteContent" />
      </div>
    </div>
    <div class="info-content-body" v-show="contentOpen">
      <div class="text-content" v-html="returnLinedContent"></div>
      <div class="photo-content" v-if="infoContent.photos.length > 0">
        <horus-inline-photo
          v-for="photo in infoContent.photos"
          :key="photo.id"
          :photoObject="photo"
          :photoMaxSize="70"
          :sizeType="'small'"
          v-on:timeliePhotoClickEvent="showPhoto(photo)" />
      </div>
      <div class="file-content" v-if="infoContent.files.length > 0">
        <horus-inline-file
          v-for="file in infoContent.files"
          :key="file.id"
          :fileObject="file"
          :sizeType="'small'"
          v-on:timelineFileClickEvent="openFile" />
      </div>
      <div class="info-content-tags">
        <horus-hash-tag-display v-for="tag in infoContent.hashTags" :hashTag="tag" :key="tag.id" :sizeType="'small'" />
      </div>
      <div class="info-content-footer">
        <div class="create-info">作成：{{ infoContent.createdAtJp }} {{ infoContent.author.name }} {{ infoContent.author.nameAdditional }}</div>
        <div class="update-info" v-if="infoContent.editor">更新：{{ infoContent.updatedAtJp }} {{ editorName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'

export default {
  name: 'HorusInforContentInModal',

  components: {
    HorusHashTagDisplay,
    HorusInlinePhoto,
    HorusInlineFile
  },

  props: {
    infoContent: Object,
    contentOpen: Boolean,
    type: String,
    showDrugHandle: Boolean
  },

  computed: {
    returnLinedContent () { return this.infoContent.content.replace(/\n/gi, '<br />') },

    createdAtStr () {
      return moment(this.infoContent.createdAt).format('MM月DD日 HH:mm')
    },

    updatedAtStr () {
      if (this.infoContent.updatedAt) {
        return moment(this.infoContent.updatedAt).format('MM月DD日 HH:mm')
      }
      return ''
    },

    editorName () {
      if (this.infoContent.editor) {
        return this.infoContent.editor.name + ' ' + this.infoContent.editor.nameAdditional
      }
      return ''
    }
  },

  methods: {
    returnLinedText (text) {
      return text.replace('\n', '<br />')
    },

    showPhoto (photo) {
      var photoIndex = this.infoContent.photos.indexOf(photo)
      this.$emit('showInfoContentPhotoEvent', this.infoContent, this.type, photoIndex)
    },

    openFile () {
      console.log('info content, openFile CALL, need to imprements')
    },

    toggleBody () {
      this.$emit('toggleBodyEvent', this.infoContent)
    },

    toInvisibleContent () {
      this.$emit('toInvisibleEvent', this.infoContent)
    },

    toVisibleContent () {
      this.$emit('toVisibleEvent', this.infoContent)
    },

    editContent () {
      this.$emit('editContentEvent', this.infoContent)
    },

    deleteContent () {
      this.$emit('removeContentEvent', this.infoContent)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-info-content-in-modal {
  margin-bottom: 5px;
  .info-content-title {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    border: 1px solid #aaa;
    cursor: pointer;
    .title-column {
      cursor: grab;
      img {
        width: 10px;
        margin-right: 10px;
      }
    }
    .controll-column {
      display: flex;
      button {
        min-width: 48px;
        height: 24px;
        padding: 3px;
        border-radius: 3px;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }
  .info-content-body {
    border: 1px solid #aaa;
    .text-content {
      padding: 5px 10px;
    }
    .photo-content {
      padding: 5px 10px;
    }
    .file-content {
      padding: 5px 10px;
    }
    .info-content-tags {
      padding: 5px 10px;
    }
    .info-content-footer {
      padding: 5px 10px;
      border-top: 1px solid #ddd;
      font-size: 0.8em;
    }
  }
}
</style>
