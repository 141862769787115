<template>
  <div class="horus-receive-deputizing-request-to-user-column labeled_content_block">
    <div class="labeled_content_single_block" v-if="receiveRequest.organization">
      <label>施設番号</label>
      <div class="content">{{ receiveRequest.organization.numberForHuman }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>名称</label>
      <div class="content">{{ requestSenderName }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ requestSenderTel }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ receiveRequest.status }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>メッセージ</label>
      <div class="content" v-html="messageContent"></div>
    </div>
    <div class="content-controll-block">
      <button type="button" @click="confirmRequest">承認もしくは拒否の処理</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusReceiveDeputizingRequestToUserColumn',

  props: {
    receiveRequest: Object
  },

  computed: {
    requestSenderName () {
      if (this.receiveRequest.organization) {
        return this.receiveRequest.organization.name + '(' + this.receiveRequest.organization.shortName + ')'
      } else if (this.receiveRequest.agentUser) {
        return this.receiveRequest.agentUser.name + '(' + this.receiveRequest.agentUser.nameKana + ')'
      }
      return '---'
    },

    requestSenderTel () {
      if (this.receiveRequest.organization) {
        return this.receiveRequest.organization.tel
      } else if (this.receiveRequest.agentUser) {
        if (this.receiveRequest.agentUser.tel) {
          return this.receiveRequest.agentUser.tel
        } else if (this.receiveRequest.agentUser.cellphone) {
          return this.receiveRequest.agentUser.cellphone
        }
      }
      return '---'
    },

    messageContent () {
      if (this.receiveRequest.message_content && this.receiveRequest.message_content.length > 0) {
        return this.receiveRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return '設定代行案内のメッセージはありません。'
      }
    }
  },

  methods: {
    confirmRequest () {
      this.$emit('confirmRequestEvent', this.receiveRequest)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
