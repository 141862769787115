<template>
  <div class="horus-profile-column" v-if="timelineOwner">
    <div class="red_titled_column">
      <h4>利用者情報</h4>
      <div class="list-data">
        <div class="list-data-line">
          <div class="labels"><label>NBID(ナラティブブックID)</label></div>
          <div class="content">{{ nbid }}</div>
        </div>
        <div class="list-data-line">
          <div class="labels"><label>登録メールアドレス</label></div>
          <div class="content">{{ registerMail }}<img src="/static/images/pencil_icon.png" class="mail-change-btn" @click="changeMail"  v-if="editable" /></div>
        </div>
        <div class="list-data-line" v-if="editable">
          <div class="labels"><label>パスワード</label></div>
          <div class="content"><button type="button" class="change-password-btn" @click="changePassword">パスワード変更</button></div>
        </div>
        <!-- deputizeの情報をここに入れる -->
        <div class="list-data-line" v-if="deputizing && !timelineOwner.selfSignin">
          <div class="labels"><label>本人ログイン不能</label></div>
          <div class="content">
            <span class="activate-invitation" v-if="authenticationAcitvateInvitation">
              送信先: {{ authenticationAcitvateInvitation.mail }} 状態: {{ authenticationAcitvateInvitation.status }}
              <button class="send-mail-btn" @click="sendMailActivateInvitation">再送信</button>
              <button class="remove-invitation-btn" @click="destroyActivateInvitation">取りやめる</button>
            </span>
            <button type="button" class="create-auth-info-btn" @click="newActivateInvitation" v-if="!authenticationAcitvateInvitation">本人ログインを可能にする</button>
          </div>
        </div>
      </div>
    </div>
    <div class="red_titled_column">
      <h4>
        <span>基本情報</span>
        <img src="/static/images/pencil_icon.png" class="edit-user-basic-btn" @click="editUserBasic" v-if="editable || deputizing" />
      </h4>
      <div class="list-data">
        <div class="list-data-line">
          <div class="labels">氏名</div>
          <div class="content">{{ fullName }}</div>
        </div>
        <div class="list-data-line">
          <div class="labels">ヨミガナ</div>
          <div class="content">{{ fullNameKana }}</div>
        </div>
        <div class="list-data-line">
          <div class="labels">生年月日</div>
          <div class="content">{{ birthDateJp }}</div>
        </div>
        <div class="list-data-line">
          <div class="labels">性別</div>
          <div class="content">{{ sexTypeJp }}</div>
        </div>
        <div class="list-data-line">
          <div class="labels">血液型</div>
          <div class="content">{{ bloodType }}</div>
        </div>
        <div class="list-data-line">
          <div class="labels">ポートレート</div>
          <div class="content">
            <img :src="portrateSrc">
          </div>
        </div>
      </div>
    </div>
    <div class="red_titled_column">
      <h4>
        <span>住所等の情報</span>
        <img src="/static/images/pencil_icon.png" class="edit-user-address-btn" @click="editUserAddress" v-if="editable || deputizing" />
      </h4>
      <div class="list-data">
        <div class="list-data-line"><div class="labels">郵便番号</div><div class="content">{{ zipCode }}</div></div>
        <div class="list-data-line"><div class="labels">住所</div><div class="content">{{ address }}</div></div>
        <div class="list-data-line"><div class="labels">電話番号</div><div class="content">{{ telNumber }}</div></div>
        <div class="list-data-line"><div class="labels">携帯電話</div><div class="content">{{ cellphoneNumber }}</div></div>
        <div class="list-data-line"><div class="labels">FAX</div><div class="content">{{ faxNumber }}</div></div>
      </div>
    </div>
    <div class="red_titled_column" v-if="(ownProfile && !signinMember) || deputizing">
      <h4>
        <span>アーカイブ</span>
      </h4>
      <div class="archive-list">
        <div class="archive-line">
          <div class="title name">名称</div>
          <div class="title request-at">リクエスト日</div>
          <div class="title status">ステータス</div>
          <div class="title controll">ダウンロード</div>
        </div>
        <div class="archive-line" v-for="request in archiveRequests" :key="'archReqList' + request.id">
          <div class="content name">{{ request.name }}</div>
          <div class="content request-at">{{ archiveRequestAt(request) }}</div>
          <div class="content status">{{ request.status }}</div>
          <div class="content controll">
            <div class="archive-controll" v-if="archivePublised(request)">
              <a :href="macDownloadSrc(request)"><button class="download-btn mac">Mac用</button></a>
              <a :href="winDownloadSrc(request)"><button class="download-btn win">Win用</button></a>
            </div>
          </div>
        </div>
      </div>
      <div class="list-data">
        <div class="list-data-line" v-if="archiveLimited">
          <div class="labels">アーカイブ作成の依頼</div>
          <div class="content"><span class="archive-limited">すでに送信済の依頼が存在するため、新規依頼は制限中です。処理が完了するまでお待ちください。</span></div>
        </div>
        <div class="list-data-line" v-if="!archiveLimited">
          <div class="labels">アーカイブ作成の依頼</div>
          <div class="content">
            <select class="archive-year-select" v-model="archiveYear">
              <option v-for="year in archiveYears" :key="'archiveYear' + year" :value="year">{{ year }}年</option>
            </select>
            <select class="archive-month-select" v-model="archiveMonth">
              <option v-for="month in archiveMonthes" :key="'archiveMonth' + month" :value="month">{{ month }}月</option>
            </select>〜現在日時まで<button class="archive-request-btn" @click="archiveRequest">アーカイブ作成依頼</button>
          </div>
        </div>
      </div>
    </div>
    <div class="red_titled_column" v-if="ownProfile">
      <h4>
        <span>登録QRコード</span>
        <button class="add-qr-code-btn" @click="registQR">新規QRコード登録</button>
        <button class="add-qr-code-btn" @click="manualRegistQR">手入力でのQRコード登録</button>
      </h4>
      <div class="static-identify-code-list" v-for="qrCode in registedQrCodes" :key="'staicID' + qrCode.id">
        <div class="static-id-category"><span class="title">種別:</span>{{ qrCode.category_name }}</div>
        <div class="static-id-code"><span class="title">番号:</span>{{ qrCode.code }}</div>
        <div class="static-id-timestamp"><span class="title">作成日:</span>{{ qrCodeReginstedAt(qrCode) }}</div>
        <div class="static-id-controll"><button class="static-id-delete-btn" @click="deleteQrCode(qrCode)">利用停止</button></div>
      </div>
      <div v-if="registedQrCodes.length < 1">登録されたアプリQRコードはありません。</div>
    </div>
    <div class="red_titled_column" v-if="ownProfile">
      <h4><span>アプリログイン設定</span></h4>
      <div class="app-signin-token-list" v-for="appToken in appSigninTokens" :key="'appsignintoken' + appToken.id">
        <div class="app-token-device"><span class="title">デバイス:</span>{{ appToken.app_type }}</div>
        <div class="app-token-timestamp"><span class="title">作成日:</span>{{ tokenCreatedAt(appToken) }}</div>
        <div class="app-token-controll"><button class="app-token-delete-btn" @click="deleteToken(appToken)">利用停止</button></div>
      </div>
      <div v-if="appSigninTokens.length < 1">登録されたアプリ専用ログイン設定はありません。</div>
    </div>
    <div class="red_titled_column" v-if="ownProfile">
      <h4><span>ナラティブフォン設定</span></h4>
      <div class="narrative-phone-configure" v-if="narrativePhoneAccount">
        <div class=""><span class="title">公開名:</span>{{ narrativePhoneAccount.name }}</div>
        <div class=""><span class="title">ナラティブフォンID:</span>{{ narrativePhoneAccount.narrative_phone_id }}</div>
        <div class="app-token-timestamp"><span class="title">公開設定:</span>{{ narrativePhoneAccount.displaySettingJp }}</div>
        <div class="app-token-controll"><button class="narrative-phone-edit-btn" @click="editNarrativePhoneConfig">公開設定編集</button></div>
      </div>
      <div v-if="!narrativePhoneAccount">ナラティブフォンとは連携していません。</div>
    </div>
    <div class="red_titled_column" v-if="ownProfile">
      <h4><span>外部連携設定</span></h4>
      <div class="">
        <div class="zoom-account-column" v-if="meetingAvailable">
          <span class="title">Zoom : </span>{{ zoomAuthInfo }}
          <button v-if="zoomUser" class="revoke-zoom-btn" @click="revokeZoomUser">Zoomとの連携を解除する</button>
          <button v-if="zoomUser" class="reconnect-zoom-btn" @click="reconnectZoomUser">Zoomと再接続する</button>
          <button v-if="!zoomUser" class="add-zoom-btn" @click="addZoomUser">Zoomと連携する</button>
        </div>
      </div>
      <div class="">
        <div class="omron-connect-column" v-if="omronConnectAvailable">
          <span class="title">OMRON CONNECT : </span>{{ omronConnectAccountInfo }}
          <button v-if="omronConnectAccountAvailable" class="revoke-omron-btn" @click="revokeOmronConnect">OMRON CONNECTとの連携を解除する</button>
          <button v-if="omronConnectAccountAvailable" class="reconnect-omron-btn" @click="reconnectOmronConnect">OMRON CONNECTと再接続する</button>
          <button v-if="!omronConnectAccountAvailable" class="add-omron-btn" @click="addOmronConnectAccount">OMRON CONNECTと連携する</button>
        </div>
      </div>
    </div>
    <div class="red_titled_column" v-if="ownProfile">
      <h4>
        <span>その他の情報</span>
      </h4>
      <div class="list-data">
        <div class="list-data-line"><div class="labels">アカウントの削除(退会)<button class="quit-btn" @click="quitNarrativeBook">退会</button></div></div>
      </div>
    </div>
    <div class="author-info" v-if="timelineOwner && timelineOwner.author">
      <strong>更新者:</strong>
      <img alt="9fe53172bc426ac2" class="portlate-img" height="30" :src="portrateSrc" width="30">
      {{ timelineOwner.author.name }} ({{ updatedAtStr }}更新)
    </div>
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusManageQrReaderBtn from '@/mixin/HorusManageQrReaderBtn.js'

import * as USER_BASIC from '@/assets/constants/userBasicData.js'
import * as ADDRESS from '@/assets/constants/addressData.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusManageQrReaderBtn, HorusConfirmDialogControll],

  name: 'HorusProfileColun',

  components: {
    HorusConfirmDialog
  },

  props: {
    loggedInUser: Object,
    signinMember: Object,
    timelineOwner: Object,
    deputizing: Boolean,
    userBasicSource: Object,
    userAddressSource: Object,
    archiveRequests: Array,
    archiveLimited: Boolean,
    authenticationAcitvateInvitation: Object,
    appSigninTokens: Array,
    registedQrCodes: Array,
    zoomUser: Object,
    omronConnectAccountAvailable: Boolean,
    narrativePhoneAccount: Object
  },

  data () {
    return {
      archiveYears: [],
      archiveMonthes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      archiveYear: 0,
      archiveMonth: 1
    }
  },

  computed: {
    nbid () {
      if (this.timelineOwner) {
        return this.timelineOwner.nbid
      } else {
        return ''
      }
    },

    registerMail () {
      if (this.timelineOwner) {
        return this.timelineOwner.mail
      } else {
        return ''
      }
    },

    portrateSrc () {
      if (this.timelineOwner) {
        return this.timelineOwner.portrateSrc
      } else {
        return ''
      }
    },

    ownProfile () {
      if ((this.loggedInUser && this.loggedInUser === this.timelineOwner) || (this.signinMember && this.timelineOwner && this.signinMember.user_id === this.timelineOwner.id)) {
        return true
      } else {
        return false
      }
    },

    editable () {
      if ((this.loggedInUser && this.loggedInUser === this.timelineOwner) || (this.signinMember && this.timelineOwner && this.signinMember.user_id === this.timelineOwner.id)) {
        return true
      } else {
        return false
      }
    },

    fullName () {
      if (this.userBasicSource) {
        return this.userBasicSource.family_name + ' ' + this.userBasicSource.given_name
      } else {
        return ''
      }
    },

    fullNameKana () {
      if (this.userBasicSource) {
        return this.userBasicSource.family_name_kana + ' ' + this.userBasicSource.given_name_kana
      } else {
        return ''
      }
    },

    birthDateJp () {
      if (this.userBasicSource) {
        var birthDate = new Date(this.userBasicSource.birth_date)
        if (birthDate.toString() === 'Invalid Date') {
          return ''
        } else {
          return moment(birthDate).format('YYYY年MM月DD日')
        }
      } else {
        return ''
      }
    },

    sexTypeJp () {
      if (this.userBasicSource) {
        return USER_BASIC.SEX_TYPES[this.userBasicSource.sex_type]
      } else {
        return ''
      }
    },

    bloodType () {
      if (this.userBasicSource) {
        return USER_BASIC.BLOOD_TYPE_ABO[this.userBasicSource.blood_type_abo] + '(' + USER_BASIC.BLOOD_TYPE_RH[this.userBasicSource.blood_type_rh] + ')'
      } else {
        return ''
      }
    },

    zipCode () {
      if (this.userAddressSource && this.userAddressSource.zip_code) {
        return this.userAddressSource.zip_code
      } else {
        return '未設定'
      }
    },

    address () {
      if (this.userAddressSource) {
        var prefecture = ADDRESS.PREFECTURE_CODE_JP.find(pref => pref[0] === this.userAddressSource.prefecture)
        return prefecture[1] + this.userAddressSource.city + this.userAddressSource.address
      } else {
        return ''
      }
    },

    telNumber () {
      if (this.userAddressSource && this.userAddressSource.tel) {
        return this.userAddressSource.tel
      } else {
        return '未設定'
      }
    },

    cellphoneNumber () {
      if (this.userAddressSource && this.userAddressSource.cellphone) {
        return this.userAddressSource.cellphone
      } else {
        return '未設定'
      }
    },

    faxNumber () {
      if (this.userAddressSource && this.userAddressSource.fax) {
        return this.userAddressSource.fax
      } else {
        return '未設定'
      }
    },

    updatedAtStr () {
      if (this.timelineOwner && this.timelineOwner.updatedAt) {
        return moment(this.timelineOwner.updatedAt).format('YYYY/MM/DD HH:mm:SS')
      } else {
        return ''
      }
    },

    meetingAvailable () {
      return process.env.VUE_APP_MEETING_AVAILABLE
    },

    zoomAuthInfo () {
      if (this.zoomUser) {
        return this.zoomUser.first_name + this.zoomUser.last_name + ' [' + this.zoomUser.email + ']'
      } else {
        return 'Zoomの連携情報はありません'
      }
    },

    omronConnectAvailable () {
      if (!this.signinMember) {
        return process.env.VUE_APP_OMRON_CONNECT_AVAILABLE
      }
      return false
    },

    omronConnectAccountInfo () {
      if (this.omronConnectAccountAvailable) {
        return 'OMRON CONNECTのアカウントは連携されています。'
      } else {
        if (process.env.VUE_APP_OMRON_CONNECT_AVAILABLE) {
          return 'OMRON CONNECTのアカウントは連携されていません。'
        } else {
          return 'OMRON CONNECTのアカウント連携は利用できません。'
        }
      }
    }
  },

  created () {
    this.initArchiveRequest()
  },

  methods: {
    initArchiveRequest () {
      var now = new Date()
      var year = now.getFullYear()
      this.archiveYear = year
      this.archiveMonth = now.getMonth() + 1
      for (var i = 5; i >= 0; i--) {
        this.archiveYears.push(year - i)
      }
    },

    newActivateInvitation () {
      this.$emit('newActivateEvent')
    },

    sendMailActivateInvitation () {
      this.$emit('sendMailActivateEvent')
    },

    destroyActivateInvitation () {
      this.$emit('destroyActivateEvent')
    },

    archiveRequestAt (request) {
      if (request.requested_at) {
        return moment(request.requested_at).format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    },

    archivePublised (request) {
      if (request.published_at) {
        return true
      } else {
        return false
      }
    },

    macDownloadSrc (request) {
      if (request.published_at) {
        return process.env.BASE_URL + '/archive_data/mac_download?id=' + request.id
      }
    },

    winDownloadSrc (request) {
      if (request.published_at) {
        return process.env.BASE_URL + '/archive_data/win_download?id=' + request.id
      }
    },

    archiveRequest () {
      this.$emit('archiveRequestEvent', this.archiveYear, this.archiveMonth)
    },

    changeMail () {
      this.$emit('changeMailEvent')
    },

    changePassword () {
      this.$emit('changePasswordEvent')
    },

    editUserBasic () {
      this.$emit('editUserBasicEvent')
    },

    editUserAddress () {
      this.$emit('editUserAddressEvent')
    },

    qrCodeReginstedAt (qrCode) {
      if (qrCode.created_at) {
        var staticIDCretedAt = new Date(qrCode.created_at)
        if (staticIDCretedAt) {
          return moment(staticIDCretedAt).format('YYYY/MM/DD HH:mm')
        }
      }
    },

    registQR () {
      this.$emit('registQrEvent')
    },

    manualRegistQR () {
      this.$emit('manualRegistQrEvent')
    },

    deleteQrCode (qrCode) {
      this.$emit('deleteQrCodeEvent', qrCode)
    },

    tokenCreatedAt (token) {
      if (token.created_at) {
        return moment(token.created_at).format('YYYY/MM/DD HH:mm')
      }
    },

    deleteToken (token) {
      this.$emit('removeAppSigninTokenEvent', token)
    },

    quitNarrativeBook () {
      this.$emit('quitNarrativeBookEvent')
    },

    addZoomUser () {
      if (this.meetingAvailable) {
        this.$emit('addZoomUserEvent')
      }
    },

    reconnectZoomUser () {
      if (this.meetingAvailable) {
        this.$emit('reconnectZoomUserEvent')
      }
    },

    revokeZoomUser () {
      if (this.meetingAvailable) {
        window.open('https://marketplace.zoom.us/user/installed', '_blank')
        // https://marketplace.zoom.us/user/installed
        // this.$emit('revokeZoomAuthEvent')
      }
    },

    addOmronConnectAccount () {
      // show dialog and redirect to omron connect signin server
      this.showOmronConnectSigninNotify()
    },

    reconnectOmronConnect () {
      // same as addOmronConnectAccount
      this.showOmronConnectSigninNotify()
    },

    showOmronConnectSigninNotify () {
      console.log('show omron notify call,')
      if (process.env.VUE_APP_OMRON_CONNECT_AVAILABLE) {
        console.log('show omron notify: VUE_APP_OMRON_CONNECT_AVAILABLE...')
        this.showConfirmDialog(
          'OMRON CONNECTアカウントの接続',
          'OMRON CONNECTアカウントの接続を行います。\nナラティブブックとOMRON CONNECTを連携させて測定機器からの情報をナラティブブックに取り込みます。\n[はい]をクリックすると、OMRON CONNECTのログインページに遷移します。連携させるOMRON CONNECTアカウントでログインを行い、同意事項をよく読んだ上で、承諾してください。',
          DIALOG_TYPE.YES_NO,
          () => {
            var omronAuthURL = location.protocol + '//' + location.host + '/api/omron_connect_account/contact_omron_connect_auth'
            console.log('omron auth url: ' + omronAuthURL)
            window.location.href = omronAuthURL
          }
        )
      }
    },

    revokeOmronConnect () {
      this.showConfirmDialog(
        'OMRON CONNECTアカウントの連携解除',
        'OMRON CONNECTアカウントとの連携を解除します。\nナラティブブック側に保持されている、OMRON CONNECTアカウントの情報と、OMRON CONNECTから取得したバイタルデータすべてを削除します。\nナラティブブックに保持されている情報を削除しても、厳密にはナラティブブックとOMRON CONNECTアカウントの接続は解除されません。\nナラティブブックとOMRON CONNECTとの接続を完全に削除する場合は、システム管理者にお問い合わせください。',
        DIALOG_TYPE.YES_NO,
        () => { this.$emit('revokeOmronConnectEvent') }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-profile-column {
  .return-to-home {
    button {
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #999;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .red_titled_column {
    margin-bottom: 20px;
    h4 {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding-bottom: 5px;
      color: #6c272d;
      border-bottom: 1px solid #aaa;
      font-size: 18px;
      font-weight: normal;
    }
    .list-data {
      .list-data-line {
        margin-bottom: 10px;
        .labels {
          font-size: 0.9em;
          font-weight: bold;
        }
        .content {
          margin-right: 10px;
          .archive-limited {
            color: #6c272d;
          }
          select {
            padding: 5px;
            height: 28px;
            font-size: 16px;
            background-color: #999;
            color: #fff;
          }
          .archive-request-btn {
            margin-left: 20px;
            border: 1px solid #666;
            border-radius: 3px;
            background-color: #666;
            color: #fff;
            font-weight: bold;
          }
          .create-auth-info-btn {
            border: 1px solid #eb6101;
            border-radius: 3px;
            background-color: #eb6101;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
  .archive-list {
    margin: 10px 0;
    .archive-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 5px;
      border-bottom: 1px solid #ddd;
      .title {
        margin-bottom: 5px;
        font-weight: bold;
      }
      .content {
        margin-bottom: 5px;
      }
      .name {
        width: 200px;
        @include mediaQuery('phone') {
          width: 60%;
        }
      }
      .request-at {
        width: 150px;
        @include mediaQuery('phone') {
          width: 40%;
        }
      }
      .status {
        width: calc(100% - 200px - 150px - 120px);
        @include mediaQuery('phone') {
          width: 60%;
        }
      }
      .controll {
        width: 120px;
        @include mediaQuery('phone') {
          width: 40%;
        }
        .archive-controll {
          text-align: right;
          button {
            margin-bottom: 5px;
            border: 1px solid #666;
            border-radius: 3px;
            background-color: #666;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
  .add-qr-code-btn {
    font-size: 14px;
    margin-left: 20px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-weight: bold;
  }
  .static-identify-code-list {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    .title {
      margin-right: 5px;
      font-weight: bold;
    }
    .static-id-category {
      margin-right: 10px;
    }
    .static-id-name {
      margin-right: 10px;
    }
    .static-id-timestamp {
      margin-right: 10px;
    }
    .static-id-controll {
      margin-right: 10px;
      .static-id-delete-btn {
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .app-signin-token-list {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    .title {
      margin-right: 5px;
      font-weight: bold;
    }
    .app-token-device {
      margin-right: 10px;
    }
    .app-token-timestamp {
      margin-right: 10px;
    }
    .app-token-controll {
      margin-right: 10px;
      .app-token-delete-btn {
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  button.quit-btn {
    margin-left: 50px;
    border: 1px solid #eb6101;
    border-radius: 3px;
    background-color: #eb6101;
    color: #fff;
    font-weight: bold;
  }
  .author-info {
    margin: 30px 0;
    padding-top: 10px;
    border-top: 1px solid #aaa;
    font-size: 0.8em;
  }
  .mail-change-btn {
    cursor: pointer;
    margin-left: 10px;
    height: 20px;
  }
  .change-password-btn {
    border-radius: 3px;
    background-color: #999;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
  }
  .edit-user-basic-btn {
    cursor: pointer;
    margin-left: 20px;
    height: 20px;
  }
  .edit-user-address-btn {
    cursor: pointer;
    margin-left: 20px;
    height: 20px;
  }
  .zoom-account-column {
    margin-bottom: 10px;
    .add-zoom-btn {
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      vertical-align: middle;
    }
    .reconnect-zoom-btn {
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      vertical-align: middle;
    }
    .revoke-zoom-btn {
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      vertical-align: middle;
    }
  }
  .omron-connect-column {
    margin-bottom: 10px;
    .add-omron-btn {
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      vertical-align: middle;
    }
    .reconnect-omron-btn {
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      vertical-align: middle;
    }
    .revoke-omron-btn {
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      vertical-align: middle;
    }
  }
}
</style>
