import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'

export default class ReporterOrganization extends Model {
  static entity = 'reporterOrganizations'

  static fields () {
    return {
      id: this.attr(null),
      deleted: this.attr(false),
      organizationNumber: this.attr(''),
      name: this.attr(''),
      nameKana: this.attr(''),
      shortName: this.attr(''),
      prefecture: this.attr(''),
      city: this.attr(''),
      addressDetail: this.attr(''),
      address: this.attr(''),
      zipCode: this.attr(''),
      tel: this.attr(''),
      mail: this.attr(''),
      revoke_log: this.attr(null),
      icon_image_version: this.attr('')
    }
  }

  get numberForHuman () {
    if (this.organizationNumber) {
      return this.organizationNumber.substr(0, 4) + '-' + this.organizationNumber.substr(4, 4) + '-' + this.organizationNumber.substr(8, 4) + '-' + this.organizationNumber.substr(12, 4)
    } else {
      return ''
    }
  }

  get iconImageSrc () {
    return process.env.BASE_URL + '/reporter_organization/icon_image?reporter_organization_id=' + this.id + '&version=' + this.icon_image_version
  }

  get members () {
    return Author.query().where('reporter_organization_id', this.id).get()
  }

  get applyingForRevoke () {
    if (this.revoke_log && !this.revoke_log.restored && this.revoke_log.revoke_key.length > 0) {
      return true
    } else {
      return false
    }
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static apiConfig = {
    actions: {
      fetchByID ({idList}) {
        return this.request({
          method: 'get',
          url: 'reporter_organization/list_by_id',
          withCredentials: true,
          params: { reporter_organizations: idList },
          dataKey: 'data.reporter_organizations'
        })
        .then(res => {
          ReporterOrganization.commit((state) => {
            state.fetchedAt = new Date()
          })
          if (res.entities && res.entities.reporterOrganizations) {
            return res.entities.reporterOrganizations
          } else {
            return []
          }
        })
      },
    
      fetch ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'reporter_organization/related_list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          dataKey: 'data.reporter_organizations'
        })
        .then(res => {
          ReporterOrganization.commit((state) => {
            state.fetchedAt = new Date()
          })
          if (res.entities && res.entities.reporterOrganizations) {
            return res.entities.reporterOrganizations
          } else {
            return []
          }
        })
      },

      async update ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'reporter_organization',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async create ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'reporter_organization',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async updateAreaBlock ({ reporterOrganizationID, areaBlockID }) {
        var formData = new FormData()
        formData.append('reporter_organization_id', reporterOrganizationID)
        formData.append('area_block_id', areaBlockID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'reporter_organization/update_area_block',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会申請（施設管理者）
      async prepareRevoke ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'reporter_organization/prepare_revoke',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会申請取り消し（システム管理者）
      async cancelRevoke ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'reporter_organization/cancel_revoke',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会受理(システム管理者)
      async revoke ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'reporter_organization/revoke',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 退会復帰(システム管理者)
      async restore ({reporterOrganizationID}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'reporter_organization/restore',
            params: { id: reporterOrganizationID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      // 未使用施設の削除
      async destroy ({reporterOrganizationID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'reporter_organization',
            params: { id: reporterOrganizationID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },

      fetchRelatedIDList ({timelineOwnerID}) {
        return this.request({
          method: 'get',
          url: 'reporter_organization/related_id_list',
          withCredentials: true,
          params: { target_user_id: timelineOwnerID },
          save: false
        })
        .then(res => {
          if (res.response.data.success === true) {
            return { reporter_organizations: res.response.data.reporter_organizations }
          }
        })
      },

      findByNumber ({organizationNumber}) {
        return this.request({
          method: 'get',
          url: 'reporter_organization/find_by_number',
          withCredentials: true,
          params: { organization_number: organizationNumber },
          save: false
        })
        .then(res => {
          return { reporter_organization: res.response.data.reporter_organization }
        })
      },

      listWithSummary () {
        return this.request({
          method: 'get',
          url: 'reporter_organization/list_with_summary',
          withCredentials: true,
          save: false
        })
        .then(res => {
          return { reporter_organizations: res.response.data.reporter_organizations }
        })
      },

      fetchWithSummary ({reporterOrganizationID}) {
        return this.request({
          method: 'get',
          url: 'reporter_organization/find_with_summary',
          withCredentials: true,
          params: { reporter_organization_id: reporterOrganizationID },
          save: false
        })
        .then(res => {
          return { reporter_organization: res.response.data.reporter_organization }
        })
      },
    }
  }
}
