import axios from './AxiosInstance'

export default {
  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'third_party_linkage/list',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ result: res.data })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetch_by_token: ({ code, token }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'third_party_linkage',
        withCredentials: true,
        params: { code: code, token: token }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ result: res.data })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetch_by_signin_token: ({signinTokenID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'third_party_linkage',
        withCredentials: true,
        params: { third_party_signin_token_id: signinTokenID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ result: res.data })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetch_by_organizaton: ({ organizationID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'third_party_linkage/list_by_organization',
        withCredentials: true,
        params: { organization_id: organizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ result: res.data })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'third_party_linkage',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  validate_mail: ({mailValidateToken}) => {
    var formData = new FormData()
    formData.append('mail_validate_token', mailValidateToken)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'third_party_linkage/validate_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  admit: ({targetID}) => {
    var formData = new FormData()
    formData.append('id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'third_party_linkage/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reject: ({targetID, message}) => {
    var formData = new FormData()
    formData.append('id', targetID)
    formData.append('reject_message', message)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'third_party_linkage/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  update: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'third_party_linkage',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'third_party_linkage',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy_physically: ({targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'third_party_linkage/destroy_physically',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
