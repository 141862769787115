<template>
  <div class="horus-system-management-operator-home-view">
    <h1 class="centering-title">管理団体ホーム</h1>
    <horus-manage-area-block-detail-column
      :systemManager="null"
      :areaBlockOperator="areaBlockOperator"
      :areaBlockID="areaBlockOperator.area_block_id"
      />
    <div class="request-url-column">
      <h3>施設登録申請URL</h3>
      <div class="organization-request">{{ organizationRequestURL }}</div>
      <h3>報告施設登録申請URL</h3>
      <div class="organization-request">{{ reporterOrganizationRequestURL }}</div>
    </div>
  </div>
</template>

<script>
import HorusManageAreaBlockDetailColumn from '@/components/manageSystem/areaBlock/HorusManageAreaBlockDetailColumn.vue'

export default {
  name: 'HorusSystemManagementOperatorView',

  components: {
    HorusManageAreaBlockDetailColumn
  },

  props: {
    areaBlockOperator: Object
  },

  computed: {
    organizationRequestURL () {
      return document.location.protocol + '//' + document.location.host + '/prepare_create_organization_request?area_block_id=' + this.areaBlockOperator.area_block_id
    },

    reporterOrganizationRequestURL () {
      return document.location.protocol + '//' + document.location.host + '/prepare_create_reporter_organization_request?area_block_id=' + this.areaBlockOperator.area_block_id
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-management-operator-home-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .request-url-column {
    margin: 10px 0;
    padding: 10px;
    background-color: #fff;
    h3 {
      margin: 0;
      font-size: 1em;
      font-weight: bold;
      color: #333;
    }
    .organization-request {
      margin: 5px 0 10px 0;
      font-family: 'Courier New', monospace;
      font-weight: bold;
    }
  }
}
</style>
