<template>
  <div class="horus-info-content-modal">
    <horus-modal-frame
      :showModalFlag="showInfoContentModal">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/info_icon.png" v-if="type == 'boadComment'" />
          <img src="/static/images/wish_icon.png" v-if="type == 'wish'" />
          {{ title }}
        </div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="controll-for-all">
          <img src="/static/images/open_close_icon.png" @click="toggleAllContent" />
          <button class="new-infocontent-button" @click="newInfoContent">新規作成</button>
        </div>
        <div class="message-for-all">
          {{ title }}の内容は、共有している人全員で追加・編集・削除することができます
        </div>
        <div class="info-content-list">
          <h4>掲示版に表示している {{ title }}</h4>
          <draggable 
            v-model="infoContents" 
            item-key="id"
            tag="horus-info-content-in-modal"
            draggable=".horus-info-content-in-modal" 
            handle=".drug-handle" 
            @end="onDrugEnd">
            <template #item="{element}">
              <horus-info-content-in-modal
                :infoContent="element"
                :contentOpen="contentOpened(element)"
                :type="type"
                :showDrugHandle="true"
                v-on:showInfoContentPhotoEvent="showPhoto"
                v-on:toggleBodyEvent="toggleInfoContent"
                v-on:toInvisibleEvent="toInvisibleContent"
                v-on:toVisibleEvent="toVisibleContent"
                v-on:editContentEvent="editContent"
                v-on:removeContentEvent="removeContent" />
            </template>
          </draggable>
        </div>
        <div class="info-content-list">
          <h4>掲示版に表示していない {{ title }}</h4>
          <horus-info-content-in-modal
            v-for="hInfoContent in hiddenInfoContents"
            :key="'infocontentmodal' + hInfoContent.id"
            :infoContent="hInfoContent"
            :contentOpen="contentOpened(hInfoContent)"
            :type="type"
            v-on:showInfoContentPhotoEvent="showPhoto"
            v-on:toggleBodyEvent="toggleInfoContent"
            v-on:toInvisibleEvent="toInvisibleContent"
            v-on:toVisibleEvent="toVisibleContent"
            v-on:editContentEvent="editContent"
            v-on:removeContentEvent="removeContent" />
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import {
  BoadComment,
  Wish
} from '@/models'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusInfoContentInModal from '@/components/leftColumn/HorusInfoContentInModal.vue'

export default {
  name: 'HorusInfoContentModal',

  components: {
    draggable,
    HorusModalFrame,
    HorusInfoContentInModal
  },

  props: {
    timelineOwner: Object,
    showInfoContentModal: Boolean,
    type: String
  },

  computed: {
    title () {
      if (this.type === 'boadComment') {
        return 'Info'
      } else if (this.type === 'wish') {
        return 'Wish'
      } else {
        return ''
      }
    }
  },

  data () {
    return {
      infoContents: [],
      hiddenInfoContents: [],
      openingContent: []
    }
  },

  watch: {
    type: { handler: 'loadInfoContents', immediate: true }
  },

  methods: {
    loadInfoContents () {
      if (this.timelineOwner) {
        if (this.type === 'wish') {
          this.loadWishes()
        } else if (this.type === 'boadComment') {
          this.loadInfos()
        } else {
          this.infoContents = []
          this.hiddenInfoContents = []
        }
      }
    },

    async loadWishes () {
      await Wish.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      this.infoContents = await Wish.query().where('user_id', this.timelineOwner.id).where('position', value => value > 0).orderBy('position', 'asc').get()
      this.hiddenInfoContents = await Wish.query().where('user_id', this.timelineOwner.id).where('position', 0).get()
    },

    async loadInfos () {
      await BoadComment.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      this.infoContents = await BoadComment.query().where('user_id', this.timelineOwner.id).where('position', value => value > 0).orderBy('position', 'asc').get()
      this.hiddenInfoContents = await BoadComment.query().where('user_id', this.timelineOwner.id).where('position', 0).get()
    },

    contentOpened (infoContent) {
      return this.openingContent.indexOf(infoContent) >= 0
    },

    toggleAllContent () {
      if (this.openingContent.length > 0) {
        this.openingContent = []
      } else {
        this.openingContent = this.infoContents.concat(this.hiddenInfoContents)
      }
    },

    toggleInfoContent (infoContent) {
      const showIndex = this.openingContent.indexOf(infoContent)
      if (showIndex >= 0) {
        this.openingContent.splice(showIndex, 1)
      } else {
        this.openingContent.push(infoContent)
      }
    },

    showPhoto (infoContent, type, firstShowIndex) {
      this.$emit('showInfoContentPhotoEvent', infoContent, type, firstShowIndex)
    },

    openFile () {
      console.log('info content, openFile CALL, need to imprements')
    },

    closeModal () {
      this.$emit('infoContentModalCloseEvent')
    },

    onDrugEnd (event) {
      if (this.type === 'wish') {
        Wish.api().updatePosition({ timelineOwnerID: this.timelineOwner.id, targetWishID: this.infoContents[event.newIndex].id, newPosition: event.newIndex + 1 })
      } else if (this.type === 'boadComment') {
        BoadComment.api().updatePosition({ timelineOwnerID: this.timelineOwner.id, targetBoadCommentID: this.infoContents[event.newIndex].id, newPosition: event.newIndex + 1 })
      }
    },

    toInvisibleContent (infoContent) {
      if (this.type === 'wish') {
        Wish.api().toInvisible({ timelineOwnerID: this.timelineOwner.id, targetWishID: infoContent.id }).then(res => {
          if (res.success) {
            this.loadInfoContents()
          }
        })
      } else if (this.type === 'boadComment') {
        BoadComment.api().toInvisible({ timelineOwnerID: this.timelineOwner.id, targetBoadCommentID: infoContent.id }).then(res => {
          if (res.success) {
            this.loadInfoContents()
          }
        })
      }
    },

    toVisibleContent (infoContent) {
      if (this.type === 'wish') {
        Wish.api().toVisible({ timelineOwnerID: this.timelineOwner.id, targetWishID: infoContent.id }).then(res => {
          if (res.success) {
            this.loadInfoContents()
          }
        })
      } else if (this.type === 'boadComment') {
        BoadComment.api().toVisible({ timelineOwnerID: this.timelineOwner.id, targetBoadCommentID: infoContent.id }).then(res => {
          if (res.success) {
            this.loadInfoContents()
          }
        })
      }
    },

    newInfoContent () {
      this.$emit('newInfoContentEvent', this.type, () => { this.loadInfoContents() })
    },

    editContent (infoContent) {
      this.$emit('editInfoContentEvent', infoContent, this.type, () => { this.loadInfoContents() })
    },

    removeContent (infoContent) {
      this.$emit('removeInfoContentEvent', infoContent, this.type, () => {
        console.log(infoContent)
        if (this.type === 'wish') {
          Wish.api().destory({ timelineOwnerID: this.timelineOwner.id, targetID: infoContent.id }).then(async () => {
            this.loadInfoContents()
          })
        } else if (this.type === 'boadComment') {
          BoadComment.api().destory({ timelineOwnerID: this.timelineOwner.id, targetID: infoContent.id }).then(async () => {
            this.loadInfoContents()
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-info-content-modal {
  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    .title {
      width: 100%;
      margin: 10px;
      text-align: center;
      font-weight: bold;
      img {
        width: 15px;
      }
    }
    .close-btn {
      text-align: right;
      width: 30px;
      img {
        cursor: pointer;
      }
    }
  }
  .modal-body {
    height: calc(100% - 48px);
    padding-bottom: 30px;
    overflow: auto;
    .controll-for-all {
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
      text-align: right;
      img {
        height: 20px;
        margin-left: 10px;
      }
      button {
        margin-left: 20px;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .message-for-all {
      margin: 10px 20px;
      padding: 5px;
      border: 1px solid #aaa;
      background-color: #fcf8e3;
      color: #666;
      text-align: center;
    }
    .info-content-list {
      margin: 5px 20px;
      h4 {
        margin: 10px 0;
        font-size: 1.2em;
        color: #6c272d;
      }
    }
  }
}
</style>
