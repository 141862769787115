import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import createPersistedState from 'vuex-persistedstate'
import database from './database'

const store = new Vuex.Store({
  plugins: [
    VuexORM.install(database),
    createPersistedState()
  ]
})

export default store