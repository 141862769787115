<template>
  <div class="horus-user-task">
    <h2>予定一覧</h2>
    <div class="task-controll">
      <div class="display-controll">
        <button class="display-change-btn" :class="{ active: termDateOfDisplay === 1 }" @click="displayTaskDateOf(1)">本日</button>
        <button class="display-change-btn" :class="{ active: termDateOfDisplay === 7 }" @click="displayTaskDateOf(7)">7日後</button>
        <button class="display-change-btn" :class="{ active: termDateOfDisplay === 30 }" @click="displayTaskDateOf(30)">30日後</button>
      </div>
      <div class="controll">
        <button class="create-btn" @click="createTask">新規作成</button>
        <img src="/static/images/gear_icon.png" class="config-btn" @click="showDisplayConfig" />
      </div>
    </div>
    <div class="user-task-list">
      <horus-user-task-column
        v-for="userTask in userTasks"
        :key="'usrTask' + userTask.id"
        :userTask="userTask"
        :signinUser="signinUser"
        :signinMember="signinMember"
        :timlineOwner="timelineOwner"
        v-on:editTaskEvent="editTask"
        v-on:deleteTaskEvent="deleteTask"
        v-on:showTaskPhotoEvent="showTaskPhoto"
        v-on:showDetailEvent="showDetail">
      </horus-user-task-column>
    </div>
  </div>
</template>

<script>
import {
  Author,
  UserTask
} from '@/models'

import moment from 'moment'

import HorusUserTaskColumn from '@/components/userTask/HorusUserTaskColumn.vue'
// import HorusTaskDisplayConfigurationModal from '@/components/userTask/HorusTaskDisplayConfigurationModal.vue'

export default {
  mixins: [],

  name: 'HorusUserTask',

  components: {
    HorusUserTaskColumn,
    // HorusTaskDisplayConfigurationModal
  },

  props: {
    timelineOwner: Object,
    signinMember: Object,
    signinUser: Object,
    taskUpdatedAt: Date,
    taskDisplayConfiguration: Object
  },

  data () {
    return {
      taskCheckedAt: null,
      userTasks: [],
      termDateOfDisplay: 1,
      // taskDisplayConf: null,
      taskDisplayConfModalShow: false,
      // taskTargetTimelineOwner: null,
      editTargetTask: null,
      formTaskTargetAt: null,
      formTaskSelectedTags: [],
      formTaskCreateTags: [],
      formTaskPushTargets: [],
      showSelectTimelineOwner: false
    }
  },

  watch: {
    taskUpdatedAt: { handler: 'loadUserTask', immediate: true },
    timelineOwner: { handler: 'reloadUserTask', immediate: true },
    signinMember: { handler: 'reloadUserTask' }
  },

  mounted () {
    this.loadUserTask()
  },

  methods: {
    async reloadUserTask () {
      await this.fetchUserTask()
    },

    async loadUserTask () {
      if (this.taskUpdatedAt && (!this.taskCheckedAt || this.taskUpdatedAt > this.taskCheckedAt)) {
        await this.fetchUserTask()
      }
      this.taskCheckedAt = new Date()
    },

    async fetchUserTask () {
      var authorID = ''
      if (this.signinUser) {
        authorID = this.signinUser.author_id
      }
      if (this.signinMember) {
        authorID = this.signinMember.author_id
      }
      var upcomingLimit = moment().add(this.termDateOfDisplay, 'days').endOf('day')
      var pastLimit = moment().startOf('day')
      if (this.timelineOwner && this.timelineOwner.author_id !== authorID) {
        this.userTasks = await UserTask.query().where((task) => {
          return task.targetAt < upcomingLimit && task.targetAt > pastLimit && task.reader_id === authorID && task.user_id === this.timelineOwner.id
        }).orderBy('target_at', 'asc').get()
      } else {
        this.userTasks = await UserTask.query().where((task) => {
          return task.targetAt < upcomingLimit && task.targetAt > pastLimit && task.reader_id === authorID
        }).orderBy('target_at', 'asc').get()
      }
      this.loadTaskAroundData()
    },

    async loadTaskAroundData () {
      var unloadAuthors = []
      for(var task of this.userTasks) {
        if (task.user_id && Author.find('usr' + task.user_id)) {
          unloadAuthors.push('usr' + task.user_id)
        }
        for(var authorID of task.notify_targets) {
          if (!Author.find(authorID)) {
            if (unloadAuthors.indexOf(authorID) < 0) unloadAuthors.push(authorID)
          }
        }
      }
      if (unloadAuthors.length > 0) {
        await Author.api().fetchByID({ idList: unloadAuthors })
      }
    },

    displayTaskDateOf (day) {
      this.termDateOfDisplay = day
      this.fetchUserTask()
    },

    createTask () {
      console.log('HorusUserTask.vue, createTask')
      this.$emit('createTaskEvent', 'none')
    },

    editTask (targetTask) {
      this.$emit('editTaskEvent', targetTask)
    },

    deleteTask (targetTask) {
      this.$emit('deleteTaskEvent', targetTask)
    },

    showTaskPhoto (userTask, photoIndex) {
      this.$emit('showTaskPhotoEvent', userTask, photoIndex)
    },

    showDetail (userTask) {
      console.log('in user task show detail.')
      this.$emit('showTaskDetailEvent', userTask)
    },

    showDisplayConfig () {
      this.$emit('showTaskConfigurationEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-user-task {
  padding: 10px 20px;
  padding-left: 0;
  @include mediaQuery('phone') {
    padding-left: 20px;
  }
  h2 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #666;
  }
  .task-controll {
    display: flex;
    justify-content: space-between;
    .display-controll {
      display: flex;
    }
    .controll {
      display: flex;
    }
    .display-change-btn {
      margin-right: 10px;
      font-weight: bold;
      border: 1px solid #666;
      border-radius: 3px;
      color: #666;
      background-color: #fff;
      &.active {
        background-color: #666;
        color: #fff;
      }
    }
  }
  .create-btn {
    margin-right: 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-weight: bold;
  }
  .config-btn {
    height: 30px;
    width: 30px;
  }
  .user-task-list {
    margin-top: 10px;
  }
}
</style>
