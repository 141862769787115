import { Model } from '@vuex-orm/core'
import moment from 'moment'
import axiosHelper from '@/api/AxiosInstance'

// カテゴリ分け用のフラグ:書き込みカテゴリ
export const ACTIVITY_LOG_CATEGORY_RECORD = 0
// カテゴリ分け用のフラグ:家族設定カテゴリ
export const ACTIVITY_LOG_CATEGORY_FAMILY = 1
// カテゴリ分け用のフラグ:かかりつけカテゴリ
export const ACTIVITY_LOG_CATEGORY_RELATION = 2
// カテゴリ分け用のフラグ:設定代行カテゴリ
export const ACTIVITY_LOG_CATEGORY_DEPUTIZE = 3
// カテゴリ分け用のフラグ:施設職員カテゴリ
export const ACTIVITY_LOG_CATEGORY_ORGANIZATION_MEMBER = 4
// カテゴリ分け用のフラグ:設定代行先の書き込みカテゴリ
export const ACTIVITY_LOG_CATEGORY_DEPUTIZE_RECORD = 10
// カテゴリ分け用のフラグ:設定代行先の家族設定カテゴリ
export const ACTIVITY_LOG_CATEGORY_DEPUTIZE_FAMILY = 11
// カテゴリ分け用のフラグ:設定代行先のかかりつけカテゴリ
export const ACTIVITY_LOG_CATEGORY_DEPUTIZE_RELATION = 12
// カテゴリ分け用のフラグ:設定代行先の設定代行カテゴリ
export const ACTIVITY_LOG_CATEGORY_DEPUTIZE_DEPUTIZE = 13
// カテゴリ分け用のフラグ:設定代行先の職員カテゴリ
export const ACTIVITY_LOG_CATEGORY_DEPUTIZE_ORGANIZATION_MEMBER = 14

// タイトル表示修飾用のフラグ:アイコン表示
export const ACTIVITY_LOG_TITLE_ICON = 100
// タイトル表示修飾用のフラグ:職員アイコン
export const ACTIVITY_LOG_TITLE_MEMBER_ICON = 101
// タイトル表示修飾用のフラグ:施設職員
export const ACTIVITY_LOG_TITLE_MEMBER = 102
// タイトル表示修飾用のフラグ:設定代行者
export const ACTIVITY_LOG_TITLE_AGENT = 103
// タイトル表示修飾用のフラグ:施設職員管理者
export const ACTIVITY_LOG_TITLE_MEMBER_MANAGER = 104
// タイトル表示修飾用のフラグ:施設かかりつけ管理者
export const ACTIVITY_LOG_TITLE_RELATION_MANAGER = 105
// タイトル表示修飾用のフラグ:施設設定代行管理者
export const ACTIVITY_LOG_TITLE_DEPUTIZE_MANAGER = 106
// タイトル表示修飾用のフラグ:報告施設職員
export const ACTIVITY_LOG_TITLE_REPORTER_MEMBER = 107
// タイトル表示修飾用のフラグ:報告施設管理者
export const ACTIVITY_LOG_TITLE_REPORTER_MANAGER = 108
// タイトル表示修飾用のフラグ:なし
export const ACTIVITY_LOG_TITLE_NONE = 200

export default class ActivityLog extends Model {
  static entity = 'activity_logs'

  static fields () {
    return {
      id: this.attr(null),
      category: this.string(''),
      title: this.string(''),
      content: this.string(''),
      link_url: this.string(''),
      created_at: this.string(''),
      read: this.boolean(false),
      clicked: this.boolean(false),
      target_author: this.string('')
    }
  }

  get createdAt () {
    return new Date(this.created_at)
  }

  get createdAtStr () {
    const created = this.createdAt
    return moment(created).format('YYYY/MM/DD HH:mm:ss')
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async storeData (payload) {
    ActivityLog.commit((state) => {
      state.fetchedAt = new Date()
    })
    console.log(payload.activity_logs)
    if (payload.activity_logs) {
      return ActivityLog.insertOrUpdate({ data: payload.activity_logs })
    }
  }

  static apiConfig = {
    actions: {
      fetch (idList) {
        return this.request({
          method: 'get',
          url: 'activity_log/list_from_id',
          withCredentials: true,
          params: { activity_logs: idList },
          // save: false
          dataKey: 'activity_logs'
        })
        .then(response => {
          // return ActivityLog.storeData(response.response.data)
          console.log(response)
          ActivityLog.commit((state) => {
            state.fetchedAt = new Date()
          })
          console.log(response.entities)
          return response.entities
        })
      },
    
      async updateReadFlag () {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'activity_log/update_read_flag',
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async toClicked ({targetID}) {
        var formData = new FormData()
        formData.append('id', targetID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'activity_log/to_clicked',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },

      unreadCount () {
        return this.request({
          method: 'get',
          url: '/activity_log/unread_count',
          withCredentials: true
        })
        .then(res => {
          return { count: res.response.data.count }
        })
      },

      fetchIDList ({fromDate}) {
        return this.request({
          method: 'get',
          url: '/activity_log/id_list_from',
          withCredentials: true,
          params: { from: fromDate }
        })
        .then(res => {
          var unStoreID = []
          res.response.data.activity_logs.forEach(alid => {
            if (!ActivityLog.find(alid)) {
              unStoreID.push(alid)
            }
          })
          if (unStoreID.length > 0) {
            ActivityLog.api().fetch(unStoreID)
          }
          return { activityLogs: res.response.data.activity_logs }
        })
      },
    }
  }

}
