import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'

export default class SystemManager extends Model {
  static entity = 'systemManagers'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      mail: this.string(''),
      signin_at: this.attr(null)
    }
  }

  get signinAt () {
    if (this.signin_at.length > 0) {
      return new Date(this.signin_at)
    }
    return null
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData (payload) {
    await SystemManager.deleteAll()
    let entities = await SystemManager.insert({ 
      data: { 
        id: payload.signin_manager_id, 
        name: payload.name, 
        mail: payload.mail,
        signin_at: new Date() 
      } 
    })
    SystemManager.commit((state) => {
      state.fetchedAt = new Date()
    })
    if (entities && entities.systemManagers) {
      return entities.systemManagers[0]
    } else {
      return null
    }
  }

  static apiConfig = {
    actions: {
      async fetch ({ loginID, password }) {
        var formData = new FormData()
        formData.append('login_id', loginID)
        formData.append('password', password)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'authenticate/system_signin',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SystemManager.updateData(res.response.data)
        })
      },

      fetchList () {
        return this.request({
          method: 'get',
          url: 'system_manager/list',
          withCredentials: true,
          save: false
        })
        .then(res => {
          return res.response.data
        })
      },
        
      async create ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'system_manager',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SystemManager.updateData(res.response.data)
        })
      },
    
      async update ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'system_manager',
            data: formData,
            save: false
          }
        })
      },
    
      async delete ({ targetID }) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'system_manager/delete',
            params: { id: targetID },
            save: false
          }
        })
      },
    
      async unlock ({ targetID }) {
        var formData = new FormData()
        formData.append('id', targetID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'system_manager/unlock',
            data: formData,
            save: false
          }
        })
      },
    
      async forgotPassword ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'system_manager/forgot_password',
            data: formData,
            save: false
          }
        })
      },
    
      async resetPassword ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'system_manager/reset_password',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SystemManager.updateData(res.response.data)
        })
      },
    
      async updatePassword ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'system_manager/update_password',
            data: formData,
            save: false
          }
        })
        .catch(error => {
          throw error
        })
      },
    
      async changeMail ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'system_manager/change_mail',
            data: formData,
            save: false
          }
        })
      },
    
      async updateMail ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'system_manager/update_mail',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SystemManager.updateData(res.response.data)
        })
      },

      nameValidation ({ name }) {
        return this.request({
          method: 'get',
          url: 'system_manager/name_validation',
          withCredentials: true,
          params: { name: name },
          save: false
        })
        .then(res => {
          return res.response.data
        })
      },
    
      mailValidation ({ mail }) {
        return this.request({
          method: 'get',
          url: 'system_manager/mail_validation',
          withCredentials: true,
          params: { mail: mail },
          save: false
        }).then(res => {
          return res.response.data
        })
      },
    
      signinIDValidation ({ signinID }) {
        return this.request({
          method: 'get',
          url: 'system_manager/signin_id_validation',
          withCredentials: true,
          params: { signin_id: signinID },
          save: false
        })
        .then(res => {
          return res.response.data
        })
      }
        
    }
  }
}
