<template>
  <div class="horus-create-no-signin-user">
    <div class="document-form">
      <div class="input-label">同意書</div>
      <div class="input-filename">{{ inputFilename }}</div>
      <input type="file" :id="fileInputID" style="display: none;" ref="fileInput" @change="postFileChange" />
      <label :for="fileInputID">
        <span class="select-file">ファイル選択</span>
      </label>
    </div>
    <div class="notify_for_admit">
      <h5>設定代行施設登録における確認事項</h5>
      <ul>
        <li>ナラティブブックが利用者本人の情報を利用者本人が希望した家族や施設と共有するシステムであることを、利用者本人が理解していること</li>
        <li>設定の変更は必ず利用者本人の意思に基づいて行うこと</li>
      </ul>
    </div>
    <div class="admit-agreement" @click="toggleAdmit">
      <horus-custom-check-box
        :active="admitAgreement"
        :enabled="true"
        :type="'color'"
        />
      上記確認事項を確認しました
    </div>
    <div class="create-controll"><button class="create-user-btn" :class="{disable: !createUserReady}" @click="createUser">新規利用者作成</button></div>
  </div>
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusCreateNoSiginUser',

  components: {
    HorusCustomCheckBox
  },

  data () {
    return {
      fileInputID: 'agreementFileInput',
      uploadFile: null,
      admitAgreement: false
    }
  },

  created () {
    this.initForm()
  },

  computed: {
    inputFilename () {
      if (this.uploadFile) {
        return this.uploadFile.name
      } else {
        return ''
      }
    },

    createUserReady () {
      if (this.uploadFile && this.admitAgreement) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initForm () {
      this.fileInputID = 'fileInputAgreement' + Math.floor(Math.random() * 10000)
    },

    initDatas () {
      this.uploadFile = null
      this.admitAgreement = false
    },

    selectDocumentFile () {
      this.$nextTick(() => {
        this.$refs.fileInput.click()
      })
    },

    postFileChange () {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.uploadFile = {
          uploadFile: selectedFiles[0],
          name: selectedFiles[0].name
        }
      }
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    createUser () {
      if (this.uploadFile && this.admitAgreement) {
        this.$emit('createUserEvent', this.uploadFile, () => { this.initDatas() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/createWithAgreementForm.scss';

.horus-create-no-signin-user {
  margin: 0;
  .document-form {
    display: flex;
    margin: 10px 0;
    .input-label {
      margin-right: 10px;
      font-weight: bold;
    }
    .input-filename {
      display: inline-block;
      min-width: 100px;
      margin-right: 10px;
      padding: 3px 5px;
      border: 1px solid #aaa;
    }
    .select-file {
      cursor: pointer;
      padding: 3px 6px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-weight: bold;
    }
  }
  .create-controll {
    margin: 10px 0;
    text-align: center;
    .create-user-btn {
      padding: 3px 10px;
      border: 1px solid #c43d53;
      border-radius: 3px;
      background-color: #c43d53;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      &.disable {
        border-color: #df7163;
        background-color: #df7163;
        color: #ddd;
      }
    }
  }
}
</style>
