import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import AreaBlock from './AreaBlock'

export default class AreaBlockOperator extends Model {
  static entity = 'areaBlockOperators'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      nickname: this.attr(''),
      mail: this.string(''),
      signin_at: this.attr(null),
      area_block_id: this.attr(null)
    }
  }

  get signinAt () {
    if (this.signin_at.length > 0) {
      return new Date(this.signin_at)
    }
    return null
  }

  get areaBlock () {
    return AreaBlock.query().where('id', this.area_block_id).first()
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData (payload) {
    await AreaBlockOperator.deleteAll()
    let entities = await AreaBlockOperator.insert({ 
      data: { 
        id: payload.area_block_operator_id, 
        name: payload.name, 
        nickname: payload.nickname, 
        mail: payload.mail, 
        area_block_id: payload.area_block_id, 
        signin_at: new Date()
      } 
    })
    if (entities.areaBlockOperator) {
      let operator = entities.areaBlockOperator[0]
      if (operator) {
        if (!AreaBlock.find(operator.area_block_id)) {
          await AreaBlock.fetch({ targetID: operator.area_block_id })
        }
      }
      AreaBlockOperator.commit((state) => {
        state.fetchedAt = new Date()
      })
      return operator
    } else {
      return null
    }
  }

  static apiConfig = {
    actions: {
      async fetch ({ loginID, password }) {
        var formData = new FormData()
        formData.append('login_id', loginID)
        formData.append('password', password)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'authenticate/area_block_signin',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return AreaBlockOperator.updateData(res.response.data)
        })
      },
    
      async changeMail ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block_operator/change_mail',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async updateMail ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block_operator/update_mail',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return AreaBlockOperator.updateData(res.response.data)
        })
      },
    
      async forgotPassword ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block_operator/forgot_password',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async resetPassword ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block_operator/reset_password',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async updatePassword ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block_operator/update_password',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return AreaBlockOperator.updateData(res.response.data)
        })
      },
    
      fetchList ({ areaBlockID }) {
        return this.request({
          method: 'get',
          url: 'area_block_operator/list',
          withCredentials: true,
          params: { area_block_id: areaBlockID },
          save: false
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async create ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'area_block_operator',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return AreaBlockOperator.updateData(res.response.data)
        })
      },
    
      async update ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block_operator',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async delete ({ areaBlockID, targetID }) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'area_block_operator',
            params: { area_block_id: areaBlockID, id: targetID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async unlock ({ areaBlockID, targetID }) {
        var formData = new FormData()
        formData.append('id', targetID)
        formData.append('area_block_id', areaBlockID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'area_block_operator/unlock',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },

      nicknameValidation ({ areaBlockID, nickname, exceptID }) {
        return this.request({
          method: 'get',
          url: 'area_block_operator/nickname_validation',
          withCredentials: true,
          params: { area_block_id: areaBlockID, nickname: nickname, except_id: exceptID },
          save: false
        })
        .then(res => {
          return res.response.data
        })
      },

      mailValidation ({ areaBlockID, mail }) {
        return this.request({
          method: 'get',
          url: 'area_block_operator/mail_validation',
          withCredentials: true,
          params: { area_block_id: areaBlockID, mail: mail },
          save: false
        })
        .then(res => {
          return res.response.data
        })
      },

      signinIDValidation ({ signinID }) {
        return this.request({
          method: 'get',
          url: 'area_block_operator/signin_id_validation',
          withCredentials: true,
          params: { signin_id: signinID },
          save: false
        })
        .then(res => {
          return res.response.data
        })
      }
    }
  }
}
