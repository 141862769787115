<template>
  <div class="horus-qr-code-reader-modal" v-show="showModal">
    <div class="horus-qr-reader-backdrop"></div>
    <div class="horus-qr-code-reader-modal-frame">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/qr_read_icon.png" />
          {{ title }}
        </div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body" :class="{ withFooter: showFooter }">
        <qrcode-stream v-if="showModal" :constraints="{ facingMode: 'environment' }" :camera="camera" @detect="decoded"></qrcode-stream>
      </div>
      <div class="modal-footer" v-if="showFooter">
        <span class="selection-title">読み取りカードの種別</span>
        <select v-model="selectedCategoryID">
          <option value="">読み取るカードの種別を選んでください</option>
          <option v-for="category in categories" v-bind:value="category.id" :key="'categorySelectForRead' + category.id">
            {{ category.name }}
          </option>
        </select>
        <span class="caption">※ナラティブブック公式カードを読み取る場合は、カード種別の選択は不要です。</span>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'HorusQrCodeReaderModal',

  components: {
    QrcodeStream
  },

  props: {
    title: String,
    showModal: Boolean,
    showFooter: Boolean,
    categories: Array
  },

  data () {
    return {
      camera: 'off',
      // categories: [],
      selectedCategoryID: null
    }
  },

  watch: {
    showModal: { handler: 'updateCameraState', immediate: true }
  },

  computed: {
    cameraMode () {
      if (this.showModal) {
        return 'environment'
      } else {
        return 'off'
      }
    }
  },

  // created () {
  //   this.loadCategories()
  // },

  methods: {
    // loadCategories () {
    //   this.$store.dispatch('fetchStaticIdentifyCodeCategories').then(res => {
    //     this.categories = res.staticIdentifyCodeCategories
    //   })
    // },

    updateCameraState () {
      if (this.showModal) {
        this.camera = 'environment'
      } else {
        this.camera = 'off'
      }
    },

    decoded (decodedString) {
      console.log('QR DECODE COMPLETE:: ' + decodedString)
      console.log('return value: ' + decodedString[0].rawValue)
      if (decodedString[0] && decodedString[0].rawValue) {
        this.$emit('qrDecodedEvent', { decodedString: decodedString[0].rawValue, categoryID: this.selectedCategoryID })
      }
    },

    closeModal () {
      this.$emit('qrReaderCancelEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-qr-code-reader-modal {
  position: absoluete;
  top: 0;
  left: 0;
  .horus-qr-reader-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .horus-qr-code-reader-modal-frame {
    position: absolute;
    top: 30px;
    left: calc((100vw - (600px - 40px)) / 2);
    width: calc(600px - 40px);
    height: calc(600px - 40px);
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: 100vw;
      height: 100vw;
      top: 0;
      left: 0;
    }
    .modal-header {
      display: flex;
      margin: 10px 5px;
      .title {
        width: 100%;
        text-align: center;
        img {
          width: 15px;
        }
      }
      .close-btn {
        text-align: right;
        height: 30px;
        img {
          cursor: pointer;
        }
        .jump-to-origin {
          margin-right: 20px;
          vertical-align: top;
        }
      }
    }
    .modal-body {
      width: 100%;
      height: 100%;
      position: relative;
      &.withFooter {
        height: calc(100% - 80px);
      }
    }
    .modal-footer {
      width: calc(100% - 20px);
      height: 60px;
      background-color: #fff;
      padding: 10px;
      text-align: center;
      .selection-title {
        margin-right: 10px;
        font-weight: bold;
      }
      .caption {
        font-size: 0.8em;
      }
    }
  }
}
</style>
