<template>
  <div class="horus-quick-agreement-action-modal" v-show="showModal">
    <div class="horus-quick-agreement-backdrop"></div>
    <div class="horus-quick-agreement-modal-frame">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/qr_read_icon.png" />
          {{ title }}
        </div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="message-column">
          <div class="relation-action-notify" v-if="actionTypeRelation">
            <div class="main-message">
              {{ authorName }}さんとの{{ actionName }}を行います。<br/>
              {{ authorName }}さんのかかりつけ施設になるため、下記の同意事項を確認してください。
            </div>
            <div class="notify_for_admit">
              <h1>かかりつけ登録における確認事項</h1>
              <ul>
                <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                <li>利用者との円滑なコミュニケーションのために、かかりつけ施設になることを利用者本人から同意されていること</li>
                <li>なりすまし防止のために、案内先の方との確認ができていること</li>
                <li>利用者のタイムラインや特記事項に書き込む情報の内容について、説明し同意を取得した上でかかりつけ設定を行ってください</li>
              </ul>
            </div>
          </div>
          <div class="deputize-action-notify" v-if="actionTypeDeputize">
            <div class="main-message">
              {{ authorName }}さんとの{{ actionName }}を行います。<br/>
              {{ authorName }}さんの設定代行施設になるため、下記の同意事項を確認してください。
            </div>
            <div class="notify_for_admit">
              <h1>設定代行登録における確認事項</h1>
              <ul>
                <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                <li>当該施設を設定代行施設として登録する事について、利用者本人が同意していること</li>
                <li>利用者本人が作成した委任状を受け取っていること</li>
                <li>設定代行施設に依頼されることを施設が同意していること</li>
                <li>設定代行施設の登録が完了後、受け取った委任状を所定の場所にアップロードすること</li>
                <li>なりすまし防止のために、利用者本人との確認ができていること</li>
                <li>設定の変更は必ず利用者本人の意思に基づいて行うこと</li>
              </ul>
            </div>
          </div>
          <div class="deputize-action-notify" v-if="actionTypeDeputizeAgent">
            <div class="main-message">
              {{ authorName }}さんとの{{ actionName }}を行います。<br/>
              {{ authorName }}さんの代理者になるため、下記の同意事項を確認してください。
            </div>
            <div class="notify_for_admit">
              <h1>代理者登録における確認事項</h1>
              <ul>
                <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                <li>あなたを代理者として登録する事について、利用者本人が同意していること</li>
                <li>代理者に依頼されることをあなたが同意していること</li>
                <li>なりすまし防止のために、利用者本人との確認ができていること</li>
                <li>設定の変更は必ず利用者本人の意思に基づいて行うこと</li>
              </ul>
            </div>
          </div>
          <div class="family-action-notify" v-if="actionTypeFamily">
            <div class="main-message">
              {{ authorName }}さんとの{{ actionName }}を行います。<br/>
              {{ authorName }}さんのナラティブブックを見るために、下記の同意事項を確認してください。
            </div>
            <div class="notify_for_admit">
              <h1>家族登録における同意事項</h1>
              <ul>
                <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                <li>円滑なコミュニケーションのために、ナラティブブックで家族設定することを相手の方から同意されていること</li>
                <li>なりすまし防止のために、依頼先の方との確認ができていること</li>
                <li>家族に登録すると家族となった利用者は、あなたのタイムラインや特記事項の閲覧や書き込みができるようになります（詳しくは利用規約の第２条及び第７条をご確認ください）</li>
              </ul>
            </div>
            <div class="family-permission-notify">あなたのナラティブブックも{{ authorName }}さんに公開する場合は、下記のチェックをはずしてください。
              <div class="show-timeline-option" @click="toggleShowTimeline">
                <horus-custom-check-box
                  :active="!showOwnTimeline"
                  :enabled="true"
                  :type="'color'"
                  />
                <span>私のナラティブブックは見せない</span>
              </div>
            </div>
          </div>
          <div class="reporter-relation-action-notify" v-if="actionTypeReporterRelation">
            <div class="main-message">
              {{ authorName }}さんとの{{ actionName }}を行います。<br/>
              {{ authorName }}さんの報告サービス施設になるため、下記の同意事項を確認してください。
            </div>
            <div class="notify_for_admit">
              <h1>報告サービス利用登録における確認事項</h1>
              <ul>
                <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
                <li>利用者との円滑なコミュニケーションのために、報告サービス施設になることを利用者本人から同意されていること</li>
                <li>なりすまし防止のために、案内先の方との確認ができていること</li>
                <li>利用者のタイムラインに書き込む情報の内容について、説明し同意を取得した上で報告サービス利用設定を行ってください</li>
              </ul>
            </div>
          </div>
          <div class="admit-agreement" @click="toggleAdmit">
            <horus-custom-check-box
              :active="admitAgreement"
              :enabled="true"
              :type="'color'"
              />
            上記確認事項を確認しました
          </div>
        </div>
        <div class="controll-column">
          <button class="cancel-btn" @click="cancel">中止</button>
          <button class="submit-btn" :class="{disable: !admitAgreement}" @click="submit">実行</button>
        </div>
      </div>
      <div class="modal-body-backdrop" v-show="showWaiting || finished || showFailMessage"></div>
      <div class="waiting-column" v-show="showWaiting">
        {{ authorName }}からの承認を待っています。<br/>
        <img class="progress" src="/static/images/progress_s.gif" /><br/>
        <button class="abort-btn" @click="finishAndClose">中止</button>
      </div>
      <div class="finished-message" v-show="finished">
        処理が完了しました。<br/>
        モーダルを閉じてください。
        <button class="finished-btn" @click="finishAndClose">閉じる</button>
      </div>
      <div class="fail-message" v-show="showFailMessage" @click="finishAndClose">{{ failMessage }}</div>
    </div>
  </div>
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as QUICK_ACTION_TYPE from '@/assets/constants/quickActionType.js'

import {
  QuickAgreementAction
} from '@/api'

export default {
  name: 'HorusQuickAgreementActonModal',

  components: {
    HorusCustomCheckBox
  },

  props: {
    quickAgreementCode: String,
    organizationID: Number,
    reporterOrganizationID: Number,
    userID: Number,
    callback: Function,
    showModal: Boolean,
    title: String,
    author: Object,
    actionType: Number
  },

  data () {
    return {
      showWaiting: false,
      admitAgreement: false,
      showOwnTimeline: false,
      quickAgreementAction: null,
      failMessage: '',
      showFailMessage: false,
      poolingIntervalID: null,
      submitProgress: false,
      finished: false
    }
  },

  watch: {
    showModal: { handler: 'initData', immediate: true }
  },

  beforeUnmount () {
    this.stopPoolingAction()
  },

  computed: {
    authorName () {
      if (this.author) {
        return this.author.name
      }
      return ''
    },

    actionName () {
      if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_FAMILY) {
        return '家族設定の依頼'
      } else if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_RELATION) {
        return 'かかりつけ設定の依頼'
      } else if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE) {
        return '設定代行の依頼'
      } else if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_REPORTER_RELATION) {
        return 'サービス利用の依頼'
      } else if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE_AGENT) {
        return '代理者設定の案内'
      } else {
        return ''
      }
    },

    actionTypeFamily () {
      if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_FAMILY) {
        return true
      }
      return false
    },

    actionTypeRelation () {
      if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_RELATION) {
        return true
      }
      return false
    },

    actionTypeDeputize () {
      if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE) {
        return true
      }
      return false
    },

    actionTypeReporterRelation () {
      if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_REPORTER_RELATION) {
        return true
      }
      return false
    },

    actionTypeDeputizeAgent () {
      if (this.actionType === QUICK_ACTION_TYPE.QUICK_ACTION_DEPUTIZE_AGENT) {
        return true
      }
      return false
    }
  },

  methods: {
    initData () {
      if (this.showModal) {
        this.showWaiting = false
        this.admitAgreement = false
        this.showOwnTimeline = false
        this.quickAgreementAction = null
        this.failMessage = ''
        this.showFailMessage = false
        this.poolingIntervalID = null
        this.submitProgress = false
        this.finished = false
      }
    },

    startPoolingAction () {
      if (this.quickAgreementCode) {
        // start pooling
        this.poolingIntervalID = setInterval(() => {
          this.fetchQuickActionResult()
        }, 5000)
      }
    },

    stopPoolingAction () {
      if (this.poolingIntervalID) {
        clearInterval(this.poolingIntervalID)
      }
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    toggleShowTimeline () {
      this.showOwnTimeline = !this.showOwnTimeline
    },

    cancel () {
      this.callback()
      this.closeModal()
    },

    submit () {
      if (this.admitAgreement && !this.submitProgress) {
        this.submitProgress = true
        var formData = new FormData()
        formData.append('code', this.quickAgreementCode)
        formData.append('action_type', this.actionType)
        if (this.organizationID) {
          formData.append('organization_id', this.organizationID)
        }
        if (this.reporterOrganizationID) {
          formData.append('reporter_organization_id', this.reporterOrganizationID)
        }
        if (this.userID) {
          formData.append('target_user_id', this.userID)
        }
        if (this.showOwnTimeline) {
          formData.append('option', 'family_both')
        }
        QuickAgreementAction.create({ formData: formData }).then(res => {
          console.log(JSON.stringify(res))
          if (res.success) {
            this.quickAgreementAction = res.quickAgreementAction
            this.showWaiting = true
            this.startPoolingAction()
          } else {
            this.failMessage = res.message
            this.showFailMessage = true
          }
        }).catch(error => {
          console.error(error)
          this.failMessage = '申請に失敗しました。初めからやり直してください。'
          this.showFailMessage = true
        })
      }
    },

    fetchQuickActionResult () {
      if (this.quickAgreementAction) {
        QuickAgreementAction.fetch({ targetID: this.quickAgreementAction.id }).then(res => {
          console.log(JSON.stringify(res))
          if (res.quickAgreementAction.agreed) {
            this.showWaiting = false
            this.finished = true
            this.stopPoolingAction()
            this.callback()
          }
        })
      }
    },

    closeModal () {
      this.stopPoolingAction()
      this.$emit('closeModalEvent')
    },

    finishAndClose () {
      this.stopPoolingAction()
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/createWithAgreementForm.scss';
@import '@/assets/css/mediaQuery.scss';

.horus-quick-agreement-action-modal {
  position: absoluete;
  top: 0;
  left: 0;
  .horus-quick-agreement-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .horus-quick-agreement-modal-frame {
    position: absolute;
    top: 30px;
    left: calc((100vw - (600px - 40px)) / 2);
    width: calc(600px - 40px);
    max-height: calc(100vh - 100px);
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    filter: drop-shadow(3px 3px 5px rgba(0,0,0,.5));
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: 100vw;
      max-height: calc(100vh - 80px);
      top: 0;
      left: 0;
    }
    .modal-header {
      display: flex;
      margin: 10px 5px;
      .title {
        width: 100%;
        text-align: center;
        img {
          width: 15px;
        }
      }
      .close-btn {
        text-align: right;
        height: 30px;
        img {
          cursor: pointer;
        }
        .jump-to-origin {
          margin-right: 20px;
          vertical-align: top;
        }
      }
    }
    .modal-body {
      width: calc(100% - 20px);
      max-height: calc(100vh - 150px);
      padding: 10px;
      position: relative;
      overflow: auto;
      .message-column {
        .main-message {
          margin-bottom: 10px;
          font-size: 1.2em;
          line-height: 1.6em;
        }
        .error-message {
          width: 400px;
          text-align: center;
        }
      }
      .family-permission-notify {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #aaa;
        .show-timeline-option {
          display: flex;
          justify-content: center;
        }
      }
      .controll-column {
        display: flex;
        justify-content: space-around;
        button {
          width: 100px;
          margin: 5px;
          padding: 3px;
          border: 1px solid #ddd;
          border-radius: 3px;
          background-color: #ddd;
          color: #fff;
          font-weight: bold;
          font-size: 13px;
          &.cancel-btn {
            border-color: #c43d53;
            background-color: #c43d53;
          }
          &.submit-btn {
            border-color: #5cb85b;
            background-color: #5cb85b;
            color: #fff;
            &.disable {
              border-color: #999;
              background-color: #999;
              color: #ddd;
            }
          }
        }
      }
    }
    .modal-body-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.3;
    }
    .waiting-column {
      position: absolute;
      top: 50px;
      left: 10px;
      width: calc(100% - 40px);
      height: 150px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 3px;
      filter: drop-shadow(3px 3px 5px rgba(0,0,0,.5));
      text-align: center;
      font-size: 1.2em;
      img {
        margin: 20px;
      }
      .abort-btn {
          width: 100px;
          margin: 5px;
          padding: 3px;
          border: 1px solid #c43d53;
          border-radius: 3px;
          color: #fff;
          background-color: #c43d53;
          font-weight: bold;
          font-size: 13px;
      }
    }
    .fail-message {
      position: absolute;
      top: 50px;
      left: 10px;
      width: calc(100% - 40px);
      padding: 10px;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 3px;
      filter: drop-shadow(3px 3px 5px rgba(0,0,0,.5));
      text-align: center;
      font-size: 1.2em;
    }
    .finished-message {
      position: absolute;
      top: 50px;
      left: 10px;
      width: calc(100% - 40px);
      padding: 10px;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 3px;
      filter: drop-shadow(3px 3px 5px rgba(0,0,0,.5));
      text-align: center;
      font-size: 1.2em;
      .finished-btn {
        width: 100px;
        margin: 5px;
        padding: 3px;
        border: 1px solid #ddd;
        border-radius: 3px;
        background-color: #ddd;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        border-color: #999;
        background-color: #999;
      }
    }
  }
}
</style>
