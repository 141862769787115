<template>
  <div class="med-ex-in-timeline">
    <div class="med-ex-title">
      <img src="/static/images/vital_green.png" />バイタル
    </div>
    <div class="med-ex-single" v-if="bloodPressureExist">
      <span class="med-ex-single-title">血圧</span>
      <span class="med-ex-single-value">{{ medicalExamination.bloodPressureJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.bodyTemperature >= 0">
      <span class="med-ex-single-title">体温</span>
      <span class="med-ex-single-value">{{ medicalExamination.bodyTemperatureJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.spO2 >= 0">
      <span class="med-ex-single-title">酸素飽和度</span>
      <span class="med-ex-single-value">{{ medicalExamination.spO2Jp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.pulseRate >= 0">
      <span class="med-ex-single-title">脈拍</span>
      <span class="med-ex-single-value">{{ medicalExamination.pulseRateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.irregularPulse >= 0">
      <span class="med-ex-single-title">不整脈</span>
      <span class="med-ex-single-value">{{ medicalExamination.irregularPulseJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.anemiaState >= 0">
      <span class="med-ex-single-title">貧血</span>
      <span class="med-ex-single-value">{{ medicalExamination.anemiaStateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.jaundiceState >= 0">
      <span class="med-ex-single-title">黄疸</span>
      <span class="med-ex-single-value">{{ medicalExamination.jaundiceStateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.oralState >= 0">
      <span class="med-ex-single-title">口腔内状況</span>
      <span class="med-ex-single-value">{{ medicalExamination.oralStateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.respiratoryNoize >= 0">
      <span class="med-ex-single-title">呼吸音</span>
      <span class="med-ex-single-value">{{ medicalExamination.respiratoryNoizeJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.respiratoryRateState >= 0">
      <span class="med-ex-single-title">呼吸数</span>
      <span class="med-ex-single-value">{{ medicalExamination.respiratoryRateStateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.hartSound >= 0">
      <span class="med-ex-single-title">心音</span>
      <span class="med-ex-single-value">{{ medicalExamination.hartSoundJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.abdominalPalpation >= 0">
      <span class="med-ex-single-title">腹部触診</span>
      <span class="med-ex-single-value">{{ medicalExamination.abdominalPalpationJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.abdominalAusculation >= 0">
      <span class="med-ex-single-title">腹部聴診</span>
      <span class="med-ex-single-value">{{ medicalExamination.abdominalAusculationJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.edema >= 0">
      <span class="med-ex-single-title">むくみ</span>
      <span class="med-ex-single-value">{{ medicalExamination.edemaJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.bedsore >= 0">
      <span class="med-ex-single-title">褥瘡</span>
      <span class="med-ex-single-value">{{ medicalExamination.bedsoreJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.eatingState >= 0">
      <span class="med-ex-single-title">食事状況</span>
      <span class="med-ex-single-value">{{ medicalExamination.eatingStateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.takeMedicineState >= 0">
      <span class="med-ex-single-title">服薬状況</span>
      <span class="med-ex-single-value">{{ medicalExamination.takeMedicineStateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.urinationState >= 0">
      <span class="med-ex-single-title">排尿状況</span>
      <span class="med-ex-single-value">{{ medicalExamination.urinationStateJp }}</span>
    </div>
    <div class="med-ex-single" v-if="this.medicalExamination.facesState >= 0">
      <span class="med-ex-single-title">排便状況</span>
      <span class="med-ex-single-value">{{ medicalExamination.facesStateJp }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusTimelineMedicaExamination',

  props: {
    medicalExamination: Object
  },

  computed: {
    bloodPressureExist () { return (this.medicalExamination.bloodPressureMin >= 0 && this.medicalExamination.bloodPressureMax >= 0) }
  }
}
</script>

<style lang="scss" scoped>
.med-ex-title {
  font-weight: bold;
  color: #666;
  img {
    margin-right: 5px;
    margin-bottom: 3px;
    width: 15px;
    vertical-align: middle;
  }
}
.med-ex-single {
  margin: 0 20px;
  .med-ex-single-title {
    font-weight: bold;
  }
}
</style>
