<template>
  <div class="horus-zoom-meeting-create-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/video_meeting_icon.png" class="title-icon" />ビデオ会議作成</h1>
        <div class="main-attendee-column" v-if="mainAttendee">
          <span class="main-attendee-title">通話先:</span>
          <img :src="mainAttendee.portrateSrc" class="author-portrate" />
          <span class="main-attendee-name">{{ mainAttendee.name }}</span>
        </div>
      </div>
      <div class="modal-body">
        <div class="topic-column">
          <horus-attribute-in-form
            :labelText="'題名'"
            :necessary="false"
            :validationState="'disabled'"
            :validationMessage="''"
            :attributeNotify="'入力がない場合、開始日時とあなたの名前と最初の参加者の名前が設定されます。'">
            <textarea placeholder="ここに書いてください" class="text-content" rows="1" ref="textContentField" v-model="topic"></textarea>
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'ミーティングパスワード'"
            :necessary="true"
            :validationState="validatePassword.state"
            :validationMessage="validatePassword.message"
            :attributeNotify="'6文字以上、10文字以内、半角英数字と記号[@-_^]のみ。'">
            <input :type="passwordInputType" v-model="meetingPassword" />
          </horus-attribute-in-form>
          <div class="toggle-password-visible" @click="togglePasswordVisible">
            <horus-custom-check-box
              :active="passwordVisible"
              :enabled="true"
              :type="'color'"
              />パスワードを表示
          </div>
        </div>
        <div class="atendees-column">
          <h2 class="attendee-title">追加の参加者</h2>
          <div class="attendee-list">
            <div
              v-for="attendee in attendees"
              :key="'meetingattendee' + attendee.id">
              <div class="attendee-column" v-if="attendee !== mainAttendee">
                <img :src="attendee.portrateSrc" class="author-portrate" />
                <span class="attendee-name">{{ attendee.name }}</span>
                <span class="remove-attendee" @click="removeAttendee(attendee)">
                  <img src="/static/images/x_dark_icon.png" class="close-icon" @click="removeAttendee(attendee)" />
                </span>
              </div>
            </div>
          </div>
          <button class="add-attendee" @click="addAttendee">参加者を追加</button>
        </div>
        <div class="controll-column">
          <div class="date-controll">
            <div class="date-display" v-if="targetTime && targetTime.date">
              <span @click="changeTargetTime">{{ targetTimeStr }}</span>
              <img src="/static/images/x_dark_icon.png" class="close-icon" @click="removeTargetTime" />
            </div>
            <button class="date-setting-btn" v-if="!targetTime.date" @click="setTargetTime">開始日時を設定</button>
          </div>
          <div class="submit-controll">
            <button class="start-btn" :class="{ enable: readyToSubmit }" v-if="targetTime.date" @click="submit">ビデオ会議を予約</button>
            <button class="start-btn" :class="{ enable: readyToSubmit }" v-if="!targetTime.date" @click="submit">今すぐ開始</button>
            <button class="cancel-btn" @click="cancel">キャンセル</button>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusZoomMeetingCreateModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    loggedInUser: Object,
    signinMember: Object,
    showModal: Boolean,
    mainAttendee: Object,
    attendees: Array,
    targetTime: Object
  },

  data () {
    return {
      topic: '',
      topicDefault: true,
      meetingPassword: '',
      passwordVisible: false
    }
  },

  computed: {
    targetTimeStr () {
      if (this.targetTime.date) {
        return moment(this.targetTime.date).format('YYYY/MM/DD HH:mm')
      }
      return '日付が設定されていません'
    },

    validatePassword () {
      if (this.meetingPassword.length <= 0) {
        return { state: 'invalid', message: 'パスワードは入力必須です' }
      } else if (this.meetingPassword.trim().match(/^([a-zA-Z0-9]|@|_|-|\^){6,10}$/g)) {
        return { state: 'valid', message: '' }
      } else {
        return { state: 'invalid', message: 'パスワードは半角英数字と記号[@-_^]のみで6文字以上10文字以内です。' }
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    readyToSubmit () {
      if (this.validatePassword.state === 'valid') {
        return true
      }
      return false
    }
  },

  watch: {
    showModal: { handler: 'initData', immediate: true },
    targetTime: { handler: 'updateTopic', deep: true }
  },

  methods: {
    initData () {
      if (this.showModal) {
        this.topicDefault = true
        this.updateTopic()
        this.meetingPassword = ''
      }
    },

    updateTopic () {
      console.log('updteTopic call,,,,')
      if (this.mainAttendee) {
        if (this.topicDefault) {
          var timeString = ''
          if (this.targetTime.date) {
            timeString = moment(this.targetTime.date).format('YYYY/MM/DD HH:mm')
          } else {
            timeString = moment(new Date()).format('YYYY/MM/DD HH:mm')
          }
          console.log('updted timeString' + timeString)
          console.log('before//' + this.topic)
          if (this.signinMember) {
            this.topic = timeString + '開始 [' + this.signinMember.author.name + '-' + this.mainAttendee.name + ']'
          } else {
            this.topic = timeString + '開始 [' + this.loggedInUser.author.name + '-' + this.mainAttendee.name + ']'
          }
          console.log('after///' + this.topic)
        }
      }
    },

    manualUpdateTopic () {
      this.topicDefault = false
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    addAttendee () {
      this.$emit('addAttendeeEvent')
    },

    removeAttendee (attendee) {
      this.$emit('removeAttendeeEvent', attendee)
    },

    setTargetTime () {
      this.$emit('setTargetTimeEvent')
    },

    changeTargetTime () {
      this.$emit('changeTargetTimeEvent')
    },

    removeTargetTime () {
      this.$emit('removeTargetTimeEvent')
    },

    submit () {
      if (this.readyToSubmit) {
        var formData = new FormData()
        formData.append('zoom_meeting[topic]', this.topic)
        formData.append('zoom_meeting[password]', this.meetingPassword)
        if (this.targetTime.date) {
          formData.append('zoom_meeting[start_time]', moment(this.targetTime.date).format('YYYY/MM/DD HH:mm:ss'))
        }
        formData.append('zoom_meeting[main_attendee]', this.mainAttendee.id)
        this.attendees.forEach(target => {
          if (target !== this.mainAttendee) {
            formData.append('zoom_meeting[attendees][]', target.id)
          }
        })
        this.$emit('submitEvent', formData)
      }
    },

    cancel () {
      this.$emit('cancelEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-zoom-meeting-create-modal {
  .modal-header {
    .main-attendee-column {
      display: flex;
      // justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 5px;
      .main-attendee-title {
        display: inline-block;
        margin-right: 15px;
        font-size: 1.1em;
        font-weight: bold;
      }
      img {
        width: 25px;
        height: 25px;
      }
      .main-attendee-name {
        display: inline-block;
        margin-left: 5px;
        font-size: 1.1em;
        font-weight: bold;
      }
    }
  }
  .modal-body {
    padding: 10px;
    .topic-column {
      label {
        font-weight: bold;
        color: #666;
      }
      .text-content {
        width: calc(100% - 80px);
        height: 3em;
      }
    }
    .toggle-password-visible {
      margin-left: 50px;
    }
    .atendees-column {
      padding: 5px 0;
      .attendee-title {
        margin: 0;
        font-size: 1em;
        font-weight: bold;
      }
      .attendee-list {
        margin: 5px 0;
        margin-left: 10px;
        padding: 0;
        border: 1px solid #aaa;
        display: flex;
        flex-wrap: wrap;
        .attendee-column {
          display: flex;
          align-items: center;
          margin: 5px 10px;
          .author-portrate {
            height: 20px;
          }
          .attendee-name {
            margin-left: 5px;
            margin-right: 10px;
          }
          .remove-attendee {
            img {
              height: 15px;
            }
          }
        }
      }
      .add-attendee {
        background-color: #666;
        color: #fff;
        border: 1px solid #666;
        border-radius: 3px;
        font-weight: bold;
      }
    }
    .controll-column {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #aaa;
      .date-controll {
        .date-display {
          padding: 3px;
          border: 1px solid #aaa;
          font-size: 16px;
          .close-icon {
            margin-left: 20px;
            height: 20px;
          }
        }
        .date-setting-btn {
          padding: 3px 5px;
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
          border-radius: 3px;
          font-weight: bold;
        }
      }
      .start-btn {
        padding: 3px 5px;
        background-color: #ddd;
        color: #fff;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-weight: bold;
        &.enable {
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
        }
      }
      .cancel-btn {
        margin-left: 20px;
        padding: 3px 5px;
        background-color: #fff;
        color: #666;
        border: 1px solid #666;
        border-radius: 3px;
        font-weight: bold;
      }
    }
  }
}
</style>
