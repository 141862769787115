<template>
  <div class="horus-critical-notification-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <div class="header-contents">
          <h1><img src="/static/images/danger_icon.png" class="title-icon" />重要なお知らせ</h1>
          <div class="close-btn">
            <img src="/static/images/x_icon.png" @click="closeModal" />
          </div>
        </div>
      </div>
      <div class="modal-body" v-if="criticalNotifications">
        <div class="summary-message" v-html="summaryHTML"></div>
        <div class="notifications" v-for="notification in criticalNotifications" :key="'usrModalCN' + notification.critical_notification_token">
          <div class="notification-title" :class="{resolve: notification.resolved}" @click="toggleShowNotificationContents(notification)">
            {{ notification.title }}
            <span class="resolved-flg" v-if="notification.resolved">(解決済)</span>
            <span class="block-signin-notify" v-if="notification.block_signin">
              この重要なお知らせはログインをブロックしています。このお知らせの内容に対処するまではナラティブブックの利用を継続することはできません。
            </span>
          </div>
          <div class="notification-contents" v-show="notificationContentsShow(notification)">
            <div class="notification-messages" v-html="messageHTML(notification)"></div>
            <div class="controlls">
              <div class="resolve-note">{{ resolveNoteMessage(notification) }}</div>
              <div class="resolve-confirmation" v-if="notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE" @click="toggleNotificationConfirm(notification)">
                <horus-custom-check-box
                  :active="notificationConfirmed(notification)"
                  :enabled="true"
                  :type="'color'"
                  />
                <span>上記内容に同意しました</span>
              </div>
              <div class="resolve-controll">
                <button class="resolve-btn" @click="handleNotification(notification)">{{ resolveButtonText(notification) }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as CRITICAL_NOTIFICATION_CONSTANTS from '@/assets/constants/notificationType.js'

export default {
  name: 'HorusCriticalNotificationModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    showModal: Boolean,
    criticalNotifications: Array,
    confirmedNotifications: Array
  },

  data () {
    return {
      CRITICAL_NOTIFICATION_CONSTANTS: CRITICAL_NOTIFICATION_CONSTANTS,
      displayNotification: null
    }
  },

  watch: {
    criticalNotifications: { handler: 'initDisplayNotification', immediate: true }
  },

  computed: {
    summaryHTML () {
      var message = ''
      if (this.criticalNotifications) {
        message = 'あなたへの重要なお知らせが' + this.criticalNotifications.length + '件あります。'
      }
      return message
    }
  },

  methods: {
    initDisplayNotification () {
      if (this.criticalNotifications) {
        this.displayNotification = this.criticalNotifications[0]
      }
    },

    toggleShowNotificationContents (notification) {
      this.displayNotification = notification
    },

    notificationContentsShow (notification) {
      if (this.displayNotification === notification) {
        return true
      }
      return false
    },

    messageHTML (notification) {
      if (notification.message) {
        return notification.message.replace(/\n/gi, '<br />')
      }
    },

    notificationConfirmed (notification) {
      if (this.confirmedNotifications && this.confirmedNotifications.some(n => n === notification)) {
        return true
      }
      return false
    },

    resolveNoteMessage (notification) {
      if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_SHOW_ONECE) {
        return 'この重要なお知らせは、あなたに対するメッセージです。内容を確認後、モーダルを閉じると今後表示されることはありません。'
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE) {
        return 'この重要なお知らせは、あなたに対して確認と同意を求めています。「上記内容に同意しました」のチェックボックスを入れて閉じると、今後表示されることはありません。'
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET) {
        return 'この重要なお知らせは、あなたに問題に対する対処を求めています。「対処する」ボタンを押して、問題を解決する必要があります。'
      }
    },

    resolveButtonText (notification) {
      if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_SHOW_ONECE) {
        return '閉じる'
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE) {
        return '解決'
      } else if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_TARGET) {
        return '問題に対処する'
      }
    },

    toggleNotificationConfirm (notification) {
      if (notification.resolve_type === CRITICAL_NOTIFICATION_CONSTANTS.RESOLVE_TYPE_CONFIRM_ONCE) {
        this.$emit('toggleNotificationConfirmationEvent', notification)
      }
    },

    handleNotification (notification) {
      console.log('handle notification')
      this.$emit('resolveNotificationEvent', notification)
    },

    closeModal () {
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-critical-notification-modal {
  .header-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
  .modal-body {
    padding: 10px;
    .summary-message {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #c53d43;
      color: #c53d43;
    }
    .notifications {
      .notification-title {
        margin-top: 5px;
        padding: 10px;
        background-color: #eee;
        font-size: 1.1em;
        font-weight: bold;
        &.resolve {
          color: #999;
        }
        .block-signin-notify {
          font-size: 0.9em;
          color: #c53d43;
        }
      }
      .notification-contents {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ddd;
        .notification-messages {
          font-size: 1.1em;
          margin-bottom: 5px;
        }
        .controlls {
          .resolve-note {
            margin-bottom: 5px;
            padding: 5px;
            border: 1px solid #aaa;
            font-size: 0.9em;
          }
          .resolve-confirmation {
            margin-bottom: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .resolve-controll {
            display: flex;
            justify-content: flex-end;
            .resolve-btn {
              margin: 0 5px;
              padding: 3px 5px;
              border-radius: 3px;
              font-weight: bold;
              border: 1px solid #666;
              background-color: #666;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
