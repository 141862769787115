<template>
  <div class="horus-organization-member-in-manage-list labeled_content_block">
    <div class="labeled_content_single_block">
      <label>氏名(NBID)</label>
      <div class="content">
        {{ member.author.name }}({{ member.nbid }})
        <span class="own-label" v-if="ownInformation">あなた自身</span>
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>管理権限</label>
      <div class="content">
        <span @click="toggleMemberManager">
          <horus-custom-check-box
            :type="'small'"
            :active="member.member_manager"
            :enabled="changeAvailable"
            />
          <span class="checkbox-label">施設管理者</span>
        </span>
        <span @click="toggleRelationManager">
          <horus-custom-check-box
            :type="'small'"
            :active="member.relation_manager"
            :enabled="changeAvailable"
            />
          <span class="checkbox-label">かかりつけ管理者</span>
        </span>
        <span @click="toggleDeputizeManager">
          <horus-custom-check-box
            :type="'small'"
            :active="member.deputize_manager"
            :enabled="organization.deputizable && changeAvailable"
            />
          <span class="checkbox-label">設定代行管理者</span>
        </span>
      </div>
    </div>
    <div class="labeled_content_single_block" v-if="organization.deputizable">
      <label>オプション</label>
      <div class="content" @click="toggleAgent">
        <horus-custom-check-box
          :type="'small'"
          :active="member.agent"
          :enabled="!organization.applyingForRevoke"
          />
        <span class="checkbox-label">設定代行者</span>
      </div>
    </div>
    <div class="small-title">表示用設定<img src="/static/images/pencil_icon.png" @click="editMemberSuom" /></div>
    <div class="display-block">
      <div class="labeled_content_single_block">
        <label>職種</label>
        <div class="content">
          <span class="disable-content" v-if="!memberAdditionalName">未設定</span>
          <span>{{ memberAdditionalName }}</span>
        </div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用氏名</label>
        <div class="content">
          <span class="disable-content" v-if="!memberName">未設定</span>
          <span>{{ memberName }}</span>
        </div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用メール</label>
        <div class="content">
          <span class="disable-content" v-if="!memberMail">未設定</span>
          <span>{{ memberMail }}</span>
        </div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用電話番号</label>
        <div class="content"><span class="disable-content" v-if="!memberTel">未設定</span>
          <span>{{ memberTel }}</span>
        </div>
      </div>
      <div class="labeled_content_single_block">
        <label>表示用写真</label>
        <div class="content">
          <img :src="member.author.portrateSrc" class="member-portrate" />
          {{ memberPortrateCaption }}
        </div>
      </div>
    </div>
    <div class="controll-for-member"><button class="remove-member-btn" :class="{disable: !changeAvailable}" @click="removeMember">職員停止</button></div>
  </div><!-- organization member in manage list -->
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusOrganizationMemberInManageList',

  components: {
    HorusCustomCheckBox
  },

  props: {
    member: Object,
    organization: Object,
    signinMember: Object
  },

  computed: {
    ownInformation () {
      if (this.member.author_id === this.signinMember.author_id) {
        return true
      } else {
        return false
      }
    },

    changeAvailable () {
      if (this.ownInformation || this.organization.applyingForRevoke) {
        return false
      } else {
        return true
      }
    },

    memberAdditionalName () {
      if (this.member.suom_additional_comment && this.member.suom_additional_comment.length > 0) {
        return this.member.suom_additional_comment
      } else {
        return null
      }
    },

    memberName () {
      if (this.member.suom_name && this.member.suom_name.length > 0) {
        return this.member.suom_name
      } else {
        return null
      }
    },

    memberMail () {
      if (this.member.suom_mail && this.member.suom_mail.length > 0) {
        return this.member.suom_mail
      } else {
        return null
      }
    },

    memberTel () {
      if (this.member.suom_tel && this.member.suom_tel.length > 0) {
        return this.member.suom_tel
      } else {
        return null
      }
    },

    memberPortrateCaption () {
      if (!this.member.suom_portrate_own) {
        return '未設定(個人プロフィールの写真を表示しています)'
      } else {
        return ''
      }
    }
  },

  methods: {
    toggleMemberManager () {
      if (!this.ownInformation) {
        this.$emit('toggleMemberManagerEvent', this.member)
      }
    },

    toggleRelationManager () {
      if (!this.ownInformation) {
        this.$emit('toggleRelationManagerEvent', this.member)
      }
    },

    toggleDeputizeManager () {
      if (!this.ownInformation) {
        this.$emit('toggleDeputizeManagerEvent', this.member)
      }
    },

    toggleAgent () {
      // if (!this.ownInformation) {
      //   this.$emit('toggleAgentEvent', this.member)
      // }
      this.$emit('toggleAgentEvent', this.member)
    },

    editMemberSuom () {
      this.$emit('editMemberSuomEvent', this.member)
    },

    removeMember () {
      if (!this.ownInformation) {
        this.$emit('removeMemberEvent', this.member)
      }
    }
  }

}
require('@/assets/css/labeledContent.scss')
</script>

<style lang="scss" scoped>
.horus-organization-member-in-manage-list {
  .checkbox-label {
    margin-right: 20px;
  }
  .own-label {
    margin-left: 10px;
    padding: 1px 5px;
    border: 1px solid #c43d53;
    color: #c43d53;
    font-size: 12px;
  }
  .controll-for-member {
    width: 100%;
    padding: 0 10px;
    text-align: right;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      &.disable {
        border-color: #aaa;
        background-color: #aaa;
      }
    }
  }
}
</style>
