import axios from './AxiosInstance'

// object info
// {
//   id: Number as ID,
//   user_id: self.user_id,
//   zip_code: String formatted,
//   prefecture: String as code,
//   city: String,
//   address: String,
//   latitude: Number float,
//   longitude: Number float,
//   tel: String formatted,
//   cellphone: String formatted,
//   fax: String formatted,
//   mail: String,
//   updated_at: String as Date,
//   deleted: Boolean,
//   author_id: Number as ID,
// }

export default {
  fetch: ({timelineOwnerID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'user_address',
        params: { target_user_id: timelineOwnerID },
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ user_address: res.data.user_address })
        }
      }).catch(error => { reject(error) })
    })
  },

  // createのみ。updateはない。
  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'user_address',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
