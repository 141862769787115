<template>
  <div class="horus-user-task-summary-column">
    <div class="task-header">
      <div class="target-date" :class="{past: targetAtPast}">{{ targetDateStr }}</div>
      <div class="video-meeting" v-if="meetingExist">
        <img src="/static/images/video_meeting_s_green_icon.png" class="video-meeting-icon">
      </div>
      <div class="target-info" v-if="taskTimelineAuthor">
        <img class="task-timeline-portrate" :src="taskTimelineAuthor.portrateSrc"/>
        <span class="timeline-owner-name">{{ taskTimelineAuthor.name }}</span>
      </div>
      <div class="target-info" v-if="taskOrganization">
        <img src="/static/images/org_member.png"/>
        <span class="timeline-owner-name">{{ taskOrganization.shortName }}</span>
      </div>
    </div>
    <div class="task-content">
      <div class="text-content" v-if="userTask.textContent">
        <div class="trancated-text" v-show="truncated" v-html="trancatedTextContent"></div>
        <div class="full-text" v-show="!truncated" v-html="textContentHtml"></div>
      </div>
      <div class="attach-content">
        <div class="photo-count" v-if="userTask.userTaskPhotos.length > 0">
          写真：{{ userTask.userTaskPhotos.length }}件
        </div>
        <div class="file-count" v-if="userTask.userTaskFiles.length > 0">
          添付ファイル：{{ userTask.userTaskFiles.length }}件
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import * as TASK_MEETING_TYPE from '@/assets/constants/taskMeetingType.js'

export default {
  name: 'HorusUserTaskSummaryColumn',

  props: {
    // timelineOwner: Object,
    signinUser: Object,
    signinMember: Object,
    userTask: Object
  },

  data () {
    return {
      truncated: true
    }
  },

  computed: {
    targetDateStr () {
      return moment(this.userTask.targetAt).format('MM月DD日 HH:mm')
    },

    targetAtPast () {
      if (this.userTask.targetAt < new Date()) {
        return true
      } else {
        return false
      }
    },

    meetingExist () {
      if (this.userTask.meetingType > 0) {
        return true
      }
      return false
    },

    trancatedTextContent () {
      if (this.userTask && this.userTask.textContent) {
        var contentText = this.userTask.textContent.replace(TASK_MEETING_TYPE.MEETING_LINK_REPLACE_TEXT, this.userTask.linkURL)
        var textLength = 20
        return contentText.length <= textLength ? contentText : (contentText.substr(0, textLength) + '...')
      }
      return ''
    },

    textContentHtml () {
      if (this.userTask && this.userTask.textContent) {
        var contentText = this.userTask.textContent.replace(TASK_MEETING_TYPE.MEETING_LINK_REPLACE_TEXT, this.userTask.linkURL)
        return contentText.replace(/\n/gi, '<br />')
      }
      return ''
    },

    taskTimelineAuthor () {
      return this.userTask.timelineAuthor
    },

    taskOrganization () {
      return this.userTask.organization
    },

    taskEditable () {
      if ((this.signinUser && this.userTask.author_id === this.signinUser.author_id) || (this.signinMember && this.userTask.author_id === this.signinMember.author_id)) {
        return true
      }
      return false
    }
  }

}
</script>

<style lang="scss" scoped>
.horus-user-task-summary-column {
  padding: 5px;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #000;
  .task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #aaa;
    .target-date {
      margin-right: 10px;
      min-width: 110px;
      font-weight: bold;
      &.past {
        color: #9e3d3f;
      }
    }
    .video-meeting {
      margin-right: 5px;
      .video-meeting-icon {
        width: 20px;
        height: 20px;
      }
    }
    .target-info {
      display: flex;
      justify-content: flex-start;
      width: calc(100% - 60px);
      .task-timeline-portrate {
        width: 20px;
        height: 20px;
      }
      .timeline-owner-name {
        margin: 0 5px;
      }
    }
  }
  .task-content {
    .text-content {
      font-size: 16px;
    }
    .attach-content {
      margin-top: 5px;
      align-items: flex-end;
      flex-wrap: wrap;
      display: flex;
    }
  }
}
</style>
