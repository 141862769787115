<template>
  <div class="horus-system-manage-static-id-code-category-edit-modal">
    <horus-modal-frame
      :showModalFlag="showEditCategory">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />静的QRコードカテゴリの{{ submitText }}</h1>
      </div>
      <div class="modal-body">
        <div class="labled-content">
          <horus-attribute-in-form
            :labelText="'名前'"
            :necessary="true"
            :validationState="validateName"
            :validationMessage="validateNameMessage"
            :attributeNotify="''">
            <input type="text" v-model="name" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'prefix'"
            :necessary="true"
            :validationState="validatePrefix"
            :validationMessage="validatePrefixMessage"
            :attributeNotify="'カテゴリ識別子。公式の場合、コードの先頭2文字の英文字。'">
            <input type="text" v-model="prefix" />
          </horus-attribute-in-form>
          <div class="content-inputs check-selections" @click="toggleOfficial">
            <horus-custom-check-box
              :active="official"
              :enabled="true"
              :type="'color'"
              />
            公式カテゴリ
            <span class="caption">※チェックを入れると公式カテゴリ</span>
          </div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">{{ submitText }}</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusSystemManageStaticIdCategoryEditModal',

  components: {
    HorusAttributeInForm,
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    editCategory: Object,
    showEditCategory: Boolean,
    registedCategories: Array
  },

  data () {
    return {
      name: '',
      prefix: '',
      official: false
    }
  },

  watch: {
    editCategory: { handler: 'initDatas', immediate: true }
  },

  computed: {
    validateName () {
      if (this.name.length > 0) {
        if (this.editCategory) {
          if (this.registedCategories.some(category => (category.name === this.name && category.id !== this.editCategory.id))) {
            return 'invalid'
          } else {
            return 'valid'
          }
        } else {
          if (this.registedCategories.some(category => category.name === this.name)) {
            return 'invalid'
          } else {
            return 'valid'
          }
        }
      } else {
        return 'invalid'
      }
    },

    validateNameMessage () {
      if (this.name.length > 0) {
        if (this.editCategory) {
          if (this.registedCategories.some(category => (category.name === this.name && category.id !== this.editCategory.id))) {
            return '名前が重複しています'
          } else {
            return ''
          }
        } else {
          if (this.registedCategories.some(category => category.name === this.name)) {
            return '名前が重複しています'
          } else {
            return ''
          }
        }
      } else {
        return '名前は入力必須です'
      }
    },

    validatePrefix () {
      if (this.prefix.length > 0) {
        if (this.editCategory) {
          if (this.registedCategories.some(category => (category.prefix === this.prefix && category.id !== this.editCategory.id))) {
            return 'invalid'
          } else {
            if (this.official) {
              if (this.prefix.match(/^[a-zA-Z]{2}$/)) {
                return 'valid'
              } else {
                return 'invalid'
              }
            } else {
              return 'valid'
            }
          }
        } else {
          if (this.registedCategories.some(category => category.prefix === this.prefix)) {
            return 'invalid'
          } else {
            if (this.official) {
              if (this.prefix.match(/^[a-zA-Z]{2}$/)) {
                return 'valid'
              } else {
                return 'invalid'
              }
            } else {
              return 'valid'
            }
          }
        }
      } else {
        return 'invalid'
      }
    },

    validatePrefixMessage () {
      if (this.prefix.length > 0) {
        if (this.editCategory) {
          if (this.registedCategories.some(category => (category.prefix === this.prefix && category.id !== this.editCategory.id))) {
            return 'prefixが重複しています'
          } else {
            if (this.official) {
              if (this.prefix.match(/^[a-zA-Z]{2}$/)) {
                return ''
              } else {
                return '公式カテゴリのprefixは英文字2文字です。'
              }
            } else {
              return ''
            }
          }
        } else {
          if (this.registedCategories.some(category => category.prefix === this.prefix)) {
            return 'prefixが重複しています'
          } else {
            if (this.official) {
              if (this.prefix.match(/^[a-zA-Z]{2}$/)) {
                return ''
              } else {
                return '公式カテゴリのprefixは英文字2文字です。'
              }
            } else {
              return ''
            }
          }
        }
      } else {
        return 'prefixは入力必須です'
      }
    },

    allValid () {
      if (this.validateName === 'valid' && this.validatePrefix === 'valid') {
        return true
      }
      return false
    },

    submitText () {
      if (this.editCategory) {
        return '更新'
      } else {
        return '新規作成'
      }
    }
  },

  methods: {
    initDatas () {
      if (this.editCategory) {
        this.name = this.editCategory.name
        this.prefix = this.editCategory.prefix
        this.official = this.editCategory.official
      } else {
        this.name = ''
        this.prefix = ''
        this.official = false
      }
    },

    toggleOfficial () {
      this.official = !this.official
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        if (this.editCategory) {
          formData.append('id', this.editCategory.id)
        }
        formData.append('static_identify_code_category[name]', this.name)
        formData.append('static_identify_code_category[prefix]', this.prefix)
        formData.append('static_identify_code_category[official]', this.official)
        this.$emit('submitEvent', formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-system-manage-static-id-code-category-edit-modal {
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
