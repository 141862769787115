import axios from './AxiosInstance'

export default {

  send: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'contact_mail',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  },

  fetch: ({limit, offset}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'contact_mail/list',
        withCredentials: true,
        params: { limit: limit, offset: offset }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ contactMails: res.data.contact_mails })
        }
      }).catch(error => { reject(error) })
    })
  }

}
