import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import moment from 'moment'

export default class PublicAnnouncement extends Model {
  static entity = 'publicAnnouncements'

  static fields () {
    return {
      id: this.attr(null),
      content: this.string(''),
      announcement_at: this.string(''),
      public_at: this.string(''),
      close_at: this.string('')
    }
  }

  get announcementAt () {
    var date = new Date(this.announcement_at)
    if (isNaN(date.getTime())) {
      return null
    } else {
      return date
    }
  }

  get announcementAtStr () {
    return moment(this.announcementAt).format('YYYY/MM/DD')
  }

  get publicAt () {
    var date = new Date(this.public_at)
    if (isNaN(date.getTime())) {
      return null
    } else {
      return date
    }
  }

  get publicAtStr () {
    if (this.publicAt) {
      return moment(this.publicAt).format('YYYY/MM/DD HH:mm:SS')
    } else {
      return '未公開'
    }
  }

  get closeAt () {
    var date = new Date(this.close_at)
    if (isNaN(date.getTime())) {
      return null
    } else {
      return date
    }
  }

  get closeAtStr () {
    if (this.closeAt) {
      return moment(this.closeAt).format('YYYY/MM/DD HH:mm:SS')
    } else {
      return '無期限'
    }
  }

  get contentHTML () {
    return this.content.replace(/\n/gi, '<br />')
  }

  get nowPublishing () {
    var now = new Date()
    var nowTime = now.getTime()
    if (this.publicAt && this.publicAt.getTime() < nowTime) {
      if (this.closeAt && this.closeAt.getTime() < nowTime) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async creanAndSave (data) {
    await PublicAnnouncement.deleteAll()
    let entities = await PublicAnnouncement.insertOrUpdate({ data: data })
    // console.log(PublicAnnouncement.all())
    PublicAnnouncement.commit((state) => {
      state.fetchedAt = new Date()
    })
    if (entities && entities.publicAnnouncements) {
      return entities.publicAnnouncements
    } else {
      return []
    }
  }

  static apiConfig = {
    actions: {
      fetch () {
        return this.request({
          method: 'get',
          url: '/public_announcement/public_list',
          withCredentials: true,
          save: false
          // dataKey: 'public_announcements'
        })
        .then(res => {
          return PublicAnnouncement.creanAndSave(res.response.data.public_announcements)
        })
        .catch(error => { error })
      },
    
      fetchList () {
        return this.request({
          method: 'get',
          url: '/public_announcement/list',
          withCredentials: true,
          save: false
          // dataKey: 'data.public_announcements'
        })
        .then(res => {
          return PublicAnnouncement.creanAndSave(res.response.data.public_announcements)
        })
        .catch(error => { error })
      },
    
      async create ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'public_announcement',
            data: formData,
            save: false
          }
        })
      },
    
      async update ({formData}) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'public_announcement',
            data: formData,
            save: false
          }
        })
      },
    
      async destroy ({targetID}) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'public_announcement',
            withCredentials: true,
            params: { id: targetID },
            save: false
          }
        })
      },
    
      async publicNow ({targetID}) {
        var formData = new FormData()
        formData.append('id', targetID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'public_announcement/public_now',
            data: formData,
            save: false
          }
        })
      },
    
      async closeNow ({targetID}) {
        var formData = new FormData()
        formData.append('id', targetID)
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'public_announcement/close_now',
            data: formData,
            save: false
          }
        })
      },
    
      async publicAgain ({targetID}) {
        var formData = new FormData()
        formData.append('id', targetID)
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'public_announcement/public_again',
            withCredentials: true,
            data: formData,
            save: false
          }
        })
      }
    }
  }
}
