<template>
  <div class="horus-create-reporter-orgaznization-request-mail-registed">
    <h2>メールアドレスの入力</h2>
    <div class="registed-content">
      メールアドレスを受け付けました。<br/>
      <span class="registed-address">{{ mailAddress }}</span><br/>
      上記メールアドレスに受信確認のためのメールを送信しました。<br/>
      このページを閉じて、送信されたメールの本文に書かれたURLにアクセスしてください。<br/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusCreateReporterOrganizationRequestMailRegisted',

  props: {
    mailAddress: String
  }
}
</script>

<style lang="scss" scoped>
.horus-create-reporter-organization-request-mail-registed {
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .registed-content {
    padding: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
    text-align: center;
    .registed-address {
      display: inline-block;
      margin: 10px;
      padding: 10px 20px;
      border: 1px solid #aaa;
      font-size: 1.2em;
      font-weight: bold;
    }
  }
}
</style>
