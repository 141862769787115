import { Model } from '@vuex-orm/core'
import moment from 'moment'
import VitalEntry from './VitalEntry'

export default class VitalActiveMass extends Model {
  static entity = 'vitalActiveMass'

  static fields () {
    return {
      id: this.attr(null),
      vital_entry_id: this.attr(null),
      user_id: this.attr(null),
      measure_at: this.attr(''),
      step_count: this.attr(null),
      fast_walk_step_count: this.attr(null),
      stairway_step_count: this.attr(null),
      used_calories_in_active: this.attr(null),
      total_used_calories: this.attr(null),
      moving_distance: this.attr(null),
      burning_body_fat: this.attr(null)
    }
  }

  get vitalEntry () {
    return VitalEntry.find(this.vital_entry_id)
  }

  get measureAt () {
    return new Date(this.measure_at)
  }

  get measureAtStr () {
    return moment(this.measureAt).format('YYYY/MM/DD HH:mm')
  }

  get measureAtJp () {
    return moment(this.measureAt).format('YYYY年MM月DD日 HH:mm')
  }
}
