<template>
  <div class="horus-system-manager-view">
    <h1 class="centering-title">システム管理ユーザー</h1>
    <h2>システム管理ユーザー一覧</h2>
    <div class="manager-list">
      <div class="list-column"
        v-for="manager in systemManagers"
        :key="'sysmanlist' + manager.id"
        >
        <div class="list-line">
          <div class="name"><span class="label">名前</span><span clas="content">{{ manager.name }}</span></div>
          <div class="authenticate"><span class="label">ログインID</span><span clas="content">{{ manager.authenticate_id }}</span></div>
          <div class="mail"><span class="label">メール</span><span clas="content">{{ manager.mail }}</span></div>
          <div class="state"><span class="label">状態</span><span clas="content">{{ state(manager) }}</span></div>
        </div>
        <div class="controll">
          <button class="edit-btn" @click="editManager(manager)">編集</button>
          <button class="edit-btn" v-if="!manager.deleted && manager.id !== systemManager.id" :class="{disable: manager.id === systemManager.id}" @click="freeze(manager)">凍結</button>
          <button class="edit-btn" v-if="manager.deleted && manager.id !== systemManager.id" :class="{disable: manager.id === systemManager.id}" @click="defrost(manager)">凍結解除</button>
          <button class="edit-btn" @click="editMail(manager)">メールアドレス変更</button>
          <button class="edit-btn" v-if="manager.id === systemManager.id" @click="editPassword">パスワード変更</button>
        </div>
      </div>
    </div>
    <h2>案内一覧&nbsp;<button type="button" class="ahead-btn" @click="newInvitation">新規作成</button></h2>
    <div class="manager-list">
      <div class="list-column"
        v-for="invitation in systemManagerInvitations"
        :key="'sysInv' + invitation.id"
        >
        <div class="list-line">
          <div class="name"><span class="label">名前</span><span clas="content">{{ invitation.name }}</span></div>
          <div class="mail"><span class="label">メール</span><span clas="content">{{ invitation.mail }}</span></div>
          <div class="authenticate"><span class="label">作成者</span><span clas="content">{{ invitation.author }}</span></div>
        </div>
        <div class="list-line">
          <div class="state"><span class="label">状態</span><span clas="content">{{ invitation.state }}</span></div>
        </div>
        <div class="controll">
          <button class="edit-btn" v-if="!invitation.used" @click="sendInvitation(invitation)">再送信</button>
          <button class="edit-btn" @click="destroyInvitation(invitation)">削除</button>
        </div>
      </div>
    </div>
    <horus-system-manager-invitation-create-modal
      :showModal="showSystemManagerInvitationCreateModal"
      v-on:cancelSubmitEvent="cancelCreateInvitation"
      v-on:submitEvent="createInvitation"
      />
    <horus-system-manager-edit-modal
      :sourceManager="editTargetManager"
      :showSystemManagerEditModal="showSystemManagerEditModal"
      v-on:cancelSubmitEvent="cancelUpdateManager"
      v-on:submitEvent="updateManager"
      />
    <horus-system-manager-edit-pass-modal
      :showSystemManagerEditPassModal="showSystemManagerEditPassModal"
      v-on:cancelSubmitEvent="cancelUpdatePassword"
      v-on:submitEvent="updatePassword"
      />
    <horus-system-manager-edit-mail-modal
      :showModal="showSystemManagerMailModal"
      :systemManager="editMailTargetManager"
      v-on:cancelSubmitEvent="cancelUpdateMail"
      v-on:submitEvent="reserveUpdateMail"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  SystemManager
} from '@/models'

import {
  SystemManagerInvitationToken
} from '@/api'

import HorusSystemManagerInvitationCreateModal from '@/components/manageSystem/systemManager/HorusSystemManagerInvitationCreateModal.vue'
import HorusSystemManagerEditModal from '@/components/manageSystem/systemManager/HorusSystemManagerEditModal.vue'
import HorusSystemManagerEditPassModal from '@/components/manageSystem/systemManager/HorusSystemManagerEditPassModal.vue'
import HorusSystemManagerEditMailModal from '@/components/manageSystem/systemManager/HorusSystemManagerEditMailModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManagerView',

  components: {
    HorusSystemManagerInvitationCreateModal,
    HorusSystemManagerEditModal,
    HorusSystemManagerEditPassModal,
    HorusSystemManagerEditMailModal,
    HorusConfirmDialog
  },

  props: {
    systemManager: Object
  },

  data () {
    return {
      systemManagers: [],
      systemManagerInvitations: [],
      showSystemManagerInvitationCreateModal: false,
      editTargetManager: null,
      showSystemManagerEditModal: false,
      showSystemManagerEditPassModal: false,
      showSystemManagerMailModal: false,
      editMailTargetManager: null
    }
  },

  mounted () {
    this.loadManagers()
    this.loadSystemManagerInvitations()
  },

  methods: {
    loadManagers () {
      SystemManager.api().fetchList().then(res => {
        this.systemManagers = res.system_managers
      }).catch(err => {
        console.log(err)
      })
    },

    loadSystemManagerInvitations () {
      SystemManagerInvitationToken.fetchList().then(res => {
        this.systemManagerInvitations = res.system_manager_invitation_tokens
      }).catch(err => {
        console.log(err)
      })
    },

    state (manager) {
      if (manager.deleted) {
        return '凍結中'
      } else {
        return '正常'
      }
    },

    newInvitation () {
      this.showSystemManagerInvitationCreateModal = true
    },

    cancelCreateInvitation (callback) {
      this.showSystemManagerInvitationCreateModal = false
      callback()
    },

    createInvitation (formData, callback) {
      SystemManagerInvitationToken.create({ formData: formData }).then(() => {
        this.loadSystemManagerInvitations()
        this.showSystemManagerCreateModal = false
        this.showNotifyDialog('新規システム管理者案内作成完了', '新規システム管理者の案内作成が完了しました。\n入力されたメールアドレス宛に案内メールを送信しています。\n案内に従ってアカウントを作成させてください。')
        callback()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('新規システム管理者案内作成失敗', '新規システム管理者案内の作成に失敗しました。入力内容を確認してください。')
      })
    },

    sendInvitation (invitation) {
      SystemManagerInvitationToken.sendMail({ targetID: invitation.id }).then(() => {
        this.loadSystemManagerInvitations()
        this.showNotifyDialog('システム管理者案内再送信完了', 'システム管理者の案内メールを再送信し、期限をリセットしました。')
      }).catch(err => {
        console.error(err.response)
        this.showNotifyDialog('システム管理者案内再送信失敗', 'システム管理者案内再送信に失敗しました。')
      })
    },

    destroyInvitation (invitation) {
      SystemManagerInvitationToken.destroy({ targetID: invitation.id }).then(() => {
        this.loadSystemManagerInvitations()
        this.showNotifyDialog('システム管理者案内削除', 'システム管理者案内を削除しました。')
      }).catch(err => {
        console.error(err.response)
        this.showNotifyDialog('システム管理者案内削除失敗', 'システム管理者案内の削除に失敗しました。')
      })
    },

    editManager (manager) {
      this.editTargetManager = manager
      this.showSystemManagerEditModal = true
    },

    cancelUpdateManager (callback) {
      this.editTargetManager = null
      this.showSystemManagerEditModal = false
      callback()
    },

    updateManager (formData, callback) {
      SystemManager.api().update({ formData: formData }).then(() => {
        this.loadManagers()
        this.editTargetManager = null
        this.showSystemManagerEditModal = false
        callback()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('システム管理者更新失敗', 'システム管理者の更新に失敗しました。入力内容を確認してください。')
      })
    },

    freeze (manager) {
      if (this.systemManager.id !== manager.id) {
        SystemManager.api().delete({ targetID: manager.id }).then(() => {
          this.loadManagers()
          this.showNotifyDialog('システム管理者凍結', 'システム管理者を凍結しました。\n凍結解除するまで、このシステム管理者アカウントは使用できません。')
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('システム管理者凍結失敗', 'システム管理者の凍結に失敗しました。')
        })
      }
    },

    defrost (manager) {
      SystemManager.api().unlock({ targetID: manager.id }).then(() => {
        this.loadManagers()
        this.showNotifyDialog('システム管理者凍結解除', 'システム管理者を凍結解除しました。\nこのシステム管理者アカウントは再び使用出来るようになりました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('システム管理者凍結解除失敗', 'システム管理者の凍結解除に失敗しました。')
      })
    },

    editPassword () {
      this.showSystemManagerEditPassModal = true
    },

    cancelUpdatePassword (callback) {
      this.showSystemManagerEditPassModal = false
      callback()
    },

    updatePassword (formData) {
      SystemManager.api().updatePassword({ formData: formData }).then(() => {
        this.showNotifyDialog('パスワード更新完了', 'パスワードを更新しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('パスワード更新失敗', 'パスワードの更新に失敗しました。')
      })
      this.showSystemManagerEditPassModal = false
    },

    editMail (manager) {
      this.editMailTargetManager = manager
      this.showSystemManagerMailModal = true
    },

    cancelUpdateMail (callback) {
      this.showSystemManagerMailModal = false
      this.editMailTargetManager = null
      callback()
    },

    reserveUpdateMail (formData, callback) {
      SystemManager.api().changeMail({ formData: formData }).then(() => {
        this.showNotifyDialog('メール変更予約完了', 'メールの変更を受け付けました。入力されたメールアドレスに疎通確認を送信しました。受信したメール本文のリンクにアクセスしてメールアドレスの変更を確定してください。')
        this.showSystemManagerMailModal = false
        this.editMailTargetManager = null
        callback()
      }).catch(err => {
        console.error(err)
      })
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-manager-view {
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  .manager-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        justify-content: space-between;
        .label {
          margin-right: 10px;
          font-weight: bold;
          font-size: 12px;
        }
      }
      .controll {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0;
        button {
          margin-left: 10px;
          border: 1px solid #039;
          border-radius: 3px;
          background-color: #039;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
