<template>
  <div class="horus-reporter-related-user-in-manage-list labeled_content_block">
    <div class="labeled_content_single_block">
      <label>施設利用者名</label>
      <div class="content">
        <img :src="user.author.portrateSrc" class="portrate" />
        {{ user.author.name }}
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>生年月日(年齢):性別</label>
      <div class="content">{{ user.author.birthDateJp }}({{ user.author.age }}):{{ user.author.sexTypeJp }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ user.author.address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ user.author.tel }}</div>
    </div>
    <div class="content-controll-block">
      <button class="remove-member-btn" @click="removeRelation">利用停止</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusReporterRelatedUserInManageList',

  components: {},

  props: {
    user: Object,
    reporterOrganization: Object
  },

  computed: {
    userAssignedAt () {
      var assignedAt = new Date(this.user.assigned_at)
      if (assignedAt) {
        return moment(assignedAt).format('YYYY/MM/DD HH:mm')
      }
      return ''
    }
  },

  methods: {
    removeRelation () {
      this.$emit('removeRelationEvent', this.user)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
