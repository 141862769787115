import axios from './AxiosInstance'

export default {
  fetch: ({targetUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_invitation_for_organization/list',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_invitation_for_organizations: res.data.reporter_organization_invitation_for_organizations })
        }
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'reporter_organization_invitation_for_organization',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({targetUserID, targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'reporter_organization_invitation_for_organization',
            withCredentials: true,
            params: { target_user_id: targetUserID, reporter_organization_invitation_for_organization_id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchReceiveList: ({reporterOrganizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_invitation_for_organization/receive_list',
        withCredentials: true,
        params: { reporter_organization_id: reporterOrganizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_invitation_for_organizations: res.data.reporter_organization_invitation_for_organizations })
        }
      }).catch(error => { reject(error) })
    })
  },

  admit: ({reporterOrganizationID, targetID}) => {
    var formData = new FormData()
    formData.append('reporter_organization_id', reporterOrganizationID)
    formData.append('reporter_organization_invitation_for_organization_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_invitation_for_organization/admit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reject: ({reporterOrganizationID, targetID}) => {
    var formData = new FormData()
    formData.append('reporter_organization_id', reporterOrganizationID)
    formData.append('reporter_organization_invitation_for_organization_id', targetID)
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_invitation_for_organization/reject',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
