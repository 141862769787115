<template>
  <div class="horus-deputized-user-in-manage-list labeled_content_block">
    <div class="labeled_content_single_block">
      <label>設定代行開始日</label>
      <div class="content">{{ deputizeRelationAssignedAt }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>利用者名(NBID)</label>
      <div class="content">
        <img :src="deputizeRelation.author.portrateSrc" class="portrate" />
        {{ deputizeRelation.author.name }}({{ deputizeRelation.nbid }})
      </div>
    </div>
    <div class="labeled_content_single_block">
      <label>生年月日(年齢):性別</label>
      <div class="content">{{ deputizeRelation.author.birthDateJp }}({{ deputizeRelation.author.age }}):{{ deputizeRelation.author.sexTypeJp }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ deputizeRelation.author.address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ deputizeRelation.author.tel }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>同意書</label>
      <div class="content">
        <div class="document-display" v-show="documentExist && !showUploadForm">
          {{ documentCreatedAt }}
          <a :href="documentDownloadURL" target="_blank"><button class="download-btn">ダウンロード</button></a>
          <label :for="'fileInputUpload' + deputizeRelation.author_id">
            <span class="document-update-btn" @click="selectDocument">更新</span>
          </label>
        </div>
        <div class="document-upload" v-show="!documentExist && !showUploadForm">
          存在しません。
          <label :for="'fileInputUpload' + deputizeRelation.author_id">
            <span class="document-create-btn" @click="selectDocument">新規追加</span>
          </label>
        </div>
        <input type="file" :id="'fileInputUpload' + deputizeRelation.author_id" style="display: none;cursor: pointer;" ref="fileInput" @change="postFileChange" />
        <div class="upload-document-form" v-show="showUploadForm">
          <label :for="'fileInputUpload' + deputizeRelation.author_id">
            <span class="selected-filename">{{ uploadFilename }}</span>
          </label>
          <button class="upload-btn" @click="uploadDocument">アップロード</button>
          <button class="upload-cancel-btn" @click="cancelUpload">キャンセル</button>
        </div>
      </div>
    </div>
    <div class="content-controll-block">
      <span class="small-caption" v-if="!deputizeRelation.removable">この利用者は利用停止出来ません。利用停止するには、自らログイン出来るように設定するか、他の設定代行施設を登録する必要があります。</span>
      <button class="remove-user-btn" :class="{disable: !deputizeRelation.removable}" @click="removeRelation">利用停止</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusDeputizedUserInManageList',

  components: {
  },

  props: {
    deputizeRelation: Object,
    organization: Object
  },

  data () {
    return {
      showUploadForm: false,
      uploadFile: {}
    }
  },

  computed: {
    deputizeRelationAssignedAt () {
      if (this.deputizeRelation.created_at) {
        return moment(this.deputizeRelation.created_at).format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    },

    documentExist () {
      if (this.deputizeRelation.document_id) {
        return true
      } else {
        return false
      }
    },

    documentCreatedAt () {
      if (this.deputizeRelation.document_created_at) {
        return moment(this.deputizeRelation.document_created_at).format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    },

    documentDownloadURL () {
      if (this.deputizeRelation.document_id) {
        return process.env.BASE_URL + '/deputized_user_creation_agreement/?organization_id=' + this.organization.id + '&subject_user_id=' + this.deputizeRelation.user_id
      }
      return null
    },

    uploadFilename () {
      if (this.uploadFile.name) {
        return this.uploadFile.name
      } else {
        return ''
      }
    }
  },

  methods: {
    selectDocument () {
      this.showUploadForm = true
      // this.$nextTick(() => {
      //   this.$refs.fileInput.click()
      // })
    },

    postFileChange () {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.uploadFile = {
          uploadFile: selectedFiles[0],
          name: selectedFiles[0].name
        }
      }
    },

    cancelUpload () {
      this.showUploadForm = false
    },

    uploadDocument () {
      if (this.uploadFile.uploadFile) {
        this.showUploadForm = false
        this.$emit('updateAgreementEvent', this.deputizeRelation, this.uploadFile)
      }
    },

    removeRelation () {
      this.$emit('removeDeputizeEvent', this.deputizeRelation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';

.horus-deputized-user-in-manage-list {
  button {
    cursor: pointer;
    margin: 3px 10px;
    padding: 3px 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-weight: bold;
  }
  .small-caption {
    font-size: 11px;
    color: #6c272d;
  }
  .document-update-btn {
    cursor: pointer;
    margin: 3px 10px;
    padding: 3px 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-weight: bold;
  }
  .document-create-btn {
    cursor: pointer;
    margin: 3px 10px;
    padding: 3px 10px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #666;
    color: #fff;
    font-weight: bold;
  }
  .remove-user-btn {
    width: 90px;
    &.disable {
      border-color: #666;
      background-color: #ddd;
      color: #999;
    }
  }
  .upload-document-form {
    .selected-filename {
      display: inline-block;
      min-width: 100px;
      border: 1px solid #aaa;
      padding: 3px 5px;
    }
    .upload-cancel-btn {
      background-color: #fff;
      color: #666;
    }
  }
}
</style>
