<template>
  <div class="horus-info-content-form-modal" v-if="showInfoContentFromModal">
    <horus-modal-frame
      :showModalFlag="showInfoContentFromModal">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/info_icon.png" v-if="type == 'boadComment'" />
          <img src="/static/images/wish_icon.png" v-if="type == 'wish'" />
          {{ title }}
        </div>
      </div><!-- header end -->
      <div class="modal-body">
        <div class="input-single">
          <div class="attr-title">タイトル</div>
          <input type="text" v-model="infoContent.title" class="title-input" />
        </div>
        <div class="input-single">
          <div class="attr-title">{{ contentTitleText }}</div>
          <textarea placeholder="ここに書いてください" class="content-input" rows="1" ref="textCommentField" v-model="infoContent.content" />
        </div>
        <div class="input-single" v-if="targetInfoContent">
          <div class="uploaded-photos" v-if="targetInfoContent.photos.length > 0">
            <div class="attr-title">アップロード済写真</div>
            <div class="uploaded-photo-thumbnails">
              <div class="uploaded-photo-thumbnail"
                v-for="infoContentPhoto in targetInfoContent.photos"
                :key="'infoContentPhotoForm' + infoContentPhoto.id">
                <img :src="infoContentPhoto.photoSrc" />
                <div class="uploaded-photo-controll" @click="togglePhotoRemove(infoContentPhoto.id)">
                  <horus-custom-check-box :type="'small'" :active="photoGonnaRemove(infoContentPhoto.id)" :enabled="true" />この写真は削除する
                </div>
              </div>
            </div>
          </div>
        </div><!-- uploaded phtoo end -->
        <div class="input-single" v-if="targetInfoContent">
          <div class="file-reports" v-if="targetInfoContent.files.length > 0">
            <div class="attr-title">アップロード済ファイル</div>
            <div class="uploaded-file-list">
              <div class="uploaded-file"
                v-for="infoContentFile in targetInfoContent.files"
                :key="'infoContentFileForm' + infoContentFile.id">
                <img src="/static/images/document_icon.png" />
                <div class="uploaded-filename">{{ infoContentFile.filename }}</div>
                <div class="uploaded-file-controll" @click="toggleFileRemove(infoContentFile.id)">
                  <horus-custom-check-box :type="'small'" :active="fileGonnaRemove(infoContentFile.id)" :enabled="true" />このファイルは削除する
                </div>
              </div>
            </div>
          </div>
        </div><!-- uploaded file end -->
        <horus-multi-photo-selector
          ref="multiPhotoSelector"
          :photos="infoContent.photos"
          :photoInputID="photoInputID"
          v-on:newPhotoPreparedEvnt="addSelectedPhoto"
          v-on:photoRemoveEvent="removePhoto"
          />
        <horus-multi-file-selector
          ref="multiFileSelector"
          :files="infoContent.files"
          :fileInputID="fileInputID"
          :displaySize="''"
          v-on:newFilePreparedEvent="addSelectedFile"
          v-on:fileRemoveEvent="removeFile"
          />
        <div class="post-controlls">
          <div class="add_icons">
            <div class="icon-button">
              <label :for="photoInputID" @click="addPhotoForPost">
                <img src="/static/images/camera_icon.png" class="add-photo"/>
              </label>
            </div>
            <div class="icon-button">
              <label :for="fileInputID" @click="addFileForPost">
                <img src="/static/images/document_icon.png" class="add-file"/>
              </label>
            </div>
            <div class="icon-button">
              <img src="/static/images/tag_icon.png" class="add-tag" @click="selectTagForPost">
            </div>
          </div>
          <div class="hash_tags">
            <div class="hash-tag"
              v-for="hashTag in selectedTags"
              :key="'infoContentSelectedTag' + hashTag.id"
              @click="removeSelectedTag(hashTag)">
              <horus-hash-tag-display
                :hashTag="hashTag"
                :sizeType="'small'" />
              <img src="/static/images/x_dark_icon.png" class="close-icon" />
            </div>
            <div class="new-hash-tag"
              v-for="(newTag, index) in createTags"
              :key="'infoContentCreatedTag' + index"
              @click="removeCreateTag(index)">
              <img src="/static/images/tag_icon_green.png" class="new-tag-icon" />
              <span v-html="modifiedTagName(newTag)"></span>
              <img src="/static/images/x_dark_icon.png" class="close-icon" />
            </div>
          </div><!-- selected hashtags end -->
          <div class="execute_controlls">
            <div class="executable_buttons">
              <button type="button" class="record_post_clear" @click="clearPostForm">キャンセル</button>
              <button type="button" class="record_post_submit" @click="submitPostForm">{{ submitText }}</button>
            </div>
          </div><!-- execute controll end -->
        </div><!-- post controll end -->
      </div><!-- body end -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'
import HorusMultiFileSelector from '@/components/common/HorusMultiFileSelector.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'

export default {
  name: 'HorusInfoContentFomrModal',

  components: {
    HorusModalFrame,
    HorusMultiPhotoSelector,
    HorusMultiFileSelector,
    HorusCustomCheckBox,
    HorusHashTagDisplay
  },

  props: {
    showInfoContentFromModal: Boolean,
    timelineOwner: Object,
    targetInfoContent: Object,
    type: String,
    selectedTags: Array,
    createTags: Array
  },

  data () {
    return {
      photoInputID: 'photoInputInfoContent',
      fileInputID: 'fileInputInfoContent',
      infoContent: {},
      removePhotos: [],
      removeFiles: []
    }
  },

  created () {
    this.initForm()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
  },

  watch: {
    targetInfoContent: { handler: 'initInfoContent', immediate: true },
    'infoContent.content': { handler: 'fixTextCommentArea' }
  },

  computed: {
    title () {
      if (this.targetInfoContent) {
        if (this.type === 'wish') {
          return 'wishの編集'
        } else if (this.type === 'boadComment') {
          return 'infoの編集'
        } else {
          return '編集'
        }
      } else {
        if (this.type === 'wish') {
          return 'wishの新規作成'
        } else if (this.type === 'boadComment') {
          return 'infoの新規作成'
        } else {
          return '新規作成'
        }
      }
    },

    contentTitleText () {
      if (this.type === 'wish') {
        return '夢や希望、目標を書いてください'
      } else if (this.type === 'boadComment') {
        return '本文'
      } else {
        return '本文'
      }
    },

    submitText () {
      if (this.targetInfoContent) {
        return '更　　新'
      } else {
        return '新規作成'
      }
    }
  },

  methods: {
    initForm () {
      this.photoInputID = 'photoInputInfoContent' + Math.floor(Math.random() * 10000)
      this.fileInputID = 'fileInputInfoContent' + Math.floor(Math.random() * 10000)
    },

    initInfoContent () {
      if (this.targetInfoContent) {
        this.infoContent = {
          title: this.targetInfoContent.title,
          content: this.targetInfoContent.content,
          photos: [],
          files: []
        }
      } else {
        this.infoContent = {
          title: '',
          content: '',
          photos: [],
          files: []
        }
      }
    },

    fixTextCommentArea () {
      this.HorusHashTagDisplay = false
      this.$nextTick(() => {
        var textArea = this.$refs.textCommentField
        if (textArea) {
          // 一度高さをリセットしないと、scrollHeightが正しく取得出来ない。ただし、ちょっとちらついてしまう場合がある
          textArea.style.height = '26px'
          if (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = textArea.scrollHeight + 'px'
          }
        }
      })
    },

    photoGonnaRemove (infoContentPhotoID) {
      return this.removePhotos.some(pID => pID === infoContentPhotoID)
    },

    togglePhotoRemove (infoContentPhotoID) {
      var removeIndex = this.removePhotos.indexOf(infoContentPhotoID)
      if (removeIndex < 0) {
        this.removePhotos.push(infoContentPhotoID)
      } else {
        this.removePhotos.splice(removeIndex, 1)
      }
    },

    fileGonnaRemove (infoContentFileID) {
      return this.removeFiles.some(pID => pID === infoContentFileID)
    },

    toggleFileRemove (infoContentFileID) {
      var removeIndex = this.removeFiles.indexOf(infoContentFileID)
      if (removeIndex < 0) {
        this.removeFiles.push(infoContentFileID)
      } else {
        this.removeFiles.splice(removeIndex, 1)
      }
    },

    addPhotoForPost () {
    //   this.$nextTick(() => {
    //     this.$refs.multiPhotoSelector.addPhotoForPost()
    //   })
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    addSelectedPhoto (photoObj) {
      this.infoContent.photos.push(photoObj)
    },

    removePhoto (index) {
      this.infoContent.photos.splice(index, 1)
    },

    addFileForPost () {
    //   this.$nextTick(() => {
    //     this.$refs.multiFileSelector.addFileForPost()
    //   })
      if (this.$native.status) {
        this.$native.event('takeFileEvent', { targetID: this.fileInputID })
      }
    },

    addSelectedFile (fileObj) {
      this.infoContent.files.push(fileObj)
    },

    removeFile (index) {
      this.infoContent.files.splice(index, 1)
    },

    modifiedTagName (newTag) {
      const splited = newTag.split('##')
      if (splited.length > 1) {
        return '<span class="hash_tag_type ' + splited[0] + '">' + splited[0] + '</span>' + splited[1]
      } else {
        return splited[0]
      }
    },

    selectTagForPost () {
      this.$emit('openTagSelectionModalEvent')
    },

    removeSelectedTag (tag) {
      this.$emit('removeSelectedTagEvent', tag)
    },

    removeCreateTag (index) {
      this.$emit('removeCreateTagEvent', index)
    },

    executeClearForm () {
      this.infoContent = {
        title: '',
        content: '',
        photos: [],
        files: []
      }
      this.removePhotos = []
      this.removeFiles = []
    },

    clearPostForm () {
      // 内容の破棄は親に任せる
      this.$emit('postformClearEvent', this.executeClearForm)
    },

    submitPostForm () {
      if (this.infoContent.title.length <= 0) {
        this.$emit('showNotifyEvent', '内容のエラー', 'タイトルは入力必須です。\n何らかの内容を入力してください。')
        return
      }
      // CREATE FORM DATA
      var typeStr = ''
      if (this.type === 'wish') {
        typeStr = 'wish'
      } else if (this.type === 'boadComment') {
        typeStr = 'boad_comment'
      }
      var formData = new FormData()
      formData.append('target_user_id', this.timelineOwner.id)
      if (this.targetInfoContent) {
        formData.append(typeStr + '_id', this.targetInfoContent.id)
        this.removePhotos.forEach(removePhoto => {
          formData.append(typeStr + '[remove_photos][]', removePhoto)
        })
        this.removeFiles.forEach(removeFile => {
          formData.append(typeStr + '[remove_files][]', removeFile)
        })
      }
      formData.append(typeStr + '[title]', this.infoContent.title)
      formData.append(typeStr + '[content]', this.infoContent.content)
      this.infoContent.photos.forEach(photoObj => {
        formData.append(typeStr + '[photos][]', photoObj.uploadFile, photoObj.name)
      })
      this.infoContent.files.forEach(fileObj => {
        formData.append(typeStr + '[files][]', fileObj.uploadFile, fileObj.name)
      })
      this.selectedTags.forEach(tag => {
        formData.append(typeStr + '[hashtags][]', tag.id)
      })
      this.createTags.forEach(newTag => {
        formData.append(typeStr + '[new_hashtags][]', newTag)
      })
      this.$emit('submitInfoContentFormEvent', formData, this.executeClearForm)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-info-content-form-modal {
  .horus-modal-frame .modal {
    max-height: calc(100vh - 50px);
  }
  .modal-header {
    height: 42px;
    .title {
      padding: 10px;
      border-bottom: 1px solid #ddd;
      text-align: center;
      font-weight: bold;
      img {
        margin-right: 10px;
        width: 10px;
      }
    }
  }
  .modal-body {
    padding: 10px 20px;
    height: calc(100% - 42px - 20px);
    overflow: scroll;
    .input-single {
      margin-bottom: 10px;
      .attr-title {
        margin: 5px 0;
        font-size: 16px;
        font-weight: bold;
      }
      .title-input {
        width: 100%;
        font-size: 16px;
      }
      .content-input {
        width: 100%;
        border: 1px solid #aaa;
        resize: none;
        font-size: 16px;
      }
    }
    .uploaded-photo-thumbnails {
      display: flex;
      flex-wrap: wrap;
      .uploaded-photo-thumbnail {
        margin: 0 10px 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
        text-align: center;
        img {
          width: 100px;
        }
        .photo-in-timeline {
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
    .uploaded-file-list {
      display: flex;
      flex-wrap: wrap;
      .uploaded-file {
        margin: 0 10px 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
        text-align: center;
        .uploaded-filename {
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
  .post-controlls {
    .add_icons {
      display: flex;
      border-top: 1px solid #aaa;
      padding-top: 10px;
      input {
        display: none;
      }
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
      }
    }
    .hash_tags {
      display: flex;
      flex-wrap: wrap;
      .hash-tag {
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
        .close-icon {
          margin-left: -5px;
          width: 10px;
          vertical-align: middle;
        }
      }
      .new-hash-tag {
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
        .new-tag-icon {
          width: 10px;
        }
        .close-icon {
          width: 10px;
        }
        span {
          font-size: 0.7em;
        }
      }
    }
  }
  .execute_controlls {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .executable_buttons {
      button {
        margin-left: 20px;
        padding: 3px 10px;
        width: 100px;
        font-size: 14px;
        &.record_post_clear {
          background-color: #fff;
          color: #333;
          border: 1px solid #999;
        }
        &.record_post_submit {
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
        }
      }
    }
  }
}
</style>
