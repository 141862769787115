<template>
  <div class="horus-create-reporter-organization-request-view container">
    <div class="navbar">
      <div class="main-bar reporter">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon">
      </div>
    </div><!-- nav bar -->
    <div class="content">
      <h1 class="centering-title">ナラティブブック報告施設新規登録</h1>
      <horus-create-reporter-organization-request-step-bar
        :step="step" />
      <horus-create-reporter-organization-request-area-block-column
        v-if="areaBlock"
        :areaBlock="areaBlock"
        />
      <horus-create-reporter-organization-request-entry
        v-if="pageState === STEP.ENTRY"
        v-on:sendMailEvent="sendMail"
        />
      <horus-create-reporter-organization-request-mail-registed
        v-if="pageState === STEP.CONFIRM_MAIL_SENT"
        :mailAddress="mailAddress"
        />
      <horus-create-reporter-organization-request-mail-confirmed
        v-if="pageState === STEP.MAIL_CONFIRMED"
        :mailAddress="mailAddress"
        v-on:inputProfileEvent="inputProfiles"
        v-on:sendMailEvent="sendMail"
        />
      <horus-create-reporter-organization-request-mail-confirm-failed
        v-if="pageState === STEP.MAIL_CONFIRM_FAILED"
        v-on:backToEntryEvent="backToEntry"
        />
      <horus-create-reporter-organization-request-input-profile
        v-if="pageState === STEP.PROFILE_INPUT"
        :validateToken="token"
        v-on:showConfirmDialogEvent="showConfirmDialog"
        v-on:submitFinishEvent="submitOrganizationRequest"
        />
      <horus-create-reporter-organization-request-finish
        v-if="pageState === STEP.FINISH"
        :requestReporterOrganization="resultRequest"
        :message="finishedMessage"
        :notifyMessage="finishedNotifyMessage"
        />
    </div><!-- content -->
    <horus-footer />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div><!-- container -->
</template>

<script>
import { AreaBlock } from '@/models'
import { ReporterOrganizationRequest } from '@/api'

import HorusCreateReporterOrganizationRequestStepBar from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestStepBar.vue'
import HorusCreateReporterOrganizationRequestAreaBlockColumn from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestAreaBlockColumn.vue'
import HorusCreateReporterOrganizationRequestEntry from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestEntry.vue'
import HorusCreateReporterOrganizationRequestMailRegisted from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestMailRegisted.vue'
import HorusCreateReporterOrganizationRequestMailConfirmed from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestMailConfirmed.vue'
import HorusCreateReporterOrganizationRequestMailConfirmFailed from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestMailConfirmFailed.vue'
import HorusCreateReporterOrganizationRequestInputProfile from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestInputProfile.vue'
import HorusCreateReporterOrganizationRequestFinish from '@/components/createReporterOrganizationRequest/HorusCreateReporterOrganizationRequestFinish.vue'

import HorusFooter from '@/components/common/HorusFooter.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import * as CREATE_STEP from '@/assets/constants/createUserStep.js'
// import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusCreateReporterOrganizationRequestView',

  components: {
    HorusCreateReporterOrganizationRequestStepBar,
    HorusCreateReporterOrganizationRequestAreaBlockColumn,
    HorusCreateReporterOrganizationRequestEntry,
    HorusCreateReporterOrganizationRequestMailRegisted,
    HorusCreateReporterOrganizationRequestMailConfirmed,
    HorusCreateReporterOrganizationRequestMailConfirmFailed,
    HorusCreateReporterOrganizationRequestInputProfile,
    HorusCreateReporterOrganizationRequestFinish,
    HorusFooter,
    HorusConfirmDialog
  },

  porps: {
  },

  watch: {
    '$route': { handler: 'validateToken', immediate: true }
  },

  data () {
    return {
      token: '',
      STEP: CREATE_STEP,
      pageState: 0,
      step: '',
      mailAddress: null,
      reporterOrganizationRequestID: '',
      areaBlock: null,
      csrfToken: null,
      createdReporterOrganizationName: '',
      finishedMessage: '',
      finishedNotifyMessage: '',
      finishedOn: '',
      resultRequest: null
    }
  },

  computed: {
    // additionalStatusMessage () {
    //   if (this.tokenType === 'organization_manager') {
    //     return '利用者作成完了後、施設管理者となります。'
    //   } else if (this.tokenType === 'organization_member') {
    //     return '利用者作成完了後、施設職員となります。'
    //   } else if (this.tokenType === 'family') {
    //     return '利用者作成完了後、家族が設定されます。'
    //   } else if (this.tokenType === 'relation') {
    //     return '利用者作成完了後、かかりつけ施設が設定されます。'
    //   } else {
    //     return ''
    //   }
    // },

    // passwordInputType () {
    //   if (this.passwordVisible) {
    //     return 'text'
    //   }
    //   return 'password'
    // }
  },

  methods: {
    sendMail (mail) {
      if (mail && mail.length > 0) {
        var areaBlockID = null
        if (this.areaBlock) {
          areaBlockID = this.areaBlock.id
        }
        ReporterOrganizationRequest.preMailValidation({ mailAddress: mail, reporterOrganizationRequestID: this.reporterOrganizationRequestID, areaBlockID: areaBlockID }).then(() => {
          this.mailAddress = mail
          this.pageState = this.STEP.CONFIRM_MAIL_SENT
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('確認メール送信の失敗', '入力されたメールアドレスへの確認メール送信に失敗しました。')
        })
      } else {
        this.showNotifyDialog('メールアドレスの入力', '報告施設登録に利用するメールアドレスを入力してください。')
      }
    },

    validateToken () {
      this.token = this.$route.query.token
      if (this.$route.query.mail) {
        this.mailAddress = this.$route.query.mail
      }
      if (this.$route.query.area_block_id) {
        AreaBlock.api().fetch({ targetID: this.$route.query.area_block_id }).then(res => {
          this.areaBlock = res.area_block
        })
      }
      if (this.token && this.token.length > 0) {
        this.pageState = this.STEP.RECEIVE_CONFIRM_TOKEN
        this.step = this.STEP.PAGE_STEP_CONFIRM_MAIL
        ReporterOrganizationRequest.confirmMail({ validationToken: this.token })
        .then(res => {
          this.reporterOrganizationRequestID = res.reporter_organization_request.reporter_organization_request_id
          this.mailAddress = res.reporter_organization_request.mail
          return res.reporter_organization_request
        })
        .then(reporter_organization_request => {
          if (reporter_organization_request.area_block_id) {
            AreaBlock.api().fetch({ targetID: reporter_organization_request.area_block_id }).then(res => {
              this.areaBlock = res.area_block
            })
          }
          this.pageState = this.STEP.MAIL_CONFIRMED
          this.step = this.STEP.PAGE_STEP_CONFIRM_MAIL
        }).catch(error => {
          console.log(error.response)
          if (error.response.status === 400) {
            this.pageState = this.STEP.MAIL_CONFIRM_FAILED
          }
        })
      } else {
        this.pageState = this.STEP.ENTRY
        this.step = this.STEP.PAGE_STEP_CHECK_MAIL
      }
    },

    backToEntry () {
      this.mailAddress = ''
      this.token = null
      this.reporterOrganizationRequestID = ''
      this.pageState = this.STEP.ENTRY
      this.step = this.STEP.PAGE_STEP_CHECK_MAIL
    },

    inputProfiles () {
      this.pageState = this.STEP.PROFILE_INPUT
      this.step = this.STEP.PAGE_STEP_INPUT_PROFILE
    },

    submitOrganizationRequest (resultObjct) {
      // 結果を表示する。
      this.resultRequest = resultObjct
      this.pageState = this.STEP.FINISH
      this.step = this.STEP.PAGE_STEP_FINISH
    },

    finishNarrativeBook () {
      // signout
      this.$router.push('/signout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-create-reporter-organization-request-view {
  height: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  .content {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1.centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.1em;
      font-weight: normal;
    }
  }
}
</style>
