<template>
  <div class="horus-create-reporter-member-invitation-modal">
    <horus-modal-frame
      :showModalFlag="showCreateMemberInvitationModal">
      <div class="modal-header">
        <h1>報告施設職員の案内状作成</h1>
      </div>
      <div class="modal-body" v-if="reporterOrganization">
        <horus-attribute-in-form
          :labelText="'職員名(宛名)'"
          :necessary="true"
          :validationState="targetNameState"
          :validationMessage="targetNameNotify"
          :attributeNotify="'送信されるメールに表示される宛名です。'">
          <input type="text" v-model="targetName" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="targetMailState"
          :validationMessage="targetMailNotify"
          :attributeNotify="''">
          <input type="text" v-model="targetMail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス(確認)'"
          :necessary="true"
          :validationState="targetMailConfirmationState"
          :validationMessage="targetMailConfirmationNotify"
          :attributeNotify="''">
          <input type="text" v-model="targetMailConfirmation" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'本人確認用コード(数字4桁:空白の場合自動生成)'"
          :necessary="false"
          :validationState="targetShortTokenState"
          :validationMessage="targetShortTokenNotify"
          :attributeNotify="'※空白の場合は自動生成 ※2段階認証のため、このコードは本人にのみ伝えてください'">
          <input type="text" v-model="targetShortToken" />
        </horus-attribute-in-form>
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendInvitation">キャンセル</button>
          <button class="submit-btn" :class="{ disable: !invitationReady }" @click="submitSendInvitation">案内状の送信</button>
        </div>
      </div><!-- modal body end -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
// import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusCreateMemberInvitationModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    // HorusCustomCheckBox
  },

  props: {
    reporterOrganization: Object,
    showCreateMemberInvitationModal: Boolean
  },

  data () {
    return {
      targetName: '',
      targetMail: '',
      targetMailConfirmation: '',
      targetShortToken: ''
    }
  },

  computed: {
    targetNameState () {
      if (this.targetName.length > 0) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    targetNameNotify () {
      if (this.targetName.length <= 0) {
        return '職員名(宛名)は入力必須です'
      } else {
        return ''
      }
    },

    targetMailState () {
      if (this.targetMail.length > 0) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    targetMailNotify () {
      if (this.targetMail.length <= 0) {
        return 'メールアドレスは入力必須です'
      } else {
        return ''
      }
    },

    targetMailConfirmationState () {
      if (this.targetMail.length > 0 && this.targetMail === this.targetMailConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    targetMailConfirmationNotify () {
      if (this.targetMail !== this.targetMailConfirmation) {
        return '入力されたメールアドレスと一致しません'
      } else {
        return ''
      }
    },

    targetShortTokenState () {
      if (this.targetShortToken.length <= 0 || this.targetShortToken.trim().match(/^\d{4}$/g)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    targetShortTokenNotify () {
      if (this.targetShortToken.length > 0 && !this.targetShortToken.trim().match(/^\d{4}$/g)) {
        return '本人確認コードは半角数字4桁で入力ください'
      } else {
        return ''
      }
    },

    invitationReady () {
      if (
        this.targetNameState === 'valid' &&
        this.targetMailState === 'valid' &&
        this.targetMailConfirmationState === 'valid' &&
        this.targetShortTokenState === 'valid'
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initParams () {
      this.targetName = ''
      this.targetMail = ''
      this.targetMailConfirmation = ''
      this.targetShortToken = '0000'
    },

    cancelSendInvitation () {
      this.$emit('cancelSentInvitationEvent', () => { this.initParams() })
    },

    submitSendInvitation () {
      if (this.invitationReady) {
        var formData = new FormData()
        formData.append('reporter_organization_id', this.reporterOrganization.id)
        formData.append('reporter_organization_member_invitation[name]', this.targetName)
        formData.append('reporter_organization_member_invitation[mail]', this.targetMail)
        formData.append('reporter_organization_member_invitation[short_token]', this.targetShortToken)
        this.$emit('submitSentInvitationEvent', formData, () => { this.initParams() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-reporter-member-invitation-modal {
  .modal-body {
    padding: 0 20px;
    background-color: #fefefe;
    input {
      font-size: 16px;
      background-color: #fff;
    }
    .optional-attributes {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #aaa;
      .manager-reserve {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-weight: bold;
      }
    }
    .submit-controll {
      padding-bottom: 20px;
      text-align: right;
      button {
        cursor: pointer;
        border: 1px solid #666;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
