import {
  Author
} from '@/models'
import {
  OrganizationMember
} from '@/api'

export default {

  data () {
    return {
      showMemberSelectModal: false,
      selectTargetOrganization: null,
      organizationMembers: []
      // selectTargetMembers: [] change to selectTargetForAuthor and definition at mixined class.
    }
  },

  watch: {
    selectTargetOrganization: { handler: 'loadOrganizationMembers', immediate: true }
  },

  methods: {
    async loadOrganizationMembers () {
      if (this.selectTargetOrganization) {
        OrganizationMember.fetch({ organizationID: this.selectTargetOrganization.id }).then(async res => {
          var members = res.organization_members
          var unloadAuthors = []
          for(var member of members) {
            if (!Author.find(member.author_id)) {
              unloadAuthors.push(member.author_id)
            }
          }
          await Author.api().fetchByID({ idList: unloadAuthors })
          for(var mem of members) {
            this.organizationMembers.push(Author.find(mem.author_id))
          }
          return members
        })
      }
    },

    openMemberSelectionModal () {
      console.log('openMemberSelectionModal call,')
      this.selectTargetForAuthor = this.taskPushTargets
      this.showMemberSelectModal = true
    },

    closeMemberSelectModal () {
      this.showMemberSelectModal = false
    },

    addSelectTargetMember (author) {
      if (!this.selectTargetForAuthor.some(na => na.id === author.id)) {
        this.selectTargetForAuthor.push(author)
      }
    },

    removeSelectTargetMember (author, index) {
      var authorIndex = index
      if (!index >= 0) {
        authorIndex = this.selectTargetForAuthor.indexOf(author)
      }
      if (authorIndex >= 0) {
        this.selectTargetForAuthor.splice(authorIndex, 1)
        this.updatePushTargetGroupState(author)
      }
    },

    toggleSelectMember (author) {
      const authorIndex = this.selectTargetForAuthor.indexOf(author)
      if (authorIndex >= 0) {
        this.removeSelectTargetMember(author, authorIndex)
      } else {
        this.addSelectTargetMember(author)
      }
    },

    allMembersSelect () {
      this.selectTargetForAuthor.splice(0, this.selectTargetForAuthor.length)
      this.organizationMembers.forEach(member => {
        this.addSelectTargetMember(member)
      })
      this.closeMemberSelectModal()
    },

    clearSelectedMembers () {
      this.selectTargetForAuthor.splice(0, this.selectTargetForAuthor.length)
      this.closeMemberSelectModal()
    }

  }
}
