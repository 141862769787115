<template>
  <div class="horus-drug-entry-edit-modal" v-if="showDrugEntryEditModal">
    <div class="backdrop"></div>
    <div class="modal">
      <div class="modal-header">
        <img src="/static/images/drug_green.png" />お薬情報
      </div>
      <div class="control">
        <button class="clear" type="button" @click="clear">クリア</button>
        <button class="commit" type="button" @click="commit">OK</button>
      </div>
      <div class="modal-body">
        <div class="comment-block">
          <div class="label">コメント</div>
          <textarea class="comment-input" v-model="dEntry.comment"></textarea>
        </div>
        <div class="stored-photo-block" v-if="dEntry.storedPhotos.length > 0">
          <h3>投稿済み写真</h3>
          <div class="uploaded-photo-thumbnails">
            <div class="uploaded-photo-thumbnail"
              v-for="dPhoto in dEntry.storedPhotos"
              :key="'editDrugEntryPhotoThumbnail' + dPhoto.id">
              <horus-inline-photo
                :photoObject="dPhoto"
                :photoMaxSize="100"
                v-on:timeliePhotoClickEvent="showPhoto(dPhoto)" />
              <div class="uploaded-photo-controll" @click="togglePhotoRemove(dPhoto.id)">
                <horus-custom-check-box :type="'small'" :active="photoGonnaRemove(dPhoto.id)" :enabled="true" />この写真は削除する
              </div>
            </div>
          </div>
        </div>
        <div class="photo-block">
          <div class="photo-icon"><label :for="photoInputID" @click="addPhoto"><img src="/static/images/camera_icon.png"></label></div>
          <horus-multi-photo-selector
            ref="multiPhotoSelector"
            :photos="dEntry.photos"
            :photoInputID="photoInputID"
            v-on:newPhotoPreparedEvnt="addSelectedPhoto"
            v-on:photoRemoveEvent="removePhoto"
            />
        </div>
      </div>
    </div><!-- modal end -->
  </div>
</template>

<script>
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'

export default {
  name: 'HorusDrugEntryEditModal',

  components: {
    HorusInlinePhoto,
    HorusCustomCheckBox,
    HorusMultiPhotoSelector
  },

  props: {
    showDrugEntryEditModal: Boolean,
    drugEntry: Object
  },

  data () {
    return {
      photoInputID: 'photoInputDrugEntry',
      dEntry: {
        comment: '',
        photos: [],
        storedPhotos: [],
        removePhotos: []
      }
    }
  },

  created () {
    this.initForm()
  },

  watch: {
    drugEntry: { handler: 'initObjects', immediate: true, deep: true }
  },

  methods: {
    initForm () {
      this.photoInputID = 'photoInputDE' + Math.floor(Math.random() * 10000)
    },

    initObjects () {
      if (this.drugEntry) {
        this.dEntry.comment = this.drugEntry.comment
        // this.dEntry.photos = this.drugEntry.photos
        this.dEntry.photos = Object.assign([], this.drugEntry.photos)
        this.dEntry.storedPhotos = Object.assign([], this.drugEntry.storedPhotos)
        this.dEntry.removePhotos = Object.assign([], this.drugEntry.removePhotos)
      }
    },

    showPhoto (photoObject) {
      this.$emit('showPhotoEvent', photoObject)
    },

    togglePhotoRemove (photoID) {
      const removeIndex = this.dEntry.removePhotos.indexOf(photoID)
      if (removeIndex < 0) {
        this.dEntry.removePhotos.push(photoID)
      } else {
        this.dEntry.removePhotos.splice(removeIndex)
      }
    },

    photoGonnaRemove (photoID) {
      if (this.dEntry.removePhotos.indexOf(photoID) < 0) {
        return false
      } else {
        return true
      }
    },

    addPhoto () {
      // this.$nextTick(() => {
      //   this.$refs.multiPhotoSelector.addPhotoForPost()
      // })
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    addSelectedPhoto (photoObj) {
      this.dEntry.photos.push(photoObj)
    },

    removePhoto (index) {
      this.dEntry.photos.splice(index, 1)
    },

    clear () {
      this.dEntry.comment = ''
      this.dEntry.photos = []
      this.dEntry.storedPhotos = []
      this.dEntry.removePhotos = []
      this.$emit('commitDrugEntryEvent', this.dEntry)
    },

    commit () {
      this.$emit('commitDrugEntryEvent', this.dEntry)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-drug-entry-edit-modal {
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .modal {
    position: absolute;
    width: 600px;
    max-height: calc(100vh - 60px);
    margin: 0;
    padding: 0;
    top: 30px;
    left: calc((100vw - 600px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100vh - 100px);
      left: 5px;
    }
    .modal-header {
      padding: 10px 20px;
      background-color: #f8fcf9;
      color: #316745;
      font-weight: bold;
      font-size: 12px;
      img {
        margin: 0 5px 3px 0;
        width: 15px;
        vertical-align: middle;
      }
    }
    .control {
      padding: 5px 10px;
      text-align: right;
      button {
        cursor: pointer;
        display: inline-block;
        height: 30px;
        width: 100px;
        margin: 0 0 0 10px;
        padding: 5px 20px;
        width: 100px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 10px;
        border-radius: 2px;
      }
      .clear {
        border: 1px solid #aaa;
        background-color: #fff;
        color: #333;
      }
      .commit {
        border: 1px solid #666;
        background-color: #666;
        color: #fff;
        &.disable {
          border: 1px solid #aaa;
          background-color: #aaa;
        }
      }
    }
    .modal-body {
      padding: 10px;
      .comment-block {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        .label {
          font-size: 14px;
          font-weight: bold;
        }
        textarea {
          width: 100%;
          resize: none;
          font-size: 16px;
        }
      }
      .stored-photo-block {
        margin: 10px 0;
        h3 {
          margin: 0;
          margin-bottom: 5px;
          border-bottom: 1px solid #aaa;
          font-size: 14px;
          font-weight: bold;
          color: #999;
        }
        .uploaded-photo-thumbnails {
          display: flex;
          flex-wrap: wrap;
          .uploaded-photo-thumbnail {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 10px 10px 0;
            padding: 10px;
            border: 1px solid #aaa;
            text-align: center;
            justify-content: center;
            .photo-in-timeline {
              margin: 0;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
