import axios from './AxiosInstance'

export default {

  fetchDeputizeAgentIDList: ({clientUserID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'agent_deputize_relation/list_by_client',
        withCredentials: true,
        params: { target_user_id: clientUserID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ deputize_agents: res.data.deputize_agents })
        }
      }).catch(error => { reject(error) })
    })
  },

  fetchDeputizeClientIDList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'agent_deputize_relation/list_by_agent',
        withCredentials: true
      }).then(res => {
        if (res.data.success === true) {
          resolve({ deputize_clients: res.data.deputize_clients })
        }
      }).catch(error => { reject(error) })
    })
  },

  destroyClient: ({clientUserID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
          axios.httpClient({
            ...header,
            ...{
            method: 'delete',
            url: 'agent_deputize_relation/destroy_client',
            withCredentials: true,
            params: { client_user_id: clientUserID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroyAgent: ({targetUserID, agentUserID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'agent_deputize_relation/destroy_agent',
            withCredentials: true,
            params: { target_user_id: targetUserID, agent_user_id: agentUserID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
