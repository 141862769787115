import { createRouter, createWebHistory } from 'vue-router'
// import store from '@/store'
import {
  SigninUser,
  SigninMember,
  SigninReporter,
  SystemManager,
  AreaBlockOperator
} from '../models'

import {
  SigninState,
  Permission,
  SystemManageSigninState,
  AreaBlockOperatorSigninState,
  // AreaBlockOperatorSigninState
} from '../api'

import HorusLandingView from '@/views/HorusLandingView.vue'
import HorusTestAppSigninView from '@/views/submitView/HorusTestAppSigninView.vue'

import HorusPrintMemberInvitationView from '@/components/prints/HorusPrintMemberInvitationView.vue'
import HorusPrintRelationInvitationView from '@/components/prints/HorusPrintRelationInvitationView.vue'
import HorusPrintDeputizeInvitationView from '@/components/prints/HorusPrintDeputizeInvitationView.vue'

import HorusCreateUserView from '@/views/HorusCreateUserView.vue'

import HorusSubmitSystemManagerView from '@/views/submitView/HorusSubmitSystemManagerView.vue'
import HorusSubmitAreaBlockOperatorView from '@/views/submitView/HorusSubmitAreaBlockOperatorView.vue'
import HorusSubmitOrganizationManagerView from '@/views/submitView/HorusSubmitOrganizationManagerView.vue'
import HorusSubmitOrganizationMemberView from '@/views/submitView/HorusSubmitOrganizationMemberView.vue'
import HorusSubmitServiceRelationView from '@/views/submitView/HorusSubmitServiceRelationView.vue'
import HorusSubmitDeputizeRelationView from '@/views/submitView/HorusSubmitDeputizeRelationView.vue'
import HorusSubmitDeputizeRequestForAgent from '@/views/submitView/HorusSubmitDeputizeRequestForAgent.vue'
import HorusSubmitFamilyView from '@/views/submitView/HorusSubmitFamilyView.vue'
import HorusSubmitReporterRelationView from '@/views/submitView/HorusSubmitReporterRelationView.vue'
import HorusSubmitReporterOrganizationManagerView from '@/views/submitView/HorusSubmitReporterOrganizationManagerView.vue'
import HorusSubmitReporterOrganizationMemberView from '@/views/submitView/HorusSubmitReporterOrganizationMemberView.vue'
import HorusSubmitAuthenticationActivateView from '@/views/submitView/HorusSubmitAuthenticationActivateView.vue'

import HorusUserMailValidationView from '@/views/submitView/HorusUserMailValidationView.vue'
import HorusResetPasswordView from '@/views/submitView/HorusResetPasswordView.vue'
import HorusChangePasswordView from '@/views/submitView/HorusChangePasswordView.vue'

import HorusForgotSystemManagerPasswordView from '@/views/submitView/HorusForgotSystemManagerPasswordView.vue'
import HorusResetSystemManagerPasswordView from '@/views/submitView/HorusResetSystemManagerPasswordView.vue'
import HorusSystemManagerMailValidationView from '@/views/submitView/HorusSystemManagerMailValidationView.vue'
import HorusForgotAreaBlockOperatorPasswordView from '@/views/submitView/HorusForgotAreaBlockOperatorPasswordView.vue'
import HorusResetAreaBlockOperatorPasswordView from '@/views/submitView/HorusResetAreaBlockOperatorPasswordView.vue'
import HorusAreaBlockOperatorMailValidationView from '@/views/submitView/HorusAreaBlockOperatorMailValidationView.vue'

import HorusRevokeFinishView from '@/views/HorusRevokeFinishView.vue'

import HorusResolveInvalidMailView from '@/components/criticalNotification/HorusResolveInvalidMailView.vue'
import HorusResolveConfirmAgreementView from '@/components/criticalNotification/HorusResolveConfirmAgreementView.vue'
import HorusBANExcuseView from '@/components/criticalNotification/HorusBANExcuseView.vue'
import HorusResolveCriticalNotificationView from '@/components/criticalNotification/HorusResolveCriticalNotificationView.vue'

import HorusThirdPartyLinkageRequestView from '@/views/submitView/HorusThirdPartyLinkageRequestView.vue'
import HorusThirdPartyLinkageValidateMailView from '@/views/submitView/HorusThirdPartyLinkageValidateMailView.vue'
import HorusPrepareThirdPartyLinkageSigninView from '@/views/submitView/HorusPrepareThirdPartyLinkageSigninView.vue'

import HorusPrepareAppSigninTokenView from '@/views/submitView/HorusPrepareAppSigninTokenView.vue'

import HorusCreateOrganizationRequestView from '@/views/HorusCreateOrganizationRequestView.vue'
import HorusCreateReporterOrganizationRequestView from '@/views/HorusCreateReporterOrganizationRequestView.vue'

import HorusPersonalInformationProtectionPolicy from '@/components/statics/HorusPersonalInformationProtectionPolicy.vue'
import HorusTermOfUse from '@/components/statics/HorusTermOfUse.vue'
import HorusContactFormView from '@/components/statics/HorusContactFormView.vue'
import HorusZoomDocument from '@/components/statics/HorusZoomDocument.vue'

import HorusSystemManagementLandingView from '@/views/manageSystem/HorusSystemManagementLandingView.vue'
import HorusSystemManagementOperatorLandingView from '@/views/manageSystem/HorusSystemManagementOperatorLandingView.vue'

import HorusSystemManagementSignInView from '@/views/manageSystem/HorusSystemManagementSignInView.vue'
import HorusSystemManagementHomeView from '@/views/manageSystem/HorusSystemManagementHomeView.vue'
import HorusSystemManagementOperatorHomeView from '@/views/manageSystem/HorusSystemManagementOperatorHomeView.vue'
import HorusSystemTotalizationView from '@/views/manageSystem/HorusSystemTotalizationView.vue'
import HorusSystemPublicAnnouncementView from '@/views/manageSystem/HorusSystemPublicAnnouncementView.vue'
import HorusManageOrganizationRequest from '@/views/manageSystem/HorusManageOrganizationRequestView.vue'
import HorusSystemManagementOrganizationView from '@/views/manageSystem/HorusSystemManagementOrganizationView.vue'
import HorusManageOrganizationAreaBlockRelationView from '@/views/manageSystem/HorusManageOrganizationAreaBlockRelationView.vue'
import HorusSystemManagementOrganizationTagView from '@/views/manageSystem/HorusSystemManagementOrganizationTagView.vue'
import HorusManageReporterOrganizationRequestView from '@/views/manageSystem/HorusManageReporterOrganizationRequestView.vue'
import HorusSystemManagementReporterOrganizationView from '@/views/manageSystem/HorusSystemManagementReporterOrganizationView.vue'
import HorusManageReporterOrganizationAreaBlockRelationView from '@/views/manageSystem/HorusManageReporterOrganizationAreaBlockRelationView.vue'
import HorusSystemManagerView from '@/views/manageSystem/HorusSystemManagerView.vue'
import HorusSystemManageUserView from '@/views/manageSystem/HorusSystemManageUserView.vue'
import HorusSystemManageArchiveView from '@/views/manageSystem/HorusSystemManageArchiveView.vue'
import HorusSystemManagementThirdPartyLinkageView from '@/views/manageSystem/HorusSystemManagementThirdPartyLinkageView.vue'
import HorusSystemManageStaticIdentifyCodeCategoryView from '@/views/manageSystem/HorusSystemManageStaticIdentifyCodeCategoryView.vue'
import HorusSystemDataManagerView from '@/views/manageSystem/HorusSystemDataManagerView.vue'
import HorusSystemContactMailView from '@/views/manageSystem/HorusSystemContactMailView.vue'
import HorusManageAreaBlock from '@/views/manageSystem/HorusManageAreaBlockView.vue'
import HorusManageAreaBlockDetail from '@/views/manageSystem/HorusManageAreaBlockDetailView.vue'
import HorusManageCriticalNotification from '@/views/manageSystem/HorusManageCriticalNotificationView.vue'

import HorusSignedInView from '@/views/HorusSignedInView.vue'
import HorusMainTimelineView from '@/views/HorusMainTimelineView.vue'
import HorusProfileView from '@/views/HorusProfileView.vue'
import HorusFamilyView from '@/views/HorusFamilyView.vue'
import HorusRelatedOrganizationView from '@/views/HorusRelatedOrganizationView.vue'
import HorusRelatedReporterOrganizationView from '@/views/HorusRelatedReporterOrganizationView.vue'
import HorusAgentConfigView from '@/views/HorusAgentConfigView.vue'
import HorusAgentView from '@/views/HorusAgentView.vue'
import HorusPrivateConfigView from '@/views/HorusPrivateConfigView.vue'
import HorusUserTaskView from '@/views/HorusUserTaskView.vue'
import HorusManageDeletedRecordsView from '@/views/HorusManageDeletedRecordsView.vue'

import HorusMemberPortalView from '@/views/HorusMemberPortalView.vue'
import HorusMemberPortalConfigView from '@/views/HorusMemberPortalConfigView.vue'
import HorusMemberProfilePersonalView from '@/views/HorusMemberProfilePersonalView.vue'
import HorusMemberProfileConfigureView from '@/views/HorusMemberProfileConfigureView.vue'

import HorusManageOrganizationBasicView from '@/views/manageOrganization/HorusManageOrganizationBasicView.vue'
import HorusManageOrganizationMemberView from '@/views/manageOrganization/HorusManageOrganizationMemberView.vue'
import HorusManageOrganizationRelationView from '@/views/manageOrganization/HorusManageOrganizationRelationView.vue'
import HorusManageOrganizationDeputizeView from '@/views/manageOrganization/HorusManageOrganizationDeputizeView.vue'

import HorusOrganizationAgentView from '@/views/HorusOrganizationAgentView.vue'

import HorusReporterPortalView from '@/views/HorusReporterPortalView.vue'
import HorusReporterPostHistoryView from '@/views/HorusReporterPostHistoryView.vue'
import HorusManageReporterOrganizationBasic from '@/views/manageReporterOrganization/HorusManageReporterOrganizationBasic.vue'
import HorusManageReporterOrganizationMember from '@/views/manageReporterOrganization/HorusManageReporterOrganizationMember.vue'
import HorusManageReporterOrganizationRelation from '@/views/manageReporterOrganization/HorusManageReporterOrganizationRelation.vue'

import HorusSignedInPermissionDenied from '@/views/HorusSignedInPermissionDenied.vue'
import HorusSignedInForbidden from '@/views/HorusSignedInForbidden.vue'
// import HorusForbiddenView from '@/views/HorusForbiddenView.vue'


const routes = [
  {
    path: '/landing/:type*',
    name: 'Landing',
    component: HorusLandingView
  },

  {
    path: '/own_authenticate',
    name: 'OwnAuthenticate',
    component: HorusLandingView
  },

  {
    path: '/signout',
    name: 'SignOut',
    // component: HorusLandingView,
    meta: { signOut: true }
  },

  {
    path: '/test_apo_signin_view',
    name: 'TestAppSigninView',
    component: HorusTestAppSigninView
  },

  {
    path: '/print_member_invitation',
    name: 'PrintMemberInvitation',
    component: HorusPrintMemberInvitationView
  },

  {
    path: '/print_relation_invitation',
    name: 'PrintRelationInvitation',
    component: HorusPrintRelationInvitationView
  },

  {
    path: '/print_deputize_invitation',
    name: 'PrintDeputizeInvitation',
    component: HorusPrintDeputizeInvitationView
  },

  {
    path: '/create_user',
    name: 'CreteUser',
    component: HorusCreateUserView,
    props: (route) => ({
      token: route.query.token,
      tokenType: route.query.token_type,
      shortToken: route.query.short_token
    })
  },

  {
    path: '/submit_system_manager_invitation',
    name: 'SubmitSystemManagerInvitation',
    component: HorusSubmitSystemManagerView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_area_block_operator',
    name: 'SubmitAreaBlockOperator',
    component: HorusSubmitAreaBlockOperatorView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_organization_manager',
    name: 'SubmitOrganizationManager',
    component: HorusSubmitOrganizationManagerView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_organization_member',
    name: 'SubmitOrganizationMember',
    component: HorusSubmitOrganizationMemberView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_service_relation',
    name: 'SubmitServiceRelation',
    component: HorusSubmitServiceRelationView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_deputize_relation',
    name: 'SubmitDeputizeRelation',
    component: HorusSubmitDeputizeRelationView,
    props: (route) => ({
      token: route.query.token
    })
  },

  // submit_agent
  {
    path: '/submit_deputize_request_for_agent',
    name: 'SubmitDeputizeRequestForAgent',
    component: HorusSubmitDeputizeRequestForAgent,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_family',
    name: 'SubmitFamily',
    component: HorusSubmitFamilyView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_reporter_relation',
    name: 'SubmitReporterRelation',
    component: HorusSubmitReporterRelationView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_reporter_organization_manager',
    name: 'SubmitReporterOrganizationManager',
    component: HorusSubmitReporterOrganizationManagerView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_reporter_organization_member',
    name: 'SubmitReporterOrganizationMember',
    component: HorusSubmitReporterOrganizationMemberView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/submit_authentication_activate',
    name: 'SubmitAuthenticationActivate',
    component: HorusSubmitAuthenticationActivateView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/mail_validation',
    name: 'MailValidation',
    component: HorusUserMailValidationView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/reset_password',
    name: 'ResetPasswordView',
    component: HorusResetPasswordView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/change_password',
    name: 'ChangePasswordView',
    component: HorusChangePasswordView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/forgot_system_manager_password',
    name: 'ForgotSystemManagerPasswordView',
    component: HorusForgotSystemManagerPasswordView
  },

  {
    path: '/reset_system_manager_password',
    name: 'ResetSystemManagerPasswordView',
    component: HorusResetSystemManagerPasswordView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/update_system_manager_mail',
    name: 'UpdateSystemManagerMail',
    component: HorusSystemManagerMailValidationView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/forgot_area_block_operator_password',
    name: 'ForgotAreaBlockOperatorPassword',
    component: HorusForgotAreaBlockOperatorPasswordView
  },

  {
    path: '/reset_area_block_operator_password',
    name: 'ResetAreaBlockOperatorPassword',
    component: HorusResetAreaBlockOperatorPasswordView,
    props: (route) => ({
      token: route.query.token
    })
  },
  // '/update_area_block_operator_mail'
  {
    path: '/update_area_block_operator_mail',
    name: 'UpdateAreaBlockOperatorMail',
    component: HorusAreaBlockOperatorMailValidationView,
    props: (route) => ({
      token: route.query.token
    })
  },

  {
    path: '/revoked',
    name: 'RevokedView',
    component: HorusRevokeFinishView
  },

  // critical notification resolver
  {
    path: '/resolve_invalid_mail',
    name: 'ResolveInvalidMail',
    component: HorusResolveInvalidMailView,
    props: (route) => ({
      criticalNotification: route.query.criticalNotification
    })
  },

  {
    path: '/resolve_confirm_agreement',
    name: 'ResolveConfirmAgreement',
    component: HorusResolveConfirmAgreementView,
    props: (route) => ({
      criticalNotification: route.query.criticalNotification
    })
  },

  {
    path: '/ban_excuse',
    name: 'ResolveBANExcuse',
    component: HorusBANExcuseView,
    props: (route) => ({
      criticalNotification: route.query.criticalNotification
    })
  },

  {
    path: '/critical_notification_resolve',
    name: 'ResolveCriticalNotification',
    component: HorusResolveCriticalNotificationView,
    props: (route) => ({
      criticalNotification: route.query.criticalNotification
    })
  },

  {
    path: '/third_party_linkage_request',
    name: 'ThirdPartyLinkageRequest',
    component: HorusThirdPartyLinkageRequestView
  },

  {
    path: '/third_party_linkage_validate_mail',
    name: 'ThirdPartyLinkageValidateMail',
    component: HorusThirdPartyLinkageValidateMailView,
    props: (route) => ({
      mail_validate_token: route.query.mail_validate_token
    })
  },

  {
    path: '/prepare_third_party_linkage_signin_token',
    name: 'ThirdPartyLinkageSingninToken',
    component: HorusPrepareThirdPartyLinkageSigninView,
    props: (route) => ({
      code: route.query.code,
      token: route.query.token,
      return_id: route.query.return_id
    })
  },

  {
    path: '/third_party_linkage_signin',
    name: 'ThirdPartyLinkageSignin',
    meta: { thirdPartyLinkageSignin: true }
  },

  {
    path: '/prepare_app_signin_token',
    name: 'AppSigninToken',
    component: HorusPrepareAppSigninTokenView,
    props: (route) => ({
      app_info: route.query.app_info
    })
  },

  {
    path: '/prepare_create_organization_request',
    name: 'PrepareCreateOrganizationRequest',
    component: HorusCreateOrganizationRequestView,
    props: (route) => ({
      organizationRequestID: route.query.organization_request_id,
      areaBlockID: route.query.area_block_id
    })
  },

  {
    path: '/create_organization_request',
    name: 'CreateOrganizationRequest',
    component: HorusCreateOrganizationRequestView,
    props: (route) => ({
      validateToken: route.query.token,
      mail: route.query.mail
    })
  },

  {
    path: '/prepare_create_reporter_organization_request',
    name: 'PrepareCreateReporterOrganizationRequest',
    component: HorusCreateReporterOrganizationRequestView,
    props: (route) => ({
      organizationRequestID: route.query.organization_request_id,
      areaBlockID: route.query.area_block_id
    })
  },

  {
    path: '/create_reporter_organization_request',
    name: 'CreateReporterOrganizationRequest',
    component: HorusCreateReporterOrganizationRequestView,
    props: (route) => ({
      validateToken: route.query.token,
      mail: route.query.mail
    })
  },

  {
    path: '/personal_information_protection_policy/:lang*',
    name: 'PersonalInfomationProtectionPolicy',
    component: HorusPersonalInformationProtectionPolicy,
    props: true
  },

  {
    path: '/term_of_use/:lang*',
    name: 'TermOfUse',
    component: HorusTermOfUse,
    props: true
  },

  {
    path: '/contact/:lang*',
    name: 'Contact',
    component: HorusContactFormView,
    props: true
  },

  {
    path: '/zoom_document/:lang*',
    name: 'ZoomDocument',
    component: HorusZoomDocument,
    props: true
  },

  {
    path: '/system_management_landing',
    name: 'SystemManagerLanding',
    component: HorusSystemManagementLandingView
  },

  {
    path: '/system_management_operator_landing',
    name: 'SystemManageOperatorLanding',
    component: HorusSystemManagementOperatorLandingView
  },

  {
    path: '/system_management',
    name: 'SystemManagement',
    component: HorusSystemManagementSignInView,
    meta: { requireAreaBlockOperatorAuth: true },
    children: [
      {
        path: 'home',
        name: 'SystemManagementHome',
        component: HorusSystemManagementHomeView,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'operator_home',
        name: 'AreaBlockOperatorHome',
        component: HorusSystemManagementOperatorHomeView,
        meta: { requireAreaBlockOperatorAuth: true }
      },

      {
        path: 'totalization',
        name: 'SystemManageTotalization',
        component: HorusSystemTotalizationView
      },

      {
        path: 'public_announcement',
        name: 'SystemManagementPublicAnnouncement',
        component: HorusSystemPublicAnnouncementView,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'organization_request',
        name: 'SystemManagementOrganizationRequest',
        component: HorusManageOrganizationRequest
      },

      {
        path: 'organization',
        name: 'SystemManagementOrganization',
        component: HorusSystemManagementOrganizationView
      },

      // {
      //   path: 'organization_detail',
      //   name: 'SystemManagemntOrganizationDetail',
      //   component: HorusSystemManagementOrganizationDetail,
      //   props: true
      // },

      {
        path: 'organization_area_block_relation',
        name: 'OrganizationAreaBlockRelation',
        component: HorusManageOrganizationAreaBlockRelationView
      },

      {
        path: 'organization_tag',
        name: 'SystemManagementOrganizationTag',
        component: HorusSystemManagementOrganizationTagView
      },

      {
        path: 'reporter_organization_request',
        name: 'SystemManagementReporterOrganizationRequest',
        component: HorusManageReporterOrganizationRequestView
      },

      {
        path: 'reporter_organization',
        name: 'SystemManagementReporterOrganization',
        component: HorusSystemManagementReporterOrganizationView
      },

      {
        path: 'reporter_organization_area_block_relation',
        name: 'ReporterOrganizationAreaBlockRelation',
        component: HorusManageReporterOrganizationAreaBlockRelationView
      },

      // {
      //   path: 'reporter_organization_detail',
      //   name: 'SystemManagemntReporterOrganizationDetail',
      //   component: HorusSystemManagementReporterOrganizationDetail,
      //   props: true
      // },

      {
        path: 'system_manager',
        name: 'SystemManagementSystemManager',
        component: HorusSystemManagerView,
        meta: { requireSystemAuth: true },
        props: true
      },

      {
        path: 'manage_user',
        name: 'SystemManagementMangeUser',
        component: HorusSystemManageUserView,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'manage_archive',
        name: 'SystemManageArchive',
        component: HorusSystemManageArchiveView,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'third_party_linkage',
        name: 'SystemThirdPartyLinkage',
        component: HorusSystemManagementThirdPartyLinkageView,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'static_identify_code_category',
        name: 'SystemStaticIdentifyCodeCategory',
        component: HorusSystemManageStaticIdentifyCodeCategoryView,
        meta: { requireSystemAuth: true }
      },

      // {
      //   path: 'client_certificate',
      //   name: 'SystemClientCertificate',
      //   component: HorusSystemManagementClientCertificateView,
      //   meta: { requireSystemAuth: true }
      // },

      {
        path: 'system_data_manager',
        name: 'SystemDataManager',
        component: HorusSystemDataManagerView,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'system_contact_mail',
        name: 'SystemContactMail',
        component: HorusSystemContactMailView,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'area_block',
        name: 'AreaBlock',
        component: HorusManageAreaBlock,
        meta: { requireSystemAuth: true }
      },

      {
        path: 'area_block_detail',
        name: 'AreaBlockDetail',
        component: HorusManageAreaBlockDetail,
        meta: { requireSystemAuth: true },
        props: true
      },

      {
        path: 'critical_notification',
        name: 'CriticalNotification',
        component: HorusManageCriticalNotification,
        meta: { requireSystemAuth: true }
      }
    ]
  },

  {
    path: '/',
    name: 'SignedIn',
    component: HorusSignedInView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/maintimeline/:id*',
        name: 'MainTimeline',
        component: HorusMainTimelineView,
        props: true,
        meta: { permissionTimeline: true }
      },

      {
        path: '/profile/:id*',
        name: 'Profile',
        component: HorusProfileView,
        meta: { permissionTimelineOrConfigure: true }
      },

      {
        path: '/family/:id*',
        name: 'Family',
        component: HorusFamilyView,
        meta: { permissionConfigure: true }
      },

      {
        path: '/related_organization/:id*',
        name: 'RelatedOrganization',
        component: HorusRelatedOrganizationView,
        meta: { permissionConfigure: true }
      },

      {
        path: '/related_reporter_organization/:id*',
        name: 'RelatedReporterOrganization',
        component: HorusRelatedReporterOrganizationView,
        meta: { permissionConfigure: true }
      },

      {
        path: '/agent_config/:id*',
        name: 'AgentConfig',
        component: HorusAgentConfigView,
        meta: { permissionConfigure: true }
      },

      {
        path: '/agent',
        name: 'AgentView',
        component: HorusAgentView
      },

      {
        path: '/private_config',
        name: 'PrivateConfig',
        component: HorusPrivateConfigView
      },

      {
        path: '/user_task',
        name: 'UserTaskView',
        component: HorusUserTaskView
        // meta: { permissionTimelineOrConfigure: true }
      },

      {
        path: '/deleted_records/:id*',
        name: 'DeletedRecords',
        component: HorusManageDeletedRecordsView,
        meta: { permissionTimeline: true }
      },

      {
        path: '/member_portal',
        name: 'MemberPortal',
        component: HorusMemberPortalView,
        meta: { permissionMember: true }
      },

      {
        path: '/member_portal_config',
        name: 'MemberPortalConfig',
        component: HorusMemberPortalConfigView,
        meta: { permissionMember: true }
      },

      {
        path: '/member_profile_personal',
        name: 'MemberPersonal',
        component: HorusMemberProfilePersonalView,
        meta: { permissionMember: true }
      },

      {
        path: '/member_profile_configure',
        name: 'MemberConfigure',
        component: HorusMemberProfileConfigureView,
        meta: { permissionMember: true }
      },

      {
        path: '/manage_organization_basic',
        name: 'ManageOrganizationBasic',
        component: HorusManageOrganizationBasicView,
        meta: { permissionMember: true }
      },

      {
        path: '/manage_organization_member',
        name: 'ManageOrganizationMember',
        component: HorusManageOrganizationMemberView
      },

      {
        path: '/manage_organization_relation',
        name: 'ManageOrganizationRelation',
        component: HorusManageOrganizationRelationView
      },

      {
        path: '/manage_organization_deputize',
        name: 'ManageOrganizationDeputize',
        component: HorusManageOrganizationDeputizeView
      },

      {
        path: '/organization_agent',
        name: 'OrganizationAgent',
        component: HorusOrganizationAgentView
      },

      {
        path: '/reporter_portal',
        name: 'ReporterPortal',
        component: HorusReporterPortalView,
        meta: { permissionReporter: true }
      },

      {
        path: '/post_history/:id*',
        name: 'PostHistory',
        component: HorusReporterPostHistoryView,
        props: true,
        meta: { permissionReporter: true }
      },

      {
        path: '/manage_reporter_organization_basic',
        name: 'ManageReporterOrganizationBasic',
        component: HorusManageReporterOrganizationBasic,
        meta: { permissionReporter: true }
      },

      {
        path: '/manage_reporter_organization_member',
        name: 'ManageReporterOrganizationMember',
        component: HorusManageReporterOrganizationMember,
        meta: { permissionReporter: true }
      },

      {
        path: '/manage_reporter_organization_relation',
        name: 'ManageReporterOrganizationRelation',
        component: HorusManageReporterOrganizationRelation,
        meta: { permissionReporter: true }
      },

      {
        path: '/permission_denied',
        name: 'SignedInPermissionDenied',
        component: HorusSignedInPermissionDenied
      },

      {
        path: '*',
        name: 'SignedInForbidden',
        component: HorusSignedInForbidden,
        meta: { notFoundRedirect: true }
      }

    ]
  },

  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'Forbidden',
  //   component: HorusForbiddenView
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// check signin controlls

// const forceSignout = async function (to, next, type = '') {
// NOTICE: アプリでログアウトを検知するためには、リダイレクトを発生させてアクセスしているURLが明示的に/landingであることを伝える必要がある。
const forceSignout = async function (to, type = '') {
  console.log('FORCE SIGNOUT CALL. type:' + type)
  await Promise.all([
    SigninUser.deleteAll(),
    SigninMember.deleteAll(),
    SigninReporter.deleteAll(),
    SystemManager.deleteAll(),
    AreaBlockOperator.deleteAll()
  ])
  var redirectPath = { replace: true, path: '/landing' }
  try {
    await SigninState.signOut()
    await SystemManageSigninState.signOut()
    await AreaBlockOperatorSigninState.signOut()
    if (type === 'system') {
      redirectPath = { replace: true, path: '/system_management_landing' }
      // return { path: '/system_management_landing' }
      // next({ path: '/system_management_landing' })
    } else if (type === 'area_block') {
      redirectPath = { replace: true, path: '/system_management_operator_landing' }
      // return { path: '/system_management_operator_landing' }
      // next({ path: '/system_management_operator_landing' })
    } else if (to.fullPath.length > 1) {
      if (to.fullPath.indexOf('worker') >= 0 || to.fullPath.indexOf('reserved_service_user_id') >= 0) {
        console.log('in force signout, to lading/member')
        redirectPath = { replace: true, path: '/landing/member' }
        // return { path: '/landing/member' }
        // next({
        //   path: '/landing/member'
        // })
      } else {
        console.log('in force signout, to lading with redirect')
        redirectPath = {
          replace: true, 
          path: '/landing#',
          query: { redirect: to.fullPath }
        }
      }
    } else {
      console.log('in force signout, to lading')
      redirectPath = { replace: true, path: '/landing#' }
      // return { path: '/landing#' }
      // next({ path: '/landing#' })
    }
  } catch (error) {
    console.error(error)
    console.log('in force signout, to lading')
    redirectPath = { replace: true, path: '/landing#' }
    // return { path: '/landing#' }
    // next({ path: '/landing#' })
  }
  console.log(redirectPath)
  window.location.assign(redirectPath.path)
  return redirectPath
}

// const normalAuthentication = async function (to, from, next) {
const normalAuthentication = async function (to) {
  var signinUser = await SigninUser.query().first()
  var signinMember = await SigninMember.query().first()
  var signinReporter = await SigninReporter.query().first()
  console.log('normalAuthentication. signinUser, signinMember')
  console.log(signinUser)
  console.log(signinMember)
  console.log(signinReporter)
  console.log(to)
  var result = true
  let signinStateRes = await parepareSigninState(signinUser, signinMember, signinReporter)
  // console.log('prepareSigninState response.')
  // console.log(res)
  try {
    if (!signinStateRes.user && !signinStateRes.member && !signinStateRes.reporter) {
      console.log('FORCE SIGNOUT')
      // forceSignout(to, next)
      var forceSignoutValue = await forceSignout(to)
      result = await forceSignout(to)
      console.log('forceSignoutValue')
      console.log(forceSignoutValue)
      // return forceSignoutValue
      // return forceSignout(to)
    } else {
      signinUser = signinStateRes.user
      signinMember = signinStateRes.member
      signinReporter = signinStateRes.reporter
    }
    if (signinUser || signinMember || signinReporter) {
      // check page permission
      if (to.matched.some(record => record.meta.permissionTimeline)) {
        // console.log('permission timeline')
        // checkPermissionTimeline(to, next, signinUser)
        result = await checkPermissionTimeline(to, signinUser)
        // return checkPermissionTimeline(to, signinUser)
      } else if (to.matched.some(record => record.meta.permissionConfigure)) {
        // console.log('permission configure')
        // checkPermissionConfigure(to, next, signinUser)
        result = await checkPermissionConfigure(to, signinUser)
        // return checkPermissionConfigure(to, signinUser)
      } else if (to.matched.some(record => record.meta.permissionTimelineOrConfigure)) {
        // console.log('permission timeline or configure')
        // checkPermissionTimelineOrConfigure(to, next, signinUser)
        result = await checkPermissionTimelineOrConfigure(to, signinUser)
        // return checkPermissionTimelineOrConfigure(to, signinUser)
      } else if (to.matched.some(record => record.meta.permissionMember)) {
        // console.log('permission member')
        // checkPermissionMember(to, next, signinMember)
        result = await checkPermissionMember(to, signinMember)
        // return checkPermissionMember(to, signinMember)
      } else if (to.matched.some(record => record.meta.permissionReporter)) {
        // console.log('permission member')
        // checkPermissionReporter(to, next, signinReporter)
        result = await checkPermissionReporter(to, signinReporter)
        // return checkPermissionReporter(to, signinReporter)
      } else if (signinReporter) {
        // permissions for reporter
      } else {
        if (to.path === '/') {
          // console.log('router, nomalAuthentication, gonna redirect to home view.......')
          if (signinMember) {
            result = { path: '/member_portal' }
            // next('/member_portal')
          } else if (signinReporter) {
            result = { path: '/reporter_portal' }
            // next('/reporter_portal')
          } else {
            result = { path: '/maintimeline' }
            // next('/maintimeline')
          }
        } else {
          // console.log('router, normalAuthentication, not addtitional auth, so move next' + to)
          result = true
          // next()
        }
      }
    }
  } catch (error) {
    console.log(error)
    return false
  }
  return result
}

const parepareSigninState = async function (user, member, reporter) {
  return new Promise((resolve, reject) => {
    SigninState.currentState().then(async state => {
      if (!state.user_id && !state.member_id && !state.reporter_id) {
        // state not signin
        console.log('user/menber not signed in.')
        resolve({ user: null, member: null, reporter: null })
      } else if (user === null && member === null && reporter == null) {
        // サーバーがサインインを返していて、ローカルにサインイン情報がない場合は、ユーザー情報を取得する
        if (state.user_id > 0) {
          if (state.member_id > 0) {
            console.log('state says member is signedin.')
            await SigninMember.api().fetchCurrentSingin()
            resolve({ user: null, member: SigninMember.query().first(), reporter: null })
          } else if (state.reporter_id > 0) {
            console.log('reporter signed in... ' + state.reporter_id)
            await SigninReporter.api().fetchCurrentSingin()
            resolve({ user: null, member: null, reporter: SigninReporter.query().first() })
          } else {
            console.log('user signed in....')
            await SigninUser.api().fetchCurrentSingin()
            console.log(SigninUser.all())
            resolve({ user: SigninUser.query().first(), member: null, reporter: null })
          }
        } else {
          console.log('hohoho')
        }
      } else if ((user && state.user_id !== user.user_id) || (member && state.member_id !== member.id) || (reporter && state.reporter_id !== reporter.id)) {
        // not match state with signinObj
        console.log('requireAuth, not match server response state with local signinObjects.')
        resolve({ user: null, member: null, reporter: null })
      } else {
        resolve({ user: user, member: member, reporter: reporter })
      }
    }).catch(error => {
      console.log(error)
      reject(error)
    })
  })
}

// const systemAuthentication = async function (to, from, next) {
const systemAuthentication = async function (to) {
    // console.log(SystemManager.all())
  var signinManager = SystemManager.query().first()
  var signinOperator = AreaBlockOperator.query().first()
  // console.log('systemAuthentication. signinManager, signinOperator')
  // console.log(signinManager)
  // console.log(signinOperator)
  await prepareSystemSigninState(signinManager, signinOperator).then(res => {
    // console.log('prepareSystemSigninState response.')
    // console.log(res)
    if (!res.manager && !res.operator) {
      // console.log('prepareSystemSigninState check failed.')
      // forceSignout(to, next, 'system')
      return forceSignout(to, 'system')
    } else {
      signinManager = res.manager
      signinOperator = res.operator
    }
  }).catch(error => {
    // console.error('prepareSigninState')
    console.error(error)
    // forceSignout(to, next, 'system')
    return forceSignout(to, 'system')
  })
  if (signinManager || signinOperator) {
    // check page permission
    if (to.matched.some(record => record.meta.requireSystemAuth)) {
      if (!signinManager) {
        // forceSignout(to, next, 'system')
        return forceSignout(to, 'system')
      } else {
        return true
        // next()
      }
    } else if (to.matched.some(record => record.meta.requireAreaBlockOperatorAuth)) {
      if (!signinManager && !signinOperator) {
        // forceSignout(to, next, 'system')
        return forceSignout(to, 'system')
      } else {
        return true
        // next()
      }
    } else {
      if (to.path === '/') {
        if (signinManager) {
          return {
            path: '/system_management_landing',
            query: { redirect: to.fullPath }
          }
          // next({
          //   path: '/system_management_landing',
          //   query: { redirect: to.fullPath }
          // })
        } else if (signinOperator) {
          return {
            path: '/system_management_operator_landing',
            query: { redirect: to.fullPath }
          }
          // next({
          //   path: '/system_management_operator_landing',
          //   query: { redirect: to.fullPath }
          // })
        } else {
          return {
            path: '/system_management_landing',
            query: { redirect: to.fullPath }
          }
          // next({
          //   path: '/system_management_landing',
          //   query: { redirect: to.fullPath }
          // })
        }
      } else {
        return true
        // next()
      }
    }
  }
}

const prepareSystemSigninState = async function (systemManager, areaBlockOperator) {
  return new Promise((resolve, reject) => {
    SystemManageSigninState.currentState().then(state => {
      // console.log('system signin state is =========')
      // console.log(state)
      // console.log(systemManager)
      // console.log(areaBlockOperator)
      if (state.manager_id || state.area_block_operator_id) {
        if (state.manager_id > 0) {
          if (systemManager) {
            // systemManagerとsignin.stateが一致しない場合は強制ログアウト
            if (systemManager.id === state.manager_id) {
              resolve({ manager: systemManager, operator: null })
            }
          } else {
            // systemManagerがなくてsignin.stateがある場合、systemManagerを取得する
            SystemManager.api().fetchCurrentSingin()
            resolve({ manager: SystemManager.query().first(), operator: null })
          }
        } else if (state.area_block_operator_id > 0) {
          if (areaBlockOperator) {
            if (areaBlockOperator.id === state.area_block_operator_id) {
              resolve({ manager: null, operator: areaBlockOperator })
            }
          } else {
            AreaBlockOperator.api().fetchCurrentSingin()
            resolve({ manager: null, operator: AreaBlockOperator.query().first() })
          }
        }
      } else {
        resolve({ manager: null, operator: null })
      }
    }).catch(error => {
      console.error(error)
      reject(error)
    })
  })
}

// const checkPermissionTimeline = async function (to, next, signinUser) {
const checkPermissionTimeline = async function (to, signinUser) {
  console.log('checkPermissonTimeline call, ')
  console.log(to)
  // omron connectレスポンス受け取り
  if (to.params.id) {
    if (signinUser && to.params.id === signinUser.user_id) {
      // idパラメータがログインユーザーならOK
      console.log('params id matchto signinUser id.')
      return true
      // next()
    } else {
      // それ以外は問い合わせ
      Permission.timeline({ targetUserID: to.params.id }).then(() => {
        console.log('permisson allowed.')
        return true
        // next()
      }).catch(error => {
        if (error.response.status === 403) {
          console.error(error.response)
          return { path: '/permission_denied' }
          // next({ path: '/permission_denied' })
        } else if (error.response.status === 404) {
          console.error(error.response)
          return false
          // next(false)
        }
      })
    }
  } else if (signinUser) {
    // idパラメータがなくて、ユーザーログインの場合はOK
    console.log('no params and signinUser is passed.')
    return true
    // next()
  } else {
    console.error('no signin')
    return false
    // next(false)
  }
}

// const checkPermissionConfigure = async function (to, next, signinUser) {
const checkPermissionConfigure = async function (to, signinUser) {
  // console.log('checkPermissionConfigure call, ')
  // console.log(to)
  if (to.params.id) {
    // idがあるなら問い合わせ
    Permission.configure({ targetUserID: to.params.id }).then(() => {
      // console.log('permission allowed.')
      return true
      // next()
    }).catch(error => {
      if (error.response.status === 403) {
        console.error('permission denied')
        return { path: '/permission_denied' }
        // next({ path: '/permission_denied' })
      } else if (error.response.status === 404) {
        console.error('target not found')
        return { name: 'SignedInForbidden' }
        // next({ name: 'SignedInForbidden' })
      }
    })
  } else if (signinUser) {
    // console.log('router, checkPermissionConfigure, signinUser exist, so move next')
    // console.log(to)
    return true
    // next()
  } else {
    console.error('no permission')
    return { path: '/permission_denied' }
    // next({ path: '/permission_denied' })
  }
}

// const checkPermissionTimelineOrConfigure = async function (to, next, signinUser) {
const checkPermissionTimelineOrConfigure = async function (to, signinUser) {
  // console.log('checkPermissionTimelineOrConfigure call, ')
  // console.log(to)
  if (to.params.id) {
    if (to.params.id === 'omron_connect') {
      if (signinUser) {
        // console.log('to params is omron,')
        return true
        // next()
      } else {
        console.error('omron_connect response, no signin')
        return false
        // next(false)
      }
    } else if (signinUser && to.params.id === signinUser.user_id) {
      // idパラメータがログインユーザーならOK
      // console.log('router, checkPermissionTimelineOrConfigre, target id equal signinUser id, move next ' + to)
      // console.log(to)
      return true
      // next()
    } else {
      // それ以外は問い合わせ
      var permitted = false
      await Permission.timeline({ targetUserID: to.params.id }).then(() => {
        // console.log('router, checkPermissionTimelineOrConfigre, check permission succeed, move next ' + to)
        permitted = true
        return true
        // next()
      }).catch(error => {
        if (error.response.status === 403) {
          console.error(error.response)
          permitted = false
        } else if (error.response.status === 404) {
          console.error(error.response)
          return false
          // next(false)
        }
      })
      if (!permitted) {
        await Permission.configure({ targetUserID: to.params.id }).then(() => {
          // console.log('not permitted.....')
          return true
          // next()
        }).catch(error => {
          if (error.response.status === 403) {
            console.error('permission denied')
            return { path: '/permission_denied' }
            // next({ path: '/permission_denied' })
          } else if (error.response.status === 404) {
            console.error('target not found')
            return { name: 'SignedInForbidden' }
            // next({ name: 'SignedInForbidden' })
          }
        })
      }
    }
  } else if (signinUser) {
    // console.log('router, checkPermissionTimelineOrConfigre, no params signined, move next ')
    // console.log(to)
    return true
    // next()
  } else {
    console.error('no signin')
    return false
    // next(false)
  }
}

// const checkPermissionMember = async function (to, next, signinMember) {
const checkPermissionMember = async function (to, signinMember) {
  // console.log('checkPermissionMember call, ')
  // console.log(to)
  if (signinMember) {
    // console.log('router, checkPermissionMember, signinMember exist, move next ' + to)
    return true
    // next()
  } else {
    console.error('no permission')
    return { path: '/permission_denied' }
    // next({ path: '/permission_denied' })
  }
}

// const checkPermissionReporter = async function (to, next, signinReporter) {
const checkPermissionReporter = async function (to, signinReporter) {
  // console.log('checkPermissionReporter call, ')
  // console.log(to)
  if (signinReporter) {
    // console.log('router, checkPermissionReporter, signinReporter exist, move next ' + to)
    return true
    // next()
  } else {
    console.error('no permission')
    return { path: '/permission_denied' }
    // next({ path: '/permission_denied' })
  }
}

// const thirdPartyLinkageAuthentication = async function (to, from, next) {
const thirdPartyLinkageAuthentication = async function (to) {
  // console.log('thirdPartyLinkageAuth')
  // console.log(to.query)
  if (to.query.code && to.query.token) {
    await SigninMember.api().signinByToken({ code: to.query.code, token: to.query.token })
    if (SigninMember.query().first()) {
      var redirectPath = '/member_portal'
      if (to.query.redirect_to && to.query.redirect_to.length > 3) {
        redirectPath = to.query.redirect_to
      }
      // console.log('router, thirdPartyLinkageAuthentication, signinMember success, move next ' + redirectPath)
      return { path: redirectPath }
      // next({ path: redirectPath })
    } else {
      console.error('member, signinByToken failed.')
      return { path: '/landing/member' }
      // next({ path: '/landing/member' })
    }
  } else {
    // console.log('bad paramator, code: ' + to.query.code + ' token: ' + to.query.token)
    return { path: '/landing/member' }
    // next({ path: '/landing/member' })
  }
}

// TODO: dont use next when not need to reactive.
router.beforeEach(async (to, from) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('gonna check normal authenticateion.')
    // normalAuthentication(to, from, next)
    var normalAuthenticationValue = await normalAuthentication(to, from)
    console.log('normal authenticate value')
    console.log(normalAuthenticationValue)
    return normalAuthenticationValue
    // return normalAuthentication(to, from)
  } else if (to.matched.some(record => record.meta.signOut)) {
    // NOTICE: アプリでログアウトを検知するためには、リダイレクトを発生させてアクセスしているURLが明示的に/landingであることを伝える必要がある。
    console.log('gonna signout...')
    const signinMember = await SigninMember.query().first()
    const signinReporter = await SigninReporter.query().first()
    const systemManager = await SystemManager.query().first()
    const areaBlockOperator = await AreaBlockOperator.query().first()
    var pathName = '/landing'
    if (signinMember) {
      // console.log('member signout')
      pathName = '/landing/member'
    } else if (signinReporter) {
      pathName = '/landing/reporter'
    } else if (systemManager) {
      pathName = '/system_management_landing'
    } else if (areaBlockOperator) {
      pathName = '/system_management_operator_landing'
    }
    await Promise.all([
      SigninUser.deleteAll(),
      SigninMember.deleteAll(),
      SigninReporter.deleteAll(),
      SystemManager.deleteAll(),
      AreaBlockOperator.deleteAll()
    ])
    try {
      await SigninState.signOut()
      console.log('signin state signOut, ' + pathName)
      // return { path: pathName }
      // window.location.assign(pathName)
      // window.location.href = pathName
      // next({path: pathName})
      // return pathName
    } catch (err) {
      console.error(err)
      // window.location.href = '/landing#'
      // return { path: pathHost + '/landing#' }
      pathName = '/landing#'
      // window.location.assign('/landing#')
      // next('/landing#')
    }
    window.location.assign(pathName)
    return { replace: true, path: pathName }
    // SystemManageSigninState.signOut()
    // AreaBlockOperatorSigninState.signOut()
    // next({
    //   path: pathName
    // })
  } else if (to.matched.some(record => record.meta.requireSystemAuth) || to.matched.some(record => record.meta.requireAreaBlockOperatorAuth)) {
    // console.log('requireSystemManagerAuth====!!!!')
    // systemAuthentication(to, from, next)
    return systemAuthentication(to, from)
  } else if (to.matched.some(record => record.meta.thirdPartyLinkageSignin)) {
    // third party linkage signin
    // thirdPartyLinkageAuthentication(to, from, next)
    return thirdPartyLinkageAuthentication(to, from)
  } else if (to.matched.some(record => record.meta.notFoundRedirect)) {
    // redirect to home
    // console.log('not found redirect...')
    const signinUser = await SigninUser.query().first()
    const signinMember = await SigninMember.query().first()
    const signinReporter = await SigninReporter.query().first()
    if (signinMember) {
      return { path: '/member_portal' }
      // next({ path: '/member_portal' })
    } else if (signinReporter) {
      return { path: '/reporter_portal' }
      // next({ path: '/reporter_portal' })
    } else if (signinUser) {
      return { path: '/maintimeline' }
      // next({ path: '/maintimeline' })
    } else {
      return { path: '/landing#' }
      // next()
    }
  // } else {
    // console.log('beforeEach, no meta....')
    // next()
  }
})

export default router
