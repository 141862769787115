import axios from './AxiosInstance'

export default {
  create: ({organizationID, userID}) => {
    var formData = new FormData()
    formData.append('organization_id', organizationID)
    formData.append('subject_user_id', userID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_agent',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  },

  destroy: ({organizationID, userID}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_agent',
            withCredentials: true,
            params: { organization_id: organizationID, subject_user_id: userID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  }
}
