<template>
  <div class="left-column-controls">
    <div class="control-icon left-column-controls-hashtag">
      <img src="/static/images/tag_icon.png" @click="openTagNarrowDownModal($event)" />
    </div>
    <div class="control-icon">
      <img src="/static/images/related_people_icon.png" @click="openAuthorNarrowDownModal($event)" />
    </div>
    <div class="control-icon" v-if="!hideDateJump">
      <img src="/static/images/calender_icon.png" @click="openDateJumpControll($event)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusLeftColumnContorll',

  data () {
    return {
      hideDateJump: false
    }
  },

  methods: {
    openTagNarrowDownModal (event) {
      this.$emit('openTagNarrowDonModalEvent', event)
    },

    openAuthorNarrowDownModal (event) {
      this.$emit('openAuthorNarrowDownModalEVent', event)
    },

    openDateJumpControll (event) {
      this.$emit('openDateJumpControllEvent', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.left-column-controls {
  display: flex;
  margin: 10px 0;
  .control-icon {
    margin-left: 10px;
    margin-right: 30px;
    cursor: pointer;
  }
  .left-column-controls-hashtag {
    position: relative;
  }
}
</style>
