import axios from './AxiosInstance'

export default {
  unreadCount: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/activity_log/unread_count',
        withCredentials: true
      }).then(res => {
        resolve({ count: res.data.count })
      }).catch(error => { reject(error) })
    })
  },

  fetchIDList: ({fromDate}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/activity_log/id_list_from',
        withCredentials: true,
        params: { from: fromDate }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ activityLogs: res.data.activity_logs })
        }
      }).catch(error => { reject(error) })
    })
  },
}
