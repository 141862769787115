<template>
  <div class="horus-sent-deputize-request-for-organization-column labeled_content_block">
    <div class="labeled_content_single_block">
      <label>施設番号</label>
      <div class="content">{{ sentRequest.organization.numberForHuman }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>名称</label>
      <div class="content">{{ sentRequest.organization.name }}({{ sentRequest.organization.shortName }})</div>
    </div>
    <div class="labeled_content_single_block">
      <label>電話番号</label>
      <div class="content">{{ sentRequest.organization.tel }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ sentRequest.status }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>メッセージ</label>
      <div class="content" v-html="messageContent"></div>
    </div>
    <div class="content-controll-block">
      <div class="sent-request-org-notify" v-if="sentRequest.organization.deleted">
        この施設はすでに退会済みです。かかりつけ申請の承認が行えません。
      </div>
      <div class="sent-request-org-notify" v-if="!sentRequest.organization.deleted && sentRequest.organization.revoke_requested">
        この施設は現在退会準備中です。かかりつけ申請の承認が行えない状況です。
      </div>
      <button type="button" @click="removeRequest" v-if="!sentRequest.used">申請取り消し</button>
      <button type="button" @click="removeRequest" v-if="sentRequest.used">このリストから削除</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusSentDeputizeRequestForOrganizationColumn',

  props: {
    sentRequest: Object
  },

  computed: {
    messageContent () {
      if (this.sentRequest.message_content && this.sentRequest.message_content.length > 0) {
        return this.sentRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return '設定代行依頼のメッセージはありません。'
      }
    }
  },

  methods: {
    removeRequest () {
      this.$emit('removeRequestEvent', this.sentRequest)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
.sent-request-org-notify {
  padding: 3px;
  border: 1px solid #c43d53;
  color: #c43d53;
}
</style>
