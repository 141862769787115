<template>
  <div class="horus-sending-modal" v-if="showSendingModal">
    <div class="backdrop"></div>
    <div class="modal">
      <div class="modal-header">
        <h1>データ送信中</h1>
      </div>
      <div class="modal-body">
        <img src="/static/images/progress.gif" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusSendingModal',

  props: {
    showSendingModal: Boolean
  }
}
</script>

<style lang="scss" scoped>
.horus-sending-modal {
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .modal {
    position: absolute;
    width: 300px;
    max-height: calc(100vh - 60px);
    margin: 0;
    padding: 0;
    top: 30px;
    left: calc((100vw - 300px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    .modal-header {
      padding: 10px;
      border-bottom: 1px solid #aaa;
      h1 {
        margin: 0;
        font-size: 14px;
        color: #666;
        text-align: center;
        img {
          margin-right: 10px;
          height: 15px;
        }
      }
    }
    .modal-body {
      text-align: center;
      img {
        margin: 20px;
      }
    }
  }
}
</style>
