<template>
  <div class="horus-confirm-create-non-signin-user-dialog">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>ログインできないユーザー作成に関する確認</h1>
      </div>
      <div class="modal-body">
        <div class="notify-message">
          ログインできない利用者アカウントを作成します。<br/>
          このアカウントは、代理者が代わりに設定するまでかかりつけや家族の設定が行なえません。<br/>
          作成したアカウントは、あなたが責任をもって管理してください。
        </div>
        <div class="notify_for_admit">
          <h5>代理者登録における確認事項</h5>
          <ul>
            <li>ナラティブブックが利用者本人の情報を利用者本人が希望した家族や施設と共有するシステムであることを、利用者本人が理解していること</li>
            <li>設定の変更は必ず利用者本人の意思に基づいて行うこと</li>
          </ul>
        </div>
        <div class="admit-agreement" @click="toggleAdmit">
          <horus-custom-check-box
            :active="admitAgreement"
            :enabled="true"
            :type="'color'"
            />
          上記確認事項を確認しました
        </div>
        <div class="create-controll">
          <button class="cancel-btn" @click="cancelCreate">キャンセル</button>
          <button class="create-user-btn" :class="{disable: !createUserReady}" @click="createUser">新規利用者作成</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusConfirmCreateNonSigninUserDialog',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    showModal: Boolean
  },

  data () {
    return {
      admitAgreement: false
    }
  },

  computed: {
    createUserReady () {
      return this.admitAgreement
    }
  },

  methods: {
    initForm () {
      this.admitAgreement = false
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    cancelCreate () {
      this.initForm()
      this.$emit('cancelCreateEvent')
    },

    createUser () {
      if (this.admitAgreement) {
        this.initForm()
        this.$emit('admitAgreementEvent')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/createWithAgreementForm.scss';
.horus-confirm-create-non-signin-user-dialog {
  .modal-body {
    padding: 10px;
    .notify-message {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #c43d53;
    }
    .create-controll {
      display: flex;
      justify-content: flex-end;
      .cancel-btn {
        margin: 0 10px;
        padding: 3px 10px;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #666;
        color: #666;
        font-weight: bold;
      }
      .create-user-btn {
        margin: 0 10px;
        padding: 3px 10px;
        background-color: #666;
        border-radius: 3px;
        border: 1px solid #666;
        color: #fff;
        font-weight: bold;
        &.disable {
          background-color: #aaa;
          border-color: #aaa;
        }
      }
    }
  }
}
</style>
