<template>
  <div class="horus-private-config-view">
    <h1 class="centering-title">プライバシー設定</h1>
    <div class="caption">
      自分の投稿をプライベートメンバーに限定して見せることができます。プライベートメンバーに加える方を選択してください。<br/>
      プライベートメンバーに投稿する時は、鍵マーク<img src="/static/images/open_lock.png" />をロック<img src="/static/images/open_lock.png" />してください。
    </div>
    <div class="author-list">
      <h3>- families -</h3>
      <div class="families author-group">
        <horus-private-permission-column
          v-for="family in families"
          :key="'privateFamily' + family.id"
          :author="family.author"
          :permitted="isPermitted(family.author)"
          v-on:togglePermissionEvent="togglePermission"
          />
      </div>
      <h3>- organizations -</h3>
      <div class="organizations author-group"
        v-for="organization in organizations"
        :key="'privateOrg' + organization.id">
        <div class="author-group-head">
          <img src="/static/images/org_member.png" />
          <span class="selection-group-name">{{ organization.shortName }}</span>
        </div>
        <horus-private-permission-column
          v-for="member in organization.members"
          :key="'privateMember' + member.id"
          :author="member"
          :permitted="isPermitted(member)"
          v-on:togglePermissionEvent="togglePermission"
          />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Family,
  Organization
} from '@/models'

import {
  RecordPrivatePermission
} from '@/api'

import HorusPrivatePermissionColumn from '@/components/privatePermission/HorusPrivatePermissionColumn.vue'

export default {
  name: 'HorusPrivateConfigView',

  components: {
    HorusPrivatePermissionColumn,
  },

  props: {
    loggedInUser: Object
  },

  data () {
    return {
      families: [],
      organizations: [],
      privatePermissions: []
    }
  },

  watch: {
    loggedInUser: { handler: 'initObjects', immediate: true }
  },

  computed: {
  },

  methods: {
    initObjects () {
      this.loadFamilies()
      this.loadOrganizations()
      this.loadPermissions()
    },

    async loadFamilies () {
      var families = await Family.query().where('user_id', this.loggedInUser.id).get()
      for(var family of families) {
        if (family.relation === 3) {
          // check already permitted and remove permission
          if (this.isPermitted(family.author)) {
            var formData = new FormData()
            formData.append('target_user_id', this.loggedInUser.id)
            formData.append('subject_user_id', family.author.rawID)
            RecordPrivatePermission.destroy({ formData: formData })
          }
        } else {
          this.families.push(family)
        }
      }
    },

    loadOrganizations () {
      Organization.api().fetchRelatedIDList({ timelineOwnerID: this.loggedInUser.id }).then(async res => {
        this.organizations = Organization.findIn(res.organizations)
      })
    },

    loadPermissions () {
      RecordPrivatePermission.fetch({ timelineOwnerID: this.loggedInUser.id }).then(res => {
        this.privatePermissions = res.record_private_permissions
      })
    },

    isPermitted (author) {
      return this.privatePermissions.some(permission => permission.author_id === author.id)
    },

    async togglePermission (author) {
      var formData = new FormData()
      formData.append('target_user_id', this.loggedInUser.id)
      if (author.isMember) {
        formData.append('subject_suom_id', author.rawID)
      } else {
        formData.append('subject_user_id', author.rawID)
      }
      if (this.isPermitted(author)) {
        RecordPrivatePermission.destroy({ formData: formData }).then(() => {
          this.loadPermissions()
        }).catch(error => {
          console.error(error)
          this.showNotifyDialog(
            'プライバシー設定の処理失敗',
            author.name + 'をプライベートメンバーから外せませんでした'
          )
        })
      } else {
        RecordPrivatePermission.create({ formData: formData }).then(() => {
          this.loadPermissions()
        }).catch(error => {
          console.error(error)
          this.showNotifyDialog(
            'プライバシー設定の処理失敗',
            author.name + 'をプライベートメンバーに出来ませんでした'
          )
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-private-config-view {
  height: calc(100% - 60px);
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  @include mediaQuery('phone') {
    padding: 10px;
    padding-bottom: 0;
  }
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .caption {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #aaa;
    font-size: 12px;
    img {
      height: 14px;
    }
  }
  .author-list {
    padding: 10px;
    background-color: #fff;
    h3 {
      margin: 0;
      color: #999;
      font-size: 14px;
    }
    .author-group {
      margin: 10px 0;
      margin-bottom: 20px;
      .author-group-head {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #666;
        img {
          margin-right: 5px;
          height: 15px;
        }
      }
    }
  }
}
</style>
