<template>
  <div class="horus-system-manage-user-view">
    <h1 class="centering-title">ユーザー管理</h1>
    <h2>ユーザー状態サマリ</h2>
    <div class="user-status">
      <div class="simple-list">
        <div class="list-element"><label>総ユーザー数</label><span>{{ systemSimpleData.total_user_count }}</span></div>
        <div class="list-element"><label>ログイン可能なユーザー数</label><span>{{ systemSimpleData.available_user_count }}</span></div>
        <div class="list-element"><label>かかりつけを設定していないユーザー数</label><span>{{ systemSimpleData.non_related_user_count }}</span></div>
        <div class="list-element"><label>退会済ユーザー数</label><span>{{ quitedUsers.length }}</span></div>
      </div>
    </div>
    <h2>退会済ユーザー</h2>
    <table class="quited-users">
      <tr><th>NBID</th><th>氏名(カナ)</th><th>生年月日</th><th>性別</th><th>メールアドレス</th><th>&nbsp;</th></tr>
      <tr v-for="user in quitedUsers" :key="'quitedList' + user.id">
        <td>{{ user.nbid }}</td>
        <td>{{ user.fullName }}({{ user.fullNameKana }})</td>
        <td>{{ user.birthDateJp }}</td>
        <td>{{ user.sexTypeJp }}</td>
        <td>{{ user.mail }}</td>
        <td class="controll"><button class="show-detail-btn" @click="showDetail(user)">詳細表示</button></td>
      </tr>
    </table>
    <h2>ユーザー検索</h2>
    <div class="user-search-form">
      <div class="caption">
        ナラティブブック内の削除されていないユーザーを検索します。<br/>
        すべての入力された条件はAND条件で検索されます。<br/>
        各項目の入力内容は、部分一致で検索されます。<br/>
      </div>
      <div class="search-item"><label>NBID</label><input type="text" size="10" v-model="searchCondition.nbid"></div>
      <div class="search-item"><label>メールアドレス</label><input type="text" size="50" v-model="searchCondition.mail"></div>
      <div class="search-item"><label>姓</label><input type="text" size="10" v-model="searchCondition.family_name"></div>
      <div class="search-item"><label>名</label><input type="text" size="10" v-model="searchCondition.given_name"></div>
      <div class="search-item"><label>姓カナ</label><input type="text" size="15" v-model="searchCondition.family_name_kana"></div>
      <div class="search-item"><label>名カナ</label><input type="text" size="15" v-model="searchCondition.given_name_kana"></div>
      <div class="search-item">
        <label>性別</label>
        <select class="sex-type-select" v-model="searchCondition.sex_type">
          <option value="">---</option>
          <option v-for="(sexType, index) in userBasicData.SEX_TYPES" :key="'sexTypeSelect' + index" :value="index">{{ sexType }}</option>
        </select>
      </div>
      <div class="search-item">
        <label>都道府県</label>
        <select class="sex-type-select" v-model="searchCondition.prefecture">
          <option v-for="prefCode in userAddressData.PREFECTURE_CODE_JP" :key="'prefectureSelect' + prefCode[0]" :value="prefCode[0]">{{ prefCode[1] }}</option>
        </select>
      </div>
      <div class="search-item"><label>市町村区</label><input type="text" size="10" v-model="searchCondition.city"></div>
      <div class="search-item"><label>住所詳細</label><input type="text" size="20" v-model="searchCondition.address"></div>
      <div class="search-item"><label>電話番号</label><input type="text" size="20" v-model="searchCondition.tel"></div>
      <div class="search-item"><label>FAX番号</label><input type="text" size="20" v-model="searchCondition.fax"></div>
      <div class="search-item"><label>携帯電話番号</label><input type="text" size="20" v-model="searchCondition.cellphone"></div>
      <div class="organization-condition">
        <h3>施設による絞り込み</h3>
        <horus-search-organization-column
          :searchResultOrganization="searchedOrganization"
          :existOrganizations="[]"
          :sentOrganizations="[]"
          :receiveOrganizations="[]"
          :searchType="''"
          v-on:searchFinishedEvent="searchOrgFinished"
          v-on:searchFailedEvent="searchOrgFailed"
          />
        <div class="targetOrganization" v-if="searchedOrganization">
          条件
          <label><input type="radio" v-model="orgRelation" value="related">かかりつけ</label>
          <label><input type="radio" v-model="orgRelation" value="member">施設職員</label>
          <label><input type="radio" v-model="orgRelation" value="manager">施設管理者</label>
          <label><input type="radio" v-model="orgRelation" value="agent">設定代行者</label>
          <label><input type="radio" v-model="orgRelation" value="deputized">設定代行関連</label>
        </div>
        <button class="organization-reset" @click="resetOrganization">施設のリセット</button>
      </div>
      <div class="search-controll">
        <button class="reset-bth" @click="resetCondition">条件リセット</button>
        <button class="search-start-btn" @click="search">検索開始</button>
      </div>
    </div>
    <div class="user-search-result">
      <h3>検索結果</h3>
      <div class="result-message">{{ searchResultMessage }}</div>
      <div class="result-list">
        <table class="quited-users">
          <tr><th>NBID</th><th>氏名(カナ)</th><th>生年月日</th><th>性別</th><th>メールアドレス</th><th>&nbsp;</th></tr>
          <tr v-for="user in searchResultUsers" :key="'seachResultList' + user.id">
            <td>{{ user.nbid }}</td>
            <td>{{ user.familyName + ' ' + user.givenName }}({{ user.familyNameKana + ' ' + user.givenNameKana }})</td>
            <td>{{ user.birth_date }}</td>
            <td>{{ user.mail }}</td>
            <td class="controll"><button class="show-detail-btn" @click="resetMail(user)">メールアドレスの変更</button></td>
          </tr>
        </table>
      </div>
    </div>
    <horus-system-quited-user-detail-modal
      :showQuitedUserDetailtModal="showQuitedUserDetailtModal"
      :user="detailTargetUser"
      v-on:restoreEvent="restoreUser"
      v-on:removePhisicalEvent="removePhisical"
      v-on:closeModalEvent="closeDetailModal"
      />
    <!-- user meil edit modal -->
    <horus-user-mail-hard-change-modal
      :targetUser="mailChangeTarget"
      :showUserMailEditModal="showUserMailEditModal"
      v-on:cancelSubmitEvent="cancelUserMailSubmit"
      v-on:submitEvent="submitUserMailChange"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author,
  TimelineOwner
} from '@/models'

import {
  SystemTotalization,
  User
} from '@/api'

import HorusSystemQuitedUserDetailModal from '@/components/manageSystem/user/HorusSystemQuitedUserDetailModal.vue'
import HorusUserMailHardChangeModal from '@/components/manageSystem/user/HorusUserMailHardChangeModal.vue'
import HorusSearchOrganizationColumn from '@/components/common/HorusSearchOrganizationColumn.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as USER_BASIC from '@/assets/constants/userBasicData.js'
import * as USER_ADDRESS from '@/assets/constants/addressData.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManageUserView',

  components: {
    HorusSystemQuitedUserDetailModal,
    HorusSearchOrganizationColumn,
    HorusUserMailHardChangeModal,
    HorusConfirmDialog
  },

  data () {
    return {
      systemSimpleData: {},
      quitedUsers: [],
      showQuitedUserDetailtModal: false,
      detailTargetUser: null,
      searchedOrganization: null,
      searchCondition: {
        nbid: '',
        mail: '',
        family_name: '',
        given_name: '',
        family_name_kana: '',
        given_name_kana: '',
        birth_date: '',
        sex_type: '',
        prefecture: '',
        city: '',
        address: '',
        tel: '',
        fax: '',
        cellphone: ''
      },
      orgRelation: '',
      searchResultMessage: '',
      searchResultUsers: [],
      showUserMailEditModal: false,
      mailChangeTarget: null,
      userBasicData: USER_BASIC,
      userAddressData: USER_ADDRESS
    }
  },

  created () {
    this.loadSimpleData()
    this.loadQuitedUsers()
  },

  mounted () {
    this.loadSimpleData()
    this.loadQuitedUsers()
  },

  methods: {
    loadSimpleData () {
      SystemTotalization.fetchSimpleData().then(res => {
        this.systemSimpleData = res.result
      })
    },

    loadQuitedUsers () {
      User.quitedList().then(res => {
        if (res.users.length > 0) {
          TimelineOwner.insertOrUpdate({ data: res.users }).then(entries => {
            this.quitedUsers = entries.timelineOwners
            var authorIDList = []
            for(var user of this.quitedUsers) {
              authorIDList.push(user.author_id)
            }
            Author.api().fetchByID({idList: authorIDList})
          })
        } else {
          this.quitedUsers = []
        }
      })
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    showDetail (user) {
      this.detailTargetUser = user
      this.showQuitedUserDetailtModal = true
    },

    closeDetailModal () {
      this.showQuitedUserDetailtModal = false
    },

    restoreUser (user) {
      this.showConfirmDialog(
        'ユーザー[' + user.nbid + ']の復帰',
        '退会前に設定されていた状態に戻します。\n状態には、ログインパスワード、かかりつけ、設定代行、家族関連、職員及び施設管理者情報全てを含みます。\nこの操作は後戻りできません。\n復帰させますか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeRestoreUser(user) }
      )
    },

    executeRestoreUser (user) {
      User.restoreUser({targetUserID: user.id}).then(() => {
        this.showConfirmDialog(
          'ユーザー[' + user.nbid + ']を復帰させました',
          'ユーザー復帰プロセスが完了しました。\nユーザーの情報は、NBID、パスワードを含め、復帰前の状態に戻りました。',
          DIALOG_TYPE.NOTIFY,
          () => {
            this.showQuitedUserDetailtModal = false
            this.loadSimpleData()
            this.loadQuitedUsers()
          }
        )
      }).catch(error => {
        console.error(error.response)
      })
    },

    removePhisical (user) {
      console.log('on caller')
      this.showConfirmDialog(
        'ユーザー[' + user.nbid + ']の物理削除',
        'ユーザーアカウントを物理削除します。\n物理削除した場合、データベース上からこのアカウントの情報の一切が削除され、元に戻す事は出来ません。\nこの操作は後戻りできません。\n物理削除しますか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeDestroyUserPhisical(user) }
      )
    },

    executeDestroyUserPhisical (user) {
      User.destroyUserPhisical({ targetUserID: user.id }).then(() => {
        this.showConfirmDialog(
          'ユーザー[' + user.nbid + ']を物理削除しました',
          'ユーザーの情報は、データベース上から完全に抹消されました',
          DIALOG_TYPE.NOTIFY,
          () => {
            this.showQuitedUserDetailtModal = false
            this.loadSimpleData()
            this.loadQuitedUsers()
          }
        )
      }).catch(error => {
        console.error(error.response)
      })
    },

    searchOrgFinished (resultOrg) {
      this.searchedOrganization = resultOrg
    },

    searchOrgFailed () {
      this.searchedOrganization = null
    },

    resetOrganization () {
      this.searchedOrganization = null
    },

    resetCondition () {
      this.searchedOrganization = null
      this.searchCondition = {
        nbid: '',
        mail: '',
        family_name: '',
        given_name: '',
        family_name_kana: '',
        given_name_kana: '',
        birth_date: '',
        sex_type: '',
        prefecture: '',
        city: '',
        address: '',
        tel: '',
        fax: '',
        cellphone: ''
      }
    },

    search () {
      var searchParams = Object.assign({}, this.searchCondition)
      if (this.searchedOrganization && this.orgRelation !== '') {
        searchParams.organization_id = this.searchedOrganization.id
        searchParams.relation = this.orgRelation
      }
      if (searchParams.prefecture === '00') {
        searchParams.prefecture = ''
      }
      User.search({ searchConditions: searchParams }).then(res => {
        this.searchResultMessage = '検索結果: ' + res.count + '件'
        if (res.users) {
          this.searchResultUsers = res.users
        } else {
          this.searchResultMessage = this.searchResultMessage + ' ※検索結果が多すぎるため、結果をリスト表示しません。'
          this.searchResultUsers = []
        }
      }).catch(error => {
        console.error(error.response)
        this.searchResultUsers = []
      })
    },

    resetMail (user) {
      this.mailChangeTarget = user
      this.showUserMailEditModal = true
    },

    cancelUserMailSubmit (callback) {
      console.log('cacel in profile view')
      this.showUserMailEditModal = false
      this.mailChangeTarget = null
      callback()
    },

    submitUserMailChange (formData) {
      User.hardResetMail({formData: formData}).then(() => {
        this.showNotifyDialog('メールアドレス強制変更', 'メールアドレスを強制変更しました。\n確認のメールが送信されていますので、変更確認のメールが到達しているかの確認を行ってください。')
        this.showUserMailEditModal = false
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('メールアドレス強制変更の失敗', 'メールアドレスの強制変更に失敗しました。')
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-manage-user-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .simple-list {
    .list-element {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: 1px solid #ddd;
      background-color: #fff;
    }
  }
  .quited-users {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-collapse: collapse;
    th {
      padding: 5px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
    td {
      padding: 5px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      &.controll {
        text-align: right;
      }
      button {
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .user-search-form {
    padding: 10px;
    border: 1px solid #aaa;
    .caption {
      padding: 5px;
      border: 1px solid #ddd;
      background-color: #fff;
      font-size: 0.9em;
    }
    .search-item {
      margin: 5px 0;
      label {
        margin-right: 10px;
        display: inline-block;
        width: 120px;
        text-align: right;
      }
    }
    .search-controll {
      padding: 10px 0;
      .reset-bth {
        padding: 3px;
        border-radius: 3px;
        font-weight: bold;
        background-color: #fff;
        border-color: #666;
        color: #666;
      }
      .search-start-btn {
        padding: 3px;
        border-radius: 3px;
        font-weight: bold;
        background-color: #666;
        border-color: #666;
        color: #fff;
      }
    }
  }
  .organization-condition {
    padding: 10px;
    border: 1px solid #aaa;
    h3 {
      margin: 0;
      font-size: 1em;
      font-weight: bold;
    }
    .targetOrganization {
      label {
        margin-right: 20px;
      }
    }
    .organization-reset {
        padding: 2px;
        border-radius: 3px;
        font-weight: bold;
        background-color: #fff;
        border-color: #666;
        color: #666;
    }
  }
  .user-search-result {
    margin: 10px 0;
    h3 {
      margin: 0;
      font-size: 1em;
      font-weight: bold;
    }
    .result-message {
      padding: 10px;
      border: 1px solid #aaa;
    }
  }
}
</style>
