<template>
  <div class="content-line body-temperature">
    <div class="vital-value-column">
      <span class="label">体温</span>
      <span class="value">{{ vitalBodyTemperature.value.toFixed(1) }}</span>
      <span class="unit">℃</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusVitalBodyTemperatureColumn',

  props: {
    vitalBodyTemperature: Object
  }
}
</script>

<style lang="scss" scoped>
.content-line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-top: 5px;
  .vital-value-column {
    display: flex;
    padding-left: 20px;
    .label {
      align-self: flex-start;
      margin-right: 5px;
      font-size: 0.9em;
    }
    .value {
      align-self: center;
      font-size: 1.5em;
      font-weight: bold;
      color: #666;
    }
    .unit {
      align-self: flex-end;
      font-size: 0.9em;
    }
  }
}
</style>
