<template>
  <div class="horus-left-column-nudged-record">
    <img :src="nudgedRecord.author.portrateSrc" class="nudge-executer-portrate" />
    <div class="record">
      <div class="record-info" @click="showModal">{{ recordTitle }}</div>
      <div class="record-content">{{ recordContent }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusLeftColumnNudgedRecord',

  props: {
    nudgedRecord: Object
  },

  computed: {
    recordTitle () {
      if (this.nudgedRecord.record.targetAt) {
        return (this.nudgedRecord.record.targetAt.getMonth() + 1) + '月' +
                this.nudgedRecord.record.targetAt.getDate() + '日' +
                this.nudgedRecord.record.targetAt.getHours() + ':' +
                this.nudgedRecord.record.targetAt.getMinutes() +
                ' ' + this.nudgedRecord.author.name
      } else {
        return ''
      }
    },

    recordContent () {
      var text = ''
      if (this.nudgedRecord.record.textComment.length > 0) {
        text = this.nudgedRecord.record.textComment
      } else {
        if (this.nudgedRecord.record.photoReports.length > 0) {
          text += '写真: ' + this.nudgedRecord.record.photoReports.length + '枚　'
        }
        if (this.nudgedRecord.record.fileReports.length > 0) {
          text += 'ファイル: ' + this.nudgedRecord.record.fileReports.length + '個　'
        }
        if (this.nudgedRecord.record.medicalExamination) {
          text += 'バイタル: ' + this.nudgedRecord.record.medicalExamination.summaryText
        }
        if (this.nudgedRecord.record.drugEntry) {
          text += 'お薬情報: '
          if (this.nudgedRecord.record.drugEntry.comment) {
            text += this.nudgedRecord.record.drugEntry.comment
          }
          if (this.nudgedRecord.record.drugEntry.drugEntryPhotos) {
            text += '写真: ' + this.nudgedRecord.record.drugEntry.drugEntryPhotos.length + '枚　'
          }
        }
      }
      if (text.length > 21) {
        return text.substr(0, 21) + '...'
      }
      return text
    }
  },

  methods: {
    showModal () {
      this.$emit('showNudgedRecordModalEvent', this.nudgedRecord)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-left-column-nudged-record {
  display: flex;
  margin: 0 10px 10px 10px;
  .nudge-executer-portrate {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  .record {
    width: 100%;
    border-bottom: 1px solid #aaa;
  }
  .record-info {
    font-size: 0.9em;
    color: #999;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .record-content {
    font-size: 0.9em;
    color: #333;
  }
}
</style>
