<template>
  <div class="horus-receive-invitation-column labeled_content_block">
    <div class="labeled_content_single_block">
      <label>送信者名</label>
      <div class="content">{{ receiveRequest.user_name }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>住所</label>
      <div class="content">{{ receiveRequest.user_address }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>期限</label>
      <div class="content">{{ limitAtStr }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>メッセージ</label>
      <div class="content" v-html="receiveRequestMessage"></div>
    </div>
    <div class="content-controll-block">
      <button type="button" @click="confirmRequest">承認もしくは拒否の処理</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusReceiveInvitationColumn',

  props: {
    receiveRequest: Object
  },

  computed: {
    limitAtStr () {
      if (this.receiveRequest && this.receiveRequest.limit_at) {
        return moment(this.receiveRequest.limit_at).format('YYYY/MM/DD HH:mm')
      } else {
        return ''
      }
    },

    receiveRequestMessage () {
      if (this.receiveRequest.message_content && this.receiveRequest.message_content.length > 0) {
        return this.receiveRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return '家族案内のメッセージはありません。'
      }
    }
  },

  methods: {
    confirmRequest () {
      this.$emit('confirmRequestEvent', this.receiveRequest)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
