<template>
  <div class="horus-system-management-organization-detail-column">
    <div class="navigation"><button class="back-btn" @click="goOrgList">一覧へ戻る</button></div>
    <h2>{{ organization.name }}<img src="/static/images/pencil_icon.png" @click="editOrganization" /></h2>
    <div class="list-data">
      <div class="list-data-line">
        <div class="labels"><label>管理団体</label></div>
        <div class="content">{{ areaBlockName }}</div><img src="/static/images/pencil_icon.png" @click="changeAreaBlock" v-if="systemManager" />
      </div>
      <div class="list-data-line">
        <div class="labels"><label>施設名称</label></div>
        <div class="content">{{ organization.name }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>ヨミガナ</label></div>
        <div class="content">{{ organization.nameKana }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>略称</label></div>
        <div class="content">{{ organization.shortName }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>郵便番号</label></div>
        <div class="content">{{ organization.zipCode }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>住所</label></div>
        <div class="content">{{ fullAddrss }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>電話番号</label></div>
        <div class="content">{{ organization.tel }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>メールアドレス</label></div>
        <div class="content">{{ organization.mail }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>施設種別</label></div>
        <div class="content">{{ organization.organization_type.name }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>タグ</label></div>
        <div class="content">{{ tagsOfOrganization }}</div><img src="/static/images/pencil_icon.png" @click="editOptions" />
      </div>
      <div class="list-data-line">
        <div class="labels"><label>設定代行権限</label></div>
        <div class="content">{{ deputizableStr }}</div><img src="/static/images/pencil_icon.png" @click="editOptions" />
      </div>
      <div class="list-data-line">
        <div class="labels"><label>追加機能</label></div>
        <div class="content">{{ additionalFunctions }}</div><img src="/static/images/pencil_icon.png" @click="editOptions" />
      </div>
      <div class="list-data-line">
        <div class="labels"><label>第三者連携サービス</label></div>
        <div class="content">{{ usingThirdPartyLinkages }}</div><img src="/static/images/pencil_icon.png" @click="editOptions" />
      </div>
      <div class="list-data-line">
        <div class="labels"><label>施設職員数</label></div>
        <div class="content">{{ organization.member_count }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>かかりつけ利用者数</label></div>
        <div class="content">{{ organization.related_user_count }}</div>
      </div>
    </div><!-- list data -->
    <h2>施設管理者</h2>
    <table class="manager-table">
      <tr><th>氏名</th><th>NBID</th><th>メールアドレス</th><th>状態</th></tr>
      <tr
        v-for="manager in managers"
        :key="'sysOrgManager' + manager.id"
        >
        <td>{{ manager.author.name }}</td>
        <td>{{ manager.nbid }}</td>
        <td>{{ manager.author.mail }}</td>
        <td>正常</td>
      </tr>
    </table>
    <h2>管理ユーザー案内&nbsp;<button type="button" class="new-btn" @click="createInvitation">新規作成</button></h2>
    <table class="invitation-table">
      <tr><th>名前</th><th>メールアドレス</th><th>期限</th><th>状態</th><th>&nbsp;</th></tr>
      <tr
        v-for="invitation in managerInvitations"
        :key="'sysOrgManInv' + invitation.id"
        >
        <td>{{ invitation.name }}</td>
        <td>{{ invitation.mail }}</td>
        <td>{{ limitAtStr(invitation) }}</td>
        <td>{{ invitation.status }}</td>
        <td><img src="/static/images/bucket_icon.png" @click="removeInvitation(invitation)" /></td>
      </tr>
    </table>
    <div class="revoke" v-if="organization.ready_to_revoke">
      <h2>退会申請</h2>
      <div class="prepared" v-if="organization.revoke_log && !organization.revoke_log.restored && (!organization.deleted || !organization.revoke_log.revoked_at)">
        退会申請準備中<br/>
        施設管理者から施設の退会申請が申し込まれています。削除処理もしくは、退会処理キャンセルをしてください。<br/>
        <span class="revoke-key">削除キー : [{{ organization.revoke_key }}]</span>
        <div class="revoke-form">
          <label>削除キー<input type="text" v-model="organizationRevokeKey" /></label>
          <button class="cancel-revoke-btn" @click="cancelRevoke">退会処理キャンセル</button>
          <button class="execute-revoke-btn" @click="revoke">退会処理実行</button>
        </div>
      </div>
      <div class="revoked" v-if="organization.deleted && organization.revoke_log && !organization.revoke_log.restored && organization.revoke_log.revoked_at">
        <span class="revoke-key">[{{ revokeAt }}]退会済み 削除キー : [{{ organization.revoke_key }}]</span>
        <div class="revoke-form">
          <button class="restore-btn" @click="restore">施設復帰処理実行</button>
        </div>
      </div>
    </div>
    <div class="delete" v-if="organization.unused">
      <h2>施設削除</h2>
      <div class="deletable">
        この施設は、管理者、職員、かかりつけ利用者が一人もいない未使用の施設であるため、削除が可能です。<br/>
        <button class="delete-org-btn" @click="deleteOrganization">施設削除</button>
      </div>
    </div>
    <horus-system-organization-edit-modal
      :sourceOrganization="organization"
      :showOrganizationEditModal="showOrganizationEditModal"
      :systemManager="systemManager"
      :areaBlockOperator="areaBlockOperator"
      :submitString="'更 新'"
      v-on:submitCancelEvent="cencelEditSubmit"
      v-on:submitEvent="submitEdit"
      />
    <horus-system-organization-option-edit-modal
      :sourceOrganization="organization"
      :showOrganizationOptionEditModal="showOrganizationOptionEditModal"
      :organizationTags="organizationTags"
      :thirdPartyLinkages="thirdPartyLinkages"
      v-on:cancelSubmitEvent="cancelOptionEdit"
      v-on:submitEvent="submitOption"
      />
    <horus-manage-organization-area-block-modal
      :showModal="organizationAreBlockDisplay"
      :sourceOrganization="organization"
      :areaBlocks="areaBlocks"
      v-on:closeModalEvent="areaBlockUpdated"
      />
    <horus-system-organization-manager-invitation-modal
      :organization="organization"
      :showOrganizationManagerInvitationModal="showOrganizationManagerInvitationModal"
      v-on:cancelSubmitEvent="cancelManagerInvitation"
      v-on:submitEvent="submitManagerInvitation"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author,
  Organization
} from '@/models'

import {
  OrganizationMember,
  OrganizationManagerInvitation,
  ThirdPartyLinkage
} from '@/api'

import moment from 'moment'

import HorusSystemOrganizationEditModal from '@/components/manageSystem/organization/HorusSystemOrganizationEditModal.vue'
import HorusSystemOrganizationOptionEditModal from '@/components/manageSystem/organization/HorusSystemOrganizationOptionEditModal.vue'
import HorusSystemOrganizationManagerInvitationModal from '@/components/manageSystem/organization/HorusSystemOrganizationManagerInvitationModal.vue'
import HorusManageOrganizationAreaBlockModal from '@/components/manageSystem/organization/HorusManageOrganizationAreaBlockModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as ADDRESS_DATA from '@/assets/constants/addressData.js'
import * as ORGANIZATION_FUNCTIONS from '@/assets/constants/organizationFunctions.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManagementOrganizationDetailColumn',

  components: {
    HorusSystemOrganizationEditModal,
    HorusSystemOrganizationOptionEditModal,
    HorusSystemOrganizationManagerInvitationModal,
    HorusManageOrganizationAreaBlockModal,
    HorusConfirmDialog
  },

  props: {
    systemManager: Object,
    areaBlockOperator: Object,
    sourceOrganization: Object,
    organizationTags: Array,
    areaBlocks: Array
  },

  data () {
    return {
      organization: null,
      thirdPartyLinkages: [],
      managers: [],
      members: [],
      managerInvitations: [],
      showOrganizationEditModal: false,
      showOrganizationOptionEditModal: false,
      showOrganizationManagerInvitationModal: false,
      organizationAreBlockDisplay: false,
      addressData: ADDRESS_DATA,
      organizationFunctions: ORGANIZATION_FUNCTIONS,
      organizationRevokeKey: ''
    }
  },

  computed: {
    areaBlockName () {
      if (this.organization.area_block_id) {
        var target = this.areaBlocks.find(areablock => areablock.id === this.organization.area_block_id)
        if (target) {
          return target.name
        }
      }
      return '所属なし'
    },

    numberForHuman () {
      if (this.organization.organizationNumber) {
        return this.organization.organizationNumber.substr(0, 4) + '-' + this.organization.organizationNumber.substr(4, 4) + '-' + this.organization.organizationNumber.substr(8, 4) + '-' + this.organization.organizationNumber.substr(12, 4)
      } else {
        return ''
      }
    },

    fullAddrss () {
      var pref = this.addressData.PREFECTURE_CODE_JP.find(code => code[0] === this.organization.prefecture)
      var addressStr = ''
      if (pref) {
        addressStr += pref[1]
      }
      addressStr += this.organization.city + this.organization.addressDetail
      return addressStr
    },

    tagsOfOrganization () {
      var tagnames = []
      this.organizationTags.forEach(tag => {
        if (this.organization.tags.some(otag => otag === tag.id)) {
          tagnames.push(tag.name)
        }
      })
      return tagnames.join(', ')
    },

    additionalFunctions () {
      var functions = []
      this.organization.additionalFunctions.forEach(addFunc => {
        var orgFunc = this.organizationFunctions.ORGANIZATION_FUNCTIONS.find(func => func.id === addFunc)
        if (orgFunc) {
          functions.push(orgFunc.name)
        }
      })
      if (functions.length > 0) {
        return functions.join(', ')
      } else {
        return '利用なし'
      }
    },

    usingThirdPartyLinkages () {
      var tpls = []
      this.organization.thirdPartyLinkages.forEach(tpl => {
        tpls.push(tpl.name)
      })
      if (tpls.length > 0) {
        return tpls.join(', ')
      } else {
        return '利用なし'
      }
    },

    deputizableStr () {
      if (this.organization.deputizable) {
        return 'あり'
      } else {
        return 'なし'
      }
    },

    revokeAt () {
      if (this.organization.revoke_log && this.organization.revoke_log.revoked_at) {
        var date = new Date(this.organization.revoke_log.revoked_at)
        return moment(date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    }
  },

  mounted () {
    this.loadThridPartyLinkages()
  },

  watch: {
    sourceOrganization: { handler: 'loadDatas', immediate: true }
  },

  methods: {
    loadDatas () {
      if (this.sourceOrganization) {
        this.organization = Object.assign(this.sourceOrganization)
        this.loadMembers()
        this.loadManagerInvitations()
      }
    },

    loadThridPartyLinkages () {
      ThirdPartyLinkage.fetch().then(res => {
        this.thirdPartyLinkages = res.result.third_party_linkages
      })
    },

    reloadOrganization () {
      Organization.api().fetchWithSummary({ organizationID: this.organization.id }).then(res => {
        this.organization = res.organization
      }).catch(error => {
        console.error(error.response)
      })
    },

    async loadMembers () {
      if (this.organization) {
        let res = await OrganizationMember.fetch({ organizationID: this.organization.id })
        this.members = res.organization_members
        var unloadAuthors = []
        for(var mem of this.members) {
          if (!Author.find(mem.author_id)) {
            if (unloadAuthors.indexOf(mem.author_id) < 0) unloadAuthors.push(mem.author_id)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ idList: unloadAuthors })
        }
        for(var member of this.members) {
          member.author = await Author.find(member.author_id)
        }
        this.managers = this.members.filter(member => (member.member_manager || member.relation_manager || member.deputize_manager))
      }
    },

    loadManagerInvitations () {
      OrganizationManagerInvitation.fetch({ organizationID: this.organization.id }).then(res => {
        this.managerInvitations = res.organization_manager_invitation_tokens
      })
    },

    limitAtStr (invitation) {
      return moment(invitation.limit_at).format('YYYY/MM/DD HH:mm')
    },

    editOrganization () {
      this.showOrganizationEditModal = true
    },

    changeAreaBlock () {
      this.organizationAreBlockDisplay = true
    },

    areaBlockUpdated () {
      this.showNotifyDialog('所属管理団体設定完了', '施設の所属する管理団体を更新しました。')
      this.reloadOrganization()
      this.organizationAreBlockDisplay = false
    },

    editOptions () {
      if (this.organization.deleted || (this.organization.revoke_log && !this.organization.revoke_log.restored && (!this.organization.deleted || !this.organization.revoke_log.revoked_at))) {
        this.showNotifyDialog('施設オプション編集停止', '施設が削除済み、削除申請中であるため、編集を行うことはできません。')
      } else {
        this.showOrganizationOptionEditModal = true
      }
    },

    cancelOptionEdit (callback) {
      this.showOrganizationOptionEditModal = false
      callback()
    },

    submitOption (formData) {
      Organization.api().updateOptions({ formData: formData }).then(() => {
        // reload data
        Organization.api().fetchWithSummary({ organizationID: this.organization.id }).then(res => {
          this.organization = res.organization
          this.showOrganizationEditModal = false
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('施設データ取得失敗', '施設オプションの設定には成功しましたが、更新されたデータの取得に失敗しました。')
        })
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('施設オプション更新失敗', '施設オプションの設定に失敗しました。')
      }).finally(() => {
        this.showOrganizationOptionEditModal = false
      })
    },

    createInvitation () {
      this.showOrganizationManagerInvitationModal = true
    },

    cancelManagerInvitation (callback) {
      this.showOrganizationManagerInvitationModal = false
      callback()
    },

    submitManagerInvitation (formData, callback) {
      OrganizationManagerInvitation.create({ formData: formData }).then(() => {
        this.loadManagerInvitations()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('施設管理者案内送信失敗', '施設管理者案内の送信に失敗しました。')
      }).finally(() => {
        this.showOrganizationManagerInvitationModal = false
        callback()
      })
    },

    removeInvitation (invitation) {
      OrganizationManagerInvitation.destroy({ organizationID: this.organization.id, targetID: invitation.id }).then(() => {
        this.loadManagerInvitations()
        this.showNotifyDialog('施設管理者案内の削除完了', '施設管理者案内を削除しました。')
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('施設管理者案内の削除失敗', '施設管理者案内の削除に失敗しました。')
      })
    },

    goOrgList () {
      this.$emit('closeDetailEvent')
    },

    cencelEditSubmit () {
      this.showOrganizationEditModal = false
    },

    submitEdit (formData, callback) {
      Organization.api().update({ formData: formData }).then(() => {
        // reload data
        Organization.api().fetchWithSummary({ organizationID: this.organization.id }).then(() => {
          this.reloadOrganization()
          this.showOrganizationEditModal = false
          callback()
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('施設データ取得失敗', '施設のデータ更新には成功しましたが、更新されたデータの取得に失敗しました。')
        })
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('施設更新失敗', '施設の情報更新に失敗しました。')
      })
    },

    cancelRevoke () {
      if (this.organizationRevokeKey === this.organization.revoke_key) {
        this.showConfirmDialog(
          '施設退会申請のキャンセル',
          '施設退会申請をキャンセルします。\nキャンセルすると、施設退会申請が無効化されます。\n無効化された場合、退会申請に際して制限されていた機能が利用可能となり、施設管理者により再度施設の退会申請を行うまで退会処理は行えなくなります。\n退会申請をキャンセルしますか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeCancelRevoke() }
        )
      } else {
        this.showNotifyDialog('施設退会申請のキャンセル', '削除キーが一致しません。')
      }
    },

    executeCancelRevoke () {
      var formData = new FormData()
      formData.append('id', this.organization.id)
      formData.append('revoke_key', this.organizationRevokeKey)
      Organization.api().cancelRevoke({ formData: formData }).then(() => {
        this.showNotifyDialog('施設退会申請のキャンセル', '施設退会処理をキャンセルしました。\n申請された退会申請は失効し、現在、施設は通常の状態に戻っています。')
        this.reloadOrganization()
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('施設退会申請のキャンセル', '施設退会申請のキャンセルに失敗しました。')
      })
    },

    revoke () {
      if (this.organizationRevokeKey === this.organization.revoke_key) {
        this.showConfirmDialog(
          '施設退会の実行',
          '施設退会の処理を実行します。\n実行すると、施設が削除され、すべてのかかりつけ、職員の関連が削除されます。\n施設の退会を実行しますか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeRevoke() }
        )
      } else {
        this.showNotifyDialog('施設退会の実行', '削除キーが一致しません。')
      }
    },

    executeRevoke () {
      var formData = new FormData()
      formData.append('id', this.organization.id)
      formData.append('revoke_key', this.organizationRevokeKey)
      Organization.api().revoke({ formData: formData }).then(() => {
        this.showNotifyDialog('施設退会処理の実行完了', '施設退会処理を実行し、成功しました。\n対象の施設は削除されました。')
        this.reloadOrganization()
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('施設退会処理の実行', '施設退会処理の実行に失敗しました。' + error.response.data.message)
      })
    },

    restore () {
      this.showConfirmDialog(
        '退会済み施設の復帰実行',
        'この退会済み施設の復帰を実行します。\n実行すると、施設の状態が削除前の状態に戻り、施設管理者、施設職員、かかりつけの状況が復帰します。\n退会済み施設の復帰を実行しますか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeRestore() }
      )
    },

    executeRestore () {
      Organization.api().restore({ organizationID: this.organization.id }).then(() => {
        this.showNotifyDialog('退会済み施設の復帰完了', '退会済み施設の復帰処理を実行し、成功しました。\n対象の施設は復元されました。')
        this.reloadOrganization()
      }).catch(error => {
        console.log(error.response)
      })
    },

    deleteOrganization () {
      if (this.organization.manager_count < 1 && this.organization.member_count < 1 && this.organization.related_user_count < 1) {
        this.showConfirmDialog(
          '未使用施設の削除実行',
          'この未使用の施設の削除を実行します。\n実行すると、施設は物理的に削除されます。\n未使用施設の削除を実行しますか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeDestroy() }
        )
      } else {
        this.showNotifyDialog('施設の削除', 'この施設は未使用ではないため、削除できません。')
      }
    },

    executeDestroy () {
      Organization.api().destroy({ organizationID: this.organization.id }).then(() => {
        this.showConfirmDialog(
          '未使用施設の削除完了',
          '未使用の施設の削除を実行し、成功しました。\n対象の施設は物理的に削除されました。',
          DIALOG_TYPE.NOTIFY,
          () => { this.goOrgList() }
        )
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog('施設の削除失敗', '施設の削除に失敗しました。')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-management-organization-detail-column {
  .navigation {
    display: flex;
    justify-content: flex-end;
    .back-btn {
      padding: 3px 10px;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
    }
  }
  .list-data {
    background-color: #fff;
    .list-data-line {
      display: flex;
      border-bottom: 1px solid #aaa;
      .labels {
        margin-right: 10px;
        width: 150px;
        text-align: right;
      }
      .content {
        padding: 0;
        background-color: inherit;
      }
      img {
        margin-left: 20px;
        height: 20px;
      }
    }
  }
  table {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
    th {
      text-align: left;
      border-bottom: 1px solid #aaa;
    }
    td {
      border-bottom: 1px solid #aaa;
    }
  }
  .revoke {
    .revoke-key {
      font-size: 1.1em;
      font-weight: bold;
    }
    .revoke-form {
      margin: 5px 0;
      padding: 5px;
      border: 1px solid #aaa;
      input {
        margin: 0 10px;
      }
      button {
        margin-left: 10px;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: bold;
      }
      .cancel-revoke-btn {
        border-color: #666;
        background-color: #fff;
        color: #666;
      }
      .execute-revoke-btn {
        border-color: #666;
        background-color: #666;
        color: #fff;
      }
      .restore-btn {
        border-color: #666;
        background-color: #666;
        color: #fff;
      }
    }
  }
  .delete {
    .deletable {
      color: #c43d53;
      .delete-org-btn {
        margin: 10px 0;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: bold;
        border-color: #666;
        background-color: #666;
        color: #fff;
      }
    }
  }
}
</style>
