<template>
  <div class="horus-reporter-post-history-view">
    <div class="sub-header">
      <div class="profile" v-if="timelineOwner">
        <div class="main-portrait">
          <a @click="openSubHeaderMenu" v-click-outside="closeSubHeaderMenu">
            <img :src="timelineOwner.portrateSrc">
            <ul class="timeline-personal-menu" v-show="subHeaderMenuVisible">
              <li><a @click="loadRecords">最新の情報に更新</a></li>
            </ul>
          </a>
        </div>
        <div class="target-person-column" v-if="timelineOwner">
          <h1>{{ timelineOwner.fullName }}さん</h1>{{ timelineOwnerProfile }}
        </div>
      </div>
    </div>
    <div class="main-column">
      <div class="post-form">
        <horus-record-post-form-for-reporter
          v-if="loggedInAuthor && timelineOwner"
          ref="recordPostForm"
          :signinReporter="signinReporter"
          :author="loggedInAuthor"
          :timelineOwner="timelineOwner"
          :selectedTags="selectedTagsForPost"
          :createTags="createTagsForPost"
          :targetDate="postTargetDate"
          :postFormAvailable="postFormAvailable"
          :postFormActive="postFormActive"
          v-on:activatePostFormEvent="activatePostForm"
          v-on:openTagSelectionModalEvent="tagSelectionForPost"
          v-on:removeSelectedTagEvent="removeSelectedTagFromPost"
          v-on:removeCreateTagEvent="removeNewPostTagForPost"
          v-on:setTargetDateForPostEvent="targetDateSelectionForPost"
          v-on:clearTargetDateForPostEvent="clearTargetDateForPost"
          v-on:showNotifyEvent="showNotifyDialog"
          v-on:postformClearEvent="confirmPostFormClear"
          v-on:submitPostFormEvent="submitPostForm"
          />
      </div>
      <div class="history-records">
        <!-- timeline narrow down controll -->
        <horus-narrow-down-hash-tags
          v-show="narrowDownTags.length > 0"
          :narrowDownTags="narrowDownTags"
          v-on:removeNarrowDownTagEvent="removeNarrowDownTag" />
        <div class="horus-normal-record-timeline" ref="mainTimelineInner" id="mainTimelineInner">
          <div class="month-splitter" v-if="timelineRecords[0] && timelineRecords[0].record">{{ timelineRecords[0].record.targetAt.getFullYear() + '年' + (timelineRecords[0].record.targetAt.getMonth() + 1) + '月' }}</div>
          <div v-if="timelineNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
          <horus-timeline-record
            v-else
            v-for="(timelineRecord, index) in timelineRecords"
            :timelineOwner="timelineOwner"
            :timelineRecord="timelineRecord"
            :loggedInAuthor="loggedInAuthor"
            :nextRecord="timelineRecords[index+1]"
            :lastWatchAt="timelineLastWatchAt"
            :key="'trmaintimelinedisplay' + timelineRecord.id"
            v-on:recordTagNarrowDownEvent="narrowDownByTag"
            v-on:showRecorPhotoEvent="showRecordPhoto"
            v-on:openRecordFileEvent="openRecordFile"
            v-on:destroyRecordEvent="destroyRecord"
            v-on:editRecordEvent="editRecord"
            />
          <div class="load-button-block" v-show="oldRecordsExist">
            <button type="button" class="load-btn" @click="loadOldRecords">以前のレポートを表示する</button>
          </div>
        </div>
      </div>
    </div>
    <horus-record-edit-for-reporter-modal
      v-if="loggedInAuthor && timelineOwner"
      :signinReporter="signinReporter"
      :author="loggedInAuthor"
      :timelineOwner="timelineOwner"
      :editRecord="editTargetRecord"
      :targetDate="editTargetDate"
      :selectedTags="selectedTagsForEdit"
      :createTags="createTagsForEdit"
      v-on:openTagSelectionModalEvent="tagSelectionForEdit"
      v-on:removeSelectedTagEvent="removeSelectedTagFromEdit"
      v-on:removeCreateTagEvent="removeNewPostTagForEdit"
      v-on:setTargetDateForPostEvent="targetDateSelectionForEdit"
      v-on:clearTargetDateForPostEvent="clearTargetDateForEdit"
      v-on:postformClearEvent="confirmEditFormClear"
      v-on:showNotifyEvent="showNotifyDialog"
      v-on:submitPostFormEvent="submitUpdateForm"
      />
    <horus-tag-narrow-down-modal
      :hashTags="hashTags"
      :activeTags="narrowDownTags"
      :showTagNarrowDownModal="showTagNarrowDownModal"
      v-on:tagNarrowDownModalCloseEvent="closeTagNarrowDownModal"
      v-on:updateNarrowDownTagEvent="updateNarrowDownTag"
      v-on:sumitNarrowDownTagEvent="submitNarrowDownTags"
      v-on:clearNarrowDownTagEvent="clearNarrowDownTags"
      v-on:showTagManageEvent="tagManageModalShow" />
    <horus-photo-modal
      :timelineOwner="timelineOwner"
      :photos="photoModalPhotos"
      :type="photoModalType"
      :author="photoModalAuthor"
      :targetAt="photoModalTargetAt"
      :title="photoModalTitle"
      :firstShow="photoModalFirst"
      :hashTags="photoModalHashTags"
      :showPhotoModal="photoModalShow"
      v-on:jumpToOriginEvent="jumpToOriginFromPhotoModal"
      v-on:photoModalCloseEvent="closePhotoModal"
      />
    <horus-tag-select-for-post-modal
      :showTagSelectionModal="showTagSelectionModal"
      :hashTags="hashTags"
      :activeTags="selectTargetsForTag"
      :createTags="createdTargetForTag"
      :signinReporter="signinReporter"
      v-on:tagSelectionModalCloseEvent="tagSelectionModalClose"
      v-on:updateSelectedTagEvent="updateSelectedTag"
      v-on:addNewPostTagEvent="addNewPostTag"
      v-on:clearSelectedTagEvent="clearSelectedTags"
      v-on:removeNewTagEvent="removeNewPostTag"
      />
    <horus-post-target-date-selection-modal
      :showDateSelectionModal="showDateSelectionModal"
      :initializeDate="postTargetDate"
      v-on:targetDateSelectionCloseEvent="targetDateSelectionModalClose"
      v-on:selectTargetDateCancelEvent="targetDateSelectionCancel"
      v-on:selectTargetDateCommitEvent="targetDateSelectionCommit"
      />
    <horus-sending-modal
      :showSendingModal="showSendingModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author,
  TimelineOwner,
  TimelineRecord,
  PhotoReport,
  FileReport,
  HashTag
} from '@/models'

import HorusRecordPostFormForReporter from '@/components/reporterOrganization/HorusRecordPostFormForReporter.vue'
import HorusRecordEditForReporterModal from '@/components/reporterOrganization/HorusRecordEditForReporterModal.vue'

import HorusNarrowDownHashTags from '@/components/timelineColumn/HorusNarrowDownHashTags.vue'
import HorusTimelineRecord from '@/components/timelineRecord/HorusTimelineRecord.vue'
import HorusTagNarrowDownModal from '@/components/leftColumn/HorusTagNarrowDownModal.vue'

import HorusTagSelectForPostModal from '@/components/postForm/HorusTagSelectForPostModal.vue' // mixin HorusTagSelection.js
import HorusPostTargetDateSelectionModal from '@/components/postForm/HorusPostTargetDateSelectionModal.vue'

import HorusPhotoModal from '@/components/common/HorusPhotoModal.vue'
import HorusSendingModal from '@/components/common/HorusSendingModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusTagSelection from '@/mixin/HorusTagSelection.js'
import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import vClickOutside from 'click-outside-vue3'
// import vClickOutside from 'click-outside-vue3'

export default {
  mixins: [HorusTagSelection, HorusConfirmDialogControll, HorusTagSelection],

  name: 'HorusReporterPostHistoryView',

  components: {
    HorusRecordPostFormForReporter,
    HorusRecordEditForReporterModal,
    HorusNarrowDownHashTags,
    HorusTimelineRecord,
    HorusPhotoModal,
    HorusTagNarrowDownModal,
    HorusTagSelectForPostModal,
    HorusPostTargetDateSelectionModal,
    HorusSendingModal,
    HorusConfirmDialog
  },

  props: {
    signinReporter: Object,
    loggedInAuthor: Object
  },

  data () {
    return {
      timelineOwner: null,
      hashTags: [],
      // subheader
      subHeaderMenuVisible: false,
      // timeline
      timelineRecords: [],
      loadReserveRecords: [],
      narrowDownTags: [],
      loadRecordLimit: 100,
      currentOffset: 0,
      oldRecordsExist: false,
      timelineLastWatchAt: null,
      // modal visible flags
      postFormAvailable: false,
      showTagNarrowDownModal: false,
      showTagSelectionModal: false,
      // photo modal attributes
      photoModalPhotos: [],
      photoModalType: '',
      photoModalAuthor: null,
      photoModalTargetAt: null,
      photoModalTitle: '',
      photoModalFirst: null,
      photoModalHashTags: [],
      photoModalShow: false,
      // loading flags
      hashTagNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      authorsNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      timelineNowLoading: true,
      // date selection modal
      showDateSelectionModal: false,
      selectTargetDate: null,
      // post controlls
      postFormActive: false,
      postTargetDate: { change: false, date: null },
      showSendingModal: false,
      // tag selection for post
      selectedTagsForPost: [],
      createTagsForPost: [],
      // record edit controlls
      editTargetRecord: null,
      editTargetDate: { change: false, date: null },
      selectedTagsForEdit: [],
      createTagsForEdit: []
    }
  },

  watch: {
    '$route': 'loadTimelineOwner',
    timelineOwner: { handler: 'loadRecords' },
    loadReserveRecords: { handler: 'loadReservedRecord' }
  },

  computed: {
    currentSearchOption () {
      var options = {}
      if (this.narrowDownTags.length > 0) {
        options.hash_tags = this.narrowDownTags.map(tag => tag.id)
      }
      return options
    },

    timelineOwnerProfile () {
      if (this.timelineOwner) {
        return this.timelineOwner.birthDateJp + '生 (' + this.timelineOwner.age + '才)' + this.timelineOwner.sexTypeJp
      }
      return ''
    }
  },

  mounted () {
    this.loadTimelineOwner()
  },

  methods: {
    async loadTimelineOwner () {
      console.log('loadTimelineOwner call, on reporter history view')
      if (this.$route.params.id) {
        await this.fetchTimelineOwner(this.$route.params.id)
        this.loadRecords()
        this.loadTimelineHashTags()
      } else {
        // ポータルに強制送還する
        console.error('timeline owner could not get')
      }
    },

    // 現在表示中のタイムラインのオーナーユーザーを取得して格納する。パラメータで指定されていない場合は、ログインユーザーがタイムラインオーナーとなる
    async fetchTimelineOwner (ownerID) {
      var owner = TimelineOwner.find(ownerID)
      if (owner === null) {
        await TimelineOwner.api().fetch({ timelineOwnerID: ownerID })
        this.timelineOwner = await TimelineOwner.find(ownerID)
      } else {
        this.timelineOwner = owner
      }
      if (!this.timelineOwner.autor) {
        await Author.api().fetchByID({ idList: [this.timelineOwner.author_id] })
      }
    },

    async loadRecords () {
      if (this.timelineOwner) {
        this.timelineNowLoading = true
        console.log('load NORMAL records')
        console.log('loading : ', this.timelineNowLoading)
        this.currentOffset = 0
        let res = await TimelineRecord.api().fetchIDListForRepoter({ timelineOwnerID: this.timelineOwner.id, limit: this.loadRecordLimit, offset: this.currentOffset, options: this.currentSearchOption })
        await this.reserveLoadRecords(res.recordIDList)
        this.timelineLastWatchAt = new Date(res.lastWatchAt)
        this.currentOffset = res.recordIDList.length
        if (this.loadRecordLimit > res.recordIDList.length) {
          this.oldRecordsExist = false
        } else {
          this.oldRecordsExist = true
        }
        this.timelineNowLoading = false
      }
    },

    async loadOldRecords () {
      if (this.timelineRecords.length > 0) {
        let res = await TimelineRecord.api().fetchIDListForRepoter({ timelineOwnerID: this.timelineOwner.id, limit: this.loadRecordLimit, offset: this.currentOffset, options: this.currentSearchOption })
        for(var recordHash of res.recordIDList) {
          var record = await TimelineRecord.find(recordHash.id)
          this.timelineRecords.push({id: recordHash.id, record: record})
          if (!record) {
            this.loadReserveRecords.push(recordHash)
          }
        }
        this.currentOffset = res.recordIDList.length
        if (this.loadRecordLimit > res.recordIDList.length) {
          this.oldRecordsExist = false
        }
      }
    },

    // 引数のrecordIDListは{id: id, update_at: update_at}の配列形式
    async reserveLoadRecords (recordIDList) {
      this.timelineRecords = []
      recordIDList.forEach(recordHash => {
        console.log(JSON.stringify(recordHash))
        var record = TimelineRecord.find(recordHash.id)
        console.log(record)
        this.timelineRecords.push({ id: recordHash.id, record: record })
        if (!record || record.update_at !== recordHash.update_at) {
          this.loadReserveRecords.push(recordHash.id)
        }
      })
    },

    async loadReservedRecord () {
      if (this.loadReserveRecords.length > 0) {
        var loadIDList = this.loadReserveRecords.slice(0, 10)
        var loadAuthorList = []
        await TimelineRecord.api().fetchForRepoter({ timelineOwnerID: this.timelineOwner.id, idList: loadIDList })
        for(var recordID of loadIDList) {
          for(var tr of this.timelineRecords) {
            if (tr.id === recordID) {
              tr.record = await TimelineRecord.find(recordID)
              if (!tr.record.author) {
                if (loadAuthorList.indexOf(tr.record.author_id) < 0) loadAuthorList.push(tr.record.author_id)
              }
            }
          }
        }
        this.loadReserveRecords.splice(0, 10)
        if (loadAuthorList.length > 0) {
          Author.api().fetchByID({ idList: loadAuthorList })
        }
      }
    },

    updateRecords () {
      console.log('updateRecords call,')
      if (this.timelineOwner) {
        TimelineRecord.api().fetchUpdateListForRepoter({ timelineOwnerID: this.timelineOwner.id }).then(res => {
          console.log('update record fetch success.')
          for(var deletedRecord of res.deletedRecords) {
            for(var [index, timelineRecord] of this.timelineRecords) {
              if (timelineRecord.id === deletedRecord) {
                this.timelineRecords.splice(index, 1)
              }
            }
          }
          var updatedRecords = []
          var addedRecords = []
          for(var updateRecord of res.records) {
            var recordExist = false
            for(var tRecord of this.timelineRecords) {
              var uRecord = TimelineRecord.find(updateRecord)
              if (tRecord.id === updateRecord) {
                tRecord.record = uRecord
                updatedRecords.push(uRecord)
                recordExist = true
              }
            }
            if (!recordExist) {
              var aRecord = TimelineRecord.find(updateRecord)
              this.timelineRecords.unshift({ id: updateRecord, record: aRecord })
              addedRecords.push(aRecord)
            }
          }
          this.timelineRecords.sort((f, s) => {
            if (f.record && s.record && f.record.targetAt > s.record.targetAt) return -1
            if (f.record && s.record && f.record.targetAt < s.record.targetAt) return 1
            return 0
          })
          this.$emit('timelineUpdateFinishEvent', addedRecords, updatedRecords)
        }).catch(err => {
          console.error(err)
        })
      }
    },

    async loadTimelineHashTags () {
      this.hashTagNowLoading = true
      await HashTag.api().fetch({ timelineOwnerID: this.timelineOwner.id }).then(() => {
        this.hashTags = HashTag.query().where('user_id', this.timelineOwner.id).get()
      })
      this.narrowDownTags = []
      this.hashTagNowLoading = false
      this.postFormAvailable = true
    },

    // subheader events
    openSubHeaderMenu () {
      this.subHeaderMenuVisible = true
    },

    closeSubHeaderMenu () {
      console.log('close subhetdermenu')
      this.subHeaderMenuVisible = false
    },

    // post record events
    activatePostForm () {
      this.postFormActive = true
    },

    tagSelectionForPost () {
      this.selectTargetsForTag = this.selectedTagsForPost
      this.createdTargetForTag = this.createTagsForPost
      this.showTagSelectionModal = true
    },

    removeSelectedTagFromPost (targetTag) {
      var tagIndex = this.selectedTagsForPost.indexOf(targetTag)
      console.log('remove selected tag from post : ' + tagIndex)
      if (tagIndex >= 0) {
        this.selectedTagsForPost.splice(tagIndex, 1)
      }
    },

    removeNewPostTagForPost (index) {
      this.createTagsForPost.splice(index, 1)
    },

    targetDateSelectionForPost () {
      this.selectTargetDate = this.postTargetDate
      this.targetDateSelectionModalOpen()
    },

    clearTargetDateForPost () {
      this.postTargetDate.change = false
      this.postTargetDate.date = null
    },

    confirmPostFormClear  (callBack) {
      this.showConfirmDialog(
        'フォームのクリア',
        '投稿フォームに入力した内容を全て消去します\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.clearPostForm()
          callBack()
        }
      )
    },

    clearPostForm () {
      this.postTargetDate = { change: false, date: null }
      this.selectedTagsForPost = []
      this.createTagsForPost = []
      this.postFormActive = false
    },

    async submitPostForm (formData, successCallback) {
      this.showSendingModal = true
      await TimelineRecord.api().createForRepoter({ formData: formData }).then(async () => {
        await successCallback()
        await this.clearPostForm()
        // checkServerUpdate
        await this.updateRecords()
        await this.loadTimelineHashTags()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('投稿失敗', '投稿の送信に失敗しました')
      }).finally(() => {
        this.showSendingModal = false
      })
    },

    // narrow down tags events
    removeNarrowDownTag (tag) {
      const tagIndex = this.narrowDownTags.indexOf(tag)
      if (tagIndex >= 0) {
        this.narrowDownTags.splice(tagIndex, 1)
        this.loadRecords()
      }
    },

    // timeline events
    narrowDownByTag  (tag) {
      this.narrowDownTags = [tag]
      this.loadRecords()
    },

    showRecordPhoto  (timelineRecord, firstShowIndex) {
      if (timelineRecord.photoReports.length > 0) {
        if (!firstShowIndex || firstShowIndex > timelineRecord.photoReports.length - 1) {
          firstShowIndex = 0
        }
      }
      this.photoModalPhotos = timelineRecord.photoReports
      this.photoModalAuthor = timelineRecord.author
      this.photoModalTargetAt = timelineRecord.targetAt
      this.photoModalType = 'photoReport'
      this.photoModalTitle = '投稿写真'
      this.photoModalFirst = this.photoModalPhotos[firstShowIndex]
      this.photoModalShow = true
    },

    openRecordFile (file) {
      console.log('openRecordFile' + file)
    },

    editRecord (record) {
      if (this.postFormActive) {
        // ダイアログで確認する
        this.showConfirmDialog(
          '投稿の作成中',
          '現在、新規投稿の作成中です。新規投稿の作成中は、別の投稿の編集は行えません。\n新規投稿の作成を中止して、投稿の編集を行えますが、その場合、作成中の内容は破棄されます。\n作成中の新規投稿を削除して、投稿の編集を行いますか?',
          DIALOG_TYPE.EXECUTE,
          () => {
            // 削除実行
            this.clearPostForm()
            this.clearEditForm()
            this.editTargetRecord = record
            this.editTargetDate.date = record.targetAt
            this.selectedTagsForEdit = record.hashTags
          }
        )
      } else {
        this.clearEditForm()
        this.editTargetRecord = record
        this.editTargetDate.date = record.targetAt
        this.selectedTagsForEdit = record.hashTags
      }
    },

    clearEditForm () {
      this.editTargetRecord = null
      this.editTargetDate = { change: false, date: null }
      this.selectedTagsForEdit = []
      this.createTagsForEdit = []
    },

    destroyRecord  (record) {
      if (record.user_id === this.loggedInAuthor.rawID || record.author_id === this.loggedInAuthor.id) {
        this.showConfirmDialog(
          '投稿の削除',
          '投稿を削除しようとしています。\n本当に削除してよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => {
            // 削除実行
            TimelineRecord.api().destroyForRepoter({ timelineOwnerID: this.timelineOwner.id, targetRecordID: record.id }).then(() => {
              this.updateRecords()
            })
          }
        )
      }
    },

    timelineUpdateFinished (addedRecords, updatedRecords) {
      // highlight?
      addedRecords.forEach(aRec => {
        this.highlightRecordBlock(aRec)
      })
      updatedRecords.forEach(uRec => {
        this.highlightRecordBlock(uRec)
      })
    },

    highlightRecordBlock (record) {
      this.$nextTick(() => {
        const centerContent = this.$refs.mainTimeline
        const targetElm = centerContent.querySelector('#timelineRecord' + record.id)
        if (targetElm) {
          const recordBlock = targetElm.querySelector('.timeline-record-block')
          const dummyBlock = targetElm.querySelector('.timeline-record-dummy-block')
          targetElm.classList.add('highlight')
          if (recordBlock) {
            recordBlock.classList.add('highlight')
          }
          if (dummyBlock) {
            dummyBlock.classList.add('highlight')
          }
        }
      })
    },

    scrollToTimelineTop () {
      this.$nextTick(() => {
        const centerContent = this.$refs.mainTimeline
        centerContent.scrollTop = 0
      })
    },

    scrollToRecord (record, offset) {
      if (record) {
        var offsetHeight = offset || -110
        if (this.signinReporter) {
          offsetHeight = -130
        }
        this.highlightRecordBlock(record)
        this.$nextTick(() => {
          const centerContent = this.$refs.mainTimeline
          const timelineInner = centerContent.querySelector('#mainTimelineInner')
          const targetElm = centerContent.querySelector('#timelineRecord' + record.id)
          // targetElm.style.backgroundColor('#f00')
          if (centerContent && timelineInner && targetElm) {
            centerContent.scrollTop = targetElm.offsetTop + timelineInner.offsetTop + offsetHeight
          }
        })
      }
    },

    // edit record events
    tagSelectionForEdit () {
      this.selectTargetsForTag = this.selectedTagsForEdit
      this.createdTargetForTag = this.createTagsForEdit
      this.showTagSelectionModal = true
    },

    removeSelectedTagFromEdit  (targetTag) {
      var tagIndex = this.selectedTagsForEdit.indexOf(targetTag)
      if (tagIndex >= 0) {
        this.selectedTagsForEdit.splice(tagIndex, 1)
      }
    },

    removeNewPostTagForEdit (index) {
      this.createTagsForEdit.splice(index, 1)
    },

    tagSelectionModalClose () {
      this.showTagSelectionModal = false
    },

    targetDateSelectionForEdit () {
      this.selectTargetDate = this.editTargetDate
      this.targetDateSelectionModalOpen()
    },

    clearTargetDateForEdit () {
      this.editTargetDate.change = false
    },

    confirmEditFormClear (callBack) {
      this.showConfirmDialog(
        '投稿の編集中止',
        '変更の内容を全て破棄します。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.clearEditForm()
          callBack()
        }
      )
    },

    async submitUpdateForm (formData, removeObjects, successCallback) {
      this.showSendingModal = true
      await TimelineRecord.api().updateForRepoter({ formData: formData }).then(async () => {
        if (removeObjects.deleteObjects.photos) {
          removeObjects.deleteObjects.photos.forEach(photoID => {
            PhotoReport.delete(photoID)
          })
        }
        if (removeObjects.deleteObjects.files) {
          removeObjects.deleteObjects.files.forEach(fileID => {
            FileReport.delete(fileID)
          })
        }
        await successCallback()
        await this.clearEditForm()
        // checkServerUpdate
        this.timelineTargetRecord = this.editTargetRecord
        await this.updateRecords()
        await this.loadTimelineHashTags()
        this.showSendingModal = false
      }).catch(err => {
        console.error(err)
        console.error(err.response)
        this.showSendingModal = false
        this.showNotifyDialog('投稿の更新失敗', '投稿の送信に失敗しました')
      })
    },

    // narrowdown tag events
    closeTagNarrowDownModal () {
      this.showTagNarrowDownModal = false
      this.loadRecords()
    },

    updateNarrowDownTag  (tag, currentActive, currentEnable) {
      if (currentEnable) {
        if (currentActive) {
          var tagIndex = this.narrowDownTags.indexOf(tag)
          if (tagIndex >= 0) {
            this.narrowDownTags.splice(tagIndex, 1)
          }
        } else {
          this.narrowDownTags.push(tag)
        }
      } else {
        this.narrowDownTags.push(tag)
      }
    },

    submitNarrowDownTags () {
      // this.focusCenterColumn()
    },

    clearNarrowDownTags () {
      this.narrowDownTags = []
      // this.focusCenterColumn()
    },

    tagManageModalShow () {
      // nothing to do
    },

    // date selection events
    targetDateSelectionModalOpen () {
      this.showDateSelectionModal = true
    },

    targetDateSelectionModalClose () {
      this.showDateSelectionModal = false
    },

    targetDateSelectionCancel () {
      this.targetDateSelectionModalClose()
      this.selectTargetDate.change = false
    },

    targetDateSelectionCommit (selectedDate) {
      this.selectTargetDate.change = true
      this.selectTargetDate.date = new Date(selectedDate.getTime())
      this.targetDateSelectionModalClose()
    },

    // photo modal events
    jumpToOriginFromPhotoModal (photoReport) {
      this.closePhotoModal()
      this.jumpToOrigin(photoReport.timelineRecord)
    },

    closePhotoModal () {
      this.photoModalPhotos = []
      this.photoModalType = ''
      this.photoModalAuthor = null
      this.photoModalTargetAt = null
      this.photoModalFirst = null
      this.photoModalTitle = ''
      this.photoModalHashTags = []
      this.photoModalShow = false
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
    // ClickOutside
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-reporter-post-history-view {
  margin: 0;
  .sub-header {
    display: flex;
    padding: 10px;
    height: 40px;
    border-bottom: 1px solid #ddd;
    position: relative;
    background-color: #bbc8e6;
    .profile {
      display: flex;
      min-width: calc(100% - 84px - 8px);
      .main-portrait {
        height: 40px;
        width: 40px;
        margin-right: 10px;
        img {
          height: 40px;
          width: 40px;
        }
      }
      .target-person-column {
        height: 40px;
        vertical-align: bottom;
        font-weight: lighter;
        a {
          color: #333;
        }
        h1 {
          font-size: 1.2em;
          font-weight: bold;
          margin-top: 2px;
          margin-bottom: 3px;
          line-height: 1em;
        }
      }
      .timeline-personal-menu {
        position: absolute;
        top: 40px;
        left: 10px;
        z-index: 1000;
        margin: 0;
        padding: 0;
        width: 250px;
        background-color: #fff;
        border: 1px solid #aaa;
        li {
          padding: 0;
          border-top: 1px solid #aaa;
          list-style-type: none;
          color: #666;
          text-align: left;
          &.menu-signout {
            background-color: #eee;
          }
          a {
            display: inline-block;
            padding: 10px 20px;
            width: calc(100% - 40px);
            color: #666;
            text-decoration: none;
          }
        }
      }
    }
  }
  .main-column {
    padding: 10px 20px;
    @include mediaQuery('phone') {
      padding: 10px 0;
    }
  }
}
</style>
