<template>
  <div class="horus-confirm-relation-request-modal">
    <horus-modal-frame
      :showModalFlag="showConfirmRelationRequestModal">
      <div class="modal-header">
        <h1>かかりつけ依頼の処理</h1>
      </div>
      <div class="modal-body" v-if="organization && targetRequest">
        <div class="org-notify" v-if="organization.revoke_requested">
          現在この施設は退会準備中です。「承認」を行うことはできません。
        </div>
        <div class="red_titled_column">
          <h4>かかりつけ依頼者からのメッセージ</h4>
          <div class="message-from-sender" v-html="messageContent"></div>
        </div>
        <div class="red_titled_column">
          <h4>かかりつけ依頼者の情報</h4>
          <div class="labeled_content_block">
            <div class="labeled_content_single_block">
              <label>氏名(カナ)</label>
              <div class="content">{{ targetRequest.user_name }}({{ targetRequest.user_name_kana }})</div>
            </div>
            <div class="labeled_content_single_block">
              <label>生年月日/性別</label>
              <div class="content">{{ userBirthDateJp }}({{ userAge }})/{{ userSexType }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>住所</label>
              <div class="content">{{ targetRequest.user_address }}</div>
            </div>
            <div class="labeled_content_single_block">
              <label>電話番号</label>
              <div class="content">{{ targetRequest.user_tel }}</div>
            </div>
          </div><!-- labeld content block (target information) end -->
        </div>
        <div class="red_titled_column" v-if="targetRequest.deputized">
          <h4>依頼者の設定代行施設</h4>
          NEED TO IMPREMENT
        </div>
        <div class="labeled_content_line_block">
          <div class="notify_for_admit">
            <h1>かかりつけ登録における確認事項</h1>
            <ul>
              <li><a href="/term_of_use" target="_blank">ナラティブブックの利用規約</a>に基づいて運用していること</li>
              <li>かかりつけ施設に依頼されることを施設が同意していること</li>
              <li>なりすまし防止のために、依頼元の方との確認ができていること</li>
              <li>利用者のタイムラインや特記事項に書き込む情報の内容について、説明し同意を取得した上でかかりつけ設定を行ってください</li>
            </ul>
          </div>
          <div class="admit-agreement" @click="toggleAdmit">
            <horus-custom-check-box
              :active="admitAgreement"
              :enabled="true"
              :type="'color'"
              />
            上記確認事項を確認しました
          </div>
          <div class="submit_notify_description">
            <div class="controlls">
              <button class="submit-btn" type="button" :class="{disable: !readyToAdmit}" @click="admit">承認</button>
              <button class="reject-btn" type="button" @click="reject">拒否</button>
              <button class="cancel-btn" type="button" @click="cancel">保留</button>
            </div>
          </div>
        </div><!-- labeled content block (confirm agreement) end -->
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusConfirmRelationRequestModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    organization: Object,
    targetRequest: Object,
    showConfirmRelationRequestModal: Boolean
  },

  data () {
    return {
      admitAgreement: false
    }
  },

  computed: {
    messageContent () {
      if (this.targetRequest.message_content && this.targetRequest.message_content.length > 0) {
        return this.targetRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return 'かかりつけ依頼のメッセージはありません。'
      }
    },

    userBirthDate () {
      if (this.targetRequest.user_birth_date.length > 0) {
        return new Date(this.targetRequest.user_birth_date)
      }
      return null
    },

    userBirthDateJp () {
      if (this.userBirthDate) {
        return moment(this.userBirthDate).format('YYYY年MM月DD日')
      }
      return '未設定'
    },

    userAge () {
      const now = new Date()
      const today = now.getFullYear() * 10000 + (now.getMonth() + 1) * 100 + now.getDate()
      const bday = this.userBirthDate.getFullYear() * 10000 + (this.userBirthDate.getMonth() + 1) * 100 + this.userBirthDate.getDate()
      return Math.floor((today - bday) / 10000)
    },

    userSexType () {
      return ['---', '男性', '女性'][this.targetRequest.user_sex_type]
    },

    readyToAdmit () {
      if (this.admitAgreement && !this.organization.revoke_requested) {
        return true
      }
      return false
    }
  },

  methods: {
    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    admit () {
      if (this.readyToAdmit) {
        this.$emit('admitRequestEvent', this.targetRequest)
      }
    },

    reject () {
      this.$emit('rejectRequestEvent', this.targetRequest)
    },

    cancel () {
      this.$emit('cancelConfirmEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/createWithAgreementForm.scss';

.horus-confirm-relation-request-modal {
  .modal-body {
    padding: 10px 20px;
    .org-notify {
      margin: 5px 0;
      padding: 3px;
      border: 1px solid #c43d53;
      color: #c43d53;
    }
  }
}
</style>
