import { Model } from '@vuex-orm/core'
import Author from '@/models/Author.js'
import StaffVisibility from '@/models/StaffVisibility.js'

export default class TimelineOwner extends Model {
  static entity = 'timelineOwners'

  static fields () {
    return {
      id: this.attr(null),
      selfSignin: this.attr(true),
      familyName: this.string(''),
      givenName: this.string(''),
      familyNameKana: this.string(''),
      givenNameKana: this.string(''),
      address: this.string(''),
      zipCode: this.string(''),
      mail: this.string(''),
      tel: this.string(''),
      fax: this.string(''),
      cellphone: this.string(''),
      nbid: this.string(''),
      birth_date: this.string(''),
      blood_type: this.string(''),
      sexType: this.number(0),
      author_id: this.attr(null),
      help_path: this.attr(''),
      update_at: this.string('')
    }
  }

  get fullName () {
    return this.familyName + ' ' + this.givenName
  }

  get fullNameKana () {
    return this.familyNameKana + ' ' + this.givenNameKana
  }

  get birthDate () {
    if (this.birth_date.length > 0) {
      return new Date(this.birth_date)
    }
    return null
  }

  get birthDateJp () {
    const birth = this.birthDate
    return birth.getFullYear() + '年' + (birth.getMonth() + 1) + '月' + birth.getDate() + '日'
  }

  get sexTypeJp () {
    return ['---', '男性', '女性'][this.sexType]
  }

  get updatedAt () {
    if (this.update_at.length > 0) {
      return new Date(this.updated_at)
    }
    return null
  }

  get age () {
    const now = new Date()
    const today = now.getFullYear() * 10000 + (now.getMonth() + 1) * 100 + now.getDate()
    const birth = this.birthDate
    const bday = birth.getFullYear() * 10000 + (birth.getMonth() + 1) * 100 + birth.getDate()
    return Math.floor((today - bday) / 10000)
  }

  get author () {
    // TODO: load data when undefined
    return Author.find(this.author_id)
  }

  get portrateSrc () {
    if (this.author) {
      return this.author.portrateSrc
    } else {
      return ''
    }
  }

  get organizationStaffVisibilities () {
    var visibilities = StaffVisibility.query().where('userID', this.id).get()
    var organizations = []
    visibilities.forEach(visibility => {
      var orgExist = false
      organizations.forEach(org => {
        if (org.organizationID === visibility.organizationID) {
          orgExist = true
          org.visibilities.push(visibility)
        }
      })
      if (!orgExist) {
        organizations.push({ organizationID: visibility.organizationID, visibilities: [visibility] })
      }
    })
    return organizations
  }

  get userStaffVisibilities () {
    return StaffVisibility.query().where('userID', this.id)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static apiConfig = {
    actions: {
      fetch ({timelineOwnerID}) {
        // console.log('timeline owner fetch')
        return this.request({
          method: 'get',
          url: 'timeline_owner',
          params: { target_user_id: timelineOwnerID },
          withCredentials: true,
          dataKey: 'timeline_owner'
        })
        .then(async res => {
          console.log('on model TimelineOwner, api, fetch, ID:' + timelineOwnerID)
          console.log(res)
          if (res.entities && res.entities.timelineOwners) {
            if (!Author.find(res.response.data.timeline_owner.author_id)) {
              await Author.api().fetch({ timelineOwnerID: res.response.data.timeline_owner.id })
            }
            TimelineOwner.commit((state) => {
              state.fetchedAt = new Date()
            })
            return res.entities.timelineOwners[0]
          }
        })
        .catch(error => { 
          console.error(error)
          throw error 
        })
      }
    }
  }
}
