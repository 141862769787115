<template>
  <div class="horus-print-relation-invitation-view print-invitation-page">
    <div class="print_date">{{ printDate }}</div>
    <h1 class="head_title">ナラティブブック かかりつけ施設 登録のご案内</h1>
    <h2 class="target_user_name"><span><span class="user_name_space" :class="{blank: !targetUserName || targetUserName.length <= 0}">{{ targetUserName }}</span>様</span></h2>
    <div class="print_content">
      <p>{{ organizationName }}を「かかりつけ施設」に登録するためのご案内です。</p>
      <br/>
      <p>かかりつけ施設とは、情報を共有し、あなたのページに投稿できる施設のことです。<br />例えば、私たちからコメントや写真を投稿できるようになります。</p>
      <p>かかりつけ施設は後で取り消すこともできます。</p>
    </div>
    <div class="manual" v-if="nbid === 'true'">
      <h2>かかりつけ施設登録手順</h2>
      <table class="print_table">
        <tr>
          <th>①受信した案内の確認</th>
          <td>
            「かかりつけ設定」のページに移動して、画面一番下の「案内状の受信リスト」左側の三角形アイコンを押して一覧を表示させます。<br/>
            一覧に、{{ organizationName }}から届いたかかりつけ案内が存在することを確認します。<br/>
            <span class="print_caption">
              「かかりつけ設定」のページを表示させるには、いくつかの方法があります。<br/>
              1．ナラティブブックにログインして、画面上部のバーの中にある自分の写真を押して、プルダウンメニューの「かかりつけ設定」をクリックすると「かかりつけ設定」のページが表示されます。
              2．ナラティブブックにログインして、「お知らせ」の中に届いている「かかりつけの案内」の項目を押すと「かかりつけ設定」のページが表示されます。
              3．かかりつけ案内のメールが届くので、その案内のリンクを押してナラティブブックを表示させ、ログインすると「かかりつけ設定」のページが表示されます。
            </span>
          </td>
        </tr>
        <tr>
          <th>②受信したかかりつけ案内の「承認もしくは拒否の処理ボタンを押す</th>
          <td>
            ボタンを押すと「案内の承認/拒否」のモーダルが表示されます。<br />
            モーダルには、送信元の施設の情報と確認事項などが表示されます。
          </td>
        </tr>
        <tr>
          <th>③確認のチェックを入れて「承認」を押す</th>
          <td>
            画面下の「上記確認事項を確認しました」の左にあるチェックボックスを押して、チェック状態にします。<br />
            「承認」のボタンがアクティブになるので、「承認」ボタンを押して承認完了です。<br/>
            <span class="print_caption">
              「拒否」のボタンを押すと、案内を拒否することが出来ます。一度拒否すると、案内は無効となります。<br/>
              「保留」のボタンを押すと、モーダルが閉じて、処理を保留する事が出来ます。「保留」した後は、いつでも承認/拒否の処理を再開できます。
            </span>
          </td>
        </tr>
      </table>
    </div>
    <div class="manual" v-if="nbid === 'false'">
      <h2>かかりつけ施設の登録時に必要な情報(初回のみ)</h2>
      <div class="notify_content">
        <span>本人確認用コード</span><span class="short_token">{{ shortToken }}</span>
      </div>
      <h2>かかりつけ施設登録手順</h2>
      <table class="print_table">
        <tr>
          <th>①メールの確認</th>
          <td>
            ナラティブブックから{{ organizationName }}のかかりつけ施設の案内が届きます。<br />
            <span class="print_caption">※もし届かない場合は、ナラティブブックで利用する以下のドメインを指定受信設定してください。（メールの再送信が必要ですので設定が終わりましたら{{ organizationName }}までご連絡ください。）<br />
            @narrativebook.jp<br />
            指定受信設定の方法は各キャリアのサイトでご確認ください。</span>
          </td>
        </tr>
        <tr>
          <th>②メールに記載のURLをクリック</th>
          <td>
            メールに記載のURLをクリックします。<br />
            <span class="print_caption">※もし画面のレイアウトが崩れている場合は、お手数ですが、ヘルプページの事前準備のご案内「１．通信機器等のご案内」に記載のナラティブブックの推奨ブラウザでURLを開いてください。</span>
          </td>
        </tr>
        <tr>
          <th>③本人確認用コード、NBID、パスワードの入力と確認事項の確認チェック</th>
          <td>
            本人確認用コードの入力を求められますので、この紙に記載の本人確認用コードを入力してください。<br/>
            <span class="print_caption">※本人確認用コードの入力は３回までです。もし３回間違えてしまった場合は、再送信が必要ですので{{ organizationName }}までご連絡ください。</span><br/>
            ナラティブブックのログインの際に使用しているNBIDとパスワードを入力し、「かかりつけ登録に於ける確認事項」をよくお読みの上、「上記確認事項を確認しました」のチェックを入れてください。<br/>
            必要事項が入力されている場合、「ログインして承認」のボタンがアクティブになるので、クリックして承認完了です。
          </td>
        </tr>
        <tr>
          <th>④完了</th>
          <td>
            「ログインして続行」をクリックすると、ナラティブブックにログインしてタイムラインに移動します。<br />
            「ログインせずに終了」をクリックすると、ナラティブブックのトップページに戻ります。
          </td>
        </tr>
      </table>
    </div>
    <input type="button" class="btn btn btn-success" value="印刷する" onclick="window.print();" />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HorusPrintRelationInvitationView',

  data () {
    return {
      organizationName: '',
      targetUserName: '',
      shortToken: '',
      nbid: false
    }
  },

  computed: {
    printDate () {
      return moment(new Date()).format('YYYY年MM月DD日')
    }
  },

  watch: {
    '$route': { handler: 'initDatas', immediate: true }
  },

  methods: {
    initDatas () {
      this.organizationName = this.$route.query.organization_name
      this.targetUserName = this.$route.query.target_user_name
      this.shortToken = this.$route.query.short_token
      this.nbid = this.$route.query.nbid
    }
  }
}
require('@/assets/css/printInvitation.scss')
</script>

<style lang="scss" scoped>
.user_name_space.blank {
  display: inline-block;
  margin: 0 10px;
  width: 300px;
  height: 50px;
  border-bottom: 1px solid #000;
}
.short_token {
  margin-left: 20px;
  font-size: 21pt;
}
</style>
