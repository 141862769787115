<template>
  <div class="horus-author-narrowdown-modal-wrapper" v-show="showAuthorNarrowDownModal">
    <div class="bn-author-narrowdown-backdrop" @click="backdropClicked"></div>
    <div class="horus-author-narrowdown-modal">
      <div class="horus-author-narrowdown-control">
        <button class="horus-author-narrowdown-clear" type="button" @click="clearNarrowDownAuthor">クリア</button>
        <button class="horus-author-narrowdown-submit" type="button" @click="submitNarrowDownAuthor">OK</button>
      </div>
      <div class="narrow-down-author-groups">
        <div class="narrow-down-group-head" @click="toggleAllFamily">
          <horus-custom-check-box :type="'small'"
            :active="allFamilyActive"
            :enabled="anyActiveAuthor" />
          <img src="/static/images/family.png" />
          <span class="narrow-down-group-name">家族</span>
        </div>
        <div class="narrow-down-group">
          <div class="narrow-down-author-block"
            v-for="family in families"
            :key="'authormodal' + family.id"
            @click="toggleAuthor(family)">
            <horus-custom-check-box :type="'small'"
              :active="isActive(family)"
              :enabled="anyActiveAuthor" />
            <img :src="family.portrateSrc" />
            <span class="author-name">{{ family.name }}</span>
          </div>
        </div>
        <div class="narrow-down-organizations" v-for="organization in organizations" :key="organization.id">
          <div class="narrow-down-group-head" @click="toggleAllMember(organization)">
            <horus-custom-check-box :type="'small'"
              :active="allMemberActive(organization)"
              :enabled="anyActiveAuthor" />
            <img src="/static/images/org_member.png" />
            <span class="narrow-down-group-name">{{ organization.shortName }}</span>
          </div>
          <div class="narrow-down-group">
            <div class="narrow-down-author-block"
              v-for="member in organizationVisibleMembers(organization)"
              :key="'authormodal' + member.id"
              @click="toggleAuthor(member)">
              <horus-custom-check-box :type="'small'"
                :active="isActive(member)"
                :enabled="anyActiveAuthor" />
              <img :src="member.portrateSrc" />
              <span class="author-name">{{ member.name }}</span>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusOrganizationMemberVisibility from '@/mixin/HorusOrganizationMemberVisibility.js'

export default {
  mixins: [HorusOrganizationMemberVisibility],

  name: 'HorusAuthorNarrowDownModal',

  components: {
    HorusCustomCheckBox
  },

  props: {
    showAuthorNarrowDownModal: Boolean,
    families: Array,
    organizations: Array,
    activeAuthors: Array,
    allFamilyActive: Boolean,
    activeOrganizations: Array,
    organizationStaffVisibilities: Array
  },

  computed: {
    anyActiveAuthor () { return (this.activeAuthors.length > 0) }
  },

  methods: {
    toggleAllFamily () {
      this.$emit('toggleAllFamilyEvent')
    },

    allMemberActive (organization) {
      return this.activeOrganizations.some(orgId => orgId === organization.id)
    },

    toggleAllMember (organization) {
      this.$emit('toggleAllMemberEvent', organization)
    },

    isActive (author) {
      return this.activeAuthors.some(active => { return active.id === author.id })
    },

    toggleAuthor (author) {
      this.$emit('toggleNarrowDownAuthor', author)
    },

    submitNarrowDownAuthor () {
      this.$emit('submitNarrowDownAuthorEvent')
      this.$emit('authorNarrowDownModalcloseEvent')
    },

    clearNarrowDownAuthor () {
      this.$emit('clearNarrowDownAuthorEvent')
      this.$emit('authorNarrowDownModalcloseEvent')
    },

    backdropClicked () {
      this.$emit('authorNarrowDownModalcloseEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-author-narrowdown-modal-wrapper {
  position: absoluete;
}
.bn-author-narrowdown-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  opacity: 0.05;
}
.horus-author-narrowdown-modal {
  position: absolute;
  width: 340px;
  max-height: calc(100vh - 250px);
  margin: 0;
  padding: 0;
  top: 130px;
  left: calc((100vw - 1000px) / 2 + 30px);
  overflow: auto;
  background-color: #fff;
  border: 1px solid #aaa;
  @include mediaQuery('tablet') {
    left: 10px;
  }
  @include mediaQuery('phone') {
    width: calc(100vw - 20px);
    left: 10px;
  }
}
.horus-author-narrowdown-control {
  padding-top: 10px;
  height: 53px;
}
.horus-author-narrowdown-submit {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #666;
  background-color: #666;
  color: #fff;
}
.horus-author-narrowdown-clear {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #333;
}
.narrow-down-group-head {
  margin: 10px 0 0 0;
  padding: 5px 5px 5px 25px;
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  cursor: pointer;
  img {
    width: 10px;
    height: 10px;
  }
}
.narrow-down-group {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}
.narrow-down-author-block {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  img {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
}
</style>
