<template>
  <div class="horus-vital-display-config-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/vital_green.png" class="title-icon" />バイタル表示設定</h1>
      </div>
      <div class="modal-body">
        <div class="description-message">メインタイムラインの左カラムで表示する、最新の項目をここで設定します。チェックをONにした項目が表示されます。</div>
        <div class="vital-display-config-form">
          <div class="vital-category">
            <div class="configuration-column" @click="toggleConfig('bloodPressure')">
              <horus-custom-check-box
                :active="configureCopy.bloodPressure"
                :enabled="true"
                :type="'color'"
                />
              <span class="label">血圧</span>
            </div>
            <div class="vital-description">
              <div class="configuration-column" @click="toggleConfig('bloodPressureUnReliable')">
                <horus-custom-check-box
                  :active="configureCopy.bloodPressureUnReliable"
                  :enabled="configureCopy.bloodPressure"
                  :type="'color'"
                  />
                <span class="label">不正計測値を含む</span>
              </div>
            </div>
          </div>
          <div class="vital-category">
            <div class="configuration-column" @click="toggleConfig('bodyComposition')">
              <horus-custom-check-box
                :active="configureCopy.bodyComposition"
                :enabled="true"
                :type="'color'"
                />
              <span class="label">体組成</span>
            </div>
            <div class="vital-description">
              <div class="configuration-column" @click="toggleConfig('bodyCompositionWeight')">
                <horus-custom-check-box
                  :active="configureCopy.bodyCompositionWeight"
                  :enabled="configureCopy.bodyComposition"
                  :type="'color'"
                  />
                <span class="label">体重</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('bodyCompositionBodyFatRatio')">
                <horus-custom-check-box
                  :active="configureCopy.bodyCompositionBodyFatRatio"
                  :enabled="configureCopy.bodyComposition"
                  :type="'color'"
                  />
                <span class="label">体脂肪率</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('bodyCompositionBaseMetabolism')">
                <horus-custom-check-box
                  :active="configureCopy.bodyCompositionBaseMetabolism"
                  :enabled="configureCopy.bodyComposition"
                  :type="'color'"
                  />
                <span class="label">基礎代謝</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('bodyCompositionBodySkeltalMuscleRatio')">
                <horus-custom-check-box
                  :active="configureCopy.bodyCompositionBodySkeltalMuscleRatio"
                  :enabled="configureCopy.bodyComposition"
                  :type="'color'"
                  />
                <span class="label">骨格筋率</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('bodyCompositionBMI')">
                <horus-custom-check-box
                  :active="configureCopy.bodyCompositionBMI"
                  :enabled="configureCopy.bodyComposition"
                  :type="'color'"
                  />
                <span class="label">BMI</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('bodyCompositionBodyAge')">
                <horus-custom-check-box
                  :active="configureCopy.bodyCompositionBodyAge"
                  :enabled="configureCopy.bodyComposition"
                  :type="'color'"
                  />
                <span class="label">体年齢</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('bodyCompositionViceralFatLevel')">
                <horus-custom-check-box
                  :active="configureCopy.bodyCompositionViceralFatLevel"
                  :enabled="configureCopy.bodyComposition"
                  :type="'color'"
                  />
                <span class="label">内臓脂肪レベル</span>
              </div>
            </div>
          </div>
          <div class="vital-category">
            <div class="configuration-column" @click="toggleConfig('activeMass')">
              <horus-custom-check-box
                :active="configureCopy.activeMass"
                :enabled="true"
                :type="'color'"
                />
              <span class="label">活動量</span>
            </div>
            <div class="vital-description">
              <div class="configuration-column" @click="toggleConfig('activeMassStepCount')">
                <horus-custom-check-box
                  :active="configureCopy.activeMassStepCount"
                  :enabled="configureCopy.activeMass"
                  :type="'color'"
                  />
                <span class="label">歩数</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('activeMassFastWalkStepCount')">
                <horus-custom-check-box
                  :active="configureCopy.activeMassFastWalkStepCount"
                  :enabled="configureCopy.activeMass"
                  :type="'color'"
                  />
                <span class="label">早歩き歩数</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('activeMassStairwayStepCount')">
                <horus-custom-check-box
                  :active="configureCopy.activeMassStairwayStepCount"
                  :enabled="configureCopy.activeMass"
                  :type="'color'"
                  />
                <span class="label">上り階段歩数</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('activeMassUsedCaloriesInActive')">
                <horus-custom-check-box
                  :active="configureCopy.activeMassUsedCaloriesInActive"
                  :enabled="configureCopy.activeMass"
                  :type="'color'"
                  />
                <span class="label">活動消費カロリー</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('activeMassTotalUsedCalories')">
                <horus-custom-check-box
                  :active="configureCopy.activeMassTotalUsedCalories"
                  :enabled="configureCopy.activeMass"
                  :type="'color'"
                  />
                <span class="label">総消費カロリー</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('activeMassMovingDistance')">
                <horus-custom-check-box
                  :active="configureCopy.activeMassMovingDistance"
                  :enabled="configureCopy.activeMass"
                  :type="'color'"
                  />
                <span class="label">移動距離</span>
              </div>
              <div class="configuration-column" @click="toggleConfig('activeMassBurningBodyFat')">
                <horus-custom-check-box
                  :active="configureCopy.activeMassBurningBodyFat"
                  :enabled="configureCopy.activeMass"
                  :type="'color'"
                  />
                <span class="label">脂肪燃焼量</span>
              </div>
            </div>
          </div>
          <div class="vital-category">
            <div class="configuration-column" @click="toggleConfig('bodyTemperature')">
              <horus-custom-check-box
                :active="configureCopy.bodyTemperature"
                :enabled="true"
                :type="'color'"
                />
              <span class="label">体温</span>
            </div>
          </div>
          <div class="vital-category">
            <div class="configuration-column" @click="toggleConfig('oxygenSaturation')">
              <horus-custom-check-box
                :active="configureCopy.oxygenSaturation"
                :enabled="true"
                :type="'color'"
                />
              <span class="label">酸素飽和度</span>
            </div>
          </div>
          <div class="vital-category">
            <div class="configuration-column" @click="toggleConfig('pulseRate')">
              <horus-custom-check-box
                :active="configureCopy.pulseRate"
                :enabled="true"
                :type="'color'"
                />
              <span class="label">心拍</span>
            </div>
          </div>
        </div>
        <div class="execute_controlls">
          <button type="button" class="submit-btn" @click="submitUpdate">設定更新</button>
        </div><!-- execute controll end -->
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import {
  VitalDisplayConfig
} from '@/models'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusVitalDisplayConfigModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    showModal: Boolean,
    timelineOwner: Object
  },

  data () {
    return {
      vitalDisplayConfig: null,
      configureCopy: {}
    }
  },

  watch: {
    showModal: { handler: 'loadDisplayConfig', immediate: true },
    vitalDisplayConfig: { handler: 'initConfigureCopy', immediate: true }
  },

  methods: {
    loadDisplayConfig () {
      if (this.timelineOwner) {
        this.vitalDisplayConfig = VitalDisplayConfig.query().where('timelineOwnerID', this.timelineOwner.id).first()
        if (!this.vitalDisplayConfig) {
          VitalDisplayConfig.api().fetch({ targetUserID: this.timelineOwner.id }).then(() => {
          // this.$store.dispatch('fetchVitalDisplayConfig', { targetUserID: this.timelineOnwer.id }).then(res => {
            this.vitalDisplayConfig = VitalDisplayConfig.query().where('timelineOwnerID', this.timelineOwner.id).first()
          })
        }
      }
    },

    initConfigureCopy () {
      if (this.vitalDisplayConfig) {
        this.configureCopy = {
          bloodPressure: Boolean(this.vitalDisplayConfig.bloodPressure),
          bloodPressureUnReliable: Boolean(this.vitalDisplayConfig.bloodPressureUnReliable),
          bodyComposition: Boolean(this.vitalDisplayConfig.bodyComposition),
          bodyCompositionWeight: Boolean(this.vitalDisplayConfig.bodyCompositionWeight),
          bodyCompositionBodyFatRatio: Boolean(this.vitalDisplayConfig.bodyCompositionBodyFatRatio),
          bodyCompositionBaseMetabolism: Boolean(this.vitalDisplayConfig.bodyCompositionBaseMetabolism),
          bodyCompositionBodySkeltalMuscleRatio: Boolean(this.vitalDisplayConfig.bodyCompositionBodySkeltalMuscleRatio),
          bodyCompositionBMI: Boolean(this.vitalDisplayConfig.bodyCompositionBMI),
          bodyCompositionBodyAge: Boolean(this.vitalDisplayConfig.bodyCompositionBodyAge),
          bodyCompositionViceralFatLevel: Boolean(this.vitalDisplayConfig.bodyCompositionViceralFatLevel),
          activeMass: Boolean(this.vitalDisplayConfig.activeMass),
          activeMassStepCount: Boolean(this.vitalDisplayConfig.activeMassStepCount),
          activeMassFastWalkStepCount: Boolean(this.vitalDisplayConfig.activeMassFastWalkStepCount),
          activeMassStairwayStepCount: Boolean(this.vitalDisplayConfig.activeMassStairwayStepCount),
          activeMassUsedCaloriesInActive: Boolean(this.vitalDisplayConfig.activeMassUsedCaloriesInActive),
          activeMassTotalUsedCalories: Boolean(this.vitalDisplayConfig.activeMassTotalUsedCalories),
          activeMassMovingDistance: Boolean(this.vitalDisplayConfig.activeMassMovingDistance),
          activeMassBurningBodyFat: Boolean(this.vitalDisplayConfig.activeMassBurningBodyFat),
          bodyTemperature: Boolean(this.vitalDisplayConfig.bodyTemperature),
          oxygenSaturation: Boolean(this.vitalDisplayConfig.oxygenSaturation),
          pulseRate: Boolean(this.vitalDisplayConfig.pulseRate)
        }
      }
    },

    toggleConfig (targetConfigName) {
      console.log('toggle config')
      console.log(this.configureCopy[targetConfigName])
      this.configureCopy[targetConfigName] = !this.configureCopy[targetConfigName]
    },

    submitUpdate () {
      var formData = new FormData()
      formData.append('vital_display_configure[blood_pressure]', this.configureCopy.bloodPressure ? 1 : 0)
      formData.append('vital_display_configure[blood_pressure_un_reliable]', this.configureCopy.bloodPressureUnReliable ? 1 : 0)
      formData.append('vital_display_configure[body_composition]', this.configureCopy.bodyComposition ? 1 : 0)
      formData.append('vital_display_configure[body_composition_weight]', this.configureCopy.bodyCompositionWeight ? 1 : 0)
      formData.append('vital_display_configure[body_composition_body_fat_ratio]', this.configureCopy.bodyCompositionBodyFatRatio ? 1 : 0)
      formData.append('vital_display_configure[body_composition_base_metabolism]', this.configureCopy.bodyCompositionBaseMetabolism ? 1 : 0)
      formData.append('vital_display_configure[body_composition_body_skeltal_muscle_ratio]', this.configureCopy.bodyCompositionBodySkeltalMuscleRatio ? 1 : 0)
      formData.append('vital_display_configure[body_composition_bmi]', this.configureCopy.bodyCompositionBMI ? 1 : 0)
      formData.append('vital_display_configure[body_composition_body_age]', this.configureCopy.bodyCompositionBodyAge ? 1 : 0)
      formData.append('vital_display_configure[body_composition_viceral_fat_level]', this.configureCopy.bodyCompositionViceralFatLevel ? 1 : 0)
      formData.append('vital_display_configure[active_mass]', this.configureCopy.activeMass ? 1 : 0)
      formData.append('vital_display_configure[active_mass_step_count]', this.configureCopy.activeMassStepCount ? 1 : 0)
      formData.append('vital_display_configure[active_mass_fast_walk_step_count]', this.configureCopy.activeMassFastWalkStepCount ? 1 : 0)
      formData.append('vital_display_configure[active_mass_stairway_step_count]', this.configureCopy.activeMassStairwayStepCount ? 1 : 0)
      formData.append('vital_display_configure[active_mass_used_calories_in_active]', this.configureCopy.activeMassUsedCaloriesInActive ? 1 : 0)
      formData.append('vital_display_configure[active_mass_total_used_calories]', this.configureCopy.activeMassTotalUsedCalories ? 1 : 0)
      formData.append('vital_display_configure[active_mass_moving_distance]', this.configureCopy.activeMassMovingDistance ? 1 : 0)
      formData.append('vital_display_configure[active_mass_burning_body_fat]', this.configureCopy.activeMassBurningBodyFat ? 1 : 0)
      formData.append('vital_display_configure[body_temperature]', this.configureCopy.bodyTemperature ? 1 : 0)
      formData.append('vital_display_configure[oxygen_saturation]', this.configureCopy.oxygenSaturation ? 1 : 0)
      formData.append('vital_display_configure[pulse_rate]', this.configureCopy.pulseRate ? 1 : 0)

      VitalDisplayConfig.api().update({ formData: formData }).then(() => {
        this.$emit('vitalDisplayDataUpdatedEvent')
      }).catch(error => {
        console.error(error)
        this.$emit('vitalDisplayUpdateFailEvent')
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.horus-vital-display-config-modal {
  .modal-body {
    padding: 10px 20px;
    .description-message {
      margin: 5px 0;
      padding: 5px;
      background-color: #eee;
    }
    .vital-display-config-form {
      .vital-category {
        border-top: 1px solid #ddd;
        .configuration-column {
          display: flex;
          justify-content: flex-start;
          align-content: center;
          padding: 5px 0;
          font-size: 18px;
        }
        .vital-description {
          padding-left: 20px;
        }
      }
    }
    .execute_controlls {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 3px 5px;
        border-radius: 3px;
        background-color: #999;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
</style>
