import axios from './AxiosInstance'

export default {

  fetch: ({authToken}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        baseURL: 'https://zoom.us',
        url: '/v2/users/me',
        withCredentials: true,
        headers: {
          'X-HTTP-Method-Override': 'GET',
          'Authorization': 'Bearer ' + authToken
        },
        params: {}
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetchStored: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'zoom_user',
        withCredentials: true,
        params: {}
      }).then(res => {
        if (res.data.success === true) {
          resolve({ zoomUser: res.data.zoom_user })
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  store: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'zoom_user/store',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: () => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'zoom_user',
            withCredentials: true
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
