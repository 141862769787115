import { Model } from '@vuex-orm/core'
import BoadComment from './BoadComment'

export default class BoadCommentPhoto extends Model {
  static entity = 'boadCommentPhotos'

  static fields () {
    return {
      id: this.attr(null),
      boad_comment_id: this.attr(null),
      filename: this.string(''),
      size: this.number(0),
      imageWidth: this.number(0),
      imageHeight: this.number(0)
    }
  }

  get photoSrc () {
    const info = BoadComment.find(this.boad_comment_id)
    return process.env.VUE_APP_BASE_URL + '/boad_comment/show_photo?target_user_id=' + info.user_id + '&boad_comment_photo_id=' + this.id
  }

  get photoThumbnailSrc () {
    const info = BoadComment.find(this.boad_comment_id)
    return process.env.VUE_APP_BASE_URL + '/boad_comment/show_photo?target_user_id=' + info.user_id + '&boad_comment_photo_id=' + this.id + '&version=thumb'
  }

  get photoMiddleSrc () {
    const info = BoadComment.find(this.boad_comment_id)
    return process.env.VUE_APP_BASE_URL + '/boad_comment/show_photo?target_user_id=' + info.user_id + '&boad_comment_photo_id=' + this.id + '&version=middle'
  }
}
