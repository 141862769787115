<template>
  <div class="horus-record-edit-modal-wrapper"  v-if="editRecord">
    <div class="backdrop"></div>
    <div class="edit-modal">
      <div class="reference-column" v-show="showReference">
        <div class="reference-display-controll">
          <img src="/static/images/x_dark_icon.png" @click="closeReference" />
        </div>
        <horus-photo-display
          :currentPhoto="referencePhoto"
          :extendClass="'single'" />
      </div><!-- reference column end -->
      <div class="form-column">
        <div class="record-header" v-show="editRecord">
          <horus-record-head-info
            :timelineOwner="timelineOwner"
            :author="editRecord.author"
            :targetAt="editRecord.targetAt"
            />
          <div class="editing-title">
            <div class="title-text">編集中</div>
            <div class="editing-notify" v-if="!authorEditable">編集権限がないため、タグの変更のみが行えます</div>
          </div>
        </div>
        <div class="form-body">
          <div class="noneditable-display-column" v-if="!authorEditable">
            <horus-record-body
              :timelineRecord="editRecord"
              :key="'editRecordBody' + editRecord.id"
              v-on:durgEntryPhotoClickEvent="showUneditableDrugPhoto"
              v-on:timelinePhotoClickEvent="showUneditablePhoto" />
          </div>
          <div class="editable-edit-column" v-if="authorEditable">
            <div class="surrogate-toggle" v-if="surrogatable">
              <button type="button" class="surrogate-toggle-btn" :class="{'surrogated': editData.surrogated}" @click="postSurrogateToggle">本人曰く</button>
            </div>
            <textarea placeholder="ここに書いてください" class="form-control text-comment" rows="1" ref="textCommentField" v-model="editData.textComment"></textarea>
            <div class="post-controlls">
              <div class="private-record-notify" v-if="editData.private"><img src="/static/images/close_lock.png" />プライベートメンバーへの投稿です。</div>
              <horus-medical-examination-display
                v-show="medicalExaminationExist"
                :medicalExamination="medicalExamination"
                />
              <horus-drug-entry-display
                v-show="drugEntryExist"
                :drugEntry="drugEntry"
                v-on:timelinePhotoClickEvent="showPhoto"
                />
              <div class="uploaded-photos" v-if="editRecord.photoReports.length > 0">
                <h3>アップロード済写真</h3>
                <div class="uploaded-photo-thumbnails">
                  <div class="uploaded-photo-thumbnail"
                    v-for="photoReport in editRecord.photoReports"
                    :key="'editinguploadedphotothumbnail' + photoReport.id">
                    <horus-inline-photo
                      :photoObject="photoReport"
                      :photoMaxSize="100"
                      v-on:timeliePhotoClickEvent="showPhoto(photoReport)" />
                    <div class="uploaded-photo-controll" @click="togglePhotoRemove(photoReport.id)">
                      <horus-custom-check-box :type="'small'" :active="photoGonnaRemove(photoReport.id)" :enabled="true" />この写真は削除する
                    </div>
                  </div>
                </div>
              </div>
              <div class="file-reports" v-if="editRecord.fileReports.length > 0">
                <h3>アップロード済ファイル</h3>
                <div class="uploaded-file-list">
                  <div class="uploaded-file"
                    v-for="fileReport in editRecord.fileReports"
                    :key="'editinguploadedfile' + fileReport.id">
                    <horus-inline-file
                      :fileObject="fileReport"
                      :sizeType="'normal'" />
                    <div class="uploaded-file-controll" @click="toggleFileRemove(fileReport.id)">
                      <horus-custom-check-box :type="'small'" :active="fileGonnaRemove(fileReport.id)" :enabled="true" />このファイルは削除する
                    </div>
                  </div>
                </div>
              </div>
              <div class="uploaded-video" v-if="editRecord.videoContent">
                <h3>アップロード済動画</h3>
                <div class="video-column">
                  <div class="video-not-ready video-block" v-if="editRecord.videoContent.content_state === 0 || editRecord.videoContent.content_state === 20">
                    <img src="/static/images/video_processing.png" class="video-processing" />
                    動画準備中
                  </div>
                  <div class="video-ready video-block" v-if="editRecord.videoContent.content_state === 10 || editRecord.videoContent.content_state === 30">
                    <div class="video-thumbnail-block" @click="videoContentClicked">
                      <img :src="editRecord.videoContent.thumbnailSrc" class="video-thumbnail" />
                      <img src="/static/images/video_play.png" class="video-play-icon" />
                    </div>
                    <div class="video-content-name">{{ editRecord.videoContent.contentName }}</div>
                  </div>
                  <div class="video-fail video-block" v-if="editRecord.videoContent.content_state == 50">
                    <img src="/static/images/video_error.png" class="video-error" />
                    動画エラー
                  </div>
                  <div class="uploaded-video-controll" @click="toggleVideoRemove">
                    <horus-custom-check-box :type="'small'" :active="editData.removeVideo" :enabled="true" />このファイルは削除する
                  </div>
                </div>
              </div>
              <div class="uploaded-audio" v-if="editRecord.audioContent">
                <h3>アップロード済音声</h3>
                <div class="audio-column">
                  <div class="audio-not-ready audio-block" v-if="editRecord.audioContent.content_state === 0 || editRecord.audioContent.content_state === 20">
                    <img src="/static/images/audio_processing.png" class="audio-processing" />
                    音声準備中
                  </div>
                  <div class="audio-ready audio-block" v-if="editRecord.audioContent.content_state === 10 || editRecord.audioContent.content_state === 30" @click="audioContentClicked">
                    <img src="/static/images/audio_play_icon.png" class="audio-play" />
                    <div class="audio-content-name">{{ editRecord.audioContent.contentName }}</div>
                  </div>
                  <div class="audio-fail audio-block" v-if="editRecord.audioContent.content_state == 50">
                    <img src="/static/images/audio_error.png" class="audio-error" />
                    音声エラー
                  </div>
                </div>
              </div>
              <horus-multi-photo-selector
                ref="multiPhotoSelector"
                :photos="editData.photos"
                :photoInputID="photoInputID"
                v-on:newPhotoPreparedEvnt="addSelectedPhoto"
                v-on:photoRemoveEvent="removePhoto"
                />
              <horus-multi-file-selector
                ref="multiFileSelector"
                :files="editData.files"
                :displaySize="''"
                :fileInputID="fileInputID"
                v-on:newFilePreparedEvent="addSelectedFile"
                v-on:fileRemoveEvent="removeFile"
                />
              <div class="video-input-column">
                <input type="file" :id="videoInputID" :name="videoInputID" accept="video/*" capture style="display: none;cursor: pointer;" ref="videoInput" :data-duration="videoSetting.duration" :data-size="videoSetting.size" @change="postVideoChange" />
                <div class="selected-video" v-if="editData.videoContent && editData.videoContent.uploadFile">
                  <img src="/static/images/video_upload_icon.png" class="selected-video-icon" />
                  <img src="/static/images/red_batsu_icon.png" class="selected-video-remove-icon" @click="removeVideo" />
                </div>
                <div class="av-notify" v-if="editData.videoContent">ひとつの投稿に添付できる動画は一つのみです。投稿済みの動画との差し替えとなります。</div>
              </div>
              <div class="audio-input-column">
                <input type="file" :id="audioInputID" :name="audioInputID" accept="audio/*" capture style="display: none;cursor: pointer;" ref="audioInput" :data-duration="audioSetting.duration" @change="postAudioChange" />
                <div class="selected-audio" v-if="editData.audioContent && editData.audioContent.uploadFile">
                  <img src="/static/images/audio_upload_icon.png" class="selected-audio-icon" />
                  <img src="/static/images/red_batsu_icon.png" class="selected-audio-remove-icon" @click="removeAudio" />
                </div>
                <div class="av-notify" v-if="editData.audioContent">ひとつの投稿に添付できる音声は一つのみです。投稿済みの音声との差し替えとなります。</div>
              </div>
            </div><!-- post controll end -->
          </div><!-- editable-edit end -->
          <div class="post-controlls">
            <div class="add_icons js_group_post_inputs">
              <div class="left_add_icons">
                <div class="icon-button" v-if="authorEditable">
                  <label :for="photoInputID">
                    <img src="/static/images/camera_icon.png" class="add-photo" @click="addPhotoForPost"/>
                  </label>
                </div>
                <div class="icon-button" v-if="authorEditable">
                  <label :for="fileInputID">
                    <img src="/static/images/document_icon.png" class="add-file" @click="addFileForPost"/>
                  </label>
                </div>
                <div class="icon-button" id="add_video_block" v-if="videoUploadAvailable">
                  <label :for="videoInputID">
                    <img src="/static/images/video_icon.png" class="add-video" @click="addVideoForPost"/>
                  </label>
                </div>
                <div class="icon-button" id="add_audio_block" v-if="audioUploadAvailable">
                  <label :for="audioInputID">
                    <img src="/static/images/mic_icon.png" class="add-audio" @click="addAudioForPost"/>
                  </label>
                </div>
                <div class="icon-button">
                  <img src="/static/images/tag_icon.png" class="add-tag" @click="selectTagForPost">
                </div>
                <div class="icon-button">
                  <img src="/static/images/notify_icon.png" class="add-pushtarget" @click="addPushForPost">
                </div>
                <div class="icon-button" v-if="authorEditable">
                  <img src="/static/images/open_lock.png" v-if="privateControllable && !editData.private" @click="toPrivateForPost" />
                  <img src="/static/images/close_lock.png" v-if="privateControllable && editData.private" @click="toNotPrivateForPost" />
                </div>
              </div>
              <div class="right_add_icons" v-if="authorEditable">
                <!-- when loggedInUser is member, imprement this. -->
                <img src="/static/images/vital_gray.png" class="vital-small" v-if="medicalExaminationAvailable" @click="addMedicalExamination" />
                <img src="/static/images/drug_icon.png" class="drug-small" v-if="drugEntryAvailable" @click="addDrugEntry" />
              </div><!-- right_add_icons end -->
            </div><!-- add_icons end -->
            <div class="hash_tags">
              <div class="hash-tag"
                v-for="hashTag in selectedTags"
                :key="'editRecordSelected' + hashTag.id"
                @click="removeSelectedTag(hashTag)">
                <horus-hash-tag-display
                  :hashTag="hashTag"
                  :sizeType="'small'" />
                <img src="/static/images/x_dark_icon.png" class="close-icon" />
              </div>
              <div class="new-hash-tag"
                v-for="(newTag, index) in createTags"
                :key="'newCreatedTagEdit' + index"
                @click="removeCreateTag(index)">
                <img src="/static/images/tag_icon_green.png" class="new-tag-icon" />
                <span v-html="modifiedTagName(newTag)"></span>
                <img src="/static/images/x_dark_icon.png" class="close-icon" />
              </div>
            </div><!-- selected hashtags end -->
            <div class="push-targets">
              <div class="push-target-author"
                v-for="pushAuthor in pushTargets"
                :key="'editData' + pushAuthor.id"
                @click="removePushTargetAuthor(pushAuthor)">
                <img :src="pushAuthor.portrateSrc" class="portrate" />
                <div class="author-name">{{ pushAuthor.name }}</div>
                <img src="/static/images/x_dark_icon.png" class="close-icon" />
              </div>
            </div><!-- push target end -->
            <div class="execute_controlls">
              <div class="left_execute_controlls">
                <div class="target-date-display" v-if="authorEditable">
                  <span class="date-display" @click="setTargetDate">{{ targetDateStr }}</span>
                </div>
              </div>
              <div class="right_execute_controlls">
                <div class="executable_buttons">
                  <button type="button" class="record_post_clear" @click="clearPostForm">キャンセル</button>
                  <button type="button" class="record_post_submit" @click="submitPostForm">更&nbsp;&nbsp;新</button>
                </div>
              </div>
            </div><!-- execute controll end -->
          </div><!-- post controll end -->
        </div><!-- form body end -->
      </div><!-- post form end -->
    </div><!-- modal end -->
  </div><!-- wrapper end -->
</template>

<script>
import moment from 'moment'

import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
import HorusRecordBody from '@/components/timelineRecord/HorusRecordBody.vue'
import HorusPhotoDisplay from '@/components/common/HorusPhotoDisplay.vue'
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusMultiPhotoSelector from '@/components/common/HorusMultiPhotoSelector.vue'
import HorusMultiFileSelector from '@/components/common/HorusMultiFileSelector.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
import HorusMedicalExaminationDisplay from '@/components/postForm/HorusMedicalExaminationDisplay.vue'
import HorusDrugEntryDisplay from '@/components/postForm/HorusDrugEntryDisplay.vue'

const NON_ZERO_TYPES = ['blood_pressure_max_value', 'blood_pressure_min_value', 'body_temperature_value', 'sp_o2_value', 'pulse_rate_value']

export default {
  name: 'HorusRecordEditModal',

  components: {
    HorusRecordHeadInfo,
    HorusRecordBody,
    HorusPhotoDisplay,
    HorusInlinePhoto,
    HorusInlineFile,
    HorusMultiPhotoSelector,
    HorusMultiFileSelector,
    HorusCustomCheckBox,
    HorusHashTagDisplay,
    HorusMedicalExaminationDisplay,
    HorusDrugEntryDisplay
  },

  // 呼び出しもとでselectedTags,pushTargetを初期化しておくこと
  props: {
    author: Object,
    signinMember: Object,
    timelineOwner: Object,
    editRecord: Object,
    targetDate: Object,
    selectedTags: Array,
    createTags: Array,
    pushTargets: Array,
    medicalExamination: Object,
    drugEntry: Object
  },

  data () {
    return {
      editData: {
        surrogated: false,
        private: false,
        textComment: '',
        photos: [],
        files: [],
        medicalExamination: {},
        drugEntry: {},
        videoContent: null,
        audioContent: null,
        removeVideo: false,
        removeAudio: false
      },
      videoSetting: { duration: 10, size: '1080p/30fps' },
      audioSetting: { duration: 60 },
      photoInputID: 'photoInputForEdit',
      fileInputID: 'fileInputForEdit',
      videoInputID: 'videInputForEdit',
      audioInputID: 'audioInputForEdit',
      removePhotos: [],
      removeFiles: [],
      referencePhoto: null,
      showReference: false,
      stageScales: {},
      videoUploadAvailable: false,
      audioUploadAvailable: false
    }
  },

  created () {
    this.initFrom()
    this.initEditData()
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.isShowButtonNative = status
    }
    this.updateUploadButtonStatus()
  },

  updated () {
    if (this.$native.status === true) {
      this.isShowButtonNative = this.$native.status
    }
    this.updateUploadButtonStatus()
  },

  watch: {
    editRecord: { handler: 'initEditData', immediate: true },
    'editData.textComment': { handler: 'fixTextCommentArea' }
  },

  computed: {
    authorIsMember () {
      if (this.author && this.author.organization_id) {
        return true
      }
      return false
    },

    authorEditable () {
      if (this.editRecord && this.author && (this.editRecord.author_id === this.author.id || this.editRecord.timelineOwner.authro_id === this.author.id)) {
        return true
      }
      return false
    },

    surrogatable () {
      if (this.timelineOwner && this.author && this.timelineOwner.author_id !== this.author.id) {
        return true
      }
      return false
    },

    privateControllable () {
      if (this.timelineOwner && this.author && this.timelineOwner.author_id === this.author.id) {
        return true
      }
      return false
    },

    targetDateStr () {
      if (this.targetDate.date) {
        return moment(this.targetDate.date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    medicalExaminationAvailable () {
      if (this.signinMember && this.signinMember.organization && this.signinMember.organization.medicalExaminationAvailable) {
        return true
      } else {
        return false
      }
    },

    drugEntryAvailable () {
      if (this.signinMember && this.signinMember.organization && this.signinMember.organization.drugEntryAvailable) {
        return true
      } else {
        return false
      }
    },

    medicalExaminationExist () {
      if (
        this.medicalExamination.blood_pressure_max_value ||
        this.medicalExamination.blood_pressure_min_value ||
        this.medicalExamination.body_temperature_value ||
        this.medicalExamination.sp_o2_value ||
        this.medicalExamination.pulse_rate_value ||
        this.medicalExamination.irregular_pulse_value >= 0 ||
        this.medicalExamination.anemia_state_value >= 0 ||
        this.medicalExamination.jaundice_state_value >= 0 ||
        this.medicalExamination.oral_state_value >= 0 ||
        this.medicalExamination.respiratory_noize_value >= 0 ||
        this.medicalExamination.respiratory_rate_state_value >= 0 ||
        this.medicalExamination.hart_sound_value >= 0 ||
        this.medicalExamination.abdominal_palpation_value >= 0 ||
        this.medicalExamination.abdominal_auscultation_value >= 0 ||
        this.medicalExamination.edema_value >= 0 ||
        this.medicalExamination.bedsore_value >= 0 ||
        this.medicalExamination.eating_state_value >= 0 ||
        this.medicalExamination.take_medicine_state_value >= 0 ||
        this.medicalExamination.urination_state_value >= 0 ||
        this.medicalExamination.faces_state_value >= 0
      ) {
        return true
      } else {
        return false
      }
    },

    drugEntryExist () {
      if (
        (this.drugEntry.comment && this.drugEntry.comment.length > 0) ||
        this.drugEntry.photos.length > 0 ||
        this.drugEntry.storedPhotos.length > 0
      ) {
        return true
      } else {
        return false
      }
    }

  },

  methods: {
    initFrom () {
      this.photoInputID = 'photoInputE' + Math.floor(Math.random() * 10000)
      this.fileInputID = 'fileInputE' + Math.floor(Math.random() * 10000)
      this.videoInputID = 'videInputE' + Math.floor(Math.random() * 10000)
      this.audioInputID = 'audioInputE' + Math.floor(Math.random() * 10000)
    },

    initEditData () {
      this.editData = {
        surrogated: false,
        private: false,
        textComment: '',
        photos: [],
        files: [],
        medicalExamination: {},
        drugEntry: {},
        videoContent: null,
        audioContent: null,
        removeVideo: false,
        removeAudio: false
      }
      this.showReference = false
      this.referencePhoto = null
      this.removePhotos = []
      this.removeFiles = []
      if (this.editRecord) {
        this.editData.surrogated = this.editRecord.surrogated
        this.editData.private = this.editRecord.private
        this.editData.textComment = this.editRecord.textComment
        this.editData.medicalExamination = this.editRecord.medicalExamination
        this.editData.videoContent = null
        this.editData.audioContent = null
      }
    },

    updateUploadButtonStatus () {
      if (this.authorEditable) {
        if (this.isShowButtonNative) {
          if (process.env.NODE_ENV === 'production') {
            this.videoUploadAvailable = true
            this.audioUploadAvailable = true
          } else if (process.env.NODE_ENV === 'staging') {
            this.videoUploadAvailable = true
            this.audioUploadAvailable = true
          } else {
            this.videoUploadAvailable = true
            this.audioUploadAvailable = true
          }
        } else {
          if (process.env.NODE_ENV === 'production') {
            this.videoUploadAvailable = false
            this.audioUploadAvailable = false
          } else if (process.env.NODE_ENV === 'staging') {
            this.videoUploadAvailable = true
            this.audioUploadAvailable = true
          } else {
            this.videoUploadAvailable = true
            this.audioUploadAvailable = true
          }
        }
      } else {
        this.videoUploadAvailable = false
        this.audioUploadAvailable = false
      }
    },

    fixTextCommentArea () {
      this.$nextTick(() => {
        var textArea = this.$refs.textCommentField
        if (textArea) {
          // 一度高さをリセットしないと、scrollHeightが正しく取得出来ない。ただし、ちょっとちらついてしまう場合がある
          textArea.style.height = '26px'
          if (textArea.offsetHeight < textArea.scrollHeight) {
            textArea.style.height = textArea.scrollHeight + 'px'
          }
        }
      })
    },

    modifiedTagName (newTag) {
      const splited = newTag.split('##')
      if (splited.length > 1) {
        return '<span class="hash_tag_type ' + splited[0] + '">' + splited[0] + '</span>' + splited[1]
      } else {
        return splited[0]
      }
    },

    togglePhotoRemove (photoReportID) {
      var removeIndex = this.removePhotos.indexOf(photoReportID)
      if (removeIndex < 0) {
        this.removePhotos.push(photoReportID)
      } else {
        this.removePhotos.splice(removeIndex, 1)
      }
    },

    photoGonnaRemove (photoReportID) {
      return this.removePhotos.some(pID => pID === photoReportID)
    },

    toggleFileRemove (fileReportID) {
      var removeIndex = this.removeFiles.indexOf(fileReportID)
      if (removeIndex < 0) {
        this.removeFiles.push(fileReportID)
      } else {
        this.removeFiles.splice(removeIndex, 1)
      }
    },

    fileGonnaRemove (fileReportID) {
      return this.removeFiles.some(fID => fID === fileReportID)
    },

    showPhoto (photoReport) {
      this.referencePhoto = photoReport
      this.showReference = true
    },

    showUneditablePhoto (record, photoIndex) {
      this.showPhoto(record.photoReports[photoIndex])
    },

    showUneditableDrugPhoto (record, drugPhotoIndex) {
      console.log(record)
      console.log(record.drugEntry)
      console.log(record.drugEntry.drugEntryPhotos)
      console.log(drugPhotoIndex)
      this.showPhoto(record.drugEntry.drugEntryPhotos[drugPhotoIndex])
    },

    closeReference () {
      this.showReference = false
      this.referencePhoto = null
    },

    videoContentClicked () {
      this.$emit('videoContentClickEvent', this.editRecord.videoContent)
    },

    audioContentClicked () {
      this.$emit('audioContentClickEvent', this.editRecord.audioContent)
    },

    addPhotoForPost () {
      // this.$nextTick(() => {
      //   this.$refs.multiPhotoSelector.addPhotoForPost()
      // })
      if (this.$native.status) {
        this.$native.event('takePhotoEvent', { targetID: this.photoInputID })
      }
    },

    addSelectedPhoto (photoObj) {
      this.editData.photos.push(photoObj)
    },

    removePhoto (index) {
      this.editData.photos.splice(index, 1)
    },

    addFileForPost () {
      // this.$nextTick(() => {
      //   this.$refs.multiFileSelector.addFileForPost()
      // })
      if (this.$native.status) {
        this.$native.event('takeFileEvent', { targetID: this.fileInputID })
      }
    },

    addSelectedFile (fileObj) {
      this.editData.files.push(fileObj)
    },

    removeFile (index) {
      this.editData.files.splice(index, 1)
    },

    addVideoForPost () {
      if (this.$native.status) {
        this.$native.event('takeVideoEvent', { duration: this.videoSetting.duration, size: this.videoSetting.size, targetID: this.videoInputID })
      }
    },

    postVideoChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.editData.videoContent = { uploadFile: selectedFiles[0], name: selectedFiles[0].name }
      }
      this.$nextTick(() => {
        this.$refs.videoInput.value = ''
      })
    },

    removeVideo () {
      this.editData.videoContent = null
    },

    toggleVideoRemove () {
      this.editData.removeVideo = !this.editData.removeVideo
    },

    addAudioForPost () {
      if (this.$native.status) {
        this.$native.event('takeAudioEvent', { duration: this.audioSetting.duration, targetID: this.audioInputID })
      }
    },

    postAudioChange (event) {
      var selectedFiles = event.target.files
      if (selectedFiles) {
        this.editData.audioContent = { uploadFile: selectedFiles[0], name: selectedFiles[0].name }
      }
      this.$nextTick(() => {
        this.$refs.audioInput.vaule = ''
      })
    },

    removeAudio () {
      this.editData.audioContent = null
    },

    toggleAudioRemove () {
      this.editData.removeAudio = !this.editData.removeAudio
    },

    selectTagForPost () {
      this.$emit('openTagSelectionModalEvent')
    },

    addPushForPost () {
      this.$emit('openPushTargetSelectionModalEvent')
    },

    postSurrogateToggle () {
      this.editData.surrogated = !this.editData.surrogated
    },

    toPrivateForPost () {
      this.editData.private = true
    },

    toNotPrivateForPost () {
      this.editData.private = false
    },

    addMedicalExamination () {
      this.$emit('openMedicalExaminationModalEvent')
    },

    addDrugEntry () {
      this.$emit('openDrugEntryModalEvent')
    },

    removeSelectedTag (tag) {
      this.$emit('removeSelectedTagEvent', tag)
    },

    removeCreateTag (index) {
      this.$emit('removeCreateTagEvent', index)
    },

    removePushTargetAuthor (author) {
      this.$emit('removeSelectedAuthorEvent', author)
    },

    setTargetDate () {
      this.$emit('setTargetDateForPostEvent')
    },

    clearTargetDate () {
      this.$emit('clearTargetDateForPostEvent')
    },

    clearPostForm () {
      this.$emit('postformClearEvent', () => { this.executeClearForm() })
    },

    executeClearForm () {
      this.initFrom()
      this.initEditData()
    },

    submitPostForm () {
      // console.log('submitUpdateForm')
      // CHECK FORM DATA
      // empty content is failed
      // console.log('textComment: ' + (!this.editData.textComment || this.editData.textComment.length === 0))
      // console.log('photos: ' + ((this.editData.photos.length + (this.editRecord.photoReports.length - this.removePhotos.length)) === 0))
      // console.log('files: ' + ((this.editData.files.length + (this.editRecord.fileReports.length - this.removeFiles.length)) === 0))
      // console.log('medEx: ' + !this.medicalExaminationExist)
      // console.log('drugEntry: ' + !this.drugEntryExist)
      // console.log('drugEntryComment: ' + (this.drugEntry.comment.length === 0))
      // console.log('drugEntryPhoto: ' + (this.drugEntry.photos.length === 0))
      // console.log('total: ' + (
      //   (!this.editData.textComment || this.editData.textComment.length === 0) &&
      //   (this.editData.photos.length + (this.editRecord.photoReports.length - this.removePhotos.length)) === 0 &&
      //   (this.editData.files.length + (this.editRecord.fileReports.length - this.removeFiles.length)) === 0 &&
      //   !this.medicalExaminationExist &&
      //   this.drugEntry.comment.length === 0 &&
      //   this.drugEntry.photos.length === 0
      // ))
      if (
        (!this.editData.textComment || this.editData.textComment.length === 0) &&
        (this.editData.photos.length + (this.editRecord.photoReports.length - this.removePhotos.length)) === 0 &&
        (this.editData.files.length + (this.editRecord.fileReports.length - this.removeFiles.length)) === 0 &&
        !this.medicalExaminationExist &&
        (this.drugEntry.comment.length === 0 && this.drugEntry.photos.length === 0) &&
        (!this.editData.videoContent && !this.editRecord.videoContent) &&
        (!this.editData.audioContent && !this.editRecord.audioContent)
      ) {
        console.log('post contents is empty!')
        this.$emit('showNotifyEvent', '投稿内容のエラー', '投稿編集のエラー／書込情報が全て削除されています。\n投稿自身を削除する場合は、削除ボタン（ゴミ箱ボタン）から行ってください。')
      } else {
        // CREATE FORM DATA
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        formData.append('target_record_id', this.editRecord.id)
        formData.append('record[private]', this.editData.private)
        formData.append('record[surrogate]', this.editData.surrogated)
        if (this.targetDate.change) {
          formData.append('record[nb_calendar_target_date]', moment(this.targetDate.date).format('YYYY/MM/DD'))
          formData.append('record[nb_calendar_target_time]', moment(this.targetDate.date).format('HH:mm'))
        }
        formData.append('record[text_comment]', this.editData.textComment)
        this.editData.photos.forEach(photo => {
          formData.append('record[photos][]', photo.uploadFile, photo.name)
        })
        this.editData.files.forEach(file => {
          formData.append('record[files][]', file.uploadFile, file.name)
        })
        this.removePhotos.forEach(removePhoto => {
          formData.append('record[remove_photos][]', removePhoto)
        })
        this.removeFiles.forEach(removeFile => {
          formData.append('record[remove_files][]', removeFile)
        })
        // remove drug entry photos 関係
        Object.entries(this.medicalExamination).forEach(entry => {
          if (NON_ZERO_TYPES.some(name => name === entry[0])) {
            if (!isNaN(entry[1]) && entry[1] > 0) {
              formData.append('record[' + entry[0] + ']', entry[1])
            }
          } else {
            if (!isNaN(entry[1]) && entry[1] >= 0) {
              formData.append('record[' + entry[0] + ']', entry[1])
            }
          }
        })
        if (this.drugEntry) {
          if (this.drugEntry.comment) {
            formData.append('record[drug_entry_comment]', this.drugEntry.comment)
          }
          if (this.drugEntry.photos) {
            this.drugEntry.photos.forEach(dPhoto => {
              formData.append('record[drug_entry_photos][]', dPhoto.uploadFile, dPhoto.name)
            })
          }
          if (this.drugEntry.removePhotos) {
            this.drugEntry.removePhotos.forEach(remDPhotoID => {
              formData.append('record[remove_drug_entry_photos][]', remDPhotoID)
            })
          }
        }
        if (this.editData.removeVideo) {
          formData.append('record[remove_video]', 'yes')
        }
        if (this.editData.videoContent && this.editData.videoContent.uploadFile) {
          formData.append('record[video]', this.editData.videoContent.uploadFile, this.editData.videoContent.name)
        }
        if (this.editData.removeAudio) {
          formData.append('record[remove_audio]', 'yes')
        }
        if (this.editData.audioContent && this.editData.audioContent.uploadFile) {
          formData.append('record[audio]', this.editData.audioContent.uploadFile, this.editData.audioContent.name)
        }
        this.selectedTags.forEach(tag => {
          formData.append('record[hashtags][]', tag.id)
        })
        this.createTags.forEach(newtag => {
          formData.append('record[new_hashtags][]', newtag)
        })
        this.pushTargets.forEach(target => {
          if (target.isMember) {
            formData.append('record[pushsuoms][]', target.rawID)
          } else {
            formData.append('record[pushtargets][]', target.rawID)
          }
        })
        // send form data
        this.$emit('submitPostFormEvent', formData, { deleteObjects: { photos: this.removePhotos, files: this.removeFiles, drugPhotos: this.drugEntry.removePhotos } }, () => {
          this.closeReference()
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mediaQuery.scss';

.horus-record-edit-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .edit-modal {
    position: absolute;
    top: 30px;
    left: calc((100vw - 900px) / 2);
    width: 900px;
    max-height: calc(100vh - 100px);
    display: flex;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100vh - 100px);
      left: 5px;
    }
  }
}
.reference-column {
  margin-right: 10px;
  border-right: 1px solid #aaa;
  width: 50%;
  .reference-display-controll {
    padding: 10px;
    img {
      width: 20px;
    }
  }
}
.form-column {
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  padding-right: 5px;
  .record-header {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    padding: 5px;
    border: 1px solid #ddd;
    .editing-title {
      text-align: right;
      .title-text {
        padding: 3px 10px;
        background-color: #007b43;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .form-body {
    padding: 10px;
    .editable-edit-column {
      .surrogate-toggle {
        margin-bottom: 5px;
        .surrogate-toggle-btn {
          border: 1px solid #999;
          border-radius: 3px;
          background-color: #999;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          &.surrogated {
            border-color: #d9333f;
            background-color: #d9333f;
          }
        }
      }
      textarea {
        width: calc(100% - 6px);
        resize: none;
        font-size: 16px;
      }
    }
  }
}
.post-controlls {
  .uploaded-photos {
    margin: 10px 0;
    h3 {
      margin: 0;
      margin-bottom: 5px;
      border-bottom: 1px solid #aaa;
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
    .uploaded-photo-thumbnails {
      display: flex;
      flex-wrap: wrap;
      .uploaded-photo-thumbnail {
        margin: 0 10px 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
        text-align: center;
        .photo-in-timeline {
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
  .file-reports {
    margin: 10px 0;
    h3 {
      margin: 0;
      margin-bottom: 5px;
      border-bottom: 1px solid #aaa;
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
    .uploaded-file-list {
      display: flex;
      flex-wrap: wrap;
      .uploaded-file {
        margin: 0 10px 10px 0;
        padding: 10px;
        border: 1px solid #aaa;
        text-align: center;
        .file-in-timeline {
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
  .uploaded-video {
    margin: 10px 0;
    h3 {
      margin: 0;
      margin-bottom: 5px;
      border-bottom: 1px solid #aaa;
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
    .video-column {
      display: inline-block;
      border: 1px solid #aaa;
      padding: 5px;
      .video-block {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .video-ready {
        display: flex;
        flex-direction: column;
        align-items: center;
        .video-thumbnail-block {
          position: relative;
          margin-bottom: 5px;
          .video-thumbnail {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }
          .video-play-icon {
            position: absolute;
            top: 20px;
            left: 10px;
          }
        }
        .video-content-name {
          font-size: 0.9em;
        }
      }
    }
  }
  .uploaded-audio {
    margin: 10px 0;
    h3 {
      margin: 0;
      margin-bottom: 5px;
      border-bottom: 1px solid #aaa;
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
    .audio-column {
      display: inline-block;
      border: 1px solid #aaa;
      padding: 5px;
      .audio-block {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .video-input-column {
    margin: 10px 0;
    .selected-video {
      position: relative;
      width: 105px;
      .upload-video-icon {
        position: absolute;
        top: 0;
        right: 0;
      }
      .selected-video-remove-icon {
        position: absolute;
        top: 0;
        left: 90;
        cursor: pointer;
      }
    }
  }
  .audio-input-column {
    margin: 10px 0;
    .selected-audio {
      position: relative;
      width: 65px;
      .upload-audio-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 52px;
      }
      .selected-audio-remove-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .av-notify {
    display: inline-block;
    padding: 3px;
    border: 1px solid #d9333f;
    color: #d9333f;
    font-size: 0.8em;
  }
  .add_icons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #aaa;
    padding-top: 10px;
    input {
      display: none;
    }
    .left_add_icons {
      display: flex;
      margin-left: 10px;
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
        @include mediaQuery('phone') {
          margin: 0 3px 0 3px;
        }
      }
    }
    .right_add_icons {
      display: flex;
      img {
        cursor: pointer;
        margin: 0 0 10px 20px;
        height: 30px;
      }
      .icon-button {
        cursor: pointer;
        margin: 0 20px 10px 0;
        @include mediaQuery('phone') {
          margin: 0 3px 0 3px;
        }
      }
    }
  }
  .hash_tags {
    display: flex;
    flex-wrap: wrap;
    .hash-tag {
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      .close-icon {
        margin-left: -5px;
        width: 10px;
        vertical-align: middle;
      }
    }
    .new-hash-tag {
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      .new-tag-icon {
        width: 10px;
      }
      .close-icon {
        width: 10px;
      }
    }
  }
}
.push-targets {
  display: flex;
  flex-wrap: wrap;
  .push-target-author {
    cursor: pointer;
    margin: 0 10px 10px 0;
    .portrate {
      width: 15px;
      vertical-align: middle;
    }
    .author-name {
      display: inline-block;
    }
    .close-icon {
      width: 10px;
      vertical-align: middle;
    }
  }
}
.execute_controlls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left_execute_controlls {
    margin-top: 10px;
    .target-date-button {
      padding: 3px 10px;
      border: 1px solid #999;
      background-color: #999;
      color: #fff;
      font-weight: lighter;
      font-size: 14px;
    }
    .target-date-display {
      span {
        display: inline-block;
        cursor: pointer;
        height: 19px;
        margin-right: 3px;
        padding: 5px 10px 3px 10px;;
        background-color: #fff;
        color: #333;
        border: 1px solid #999;
        font-size: 16px;
      }
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
  .right_execute_controlls {
    margin-top: 10px;
    text-align: right;
    .executable_buttons {
      button {
        padding: 3px 10px;
        font-weight: lighter;
        font-size: 14px;
        &.record_post_clear {
          background-color: #fff;
          color: #333;
          border: 1px solid #ddd;
        }
        &.record_post_submit {
          background-color: #666;
          color: #fff;
          border: 1px solid #666;
        }
      }
    }
  }
}
</style>
