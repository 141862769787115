<template>
  <div class="horus-member-portal-view">
    <div class="switching-tab-bar">
      <div class="left-column">
        <img src="/static/images/addressbook_icon.png" @click="updateColumnDisplay('userlist')"/>
      </div>
      <div class="right-column">
        <img src="/static/images/task_pad_icon.png" @click="updateColumnDisplay('task')" />
        <img src="/static/images/timeline.png" @click="updateColumnDisplay('multipost')" />
      </div>
    </div>
    <div class="horus-member-portal-frame">
      <div class="horus-member-portal-inner-frame">
        <div class="horus-member-portal-content" :class="{'two-column': columnDisplay !== 'userlist'}">
          <div class="member-portal-controll">
            <input type="text" class="search-user-input" placeholder="絞込(かな)" v-model="searchText"/>
            <div class="controll-buttons">
              <img src="/static/images/qr_icon.png" class="name-index-btn" @click="selectByQR" />
              <img src="/static/images/name_index_on_icon.png" class="name-index-btn" v-show="!nameIndexOn" @click="toggleNameIndex" />
              <img src="/static/images/name_index_off_icon.png" class="name-index-btn" v-show="nameIndexOn" @click="toggleNameIndex" />
              <img src="/static/images/grid_layout_icon.png" class="layout-btn" v-show="!layoutGrid" @click="toggleUserLayoutGrid" />
              <img src="/static/images/line_layout_icon.png" class="layout-btn" v-show="layoutGrid" @click="toggleUserLayoutGrid" />
              <select class="user-display-type-select" v-model="userDisplayLimitType">
                <option v-bind:value="LIMIT_TYPE_CHARGED">担当表示</option>
                <option v-bind:value="LIMIT_TYPE_LIMITED">一部表示</option>
                <option v-bind:value="LIMIT_TYPE_NONE">全表示</option>
              </select>
            </div>
          </div><!-- controll end -->
          <div v-if="usersNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
          <div v-if="relatedUsers.length <= 0" class="now-loading">かかりつけ利用者が登録されていません</div>
          <div class="user-list" v-if="!nameIndexOn">
            <div class="list-inside-box">
              <horus-related-user-column
                v-for="user in relatedUsers"
                v-show="(userDisplayLimitType != LIMIT_TYPE_LIMITED && userDisplayLimitType != LIMIT_TYPE_CHARGED) || (userDisplayLimitType == LIMIT_TYPE_CHARGED && user.charged) || (searchText.length > 0 && isLimited(user)) || (userDisplayLimitType == LIMIT_TYPE_LIMITED && user.limited)"
                :key="'memberPortalUserList' + user.author_id"
                :user="user"
                :signinMember="signinMember"
                :layoutGrid="layoutGrid"
                :meetingAvailable="meetingAvailable"
                v-on:showTimelineEvent="showTimeline"
                v-on:createTaskEvent="createTaskByUser"
                v-on:createMeetingEvent="prepareCreateMeeting"
                />
            </div>
          </div>
          <div  class="user-indexed" v-if="nameIndexOn">
            <div class="jump-index">
              <button class="jump-index-btn"><a href="#name-index-0">ア</a></button>
              <button class="jump-index-btn"><a href="#name-index-1">カ</a></button>
              <button class="jump-index-btn"><a href="#name-index-2">サ</a></button>
              <button class="jump-index-btn"><a href="#name-index-3">タ</a></button>
              <button class="jump-index-btn"><a href="#name-index-4">ナ</a></button>
              <button class="jump-index-btn"><a href="#name-index-5">ハ</a></button>
              <button class="jump-index-btn"><a href="#name-index-6">マ</a></button>
              <button class="jump-index-btn"><a href="#name-index-7">ヤ</a></button>
              <button class="jump-index-btn"><a href="#name-index-8">ラ</a></button>
              <button class="jump-index-btn"><a href="#name-index-9">ワ</a></button>
              <button class="jump-index-btn"><a href="#name-index-10">他</a></button>
            </div>
            <div class="user-list-indexed">
              <div class="index-column"
                v-for="(indexColumn, index) in aiueoArray"
                :key="'indexColumn' + indexColumn.name">
                <div class="index-title"><a :name="'name-index-' + index">{{ indexColumn.name }}</a></div>
                <div class="indexed-users">
                  <horus-related-user-column
                    v-for="user in indexColumn.users"
                    v-show="(searchText.length > 0 && isLimited(user)) || (userDisplayLimitType == LIMIT_TYPE_CHARGED && user.charged) || (userDisplayLimitType == LIMIT_TYPE_LIMITED && user.limited) || (userDisplayLimitType != LIMIT_TYPE_LIMITED && userDisplayLimitType != LIMIT_TYPE_CHARGED)"
                    :key="'memberPortalIndexedUser' + user.author_id"
                    :user="user"
                    :signinMember="signinMember"
                    :layoutGrid="layoutGrid"
                    :meetingAvailable="meetingAvailable"
                    v-on:showTimelineEvent="showTimeline"
                    v-on:createTaskEvent="createTaskByUser"
                    v-on:createMeetingEvent="prepareCreateMeeting"
                    />
                </div>
              </div>
            </div>
          </div><!-- user-indexed -->
        </div><!-- horus-member-portal-content -->
        <div class="user-task-column" :class="{'show-task': columnDisplay === 'task'}">
          <horus-user-task
            :timelineOwner="timelineOwner"
            :signinMember="signinMember"
            :taskUpdatedAt="taskUpdatedAt"
            :taskDisplayConfiguration="taskDisplayConfiguration"
            v-on:userTaskUpdatedEvent="userTaskUpdated"
            v-on:createTaskEvent="createUserTask('none')"
            v-on:editTaskEvent="editTask"
            v-on:deleteTaskEvent="deleteTask"
            v-on:showTaskDetailEvent="showTaskDetail"
            v-on:showTaskPhotoEvent="showTaskPhoto"
            v-on:showTaskConfigurationEvent="showTaskConfiguration"
            />
        </div>
        <div class="multipost-records-column" :class="{'show-multipost': columnDisplay === 'multipost'}">
          <h2>一斉同報<button class="new-multipost" @click="showMultipostSelection">新規一斉同報</button></h2>
          <div class="multipost-record" v-for="record in multipostHistory" :key="'multiposts' + record.id">
            <div class="record-head-text">
              <div class="record-target-date">送信日時: {{ record.targetAtJp }}</div>
              <div class="target-authors">
                <div>送信先</div>
                <div class="target-author" v-for="author in record.multipostAuthors" :key="'mltp' + record.id + 'au' + author.id">
                  <img :src="author.portrateSrc" />
                  <div class="name-column">{{ author.name }}({{ author.age }})</div>
                </div>
              </div>
            </div>
            <horus-record-body
              :timelineRecord="record"
              />
          </div>
        </div>
      </div><!-- horus-member-portal-inner-frame -->
      <horus-footer />
    </div>
    <horus-select-timeline-owner-modal
      :signinMember="signinMember"
      :timelineOwners="relatedUsers"
      :showModal="showSelectTimelineOwnerModal"
      v-on:timelineOwnerSelectedEvent="timelineOwnerSelected"
      v-on:timelineOwnerSelectCancelEvent="timelineOwnerSelectCancel"
      />
    <horus-zoom-meeting-create-modal
      :loggedInUser="loggedInUser"
      :signinMember="signinMember"
      :showModal="showMeetingFormModal"
      :mainAttendee="meetingMainAttendee"
      :attendees="meetingAttendees"
      :targetTime="meetingTargetTime"
      v-on:addAttendeeEvent="addMeetingAttendee"
      v-on:removeAttendeeEvent="removeMeetingAttendee"
      v-on:setTargetTimeEvent="setMeetingTargetTime"
      v-on:changeTargetTimeEvent="setMeetingTargetTime"
      v-on:removeTargetTimeEvent="removeMeetingTargetTime"
      v-on:submitEvent="submitMeeting"
      v-on:cancelEvent="cancelMeeting"
      v-if="meetingAvailable"
      />
    <horus-user-task-form-modal
      :showModal="showUserTaskFormModal"
      :signinMember="signinMember"
      :zoomUser="zoomUser"
      :timelineOwner="timelineOwner"
      :organization="selectTargetOrganization"
      :sourceTask="editTargetTask"
      :targetDate="formTaskTargetAt"
      :selectedTags="taskSelectedTags"
      :createTags="taskCreateTags"
      :pushTargets="taskPushTargets"
      v-on:setTargetDateEvent="setTaskTargetDate"
      v-on:showTaskPhotoEvent="showTaskPhoto"
      v-on:openTagSelectionModalEvent="openTagSelectionModalForTask"
      v-on:removeSelectedTagEvent="removeSelectedTagForTask"
      v-on:removeCreateTagEvent="removeCreateTagForTask"
      v-on:openPushTargetSelectionModalEvent="openPushTargetSelectionModalForTask"
      v-on:openMemberSelectModalEvent="openMemberSelectionModal"
      v-on:removeSelectedAuthorEvent="removeSelectedAuthorForTask"
      v-on:postformClearEvent="taskFormClear"
      v-on:submitPostFormEvent="submitTaskForm"
      v-on:showNotifyEvent="showNotifyDialog" />
    <horus-photo-modal
      :timelineOwner="timelineOwner"
      :photos="photoModalPhotos"
      :type="'userTask'"
      :author="null"
      :targetAt="null"
      :title="'予定写真'"
      :firstShow="photoModalFirst"
      :hashTags="[]"
      :showPhotoModal="photoModalShow"
      v-on:jumpToOriginEvent="jumpToOriginFromPhotoModal"
      v-on:photoModalCloseEvent="closePhotoModal"
      />
    <horus-tag-select-for-post-modal
      :showTagSelectionModal="presentedModal === PRESENTED_MODAL_TYPE.TAG_SELECTION"
      :hashTags="hashTags"
      :activeTags="selectTargetsForTag"
      :createTags="createdTargetForTag"
      :signinMember="signinMember"
      v-on:tagSelectionModalCloseEvent="tagSelectionModalClose"
      v-on:updateSelectedTagEvent="updateSelectedTag"
      v-on:addNewPostTagEvent="addNewPostTag"
      v-on:clearSelectedTagEvent="clearSelectedTags"
      v-on:removeNewTagEvent="removeNewPostTag"
      />
    <horus-author-select-for-post-modal
      :showAuthorSelectionModal="presentedModal === PRESENTED_MODAL_TYPE.PUSH_TARGET_SELECTION"
      :signinMember="signinMember"
      :families="notificatableFamilies"
      :organizations="organizations"
      :organizationStaffVisibilities="organizationStaffVisibilities"
      :selectedAuthors="selectTargetForAuthor"
      :allFamilySelected="allFamilySelectForAuthor"
      :selectedOrganizations="selectOrganizationTargetForAuthor"
      :selectLockAuthor="selectLockAuthor"
      :deselectLockAuthor="deselectLockAuthor"
      v-on:toggleAllFamilyEvent="toggleAllFamilySelect"
      v-on:toggleAllMemberEvent="toggleAllMemberSelect"
      v-on:toggleSelectAuthorEvent="toggleSelectAuthor"
      v-on:allAuthorSelectEvent="allAuthorsSelect"
      v-on:clearSelectedAuthorEvent="clearSelectedAuthors"
      v-on:authorSelectionModalCloseEvent="pushTargetSelectModalClose"
      />
    <horus-member-select-modal
      :showModal="showMemberSelectModal"
      :organization="selectTargetOrganization"
      :selectedMembers="selectTargetForAuthor"
      :signinMember="signinMember"
      :modalTitle="'予定の共有職員選択'"
      v-on:toggleSelectMemberEvent="toggleSelectMember"
      v-on:allMemberSelectEvent="allMembersSelect"
      v-on:clearSelectedMemberEvent="clearSelectedMembers"
      v-on:memberSelectionModalCloseEvent="closeMemberSelectModal"
      />
    <horus-select-timeline-owner-for-multipost
      :showModal="multipostTargetSelectionDisplay"
      :timelineOwners="relatedUsers"
      v-on:multipostTargetSelectCancelEvent="cancelMultipostTargetSelection"
      v-on:multipostTargetSelectSubmitEvent="submitMultipostTargetSelection"
      v-on:multipostTargetSelectAllSubmitEvent="submitMultipostTargetSelectAll"
      />
    <horus-multipost-form-modal
      :showModal="multipostFormDisplay"
      :signinMember="signinMember"
      :multipostTargets="multipostTargets"
      :targetDate="multipostTargetDate"
      v-on:setTargetDateForPostEvent="setTargetDateForMultipost"
      v-on:clearTargetDateForPostEvent="clearTargetDateForMultipost"
      v-on:postformCancelEvent="cancelMultipost"
      v-on:submitPostFormEvent="submitMultipost"
      />
    <horus-post-target-date-selection-modal
      :showDateSelectionModal="postTargetDateModalDisplay"
      :initializeDate="multipostTargetDate"
      v-on:targetDateSelectionCloseEvent="targetDateSelectionModalClose"
      v-on:selectTargetDateCancelEvent="targetDateSelectionCancel"
      v-on:selectTargetDateCommitEvent="targetDateSelectionCommit"
      />
    <horus-future-date-selection-modal
      :showDateSelectionModal="futureDateSelectionModalShow"
      :initializeDate="formTaskTargetAt"
      v-on:targetDateSelectionCloseEvent="futureDateSelectionModalClose"
      v-on:selectTargetDateCancelEvent="futureDateSelectionCancel"
      v-on:selectTargetDateCommitEvent="futureDateSelectionCommit"
      />
    <horus-sending-modal
      :showSendingModal="showSendingModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  TimelineOwner,
  Author,
  TimelineRecord,
  StaffVisibility
} from '@/models'

import {
  RelatedUser,
  MemberPortalConfiguration,
  MemberPortalShowingUser,
  StaticIdentifyCode,
  UserWatchingState
} from '@/api'

import HorusRelatedUserColumn from '@/components/memberPortal/HorusRelatedUserColumn.vue'
import HorusUserTask from '@/components/userTask/HorusUserTask.vue'

import HorusPhotoModal from '@/components/common/HorusPhotoModal.vue'
import HorusSelectTimelineOwnerModal from '@/components/userTask/HorusSelectTimelineOwnerModal.vue'
import HorusUserTaskFormModal from '@/components/userTask/HorusUserTaskFormModal.vue'
import HorusTagSelectForPostModal from '@/components/postForm/HorusTagSelectForPostModal.vue' // mixin HorusTagSelection.js
import HorusAuthorSelectForPostModal from '@/components/postForm/HorusAuthorSelectForPostModal.vue' // mixin HorusAuthorSelection.js
import HorusMemberSelectModal from '@/components/common/HorusMemberSelectModal.vue' // mixin HorusMemberSelection.js
import HorusFutureDateSelectionModal from '@/components/common/HorusFutureDateSelectionModal.vue'// mixin HorusFutureDateSelectionControll.js
// create meeting modal
import HorusZoomMeetingCreateModal from '@/components/zoomMeeting/HorusZoomMeetingCreateModal.vue'// mixin HorusZoomMeetingControll.js
// multipost
import HorusSelectTimelineOwnerForMultipost from '@/components/multipost/HorusSelectTimelineOwnerForMultipost.vue'
import HorusMultipostFormModal from '@/components/multipost/HorusMultipostFormModal.vue'
import HorusPostTargetDateSelectionModal from '@/components/postForm/HorusPostTargetDateSelectionModal.vue'
import HorusRecordBody from '@/components/timelineRecord/HorusRecordBody.vue'
import HorusSendingModal from '@/components/common/HorusSendingModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

// mixins
import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusManageQrReaderBtn from '@/mixin/HorusManageQrReaderBtn.js'
import HorusUserTaskControll from '@/mixin/HorusUserTaskControll.js'
import HorusTagSelection from '@/mixin/HorusTagSelection.js'
import HorusAuthorSelection from '@/mixin/HorusAuthorSelection.js'
import HorusOrganizationMemberVisibility from '@/mixin/HorusOrganizationMemberVisibility.js'
import HorusTimelineOwnerRelatedData from '@/mixin/HorusTimelineOwnerRelatedData.js'
import HorusMemberSelection from '@/mixin/HorusMemberSelection.js'
import HorusFutureDateSelectionControll from '@/mixin/HorusFutureDateSelectionControll.js'
import HorusZoomMeetingControll from '@/mixin/HorusZoomMeetingControll.js'

import * as PRESENTED_MODAL_TYPE from '@/assets/constants/modalType.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [
    HorusConfirmDialogControll,
    HorusManageQrReaderBtn,
    HorusUserTaskControll,
    HorusTagSelection,
    HorusAuthorSelection,
    HorusOrganizationMemberVisibility,
    HorusTimelineOwnerRelatedData,
    HorusMemberSelection,
    HorusFutureDateSelectionControll,
    HorusZoomMeetingControll
  ],

  name: 'HorusMemberPortal',

  props: {
    signinMember: Object,
    loggedInAuthor: Object,
    zoomUser: Object,
    taskUpdatedAt: Date,
    taskDisplayConfiguration: Object
  },

  components: {
    HorusRelatedUserColumn,
    HorusUserTask,
    HorusSelectTimelineOwnerModal,
    HorusUserTaskFormModal,
    HorusPhotoModal,
    HorusTagSelectForPostModal,
    HorusAuthorSelectForPostModal,
    HorusMemberSelectModal,
    // HorusPostTargetDateSelectionModal,
    HorusFutureDateSelectionModal,
    HorusFooter,
    HorusConfirmDialog,
    HorusZoomMeetingCreateModal,
    HorusSelectTimelineOwnerForMultipost,
    HorusMultipostFormModal,
    HorusPostTargetDateSelectionModal,
    HorusRecordBody,
    HorusSendingModal
  },

  data () {
    return {
      timelineOwner: null,
      nameIndexOn: false,
      layoutGrid: true,
      userDisplayLimited: false,
      userDisplayLimitType: 2,
      searchText: '',
      portaConfig: null,
      usersNowLoading: true,
      limitedUsers: [],
      relatedUsers: [],
      chargedUsers: [],
      aiueoArray: [],
      isNative: false,
      showTask: false,
      columnDisplay: 'userlist',
      PRESENTED_MODAL_TYPE: PRESENTED_MODAL_TYPE,
      presentedModal: PRESENTED_MODAL_TYPE.NONE,
      multipostHistory: [],
      multipostTargetSelectionDisplay: false,
      multipostTargets: [],
      multipostFormDisplay: false,
      multipostTargetDate: { change: false, date: new Date() },
      postTargetDateModalDisplay: false,
      showSendingModal: false
    }
  },

  created () {
    this.LIMIT_TYPE_NONE = 0
    this.LIMIT_TYPE_LIMITED = 1
    this.LIMIT_TYPE_CHARGED = 2
  },

  watch: {
    signinMember: { handler: 'initObjects', immediate: true },
    userDisplayLimitType: { handler: 'updateUserDisplayLimitType', immediate: true }
  },

  computed: {
    meetingAvailable () {
      if (process.env.VUE_APP_MEETING_AVAILABLE && this.signinMember.organization && this.signinMember.organization.callVideoMeetingAvailable && this.zoomUser) {
        return true
      }
      return false
    }
  },

  methods: {
    async initObjects () {
      console.log('initObject call,')
      await this.loadRelatedUsers()
      this.loadPortalConfig()
      this.loadPortalDisplayUser()
      this.loadStaffVisibility()
      this.loadUnreadCount()
      this.loadMultipostHistory()
    },

    async loadRelatedUsers () {
      console.log('loadRelatedUsers,')
      if (this.signinMember) {
        console.log('loadRelatedUsers, with signinMember...')
        this.usersNowLoading = true
        let relatedRes = await RelatedUser.fetch()
        console.log(relatedRes)
        var unloadAuthors = []
        for(var userStat of relatedRes.related_user_status) {
          if (!Author.find(userStat.author_id)) {
            if (unloadAuthors.indexOf(userStat.author_id) < 0) unloadAuthors.push(userStat.author_id)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ idList: unloadAuthors })
          console.log('author load.')
        }
        console.log('before set author')
        console.log(relatedRes.related_user_status)
        for(var userStatus of relatedRes.related_user_status) {
          userStatus.author = await Author.find(userStatus.author_id)
        }
        console.log('after set author')
        console.log(relatedRes.related_user_status)
        this.relatedUsers = relatedRes.related_user_status
        console.log(this.relatedUsers)
        this.relatedUsers.sort((f, s) => {
          if (f.author.nameKana > s.author.nameKana) {
            return 1
          } else if (f.author.nameKana < s.author.nameKana) {
            return -1
          } else {
            return 0
          }
        })
        console.log('after sort')
        console.log(this.relatedUsers)
        await this.prepareAiueo()
        console.log('after prepareaiueo')
        console.log(this.relatedUsers)
        this.usersNowLoading = false
      }
    },

    loadUnreadCount () {
      UserWatchingState.fetch({ targetUserID: null }).then(res => {
        console.log(res)
        res.user_watching_states.forEach(state => {
          // console.log(state)
          var targetUser = this.relatedUsers.find((ruser) => ruser.user_id == state.user_id)
          // console.log(targetUser)
          if (targetUser) {
            targetUser.unread_count = state.unread_count
          }
        })
      }).catch(err => {
        console.error(err)
      })
    },

    prepareAiueo () {
      this.aiueoArray = [
        {name: 'ア', users: []},
        {name: 'カ', users: []},
        {name: 'サ', users: []},
        {name: 'タ', users: []},
        {name: 'ナ', users: []},
        {name: 'ハ', users: []},
        {name: 'マ', users: []},
        {name: 'ヤ', users: []},
        {name: 'ラ', users: []},
        {name: 'ワ', users: []},
        {name: '他', users: []}
      ]
      var indexArray = ['アイウエオ', 'カキクケコガギグゲゴ', 'サシスセソザジズゼゾ', 'タチツテトダヂヅデド', 'ナニヌネノ', 'ハヒフヘホバビブベボパピプペポ', 'マミムメモ', 'ヤユヨ', 'ラリルレロ', 'ワヲン']
      this.relatedUsers.forEach(user => {
        var exist = false
        indexArray.forEach((kanaSet, index) => {
          if (kanaSet.indexOf(user.author.nameKana[0]) >= 0) {
            this.aiueoArray[index].users.push(user)
            exist = true
          }
        })
        if (!exist) {
          this.aiueoArray[10].users.push(user)
        }
      })
    },

    async loadPortalConfig () {
      let rel = await MemberPortalConfiguration.fetch()
      if (rel.portal_configuration.display_style === 0) {
        this.layoutGrid = true
      } else {
        this.layoutGrid = false
      }
      if (rel.portal_configuration.indexing_element === 0) {
        this.nameIndexOn = false
      } else {
        this.nameIndexOn = true
      }
    },

    async loadPortalDisplayUser () {
      let rel = await MemberPortalShowingUser.fetchList()
      this.limitedUsers = rel.portal_showing_users
      for(var user of this.relatedUsers) {
        if (this.limitedUsers.some(lu => lu.user_id === user.user_id)) {
          user.limited = true
        } else {
          user.limited = false
        }
      }
      if (this.limitedUsers.length <= 0) {
        this.userDisplayLimited = false
        this.userDisplayLimitType = this.LIMIT_TYPE_CHARGED
      } else {
        this.userDisplayLimited = true
        this.userDisplayLimitType = this.LIMIT_TYPE_LIMITED
      }
    },

    async loadStaffVisibility () {
      // console.log('load staff visibilities.......')
      // if (this.relatedUsers.length > this.signinMember.author.memberStaffVisibilities) {
      //   console.log('gonna load staff_visibility')
        await StaffVisibility.api().listForMember()
      // }
      // console.log('staffVisibility loaded.')
      // console.log(this.signinMember.author)
      // console.log(this.signinMember.author.memberStaffVisibilities)
      this.chargedUsers = this.signinMember.author.memberStaffVisibilities
      // console.log(this.chargedUsers)
      // console.log(this.relatedUsers)
      for(var user of this.relatedUsers) {
        var visibility = this.chargedUsers.find(cu => cu.userID === user.user_id)
        if (visibility && visibility.visible) {
          user.charged = true
        } else {
          user.charged = false
        }
      }
      console.log('staffVisibility process end')
    },

    isVisible (user) {
      switch (this.userDisplayLimitType) {
        case this.LIMIT_TYPE_CHARGED:
          // return this.isCharged(user)
          return user.charged
        case this.LIMIT_TYPE_LIMITED:
          return this.isLimited(user)
        default:
          return true
      }
    },

    isLimited (user) {
      if (this.searchText.length > 0) {
        var fixedSarchText = this.searchText.replace(/[ぁ-ん]/g, (s) => {
          return String.fromCharCode(s.charCodeAt(0) + 0x60)
        })
        var contentText = user.author.name + user.author.nameKana + user.author.address
        if (contentText.indexOf(fixedSarchText) >= 0) {
          return true
        } else {
          return false
        }
      } else if (this.userDisplayLimited) {
        return user.limited
      }
      return true
    },

    isCharged (user) {
      var visibility = this.chargedUsers.find(cu => cu.userID === user.user_id)
      if (visibility && visibility.visible) {
        return true
      }
      return false
    },

    toggleNameIndex () {
      this.nameIndexOn = !this.nameIndexOn
      this.portalConfigUpdate()
    },

    toggleUserLayoutGrid () {
      this.layoutGrid = !this.layoutGrid
      this.portalConfigUpdate()
    },

    toggleUserDisplayLimited () {
      if (this.limitedUsers.length <= 0) {
        this.userDisplayLimited = false
      } else {
        this.userDisplayLimited = !this.userDisplayLimited
      }
    },

    updateUserDisplayLimitType () {
      if (this.userDisplayLimitType === this.LIMIT_TYPE_LIMITED && this.limitedUsers.length <= 0) {
        this.userDisplayLimitType = this.LIMIT_TYPE_CHARGED
      }
    },

    portalConfigUpdate () {
      var formData = new FormData()
      var displayStyle = 1
      if (this.layoutGrid) {
        displayStyle = 0
      }
      var indexingElement = 0
      if (this.nameIndexOn) {
        indexingElement = 1
      }
      formData.append('cgc_portal_configuration[display_style]', displayStyle)
      formData.append('cgc_portal_configuration[indexing_element]', indexingElement)
      MemberPortalConfiguration.update({ formData: formData })
    },

    selectByQR () {
      this.$emit('showQrReaderEvent', 'タイムライン表示', this.showTimelineByQR, true)
    },

    showTimelineByQR (decodedObject) {
      var categoryID = ''
      if (decodedObject.categoryID && decodedObject.categoryID > 0) {
        categoryID = decodedObject.categoryID
      }
      StaticIdentifyCode.fetchUser({ categoryID: categoryID, IDCode: decodedObject.decodedString }).then(res => {
        if (res.success) {
          var targetUser = this.relatedUsers.find(user => user.nbid === res.nbid)
          if (targetUser) {
            this.showConfirmDialog(
              'タイムラインに移動',
              '[' + res.author.name + ']のタイムラインに移動します。\nよろしいですか?',
              DIALOG_TYPE.YES_NO,
              () => {
                this.showTimeline(targetUser)
              }
            )
          } else {
            this.showNotifyDialog(
              'QRコードが違います',
              '読み取ったQRはかかりつけ利用者のQRではありません。'
            )
          }
        } else {
          this.showNotifyDialog(
            'ユーザーの取得失敗',
            res.message
          )
        }
      }).catch(error => {
        console.log(error.response)
        this.showNotifyDialog(
          'ユーザーの取得失敗',
          'ユーザーの取得に失敗しました。登録されていないQRコードの可能性があります'
        )
      })
    },

    showTimeline (user) {
      this.$router.push('/maintimeline/' + user.user_id)
    },

    toggleDisplay () {
      this.showTask = !this.showTask
    },

    updateColumnDisplay (type) {
      this.columnDisplay = type
    },

    async prepareCreateMeeting (user) {
      console.log(user)
      var owner = TimelineOwner.find(user.user_id)
      if (owner === null) {
        await TimelineOwner.api().fetch({ timelineOwnerID: user.user_id })
        this.timelineOwner = TimelineOwner.find(user.user_id)
      } else {
        this.timelineOwner = owner
      }
      this.organizationStaffVisibilities = this.timelineOwner.organizationStaffVisibilities
      await this.loadTimelineOwnerAroundDatas() // -> HorusUserTaskControll.js
      this.createMeeting(user.author)
    },

    loadMultipostHistory () {
      TimelineRecord.api().fetchMultipostRecordIDList({ offset: 0, limit: 100 }).then(res => {
        console.log(res)
        this.multipostHistory = TimelineRecord.query().where('multipost', true).where('author_id', this.signinMember.author_id).orderBy('target_at', 'desc').get()
      })
    },

    loadPresentMultipostHistory () {
      TimelineRecord.api().fetchMultipostRecordIDList({ offset: this.multipostHistory.length, limit: 100 }).then(() => {
        this.multipostHistory = TimelineRecord.query().where('multipost', true).where('author_id', this.signinMember.author_id).orderBy('target_at', 'desc').get()
      })
    },

    showMultipostSelection () {
      this.multipostTargetSelectionDisplay = true
    },

    cancelMultipostTargetSelection () {
      this.multipostTargetSelectionDisplay = false
    },

    submitMultipostTargetSelection (selectedOwners) {
      this.multipostTargets = [...selectedOwners]
      this.multipostTargetSelectionDisplay = false
      // for next
      this.showMultipostForm()
    },

    submitMultipostTargetSelectAll () {
      this.multipostTargets = [...this.relatedUsers]
      this.multipostTargetSelectionDisplay = false
      this.showMultipostForm()
    },

    showMultipostForm () {
      this.multipostFormDisplay = true
    },

    setTargetDateForMultipost () {
      this.postTargetDateModalDisplay = true
    },

    clearTargetDateForMultipost () {
      this.multipostTargetDate.change = false
      this.multipostTargetDate.date = new Date()
      this.postTargetDateModalDisplay = false
    },

    targetDateSelectionModalClose () {
      this.postTargetDateModalDisplay = false
    },

    targetDateSelectionCancel () {
      this.postTargetDateModalDisplay = false
    },

    targetDateSelectionCommit (selectedDate) {
      this.multipostTargetDate.change = true
      this.multipostTargetDate.date = new Date(selectedDate.getTime())
      this.targetDateSelectionModalClose()
    },

    cancelMultipost (callback) {
      this.multipostFormDisplay = false
      this.multipostTargetDate.date = new Date()
      this.multipostTargets = []
      callback()
    },

    submitMultipost (formData, callback) {
      this.showSendingModal = true
      TimelineRecord.api().postNewRecord({ formData: formData }).then(res => {
        console.log(res)
        this.multipostFormDisplay = false
        this.multipostTargetDate.date = new Date()
        this.multipostTargets = []
        callback()
        this.showSendingModal = false
        this.showNotifyDialog(
          '一斉投稿完了',
          '指定された利用者のタイムラインに、一斉書き込みを完了しました'
        )
        this.loadMultipostHistory()
      }).catch(err => {
        console.error(err)
        this.showSendingModal = false
        this.showNotifyDialog(
          '一斉書き込み失敗',
          '指定された利用者への一斉書き込みに失敗しました'
        )
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-member-portal-view {
  height: calc(100% - 50px);
  background-color: #f5f5f5;
  .switching-tab-bar {
    display: flex;
    justify-content: space-between;
    height: 25px;
    padding: 5px 10px;
    background-color: #ddd;
    border-bottom: 1px solid #aaa;
    img {
      margin: 0 10px;
      height: 25px;
      // width: 25px;
    }
  }
  .horus-member-portal-frame {
    height: calc(100% - 36px);
    overflow: auto;
  }
  .horus-member-portal-inner-frame {
    display: flex;
  }
  .horus-member-portal-content {
    padding: 10px 20px 0;
    width: 100%;
    height: calc(100% - 10px);
    @include mediaQuery('phone') {
      padding: 10px;
    }
    &.two-column {
      width: calc(100% - 360px);
      @include mediaQuery('phone') {
        display: none;
      }
    }
    .now-loading {
      padding: 30px 0;
      text-align: center;
    }
    .member-portal-controll {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      input.search-user-input {
        font-size: 16px;
        height: 25px;
        @include mediaQuery('phone') {
          width: 100px;
        }
      }
      .controll-buttons {
        display: flex;
        img {
          margin-left: 20px;
          height: 40px;
          width: 40px;
          @include mediaQuery('phone') {
            margin-left: 10px;
          }
        }
        button {
          margin-left: 20px;
          background-color: #999;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
          @include mediaQuery('phone') {
            margin-left: 10px;
          }
        }
        .user-display-type-select {
          margin-left: 10px;
          padding: 10px;
          height: 40px;
          border-radius: 3px;
          font-weight: bold;
        }
      }
    }
    .user-list {
      height: calc(100% - 40px);
      overflow: auto;
      .list-inside-box {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        align-content: flex-start;
        margin-top: 10px;
      }
    }
    .user-indexed {
      margin-top: 10px;
      height: calc(100% - 60px);
      .jump-index {
        button {
          margin-right: 10px;
          padding: 5px 8px;
          border: 1px solid #999;
          border-radius: 3px;
          background-color: #999;
          color: #fff;
          font-weight: bold;
          font-size: 15px;
          @include mediaQuery('phone') {
            margin-bottom: 10px;
          }
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
      .user-list-indexed {
        height: calc(100% - 20px);
        // overflow: scroll;
        @include mediaQuery('phone') {
          height: calc(100% - 78px);
        }
        .index-title {
          margin: 10px 0;
          padding: 0 10px;
          border-bottom: 1px solid #aaa;
          font-weight: bold;
          font-size: 15px;
          color: #666;
          a {
            color: inherit;
            text-decoration: none;
          }
        }
        .indexed-users {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
        }
      }
    }
  }
  .user-task-column {
    display: none;
    &.show-task {
      display: block;
      width: 360px;
      @include mediaQuery('phone') {
        width: 100%;
      }
    }
  }
  .multipost-records-column {
    display: none;
    &.show-multipost {
      display: block;
      width: 340px;
      padding: 10px 20px;
      padding-left: 0;
      @include mediaQuery('phone') {
        width: 100%;
        padding-left: 20px;
      }
    }
    h2 {
      margin: 0;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      color: #666;
      button {
        margin-left: 20px;
        padding: 3px 5px;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    .multipost-record {
      padding: 5px;
      border: 1px solid #999;
      background-color: #fff;
      margin-bottom: 5px;
      .record-head-text {
        border-bottom: 1px solid #aaa;
        .record-target-date {
          font-weight: bold;
        }
        .target-authors {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .target-author {
            display: flex;
            align-items: center;
            margin: 5px 10px;
            img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
