<template>
  <div class="horus-organization-agent-view">
    <div class="agent-content">
      <h1 class="centering-title">設定代行</h1>
      <div class="user-list-block red_titled_column">
        <h4>利用者一覧</h4>
        <input type="text" class="serch-user" placeholder="絞り込み(氏名と住所の部分一致)" v-model="searchText" />
        <div class="deputized-users" v-if="!usersNowLoading">
          <div class="deputized-user"
            v-for="relation in deputizeRelations"
            :key="'deputizeRelation' + relation.id"
            v-show="hitSearchText(relation.author)">
            <img :src="relation.author.portrateSrc" />
            <div class="user-info">
              <div class="name-column">
                <span class="user-name">{{ relation.author.name }}&nbsp;({{ relation.author.age }})</span>
                <span class="no-signin-notify" v-if="!relation.self_login">本人ログインできない利用者</span>
              </div>
              <div class="address-column">
                <span class="address">{{ relation.author.address }}</span>
                <span class="tel">TEL : {{ relation.author.tel }}</span>
              </div>
              <div class="controll">
                <button class="show-log-btn" @click="showLog(relation)">設定代行ログ</button>
                <button class="show-log-btn" @click="moveToProfile(relation)">プロフィール編集</button>
                <button class="show-log-btn" @click="moveToServiceRelation(relation)">かかりつけ設定</button>
                <button class="show-log-btn" @click="moveToFamily(relation)">家族設定</button>
                <button class="show-log-btn" @click="moveToAgentConfig(relation)">設定代行管理</button>
              </div>
            </div>
          </div>
        </div><!-- deputized-users -->
        <div class="now-loading" v-if="usersNowLoading">ロード中...</div>
      </div>
    </div>
    <horus-footer />
    <horus-agent-log-modal
      :showAgentLog="showAgentLog"
      :signinMember="signinMember"
      :relation="targetRelation"
      v-on:closeModalEvent="closeAgentLog"
      />
  </div>
</template>

<script>
import {
  Author
} from '@/models'
import {
  OrganizationDeputizeRelation
} from '@/api'

import HorusAgentLogModal from '@/components/organizationAgent/HorusAgentLogModal.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusOrganizationAgentView',

  components: {
    HorusAgentLogModal,
    HorusFooter
  },

  props: {
    organization: Object,
    signinMember: Object
  },

  data () {
    return {
      usersNowLoading: true,
      deputizeRelations: [],
      searchText: '',
      targetRelation: null,
      showAgentLog: false
    }
  },

  watch: {
    organization: { handler: 'loadRelations', immediate: true }
  },

  methods: {
    async loadRelations () {
      if (this.organization) {
        this.usersNowLoading = true
        await OrganizationDeputizeRelation.fetchDeputizedUserIDList({ organizationID: this.organization.id }).then(async rel => {
          var unloadAuthors = []
          for(var dUser of rel.deputized_users) {
            if (!Author.find(dUser.author_id)) {
              if (unloadAuthors.indexOf(dUser.author_id) < 0) unloadAuthors.push(dUser.author_id)
            }
          }
          if (unloadAuthors.length > 0) {
            await Author.api().fetchByID({ idList: unloadAuthors })
          }
          for(var user of rel.deputized_users) {
            user.author = await Author.find(user.author_id)
          }
          this.deputizeRelations = rel.deputized_users
          this.deputizeRelations.sort((f, s) => {
            if (f.author.nameKana > s.author.nameKana) {
              return 1
            } else if (f.author.nameKana < s.author.nameKana) {
              return -1
            } else {
              return 0
            }
          })
        })
        this.usersNowLoading = false
      }
    },

    hitSearchText (author) {
      if (this.searchText.length > 0) {
        var fixedSarchText = this.searchText.replace(/[ぁ-ん]/g, (s) => {
          return String.fromCharCode(s.charCodeAt(0) + 0x60)
        })
        var contentText = author.name + author.nameKana + author.address
        if (contentText.indexOf(fixedSarchText) >= 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    showLog (relation) {
      this.targetRelation = relation
      this.showAgentLog = true
    },

    closeAgentLog () {
      this.showAgentLog = false
      this.targetRelation = null
    },

    moveToProfile (relation) {
      this.$router.push({ path: '/profile/' + relation.user_id, query: { deputize: true } })
    },

    moveToServiceRelation (relation) {
      this.$router.push({ path: '/related_organization/' + relation.user_id, query: { deputize: true } })
    },

    moveToFamily (relation) {
      this.$router.push({ path: '/family/' + relation.user_id, query: { deputize: true } })
    },

    moveToAgentConfig (relation) {
      this.$router.push({ path: '/agent_config/' + relation.user_id, query: { deputize: true } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/centeringTitle.scss';
@import '@/assets/css/redTitledColumn.scss';
@import '@/assets/css/mediaQuery.scss';

.horus-organization-agent-view {
  height: calc(100% - 50px);
  background-color: #f5f5f5;
  overflow: scroll;
  .agent-content {
    padding: 10px 50px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    .serch-user {
      margin: 10px 0;
      width: 300px;
      font-size: 16px;
      @include mediaQuery('phone') {
        width: calc(100% - 10px);
      }
    }
    .now-loading {
      padding: 10px;
      background-color: #ddd;
      text-align: center;
      font-weight: bold;
    }
    .deputized-users {
      .deputized-user {
        display: flex;
        margin: 5px 0;
        padding: 10px;
        border: 1px solid #aaa;
        background-color: #fff;
        img {
          width: 80px;
          height: 80px;
        }
        .user-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 10px;
          .name-column {
            .user-name {
              font-size: 16px;
              font-weight: bold;
            }
          }
          .no-signin-notify {
            margin-left: 10px;
            color: #c43d53;
          }
        }
        .controll {
          display: flex;
          flex-wrap: wrap;
          button {
            margin: 5px;
            border: 1px solid #666;
            border-radius: 3px;
            background-color: #666;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
