<template>
  <div class="horus-member-portal-config-view">
    <h1 class="centering-title">トップページ設定</h1>
    <div class="caption">
      かかりつけている施設利用者の方のうち、トップページの【一部表示】に表示する方にチェックしてください。<br/>
      トップページで【一部表示】と【全表示】の切り替えが行えます。
    </div>
    <div class="user-list">
      <div
        class="user-column"
        v-for="user in relatedUsers"
        :key="'memberPortalConfUser' + user.user_id"
        @click="toggleShowPortal(user)">
        <horus-custom-check-box
          :active="showInPortal(user)"
          :enabled="true"
          :type="'color'"
          />
        <img class="portrate" :src="user.author.portrateSrc" />
        <span class="name">{{ user.author.name }}</span>
      </div>
    </div>
    <horus-footer />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Author
} from '@/models'

import {
  RelatedUser,
  MemberPortalShowingUser
} from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusMemberPortalConfigView',

  components: {
    HorusCustomCheckBox,
    HorusFooter,
    HorusConfirmDialog
  },

  props: {
    signinMember: Object
  },

  data () {
    return {
      limitedUsers: [],
      relatedUsers: []
    }
  },

  watch: {
    signinMember: { handler: 'initObjects', immediate: true }
  },

  methods: {
    initObjects () {
      this.loadRelatedUsers()
      this.loadPortalDisplayUser()
    },

    async loadRelatedUsers () {
      await RelatedUser.fetch().then(async rel => {
      // await this.$store.dispatch('fetchRelatedUser').then(async rel => {
        var unloadAuthors = []
        for(var ustat of rel.related_user_status) {
          if (!Author.find(ustat.author_id)) {
            if (unloadAuthors.indexOf(ustat.author_id) < 0) unloadAuthors.push(ustat.author_id)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().findByID({ timelineOwnerID: this.signinMember.user_id, idList: unloadAuthors })
        }
        for(var userStatus of rel.related_user_status) {
          userStatus.author = await Author.find(userStatus.author_id)
        }
        this.relatedUsers = rel.related_user_status
        this.relatedUsers.sort((f, s) => {
          if (f.author.nameKana > s.author.nameKana) {
            return 1
          } else if (f.author.nameKana < s.author.nameKana) {
            return -1
          } else {
            return 0
          }
        })
      })
    },

    async loadPortalDisplayUser () {
      await MemberPortalShowingUser.fetchList().then(rel => {
        this.limitedUsers = rel.portal_showing_users
      })
    },

    showInPortal (user) {
      if (this.limitedUsers) {
        if (this.limitedUsers.some(lu => lu.user_id === user.user_id)) {
          return true
        } else {
          return false
        }
      }
    },

    toggleShowPortal (user) {
      if (this.showInPortal(user)) {
        MemberPortalShowingUser.destroy({ targetUserID: user.user_id }).then(() => {
          this.loadPortalDisplayUser()
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog(
            'トップページ設定の更新失敗',
            'トップページに表示するユーザーの設定に失敗しました'
          )
        })
      } else {
        MemberPortalShowingUser.create({ targetUserID: user.user_id }).then(() => {
          this.loadPortalDisplayUser()
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog(
            'トップページ設定の更新失敗',
            'トップページに表示するユーザーの設定に失敗しました'
          )
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-member-portal-config-view {
  height: calc(100% - 60px);
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  @include mediaQuery('phone') {
    padding: 10px;
  }
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .caption {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #aaa;
    font-size: 12px;
    img {
      height: 14px;
    }
  }
  .user-list {
    .user-column {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #aaa;
      background-color: #fff;
      .portrate {
        margin: 0 10px;
        width: 40px;
      }
      .name {
        font-weight: bold;
      }
    }
  }
}
</style>
