<template>
  <div class="horus-record-body" :class="{ surrogated: timelineRecord.surrogated }" v-if="timelineRecord">
    <div class="surrogated-header" v-if="timelineRecord.surrogated">
      <img :src="timelineRecord.timelineOwner.portrateSrc" />本人曰く
    </div>
    <div class="text-comment" v-html="textCommentHtml"></div>
    <!-- medicalExamination, drugEntry -->
    <div class="medical-exiamination" v-if="timelineRecord.medicalExamination">
      <horus-timeline-medical-examination
        :medicalExamination="timelineRecord.medicalExamination"
        :key="timelineRecord.medicalExamination.id" />
    </div>
    <div class="drug-entry" v-if="timelineRecord.drugEntry">
      <horus-timeline-drug-entry
        :drugEntry="timelineRecord.drugEntry"
        :key="timelineRecord.drugEntry.id"
        v-on:durgEntryPhotoClickEvent="showDrugEntryPhoto" />
    </div>
    <div class="photo-reports" v-if="timelineRecord.photoReports.length > 0">
      <horus-inline-photo
        v-for="photoReport in timelineRecord.photoReports"
        :key="photoReport.id"
        :photoObject="photoReport"
        :photoMaxSize="100"
        v-on:timeliePhotoClickEvent="showPhoto(photoReport)" />
    </div>
    <div class="file-reports" v-if="timelineRecord.fileReports.length > 0">
      <horus-inline-file
        v-for="fileReport in timelineRecord.fileReports"
        :key="fileReport.id"
        :fileObject="fileReport"
        :sizeType="'normal'"
        v-on:timelineFileClickEvent="openFile" />
    </div>
    <div class="video-content" v-if="timelineRecord.videoContent">
      <div class="video-not-ready" v-if="timelineRecord.videoContent.content_state === 0 || timelineRecord.videoContent.content_state === 20">
        <img src="/static/images/video_processing.png" class="video-processing" />
        動画準備中
      </div>
      <div class="video-ready" v-if="timelineRecord.videoContent.content_state === 10 || timelineRecord.videoContent.content_state === 30">
        <div class="video-thumbnail-block" @click="videoContentClicked">
          <img :src="timelineRecord.videoContent.thumbnailSrc" class="video-thumbnail" />
          <img src="/static/images/video_play.png" class="video-play-icon" />
        </div>
        <div class="video-content-name">{{ videoContentName }}</div>
      </div>
      <div class="video-fail" v-if="timelineRecord.videoContent.content_state == 50">
        <img src="/static/images/video_error.png" class="video-error" />
        動画エラー
      </div>
    </div>
    <div class="audio-content" v-if="timelineRecord.audioContent">
      <div class="audio-not-ready" v-if="timelineRecord.audioContent.content_state === 0 || timelineRecord.audioContent.content_state === 20">
        <img src="/static/images/audio_processing.png" class="audio-processing" />
        音声準備中
      </div>
      <div class="audio-ready" v-if="false && timelineRecord.audioContent.content_state === 10 || timelineRecord.audioContent.content_state === 30" @click="audioContentClicked">
        <img src="/static/images/audio_play_icon.png" class="audio-play" />
        <div class="audio-content-name">{{ autdioContentName }}</div>
      </div>
      <div class="audio-fail" v-if="timelineRecord.audioContent.content_state == 50">
        <img src="/static/images/audio_error.png" class="audio-error" />
        音声エラー
      </div>
    </div>
  </div>
</template>

<script>
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'
import HorusInlineFile from '@/components/common/HorusInlineFile.vue'
import HorusTimelineMedicalExamination from '@/components/timelineRecord/HorusTimelineMedicalExamination.vue'
import HorusTimelineDrugEntry from '@/components/timelineRecord/HorusTimelineDrugEntry.vue'

export default {
  name: 'HorusRecordBody',

  components: {
    HorusInlinePhoto,
    HorusInlineFile,
    HorusTimelineMedicalExamination,
    HorusTimelineDrugEntry
  },

  props: {
    timelineRecord: Object
  },

  computed: {
    textCommentHtml () {
      if (this.timelineRecord.textComment) {
        return this.timelineRecord.textComment.replace(/\n/gi, '<br />')
      }
      return ''
    },

    videoContentName () {
      if (this.timelineRecord) {
        return this.timelineRecord.targetAtStr + ' ' + this.timelineRecord.author.name + 'の動画'
      }
      return ''
    },

    autdioContentName () {
      if (this.timelineRecord) {
        return this.timelineRecord.targetAtStr + ' ' + this.timelineRecord.author.name + 'の音声'
      }
      return ''
    }
  },

  methods: {
    showPhoto (photoReport) {
      this.$emit('timelinePhotoClickEvent', this.timelineRecord, this.timelineRecord.photoReports.indexOf(photoReport))
    },

    showDrugEntryPhoto (entryPhoto) {
      this.$emit('durgEntryPhotoClickEvent', this.timelineRecord, this.timelineRecord.drugEntry.drugEntryPhotos.indexOf(entryPhoto))
    },

    openFile (file) {
      this.$emit('timelineFileClickEvent', file)
    },

    videoContentClicked () {
      this.$emit('videoContentClickEvent', this.timelineRecord.videoContent)
    },

    audioContentClicked () {
      this.$emit('audioContentClickEvent', this.timelineRecord.audioContent)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-record-body {
  padding: 0 5px;
  &.surrogated {
    padding-left: 10px;
    border-left: 4px solid #96514d;
    .surrogated-header {
      height: 35px;
      margin-left: -10px;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid #96514d;
      font-weight: bold;
      color: #96514d;
      vertical-align: middle;
      img {
        margin-right: 10px;
        height: 30px;
      }
    }
  }
  .text-comment {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
  }
  .medical-exiamination {
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid #ddd;
  }
  .drug-entry {
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid #ddd;
  }
  .photo-reports {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  .video-content {
    // width: 100px;
    display: flex;
    .video-not-ready {
      display: flex;
      flex-direction: column;
      align-items: center;
      .video-processing {
        margin-bottom: 3px;
      }
    }
    .video-fail {
      display: flex;
      flex-direction: column;
      align-items: center;
      .video-error {
        margin-bottom: 3px;
      }
    }
    .video-ready {
      display: flex;
      flex-direction: column;
      align-items: center;
      .video-thumbnail-block {
        position: relative;
        margin-bottom: 5px;
        .video-thumbnail {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
        .video-play-icon {
          position: absolute;
          top: 20px;
          left: 10px;
        }
      }
      .video-content-name {
        font-size: 0.9em;
      }
    }
  }
  .audio-content {
    // width: 80px;
    display: flex;
    .audio-not-ready {
      display: flex;
      flex-direction: column;
      align-items: center;
      .audio-processing {
        margin-bottom: 5px;
      }
    }
    .audio-fail {
      display: flex;
      flex-direction: column;
      align-items: center;
      .audio-error {
        margin-bottom: 5px;
      }
    }
    .audio-ready {
      display: flex;
      flex-direction: column;
      align-items: center;
      .audio-play {
        margin-bottom: 5px;
      }
      .audio-content-name {
        font-size: 0.9em;
      }
    }
  }
}
</style>
