import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'
import TimelineOwner from './TimelineOwner'

export default class SigninUser extends Model {
  static entity = 'signinUsers'

  static fields () {
    return {
      user_id: this.attr(null),
      author_id: this.attr(null),
      signin_at: this.string('')
    }
  }

  get author () {
    // TODO: load data when undefined
    return Author.find(this.author_id)
  }

  get timelineOwner () {
    // TODO: load data when undefined
    return TimelineOwner.find(this.user_id)
  }

  get signinAt () {
    if (this.signin_at.length > 0) {
      return new Date(this.signin_at)
    }
    return null
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData (payload) {
    // console.log('SigninUser updateData')
    // console.log(payload)
    await SigninUser.deleteAll()
    let entities = await SigninUser.insert({ 
      data: { 
        user_id: payload.signin_user_id, 
        author_id: payload.author_id, 
        signin_at: new Date() 
      } 
    })
    // console.log(entities)
    let signinUser = entities.signinUsers[0]
    // console.log(signinUser)
    if (!Author.find(signinUser.author_id)) {
      await Author.api().fetchByID({ idList: [signinUser.author_id] })
    }
    SigninUser.commit((state) => {
      state.fetchedAt = new Date()
    })
    return signinUser
  }

  static apiConfig = {
    actions: {
      async fetch ({ loginID, password }) {
        var formData = new FormData()
        formData.append('login_id', loginID)
        formData.append('password', password)
        var axiosHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...axiosHeader,
          ...{
            method: 'put',
            url: 'authenticate/signin',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return SigninUser.updateData(res.response.data)
        })
        .catch(error => {
          throw error
        })
      },
    
      async fetchCurrentSingin () {
        return this.request({
          method: 'get',
          url: 'authenticate/current_signin_user',
          withCredentials: true,
          save: false
        })
        .then(res => {
          // console.log('in current_user model fetch')
          // console.log(res.response.data)
          return SigninUser.updateData(res.response.data)
        })
        .catch(error => { throw error })
      },
    
      async updatePassword ({ targetUserID, newPassword, passwordConfirmation, expireAppTokens }) {
        var formData = new FormData()
        formData.append('authenticate_info[password]', newPassword)
        formData.append('authenticate_info[password_confirmation]', passwordConfirmation)
        formData.append('target_user_id', targetUserID)
        if (expireAppTokens) {
          formData.append('expire_app_tokens', 'YES')
        }
        var axiosHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...axiosHeader,
          ...{
            method: 'put',
            url: 'user/update_password',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response
        })
        .catch(error => {
          throw error
        })
      }
    
    }
  }

}
