import axios from './AxiosInstance'

export default {
  fetch: ({reporterOrganizationID}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'reporter_organization_member/list',
        withCredentials: true,
        params: { reporter_organization_id: reporterOrganizationID }
      }).then(res => {
        if (res.data.success === true) {
          resolve({ reporter_organization_members: res.data.reporter_organization_members })
        }
      }).catch(error => { reject(error) })
    })
  },

  update: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'reporter_organization_member',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({reporterOrganizationID, memberID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'reporter_organization_member',
            withCredentials: true,
            params: { reporter_organization_id: reporterOrganizationID, reporter_organization_member_id: memberID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}
