<template>
  <div class="horus-user-mail-hard-change-modal">
    <horus-modal-frame
      :showModalFlag="showUserMailEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />メールアドレスの強制変更</h1>
      </div>
      <div class="modal-body">
        <div class="target-user" v-if="targetUser">
          <div class="title">対象ユーザー</div>
          <div><span>NBID</span>{{ targetUser.nbid }}</div>
          <div><span>氏名</span>{{ targetUser.familyName + ' ' + targetUser.givenName }}({{ targetUser.familyNameKana + ' ' + targetUser.givenNameKana }})</div>
          <div><span>政変月日</span>{{ targetUser.birth_date }}</div>
          <div><span>変更前メールアドレス</span>{{ targetUser.mail }}</div>
        </div>
        <div class="caption">
          対象ユーザーのメールアドレスを強制変更します。<br/>
          メールの疎通確認は行いません。<br/>
          新規のメールアドレスに対して、強制変更を行った旨のメールが送信されます。<br/>
          操作後、対象ユーザーに変更されたメールアドレスにメールが届いているかどうかの確認をすることを強くおすすめします。
        </div>
        <horus-attribute-in-form
          :labelText="'新しいメールアドレス'"
          :necessary="true"
          :validationState="validateMail"
          :validationMessage="validateMailMessage"
          :attributeNotify="''">
          <input type="text" v-model="mail" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: validateMail !== 'valid'}" @click="submit">変 更</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
export default {
  name: 'HorusUserMailHardChangeModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm
  },

  props: {
    showUserMailEditModal: Boolean,
    targetUser: Object
  },

  data () {
    return {
      mail: ''
    }
  },

  computed: {
    validateMail () {
      if (this.mail.length > 0) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validateMailMessage () {
      if (this.mail.length > 0) {
        return ''
      } else {
        return 'メールアドレスは入力必須です。'
      }
    }
  },

  methods: {
    cancelSubmit () {
      console.log('cancel in modal')
      this.$emit('cancelSubmitEvent', () => { this.mail = '' })
    },

    submit () {
      var formData = new FormData()
      formData.append('mail_address', this.mail)
      formData.append('target_user_id', this.targetUser.id)
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-user-mail-hard-change-modal {
  .modal-body {
    padding: 10px 20px;
    .target-user {
      .title {
        font-weight: bold;
      }
      span {
        display: inline-block;
        margin-right: 10px;
        width: 150px;
        text-align: right;
      }
    }
    .caption {
      margin: 10px;
      padding: 10px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 12px;
    }
    input {
      font-size: 16px;
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
