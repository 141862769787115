<template>
  <div class="horus-system-manager-edit-mail-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />システム管理者メールアドレス変更</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'新規メールアドレス'"
          :necessary="true"
          :validationState="validateMail.state"
          :validationMessage="validateMail.message"
          :attributeNotify="''">
          <input type="text" v-model="mail" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">変 更</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { SystemManager } from '@/models'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemManagerEditMailModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
  },

  props: {
    showModal: Boolean,
    systemManager: Object
  },

  data () {
    return {
      mail: '',
      validateMail: { state: 'disabled', valid: false, message: '' }
    }
  },

  watch: {
    mail: { handler: 'mailValidation' }
  },

  computed: {
    allValid () {
      if (this.validateMail.valid) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initDatas () {
      this.mail = ''
    },

    mailValidation () {
      if (this.mail.length > 6) {
        SystemManager.api().mailValidation({ mail: this.mail }).then(res => {
          if (res.success) {
            if (res.valid) {
              this.validateMail = { state: 'valid', valid: true, message: '' }
            } else {
              this.validateMail = { state: 'invalid', valid: false, message: '入力されたメールアドレスは登録済みです。' }
            }
          } else {
            this.validateMail = { state: 'disabled', valid: true, message: '入力チェックが機能していません' }
          }
        }).catch(err => {
          console.log(err)
          this.validateMail = { state: 'disabled', valid: true, message: '入力チェックが機能していません' }
        })
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent', () => { this.initDatas() })
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('new_mail_address', this.mail)
        formData.append('system_manager_id', this.systemManager.id)
        this.$emit('submitEvent', formData, () => { this.initDatas() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-system-manager-edit-mail-modal {
  .modal-body {
    padding: 10px 20px;
    .toggle-password-visible {
      display: flex;
      margin-left: 36px;
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
