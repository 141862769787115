<template>
  <div class="horus-modal-frame" v-if="showModalFlag">
    <div class="backdrop" @click="backdropClicked"></div>
    <div class="modal">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusModalFrame',

  props: {
    showModalFlag: Boolean
  },

  methods: {
    backdropClicked () {
      this.$emit('backdropClickEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-modal-frame {
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  @supports(-webkit-touch-callout: none) {
    /* iPhoneの表示のみ指定を上書き */
    .backdrop {
      height: -webkit-fill-available;
    }
  }
  .modal {
    position: absolute;
    width: 800px;
    max-height: calc(100% - 60px);
    margin: 0;
    padding: 0;
    top: 30px;
    left: calc((100vw - 800px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100% - 60px);
      top: 5px;
      left: 5px;
    }
    ::v-deep(.modal-header) {
      padding: 10px;
      border-bottom: 1px solid #aaa;
      h1 {
        margin: 0;
        font-size: 14px;
        color: #666;
        text-align: center;
        img {
          margin-right: 10px;
          height: 15px;
        }
      }
    }
  }
  @supports(-webkit-touch-callout: none) {
    /* iPhoneの表示のみ指定を上書き */
    .modal{
      max-height: calc(-webkit-fill-available - 60px);
    }
  }
}
</style>
