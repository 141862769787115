// 高さがautoのブロックに対して、縦に伸び縮みするtransitionを指定したい場合に利用する。
// HTML: transition対象のブロックを<transition>で囲み、対象のtransitionタグのオプションに以下の設定を施しておく
//   @enter="accordionEnter"
//   @after-enter="accordionAfterEnter"
//   @leave="accordionLeave"
//   @after-leave="accordionAfterLeave"
//   実際にtransitionを適用するブロックは、v-showで表示/非表示をコントロールするようにしておく
// CSS:
//   transitionタグのname属性に指定した名称に従い、transitionのアニメーション設定をしておく
//   name="hoge"の場合:
//     .hoge--enter-active, .hoge-leave-active {
//       transition: height 300ms ease;
//     }
//   transitionに内包されるブロック(height: auto;に設定しているブロック)のoverflowをhiddenに設定しておくこと

function nextFrame (fn) {
  window.requestAnimationFrame(() => window.requestAnimationFrame(fn))
}

export default {
  methods: {
    // accordion transition helper methods
    accordionEnter (elm) {
      // elm.style.overflow = 'hidden'
      elm.style.height = '0'
      nextFrame(() => {
        elm.style.height = `${elm.scrollHeight}px`
      })
    },

    accordionLeave (elm) {
      // elm.style.overflow = 'hidden'
      elm.style.height = `${elm.scrollHeight}px`
      nextFrame(() => {
        elm.style.height = '0'
      })
    },

    accordionAfterEnter (elm) {
      elm.style.height = ''
      elm.style.overflow = ''
    },

    accordionAfterLeave (elm) {
      elm.style.height = ''
      elm.style.overflow = ''
    }
  }
}
