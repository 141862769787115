<template>
  <div class="record-head-info" v-if="author">
    <img :src="author.portrateSrc" class="author-portrate" />
    <div class="record-head-text">
      <div class="record-target-date">{{ recordTargetDateJp }}</div>
      <div class="record-haed-author" @click="showAuthorInfo($event)" v-click-outside="closeAuthorInfo">
        <span class="author-name">{{ author.name }}</span>
        <span class="author-name-additional">{{ authorAdditionalName }}</span>
        <horus-author-modal :author="author" :key="author.id" :currentOpenAuthor="currentOpenAuthor" />
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
// import vClickOutside from 'click-outside-vue3'
import moment from 'moment'

import HorusAuthorModal from '@/components/common/HorusAuthorModal.vue'

export default {
  name: 'HorusRecordHeadInfo',

  components: {
    HorusAuthorModal
  },

  props: {
    timelineOwner: Object,
    author: Object,
    targetAt: Date,
    // displayStyle: String
  },

  data () {
    return {
      currentOpenAuthor: ''
    }
  },

  computed: {
    recordTargetDateJp () {
      if (this.targetAt) {
        return moment(this.targetAt).format('MM月DD日 HH:mm')
      } else {
        return ''
      }
    },

    authorAdditionalName () {
      if (this.author.nameAdditional) {
        return '(' + this.author.nameAdditional + ')'
      } else if (this.author.id === this.timelineOwner.author_id) {
        return '(本人)'
      }
      return ''
    }
  },

  methods: {
    showAuthorInfo () {
      this.currentOpenAuthor = this.author.id
      // const targetElm = event.target.parentNode.querySelector('.author-modal')
      // this.$emit('modalOpenEvent', targetElm, () => { this.currentOpenAuthor = '' })
    },

    closeAuthorInfo () {
      this.currentOpenAuthor = null
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
    // ClickOutside
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
.record-head-info {
  display: flex;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  &.small {
    img {
      width: 40px;
      height: 40px;
    }
  }
  &.responsible-small {
    @include mediaQuery('phone') {
      img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
  }
}
.record-target-date {
  font-size: 1.1em;
  font-weight: bold;
  color: #666;
}
.record-haed-author {
  font-size: 0.8em;
  cursor: pointer;
  position: relative;
}
</style>
