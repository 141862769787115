<template>
  <div class="horus-member-profile-third-party-linkage-modal">
    <horus-modal-frame
      :showModalFlag="showThirdPartyLinkageModal">
      <div class="modal-header">
        <h1>第三者サービス連携情報詳細</h1>
      </div>
      <div class="modal-body">
        <div class="form-list-line" v-if="linkage">
          <div class="category-title">サービス情報</div>
          <div class="form-line name">
            <label class="linkage-label">名称</label>
            {{ linkage.name }}
          </div>
          <div class="form-line description">
            <label class="linkage-label">詳細</label>
            <span v-html="brTextHtml(linkage.description)"></span>
          </div>
          <div class="form-line code">
            <label class="linkage-label">第三者サービスコード</label>
            {{ linkage.code }}
          </div>
          <div class="category-title">サービス提供者情報</div>
          <div class="form-line provider_name">
            <label class="linkage-label">提供者名</label>
            {{ linkage.provider_name }}
          </div>
          <div class="form-line provider_zip_code">
            <label class="linkage-label">郵便番号</label>
            {{ linkage.provider_zip_code }}
          </div>
          <div class="form-line provider_address">
            <label class="linkage-label">住所</label>
            <span v-html="brTextHtml(linkage.provider_address)"></span>
          </div>
          <div class="form-line provider_mail">
            <label class="linkage-label">メールアドレス</label>
            {{ linkage.provider_mail }}
          </div>
          <div class="form-line provider_tel">
            <label class="linkage-label">電話番号</label>
            {{ linkage.provider_tel }}
          </div>
          <div class="form-line provider_url">
            <label class="linkage-label">ホームページ</label>
            <a :href="linkage.provider_url" target="_blank">{{ linkage.provider_url }}</a>
          </div>
          <div class="form-line provider_contact">
            <label class="linkage-label">担当者名</label>
            {{ linkage.provider_contact }}
          </div>
          <div class="form-line created_at"><label class="linkage-label">作成日</label>{{ createdAtStr }}</div>
          <div class="form-line updated_at"><label class="linkage-label">更新日</label>{{ updatedAtStr }}</div>
          <div class="controll-column">
            <button class="close-btn" @click="closeModal">閉じる</button>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusMemberProfileThirdPartyLinkageModal',

  components: {
    HorusModalFrame
  },

  props: {
    linkage: Object,
    showThirdPartyLinkageModal: Boolean
  },

  computed: {
    createdAtStr () {
      if (this.linkage.created_at) {
        return moment(this.linkage.created_at).format('YYYY/MM/DD HH:mm:SS')
      }
      return ''
    },

    updatedAtStr () {
      if (this.linkage.updated_at) {
        return moment(this.linkage.updated_at).format('YYYY/MM/DD HH:mm:SS')
      }
      return ''
    }
  },

  methods: {
    brTextHtml (text) {
      if (text) {
        return text.replace(/\n/gi, '<br />')
      }
      return ''
    },

    closeModal () {
      this.$emit('closeEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-member-profile-third-party-linkage-modal {
  .modal-body {
    padding: 0 20px 10px 20px;
  }
  .form-list-line {
    .category-title {
      margin-top: 20px;
      font-weight: bold;
    }
    .form-line {
      display: flex;
      margin: 5px 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
      .linkage-label {
        width: 150px;
        padding: 0 10px;
        text-align: right;
        font-weight: bold;
      }
    }
  }
  .controll-column {
    margin: 10px;
    text-align: center;
    button {
      margin: 0 10px;
      padding: 3px 10px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      &.close-btn {
        color: #666;
        background-color: #fff;
      }
    }
  }
}
</style>
