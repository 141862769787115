<template>
  <div class="horus-medical-examination-edit-modal" v-if="showMedicalExaminationEditModal">
    <div class="backdrop"></div>
    <div class="modal">
      <div class="modal-header">
        <img src="/static/images/vital_green.png" />バイタル
      </div>
      <div class="control">
        <button class="clear" type="button" @click="clear">クリア</button>
        <button class="commit" type="button" :class="{disable: !validToCommit}" @click="commit">OK</button>
      </div>
      <div class="modal-body">
        <div class="section-title"><img src="/static/images/hart_green.png" />測定</div>
        <div class="section-block">
          <label>血圧</label>
          <div class="inputs">
            <input type="number" size="4" v-model="medEx.blood_pressure_max_value" class="blood_pressure"/>/
            <input type="number" size="4" v-model="medEx.blood_pressure_min_value" class="blood_pressure"/>mmHg
            <div class="notify-messages" v-show="!bloodPressureValid">{{ bloodPressureNotify }}</div>
          </div>
        </div>
        <div class="section-block">
          <label>体温</label>
          <div class="inputs">
            <input type="number" size="4" v-model="medEx.body_temperature_value" class="body_temp"/>℃
            <div class="notify-messages" v-show="!bodyTempValid">{{ bodyTempNotify }}</div>
          </div>
        </div>
        <div class="section-block">
          <label>酸素飽和度</label>
          <div class="inputs">
            <input type="number" v-model="medEx.sp_o2_value" class="sp02"/>％
            <div class="notify-messages" v-show="!spO2Valid">{{ spO2Notify }}</div>
          </div>
        </div>
        <div class="section-block">
          <label>脈拍</label>
          <div class="inputs">
            <input type="number" v-model="medEx.pulse_rate_value" class="pulse_rate"/>回／分
            <div class="notify-messages" v-show="!pulseRateValid">{{ pulseRateNotify }}</div>
          </div>
        </div>
        <div class="section-block">
          <label>不整脈</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: 'あり'}, {value: 2, label: 'なし'}]"
            :selectedValue="medEx.irregular_pulse_value"
            v-on:valueSelectedEvent="irregularPulseValueChange"
            />
        </div>
        <div class="section-title"><img src="/static/images/medex_head_icon.png" />頭頸部</div>
        <div class="section-block">
          <label>貧血</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: 'あり'}, {value: 2, label: 'なし'}]"
            :selectedValue="medEx.anemia_state_value"
            v-on:valueSelectedEvent="anemiaStateValueChange"
            />
        </div>
        <div class="section-block">
          <label>黄疸</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: 'あり'}, {value: 2, label: 'なし'}]"
            :selectedValue="medEx.jaundice_state_value"
            v-on:valueSelectedEvent="jaundiceStateValueChange"
            />
        </div>
        <div class="section-block">
          <label>口腔状況</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '異常'}, {value: 2, label: '正常'}]"
            :selectedValue="medEx.oral_state_value"
            v-on:valueSelectedEvent="oralStateValueChange"
            />
        </div>
        <div class="section-title"><img src="/static/images/medex_chest_icon.png" />胸部</div>
        <div class="section-block">
          <label>呼吸雑音</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '異常'}, {value: 2, label: '正常'}]"
            :selectedValue="medEx.respiratory_noize_value"
            v-on:valueSelectedEvent="respiratoryNoizeValueChange"
            />
        </div>
        <div class="section-block">
          <label>呼吸数</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '異常'}, {value: 2, label: '正常'}]"
            :selectedValue="medEx.respiratory_rate_state_value"
            v-on:valueSelectedEvent="respiratoryRateStateValueChange"
            />
        </div>
        <div class="section-block">
          <label>心音</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '異常'}, {value: 2, label: '正常'}]"
            :selectedValue="medEx.hart_sound_value"
            v-on:valueSelectedEvent="hartSoundValueChange"
            />
        </div>
        <div class="section-title"><img src="/static/images/medex_abdorsal_icon.png" />腹部</div>
        <div class="section-block">
          <label>腹部触診</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '異常'}, {value: 2, label: '正常'}]"
            :selectedValue="medEx.abdominal_palpation_value"
            v-on:valueSelectedEvent="abdominalPalpationValueChange"
            />
        </div>
        <div class="section-block">
          <label>腹部聴診</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '異常'}, {value: 2, label: '正常'}]"
            :selectedValue="medEx.abdominal_auscultation_value"
            v-on:valueSelectedEvent="abdominalAuscultationValueChange"
            />
        </div>
        <div class="section-title"><img src="/static/images/medex_whole_body_icon.png" />皮膚</div>
        <div class="section-block">
          <label>浮腫</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: 'あり'}, {value: 2, label: 'なし'}]"
            :selectedValue="medEx.edema_value"
            v-on:valueSelectedEvent="edemaValueChange"
            />
        </div>
        <div class="section-block">
          <label>褥瘡</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: 'あり'}, {value: 2, label: 'なし'}]"
            :selectedValue="medEx.bedsore_value"
            v-on:valueSelectedEvent="bedsoreValueChange"
            />
        </div>
        <div class="section-title"><img src="/static/images/medex_life_icon.png" />生活</div>
        <div class="section-block">
          <label>食事状況</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '問題あり'}, {value: 2, label: '問題なし'}]"
            :selectedValue="medEx.eating_state_value"
            v-on:valueSelectedEvent="eatingStateValueChange"
            />
        </div>
        <div class="section-block">
          <label>服薬状況</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '問題あり'}, {value: 2, label: '問題なし'}]"
            :selectedValue="medEx.take_medicine_state_value"
            v-on:valueSelectedEvent="takeMedicineStateValueChange"
            />
        </div>
        <div class="section-block">
          <label>排尿状況</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '問題あり'}, {value: 2, label: '問題なし'}]"
            :selectedValue="medEx.urination_state_value"
            v-on:valueSelectedEvent="urinationStateValueChange"
            />
        </div>
        <div class="section-block">
          <label>排便状況</label>
          <horus-radio-button-group
            :valueAndLabels="[{value: 0, label: '記録なし'}, {value: 1, label: '問題あり'}, {value: 2, label: '問題なし'}]"
            :selectedValue="medEx.faces_state_value"
            v-on:valueSelectedEvent="facesStateValueChange"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorusRadioButtonGroup from '@/components/common/HorusRadioButtonGroup.vue'

export default {
  name: 'HorusMedicalExaminationEditModal',

  components: {
    HorusRadioButtonGroup
  },

  props: {
    medicalExamination: Object,
    showMedicalExaminationEditModal: Boolean
  },

  data () {
    return {
      medEx: {
        blood_pressure_max_value: null,
        blood_pressure_min_value: null,
        body_temperature_value: null,
        sp_o2_value: null,
        pulse_rate_value: null,
        irregular_pulse_value: -1,
        anemia_state_value: -1,
        jaundice_state_value: -1,
        oral_state_value: -1,
        respiratory_noize_value: -1,
        respiratory_rate_state_value: -1,
        hart_sound_value: -1,
        abdominal_palpation_value: -1,
        abdominal_auscultation_value: -1,
        edema_value: -1,
        bedsore_value: -1,
        eating_state_value: -1,
        take_medicine_state_value: -1,
        urination_state_value: -1,
        faces_state_value: -1
      }
    }
  },

  watch: {
    medicalExamination: { handler: 'cloneObject', immediate: true },
    showMedicalExaminationEditModal: { handler: 'cloneObject', immediate: true }
  },

  computed: {
    validateBloodPressure () {
      var valid = true
      var message = ''
      if (this.medEx.blood_pressure_max_value) {
        if (isNaN(this.medEx.blood_pressure_max_value)) {
          message += '収縮期(最大)血圧は数値で入力してください。'
          valid = false
        } else if (Number(this.medEx.blood_pressure_max_value) > 300 || Number(this.medEx.blood_pressure_max_value) < 0) {
          message += '収縮期(最大)血圧は0〜300の範囲で入力ください。'
          valid = false
        }
      }
      if (this.medEx.blood_pressure_min_value) {
        if (isNaN(this.medEx.blood_pressure_min_value)) {
          message += '拡張期(最小)血圧は数値で入力してください。'
          valid = false
        } else if (Number(this.medEx.blood_pressure_min_value) > 300 || Number(this.medEx.blood_pressure_min_value) < 0) {
          message += '拡張期(最小)血圧は0〜300の範囲で入力ください。'
          valid = false
        }
      }
      if (this.medEx.blood_pressure_max_value && this.medEx.blood_pressure_min_value) {
        if (Number(this.medEx.blood_pressure_max_value) < Number(this.medEx.blood_pressure_min_value)) {
          message += '収縮期(最大)血圧は拡張期(最小)血圧よりも大きな値を入力ください。'
          valid = false
        }
      }
      if ((this.medEx.blood_pressure_max_value && !this.medEx.blood_pressure_min_value) || (!this.medEx.blood_pressure_max_value && this.medEx.blood_pressure_min_value)) {
        message += '収縮期(最大)血圧と拡張期(最小)血圧の両方を入力してください。'
        valid = false
      }
      return { valid: valid, message: message }
    },

    bloodPressureValid () {
      return this.validateBloodPressure.valid
    },

    bloodPressureNotify () {
      return this.validateBloodPressure.message
    },

    bodyTempValid () {
      if (this.medEx.body_temperature_value) {
        if (isNaN(this.medEx.body_temperature_value)) {
          return false
        } else if (this.medEx.body_temperature_value < 33 || this.medEx.body_temperature_value > 43) {
          return false
        }
      }
      return true
    },

    bodyTempNotify () {
      if (this.medEx.body_temperature_value) {
        if (isNaN(this.medEx.body_temperature_value)) {
          return '体温は数値で入力してください。'
        } else if (this.medEx.body_temperature_value < 33 || this.medEx.body_temperature_value > 43) {
          return '体温は、33℃〜43℃の範囲で入力ください。'
        }
      }
      return ''
    },

    spO2Valid () {
      if (this.medEx.sp_o2_value) {
        if (isNaN(this.medEx.sp_o2_value)) {
          return false
        } else if (this.medEx.sp_o2_value < 0 || this.medEx.sp_o2_value > 100) {
          return false
        }
      }
      return true
    },

    spO2Notify () {
      if (this.medEx.sp_o2_value) {
        if (isNaN(this.medEx.sp_o2_value)) {
          return '酸素飽和度は数値で入力ください。'
        } else if (this.medEx.sp_o2_value < 0 || this.medEx.sp_o2_value > 100) {
          return '酸素飽和度は0〜100％の範囲で入力ください。'
        }
      }
      return ''
    },

    pulseRateValid () {
      if (this.medEx.pulse_rate_value) {
        if (isNaN(this.medEx.pulse_rate_value)) {
          return false
        } else if (this.medEx.pulse_rate_value < 0 || this.medEx.pulse_rate_value > 200) {
          return false
        }
      }
      return true
    },

    pulseRateNotify () {
      if (this.medEx.pulse_rate_value) {
        if (isNaN(this.medEx.pulse_rate_value)) {
          return '脈拍は数値で入力してください。'
        } else if (this.medEx.pulse_rate_value < 0 || this.medEx.pulse_rate_value > 200) {
          return '脈拍は200以下で入力ください。'
        }
      }
      return ''
    },

    validToCommit () {
      if (this.bloodPressureValid && this.bodyTempValid && this.spO2Valid && this.pulseRateValid) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    cloneObject () {
      this.medEx = Object.assign(this.medEx, this.medicalExamination)
      // 数値で表現される値が-1(値なし)の時、nullを大入試直す
      if (this.medEx.blood_pressure_max_value < 0) {
        this.medEx.blood_pressure_max_value = null
      }
      if (this.medEx.blood_pressure_min_value < 0) {
        this.medEx.blood_pressure_min_value = null
      }
      if (this.medEx.body_temperature_value < 0) {
        this.medEx.body_temperature_value = null
      }
      if (this.medEx.sp_o2_value < 0) {
        this.medEx.sp_o2_value = null
      }
      if (this.medEx.pulse_rate_value < 0) {
        this.medEx.pulse_rate_value = null
      }
    },

    initObject () {
      this.medEx = {
        blood_pressure_max_value: null,
        blood_pressure_min_value: null,
        body_temperature_value: null,
        sp_o2_value: null,
        pulse_rate_value: null,
        irregular_pulse_value: -1,
        anemia_state_value: -1,
        jaundice_state_value: -1,
        oral_state_value: -1,
        respiratory_noize_value: -1,
        respiratory_rate_state_value: -1,
        hart_sound_value: -1,
        abdominal_palpation_value: -1,
        abdominal_auscultation_value: -1,
        edema_value: -1,
        bedsore_value: -1,
        eating_state_value: -1,
        take_medicine_state_value: -1,
        urination_state_value: -1,
        faces_state_value: -1
      }
    },

    irregularPulseValueChange (value) {
      this.medEx.irregular_pulse_value = value
    },

    anemiaStateValueChange (value) {
      this.medEx.anemia_state_value = value
    },

    jaundiceStateValueChange (value) {
      this.medEx.jaundice_state_value = value
    },

    oralStateValueChange (value) {
      this.medEx.oral_state_value = value
    },

    respiratoryNoizeValueChange (value) {
      this.medEx.respiratory_noize_value = value
    },

    respiratoryRateStateValueChange (value) {
      this.medEx.respiratory_rate_state_value = value
    },

    hartSoundValueChange (value) {
      this.medEx.hart_sound_value = value
    },

    abdominalPalpationValueChange (value) {
      this.medEx.abdominal_palpation_value = value
    },

    abdominalAuscultationValueChange (value) {
      this.medEx.abdominal_auscultation_value = value
    },

    edemaValueChange (value) {
      this.medEx.edema_value = value
    },

    bedsoreValueChange (value) {
      this.medEx.bedsore_value = value
    },

    eatingStateValueChange (value) {
      this.medEx.eating_state_value = value
    },

    takeMedicineStateValueChange (value) {
      this.medEx.take_medicine_state_value = value
    },

    urinationStateValueChange (value) {
      this.medEx.urination_state_value = value
    },

    facesStateValueChange (value) {
      this.medEx.faces_state_value = value
    },

    isExist () {
      if (
        this.medEx.blood_pressure_max_value ||
        this.medEx.blood_pressure_min_value ||
        this.medEx.body_temperature_value ||
        this.medEx.sp_o2_value ||
        this.medEx.pulse_rate_value ||
        this.medEx.irregular_pulse_value >= 0 ||
        this.medEx.anemia_state_value >= 0 ||
        this.medEx.jaundice_state_value >= 0 ||
        this.medEx.oral_state_value >= 0 ||
        this.medEx.respiratory_noize_value >= 0 ||
        this.medEx.respiratory_rate_state_value >= 0 ||
        this.medEx.hart_sound_value >= 0 ||
        this.medEx.abdominal_palpation_value >= 0 ||
        this.medEx.abdominal_auscultation_value >= 0 ||
        this.medEx.edema_value >= 0 ||
        this.medEx.bedsore_value >= 0 ||
        this.medEx.eating_state_value >= 0 ||
        this.medEx.take_medicine_state_value >= 0 ||
        this.medEx.urination_state_value >= 0 ||
        this.medEx.faces_state_value >= 0
      ) {
        return true
      } else {
        return false
      }
    },

    clear () {
      this.initObject()
      this.$emit('commitMedicalExaminationEvent', this.medEx, this.isExist(), () => { console.log('call clear') })
    },

    commit () {
      if (this.bloodPressureValid && this.bodyTempValid && this.spO2Valid && this.pulseRateValid) {
        this.$emit('commitMedicalExaminationEvent', this.medEx, this.isExist(), () => { this.initObject() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mediaQuery.scss';

.horus-medical-examination-edit-modal {
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.3;
  }
  .modal {
    position: absolute;
    width: 600px;
    max-height: calc(100vh - 60px);
    margin: 0;
    padding: 0;
    top: 30px;
    left: calc((100vw - 600px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    @include mediaQuery('tablet') {
      width: calc(100vw - 40px);
      left: 20px;
    }
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100vh - 100px);
      left: 5px;
    }
    .modal-header {
      padding: 10px 20px;
      background-color: #f8fcf9;
      color: #316745;
      font-weight: bold;
      font-size: 12px;
      img {
        margin: 0 5px 3px 0;
        width: 15px;
        vertical-align: middle;
      }
    }
    .control {
      padding: 5px 10px;
      text-align: right;
      button {
        cursor: pointer;
        display: inline-block;
        height: 30px;
        width: 100px;
        margin: 0 0 0 10px;
        padding: 5px 20px;
        width: 100px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 10px;
        border-radius: 2px;
      }
      .clear {
        border: 1px solid #aaa;
        background-color: #fff;
        color: #333;
      }
      .commit {
        border: 1px solid #666;
        background-color: #666;
        color: #fff;
        &.disable {
          border: 1px solid #aaa;
          background-color: #aaa;
        }
      }
    }
    .modal-body {
      height: calc(100vh - 60px - 110px);
      overflow: scroll;
      padding: 10px;
      .section-title {
        margin-top: 5px;
        padding: 2px 3px;
        background-color: #f8fcf9;
        border-bottom: 1px solid #316745;
        color: #666;
        font-size: 12px;
        img {
          margin-right: 10px;
          width: 18px;
        }
      }
      .section-block{
        display: flex;
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
        font-size: 16px;
        label {
          display: inline-block;
          margin-right: 10px;
          width: 135px;
          text-align: right;
          @include mediaQuery('phone') {
            width: 80px;
          }
        }
        div {
          @include mediaQuery('phone') {
            width: calc(100% - 80px);
          }
        }
        .inputs {
          font-size: 14px;
          input {
            padding: 3px;
            margin-right: 5px;
            width: 60px;
            font-size: 16px;
          }
        }
        .notify-messages {
          font-size: 12px;
          color: #a22041;
        }
      }
    }
  }
}
</style>
