<template>
  <div class="content-line body-composition">
    <div class="vital-value-column">
      <span class="label">体重</span>
      <span class="value">{{ vitalBodyComposition.weight }}</span>
      <span class="unit">kg</span>
    </div>
    <div class="vital-value-column">
      <span class="label">体脂肪率</span>
      <span class="value">{{ vitalBodyComposition.body_fat_ratio }}</span>
      <span class="unit">％</span>
    </div>
    <div class="vital-value-column">
      <span class="label">基礎代謝</span>
      <span class="value">{{ vitalBodyComposition.base_metabolism }}</span>
      <span class="unit">kcal</span>
    </div>
    <div class="vital-value-column">
      <span class="label">骨格筋率</span>
      <span class="value">{{ vitalBodyComposition.body_skeltal_muscle_ratio }}</span>
      <span class="unit">％</span>
    </div>
    <div class="vital-value-column">
      <span class="label">BMI</span>
      <span class="value">{{ vitalBodyComposition.bmi }}</span>
      <span class="unit"></span>
    </div>
    <div class="vital-value-column">
      <span class="label">体年齢</span>
      <span class="value">{{ vitalBodyComposition.body_age }}</span>
      <span class="unit">歳</span>
    </div>
    <div class="vital-value-column">
      <span class="label">内蔵脂肪レベル</span>
      <span class="value">{{ vitalBodyComposition.viceral_fat_level }}</span>
      <span class="unit"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusVitalBodyCompositionColumn',

  props: {
    vitalBodyComposition: Object
  }
}
</script>

<style lang="scss" scoped>
.content-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  padding-top: 5px;
  .vital-value-column {
    display: flex;
    padding-left: 20px;
    .label {
      align-self: flex-start;
      margin-right: 5px;
      font-size: 0.9em;
    }
    .value {
      align-self: center;
      font-size: 1.5em;
      font-weight: bold;
      color: #666;
    }
    .unit {
      align-self: flex-end;
      font-size: 0.9em;
    }
  }
}
</style>
