<template>
  <div class="horus-receive-reporter-relation-request-in-manage labeled_content_block">
    <div class="labeled_content_single_block">
      <label>送信者名</label>
      <div class="content">{{ receiveRequest.user_name }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>状態</label>
      <div class="content">{{ receiveRequest.status }}</div>
    </div>
    <div class="labeled_content_single_block">
      <label>メッセージ</label>
      <div class="content" v-html="receiveRequestMessage"></div>
    </div>
    <div class="content-controll-block">
      <button type="button" @click="confirmRequest">承認もしくは拒否の処理</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusReceiveReporterRelationRequestInManage',

  props: {
    receiveRequest: Object
  },

  computed: {
    receiveRequestMessage () {
      if (this.receiveRequest.message_content && this.receiveRequest.message_content.length > 0) {
        return this.receiveRequest.message_content.replace(/\n/gi, '<br />')
      } else {
        return 'かかりつけ申込のメッセージはありません。'
      }
    }
  },

  methods: {
    confirmRequest () {
      this.$emit('confirmRequestEvent', this.receiveRequest)
    }
  }
}
// require('@/assets/css/labeledContent.scss')
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';
</style>
