<template>
  <div class="horus-user-basic-edit-modal">
    <horus-modal-frame
      :showModalFlag="showUserBasicEditModal">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />ユーザー基本情報編集
        </h1>
      </div>
      <div class="modal-body">
        <horus-user-basic-form
          :timelineOwner="timelineOwner"
          :userBasicSource="userBasicSource"
          :submitTitle="'更  新'"
          v-on:cancelSubmitEvent="cancelSubmit"
          v-on:executeSubmitEvent="executeSubmit"
          />
      </div>
      <div class="modal-footer"></div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusUserBasicForm from '@/components/profile/HorusUserBasicForm.vue'

export default {
  name: 'HorusUserBasicEditModal',

  components: {
    HorusModalFrame,
    HorusUserBasicForm
  },

  props: {
    timelineOwner: Object,
    showUserBasicEditModal: Boolean,
    userBasicSource: Object
  },

  methods: {
    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    executeSubmit (formData) {
      this.$emit('executeSubmitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-user-basic-edit-modal {
  .modal-body {
    padding: 10px;
  }
}
</style>
