<template>
  <div class="horus-user-task-view">
    <div class="user-task-contents">
      <h1 class="centering-title">予定管理</h1>
      <horus-user-task
        :signinUser="loggedInUser"
        :signinMember="signinMember"
        :taskUpdatedAt="taskUpdatedAt"
        :taskDisplayConfiguration="taskDisplayConfiguration"
        v-on:userTaskUpdatedEvent="userTaskUpdated"
        v-on:createTaskEvent="createUserTask('none')"
        v-on:editTaskEvent="editTask"
        v-on:deleteTaskEvent="deleteTask"
        v-on:showTaskDetailEvent="showTaskDetail"
        v-on:showTaskPhotoEvent="showTaskPhoto"
        v-on:showTaskConfigurationEvent="showTaskConfiguration"
        />
    </div>
    <horus-footer />
    <horus-select-timeline-owner-modal
      :timelineOwners="relatedUsers"
      :signinMember="signinMember"
      :showModal="showSelectTimelineOwnerModal"
      v-on:timelineOwnerSelectedEvent="timelineOwnerSelected"
      v-on:timelineOwnerSelectCancelEvent="timelineOwnerSelectCancel"
      />
    <horus-user-task-form-modal
      :showModal="showUserTaskFormModal"
      :signinUser="loggedInUser"
      :signinMember="signinMember"
      :zoomUser="zoomUser"
      :timelineOwner="timelineOwner"
      :organization="selectTargetOrganization"
      :sourceTask="editTargetTask"
      :targetDate="formTaskTargetAt"
      :selectedTags="taskSelectedTags"
      :createTags="taskCreateTags"
      :pushTargets="selectTargetForAuthor"
      :organizationStaffVisibilities="organizationStaffVisibilities"
      v-on:setTargetDateEvent="setTaskTargetDate"
      v-on:showTaskPhotoEvent="showTaskPhoto"
      v-on:openTagSelectionModalEvent="openTagSelectionModalForTask"
      v-on:removeSelectedTagEvent="removeSelectedTagForTask"
      v-on:removeCreateTagEvent="removeCreateTagForTask"
      v-on:openPushTargetSelectionModalEvent="openPushTargetSelectionModalForTask"
      v-on:removeSelectedAuthorEvent="removeSelectedAuthorForTask"
      v-on:postformClearEvent="taskFormClear"
      v-on:submitPostFormEvent="submitTaskForm"
      v-on:showNotifyEvent="showNotifyDialog"
      />
    <horus-photo-modal
      :timelineOwner="timelineOwner"
      :photos="photoModalPhotos"
      :type="'userTask'"
      :author="null"
      :targetAt="null"
      :title="'予定写真'"
      :firstShow="photoModalFirst"
      :hashTags="[]"
      :showPhotoModal="photoModalShow"
      v-on:jumpToOriginEvent="jumpToOriginFromPhotoModal"
      v-on:photoModalCloseEvent="closePhotoModal"
      />
    <horus-tag-select-for-post-modal
      :showTagSelectionModal="presentedModal === PRESENTED_MODAL_TYPE.TAG_SELECTION"
      :hashTags="hashTags"
      :activeTags="selectTargetsForTag"
      :createTags="createdTargetForTag"
      v-on:tagSelectionModalCloseEvent="tagSelectionModalClose"
      v-on:updateSelectedTagEvent="updateSelectedTag"
      v-on:addNewPostTagEvent="addNewPostTag"
      v-on:clearSelectedTagEvent="clearSelectedTags"
      v-on:removeNewTagEvent="removeNewPostTag"
      />
    <horus-author-select-for-post-modal
      :showAuthorSelectionModal="presentedModal === PRESENTED_MODAL_TYPE.PUSH_TARGET_SELECTION"
      :families="notificatableFamilies"
      :organizations="organizations"
      :selectedAuthors="selectTargetForAuthor"
      :selectLockAuthor="[]"
      :deselectLockAuthor="[]"
      :allFamilySelected="allFamilySelectForAuthor"
      :selectedOrganizations="selectOrganizationTargetForAuthor"
      :organizationStaffVisibilities="organizationStaffVisibilities"
      v-on:toggleAllFamilyEvent="toggleAllFamilySelect"
      v-on:toggleAllMemberEvent="toggleAllMemberSelect"
      v-on:toggleSelectAuthorEvent="toggleSelectAuthor"
      v-on:allAuthorSelectEvent="allAuthorsSelect"
      v-on:clearSelectedAuthorEvent="clearSelectedAuthors"
      v-on:authorSelectionModalCloseEvent="pushTargetSelectModalClose"
      />
    <horus-future-date-selection-modal
      :showDateSelectionModal="futureDateSelectionModalShow"
      :initializeDate="formTaskTargetAt"
      v-on:targetDateSelectionCloseEvent="futureDateSelectionModalClose"
      v-on:selectTargetDateCancelEvent="futureDateSelectionCancel"
      v-on:selectTargetDateCommitEvent="futureDateSelectionCommit"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusUserTask from '@/components/userTask/HorusUserTask.vue'
import HorusSelectTimelineOwnerModal from '@/components/userTask/HorusSelectTimelineOwnerModal.vue'
import HorusUserTaskFormModal from '@/components/userTask/HorusUserTaskFormModal.vue'
import HorusPhotoModal from '@/components/common/HorusPhotoModal.vue'
import HorusTagSelectForPostModal from '@/components/postForm/HorusTagSelectForPostModal.vue' // mixin HorusTagSelection.js
import HorusAuthorSelectForPostModal from '@/components/postForm/HorusAuthorSelectForPostModal.vue' // mixin HorusAuthorSelection.js
import HorusFutureDateSelectionModal from '@/components/common/HorusFutureDateSelectionModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusUserTaskControll from '@/mixin/HorusUserTaskControll.js'
import HorusTagSelection from '@/mixin/HorusTagSelection.js'
import HorusAuthorSelection from '@/mixin/HorusAuthorSelection.js'
import HorusTimelineOwnerRelatedData from '@/mixin/HorusTimelineOwnerRelatedData.js'
import HorusOrganizationMemberVisibility from '@/mixin/HorusOrganizationMemberVisibility.js'
import HorusFutureDateSelectionControll from '@/mixin/HorusFutureDateSelectionControll.js'

import * as PRESENTED_MODAL_TYPE from '@/assets/constants/modalType.js'

export default {
  mixins: [
    HorusConfirmDialogControll,
    HorusUserTaskControll,
    HorusTagSelection,
    HorusAuthorSelection,
    HorusTimelineOwnerRelatedData,
    HorusOrganizationMemberVisibility,
    HorusFutureDateSelectionControll
  ],

  name: 'HorusUserTaskView',

  props: {
    loggedInUser: Object,
    signinMember: Object,
    zoomUser: Object,
    taskUpdatedAt: Date,
    taskDisplayConfiguration: Object
  },

  components: {
    HorusUserTask,
    HorusSelectTimelineOwnerModal,
    HorusUserTaskFormModal,
    HorusPhotoModal,
    HorusTagSelectForPostModal,
    HorusAuthorSelectForPostModal,
    HorusFutureDateSelectionModal,
    HorusFooter,
    HorusConfirmDialog
  },

  data () {
    return {
      // relatedUsers: [],
      PRESENTED_MODAL_TYPE: PRESENTED_MODAL_TYPE,
      presentedModal: PRESENTED_MODAL_TYPE.NONE,
      timelineOwner: null, // variable for userTask create and edit.
      organizationStaffVisibilities: []
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-user-task-view {
  height: calc(100% - 50px);
  overflow: auto;
  .user-task-contents {
    padding: 10px 20px;
    padding-right: 0;
    @include mediaQuery('phone') {
      padding-left: 0;
    }
    .centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
  }
}
</style>
